import {PhotoContentPredicter} from './photo_content_predicter';

export enum DefaultPhotoLabel {
    VIEW = 'Uitzicht',
    STREET_VIEW = 'Straatbeeld',
    FRONT_VIEW = 'Vooraanzicht',
    BACKSIDE = 'Achtergevel',
    TOILET = 'Toilet',
    KITCHEN = 'Keuken',
    SCULLERY = 'Bijkeuken',
    LAUNDRY_ROOM = 'Wasruimte',
    BATHROOM = 'Badkamer',
    BEDROOM = 'Slaapkamer',
    LIVINGROOM = 'Woonkamer',
    FUSE_BOX = 'Meterkast',
    ATTIC = 'Zolder',
    POOL = 'Zwembad',
    STORAGE = 'Berging',
    ENTRANCE_HALL = 'Hal',
    CELLAR = 'Kelder',
    SERRE = 'Serre',
    BALCONY = 'Balkon',
    TECHNICAL_ROOM = 'Technische ruimte',
    CV = 'Cv',
    LOFT = 'Bergzolder',
    OFFICE = 'Werkkamer',
    OUTBUILDING = 'Bijgebouw',
    BARN = 'Schuur',
    PARKING = 'Parkeerplaats',
    GARAGE = 'Garage',
    CARPORT = 'Carport',
    CANOPY = 'Overkapping',
    GARDEN = 'Tuin',
    TERRAS = 'Terras',
    ROOF_TERRAS = 'Dakterras',
    GRASSLAND = 'Weiland',
    OMVORMER = 'Omvormer',
}

export enum ConstructionalDefectsPhotoLabel {
    SHRUNKEN_PLASTIC_ROOFING = 'GekrompenKunststofDakbedekking',
    RAIN_DAMAGE_ON_PAINTWORK = 'RegenschadeAanVerfwerk',
    MOSSGROW_ON_JOINT = 'MosgroeiOpHetVoegwerk',
    PLACE_WOODEN_DORMER = 'HoutenDakkapelPlaatsen',
    WASTE_IN_CRAWLSPACE = 'AfvalInKruipruimte',
    CRACKS_IN_BRICK_CHIMNEY = 'ScheurenInGemetseldeSchoorstee',
    PEELING_OF_CERAMIC_ROOFTILES = 'AfschilferenVanKeramischeDakpa',
    CONDENSATION_BY_BAD_SUPPORT = 'CondensatieBijSlechteOndersteu',
    PLYWOOD_DELAMINATION = 'DelaminatieMultiplexBekledinge',
    LEAKAGE_ABOVE_FACADEWINDOWS = 'LekkageBovenKozijnenInGevel',
    LOOSE_ROOFING = 'LosliggendeDakbedekkingInclusi',
    BLOWNOFF_ROOFTILES = 'AfwaaienVanDakpannen',
    LEAKAGE_AT_HEAVENWATER_DRAIN = 'LekkageBijHemelwaterafvoerenMe',
    LEAKING_HEAVENWATER_DRAINAGE = 'LekkendeHemelwaterafvoer',
    DEFECT_HANG_AND_LATCHING = 'DefectHangEnSluitwerk',
    MOLD_AND_MOISTURE_BATHROOM = 'SchimelEnVochtplekkenInBadkame',
    PLASTERING_RELEASE_ON_CONCRETE = 'LoskomenPleisterwerkOpBeton',
    BAD_PAINTING_CONDITION = 'SchilderwerkInSlechteConditie',
    MOLD_ON_WALLS_CEILING = 'SchimmelvormingOpWandenPlafond',
    LEAD_WATERPIPE = 'LodenWaterleiding',
    CHIMNEY_LEAKAGE = 'LekkageBijSchoorsteen',
    VIBRATING_ROOF_FAN = 'TrillendeDakventilator',
    BEAM_WOOD_ROT = 'HoutrotBalkkoppenBeganeGrondVl',
    FACADE_CRACKS = 'ScheurenGevelmetelwerkNabijOpe',
    CRACKED_LEAD_AT_ROOF_CONNECTION = 'GescheurdLoodBijDakaansluiting',
    CRUMBLING_STUCCO = 'AfbrokkelendStucwerk',
    RELEASING_PLASTERWORK = 'LoslatendPleisterwerk',
    CRACKED_BITUM_ROOF = 'CraqueleInDeBitumineuzeDakbede',
    CONCRETE_CARBONATION = 'Betoncarbonatatie',
    FUNGUS_IN_CREEPSPACE = 'ZwamvormingInKruipruimte',
    POLLUTED_BLINDS_OF_AIRCO = 'VervuildeLamellenVanAircos',
    ZINC_DEGRADATION_ON_BALCONY = 'AantastingZinkOpBalkonsEnPlatt',
    PEELING_BRICK = 'AFschilferenVanBaksteen',
    OBSOLOTE_CV_BOILER = 'VerouderdeCVKetel',
    COTTON_ELECTRICITY_WIRE = 'ElektraDraadVanKatoen',
    MASONRY_CRACKS = 'ScheurenInMetselwerk',
    SALT_DEPOSIT_ON_MASONRY = 'ZoutafzettingOpMetselwerk',
    SETTLEMENTS_AS_GROUNDWATER_RESULT = 'ZettingenTenGevolgeVanGrondwat',
    BASEMENT_LEAKAGE = 'LekkageInKelder',
    TOILET_RENOVATION = 'ToiletRenoveren',
    BAD_PAVEMENT = 'SlechteBestrating',
    MOLD_ON_ROOF_DECKING = 'SchimmelgroeiOpDakbeschotVanSp',
    REPLACE_KITCHEN = 'KeukenblokVervangen',
    REPLACE_BATHROOM = 'BadkamerVervangen',
    REPLACE_BATHGEYSER = 'BadgeiserVervangen',
    STEEL_WINDOW_CORROSION = 'CorrosieStalenKozijnen',
    GAS_PIPE_CORROSION = 'CorroderendeGasleiding',
    LINOLEUM_BLADDER_FORMATION = 'BlaasvromingInLinoleumVloerbed',
    GREEN_RASH_ON_COPPER = 'GroeneUitslagOpKoper',
    TEARING_OF_ROLLING_LAYER = 'ScheurenInRollaag',
    LEAKAGE_UNDER_BALCONY = 'LekkageInWoningenOnderEenBalko',
    PLACE_WOOD_SKYLIGHT = 'DakraamHoutPlaatsen',
    FRACTURE_IN_GLASS = 'BreukBeglazing',
    PAINT_BLISTERING = 'BlazenEnBlarenInHetVerfsysteem',
    GLAZED_TILE_CRACKS = 'HaarscheurenInGeglazuurdeTegel',
    TILES_LETTING_GO_BY_NON_ISOLATION = 'LoslatenTegelvloerBijOngeisole',
    CRACKED_PAINT_ON_WOOD = 'BarstvormingVerfsysteemOpHout',
    FLAT_ROOF_PUDDLES = 'PlasvormingOpPlatteDaken',
    INSUFFICIANT_GAS_OUTLET = 'OnvoldoendeTrekRookgasafvoerHo',
    CRACKED_CONCRETE = 'ScheurenInBetonnenBalkonsTerPl',
    GRAFFITI = 'Graffiti',
    SEWER_LEAKAGE = 'LekkageRioleringenVAnGres',
    CONCRETE_CORROSION = 'BetonschadeDoorCorrosieVanWape',
    DAMAGED_JOINT = 'BeschadigingVoegen',
    ASBESTOS_AROUND_PIPES = 'AsbesthoudendeMaterialenOmLeid',
    FACADE_FRAME_WOOD_ROT = 'HoutrotBijGevelkozijnen',
    OUTDATED_DISTRIBUTION_DEVICE = 'VerouderdeVerdeelinrichtingInM',
    RADIATOR_CORROSION = 'CorrosievormingRadiator',
    MISSING_FALL_PROTECTION = 'OntbrekenVanValbeveiliging',
    CRACKING_STAIRS = 'KrakenVanTraptreden',
    MOSAIC_TILE_SOFT_JOINT = 'ZachteVoegenInMozaiektegelwerk',
    INSULATED_CAVITY_RAIN_PENETRATION = 'RegendoorslagBijGeisoleerdeSpo',
    CRACKED_SEWER_PIPES = 'GescheurdeLeidingVanDeRiolerin',
    BOKTOR_IN_WOOD_CONSTRUCTION = 'BoktorInHoutsctructies',
    DAMP_SPOTS_ON_CEILING = 'VochtplekkenOpPlafond',
    FOOTER_CHIMNEY_LEAKAGE = 'LekkageVoetloodSchoorsteen',
    PARQUET_FLOOR_BUFFING = 'OpbollenVanEenHoutenParketvloe',
    POLLUTED_SOLAR_PANELS = 'VervuildeZonnepanelen',
    CRACKS_CEMENT_BONDED_SCREED = 'ScheurenInCementgebondenDekvlo',
    WEAK_JOINTS = 'ZwakVoegwerk',
    STINKING_SEWERAGE = 'StankVanDeBinnenriolering',
    INSUFFICIENT_MECHANICAL_VENTILLATION = 'OnvoldoendeWerkendMechanischVe',
    BLISTERING_ROOFING = 'BlaasvormingInDakbedekking',
    UNSAFE_ESCAPE_STAIRS = 'OnveiligeVluchttrappenExterieu',
    GAS_NUISANCE = 'OverlastDoorRookgassen',
    SOLAR_PANEL_FIRE_HAZARD = 'BrandgevaarBijZonnepanelen',
    CEILING_MOISTURE_PENETRATION = 'VochtdoorslagBijPlafonds',
    BROKEN_EXPANSION_TANK = 'KapotExpansievat',
    CRACKED_CONCRETE_FLOOR = 'ScheurenInEenBetonvloer',
    MOISTURE_SPOTS = 'Vochtplekken',
    BASEMENT_WALL_LEAKAGE = 'LekkageInKelderwanden',
    ROOF_CONSTRUCTION_MOISTER = 'VochtproblemenInEenDakconstruc',
    DAMAGE_WOODEN_ROOF_TRUSSES = 'AanstatingHoutenDakspantenDoor',
    ASBESTOS_PLATES = 'AsbesthoudendePlaten',
    CRACKS_NON_LOAD_INNER_WALL_BEARING = 'ScheurenInNietdragendeBinnenwa',
    OBSELETE_BOILER = 'VerouderdeBoiler',
    CRACKS_IN_CONSTRUCTION = 'ScheurenInDeConstructie',
}

export type PhotoLabel = DefaultPhotoLabel | ConstructionalDefectsPhotoLabel;

export interface PhotoRecognition {
    getRecognition(file: File): Promise<PhotoRecognizedAs | null>;
    prepare(): Promise<void>;
}

export interface PhotoRecognizedAs {
    label: PhotoLabel | null;
    probability: number;
}

export class AutoMLPhotoRecognition implements PhotoRecognition {
    constructor(private predictor: PhotoContentPredicter) {}

    public async getRecognition(file: File): Promise<PhotoRecognizedAs | null> {
        const prediction = await this.predictor.getPrediction(file);
        let label: PhotoLabel | null = null;
        let propability = 0;
        if (prediction !== null) {
            if (prediction.className !== null) {
                label = this.getPhotoLabelFromString(prediction.className.toString());
            }
            if (prediction.probability !== null) {
                propability = prediction.probability;
            }
        }
        return {
            label: label,
            probability: propability,
        };
    }

    public prepare(): Promise<void> {
        return this.predictor.prepare();
    }

    private getPhotoLabelFromString(string: string): PhotoLabel | null {
        const labels = [DefaultPhotoLabel, ConstructionalDefectsPhotoLabel];
        for (const labelsKey of labels) {
            for (const item of Object.values(labelsKey)) {
                if (item.toString().trim().toLowerCase() === string.toString().trim().toLowerCase()) {
                    return item;
                }
            }
        }
        return null;
    }
}
