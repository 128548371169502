import * as React from 'react';

import {Appraisal} from '../../../../../../../../models/appraisal';
import {AreaConclusion} from '../../../../../../../../models/area_conclusion';
import {FloorAreaPriceBadge} from '../../../../../../../../components/badges/floor_area_price_badge';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {isEmpty} from '../../../../../../../../../support/util';
import {observer} from 'mobx-react';

interface OwnProps {
    floorArea: number | null;
    areaConclusion: AreaConclusion | null;
    valuation: string | null;
    price: string | null;
    compact?: boolean;
    questionSet: QuestionSet;
    appraisal: Appraisal;
}

@observer
export class FloorAreaPrice extends React.Component<OwnProps> {
    private formatPrice(from: number): string {
        return `€ ${from.toLocaleString('nl-NL')}/m²`;
    }

    private average(fromString: string, tilString: string | undefined): number {
        const from = parseInt(fromString, 10);
        const till = tilString !== undefined ? parseInt(tilString, 10) : from;

        return (from + till) / 2;
    }

    public render() {
        if (
            this.props.price === null ||
            isEmpty(this.props.price.trim()) ||
            this.props.floorArea === null ||
            this.props.floorArea === 0
        ) {
            return <>{'Onbekend'}</>;
        }

        const [from, till] = this.props.price.split(' - ');
        const price = this.props.price !== null ? parseInt(this.props.price, 10) : this.average(from, till);
        const pricePerM2 = Math.round(price / this.props.floorArea);
        const referencePricePerM2 =
            this.props.areaConclusion !== null &&
            this.props.valuation !== null &&
            this.props.areaConclusion.gebruiksoppervlakteWonen !== null &&
            this.props.areaConclusion.gebruiksoppervlakteWonen !== 0
                ? Math.round(parseInt(this.props.valuation, 10) / this.props.areaConclusion.gebruiksoppervlakteWonen)
                : null;

        return (
            <>
                {referencePricePerM2 !== null ? (
                    <FloorAreaPriceBadge
                        floorAreaPrice={pricePerM2}
                        referenceSetFloorAreaPrice={referencePricePerM2}
                        compact={this.props.compact}
                    />
                ) : null}
                <span key="price_m2">{this.formatPrice(pricePerM2)}</span>
            </>
        );
    }
}
