(() => {
    const preview = $('#display_name_preview');
    if (preview.length > 0) {
        const updatePreview = () => {
            const address = $('#address').val();
            const houseNumber = $('#house_number').val();
            const postalCode = $('#postal_code').val();
            const city = $('#city').val();

            const companyName = $('#company_name').val();
            const salutation = $('input[name="salutation"]:checked').val();
            const initials = $('#initials').val();
            const firstName = $('#first_name').val();
            const lastName = $('#last_name').val();

            const fullNamePreference = $('input[name="display_name_preference_full_name"]:checked').val();
            const referencePreference = $('input[name="display_name_preference_referenced_name"]:checked').val();
            const signaturePreference = $('input[name="display_name_preference_signature"]:checked').val();

            let fullName = firstName + ' ' + lastName;
            let initialsName = salutation + ' ' + initials + ' ' + lastName;

            $('label[for="display_name_preference_full_name_full_name"]').text(fullName);
            $('label[for="display_name_preference_full_name_initials_name"]').text(initialsName);

            if (fullNamePreference === 'initials_name') {
                fullName = initialsName;
            }

            preview.text(
                `${
                    signaturePreference === 'signature_company'
                        ? companyName
                        : signaturePreference === 'signature_combined'
                        ? `${fullName} \n${companyName}`
                        : fullName
                }
${address} ${houseNumber}
${postalCode} ${city}

Beste ${fullName},

${
    referencePreference === 'reference_we'
        ? 'Hierbij willen wij u laten weten dat...'
        : 'Hierbij wil ik u laten weten dat...'
}
`
            );
        };

        updatePreview();

        $('input').on('change', () => updatePreview());
    }
})();
