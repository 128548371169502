import {AutoMLDetailPhotoRecognition, PhotoDetailPredictor} from './automl_detail_photo_recognition';
import {AutoMLPhotoContentPredicter, AutoMLVersion} from './automl_photo_content_predicter';
import {AutoMLPhotoRecognition, PhotoRecognition, PhotoRecognizedAs} from './automl_photo_recognition';
import {PhotoContentPredicter, Prediction} from './photo_content_predicter';

export interface PhotoContentHandler {
    getPrediction(file: File): Promise<Prediction | null>;
    getVersionPrediction(version: AutoMLVersion, uploadedFile: File): Promise<Prediction | null>;
    prepare(): Promise<void>;
    getRecognition(file: File | string): Promise<PhotoRecognizedAs | null>;
    getDetailsFromPhoto(photo: PhotoRecognizedAs, file: File): Promise<Prediction[]>;
}

export class AutomlPhotoHandler implements PhotoContentPredicter, PhotoRecognition, PhotoContentHandler {
    private prepared = false;
    private recognizer: PhotoRecognition;
    private predictor: PhotoContentPredicter;
    private detailRecognizer: PhotoDetailPredictor;

    constructor(version: AutoMLVersion) {
        this.predictor = new AutoMLPhotoContentPredicter(version);
        this.recognizer = new AutoMLPhotoRecognition(this.predictor);
        this.detailRecognizer = new AutoMLDetailPhotoRecognition(this.predictor);
    }

    public async getRecognition(file: File): Promise<PhotoRecognizedAs | null> {
        return this.recognizer.getRecognition(file);
    }

    public async getPrediction(file: File): Promise<Prediction | null> {
        return this.predictor.getPrediction(file);
    }

    public getVersionPrediction(version: AutoMLVersion, uploadedFile: File): Promise<Prediction | null> {
        return this.predictor.getVersionPrediction(version, uploadedFile);
    }

    public getVersionPredictions(version: AutoMLVersion, uploadedFile: File): Promise<Prediction[] | null> {
        return this.predictor.getVersionPredictions(version, uploadedFile);
    }

    public getDetailsFromPhoto(photo: PhotoRecognizedAs, file: File): Promise<Prediction[]> {
        return this.detailRecognizer.getDetailsFromPhoto(photo, file);
    }

    public async prepare(): Promise<void> {
        if (!this.prepared) {
            await this.recognizer.prepare();
            this.prepared = true;
        }
    }
}
