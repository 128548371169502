import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';

import {EditableDayPickerInput} from '../components/editable_day_picker_input';
import {Label} from '../components/label';
import {ValidationMessages} from '../components/validation_messages';
import {classNames} from '../../../../../../support/classnames';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {isMobile} from '../../../../../../support/check_mobile';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const DatePickerQuestion: React.FC<OwnProps> = observer(function DatePickerQuestion(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));
    if (presenter.isHidden) {
        return null;
    }

    function handleDateChange(date: string | undefined) {
        if (date !== undefined) {
            presenter.onChange(date);
        }
    }

    function renderPicker() {
        return (
            <div className="input-group">
                <div className="input-group-prepend">
                    <div className="input-group-text ion-md-calendar">&nbsp;</div>
                </div>
                {isMobile() ? (
                    <input
                        type="date"
                        className="form-control"
                        value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                        onChange={(e) => presenter.onChange(e.target.value, false)}
                        onBlur={() => presenter.onTouched()}
                    />
                ) : (
                    <EditableDayPickerInput
                        value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                        onChange={(date) => handleDateChange(date)}
                    />
                )}
            </div>
        );
    }

    function renderDisabled() {
        return (
            <div className="input-group">
                {presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : '-'}
            </div>
        );
    }

    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'automation-filled': presenter.filledByAutomator !== null,
            })}
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
        >
            <Label
                for={presenter.name}
                question={props.question}
                parentAnswerUuid={props.parentAnswerUuid}
                iteration={props.iteration}
                appraisal={props.appraisal}
                questionSet={props.questionSet}
                disabled={props.disabled || presenter.isDisabled}
                hideLabel={props.hideLabel}
                showLabel={props.question.showLabel}
                renderingContext={props.renderingContext}
            />
            {presenter.isDisabled ? renderDisabled() : renderPicker()}
            <ValidationMessages
                alignLeft
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={presenter.answer}
                validationMessages={filterValidationMessages(
                    props.question,
                    presenter.answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
        </div>
    );
});
