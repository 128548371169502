export function drawOnCanvas(canvas: HTMLCanvasElement, image: HTMLImageElement, rotationDegrees: 0 | 90 | 180 | 270) {
    if (rotationDegrees !== 0 && rotationDegrees !== 90 && rotationDegrees !== 180 && rotationDegrees !== 270) {
        console.warn('Invalid degrees for rotation:', rotationDegrees);
        return;
    }

    const context = canvas.getContext('2d');
    if (context) {
        if (rotationDegrees === 90 || rotationDegrees === 270) {
            canvas.width = image.height;
            canvas.height = image.width;
        } else {
            canvas.width = image.width;
            canvas.height = image.height;
        }

        context.imageSmoothingEnabled = false;

        context.clearRect(0, 0, canvas.width, canvas.height);
        const x = canvas.width / 2;
        const y = canvas.height / 2;
        const width = image.width;
        const height = image.height;
        const angleInRadians = (rotationDegrees * Math.PI) / 180;

        context.translate(x, y);
        context.rotate(angleInRadians);
        context.drawImage(image, -width / 2, -height / 2, width, height);
        context.rotate(-angleInRadians);
        context.translate(-x, -y);
    }
}
