import {Observable, combineLatest, of} from 'rxjs';

import {AnswerController} from '../answering/answer_controller';
import {AppraisalProvider} from '../appraisal_provider';
import {ConditionsInteractor} from './conditions_interactor';
import {QuestionSet} from '../../models/question_set';
import {collectAnswersObservablesForConditionGroups} from './answer_observable_for_condition_groups';
import {isHiddenBasedOnEffectStates} from './hidden_based_on_effect_states';
import {map} from 'rxjs/operators';

export interface QuestionEffectInteractor {
    isHiddenStream(questionUuid: string, parentAnswerUuid: string | null): Observable<boolean>;

    isHidden(questionUuid: string, parentAnswerUuid: string | null, cache?: Record<string, boolean>): boolean;
}

export class DefaultQuestionEffectInteractor implements QuestionEffectInteractor {
    constructor(
        private questionSet: QuestionSet,
        private conditionsInteractor: ConditionsInteractor,
        private answerController: AnswerController,
        private appraisalProvider: AppraisalProvider
    ) {}

    /**
     * The isHiddenStream is only an partial reactive stream, it only updates when one of the conditions of this question change
     * It doesnt propagate updates if one of the parents their visibility changes
     * This becaus it calls the static isHidden call which recursively checks its parents, which isn't a stream
     * This however is most of the times fine since react will unmount parent questions which the children are nested in
     * It however can be an issue if you are checking a question directly, for example for widgets
     */
    public isHiddenStream(questionUuid: string, parentAnswerUuid: string | null): Observable<boolean> {
        const question = this.questionSet.findQuestionByUuid(questionUuid);
        if (question === undefined || question.conditionGroups.length === 0) {
            return of(false);
        }

        const answerObservablesForQuestions = collectAnswersObservablesForConditionGroups(
            this.answerController,
            question.conditionGroups,
            parentAnswerUuid
        );

        return combineLatest([this.appraisalProvider.stream, ...answerObservablesForQuestions]).pipe(
            map(() => this.isHidden(questionUuid, parentAnswerUuid, {}))
        );
    }

    private createCacheKey(questionUuid: string, parentAnswerUuid: string | null) {
        return `${questionUuid}_${parentAnswerUuid}`;
    }

    public isHidden(questionUuid: string, parentAnswerUuid: string | null, cache?: Record<string, boolean>): boolean {
        const thisKey = this.createCacheKey(questionUuid, parentAnswerUuid);
        if (cache && cache[thisKey] != null) {
            return cache[thisKey];
        }

        const effectStates = this.conditionsInteractor.get(questionUuid, parentAnswerUuid);

        const isHidden = isHiddenBasedOnEffectStates(effectStates);
        if (isHidden) {
            if (cache) {
                cache[thisKey] = true;
            }
            return true;
        }

        //Find the parent answer
        const parentAnswer = parentAnswerUuid != null ? this.answerController.byUuid(parentAnswerUuid) : null;
        if (parentAnswer === null) {
            if (cache) {
                cache[thisKey] = false;
            }
            return false;
        }

        const result = this.isHidden(parentAnswer.questionUuid, parentAnswer.parentUuid, cache);
        if (cache) {
            cache[thisKey] = result;
        }
        return result;
    }
}
