import {ImageUrlPair} from '../../../../../../../models/image_url_pair';
import {ReferenceSaleImage} from '../v1/models/reference_sale_image';
import {Source} from '../v3/models/reference_object';

export function buildImageUrls(
    uploadedPhotoUrl: string | null,
    frontviewUrl: string | null,
    street: string,
    houseNumber: string,
    city: string,
    source: string | null,
    answerImageUrlPairs: ImageUrlPair[] | null,
    referenceObjectImageUrlPairs: ReferenceSaleImage[] | null,
    referenceObjectId: string | null
): ImageUrlPair[] {
    const pairs = new Map<string, ImageUrlPair>();

    if (uploadedPhotoUrl) {
        pairs.set(uploadedPhotoUrl, {path: null, url: uploadedPhotoUrl});
    }

    if (frontviewUrl) {
        let url = frontviewUrl;
        if (url.startsWith('//')) {
            url = url.replace(/^\/\//, 'https://');
        }
        pairs.set(url, {path: null, url: url});
    }

    // When the original source is Brainbay (NVM) it should not have streetview
    if (source !== Source.ARCHIEF_NVM) {
        const streetViewUrl = '/reference-objects/address/' + street + '/' + houseNumber + '/' + city + '/streetview';
        pairs.set(streetViewUrl, {path: null, url: streetViewUrl});
    }

    if (answerImageUrlPairs && answerImageUrlPairs.length > 0) {
        for (const pair of answerImageUrlPairs) {
            pairs.set(pair.url, pair);
        }
    }

    if (referenceObjectImageUrlPairs && referenceObjectImageUrlPairs.length > 0) {
        for (const pair of referenceObjectImageUrlPairs) {
            if (pair.url !== null) {
                pairs.set(pair.url, {
                    path: pair.key,
                    url: pair.url,
                });
            }
        }
    }

    if (pairs.size === 0 && referenceObjectId !== null && source !== Source.ARCHIEF_NVM) {
        const streetViewUrl = '/reference-objects/sale/' + referenceObjectId + '/streetview';
        pairs.set(streetViewUrl, {path: null, url: streetViewUrl});
    }

    return Array.from(pairs.values());
}
