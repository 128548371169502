export enum IteratorQuestionType {
    // Iterators
    PAGE_PART_FLOORS = 'page-part-floors',
    PAGE_PART_ITERATOR = 'page-part-iterator',
    ITERATOR = 'iterator',
    ITERATOR_FILES = 'iterator-files',
    ITERATOR_GROUP = 'iterator-group', //This is a special type, only on client side
    PHOTO_ITERATOR = 'photo-iterator',
    ITERATOR_PHOTO_GROUP = 'iterator-photo-group', //This is a special type, only on client side
    PHOTO_ITERATOR_SMALL = 'photo-iterator-small',
    ITERATOR_PHOTO_GROUP_SMALL = 'iterator-photo-group-small', //This is a special type, only on client side
    ITERATOR_CADASTRAL = 'iterator-cadastral',
    ITERATOR_REFERENCE_OBJECTS_V3 = 'iterator-reference-objects-v3',
}

export enum LayoutQuestionType {
    HORIZONTAL_GROUP = 'horizontal-group',
    QUESTION_ROW = 'question-row',
    QUESTION_ROW_COMPACT = 'question-row-compact',
}

export enum FloorQuestionType {
    // Floors
    FLOOR_GROUP_ATTIC = 'floor-group-attic',
    FLOOR_GROUP_FLOOR = 'floor-group-floor',
    FLOOR_GROUP_GROUND = 'floor-group-ground',
    FLOOR_GROUP_BASEMENT = 'floor-group-basement',
}

export enum RootGroupQuestionType {
    // Groups
    PRE_GROUP = 'pre-group',
    POST_GROUP = 'post-group',
    HOUSE_GROUP = 'house-group',
    PHOTO_GROUP = 'photo-group',
    BUILDING_COSTS_SHEET = 'building-costs-sheet',
    ENERGETIC_SHEET = 'energetic-sheet',
    CONCEPT_REPORT = 'concept-report',

    HOUSE_INSIDE = 'house-inside',
    OBSERVATION_GROUP = 'observation-group',
    OUTSIDE_GROUP = 'outside-group',
    ROOT_GROUP = 'root-group',

    // HOUSE_GROUP_COMPACT isnt truly a root question tho, but it behaves like one
    HOUSE_GROUP_COMPACT = 'house-group-compact',
}

export type RootQuestionType = RootGroupQuestionType | FloorQuestionType;

export enum NormalQuestionType {
    // Groups
    GROUP = 'group',
    GROUP_COMPACT = 'group-compact',
    GROUP_REFERENCE_OBJECT_DETAILS = 'group-reference-object-details',
    PAGE_PART_GROUP = 'page-part-group',
    SYMLINK_LINK = 'symlink-link',
    SYMLINK_TARGET = 'symlink-target',
    SYMLINK_TARGET_COPY = 'symlink-target-copy',
    WIDGET_GROUP = 'widget-group',
    GROUP_COLLAPSIBLE = 'group-collapsible',
    FILES_GROUP = 'files-group',
    BUILDING_COSTS_GROUP = 'building-costs-group',
    BUILDING_COSTS_PHOTO_GROUP = 'building-costs-photo-group',
    COMPARE_GROUP = 'compare-group',

    // Modals
    MODAL = 'modal',
    MODAL_WITH_ADAPTED_DEFAULT_VALUES = 'modal-with-adapted-default-values',

    // Special
    AREA_SUMMARY = 'area-summary',
    APPRAISAL_DETAILS = 'appraisal-details',
    SURFACE_CALCULATOR = 'surface-calculator',
    OBJECT_USAGE_DETAILS = 'object-usage-details',
    CADASTRAL_DOCUMENT = 'cadastral-document',

    // Other
    CHECKLIST = 'checklist',

    BUILDING_DEFECTS = 'building-defects',
    BUILDING_DEFECTS_COSTS = 'building-costs',
    BUILDING_COSTS_ROW = 'building-costs-row',
    BUILDING_COSTS_PHOTO = 'building-costs-photo',
    BUILDING_COSTS_CONDITION = 'building-costs-condition',

    CONCEPT_REPORT_REPLACEMENT = 'concept-report-replacement',

    SEARCH = 'search',

    BUILDING_INSPECTION_APPOINTMENT = 'building-inspection-appointment',

    BOUWKUNDIGE_STAAT_VIEWER = 'bouwkundige-staat-viewer',
    CONSTRUCTION_SPECIFICATION_VIEWER = 'construction-specification-viewer',
    ENERGETISCHE_STAAT_VIEWER = 'energetische-staat-viewer',

    // Simple types
    HIDDEN = 'hidden',
    DESCRIPTION = 'description',
    DESCRIPTION_IMAGE = 'description-image',
    ALERT_INLINE = 'alert-inline',
    REFERENCE_OBJECTS = 'reference-objects',
    REFERENCE_OBJECTS_SALE = 'reference-objects-sale',
    REFERENCE_OBJECTS_RENT = 'reference-objects-rent',
    REFERENCE_OBJECTS_V2 = 'reference-objects-v2',
    REFERENCE_OBJECTS_V3 = 'reference-objects-v3',
    REFERENCE_OBJECT_PHOTO = 'reference-object-photo',
    REFERENCE_OBJECT_EDITABLE_ADDRESS = 'reference-object-editable-address',
    REFERENCE_OBJECT_EXPLANATION = 'reference-object-explanation',
    OPEN = 'open',
    OPEN_COMPACT = 'open-compact',
    OPEN_MAX_CHARACTERS = 'open-max-characters',
    MC = 'multiple-choice',
    MULTIPLE_BOOLEAN_GROUP = 'multiple-boolean-group',
    COLLAPSABLE_CHECKLIST = 'collapsable-checklist',
    MC_SELECT = 'multiple-choice-select',
    MC_SELECT_OPTIONAL = 'multiple-choice-select-optional',
    MC_SELECT_COMPARE = 'multiple-choice-select-compare',
    MC_SELECT_DYNAMIC = 'multiple-choice-select-dynamic',
    SURFACE_METHOD_SELECT = 'surface-method-select',
    BOOLEAN = 'boolean',
    DATE = 'date',
    DATE_PICKER = 'date-picker',
    YEAR = 'year',
    NUMBER = 'number',
    NEGATIVE_NUMBER = 'negative-number',
    INT_NUMBER = 'int-number',
    INT_COMPARE = 'int-compare',
    INT_UNKNOWN = 'int-unknown',
    NEGATIVE_CURRENCY = 'negative-currency',
    NEGATIVE_CURRENCY_CENTS = 'negative-currency-cents',
    CURRENCY = 'currency',
    CURRENCY_INDEXABLE = 'currency-indexable',
    CURRENCY_CENTS = 'currency-cents',
    ATTACHMENT = 'attachment',
    PHOTO = 'photo',
    PHOTO_SMALL = 'photo-small',
    PHOTO_DROP_ZONE = 'photo-drop-zone',
    PHOTO_DROP_ZONE_SINGLE = 'photo-drop-zone-single',
    PHOTO_VIEWER = 'photo-viewer',
    FORCED_SALE_FILTER_RANGES = 'forced-sale-filter-ranges',
    ATTACHMENT_WIDGET = 'attachment-widget',
    ENERGETIC_WIDGET = 'energetic-widget',
    ENERGETIC_OVERVIEW = 'energetic-overview',
}

export type QuestionType = IteratorQuestionType | NormalQuestionType | RootQuestionType | LayoutQuestionType;

export function isMcType(type: QuestionType): boolean {
    return [
        NormalQuestionType.MC,
        NormalQuestionType.MULTIPLE_BOOLEAN_GROUP,
        NormalQuestionType.COLLAPSABLE_CHECKLIST,
        NormalQuestionType.MC_SELECT,
        NormalQuestionType.MC_SELECT_OPTIONAL,
        NormalQuestionType.MC_SELECT_COMPARE,
        NormalQuestionType.MC_SELECT_DYNAMIC,
    ].includes(type as NormalQuestionType);
}
