import {Answer} from '../../models/answer';

export function createAnswerHash(a: Answer) {
    return [
        a.createdAt,
        a.updatedAt,
        a.uuid,
        a.parentUuid,
        a.contents,
        a.iteration,
        a.answerOptionId,
        a.isDeleted,
        a.isVisited,
        a.rank,
    ].join('-');
}
