import {runInAction} from 'mobx';
import {Presenter} from './presenter';

export enum LoadingStateType {
    IDLE = 'idle',
    LOADING = 'loading',
    ERRORED = 'errored',
}

export function errorMessage(error: unknown | {errorMessage: string}) {
    if (
        typeof error === 'object' &&
        error !== null &&
        'errorMessage' in error &&
        typeof (error as {errorMessage: string}).errorMessage === 'string'
    ) {
        return (error as {errorMessage: string}).errorMessage;
    }
    return null;
}

export async function guard<T>(presenter: Presenter & {loadingState: LoadingStateType}, callback: () => Promise<T>) {
    runInAction(() => {
        presenter.loadingState = LoadingStateType.LOADING;
    });
    try {
        const result = await callback();
        runInAction(() => {
            presenter.loadingState = LoadingStateType.IDLE;
        });
        return result;
    } catch (error) {
        console.error(error);
        runInAction(() => {
            presenter.loadingState = LoadingStateType.ERRORED;
        });
        throw error;
    }
}
