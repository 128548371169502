import * as Throttle from 'promise-parallel-throttle';

import {
    AttachmentProps,
    ImageUploadState,
} from '../../appraise/ui/content/questions/advanced/attachment_question_presenter';
import {FailedUppyFile, UploadedUppyFile} from '@uppy/core';
import {FlashMessageBroadcaster, Type} from '../flash_message_broadcaster';

import {AnswerController} from '../answering/answer_controller';
import {AnswerInteractor} from '../answering/answer_interactor';
import {AnswerTouchState} from '../../enum/answer_touch_state';
import {BlobCacheInteractor} from './blob_cache_interactor';
import {UploadInteractor} from './upload_interactor';
import {bugsnagClient} from '../../../support/bugsnag_client';
import {isCorrectFileType} from '../../../support/file_type_check';
import {partition} from '../../../support/partition_array';
import {getFileName} from '../../../support/file_name';

interface MultiUploadCallbacks {
    updateAnswerFilePairsProgress: (iteration: string, progress: number) => void;
    alertNotAvailable: () => void;
    alertIncorrectType: (file: File, answerFilePair: AnswerFilePair) => void;
    handleError: (iteration: string, error: string) => void;
}

interface UploadSucceededResult {
    succeeded: true;
    file: UploadedUppyFile<AttachmentProps, Record<string, unknown>>;
}

interface UploadFailedResult {
    succeeded: false;
    file?: FailedUppyFile<AttachmentProps, Record<string, unknown>>;
    message?: string;
}

export interface AnswerFilePair {
    iteratorAnswerUuid: string;
    answerUuid: string;
    iteration: string;
    file: File;
    progress?: number;
}

export type UploadResult = UploadSucceededResult | UploadFailedResult;

export interface FileUploadInteractor {
    cache(fileUuid: string, file: File): Promise<boolean>;

    attachToAnswer(fileUuid: string, answerUuid: string, file: File): Promise<boolean>;

    uploadForAnswer(
        answerUuid: string,
        file: File,
        options: {
            fileTypes?: string[];
            contents?: AttachmentProps;
            progressCallback?: (progress: number) => void;
            preventSubmittingAnswers?: boolean;
        }
    ): Promise<UploadResult>;

    upload(
        file: File,
        options: {
            fileTypes?: string[];
            progressCallback?: (progress: number) => void;
        }
    ): Promise<UploadResult>;

    uploadIteratedFilesForAnswers(
        answerFilePairs: AnswerFilePair[],
        options: {
            fileTypes?: string[];
        },
        callbacks: MultiUploadCallbacks
    ): void;
}

export class AttachmentUploadInteractor implements FileUploadInteractor {
    constructor(
        private uploadInteractor: UploadInteractor,
        private blobCacheInteractor: BlobCacheInteractor,
        private answerController: AnswerController,
        private answerInteractor: AnswerInteractor,
        private flashMessageBroadcaster: FlashMessageBroadcaster
    ) {}

    public async cache(fileUuid: string, file: File): Promise<boolean> {
        await this.blobCacheInteractor.put(fileUuid, file);
        return true;
    }

    public async attachToAnswer(fileUuid: string, answerUuid: string, file: File): Promise<boolean> {
        const result = await this.blobCacheInteractor.move(fileUuid, answerUuid);
        await this.markUnsynced(answerUuid, file);
        return result;
    }

    public async uploadForAnswer(
        answerUuid: string,
        file: File,
        options: {
            fileTypes?: string[];
            contents?: Partial<AttachmentProps>;
            progressCallback?: (progress: number) => void;
            preventSubmittingAnswers?: boolean;
        }
    ): Promise<UploadResult> {
        try {
            return await this.uploadFileForAnswer(answerUuid, file, options);
        } catch (error) {
            console.error('Error uploading file', error);
            await this.markFailed(answerUuid, file, options.contents);
            bugsnagClient?.notify(error);
            return {
                succeeded: false,
            };
        } finally {
            if (options.preventSubmittingAnswers !== true) {
                await this.answerInteractor.submit();
            }
        }
    }

    public async upload(
        file: File,
        options: {
            fileTypes?: string[];
            progressCallback?: (progress: number) => void;
        }
    ): Promise<UploadResult> {
        try {
            const uploadResult = await this.uploadInteractor.upload(file, options);
            if (uploadResult.successful.length === 1 && uploadResult.successful[0].meta.path !== null) {
                return {
                    succeeded: true,
                    file: uploadResult.successful[0],
                };
            }
            return {
                succeeded: false,
                file: uploadResult.failed[0],
            };
        } catch (error) {
            console.error('Error uploading file', error);
            bugsnagClient?.notify(error);
            return {
                succeeded: false,
            };
        } finally {
            await this.answerInteractor.submit();
        }
    }

    public async uploadIteratedFilesForAnswers(
        answerFilePairs: AnswerFilePair[],
        options: {
            fileTypes?: string[];
            contents?: Partial<AttachmentProps>;
        },
        callbacks: MultiUploadCallbacks
    ) {
        try {
            if (!(await this.blobCacheInteractor.canStore(answerFilePairs[0].file))) {
                callbacks.alertNotAvailable();
                return;
            }

            const {matched, unmatched} = partition(answerFilePairs, (answerFilePair) => {
                return options.fileTypes !== undefined && !isCorrectFileType(answerFilePair.file, options.fileTypes);
            });

            for (const incorrectTypePair of matched) {
                callbacks.alertIncorrectType(incorrectTypePair.file, incorrectTypePair);
            }

            await Throttle.all(
                unmatched.map((answerFilePair) => async () => {
                    const uploadOptions = {
                        ...options,
                        progressCallback: (progress: number) => {
                            callbacks.updateAnswerFilePairsProgress(answerFilePair.iteration, progress);
                        },
                    };
                    const result = await this.uploadFileForAnswer(
                        answerFilePair.answerUuid,
                        answerFilePair.file,
                        uploadOptions
                    );
                    if (!result.succeeded && result.message) {
                        callbacks.handleError(answerFilePair.iteration, result.message);
                    }
                }),
                {
                    maxInProgress: 5,
                    failFast: false,
                }
            );

            await this.answerInteractor.submit();
        } catch (error) {
            console.error('Uploading of files failed', error);
            bugsnagClient?.notify(error);
            this.flashMessageBroadcaster.broadcast(
                'Uploaden van sommige bestanden is gefaald, probeer het nogmaals.',
                Type.Danger
            );
        } finally {
            await this.answerInteractor.submit();
        }
    }

    protected async uploadFileForAnswer(
        answerUuid: string,
        file: File,
        options: {
            contents?: Partial<AttachmentProps>;
            progressCallback?: (progress: number) => void;
        }
    ): Promise<UploadResult> {
        const unsyncedAttachmentProps = await this.markUnsynced(answerUuid, file, options.contents);

        if ((await this.blobCacheInteractor.find(answerUuid)) === null) {
            await this.blobCacheInteractor.put(answerUuid, file);
        }

        const syncingAttachmentProps = await this.markSyncing(answerUuid, file, unsyncedAttachmentProps);

        try {
            const uploadResult = await this.uploadInteractor.upload(file, {
                ...options,
                metaData: {
                    uuid: answerUuid,
                },
            });
            if (uploadResult.successful.length === 1 && uploadResult.successful[0].meta.path !== null) {
                await this.markSynced(answerUuid, file, {
                    ...syncingAttachmentProps,
                    path: uploadResult.successful[0].meta.path,
                });
                await this.blobCacheInteractor.remove(answerUuid);

                return {
                    succeeded: true,
                    file: uploadResult.successful[0],
                };
            }

            await this.markFailed(answerUuid, file, syncingAttachmentProps);

            return {
                succeeded: false,
                file: uploadResult.failed[0],
            };
        } catch (error) {
            if (error instanceof Error) {
                return {
                    succeeded: false,
                    message: error.message,
                };
            }
            return {
                succeeded: false,
            };
        }
    }

    // eslint-disable-next-line require-await
    protected async getUnsyncedAttachmentProps(
        answerUuid: string,
        file: File,
        contents?: Partial<AttachmentProps>
    ): Promise<AttachmentProps> {
        return {
            name: getFileName(file),
            path: null,
            type: file.type,
            ...(contents ?? {}),
            state: ImageUploadState.UNSYNCED,
        };
    }

    // eslint-disable-next-line require-await
    protected async getSyncingAttachmentProps(
        answerUuid: string,
        file: File,
        contents: AttachmentProps
    ): Promise<AttachmentProps> {
        return {
            ...contents,
            state: ImageUploadState.SYNCING,
        };
    }

    // eslint-disable-next-line require-await
    protected async getSyncedAttachmentProps(
        answerUuid: string,
        file: File,
        contents: AttachmentProps
    ): Promise<AttachmentProps> {
        return {
            ...contents,
            state: ImageUploadState.SYNCED,
        };
    }

    // eslint-disable-next-line require-await
    protected async getFailedAttachmentProps(
        answerUuid: string,
        file: File,
        contents?: Partial<AttachmentProps>
    ): Promise<AttachmentProps> {
        return {
            name: getFileName(file),
            path: null,
            type: file.type,
            ...(contents ?? {}),
            state: ImageUploadState.FAILED,
        };
    }

    private async markUnsynced(
        answerUuid: string,
        file: File,
        contents?: Partial<AttachmentProps>
    ): Promise<AttachmentProps> {
        const unsyncedAttachmentProps = await this.getUnsyncedAttachmentProps(answerUuid, file, contents);
        this.answerController.onContentsChange(
            answerUuid,
            JSON.stringify(unsyncedAttachmentProps),
            AnswerTouchState.TOUCHED
        );
        return unsyncedAttachmentProps;
    }

    private async markSyncing(answerUuid: string, file: File, contents: AttachmentProps): Promise<AttachmentProps> {
        const syncingAttachmentProps = await this.getSyncingAttachmentProps(answerUuid, file, contents);
        this.answerController.onContentsChange(
            answerUuid,
            JSON.stringify(syncingAttachmentProps),
            AnswerTouchState.TOUCHED
        );
        return syncingAttachmentProps;
    }

    private async markSynced(answerUuid: string, file: File, contents: AttachmentProps): Promise<AttachmentProps> {
        const syncedAttachmentProps = await this.getSyncedAttachmentProps(answerUuid, file, contents);
        this.answerController.onContentsChange(
            answerUuid,
            JSON.stringify(syncedAttachmentProps),
            AnswerTouchState.TOUCHED
        );
        return syncedAttachmentProps;
    }

    private async markFailed(
        answerUuid: string,
        file: File,
        contents?: Partial<AttachmentProps>
    ): Promise<AttachmentProps> {
        const failedAttachmentProps = await this.getFailedAttachmentProps(answerUuid, file, contents);
        this.answerController.onContentsChange(
            answerUuid,
            JSON.stringify(failedAttachmentProps),
            AnswerTouchState.TOUCHED
        );
        return failedAttachmentProps;
    }
}
