import {SortingDirection, SortingMethod} from '../../../../../../../../../enum/reference_objects_sorting';

import {Appraisal} from '../../../../../../../../../models/appraisal';
import {ReferenceObjectSortingStrategyProvider} from './reference_object_sorting_strategy_provider';
import {SortableObject} from './strategies/reference_object_sorting_strategy';

export class ReferenceObjectSorter {
    constructor(private referenceObjectSortingStrategyProvider: ReferenceObjectSortingStrategyProvider) {}

    public sortReferenceObjects<TUnkown, TSortableObject extends SortableObject>(
        objects: TUnkown[],
        sortableObjectTransformer: (item: TUnkown) => TSortableObject,
        appraisal: Appraisal,
        sortingMethod: SortingMethod,
        sortingDirection: SortingDirection,
        gebruiksoppervlakteWonen: number | null,
        perceelOppervlakte: number | null
    ): TUnkown[] {
        const sorter = this.referenceObjectSortingStrategyProvider.create(
            sortingMethod,
            gebruiksoppervlakteWonen,
            perceelOppervlakte
        );
        return sorter.sortReferenceObjects(objects, sortableObjectTransformer, sortingDirection);
    }
}
