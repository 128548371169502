import * as React from 'react';

import {Appraisal} from '../../../models/appraisal';
import {FC} from 'react';
import {FloorSelector} from './floor_selector/floor_selector';
import {PagePartsSet} from '../../../models/page_parts_set';
import {QuestionSet} from '../../../models/question_set';
import {RenderingContextType} from '../../../enum/rendering_context_type';
import {SidebarPresenter} from './sidebar_presenter';
import {SidebarRootItem} from './sidebar/sidebar_root_item';
import {ValidationError} from '../../../models/validation_error';
import {classNames} from '../../../../support/classnames';
import {observer} from 'mobx-react-lite';
import {usePresenter} from '../../../../support/presenter/use_presenter';
import {StaticButtons} from './static_buttons/static_buttons';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet | null;
    validationErrors: ValidationError[];
    renderingContext: RenderingContextType;
}

export const Sidebar: FC<OwnProps> = observer(
    ({appraisal, questionSet, pagePartsSet, renderingContext, validationErrors}) => {
        const presenter = usePresenter(
            (component) =>
                new SidebarPresenter(component.business.sidebarTreeBuilder(appraisal, questionSet, pagePartsSet))
        );

        return (
            <div
                className={classNames('card-progress d-flex flex-column collapsable', {
                    open: !presenter.collapsed,
                    'page-parts-active': pagePartsSet !== null,
                })}
            >
                {renderingContext === RenderingContextType.PAGE_PARTS_APPRAISAL && (
                    <div className="card-progress-sticky">
                        <StaticButtons
                            appraisal={appraisal}
                            questionSet={questionSet}
                            pagePartsSet={pagePartsSet}
                            validationErrors={validationErrors}
                            renderingContext={renderingContext}
                        />
                        <FloorSelector
                            appraisal={appraisal}
                            questionSet={questionSet}
                            collapsed={presenter.collapsed}
                            renderingContext={renderingContext}
                        />
                    </div>
                )}

                <div className="scrollable">
                    <ul
                        id="sidebar"
                        data-tooltip="sidebar"
                        data-tooltip-title="De routebalk"
                        data-tooltip-description="De routebalk links geeft de navigatie van de taxatie weer. Dit zijn de verschillende stappen van de taxatie."
                        data-tooltip-position="right-center"
                    >
                        {presenter.sidebarItems.map((sidebarMenuItem) => (
                            <SidebarRootItem
                                key={sidebarMenuItem.link}
                                sidebarItem={sidebarMenuItem}
                                pagePartsSet={pagePartsSet}
                                appraisal={appraisal}
                                questionSet={questionSet}
                                validationErrors={validationErrors}
                                renderingContext={renderingContext}
                            />
                        ))}
                    </ul>
                </div>
                <button title="Toggle sidebar" onClick={() => presenter.toggle()} className="btn-text-link toggle-bar">
                    &nbsp;
                </button>
            </div>
        );
    }
);
