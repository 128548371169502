import React from 'react';
import {usePresenter} from '../../../../support/presenter/use_presenter';
import {Appraisal} from '../../../models/appraisal';
import {QuestionSet} from '../../../models/question_set';
import {ImportExportPresenter} from './import_export_presenter';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export function Import(props: OwnProps) {
    const presenter = usePresenter(
        (component) =>
            new ImportExportPresenter(
                props.appraisal,
                component.business.answerController(props.appraisal, props.questionSet),
                component.business.flashMessageBroadcaster,
                component.business.networkStatusProvider
            )
    );

    return (
        <div className="row">
            <div className="col-12">
                <h2>Upload ondersteuningsinformatie</h2>
                <p>
                    Upload een data-bestand met ontwikkelaarsinformatie voor het oplossen van problemen binnen deze
                    taxatie.
                </p>
                <input
                    id="debug-file"
                    type="file"
                    onChange={(ev) => {
                        if (ev.target.files?.[0]) {
                            presenter.import(ev.target.files[0]);
                        }
                    }}
                />
            </div>
        </div>
    );
}
