import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from '../../../../../simple/simple_question_presenter';

import {Label} from '../../../../../components/label';
import {MultipleChoiceSelectCompareQuestionPresenter} from './multiple_choice_select_compare_question_presenter';
import {ObjectType} from '../../../../../../../../../enum/object_type';
import {ObjectTypeBadge} from '../../../../../../../../../components/badges/object_type_badge';
import {Select} from '../../../../../../../components/select';
import {
    SpecialCompareSelectorType,
    StringCompareSelectorType,
} from '../../../../../../../../../enum/compare_selector_type';
import {ValidationMessages} from '../../../../../components/validation_messages';
import {classNames} from '../../../../../../../../../../support/classnames';
import {filterValidationMessages} from '../../../../../../../support/filter_validation_messages';
import {isEmpty} from '../../../../../../../../../../support/util';
import {observer} from 'mobx-react';
import {CompactEditable} from '../../../../../../../components/compact_editable';
import {ComparabilityBadge} from '../../../../../../../../../components/badges/comparability_option_badge';
import {EnergyLabelBadge} from '../../../../../../../../../components/badges/energy_label_badge';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const MultipleChoiceSelectCompareQuestion: React.FC<OwnProps> = observer(
    function MultipleChoiceSelectCompareQuestion(props) {
        const presenter = usePresenter(
            (container) =>
                new MultipleChoiceSelectCompareQuestionPresenter(
                    container.business.compareValuesProvider(props.appraisal, props.questionSet),
                    ...simpleQuestionPresenterConstructorParametersFactory(props, container)
                )
        );
        if (presenter.isHidden) {
            return null;
        }

        const hasDefaultValue = presenter.answerOptions.find((o) => o.isDefault) !== undefined;

        function renderInput() {
            const answer = presenter.answer;
            const value = answer && answer.answerOptionId !== null ? '' + answer.answerOptionId : null;

            const options = presenter.answerOptions.map((answerOption) => {
                const option = {
                    value: String(answerOption.id),
                    label: answerOption.contents,
                };
                if (answerOption.isRequired) {
                    option.label += '*';
                }
                if (answerOption.isUnique) {
                    option.label += ' (max. 1)';
                }
                return option;
            });
            if (isEmpty(value)) {
                options.unshift({
                    value: '-1',
                    label: '- maak een keuze -',
                });
            }

            return (
                <Select
                    id={presenter.name}
                    className={classNames({
                        'required-not-selected': isEmpty(value),
                    })}
                    value={value || '-1'}
                    isDisabled={presenter.isDisabled || props.disabled === true}
                    onChange={(newValue) => {
                        const parsedValue = newValue ? parseInt(newValue, 10) : null;
                        if (parsedValue !== -1) {
                            presenter.onAnswerOptionChange(parsedValue);
                        }
                    }}
                    options={options}
                    isClearable={!props.question.isRequired && hasDefaultValue}
                />
            );
        }

        function renderBadge(compact = false) {
            if (!isEmpty(presenter.compareValue)) {
                switch (props.question.compareSelector) {
                    case StringCompareSelectorType.ENERGY_LABEL:
                        return (
                            <EnergyLabelBadge
                                compact={compact}
                                energyLabel={presenter.currentValue}
                                referenceEnergyLabel={presenter.compareValue}
                            />
                        );
                    case StringCompareSelectorType.OBJECT_TYPE:
                        return (
                            <ObjectTypeBadge
                                objectType={presenter.currentValue as ObjectType | null}
                                referenceObjectType={presenter.compareValue as ObjectType | null}
                                compact={compact}
                            />
                        );
                    case SpecialCompareSelectorType.COMPARABILITY_OPTIONS:
                        return compact ? <ComparabilityBadge option={presenter.currentValue} /> : null;
                    default:
                        return null;
                }
            }
            return null;
        }

        function renderInputWithBadge() {
            const badge = renderBadge();
            return (
                <span className="row">
                    <span className={badge === null ? 'col-12' : 'col-8'}>{renderInput()}</span>
                    <span className={badge === null ? 'hidden' : 'col-4'}>
                        <span className="form-badge">{badge}</span>
                    </span>
                </span>
            );
        }

        function renderCompact() {
            const badge = renderBadge(true);

            const answer = presenter.answer;
            const value =
                answer && answer.answerOptionId !== null
                    ? presenter.answerOptions.find((ao) => ao.id === answer.answerOptionId)?.contents
                    : null;

            return (
                <>
                    <Label
                        for={presenter.name}
                        question={props.question}
                        parentAnswerUuid={props.parentAnswerUuid}
                        iteration={props.iteration}
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        disabled={props.disabled || presenter.isDisabled}
                        disableHistory
                        hideLabel={props.hideLabel}
                        showLabel={props.question.showLabel}
                        renderingContext={props.renderingContext}
                    />
                    <span className="row">
                        <span className={badge === null ? 'col-12' : 'col-8'}>{value ?? <i>Niet ingevuld</i>}</span>
                        <span className={badge === null ? 'hidden' : 'col-4'}>
                            <span className="form-badge">{badge}</span>
                        </span>
                    </span>
                </>
            );
        }

        return (
            <div
                className={classNames('form-group', presenter.filledByAutomator, {
                    'automation-filled': presenter.filledByAutomator !== null,
                })}
            >
                <CompactEditable
                    renderingContext={props.renderingContext}
                    compact={() => renderCompact()}
                    expanded={() => (
                        <>
                            <Label
                                for={presenter.name}
                                question={props.question}
                                parentAnswerUuid={props.parentAnswerUuid}
                                iteration={props.iteration}
                                appraisal={props.appraisal}
                                questionSet={props.questionSet}
                                disabled={props.disabled || presenter.isDisabled}
                                hideLabel={props.hideLabel}
                                showLabel={props.question.showLabel}
                                renderingContext={props.renderingContext}
                            />
                            {renderInput()}
                        </>
                    )}
                    question={props.question}
                    answer={presenter.answer}
                >
                    <Label
                        for={presenter.name}
                        question={props.question}
                        parentAnswerUuid={props.parentAnswerUuid}
                        iteration={props.iteration}
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        disabled={props.disabled || presenter.isDisabled}
                        hideLabel={props.hideLabel}
                        showLabel={props.question.showLabel}
                        renderingContext={props.renderingContext}
                    />
                    {renderInputWithBadge()}
                </CompactEditable>

                <ValidationMessages
                    disabled={props.disabled || presenter.isDisabled}
                    alignLeft
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    answer={presenter.answer}
                    validationMessages={filterValidationMessages(
                        props.question,
                        presenter.answer,
                        props.validationMessages,
                        presenter.validationMessages
                    )}
                />
            </div>
        );
    }
);
