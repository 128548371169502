import {makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../../../../../support/composite_subscription';
import {Presenter} from '../../../../../../../support/presenter/presenter';
import {PagePartsSet} from '../../../../../../models/page_parts_set';
import {Question} from '../../../../../../models/question';

export type PhotoIteratorCustomizationType = {
    order: string[];
};

export const DEFAULT_SORT_ORDER = [
    'vooraanzicht',
    'achtergevel',
    'straatbeeld',
    'tuin',
    'woonkamer',
    'keuken',
    'badkamer',
    'slaapkamer',
    'overige',
    'verbouwing',
];

export class PhotoIteratorCustomizerPresenter implements Presenter {
    @observable public sortOrder: string[] | null = null;

    private subscriptions = new CompositeSubscription();

    constructor(private question: Question, private pagePartsSet: PagePartsSet) {
        makeObservable(this);
    }

    public async mount(): Promise<void> {
        this.subscriptions.add(
            this.pagePartsSet.customizations
                .streamByQuestionUuid<PhotoIteratorCustomizationType>(this.question.uuid)
                .subscribe((customization) => {
                    runInAction(() => {
                        this.sortOrder = customization?.order ?? DEFAULT_SORT_ORDER;
                    });
                })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public move(label: string, index: number) {
        if (!this.sortOrder) {
            return;
        }
        const order = [...this.sortOrder];

        const oldIndex = order.indexOf(label);
        if (oldIndex === -1) {
            return;
        }

        order.splice(oldIndex, 1);
        order.splice(index, 0, label);

        this.pagePartsSet.customizations.setForQuestionUuid<PhotoIteratorCustomizationType>(this.question.uuid, {
            order,
        });
    }

    public getDisplayLabel(label: string): string {
        return label.charAt(0).toUpperCase() + label.slice(1);
    }
}
