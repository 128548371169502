import $ from 'jquery';

/**
 * Appraisal create address page
 */
(() => {
    const clientCountry = $('#client_country');
    const secondaryClientCountry = $('#secondary_client_country');
    const clientPostalCode = $('#client_postal_code');
    const secondaryClientPostalCode = $('#secondary_client_postal_code');

    if (clientCountry.val() !== 'Netherlands') {
        clientPostalCode.removeAttr('pattern');
    }
    if (secondaryClientCountry.val() !== 'Netherlands') {
        secondaryClientPostalCode.removeAttr('pattern');
    }
    if (clientCountry.length > 0) {
        clientCountry.on('change', function () {
            if (clientCountry.val() === 'Netherlands') {
                clientPostalCode.attr('pattern', '[0-9]{4,4}[a-zA-Z]{2,2}');
            } else {
                clientPostalCode.removeAttr('pattern');
            }
        });
    }
    if (secondaryClientCountry.length > 0) {
        secondaryClientCountry.on('change', function () {
            if (secondaryClientCountry.val() === 'Netherlands') {
                secondaryClientPostalCode.attr('pattern', '[0-9]{4,4}[a-zA-Z]{2,2}');
            } else {
                secondaryClientPostalCode.removeAttr('pattern');
            }
        });
    }
})();
