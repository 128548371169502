import {Presenter} from '../../../../support/presenter/presenter';
import {observable, makeObservable} from 'mobx';
import {AppraisalApi} from '../../../network/appraisal_api';
import {Appraisal} from '../../../models/appraisal';

export class NotesPresenter implements Presenter {
    @observable public modalVisible = false;
    @observable public notes: string;
    @observable public saving = false;

    private _appraisal: Appraisal;
    private _appraisalApi: AppraisalApi;
    private lastSavedNotes: string;
    private _timeout: number | null = null;

    constructor(appraisal: Appraisal, appraisalApi: AppraisalApi) {
        makeObservable(this);
        this._appraisal = appraisal;
        this.notes = appraisal.notes;
        this.lastSavedNotes = appraisal.notes;
        this._appraisalApi = appraisalApi;
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public onClick() {
        this.modalVisible = true;
    }

    public async onSaveClick() {
        if (this.notes === this.lastSavedNotes) {
            this.modalVisible = false;
            return;
        }
        try {
            this.saving = true;

            await this._appraisalApi.updateNotes(this._appraisal.id, this.notes);

            this.modalVisible = false;
        } catch (e) {
            this.modalVisible = true;
        } finally {
            this.saving = false;
        }
    }

    public async onModalClose() {
        this.modalVisible = false;
        await this._appraisalApi.updateNotes(this._appraisal.id, this.notes);
    }

    public onChange(value: string) {
        this.notes = value;

        if (this._timeout) {
            clearTimeout(this._timeout);
        }
        this._timeout = window.setTimeout(async () => {
            if (this.notes === this.lastSavedNotes) {
                return;
            }
            const notes = this.notes;
            await this._appraisalApi.updateNotes(this._appraisal.id, this.notes);
            this.lastSavedNotes = notes;
        }, 500);
    }
}
