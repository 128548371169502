import {NetworkStatus, NetworkStatusProvider} from '../../../business/network_status_provider';

import {CompositeSubscription} from '../../../../support/composite_subscription';
import {Global} from '../../../../business/global_provider';
import {Presenter} from '../../../../support/presenter/presenter';
import {runInAction} from 'mobx';

export class EndButtonPresenter implements Presenter {
    private networkStatus: NetworkStatus | null = null;
    private subscriptions = new CompositeSubscription();

    constructor(private global: Global, private networkStatusProvider: NetworkStatusProvider) {}

    public mount(): void {
        this.subscriptions.add(
            this.networkStatusProvider.status().subscribe((networkStatus) => {
                runInAction(() => {
                    this.networkStatus = networkStatus;
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public onClick() {
        if (
            this.networkStatus === null ||
            this.networkStatus === NetworkStatus.ONLINE ||
            confirm('Je bent offline, weet je zeker dat je verder wilt?')
        ) {
            window.location.href = this.global.backUrl;
        }
    }
}
