import * as React from 'react';
import {Answer} from '../../../../../models/answer';

interface OwnProps {
    currentAnswer?: Answer;
    adaptedDefaultValue?: string;
    onChange: (value: string) => void;
    disableAdaptedValueContainer?: boolean;
}

export class AlternativeAdaptedDefaultAnswer extends React.Component<OwnProps> {
    public render() {
        const {currentAnswer, adaptedDefaultValue, onChange, disableAdaptedValueContainer} = this.props;

        const currentValue = currentAnswer !== undefined ? currentAnswer.contents : null;

        if (
            adaptedDefaultValue === undefined ||
            currentValue === adaptedDefaultValue ||
            disableAdaptedValueContainer === true
        ) {
            return null;
        }

        return (
            <div className="macros-container">
                <strong>Oud antwoord</strong>

                <ul className="macros">
                    <li>
                        <a onClick={() => onChange(adaptedDefaultValue)} title={adaptedDefaultValue}>
                            {adaptedDefaultValue}
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}
