import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from '../../../../../simple/simple_question_presenter';

import {DefaultExplanationDataProvider} from '../../internal/explanation_appraiser_generator/explanation_data_provider';
import {ExplanationAppraiserGenerator} from '../../internal/explanation_appraiser_generator/explanation_appraiser_generator';
import {ExplanationBuildYearGenerator} from '../../internal/explanation_appraiser_generator/explanation_build_year_generator';
import {ExplanationBuildingTypeGenerator} from '../../internal/explanation_appraiser_generator/explanation_building_type_generator';
import {ExplanationBuildingsGenerator} from '../../internal/explanation_appraiser_generator/explanation_buildings_generator';
import {ExplanationFloorAreaGenerator} from '../../internal/explanation_appraiser_generator/explanation_floor_area_generator';
import {ExplanationPlotAreaGenerator} from '../../internal/explanation_appraiser_generator/explanation_plot_area_generator';
import {Label} from '../../../../../components/label';
import {Macros} from '../../../../macros/macros';
import {ReferenceObjectExplanationPresenter} from './reference_object_explanation_presenter';
import {ValidationMessages} from '../../../../../components/validation_messages';
import {classNames} from '../../../../../../../../../../support/classnames';
import {filterValidationMessages} from '../../../../../../../support/filter_validation_messages';
import {macroIsActive} from '../../../../../../../../../business/toggle_macro';
import {observer} from 'mobx-react';
import {CompactEditable} from '../../../../../../../components/compact_editable';
import {Modal} from '../../../../../components/modal';
import {ExplanationEnergyLabelGenerator} from '../../internal/explanation_appraiser_generator/explanation_energy_label_generator';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const ReferenceObjectExplanation: React.FC<OwnProps> = observer(function ReferenceObjectExplanation(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectExplanationPresenter(
                container.business.v3ReferenceSetsProvider(props.appraisal, props.questionSet),
                new ExplanationAppraiserGenerator(
                    new DefaultExplanationDataProvider(
                        props.appraisal,
                        container.business.buildYearProvider(props.appraisal, props.questionSet),
                        container.business.valuationProvider(props.appraisal, props.questionSet)
                    ),
                    new ExplanationBuildYearGenerator(),
                    new ExplanationFloorAreaGenerator(),
                    new ExplanationPlotAreaGenerator(),
                    new ExplanationBuildingsGenerator(),
                    new ExplanationBuildingTypeGenerator(),
                    new ExplanationEnergyLabelGenerator()
                ),
                container.business.textAIReferenceObjectContextBuilder(props.appraisal, props.questionSet),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );
    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

    const updateTextareaHeight = React.useCallback(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = '5px';
            textareaRef.current.style.height = textareaRef.current.scrollHeight + 5 + 'px';
        }
    }, [textareaRef]);
    React.useEffect(() => {
        updateTextareaHeight();
    }, [presenter.answer?.contents]);

    function renderCompact() {
        const {referenceObjectDataWithSet} = presenter;

        return (
            <>
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    disableHistory
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                {referenceObjectDataWithSet && (
                    <button
                        className="btn btn-xs reference-object-explanation-generate-button ion-md-refresh"
                        onClick={() => {
                            if (
                                (presenter.answer?.contents ?? '').trim() === '' ||
                                confirm('Weet je dit zeker? De huidige tekst zal overschreven worden.')
                            ) {
                                presenter.onGenerateClick(referenceObjectDataWithSet);
                            }
                        }}
                    />
                )}
                <div>{presenter.answer?.contents ?? <i>Niet ingevuld</i>}</div>
            </>
        );
    }

    function renderExpanded(close: () => void) {
        return (
            <>
                {renderCompact()}
                <Modal visible={true} onHide={close} onShow={() => updateTextareaHeight()}>
                    <div className="modal-header">
                        <h2>{props.question.contents}</h2>
                    </div>
                    <div className="modal-body">
                        <div className="form-group has-macros">{renderInput()}</div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary" onClick={close}>
                            Sluiten
                        </button>
                    </div>
                </Modal>
            </>
        );
    }

    function renderInput() {
        const {referenceObjectDataWithSet} = presenter;
        return (
            <div className="input-group">
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />

                {!presenter.isDisabled && !props.disabled && presenter.macros !== undefined ? (
                    <Macros
                        questionUuid={props.question.uuid}
                        parentAnswerUuid={props.parentAnswerUuid ?? null}
                        hasAdvancedOptions={false}
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        isActive={(contents) =>
                            presenter.answer ? macroIsActive(contents, presenter.answer.contents) : false
                        }
                        onAdd={presenter.onAddAsMacroClick}
                        onUseMacro={presenter.onMacroClick}
                        onRemove={presenter.onRemoveMacroClick}
                        onFavorite={presenter.onFavoriteMacroClick}
                        showAddButton={presenter.showAddMacroButton}
                        useAI={[presenter.textAIReferenceObjectContextBuilder]}
                    />
                ) : null}

                {referenceObjectDataWithSet !== null && (
                    <button
                        className="btn btn-textarea-inset ion-md-refresh"
                        onClick={() => {
                            if (
                                (presenter.answer?.contents ?? '').trim() === '' ||
                                confirm('Weet je dit zeker? De huidige tekst zal overschreven worden.')
                            ) {
                                presenter.onGenerateClick(referenceObjectDataWithSet);
                            }
                        }}
                    >
                        Genereer
                    </button>
                )}
                <textarea
                    id={presenter.name}
                    name={presenter.name}
                    className={classNames('form-control no-min-height', {'has-inset': presenter.showAddMacroButton})}
                    ref={textareaRef}
                    readOnly={presenter.isDisabled || props.disabled}
                    value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                    onChange={(e) => presenter.onChange(e.target.value, false)}
                    onBlur={() => presenter.onTouched()}
                    rows={1}
                />
            </div>
        );
    }

    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'has-macros': presenter.macros !== undefined && presenter.macros.length !== 0,
                'automation-filled': presenter.filledByAutomator !== null,
            })}
        >
            <CompactEditable
                renderingContext={props.renderingContext}
                expanded={(close) => renderExpanded(close)}
                compact={() => renderCompact()}
                captureClick={false}
                question={props.question}
            >
                {renderInput()}
            </CompactEditable>
            <ValidationMessages
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={presenter.answer}
                validationMessages={filterValidationMessages(
                    props.question,
                    presenter.answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
        </div>
    );
});
