import {ModalState, ModalType} from './models/modal_state';
import {NetworkStatus, NetworkStatusProvider} from '../../../../../../../business/network_status_provider';
import {SortingDirection, SortingMethod} from '../../../../../../../enum/reference_objects_sorting';
import {
    SuggestedReferenceObjectAnswer,
    SuggestedReferenceObjectAnswersCalculator,
} from './calculators/suggested_reference_object_answers_calculator';
import {TreeItemWithoutParents, removeParentsFromTree} from '../../../../../../../../support/generic_tree';
import {V2ReferenceSet, V2ReferenceSetsProvider} from './internal/reference_sets/reference_sets_provider';
import {action, autorun, computed, makeObservable, observable, runInAction} from 'mobx';

import {Answer} from '../../../../../../../models/answer';
import {AnswerController} from '../../../../../../../business/answering/answer_controller';
import {AnswerInteractor} from '../../../../../../../business/answering/answer_interactor';
import {AnswerTouchState} from '../../../../../../../enum/answer_touch_state';
import {Appraisal} from '../../../../../../../models/appraisal';
import {AppraisalProvider} from '../../../../../../../business/appraisal_provider';
import {AppraisalState} from '../../../../../../../enum/appraisal_state';
import {AppraisalValidationType} from '../../../../../../../enum/appraisal_validation_type';
import {AppraiseSecondaryConfigStackInteractor} from '../../../../../../../business/appraise_secondary_config_stack_interactor';
import {AppraiseSecondaryType} from '../../../../../../../models/appraise_secondary_config';
import {CompositeSubscription} from '../../../../../../../../support/composite_subscription';
import {Presenter} from '../../../../../../../../support/presenter/presenter';
import {Question} from '../../../../../../../models/question';
import {QuestionAnswerPair} from '../../../../../../../../support/question_answer_tree';
import {QuestionSet} from '../../../../../../../models/question_set';
import {ReferenceObject} from './models/reference_object';
import {ReferenceObjectAnswer} from './models/reference_object_answer';
import {ReferenceObjectSorter} from './internal/reference_object_sort/reference_object_sorter';
import {ReferenceObjectSortingStrategyProvider} from './internal/reference_object_sort/reference_object_sorting_strategy_provider';
import {ReferenceObjectsInteractor} from '../interactors/reference_objects_interactor';
import {ReferenceSaleSetData} from '../../../../../../../business/reference_object_provider';
import {SetType} from '../../../../../../../models/reference_set/set_type';
import {getFromReferenceObjectAnswer} from './internal/normalized_sale_date';
import {isEmpty} from '../../../../../../../../support/util';
import {isSet} from '../../../../../../../../support/is_set';

type ParentLessSetDefinitionWithoutCount = V2ReferenceSet<TreeItemWithoutParents<QuestionAnswerPair>>;

export interface ParentlessSetDefinition extends ParentLessSetDefinitionWithoutCount {
    amountSelected: number;
}

export enum ActiveSetDefinitionState {
    EMPTY = 'empty',
    DISABLED = 'disabled',
    ACTIVE = 'active',
}

interface EmptyActiveSetDefinition {
    state: ActiveSetDefinitionState.EMPTY;
}

interface SetDefinitionWithoutAnswer<T extends ParentLessSetDefinitionWithoutCount> {
    state: ActiveSetDefinitionState.DISABLED | ActiveSetDefinitionState.ACTIVE;
    setDefinition: T;
    referenceObjectSetData: ReferenceSaleSetData | null;
    missingPreconditions: string[];
}

export interface ActiveSetDefinition extends SetDefinitionWithoutAnswer<ParentlessSetDefinition> {
    referenceObjectAnswers: ReferenceObjectAnswer[];
}

export interface VisibleReferenceObject {
    referenceObject: ReferenceObject;
    referenceObjectAnswer: ReferenceObjectAnswer | null;
}

export interface PreselectedReferenceObject {
    id: string;
    source: string;
}

const secondaryAppraisalId = 'object-references-v2-map';

export class ReferenceObjectsQuestionPresenter implements Presenter {
    public minReferenceObjects = 3;
    @observable public numVisible = 12;
    @observable.ref public answer: Answer | null = null;
    @observable public sortingMethod = SortingMethod.ALPHABETICALLY;
    @observable public sortingDirection = SortingDirection.ASCENDING;
    @observable public isComparing = false;
    @observable public networkStatus: NetworkStatus = NetworkStatus.ONLINE;
    @observable public modalState: ModalState = {
        type: ModalType.NONE,
    };
    @observable public isSavingAnswer = false;
    @observable.ref public hoveringReferenceObject: ReferenceObject | null = null;
    @observable.ref public clickedReferenceObject: ReferenceObject | null = null;
    @observable private _referenceSets: ParentLessSetDefinitionWithoutCount[] | null = null;

    private timeout: number | null = null;

    @computed
    public get referenceSets(): ParentlessSetDefinition[] | null {
        if (this._referenceSets === null) {
            return null;
        }

        return this._referenceSets.map((set) => {
            return {
                ...set,
                amountSelected: this.answer
                    ? this.referenceObjectAnswersForValuationType(this.answer, set.valuationType).length
                    : 0,
            };
        });
    }

    @computed
    public get suggestedReferenceObjectAnswer(): SuggestedReferenceObjectAnswer[] {
        if (this.answer === null || this.referenceSets === null || this.activeSetDefinition === null) {
            return [];
        }

        return this.suggestedReferenceObjectAnswersCalculator.get(
            this.referenceObjectAnswers(this.answer),
            this.referenceSets,
            this.activeSetDefinition?.setDefinition.valuationType
        );
    }

    @computed
    public get canAdd(): boolean {
        const activeSet = this.activeSetDefinition;
        if (!activeSet || activeSet.state === ActiveSetDefinitionState.DISABLED) {
            return false;
        }

        return (
            activeSet.referenceObjectAnswers.length < this.questionSet.reportDefintionConfig.maxReferenceObjectsInSet
        );
    }

    @computed
    public get isFrozen(): boolean {
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return this.appraisal.status !== AppraisalState.PROCESSING && this.question.freezes;
        }

        return (
            this.appraisalProvider.appraisal.status === AppraisalState.APPROVED ||
            this.appraisalProvider.appraisal.status === AppraisalState.CANCELED ||
            this.appraisalProvider.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    private referenceObjectAnswers(answer: Answer): ReferenceObjectAnswer[] {
        if (answer === null || answer.contents === null) {
            return [];
        }

        const answers = JSON.parse(answer.contents) as ReferenceObjectAnswer[];
        if (Array.isArray(answers)) {
            return answers.filter((a) => isSet(a));
        }

        return [];
    }

    private referenceObjectAnswersForValuationType(answer: Answer, valuationType: string) {
        const referenceObjectAnswers = this.referenceObjectAnswers(answer);
        return referenceObjectAnswers.filter((a) => a.valuationType === valuationType) ?? [];
    }

    @observable private _activeSetDefinition:
        | SetDefinitionWithoutAnswer<ParentLessSetDefinitionWithoutCount>
        | EmptyActiveSetDefinition = {
        state: ActiveSetDefinitionState.EMPTY,
    };
    @computed
    public get activeSetDefinition(): ActiveSetDefinition | null {
        if (this._activeSetDefinition.state === ActiveSetDefinitionState.EMPTY || this.answer === null) {
            return null;
        }

        return {
            ...this._activeSetDefinition,
            setDefinition: {
                ...this._activeSetDefinition.setDefinition,
                amountSelected: this.answer
                    ? this.referenceObjectAnswersForValuationType(
                          this.answer,
                          this._activeSetDefinition.setDefinition.valuationType
                      ).length
                    : 0,
            },
            referenceObjectAnswers: this.referenceObjectAnswersForValuationType(
                this.answer,
                this._activeSetDefinition.setDefinition.valuationType
            ),
        };
    }

    private referenceObjectSorter = new ReferenceObjectSorter(new ReferenceObjectSortingStrategyProvider());

    private sortReferenceObjects(activeSetDefinition: ActiveSetDefinition): ReferenceObject[] {
        //Extract all the possible reference objects
        const referenceObjects = [
            ...activeSetDefinition.referenceObjectAnswers.map((roa) => {
                const saleSetReferenceSale =
                    activeSetDefinition.referenceObjectSetData?.referenceSales.find((rs) => rs.id === roa.id) || null;
                return this.referenceObjectFromReferenceObjectAnswer(roa, saleSetReferenceSale);
            }),
            ...(activeSetDefinition.referenceObjectSetData?.referenceSales.filter((rs) =>
                this.filterAnswers(activeSetDefinition, rs)
            ) ?? []),
        ];

        // first sort the referenceObject based on the selected method
        const sortedReferenceSales = this.referenceObjectSorter.sortReferenceObjects(
            referenceObjects,
            this.appraisal,
            this.questionSet,
            this.sortingMethod,
            this.sortingDirection,
            activeSetDefinition.setDefinition.surfaceArea,
            activeSetDefinition.setDefinition.plotArea
        );

        //Get all references which are not selected
        const nonSelectedReferences = sortedReferenceSales.filter(
            (rs) => !activeSetDefinition.referenceObjectAnswers.some((roa) => roa.id === rs.id)
        );

        //Create a list of the selected references
        const selectedReferences = activeSetDefinition.referenceObjectAnswers
            .map((answer) => sortedReferenceSales.find((reference) => reference.id === answer.id))
            .filter((r): r is ReferenceObject => r !== undefined);

        //Combine 2 lists with selected references first
        return [...selectedReferences, ...nonSelectedReferences];
    }

    private referenceObjectFromReferenceObjectAnswer(
        referenceObjectAnswer: ReferenceObjectAnswer,
        referenceSale: ReferenceObject | null
    ): ReferenceObject {
        return {
            // Shared data with Sale class
            id: referenceObjectAnswer.id,
            source: referenceSale?.source || null,
            postalCode: referenceObjectAnswer.referenceObject.adres.postcode,
            letter: referenceObjectAnswer.referenceObject.adres.huisnummerToevoeging,
            street: referenceObjectAnswer.referenceObject.adres.straat,
            houseNumber: referenceObjectAnswer.referenceObject.adres.huisnummer,
            city: referenceObjectAnswer.referenceObject.adres.plaats,
            priceRange:
                referenceObjectAnswer.referenceObject.verkoopprijs === null
                    ? null
                    : '' + referenceObjectAnswer.referenceObject.verkoopprijs,
            indexedPriceRange:
                referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs === null
                    ? null
                    : '' + referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs,
            saleQuarter: referenceObjectAnswer.referenceObject.verkoopdatum,
            normalizedSaleDate: getFromReferenceObjectAnswer(referenceObjectAnswer),
            transportDate: referenceObjectAnswer.referenceObject.transportdatum ?? referenceSale?.transportDate ?? null,
            objectType: referenceObjectAnswer.referenceObject.woningType ?? referenceSale?.objectType,
            plotArea: referenceObjectAnswer.referenceObject.perceelOppervlakte,
            floorArea: referenceObjectAnswer.referenceObject.gebruiksOppervlakte,
            buildYear: referenceObjectAnswer.referenceObject.bouwjaar,
            volume: referenceObjectAnswer.referenceObject.inhoud,
            latitude: referenceObjectAnswer.referenceObject.adres.latitude
                ? referenceObjectAnswer.referenceObject.adres.latitude
                : 0,
            longitude: referenceObjectAnswer.referenceObject.adres.longitude
                ? referenceObjectAnswer.referenceObject.adres.longitude
                : 0,
            energyLabel: referenceObjectAnswer.referenceObject.energielabel ?? referenceSale?.energyLabel,
            energyLabelIsPreliminary:
                referenceObjectAnswer.referenceObject.energielabelVoorlopig ??
                referenceSale?.energyLabelIsPreliminary ??
                false,
            energyLabelValidDate:
                referenceObjectAnswer.referenceObject.energielabelGeldigTot ??
                referenceSale?.energyLabelValidDate ??
                null,
            daysOpenForSale: referenceSale?.daysOpenForSale ?? null,
            hasWarning: referenceSale?.hasWarning ?? false,
            warning: referenceSale?.warning ?? null,
            ownership: referenceSale?.ownership ?? null,
            brochureUrl: referenceSale?.brochureUrl ?? null,
            frontview: referenceSale?.frontview ?? null,
            images: referenceSale?.images ?? [],
            rooms: referenceSale?.rooms ?? null,

            // Specific data
            highlights: referenceSale?.highlights ?? [],
            overview: referenceSale?.overview ?? {},
            installationDates: referenceSale?.installationDates ?? null,
            matchingPercentage: referenceSale?.matchingPercentage ?? null,
        };
    }
    private filterAnswers(activeSetDefinition: ActiveSetDefinition, referenceObject: ReferenceObject): boolean {
        return !activeSetDefinition.referenceObjectAnswers.some(
            (referenceObjectAnswer) => referenceObjectAnswer.id === referenceObject.id
        );
    }

    @computed
    public get visibleReferenceObjects(): VisibleReferenceObject[] {
        const activeSetDefinition = this.activeSetDefinition;
        if (activeSetDefinition === null) {
            return [];
        }

        return this.sortReferenceObjects(activeSetDefinition)
            .slice(0, this.numVisible)
            .map((referenceObject) => {
                const referenceObjectAnswer =
                    activeSetDefinition.referenceObjectAnswers.find((answer) => answer.id === referenceObject.id) ||
                    null;

                const saleSetReferenceSale =
                    activeSetDefinition.referenceObjectSetData?.referenceSales.find(
                        (rs) => rs.id === referenceObject.id
                    ) || referenceObject;

                return {
                    referenceObject: saleSetReferenceSale,
                    referenceObjectAnswer,
                };
            });
    }

    private subscriptions = new CompositeSubscription();

    constructor(
        private appraisal: Appraisal,
        private question: Question,
        private questionSet: QuestionSet,
        private appraisalProvider: AppraisalProvider,
        private parentAnswerUuid: string | undefined,
        private iteration: string | undefined,
        private answerController: AnswerController,
        private referenceSetsProvider: V2ReferenceSetsProvider,
        private referenceObjectsInteractor: ReferenceObjectsInteractor,
        private suggestedReferenceObjectAnswersCalculator: SuggestedReferenceObjectAnswersCalculator,
        private appraiseSecondaryConfigStackInteractor: AppraiseSecondaryConfigStackInteractor,
        private networkStatusProvider: NetworkStatusProvider,
        private answerInteractor: AnswerInteractor
    ) {
        makeObservable(this);
    }

    public async mount() {
        this.subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid ?? null, this.iteration ?? null)
                .subscribe((answer) => {
                    runInAction(() => {
                        this.answer = answer;
                    });
                })
        );

        this.subscriptions.add(
            this.networkStatusProvider.status().subscribe((networkStatus) => {
                runInAction(() => {
                    this.networkStatus = networkStatus;
                });
            })
        );

        this.subscriptions.add(
            this.referenceSetsProvider.referenceSets().subscribe((sets) => {
                if (sets) {
                    const referenceSets = sets.map((set) => {
                        //Remove the parents in the tree since mobx can't handle circular references
                        return {
                            ...set,
                            groupTree: removeParentsFromTree(set.groupTree),
                        };
                    });

                    if (
                        this._activeSetDefinition.state === ActiveSetDefinitionState.EMPTY &&
                        referenceSets.length > 0
                    ) {
                        this.setActiveSetDefinition(referenceSets[0]);
                    }

                    const updatedReferenceSet = referenceSets.find(
                        (rs) => rs.valuationType === this.activeSetDefinition?.setDefinition.valuationType
                    );
                    if (updatedReferenceSet) {
                        this.debouncedSetActiveSetDefinition(updatedReferenceSet);
                    }

                    this._referenceSets = referenceSets;
                }
            })
        );

        this.subscriptions.add(
            autorun(() => {
                //If the modal is open, dont update the secondary view, performance reasons
                if (this.modalState.type !== ModalType.NONE) {
                    return;
                }

                if (this.activeSetDefinition === null || isEmpty(this.appraisal.objectType)) {
                    const tempSecondaryAppraisalId = 'temp_' + secondaryAppraisalId;
                    this.appraiseSecondaryConfigStackInteractor.remove((c) => {
                        return c.id !== tempSecondaryAppraisalId && c.type === AppraiseSecondaryType.REFERENCE_V2_MAP;
                    });
                    this.appraiseSecondaryConfigStackInteractor.upsert({
                        id: tempSecondaryAppraisalId,
                        type: AppraiseSecondaryType.REFERENCE_V2_MAP,
                        onClose: () => {
                            //Noop
                        },
                        onClickChange: () => {
                            //Noop
                        },
                        onHoverChange: () => {
                            //Noop
                        },
                        clickedReferenceObject: null,
                        hoveringReferenceObject: null,
                        appraisal: this.appraisal,
                        showDetailsModal: this.showDetailsModal,
                        visibleReferenceObjects: [],
                    });
                } else {
                    const loadedSecondaryAppraisalId = 'loaded_' + secondaryAppraisalId;
                    this.appraiseSecondaryConfigStackInteractor.remove((c) => {
                        return c.id !== loadedSecondaryAppraisalId && c.type === AppraiseSecondaryType.REFERENCE_V2_MAP;
                    });

                    this.appraiseSecondaryConfigStackInteractor.upsert({
                        id: loadedSecondaryAppraisalId,
                        type: AppraiseSecondaryType.REFERENCE_V2_MAP,
                        onClose: () => {
                            //Noop
                        },
                        onClickChange: (rs) => this.setClickedReferenceObject(rs),
                        onHoverChange: (rs) => this.setHoveringReferenceObject(rs),
                        clickedReferenceObject: this.clickedReferenceObject,
                        hoveringReferenceObject: this.hoveringReferenceObject,
                        appraisal: this.appraisal,
                        visibleReferenceObjects: this.visibleReferenceObjects,
                        showDetailsModal: this.showDetailsModal,
                    });
                }
            })
        );
    }

    public async unmount() {
        this.subscriptions.clear();
        this.appraiseSecondaryConfigStackInteractor.remove((c) => String(c.id).endsWith(secondaryAppraisalId));
    }

    @action
    public setClickedReferenceObject = (referenceObject: ReferenceObject | null) => {
        this.clickedReferenceObject = referenceObject;
    };

    @action
    public setHoveringReferenceObject = (referenceObject: ReferenceObject | null) => {
        this.hoveringReferenceObject = referenceObject;
    };

    @action
    public onRemove = async (toBeRemovedReferenceObjectAnswer: ReferenceObjectAnswer) => {
        if (this.answer === null) {
            return;
        }

        const filteredReferenceObjectAnswers = this.referenceObjectAnswers(this.answer).filter(
            (referenceObjectAnswer) => {
                return !(
                    referenceObjectAnswer.id === toBeRemovedReferenceObjectAnswer.id &&
                    referenceObjectAnswer.valuationType === toBeRemovedReferenceObjectAnswer.valuationType
                );
            }
        );

        this.answerController.onContentsChange(
            this.answer.uuid,
            JSON.stringify(filteredReferenceObjectAnswers),
            AnswerTouchState.TOUCHED
        );
        await this.onReferenceObjectStore();
    };

    @action
    public onChange = (referenceObject: ReferenceObject, updatedReferenceObjectAnswer: ReferenceObjectAnswer) => {
        if (this.answer === null || this.activeSetDefinition === null) {
            return;
        }

        const updatedReferenceObjectAnswers = this.referenceObjectAnswers(this.answer)
            .filter((referenceObjectAnswer) => referenceObjectAnswer !== null && referenceObjectAnswer !== undefined)
            .map((referenceObjectAnswer) => {
                if (
                    referenceObjectAnswer.id === referenceObject.id &&
                    referenceObjectAnswer.valuationType === this.activeSetDefinition?.setDefinition.valuationType
                ) {
                    return {
                        ...updatedReferenceObjectAnswer,
                        valuationType: this.activeSetDefinition.setDefinition.valuationType,
                    };
                }
                return referenceObjectAnswer;
            });

        this.answerController.onContentsChange(
            this.answer.uuid,
            JSON.stringify(updatedReferenceObjectAnswers),
            AnswerTouchState.TOUCHED
        );
    };

    @action
    public onAddAndOpenModal = async (
        referenceObjectAnswer: ReferenceObjectAnswer,
        referenceObject?: ReferenceObject
    ) => {
        await this.onAdd(referenceObjectAnswer);

        //If we open the new modal too quickly, the body doesnt get the modal class and scrolling is still active on the body
        setTimeout(() => {
            if (referenceObject) {
                this.showAnswerModal(referenceObject);
            } else {
                this.showAnswerModal(
                    this.referenceObjectFromReferenceObjectAnswer(referenceObjectAnswer, referenceObject ?? null)
                );
            }
        }, 500);
    };

    public onAdd = async (toBeAddReferenceObjectAnswer: ReferenceObjectAnswer) => {
        if (this.answer === null || this.activeSetDefinition === null) {
            return;
        }

        await this.onModalHide();

        const referenceObjectAnswer = {
            ...toBeAddReferenceObjectAnswer,
            valuationType: this.activeSetDefinition.setDefinition.valuationType,
        };
        const referenceObjectAnswers = [...this.referenceObjectAnswers(this.answer), referenceObjectAnswer];

        this.answerController.onContentsChange(
            this.answer.uuid,
            JSON.stringify(referenceObjectAnswers),
            AnswerTouchState.TOUCHED
        );
        await this.onReferenceObjectStore();

        return referenceObjectAnswer;
    };

    public onAddMany = async (suggestedReferenceObjectAnswer: SuggestedReferenceObjectAnswer) => {
        if (this.answer === null || this.activeSetDefinition === null) {
            return;
        }

        await this.onModalHide();

        const referenceObjectAnswers = [
            ...this.referenceObjectAnswers(this.answer),
            ...suggestedReferenceObjectAnswer.answers.map((toBeAddedReferenceObjectAnswer) => ({
                ...toBeAddedReferenceObjectAnswer,
                valuationType: this.activeSetDefinition?.setDefinition.valuationType,
            })),
        ];

        this.answerController.onContentsChange(
            this.answer.uuid,
            JSON.stringify(referenceObjectAnswers),
            AnswerTouchState.TOUCHED
        );
        await this.onReferenceObjectStore();
    };

    public debouncedSetActiveSetDefinition = async (setDefinition: ParentLessSetDefinitionWithoutCount) => {
        if (this.timeout) {
            clearInterval(this.timeout);
        }

        this.timeout = window.setTimeout(() => {
            this.setActiveSetDefinition(setDefinition);
        }, 1000);
    };

    private getFailedPreconditions(setDefinition: ParentLessSetDefinitionWithoutCount) {
        const missingPreconditions: string[] = [];

        if (isEmpty(setDefinition.buildYear)) {
            missingPreconditions.push('bouwjaar');
        }

        if (isEmpty(setDefinition.surfaceArea)) {
            missingPreconditions.push('gebruiksoppervlakte wonen');
        }

        if (isEmpty(setDefinition.valuationDate)) {
            missingPreconditions.push('opnamedatum');
        }

        if (isEmpty(this.appraisal.objectType)) {
            missingPreconditions.push('object type');
        }

        return missingPreconditions;
    }

    @action
    public setActiveSetDefinition = async (setDefinition: ParentLessSetDefinitionWithoutCount) => {
        const missingPrecondition = this.getFailedPreconditions(setDefinition);

        this._activeSetDefinition = {
            setDefinition: setDefinition,
            state:
                missingPrecondition.length === 0 ? ActiveSetDefinitionState.DISABLED : ActiveSetDefinitionState.ACTIVE,
            referenceObjectSetData: this.activeSetDefinition?.referenceObjectSetData ?? null,
            missingPreconditions: missingPrecondition,
        };

        if (missingPrecondition.length === 0) {
            // Reference objects only needed when there is sale.
            let referenceObjectSetData: ReferenceSaleSetData | null = null;
            if (this.activeSetDefinition?.setDefinition.type === SetType.SOLD) {
                referenceObjectSetData = await this.referenceObjectsInteractor.request({
                    appraisalId: this.appraisal.id,
                    setType: setDefinition.type,
                    valuationTypeUuid: setDefinition.valuationType,
                    buildYear: setDefinition.buildYear ?? 0,
                    livingArea: setDefinition.surfaceArea ?? 0,
                    plotArea: setDefinition.plotArea,
                });
            }

            this._activeSetDefinition = {
                setDefinition: setDefinition,
                state: ActiveSetDefinitionState.ACTIVE,
                referenceObjectSetData: referenceObjectSetData,
                missingPreconditions: missingPrecondition,
            };

            const taskId = referenceObjectSetData?.taskId ?? null;
            if (taskId !== null) {
                const enhancedReferenceObjectSetData = await this.referenceObjectsInteractor.requestEnhancement(
                    taskId,
                    {
                        appraisalId: this.appraisal.id,
                        setType: setDefinition.type,
                        valuationTypeUuid: setDefinition.valuationType,
                        buildYear: setDefinition.buildYear ?? 0,
                        livingArea: setDefinition.surfaceArea ?? 0,
                        plotArea: setDefinition.plotArea,
                    }
                );

                if (
                    enhancedReferenceObjectSetData !== null &&
                    this._activeSetDefinition.state === ActiveSetDefinitionState.ACTIVE
                ) {
                    this._activeSetDefinition = {
                        setDefinition: setDefinition,
                        state: ActiveSetDefinitionState.ACTIVE,
                        referenceObjectSetData: enhancedReferenceObjectSetData,
                        missingPreconditions: missingPrecondition,
                    };
                }
            }
        }
    };

    @action
    public updateSortingMethod = (method: SortingMethod): void => {
        if (method === this.sortingMethod) {
            this.isComparing = false;
            this.sortingDirection =
                this.sortingDirection === SortingDirection.ASCENDING
                    ? SortingDirection.DESCENDING
                    : SortingDirection.ASCENDING;
        } else {
            this.isComparing = false;
            this.sortingMethod = method;
            this.sortingDirection = this.getDefaultSortingDirection(method);
        }
    };

    @action
    public updateIsComparing = (isComparing: boolean) => {
        this.isComparing = isComparing;
    };

    private getDefaultSortingDirection(sortingMethod: SortingMethod): SortingDirection {
        switch (sortingMethod) {
            case SortingMethod.ALPHABETICALLY:
                return SortingDirection.ASCENDING;
            case SortingMethod.SALES_DATE:
                return SortingDirection.DESCENDING;
            case SortingMethod.DISTANCE:
                return SortingDirection.ASCENDING;
            case SortingMethod.DEVIATION_SCORE:
                return SortingDirection.ASCENDING;
        }
    }

    @action
    public showMoreReferenceObjects = () => {
        this.numVisible += 12;
    };

    @action
    public onAddCustomReferenceSaleButtonClick = () => {
        this.modalState = {
            type: ModalType.CUSTOM_REFERENCE_SALE,
        };
    };

    @action
    public showAnswerModal = (referenceObject: ReferenceObject) => {
        this.modalState = {
            type: ModalType.OBJECT_ANSWER,
            referenceObject,
        };
    };

    @action
    public showDetailsModal = (referenceObject: ReferenceObject) => {
        this.modalState = {
            type: ModalType.REFERENCE_OBJECT_DETAILS,
            referenceObject,
        };
    };

    private onReferenceObjectStore = async () => {
        if (this.timeout) {
            clearInterval(this.timeout);
        }

        this.timeout = window.setTimeout(() => {
            this.isSavingAnswer = true;
        }, 1000);
        try {
            await this.answerInteractor.submit();
        } finally {
            clearInterval(this.timeout);
            this.isSavingAnswer = false;
        }
    };

    @action
    public onModalHide = async () => {
        if (this.modalState.type === ModalType.OBJECT_ANSWER) {
            await this.onReferenceObjectStore();
        }

        this.modalState = {
            type: ModalType.NONE,
        };
    };
}
