import {observer} from 'mobx-react';
import * as React from 'react';
import {Macros} from '../../../appraising/appraise/ui/content/questions/advanced/macros/macros';
import {macroIsActive, toggleMacro} from '../../../appraising/business/toggle_macro';
import {Appraisal} from '../../../appraising/models/appraisal';
import {MacroExternalType} from '../../../appraising/models/macro';
import {QuestionSet} from '../../../appraising/models/question_set';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {ReasoningInputPresenter} from './reasoning_input_presenter';

export const ReasoningInput = observer(
    ({
        questionSet,
        appraisal,
        onChange,
        showRequiredError,
    }: {
        questionSet: QuestionSet;
        appraisal: Appraisal;
        onChange: (reasoning: string) => void;
        showRequiredError?: boolean;
    }) => {
        const presenter = usePresenter(
            (container) => new ReasoningInputPresenter(container.business.macroInteractor(questionSet), onChange)
        );

        return (
            <div className={presenter.macros?.length !== 0 ? 'form-group has-macros' : 'form-group'}>
                <div className="label-container">
                    <span className="form-label-highlight">*</span>
                    <label>Onderbouwing voor afwijzen/accepteren</label>
                </div>
                {presenter.macros && (
                    <Macros
                        externalType={MacroExternalType.PRECHECK_REASONING}
                        parentAnswerUuid={null}
                        hasAdvancedOptions={false}
                        questionSet={questionSet}
                        appraisal={appraisal}
                        isActive={(contents) => macroIsActive(contents, presenter.precheckReasoning)}
                        onUseMacro={(contents) =>
                            presenter.onReasoningChange(toggleMacro(contents, presenter.precheckReasoning))
                        }
                        onAdd={presenter.onAddAsMacroClick}
                        onRemove={presenter.onRemoveMacroClick}
                        onFavorite={presenter.onFavoriteMacroClick}
                        showAddButton={
                            presenter.precheckReasoning !== '' &&
                            !presenter.macros.some((m) => m.contents === presenter.precheckReasoning)
                        }
                    />
                )}
                <textarea
                    className="form-control"
                    rows={3}
                    value={presenter.precheckReasoning}
                    onChange={(e) => presenter.onReasoningChange(e.target.value)}
                />
                {showRequiredError && presenter.precheckReasoning === '' && (
                    <span className="invalid-feedback" role="alert">
                        Bij afwijzen is het verplicht een onderbouwing op te geven.
                    </span>
                )}
            </div>
        );
    }
);
