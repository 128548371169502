import * as React from 'react';

import {observer} from 'mobx-react';
import {isSet} from '../../../../../../../support/is_set';
import {PhotoPair} from '../../../../../../business/attachment_questions_interactor';
import {Modal} from '../../components/modal';

interface OwnProps {
    photoPairs: PhotoPair[];
    onImagePicked: (pair: PhotoPair) => void;
    onClose: () => void;
    loading?: boolean;
}

@observer
export class PhotoPickModal extends React.Component<OwnProps> {
    public render() {
        const images = this.props.photoPairs.filter(
            (pair) => !pair.iteratorPhotoGroup.isDeleted && isSet(pair.photoAnswer.file)
        );

        const deletedImages = this.props.photoPairs.filter(
            (pair) => pair.iteratorPhotoGroup.isDeleted && isSet(pair.photoAnswer.file)
        );

        return (
            <Modal visible={true} forced={true}>
                <div className="modal-header">
                    <h2>Kies foto</h2>
                </div>
                <div className="modal-body">
                    <div className="photo-container">
                        <div className="row grid">
                            {images.map((pair) => {
                                return (
                                    <div
                                        key={pair.photoAnswer.uuid}
                                        className="col-12 col-sm-6 form-grid-item form-grid-item-no-footer"
                                    >
                                        <div className="form-grid-item-img">
                                            <div className="form-grid-item-img-content">
                                                <div className="img-wrap">
                                                    <button
                                                        disabled={this.props.loading}
                                                        className="btn-link"
                                                        onClick={() => this.props.onImagePicked(pair)}
                                                    >
                                                        <img className="d-flex" src={pair.photoAnswer.file?.url} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {deletedImages.length > 0 && (
                            <div className="row grid">
                                <div className="col-12">
                                    <h3>Niet getoond in rapport</h3>
                                </div>
                                {deletedImages.map((pair) => {
                                    return (
                                        <div
                                            key={pair.photoAnswer.uuid}
                                            className="col-12 col-sm-6 form-grid-item form-grid-item-no-footer"
                                        >
                                            <div className="form-grid-item-img">
                                                <div className="form-grid-item-img-content">
                                                    <div className="img-wrap">
                                                        <button
                                                            disabled={this.props.loading}
                                                            className="btn-link"
                                                            onClick={() => this.props.onImagePicked(pair)}
                                                        >
                                                            <img className="d-flex" src={pair.photoAnswer.file?.url} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="btn btn-primary btn-full ion-md-close"
                        type="button"
                        onClick={() => this.props.onClose()}
                    >
                        Sluiten
                    </button>
                </div>
            </Modal>
        );
    }
}
