import React, {useEffect, useMemo, useRef} from 'react';
import {classNames} from '../../../../../support/classnames';

interface OwnProps extends React.HTMLAttributes<HTMLDivElement> {
    comparability: number;
}

export function ComparabilityIndicator({comparability, ...htmlProps}: OwnProps) {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const fillRotation = Math.floor(180 * comparability);
        const fixRotation = fillRotation * 2;

        ref.current.querySelectorAll<HTMLElement>('.circle .fill, .circle .mask.full').forEach((el) => {
            el.style.transform = `rotate(${fillRotation}deg)`;
        });

        ref.current.querySelectorAll<HTMLElement>('.circle .fill.fix').forEach((el) => {
            el.style.transform = `rotate(${fixRotation}deg)`;
        });
    }, [comparability]);

    const circleClassName = useMemo(() => {
        if (comparability > 0.8) {
            return 'circle-much-better';
        } else if (comparability > 0.6) {
            return 'circle-better';
        } else if (comparability > 0.4) {
            return 'circle-neutral';
        } else if (comparability > 0.2) {
            return 'circle-worse';
        } else {
            return 'circle-much-worse';
        }
    }, [comparability]);

    return (
        // eslint-disable-next-line react/prop-types
        <div
            ref={ref}
            {...htmlProps}
            className={classNames('comparability-indicator', circleClassName, htmlProps.className)}
        >
            <div className="circle">
                <div className="mask full">
                    <div className="fill"></div>
                </div>
                <div className="mask half">
                    <div className="fill"></div>
                    <div className="fill fix"></div>
                </div>
            </div>
        </div>
    );
}
