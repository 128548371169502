import {ReferenceObjectSortingStrategy} from './reference_object_sorting_strategy';
import {ReferenceSale} from '../../models/reference_sale';
import {SortingDirection} from '../../../../../../../../../enum/reference_objects_sorting';

export class ReferenceObjectSorterByAlphabet implements ReferenceObjectSortingStrategy {
    public sortReferenceObjects(referenceSales: ReferenceSale[], sortingDirection: SortingDirection) {
        const referenceSalesOrtec: ReferenceSale[] = [];
        const referenceSalesNVM: ReferenceSale[] = [];
        const referenceSalesDefault: ReferenceSale[] = [];

        for (const referenceSale of referenceSales) {
            if (referenceSale.source !== null) {
                if (referenceSale.source.toLowerCase().includes('nvm')) {
                    referenceSalesNVM.push(referenceSale);
                } else if (referenceSale.source.toLowerCase().includes('ortec')) {
                    referenceSalesOrtec.push(referenceSale);
                } else {
                    referenceSalesDefault.push(referenceSale);
                }
            } else {
                referenceSalesDefault.push(referenceSale);
            }
        }

        const returnSortReferences: ReferenceSale[] = [];

        let sortedReferences = this.sortReferenceSales(referenceSalesNVM, sortingDirection);
        for (const sortedReference of sortedReferences) {
            returnSortReferences.push(sortedReference);
        }
        sortedReferences = this.sortReferenceSales(referenceSalesOrtec, sortingDirection);
        for (const sortedReference of sortedReferences) {
            returnSortReferences.push(sortedReference);
        }
        sortedReferences = this.sortReferenceSales(referenceSalesDefault, sortingDirection);
        for (const sortedReference of sortedReferences) {
            returnSortReferences.push(sortedReference);
        }

        return returnSortReferences;
    }

    private sortReferenceSales(referenceSales: ReferenceSale[], sortingDirection: SortingDirection) {
        return referenceSales.sort((referenceSale1, referenceSale2) => {
            return this.getFullAddressForReferenceSale(referenceSale1) >
                this.getFullAddressForReferenceSale(referenceSale2)
                ? sortingDirection === SortingDirection.DESCENDING
                    ? -1
                    : 1
                : sortingDirection === SortingDirection.DESCENDING
                ? 1
                : -1;
        });
    }

    private getFullAddressForReferenceSale = (referenceSale: ReferenceSale) => {
        return referenceSale.street.toLocaleLowerCase() + ' ' + referenceSale.houseNumber + ' ' + referenceSale.letter;
    };
}
