import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import * as React from 'react';
import {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import {classNames} from '../../../support/classnames';
import {PagePartsSet} from '../../models/page_parts_set';
import {PagePartConfigurationType} from '../../models/page_part_configuration';

interface OwnProps {
    disabled: boolean;
    pagePartsSet: PagePartsSet;
    visibleFullSetsCount: number;
    duplicatePagePartsSet(set: PagePartsSet): void;
    showPagePartsSet(set: PagePartsSet): void;
    hidePagePartsSet(set: PagePartsSet): void;
    deletePagePartsSet(set: PagePartsSet): void;
    stopSharingPagePartsSet(set: PagePartsSet): void;
    sharePagePartsSet(set: PagePartsSet): void;
}

export const ConfigListItem: FunctionComponent<OwnProps> = ({
    disabled,
    pagePartsSet,
    visibleFullSetsCount,
    duplicatePagePartsSet,
    showPagePartsSet,
    hidePagePartsSet,
    deletePagePartsSet,
    stopSharingPagePartsSet,
    sharePagePartsSet,
}) => {
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: pagePartsSet.id});

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <li
            ref={setNodeRef}
            style={pagePartsSet.config.isVisible ? style : undefined}
            {...(pagePartsSet.config.isVisible ? attributes : {})}
            {...(pagePartsSet.config.isVisible ? listeners : {})}
        >
            <span
                className={classNames('list-avatar', {
                    'ion-md-list':
                        pagePartsSet.config.type === PagePartConfigurationType.SYSTEM_COMPLETE ||
                        pagePartsSet.config.type === PagePartConfigurationType.SYSTEM_PARTIAL,
                    'ion-md-person':
                        pagePartsSet.config.type === PagePartConfigurationType.CUSTOM && pagePartsSet.config.isOwned,
                    'ion-md-people':
                        pagePartsSet.config.type === PagePartConfigurationType.CUSTOM && !pagePartsSet.config.isOwned,
                })}
            ></span>
            <span className="list-title">{pagePartsSet.name}</span>
            <small className="list-details">{pagePartsSet.config.userName}</small>
            <span className="list-options">
                {pagePartsSet.config.isOwned &&
                    (disabled ? (
                        <button disabled title="Bewerk deze configuratie" className="ion-md-create" />
                    ) : (
                        <Link
                            to={`/page-parts-configuration/${pagePartsSet.id}/${pagePartsSet.rootItems[0]?.uuid ?? ''}`}
                            title="Bewerk deze configuratie"
                            className="ion-md-create"
                        />
                    ))}
                {pagePartsSet.config.isOwned &&
                    (pagePartsSet.config.isShared ? (
                        <button
                            disabled={disabled}
                            className="c-icon-people-off"
                            title="Stop met delen van deze configuratie"
                            onClick={() => stopSharingPagePartsSet(pagePartsSet)}
                        />
                    ) : (
                        <button
                            disabled={disabled}
                            className="ion-md-people"
                            title="Deel deze configuratie met je collega's"
                            onClick={() => sharePagePartsSet(pagePartsSet)}
                        />
                    ))}
                <button
                    disabled={disabled}
                    className="ion-md-copy"
                    title="Dupliceer configuratie"
                    onClick={() => duplicatePagePartsSet(pagePartsSet)}
                />
                {pagePartsSet.config.isVisible &&
                pagePartsSet.config.type === PagePartConfigurationType.SYSTEM_COMPLETE &&
                visibleFullSetsCount === 1 ? null : pagePartsSet.config.isVisible ? (
                    <button
                        disabled={disabled}
                        className="ion-md-eye-off"
                        title="Verberg configuratie in taxeer omgeving"
                        onClick={() => hidePagePartsSet(pagePartsSet)}
                    />
                ) : (
                    <button
                        disabled={disabled}
                        className="ion-md-eye"
                        title="Geef configuratie weer in taxeer omgeving"
                        onClick={() => showPagePartsSet(pagePartsSet)}
                    />
                )}
                {pagePartsSet.config.isOwned && (
                    <button
                        disabled={disabled}
                        className="ion-md-trash"
                        title="Verwijder deze configuratie"
                        onClick={() => deletePagePartsSet(pagePartsSet)}
                    />
                )}
            </span>
        </li>
    );
};
