import {SimpleQuestionPresenter} from '../simple/simple_question_presenter';
import {ExternalAppointmentsApi} from '../../../../../network/external_appointment_api';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {AppointmentType, PaymentByType} from '../../../../../models/external_appointment';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {ObjectOwnershipType} from '../../../../../enum/object_ownership_type';
export interface AppointmentContact {
    type: PaymentByType;
    name: string;
}
import {BuildingInspectionAppointmentInteractor} from '../../../../../business/building_inspection_appointment_interactor';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';

export class BuildingInspectionAppointmentPresenter extends SimpleQuestionPresenter {
    private subscriptions = new CompositeSubscription();

    @observable
    private meetsRequiredConditions = false;

    @observable
    private buildingReportRequired = false;

    @observable
    public buildingInspectionAppointmentDate?: string | null = null;

    @computed
    public get shouldShow() {
        return this.meetsRequiredConditions || this.buildingReportRequired || this.appointmentRequested;
    }

    @observable
    public appointmentRequested: boolean | null = null;

    @observable
    public respondsInAnHour: boolean | null = null;

    @observable
    public creatingRequest = false;

    @observable
    public selectedContact: PaymentByType = PaymentByType.APPRAISER;

    @observable
    public comment = '';

    @observable
    public isSubmitting = false;

    @computed
    public get isDeclined() {
        return this.answer && this.answer.contents === 'disabled';
    }

    constructor(
        private externalAppointmentsApi: ExternalAppointmentsApi,
        private buildingInspectionAppointmentInteractor: BuildingInspectionAppointmentInteractor,
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    @computed
    public get appointmentContacts(): AppointmentContact[] {
        if (this.appraisal.objectOwnershipType.value === ObjectOwnershipType.BUYER) {
            return [
                {type: PaymentByType.APPRAISER, name: 'Taxateur'},
                {type: PaymentByType.OWNER, name: 'Huidige bewoner'},
                {type: PaymentByType.CLIENT, name: 'Koper (opdrachtgever)'},
            ];
        }

        return [
            {type: PaymentByType.APPRAISER, name: 'Taxateur'},
            {type: PaymentByType.CLIENT, name: 'Opdrachtgever'},
        ];
    }

    public async mount() {
        super.mount();

        this.subscriptions.add(
            this.buildingInspectionAppointmentInteractor
                .buildingReportRequiredStream()
                .subscribe((isBuildingReportRequired) => {
                    runInAction(() => {
                        this.buildingReportRequired = isBuildingReportRequired.isRequired;
                    });
                })
        );
        this.subscriptions.add(
            this.buildingInspectionAppointmentInteractor
                .meetsRequiredConditionsStream()
                .subscribe((meetsRequiredConditions) => {
                    runInAction(() => {
                        this.meetsRequiredConditions = meetsRequiredConditions;
                    });
                })
        );
        if (this.renderingContext !== RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
            await this.loadBuildingInspectorAppointment();
        }
    }

    private async loadBuildingInspectorAppointment() {
        try {
            const externalAppointments = await this.externalAppointmentsApi.getByAppraisalId(this.appraisal.id);
            const buildingInspectionAppointment = externalAppointments.find(
                (appointment) => appointment.appointmentType === AppointmentType.BUILDING_INSPECTOR
            );
            runInAction(() => {
                this.appointmentRequested = buildingInspectionAppointment !== undefined;
                if (buildingInspectionAppointment && buildingInspectionAppointment.createdAt) {
                    const now = new Date();
                    const createdMillisecondsAgo = now.getTime() - buildingInspectionAppointment.createdAt.getTime();
                    const createdMinutesAgo = createdMillisecondsAgo / 60000;
                    const timeIsInAvailableHours =
                        now.getTime() > this.availableFrom().getTime() &&
                        now.getTime() < this.availableTill().getTime();
                    this.respondsInAnHour = createdMinutesAgo < 60 && timeIsInAvailableHours;
                    this.buildingInspectionAppointmentDate = buildingInspectionAppointment.appointmentDate;
                }
            });
        } catch (e) {
            runInAction(() => {
                this.appointmentRequested = false;
            });
        }
    }

    @action
    public onCreateRequestClicked() {
        this.creatingRequest = true;
    }

    @action
    public onContactChange(contact: string) {
        switch (contact) {
            case PaymentByType.APPRAISER:
                this.selectedContact = PaymentByType.APPRAISER;
                break;
            case PaymentByType.CLIENT:
                this.selectedContact = PaymentByType.CLIENT;
                break;
            case PaymentByType.OWNER:
                this.selectedContact = PaymentByType.OWNER;
                break;
        }
    }

    @action
    public onCommentChange(comment: string) {
        this.comment = comment;
    }

    @action
    public async onSubmitRequestClicked() {
        this.isSubmitting = true;
        await this.externalAppointmentsApi.store({
            appointmentType: AppointmentType.BUILDING_INSPECTOR,
            paymentByType: this.selectedContact,
            appraisalId: this.appraisal.id,
            comment: this.comment,
        });
        await this.loadBuildingInspectorAppointment();
        this.isSubmitting = false;
    }

    @action
    public onDeclineClicked() {
        this.onChange('disabled');
    }

    @action
    public onCancelClicked() {
        this.creatingRequest = false;
    }

    private availableFrom(): Date {
        const date = new Date();
        date.setHours(8, 30);

        return date;
    }

    private availableTill(): Date {
        const date = new Date();
        date.setHours(16, 30);

        return date;
    }
}
