import {action, autorun, makeObservable, observable, runInAction} from 'mobx';

import {Answer} from '../../../../../models/answer';
import {AnswerController} from '../../../../../business/answering/answer_controller';
import {AnswerTouchState} from '../../../../../enum/answer_touch_state';
import {Appraisal} from '../../../../../models/appraisal';
import {AppraiseSecondaryConfigStackInteractor} from '../../../../../business/appraise_secondary_config_stack_interactor';
import {AppraiseSecondaryType} from '../../../../../models/appraise_secondary_config';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {ConceptReportProvider} from '../../../../../business/concept_report_provider';
import {NormalQuestionType} from '../../../../../enum/question_type';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {ReportSelectorType} from '../../../../../enum/report_selector_type';
import {isSet} from '../../../../../../support/is_set';
import {map} from 'rxjs/operators';

interface QuestionAnswerPair {
    question: Question;
    answer: Answer;
}

const secondaryAppraisalId = 'report-replacements';

export class ConceptReportPresenter implements Presenter {
    @observable public initialConceptReport: string | null = null;
    @observable public lastFocusedReference: ReportSelectorType | null = null;

    @observable.ref private replacementAnswers: QuestionAnswerPair[] = [];
    @observable public htmlIFrameElement: HTMLIFrameElement | null = null;

    private initialReplacementValues: Partial<Record<ReportSelectorType, string>> = {};

    private subscriptions = new CompositeSubscription();

    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private conceptReportProvider: ConceptReportProvider,
        private answerController: AnswerController,
        private appraiseSecondaryConfigStackInteractor: AppraiseSecondaryConfigStackInteractor
    ) {
        makeObservable(this);
    }

    public async mount() {
        runInAction(async () => {
            this.initialConceptReport = await this.conceptReportProvider.getHtmlConceptReport(this.appraisal.id);
        });

        const conceptReportReplacementQuestions = this.questionSet.findQuestionsByType(
            NormalQuestionType.CONCEPT_REPORT_REPLACEMENT
        );
        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidsStream(conceptReportReplacementQuestions.map((q) => q.uuid))
                .pipe(
                    map((replacementAnswers) => {
                        return replacementAnswers
                            .map((ra) => {
                                return {
                                    answer: ra,
                                    question: conceptReportReplacementQuestions.find((q) => q.uuid === ra.questionUuid),
                                };
                            })
                            .filter((pair): pair is QuestionAnswerPair => isSet(pair.question));
                    })
                )
                .subscribe((replacementAnswers) => {
                    runInAction(() => {
                        this.replacementAnswers = replacementAnswers;
                    });
                })
        );

        this.subscriptions.add(
            autorun(() => {
                if (this.htmlIFrameElement !== null && this.replacementAnswers.length > 0) {
                    for (const replacementAnswer of this.replacementAnswers) {
                        if (replacementAnswer.question.reportSelector) {
                            const domNode =
                                this.htmlIFrameElement.contentDocument?.querySelector<HTMLElement>(
                                    `[data-reference="${replacementAnswer.question.reportSelector}"]`
                                ) ?? null;
                            if (domNode) {
                                if (!this.initialReplacementValues[replacementAnswer.question.reportSelector]) {
                                    this.initialReplacementValues[replacementAnswer.question.reportSelector] =
                                        domNode.innerText;
                                    if (
                                        !replacementAnswer.answer.contents ||
                                        replacementAnswer.answer.contents.length === 0
                                    ) {
                                        this.answerController.onContentsChange(
                                            replacementAnswer.answer.uuid,
                                            domNode.innerText,
                                            AnswerTouchState.TOUCHED
                                        );
                                    }
                                }

                                if (replacementAnswer.answer.contents && replacementAnswer.answer.contents.length > 0) {
                                    domNode.innerText = replacementAnswer.answer.contents;
                                } else if (this.initialReplacementValues[replacementAnswer.question.reportSelector]) {
                                    domNode.innerText =
                                        this.initialReplacementValues[replacementAnswer.question.reportSelector] ??
                                        ('' as string);
                                }

                                if (this.lastFocusedReference === replacementAnswer.question.reportSelector) {
                                    if ('scrollIntoViewIfNeeded' in domNode) {
                                        (domNode as {scrollIntoViewIfNeeded: () => void}).scrollIntoViewIfNeeded();
                                    } else {
                                        domNode.scrollIntoView();
                                    }
                                }
                            }
                        }
                    }
                }
            })
        );

        this.subscriptions.add(
            autorun(() => {
                this.appraiseSecondaryConfigStackInteractor.upsert({
                    id: secondaryAppraisalId,
                    type: AppraiseSecondaryType.REPORT_REPLACEMENTS,
                    appraisal: this.appraisal,
                    onClose: () => {
                        //Noop
                    },
                    onReferenceFocus: (reference: ReportSelectorType) => {
                        if (this.htmlIFrameElement) {
                            const domNode =
                                this.htmlIFrameElement.contentDocument?.querySelector<HTMLElement>(
                                    `[data-reference="${reference}"]`
                                ) ?? null;
                            if (domNode) {
                                if ('scrollIntoViewIfNeeded' in domNode) {
                                    (
                                        domNode as unknown as {scrollIntoViewIfNeeded: () => void}
                                    ).scrollIntoViewIfNeeded();
                                } else {
                                    domNode.scrollIntoView();
                                }
                            }
                        }
                    },
                    questionSet: this.questionSet,
                    lastFocusedReference: this.lastFocusedReference,
                    questionRenderingData: null,
                });
            })
        );

        this.subscriptions.add(
            this.conceptReportProvider.getReplacementInvalidateStream().subscribe((type) => {
                if (!this.htmlIFrameElement) {
                    return;
                }

                for (const replacementAnswer of this.replacementAnswers) {
                    if (replacementAnswer.question.reportSelector === type) {
                        const domNode =
                            this.htmlIFrameElement.contentDocument?.querySelector<HTMLElement>(
                                `[data-reference="${replacementAnswer.question.reportSelector}"]`
                            ) ?? null;
                        if (!domNode) {
                            continue;
                        }

                        const value = this.initialReplacementValues[replacementAnswer.question.reportSelector];

                        if (!value) {
                            continue;
                        }

                        this.answerController.onContentsChange(
                            replacementAnswer.answer.uuid,
                            value,
                            AnswerTouchState.TOUCHED
                        );

                        domNode.innerText = value;

                        if ('scrollIntoViewIfNeeded' in domNode) {
                            (domNode as {scrollIntoViewIfNeeded: () => void}).scrollIntoViewIfNeeded();
                        } else {
                            domNode.scrollIntoView();
                        }
                    }
                }
            })
        );
    }

    public async unmount() {
        this.subscriptions.clear();
        this.appraiseSecondaryConfigStackInteractor.remove((c) => c.id === secondaryAppraisalId);
    }

    @action
    public onReplacementClick(replacementReference: ReportSelectorType) {
        this.lastFocusedReference = replacementReference;
    }

    @action
    public onIframeRefupdate(iframe: HTMLIFrameElement) {
        if (this.htmlIFrameElement !== iframe) {
            this.htmlIFrameElement = iframe;
            this.htmlIFrameElement.contentDocument?.body.classList.add('concept-report-preview');
        }
    }
}
