import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {FunctionComponent} from 'react';
import {LoadingStateType} from '../../../support/presenter/helpers';
import {Modal} from '../../appraise/ui/content/questions/components/modal';
import {NewConfigurationModalState} from './config_list_tab_presenter';

interface OwnProps {
    loadingState: LoadingStateType;
    newConfigurationModalState: NewConfigurationModalState;
    onNewConfigurationNameChange: (value: string) => void;
    onNewConfigurationDescriptionChange: (value: string) => void;
    onNewConfigurationIsSharedChange: (value: boolean) => void;
    setIsAddConfigurationModalOpen: (isOpen: boolean) => void;
    createnNewConfiguration: () => void;
}

export const NewConfigModal: FunctionComponent<OwnProps> = observer(
    ({
        loadingState,
        newConfigurationModalState,
        onNewConfigurationNameChange,
        onNewConfigurationDescriptionChange,
        onNewConfigurationIsSharedChange,
        setIsAddConfigurationModalOpen,
        createnNewConfiguration,
    }) => {
        return (
            <Modal visible={true}>
                <div className="modal-header">
                    <h2>Nieuwe configuratie</h2>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="new-configuration-name">Naam</label>
                                <input
                                    id="new-configuration-name"
                                    type="text"
                                    className="form-control"
                                    value={newConfigurationModalState.name}
                                    onChange={(e) => onNewConfigurationNameChange(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="new-configuration-description">Beschrijving</label>
                                <textarea
                                    id="new-configuration-description"
                                    className="form-control"
                                    value={newConfigurationModalState.description}
                                    onChange={(e) => onNewConfigurationDescriptionChange(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <input
                                    id="new-configuration-is-shared"
                                    name="new-configuration-is-shared"
                                    type="checkbox"
                                    checked={newConfigurationModalState.isShared === true}
                                    onChange={(e) => onNewConfigurationIsSharedChange(e.target.checked === true)}
                                />
                                <label htmlFor="new-configuration-is-shared" className="col-form-label">
                                    Configuratie is gedeeld met collega&apos;s
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        disabled={loadingState === LoadingStateType.LOADING}
                        className="btn btn-secondary"
                        onClick={() => setIsAddConfigurationModalOpen(false)}
                    >
                        Sluiten
                    </button>
                    <button
                        disabled={loadingState === LoadingStateType.LOADING}
                        className="btn btn-primary"
                        onClick={() => createnNewConfiguration()}
                    >
                        Opslaan
                    </button>
                </div>
            </Modal>
        );
    }
);
