import {
    ValidationConclusion,
    ValidationConclusionProvider,
} from '../../../../business/validation/validation_conclusion_provider';
import {makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {Presenter} from '../../../../../support/presenter/presenter';

export class NwwiControlReportWidgetPresenter implements Presenter {
    @observable.ref public validationConclusion: ValidationConclusion | null = null;

    private subscriptions = new CompositeSubscription();

    constructor(private validationConclusionProvider: ValidationConclusionProvider) {
        makeObservable(this);
    }

    public async mount() {
        this.subscriptions.add(
            this.validationConclusionProvider.stream.subscribe((validationConclusion) => {
                runInAction(() => {
                    this.validationConclusion = validationConclusion;
                });
            })
        );
    }

    public async unmount() {
        this.subscriptions.clear();
    }

    public refresh() {
        this.validationConclusionProvider.refresh();
    }
}
