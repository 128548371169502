import {renderButton} from './button';

export enum BlockTypes {
    BUTTON = 'button',
}

export function renderBlock(type: BlockTypes, content: string | null): string {
    switch (type) {
        case BlockTypes.BUTTON: {
            return renderButton(content);
        }
        default: {
            return '';
        }
    }
}
