import * as React from 'react';

import {
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {restrictToVerticalAxis, restrictToWindowEdges} from '@dnd-kit/modifiers';

import {FunctionComponent} from 'react';
import {PagePart} from '../../../models/page_part';
import {PagePartsSet} from '../../../models/page_parts_set';
import {Sidebar} from './sidebar';
import {SortableRootItem} from './sortable_root_item';

interface OwnProps {
    pagePartsSet: PagePartsSet | null;
    activePagePartUuid: string | null;
    updateRank: (newRank: number, uuid: string) => void;
    onNewSubPage: (parent: PagePart) => void;
    onDelete: (uuid: string) => void;
    onNewRootPage: () => void;
}

export const SortableSidebar: FunctionComponent<OwnProps> = ({
    pagePartsSet,
    activePagePartUuid,
    updateRank,
    onNewSubPage,
    onDelete,
    onNewRootPage,
}) => {
    if (pagePartsSet === null) {
        return null;
    }
    const rootItems = pagePartsSet.rootItems.map((pp) => ({...pp, id: pp.uuid})).sort((a, b) => a.rank - b.rank);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragEnd(event: DragEndEvent) {
        const {active, over} = event;

        if (over && active.id !== over.id) {
            const newIndex = rootItems.findIndex((pp) => pp.uuid === over.id);
            updateRank(newIndex, active.id as string);
        }
    }

    return (
        <Sidebar>
            <DndContext
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={rootItems}
                    strategy={verticalListSortingStrategy}
                    disabled={pagePartsSet?.config.isEditable === false}
                >
                    {rootItems.map((pagePart) => (
                        <SortableRootItem
                            key={pagePart.uuid}
                            pagePart={pagePart}
                            pagePartsSet={pagePartsSet}
                            activePagePartUuid={activePagePartUuid}
                            onNewSubPage={onNewSubPage}
                            updateRank={updateRank}
                            onDelete={onDelete}
                        />
                    ))}
                </SortableContext>
            </DndContext>

            {pagePartsSet?.config.isEditable == true && (
                <div className="child-item card-progress-large">
                    <button className="tree-button ion-md-add" onClick={() => onNewRootPage()}>
                        <span className="title">
                            <em>Pagina</em>
                        </span>
                    </button>
                </div>
            )}
        </Sidebar>
    );
};
