import {SortingDirection, SortingMethod} from '../../../../../../../enum/reference_objects_sorting';
import {action, makeObservable, observable} from 'mobx';

import {Appraisal} from '../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../models/question_set';
import {ReferenceObjectAnswer} from './models/reference_object_answer';
import {ReferenceObjectSorter} from './internal/reference_object_sorter';
import {ReferenceObjectSortingStrategyProvider} from './internal/reference_object_sorting_strategy_provider';
import {ReferenceSale} from './models/reference_sale';
import {ReferenceSet} from './models/reference_set';
import {VisibleReferenceSale} from './reference_objects_question_presenter';
import {getFromReferenceObjectAnswer} from './internal/normalized_sale_date';

export class ReferenceObjectsMapDataContainer {
    @observable public numVisible = 12;
    @observable public hoveringReferenceSale: ReferenceSale | null = null;
    @observable public clickedReferenceSale: ReferenceSale | null = null;

    @observable public sortingMethod = SortingMethod.ALPHABETICALLY;
    @observable public sortingDirection = SortingDirection.ASCENDING;
    @observable public isComparing = false;

    constructor(protected appraisal: Appraisal, protected questionSet: QuestionSet) {
        makeObservable(this);
    }

    @action
    public showMoreReferenceSales = () => {
        this.numVisible = this.numVisible + 12;
    };

    @action
    public setClickedReferenceSale = (referenceSale: ReferenceSale | null) => {
        this.clickedReferenceSale = referenceSale;
    };

    @action
    public setHoveringReferenceSale = (referenceSale: ReferenceSale | null) => {
        this.hoveringReferenceSale = referenceSale;
    };

    @action
    public updateSortingMethod = (method: SortingMethod): void => {
        if (method === this.sortingMethod) {
            this.isComparing = false;
            this.sortingDirection =
                this.sortingDirection === SortingDirection.ASCENDING
                    ? SortingDirection.DESCENDING
                    : SortingDirection.ASCENDING;
        } else {
            this.isComparing = false;
            this.sortingMethod = method;
            this.sortingDirection = this.getDefaultSortingDirection(method);
        }
    };

    @action
    public updateIsComparing = (isComparing: boolean) => {
        this.isComparing = isComparing;
    };

    private getDefaultSortingDirection(sortingMethod: SortingMethod): SortingDirection {
        switch (sortingMethod) {
            case SortingMethod.ALPHABETICALLY:
                return SortingDirection.ASCENDING;
            case SortingMethod.SALES_DATE:
                return SortingDirection.DESCENDING;
            case SortingMethod.DISTANCE:
                return SortingDirection.ASCENDING;
            case SortingMethod.DEVIATION_SCORE:
                return SortingDirection.ASCENDING;
        }
    }

    private _referenceObjectSorter = new ReferenceObjectSorter(new ReferenceObjectSortingStrategyProvider());
    private sortReferenceObjects(referenceSaleSet: ReferenceSet, referenceSales: ReferenceSale[]): ReferenceSale[] {
        // first sort the referenceObject based on the selected method
        const sortedReferenceSales = this._referenceObjectSorter.sortReferenceObjects(
            referenceSales,
            this.appraisal,
            this.questionSet,
            this.sortingMethod,
            this.sortingDirection,
            referenceSaleSet.areaConclusion?.gebruiksoppervlakteWonen ?? null,
            referenceSaleSet.areaConclusion?.perceelOppervlakte ?? null
        );

        //Get all references which are not selected
        const nonSelectedReferences = sortedReferenceSales.filter(
            (rs) => referenceSaleSet.referenceObjectAnswers.findIndex((roa) => roa.id === rs.id) === -1
        );

        //Create a list of the selected references
        const selectedReferences = referenceSaleSet.referenceObjectAnswers
            .map((answer) => sortedReferenceSales.find((reference) => reference.id === answer.id))
            .filter((r): r is ReferenceSale => r !== undefined);

        //Combine 2 lists with selected references first
        return [...selectedReferences, ...nonSelectedReferences];
    }

    private referenceSaleFromReferenceObjectAnswer(referenceObjectAnswer: ReferenceObjectAnswer): ReferenceSale {
        return {
            // Shared data with Sale class
            id: referenceObjectAnswer.id,
            source: null,
            postalCode: referenceObjectAnswer.referenceObject.adres.postcode,
            letter: referenceObjectAnswer.referenceObject.adres.huisnummerToevoeging,
            street: referenceObjectAnswer.referenceObject.adres.straat,
            houseNumber: referenceObjectAnswer.referenceObject.adres.huisnummer,
            city: referenceObjectAnswer.referenceObject.adres.plaats,
            priceRange:
                referenceObjectAnswer.referenceObject.verkoopprijs === null
                    ? null
                    : '' + referenceObjectAnswer.referenceObject.verkoopprijs,
            indexedPriceRange:
                referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs === null
                    ? null
                    : '' + referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs,
            saleQuarter: referenceObjectAnswer.referenceObject.verkoopdatum,
            normalizedSaleDate: getFromReferenceObjectAnswer(referenceObjectAnswer),
            transportDate: null,
            objectType: null,
            plotArea: referenceObjectAnswer.referenceObject.perceelOppervlakte,
            floorArea: referenceObjectAnswer.referenceObject.gebruiksOppervlakte,
            buildYear: referenceObjectAnswer.referenceObject.bouwjaar,
            volume: referenceObjectAnswer.referenceObject.inhoud,
            latitude: referenceObjectAnswer.referenceObject.adres.latitude
                ? referenceObjectAnswer.referenceObject.adres.latitude
                : 0,
            longitude: referenceObjectAnswer.referenceObject.adres.longitude
                ? referenceObjectAnswer.referenceObject.adres.longitude
                : 0,
            energyLabel: referenceObjectAnswer.referenceObject.energielabel,
            energyLabelIsPreliminary: false,
            energyLabelValidDate: referenceObjectAnswer.referenceObject.energielabelGeldigTot ?? null,
            daysOpenForSale: null,
            hasWarning: false,
            warning: null,
            ownership: null,
            brochureUrl: null,
            frontview: null,
            images: [],
            rooms: null,
            complete: null,
            rejectReason: null,

            // Specific data
            highlights: [],
            overview: null,
            installationDates: null,
            matchingPercentage: null,
            additionalPropertiesByCategory: null,
            saleText: null,
        };
    }
    private filterAnswers(referenceSaleSet: ReferenceSet, referenceObject: ReferenceSale): boolean {
        return !referenceSaleSet.referenceObjectAnswers.some(
            (referenceObjectAnswer) => referenceObjectAnswer.id === referenceObject.id
        );
    }

    protected getVisibleReferenceSales(referenceSaleSet: ReferenceSet | null): VisibleReferenceSale[] {
        if (referenceSaleSet === null) {
            return [];
        }

        return this.sortReferenceObjects(referenceSaleSet, [
            ...referenceSaleSet.referenceObjectAnswers.map((roa) => this.referenceSaleFromReferenceObjectAnswer(roa)),
            ...referenceSaleSet.referenceObjects.filter((rs) => this.filterAnswers(referenceSaleSet, rs)),
        ])
            .slice(0, this.numVisible)
            .map((referenceSale) => {
                const referenceObjectAnswer =
                    referenceSaleSet.referenceObjectAnswers.find((answer) => answer.id === referenceSale.id) || null;

                const saleSetReferenceSale =
                    referenceSaleSet.referenceObjects.find((rs) => rs.id === referenceSale.id) || referenceSale;

                return {
                    referenceSale: saleSetReferenceSale,
                    referenceObjectAnswer,
                };
            });
    }
}
