import {canvasToFile} from '../../../../../../../../support/create_file';

export async function fileFromCanvas(canvas: HTMLCanvasElement, fileName: string): Promise<File> {
    const file = await canvasToFile(canvas, {}, fileName, Date.now(), 1);
    if (file) {
        return file;
    } else {
        throw new Error('Foto kan niet opgeslagen worden');
    }
}
