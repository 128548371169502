interface Task {
    status_url: string;
    redirect_url: string;
    fail_redirect_url: string;
}

enum State {
    CREATED = 'created',
    STARTED = 'started',
    FINISHED = 'finished',
}

enum TaskResult {
    CREATED = 'created',
    STARTED = 'started',
    FINISHED = 'finished',
    WARNING = 'warning',
    ERROR = 'error',
}

declare const APPRAISE_TASK: Task | undefined;

if (typeof APPRAISE_TASK !== 'undefined') {
    poll(APPRAISE_TASK);
}

async function poll(task: Task) {
    let state: State | null = null;
    let result: TaskResult | null = null;
    try {
        const pollResult = await get(task.status_url);
        state = pollResult.status;
        result = pollResult.result;
    } catch (e) {
        //Just keep trying when things go wrong
    }

    if (state === State.FINISHED && result === TaskResult.FINISHED) {
        $('#appraise-button').removeClass('disabled').empty().text('Opname');
    } else if (task.fail_redirect_url !== undefined && (result === TaskResult.ERROR || result === TaskResult.WARNING)) {
        $('#appraise-button').empty();
    } else {
        setTimeout(() => {
            poll(task);
        }, 4000);
    }
}

function get(statusUrl: string) {
    return new Promise<{
        status: State | null;
        result: TaskResult | null;
    }>((resolve, reject) => {
        window
            .fetch(statusUrl, {
                method: 'GET',
                credentials: 'same-origin',
                headers: {
                    'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(async (result) => {
                if (result.ok) {
                    resolve(await result.json());
                } else {
                    reject();
                }
            })
            .catch((error) => reject(error));
    });
}
