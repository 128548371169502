import * as React from 'react';

import {Appraisal} from '../../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {VolumePriceBadge} from '../../../../../../../../components/badges/volume_price_badge';
import {isEmpty} from '../../../../../../../../../support/util';
import {observer} from 'mobx-react';

interface OwnProps {
    compact?: boolean;
    referenceObjectVolume: number | null;
    referenceObjectPrice: string | number | null;
    referenceSetPrice: number | null;
    referenceSetVolume: number | null;
    questionSet: QuestionSet;
    appraisal: Appraisal;
}

@observer
export class VolumePrice extends React.Component<OwnProps> {
    private formatPrice(from: number): string {
        return `€ ${from.toLocaleString('nl-NL')}`;
    }

    private average(fromString: string, tilString: string | undefined): number {
        const from = parseInt(fromString, 10);
        const till = tilString !== undefined ? parseInt(tilString, 10) : from;

        return (from + till) / 2;
    }

    public render() {
        if (
            this.props.referenceObjectPrice === null ||
            isEmpty(String(this.props.referenceObjectPrice).trim()) ||
            this.props.referenceObjectVolume === null ||
            this.props.referenceObjectVolume === 0
        ) {
            return <>{'-'}</>;
        }

        let price;
        if (typeof this.props.referenceObjectPrice === 'string') {
            if (this.props.referenceObjectPrice.includes(' - ')) {
                const [from, till] = this.props.referenceObjectPrice.split(' - ');
                price = this.average(from, till);
            } else {
                price = parseInt(this.props.referenceObjectPrice, 10);
            }
        } else {
            price = this.props.referenceObjectPrice;
        }

        const referenceObjectPricePerM3 = Math.round(price / this.props.referenceObjectVolume);
        const referenceSetPricePerM3 =
            this.props.referenceSetVolume !== null &&
            this.props.referenceSetVolume !== 0 &&
            this.props.referenceSetPrice !== null
                ? Math.round(this.props.referenceSetPrice / this.props.referenceSetVolume)
                : null;

        return (
            <>
                <span>{this.formatPrice(referenceObjectPricePerM3)}</span>
                {referenceSetPricePerM3 !== null ? (
                    <VolumePriceBadge
                        volumePrice={referenceSetPricePerM3}
                        referenceSetVolumePrice={referenceObjectPricePerM3}
                        compact={this.props.compact}
                    />
                ) : null}
            </>
        );
    }
}
