import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from '../simple/simple_question_presenter';

import {ValidationError} from '../../../../../models/validation_error';
import {ValidationMessageMap} from '../../../../../business/validation/validation_message';
import {AttachmentWidgetPresenter} from './attachment_widget_presenter';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
}

export const AttachmentWidget: React.FC<OwnProps> = observer(function AttachmentWidget(props) {
    const presenter = usePresenter(
        (container) =>
            new AttachmentWidgetPresenter(
                props.validationErrors,
                props.validationMessages,
                props.pagePartsSet,
                props.activePagePart,
                container.business.modalConfigStackInteractor,
                container.business.appraiseSecondaryConfigStackInteractor,
                container.business.childQuestionValidationProvider(
                    props.appraisal,
                    props.questionSet,
                    props.validationErrors
                ),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );

    if (presenter.isHidden) {
        return null;
    }
    return (
        <div className="list-item-attachment">
            <a className="list-item-attachment-download" onClick={() => presenter.toggleWidgetContainer()} />
            <span className="list-avatar list-avatar-active ion-md-cloud-done" />
            <span className="form-group list-title-download">{props.question.contents}</span>
            <small className="list-details list-details-download">Bekijk de automatisch gegenereerde bijlage</small>
        </div>
    );
});
