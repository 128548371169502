import {Presenter} from '../../support/presenter/presenter';
import {AppraisalProvider} from '../business/appraisal_provider';
import {Appraisal} from '../models/appraisal';

export class NotesWidgetRootPresenter implements Presenter {
    constructor(private appraisal: Appraisal, private appraisalProvider: AppraisalProvider) {}

    public mount(): void {
        this.appraisalProvider.onChange(this.appraisal);
    }
}
