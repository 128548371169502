import * as React from 'react';

import {format, parse} from 'date-fns';

import {Appraisal} from '../../../models/appraisal';
import {BuildYearBadge} from '../../../components/badges/build_year_badge';
import {ExplanationAppraiserGenerator} from '../../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/explanation_appraiser_generator/explanation_appraiser_generator';
import {ExplanationBuildYearGenerator} from '../../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/explanation_appraiser_generator/explanation_build_year_generator';
import {ExplanationBuildingTypeGenerator} from '../../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/explanation_appraiser_generator/explanation_building_type_generator';
import {ExplanationBuildingsGenerator} from '../../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/explanation_appraiser_generator/explanation_buildings_generator';
import {ExplanationFloorAreaGenerator} from '../../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/explanation_appraiser_generator/explanation_floor_area_generator';
import {ExplanationPlotAreaGenerator} from '../../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/explanation_appraiser_generator/explanation_plot_area_generator';
import {FloorAreaBadge} from '../../../components/badges/floor_area_badge';
import {Modal} from '../../../appraise/ui/content/questions/components/modal';
import {ModelValuesDetails} from '../../models/model_values_details';
import {ModelValuesExplanationDataProvider} from '../../explanation_data_provider';
import {PlotAreaBadge} from '../../../components/badges/plot_area_badge';
import {ReferentieObjectContainerPresenter} from './referentie_object_container_presenter';
import {Textarea} from './textarea';
import {ValidationInstituteReferentieObject} from '../../../models/validation_institute_reference_object';
import {VolumeBadge} from '../../../components/badges/volume_badge';
import {classNames} from '../../../../support/classnames';
import {formatMoney} from '../../../appraise/ui/support/format_money';
import {observer} from 'mobx-react';
import {QuestionSet} from '../../../models/question_set';
import {ExplanationEnergyLabelGenerator} from '../../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/explanation_appraiser_generator/explanation_energy_label_generator';
import {usePresenter} from '../../../../support/presenter/use_presenter';

interface OwnProps {
    referentieObject: ValidationInstituteReferentieObject;
    modelValuesDetails: ModelValuesDetails;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    onSubmit: () => Promise<void>;
    onChange: (referentieObject: ValidationInstituteReferentieObject) => ValidationInstituteReferentieObject;
}

export const ReferentieObjectContainer: React.FC<OwnProps> = observer(function ReferentieObjectContainer(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferentieObjectContainerPresenter(
                props.referentieObject,
                props.modelValuesDetails,
                props.onChange,
                props.onSubmit,
                new ExplanationAppraiserGenerator(
                    new ModelValuesExplanationDataProvider(props.modelValuesDetails, props.appraisal),
                    new ExplanationBuildYearGenerator(),
                    new ExplanationFloorAreaGenerator(),
                    new ExplanationPlotAreaGenerator(),
                    new ExplanationBuildingsGenerator(),
                    new ExplanationBuildingTypeGenerator(),
                    new ExplanationEnergyLabelGenerator()
                ),
                container.business.macroInteractor(props.questionSet),
                container.business.answerController(props.appraisal, props.questionSet),
                props.questionSet,
                container.globalProvider
            )
    );

    function formatAddress(referentieObject: ValidationInstituteReferentieObject) {
        const address = referentieObject.adres;
        return (
            <span>
                {address.straat} {address.huisnummer}{' '}
                {address.huisnummerToevoeging ? address.huisnummerToevoeging : null}
            </span>
        );
    }

    function formatDate(date: string | null) {
        if (!date) {
            return null;
        }
        return format(parse(date, 'dd-MM-yyyy HH:mm', new Date()), 'dd-MM-yyyy');
    }

    function renderModal() {
        const referentieObject = presenter.referentieObjectForModal;
        return referentieObject !== null ? (
            <Modal
                key={referentieObject._uuid}
                forced={!presenter.canSave}
                onHide={() => presenter.onReferentieObjectDetailModalClose()}
                visible={true}
            >
                <div className="modal-header">
                    <h2>
                        {formatAddress(referentieObject)} {referentieObject.adres.postcode}{' '}
                        {referentieObject.adres.plaats}
                    </h2>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <dl className="text-left">
                                <dt>Gebruiksoppervlakte</dt>
                                <dd>
                                    ca. {referentieObject.gebruiksOppervlakte}m²
                                    {referentieObject.gebruiksOppervlakte !== null ? (
                                        <FloorAreaBadge
                                            floorArea={referentieObject.gebruiksOppervlakte}
                                            referenceFloorArea={parseInt(props.modelValuesDetails.usable_area, 10)}
                                        />
                                    ) : null}
                                </dd>
                            </dl>
                        </div>
                        <div className="col-12 col-md-6">
                            <dl className="text-left">
                                <dt>Perceeloppervlakte</dt>
                                <dd>
                                    {referentieObject.perceelOppervlakte}m²
                                    {props.referentieObject.perceelOppervlakte !== null ? (
                                        <PlotAreaBadge
                                            plotArea={props.referentieObject.perceelOppervlakte}
                                            referencePlotArea={parseInt(props.modelValuesDetails.lot_area, 10)}
                                        />
                                    ) : null}
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div className="row">
                        {props.referentieObject.inhoud !== null && props.referentieObject.inhoud > 0 ? (
                            <div className="col-12 col-md-6">
                                <dl className="text-left">
                                    <dt>Bruto inhoud</dt>
                                    <dd>
                                        ca. {referentieObject.inhoud}m³
                                        <VolumeBadge
                                            volume={props.referentieObject.inhoud}
                                            referenceVolume={parseInt(props.modelValuesDetails.gross_contents, 10)}
                                        />
                                    </dd>
                                </dl>
                            </div>
                        ) : null}
                        <div className="col-12 col-md-6">
                            <dl className="text-left">
                                <dt>Bouwjaar</dt>
                                <dd>
                                    {referentieObject.bouwjaar}
                                    {referentieObject.bouwjaar !== null ? (
                                        <BuildYearBadge
                                            buildYear={referentieObject.bouwjaar}
                                            referenceBuildYear={parseInt(props.modelValuesDetails.build_year, 10)}
                                        />
                                    ) : null}
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <dl className="text-left">
                                <dt>Verkoopprijs</dt>
                                <dd>{formatMoney(referentieObject.verkoopprijs)}</dd>
                            </dl>
                        </div>
                        <div className="col-12 col-md-6">
                            <dl className="text-left">
                                <dt>Gecorrigeerde Verkoopprijs</dt>
                                <dd>{formatMoney(referentieObject.gecorrigeerdeVerkoopprijs)}</dd>
                            </dl>
                        </div>
                    </div>

                    <div className="form-group">
                        <h3>
                            Toelichting
                            <span className="form-label-highlight">*</span>
                        </h3>
                        <Textarea
                            name="description"
                            macros={presenter.macros}
                            value={referentieObject.toelichtingGebruikReferentieObject}
                            onChange={(value) => presenter.onToelichtingGebruikReferentieObject(value)}
                            onGenerateClick={() => presenter.onToelichtingGenerate()}
                            appraisal={props.appraisal}
                            questionSet={props.questionSet}
                            macroActions={{
                                onAdd: presenter.onAddAsMacroClick,
                                onRemove: presenter.onRemoveMacroClick,
                                onFavorite: presenter.onFavoriteMacroClick,
                            }}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="btn btn-secondary ion-md-square-outline"
                        type="button"
                        onClick={() => presenter.onUncheckClick()}
                    >
                        Uitvinken
                    </button>
                    <button
                        className="btn btn-primary ion-md-checkmark"
                        type="button"
                        disabled={!presenter.canSave}
                        onClick={() => presenter.onReferentieObjectDetailModalClose()}
                    >
                        Opslaan
                    </button>
                </div>
            </Modal>
        ) : null;
    }

    return (
        <div
            className={classNames(
                'col-12 col-sm-6 form-grid-item form-grid-item-large',
                props.referentieObject._selected ? 'selected' : null,
                props.referentieObject._matched ? 'auto-matched' : null
            )}
        >
            {props.referentieObject._matched && props.referentieObject._selected ? (
                <div className="ribbon ion-md-color-wand">Automatisch geselecteerd</div>
            ) : null}

            {props.referentieObject.streetviewUrl && (
                <div className="form-grid-item-img">
                    <div className="form-grid-item-img-content">
                        <img src={props.referentieObject.streetviewUrl} />
                        <div
                            className={
                                props.referentieObject._selected
                                    ? 'active-icon ion-md-checkmark visible'
                                    : 'active-icon ion-md-checkmark'
                            }
                        >
                            &nbsp;
                        </div>
                    </div>
                </div>
            )}
            <div className="form-grid-item-body">
                <div className="row">
                    <div className="col-12">
                        <div className="grid-item-title">
                            <p>
                                <strong>{formatAddress(props.referentieObject)}</strong>
                                <br />
                                <span className="uppercase">
                                    {props.referentieObject.adres.postcode} {props.referentieObject.adres.plaats}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="col-6 text-left">
                        <dl>
                            <dt>Gebruiksoppervlakte</dt>
                            <dd>
                                ca. {props.referentieObject.gebruiksOppervlakte}m²
                                {props.referentieObject.gebruiksOppervlakte !== null ? (
                                    <FloorAreaBadge
                                        compact
                                        floorArea={props.referentieObject.gebruiksOppervlakte}
                                        referenceFloorArea={parseInt(props.modelValuesDetails.usable_area, 10)}
                                    />
                                ) : null}
                            </dd>
                            {props.referentieObject.inhoud !== null && props.referentieObject.inhoud > 0 ? (
                                <>
                                    <dt>Bruto inhoud</dt>
                                    <dd>
                                        ca. {props.referentieObject.inhoud}m³
                                        <VolumeBadge
                                            compact
                                            volume={props.referentieObject.inhoud}
                                            referenceVolume={parseInt(props.modelValuesDetails.gross_contents, 10)}
                                        />
                                    </dd>
                                </>
                            ) : null}
                            <dt>Verkoopprijs</dt>
                            <dd>{formatMoney(props.referentieObject.verkoopprijs)}</dd>
                            <dt>Verkoopdatum</dt>
                            <dd>{formatDate(props.referentieObject.verkoopdatum)}</dd>
                        </dl>
                    </div>
                    <div className="col-6 text-right">
                        <dl>
                            {props.referentieObject.perceelOppervlakte !== null ? (
                                <>
                                    <dt>Perceeloppervlakte</dt>
                                    <dd>
                                        {props.referentieObject.perceelOppervlakte}m²
                                        <PlotAreaBadge
                                            compact
                                            plotArea={props.referentieObject.perceelOppervlakte}
                                            referencePlotArea={parseInt(props.modelValuesDetails.lot_area, 10)}
                                        />
                                    </dd>
                                </>
                            ) : null}
                            <dt>Bouwjaar</dt>
                            <dd>
                                {props.referentieObject.bouwjaar}
                                {props.referentieObject.bouwjaar !== null ? (
                                    <BuildYearBadge
                                        compact
                                        buildYear={props.referentieObject.bouwjaar}
                                        referenceBuildYear={parseInt(props.modelValuesDetails.build_year, 10)}
                                    />
                                ) : null}
                            </dd>
                            <dt>Gecorrigeerde Verkoopprijs</dt>
                            <dd>{formatMoney(props.referentieObject.gecorrigeerdeVerkoopprijs)}</dd>
                            <dt>Woningtype</dt>
                            <dd>
                                {props.referentieObject.woningType.charAt(0).toUpperCase() +
                                    props.referentieObject.woningType.slice(1)}
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
            <div
                className={
                    props.referentieObject._selected
                        ? 'form-grid-item-footer d-flex justify-content-between'
                        : 'form-grid-item-footer d-flex justify-content-end'
                }
            >
                {props.referentieObject._selected ? (
                    <a className="ion-md-trash" onClick={() => presenter.onDeleteClick()}>
                        Verwijderen
                    </a>
                ) : (
                    <a className="ion-md-add-circle" onClick={() => presenter.onAddClick()}>
                        Toevoegen
                    </a>
                )}
                {props.referentieObject._selected ? (
                    <a
                        className="ion-md-create"
                        onClick={() => presenter.showDetailsOfReferentieObject(props.referentieObject)}
                    >
                        Bewerk
                    </a>
                ) : null}
            </div>
            {renderModal()}
        </div>
    );
});
