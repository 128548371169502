import {AdaptedDefaultValue} from '../../models/adapted_default_value';

export interface ApiAdaptedDefaultValue {
    question_uuid: string;
    content: string;
}

export function apiAdaptedDefaultValueToAdaptedDefaultValue(
    apiAdaptedDefaultValue: ApiAdaptedDefaultValue
): AdaptedDefaultValue {
    return {
        questionUuid: apiAdaptedDefaultValue.question_uuid,
        content: apiAdaptedDefaultValue.content,
    };
}
