import * as React from 'react';

import {Appraisal} from '../../../../../../../../models/appraisal';
import {AreaConclusion} from '../../../../../../../../models/area_conclusion';
import {FloorAreaBadge} from '../../../../../../../../components/badges/floor_area_badge';
import {QuestionSet} from '../../../../../../../../models/question_set';

interface OwnProps {
    compact?: boolean;
    floorArea: number;
    areaConclusion: AreaConclusion;
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export class FloorAreaBadgeAdapter extends React.Component<OwnProps> {
    public render() {
        return (
            <FloorAreaBadge
                compact={this.props.compact}
                floorArea={this.props.floorArea}
                referenceFloorArea={this.props.areaConclusion.gebruiksoppervlakteWonen}
            />
        );
    }
}
