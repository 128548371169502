import * as React from 'react';

import {Loader} from '../../../../../components/loader';
import {classNames} from '../../../../../../../../support/classnames';
import {fileFromCanvas} from '../internal/file_from_canvas';
import {observer} from 'mobx-react';
import {EditableImageHelper} from './editable_image_helper';
import {Answer} from '../../../../../../../models/answer';
import {usePresenter} from '../../../../../../../../support/presenter/use_presenter';
import {EditableImageModalPresenter} from './editable_image_modal_presenter';
import {Modal} from '../../../components/modal';
import {Appraisal} from '../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../models/question_set';

export interface EditableImageModalProps {
    answer: Answer;
    questionSet: QuestionSet;
    appraisal: Appraisal;
    fileTypes: string[];
    isEditable: boolean;
    onClose: () => void;
}

export const EditableImageModal: React.FC<EditableImageModalProps> = observer((props) => {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const helper = React.useMemo(() => new EditableImageHelper(), []);

    const presenter = usePresenter(
        (component) =>
            new EditableImageModalPresenter(
                props.answer,
                props.fileTypes,
                component.business.blobCacheInteractor(),
                component.business.attachmentUploadInteractor(props.appraisal, props.questionSet),
                component.business.flashMessageBroadcaster
            )
    );

    const {answer, url, uncompressedUrl} = presenter;

    React.useEffect(() => {
        if (url === null && uncompressedUrl === null) {
            return;
        }

        helper.canvas = canvasRef.current;
        helper.load((uncompressedUrl ?? url) as string);

        return () => {
            helper.destroy();
        };
    }, [helper, url, uncompressedUrl]);

    const rotate = React.useCallback(async () => {
        await helper.rotate();
    }, [helper]);

    const persist = React.useCallback(async () => {
        if (canvasRef.current) {
            const file = await fileFromCanvas(canvasRef.current, answer.file ? answer.file.originalFilename : '');
            await presenter.persist(file);
            props.onClose();
        }
    }, [canvasRef, presenter, answer, props.onClose]);

    if (url === null && uncompressedUrl === null) {
        return null;
    }

    return (
        <Modal
            visible={true}
            forced={true}
            className="editable-image-modal"
            backdropClassName="editable-image-modal-backdrop"
        >
            <div className="photo-container">
                <div className="editable-image-container">
                    {props.isEditable && helper.loadedEmptyImage === false && (
                        <button
                            disabled={presenter.isBusy || !props.isEditable || helper.loaded === false}
                            className={'editable-image-container-btn'}
                            onClick={(e) => {
                                e.stopPropagation();
                                rotate();
                            }}
                        >
                            <span className="editable-image-container-btn-icon ion-ios-refresh"></span>
                        </button>
                    )}
                    <canvas
                        ref={canvasRef}
                        className={classNames('photo', {opaque: presenter.isBusy || helper.loadedEmptyImage})}
                    >
                        Your browser does not support the HTML canvas tag.
                    </canvas>
                    {(presenter.isBusy || helper.loaded === false) && (
                        <div className="loader-container">
                            <Loader />
                        </div>
                    )}
                    {helper.loadedEmptyImage && <div className="loader-container">Fout bij het laden van de foto.</div>}
                </div>
            </div>
            <div className="modal-footer">
                <button
                    className="btn btn-secondary ion-md-close"
                    type="button"
                    disabled={presenter.isBusy}
                    onClick={() => !presenter.isBusy && props.onClose()}
                >
                    Sluiten
                </button>
                <button className="btn btn-primary ion-md-save" onClick={() => persist()}>
                    {presenter.isBusy ? <Loader /> : 'Opslaan'}
                </button>
            </div>
        </Modal>
    );
});
