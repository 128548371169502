import {QuestionExtension} from '../../models/question_extension';
import {QuestionExtensionType} from '../../enum/question_extension_type';
import {QuestionLayoutType} from '../../enum/question_layout_type';
import {TextAISource} from '../text_ai_api';
import {WidgetOpenerType} from '../../enum/widget_opener_type';
import {TechnicalReference} from '../../enum/technical_reference';

export interface ApiQuestionExtensionBase {
    type: QuestionExtensionType;
}

export interface ApiColumnGroupQuestionExtension extends ApiQuestionExtensionBase {
    type: QuestionExtensionType.COLUMN_GROUP;
    layout_type: QuestionLayoutType;
}

export interface ApiSuperMacrosQuestionExtension extends ApiQuestionExtensionBase {
    type: QuestionExtensionType.SUPER_MACROS;
    is_appraisal_copier: boolean;
}

export interface ApiTextAIQuestionExtension extends ApiQuestionExtensionBase {
    type: QuestionExtensionType.TEXT_AI;
    is_chat: boolean;
    should_auto_trigger: boolean;
    prompt: string | null;
    sources: TextAISource[];
}

export interface ApiWidgetOpenerQuestionExtension extends ApiQuestionExtensionBase {
    type: QuestionExtensionType.WIDGET_OPENER;
    opener_type: WidgetOpenerType;
}

export interface ApiPhotoRequirementQuestionExtension extends ApiQuestionExtensionBase {
    type: QuestionExtensionType.PHOTO_REQUIREMENT;
    technical_references: TechnicalReference[];
}

export type ApiQuestionExtension =
    | ApiColumnGroupQuestionExtension
    | ApiSuperMacrosQuestionExtension
    | ApiTextAIQuestionExtension
    | ApiWidgetOpenerQuestionExtension
    | ApiPhotoRequirementQuestionExtension;

export function apiQuestionExtensionToQuestionExtension(
    apiQuestionExtension: ApiQuestionExtension
): QuestionExtension | null {
    switch (apiQuestionExtension.type) {
        case QuestionExtensionType.COLUMN_GROUP:
            return {
                type: apiQuestionExtension.type,
                layoutType: apiQuestionExtension.layout_type,
            };
        case QuestionExtensionType.SUPER_MACROS:
            return {
                type: apiQuestionExtension.type,
                isAppraisalCopier: apiQuestionExtension.is_appraisal_copier,
            };
        case QuestionExtensionType.TEXT_AI:
            return {
                type: apiQuestionExtension.type,
                isChat: apiQuestionExtension.is_chat,
                shouldAutoTrigger: apiQuestionExtension.should_auto_trigger,
                prompt: apiQuestionExtension.prompt,
                sources: apiQuestionExtension.sources,
            };
        case QuestionExtensionType.WIDGET_OPENER:
            return {
                type: apiQuestionExtension.type,
                openerType: apiQuestionExtension.opener_type,
            };
        case QuestionExtensionType.PHOTO_REQUIREMENT:
            return {
                type: apiQuestionExtension.type,
                technicalReferences: apiQuestionExtension.technical_references,
            };
        default:
            return null;
    }
}
