import {action, makeObservable, observable, runInAction} from 'mobx';
import {Presenter} from '../../../../../support/presenter/presenter';
import {Appraisal} from '../../../../models/appraisal';
import {AnswerInteractor} from '../../../../business/answering/answer_interactor';
import {ComposedNotificationsApi} from '../../../../network/composed_notification_api';
import {FlashMessageBroadcaster, Type} from '../../../../business/flash_message_broadcaster';
import {ComposedNotificationType} from '../../../../enum/composed_notification_type';
import {AnswerController} from '../../../../business/answering/answer_controller';
import {debounceTime, map} from 'rxjs/operators';
import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {ChildQuestionValidator} from '../../../../business/validation/child_validator';
import {getNewestAnswer} from '../../../../../support/get_newest_answer';
import {QuestionSet} from '../../../../models/question_set';
import {DetailName} from '../../../../enum/detail_name';

export class ClientFilesPresenter implements Presenter {
    @observable public loading = false;
    @observable public finished = false;
    @observable public hasChanges = false;
    @observable public hasErrors = false;

    private subscriptions = new CompositeSubscription();

    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private childValidator: ChildQuestionValidator,
        private answerInteractor: AnswerInteractor,
        private composedNotificationApi: ComposedNotificationsApi,
        private flashMessageBroadcaster: FlashMessageBroadcaster,
        private answerController: AnswerController
    ) {
        makeObservable(this);
    }

    public mount(): void {
        const loaded = new Date();

        const rootQuestion = this.questionSet.questions.find((q) => q.detailName === DetailName.CLIENT_FILES);

        this.subscriptions.add(
            this.answerController
                .answersStream()
                .pipe(
                    debounceTime(1000),
                    map((answers) =>
                        answers.filter(
                            (answer) =>
                                answer.changed ||
                                (answer.updatedAt !== null && answer.updatedAt > loaded) ||
                                (answer.createdAt !== null && answer.createdAt > loaded)
                        )
                    )
                )
                .subscribe((unsavedAnswers) => {
                    if (!rootQuestion) return;

                    const rootAnswer = getNewestAnswer(this.answerController.answersForQuestionUuid(rootQuestion.uuid));
                    const errors = this.childValidator.validate(rootQuestion.uuid, rootAnswer?.uuid ?? null);

                    runInAction(() => {
                        this.hasChanges = unsavedAnswers.length > 0;
                        this.hasErrors = errors.length > 0;
                    });
                })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    public async onClick() {
        runInAction(() => {
            this.loading = true;
            this.finished = false;
        });

        try {
            await this.answerInteractor.submit();
            await this.composedNotificationApi.notify(
                this.appraisal.id,
                ComposedNotificationType.CLIENT_ATTACHMENTS_CHANGED
            );

            this.flashMessageBroadcaster.broadcast(
                'De bestanden zijn succesvol verstuurd naar de taxateur.',
                Type.Success
            );

            runInAction(() => {
                this.loading = false;
                this.finished = true;
            });
        } catch (e) {
            this.flashMessageBroadcaster.broadcast(
                'Er is wat mis gegaan met het versturen naar de taxateur, probeer a.u.b. opnieuw.',
                Type.Danger
            );

            runInAction(() => {
                this.loading = false;
                this.finished = true;
            });
        }
    }
}
