/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import {Adres} from '../../../../models/validation_institute_reference_object';
import {PriceRange} from '../../content/questions/advanced/reference_objects_question/v2/components/price_range';
import {ReferenceObjectTileImageSlider} from '../../content/questions/advanced/reference_objects_question/v3/components/reference_object_tile/reference_object_tile_image_slider';
import {QuestionSet} from '../../../../models/question_set';
import {Appraisal} from '../../../../models/appraisal';
import {ReferenceObject} from '../../content/questions/advanced/reference_objects_question/v3/models/reference_object';
import {ReferenceObjectAnswer} from '../../content/questions/advanced/reference_objects_question/v3/models/reference_object_answer';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {ReferenceObjectWidgetTilePresenter} from './reference_object_widget_tile_presenter';
import {referenceObjectImages} from '../../content/questions/advanced/reference_objects_question/v3/internal/reference_sale_images';

interface OwnProps {
    address: Adres;
    price: string | null;
    questionSet: QuestionSet;
    appraisal: Appraisal;
    referenceObject: ReferenceObject | null;
    referenceObjectAnswer: ReferenceObjectAnswer | null;
    children?: React.ReactNode;
}

export function ReferenceObjectWidgetTile({
    referenceObject,
    referenceObjectAnswer,
    address,
    price,
    questionSet,
    appraisal,
    children,
}: OwnProps) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectWidgetTilePresenter(
                referenceObject,
                referenceObjectAnswer,
                container.business.referenceObjectsAnswerEnhancementInteractor(appraisal, questionSet)
            )
    );

    const images = useMemo(() => {
        return referenceObjectImages(presenter.enhancedReferenceSale ?? referenceObject, referenceObjectAnswer);
    }, [referenceObject, referenceObjectAnswer, presenter.enhancedReferenceSale]);

    return (
        <div className={'col-12 col-sm-6 col-md-4 form-grid-item'}>
            <div className="form-grid-item-img">
                <ReferenceObjectTileImageSlider
                    images={images}
                    hasCheckmark={false}
                    questionSet={questionSet}
                    appraisal={appraisal}
                />
            </div>
            <div className="form-grid-item-body">
                <div className="row">
                    <div className="col-12">
                        <div className="grid-item-title">
                            <strong className="grid-item-address">
                                {address.straat}{' '}
                                {address.huisnummer +
                                    (address.huisnummerToevoeging ? '-' + address.huisnummerToevoeging : '')}
                            </strong>
                            <br />
                            <PriceRange range={price} />
                        </div>
                    </div>
                    <div className="col-12">{children}</div>
                </div>
            </div>
        </div>
    );
}
