import {CompositeSubscription} from '../../../../../../../../support/composite_subscription';
import {getNewestAnswer} from '../../../../../../../../support/get_newest_answer';
import {isEmpty} from '../../../../../../../../support/util';
import {AnswerController} from '../../../../../../../business/answering/answer_controller';
import {TechnicalReference} from '../../../../../../../enum/technical_reference';
import {Answer} from '../../../../../../../models/answer';
import {Appraisal} from '../../../../../../../models/appraisal';
import {Question} from '../../../../../../../models/question';
import {QuestionSet} from '../../../../../../../models/question_set';
import {ReferenceObjectApi} from '../../../../../../../network/reference_objects_api';
import {ReferenceObjectAnswer} from '../../reference_objects_question/v3/models/reference_object_answer';
import {IndexSource} from '../index_source';

export class Referencesv3IndexSource implements IndexSource {
    private subscriptions = new CompositeSubscription();

    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private question: Question,
        private answerController: AnswerController,
        private referenceObjectApi: ReferenceObjectApi
    ) {}

    public enable(answer: Answer, onIndexableChange: (isIndexable: boolean) => void) {
        const questionsAnswers = this.getQuestionsAnswers(answer);
        if (questionsAnswers === null) {
            return;
        }

        const {salePriceQuestion, salePriceAnswer, saleDateQuestion, saleDateAnswer} = questionsAnswers;

        const update = () => {
            const newestQuestionAnswers = this.getQuestionsAnswers(answer);

            onIndexableChange(
                newestQuestionAnswers !== null &&
                    !isEmpty(newestQuestionAnswers.salePriceAnswer.contents) &&
                    !isEmpty(newestQuestionAnswers.saleDateAnswer.contents)
            );
        };

        this.subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(
                    salePriceQuestion.uuid,
                    salePriceAnswer.parentUuid,
                    salePriceAnswer.iteration
                )
                .subscribe(() => {
                    update();
                })
        );

        this.subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(saleDateQuestion.uuid, saleDateAnswer.parentUuid, saleDateAnswer.iteration)
                .subscribe(() => {
                    update();
                })
        );
    }

    public disable() {
        this.subscriptions.clear();
    }

    public async index(answer: Answer): Promise<number | null> {
        const questionsAnswers = this.getQuestionsAnswers(answer);

        if (!questionsAnswers) {
            return null;
        }

        const {salePriceAnswer, saleDateAnswer, referenceObjectAnswerContents} = questionsAnswers;

        if (!salePriceAnswer.contents || !saleDateAnswer.contents) {
            return null;
        }

        return await this.referenceObjectApi.indexPrice(
            this.appraisal.id,
            Number(salePriceAnswer.contents),
            referenceObjectAnswerContents.referenceObject.adres.postcode,
            saleDateAnswer.contents
        );
    }

    private getQuestionsAnswers(answer: Answer) {
        const iteratorGroupQuestion = this.questionSet.findParentByPredicateRecursive(
            this.question,
            (q) => q.technicalReference === TechnicalReference.REFERENCE_OBJECTS_V3_ITERATOR_GROUP
        );

        if (!iteratorGroupQuestion) {
            return null;
        }

        const salePriceQuestion = this.questionSet.findChildByPredicateRecursive(
            iteratorGroupQuestion,
            (q) => q.technicalReference === TechnicalReference.REFERENCE_OBJECT_VERKOOPPRIJS
        );
        const correctedSalePriceQuestion = this.questionSet.findChildByPredicateRecursive(
            iteratorGroupQuestion,
            (q) => q.technicalReference === TechnicalReference.REFERENCE_OBJECT_GECORRIGEERDE_VERKOOPPRIJS
        );
        const saleDateQuestion = this.questionSet.findChildByPredicateRecursive(
            iteratorGroupQuestion,
            (q) => q.technicalReference === TechnicalReference.REFERENCE_OBJECT_VERKOOPDATUM
        );

        if (!salePriceQuestion || !correctedSalePriceQuestion || !saleDateQuestion) {
            return null;
        }

        const referenceObjectAnswer = getNewestAnswer(
            this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIteration(
                iteratorGroupQuestion.uuid,
                answer.uuid
            ) ?? []
        );

        const salePriceAnswer = getNewestAnswer(
            this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIteration(
                salePriceQuestion.uuid,
                answer.uuid
            ) ?? []
        );
        const correctedSalePriceAnswer = getNewestAnswer(
            this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIteration(
                correctedSalePriceQuestion.uuid,
                answer.uuid
            ) ?? []
        );
        const saleDateAnswer = getNewestAnswer(
            this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIteration(
                saleDateQuestion.uuid,
                answer.uuid
            ) ?? []
        );

        if (!referenceObjectAnswer?.contents || !salePriceAnswer || !correctedSalePriceAnswer || !saleDateAnswer) {
            return null;
        }

        const referenceObjectAnswerContents: ReferenceObjectAnswer = JSON.parse(referenceObjectAnswer.contents);

        return {
            salePriceQuestion,
            correctedSalePriceQuestion,
            saleDateQuestion,
            salePriceAnswer,
            correctedSalePriceAnswer,
            saleDateAnswer,
            referenceObjectAnswerContents,
        };
    }
}
