import * as React from 'react';

import {AnswerAuditTrailItem} from '../../../../../models/answer_audit_trail_item';
import {AnswerTrailList} from '../../../components/answer_trail_list';
import {Modal} from '../../questions/components/modal';
import {QuestionType} from '../../../../../enum/question_type';

export interface AnswerTrailModalProps {
    auditTrailItems: AnswerAuditTrailItem[][];
    isLoading: boolean;
    isDisabled: boolean;
    questionType: QuestionType;
    onClose: () => void;
    onHistoricAnswerClick: (items: AnswerAuditTrailItem[]) => void;
    getAnswerContents: (item: AnswerAuditTrailItem) => string | null;
}

export class AnswerTrailModal extends React.Component<AnswerTrailModalProps> {
    public render() {
        return (
            <Modal visible onHide={() => this.props.onClose()}>
                <div className="modal-header">
                    <h2>Antwoord geschiedenis</h2>
                </div>
                <div className="modal-body">
                    <p className="text-center">
                        Overzicht van antwoorden. Selecteer een antwoord om deze te herstellen.
                    </p>
                    <AnswerTrailList
                        auditTrailItems={this.props.auditTrailItems}
                        onHistoricAnswerClick={this.props.onHistoricAnswerClick}
                        isLoading={this.props.isLoading}
                        isDisabled={this.props.isDisabled}
                        questionType={this.props.questionType}
                        getAnswerContents={this.props.getAnswerContents}
                    />
                </div>
                <div className="modal-footer">
                    <button
                        className="btn btn-primary btn-full ion-md-close"
                        type="button"
                        onClick={() => this.props.onClose()}
                    >
                        Sluiten
                    </button>
                </div>
            </Modal>
        );
    }
}
