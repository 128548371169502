import {makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../support/composite_subscription';
import {NotificationMessage} from '../models/notification_message';
import {NotificationsInteractor} from '../interactors/notifications_interactor';
import {Presenter} from '../support/presenter';

export class NotificationModalPresenter implements Presenter {
    @observable.ref public notification: NotificationMessage | null = null;

    private subscriptions = new CompositeSubscription();
    constructor(private notificationsInteractor: NotificationsInteractor) {
        makeObservable(this);
    }

    public mount() {
        this.subscriptions.add(
            this.notificationsInteractor.modalStream().subscribe((notification) => {
                runInAction(() => {
                    this.notification = notification;
                });
            })
        );
    }

    public unmount() {
        this.subscriptions.clear();
    }

    public onShow(notification: NotificationMessage) {
        this.notificationsInteractor.onShowModal(notification.id);
    }

    public async onHide(notification: NotificationMessage) {
        await this.notificationsInteractor.onReadModal(notification);
        this.notificationsInteractor.onHideModal();
    }

    public onShowAppraisal(notification: NotificationMessage) {
        if (notification.appraisalId !== null) {
            window.location.href = '/appraisals/' + notification.appraisalId + '/show-or-consider';
        }
    }
}
