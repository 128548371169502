import * as React from 'react';
import {createPortal} from 'react-dom';

import {
    Placement,
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useClick,
    useDismiss,
    useRole,
    useInteractions,
} from '@floating-ui/react';

interface OwnProps {
    className?: string;
    placement?: Placement;
    toggle: React.ReactNode;
    popper: React.ReactNode;
    popperClassName?: string;
    isPopperOpen: boolean;
    setIsPopperOpen: (isOpen: boolean) => void;
    popperOffset?: [number, number];
}

export const Popper: React.FunctionComponent<OwnProps> = ({
    className,
    placement,
    toggle,
    popper,
    setIsPopperOpen,
    isPopperOpen,
    popperClassName,
    popperOffset,
}) => {
    const {
        refs,
        floatingStyles,
        context,
        placement: definitivePlacement,
    } = useFloating({
        placement: placement,
        open: isPopperOpen,
        onOpenChange: setIsPopperOpen,
        middleware: popperOffset
            ? [offset({mainAxis: popperOffset[1], crossAxis: popperOffset[0]}), flip(), shift()]
            : [flip(), shift()],
        whileElementsMounted: autoUpdate,
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);

    const {getReferenceProps, getFloatingProps} = useInteractions([click, dismiss, role]);

    const portalTarget = document.getElementById('popper-portal');

    return (
        <div className={className}>
            <div ref={refs.setReference} {...getReferenceProps()}>
                {toggle}
            </div>
            {isPopperOpen &&
                (portalTarget ? (
                    createPortal(
                        <div
                            style={floatingStyles}
                            className={popperClassName}
                            {...getFloatingProps()}
                            ref={refs.setFloating}
                            role="dialog"
                            data-popper-placement={definitivePlacement}
                        >
                            {popper}
                        </div>,
                        portalTarget
                    )
                ) : (
                    <div
                        style={floatingStyles}
                        className={popperClassName}
                        {...getFloatingProps()}
                        ref={refs.setFloating}
                        role="dialog"
                        data-popper-placement={definitivePlacement}
                    >
                        {popper}
                    </div>
                ))}
        </div>
    );
};
