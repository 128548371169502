import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {PagePartConfiguration} from './page_part_configuration';
import {PagePartCustomization} from './page_part_customization';

export interface CustomizationsSet {
    id: number;
    name: string | null;
    config: PagePartConfiguration;
    customizations: PagePartCustomization[];

    getByQuestionUuid<T = unknown>(uuid: string): T | null;
    streamByQuestionUuid<T = unknown>(uuid: string): Observable<T | null>;

    setForQuestionUuid<T = unknown>(uuid: string, data: T): void;
}

export class DefaultCustomizationsSet implements CustomizationsSet {
    private customizationsByQuestionUuidMap: Map<string, PagePartCustomization>;
    private customizationsSubject: BehaviorSubject<Map<string, PagePartCustomization>>;

    constructor(private pagePartConfiguration: PagePartConfiguration) {
        this.customizationsByQuestionUuidMap = this.createCustomizationsMap(this.customizations);
        this.customizationsSubject = new BehaviorSubject(this.customizationsByQuestionUuidMap);
    }

    public get id() {
        return this.pagePartConfiguration.id;
    }

    public get name() {
        return this.pagePartConfiguration.name;
    }

    public get config() {
        return this.pagePartConfiguration;
    }

    public get customizations(): PagePartCustomization[] {
        return this.pagePartConfiguration.customizations;
    }

    private createCustomizationsMap(customizations: PagePartCustomization[]): Map<string, PagePartCustomization> {
        const result = new Map<string, PagePartCustomization>();
        for (const c of customizations) {
            result.set(c.questionUuid, c);
        }
        return result;
    }

    public getByQuestionUuid<T = unknown>(uuid: string): T | null {
        return (this.customizationsByQuestionUuidMap.get(uuid)?.data as T) ?? null;
    }

    public streamByQuestionUuid<T = unknown>(uuid: string): Observable<T | null> {
        return this.customizationsSubject.asObservable().pipe(
            map((map) => (map.get(uuid)?.data as T) ?? null),
            distinctUntilChanged()
        );
    }

    public setForQuestionUuid<T = unknown>(uuid: string, data: T | null): void {
        if (this.customizationsByQuestionUuidMap === null) {
            this.customizationsByQuestionUuidMap = this.createCustomizationsMap(this.customizations);
        }
        const customization = this.customizationsByQuestionUuidMap.get(uuid);
        if (customization && data !== null) {
            // Update existing
            customization.data = data;
        } else if (customization && data === null) {
            // Delete existing
            this.customizationsByQuestionUuidMap.delete(uuid);
            this.pagePartConfiguration.customizations = this.pagePartConfiguration.customizations.filter(
                (c) => c.questionUuid !== uuid
            );
        } else if (customization === undefined && data !== null) {
            // Create new
            const customization = {
                pagePartConfigurationId: this.id,
                questionUuid: uuid,
                data,
            };

            this.customizationsByQuestionUuidMap.set(uuid, customization);
            this.pagePartConfiguration.customizations.push(customization);
        }

        this.customizationsSubject.next(this.customizationsByQuestionUuidMap);
    }
}
