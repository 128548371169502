import * as React from 'react';

import {isEmpty} from '../../../../../../../../../support/util';

interface OwnProps {
    range: string | null;
    break?: boolean;
}

export class PriceRange extends React.Component<OwnProps> {
    private formatPrice(price: number) {
        return `€ ${price.toLocaleString('nl-NL')}`;
    }

    public render() {
        if (this.props.range === null || isEmpty(this.props.range.trim())) {
            return <>{'-'}</>;
        }
        const [fromStr, tillStr] = this.props.range.split(' - ');

        const from = parseInt(fromStr, 10);
        const till = tillStr ? parseInt(tillStr, 10) : null;

        if (!till || Number.isNaN(till)) {
            return <span>{this.formatPrice(from)}</span>;
        }

        if (this.props.break === true) {
            return (
                <>
                    <span key="from">{this.formatPrice(from)}</span>
                    <br />
                    <span key="till">{this.formatPrice(till)}</span>
                </>
            );
        }

        return (
            <>
                <span key="from">{this.formatPrice(from)} - </span>
                <span key="till">{this.formatPrice(till)}</span>
            </>
        );
    }
}
