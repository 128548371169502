import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {NetworkStatus} from '../../../../business/network_status_provider';
import {NotificationPillPresenter} from './notification_pill_presenter';
import {QuestionSet} from '../../../../models/question_set';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {UnsyncedType} from '../../../../business/photo_answer_retry_interactor';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export const NotificationPill: React.FC<OwnProps> = observer(function NotificationPill(props) {
    const presenter = usePresenter(
        (container) =>
            new NotificationPillPresenter(
                container.business.photoAnswerRetryInteractor(props.appraisal, props.questionSet),
                container.business.networkStatusProvider
            )
    );

    const unsyncedState = presenter.unsyncedState;
    if (unsyncedState && unsyncedState.type !== UnsyncedType.IDLE) {
        if (unsyncedState.missing.length > 0) {
            return (
                <span className="badge badge-pill badge-danger ml-2">
                    {unsyncedState.missing.length} {unsyncedState.missing.length === 1 ? 'foto' : "foto's"} niet
                    gevonden op dit apparaat.
                </span>
            );
        } else {
            return (
                <span className="badge badge-pill badge-warning ml-2">
                    {unsyncedState.unsynced.length} {unsyncedState.unsynced.length === 1 ? 'foto' : "foto's"} nog niet
                    gesynct
                </span>
            );
        }
    }

    if (presenter.networkStatus === NetworkStatus.OFFLINE) {
        return <span className="badge badge-pill badge-secondary ml-2">Offline</span>;
    }
    return null;
});
