import React from 'react';

import {classNames} from '../../../support/classnames';
import {BadgeContext} from '../../enum/badge_context';
import {diffToBadgeContext} from './badge_context_calculators';

interface OwnProps {
    compact?: boolean;
    volumePrice: number;
    referenceSetVolumePrice: number;
}

const formatPrice = (from: number): string => {
    return `€ ${from.toLocaleString('nl-NL')}/m³`;
};

export function VolumePriceBadge(props: OwnProps) {
    let badge: BadgeContext | null = null;
    let diff: number | null = null;

    if (
        props.referenceSetVolumePrice !== null &&
        props.referenceSetVolumePrice !== 0 &&
        Math.abs(props.volumePrice) !== 0
    ) {
        diff = (100 * (props.volumePrice - props.referenceSetVolumePrice)) / props.referenceSetVolumePrice;
        badge = diffToBadgeContext(diff);
    }

    if (props.referenceSetVolumePrice === null || props.referenceSetVolumePrice === 0) {
        return null;
    }

    // Check for absolute value to avoid "-0%"
    const fixedValue = Math.abs(diff ?? 0).toFixed(0) === '0' ? '0' : diff?.toFixed(2);

    return (
        <span className="badge-container">
            {badge && <span className={classNames('badge', badge)}>{fixedValue}</span>}
            {!props.compact ? `(${formatPrice(props.referenceSetVolumePrice)})` : null}
        </span>
    );
}
