export enum BuildPeriodType {
    BELOW_1906 = '1906',
    BETWEEN_1906_1930 = '1906-1930',
    BETWEEN_1931_1944 = '1931-1944',
    BETWEEN_1945_1959 = '1945-1959',
    BETWEEN_1960_1970 = '1960-1970',
    BETWEEN_1971_1980 = '1971-1980',
    BETWEEN_1981_1990 = '1981-1990',
    BETWEEN_1991_2000 = '1991-2000',
    BETWEEN_2001_2010 = '2001-2010',
    BETWEEN_2011_2020 = '2011-2020',
    AFTER_2020 = '2020',
}

export function getBuildPeriodRange(type: BuildPeriodType): number[] {
    switch (type) {
        case BuildPeriodType.BELOW_1906:
            return [0, 1906];
        case BuildPeriodType.BETWEEN_1906_1930:
            return [1906, 1930];
        case BuildPeriodType.BETWEEN_1931_1944:
            return [1931, 1944];
        case BuildPeriodType.BETWEEN_1945_1959:
            return [1945, 1959];
        case BuildPeriodType.BETWEEN_1960_1970:
            return [1960, 1970];
        case BuildPeriodType.BETWEEN_1971_1980:
            return [1971, 1980];
        case BuildPeriodType.BETWEEN_1981_1990:
            return [1981, 1990];
        case BuildPeriodType.BETWEEN_1991_2000:
            return [1991, 2000];
        case BuildPeriodType.BETWEEN_2001_2010:
            return [2001, 2010];
        case BuildPeriodType.BETWEEN_2011_2020:
            return [2011, 2020];
        case BuildPeriodType.AFTER_2020:
            return [2020, 2020];
    }
}
