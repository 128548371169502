import * as React from 'react';

import {IteratorQuestionType, NormalQuestionType, QuestionType} from '../../../../enum/question_type';

import {Appraisal} from '../../../../models/appraisal';
import {ConstructionDefectsWidgetContext} from './construction_defects_widget_context';
import {ConstructionalDefectsPresenter} from './constructional_defects_presenter';
import {ConstructionalDefectsQuestion} from './content/constructional_defects_question';
import {ExternalAdviseBuildingInspector} from './content/external_advise_building_inspector';
import {PagePart} from '../../../../models/page_part';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {Question} from '../../../../models/question';
import {QuestionContainer} from '../../content/questions/question_container';
import {QuestionSet} from '../../../../models/question_set';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {ValidationError} from '../../../../models/validation_error';
import {ValidationInstituteConstructionalDefect} from '../../../../models/validation_institute_constructional_defect';
import {ValidationMessageMap} from '../../../../business/validation/validation_message';
import {observer} from 'mobx-react';
import {DetailName} from '../../../../enum/detail_name';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {QuestionRenderingData} from '../../../../models/question_rendering_data';

export interface ConstructionalDefectsProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    onClose: () => void;
    question: Question;
    parentAnswerUuid: string;
    validationInstituteConstructionalDefect?: ValidationInstituteConstructionalDefect;
    constructionDefectLabel?: string;
    validationErrors?: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    constructionDefectPhotoAnswerUuid?: string;
    constructionCostsWidgetContext: ConstructionDefectsWidgetContext;
    onChangeConstructionCostsWidgetContext: (c: ConstructionDefectsWidgetContext) => void;
    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const ConstructionalDefects: React.FunctionComponent<ConstructionalDefectsProps> = observer((props) => {
    const presenter = usePresenter(
        (container) =>
            new ConstructionalDefectsPresenter(
                props.question,
                props.questionSet,
                container.network.constructionCostsSummaryApi,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.questionEffectsInteractor(props.appraisal, props.questionSet),
                props.parentAnswerUuid,
                container.globalProvider,
                props.validationInstituteConstructionalDefect,
                props.constructionDefectLabel,
                props.constructionDefectPhotoAnswerUuid
            )
    );
    const question = {...props.question, type: NormalQuestionType.GROUP_COMPACT};

    function renderConstructionalDefects(question: Question, disabled: boolean) {
        const hasPhotoIterator =
            props.questionSet.findChildByPredicateRecursive(
                props.question,
                (q) => q.type === IteratorQuestionType.PHOTO_ITERATOR_SMALL
            ) !== null;

        const isBuildingInspectorsDetail = presenter.global.detailName === DetailName.ADVICE_BUILDING_COSTS;

        const hiddenQuestionTypes: QuestionType[] = [];

        if (!isBuildingInspectorsDetail) {
            hiddenQuestionTypes.push(IteratorQuestionType.PHOTO_ITERATOR_SMALL);
        } else if (hasPhotoIterator) {
            hiddenQuestionTypes.push(NormalQuestionType.BUILDING_COSTS_PHOTO);
        }

        return (
            <div className="constructional-defects">
                <QuestionContainer
                    key={question.uuid + '-defect'}
                    appraisal={props.appraisal}
                    question={question}
                    questionSet={props.questionSet}
                    parentAnswerUuid={props.parentAnswerUuid}
                    validationErrors={props.validationErrors ?? []}
                    validationMessages={props.validationMessages}
                    disabled={disabled}
                    hiddenQuestionTypes={hiddenQuestionTypes}
                    pagePartsSet={props.pagePartsSet}
                    activePagePart={props.activePagePart}
                    renderingContext={props.renderingContext}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    questionRenderingData={props.questionRenderingData}
                />
                {presenter.showConstructionCosts && (
                    <>
                        {!presenter.isLoading && presenter.initialValidationInstituteConstructionalDefect ? (
                            <ConstructionalDefectsQuestion
                                key={props.question.uuid}
                                question={props.question}
                                parentAnswerUuid={props.parentAnswerUuid}
                                constructionCosts={presenter.constructionCosts}
                                appraisal={props.appraisal}
                                questionSet={props.questionSet}
                                initialValidationInstituteConstructionalDefect={
                                    presenter.initialValidationInstituteConstructionalDefect
                                }
                                constructionCostsWidgetContext={props.constructionCostsWidgetContext}
                                onClose={() => props.onClose()}
                                onChangeConstructionCostsWidgetContext={(c: ConstructionDefectsWidgetContext) =>
                                    props.onChangeConstructionCostsWidgetContext(c)
                                }
                            />
                        ) : (
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h3>Lijst van mogelijke kosten aan het ophalen...</h3>
                                </div>
                            </div>
                        )}
                    </>
                )}
                <span className="spacer-sm">&nbsp;</span>
                <ExternalAdviseBuildingInspector
                    appraisal={props.appraisal}
                    parentQuestion={props.question}
                    answerUuid={props.parentAnswerUuid}
                    question={question}
                    questionSet={props.questionSet}
                    validationErrors={props.validationErrors ?? []}
                    validationMessages={props.validationMessages}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    constructionCostsWidgetContext={props.constructionCostsWidgetContext}
                    onChangeConstructionCostsWidgetContext={(c: ConstructionDefectsWidgetContext) =>
                        props.onChangeConstructionCostsWidgetContext(c)
                    }
                    pagePartsSet={props.pagePartsSet}
                    activePagePart={props.activePagePart}
                    renderingContext={props.renderingContext}
                    questionRenderingData={props.questionRenderingData}
                />
            </div>
        );
    }

    function renderConstructionalDefectsNotes(question: Question) {
        return (
            <div className="constructional-defects constructional-defects-notes">
                {renderConstructionalDefectsQuestion(question, false, [
                    NormalQuestionType.BUILDING_COSTS_PHOTO,
                    IteratorQuestionType.PHOTO_ITERATOR_SMALL,
                ])}
            </div>
        );
    }

    function renderConstructionalDefectsQuestion(
        question: Question,
        disabled: boolean,
        hiddenQuestionTypes: QuestionType[] = []
    ) {
        return (
            <QuestionContainer
                key={question.uuid + '-defect'}
                appraisal={props.appraisal}
                question={question}
                questionSet={props.questionSet}
                parentAnswerUuid={props.parentAnswerUuid}
                validationErrors={props.validationErrors ?? []}
                validationMessages={props.validationMessages}
                forceShowValidationMessages={props.forceShowValidationMessages}
                disabled={disabled}
                hiddenQuestionTypes={hiddenQuestionTypes}
                pagePartsSet={props.pagePartsSet}
                activePagePart={props.activePagePart}
                renderingContext={props.renderingContext}
                questionRenderingData={props.questionRenderingData}
            />
        );
    }

    function renderExternalAdviceRequest(question: Question) {
        return (
            <div className="constructional-defects">
                <ExternalAdviseBuildingInspector
                    appraisal={props.appraisal}
                    parentQuestion={props.question}
                    question={question}
                    questionSet={props.questionSet}
                    answerUuid={props.parentAnswerUuid}
                    constructionCostsWidgetContext={props.constructionCostsWidgetContext}
                    validationErrors={props.validationErrors ?? []}
                    validationMessages={props.validationMessages}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    onChangeConstructionCostsWidgetContext={(c: ConstructionDefectsWidgetContext) =>
                        props.onChangeConstructionCostsWidgetContext(c)
                    }
                    pagePartsSet={props.pagePartsSet}
                    activePagePart={props.activePagePart}
                    renderingContext={props.renderingContext}
                    questionRenderingData={props.questionRenderingData}
                />
            </div>
        );
    }

    switch (props.constructionCostsWidgetContext) {
        case ConstructionDefectsWidgetContext.ADVICE:
        case ConstructionDefectsWidgetContext.REQUEST_DEFECT_WIDGET:
        case ConstructionDefectsWidgetContext.NEW_REQUEST_DEFECT_WIDGET: {
            const isQuestionDisabled = props.constructionCostsWidgetContext === ConstructionDefectsWidgetContext.ADVICE;
            return renderConstructionalDefects(question, isQuestionDisabled);
        }
        case ConstructionDefectsWidgetContext.NOTES:
            return renderConstructionalDefectsNotes(question);

        case ConstructionDefectsWidgetContext.NEW_REQUEST:
            return renderExternalAdviceRequest(question);
    }
});
