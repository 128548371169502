import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../../../../../../../../support/composite_subscription';

import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {MacroInteractor} from '../../../../../../../../../business/macro_interactor';
import {ReferenceObjectsMetadataProvider} from '../../../../../../../../../business/reference_objects_metadata/reference_objects_metadata_provider';
import {ReferenceObjectProvider} from '../../../../../../../../../business/reference_object_provider';
import {ReferenceObjectsMetadataVersion} from '../../../../../../../../../enum/reference_objects_metadata_version';
import {Macro, MacroExternalType} from '../../../../../../../../../models/macro';
import {ReferenceObjectsV3Metadata} from '../../../../../../../../../models/reference_objects_metadata';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';
import {Sale} from '../../../../../../../../../models/sale';
import {ReferenceObject} from '../../models/reference_object';
import {SetDefinition} from '../../reference_objects_question_presenter';
import {ReferenceObjectsAnswerEnhancementInteractor} from '../../../interactors/reference_objects_answer_enhancement_interactor';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {ReferenceSale} from '../../../v1/models/reference_sale';

export class ReferenceObjectDetailsModalPresenter implements Presenter {
    @observable public isAdding = false;
    @observable public metadata: ReferenceObjectsV3Metadata | null = null;
    @observable public rejectMacros: Macro[] | null = null;
    @observable public rejectReason: string | null = null;
    @observable public enhancedReferenceSale: ReferenceSale | null = null;

    private subscriptions = new CompositeSubscription();

    constructor(
        private referenceObjectId: string,
        private referenceObjectSource: string | null,
        rejectReason: string | null,
        private setDefinition: SetDefinition | null,
        private propsOnAdd: null | ((sale: Sale) => Promise<void>),
        private propsOnHide: null | (() => void),
        private referenceObject: ReferenceObject | null,
        private referenceObjectAnswer: ReferenceObjectAnswer | null,
        private referenceObjectInteractor: ReferenceObjectProvider,
        private referenceObjectsMetadataProvider: ReferenceObjectsMetadataProvider,
        private macroInteractor: MacroInteractor,
        private referenceObjectsAnswerEnhancementInteractor: ReferenceObjectsAnswerEnhancementInteractor
    ) {
        makeObservable(this);

        this.rejectReason = rejectReason;
    }

    public mount(): void {
        if (this.setDefinition !== null && this.setDefinition.groupTree.item.answer !== null) {
            this.subscriptions.add(
                this.referenceObjectsMetadataProvider
                    .getMetadataByAnswerUuidStream(
                        this.setDefinition.groupTree.item.question.uuid,
                        this.setDefinition.groupTree.item.answer.uuid
                    )
                    .subscribe((metadata) => {
                        runInAction(() => {
                            if (metadata === null || metadata.version !== ReferenceObjectsMetadataVersion.V3) {
                                this.metadata = null;
                                return;
                            }

                            this.metadata = metadata;
                        });
                    })
            );
        }

        this.subscriptions.add(
            this.macroInteractor
                .macrosForExternalType(MacroExternalType.REFEFENCES_REJECT_REASON)
                .subscribe((macros) => {
                    runInAction(() => {
                        this.rejectMacros = macros;
                    });
                })
        );

        if (this.referenceObjectAnswer !== null && this.referenceObject === null) {
            this.subscriptions.add(
                this.referenceObjectsAnswerEnhancementInteractor
                    .streamForAnswer(this.referenceObjectAnswer)
                    .subscribe((enhancedObject) => {
                        runInAction(() => {
                            this.enhancedReferenceSale = enhancedObject;
                        });
                    })
            );
        }
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @computed
    public get isRejected(): boolean {
        if (this.metadata === null || this.referenceObject === null) {
            return false;
        }
        return this.metadata.rejectedObjects.some(
            (rejectedObject) =>
                rejectedObject.id === this.referenceObjectId && rejectedObject.source === this.referenceObjectSource
        );
    }

    public async onAdd() {
        if (this.propsOnAdd === null) {
            return;
        }

        try {
            runInAction(() => {
                this.isAdding = true;
            });
            const sale = await this.referenceObjectInteractor.getSale(
                this.referenceObjectId,
                this.setDefinition?.type ?? SetType.SOLD
            );
            await this.propsOnAdd(sale);
        } finally {
            runInAction(() => {
                this.isAdding = false;
            });
        }
    }

    @action
    public onRejectReasonChange(rejectReason: string | null) {
        this.rejectReason = rejectReason;
    }

    public onSave() {
        if (this.setDefinition === null || this.setDefinition.groupTree.item.answer === null) {
            return;
        }

        for (const pair of this.referenceObjectsMetadataProvider
            .getAllMetadataByAnswerUuid(
                this.setDefinition.groupTree.item.question.uuid,
                this.setDefinition.groupTree.item.answer.uuid
            )
            .reverse()) {
            const object = pair.metadata.rejectedObjects.find(
                (rejectedObject) =>
                    rejectedObject.id === this.referenceObjectId && rejectedObject.source === this.referenceObjectSource
            );

            if (object === undefined) {
                continue;
            }

            if (object.rejectReason !== this.rejectReason) {
                object.rejectReason = this.rejectReason;
                this.referenceObjectsMetadataProvider.storeMetadata(pair);
            }

            this.propsOnHide?.();
            return;
        }
    }

    public onAddAsRejectMacroClick = async () => {
        if (this.rejectReason !== '' && this.rejectReason !== null) {
            try {
                await this.macroInteractor.storeExternal(this.rejectReason, MacroExternalType.REFEFENCES_REJECT_REASON);
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };

    public onRemoveRejectMacroClick = async (toBeRemovedMacro: Macro) => {
        if (this.rejectMacros !== null) {
            try {
                const macro = this.rejectMacros.find((m) => m.id === toBeRemovedMacro.id);
                if (macro !== undefined) {
                    if (!macro.isUserDefined) {
                        await this.macroInteractor.hideForUser(macro.id);
                    } else {
                        await this.macroInteractor.destroy(macro.id);
                    }
                }
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };

    public onFavoriteRejectMacroClick = async (toBeFavoritedMacro: Macro) => {
        if (this.rejectMacros !== null) {
            try {
                const macro = this.rejectMacros.find((m) => m.id === toBeFavoritedMacro.id);
                if (macro !== undefined) {
                    await this.macroInteractor.toggleFavorite(macro.id);
                }
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };

    public onReject() {
        if (
            !this.setDefinition ||
            !this.setDefinition.groupTree.item.answer ||
            !this.referenceObject ||
            this.isRejected
        ) {
            return;
        }

        const metadataPair = this.referenceObjectsMetadataProvider.getMetadataByAnswerUuid(
            this.setDefinition.groupTree.item.question.uuid,
            this.setDefinition.groupTree.item.answer.uuid
        );

        if (!metadataPair) {
            return;
        }

        metadataPair.metadata.rejectedObjects.push(this.referenceObject);

        this.referenceObjectsMetadataProvider.storeMetadata(metadataPair);
    }
}
