import * as React from 'react';

import {SortingDirection, SortingMethod} from '../../../../../../../enum/reference_objects_sorting';

import {Appraisal} from '../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../models/question_set';
import {ReferenceObjectAnswer} from './models/reference_object_answer';
import {ReferenceObjectCurrentlyInRentTile} from './reference_objects_rent/reference_object_in_rent_tile';
import {ReferenceObjectCurrentlyInSaleTile} from './reference_objects_sale/reference_object_in_sale_tile';
import {ReferenceObjectTile} from './reference_objects/reference_object_tile';
import {ReferenceObjectTilePlaceholder} from './reference_object_tile_placeholder';
import {ReferenceObjectType} from '../../../../../../../enum/reference_object_type';
import {ReferenceSale} from './models/reference_sale';
import {ReferenceSet} from './models/reference_set';
import {VisibleReferenceSale} from './reference_objects_question_presenter';
import {classNames} from '../../../../../../../../support/classnames';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    referenceSaleSet: ReferenceSet;
    isFrozen: boolean;
    canAdd: boolean;
    isUpdatingReferenceObjects: boolean;
    onAdd: (referenceSale: ReferenceSale, referenceObjectAnswer: ReferenceObjectAnswer) => void;
    onChange: (referenceSale: ReferenceSale, referenceObjectAnswer: ReferenceObjectAnswer) => void;
    onRemove: (referenceObjectAnswer: ReferenceObjectAnswer) => void;
    showAnswerModal: (referenceSale: ReferenceSale, referenceObjectAnswer: ReferenceObjectAnswer) => void;
    showDetailsModal: (referenceSale: ReferenceSale) => void;
    fetchingReferenceObjects: boolean;
    isComparing: boolean;
    sortingMethod: SortingMethod;
    sortingDirection: SortingDirection;
    updateSortingMethod: (sortingMethod: SortingMethod) => void;
    updateIsComparing: (isComparing: boolean) => void;
    visibleReferenceSales: VisibleReferenceSale[];
    hoveringReferenceSale: ReferenceSale | null;
    clickedReferenceSale: ReferenceSale | null;
    setHoveringReferenceSale: (rs: ReferenceSale | null) => void;
    setClickedReferenceSale: (rs: ReferenceSale | null) => void;
    numVisible: number;
    showMoreReferenceSales: () => void;
    referenceObjectType: ReferenceObjectType;
    children?: React.ReactNode;
}

export class ReferenceObjectGrid extends React.Component<OwnProps> {
    private renderSortingButtons(visibleReferenceSales: VisibleReferenceSale[]) {
        let sortingMethods = [];
        switch (this.props.referenceObjectType) {
            case ReferenceObjectType.RENT:
            case ReferenceObjectType.SALE:
                sortingMethods = [SortingMethod.ALPHABETICALLY];
                break;
            case ReferenceObjectType.DEFAULT:
                sortingMethods = [
                    SortingMethod.ALPHABETICALLY,
                    SortingMethod.SALES_DATE,
                    SortingMethod.DEVIATION_SCORE,
                ];
                if (this.props.appraisal.latitude !== null && this.props.appraisal.longitude !== null) {
                    sortingMethods.push(SortingMethod.DISTANCE);
                }
                break;
        }

        const hasAnswers = visibleReferenceSales.some((sale) => sale.referenceObjectAnswer !== null);

        return (
            <div className="row">
                <div className="col-12">
                    <ul className="nav-filter">
                        {sortingMethods.map((method: SortingMethod) => {
                            return (
                                <li
                                    key={method}
                                    className={classNames('nav-filter-item', {
                                        active: !this.props.isComparing && this.props.sortingMethod === method,
                                        'ion-md-arrow-dropup':
                                            !this.props.isComparing &&
                                            this.props.sortingMethod === method &&
                                            this.props.sortingDirection === SortingDirection.ASCENDING,
                                        'ion-md-arrow-dropdown':
                                            !this.props.isComparing &&
                                            this.props.sortingMethod === method &&
                                            this.props.sortingDirection === SortingDirection.DESCENDING,
                                    })}
                                    onClick={() => this.props.updateSortingMethod(method)}
                                >
                                    {method}
                                </li>
                            );
                        })}
                        <li
                            key="compare"
                            className={classNames('nav-filter-item', {
                                active: this.props.isComparing,
                                disabled: !hasAnswers,
                            })}
                            onClick={() => this.props.updateIsComparing(true)}
                        >
                            Vergelijken
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    public render() {
        return (
            <>
                {this.renderSortingButtons(this.props.visibleReferenceSales)}

                <div
                    className={classNames('row grid reference-object-grid', {
                        'reference-object-grid-reload': this.props.isUpdatingReferenceObjects,
                    })}
                >
                    {this.props.visibleReferenceSales.map(({referenceSale, referenceObjectAnswer}, index) => {
                        switch (this.props.referenceObjectType) {
                            case ReferenceObjectType.SALE:
                                return (
                                    <ReferenceObjectCurrentlyInSaleTile
                                        key={referenceSale.id}
                                        appraisal={this.props.appraisal}
                                        questionSet={this.props.questionSet}
                                        valuation={this.props.referenceSaleSet.valuation}
                                        areaConclusion={this.props.referenceSaleSet.areaConclusion}
                                        energyConclusion={this.props.referenceSaleSet.energyConclusion}
                                        buildYear={this.props.referenceSaleSet.buildYear}
                                        referenceSale={referenceSale}
                                        referenceObjectAnswer={referenceObjectAnswer}
                                        index={index}
                                        isMapHovering={this.props.hoveringReferenceSale === referenceSale}
                                        isMapSelected={this.props.clickedReferenceSale === referenceSale}
                                        isFrozen={this.props.isFrozen}
                                        canAdd={this.props.canAdd}
                                        onAdd={this.props.onAdd}
                                        onRemove={this.props.onRemove}
                                        onHoverChange={this.props.setHoveringReferenceSale}
                                        onClickChange={this.props.setClickedReferenceSale}
                                        showAnswerModal={this.props.showAnswerModal}
                                        showDetailsModal={this.props.showDetailsModal}
                                        isCompare={this.props.isComparing}
                                    />
                                );
                            case ReferenceObjectType.RENT:
                                return (
                                    <ReferenceObjectCurrentlyInRentTile
                                        key={referenceSale.id}
                                        appraisal={this.props.appraisal}
                                        questionSet={this.props.questionSet}
                                        valuation={this.props.referenceSaleSet.valuation}
                                        areaConclusion={this.props.referenceSaleSet.areaConclusion}
                                        energyConclusion={this.props.referenceSaleSet.energyConclusion}
                                        buildYear={this.props.referenceSaleSet.buildYear}
                                        referenceSale={referenceSale}
                                        referenceObjectAnswer={referenceObjectAnswer}
                                        index={index}
                                        isMapHovering={this.props.hoveringReferenceSale === referenceSale}
                                        isMapSelected={this.props.clickedReferenceSale === referenceSale}
                                        isFrozen={this.props.isFrozen}
                                        canAdd={this.props.canAdd}
                                        onAdd={this.props.onAdd}
                                        onRemove={this.props.onRemove}
                                        onHoverChange={this.props.setHoveringReferenceSale}
                                        onClickChange={this.props.setClickedReferenceSale}
                                        showAnswerModal={this.props.showAnswerModal}
                                        showDetailsModal={this.props.showDetailsModal}
                                        isCompare={this.props.isComparing}
                                    />
                                );
                            case ReferenceObjectType.DEFAULT:
                            default:
                                return (
                                    <ReferenceObjectTile
                                        key={referenceSale.id}
                                        appraisal={this.props.appraisal}
                                        questionSet={this.props.questionSet}
                                        valuation={this.props.referenceSaleSet.valuation}
                                        areaConclusion={this.props.referenceSaleSet.areaConclusion}
                                        energyConclusion={this.props.referenceSaleSet.energyConclusion}
                                        buildYear={this.props.referenceSaleSet.buildYear}
                                        referenceSale={referenceSale}
                                        referenceObjectAnswer={referenceObjectAnswer}
                                        index={index}
                                        isMapHovering={this.props.hoveringReferenceSale === referenceSale}
                                        isMapSelected={this.props.clickedReferenceSale === referenceSale}
                                        isFrozen={this.props.isFrozen}
                                        canAdd={this.props.canAdd}
                                        onAdd={this.props.onAdd}
                                        onRemove={this.props.onRemove}
                                        onHoverChange={this.props.setHoveringReferenceSale}
                                        onClickChange={this.props.setClickedReferenceSale}
                                        showAnswerModal={this.props.showAnswerModal}
                                        showDetailsModal={this.props.showDetailsModal}
                                        isCompare={this.props.isComparing}
                                    />
                                );
                        }
                    })}
                    {!this.props.fetchingReferenceObjects && this.props.visibleReferenceSales.length === 0 ? (
                        <ReferenceObjectTilePlaceholder animated={false} tiles={3} />
                    ) : null}
                    {this.props.fetchingReferenceObjects ? (
                        <ReferenceObjectTilePlaceholder animated={true} tiles={3} />
                    ) : null}
                </div>
                <div className="row">
                    <div className="col-12">
                        {this.props.referenceSaleSet.referenceObjects.length > this.props.numVisible ? (
                            <a
                                onClick={() => this.props.showMoreReferenceSales()}
                                className="btn btn-sm ion-md-refresh float-left"
                            >
                                Laad meer
                            </a>
                        ) : null}
                        {this.props.children}
                    </div>
                </div>
            </>
        );
    }
}
