import * as React from 'react';

import {Appraisal} from '../../../../../../../../../../../models/appraisal';
import {AppraiseModel, isAppraiseModel} from '../../../../../../../../../../../enum/appraise_model';
import {DateInput} from '../../../inputs/date';
import {FunctionComponent} from 'react';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';
import {Select} from '../../../inputs/select';
import {isEmpty} from '../../../../../../../../../../../../support/util';

interface OwnProps {
    appraisal: Appraisal;
    energyLabel: string | null;
    referenceObjectAnswer: ReferenceObjectAnswer;
    isFrozen: boolean;
    onEnergyLabelChange: (energyLabel: string) => void;
    onEnergyLabelValidTillChange: (date: string) => void;
}

export const EnergyLabel: FunctionComponent<OwnProps> = ({
    appraisal,
    energyLabel,
    referenceObjectAnswer,
    onEnergyLabelChange,
    onEnergyLabelValidTillChange,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-sm-12">
                <dl>
                    <div className="row">
                        <div className="col-sm-6">
                            <Select
                                isRequired
                                name="energielabel"
                                label="Energielabel"
                                options={{
                                    A: 'A',
                                    B: 'B',
                                    C: 'C',
                                    D: 'D',
                                    E: 'E',
                                    F: 'F',
                                    G: 'G',
                                    Unknown: 'Geen energielabel',
                                }}
                                badge={energyLabel ? <>{'(' + energyLabel + ')'}</> : null}
                                emptyPlaceholder="Selecteer energielabel"
                                value={
                                    !isEmpty(validationInstituteReferentieObject.energielabel) &&
                                    validationInstituteReferentieObject.energielabel !== '-'
                                        ? validationInstituteReferentieObject.energielabel
                                        : 'Geen energielabel'
                                }
                                onChange={onEnergyLabelChange}
                                disabled={isFrozen}
                            />
                        </div>
                        {/* This field is only required in model 2021 */}
                        {isAppraiseModel(appraisal, AppraiseModel.MODEL2021) &&
                            validationInstituteReferentieObject.energielabel !== 'Geen energielabel' && (
                                <div className="col-sm-6">
                                    <DateInput
                                        isRequired
                                        name="energielabelGeldigTot"
                                        label="Energielabel geldig tot"
                                        value={validationInstituteReferentieObject.energielabelGeldigTot ?? ''}
                                        onChange={onEnergyLabelValidTillChange}
                                        disabled={isFrozen}
                                    />
                                </div>
                            )}
                    </div>
                </dl>
            </div>
        </div>
    );
};
