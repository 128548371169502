export function linkifyChunks(inputText: Array<string | JSX.Element>, urls: RegExpMatchArray | null) {
    if (urls !== null) {
        const replacePattern2 = /(\^)/gim;
        let counter = 0;
        inputText.forEach((currentValue, index) => {
            if (typeof currentValue === 'string') {
                if (currentValue.includes('^')) {
                    const url = linkifyString(urls[counter]);
                    currentValue = currentValue.replace(replacePattern2, url);
                    inputText[index] = currentValue;
                    counter++;
                }
            }
        });
    }
    return inputText;
}

export function linkifyString(inputText: string) {
    inputText = inputText.replace(
        /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim,
        '<a href="$1" target="_blank">$1</a>'
    );
    return inputText;
}

export function delinkify(inputText: string) {
    let replacedText;

    //URLs starting with http://, https://, or ftp://
    const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '^');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '^');
    return replacedText;
}
