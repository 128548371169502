import {GlobalProvider} from '../../business/global_provider';
import {UserType} from '../enum/user_type';

export interface UserInteractor {
    isAppraiser(): boolean;
    isEmployee(): boolean;
    isJuniorAppraiser(): boolean;
}

export class DefaultUserInteractor implements UserInteractor {
    constructor(private globalProvider: GlobalProvider) {}

    public isAppraiser(): boolean {
        if (this.globalProvider.global.userType !== undefined) {
            return this.globalProvider.global.userType === UserType.APPRAISER;
        }

        /* This is a bigtime hack, however the only time a non-appraiser looks at the appraisal
        environment is while a detailName is present and vice versa, so for now this is good enough */
        return this.globalProvider.global.detailName === null;
    }

    public isJuniorAppraiser(): boolean {
        if (this.globalProvider.global.userType !== undefined) {
            return this.globalProvider.global.userType === UserType.JUNIOR_APPRAISER;
        }

        /* This is a bigtime hack, however the only time a non-appraiser looks at the appraisal
        environment is while a detailName is present and vice versa, so for now this is good enough */
        return this.globalProvider.global.detailName === null;
    }

    public isEmployee(): boolean {
        if (this.globalProvider.global.userType !== undefined) {
            return this.globalProvider.global.userType === UserType.EMPLOYEE;
        }
        return false;
    }
}
