import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {Appraisal} from '../../../../../../models/appraisal';
import {AppraisalState} from '../../../../../../enum/appraisal_state';
import {AppraisalValidationType} from '../../../../../../enum/appraisal_validation_type';
import {DetailModalData} from '../../../photo_details_modal';
import {ImagesUploadInteractor} from '../../../images_upload_interactor';
import {Presenter} from '../../../../../../../support/presenter/presenter';
import {Uppy} from '@uppy/core';
import {ProgressType} from '../../../camera_presenter';
import {RenderingContextType} from '../../../../../../enum/rendering_context_type';
import {RootGroupQuestionType} from '../../../../../../enum/question_type';
import {Question} from '../../../../../../models/question';
import {TechnicalReference} from '../../../../../../enum/technical_reference';
import {QuestionSet} from '../../../../../../models/question_set';

export class PhotoDropZonePresenter implements Presenter {
    @observable public uppy: Uppy;
    @observable.ref public detailModalData: DetailModalData | null = null;
    @observable public isAvailable = true;
    @observable.ref public progress: {percentage: number; type: ProgressType} = {
        percentage: 0,
        type: ProgressType.IDLE,
    };
    @observable public errorMessage: string | null = null;

    @computed
    public get isDisabled(): boolean {
        if (!this.appraisal.isEditableAppraisal) {
            return true;
        }
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return false;
        }
        return (
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    constructor(
        private appraisal: Appraisal,
        questionSet: QuestionSet,
        private imagesUploadInteractor: ImagesUploadInteractor,
        renderingContext: RenderingContextType
    ) {
        let rootQuestion: Question | null =
            questionSet.findQuestionsByType(RootGroupQuestionType.PHOTO_GROUP)[0] ?? null;
        if (
            renderingContext === RenderingContextType.PAGE_PARTS_APPRAISAL ||
            renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR
        ) {
            rootQuestion = questionSet.findQuestionByTechnicalReference(TechnicalReference.PHOTO_ITERATOR_DEFAULT);
        }

        const uppy = new Uppy();
        uppy.on('files-added', async (uppyFiles) => {
            const files = uppyFiles.map((uppyFile) => uppyFile.data).filter((file): file is File => 'name' in file);
            if (files.length > 0) {
                if (this.errorMessage != null) {
                    this.clearError();
                }

                await this.imagesUploadInteractor.onFiles(files, rootQuestion, {
                    alertNotAvailable: () => {
                        this.alertNotAvailable();
                    },
                    alertIncorrectType: () => {
                        this.alertIncorrectType();
                    },
                    updateProgress: (progress, type) => {
                        if (type === ProgressType.FAILURE) {
                            // Progress bar doesn't show errors
                            type = ProgressType.IDLE;
                        }
                        this.updateProgress(progress, type);
                    },
                    onDetailModalData: (data) => runInAction(() => (this.detailModalData = data)),
                });
                this.uppy.reset();
            }
        });

        this.uppy = uppy;

        makeObservable(this);
    }

    public mount() {
        // NOOP
    }

    public unmount() {
        this.uppy.close();
    }

    @action
    private updateProgress(progress: number, type: ProgressType) {
        this.progress = {percentage: progress, type};
    }

    @action
    public onCloseModal() {
        this.detailModalData = null;
    }

    @action
    private clearError() {
        this.errorMessage = null;
    }

    private alertIncorrectType() {
        this.errorMessage =
            'Fout bij het uploaden. Is het een correct formaat? Voor afbeeldingen kun je .jpg en .png uploaden.';
    }

    @action
    private alertNotAvailable() {
        this.errorMessage =
            "Offline opslag niet beschikbaar op dit apparaat, gebruik de systeem camera app om foto's te maken en voeg deze op een later moment toe.";
        this.isAvailable = false;
    }
}
