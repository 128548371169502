import {Answer} from '../../../models/answer';
import {AnswerApi} from '../../../network/answer_api';
import {bugsnagClient} from '../../../../support/bugsnag_client';
import {createAnswerHash} from '../../support/create_answer_hash';

interface Job {
    executing: boolean;
    appraisalId: number;
    answers: Answer[];
    referenceDate: Date | null;
    resolve: (value: PromiseLike<Answer[]> | Answer[]) => void;
    reject: (reason?: unknown) => void;
    cleanupPromise?: (value: Answer[]) => Promise<unknown>;
}

export class AnswerStorageQueue {
    private jobs: Job[] = [];

    constructor(private answerApi: AnswerApi) {}

    public storeAnswers(
        appraisalId: number,
        answers: Answer[],
        referenceDate: Date | null,
        cleanupPromise?: (value: Answer[]) => Promise<unknown>
    ): Promise<Answer[]> {
        if (process.env.NODE_ENV === 'development') {
            if (answers.some((a) => a.changed === false)) {
                console.trace();
                console.warn(appraisalId, answers, referenceDate);
                for (const answer of answers) {
                    console.warn({...answer});
                }

                throw new Error('some answers arent changed');
            }
        }
        return new Promise((resolve, reject) => {
            this.schedule(appraisalId, answers, referenceDate, resolve, reject, cleanupPromise);
        });
    }

    private schedule(
        appraisalId: number,
        answers: Answer[],
        referenceDate: Date | null,
        resolve: (value: PromiseLike<Answer[]> | Answer[]) => void,
        reject: (reason?: unknown) => void,
        cleanupPromise?: (value: Answer[]) => Promise<unknown>
    ) {
        this.jobs.push({
            executing: false,
            appraisalId: appraisalId,
            answers: this.filterAnswers(appraisalId, answers),
            referenceDate: referenceDate || null,
            resolve,
            reject,
            cleanupPromise,
        });

        this.trigger();
    }

    private previousHashes = new Set<string>();
    private trigger() {
        if (this.hasRunningJobs()) {
            return;
        }

        (async () => {
            const job = this.jobs[0];
            if (job) {
                job.executing = true;
                try {
                    const jobAnswers = [];

                    for (const newAnswer of job.answers) {
                        const answerHash = createAnswerHash(newAnswer);
                        if (this.previousHashes.has(answerHash)) {
                            if (!process.env.MIX_FEATURE_FRONTEND_SKIP_EXISTING_ANSWERS) {
                                jobAnswers.push(newAnswer);
                            }
                            console.warn('Tried to send answer that was already previously submitted!');
                            bugsnagClient?.notify(
                                new Error('Tried to send answer that was already previously submitted!'),
                                {
                                    metaData: {
                                        origin: 'answer_storage_queue.ts trigger()',
                                        hash: answerHash,
                                        answer: newAnswer,
                                    },
                                }
                            );
                        } else {
                            jobAnswers.push(newAnswer);
                        }
                        this.previousHashes.add(answerHash);
                    }

                    if (jobAnswers.length === 0 && job.answers.length !== 0) {
                        job.resolve([]);
                    } else {
                        const result = await this.answerApi.storeAnswers(
                            job.appraisalId,
                            jobAnswers,
                            job.referenceDate
                        );
                        if (job.cleanupPromise !== undefined) {
                            await job.cleanupPromise(result);
                        }

                        job.resolve(result);
                    }
                } catch (e) {
                    job.reject(e);
                } finally {
                    this.jobs.shift();
                    this.trigger();
                }
            }
        })();
    }

    private hasRunningJobs(): boolean {
        return this.jobs.some((job) => job.executing);
    }

    private filterAnswers(appraisalId: number, answers: Answer[]): Answer[] {
        return answers.filter((answer) => {
            return !this.storingOfAnswerIsQueued(appraisalId, answer);
        });
    }

    private storingOfAnswerIsQueued(appraisalId: number, answer: Answer): boolean {
        return this.jobs.some((job) => {
            if (job.appraisalId !== appraisalId) {
                return false;
            }
            return job.answers.some((scheduledAnswer) => {
                return (
                    scheduledAnswer.uuid === answer.uuid &&
                    scheduledAnswer.updatedAt?.getTime() === answer.updatedAt?.getTime()
                );
            });
        });
    }
}
