import * as React from 'react';

import {FunctionComponent} from 'react';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';
import {Textarea} from '../../../inputs/textarea';
import {getPositiveNegativeMacros} from '../../../../internal/positive_negative_macros';

interface OwnProps {
    referenceObjectAnswer: ReferenceObjectAnswer;
    onLuxuryDifferenceExplanationChange: (luxuryDifferenceExplanation: string) => void;
    isFrozen: boolean;
}

export const LuxuryDifferenceExplanation: FunctionComponent<OwnProps> = ({
    referenceObjectAnswer,
    onLuxuryDifferenceExplanationChange,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-sm-12">
                <Textarea
                    isRequired
                    label="Mate van luxe verschil toelichting"
                    name="input-luxury-explanation"
                    value={validationInstituteReferentieObject.mateVanLuxeUitleg ?? ''}
                    onChange={onLuxuryDifferenceExplanationChange}
                    macros={getPositiveNegativeMacros(
                        referenceObjectAnswer.referenceObject.mateVanLuxeStatus,
                        [
                            'Het referentieobject is meer luxe afgewerkt',
                            'Het referentieobject is hoogwaardiger afgewerkt',
                        ],
                        [
                            'Het referentieobject is minder luxe afgewerkt',
                            'Het referentieobject is minder hoogwaardig afgewerkt',
                            'Het referentieobject is eenvoudiger afgewerkt',
                        ]
                    )}
                    disabled={isFrozen}
                    maxLength={1000}
                />
            </div>
        </div>
    );
};
