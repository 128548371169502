import {combineLatest, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {findChildRecursiveByPredicate, findParentByPredicateRecursive} from '../../../support/generic_tree';
import {isEmpty, isNumeric} from '../../../support/util';
import {
    createReferenceObjectData,
    ReferenceObjectData,
} from '../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/create_reference_object_data';
import {
    getObjectIndexedPrice,
    getObjectUnindexedPrice,
    getPriceOrPriceRangeFloorArea,
} from '../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/get_object_price';
import {
    V3ReferenceSet,
    V3ReferenceSetsProvider,
} from '../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/reference_sets/reference_sets_provider';
import {
    badgeContextsToAverage,
    buildYearDiffToBadgeContext,
    conditionToBadgeContext,
    diffToBadgeContext,
    energyLabelDiffToBadgeContext,
    objectTypeDiffToBadgeContext,
    statusTextToBadgeContext,
} from '../../components/badges/badge_context_calculators';
import {BadgeContext} from '../../enum/badge_context';
import {
    IntCompareSelectorType,
    SpecialCompareSelectorType,
    StringCompareSelectorType,
} from '../../enum/compare_selector_type';
import {ObjectType} from '../../enum/object_type';
import {NormalQuestionType} from '../../enum/question_type';
import {TechnicalReference} from '../../enum/technical_reference';
import {Question} from '../../models/question';
import {QuestionSet} from '../../models/question_set';
import {AnswerController} from '../answering/answer_controller';
import {AppraisalProvider} from '../appraisal_provider';
import {BuildYearProvider} from '../build_year_provider';
import {EnergyLabelProvider} from '../energy_label_provider';
import {PlotAreaProvider} from '../plot_area_provider';
import {SurfaceAreaProvider} from '../support/surface_area_provider';
import {VolumeProvider} from '../volume_provider';

export interface CompareValuesProvider {
    badgeByAnswerIdentifiersStream(
        questionUuid: string,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<BadgeContext | null>;

    compareValueByAnswerIdentifiersStream(
        questionUuid: string,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<string | number | null>;

    badgesByAnswerIdentifiersStream(
        questionUuid: string,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<Record<string, BadgeContext | null>>;

    compareValuesByAnswerIdentifiersStream(
        questionUuid: string,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<Record<string, string | number | null>>;
}

export type ReferenceObjectCompareValues = 'price_per_m2' | 'match_score';

export class DefaultCompareValuesProvider implements CompareValuesProvider {
    constructor(
        private questionSet: QuestionSet,
        private buildYearProvider: BuildYearProvider,
        private surfaceAreaProvider: SurfaceAreaProvider,
        private volumeProvider: VolumeProvider,
        private plotAreaProvider: PlotAreaProvider,
        private referenceSetsProvider: V3ReferenceSetsProvider,
        private energyLabelProvider: EnergyLabelProvider,
        private answerController: AnswerController,
        private appraisalProvider: AppraisalProvider
    ) {}

    public badgeByAnswerIdentifiersStream(
        questionUuid: string,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<BadgeContext | null> {
        return this.badgesByAnswerIdentifiersStream(questionUuid, parentAnswerUuid, iteration).pipe(
            map((data) => {
                const badges = Object.values(data).filter((badge): badge is BadgeContext => badge !== null);

                return badgeContextsToAverage(badges);
            })
        );
    }

    public badgesByAnswerIdentifiersStream(
        questionUuid: string,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<Record<string, BadgeContext | null>> {
        const question = this.questionSet.findQuestionByUuid(questionUuid);

        if (!question) {
            return of({});
        }

        switch (question.type) {
            case NormalQuestionType.INT_COMPARE: {
                return this.getIntCompareValueStream(question, parentAnswerUuid, iteration).pipe(
                    switchMap((value) => this.getIntCompareBadgeStream(question, parentAnswerUuid, iteration, value)),
                    map((badge) => ({badge: badge}))
                );
            }
            case NormalQuestionType.MC_SELECT_COMPARE: {
                return this.getMcSelectCompareValueStream(question, parentAnswerUuid, iteration).pipe(
                    switchMap((value) =>
                        this.getMcSelectCompareBadgeStream(question, parentAnswerUuid, iteration, value)
                    ),
                    map((badge) => ({badge: badge}))
                );
            }
            case NormalQuestionType.REFERENCE_OBJECT_EDITABLE_ADDRESS: {
                return this.getReferenceObjectBadgeStream(question, parentAnswerUuid, iteration);
            }
            default: {
                return of({});
            }
        }
    }

    public compareValueByAnswerIdentifiersStream(
        questionUuid: string,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<string | number | null> {
        return this.compareValuesByAnswerIdentifiersStream(questionUuid, parentAnswerUuid, iteration).pipe(
            map((data) => {
                const keys = Object.keys(data);
                if (keys.length === 1) {
                    return data[keys[0]];
                }

                return null;
            })
        );
    }

    public compareValuesByAnswerIdentifiersStream(
        questionUuid: string,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<Record<string, string | number | null>> {
        const question = this.questionSet.findQuestionByUuid(questionUuid);

        if (!question) {
            return of({});
        }

        switch (question.type) {
            case NormalQuestionType.INT_COMPARE: {
                return this.getIntCompareValueStream(question, parentAnswerUuid, iteration).pipe(
                    map((value) => ({value: value}))
                );
            }
            case NormalQuestionType.MC_SELECT_COMPARE: {
                return this.getMcSelectCompareValueStream(question, parentAnswerUuid, iteration).pipe(
                    map((value) => ({value: value}))
                );
            }
            case NormalQuestionType.REFERENCE_OBJECT_EDITABLE_ADDRESS: {
                return this.getReferenceObjectCompareValuesStream(question, parentAnswerUuid, iteration);
            }
            default: {
                return of({});
            }
        }
    }

    private getIntCompareValueStream(
        question: Question,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<number | null> {
        switch (question.compareSelector) {
            case IntCompareSelectorType.BUILDYEAR:
                return this.buildYearProvider.stream();
            case IntCompareSelectorType.SURFACE_AREA:
                return this.surfaceAreaProvider.surfaceArea();
            case IntCompareSelectorType.VOLUME:
                return this.volumeProvider.stream();
            case IntCompareSelectorType.PLOT_AREA:
                return this.plotAreaProvider.plotArea();
            case IntCompareSelectorType.SPECIAL_BUILDYEAR:
                return this.getReferenceSetStream(question, parentAnswerUuid, iteration).pipe(
                    map((set) => set?.buildYear ?? null)
                );
            case IntCompareSelectorType.SPECIAL_SURFACE_AREA:
                return this.getReferenceSetStream(question, parentAnswerUuid, iteration).pipe(
                    map((set) => set?.surfaceArea ?? null)
                );
            case IntCompareSelectorType.SPECIAL_VOLUME:
                return this.getReferenceSetStream(question, parentAnswerUuid, iteration).pipe(
                    map((set) => set?.volume ?? null)
                );
            case IntCompareSelectorType.SPECIAL_PLOT_AREA:
                return this.getReferenceSetStream(question, parentAnswerUuid, iteration).pipe(
                    map((set) => set?.plotArea ?? null)
                );
            default:
                throw new Error('int-compare question needs a int compare_selector property.');
        }
    }

    private getIntCompareBadgeStream(
        question: Question,
        parentAnswerUuid: string | null,
        iteration: string | null,
        comparisonValue: number | null
    ): Observable<BadgeContext | null> {
        return this.answerController.answerByIdentifiersStream(question.uuid, parentAnswerUuid, iteration).pipe(
            map((answer) => {
                if (answer?.contents && isNumeric(answer.contents) && comparisonValue !== null) {
                    const value = parseInt(answer.contents, 10);
                    switch (question.compareSelector) {
                        case IntCompareSelectorType.BUILDYEAR:
                        case IntCompareSelectorType.SPECIAL_BUILDYEAR:
                            return buildYearDiffToBadgeContext(value - comparisonValue);
                        case IntCompareSelectorType.SURFACE_AREA:
                        case IntCompareSelectorType.SPECIAL_SURFACE_AREA:
                        case IntCompareSelectorType.VOLUME:
                        case IntCompareSelectorType.SPECIAL_VOLUME:
                        case IntCompareSelectorType.PLOT_AREA:
                        case IntCompareSelectorType.SPECIAL_PLOT_AREA:
                            return diffToBadgeContext((100 * (value - comparisonValue)) / comparisonValue);
                        default:
                            return null;
                    }
                }

                return null;
            })
        );
    }

    private getReferenceSetStream(
        question: Question,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<V3ReferenceSet | null> {
        return combineLatest([
            this.referenceSetsProvider.referenceSets(),
            this.answerController.answerByIdentifiersStream(question.uuid, parentAnswerUuid, iteration),
        ]).pipe(
            map(([referenceSets, answer]) => {
                if (referenceSets === null) {
                    return null;
                }

                const thisReferenceSet = referenceSets.find((set) =>
                    findChildRecursiveByPredicate(
                        set.groupTree,
                        (item) => item.answer !== null && item.answer.uuid === answer.uuid
                    )
                );

                return thisReferenceSet ?? null;
            })
        );
    }

    private getMcSelectCompareValueStream(
        question: Question,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<string | null> {
        switch (question.compareSelector) {
            case StringCompareSelectorType.ENERGY_LABEL:
                return this.energyLabelProvider.stream();
            case StringCompareSelectorType.OBJECT_TYPE:
                return of(this.appraisalProvider.appraisal.objectType?.toString() ?? null);
            case SpecialCompareSelectorType.COMPARABILITY_OPTIONS:
                return this.answerController.answerByIdentifiersStream(question.uuid, parentAnswerUuid, iteration).pipe(
                    map((answer) => {
                        if (!answer?.answerOptionId) {
                            return null;
                        }

                        return question.answerOptions.find((ao) => ao.id === answer?.answerOptionId)?.contents ?? null;
                    })
                );
            default:
                throw new Error('select-compare question needs a string compare_selector property.');
        }
    }

    private getMcSelectCompareBadgeStream(
        question: Question,
        parentAnswerUuid: string | null,
        iteration: string | null,
        comparisonValue: string | null
    ): Observable<BadgeContext | null> {
        return this.answerController.answerByIdentifiersStream(question.uuid, parentAnswerUuid, iteration).pipe(
            map((answer) => {
                if (!answer?.answerOptionId) {
                    return null;
                }

                const currentValue =
                    question.answerOptions.find((ao) => ao.id === answer?.answerOptionId)?.contents ?? null;
                if (currentValue === null || comparisonValue === null) {
                    return null;
                }

                switch (question.compareSelector) {
                    case StringCompareSelectorType.ENERGY_LABEL:
                        return energyLabelDiffToBadgeContext(currentValue, comparisonValue);
                    case StringCompareSelectorType.OBJECT_TYPE:
                        return objectTypeDiffToBadgeContext(currentValue as ObjectType, comparisonValue as ObjectType);
                    case SpecialCompareSelectorType.COMPARABILITY_OPTIONS:
                        return conditionToBadgeContext(currentValue) ?? statusTextToBadgeContext(currentValue);
                    default:
                        return null;
                }

                return null;
            })
        );
    }

    private getReferenceObjectDetailsStream(
        question: Question,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<{set: V3ReferenceSet; data: ReferenceObjectData} | null> {
        return this.referenceSetsProvider.referenceSets().pipe(
            map((sets) => {
                for (const set of sets ?? []) {
                    const treeItem = findChildRecursiveByPredicate(
                        set.groupTree,
                        (node) =>
                            node.question.uuid === question.uuid &&
                            node.answer?.parentUuid === parentAnswerUuid &&
                            node.answer?.iteration === iteration
                    );
                    if (treeItem) {
                        return {
                            set,
                            treeItem,
                        };
                    }
                }

                return null;
            }),
            map((data) => {
                if (data === null) {
                    return null;
                }

                const {set, treeItem} = data;

                const referenceGroupItem = findParentByPredicateRecursive(
                    treeItem,
                    (item) =>
                        item.question.technicalReference === TechnicalReference.REFERENCE_OBJECTS_V3_ITERATOR_GROUP
                );

                if (referenceGroupItem === null) {
                    return null;
                }

                const referenceObjectData = createReferenceObjectData(referenceGroupItem);

                if (referenceObjectData === null) {
                    return null;
                }

                return {
                    set,
                    data: referenceObjectData,
                };
            })
        );
    }

    private getReferenceObjectCompareValuesStream(
        question: Question,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<Record<ReferenceObjectCompareValues, number | string | null>> {
        return this.getReferenceObjectDetailsStream(question, parentAnswerUuid, iteration).pipe(
            map((details) => {
                if (details === null) {
                    return {
                        match_score: null,
                        price_per_m2: null,
                    };
                }

                const {set} = details;

                const pricePerM2 =
                    set.valuation !== null && set.surfaceArea !== null && set.surfaceArea !== 0
                        ? Math.round(set.valuation / set.surfaceArea)
                        : null;

                return {
                    match_score: null,
                    price_per_m2: pricePerM2,
                };
            })
        );
    }

    private getReferenceObjectBadgeStream(
        question: Question,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<Record<ReferenceObjectCompareValues, BadgeContext | null>> {
        return this.getReferenceObjectDetailsStream(question, parentAnswerUuid, iteration).pipe(
            map((details) => {
                if (details === null) {
                    return {
                        match_score: null,
                        price_per_m2: null,
                    };
                }

                const {set, data} = details;

                const referenceSetPricePerM2 =
                    set.valuation !== null && set.surfaceArea !== null && set.surfaceArea !== 0
                        ? Math.round(set.valuation / set.surfaceArea)
                        : null;

                const referenceObjectFloorArea = data.referenceObjectAnswer.referenceObject.gebruiksOppervlakte ?? null;

                let priceOrPriceRange = getObjectIndexedPrice(set.type, data.referenceObjectAnswer.referenceObject);
                if (priceOrPriceRange === null || isEmpty(String(priceOrPriceRange).trim())) {
                    priceOrPriceRange = getObjectUnindexedPrice(set.type, data.referenceObjectAnswer.referenceObject);
                }

                let pricePerM2Badge: BadgeContext | null = null;
                if (
                    priceOrPriceRange !== null &&
                    !isEmpty(String(priceOrPriceRange).trim()) &&
                    referenceObjectFloorArea !== null &&
                    referenceObjectFloorArea !== 0 &&
                    referenceSetPricePerM2 !== null
                ) {
                    const priceOrPriceRangePerM2 = getPriceOrPriceRangeFloorArea(
                        priceOrPriceRange,
                        referenceObjectFloorArea
                    );

                    let pricePerM2: number;
                    if (Array.isArray(priceOrPriceRangePerM2)) {
                        pricePerM2 = (priceOrPriceRangePerM2[0] + priceOrPriceRangePerM2[1]) / 2;
                    } else {
                        pricePerM2 = priceOrPriceRangePerM2;
                    }

                    if (pricePerM2 !== 0) {
                        pricePerM2Badge = diffToBadgeContext(
                            (100 * (pricePerM2 - referenceSetPricePerM2)) / referenceSetPricePerM2
                        );
                    }
                }

                let matchScoreBadge: BadgeContext | null = null;
                if (data.referenceObjectAnswer.matchingPercentage !== null) {
                    // Brainbay percentage has format 8900 for 89%
                    const percentage = data.referenceObjectAnswer.matchingPercentage / 100;

                    if (percentage > 80) {
                        matchScoreBadge = BadgeContext.BadgeNeutral;
                    } else if (percentage > 50) {
                        matchScoreBadge = BadgeContext.BadgeWorse;
                    } else {
                        matchScoreBadge = BadgeContext.BadgeMuchWorse;
                    }
                }

                return {
                    match_score: matchScoreBadge,
                    price_per_m2: pricePerM2Badge,
                };
            })
        );
    }
}
