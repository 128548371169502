import * as React from 'react';

import {Appraisal} from '../../../../../../models/appraisal';
import {Macro, MacroExternalType} from '../../../../../../models/macro';
import {MacrosPresenter} from './macros_presenter';
import {QuestionSet} from '../../../../../../models/question_set';
import {observer} from 'mobx-react';
import {TextAIContextBuilder} from '../../../../../../business/textai/textai_context_builder';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {MacrosComponent} from './macros_component';

interface OwnProps {
    questionUuid?: string;
    externalType?: MacroExternalType;
    hasAdvancedOptions: boolean;
    parentAnswerUuid: string | null;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    showAddButton: boolean;
    useAI?: boolean | TextAIContextBuilder[];
    onAdd: () => void;
    onUseMacro: (content: string | null) => void;
    onRemove: (macro: Macro) => void;
    onFavorite?: (macro: Macro) => void;
    isActive: (contents: string | null) => boolean;
}

export const Macros: React.FC<OwnProps> = observer((props) => {
    const containerRef = React.useRef<HTMLSpanElement>(null);

    const presenter = usePresenter(
        (container) =>
            new MacrosPresenter(
                props.questionUuid ?? null,
                props.externalType ?? null,
                props.parentAnswerUuid,
                props.questionSet,
                props.appraisal,
                Array.isArray(props.useAI) ? props.useAI : [],
                container.business.macroInteractor(props.questionSet),
                container.business.macroEffectsInteractor(props.appraisal, props.questionSet),
                container.business.textAIInteractor(props.appraisal, props.questionSet),
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.modalOrSecondaryConfigStackInteractor,
                container.business.superMacroInteractor(props.appraisal, props.questionSet),
                containerRef
            )
    );

    const [searchValue, setSearch] = React.useState('');

    const [showSearch, setShowSearch] = React.useState(false);

    const [showHiddenMacros, setShowHiddenMacros] = React.useState(false);

    const words = searchValue.split(' ').map((w) => w.toLowerCase());

    const macros = presenter.visibleMacros.filter((macro) =>
        words.every((w) => macro.contents?.toLowerCase().includes(w))
    );
    const superHiddenMacros = presenter.superHiddenMacros.filter((macro) =>
        words.every((w) => macro.contents?.toLowerCase().includes(w))
    );

    return (
        <MacrosComponent
            containerRef={containerRef}
            allowSearch={true}
            allowAddingMacro={true}
            showSearch={showSearch}
            searchValue={searchValue}
            setSearch={setSearch}
            setShowSearch={setShowSearch}
            showAddButton={props.showAddButton}
            onAddMacro={props.onAdd}
            superHiddenMacros={superHiddenMacros}
            setShowHiddenMacros={setShowHiddenMacros}
            showHiddenMacros={showHiddenMacros}
            useAI={props.useAI}
            isTextAIEnabled={presenter.isTextAIEnabled}
            promptAI={presenter.promptAI}
            textAIResponse={presenter.textAIResponse}
            textAILoading={presenter.textAILoading}
            toggleMacroSettingsWidget={presenter.toggleMacroSettingsWidget}
            macros={macros}
            isActive={props.isActive}
            onUseMacro={props.onUseMacro}
            onFavorite={props.onFavorite}
            hasAdvancedOptions={props.hasAdvancedOptions}
            onRemove={props.onRemove}
        />
    );
});
