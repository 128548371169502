import * as React from 'react';

import {simpleQuestionPresenterFactory, SimpleQuestionPresenterProps} from './simple_question_presenter';

import {AdaptedDefaultValuesMap} from '../../../../../models/adapted_default_values_map';
import {Appraisal} from '../../../../../models/appraisal';
import {QuestionContainer} from '../question_container';
import {QuestionSet} from '../../../../../models/question_set';
import {createValidationMessagesMap} from '../../../../../../support/create_validation_messages_map';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {observer} from 'mobx-react';
import {QuestionLayoutType} from '../../../../../enum/question_layout_type';
import {Question} from '../../../../../models/question';
import {classNames} from '../../../../../../support/classnames';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {QuestionExtensionType} from '../../../../../enum/question_extension_type';
import {ColumnGroupQuestionExtension} from '../../../../../models/question_extension';
import {hasSuperMacros, SuperMacros} from '../extensions/super_macros/super_macros';

interface OwnProps extends SimpleQuestionPresenterProps {
    questionSet: QuestionSet;
    onChange?: (appraisal: Appraisal) => void;
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
}

export const ColumnGroup: React.FC<OwnProps> = observer(function ColumnGroup(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));

    if (presenter.isHidden) {
        return null;
    }

    const answer = presenter.answer;
    if (answer === undefined) {
        return <div>Loading group...</div>;
    }

    function getColumnCount(question: Question) {
        const columnExtension = question.extensions.find(
            (extension) => extension.type === QuestionExtensionType.COLUMN_GROUP
        ) as ColumnGroupQuestionExtension | undefined;
        if (columnExtension) {
            switch (columnExtension.layoutType) {
                case QuestionLayoutType.ONE_COLUMN:
                    return 1;
                case QuestionLayoutType.TWO_COLUMNS:
                    return 2;
                case QuestionLayoutType.THREE_COLUMNS:
                    return 3;
            }
        }
        return 2;
    }

    const childrenForQuestion = findChildrenForQuestion(props.question, props.questionSet);
    const columnCount = getColumnCount(props.question);

    const columnsData = [];
    for (let i = 0; i < childrenForQuestion.length; i += columnCount) {
        columnsData.push(childrenForQuestion.slice(i, i + columnCount));
    }

    const superMacros = hasSuperMacros(props.question);

    return (
        <div
            className={classNames('group-container columns columns-' + columnCount, {
                'super-macro-group': superMacros,
            })}
        >
            {superMacros && <SuperMacros {...props} />}
            {columnsData.map((questions, key) => {
                return (
                    <div className="row" key={key}>
                        {questions.map((child, nth) => (
                            <div
                                key={child.uuid}
                                className={classNames(`col-12 col-lg-${12 / columnCount}`, {
                                    'col-md-6': columnCount < 3 || (columnCount === 3 && nth < 2),
                                    'col-md-12': columnCount === 3 && nth === 2,
                                })}
                            >
                                <QuestionContainer
                                    key={child.uuid}
                                    disabled={props.disabled}
                                    appraisal={props.appraisal}
                                    question={child}
                                    onChange={props.onChange}
                                    questionSet={props.questionSet}
                                    validationErrors={props.validationErrors}
                                    validationMessages={createValidationMessagesMap(
                                        presenter.validationMessages,
                                        props.validationMessages
                                    )}
                                    forceShowValidationMessages={props.forceShowValidationMessages}
                                    parentAnswerUuid={answer.uuid}
                                    adaptedDefaultValues={props.adaptedDefaultValues}
                                    disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                                    hiddenQuestionTypes={props.hiddenQuestionTypes}
                                    pagePartsSet={props.pagePartsSet}
                                    activePagePart={props.activePagePart}
                                    renderingContext={props.renderingContext}
                                    questionRenderingData={props.questionRenderingData}
                                />
                            </div>
                        ))}
                    </div>
                );
            })}
        </div>
    );
});
