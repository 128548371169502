import * as React from 'react';

import {Appraisal} from '../../../../../../models/appraisal';
import {observer} from 'mobx-react';
import {RenderingContextType} from '../../../../../../enum/rendering_context_type';
import {BouwkundigeStaatViewerDummy} from './bouwkundige_staat_viewer_dummy';
import {ReportIframeViewer} from '../report_viewer/report_iframe_viewer';
import {GeneratedReportType} from '../../../../../../enum/generated_report';
import {QuestionSet} from '../../../../../../models/question_set';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    renderingContext: RenderingContextType;
}

export const BouwkundigeStaatViewer: React.FunctionComponent<OwnProps> = observer((props) => {
    if (props.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
        return <BouwkundigeStaatViewerDummy />;
    }
    return (
        <ReportIframeViewer
            questionSet={props.questionSet}
            appraisal={props.appraisal}
            reportType={GeneratedReportType.BOUWKUNDIGE_STAAT}
        />
    );
});
