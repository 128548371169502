import {ObjectType} from '../../enum/object_type';

export function isApartment(objectType: string | undefined) {
    if (objectType === undefined) {
        return false;
    }

    const apartmentTypes = [
        ObjectType.BENEDENWONING,
        ObjectType.BOVENWONING,
        ObjectType.CORRIDORFLAT,
        ObjectType.GALERIJFLAT,
        ObjectType.MAISONNETTE,
        ObjectType.PORTIEKFLAT,
        ObjectType.PORTIEKWONING,
    ] as string[];

    return apartmentTypes.includes(objectType);
}
