import {action, makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../../../../../../support/composite_subscription';
import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {AnswerController} from '../../../../../../../../../business/answering/answer_controller';
import {ChildQuestionValidationProvider} from '../../../../../../../../../business/validation/child_validation_provider';
import {ValidationMessage} from '../../../../../../../../../business/validation/validation_message';
import {Question} from '../../../../../../../../../models/question';

export class ReferenceObjectAnswerModalPresenter implements Presenter {
    @observable public validationMessages: ValidationMessage[] = [];
    @observable public forceShowValidationMessages = false;

    private subscriptions = new CompositeSubscription();

    constructor(
        private question: Question,
        private parentAnswerUuid: string,
        private answerController: AnswerController,
        private childValidationProvider: ChildQuestionValidationProvider
    ) {
        makeObservable(this);
    }

    public mount(): void {
        const answer = this.answerController.answerByIdentifiers(this.question.uuid, this.parentAnswerUuid, null);
        if (answer === null) {
            throw new Error('No valid answer');
        }

        this.subscriptions.add(
            this.childValidationProvider.validate(this.question.uuid, answer.uuid).subscribe((validationMessages) => {
                if (validationMessages.length === 0 && this.validationMessages.length === 0) {
                    return;
                }
                runInAction(() => {
                    this.validationMessages = validationMessages;
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    public onForceShowValidationMessages() {
        this.forceShowValidationMessages = true;
    }
}
