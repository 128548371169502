import * as React from 'react';

import {CSS} from '@dnd-kit/utilities';
import {PagePart} from '../../../models/page_part';
import {PagePartsSet} from '../../../models/page_parts_set';
import {RootSidebarItem} from './root_sidebar_item';
import {SortableChildren} from './sortable_children';
import {useSortable} from '@dnd-kit/sortable';

interface OwnProps {
    pagePart: PagePart;
    pagePartsSet: PagePartsSet;
    activePagePartUuid: string | null;
    onNewSubPage: (parent: PagePart) => void;
    updateRank: (newRank: number, uuid: string) => void;
    onDelete: (uuid: string) => void;
}

export const SortableRootItem: React.FunctionComponent<OwnProps> = ({
    pagePart,
    pagePartsSet,
    activePagePartUuid,
    onNewSubPage,
    updateRank,
    onDelete,
}) => {
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: pagePart.uuid});

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    const childPageParts = pagePartsSet.getChildrenForUuid(pagePart.uuid);
    const isActiveOrHasActiveChild =
        pagePart.uuid === activePagePartUuid || childPageParts.some((child) => child.uuid === activePagePartUuid);

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <RootSidebarItem
                uuid={pagePart.uuid}
                pagePartsConfigurationId={pagePartsSet.id}
                icon={pagePart.icon ? 'c-icon-' + pagePart.icon : 'c-icon-group'}
                isActive={isActiveOrHasActiveChild}
                label={pagePart.name ?? pagePart.uuid}
                onDelete={onDelete}
            >
                {pagePart.isIndexPage === true && (
                    <>
                        <div>
                            <SortableChildren
                                pagePartsConfigurationId={pagePartsSet.id}
                                childPageParts={childPageParts}
                                activePagePartUuid={activePagePartUuid}
                                updateRank={updateRank}
                                onDelete={onDelete}
                            />
                        </div>
                        {pagePartsSet?.config.isEditable == true && (
                            <div title="Nieuwe sub-pagina" className="child-item card-progress-medium">
                                <button className="tree-button ion-md-add" onClick={() => onNewSubPage(pagePart)}>
                                    <span className="title">
                                        <em>Sub-pagina</em>
                                    </span>
                                </button>
                            </div>
                        )}
                    </>
                )}
            </RootSidebarItem>
        </div>
    );
};
