import * as React from 'react';

import GoogleMapReact, {Bounds} from 'google-map-react';
import {Location, getCenterLocation} from '../internal/center_location';

import {ApiGlobal} from '../../../../../../../../../business/global_provider';
import {Appraisal} from '../../../../../../../../models/appraisal';
import {Cluster} from './reference_object_map_cluster';
import {Pointer} from './reference_object_map_pointer';
import {ReferenceObjectMapDetails} from './reference_object_map_details';
import {ReferenceSale} from '../models/reference_sale';
import Supercluster from 'supercluster';
import {VisibleReferenceSale} from '../reference_objects_question_presenter';
import {mapStyles} from '../internal/map_styles';

declare let GLOBAL: ApiGlobal;

export interface ReferenceObjectMapProps {
    appraisal: Appraisal;
    visibleReferenceSales: VisibleReferenceSale[];
    onHoverChange: (referenceSale: ReferenceSale | null) => void;
    onClickChange: (referenceSale: ReferenceSale | null) => void;
    clickedReferenceSale: ReferenceSale | null;
    hoveringReferenceSale: ReferenceSale | null;
    showDetailsModal: (referenceSale: ReferenceSale) => void;
}

interface OwnState {
    centerLocation: Location;
    initialCenterLocation: Location;
    bounds: null | Bounds;
}

interface GloogleMap {
    setZoom(zoom: number): void;
    panTo(coords: {lat: number; lng: number}): void;
}

export class ReferenceObjectMap extends React.Component<ReferenceObjectMapProps, OwnState> {
    private mapRef: null | GloogleMap = null;

    public state: OwnState = {
        centerLocation: getCenterLocation(
            {
                lat: this.props.appraisal.latitude || 0,
                lng: this.props.appraisal.longitude || 0,
            },
            this.props.visibleReferenceSales.map(({referenceSale}) => ({
                lat: referenceSale.latitude,
                lng: referenceSale.longitude,
            }))
        ),
        initialCenterLocation: getCenterLocation(
            {
                lat: this.props.appraisal.latitude || 0,
                lng: this.props.appraisal.longitude || 0,
            },
            this.props.visibleReferenceSales.map(({referenceSale}) => ({
                lat: referenceSale.latitude,
                lng: referenceSale.longitude,
            }))
        ),
        bounds: null,
    };

    public componentDidUpdate(prevProps: ReferenceObjectMapProps) {
        const clickedReferenceSale = this.props.clickedReferenceSale;
        const previousClickedId = prevProps.clickedReferenceSale ? prevProps.clickedReferenceSale.id : null;

        if (clickedReferenceSale !== null && clickedReferenceSale.id !== previousClickedId && this.mapRef !== null) {
            this.mapRef.setZoom(19);
            this.mapRef.panTo({lat: clickedReferenceSale.latitude, lng: clickedReferenceSale.longitude});
        }

        if (this.mapRef && this.props.appraisal.latitude && this.props.appraisal.longitude) {
            const initialCenterLocation = getCenterLocation(
                {
                    lat: this.props.appraisal.latitude,
                    lng: this.props.appraisal.longitude,
                },
                this.props.visibleReferenceSales.map(({referenceSale}) => ({
                    lat: referenceSale.latitude,
                    lng: referenceSale.longitude,
                }))
            );

            if (
                initialCenterLocation.zoom !== this.state.initialCenterLocation.zoom ||
                initialCenterLocation.center.lat !== this.state.initialCenterLocation.center.lat ||
                initialCenterLocation.center.lng !== this.state.initialCenterLocation.center.lng
            ) {
                this.setState({initialCenterLocation});
                this.mapRef.setZoom(initialCenterLocation.zoom);
                this.mapRef.panTo(initialCenterLocation.center);
            }
        }
    }

    private zoomToCluster(
        superCluster: Supercluster,
        cluster: Supercluster.ClusterFeature<Supercluster.AnyProps> | Supercluster.PointFeature<Supercluster.AnyProps>
    ) {
        if (this.mapRef === null) {
            return;
        }

        if (cluster.id) {
            const expansionZoom = Math.min(superCluster.getClusterExpansionZoom(cluster.id as number), 22);
            const [longitude, latitude] = cluster.geometry.coordinates;
            this.mapRef.setZoom(expansionZoom);
            this.mapRef.panTo({lat: latitude, lng: longitude});
        }
    }

    private createMapOptions(maps: GoogleMapReact.Maps) {
        return {
            styles: mapStyles,
            zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_CENTER,
                style: maps.ZoomControlStyle.SMALL,
            },
            mapTypeControl: false,
            panControl: true,
            disableDefaultUI: false,
            draggable: true,
            fullscreenControl: false,
            gestureHandling: 'greedy',
        };
    }

    private calculateClusters(superCluster: Supercluster, visibleReferenceSales: VisibleReferenceSale[]) {
        superCluster.load(
            visibleReferenceSales.map((visibleReferenceSale) => {
                return {
                    type: 'Feature' as const,
                    geometry: {
                        type: 'Point' as const,
                        coordinates: [
                            visibleReferenceSale.referenceSale.longitude,
                            visibleReferenceSale.referenceSale.latitude,
                        ],
                    },
                    properties: visibleReferenceSale,
                };
            })
        );
        return superCluster.getClusters(
            this.state.bounds
                ? [
                      this.state.bounds.nw.lng,
                      this.state.bounds.se.lat,
                      this.state.bounds.se.lng,
                      this.state.bounds.nw.lat,
                  ]
                : [0, 0, 0, 0],
            Math.round(this.state.centerLocation.zoom)
        );
    }

    public render() {
        if (
            GLOBAL.google_maps_api_key === undefined ||
            GLOBAL.google_maps_api_key === null ||
            !this.props.appraisal.latitude ||
            !this.props.appraisal.longitude
        ) {
            return null;
        }

        const centerLocation = getCenterLocation(
            {
                lat: this.props.appraisal.latitude,
                lng: this.props.appraisal.longitude,
            },
            []
        );

        const superCluster = new Supercluster({
            radius: 180,
            maxZoom: 19,
        });

        const clusters = this.calculateClusters(superCluster, this.props.visibleReferenceSales);

        return (
            <div className="reference-object-map-container">
                <div className="reference-object-map-title">
                    <h2>Object locaties</h2>
                </div>

                {this.props.clickedReferenceSale !== null && (
                    <ReferenceObjectMapDetails
                        onClickChange={this.props.onClickChange}
                        referenceSale={this.props.clickedReferenceSale}
                        visibleReferenceSales={this.props.visibleReferenceSales}
                        showDetailsModal={this.props.showDetailsModal}
                    />
                )}

                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: GLOBAL.google_maps_api_key,
                        language: 'nl',
                    }}
                    options={this.createMapOptions}
                    defaultZoom={centerLocation.zoom}
                    defaultCenter={centerLocation.center}
                    onChange={({zoom, bounds}) => {
                        this.setState((oldState) => ({
                            centerLocation: {
                                center: oldState.centerLocation.center,
                                zoom,
                            },
                            bounds,
                        }));
                    }}
                    yesIWantToUseGoogleMapApiInternals={true}
                    shouldUnregisterMapOnUnmount={true}
                    onGoogleApiLoaded={({map}) => {
                        this.mapRef = map;
                    }}
                >
                    <Pointer
                        lat={this.props.appraisal.latitude}
                        lng={this.props.appraisal.longitude}
                        isHovering={false}
                        isBaseObject={true}
                    />

                    {clusters.map((cluster) => {
                        if (cluster.properties.cluster && cluster.id) {
                            const childIds = superCluster
                                .getLeaves(cluster.id as number, Infinity)
                                .map((point) => point.properties.referenceSale.id);
                            const isHoveringChild =
                                this.props.hoveringReferenceSale !== null &&
                                childIds.includes(this.props.hoveringReferenceSale.id);

                            return (
                                <Cluster
                                    key={cluster.id}
                                    lng={cluster.geometry.coordinates[0]}
                                    lat={cluster.geometry.coordinates[1]}
                                    pointCount={cluster.properties.point_count}
                                    isHovering={isHoveringChild}
                                    onClick={() => this.zoomToCluster(superCluster, cluster)}
                                />
                            );
                        }

                        const {referenceSale, referenceObjectAnswer} = cluster.properties;
                        return (
                            <Pointer
                                key={referenceSale.id}
                                lng={referenceSale.longitude}
                                lat={referenceSale.latitude}
                                referenceSale={referenceSale}
                                referenceObjectAnswer={referenceObjectAnswer}
                                isHovering={this.props.hoveringReferenceSale === referenceSale}
                                onClick={() => this.props.onClickChange(referenceSale)}
                                onMouseEnter={() => this.props.onHoverChange(referenceSale)}
                                onMouseLeave={() => this.props.onHoverChange(null)}
                                className={
                                    this.props.clickedReferenceSale === referenceSale || referenceObjectAnswer !== null
                                        ? 'pointer-selected'
                                        : 'pointer-default'
                                }
                                isBaseObject={false}
                            />
                        );
                    })}
                </GoogleMapReact>
            </div>
        );
    }
}
