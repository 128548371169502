import {AppraiseSecondaryConfig} from '../models/appraise_secondary_config';
import {AppraiseSecondaryConfigStackInteractor} from './appraise_secondary_config_stack_interactor';
import {ModalConfig} from '../models/modal_config';
import {ModalConfigStackInteractor} from './modal_config_stack_interactor';
import {isCompact} from '../../support/check_mobile';

export class ModalOrSecondaryConfigStackInteractor {
    constructor(
        private modalConfigStackInteractor: ModalConfigStackInteractor,
        private appraiseSecondaryConfigStackInteractor: AppraiseSecondaryConfigStackInteractor
    ) {}

    public upsert(modalConfig: ModalConfig, appraiseSecondaryConfig: AppraiseSecondaryConfig) {
        // If there is already a modal shown, we prefer to show a modal instead (instead of the secondary appearing behind the modal)
        // We need to check this using the '.modal.show' element check, as not all modals are shown via the regular modal stack (e.g. reference object modals)
        if (isCompact() || document.querySelector('.modal.show') !== null) {
            this.modalConfigStackInteractor.remove((c) => c.type === modalConfig.type && c.id !== modalConfig.id);
            this.modalConfigStackInteractor.upsert(modalConfig);
        } else {
            this.appraiseSecondaryConfigStackInteractor.remove(
                (c) => c.type === appraiseSecondaryConfig.type && c.id !== appraiseSecondaryConfig.id
            );
            this.appraiseSecondaryConfigStackInteractor.upsert(appraiseSecondaryConfig);
        }
    }

    public remove(id: string) {
        this.appraiseSecondaryConfigStackInteractor.remove((c) => c.id === id);
        this.modalConfigStackInteractor.remove((c) => c.id === id);
    }

    public removeByPredicate(
        appraiseSecondaryPredicate: string | number | ((config: AppraiseSecondaryConfig) => boolean),
        modalPredicate: string | number | ((config: ModalConfig) => boolean)
    ) {
        this.appraiseSecondaryConfigStackInteractor.remove(appraiseSecondaryPredicate);
        this.modalConfigStackInteractor.remove(modalPredicate);
    }
}
