import * as React from 'react';

import {classNames} from '../../../../support/classnames';

interface OwnProps {
    isSmall?: boolean;
    addSpace?: boolean;
    icon?: string;
}

export class Loader extends React.Component<OwnProps> {
    public render() {
        return (
            <>
                {(this.props.isSmall === undefined || !this.props.isSmall) &&
                this.props.addSpace !== undefined &&
                this.props.addSpace ? (
                    <span className="spacer">&nbsp;</span>
                ) : null}
                <div
                    className={classNames('loader', {
                        'loader-small': this.props.isSmall ?? false,
                    })}
                >
                    {this.props.icon ? (
                        <span className={classNames('loader-icon', this.props.icon)}>&nbsp;</span>
                    ) : null}
                    <span>&nbsp;</span>
                </div>
            </>
        );
    }
}
