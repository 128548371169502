import {BuildYearProvider} from '../../../../../../../../../business/build_year_provider';
import {ReferenceObjectSortingStrategy} from './reference_object_sorting_strategy';
import {ReferenceSale} from '../../models/reference_sale';
import {SortingDirection} from '../../../../../../../../../enum/reference_objects_sorting';

interface ReferenceSaleWithRequiredAttributes extends ReferenceSale {
    buildYear: number;
    plotArea: number;
    floorArea: number;
}

export class ReferenceObjectSorterByDeviationScore implements ReferenceObjectSortingStrategy {
    constructor(
        private buildYearProvider: BuildYearProvider,
        private gebruiksoppervlakteWonen: number | null,
        private perceelOppervlakte: number | null
    ) {}

    public sortReferenceObjects(referenceSales: ReferenceSale[], sortingDirection: SortingDirection): ReferenceSale[] {
        // make array of custom reference sales
        const customReferenceObjects = referenceSales.filter((referenceSale: ReferenceSale) =>
            referenceSale.id.includes('custom')
        );

        // make array of reference sales with all the required properties
        const referenceSalesWithRequiredAttributes = referenceSales.filter(
            (referenceSale: ReferenceSale): referenceSale is ReferenceSaleWithRequiredAttributes =>
                !referenceSale.id.includes('custom') &&
                referenceSale.buildYear !== null &&
                referenceSale.plotArea !== null &&
                referenceSale.floorArea !== null
        );

        // make array of reference sales with at least one of the required attributes missing
        const referenceSalesWithoutNormalizedSaleDate = referenceSales.filter(
            (referenceSale: ReferenceSale) =>
                !referenceSale.id.includes('custom') &&
                referenceSale.buildYear === null &&
                referenceSale.plotArea === null &&
                referenceSale.floorArea === null
        );

        // sort the reference objects with all required attributes
        const sortedReferenceObjects: ReferenceSale[] = referenceSalesWithRequiredAttributes.sort(
            (referenceSale1, referenceSale2) => {
                return this.getDeviationScoreWithAppraisalForReferenceSale(referenceSale1) >
                    this.getDeviationScoreWithAppraisalForReferenceSale(referenceSale2)
                    ? sortingDirection === SortingDirection.DESCENDING
                        ? -1
                        : 1
                    : sortingDirection === SortingDirection.DESCENDING
                    ? 1
                    : -1;
            }
        );

        // put the invalid reference sales at the end
        return [...customReferenceObjects, ...sortedReferenceObjects, ...referenceSalesWithoutNormalizedSaleDate];
    }

    private getDeviationScoreWithAppraisalForReferenceSale(referenceSale: ReferenceSaleWithRequiredAttributes): number {
        let appraisalBuildYear = this.buildYearProvider.getDestructionYear();
        if (appraisalBuildYear === null) {
            appraisalBuildYear = this.buildYearProvider.get();
        }

        // cannot be null as the reference object view cannot be opened when build year is not known
        if (appraisalBuildYear === null || this.gebruiksoppervlakteWonen === null || this.perceelOppervlakte === null) {
            return 0;
        }

        const buildYearDeviationScore = this.getBuildYearDeviationScore(appraisalBuildYear, referenceSale.buildYear);
        const floorAreaDeviationScore = this.getAreaDeviationScore(
            this.gebruiksoppervlakteWonen,
            referenceSale.floorArea
        );
        const lotAreaDeviationScore = this.getAreaDeviationScore(this.perceelOppervlakte, referenceSale.plotArea);

        return buildYearDeviationScore + floorAreaDeviationScore + lotAreaDeviationScore;
    }

    private getBuildYearDeviationScore(appraisalBuildYear: number, referenceSaleBuildYear: number): number {
        const difference = Math.abs(appraisalBuildYear - referenceSaleBuildYear);

        if (difference < 10) {
            return 0;
        } else if (difference < 20) {
            return 2;
        } else {
            return 5;
        }
    }

    private getAreaDeviationScore(appraisalArea: number, referenceSaleArea: number): number {
        const difference = Math.abs(appraisalArea - referenceSaleArea);

        if (difference < 10) {
            return 0;
        } else if (difference < 30) {
            return 2;
        } else {
            return 5;
        }
    }
}
