import {useEffect, useRef, useState} from 'react';

import {Presenter} from './presenter';

//Used to extract the parameters of the 'onUpdatedProps' method inside the presenter.
type OnPropsArguments<T> = T extends (...args: infer P) => unknown ? P : void;

//If the above type extracted parameters this returns a tuple that includes those as second argument.
//This way we need to either only pass the presenter in the hook or the presenter + the props.
type Params<TProps, TContainer, TPresenter> = TProps extends void
    ? [createPresenterCallback: (container: TContainer) => TPresenter]
    : [createPresenterCallback: (container: TContainer) => TPresenter, props: TProps];

export function presenterHookFactory<TContainer>(container: TContainer) {
    return function usePresenter<
        TPresenter extends Presenter,
        TProps extends OnPropsArguments<TPresenter['onUpdatedProps']>
    >(...[createPresenterCallback, props]: Params<TProps, TContainer, TPresenter>): TPresenter {
        const [presenter] = useState<TPresenter>(() => createPresenterCallback(container));
        const didMount = useRef(false);

        useEffect(() => {
            if (didMount.current === true && presenter.onUpdatedProps && props && Array.isArray(props)) {
                presenter.onUpdatedProps(...props);
            }
        }, [presenter, ...(props ?? [])]);

        useEffect(() => {
            if (presenter.mount && didMount.current === false) {
                didMount.current = true;
                presenter.mount();
            }
            return () => {
                if (presenter.unmount) {
                    didMount.current = false;
                    presenter.unmount();
                }
            };
        }, [presenter]);

        return presenter;
    };
}
