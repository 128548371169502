import * as React from 'react';

import {Appraisal} from '../../../../../models/appraisal';
import {AppraisalDetailsPresenter} from './appraisal_details_presenter';
import {Label} from '../components/label';
import {ObjectType} from '../../../../../enum/object_type';
import {ObjectTypeSelect} from '../components/object_type_select';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {isEmpty} from '../../../../../../support/util';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';

interface OwnProps {
    appraisal: Appraisal;
    question: Question;
    parentAnswerUuid: string | undefined;
    iteration: string | undefined;
    questionSet: QuestionSet;
    onChange: (appraisal: Appraisal) => void;
    disabled?: boolean;
    hideLabel?: boolean;
    renderingContext: RenderingContextType;
}

export const AppraisalDetails: React.FC<OwnProps> = observer(function AppraisalDetails(props) {
    const presenter = usePresenter(
        (container) =>
            new AppraisalDetailsPresenter(
                props.appraisal,
                props.question,
                props.onChange,
                container.network.appraisalApi
            )
    );

    return (
        <div>
            <div className="form-group" data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}>
                <Label
                    for="object_type"
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <ObjectTypeSelect
                    disabled={props.disabled || presenter.isDisabled}
                    value={isEmpty(props.appraisal.objectType) ? '-1' : props.appraisal.objectType}
                    onChange={(value: ObjectType) => presenter.onObjectTypeChange(value, props.appraisal)}
                />
            </div>
        </div>
    );
});
