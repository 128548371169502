import {
    ReferenceObjectDataWithSet,
    createReferenceObjectDataForAnswer,
} from '../../internal/create_reference_object_data';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {AnswerInteractor} from '../../../../../../../../../business/answering/answer_interactor';
import {AttachmentQuestionPresenter} from '../../../../attachment_question_presenter';
import {AttachmentQuestionsInteractor} from '../../../../../../../../../business/attachment_questions_interactor';
import {BlobCacheInteractor} from '../../../../../../../../../business/attachments/blob_cache_interactor';
import {CompositeSubscription} from '../../../../../../../../../../support/composite_subscription';
import {FlashMessageBroadcaster} from '../../../../../../../../../business/flash_message_broadcaster';
import {GlobalProvider} from '../../../../../../../../../../business/global_provider';
import {ImageUploadInteractor} from '../../../../../../../../../business/attachments/image_upload_interactor';
import {SimpleQuestionPresenter} from '../../../../../simple/simple_question_presenter';
import {V3ReferenceSetsProvider} from '../../internal/reference_sets/reference_sets_provider';
import {filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {fetchReferenceObjectPhoto} from './internal/fetch_reference_object_photo';
import {AutomatorInteractor} from '../../../../../../../../../business/automator_interactor';
import {ReferenceSaleImage} from '../../../v1/models/reference_sale_image';
import {ImageType} from '../../../../../../../../../components/image_viewer/image_viewer';
import {ReferenceObjectsAnswerEnhancementInteractor} from '../../../interactors/reference_objects_answer_enhancement_interactor';
import {ReferenceSale} from '../../../v1/models/reference_sale';

export class ReferenceObjectPhotoQuestionPresenter extends AttachmentQuestionPresenter {
    @observable public currentImage: ReferenceSaleImage | null = null;
    @observable public referenceObjectDataWithSet: ReferenceObjectDataWithSet | null = null;
    @observable public enhancedReferenceObject: ReferenceSale | null = null;

    @observable private uploadingSelection = false;

    @computed
    public get busy() {
        return this.loading || this.uploadingSelection;
    }

    private subscriptions = new CompositeSubscription();

    constructor(
        fileTypes: string[],
        blobCacheInteractor: BlobCacheInteractor,
        imageUploadInteractor: ImageUploadInteractor,
        answerInteractor: AnswerInteractor,
        flashMessageBroadcaster: FlashMessageBroadcaster,
        globalProvider: GlobalProvider,
        attachmentQuestionsInteractor: AttachmentQuestionsInteractor,
        automatorInteractor: AutomatorInteractor,
        private referenceSetsProvider: V3ReferenceSetsProvider,
        private referenceObjectsAnswerEnhancementInteractor: ReferenceObjectsAnswerEnhancementInteractor,
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(
            fileTypes,
            globalProvider,
            blobCacheInteractor,
            imageUploadInteractor,
            answerInteractor,
            attachmentQuestionsInteractor,
            flashMessageBroadcaster,
            automatorInteractor,
            ...simpleQuestionPresenterParameters
        );
        makeObservable(this);
    }

    public mount() {
        super.mount();

        const referenceObjectDataStream = this.referenceSetsProvider.referenceSets().pipe(
            map((referenceSets) => {
                return createReferenceObjectDataForAnswer(
                    referenceSets,
                    this.question.uuid,
                    this.parentAnswerUuid ?? null
                );
            }),
            shareReplay(1)
        );

        this.subscriptions.add(
            referenceObjectDataStream.subscribe((referenceObjectDataWithSet) => {
                runInAction(() => {
                    this.referenceObjectDataWithSet = referenceObjectDataWithSet;
                });
            })
        );

        this.subscriptions.add(
            referenceObjectDataStream
                .pipe(
                    filter(
                        (referenceObjectDataWithSet): referenceObjectDataWithSet is ReferenceObjectDataWithSet =>
                            referenceObjectDataWithSet !== null
                    ),
                    switchMap((referenceObjectDataWithSet) =>
                        this.referenceObjectsAnswerEnhancementInteractor.streamForAnswer(
                            referenceObjectDataWithSet?.data.referenceObjectAnswer
                        )
                    )
                )
                .subscribe((enhancedReferenceObject) => {
                    runInAction(() => {
                        this.enhancedReferenceObject = enhancedReferenceObject;
                    });
                })
        );
    }

    public unmount() {
        super.unmount();

        this.subscriptions.clear();
    }

    @action
    public setCurrentImage(image: ReferenceSaleImage | ImageType, images: ReferenceSaleImage[]) {
        let url: string | null = null;
        if (typeof image === 'string') {
            url = image;
        } else if ('uncompressedUrl' in image && image.uncompressedUrl) {
            url = image.uncompressedUrl;
        } else if ('url' in image && image.url) {
            url = image.url;
        }

        if (url === null) {
            return;
        }

        const referenceSaleImage = images.find((i) => i.url === url);
        if (!referenceSaleImage) {
            this.currentImage = {
                key: null,
                url: url,
                confidence: null,
                date: null,
                source: null,
                label: null,
            };
        } else {
            this.currentImage = referenceSaleImage;
        }
    }

    public async onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        await super.onFileChange(e);
        this.updateCurrentImage();
    }

    public async onSelectionChange() {
        runInAction(() => {
            this.uploadingSelection = true;
        });

        try {
            if (!this.currentImage?.url || this.currentImage.url.trim().length === 0) {
                return;
            }
            const file = await fetchReferenceObjectPhoto(this.currentImage.url);
            if (file) {
                await this.onChangeFile(file);
                this.updateCurrentImage();
            }
        } finally {
            runInAction(() => {
                this.uploadingSelection = false;
            });
        }
    }

    @action
    private updateCurrentImage() {
        this.currentImage = {
            key: this.answer?.file?.path ?? null,
            url: this.url,
            confidence: null,
            date: null,
            source: null,
            label: null,
        };
    }
}
