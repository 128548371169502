import {ServerTime, ServerTimeInteractor} from './server_time_interactor';
import {removeMilliseconds} from '../../support/floor_seconds';
import {map, tap} from 'rxjs/operators';

import {Observable} from 'rxjs';
import {TimeProvider} from './time_provider';

export interface ServerTimeProvider {
    date: Date;

    dates(): Observable<Date>;
}

export class ServerTimeProviderImpl implements ServerTimeProvider {
    private _serverTimeInteractor: ServerTimeInteractor;
    private _timeProvider: TimeProvider;
    private _lastKnownServerTime: ServerTime | undefined;

    public get date(): Date {
        const serverTime = this._lastKnownServerTime;
        if (serverTime === undefined) {
            throw new Error('Static time requested before hydrating');
        }
        return new Date(this._timeProvider.getTime() - serverTime.offsetMillis);
    }

    constructor(serverTimeInteractor: ServerTimeInteractor, timeProvider: TimeProvider) {
        this._serverTimeInteractor = serverTimeInteractor;
        this._timeProvider = timeProvider;
    }

    public dates(): Observable<Date> {
        return this._serverTimeInteractor.serverTimes().pipe(
            tap((serverTime: ServerTime) => {
                this._lastKnownServerTime = serverTime;
            }),
            map((serverTime: ServerTime) => {
                return removeMilliseconds(new Date(this._timeProvider.getTime() - serverTime.offsetMillis));
            })
        );
    }
}
