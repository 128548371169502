export enum GeneratedReportType {
    CONCEPT_REPORT = 'concept-report',
    BOUWKUNDIGE_STAAT = 'bouwkundige-staat',
    ENERGETISCHE_STAAT = 'energetische-staat',
    CONSTRUCTION_SPECIFICATION = 'construction-specification',
    UNIFORM_MEASUREMENT = 'uniform-measurement',
}

export enum GeneratedReportFormat {
    HTML = 'html',
    PDF = 'pdf',
}
