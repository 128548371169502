import 'blueimp-canvas-to-blob';

import {bugsnagClient} from '../../support/bugsnag_client';
import {canvasToFile} from '../../support/create_file';
import loadImage from 'blueimp-load-image';
import {normalizeString} from '../../support/normalize_string';

interface CompressorOptionsBase {
    quality: number;
    maxHeight: number;
    maxWidth: number;
}

interface CompressorOptionsWithAspectRatio extends CompressorOptionsBase {
    landscapeAspectRatio: number;
    portraitAspectRatio: number;
}

interface CompressorOptionsWithContain extends CompressorOptionsBase {
    contain: true;
}

export type CompressorOptions = CompressorOptionsBase | CompressorOptionsWithAspectRatio | CompressorOptionsWithContain;

export class ImageCompressor {
    public async compress(originalFile: File, options: CompressorOptions): Promise<File> {
        try {
            if (!originalFile.type.includes('image/')) {
                return originalFile;
            }

            const data = await loadImage(originalFile, {
                meta: true,
                canvas: true,
                orientation: true,
            });

            const scaledImage = this.scaleImage(data, options);

            const file = await canvasToFile(
                scaledImage,
                data,
                normalizeString(originalFile.name),
                originalFile.lastModified,
                options.quality
            );
            try {
                if (file) {
                    return file;
                } else {
                    bugsnagClient?.notify(new Error('image_compressor: canvasToFile failed'), {
                        metaData: {
                            source: 'image_compressor.ts compress()',
                            img: scaledImage.toString(),
                            typeofImg: typeof scaledImage,
                        },
                    });
                    return originalFile;
                }
            } catch (error) {
                console.error('image_compressor', error);
                bugsnagClient?.notify(error, {
                    metaData: {
                        source: 'image_compressor.ts compress()',
                        img: scaledImage.toString(),
                        typeofImg: typeof scaledImage,
                    },
                });
                return originalFile;
            }
        } catch (error) {
            console.error('image_compressor', error);
            bugsnagClient?.notify(error, {
                metaData: {
                    source: 'image_compressor.ts compress()',
                },
            });
            return originalFile;
        }
    }

    private scaleImage(
        data: loadImage.LoadImageResult,
        options: CompressorOptions
    ): HTMLImageElement | HTMLCanvasElement {
        try {
            type ScaleFunction = (
                file: HTMLImageElement | HTMLCanvasElement,
                options: loadImage.CropOptions & loadImage.BasicOptions & loadImage.CanvasOptions
            ) => HTMLImageElement | HTMLCanvasElement;
            const scaleFunction: ScaleFunction = (loadImage as unknown as {scale: ScaleFunction}).scale;

            if (
                data.originalWidth &&
                data.originalHeight &&
                'landscapeAspectRatio' in options &&
                'portraitAspectRatio' in options &&
                options.landscapeAspectRatio &&
                options.portraitAspectRatio
            ) {
                if (data.originalWidth > data.originalHeight) {
                    // landscape mode
                    if (data.originalWidth / data.originalHeight > options.landscapeAspectRatio) {
                        return scaleFunction(data.image, {
                            crop: true,
                            cover: true,
                            maxHeight: options.maxHeight,
                            maxWidth: options.maxHeight * options.landscapeAspectRatio,
                        });
                    } else {
                        return scaleFunction(data.image, {
                            crop: true,
                            cover: true,
                            maxHeight: options.maxWidth / options.landscapeAspectRatio,
                            maxWidth: options.maxWidth,
                        });
                    }
                } else {
                    // portrait mode
                    if (data.originalHeight / data.originalWidth > options.portraitAspectRatio) {
                        return scaleFunction(data.image, {
                            crop: true,
                            cover: true,
                            maxHeight: options.maxHeight / options.portraitAspectRatio,
                            maxWidth: options.maxHeight,
                        });
                    } else {
                        return scaleFunction(data.image, {
                            crop: true,
                            cover: true,
                            maxHeight: options.maxWidth,
                            maxWidth: options.maxWidth * options.portraitAspectRatio,
                        });
                    }
                }
            }

            if ('contain' in options && options.contain === true) {
                return scaleFunction(data.image, {
                    crop: false,
                    canvas: true,
                    contain: true,
                    maxHeight: options.maxHeight,
                    maxWidth: options.maxWidth,
                });
            } else {
                return scaleFunction(data.image, {
                    crop: true,
                    canvas: true,
                    cover: true,
                    maxHeight: options.maxHeight,
                    maxWidth: options.maxWidth,
                });
            }
        } catch (error) {
            console.error('image_compressor.cropImage()', error);
            bugsnagClient?.notify(error, {
                metaData: {
                    source: 'image_compressor.ts cropImage()',
                },
            });
            return data.image;
        }
    }
}
