import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';

/**
 * Find all children for a question in their correct order
 * @param question
 * @param questionSet
 * @return Question[]
 */
export function findChildrenForQuestion(question: Question, questionSet: QuestionSet): Question[] {
    return questionSet.findChildQuestionsByParentUuid(question.uuid).sort(questionSortCompareFn);
}

/**
 * Find all children for a question
 * @param questionUuid
 * @param questionSet
 * @return Question[]
 */
export function findChildrenForQuestionUuid(questionUuid: string, questionSet: QuestionSet): Question[] {
    return questionSet.questions.filter((potentialChild) => potentialChild.parentUuid === questionUuid);
}

/**
 * Find deep children
 * @param questionUuid
 * @param questionSet
 * @return Question[]
 */
export function findAllChildrenForQuestionUuid(
    questionUuid: string,
    questionSet: QuestionSet,
    blacklistUuids?: string[]
): Question[] {
    const question = questionSet.findQuestionByUuid(questionUuid);
    if (question === undefined) {
        return [];
    }

    return findAllChildrenForQuestion(question, questionSet, blacklistUuids);
}

/**
 * Find deep children
 * @param question
 * @param questionSet
 * @return Question[]
 */
export function findAllChildrenForQuestion(
    question: Question,
    questionSet: QuestionSet,
    blacklistUuids?: string[]
): Question[] {
    const children = questionSet
        .findChildQuestionsByParentUuid(question.uuid)
        .filter((child) => blacklistUuids?.includes(child.uuid) !== true);

    return [
        ...children,
        ...children.reduce((p: Question[], c: Question) => {
            return [...p, ...findAllChildrenForQuestion(c, questionSet)];
        }, []),
    ];
}

/**
 * Compare function to use for sorting of questions
 * @param a
 * @param b
 */
export function questionSortCompareFn(a: Question, b: Question) {
    if (a.order === b.order) {
        return 0;
    }
    return a.order < b.order ? -1 : 1;
}
