import * as React from 'react';

import {Appraisal} from '../../../../../models/appraisal';
import {Global} from '../../../../../../business/global_provider';
import {History} from 'history';
import {Loader} from '../../../components/loader';
import {NetworkStatus} from '../../../../../business/network_status_provider';
import {QuestionSet} from '../../../../../models/question_set';
import {TaskHelper} from '../../../../../business/task_helper';
import {ValidationError} from '../../../../../models/validation_error';
import {ValidationErrorsDownloadConceptReportPresenter} from './validation_errors_download_concept_report_presenter';
import {classNames} from '../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps {
    history: History;
    global: Global;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
}

export const ValidationErrorsDownloadConceptReport: React.FC<OwnProps> = observer(
    function ValidationErrorsDownloadConceptReport(props) {
        const presenter = usePresenter(
            (container) =>
                new ValidationErrorsDownloadConceptReportPresenter(
                    props.questionSet,
                    props.appraisal,
                    container.business.conceptReportProvider(
                        new TaskHelper(container.network.ajaxDriver),
                        container.network.appraisalApi
                    ),
                    container.business.answerInteractor(props.appraisal, props.questionSet),
                    container.business.flashMessageBroadcaster,
                    container.business.networkStatusProvider
                )
        );

        if (presenter.networkStatus === NetworkStatus.OFFLINE) {
            return (
                <div className="row">
                    <div className="col-12">
                        <h2>Download concept</h2>
                        <p>Het is niet mogelijk om het concept te downloaden omdat er geen internetverbinding is.</p>
                    </div>
                </div>
            );
        }

        if (!presenter.canDownloadConceptReport) {
            return (
                <div className="row">
                    <div className="col-12">
                        <h2>Download concept</h2>
                        <p>Het is momenteel niet mogelijk om het concept rapport te downloaden.</p>
                    </div>
                </div>
            );
        }

        return (
            <div className="row">
                <div className="col-12">
                    <h2>Download concept</h2>
                    <p>Download een .pdf versie van het concept rapport.</p>
                </div>
                <div className="col-12">
                    <div className="spacer" />
                    {presenter.conceptReportUrl === null ? (
                        <a
                            className={classNames('btn btn-sm btn-primary float-right ion-md-refresh', {
                                disabled: presenter.isDownloadingReport,
                            })}
                            onClick={() => presenter.downloadConceptReport()}
                        >
                            {presenter.isDownloadingReport ? <Loader isSmall /> : null}
                            Genereer .pdf
                        </a>
                    ) : (
                        <a
                            className="btn btn-sm btn-primary float-right ion-md-cloud-download"
                            href={presenter.conceptReportUrl}
                        >
                            Download .pdf
                        </a>
                    )}
                    <div className="spacer" />
                </div>
            </div>
        );
    }
);
