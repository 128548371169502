import {Observable} from 'rxjs';
import {IteratorQuestionType, NormalQuestionType, RootGroupQuestionType} from '../../../enum/question_type';
import {flattenTree, QuestionAnswerPair} from '../../../../support/question_answer_tree';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

import {Answer} from '../../../models/answer';
import {AnswerRegistry} from './answer_registry';
import {AnswerTouchState} from '../../../enum/answer_touch_state';
import {Appraisal} from '../../../models/appraisal';
import {MacroInteractor} from '../../macro_interactor';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {ServerTimeProvider} from '../../../server_time/server_time_provider';
import {TreeItem} from '../../../../support/generic_tree';
import Uuid from 'uuid';
import {isInEnum} from '../../../../support/is_in_enum';
import {isIteratorQuestionType} from '../../../../support/is_iterator_question_type';
import {isRootQuestionType} from '../../../../support/is_root_question_type';
import {isSet} from '../../../../support/is_set';
import {sortAnswersByUpdatedAt} from '../../../../support/sort_answer';
import v5 from 'uuid/v5';
import {SuperMacroFilter} from '../../super_macro_filter';
import {Global, GlobalProvider} from '../../../../business/global_provider';
import {getNewestAnswer} from '../../../../support/get_newest_answer';
import {TechnicalReference} from '../../../enum/technical_reference';
import {DateFormat, formatDate} from '../../../appraise/ui/support/format_date';

export class AnswerSelector {
    /**
     * Application wide Uuid V5 namespace
     */
    private NAMESPACE = '004d7934-12a2-11ea-8d71-362b9e155667';

    constructor(
        private global: Global,
        private answerRegistry: AnswerRegistry,
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private serverTimeProvider: ServerTimeProvider,
        private macroInteractor: MacroInteractor,
        private superMacroFilter: SuperMacroFilter,
        private globalProvider: GlobalProvider
    ) {}

    public answerByIdentifiersOrStub(
        questionUuid: string,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Answer {
        const existingAnswer = this.answerByIdentifiers(questionUuid, parentAnswerUuid, iteration);
        if (existingAnswer) {
            return existingAnswer;
        }

        const result = this.stub(questionUuid, parentAnswerUuid, iteration);
        this.answerRegistry.push(result);
        return result;
    }

    public answerByIdentifiersStream(
        questionUuid: string,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<Answer> {
        return this.answerRegistry.streamByQuestionUuid(questionUuid).pipe(
            map((answers) =>
                answers
                    .sort(this.sortByUpdatedAt)
                    .find(
                        (answer) =>
                            answer.questionUuid === questionUuid &&
                            answer.parentUuid === parentAnswerUuid &&
                            answer.iteration === iteration
                    )
            ),
            map((answer) => {
                if (answer === undefined) {
                    const result = this.stub(questionUuid, parentAnswerUuid, iteration);
                    return this.answerRegistry.push(result);
                }
                return answer;
            }),
            filter((value): value is Answer => value != null),
            distinctUntilChanged()
        );
    }

    public childrenAnswersForAnswerRecursively(answer: Answer, recursively = true) {
        let result: Answer[] = [];
        const directChildren = this.answerRegistry.getByParentAnswerUuid(answer.uuid);
        result = result.concat(directChildren);

        if (recursively) {
            for (const child of directChildren) {
                result = result.concat(this.childrenAnswersForAnswerRecursively(child));
            }
        }

        return result;
    }

    public childrenAnswersForAnswerRecursivelyStream(answer: Answer): Observable<Answer[]> {
        return this.answerRegistry.stream.pipe(
            map(() => this.childrenAnswersForAnswerRecursively(answer)),
            distinctUntilChanged((a, b) => {
                if (a.length !== b.length) {
                    return false;
                }
                return a.every((c, index) => c === b[index]);
            })
        );
    }

    public childrenAnswersForAnswer(answer: Answer): Answer[] {
        return this.childrenAnswersForAnswerRecursively(answer);
    }

    public answerByIdentifiers(
        questionUuid: string,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Answer | null {
        const answer = this.answerRegistry
            .getByQuestionUuid(questionUuid)
            .sort(this.sortByUpdatedAt)
            .find(
                (a) => a.questionUuid === questionUuid && a.parentUuid === parentAnswerUuid && a.iteration === iteration
            );

        return answer !== undefined ? answer : null;
    }

    public answersForQuestionUuidAndParentAnswerUuidStream(
        questionUuid: string,
        parentAnswerUuid: string | null
    ): Observable<Answer[]> {
        return this.answerRegistry.streamByQuestionUuid(questionUuid).pipe(
            map((answers) =>
                answers.filter(
                    //The answer.parentUuid === null condition means it is a preset from the server since it doesn't generate the entire tree
                    (answer) => answer.parentUuid === parentAnswerUuid || answer.parentUuid === null
                )
            )
        );
    }

    public answersByParentAnswerUuid(parentAnswerUuid: string | null): Answer[] {
        return this.answerRegistry.getByParentAnswerUuid(parentAnswerUuid);
    }

    public answersForQuestionUuidAndIteration(questionUuid: string, iteration: string | null): Observable<Answer[]> {
        return this.answerRegistry
            .streamByQuestionUuid(questionUuid)
            .pipe(map((answers) => answers.filter((answer) => answer.iteration === iteration)));
    }

    public answersForQuestionUuidStream(questionUuid: string): Observable<Answer[]> {
        return this.answerRegistry.streamByQuestionUuid(questionUuid);
    }

    public answersForQuestionUuid(questionUuid: string, parentAnswerUuid?: string): Answer[] {
        return this.answerRegistry.getByQuestionUuid(questionUuid, parentAnswerUuid);
    }

    public answersForQuestionUuidsStream(questionUuids: string[], parentAnswerUuid?: string): Observable<Answer[]> {
        return this.answerRegistry.streamByQuestionUuids(questionUuids).pipe(
            map((answers) => {
                return answers.filter((a) => {
                    if (parentAnswerUuid !== undefined) {
                        return a.parentUuid === parentAnswerUuid;
                    }
                    return true;
                });
            }),
            distinctUntilChanged((a, b) => {
                if (a.length !== b.length) {
                    return false;
                }
                return a.every((_, index) => a[index] === b[index]);
            })
        );
    }

    private findAutomatedAnswer(questionUuid: string) {
        const automatedAnswers = this.answerRegistry.getByQuestionUuid(questionUuid).filter((answer) => {
            return (
                (answer.contents !== null || answer.answerOptionId !== null) &&
                answer.isAutomated &&
                this.getNearestIterationForAnswerUuid(answer.uuid) === null
            );
        });

        return automatedAnswers.length > 0 ? automatedAnswers[automatedAnswers.length - 1] : null;
    }

    private findQuestion(questionUuid: string) {
        return this.questionSet.findQuestionByUuid(questionUuid) || null;
    }

    private findAnswerOptionIdForStub(question: Question | null): null | number {
        if (question !== null && question.type === NormalQuestionType.BOOLEAN) {
            // In essence boolean questions have no answer options.
            return null;
        }

        return question?.answerOptions?.find((answerOption) => answerOption.isDefault)?.id ?? null;
    }

    public answersForQuestionUuidAndParentAnswerUuidInSameIterationOrNull(
        questionUuid: string,
        parentAnswerUuid: string | null
    ): Answer[] | null {
        const answers = this.answersForQuestionUuid(questionUuid);
        //If there is only null to return prevent looking up nearest iterations for performance purposes
        if (answers.length === 0) {
            return null;
        }

        const nearestIterationForAnswerUuid = this.getNearestIterationForAnswerUuid(parentAnswerUuid);

        //If the question doesnt have an iteration, but the conditon questions do, return them all
        //This happends if for example we hide/show a sidebar item depending on questions inside an iteration
        if (
            nearestIterationForAnswerUuid === null &&
            answers.length > 0 &&
            this.getNearestIterationForAnswerUuid(answers[0].uuid) !== null
        ) {
            //Since these questions can be in an iterator, make sure that iteration isnt deleted
            return this.answerRegistry.filterDeleted(answers);
        }

        //If an answer doesn't have a parent iterator by definition only one answer should get returned from answers
        //so we'll take that one
        if (nearestIterationForAnswerUuid === null && answers.length > 0) {
            return [answers[0]];
        }

        //If we try to find the nearest iteration of the trigger & the question we will sometimes get an invalid result
        //This because if we have a nested iteration its possible the trigger finds an parent iteration and our question another iteration
        //These two will never match, so instead we are now building a path upward of both the trigger question and the effect question
        //If they both have the same highest root parent we walk down again and see if they both share the latest iteration
        //If so we can return this trigger answer as the result
        const effectPath = this.parentAnswerPath(parentAnswerUuid);

        for (const answer of answers) {
            const triggerPath = this.parentAnswerPath(answer.uuid);

            let lastFoundSharedIteration: string | null = null;
            //If they share the same highest parent we got a path match
            if (triggerPath[0].uuid === effectPath[0].uuid) {
                //Now keep looping through em till we find the lowest item that still matches
                for (let index = 0; index < Math.min(triggerPath.length, effectPath.length); index++) {
                    const triggerAnswer = triggerPath[index];
                    const effectAnswer = effectPath[index];

                    if (
                        triggerAnswer.questionUuid === effectAnswer.questionUuid &&
                        (triggerAnswer.iteration || effectAnswer.iteration)
                    ) {
                        //If either of the 2 have an iteration, check if they are the same
                        //If so, this is a shared iteration between them
                        if (triggerAnswer.iteration === effectAnswer.iteration) {
                            lastFoundSharedIteration = triggerAnswer.iteration;
                        } else {
                            lastFoundSharedIteration = null;
                            continue;
                        }
                    }
                }
            }

            if (lastFoundSharedIteration !== null) {
                return [answer];
            }
        }

        const foundAnswer = answers.find((answer) => {
            //Used to have a trigger outside of our own iteration
            if (nearestIterationForAnswerUuid !== null && this.getNearestIterationForAnswerUuid(answer.uuid) === null) {
                return true;
            }
            return this.getNearestIterationForAnswerUuid(answer.uuid) === nearestIterationForAnswerUuid;
        });

        return foundAnswer ? [foundAnswer] : null;
    }

    private parentAnswerPath(answerUuid: string | null): Answer[] {
        if (answerUuid === null) {
            return [];
        }

        const result = [];
        let cursor = this.answerRegistry.getByUuid(answerUuid);
        while (cursor != undefined) {
            result.push(cursor);

            if (cursor.parentUuid) {
                cursor = this.answerRegistry.getByUuid(cursor.parentUuid);
            } else {
                cursor = undefined;
            }
        }
        return result.reverse();
    }

    public answersForQuestionUuidAndParentAnswerUuidInSameIterationOrNullStream(
        questionUuid: string,
        parentAnswerUuid: string | null
    ): Observable<Answer[] | null> {
        return this.answersForQuestionUuidStream(questionUuid).pipe(
            map(() =>
                this.answersForQuestionUuidAndParentAnswerUuidInSameIterationOrNull(questionUuid, parentAnswerUuid)
            )
        );
    }

    public getNearestIterationForAnswerUuid(answerUuid: string | null): string | null {
        if (answerUuid === null) {
            return null;
        }
        const byUuid = this.answerRegistry.getByUuid(answerUuid);
        if (byUuid === undefined) {
            return null;
        }
        if (byUuid.iteration !== null) {
            return byUuid.iteration;
        }
        return this.getNearestIterationForAnswerUuid(byUuid.parentUuid);
    }

    private sortByUpdatedAt(a: Answer, b: Answer) {
        return sortAnswersByUpdatedAt(a, b);
    }

    private getNearestIterationForTree(tree: TreeItem<QuestionAnswerPair>): string | null {
        if (tree.item.answer?.iteration) {
            return tree.item.answer.iteration;
        }

        if (!tree.parent) {
            if (tree.item.answer?.parentUuid) {
                return this.getNearestIterationForAnswerUuid(tree.item.answer?.parentUuid);
            }

            return null;
        }

        return this.getNearestIterationForTree(tree.parent);
    }

    private stub(questionUuid: string, parentAnswerUuid: string | null, iteration: string | null): Answer {
        if (process.env.NODE_ENV === 'development') {
            const question = this.questionSet.findQuestionByUuid(questionUuid);

            if (question?.type === NormalQuestionType.PAGE_PART_GROUP && parentAnswerUuid !== null) {
                console.error('questionUuid, parentAnswerUuid, iteration:', questionUuid, parentAnswerUuid, iteration);
                console.error('question:', question);
                throw new Error('Trying to stub a PAGE_PART_GROUP question with an parentAnswerUuid');
            }

            if (
                (question?.type === NormalQuestionType.SYMLINK_TARGET ||
                    question?.type === NormalQuestionType.SYMLINK_TARGET_COPY) &&
                iteration === null
            ) {
                console.error('questionUuid, parentAnswerUuid, iteration:', questionUuid, parentAnswerUuid, iteration);
                console.error('question:', question);
                throw new Error(
                    'A SYMLINK_TARGET needs to get an iteration, either its parent question (SYMLINK_LINK) its uuid or another SYMLINK_LINK its uuid in case we want to mirror that "iteration" its answers.'
                );
            }

            if (
                question &&
                parentAnswerUuid === null &&
                !isRootQuestionType(question.type) &&
                !isIteratorQuestionType(question.type) &&
                question.type !== NormalQuestionType.GROUP &&
                question.type !== NormalQuestionType.WIDGET_GROUP &&
                question.type !== NormalQuestionType.GROUP_COMPACT &&
                question.type !== NormalQuestionType.FILES_GROUP &&
                question.type !== NormalQuestionType.BUILDING_COSTS_GROUP &&
                question.type !== NormalQuestionType.BUILDING_COSTS_PHOTO_GROUP &&
                question.type !== NormalQuestionType.PAGE_PART_GROUP &&
                question.type !== NormalQuestionType.SYMLINK_LINK &&
                question.type !== NormalQuestionType.SYMLINK_TARGET &&
                question.type !== NormalQuestionType.SYMLINK_TARGET_COPY &&
                question.technicalReference !== TechnicalReference.USER_SETTINGS
            ) {
                console.error('questionUuid, parentAnswerUuid, iteration:', questionUuid, parentAnswerUuid, iteration);
                console.error('question:', question);
                console.error(
                    'Trying to stub a question which should probably have a parentAnswerUuid since its not a root question nor a content (page) question.'
                );
            }

            const existingAnswers = this.answerRegistry.getByQuestionUuid(questionUuid);
            if (
                existingAnswers.length > 0 &&
                parentAnswerUuid === null &&
                existingAnswers.every((a) => isSet(a.parentUuid))
            ) {
                console.error('questionUuid, parentAnswerUuid, iteration:', questionUuid, parentAnswerUuid, iteration);
                console.error('existing answers:', existingAnswers);
                console.error('question:', question);

                throw new Error(
                    'Trying to stub a answer for question ' +
                        questionUuid +
                        ' with parentAnswerUuid = null, while all other sibling answers have a parentAnswerUuid'
                );
            }

            if (
                existingAnswers.length > 0 &&
                parentAnswerUuid !== null &&
                existingAnswers.every(
                    (a) => !isSet(a.parentUuid) && !isSet(a.filledByAutomator) && a.hasAutomator === false
                )
            ) {
                console.error('questionUuid, parentAnswerUuid, iteration:', questionUuid, parentAnswerUuid, iteration);
                console.error('existing answers:', existingAnswers);
                console.error('question:', question);

                throw new Error(
                    'Trying to stub a answer for question ' +
                        questionUuid +
                        ' with parentAnswerUuid = ' +
                        parentAnswerUuid +
                        ', while all other answers dont have a parentAnswerUuid'
                );
            }

            const nonAutomatedExistingAnsweres = existingAnswers.filter((a) => !a.filledByAutomator);

            if (
                nonAutomatedExistingAnsweres.length > 0 &&
                iteration === null &&
                nonAutomatedExistingAnsweres.every((a) => isSet(a.iteration))
            ) {
                console.error('questionUuid, parentAnswerUuid, iteration:', questionUuid, parentAnswerUuid, iteration);
                console.error('existing answers:', nonAutomatedExistingAnsweres);
                console.error('question:', question);

                throw new Error(
                    'Trying to stub a answer with iteration = null, while all other answers have a iteration'
                );
            }

            if (
                nonAutomatedExistingAnsweres.length > 0 &&
                iteration !== null &&
                nonAutomatedExistingAnsweres.every((a) => !isSet(a.iteration))
            ) {
                console.error('questionUuid, parentAnswerUuid, iteration:', questionUuid, parentAnswerUuid, iteration);
                console.error('existing answers:', nonAutomatedExistingAnsweres);
                console.error('question:', question);

                throw new Error(
                    'Trying to stub a answer with iteration = ' +
                        iteration +
                        ', while all other answers dont have an iteration'
                );
            }
        }

        let automatedAnswer: Answer | null = null;
        if (iteration !== null || this.getNearestIterationForAnswerUuid(parentAnswerUuid) !== null) {
            automatedAnswer = this.findAutomatedAnswer(questionUuid);
        }

        const question: Question | null = this.findQuestion(questionUuid);
        const answerOptionId = this.findAnswerOptionIdForStub(question);

        if (process.env.NODE_ENV === 'development') {
            if (question === null) {
                throw new Error('Trying to stub for a non-existing question: ' + questionUuid);
            }

            if (
                question?.type !== RootGroupQuestionType.HOUSE_GROUP_COMPACT &&
                question?.type !== RootGroupQuestionType.HOUSE_INSIDE &&
                question?.type !== RootGroupQuestionType.OUTSIDE_GROUP &&
                question?.type !== RootGroupQuestionType.CONCEPT_REPORT &&
                question?.type !== RootGroupQuestionType.ROOT_GROUP &&
                isInEnum(RootGroupQuestionType, question?.type)
            ) {
                throw new Error('Root answer with type ' + question?.type + ' shouldnt be stubbed!');
            }
            // if (question?.type === RootGroupQuestionType.HOUSE_GROUP_COMPACT && parentAnswerUuid) {
            //     throw new Error('A HOUSE_GROUP_COMPACT shouldnt be stubbed with a parentAnswerUuid');
            // }
        }

        const maxRank = this.maxRank(question, parentAnswerUuid, iteration);

        let defaultValue = this.getFavoriteMacroValue(questionUuid) ?? question?.defaultValue ?? null;
        if (
            question?.defaultValue === 'NOW' &&
            (question?.type === NormalQuestionType.DATE || question?.type === NormalQuestionType.DATE_PICKER)
        ) {
            defaultValue = formatDate(new Date(), DateFormat.TAXAPI);
        }

        return {
            iteration: iteration,
            uuid: this.buildId(questionUuid, parentAnswerUuid, iteration),
            parentUuid: parentAnswerUuid,
            changed: true,
            questionUuid: questionUuid,
            answerOptionId: automatedAnswer?.answerOptionId ?? answerOptionId,
            contents: automatedAnswer?.contents ?? defaultValue,
            isDeleted: false,
            isHardDeleted: false,
            isVisited: false,
            updatedAt: this.serverTimeProvider.date,
            createdAt: this.serverTimeProvider.date,
            hasAutomator: automatedAnswer?.filledByAutomator !== null,
            filledByAutomator: automatedAnswer?.filledByAutomator ?? null,
            file: automatedAnswer?.file ?? null,
            rank: automatedAnswer?.rank ?? maxRank,
            isAutomated: automatedAnswer?.isAutomated ?? false,
            touchState: automatedAnswer?.touchState ?? AnswerTouchState.UNTOUCHED,
            createdByUserId: this.globalProvider?.global?.userId,
            createdByUserType: this.globalProvider?.global?.userType,
        };
    }

    private getFavoriteMacroValue(questionUuid: string): string | null {
        if (this.macroInteractor.hasAutofillFavorites(questionUuid)) {
            const macros = this.macroInteractor.getAutofillFavorites(questionUuid);

            // Because there is a relation with the building question in the SuperMacroFilter, it is not possible
            // to just put the SuperMacroFilter in the constructor, because there will be a circular reference on
            // the answerController which results in an error.
            const buildYearQuestion = this.questionSet.findQuestionByTechnicalReference(
                TechnicalReference.OBJECT_BUILD_YEAR
            );
            const buildYearAnswer = buildYearQuestion
                ? getNewestAnswer(this.answersForQuestionUuid(buildYearQuestion.uuid))
                : null;

            const macro = macros.find((macro) => {
                return this.macroInteractor.getSettingsStream(macro.id).pipe(
                    map((settings) => {
                        if (settings === null) {
                            return true;
                        }
                        return this.superMacroFilter.isVisible(settings, buildYearAnswer);
                    })
                );
            });

            return macro?.contents ?? null;
        }

        return null;
    }

    private maxRank(
        question: Question | null,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): number | null {
        if (question?.type === IteratorQuestionType.PHOTO_ITERATOR && parentAnswerUuid !== null && iteration !== null) {
            const siblings = this.answerRegistry.getByQuestionUuid(question.uuid);
            const siblingRanks = siblings.map((a) => a.rank).filter((r): r is number => r !== null);
            return Math.max(...siblingRanks, 0) + 1;
        }

        return null;
    }

    private buildId(
        questionUuid: string,
        parentAnswerUuid: string | null,
        iteration: string | null,
        nearestIteration: string | null = null
    ): string {
        nearestIteration = iteration ?? nearestIteration ?? this.getNearestIterationForAnswerUuid(parentAnswerUuid);

        const seed =
            nearestIteration !== null
                ? `${nearestIteration}|${this.appraisal.id}|${questionUuid}`
                : `${this.appraisal.id}|${questionUuid}`;

        return v5(seed, this.NAMESPACE);
    }

    private duplicateAnswer(
        parentUuid: string | null,
        question: Question,
        answer: Answer,
        iteration?: string | null,
        newTree: TreeItem<QuestionAnswerPair> | null = null
    ): Answer {
        let nearestIteration: string | null = null;
        if (!iteration) {
            iteration = answer.iteration !== null ? Uuid.v4() : null;

            if (newTree !== null) {
                nearestIteration = this.getNearestIterationForTree(newTree);
            }
        }

        const uuid = this.buildId(question.uuid, parentUuid, iteration, nearestIteration);
        const oldAnswer = this.answerByIdentifiers(question.uuid, parentUuid, iteration);

        return {
            ...answer,
            iteration: iteration,
            uuid: uuid,
            parentUuid: parentUuid,
            changed: true,
            isVisited: false,
            updatedAt: this.serverTimeProvider.date,
            createdAt: oldAnswer?.createdAt ?? this.serverTimeProvider.date,
        };
    }

    private duplicateTreeChildren(
        parent: QuestionAnswerPair,
        tree: TreeItem<QuestionAnswerPair>,
        parentItem: TreeItem<QuestionAnswerPair>
    ): Array<TreeItem<QuestionAnswerPair>> {
        return tree.children.map((child) => {
            const childPair = {
                ...child.item,
            };

            if (childPair.answer && parent.answer) {
                childPair.answer = this.duplicateAnswer(
                    parent.answer.uuid,
                    childPair.question,
                    childPair.answer,
                    null,
                    parentItem
                );
            }

            const childTree: TreeItem<QuestionAnswerPair> = {
                parent: parentItem,
                item: childPair,
                children: [],
            };

            childTree.children = this.duplicateTreeChildren(childPair, child, childTree);

            return childTree;
        });
    }

    public duplicateTree(
        tree: TreeItem<QuestionAnswerPair>,
        newParentUuid: string | null,
        newIteration?: string | null
    ): void {
        let newRootAnswer: Answer | null = null;
        if (tree.item.answer) {
            newRootAnswer = this.duplicateAnswer(newParentUuid, tree.item.question, tree.item.answer, newIteration);
        } else {
            newRootAnswer = this.stub(tree.item.question.uuid, newParentUuid ?? null, Uuid.v4());
        }

        const rootPair = {
            question: tree.item.question,
            answer: newRootAnswer,
        };

        const newTree: TreeItem<QuestionAnswerPair> = {
            parent: null,
            item: rootPair,
            children: [],
        };
        newTree.children = this.duplicateTreeChildren(rootPair, tree, newTree);

        const answers = flattenTree(newTree)
            .map((pair) => pair.answer)
            .filter((answer): answer is Answer => answer !== null);

        if (answers.length > 0) {
            this.answerRegistry.updateMany(answers);
        }
    }

    public copyTree(
        tree: TreeItem<QuestionAnswerPair>,
        newParentUuid: string | null,
        questionMatcher: (oldQuestion: Question) => Question | null,
        newIteration?: string | null
    ): TreeItem<QuestionAnswerPair> | null {
        const newRootQuestion = questionMatcher(tree.item.question);
        if (!newRootQuestion) {
            // Could not match question
            return null;
        }

        let newRootAnswer: Answer | null;
        if (tree.item.answer) {
            newRootAnswer = this.copyAnswer(
                newParentUuid,
                tree.item.question,
                newRootQuestion,
                tree.item.answer,
                newIteration
            );
        } else {
            newRootAnswer = this.stub(newRootQuestion.uuid, newParentUuid ?? null, Uuid.v4());
        }

        if (!newRootAnswer) {
            // Could not copy answer
            return null;
        }

        const rootPair = {
            question: tree.item.question,
            answer: newRootAnswer,
        };

        const newTree: TreeItem<QuestionAnswerPair> = {
            parent: null,
            item: rootPair,
            children: [],
        };
        const newChildren = this.copyTreeChildren(rootPair, tree, newTree, questionMatcher);
        if (newChildren === null) {
            return null;
        }
        newTree.children = newChildren;

        const answers = flattenTree(newTree)
            .map((pair) => pair.answer)
            .filter((answer): answer is Answer => answer !== null);

        if (answers.length > 0) {
            this.answerRegistry.updateMany(answers);
        }

        return newTree;
    }

    private copyTreeChildren(
        parent: QuestionAnswerPair,
        oldTree: TreeItem<QuestionAnswerPair>,
        newTree: TreeItem<QuestionAnswerPair>,
        questionMatcher: (oldQuestion: Question) => Question | null
    ): Array<TreeItem<QuestionAnswerPair>> | null {
        const newChildren: Array<TreeItem<QuestionAnswerPair>> = [];
        for (const oldPair of oldTree.children) {
            const newQuestion = questionMatcher(oldPair.item.question);
            if (!newQuestion) {
                // Could not match question
                return null;
            }

            let newAnswer: Answer | null = null;
            if (parent.answer && oldPair.item.answer) {
                newAnswer = this.copyAnswer(
                    parent.answer.uuid,
                    oldPair.item.question,
                    newQuestion,
                    oldPair.item.answer
                );
                if (!newAnswer) {
                    // Could not copy answer
                    return null;
                }
            }

            const newPair: QuestionAnswerPair = {
                question: newQuestion,
                answer: newAnswer,
            };
            const newTreeItem: TreeItem<QuestionAnswerPair> = {
                parent: newTree,
                item: newPair,
                children: [],
            };

            const copied = this.copyTreeChildren(newPair, oldPair, newTreeItem, questionMatcher);
            if (copied === null) {
                return null;
            }

            newTreeItem.children = copied;

            newChildren.push(newTreeItem);
        }

        return newChildren;
    }

    private copyAnswer(
        parentUuid: string | null,
        originalQuestion: Question,
        targetQuestion: Question,
        answer: Answer,
        iteration?: string | null
    ): Answer | null {
        if (originalQuestion.type !== targetQuestion.type) {
            return null;
        }

        if (!iteration) {
            iteration = answer.iteration !== null ? Uuid.v4() : null;
        }
        const uuid = this.buildId(targetQuestion.uuid, parentUuid, iteration);
        const oldAnswer = this.answerByIdentifiers(originalQuestion.uuid, parentUuid, iteration);

        let newAnswerOptionId: number | null = null;
        if (answer.answerOptionId !== null) {
            const originalAnswerOption = originalQuestion.answerOptions.find((o) => o.id === answer.answerOptionId);
            if (!originalAnswerOption) {
                return null;
            }

            const newAnswerOption = targetQuestion.answerOptions.find(
                (o) => o.contents === originalAnswerOption.contents
            );
            if (!newAnswerOption) {
                return null;
            }

            newAnswerOptionId = newAnswerOption.id;
        }

        return {
            ...answer,
            questionUuid: targetQuestion.uuid,
            answerOptionId: newAnswerOptionId,
            iteration: iteration,
            uuid: uuid,
            parentUuid: parentUuid,
            changed: true,
            isVisited: false,
            updatedAt: this.serverTimeProvider.date,
            createdAt: oldAnswer?.createdAt ?? this.serverTimeProvider.date,
        };
    }

    public resetTree(tree: TreeItem<QuestionAnswerPair>, resetIsDeleted?: boolean): void {
        const answers: Answer[] = [];

        for (const {answer, question} of flattenTree(tree)) {
            const defaultAnswerOptionId = this.findAnswerOptionIdForStub(question);

            const answerOptionId = defaultAnswerOptionId;
            const contents = question?.defaultValue ?? null;

            if (
                answer &&
                (answer.contents !== contents ||
                    answer.answerOptionId !== answerOptionId ||
                    (resetIsDeleted === true && answer.isDeleted === true))
            ) {
                answers.push({
                    ...answer,
                    changed: true,
                    answerOptionId: answerOptionId,
                    contents: question?.defaultValue ?? null,
                    updatedAt: this.serverTimeProvider.date,
                    isDeleted: resetIsDeleted === true ? false : answer.isDeleted,
                });
            }
        }

        if (answers.length > 0) {
            this.answerRegistry.updateMany(answers);
        }
    }

    public deleteTree(tree: TreeItem<QuestionAnswerPair>): void {
        const answers = flattenTree(tree)
            .map((i) => i.answer)
            .filter((a): a is Answer => a !== null);

        this.answerRegistry.deleteMultiple(answers.map((a) => a.uuid));
    }
}
