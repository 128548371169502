import {AjaxDriver} from '../../network/driver/ajax_driver';
import {ReferencePreferences} from '../models/reference_preferences';
import {referencePreferencesToApiReferencePreferences} from './models/api_reference_preferences';

export interface UserSettingsApi {
    storeReferencesPreferences(preferences: ReferencePreferences): Promise<void>;
}

export class DefaultUserSettingsApi implements UserSettingsApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public storeReferencesPreferences(preferences: ReferencePreferences): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/user-settings/reference-preferences`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify(referencePreferencesToApiReferencePreferences(preferences)),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve();
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
