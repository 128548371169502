import {useEffect, useRef} from 'react';

function transitionFromHeight(element: HTMLElement, callback: () => void, height: number) {
    const el = $(element);

    el.css('height', `${height}px`);
    element.offsetHeight; // Trigger reflow
    el.addClass('collapsing').removeClass('collapse show');
    el.css('height', '');

    el.one('transitionend', () => {
        el.addClass('collapse').removeClass('collapsing');
        el.css('height', '');
        callback();
    });
}

export function useCollapse(collapsed: boolean) {
    const collapseClass = useRef(collapsed ? 'collapse' : 'collapse show');
    const collapseRef = useRef<HTMLDivElement | null>(null);

    if (collapseRef.current === null) {
        collapseClass.current = collapsed ? 'collapse' : 'collapse show';
    }

    useEffect(() => {
        if (!collapseRef.current) {
            return;
        }

        const element = collapseRef.current;

        if (collapsed === false) {
            const el = $(element);
            transitionFromHeight(
                element,
                () => {
                    collapseClass.current = 'collapse show';
                    el.addClass('show');
                },
                0
            );
            el.css('height', `${element.scrollHeight}px`);
            collapseClass.current = 'collapsing';
        } else {
            transitionFromHeight(
                element,
                () => {
                    collapseClass.current = 'collapse';
                },
                element.getBoundingClientRect().height
            );
            collapseClass.current = 'collapsing';
        }
    }, [collapsed]);

    return {
        className: 'collapsible ' + collapseClass.current,
        ref: collapseRef,
    };
}
