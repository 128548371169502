//https://gist.github.com/banksean/300494
export class MersenneTwister {
    /* Period parameters */
    private N = 624;
    private M = 397;
    private MATRIX_A = 0x9908b0df; /* constant vector a */
    private UPPER_MASK = 0x80000000; /* most significant w-r bits */
    private LOWER_MASK = 0x7fffffff; /* least significant r bits */

    private mt = new Array(this.N); /* the array for the state vector */
    private mti = this.N + 1; /* mti==N+1 means mt[N] is not initialized */

    constructor(seed: number) {
        if (seed == undefined) {
            seed = new Date().getTime();
        }

        this.initGenRand(seed);
    }
    private initGenRand(seed: number) {
        this.mt[0] = seed >>> 0;
        for (this.mti = 1; this.mti < this.N; this.mti++) {
            const s = this.mt[this.mti - 1] ^ (this.mt[this.mti - 1] >>> 30);
            this.mt[this.mti] =
                ((((s & 0xffff0000) >>> 16) * 1812433253) << 16) + (s & 0x0000ffff) * 1812433253 + this.mti;
            /* See Knuth TAOCP Vol2. 3rd Ed. P.106 for multiplier. */
            /* In the previous versions, MSBs of the seed affect   */
            /* only MSBs of the array mt[].                        */
            /* 2002/01/09 modified by Makoto Matsumoto             */
            this.mt[this.mti] >>>= 0;
            /* for >32 bit machines */
        }
    }

    public genRandInt32() {
        let y;
        const mag01 = [0x0, this.MATRIX_A];
        /* mag01[x] = x * MATRIX_A  for x=0,1 */

        if (this.mti >= this.N) {
            /* generate N words at one time */
            let kk;

            if (this.mti == this.N + 1)
                /* if init_genrand() has not been called, */
                this.initGenRand(5489); /* a default initial seed is used */

            for (kk = 0; kk < this.N - this.M; kk++) {
                y = (this.mt[kk] & this.UPPER_MASK) | (this.mt[kk + 1] & this.LOWER_MASK);
                this.mt[kk] = this.mt[kk + this.M] ^ (y >>> 1) ^ mag01[y & 0x1];
            }
            for (; kk < this.N - 1; kk++) {
                y = (this.mt[kk] & this.UPPER_MASK) | (this.mt[kk + 1] & this.LOWER_MASK);
                this.mt[kk] = this.mt[kk + (this.M - this.N)] ^ (y >>> 1) ^ mag01[y & 0x1];
            }
            y = (this.mt[this.N - 1] & this.UPPER_MASK) | (this.mt[0] & this.LOWER_MASK);
            this.mt[this.N - 1] = this.mt[this.M - 1] ^ (y >>> 1) ^ mag01[y & 0x1];

            this.mti = 0;
        }

        y = this.mt[this.mti++];

        /* Tempering */
        y ^= y >>> 11;
        y ^= (y << 7) & 0x9d2c5680;
        y ^= (y << 15) & 0xefc60000;
        y ^= y >>> 18;

        return y >>> 0;
    }

    public random() {
        return this.genRandInt32() * (1.0 / 4294967296.0);
        /* divided by 2^32 */
    }

    public randomBetween(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(this.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
    }

    public randomBetweenFromArray(array: number[], min: number, max: number) {
        const value = this.randomBetween(min, max);
        return array.reduce(function (prev: number, curr: number) {
            return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
        });
    }
}
