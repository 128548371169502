(() => {
    const priceField = document.getElementById('reference-objects-full-appraisal-price');
    if (priceField) {
        const priceHint = document.getElementById('reference-objects-price-hint');

        const displayPrice = () => {
            const types = Array.from(document.querySelectorAll('input[name="reference_subscription_type[]"]'))
                .filter((el) => el.checked)
                .map((el) => el.value);

            priceHint.innerHTML = '';

            let price = REFERENCE_OBJECT_PRICES.base;
            types.forEach((type) => (price += REFERENCE_OBJECT_PRICES.reference_objects[type]));

            let discount = 0;
            if (
                types.includes('ortec_references') &&
                types.includes('brainbay_references') &&
                types.includes('altum_references')
            ) {
                discount = REFERENCE_OBJECT_PRICES.discounts['ortec+brainbay+altum'];
            } else if (types.includes('brainbay_references') && types.includes('altum_references')) {
                discount = REFERENCE_OBJECT_PRICES.discounts['brainbay+altum'];
            } else if (
                types.includes('ortec_references') &&
                (types.includes('brainbay_references') || types.includes('altum_references'))
            ) {
                discount = REFERENCE_OBJECT_PRICES.discounts['ortec+brainbay_or_altum'];
            }

            if (discount > 0) {
                price -= discount;
                priceHint.innerHTML = `Incl. € ${(discount / 100).toLocaleString('nl-NL', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })} korting`;
            }

            price /= 100;

            priceField.innerHTML =
                '€ ' +
                price.toLocaleString('nl-NL', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
        };

        displayPrice();

        $('input[name="reference_subscription_type[]"]').on('change', displayPrice);
    }
})();

(() => {
    const termsGroup = document.getElementById('building-inspectors-terms-group');
    if (termsGroup) {
        const hasErrors = Array.from(termsGroup.children).some((c) => c.className === 'invalid-feedback');
        const updateTermsGroup = (initial = false) => {
            const types = Array.from(document.querySelectorAll('input[name="building_inspectors_subscription_type"]'))
                .filter((el) => el.checked)
                .map((el) => el.value);

            termsGroup.style.display = 'inline-block';
            if ((types.length === 1 && types[0] === '') || (initial && !hasErrors)) {
                termsGroup.style.display = 'none';
            }
        };
        $('input[name="building_inspectors_subscription_type"]').on('change', () => updateTermsGroup());
        updateTermsGroup(true);
    }

    const buildingInspectorsSaveButton = document.getElementById('building-inspectors-save-button');
    if (buildingInspectorsSaveButton) {
        const showSaveButton = () => {
            buildingInspectorsSaveButton.style.display = 'inline-block';
        };
        $('input[name="building_inspectors_subscription_type"]').on('change', showSaveButton);
        $('input[name="building_inspectors_agree_with_terms"]').on('change', showSaveButton);
        $('input[name="enable_building_inspectors_recurring_payments"]').on('change', showSaveButton);
    }
})();

(() => {
    const sustainabilitySaveButton = document.getElementById('sustainability-save-button');
    if (sustainabilitySaveButton) {
        $('input[name="sustainability_subscription_type"]').on('change', () => {
            sustainabilitySaveButton.style.display = 'inline-block';
        });
    }
})();

(() => {
    const recurringPaymentsGroup = document.getElementById('building-inspectors-recurring-group');
    if (recurringPaymentsGroup) {
        const updateRecurringPaymentsGroup = () => {
            let json = null;
            try {
                json = JSON.parse(
                    Array.from(document.querySelectorAll('input[name="building_inspectors_subscription_type"]'))
                        .filter((el) => el.checked)
                        .map((el) => el.value)[0]
                );
            } catch (e) {
                //
            }

            recurringPaymentsGroup.style.display = 'none';
            if (json && (json?.frequency === 'yearly' || json?.frequency === 'monthly')) {
                recurringPaymentsGroup.style.display = 'inline-block';
            }
        };
        $('input[name="building_inspectors_subscription_type"]').on('change', updateRecurringPaymentsGroup);
        updateRecurringPaymentsGroup();
    }
})();

(() => {
    const subscriptionPaymentMethodGroup = document.getElementById('subscription-payment-method');
    if (subscriptionPaymentMethodGroup) {
        const updateVisibleBlock = () => {
            $('.payment-method-block').hide();

            const value = Array.from(document.querySelectorAll('input[name="payment_method"]'))
                .filter((el) => el.checked)
                .map((el) => el.value)[0];
            if (value) $(`#payment-method-block-${value}`).show();
        };

        $('input[name="payment_method"]').on('change', updateVisibleBlock);
        updateVisibleBlock();
    }
})();
