window.$ = window.jQuery = require('jquery');

require('bootstrap');
require('bootstrap-select');

(() => {
    if ($('.selectpicker').length > 0) {
        $.fn.selectpicker.Constructor.BootstrapVersion = '4';

        $('.selectpicker').selectpicker({
            dropupAuto: false,
            iconBase: 'ionicons',
            tickIcon: 'ion-md-checkmark-circle',
            virtualScroll: 25,
        });
    }
})();
