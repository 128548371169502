export interface EnergyConclusion {
    label: string | null;
    isPreliminary: boolean;
}

export interface ApiEnergyConclusion {
    label: string | null;
    is_preliminary: boolean;
}

export function getFromApiEnergyConclusion(apiEnergyConclusion: ApiEnergyConclusion): EnergyConclusion {
    return {
        label: apiEnergyConclusion.label,
        isPreliminary: apiEnergyConclusion.is_preliminary,
    };
}
