import {computed, makeObservable} from 'mobx';

import {Presenter} from '../../../../../support/presenter/presenter';
import {PhotoAnswerWithFile} from '../../../../business/photo_answer_retry_interactor';

export class AnswerImagePresenter implements Presenter {
    private objectUrl: string | null = null;
    @computed public get url() {
        if (this.photoAnswerWithFile.answer.file?.url) {
            return this.photoAnswerWithFile.answer.file?.url;
        }

        if (this.photoAnswerWithFile.answer.file?.uncompressedUrl) {
            return this.photoAnswerWithFile.answer.file?.uncompressedUrl;
        }

        if (this.photoAnswerWithFile.file) {
            if (!this.objectUrl) {
                this.objectUrl = URL.createObjectURL(this.photoAnswerWithFile.file);
            }
            return this.objectUrl;
        }

        return null;
    }

    constructor(private photoAnswerWithFile: PhotoAnswerWithFile) {
        makeObservable(this);
    }

    public mount() {
        // Noop
    }

    public unmount() {
        // Noop
    }
}
