import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';

import {GroupType} from '../../../enum/group_type';
import {Answer} from '../../../models/answer';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {AnswerController} from '../../answering/answer_controller';
import {SurfaceAreaProvider} from '../../support/surface_area_provider';
import {AnswerValidator} from '../answer_validator';
import {TechnicalReference} from '../../../enum/technical_reference';

export class FloorSurfaceAreaValidator implements AnswerValidator {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private surfaceAreaProvider: SurfaceAreaProvider
    ) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (question.technicalReference !== TechnicalReference.AREA_GROUP || !answer) {
            return [];
        }

        const surfaceQuestions = [
            ...this.questionSet.findQuestionsByGroupType(GroupType.SURFACE_AREA_ADDITIVE),
            ...this.questionSet.findQuestionsByGroupType(GroupType.SURFACE_AREA_DEDUCTIVE),
        ];

        const answers = surfaceQuestions
            .map((q) =>
                this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIteration(
                    q.uuid,
                    answer.parentUuid
                )
            )
            .filter((a): a is Answer[] => a !== null)
            .flat();

        const surfaceArea = this.surfaceAreaProvider.getForAreaAnswers(answers);

        if (surfaceArea !== null && surfaceArea < 0) {
            return [
                {
                    type: ValidationMessageType.TAXAPI,
                    importance: ValidationMessageImportance.ERROR,
                    question: question,
                    answer: answer,
                    messages: [],
                    fallbackMessage:
                        'De woonlaag heeft een negatief oppervlakte (Overig inpandig + correctie is meer dan de oppervlakte van de woonlaag).',
                    floorInfo: null,
                },
            ];
        }

        return [];
    }
}
