import {Answer} from '../../../../../../models/answer';
import {AnswerController} from '../../../../../../business/answering/answer_controller';
import {IteratorFilesAnswerData} from './iterator_files_attachment_provider';
import {AnswerTouchState} from '../../../../../../enum/answer_touch_state';
import {isEmpty} from '../../../../../../../support/util';

export enum IteratorFilesListType {
    FILE_LIST = 'file-list',
    UPLOAD_LIST = 'upload-list',
}

export interface IteratorFilesUploadListData {
    listType: IteratorFilesListType;
}

export interface IteratorFilesFileListData extends IteratorFilesUploadListData {
    listType: IteratorFilesListType;
    dateMsFinished: number;
}

export interface IteratorFilesAttachmentInteractor {
    updateStatus(data: IteratorFilesAnswerData): Answer | null;
    isFinished(data: IteratorFilesAnswerData): boolean;
    willChangeFromList(data: IteratorFilesAnswerData): boolean;
    didChangeFromList(data: IteratorFilesAnswerData): boolean;
    hasOpenField(data: IteratorFilesAnswerData): boolean;
    acceptOpenField(answer: Answer): void;
}

export class DefaultIteratorFilesAttachmentInteractor implements IteratorFilesAttachmentInteractor {
    private ANSWER_OPTION_OTHER = 'Anders';
    constructor(private answerController: AnswerController) {}

    public updateStatus(data: IteratorFilesAnswerData): Answer | null {
        const currentListData = this.parseContent(data.iteration.contents);
        if (currentListData === null) {
            let contents = JSON.stringify({
                listType: IteratorFilesListType.UPLOAD_LIST,
            });
            if (this.hasCompleteTitle(data)) {
                // When the data is complete but there is no list information, set the information to files list with
                // changed time in the past so no animation is shown.
                contents = JSON.stringify({
                    listType: IteratorFilesListType.FILE_LIST,
                    dateMsFinished: Date.now() - 20000,
                });
            }
            return this.answerController.onContentsChange(data.iteration.uuid, contents, AnswerTouchState.TOUCHED);
        } else if (currentListData.listType === IteratorFilesListType.UPLOAD_LIST && this.hasCompleteTitle(data)) {
            return this.answerController.onContentsChange(
                data.iteration.uuid,
                JSON.stringify({
                    listType: IteratorFilesListType.FILE_LIST,
                    dateMsFinished: Date.now(),
                }),
                AnswerTouchState.TOUCHED
            );
        }

        return null;
    }

    public acceptOpenField(answer: Answer): void {
        this.answerController.onContentsChange(
            answer.uuid,
            JSON.stringify({
                listType: IteratorFilesListType.FILE_LIST,
                dateMsFinished: Date.now() - 4000,
            }),
            AnswerTouchState.TOUCHED
        );
    }

    public isFinished(data: IteratorFilesAnswerData): boolean {
        if (isEmpty(data.iteration.contents)) {
            this.updateStatus(data);
        }
        const currentListData = this.parseContent(data.iteration.contents);
        if (currentListData?.listType === IteratorFilesListType.FILE_LIST) {
            const dateMs = Date.now() - 4000; // Now - 4 sec
            const data = currentListData as IteratorFilesFileListData;
            return data.dateMsFinished < dateMs;
        } else if (isEmpty(data.iteration.contents)) {
            // When the contents is empty  even after updating the status this means it may be an existing answer before
            // changing the question set.
            return true;
        }
        return false;
    }

    public willChangeFromList(data: IteratorFilesAnswerData): boolean {
        if (isEmpty(data.iteration.contents)) {
            this.updateStatus(data);
        }
        const currentListData = this.parseContent(data.iteration.contents);
        if (currentListData?.listType === IteratorFilesListType.FILE_LIST) {
            const dateMs = Date.now() - 4000; // Now - 4 sec
            const data = currentListData as IteratorFilesFileListData;
            return data.dateMsFinished > dateMs;
        }
        return false;
    }

    public didChangeFromList(data: IteratorFilesAnswerData) {
        if (isEmpty(data.iteration.contents)) {
            this.updateStatus(data);
        }
        const currentListData = this.parseContent(data.iteration.contents);
        if (currentListData?.listType === IteratorFilesListType.FILE_LIST) {
            const dateMs = Date.now() - 20000; // Now - 20 sec
            const data = currentListData as IteratorFilesFileListData;
            return data.dateMsFinished > dateMs;
        }
        return false;
    }

    public hasOpenField(data: IteratorFilesAnswerData): boolean {
        const otherAnswerOption = data.mcTree.question?.answerOptions.find(
            (o) => o.contents === this.ANSWER_OPTION_OTHER
        );
        if (otherAnswerOption !== undefined && data.mcTree.answer?.answerOptionId === otherAnswerOption.id) {
            return true;
        }
        return false;
    }

    private hasCompleteTitle(data: IteratorFilesAnswerData): boolean {
        if (data.mcTree.answer === null || data.fileTree.answer === null || data.fileTree.answer.contents === null) {
            return false;
        }
        if (this.hasOpenField(data)) {
            return false;
        }

        return data.mcTree.answer.answerOptionId !== null;
    }

    private parseContent(contents: string | null): IteratorFilesUploadListData | null {
        if (contents === null || !contents.startsWith('{')) {
            return null;
        }
        return JSON.parse(contents) as IteratorFilesUploadListData;
    }
}
