import React from 'react';
import {BadgeContext} from '../../../appraising/enum/badge_context';
import {GivenPreselectionReferenceObject} from '../../../appraising/network/preselected_reference_objects_api';
import {ReferenceSale} from '../../../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v1/models/reference_sale';
import {getUrlFromImageUrlPair} from '../../../appraising/models/image_url_pair';
import {ReferenceObjectTileImageSlider} from '../../../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v3/components/reference_object_tile/reference_object_tile_image_slider';

export type WithDistance<T> = T & {distance: number | null};

interface OwnProps {
    swipeLeft?: () => void;
    swipeRight?: () => void;
    onClick?: () => void;
    object: Partial<WithDistance<ReferenceSale>>;
    given?: GivenPreselectionReferenceObject;
}

export class ReferenceObjectWheelItem extends React.Component<OwnProps> {
    private getPurchasePrice(purchasePrice: string | null) {
        if (!purchasePrice) {
            return '';
        }

        const range = purchasePrice.split('-');
        if (range.length === 1) {
            const price = Number(range[0]);
            if (Number.isNaN(price)) {
                return purchasePrice;
            }

            return `€ ${price.toLocaleString('nl-NL')}`;
        } else if (range.length !== 2) {
            return purchasePrice;
        }

        const from = Number(range[0]);
        const to = Number(range[1]);

        if (Number.isNaN(from) || Number.isNaN(to)) {
            return purchasePrice;
        }

        return `€ ${from.toLocaleString('nl-NL')} - € ${to.toLocaleString('nl-NL')}`;
    }

    private diffBuildYear(buildYear: number | null, referenceBuildYear: number): BadgeContext {
        if (!buildYear) {
            return BadgeContext.BadgeNeutral;
        }

        const diff = buildYear - referenceBuildYear;

        if (diff > 10 && diff <= 20) {
            return BadgeContext.BadgeBetter;
        } else if (diff > 20) {
            return BadgeContext.BadgeMuchBetter;
        } else if (diff < -10 && diff >= -20) {
            return BadgeContext.BadgeWorse;
        } else if (diff < -20) {
            return BadgeContext.BadgeMuchWorse;
        }
        return BadgeContext.BadgeNeutral;
    }

    private diffFloorArea(floorArea: number | null, referenceFloorArea: number): BadgeContext {
        if (!floorArea) {
            return BadgeContext.BadgeNeutral;
        }

        const diff = (100 * (floorArea - referenceFloorArea)) / referenceFloorArea;

        if (diff > 20) {
            return BadgeContext.BadgeMuchBetter;
        } else if (diff > 10) {
            return BadgeContext.BadgeBetter;
        } else if (diff < -20) {
            return BadgeContext.BadgeMuchWorse;
        } else if (diff < -10) {
            return BadgeContext.BadgeWorse;
        }
        return BadgeContext.BadgeNeutral;
    }

    private diffPlotArea(plotArea: number | null, referencePlotArea: number): BadgeContext {
        if (!plotArea) {
            return BadgeContext.BadgeNeutral;
        }

        const diff = (100 * (plotArea - referencePlotArea)) / referencePlotArea;

        if (diff > 20) {
            return BadgeContext.BadgeMuchBetter;
        } else if (diff > 10) {
            return BadgeContext.BadgeBetter;
        } else if (diff < -20) {
            return BadgeContext.BadgeMuchWorse;
        } else if (diff < -10) {
            return BadgeContext.BadgeWorse;
        }
        return BadgeContext.BadgeNeutral;
    }

    public render() {
        const {object} = this.props;
        const purchasePrice = this.getPurchasePrice(object.indexedPriceRange ?? object.priceRange ?? null);

        const images = object.images ? [...object.images] : [];
        if (object.frontview) {
            images.unshift({
                url: getUrlFromImageUrlPair(object.frontview),
                confidence: 1,
                label: 'vooraanzicht',
                date: null,
                key: null,
                source: null,
            });
        }

        return (
            <div
                className={'col-12 col-sm-6 col-md-3 form-grid-item m-1 pb-0 reference-object-item'}
                onClick={this.props.onClick}
            >
                <div className="form-grid-item-img">
                    <ReferenceObjectTileImageSlider images={images} hasCheckmark={false} />
                </div>
                <div className="form-grid-item-body flex-column">
                    <div className="row mb-3">
                        <div className="col-6">
                            {[
                                object.street,
                                object.houseNumber ?? '' + object.letter ?? '',
                                object.postalCode,
                                object.city,
                            ]
                                .filter((i) => i)
                                .join(' ')}
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            <strong className="text-right">{object.objectType}</strong>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 d-flex justify-content-center pr-0">
                            <span
                                className={
                                    'badge badge-large d-flex align-items-center ' +
                                    (this.props.given
                                        ? this.diffBuildYear(
                                              this.props.object.buildYear ?? null,
                                              this.props.given.buildYear
                                          )
                                        : '')
                                }
                            >
                                <span className="c-icon-build-year pr-1" />
                                {object.buildYear}
                            </span>
                        </div>
                        <div className="col-4 d-flex justify-content-center">
                            <span
                                className={
                                    'badge badge-large d-flex align-items-center ' +
                                    (this.props.given
                                        ? this.diffFloorArea(
                                              this.props.object.floorArea ?? null,
                                              this.props.given.floorArea
                                          )
                                        : '')
                                }
                            >
                                <span className="c-icon-floor-area pr-1" />
                                {object.floorArea} m²
                            </span>
                        </div>
                        <div className="col-4 d-flex justify-content-center pl-0">
                            <span
                                className={
                                    'badge badge-large d-flex align-items-center ' +
                                    (this.props.given
                                        ? this.diffPlotArea(
                                              this.props.object.plotArea ?? null,
                                              this.props.given.plotArea
                                          )
                                        : '')
                                }
                            >
                                <span className="c-icon-plot-area pr-1" />
                                {object.plotArea} m²
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex flex-column align-items-center">
                            <small>
                                Verkoopdatum:{' '}
                                {object.normalizedSaleDate
                                    ? `${object.normalizedSaleDate.month + 1}-${object.normalizedSaleDate.year}`
                                    : 'Onbekend'}
                            </small>
                            <small>
                                Afstand: {object.distance ? `${(object.distance / 1000).toFixed(1)} km` : 'Onbekend'}
                            </small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3 d-flex justify-content-start">
                            <button className="btn btn-sm btn-primary" onClick={this.props.swipeLeft}>
                                <span className="ion-md-close" />
                            </button>
                        </div>
                        <div className="col-6 p-0 d-flex justify-content-center align-items-end">
                            <strong>{purchasePrice}</strong>
                        </div>
                        <div className="col-3 d-flex justify-content-end">
                            <button className="btn btn-sm btn-primary" onClick={this.props.swipeRight}>
                                <span className="ion-md-heart" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
