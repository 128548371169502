import {action, makeObservable, observable} from 'mobx';

import {AnswerInteractor} from '../../../../../../business/answering/answer_interactor';
import {AttachmentQuestionPresenter} from '../attachment_question_presenter';
import {AttachmentQuestionsInteractor} from '../../../../../../business/attachment_questions_interactor';
import {BlobCacheInteractor} from '../../../../../../business/attachments/blob_cache_interactor';
import {FlashMessageBroadcaster} from '../../../../../../business/flash_message_broadcaster';
import {GlobalProvider} from '../../../../../../../business/global_provider';
import {ImageUploadInteractor} from '../../../../../../business/attachments/image_upload_interactor';
import {SimpleQuestionPresenter} from '../../simple/simple_question_presenter';
import {AutomatorInteractor} from '../../../../../../business/automator_interactor';

export class PhotoQuestionPresenter extends AttachmentQuestionPresenter {
    @observable public isPersistPending = false;
    @observable public isPersisting = false;
    @observable public isImageLoaded = false;

    constructor(
        fileTypes: string[],
        blobCacheInteractor: BlobCacheInteractor,
        imageUploadInteractor: ImageUploadInteractor,
        answerInteractor: AnswerInteractor,
        attachmentQuestionsInteractor: AttachmentQuestionsInteractor,
        flashMessageBroadcaster: FlashMessageBroadcaster,
        globalProvider: GlobalProvider,
        automatorInteractor: AutomatorInteractor,
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(
            fileTypes,
            globalProvider,
            blobCacheInteractor,
            imageUploadInteractor,
            answerInteractor,
            attachmentQuestionsInteractor,
            flashMessageBroadcaster,
            automatorInteractor,
            ...simpleQuestionPresenterParameters
        );
        makeObservable(this);
    }

    @action
    public onImageLoad() {
        this.isImageLoaded = true;
    }
}
