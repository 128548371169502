import {PagePart} from '../../models/page_part';

export interface ApiPagePart {
    uuid: string;
    parent_uuid: string | null;
    page_part_configuration_id: number;
    question_uuid: string | null;
    rank: number;
    name: string | null;
    icon: string | null;
    explanation: string | null;
    is_index_page: boolean;
}

export function apiPagePartToPagePart(apiPagePart: ApiPagePart): PagePart {
    return {
        uuid: apiPagePart.uuid,
        parentUuid: apiPagePart.parent_uuid,
        pagePartConfigurationId: apiPagePart.page_part_configuration_id,
        name: apiPagePart.name ?? '',
        icon: apiPagePart.icon,
        rank: apiPagePart.rank,
        explanation: apiPagePart.explanation,
        questionUuid: apiPagePart.question_uuid,
        isIndexPage: apiPagePart.is_index_page,
    };
}

export function pagePartToApiPagePart(pagePart: PagePart): ApiPagePart {
    return {
        uuid: pagePart.uuid,
        parent_uuid: pagePart.parentUuid,
        page_part_configuration_id: pagePart.pagePartConfigurationId,
        name: pagePart.name ?? '',
        icon: pagePart.icon,
        rank: pagePart.rank,
        explanation: pagePart.explanation,
        question_uuid: pagePart.questionUuid,
        is_index_page: pagePart.isIndexPage,
    };
}
