import {
    FloorQuestionType,
    QuestionType,
    RootGroupQuestionType,
    RootQuestionType,
} from '../appraising/enum/question_type';

export function isRootQuestionType(questionType: QuestionType): questionType is RootQuestionType {
    for (const rootType of Object.values(FloorQuestionType)) {
        if (rootType === questionType) {
            return true;
        }
    }

    for (const rootType of Object.values(RootGroupQuestionType)) {
        if (rootType === questionType) {
            return true;
        }
    }
    return false;
}
