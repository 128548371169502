import * as React from 'react';

import {Appraisal} from '../../../models/appraisal';
import {Global} from '../../../../business/global_provider';
import {History} from 'history';
import {Loader} from '../components/loader';
import {NextButtonPresenter} from './next_button_presenter';
import {PagePartsSet} from '../../../models/page_parts_set';
import {QuestionSet} from '../../../models/question_set';
import {ValidationError} from '../../../models/validation_error';
import {classNames} from '../../../../support/classnames';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../support/presenter/use_presenter';
import {RenderingContextType} from '../../../enum/rendering_context_type';
import {UnjudgedPagesProvider} from '../content/validation_errors_page/unjudged_pages_provider';
import {ReferenceObjectGroupAction} from '../content/validation_errors_page/actions/reference_objects_group_action';

interface OwnProps {
    global: Global;
    history: History;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet | null;
    validationErrors: ValidationError[];
    renderingContext: RenderingContextType;
}

export const NextButton: React.FC<OwnProps> = observer((props) => {
    const presenter = usePresenter(
        (container) =>
            new NextButtonPresenter(
                props.appraisal,
                props.history,
                props.renderingContext,
                container.ui.appraisalNavigator,
                container.business.sidebarTreeBuilder(props.appraisal, props.questionSet, props.pagePartsSet),
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.photoAnswerRetryInteractor(props.appraisal, props.questionSet),
                container.business.flashMessageBroadcaster,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.networkStatusProvider,
                container.business.childQuestionValidator(props.appraisal, props.questionSet, props.validationErrors),
                container.business.validationConclusionProvider(
                    props.appraisal,
                    props.questionSet,
                    props.pagePartsSet,
                    props.validationErrors,
                    props.renderingContext
                ),
                container.business.unsyncedAnswerRetryInteractor(props.appraisal, props.questionSet),
                new UnjudgedPagesProvider(
                    container.business.sidebarTreeBuilder(props.appraisal, props.questionSet, props.pagePartsSet),
                    container.business.answerController(props.appraisal, props.questionSet),
                    [new ReferenceObjectGroupAction()]
                ),
                container.globalProvider
            )
    );

    return (
        <button
            className={classNames('btn', {
                'btn-primary': presenter.submitButtonType === 'normal',
                'btn-warning': presenter.submitButtonType === 'danger',
            })}
            disabled={presenter.isNextButtonDisabled}
            onClick={() => presenter.storeAnswersAndProceed()}
            data-tooltip="btn-footer-back"
            data-tooltip-title="Volgende stap"
            data-tooltip-description="Met deze knop ga je naar de volgende pagina zoals de routebalk weergeeft.."
            data-tooltip-position="top-center"
        >
            {presenter.submitting ? <Loader /> : presenter.submitButtonText}
        </button>
    );
});
