import {Observable, combineLatest, of} from 'rxjs';
import {TreeItem, findChildRecursiveByPredicate} from '../../../../../../../../../../support/generic_tree';
import {V2SetDefinition, V2SetDefinitionsProvider} from './set_definitions_provider';
import {map, shareReplay, startWith, switchMap} from 'rxjs/operators';

import {AnswerOption} from '../../../../../../../../../models/answer_option';
import {BuildYearProvider} from '../../../../../../../../../business/build_year_provider';
import {EnergyLabelProvider} from '../../../../../../../../../business/energy_label_provider';
import {GebruiksOppervlakteBuitenruimteProvider} from '../../../../../../../../../business/bbmi_areas/gebruiks_oppervlakte_buitenruimte_provider';
import {GebruiksOppervlakteExterneBergruimteProvider} from '../../../../../../../../../business/bbmi_areas/gebruiks_oppervlakte_externe_bergruimte_provider';
import {OverigeInpandigeRuimteProvider} from '../../../../../../../../../business/bbmi_areas/overige_inpandige_ruimte';
import {PlotAreaProvider} from '../../../../../../../../../business/plot_area_provider';
import {QuestionAnswerPair} from '../../../../../../../../../../support/question_answer_tree';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';
import {SurfaceAreaProvider} from '../../../../../../../../../business/support/surface_area_provider';
import {TechnicalReference} from '../../../../../../../../../enum/technical_reference';
import {ValuationDateProvider} from '../../../../../../../../../business/valuation_date_provider';
import {VolumeProvider} from '../../../../../../../../../business/volume_provider';

export interface V2ReferenceSet<T = TreeItem<QuestionAnswerPair>> extends V2SetDefinition<T> {
    buildYear: number | null;
    plotArea: number | null; //perceelOppervlakte
    surfaceArea: number | null; //gebruiksoppervlakteWonen
    gebruiksOppervlakteBuitenruimte: number | null;
    overigeInpandigeRuimte: number | null;
    gebruiksOppervlakteExterneBergruimte: number | null;
    volume: number | null; //brutoInhoud
    energyLabel: string | null;
    valuationDate: Date | null;
}

export interface V2ReferenceSetsProvider {
    referenceSets(): Observable<V2ReferenceSet[] | null>;
}

export class DefaultV2ReferenceSetsProvider implements V2ReferenceSetsProvider {
    constructor(
        private setDefinitionsProvider: V2SetDefinitionsProvider,
        private buildYearProvider: BuildYearProvider,
        private surfaceAreaProvider: SurfaceAreaProvider,
        private plotAreaProvider: PlotAreaProvider,
        private overigeInpandigeRuimteProvider: OverigeInpandigeRuimteProvider,
        private gebruiksOppervlakteBuitenruimteProvider: GebruiksOppervlakteBuitenruimteProvider,
        private gebruiksOppervlakteExterneBergruimteProvider: GebruiksOppervlakteExterneBergruimteProvider,
        private volumeProvider: VolumeProvider,
        private energyLabelProvider: EnergyLabelProvider,
        private valuationDateProvider: ValuationDateProvider
    ) {}

    private extractSpecialValues(
        setDefinitions: V2SetDefinition[],
        buildYear: number | null,
        surfaceArea: number | null,
        plotArea: number | null,
        overigeInpandigeRuimte: number | null,
        gebruiksOppervlakteBuitenruimte: number | null,
        gebruiksOppervlakteExterneBergruimte: number | null,
        volume: number | null,
        energyLabel: string | null,
        valuationDate: Date | null
    ): V2ReferenceSet[] {
        return setDefinitions.map((setDefinition): V2ReferenceSet => {
            const specialValueArgumentGroup = findChildRecursiveByPredicate(
                setDefinition.groupTree,
                (item) =>
                    item.question.technicalReference ===
                    (setDefinition.type === SetType.SOLD
                        ? TechnicalReference.SPECIAL_VALUE_ARGUMENT_GROUP
                        : TechnicalReference.SPECIAL_VALUE_ARGUMENT_GROUP_RENT)
            );
            if (specialValueArgumentGroup) {
                const buildYearItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) => i.question.technicalReference === TechnicalReference.SPECIAL_VALUE_ARGUMENT_BUILD_YEAR
                );
                const surfaceAreaItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) =>
                        i.question.technicalReference ===
                        TechnicalReference.SPECIAL_VALUE_ARGUMENT_GEBRUIKSOPPERVLAKTE_WONEN
                );
                const plotAreaItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) =>
                        i.question.technicalReference === TechnicalReference.SPECIAL_VALUE_ARGUMENT_PERCEEL_OPPERVLAKTE
                );

                const overigeInpandigeRuimteItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) =>
                        i.question.technicalReference ===
                        TechnicalReference.SPECIAL_VALUE_ARGUMENT_OVERIGE_INPANDIGE_RUIMTE
                );
                const gebruiksOppervlakteBuitenruimteItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) =>
                        i.question.technicalReference ===
                        TechnicalReference.SPECIAL_VALUE_ARGUMENT_GEBOUWGEBONDEN_BUITENRUIMTE
                );
                const gebruiksOppervlakteExterneBergruimteItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) =>
                        i.question.technicalReference === TechnicalReference.SPECIAL_VALUE_ARGUMENT_EXTERNE_BERGRUIMTE
                );
                const volumeItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) => i.question.technicalReference === TechnicalReference.SPECIAL_VALUE_ARGUMENT_BRUTO_INHOUD
                );
                const energyLabelItem = findChildRecursiveByPredicate(
                    specialValueArgumentGroup,
                    (i) => i.question.technicalReference === TechnicalReference.SPECIAL_VALUE_ARGUMENT_ENERGY_LABEL
                );

                const specialBuildYear = parseInt(String(buildYearItem?.item.answer?.contents), 10);
                const specialPlotArea = parseFloat(String(plotAreaItem?.item.answer?.contents));
                const specialSurfaceArea = parseFloat(String(surfaceAreaItem?.item.answer?.contents));
                const specialOverigeInpandigeRuimte = parseFloat(
                    String(overigeInpandigeRuimteItem?.item.answer?.contents)
                );
                const specialGebruiksOppervlakteBuitenruimte = parseFloat(
                    String(gebruiksOppervlakteBuitenruimteItem?.item.answer?.contents)
                );
                const specialGebruiksOppervlakteExterneBergruimte = parseFloat(
                    String(gebruiksOppervlakteExterneBergruimteItem?.item.answer?.contents)
                );
                const specialVolume = parseFloat(String(volumeItem?.item.answer?.contents));
                const specialEnergyLabel = energyLabelItem?.item.question?.answerOptions.find(
                    (a: AnswerOption) => a.id === energyLabelItem?.item.answer?.answerOptionId
                )?.contents;

                return {
                    ...setDefinition,
                    buildYear: !isNaN(specialBuildYear) ? specialBuildYear : buildYear,
                    plotArea: !isNaN(specialPlotArea) ? specialPlotArea : plotArea,
                    surfaceArea: !isNaN(specialSurfaceArea) ? specialSurfaceArea : surfaceArea,
                    overigeInpandigeRuimte: !isNaN(specialOverigeInpandigeRuimte)
                        ? specialOverigeInpandigeRuimte
                        : overigeInpandigeRuimte,
                    gebruiksOppervlakteBuitenruimte: !isNaN(specialGebruiksOppervlakteBuitenruimte)
                        ? specialGebruiksOppervlakteBuitenruimte
                        : gebruiksOppervlakteBuitenruimte,
                    gebruiksOppervlakteExterneBergruimte: !isNaN(specialGebruiksOppervlakteExterneBergruimte)
                        ? specialGebruiksOppervlakteExterneBergruimte
                        : gebruiksOppervlakteExterneBergruimte,
                    volume: !isNaN(specialVolume) ? specialVolume : volume,
                    energyLabel: specialEnergyLabel ? specialEnergyLabel : energyLabel,
                    valuationDate,
                };
            }

            return {
                ...setDefinition,
                buildYear,
                plotArea,
                surfaceArea,
                overigeInpandigeRuimte,
                gebruiksOppervlakteBuitenruimte,
                gebruiksOppervlakteExterneBergruimte,
                volume,
                energyLabel,
                valuationDate,
            };
        });
    }

    private observableCache: Observable<V2ReferenceSet[] | null> | null = null;
    public referenceSets(): Observable<V2ReferenceSet[] | null> {
        if (this.observableCache !== null) {
            return this.observableCache;
        }

        this.observableCache = this.setDefinitionsProvider.setDefinitions().pipe(
            switchMap((tree) => {
                return combineLatest([
                    of(tree),
                    this.buildYearProvider.stream(0),
                    this.surfaceAreaProvider.surfaceArea(),
                    this.plotAreaProvider.plotArea(0),
                    this.overigeInpandigeRuimteProvider.stream().pipe(startWith(0)),
                    this.gebruiksOppervlakteBuitenruimteProvider.stream().pipe(startWith(0)),
                    this.gebruiksOppervlakteExterneBergruimteProvider.stream().pipe(startWith(0)),
                    this.volumeProvider.stream(),
                    this.energyLabelProvider.stream(),
                    of(this.valuationDateProvider.valuation),
                ]) as Observable<
                    [
                        typeof tree,
                        number | null,
                        number | null,
                        number | null,
                        number | null,
                        number | null,
                        number | null,
                        number | null,
                        string | null,
                        Date | null
                    ]
                >;
            }),
            map(([tree, ...rest]) => {
                if (!tree) {
                    return null;
                }

                return this.extractSpecialValues(tree, ...rest);
            }),
            shareReplay(1)
        );
        return this.observableCache;
    }
}
