import Bugsnag, {NotifiableError} from '@bugsnag/js';

//Add global metadata for user recognition/appraisal?
//-https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#global-metadata

export const bugsnagOwnClient = process.env.MIX_BUGSNAG_FRONT_END_API_KEY
    ? Bugsnag.start({
          apiKey: process.env.MIX_BUGSNAG_FRONT_END_API_KEY,
          releaseStage: getReleaseStage(),
          onError: (event) => {
              //Remove the appraisal id from the url, else it will clutter bugsnag
              const result = new RegExp(`.*/appraisals/(\\d+)/.*$`).exec(window.location.href);
              if (result !== null) {
                  const appraisalId = result[1];
                  event.addMetadata('appraisal', {id: appraisalId});
                  event.context = window.location.href.replace(`/${appraisalId}`, '/{appraisal-id}');
              }
          },
      })
    : null;

/**
 * Our own "version" of the bugsnag client, before we could call .notify with extra metadata
 * The new client doesnt allow this, so we shim it with our own logic
 */
export const bugsnagClient = bugsnagOwnClient
    ? {
          notify: (error: NotifiableError | unknown, metadata?: Record<string, Record<string, unknown>>) => {
              bugsnagOwnClient.notify(error as NotifiableError, (event) => {
                  if (metadata) {
                      for (const [key, values] of Object.entries(metadata)) {
                          event.addMetadata(key, values);
                      }
                  }
              });
          },
      }
    : null;

function getReleaseStage(): string {
    if (process.env.MIX_BUGSNAG_RELEASE_STAGE) {
        return process.env.MIX_BUGSNAG_RELEASE_STAGE;
    }

    let host = window.location.host;
    if (host.startsWith('www.')) {
        host = host.substring(4);
    }
    if (host === 'taxapi.app') {
        return 'production';
    }
    if (host.startsWith('acceptance') || host.startsWith('acc-shock')) {
        return 'acceptance';
    }

    return process.env.NODE_ENV ?? 'production';
}
