import {observer} from 'mobx-react';
import React from 'react';
import {Select} from '../../../appraising/appraise/ui/components/select';
import {
    AltumSettingsDistance,
    AltumSettings,
    AltumSettingsTransactionDate,
} from '../../../appraising/network/preselected_reference_objects_api';
import {AltumSettingsComparabilityDropdown} from './comparability_dropdown';
import {AltumSettingsSlider} from './slider';

interface OwnProps {
    value: AltumSettings;
    onChange: (value: AltumSettings) => void;
    givenValues?: {
        buildYear?: string;
        surfaceArea?: number;
    };
    showVisualSimilarity?: boolean;
}

@observer
export class AltumReferenceObjectSettings extends React.Component<OwnProps> {
    public render() {
        return (
            <div className="col-12">
                <AltumSettingsSlider
                    title="Bouwjaar"
                    value={this.props.value.buildyear_weight}
                    dropdown={
                        <AltumSettingsComparabilityDropdown
                            value={this.props.value.buildyear_comparabilty}
                            onChange={(value) =>
                                this.props.onChange({
                                    ...this.props.value,
                                    buildyear_comparabilty: value,
                                })
                            }
                            allText="Alles"
                            comparableText="< 10 jaar"
                            comparableBetterLesserText="< 20 jaar"
                        />
                    }
                    onChange={(value) =>
                        this.props.onChange({
                            ...this.props.value,
                            buildyear_weight: value,
                        })
                    }
                    description={this.props.givenValues?.buildYear}
                />
                <AltumSettingsSlider
                    title="Gebruiksoppervlakte"
                    value={this.props.value.objectarea_weight}
                    dropdown={
                        <AltumSettingsComparabilityDropdown
                            value={this.props.value.objectarea_comparabilty}
                            onChange={(value) =>
                                this.props.onChange({
                                    ...this.props.value,
                                    objectarea_comparabilty: value,
                                })
                            }
                            allText="Alles"
                            comparableText="< 10%"
                            comparableBetterLesserText="< 20%"
                        />
                    }
                    onChange={(value) =>
                        this.props.onChange({
                            ...this.props.value,
                            objectarea_weight: value,
                        })
                    }
                    description={this.props.givenValues?.surfaceArea ? `${this.props.givenValues.surfaceArea} m²` : ''}
                />
                <AltumSettingsSlider
                    title="Transactiedatum"
                    value={this.props.value.transaction_date_weight}
                    labelLow={'Niet zo recent'}
                    labelHigh={'Recent'}
                    dropdown={
                        <Select
                            id="transaction-date"
                            isDisabled={false}
                            value={this.props.value.transaction_date_limit.toString()}
                            onChange={(val) =>
                                this.props.onChange({
                                    ...this.props.value,
                                    transaction_date_limit: val
                                        ? parseInt(val, 10)
                                        : AltumSettingsTransactionDate.MONTHS_6,
                                })
                            }
                            options={[
                                {
                                    label: 'Laatste 6 Maanden',
                                    value: AltumSettingsTransactionDate.MONTHS_6.toString(),
                                },
                                {
                                    label: 'Afgelopen Jaar',
                                    value: AltumSettingsTransactionDate.YEAR_1.toString(),
                                },
                                {
                                    label: 'Laatste 2 Jaar',
                                    value: AltumSettingsTransactionDate.YEAR_2.toString(),
                                },
                                {
                                    label: 'Laatste 3 Jaar',
                                    value: AltumSettingsTransactionDate.YEAR_3.toString(),
                                },
                                {
                                    label: 'Laatste 4 Jaar',
                                    value: AltumSettingsTransactionDate.YEAR_4.toString(),
                                },
                                {
                                    label: 'Laatste 5 Jaar',
                                    value: AltumSettingsTransactionDate.YEAR_5.toString(),
                                },
                            ]}
                        />
                    }
                    onChange={(value) =>
                        this.props.onChange({
                            ...this.props.value,
                            transaction_date_weight: value,
                        })
                    }
                />
                <AltumSettingsSlider
                    title="Afstand tot taxatie"
                    value={this.props.value.distance_weight}
                    labelLow={'Meer dan 10 km'}
                    labelHigh={'Minder dan 1 km'}
                    dropdown={
                        <Select
                            id="transaction-date"
                            isDisabled={false}
                            value={this.props.value.distance_filter}
                            onChange={(val) =>
                                this.props.onChange({
                                    ...this.props.value,
                                    distance_filter: (val ?? AltumSettingsDistance.STREET) as AltumSettingsDistance,
                                })
                            }
                            options={[
                                {
                                    label: 'Straat',
                                    value: AltumSettingsDistance.STREET,
                                },
                                {
                                    label: 'Buurt',
                                    value: AltumSettingsDistance.NEIGHBOURHOOD,
                                },
                                {
                                    label: 'Wijk',
                                    value: AltumSettingsDistance.DISTRICT,
                                },
                                {
                                    label: '1 km radius',
                                    value: AltumSettingsDistance.RADIUS_1KM,
                                },
                                {
                                    label: '5 km radius',
                                    value: AltumSettingsDistance.RADIUS_5KM,
                                },
                                {
                                    label: 'Alles',
                                    value: AltumSettingsDistance.ALL,
                                },
                            ]}
                        />
                    }
                    onChange={(value) =>
                        this.props.onChange({
                            ...this.props.value,
                            distance_weight: value,
                        })
                    }
                />
                {this.props.showVisualSimilarity !== false && (
                    <AltumSettingsSlider
                        title="Visuele vergelijkbaarheid"
                        value={this.props.value.visual_comparability_weight}
                        labelLow={'Niet vergelijkbaar'}
                        labelHigh={'Goed vergelijkbaar'}
                        onChange={(value) =>
                            this.props.onChange({
                                ...this.props.value,
                                visual_comparability_weight: value,
                            })
                        }
                    />
                )}
            </div>
        );
    }
}
