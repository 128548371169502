import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from '../simple/simple_question_presenter';

import {BuildingInspectionAppointmentPresenter} from './building_inspection_appointment_presenter';
import {observer} from 'mobx-react';
import {QuestionContainer} from '../question_container';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {classNames} from '../../../../../../support/classnames';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const BuildingInspectionAppointment: React.FC<OwnProps> = observer(function BuildingInspectionAppointment(
    props
) {
    const presenter = usePresenter(
        (container) =>
            new BuildingInspectionAppointmentPresenter(
                container.network.externalAppointmentApi,
                container.business.buildingInspectionAppointmentInteractor(props.appraisal, props.questionSet),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );

    if (presenter.isDeclined || !presenter.shouldShow) {
        return null;
    }

    function renderAppointmentRequested() {
        console.error(presenter.buildingInspectionAppointmentDate);
        if (presenter.buildingInspectionAppointmentDate !== null) {
            return (
                <div>
                    {'Er is een afspraak gepland met de bewoner op: ' + presenter.buildingInspectionAppointmentDate}
                </div>
            );
        } else if (presenter.respondsInAnHour) {
            return (
                <div>
                    {
                        'Er is een verzoek tot het maken van een afspraak verstuurd. De bouwinspecteur neemt binnen 15 minuten contact op.'
                    }
                </div>
            );
        } else {
            return <div>{'Er is een verzoek tot het maken van een afspraak verstuurd.'}</div>;
        }
    }

    function renderRequest() {
        return (
            <>
                <span>
                    Wij raden aan om een bouwkundig rapport op te laten maken. Laat jezelf of de opdrachtgever bellen om
                    een afspraak te maken. Jobse Groep streeft ernaar om binnen 15 minuten contact op te nemen.
                </span>

                <div>
                    {presenter.creatingRequest ? (
                        <>
                            <div className="form-group" data-test-box="Contactpersoon">
                                <label htmlFor="duplication-target">Contactpersoon:</label>
                                <div className="input-group">
                                    <select
                                        className="form-control custom-select"
                                        value={presenter.selectedContact}
                                        onChange={(e) => presenter.onContactChange(e.target.value)}
                                    >
                                        {presenter.appointmentContacts.map((contact) => (
                                            <option key={contact.type} value={contact.type}>
                                                {contact.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="form-grid-item-body">
                                <div className="form-group">
                                    {findChildrenForQuestion(props.question, props.questionSet).map((child) => (
                                        <QuestionContainer
                                            key={child.uuid}
                                            disabled={presenter.loading || props.disabled}
                                            appraisal={props.appraisal}
                                            question={child}
                                            parentAnswerUuid={props.parentAnswerUuid}
                                            questionSet={props.questionSet}
                                            validationErrors={props.validationErrors}
                                            validationMessages={props.validationMessages}
                                            forceShowValidationMessages={props.forceShowValidationMessages}
                                            hiddenQuestionTypes={props.hiddenQuestionTypes}
                                            pagePartsSet={props.pagePartsSet}
                                            activePagePart={props.activePagePart}
                                            renderingContext={props.renderingContext}
                                            questionRenderingData={props.questionRenderingData}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="form-group" data-test-box="Contactpersoon">
                                <label htmlFor="duplication-target">Opmerkingen:</label>
                                <div className="input-group">
                                    <textarea
                                        className={classNames('form-control')}
                                        rows={3}
                                        readOnly={presenter.isSubmitting}
                                        value={presenter.comment}
                                        onChange={(e) => presenter.onCommentChange(e.target.value)}
                                    />
                                </div>
                            </div>
                            <button
                                disabled={presenter.isSubmitting}
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => presenter.onSubmitRequestClicked()}
                            >
                                Verzenden
                            </button>
                            <button className="btn btn-secondary btn-sm" onClick={() => presenter.onCancelClicked()}>
                                Annuleren
                            </button>
                        </>
                    ) : (
                        <div className="mt-3">
                            <button
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => presenter.onCreateRequestClicked()}
                            >
                                Neem contact op
                            </button>
                            <button className="btn btn-secondary btn-sm" onClick={() => presenter.onDeclineClicked()}>
                                Nee bedankt
                            </button>
                        </div>
                    )}
                </div>
            </>
        );
    }

    return (
        <div className="form-group">
            <div className="alert-inline alert-info ion-md-information-circle">
                <h3>Bouwinspectie</h3>
                {presenter.appointmentRequested ? renderAppointmentRequested() : renderRequest()}
            </div>
        </div>
    );
});
