import {TechnicalReference} from '../../enum/technical_reference';
import {AnswerOption} from '../../models/answer_option';

export interface ApiAnswerOption {
    id: number;
    uuid: string;
    contents: string;
    report_value: string | null;
    technical_reference: TechnicalReference[] | null;
    labels: string | null;
    is_unique: boolean;
    is_required: boolean;
    is_default: boolean;
    is_ignored_in_report: boolean;
}

export function apiAnswerOptionToAnswerOption(apiAnswerOption: ApiAnswerOption): AnswerOption {
    return {
        id: apiAnswerOption.id,
        uuid: apiAnswerOption.uuid,
        contents: apiAnswerOption.contents,
        reportValue: apiAnswerOption.report_value,
        technicalReference: apiAnswerOption.technical_reference ?? [],
        labels: apiAnswerOption.labels,
        isUnique: apiAnswerOption.is_unique,
        isRequired: apiAnswerOption.is_required,
        isDefault: apiAnswerOption.is_default,
        isIgnoredInReport: apiAnswerOption.is_ignored_in_report,
    };
}
