import * as React from 'react';

import {Appraisal} from '../../../../../models/appraisal';
import {PhotosViewerPresenter} from './photo_viewer_presenter';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {ImageSlider} from '../../../../../components/image_viewer/image_slider';
import {observer} from 'mobx-react';

export interface OwnProps {
    question: Question;
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export const PhotosViewer: React.FC<OwnProps> = observer(function PhotosViewer(props) {
    const presenter = usePresenter(
        (container) =>
            new PhotosViewerPresenter(
                props.question,
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet)
            )
    );

    return (
        <div className="form-group photo-viewer">
            <ImageSlider zoomable images={presenter.fileReferences.map((file) => file.url)} />
        </div>
    );
});
