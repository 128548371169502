import * as React from 'react';

import {macroIsActive, toggleMacro} from '../../../../../../../../../business/toggle_macro';

import {classNames} from '../../../../../../../../../../support/classnames';

interface OwnProps {
    label: string;
    isRequired?: boolean;
    name: string;
    value: string;
    macros?: string[];
    onChange: (e: string) => void;
    disabled?: boolean;
    maxLength?: number;
    className?: string;
    children?: React.ReactNode;
}

export class Textarea extends React.Component<OwnProps> {
    public render() {
        const macros = this.props.macros || [];
        return (
            <div className={!this.props.disabled && macros.length !== 0 ? 'form-group has-macros' : 'form-group'}>
                <label htmlFor={this.props.name}>
                    {this.props.label}
                    {this.props.isRequired ? <span className="form-label-highlight">*</span> : null}
                </label>
                {!this.props.disabled && macros.length !== 0 ? (
                    <span className="macros-container">
                        <strong>Macro&apos;s</strong>
                        <ul className="macros">
                            {macros.map((macro) => (
                                <li
                                    key={macro}
                                    className={classNames({
                                        active: macroIsActive(macro, this.props.value),
                                    })}
                                >
                                    <button
                                        className="add-macro"
                                        onClick={() => this.props.onChange(toggleMacro(macro, this.props.value))}
                                        title={macro}
                                    >
                                        {macro}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </span>
                ) : null}
                {this.props.children}
                <textarea
                    id={this.props.name}
                    name={this.props.name}
                    className={classNames(this.props.className, 'form-control')}
                    rows={3}
                    value={this.props.value}
                    onChange={(e) => this.props.onChange(e.target.value)}
                    readOnly={this.props.disabled}
                    maxLength={this.props.maxLength}
                />
            </div>
        );
    }
}
