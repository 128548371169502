import React, {useEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import {Loader} from '../../../appraising/appraise/ui/components/loader';
import {Modal} from '../../../appraising/appraise/ui/content/questions/components/modal';
import {classNames} from '../../../support/classnames';

interface OwnProps {
    id: string;
    title: string;
    icon: string;
    description: string;
    loading?: boolean;
    children?: React.ReactNode;
    errorStatus?: boolean;
}

export function PrecheckWidget(props: OwnProps) {
    const [mobileButtonContainer, setMobileButtonContainer] = useState<HTMLDivElement | null>(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const footer = document.querySelector('.card footer');

        if (!footer) {
            return;
        }

        const container = document.createElement('div');
        container.id = `precheck-widget-${props.id}`;
        container.classList.add('pre-check-mobile-view');
        container.classList.add('row');

        footer.parentNode?.insertBefore(container, footer);

        setMobileButtonContainer(container);

        return () => {
            container.remove();
            setMobileButtonContainer(null);
        };
    }, [props.id]);

    return (
        <div className="widget pre-check-widget">
            {!props.loading ? (
                <>
                    <div className="widget-title">
                        <h3>
                            <span className={props.icon} />
                            {props.title}
                        </h3>
                    </div>
                    {props.children}
                </>
            ) : (
                <div className="d-flex justify-content-center">
                    <Loader isSmall />
                </div>
            )}
            <Modal visible={modalOpen}>
                <div className="modal-header">
                    <h2>
                        <span className={classNames(props.icon, 'mr-1')} />
                        {props.title}
                    </h2>
                </div>
                <div className="modal-body d-flex flex-column">{props.children}</div>
                <div className="modal-footer">
                    <button className="btn btn-secondary btn-full ion-md-close" onClick={() => setModalOpen(false)}>
                        Sluiten
                    </button>
                </div>
            </Modal>
            {mobileButtonContainer &&
                createPortal(
                    <dl
                        className={classNames('alert-inline ion-md-help-circle', {
                            'alert-danger': props.errorStatus,
                            'alert-success': !props.errorStatus,
                        })}
                    >
                        <dt>Pre-check: {props.title}</dt>
                        <dd>
                            <div className="d-flex justify-content-between align-items-center">
                                {props.description}
                                <button
                                    className="btn btn-sm btn-primary ml-1"
                                    style={{flexShrink: 0}}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setModalOpen(true);
                                    }}
                                >
                                    Bekijken
                                </button>
                            </div>
                        </dd>
                    </dl>,
                    mobileButtonContainer
                )}
        </div>
    );
}
