import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../../support/composite_subscription';
import {Presenter} from '../../../../support/presenter/presenter';
import {SearchAppraisal} from '../../../models/search_appraisal';
import {SuperMacroValueMap} from '../../../models/macro';
import {Appraisal} from '../../../models/appraisal';
import {AppraisalApi} from '../../../network/appraisal_api';
import {MacroInteractor} from '../../../business/macro_interactor';
import {QuestionSet} from '../../../models/question_set';
import {QuestionExtensionType} from '../../../enum/question_extension_type';
import {Question} from '../../../models/question';
import {AnswerController} from '../../../business/answering/answer_controller';
import {AnswerPathStubber} from '../../../business/answering/answer_path_stubber';
import {FlashMessageBroadcaster, Type} from '../../../business/flash_message_broadcaster';

export class AppraisalCopyPresenter implements Presenter {
    private _subscriptions = new CompositeSubscription();

    @observable public appraisalSearchQuery: string | null = null;
    @observable public searchAppraisals: SearchAppraisal[] | null = null;
    @observable public isSearchingAppraisals = false;
    @observable public isLoadingAppraisalValues = false;
    @observable public appraisalFillValues: SuperMacroValueMap[] | null = null;
    @observable public selectedFillValues: SuperMacroValueMap[] = [];

    @observable
    private appraisalSearchTimeout?: NodeJS.Timeout;

    private copyableQuestions: Question[] = [];

    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private appraisalApi: AppraisalApi,
        private macroInteractor: MacroInteractor,
        private answerController: AnswerController,
        private answerPathStubber: AnswerPathStubber,
        private flashMessageBroadcaster: FlashMessageBroadcaster
    ) {
        makeObservable(this);

        this.copyableQuestions = this.questionSet.questions.filter((q) =>
            q.extensions.some((e) => e.type === QuestionExtensionType.SUPER_MACROS && e.isAppraisalCopier)
        );
    }

    public mount(): void {
        // Unused
    }

    public unmount(): void {
        this._subscriptions.clear();
    }

    @computed
    public get isAppraisalSearchPending() {
        return this.appraisalSearchTimeout !== undefined;
    }

    @action
    public onSearchAppraisal(query?: string, force = false) {
        if (query !== undefined) {
            this.appraisalSearchQuery = query;
        }

        const search = () => {
            runInAction(() => {
                this.isSearchingAppraisals = true;
                this.appraisalFillValues = null;
                this.selectedFillValues = [];
                clearTimeout(this.appraisalSearchTimeout);
                this.appraisalSearchTimeout = undefined;
            });
            this.appraisalApi
                .search(this.appraisalSearchQuery ?? '')
                .then((appraisals) => {
                    runInAction(() => {
                        this.searchAppraisals = appraisals.filter((a) => a.id !== this.appraisal.id);
                    });
                })
                .finally(() => {
                    runInAction(() => {
                        this.isSearchingAppraisals = false;
                    });
                });
        };

        if (this.appraisalSearchTimeout) {
            clearTimeout(this.appraisalSearchTimeout);
            this.appraisalSearchTimeout = undefined;
        }

        if (force) {
            search();
        } else {
            this.appraisalSearchTimeout = setTimeout(search, 500);
        }
    }

    public async getAppraisalValues(appraisalId: number) {
        if (this.copyableQuestions.length === 0) {
            runInAction(() => {
                this.isLoadingAppraisalValues = false;
                this.appraisalFillValues = [];
                this.selectedFillValues = [];
            });
            return;
        }

        runInAction(() => {
            this.isLoadingAppraisalValues = true;
        });

        const values = await this.macroInteractor.getAppraisalValues(
            appraisalId,
            this.copyableQuestions.map((q) => q.uuid)
        );

        runInAction(() => {
            this.isLoadingAppraisalValues = false;
            this.appraisalFillValues = values;
            this.selectedFillValues = [];
        });
    }

    @action
    public setSelectedFillValues(values: SuperMacroValueMap[]) {
        this.selectedFillValues = values;
    }

    @action
    public clearAppraisalValues() {
        this.appraisalFillValues = null;
        this.selectedFillValues = [];
    }

    public copySelectedValues() {
        this.answerController.aggregateUpdates(() => {
            for (const valueMap of this.selectedFillValues) {
                const question = this.questionSet.findQuestionByUuid(valueMap.questionUuid);
                if (question === undefined) {
                    console.error(`Could not find question with uuid ${valueMap.questionUuid}`);
                    continue;
                }

                // Try to locate top level answer
                const questionPath =
                    question.parentUuid === null
                        ? [question]
                        : this.questionSet.findParentPathByPredicateRecursive(question, (q) => q.parentUuid === null);
                if (questionPath === null || questionPath.length === 0) {
                    console.error(`Could not find root question for question with uuid ${valueMap.questionUuid}`);
                    continue;
                }

                const rootQuestion = questionPath[0];

                let rootAnswer = this.answerController.answerByIdentifiersOrStub(
                    rootQuestion.uuid,
                    null,
                    valueMap.iterationMap?.[rootQuestion.uuid] ?? null
                );

                if (rootAnswer.isDeleted) {
                    rootAnswer = this.answerController.restore(rootAnswer.uuid) ?? rootAnswer;
                }

                // Stub from root to group, keeping into account the iteration map
                const stubbedAnswers = this.answerPathStubber
                    .stubChildren(
                        rootAnswer,
                        {},
                        Object.entries(valueMap.iterationMap ?? {}).map(([questionUuid, iteration]) => ({
                            questionUuid,
                            iteration,
                        })),
                        // We only allow stubbing questions on the path from root to the question we're copying
                        questionPath.map((q) => q.uuid),
                        false,
                        () => false
                    )
                    .concat([rootAnswer]);

                const questionAnswer = stubbedAnswers.find((a) => a.questionUuid === question.uuid);
                if (questionAnswer === undefined) {
                    console.error(`Could not find answer for question with uuid ${valueMap.questionUuid}`);
                    continue;
                }

                // Now that we have stubbed our way to the question that is applicable to this value map we can actually copy all values for it's children that are actually in the value map

                this.answerPathStubber.stubValuesMap(questionAnswer, valueMap.values, true);
            }
        });

        this.clearAppraisalValues();
        this.flashMessageBroadcaster.broadcast('De antwoorden zijn succesvol gekopieerd.', Type.Success);
    }
}
