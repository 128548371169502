import {Observable, combineLatest} from 'rxjs';

import {AnswerController} from '../answering/answer_controller';
import {AppraisalProvider} from '../appraisal_provider';
import {ConditionGroup} from '../../models/condition_group';
import {ConditionsInteractor} from './conditions_interactor';
import {collectAnswersObservablesForConditionGroups} from './answer_observable_for_condition_groups';
import {isHiddenBasedOnEffectStates} from './hidden_based_on_effect_states';
import {map} from 'rxjs/operators';

export interface MacroEffectInteractor {
    isHiddenStream(conditionGroups: ConditionGroup[], parentAnswerUuid: string | null): Observable<boolean>;
}

export class DefaultMacroEffectInteractor implements MacroEffectInteractor {
    constructor(
        private conditionsInteractor: ConditionsInteractor,
        private answerController: AnswerController,
        private appraisalProvider: AppraisalProvider
    ) {}

    public isHiddenStream(conditionGroups: ConditionGroup[], parentAnswerUuid: string | null): Observable<boolean> {
        const answerObservablesForQuestions = collectAnswersObservablesForConditionGroups(
            this.answerController,
            conditionGroups,
            parentAnswerUuid
        );

        return combineLatest([this.appraisalProvider.stream, ...answerObservablesForQuestions]).pipe(
            map(() => this.isHidden(conditionGroups, parentAnswerUuid))
        );
    }

    private isHidden(conditionGroups: ConditionGroup[], parentAnswerUuid: string | null): boolean {
        const effectStates = this.conditionsInteractor.getForConditionGroups(conditionGroups, parentAnswerUuid);

        const isHidden = isHiddenBasedOnEffectStates(effectStates);
        if (isHidden) {
            return true;
        }

        return false;
    }
}
