import {ExplanationData} from './explanation_data';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';

export class ExplanationEnergyLabelGenerator {
    public generateDetailsSentence(
        explanationData: ExplanationData,
        referenceObjectAnswer: ReferenceObjectAnswer
    ): string {
        const result: string[] = [];
        if (explanationData.object.energyLabel === undefined) {
            return result.join('');
        }
        const diff = this.calculateGradeDifference(
            referenceObjectAnswer.referenceObject.energielabel,
            explanationData.object.energyLabel
        );
        if (diff !== 0 && explanationData.object.energyLabel !== null) {
            result.push(
                `heeft energielabel ${referenceObjectAnswer.referenceObject.energielabel} ten opzichte van ${explanationData.object.energyLabel} van het getaxeerde object`
            );
        }

        return result.join('');
    }
    public getEnergyLabelStatus(referenceObjectAnswer: ReferenceObjectAnswer, explanationData: ExplanationData) {
        if (explanationData.object.energyLabel === undefined) {
            return 'Geen energielabel';
        }

        const diff = this.calculateGradeDifference(
            referenceObjectAnswer.referenceObject.energielabel,
            explanationData.object.energyLabel
        );
        if (diff !== null) {
            if (diff >= 1 && diff <= 4) {
                return 'Lager energielabel';
            } else if (diff > 4) {
                return 'Veel lager energielabel';
            } else if (diff <= -1 && diff >= -4) {
                return 'Hoger energielabel';
            } else if (diff < -4) {
                return 'Veel hoger energielabel';
            } else {
                return 'Vergelijkbaar';
            }
        }
        return 'Onbekend';
    }

    private assignNumericValue(grade: string | null) {
        switch (grade) {
            case 'A+++++':
                return 10;
            case 'A++++':
                return 9;
            case 'A+++':
                return 8;
            case 'A++':
                return 7;
            case 'A+':
                return 6;
            case 'A':
                return 5;
            case 'B':
                return 4;
            case 'C':
                return 3;
            case 'D':
                return 2;
            case 'E':
                return 1;
            case 'F':
                return 0;
            case 'G':
                return -1;
            case 'Geen energielabel':
            default:
                return null; // Return null for an unknown grade
        }
    }

    // Functie om het verschil tussen twee klasseringen te berekenen
    private calculateGradeDifference(grade1: string | null, grade2: string | null) {
        const numericValue1 = this.assignNumericValue(grade1);
        const numericValue2 = this.assignNumericValue(grade2);

        if (numericValue1 !== null && numericValue2 !== null) {
            return Math.abs(numericValue1 - numericValue2);
        } else if (numericValue1 === null || numericValue2 === null) {
            return null; // Return null if one grade is unknown
        } else {
            return null; // Handle unknown grades
        }
    }
}
