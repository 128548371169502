import * as React from 'react';

import {Appraisal} from '../../../../../models/appraisal';
import {ObjectUsageDetailsPresenter} from './object_usage_details_presenter';
import {Question} from '../../../../../models/question';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps {
    appraisal: Appraisal;
    question: Question;
    disabled?: boolean;
}

export const ObjectUsageDetails: React.FC<OwnProps> = observer(function ObjectUsageDetails(props) {
    const presenter = usePresenter(() => new ObjectUsageDetailsPresenter(props.appraisal, props.question));

    return (
        <div>
            <div className="form-group">
                <label htmlFor="object_usage_type">Situatie van de woning</label>
                <input
                    type="text"
                    id="object_usage_type"
                    name="object_usage_type"
                    className="form-control"
                    disabled={props.disabled || presenter.isDisabled}
                    value={props.appraisal.objectUsageType.text}
                />
            </div>
            <div className="form-group">
                <label htmlFor="object_ownership_type">Hoe gaat de woning gebruikt worden?</label>
                <input
                    type="text"
                    id="object_ownership_type"
                    name="object_ownership_type"
                    className="form-control"
                    disabled={props.disabled || presenter.isDisabled}
                    value={props.appraisal.objectOwnershipType.text}
                />
            </div>
            {props.appraisal.objectOwnershipType.other !== null && (
                <div className="form-group">
                    <textarea
                        id="object_ownership_type_other"
                        name="object_ownership_type_other"
                        className="form-control"
                        readOnly={props.disabled || presenter.isDisabled}
                        value={props.appraisal.objectOwnershipType.other}
                    />
                </div>
            )}
            <div className="alert-inline alert-hint ion-md-help-circle">
                De situatie van de woning en toekomstig gebruik van de woning kan aangepast worden in de
                opdrachtvoorwaarden.
            </div>
        </div>
    );
});
