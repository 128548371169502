import * as React from 'react';

import {CompactHouseGroupQuestionPresenter} from './compact_house_group_question_presenter';
import {SimpleQuestionPresenterProps} from '../simple/simple_question_presenter';
import {classNames} from '../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {QuestionLoader} from '../../../components/question_loader';
import {shouldShowTitle} from '../../../../../../support/should_show_title';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const CompactHouseGroupQuestion: React.FC<OwnProps> = observer(function CompactHouseGroupQuestion(props) {
    const presenter = usePresenter(
        (container) =>
            new CompactHouseGroupQuestionPresenter(
                props.question,
                props.iteration,
                props.appraisal,
                container.business.answerController(props.appraisal, props.questionSet),
                props.questionSet,
                props.parentAnswerUuid,
                props.renderingContext
            )
    );

    if (presenter.numberOfFloors === undefined) {
        return <QuestionLoader />;
    }

    return (
        <div className="form-group">
            {shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel) && (
                <label>Selecteer het aantal woonlagen</label>
            )}
            <div className="form-control form-house-group-compact">
                <div className="btns btns-start">
                    <button
                        disabled={presenter.numberOfFloors === 0 || props.disabled || presenter.isDisabled}
                        className={classNames('btn ion-md-remove', {
                            disabled: presenter.numberOfFloors === 0 || props.disabled || presenter.isDisabled,
                        })}
                        onClick={() => presenter.setDeleteVisible(!presenter.deleteVisible)}
                    >
                        &nbsp;
                    </button>
                </div>
                <div
                    className={classNames('house-part-floor', {
                        disabled: presenter.numberOfFloors === 0 || props.disabled || presenter.isDisabled,
                    })}
                >
                    <strong className="count">
                        {presenter.numberOfFloors} {presenter.numberOfFloors === 1 ? 'Woonlaag' : 'Woonlagen'}
                    </strong>
                </div>
                <div className="btns btns-end">
                    <button
                        disabled={props.disabled || presenter.isDisabled}
                        className={classNames('btn ion-md-add', {
                            disabled: props.disabled || presenter.isDisabled,
                        })}
                        onClick={() => presenter.incrementFloors()}
                    >
                        &nbsp;
                    </button>
                </div>
            </div>
            <div
                className={classNames('group-collapsible', {
                    'group-collapsible-visible': presenter.deleteVisible,
                })}
            >
                <ul className="list-simple group-collapsible-children group-collapsible-no-border mt-2">
                    {Array.from(presenter.floorNames?.entries() ?? [])
                        .sort(([a], [b]) => a - b)
                        .map(([index, name]) => (
                            <li key={index}>
                                {index}e woonlaag&nbsp;
                                <span className="text-secondary">{name}</span>
                                <span className="list-options">
                                    <a className="ion-md-trash" onClick={() => presenter.removeFloor(index)}></a>
                                </span>
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
});
