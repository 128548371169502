import * as React from 'react';

import {Appraisal} from '../../../../../../../../../models/appraisal';
import {BuildYearBadge} from '../../../../../../../../../components/badges/build_year_badge';
import {BuildyearBadgeAdapterPresenter} from './buildyear_badge_adapter_presenter';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';

interface OwnProps {
    buildYear: number;
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export const BuildyearBadge: React.FC<OwnProps> = observer(function BuildyearBadge(props) {
    const presenter = usePresenter(
        (container) =>
            new BuildyearBadgeAdapterPresenter(
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet)
            )
    );

    if (presenter.buildYear === null) {
        return null;
    }
    return <BuildYearBadge buildYear={props.buildYear} referenceBuildYear={parseInt(presenter.buildYear, 10)} />;
});
