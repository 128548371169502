import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {isEmpty} from '../../../../../../../../../../support/util';
import {lowercaseFirstChar} from '../../../../../../../../../../support/string';

export class ExplanationBuildingsGenerator {
    public generateDetailsSentence(referenceObjectAnswer: ReferenceObjectAnswer): string {
        const result: string[] = [];

        if (referenceObjectAnswer.referenceObject.aanbouwStatus) {
            result.push(
                `bij,- op- of aanbouwen die ${referenceObjectAnswer.referenceObject.aanbouwStatus.toLocaleLowerCase()} zijn`
            );
        }

        if (!isEmpty(referenceObjectAnswer.referenceObject.aanbouwUitleg)) {
            result.push(
                `, namelijk: ${lowercaseFirstChar(
                    referenceObjectAnswer.referenceObject.aanbouwUitleg.trim() +
                        (!referenceObjectAnswer.referenceObject.aanbouwUitleg.endsWith('.') ? '.' : '')
                )}`
            );
        }
        return result.join('');
    }
}
