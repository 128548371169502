import * as React from 'react';
import {ReferenceSaleImage} from '../../../v1/models/reference_sale_image';
import {buildPhotoSets} from './photo_sets';
import {ImageSlider} from '../../../../../../../../../components/image_viewer/image_slider';
import {imagesToKey} from '../reference_object_tile/reference_object_tile_image_slider';
import {Swiper} from 'swiper/types';
import {ReferenceObjectsQuestionContext} from '../../referene_objects_question_context';

export function ReferenceImageSlider({
    images,
    photoAnswerUuid,
    onSwiperInit,
    onSwiperSlideChange,
}: {
    images: ReferenceSaleImage[];
    photoAnswerUuid?: string;
    onSwiperInit?: (activeImage: ReferenceSaleImage, swiper: Swiper) => void;
    onSwiperSlideChange?: (activeImage: ReferenceSaleImage, swiper: Swiper) => void;
}) {
    const context = React.useContext(ReferenceObjectsQuestionContext);

    const photoSets = React.useMemo(() => buildPhotoSets(images), [imagesToKey(images)]);

    const activeSet = 0;

    const urls = React.useMemo(
        () =>
            photoSets.length > 0
                ? photoSets[activeSet]?.images.map((i) => i.url).filter((url): url is string => url !== null) ?? []
                : [],
        [photoSets, activeSet]
    );

    const onAfterInit = React.useCallback(
        (swiper: Swiper) => {
            if (onSwiperInit) {
                const url = urls[swiper.realIndex];
                const image = photoSets[activeSet]?.images.find((i) => i.url === url);
                if (image) {
                    onSwiperInit(image, swiper);
                }
            }
        },
        [photoSets, urls, onSwiperInit]
    );

    const onRealIndexChange = React.useCallback(
        (swiper: Swiper) => {
            if (onSwiperSlideChange) {
                const url = urls[swiper.realIndex];
                const image = photoSets[activeSet]?.images.find((i) => i.url === url);
                if (image) {
                    onSwiperSlideChange(image, swiper);
                }
            }
        },
        [photoSets, urls, onSwiperSlideChange]
    );

    return (
        <ImageSlider
            images={urls}
            onClick={(slider) => {
                const img = slider.clickedSlide?.querySelector('img');

                if (img && img instanceof HTMLImageElement) {
                    const index = images.findIndex((i) => i.url === img.src);
                    context.showImagesModal?.(images, index >= 0 ? index : undefined, photoAnswerUuid);
                }
            }}
            onAfterInit={onAfterInit}
            onRealIndexChange={onRealIndexChange}
        />
    );
}
