import {
    Sustainability,
    SustainabilityCurrentFuture,
    SustainabilityGlass,
    SustainabilityInstallation,
    SustainabilityInsulation,
    SustainabilityMeasure,
    SustainabilityVentilation,
} from '../../models/sustainability';

export interface ApiSustainability {
    build_year: number;
    inner_surface: number;
    building_type: string;

    provided_label: {
        temporary: string | null;
        definitive: string | null;
        definitive_type: string | null;
        definitive_validity: string | null;
    };

    label: ApiSustainabilityCurrentFuture<string | null>;
    beng2: ApiSustainabilityCurrentFuture<number | null>;
    comfort_score: ApiSustainabilityCurrentFuture<number | null>;
    co2: ApiSustainabilityCurrentFuture<number | null>;
    energy_usage: ApiSustainabilityCurrentFuture<number | null>;
    gas_usage: ApiSustainabilityCurrentFuture<number | null>;

    financial: {
        total_investment: number | null;
        total_savings: number | null;
        months_to_pay_off: number | null;
        eco_value: number | null;
        savings_monthly: number | null;
        energy_cost_monthly: number | null;
        monthly_loan_payment: number | null;
    };

    energy_price: {
        variable_gas_price: number | null;
        variable_electricity_price: number | null;
        solarpanel_watt_peak_per_m2: number | null;
    };

    wall_insulation: ApiSustainabilityMeasure<SustainabilityInsulation | null>;
    roof_insulation: ApiSustainabilityMeasure<SustainabilityInsulation | null>;
    floor_insulation: ApiSustainabilityMeasure<SustainabilityInsulation | null>;
    living_room_windows: ApiSustainabilityMeasure<SustainabilityGlass | null>;
    bedroom_windows: ApiSustainabilityMeasure<SustainabilityGlass | null>;
    ventilation: ApiSustainabilityMeasure<SustainabilityVentilation | null>;
    installation: ApiSustainabilityMeasure<SustainabilityInstallation | null>;
    solar_panels: ApiSustainabilityMeasure<number | null>;
    shower: ApiSustainabilityMeasure<boolean | null>;
}

export interface ApiSustainabilityCurrentFuture<T> {
    current: {
        value: T;
        description: string | null;
    };

    future: {
        value: T;
        description: string | null;
    };
}

export interface ApiSustainabilityMeasure<T> extends ApiSustainabilityCurrentFuture<T> {
    investment: number | null;
    savings: number | null;
    co2_reduce: number | null;
    beng2: number | null;
    gas_savings: number | null;
    electricity_savings: number | null;
}

export function apiSustainabilityToSustainability(data: ApiSustainability): Sustainability {
    return {
        buildYear: data.build_year,
        innerSurface: data.inner_surface,
        buildingType: data.building_type,
        providedLabel: {
            temporary: data.provided_label.temporary,
            definitive: data.provided_label.definitive,
            definitiveType: data.provided_label.definitive_type,
            definitiveValidity: data.provided_label.definitive_validity,
        },
        label: apiSustainabilityCurrentFutureToSustainabilityCurrentFuture(data.label),
        beng2: apiSustainabilityCurrentFutureToSustainabilityCurrentFuture(data.beng2),
        comfortScore: apiSustainabilityCurrentFutureToSustainabilityCurrentFuture(data.comfort_score),
        co2: apiSustainabilityCurrentFutureToSustainabilityCurrentFuture(data.co2),
        energyUsage: apiSustainabilityCurrentFutureToSustainabilityCurrentFuture(data.energy_usage),
        gasUsage: apiSustainabilityCurrentFutureToSustainabilityCurrentFuture(data.gas_usage),
        financial: {
            totalInvestment: data.financial.total_investment,
            totalSavings: data.financial.total_savings,
            monthsToPayOff: data.financial.months_to_pay_off,
            ecoValue: data.financial.eco_value,
            savingsMonthly: data.financial.savings_monthly,
            energyCostMonthly: data.financial.energy_cost_monthly,
            monthlyLoanPayment: data.financial.monthly_loan_payment,
        },
        energyPrice: {
            variableGasPrice: data.energy_price.variable_gas_price,
            variableElectricityPrice: data.energy_price.variable_electricity_price,
            solarpanelWattPeakPerM2: data.energy_price.solarpanel_watt_peak_per_m2,
        },
        wallInsulation: apiSustainabilityMeasureToSustainabilityMeasure(data.wall_insulation),
        roofInsulation: apiSustainabilityMeasureToSustainabilityMeasure(data.roof_insulation),
        floorInsulation: apiSustainabilityMeasureToSustainabilityMeasure(data.floor_insulation),
        livingRoomWindows: apiSustainabilityMeasureToSustainabilityMeasure(data.living_room_windows),
        bedroomWindows: apiSustainabilityMeasureToSustainabilityMeasure(data.bedroom_windows),
        ventilation: apiSustainabilityMeasureToSustainabilityMeasure(data.ventilation),
        installation: apiSustainabilityMeasureToSustainabilityMeasure(data.installation),
        solarPanels: apiSustainabilityMeasureToSustainabilityMeasure(data.solar_panels),
        shower: apiSustainabilityMeasureToSustainabilityMeasure(data.shower),
    };
}

export function apiSustainabilityCurrentFutureToSustainabilityCurrentFuture<T>(
    data: ApiSustainabilityCurrentFuture<T>
): SustainabilityCurrentFuture<T> {
    return {
        current: {
            value: data.current.value,
            description: data.current.description,
        },
        future: {
            value: data.future.value,
            description: data.future.description,
        },
    };
}

export function apiSustainabilityMeasureToSustainabilityMeasure<T>(
    data: ApiSustainabilityMeasure<T>
): SustainabilityMeasure<T> {
    return {
        ...apiSustainabilityCurrentFutureToSustainabilityCurrentFuture(data),
        investment: data.investment,
        savings: data.savings,
        co2Reduce: data.co2_reduce,
        beng2: data.beng2,
        gasSavings: data.gas_savings,
        electricitySavings: data.electricity_savings,
    };
}
