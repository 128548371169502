import {action, computed, makeObservable, observable, override} from 'mobx';

import {SimpleQuestionPresenter} from '../../simple/simple_question_presenter';
import {BuildingCostsLabel, buildingCostsLabels} from './building_costs_labels';

export class BuildingCostsConditionPresenter extends SimpleQuestionPresenter {
    @observable
    public active = false;

    @computed
    public get activeLabelValue() {
        return this.textToValue(this.answer?.contents ?? null);
    }

    constructor(...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public mount(): void {
        super.mount();
    }

    public unmount(): void {
        super.unmount();
    }

    @override
    public onChange(value: string, setTouchedState = true) {
        if (this.answer === undefined) {
            throw new Error('Can not use undefined answer');
        }

        super.onChange(this.valueToTextValue(parseInt(value, 10)), setTouchedState);
    }

    @computed
    public get short(): string {
        if (this.answer !== undefined && this.answer.contents !== null) {
            const label = this.activeLabel(this.answer.contents);
            if (label) {
                return label.short;
            }
        }
        if (this.question.defaultValue !== null) {
            const defaultValue = this.findDefaultValue();
            if (defaultValue !== null) {
                return defaultValue.short;
            }
        }
        return 'G';
    }

    @computed
    public get hex(): string {
        if (this.answer !== undefined && this.answer.contents !== null) {
            const label = this.activeLabel(this.answer.contents);
            if (label) {
                return label.hex;
            }
        }
        if (this.question.defaultValue !== null) {
            const defaultValue = this.findDefaultValue();
            if (defaultValue !== null) {
                return defaultValue.hex;
            }
        }
        return '#2BD7D8';
    }

    @action
    public toggleActive() {
        this.active = !this.active;
    }

    private valueToTextValue(value: number | null): string {
        const label = buildingCostsLabels.find((o: BuildingCostsLabel) => o.value === value);
        if (label) {
            return label.textValue;
        }
        if (this.question.defaultValue !== null) {
            const defaultValue = this.findDefaultValue();
            if (defaultValue !== null) {
                return defaultValue.textValue;
            }
        }
        return 'goed'; // default value
    }

    private textToValue(textValue: string | null): number {
        const label = buildingCostsLabels.find((o: BuildingCostsLabel) => o.textValue === textValue);
        if (label) {
            return label.value;
        }
        if (this.question.defaultValue !== null) {
            const defaultValue = this.findDefaultValue();
            if (defaultValue !== null) {
                return defaultValue.value;
            }
        }
        return 6; // default value
    }

    private activeLabel(textValue: string): BuildingCostsLabel | null {
        return buildingCostsLabels.find((o: BuildingCostsLabel) => o.textValue === textValue) ?? null;
    }

    private findDefaultValue() {
        return (
            buildingCostsLabels.find(
                (x) =>
                    x.textValue.toLowerCase() === this.question.defaultValue?.toLowerCase() ||
                    x.short.toLowerCase() === this.question.defaultValue?.toLowerCase()
            ) ?? null
        );
    }
}
