import {makeObservable, observable, runInAction} from 'mobx';

import {Appraisal} from '../../../../../../models/appraisal';
import {Presenter} from '../../../../../../../support/presenter/presenter';
import {TaskHelper} from '../../../../../../business/task_helper';
import {AppraisalApi} from '../../../../../../network/appraisal_api';
import {AnswerInteractor} from '../../../../../../business/answering/answer_interactor';
import {GeneratedReportFormat, GeneratedReportType} from '../../../../../../enum/generated_report';

export class ReportIframeViewerPresenter implements Presenter {
    @observable public html: string | null = null;
    @observable public loadingFile = false;
    @observable public pdfUrl: string | null = null;

    constructor(
        private reportType: GeneratedReportType,
        private answerInteractor: AnswerInteractor,
        private appraisal: Appraisal,
        private appraisalApi: AppraisalApi,
        private taskHelper: TaskHelper
    ) {
        makeObservable(this);
    }

    public async mount() {
        await this.getHtml();
    }

    public async unmount() {
        // No action
    }

    public async getHtml(): Promise<void> {
        runInAction(() => {
            // Trigger loading screen
            this.html = null;
            this.loadingFile = false;
        });

        // Make sure most recent answers are saved
        await this.answerInteractor.submit();

        const html = await this.appraisalApi.generateReport(
            this.appraisal.id,
            this.reportType,
            GeneratedReportFormat.HTML
        );
        if (!TaskHelper.isTaskReference(html)) {
            runInAction(() => {
                this.html = html;
            });
            return;
        }

        const result = await this.taskHelper.poll<string>(html.taskId);
        if (result !== null) {
            runInAction(() => {
                this.html = result;
            });
            return;
        }

        throw new Error('No result');
    }

    public async downloadPdf() {
        runInAction(() => {
            // Trigger loading screen
            this.html = null;
            this.loadingFile = true;
        });

        const pdf = await this.appraisalApi.generateReport(
            this.appraisal.id,
            this.reportType,
            GeneratedReportFormat.PDF
        );
        if (!TaskHelper.isTaskReference(pdf)) {
            runInAction(() => {
                this.pdfUrl = pdf;
                this.loadingFile = false;
            });
            return;
        }

        const result = await this.taskHelper.poll<string>(pdf.taskId);
        if (result !== null) {
            runInAction(() => {
                this.pdfUrl = result;
                this.loadingFile = false;
            });
            return;
        }

        throw new Error('No result');
    }
}
