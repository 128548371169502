import {LocalStorage} from '../../persistence/drivers/local_storage';

const localStorageGeoKey = 'geolocation_permission_granted';

export interface GeolocationPermission {
    hasGeolocationPermission(): Promise<boolean>;

    setGeolocationPermission(hasPermission: boolean): void;
}

export class DefaultGeolocationPermission implements GeolocationPermission {
    constructor(private localStorage: LocalStorage) {}

    public async hasGeolocationPermission() {
        if ('permissions' in navigator) {
            const queryResult = await navigator.permissions.query({name: 'geolocation'});
            if (queryResult.state === 'granted') {
                return true;
            }
        }

        const localStoragePermission = this.localStorage.getItem<boolean>(localStorageGeoKey);

        return localStoragePermission !== undefined && localStoragePermission;
    }

    public setGeolocationPermission(hasPermission: boolean) {
        if (!('permissions' in navigator)) {
            this.localStorage.setItem<boolean>(localStorageGeoKey, hasPermission);
        }
    }
}
