import * as React from 'react';

import {ModalState, ModalType, ObjectAnswerModalState} from '../models/modal_state';

import {ActiveSetDefinition} from '../reference_objects_question_presenter';
import {AddCustomReferenceObjectModal} from './modals/add_custom_reference_object_modal';
import {Appraisal} from '../../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {ReferenceObject} from '../models/reference_object';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {ReferenceObjectAnswerModal} from './modals/reference_object_answer_modal/reference_object_answer_modal';
import {ReferenceObjectDetailsModal} from './modals/reference_object_details_modal';
import {SetType} from '../../../../../../../../models/reference_set/set_type';

export interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    modalState: ModalState;
    activeSetDefinition: ActiveSetDefinition | null;
    isFrozen: boolean;
    isSavingAnswer: boolean;
    onModalHide: () => void;
    onAdd: (referenceObjectAnswer: ReferenceObjectAnswer) => void;
    onAddAndOpenModal: (referenceObjectAnswer: ReferenceObjectAnswer, referenceObject?: ReferenceObject) => void;
    onRemove: (referenceObjectAnswer: ReferenceObjectAnswer) => void;
    onChange: (referenceObject: ReferenceObject, referenceObjectAnswer: ReferenceObjectAnswer) => void;
    disabled?: boolean;
}

export class ReferenceObjectModals extends React.Component<OwnProps> {
    public render() {
        //Always recalculate the answer since this gets updated
        const objectAnswerModalAnswer =
            this.props.modalState.type === ModalType.OBJECT_ANSWER
                ? this.props.activeSetDefinition?.referenceObjectAnswers.find(
                      (answer) => answer.id === (this.props.modalState as ObjectAnswerModalState).referenceObject.id
                  ) ?? null
                : null;

        switch (this.props.modalState.type) {
            case ModalType.CUSTOM_REFERENCE_SALE: {
                return (
                    <AddCustomReferenceObjectModal
                        appraisal={this.props.appraisal}
                        questionSet={this.props.questionSet}
                        onHide={this.props.onModalHide}
                        onAddAndOpenModal={this.props.onAddAndOpenModal}
                        visible={true}
                        referenceObjectsAnswers={this.props.activeSetDefinition?.referenceObjectAnswers ?? []}
                        disabled={this.props.disabled}
                        setType={this.props.activeSetDefinition?.setDefinition.type ?? SetType.SOLD}
                    />
                );
            }
            case ModalType.OBJECT_ANSWER: {
                const {activeSetDefinition} = this.props;
                if (activeSetDefinition === null || objectAnswerModalAnswer === null) {
                    return null;
                }
                return (
                    <ReferenceObjectAnswerModal
                        appraisal={this.props.appraisal}
                        setType={activeSetDefinition.setDefinition.type}
                        questionSet={this.props.questionSet}
                        referenceObjectAnswer={objectAnswerModalAnswer}
                        gebruiksoppervlakteWonen={activeSetDefinition.setDefinition.surfaceArea}
                        brutoInhoud={activeSetDefinition.setDefinition.volume}
                        valuation={activeSetDefinition.setDefinition.valuation}
                        energyLabel={activeSetDefinition.setDefinition.energyLabel}
                        perceelOppervlakte={activeSetDefinition.setDefinition.plotArea}
                        referenceObject={this.props.modalState.referenceObject}
                        gebruiksOppervlakteBuitenruimte={
                            activeSetDefinition.setDefinition.gebruiksOppervlakteBuitenruimte
                        }
                        overigeInpandigeRuimte={activeSetDefinition.setDefinition.overigeInpandigeRuimte}
                        gebruiksOppervlakteExterneBergruimte={
                            activeSetDefinition.setDefinition.gebruiksOppervlakteExterneBergruimte
                        }
                        buildYear={activeSetDefinition.setDefinition.buildYear}
                        visible={true}
                        isFrozen={this.props.isFrozen}
                        onChange={(referenceObjectAnswer) => {
                            this.props.onChange(
                                (this.props.modalState as ObjectAnswerModalState).referenceObject,
                                referenceObjectAnswer
                            );
                        }}
                        onHide={() => this.props.onModalHide()}
                    >
                        {this.props.isFrozen ? null : (
                            <button
                                className="btn btn-secondary ion-md-square-outline"
                                type="button"
                                onClick={() => {
                                    this.props.onRemove(objectAnswerModalAnswer);
                                }}
                                disabled={this.props.isSavingAnswer}
                            >
                                Uitvinken
                            </button>
                        )}
                    </ReferenceObjectAnswerModal>
                );
            }
            case ModalType.REFERENCE_OBJECT_DETAILS: {
                const {activeSetDefinition} = this.props;
                if (activeSetDefinition === null) {
                    return null;
                }

                return (
                    <ReferenceObjectDetailsModal
                        appraisal={this.props.appraisal}
                        questionSet={this.props.questionSet}
                        referenceObject={this.props.modalState.referenceObject}
                        valuation={activeSetDefinition.setDefinition.valuation}
                        perceelOppervlakte={activeSetDefinition.setDefinition.plotArea}
                        gebruiksoppervlakteWonen={activeSetDefinition.setDefinition.surfaceArea}
                        brutoInhoud={activeSetDefinition.setDefinition.volume}
                        energieLabel={activeSetDefinition.setDefinition.energyLabel}
                        objectType={this.props.appraisal.objectType}
                        visible={this.props.modalState.type === ModalType.REFERENCE_OBJECT_DETAILS}
                        onHide={() => this.props.onModalHide()}
                        onAdd={(referenceObject: ReferenceObject, referenceObjectAnswer: ReferenceObjectAnswer) => {
                            this.props.onAdd(referenceObjectAnswer);
                        }}
                    />
                );
            }
            case ModalType.NONE:
            default: {
                return null;
            }
        }
    }
}
