import * as React from 'react';

import {useLocation} from 'react-router';

import {Appraisal} from '../../../../../models/appraisal';
import {LabelPresenter} from './label_presenter';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {classNames} from '../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';
import {shouldShowTitle} from '../../../../../../support/should_show_title';
import {SimpleTooltip} from '../../../../../components/tooltip';

interface OwnProps {
    for: string;
    question: Question;
    parentAnswerUuid: string | undefined;
    iteration: string | undefined;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    disableHistory?: boolean;
    disabled: boolean;

    hideLabel?: boolean;
    showLabel?: boolean;
    renderingContext: RenderingContextType;
}

export const Label: React.FC<OwnProps> = observer(function Label(props) {
    const presenter = usePresenter(
        (container) =>
            new LabelPresenter(
                props.appraisal,
                props.questionSet,
                props.question,
                props.parentAnswerUuid,
                props.iteration,
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.answerController(props.appraisal, props.questionSet),
                container.network.answerAuditTrailItemApi,
                container.business.modalOrSecondaryConfigStackInteractor,
                container.globalProvider
            )
    );
    const location = useLocation<{questionUuids?: string[]; focusValidation?: boolean}>();

    if (!shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel)) {
        return null;
    }

    function getDisplayUrl() {
        if (props.question.readMoreUrl === null) {
            return null;
        }
        const url = new URL(props.question.readMoreUrl);
        return url.host.indexOf('www.') === 0 ? url.host.substr(4) : url.host;
    }

    function renderReadMoreUrl() {
        const displayUrl = getDisplayUrl();
        if (displayUrl === null || props.question.readMoreUrl === null) {
            return null;
        }

        return (
            <>
                {' '}
                (
                <a href={props.question.readMoreUrl} target="_blank" rel="noreferrer">
                    {displayUrl}
                </a>
                )
            </>
        );
    }

    function renderTooltip() {
        if (props.question.tooltip === null) {
            return null;
        }

        return (
            <>
                {' '}
                <SimpleTooltip content={props.question.tooltip} placement="top">
                    <button className="btn-text-link ion-ios-help-circle">&nbsp;</button>
                </SimpleTooltip>
            </>
        );
    }

    function shouldFocusValidation() {
        return (
            'state' in location &&
            location.state !== undefined &&
            location.state !== null &&
            'focusValidation' in location.state &&
            location.state.focusValidation === true &&
            'questionUuids' in location.state &&
            location.state.questionUuids !== undefined &&
            location.state.questionUuids.indexOf(props.question.uuid) !== -1
        );
    }

    const answer = presenter.answer;
    const showHistory = props.disableHistory === undefined || !props.disableHistory;
    return (
        <>
            <span className="label-container">
                {showHistory && answer !== null && (
                    <button
                        type="button"
                        className="history-button btn-text-link ion-md-timer"
                        disabled={presenter.auditTrailData.loading}
                        onClick={() => presenter.onRevealClick(answer.uuid, props.disabled)}
                        tabIndex={-1}
                    />
                )}
                <label htmlFor={props.for} className={classNames({'validation-focused': shouldFocusValidation()})}>
                    <span>
                        {props.question.contents}
                        {props.question.isRequired ? <span className="form-label-highlight">*</span> : null}
                    </span>
                    {renderReadMoreUrl()}
                    {renderTooltip()}
                </label>
            </span>
        </>
    );
});
