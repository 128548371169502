import * as React from 'react';

import {Appraisal} from '../../../../../../../../../../../models/appraisal';
import {BuildyearBadge} from '../../../badges/buildyear_badge_adapter';
import {FunctionComponent} from 'react';
import {NullableNumberInput} from '../../../inputs/nullable_number';
import {QuestionSet} from '../../../../../../../../../../../models/question_set';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    referenceObjectAnswer: ReferenceObjectAnswer;
    isFrozen: boolean;
    onBuildYearChange: (buildYear: number | null) => void;
}

export const BuildYear: FunctionComponent<OwnProps> = ({
    appraisal,
    questionSet,
    referenceObjectAnswer,
    onBuildYearChange,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-sm-6">
                <dl>
                    <NullableNumberInput
                        isRequired
                        name="bouwjaar"
                        label="Bouwjaar"
                        badge={
                            validationInstituteReferentieObject.bouwjaar !== null ? (
                                <BuildyearBadge
                                    buildYear={validationInstituteReferentieObject.bouwjaar}
                                    appraisal={appraisal}
                                    questionSet={questionSet}
                                />
                            ) : null
                        }
                        value={validationInstituteReferentieObject.bouwjaar}
                        onChange={onBuildYearChange}
                        disabled={isFrozen}
                    />
                </dl>
            </div>
        </div>
    );
};
