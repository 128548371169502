import {ValidationMessage} from './validation_message';

export interface QuestionValidator {
    validate(questionUuid: string): ValidationMessage[];
}

export class DefaultQuestionValidator implements QuestionValidator {
    constructor(private questionValidators: QuestionValidator[]) {}

    public validate(questionUuid: string): ValidationMessage[] {
        return this.questionValidators.reduce<ValidationMessage[]>((p, c) => {
            return [...p, ...c.validate(questionUuid)];
        }, []);
    }
}
