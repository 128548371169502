import {Observable, combineLatest, of} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import {AnswerController} from '../answering/answer_controller';
import {QuestionEffectInteractor} from '../conditions/question_effects_interactor';
import {QuestionSet} from '../../models/question_set';
import {TechnicalReference} from '../../enum/technical_reference';
import {Answer} from '../../models/answer';
import {Question} from '../../models/question';
import {normalizeNumber} from '../support/normalize_number';

export interface BbmiAreaProvider {
    stream(
        bbmiTechnicalReference: TechnicalReference,
        fallbackAreaTechnicalReference: TechnicalReference
    ): Observable<number | null>;
}

export class DefaultBbmiAreaProvider implements BbmiAreaProvider {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor
    ) {}

    public stream(
        bbmiTechnicalReference: TechnicalReference,
        fallbackAreaTechnicalReference: TechnicalReference
    ): Observable<number | null> {
        const bbmiQuestions = this.questionSet.findQuestionsByTechnicalReference(bbmiTechnicalReference);
        const areaQuestions = this.questionSet.findQuestionsByTechnicalReference(fallbackAreaTechnicalReference);
        const bbmiMeasurementQuestions = this.questionSet.findQuestionsByTechnicalReference(
            TechnicalReference.BBMI_MEET_RAPPORT
        );

        if (bbmiQuestions.length === 0 && areaQuestions.length === 0) {
            return of(null);
        }

        return combineLatest([
            this.answerController
                .answersForQuestionUuidsStream([...bbmiMeasurementQuestions, ...bbmiQuestions].map((q) => q.uuid))
                .pipe(
                    startWith([]),
                    //Filter deleted and hidden answers
                    map((answers) => this.answerController.filterDeleted(answers)),
                    map((answers) =>
                        answers.filter(
                            (answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid)
                        )
                    )
                ),
            this.answerController.answersForQuestionUuidsStream(areaQuestions.map((q) => q.uuid)).pipe(
                startWith([]),
                //Filter deleted and hidden answers
                map((answers) => this.answerController.filterDeleted(answers)),
                map((answers) =>
                    answers.filter(
                        (answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid)
                    )
                )
            ),
        ]).pipe(
            map(([bbmiAnswers, areaAnswers]) => {
                const hasMeasurementReport = this.hasMeasurementReport(bbmiAnswers, bbmiMeasurementQuestions);
                if (hasMeasurementReport) {
                    for (const bbmiAnswer of bbmiAnswers) {
                        if (bbmiAnswer.contents) {
                            const value = parseFloat(bbmiAnswer.contents);
                            if (!Number.isNaN(value) && typeof value === 'number') {
                                return Math.abs(value);
                            }
                        }
                    }
                }

                let surface = 0.0;
                for (const areaAnswer of areaAnswers) {
                    if (areaAnswer.contents) {
                        const value = parseFloat(areaAnswer.contents);
                        if (!Number.isNaN(value) && typeof value === 'number') {
                            surface += Math.abs(value);
                        } else {
                            try {
                                const obj = JSON.parse(areaAnswer.contents);
                                if (
                                    obj !== null &&
                                    typeof obj === 'object' &&
                                    'total' in obj &&
                                    typeof obj.total === 'number'
                                ) {
                                    surface += Math.abs(obj.total);
                                }
                            } catch (e) {
                                console.warn(e);
                            }
                        }
                    }
                }

                if (!(surface > 0)) {
                    return null;
                }

                return normalizeNumber(surface, 0);
            })
        );
    }

    private hasMeasurementReport(bbmiAnswers: Answer[], bbmiMeasurementQuestions: Question[]): boolean {
        // No answers to check when there are no questions
        if (bbmiMeasurementQuestions.length === 0) {
            return true;
        }
        const answerOptionIds: number[] = bbmiMeasurementQuestions
            .map((q) => {
                return q.answerOptions.find((o) => o.contents.toLowerCase() === 'ja')?.id;
            })
            .filter((id): id is number => id !== undefined);

        return (
            bbmiAnswers
                .filter((answer) => bbmiMeasurementQuestions.map((q) => q.uuid).includes(answer.questionUuid))
                .find((a) => a.contents === '1' || (a.answerOptionId && answerOptionIds.includes(a.answerOptionId))) !==
            undefined
        );
    }
}
