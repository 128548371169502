import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';

import {AdaptedDefaultValuesMap} from '../../../../../models/adapted_default_values_map';
import {QuestionContainer} from '../question_container';
import {QuestionSet} from '../../../../../models/question_set';
import {classNames} from '../../../../../../support/classnames';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {shouldShowTitle} from '../../../../../../support/should_show_title';

interface OwnProps extends SimpleQuestionPresenterProps {
    questionSet: QuestionSet;
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
}

export const Checklist: React.FC<OwnProps> = observer(function Checklist(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));

    if (presenter.isHidden) {
        return null;
    }

    const answer = presenter.answer;
    if (answer === undefined) {
        return <div>Loading checklist...</div>;
    }

    function isRenderedAtTopLevel() {
        return props.parentAnswerUuid !== undefined;
    }

    function renderTitle() {
        return isRenderedAtTopLevel() &&
            shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel) ? (
            <div>
                <h3>{props.question.contents}</h3>
            </div>
        ) : null;
    }

    const checklistQuestions = findChildrenForQuestion(props.question, props.questionSet);

    return (
        <div>
            {renderTitle()}
            <div
                className={classNames('form-checklist', presenter.filledByAutomator, {
                    'automation-filled': presenter.filledByAutomator !== null,
                })}
            >
                <div className="row">
                    {checklistQuestions.map((child) => (
                        <div key={child.uuid} className="col-12 col-md-6">
                            <QuestionContainer
                                key={child.uuid}
                                appraisal={props.appraisal}
                                question={child}
                                questionSet={props.questionSet}
                                parentAnswerUuid={answer.uuid}
                                validationErrors={props.validationErrors}
                                validationMessages={props.validationMessages}
                                adaptedDefaultValues={props.adaptedDefaultValues}
                                forceShowValidationMessages={props.forceShowValidationMessages}
                                disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                                hiddenQuestionTypes={props.hiddenQuestionTypes}
                                pagePartsSet={props.pagePartsSet}
                                activePagePart={props.activePagePart}
                                renderingContext={props.renderingContext}
                                questionRenderingData={props.questionRenderingData}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});
