import {AjaxDriver} from '../../../network/driver/ajax_driver';
import {FetchDriver} from '../../../network/driver/fetch_driver';
import {DefaultGeoApi, GeoApi} from '../network/geo_api';

export interface Component {
    geoApi: GeoApi;
}

export interface CreateAppraisalProps {
    googleMapsApiKey: string | null;
    userLatitude: number | null;
    userLongitude: number | null;
    projectName: string | null;
    plotNumber: string | null;
    disabled: boolean;
}

class ComponentImpl implements Component {
    private ajaxDriver: AjaxDriver = new FetchDriver();
    private _geoApi: GeoApi | undefined;

    public get geoApi(): GeoApi {
        if (this._geoApi === undefined) {
            this._geoApi = new DefaultGeoApi(this.ajaxDriver);
        }
        return this._geoApi;
    }
}

let instance: Component;

export function component(): Component {
    if (instance === undefined) {
        instance = new ComponentImpl();
    }

    return instance;
}
