import React, {useState} from 'react';
import {classNames} from '../../../../support/classnames';

export function Collapsible({
    title,
    children,
    light,
    defaultShown,
}: {
    title?: React.ReactNode;
    children?: React.ReactNode;
    light?: boolean;
    defaultShown?: boolean;
}) {
    const [collapsed, setCollapsed] = useState(defaultShown !== true);
    return (
        <div
            className={classNames('group-collapsible', {
                'group-collapsible-visible': !collapsed,
                'group-collapsible-light': light,
            })}
        >
            <a
                className={classNames('group-collapsible-header', {
                    'ion-ios-arrow-down': collapsed,
                    'ion-ios-arrow-up': !collapsed,
                })}
                onClick={() => setCollapsed(!collapsed)}
            >
                {title}
            </a>
            <div className="group-collapsible-children">
                <div className="group-collapsible-inner">{children}</div>
            </div>
        </div>
    );
}
