import * as React from 'react';

import {AttachmentQuestionProps} from '../../../../attachment_question_presenter';
import {ReferenceObjectPhotoQuestionPresenter} from './reference_object_photo_question_presenter';
import {classNames} from '../../../../../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {simpleQuestionPresenterConstructorParametersFactory} from '../../../../../simple/simple_question_presenter';
import {Source, sourceToClassName} from '../../models/reference_object';
import {RenderingContextType} from '../../../../../../../../../enum/rendering_context_type';
import {ScrollShrink} from '../../../../../../../components/scroll_shrink';
import {ReferenceObjectComparisonModalContext} from '../../components/modals/reference_object_comparison_model_context';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';
import {referenceObjectImages} from '../../internal/reference_sale_images';
import {ReferenceSaleImage} from '../../../v1/models/reference_sale_image';
import {ReferenceImageSlider} from '../../components/photos/image_slider';

interface OwnProps extends AttachmentQuestionProps {}

export const ReferenceObjectPhotoQuestion: React.FC<OwnProps> = observer(function ReferenceObjectPhotoQuestion(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectPhotoQuestionPresenter(
                props.fileTypes,
                container.business.blobCacheInteractor(),
                container.business.imageUploadInteractor(props.appraisal, props.questionSet),
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.flashMessageBroadcaster,
                container.globalProvider,
                container.business.attachmentQuestionsInteractor(props.appraisal, props.questionSet),
                container.business.automatorInteractor(props.appraisal, props.questionSet),
                container.business.v3ReferenceSetsProvider(props.appraisal, props.questionSet),
                container.business.referenceObjectsAnswerEnhancementInteractor(props.appraisal, props.questionSet),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );
    const modalContext = React.useContext(ReferenceObjectComparisonModalContext);

    const {referenceObjectDataWithSet} = presenter;
    if (referenceObjectDataWithSet === null) {
        return null;
    }

    const images = referenceObjectImages(
        presenter.enhancedReferenceObject,
        referenceObjectDataWithSet.data.referenceObjectAnswer
    );

    if (props.renderingContext === RenderingContextType.COMPACT_GRID_COLUMNS) {
        return renderCompact(images);
    }

    function renderCompact(images: ReferenceSaleImage[]) {
        const {referenceObjectDataWithSet} = presenter;

        const {adres} = referenceObjectDataWithSet?.data.referenceObjectAnswer.referenceObject ?? {};

        return (
            <ScrollShrink
                className={classNames('detail-image detail-image-answer-modal detail-image-compact', {
                    opaque: presenter.busy,
                })}
                threshold={150}
            >
                <div
                    className={classNames(
                        'reference-logo hide-shrink',
                        sourceToClassName(referenceObjectDataWithSet?.data.referenceObjectAnswer.source ?? null)
                    )}
                >
                    &nbsp;
                </div>
                <div className="shrink-slider-wrapper">
                    <ReferenceImageSlider
                        images={images}
                        onSwiperInit={(image) => presenter.setCurrentImage(image, images)}
                        onSwiperSlideChange={(image) => presenter.setCurrentImage(image, images)}
                        photoAnswerUuid={presenter.answer?.uuid}
                    />
                    <div className="hide-grow shrink-details">
                        <span>
                            {adres?.straat}{' '}
                            {adres?.huisnummer + (adres?.huisnummerToevoeging ? '-' + adres?.huisnummerToevoeging : '')}
                        </span>
                        <button className="btn btn-xs ml-6 ion-md-expand" onClick={modalContext.openAnswerModal} />
                    </div>
                    {presenter.currentImage && (
                        <img className="shrink-image" src={presenter.currentImage.url ?? undefined} />
                    )}
                </div>
                <div className="detail-image-compact-buttons hide-shrink">
                    {referenceObjectDataWithSet?.data.referenceObjectAnswer.brochureUrl && (
                        <a
                            className="btn btn-sm float-left"
                            href={referenceObjectDataWithSet.data.referenceObjectAnswer.brochureUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {referenceObjectDataWithSet.data.referenceObjectAnswer.source === Source.ARCHIEF_NVM
                                ? 'Stamkaart'
                                : referenceObjectDataWithSet.data.referenceObjectAnswer.brochureUrl.includes('funda.nl')
                                ? 'Funda'
                                : 'Brochure'}
                        </a>
                    )}
                    <label
                        className={classNames('btn btn-sm ion-md-camera float-right', {
                            'btn-single-icon': images.length > 0,
                            disabled: props.disabled || presenter.busy,
                        })}
                    >
                        <input
                            type="file"
                            name="files[]"
                            style={{display: 'none'}}
                            onChange={(e) => presenter.onFileChange(e)}
                            disabled={props.disabled || presenter.busy}
                        />
                        {images.length === 0 && 'Upload foto'}
                    </label>
                    {images.length > 0 && (
                        <button
                            className={classNames(
                                'btn btn-sm btn-primary ion-md-checkmark float-right btn-single-icon',
                                {
                                    'ion-animate': !presenter.loading,
                                }
                            )}
                            onClick={() => presenter.onSelectionChange()}
                            disabled={presenter.currentImage?.url === presenter.url || props.disabled || presenter.busy}
                        >
                            {presenter.busy && <span className="loader" />}
                        </button>
                    )}
                </div>
            </ScrollShrink>
        );
    }

    return (
        <div>
            <div
                className={classNames('detail-image', 'detail-image-answer-modal', {
                    opaque: presenter.busy,
                })}
            >
                <div
                    className={classNames(
                        'reference-logo',
                        sourceToClassName(referenceObjectDataWithSet.data.referenceObjectAnswer.source ?? null)
                    )}
                >
                    &nbsp;
                </div>
                <ReferenceImageSlider
                    images={images}
                    onSwiperInit={(image) => presenter.setCurrentImage(image, images)}
                    onSwiperSlideChange={(image) => presenter.setCurrentImage(image, images)}
                    photoAnswerUuid={presenter.answer?.uuid}
                />
            </div>
            <div className="image-selector-buttons-container">
                {referenceObjectDataWithSet.data.referenceObjectAnswer.brochureUrl && (
                    <a
                        className="btn btn-sm"
                        href={referenceObjectDataWithSet.data.referenceObjectAnswer.brochureUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {referenceObjectDataWithSet.data.referenceObjectAnswer.source === Source.ARCHIEF_NVM
                            ? 'Stamkaart'
                            : referenceObjectDataWithSet.data.referenceObjectAnswer.brochureUrl.includes('funda.nl')
                            ? 'Funda'
                            : 'Brochure'}
                    </a>
                )}
                <label
                    className={classNames('btn btn-sm ion-md-camera float-right', {
                        'btn-single-icon': images.length > 0,
                        disabled: props.disabled || presenter.busy,
                    })}
                >
                    <input
                        type="file"
                        name="files[]"
                        style={{display: 'none'}}
                        onChange={(e) => presenter.onFileChange(e)}
                        disabled={props.disabled || presenter.busy}
                    />
                    {images.length === 0 && 'Upload foto'}
                </label>
                {images.length > 0 && (
                    <button
                        className={classNames('btn btn-sm btn-primary ion-md-checkmark float-right', {
                            'ion-animate': !presenter.loading,
                        })}
                        onClick={() => presenter.onSelectionChange()}
                        disabled={presenter.currentImage?.url === presenter.url || props.disabled || presenter.busy}
                    >
                        {presenter.busy ? (
                            <span>
                                <span className="loader" />
                                <span>Kies deze foto</span>
                            </span>
                        ) : (
                            'Kies deze foto'
                        )}
                    </button>
                )}
            </div>
        </div>
    );
});
