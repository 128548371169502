import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';
import {CSS} from '@dnd-kit/utilities';
import {PagePartsSet} from '../../../../../models/page_parts_set';
import {Question} from '../../../../../models/question';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {IteratorFilesCustomizerPresenter} from './iterator_files_customizer_presenter';
import {observer} from 'mobx-react';
import {QuestionSet} from '../../../../../models/question_set';

function Label({label, id}: {label: string; id: string}) {
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <div className="draggable-item" ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {label}
        </div>
    );
}

interface OwnProps {
    question: Question;
    pagePartsSet: PagePartsSet;
    questionSet: QuestionSet;
}

export const IteratorFilesCustomizer = observer((props: OwnProps) => {
    const presenter = usePresenter(
        () => new IteratorFilesCustomizerPresenter(props.question, props.pagePartsSet, props.questionSet)
    );

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragEnd(event: DragEndEvent) {
        const {active, over} = event;

        if (over && active.id !== over.id) {
            const newIndex = presenter.sortOrder?.indexOf(over.id as string);
            if (newIndex !== undefined && newIndex >= 0) {
                presenter.move(active.id as string, newIndex);
            }
        }
    }

    return (
        <div>
            <p>Pas de volgorde aan waarop de overige bijlagen worden getoond.</p>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={presenter.sortOrder ?? []} strategy={verticalListSortingStrategy}>
                    {presenter.sortOrder?.map((label) => (
                        <Label id={label} label={presenter.getDisplayLabel(label)} key={label} />
                    ))}
                </SortableContext>
            </DndContext>
        </div>
    );
});
