import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {Answer} from '../../../../../models/answer';
import {AnswerTouchState} from '../../../../../enum/answer_touch_state';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {Question} from '../../../../../models/question';
import {SimpleQuestionPresenter} from '../simple/simple_question_presenter';

export class MultipleBooleanGroupQuestionPresenter extends SimpleQuestionPresenter {
    private CHECKED_VALUE = '1';
    private UNCHECKED_VALUE = '0';

    private subscriptions = new CompositeSubscription();

    @observable.ref public childAnswers?: Answer[];

    constructor(
        private childQuestions: Question[],
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);

        if (this.answer) {
            const questionUuids = this.childQuestions.map((child) => child.uuid);
            this.childAnswers = this.answerController.answersForQuestionUuids(questionUuids, this.answer.uuid);
        }

        makeObservable(this);
    }

    public async mount(): Promise<void> {
        super.mount();
        const questionUuids = this.childQuestions.map((child) => child.uuid);

        // Subscribe child questions
        if (this.answer) {
            for (const questionUuid of questionUuids) {
                this.subscriptions.add(
                    this.answerController
                        .answerByIdentifiersStream(questionUuid, this.answer.uuid, null)
                        .subscribe((answer) => {
                            runInAction(() => {
                                this.childAnswers = [
                                    ...(this.childAnswers ?? []).filter((a) => a.uuid !== answer.uuid),
                                    answer,
                                ];
                            });
                        })
                );
            }
        }

        // Set adapted values
        if (this.adaptedDefaultValues !== undefined && this.selectedQuestions.length === 0) {
            const selectedUuids = [];
            for (const questionUuid of questionUuids) {
                const adaptedValue = this.adaptedDefaultValues[questionUuid];
                if (adaptedValue !== undefined && adaptedValue === this.CHECKED_VALUE) {
                    selectedUuids.push(questionUuid);
                }
            }

            this.onChildChange(selectedUuids, questionUuids);
        }

        for (const childAnswer of this.childAnswers ?? []) {
            if (this.filledByAutomator === null && childAnswer.filledByAutomator !== null) {
                runInAction(() => {
                    this.filledByAutomator = childAnswer.filledByAutomator;
                });
            }
        }
    }

    public unmount(): void {
        super.unmount();
        this.subscriptions.clear();
    }

    @computed
    public get placeholderText() {
        if (this.childQuestions.some((q) => q.contents.toLowerCase() === 'niet van toepassing')) {
            return 'Maak een keuze';
        }
        return 'Niet van toepassing';
    }

    @computed
    public get selectedQuestions() {
        return (this.childAnswers ?? [])
            .filter((a) => a.contents === this.CHECKED_VALUE)
            .map((a: Answer) => this.questionSet.findQuestionByUuid(a.questionUuid))
            .filter((q): q is Question => q !== undefined);
    }

    @action
    public onChildChange(selectedQuestionUuids: string[] = [], questionUuids: string[]) {
        for (const uuid of questionUuids) {
            const answer = (this.childAnswers ?? []).find((a) => a.questionUuid === uuid);
            if (answer !== undefined) {
                this.filledByAutomator = null;
                const newValue = selectedQuestionUuids.includes(uuid) ? this.CHECKED_VALUE : this.UNCHECKED_VALUE;
                if (answer.contents !== newValue) {
                    this.answerController.onContentsChange(answer.uuid, newValue, AnswerTouchState.TOUCHED);
                }
            }
        }
        if (this.answer) {
            this.answerController.onTouchStateChange(this.answer.uuid, AnswerTouchState.TOUCHED);
        }
    }
}
