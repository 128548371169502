import * as Throttle from 'promise-parallel-throttle';
import * as Uuid from 'uuid';

import {AnswerPathStubber, QuestionUuidIteratorUuidPair} from '../../../business/answering/answer_path_stubber';
import {CameraProgress, AttachedPhoto, ProgressType} from './camera_presenter';
import {FlashMessageBroadcaster, Type} from '../../../business/flash_message_broadcaster';
import {HowSureAreWeLevel, PredictionResult} from '../../../business/ml/vision/photo_content_predicter';
import {NetworkStatus, NetworkStatusProvider} from '../../../business/network_status_provider';
import {PhotoRecognitionHandler, PhotoRecognitionResult} from '../../../business/answering/photo_recognition_handler';
import {action, autorun, computed, makeObservable, observable, runInAction} from 'mobx';
import {distinctUntilChanged, filter, flatMap, skip, takeWhile} from 'rxjs/operators';
import {findAllChildrenForQuestionUuid, findChildrenForQuestion} from '../support/question_filtering';

import {Answer} from '../../../models/answer';
import {AnswerByLabelSubmitter} from '../../../business/answering/answer_by_label_submitter';
import {AnswerController} from '../../../business/answering/answer_controller';
import {Appraisal} from '../../../models/appraisal';
import {AppraisalState} from '../../../enum/appraisal_state';
import {AppraisalValidationType} from '../../../enum/appraisal_validation_type';
import {AppraiseSecondaryConfigStackInteractor} from '../../../business/appraise_secondary_config_stack_interactor';
import {AppraiseSecondaryType} from '../../../models/appraise_secondary_config';
import {BlobCacheInteractor} from '../../../business/attachments/blob_cache_interactor';
import {CompositeSubscription} from '../../../../support/composite_subscription';
import {ConstructionDefectsWidgetContext} from '../appraise_secondary/construction_defects/construction_defects_widget_context';
import {FeatureFlags} from '../../../../feature_flags';
import {ImageOrientationFixer} from '../../../business/image_orientation_fixer';
import {ImageUploadInteractor} from '../../../business/attachments/image_upload_interactor';
import {ModalConfigStackInteractor} from '../../../business/modal_config_stack_interactor';
import {ModalType} from '../../../models/modal_config';
import {NormalQuestionType} from '../../../enum/question_type';
import {PhotoAnswerRetryInteractor} from '../../../business/photo_answer_retry_interactor';
import {Presenter} from '../../../../support/presenter/presenter';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {ResultType} from '../../../business/generic_config_stack_interactor';
import {StatusLabelType} from '../container/status_label';
import {ValidationMessageMap} from '../../../business/validation/validation_message';
import {bugsnagClient} from '../../../../support/bugsnag_client';
import {isCompact} from '../../../../support/check_mobile';
import {isImageFile} from '../../../../support/file_type_check';
import pTimeout from 'p-timeout';
import {sum} from '../../../../support/sum';
import {RenderingContextType} from '../../../enum/rendering_context_type';
import {TechnicalReference} from '../../../enum/technical_reference';
import {QuestionRenderingData} from '../../../models/question_rendering_data';
import {ALLOWED_IMAGE_TYPES} from './questions/question_container';

interface PreparedFile extends AttachedPhoto {
    buildingCostsLabel: string | null;
    floorAnswerUuid: string | null;
    buildingCostsQuestionUuid: string | null;
}

interface DefectsWidgetData {
    costGroupQuestion: Question;
    costGroupAnswer: Answer;
    fileAnswer: Answer;
    buildingCostsLabel: string | null;
}

export class ConstructionDefectsButtonPresenter implements Presenter {
    @observable public progress = 0;
    @observable public progressType = ProgressType.IDLE;
    @observable public isAvailable = true;
    @observable public numUploadsProcessed = 0;

    @observable.ref private widgetData: DefectsWidgetData | null = null;

    private subscriptions = new CompositeSubscription();
    private _fileTypes = ALLOWED_IMAGE_TYPES;

    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private renderingContext: RenderingContextType,
        private questionRenderingData: QuestionRenderingData | null,
        private validationMessages: ValidationMessageMap,
        private answerController: AnswerController,
        private imageUploadInteractor: ImageUploadInteractor,
        private photoAnswerRetryInteractor: PhotoAnswerRetryInteractor,
        private networkStatusProvider: NetworkStatusProvider,
        private imageOrientationFixer: ImageOrientationFixer,
        private blobCacheInteractor: BlobCacheInteractor,
        private flashMessageBroadcaster: FlashMessageBroadcaster,
        private onProgressChangeListener: (progress: CameraProgress) => void,
        private answerPathStubber: AnswerPathStubber,
        private appraiseSecondaryConfigStackInteractor: AppraiseSecondaryConfigStackInteractor,
        private modalConfigStackInteractor: ModalConfigStackInteractor,
        private photoRecognitionHandler: PhotoRecognitionHandler,
        private answerByLabelSubmitter: AnswerByLabelSubmitter
    ) {
        makeObservable(this);
    }

    @computed
    public get isDisabled(): boolean {
        if (!this.appraisal.isEditableAppraisal) {
            return true;
        }
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return false;
        }
        return (
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    public mount() {
        this.subscriptions.add(
            this.networkStatusProvider
                .status()
                .pipe(
                    skip(1),
                    distinctUntilChanged(),
                    filter((status) => status === NetworkStatus.ONLINE),
                    flatMap(() =>
                        this.photoAnswerRetryInteractor
                            .attempt()
                            .pipe(takeWhile((photoAnswerRetryStatus) => photoAnswerRetryStatus.queued > 0))
                    )
                )
                .subscribe()
        );

        this.subscriptions.add(
            autorun(() => {
                const secondaryWidgetId = `building-cost-iterator-secondary-widget`;
                if (this.widgetData !== null) {
                    if (isCompact()) {
                        this.modalConfigStackInteractor.upsert({
                            id: secondaryWidgetId,
                            type: ModalType.DEFECTS,
                            appraisal: this.appraisal,
                            questionSet: this.questionSet,
                            onClose: () => {
                                runInAction(() => {
                                    this.widgetData = null;
                                });
                            },
                            question: this.widgetData.costGroupQuestion,
                            parentAnswerUuid: this.widgetData.costGroupAnswer.uuid,
                            constructionDefectLabel: this.widgetData.buildingCostsLabel ?? undefined,
                            constructionDefectPhotoAnswerUuid: this.widgetData.fileAnswer?.uuid ?? undefined,
                            validationMessages: this.validationMessages,
                            forceShowValidationMessages: false,
                            constructionCostsWidgetContext: ConstructionDefectsWidgetContext.REQUEST_DEFECT_WIDGET,
                            onChangeConstructionCostsWidgetContext: () => {
                                //Noop
                            },
                            pagePartsSet: null,
                            activePagePart: null,
                            questionRenderingData: this.questionRenderingData,
                        });
                    } else {
                        this.appraiseSecondaryConfigStackInteractor.upsert({
                            id: secondaryWidgetId,
                            type: AppraiseSecondaryType.DEFECTS,
                            appraisal: this.appraisal,
                            questionSet: this.questionSet,
                            onClose: () => {
                                runInAction(() => {
                                    this.widgetData = null;
                                });
                            },
                            question: this.widgetData.costGroupQuestion,
                            parentAnswerUuid: this.widgetData.costGroupAnswer.uuid,
                            constructionDefectLabel: this.widgetData.buildingCostsLabel ?? undefined,
                            constructionDefectPhotoAnswerUuid: this.widgetData.fileAnswer?.uuid ?? undefined,
                            validationMessages: this.validationMessages,
                            forceShowValidationMessages: false,
                            constructionCostsWidgetContext: ConstructionDefectsWidgetContext.REQUEST_DEFECT_WIDGET,
                            onChangeConstructionCostsWidgetContext: () => {
                                //Noop
                            },
                            pagePartsSet: null,
                            activePagePart: null,
                            questionRenderingData: this.questionRenderingData,
                        });
                    }
                } else {
                    this.appraiseSecondaryConfigStackInteractor.remove((c) => c.id === secondaryWidgetId);
                    this.modalConfigStackInteractor.remove((c) => c.id === secondaryWidgetId);
                }
            })
        );
    }

    public unmount() {
        this.subscriptions.clear();
    }

    public async onFilesChange(e: React.ChangeEvent<HTMLInputElement>) {
        try {
            const files = e.target.files;
            if (files !== null) {
                if (!(await this.blobCacheInteractor.canStore(files[0]))) {
                    this.alertNotAvailable();
                    return;
                }

                const filesList: File[] = Array.from(files);
                const onlyHasImages = filesList.every((file) => isImageFile(file, this._fileTypes));
                if (onlyHasImages === false) {
                    this.alertIncorrectType();
                    return;
                }

                this.numUploadsProcessed++;
                this.updateProgress(0, ProgressType.CRITICAL);

                let rootQuestion: Question | null =
                    this.questionSet.findQuestionsByType(NormalQuestionType.BUILDING_COSTS_PHOTO_GROUP)[0] ?? null;
                if (
                    this.renderingContext === RenderingContextType.PAGE_PARTS_APPRAISAL ||
                    this.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR
                ) {
                    rootQuestion = this.questionSet.findQuestionByTechnicalReference(
                        TechnicalReference.PHOTO_ITERATOR_CONSTRUCTION
                    );
                }

                const photoQuestion = this.photoRecognitionHandler.getPhotoQuestion(rootQuestion);
                if (!photoQuestion) {
                    throw new Error('Failed getting photo question to attach file to');
                }

                const preparedPhotos = await Throttle.all<AttachedPhoto | Error | null>(
                    filesList.map((file) => () => this.prepare(file, photoQuestion)),
                    {
                        maxInProgress: 1,
                        failFast: false,
                        progressCallback: (result) => {
                            this.updateProgress(result.amountDone / filesList.length, ProgressType.CRITICAL);
                        },
                    }
                );

                this.updateProgress(0, ProgressType.RECOGNITION);

                const preparedFiles = await Throttle.all<PreparedFile | Error>(
                    preparedPhotos
                        .filter((pp): pp is PreparedFile => pp !== null && !(pp instanceof Error))
                        .map((preparedPhoto) => () => {
                            // Label photo
                            return this.labelPhoto(preparedPhoto);
                        }),
                    {
                        maxInProgress: 1,
                        failFast: false,
                        progressCallback: (result) => {
                            this.updateProgress(result.amountDone / preparedPhotos.length, ProgressType.RECOGNITION);
                        },
                    }
                );

                this.updateProgress(0.01, ProgressType.CASUAL);

                const progressMap = new Map<string, number>();
                const uploadedFiles = await Throttle.all(
                    preparedFiles
                        .filter((pf): pf is PreparedFile => pf !== null && !(pf instanceof Error))
                        .map(
                            (pf) => () =>
                                this.upload(pf, (progress) => {
                                    progressMap.set(pf.answer.uuid, progress);
                                    this.updateProgress(
                                        sum(Array.from(progressMap.values())) / preparedPhotos.length,
                                        ProgressType.CASUAL
                                    );
                                })
                        ),
                    {
                        maxInProgress: 5,
                        failFast: false,
                    }
                );

                this.updateProgress(100, ProgressType.IDLE);

                // Trigger the widget when the upload finished
                const firstResult = uploadedFiles.find((pf) => pf !== null) ?? null;
                if (firstResult !== null) {
                    await this.triggerDefectsWidget(firstResult);
                }
            }
        } catch (e) {
            console.error('Uploading of files failed', e);
            bugsnagClient?.notify(e);
            this.updateProgress(100, ProgressType.FAILURE);
            this.flashMessageBroadcaster.broadcast(
                'Uploaden van sommige fotos gefaald, probeer het nogmaals.',
                Type.Danger
            );
        }
    }

    private async prepare(newFile: File, photoQuestion: Question): Promise<AttachedPhoto | null> {
        try {
            const uuid = Uuid.v4();

            //1. Fix rotation of image
            let file: File | null = null;
            try {
                file = await pTimeout(this.fixRotation(newFile), 10 * 1000);
            } catch (error) {
                file = newFile;
            }

            //2. Make sure we've stored this thing locally
            const result = await this.imageUploadInteractor.cache(uuid, file);
            if (!result) {
                return null;
            }

            //3. When successful (appraiser didn't refresh page or other stupid behavior) only then register an answer
            const photoQuestionAndAnswerPair = this.photoRecognitionHandler.preparePhotoAnswers(photoQuestion);
            if (photoQuestionAndAnswerPair === null) {
                return null;
            }

            //4. Move file to new location
            await this.imageUploadInteractor.attachToAnswer(uuid, photoQuestionAndAnswerPair.answer.uuid, file);

            return {
                file: file,
                question: photoQuestionAndAnswerPair.question,
                answer: photoQuestionAndAnswerPair.answer,
            };
        } catch (e) {
            console.error('Error while preparing file for upload', e);
            bugsnagClient?.notify(e, {
                metaData: {
                    origin: 'construction_defects_button_presenter.ts prepare()',
                },
            });
            return null;
        }
    }

    private async labelPhoto(preparedPhoto: AttachedPhoto): Promise<PreparedFile> {
        //5. Do photo recognition
        let predictionResult: PredictionResult | null = null;
        try {
            predictionResult = await pTimeout(
                this.photoRecognitionHandler.handle(
                    preparedPhoto.file,
                    preparedPhoto.question,
                    preparedPhoto.answer,
                    0.6,
                    0.2
                ),
                10 * 1000
            );
        } catch (error) {
            console.warn('Photo prediction failed', error);
        }

        //6. If recognized show flashmessage
        if (
            predictionResult &&
            predictionResult.howSure === HowSureAreWeLevel.VerySure &&
            predictionResult.className !== null
        ) {
            this.flashMessageBroadcaster.broadcast(
                'Foto herkend als ' + predictionResult.className.toLocaleLowerCase() + '.',
                Type.Success,
                {
                    type: StatusLabelType.Beta,
                    content: 'Bèta',
                }
            );
        }

        //7. Show a modal with defect details (location, etc.)
        const modalResult = await this.modalConfigStackInteractor.insert<PhotoRecognitionResult>({
            id: 'label_select_modal',
            type: ModalType.LABEL_SELECT,
            parentAnswerUuid: preparedPhoto.answer.uuid,
            questions: this.getLabelSelectQuestions(preparedPhoto.question),
            photoRecognized: predictionResult !== null && predictionResult.howSure === HowSureAreWeLevel.VerySure,
            predictionResult: predictionResult,
        });

        //8. If the modal is successful, submit the labels
        if (modalResult.type === ResultType.SUCCEEDED) {
            if (predictionResult !== null) {
                for (const label of predictionResult?.className?.split(',') ?? []) {
                    await this.answerByLabelSubmitter.submit(label.trim());
                }
            }
            return {
                ...preparedPhoto,
                buildingCostsLabel: modalResult.result.buildingCostsLabel,
                floorAnswerUuid: modalResult.result.answerUuid,
                buildingCostsQuestionUuid: modalResult.result.questionUuid,
            };
        }

        return {
            ...preparedPhoto,
            buildingCostsLabel: predictionResult?.className ?? null,
            floorAnswerUuid: null,
            buildingCostsQuestionUuid: null,
        };
    }

    private getLabelSelectQuestions(photoQuestion: Question) {
        return findChildrenForQuestion(photoQuestion, this.questionSet).map((child) => {
            //Transforming type to a MC that shows all the options, since this is a "quick choice" modal
            if (child.type === NormalQuestionType.MC_SELECT) {
                return {
                    ...child,
                    type: NormalQuestionType.MC,
                };
            }
            return child;
        });
    }

    private triggerDefectsWidget(preparedFile: PreparedFile) {
        if (preparedFile.buildingCostsQuestionUuid === null) {
            return;
        }

        //1. Get the building costs group from modal
        const buildingCostsGroupQuestion = this.questionSet.findQuestionByUuid(preparedFile.buildingCostsQuestionUuid);
        if (buildingCostsGroupQuestion === null) {
            return;
        }

        //2. Find the building costs question
        const buildingCostsQuestion = findAllChildrenForQuestionUuid(
            preparedFile.buildingCostsQuestionUuid,
            this.questionSet
        ).find((q) => q.type === NormalQuestionType.BUILDING_DEFECTS_COSTS);

        //3. Find out the floor iteration if needed
        const floorAnswer =
            preparedFile.floorAnswerUuid != null ? this.answerController.byUuid(preparedFile.floorAnswerUuid) : null;

        const questionUuidIteratorUuidPair: QuestionUuidIteratorUuidPair[] = [];
        if (floorAnswer && floorAnswer.iteration) {
            const currentFloorIterationPair: QuestionUuidIteratorUuidPair = {
                questionUuid: floorAnswer?.questionUuid,
                iteration: floorAnswer.iteration,
            };
            questionUuidIteratorUuidPair.push(currentFloorIterationPair);
        }

        if (buildingCostsQuestion) {
            const path = this.answerPathStubber.stubPathForQuestionUuid(
                preparedFile.buildingCostsQuestionUuid,
                questionUuidIteratorUuidPair
            );
            const groupPair = path[path.length - 1];
            if (groupPair) {
                runInAction(() => {
                    this.widgetData = {
                        costGroupQuestion: buildingCostsQuestion,
                        costGroupAnswer: groupPair.answer,
                        fileAnswer: preparedFile.answer,
                        buildingCostsLabel: preparedFile.buildingCostsLabel,
                    };
                });
            }
        }
    }

    private async upload(
        preparedFile: PreparedFile,
        progressCallback: (progress: number) => void
    ): Promise<null | PreparedFile> {
        try {
            const result = await this.imageUploadInteractor.uploadForAnswer(
                preparedFile.answer.uuid,
                preparedFile.file,
                {
                    fileTypes: this._fileTypes,
                    progressCallback,
                }
            );

            if (result.succeeded) {
                return preparedFile;
            } else {
                this.alertIncorrectType();
            }
        } catch (e) {
            console.error('Error triggering image upload', e);
            bugsnagClient?.notify(e);
        }

        return null;
    }

    private async fixRotation(uploadedFile: File): Promise<File> {
        try {
            return await this.imageOrientationFixer.fix(uploadedFile);
        } catch (e) {
            bugsnagClient?.notify(e, {
                metaData: {
                    origin: 'camera_presenter.ts fixRotation()',
                },
            });

            //We'll just ignore this error, apparently some iOS devices don't want to rotate
            return uploadedFile;
        }
    }

    public onLabelClick() {
        if (!this.isAvailable) {
            this.alertNotAvailable();
        }
        if (FeatureFlags.photoRecognition) {
            //Instead of trying to load the entire model on mount (and in turn 2MB+ on every page load)
            //Only at the moment the appraiser intents to upload a photo we're preparing the predictor
            this.photoRecognitionHandler.prepare();
        }
    }

    private alertIncorrectType() {
        alert('Fout bij het uploaden. Is een correct formaat? Voor afbeeldingen kun je .jpg en .png uploaden.');
    }

    @action
    private alertNotAvailable() {
        alert(
            "Offline opslag niet beschikbaar op dit apparaat, gebruik de systeem camera app om foto's te maken en voeg deze op een later moment toe."
        );
        this.isAvailable = false;
    }

    @action
    public updateProgress(progress: number, type: ProgressType) {
        this.progress = progress;
        this.progressType = type;
        this.onProgressChangeListener({
            progress,
            type,
        });
    }
}
