export interface AreaConclusion {
    brutoInhoud: number;
    perceelOppervlakte: number;
    gebruiksoppervlakteWonen: number;
    gebouwgebondenBuitenruimte?: number;
    overigeInpandigeRuimte?: number;
    externeBergruimte?: number;
}

export interface ApiAreaConclusion {
    bruto_inhoud: number | null;
    perceel_oppervlakte: number | null;
    gebruiksoppervlakte_wonen: number | null;
    gebouwgebonden_buitenruimte?: number | null;
    overige_inpandige_ruimte?: number | null;
    externe_bergruimte?: number | null;
}

export function getFromApiAreaConclusion(apiAreaConclusion: ApiAreaConclusion): AreaConclusion {
    return {
        brutoInhoud: apiAreaConclusion.bruto_inhoud ?? 0,
        perceelOppervlakte: apiAreaConclusion.perceel_oppervlakte ?? 0,
        gebruiksoppervlakteWonen: apiAreaConclusion.gebruiksoppervlakte_wonen ?? 0,
        gebouwgebondenBuitenruimte: apiAreaConclusion?.gebouwgebonden_buitenruimte ?? 0,
        overigeInpandigeRuimte: apiAreaConclusion?.overige_inpandige_ruimte ?? 0,
        externeBergruimte: apiAreaConclusion?.externe_bergruimte ?? 0,
    };
}
