import {makeObservable} from 'mobx';
import {ConceptReportProvider} from '../../../../../business/concept_report_provider';
import {SimpleQuestionPresenter} from './simple_question_presenter';

export class ConceptReportReplacementQuestionPresenter extends SimpleQuestionPresenter {
    constructor(
        private conceptReportProvider: ConceptReportProvider,
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public async regenerate() {
        if (this.question.reportSelector) {
            this.onChange('');
            this.conceptReportProvider.invalidateReplacementAnswer(this.question.reportSelector);
        }
    }
}
