import {action, computed, makeObservable, observable} from 'mobx';
import {
    compactHouseGroupConfigurationFromAnswer,
    getDefaultCompactHouseGroupConfiguration,
} from '../support/house_group_question/compact_house_group_default_configuration';

import {Answer} from '../../../../../models/answer';
import {AnswerController} from '../../../../../business/answering/answer_controller';
import {AnswerTouchState} from '../../../../../enum/answer_touch_state';
import {Appraisal} from '../../../../../models/appraisal';
import {AppraisalState} from '../../../../../enum/appraisal_state';
import {AppraisalValidationType} from '../../../../../enum/appraisal_validation_type';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {FloorQuestionType} from '../../../../../enum/question_type';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';
import {TechnicalReference} from '../../../../../enum/technical_reference';
import {buildQuestionAnswerTree} from '../../../../../../support/question_answer_tree';

export class CompactHouseGroupQuestionPresenter implements Presenter {
    @observable public numberOfFloors?: number;
    @observable public floorNames?: Map<number, string>;
    @observable public deleteVisible = false;

    private answer?: Answer;
    private subscriptions = new CompositeSubscription();

    private floorTypeQuestion?: Question | null;
    private floorNumberQuestion?: Question | null;

    constructor(
        private question: Question,
        private iteration: string | undefined,
        private appraisal: Appraisal,
        private answerController: AnswerController,
        private questionSet: QuestionSet,
        private parentAnswerUuid: string | undefined,
        private renderingContext: RenderingContextType
    ) {
        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(
                    this.question.uuid,
                    this.renderingContext === RenderingContextType.APPRAISAL ? null : this.parentAnswerUuid ?? null,
                    this.iteration || null
                )
                .subscribe((answer) => {
                    this.answer = answer;
                    this.fillFromAnswer(answer);
                })
        );

        this.floorTypeQuestion = this.questionSet.findQuestionByTechnicalReference(TechnicalReference.FLOOR_TYPE);
        this.floorNumberQuestion = this.questionSet.findQuestionByTechnicalReference(TechnicalReference.FLOOR_NUMBER);

        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidsStream(
                    [this.floorTypeQuestion?.uuid, this.floorNumberQuestion?.uuid].filter(
                        (uuid): uuid is string => uuid !== undefined
                    )
                )
                .subscribe(() => this.setFloorNames())
        );
    }

    @computed
    public get isDisabled(): boolean {
        if (!this.appraisal.isEditableAppraisal) {
            return true;
        }
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return this.appraisal.isFrozen && this.question.freezes;
        }
        return (
            (this.appraisal.isFrozen && this.question.freezes) ||
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    public setDeleteVisible(visible: boolean) {
        this.deleteVisible = visible;
    }

    @action
    public incrementFloors() {
        if (!this.numberOfFloors) {
            this.numberOfFloors = 0;
        }
        this.numberOfFloors++;
        this.onChange();
        this.setDeleteVisible(true);
    }

    @action
    public decrementFloors() {
        if (!this.numberOfFloors) {
            this.numberOfFloors = 0;
        }
        if (this.numberOfFloors > 0) {
            this.numberOfFloors--;
        }
        this.onChange();
        this.setDeleteVisible(true);
    }

    public removeFloor(floorNumber: number) {
        const question = this.questionSet.findQuestionsByType(FloorQuestionType.FLOOR_GROUP_FLOOR)[0];

        if (!question || this.numberOfFloors === undefined || this.numberOfFloors < floorNumber) {
            return;
        }

        const answers = this.answerController.answersForQuestionUuid(question.uuid);

        for (const answer of answers) {
            const answerFloorNumber = answer.iteration ? Number(answer.iteration) : null;
            if (
                !answer.iteration ||
                !answerFloorNumber ||
                Number.isNaN(answer.iteration) ||
                answerFloorNumber <= floorNumber ||
                answerFloorNumber > this.numberOfFloors
            ) {
                continue;
            }

            const previousFloorAnswer = answers.find((a) => Number(a.iteration) === answerFloorNumber - 1);

            // We need to clear the answer trees for each child group, as the question tree breaks when trying to retrieve it for the root floor question
            for (const group of this.questionSet.findChildQuestionsByParentUuid(question.uuid)) {
                const previousFloorGroup = previousFloorAnswer
                    ? this.answerController.answerByIdentifiers(group.uuid, null, previousFloorAnswer.iteration)
                    : null;

                const currentFloorGroup = this.answerController.answerByIdentifiers(group.uuid, null, answer.iteration);

                if (previousFloorGroup) {
                    const previousFloorTree = buildQuestionAnswerTree(
                        this.questionSet,
                        this.answerController.answers(),
                        previousFloorGroup
                    );

                    this.answerController.resetTree(previousFloorTree);
                }

                if (currentFloorGroup && previousFloorAnswer) {
                    const currentFloorTree = buildQuestionAnswerTree(
                        this.questionSet,
                        this.answerController.answers(),
                        currentFloorGroup
                    );

                    this.answerController.duplicateTree(currentFloorTree, null, previousFloorAnswer.iteration);
                }
            }
        }

        this.decrementFloors();
    }

    public onChange() {
        const value = JSON.stringify({
            numberOfFloors: this.numberOfFloors,
        });
        if (this.answer === undefined) {
            throw new Error('Can not use undefined answer');
        }
        this.answerController.onContentsChange(this.answer.uuid, value, AnswerTouchState.TOUCHED);

        const question = this.questionSet.questions.find((q) => q.type === FloorQuestionType.FLOOR_GROUP_FLOOR);

        if (this.numberOfFloors && question) {
            // Clear answers of deleted floors
            const answers = this.answerController.answersForQuestionUuid(question.uuid);
            for (const answer of answers) {
                if (answer.iteration && Number(answer.iteration) > this.numberOfFloors) {
                    // We need to clear the answer trees for each child group, as the question tree breaks when trying to retrieve it for the root floor question
                    for (const group of this.questionSet.findChildQuestionsByParentUuid(question.uuid)) {
                        const groupAnswer = this.answerController.answerByIdentifiers(
                            group.uuid,
                            null,
                            answer.iteration
                        );
                        if (groupAnswer) {
                            const tree = buildQuestionAnswerTree(
                                this.questionSet,
                                this.answerController.answers(),
                                groupAnswer
                            );

                            this.answerController.resetTree(tree);
                        }
                    }
                }
            }
        }

        this.setFloorNames();
    }

    @action
    private fillFromAnswer(answer: Answer) {
        const houseGroupConfiguration =
            answer.contents === null
                ? getDefaultCompactHouseGroupConfiguration()
                : compactHouseGroupConfigurationFromAnswer(answer);
        this.numberOfFloors = houseGroupConfiguration.numberOfFloors;
    }

    @action
    private setFloorNames() {
        const question = this.questionSet.questions.find((q) => q.type === FloorQuestionType.FLOOR_GROUP_FLOOR);

        if (!question || this.numberOfFloors === undefined || !this.floorTypeQuestion || !this.floorNumberQuestion) {
            return;
        }

        this.floorNames = new Map();

        const floorAnswers = this.answerController.answersForQuestionUuid(question.uuid);
        for (const answer of floorAnswers) {
            const answerFloorNumber = answer.iteration ? Number(answer.iteration) : null;
            if (
                !answer.iteration ||
                !answerFloorNumber ||
                Number.isNaN(answer.iteration) ||
                answerFloorNumber > this.numberOfFloors
            ) {
                continue;
            }

            const typeAnswers = this.answerController.filterDeleted(
                this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIteration(
                    this.floorTypeQuestion.uuid,
                    answer.uuid
                ) ?? []
            );

            const numberAnswers = this.answerController.filterDeleted(
                this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIteration(
                    this.floorNumberQuestion.uuid,
                    answer.uuid
                ) ?? []
            );

            const type =
                typeAnswers.length === 1
                    ? this.floorTypeQuestion.answerOptions.find((opt) => opt.id === typeAnswers[0].answerOptionId)
                          ?.contents
                    : null;
            const number =
                numberAnswers.length === 1
                    ? this.floorNumberQuestion.answerOptions.find((opt) => opt.id === numberAnswers[0].answerOptionId)
                          ?.contents
                    : null;

            if (type === 'Verdieping' && number) {
                this.floorNames.set(answerFloorNumber, type + ' ' + number);
            } else if (type) {
                this.floorNames.set(answerFloorNumber, type);
            } else {
                this.floorNames.set(answerFloorNumber, '');
            }
        }

        for (let i = 1; i <= this.numberOfFloors; i++) {
            if (!this.floorNames.has(i)) {
                this.floorNames.set(i, '');
            }
        }
    }
}
