import * as React from 'react';

import {ObjectType} from '../../../../../../../../../enum/object_type';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';
import {StatusDetailListForBuildYear} from '../../../../../../../../../components/status_details_list_for_build_year';
import {StatusDetailListForEnergyLabel} from '../../../../../../../../../components/status_details_list_for_energy_label';
import {StatusDetailListForObjectType} from '../../../../../../../../../components/status_details_list_for_object_type';
import {StatusDetailListForReferenceValue} from '../../../../../../../../../components/status_details_list_for_reference_value';
import {StatusDetailListForStatus} from '../../../../../../../../../components/status_details_list_for_status';
import {format} from 'date-fns';
import {isEmpty} from '../../../../../../../../../../support/util';

interface OwnProps {
    referenceObjectAnswer: ReferenceObjectAnswer;

    setType: SetType;
    buildYear: number | null;
    surfaceArea: number | null;
    plotArea: number | null;
    volume: number | null;
    energyLabel: string | null;
    objectType: ObjectType | null;
}

export class ReferenceObjectCompare extends React.Component<OwnProps> {
    private renderKoopprijs(referenceObjectAnswer: ReferenceObjectAnswer) {
        return (
            <>
                <div className="col-6 text-left">
                    <dl>
                        <dt>Koopsom</dt>
                        <dd>
                            {referenceObjectAnswer.referenceObject.verkoopprijs !== null
                                ? `€ ${referenceObjectAnswer.referenceObject.verkoopprijs.toLocaleString('nl-NL')}`
                                : '-'}
                        </dd>
                    </dl>
                </div>
                <div className="col-6 text-right">
                    <dl>
                        <dt>Gecorr. koopsom</dt>
                        <dd>
                            {referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs !== null
                                ? `€ ${referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs.toLocaleString(
                                      'nl-NL'
                                  )}`
                                : '-'}
                        </dd>
                    </dl>
                </div>
                <div className="col-12">
                    <dl>
                        <dt>Verkoopdatum</dt>
                        <dd>
                            {referenceObjectAnswer.referenceObject.verkoopdatum !== null &&
                            !isEmpty(referenceObjectAnswer.referenceObject.verkoopdatum)
                                ? format(new Date(referenceObjectAnswer.referenceObject.verkoopdatum), 'dd-MM-yyyy')
                                : '-'}
                        </dd>
                    </dl>
                </div>
            </>
        );
    }

    private renderHuurprijs(referenceObjectAnswer: ReferenceObjectAnswer) {
        return (
            <>
                <div className="col-6 text-left">
                    <dl>
                        <dt>Aanvangs huurprijs</dt>
                        <dd>
                            {referenceObjectAnswer.referenceObject.aanvangsHuurprijsPerMaand !== undefined &&
                            referenceObjectAnswer.referenceObject.aanvangsHuurprijsPerMaand !== null
                                ? `€ ${referenceObjectAnswer.referenceObject.aanvangsHuurprijsPerMaand.toLocaleString(
                                      'nl-NL'
                                  )}`
                                : '-'}
                        </dd>
                    </dl>
                </div>
                <div className="col-6 text-right">
                    <dl>
                        <dt>Gecorr. huurprijs</dt>
                        <dd>
                            {referenceObjectAnswer.referenceObject.gecorrigeerdeHuurprijsPerMaand !== undefined &&
                            referenceObjectAnswer.referenceObject.gecorrigeerdeHuurprijsPerMaand !== null
                                ? `€ ${referenceObjectAnswer.referenceObject.gecorrigeerdeHuurprijsPerMaand.toLocaleString(
                                      'nl-NL'
                                  )}`
                                : '-'}
                        </dd>
                    </dl>
                </div>
                <div className="col-12">
                    <dl>
                        <dt>Ingangsdatum Huur</dt>
                        <dd>
                            {referenceObjectAnswer.referenceObject.ingangsdatumHuur !== undefined &&
                            referenceObjectAnswer.referenceObject.ingangsdatumHuur !== null &&
                            !isEmpty(referenceObjectAnswer.referenceObject.ingangsdatumHuur)
                                ? format(new Date(referenceObjectAnswer.referenceObject.ingangsdatumHuur), 'dd-MM-yyyy')
                                : '-'}
                        </dd>
                    </dl>
                </div>
            </>
        );
    }

    public render() {
        const {setType, referenceObjectAnswer, buildYear, surfaceArea, plotArea, volume, energyLabel, objectType} =
            this.props;

        return (
            <>
                {setType === SetType.RENT
                    ? this.renderHuurprijs(referenceObjectAnswer)
                    : this.renderKoopprijs(referenceObjectAnswer)}

                <div className="col-12">
                    <StatusDetailListForObjectType
                        label="Woningtype"
                        value={referenceObjectAnswer.referenceObject.woningType as ObjectType | null}
                        referenceValue={objectType}
                        status={referenceObjectAnswer.referenceObject.woningTypeStatus}
                    />
                    <StatusDetailListForBuildYear
                        label="Bouwjaar"
                        value={referenceObjectAnswer.referenceObject.bouwjaar}
                        referenceValue={buildYear}
                    />
                    <StatusDetailListForReferenceValue
                        label="Gebruiksoppervlakte wonen"
                        value={
                            referenceObjectAnswer.referenceObject.gebruiksOppervlakte
                                ? referenceObjectAnswer.referenceObject.gebruiksOppervlakte
                                : null
                        }
                        referenceValue={surfaceArea}
                        unit="m²"
                    />
                    <StatusDetailListForReferenceValue
                        label="Perceeloppervlakte"
                        value={
                            referenceObjectAnswer.referenceObject.perceelOppervlakte
                                ? referenceObjectAnswer.referenceObject.perceelOppervlakte
                                : null
                        }
                        referenceValue={plotArea}
                        unit="m²"
                    />
                    <StatusDetailListForReferenceValue
                        label="Inhoud"
                        value={
                            referenceObjectAnswer.referenceObject.inhoud
                                ? referenceObjectAnswer.referenceObject.inhoud
                                : null
                        }
                        referenceValue={volume}
                        unit="m³"
                    />
                    <StatusDetailListForEnergyLabel
                        label="Energielabel"
                        value={referenceObjectAnswer.referenceObject.energielabel}
                        referenceValue={energyLabel}
                    />
                    <StatusDetailListForStatus
                        label="Onderhoudssituatie"
                        value={referenceObjectAnswer.referenceObject.onderhoudsSituatieUitleg}
                        status={referenceObjectAnswer.referenceObject.onderhoudsSituatieStatus}
                        useSmallText
                        hideTextNeutral
                    />
                    {referenceObjectAnswer.referenceObject.mateVanLuxeStatus !== undefined &&
                        referenceObjectAnswer.referenceObject.mateVanLuxeUitleg !== undefined && (
                            <StatusDetailListForStatus
                                label="Mate van luxe"
                                value={referenceObjectAnswer.referenceObject.mateVanLuxeUitleg}
                                status={referenceObjectAnswer.referenceObject.mateVanLuxeStatus}
                                useSmallText
                                hideTextNeutral
                            />
                        )}
                    {referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus !== undefined &&
                        referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg !== undefined && (
                            <StatusDetailListForStatus
                                label="Mate van doelmatigheid"
                                value={referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg}
                                status={referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus}
                                useSmallText
                                hideTextNeutral
                            />
                        )}
                    <StatusDetailListForStatus
                        label="Ligging"
                        value={referenceObjectAnswer.referenceObject.liggingUitleg}
                        status={referenceObjectAnswer.referenceObject.liggingStatus}
                        useSmallText
                        hideTextNeutral
                    />
                    <StatusDetailListForStatus
                        label="Bij-, op en/of aanbouwen"
                        value={referenceObjectAnswer.referenceObject.aanbouw}
                        status={referenceObjectAnswer.referenceObject.aanbouwStatus}
                        useSmallText
                    />
                </div>
            </>
        );
    }
}
