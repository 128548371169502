import {ReferenceObjectAnswer} from '../../../models/reference_object_answer';
import {isEmpty} from '../../../../../../../../../../../support/util';

export class ExplanationBuildingsGenerator {
    public generateDetailsSentence(referenceObjectAnswer: ReferenceObjectAnswer): string {
        const result: string[] = [];

        result.push(
            `bij,- op- of aanbouwen die ${referenceObjectAnswer.referenceObject.aanbouwStatus.toLocaleLowerCase()} zijn`
        );
        if (!isEmpty(referenceObjectAnswer.referenceObject.aanbouwUitleg)) {
            result.push(`, namelijk: ${referenceObjectAnswer.referenceObject.aanbouwUitleg.toLocaleLowerCase()}`);
        }
        return result.join('');
    }
}
