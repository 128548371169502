import {ApiAdaptedDefaultValue, apiAdaptedDefaultValueToAdaptedDefaultValue} from './models/api_adapted_default_value';

import {AdaptedDefaultValue} from '../models/adapted_default_value';
import {AjaxDriver} from '../../network/driver/ajax_driver';
import {TaskReference} from '../models/task_reference';
import {isApiTaskReference} from './models/api_task_reference';

export interface AdaptedDefaultValuesApi {
    getAdaptedDefaultValues(
        appraisalId: number,
        questionUuids: string[]
    ): Promise<AdaptedDefaultValue[] | TaskReference>;
}

export class DefaultAdaptedDefaultValuesApi implements AdaptedDefaultValuesApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public getAdaptedDefaultValues(
        appraisalId: number,
        questionUuids: string[]
    ): Promise<AdaptedDefaultValue[] | TaskReference> {
        return new Promise<AdaptedDefaultValue[] | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/default-adapted-values`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        question_uuids: questionUuids,
                    }),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiAdaptedDefaultValue[] | TaskReference = await result.json();
                        if (!data) {
                            reject();
                        } else if (isApiTaskReference(data)) {
                            resolve({
                                taskId: data.task_id,
                            });
                        } else {
                            if (!Array.isArray(data)) {
                                reject();
                            } else {
                                const adaptedDefaultValues = data.map((value) =>
                                    apiAdaptedDefaultValueToAdaptedDefaultValue(value)
                                );
                                resolve(adaptedDefaultValues);
                            }
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
