import * as React from 'react';

import {classNames} from '../../../../../../../../../../support/classnames';

interface OwnProps {
    animated?: boolean;
    tiles?: number;
}

export class ReferenceObjectTilePlaceholder extends React.Component<OwnProps> {
    public render() {
        let tileOpacityPercentages = [80, 70, 60, 50, 40, 30];
        const tileDetailOpacityPercentages = [100, 100, 80, 80, 60, 60, 40, 40];
        if (this.props.tiles !== undefined) {
            tileOpacityPercentages = tileOpacityPercentages.splice(0, this.props.tiles);
        }

        return (
            <>
                {tileOpacityPercentages.map((blockOpacity) => {
                    return (
                        <div
                            className={classNames(
                                'col-12 col-sm-6 col-md-4 form-grid-item form-grid-item-placeholder',
                                'form-grid-item-placeholder-opaque-' + blockOpacity,
                                {animated: this.props.animated !== undefined && this.props.animated}
                            )}
                            key={blockOpacity}
                        >
                            <div className="form-grid-item-img form-grid-item-placeholder-img">
                                <div className="form-grid-item-img-content"></div>
                            </div>
                            <div className="form-grid-item-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="grid-item-title grid-item-title-with-avatar">
                                            <div className="grid-item-title-avatar grid-item-title-avatar-icon-pin">
                                                &nbsp;
                                            </div>
                                            <p>
                                                <span className="form-grid-item-placeholder-title">&nbsp;</span>
                                                <span className="form-grid-item-placeholder-text">&nbsp;</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 text-left">
                                        <dl className={'form-grid-item-placeholder-item-opaque-100'}>
                                            <dt>
                                                <span className="form-grid-item-placeholder-title">&nbsp;</span>
                                            </dt>
                                            <dd>
                                                <span className="form-grid-item-placeholder-text">&nbsp;</span>
                                            </dd>
                                        </dl>
                                    </div>
                                    {tileDetailOpacityPercentages.map((textOpacity, index) => {
                                        return (
                                            <div
                                                key={textOpacity + index}
                                                className={'col-6 ' + (index % 2 === 0 ? 'text-left' : 'text-right')}
                                            >
                                                <dl className={'form-grid-item-placeholder-item-opaque-' + textOpacity}>
                                                    <dt>
                                                        <span className="form-grid-item-placeholder-title">&nbsp;</span>
                                                    </dt>
                                                    <dd>
                                                        <span className="form-grid-item-placeholder-text">&nbsp;</span>
                                                    </dd>
                                                </dl>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="form-grid-item-footer">&nbsp;</div>
                        </div>
                    );
                })}
            </>
        );
    }
}
