import {Presenter} from '../../../../support/presenter/presenter';
import {AnswerInteractor} from '../../../business/answering/answer_interactor';

export class OnMountSaverPresenter implements Presenter {
    constructor(private answerInteractor: AnswerInteractor) {}

    public mount() {
        this.answerInteractor.submit();
    }
    public unmount() {
        // Noop
    }
}
