import {ConstructionCostsAllocationType} from '../enum/constructional_defect';
import {ConstructionCostsLine} from './construction_costs_summary';

export enum ConstructionCostDuration {
    DIRECT = 'direct',
    INDIRECT = 'indirect',
    BOTH = 'both',
}

export interface DefinedConstructionCostsLine extends ConstructionCostsLine {
    amount: number | null;
    duration: ConstructionCostDuration;
}

export interface ValidationInstituteConstructionalDefect {
    reference: string;
    lines: DefinedConstructionCostsLine[];
    costs5Years: number;
    directCosts: number;
    allocation: ConstructionCostsAllocationType | null;
}
