interface LeewayRect {
    top: number;
    right: number;
    bottom: number;
    left: number;
}
export function isElementInViewport(element: Element, leeway?: LeewayRect) {
    const rect = element.getBoundingClientRect();

    return (
        rect.top + (leeway?.top ?? 0) >= 0 &&
        rect.left + (leeway?.left ?? 0) >= 0 &&
        rect.bottom - (leeway?.bottom ?? 0) <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right - (leeway?.right ?? 0) <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function scrollIntoViewIfNeeded(element: Element, options: ScrollIntoViewOptions, leeway?: LeewayRect) {
    if (!isElementInViewport(element, leeway)) {
        element.scrollIntoView(options);
    }
}

export function timedScrollIntoViewIfNeeded(element: Element, options: ScrollIntoViewOptions, leeway?: LeewayRect) {
    scrollIntoViewIfNeeded(element, options, leeway);
    setTimeout(() => scrollIntoViewIfNeeded(element, options, leeway), 10);
    setTimeout(() => scrollIntoViewIfNeeded(element, options, leeway), 150);
}
