import {AppointmentType, ExternalAppointment, PaymentByType} from '../models/external_appointment';

import {AjaxDriver} from '../../network/driver/ajax_driver';

interface ApiExternalAppointment {
    id?: number;
    appointment_type: AppointmentType;
    payment_by_type: PaymentByType;
    appraisal_id: number;
    request_by_user_id?: number;
    created_at?: string | null;
    appointment_date?: string | null;
    comment: string;
}

export interface ExternalAppointmentsApi {
    getByAppraisalId(appraisalId: number): Promise<ExternalAppointment[]>;
    store(exernalAppointment: ExternalAppointment): Promise<void>;
}

export class DefaultExternalAppointmentsApi implements ExternalAppointmentsApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public async getByAppraisalId(appraisalId: number): Promise<ExternalAppointment[]> {
        try {
            const response = await this.ajaxDriver.fetch(`/ajax/appraisals/${appraisalId}/external-appointments`, {
                method: 'GET',
                credentials: 'same-origin',
                headers: {
                    'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                const apiExternalAppointments = await response.json();
                return apiExternalAppointments.map(apiExternalAppointmentToExternalAppointment);
            }

            return [];
        } catch (e) {
            console.warn(e);
            return [];
        }
    }

    public async store(externalAppointment: ExternalAppointment): Promise<void> {
        try {
            const response = await this.ajaxDriver.fetch(
                `/ajax/appraisals/${externalAppointment.appraisalId}/external-appointments`,
                {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify(externalAppointmentToAiExternalAppointment(externalAppointment)),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.ok) {
                return response.json();
            }
        } catch (e) {
            console.warn(e);
        }
    }
}

function apiExternalAppointmentToExternalAppointment(
    apiExternalAppointment: ApiExternalAppointment
): ExternalAppointment {
    return {
        id: apiExternalAppointment.id,
        appointmentType: apiExternalAppointment.appointment_type,
        paymentByType: apiExternalAppointment.payment_by_type,
        appraisalId: apiExternalAppointment.appraisal_id,
        requestedByUserId: apiExternalAppointment.request_by_user_id,
        createdAt: apiExternalAppointment.created_at ? new Date(apiExternalAppointment.created_at) : null,
        comment: apiExternalAppointment.comment,
        appointmentDate: apiExternalAppointment.appointment_date ? apiExternalAppointment.appointment_date : null,
    };
}

function externalAppointmentToAiExternalAppointment(externalAppointment: ExternalAppointment): ApiExternalAppointment {
    return {
        id: externalAppointment.id,
        appointment_type: externalAppointment.appointmentType,
        payment_by_type: externalAppointment.paymentByType,
        appraisal_id: externalAppointment.appraisalId,
        request_by_user_id: externalAppointment.requestedByUserId,
        comment: externalAppointment.comment,
    };
}
