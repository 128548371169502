import * as React from 'react';

import {Appraisal} from '../../../../../../../../../models/appraisal';
import {BuildYearBadge} from '../../../../../../../../../components/badges/build_year_badge';
import {FloorAreaBadge} from '../../../../../../../../../components/badges/floor_area_badge';
import {FloorAreaPrice} from '../floor_area_price';
import {Loader} from '../../../../../../../components/loader';
import {Modal} from '../../../../../components/modal';
import {PlotAreaBadge} from '../../../../../../../../../components/badges/plot_area_badge';
import {PriceRange} from '../price_range';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {ReferenceObjectDetailsModalPresenter} from './reference_object_details_modal_presenter';
import {Sale} from '../../../../../../../../../models/sale';
import {classNames} from '../../../../../../../../../../support/classnames';
import {format} from 'date-fns';
import {transformRecord} from '../../../../../../../../../models/validation_institute_reference_object';
import {observer} from 'mobx-react';
import {ReferenceObject, Source, sourceToClassName} from '../../models/reference_object';
import {VolumeBadge} from '../../../../../../../../../components/badges/volume_badge';
import {ObjectTypeBadge} from '../../../../../../../../../components/badges/object_type_badge';
import {ObjectType} from '../../../../../../../../../enum/object_type';
import {SetDefinition} from '../../reference_objects_question_presenter';
import {Macros} from '../../../../macros/macros';
import {macroIsActive, toggleMacro} from '../../../../../../../../../business/toggle_macro';
import {MacroExternalType} from '../../../../../../../../../models/macro';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';
import {Collapsible} from '../../../../../../../components/collapsible';
import {ReferenceImageViewer} from '../photos/image_viewer';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {referenceObjectImages} from '../../internal/reference_sale_images';
interface OwnProps {
    appraisal: Appraisal;
    referenceBuildyear: number | null;
    referencePlotArea: number | null;
    referenceFloorArea: number | null;
    referenceVolume: number | null;
    referenceObjectType: ObjectType | string | null;
    referenceEnergyLabel: string | null;
    referenceObject: ReferenceObject | null;
    questionSet: QuestionSet;
    valuation: number | null;
    visible: boolean;
    onHide: () => void;
    canAdd: boolean;
    onAdd: null | ((sale: Sale) => Promise<void>);

    overview: Record<string, string> | null;
    installationDates: Record<string, string> | null;
    id: string;
    street: string;
    houseNumber: string;
    letter: string;
    postalCode: string;
    city: string;
    objectType: ObjectType | string | null;
    buildYear: number | null;
    floorArea: number | null;
    plotArea: number | null;
    volume: number | null;
    priceRange: string | number | null;
    indexedPriceRange: string | number | null;
    saleQuarter: string | null;
    highlights: string[] | null;
    frontview: string | null;
    ownership: string | null;
    rooms: number | null;
    energyLabel: string | null;
    transportDate: string | null;
    daysOpenForSale: string | null;
    brochureUrl: string | null;
    source: string | null;
    additionalPropertiesByCategory: Record<string, Record<string, string>> | null;
    saleText: string | null;
    rejectReason: string | null;
    complete: boolean | null;
    setDefinition: SetDefinition | null;
    isFrozen: boolean;
    isEnhancing: boolean;
    referenceObjectAnswer: ReferenceObjectAnswer | null;
}

export const ReferenceObjectDetailsModal: React.FC<OwnProps> = observer(function ReferenceObjectDetailsModal(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectDetailsModalPresenter(
                props.id,
                props.source,
                props.rejectReason,
                props.setDefinition,
                props.onAdd,
                props.onHide,
                props.referenceObject,
                props.referenceObjectAnswer,
                container.business.referenceObjectProvider(props.appraisal, props.questionSet),
                container.business.referenceObjectsMetadataProvider(props.appraisal, props.questionSet),
                container.business.macroInteractor(props.questionSet),
                container.business.referenceObjectsAnswerEnhancementInteractor(props.appraisal, props.questionSet)
            )
    );

    const overview = transformRecord(props.overview);
    const installationDates = transformRecord(props.installationDates);

    const images = React.useMemo(() => {
        return referenceObjectImages(
            presenter.enhancedReferenceSale ?? props.referenceObject,
            props.referenceObjectAnswer
        );
    }, [props.referenceObject, props.referenceObjectAnswer, presenter.enhancedReferenceSale]);

    return (
        <Modal key={props.id} onHide={() => props.onHide()} visible={props.visible}>
            <div className="modal-header">
                <h2>
                    {props.street} {props.houseNumber},{props.city}
                </h2>
            </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-sm-6">
                        <h2>Object details</h2>
                        <dl>
                            <dt>Adres</dt>
                            <dl>
                                {props.street} {props.houseNumber}
                                {props.letter}
                                <br />
                                {props.postalCode} {props.city}
                            </dl>
                        </dl>
                        <dl>
                            <dt>Woningtype</dt>
                            <dd>
                                {props.objectType ?? '-'}
                                {props.referenceObjectType && (
                                    <ObjectTypeBadge
                                        objectType={props.objectType as ObjectType}
                                        referenceObjectType={props.referenceObjectType as ObjectType}
                                    />
                                )}
                            </dd>
                        </dl>
                        <dl>
                            <dt>Bouwjaar</dt>
                            <dl>
                                {props.buildYear === null ? '-' : props.buildYear}
                                {props.buildYear !== null && props.referenceBuildyear !== null ? (
                                    <BuildYearBadge
                                        buildYear={props.buildYear}
                                        referenceBuildYear={props.referenceBuildyear}
                                    />
                                ) : null}
                            </dl>
                        </dl>
                        <dl>
                            <dt>Energielabel</dt>
                            <dd>
                                {props.energyLabel !== null ? props.energyLabel : '-'}{' '}
                                {props.referenceEnergyLabel !== null ? '(' + props.referenceEnergyLabel + ')' : ''}
                            </dd>
                        </dl>
                        <dl>
                            <dt>Gebruiksoppervlakte wonen</dt>
                            <dl>
                                {props.floorArea === null ? '-' : props.floorArea + 'm²'}
                                {props.referenceFloorArea !== null && props.floorArea !== null ? (
                                    <FloorAreaBadge
                                        floorArea={props.floorArea}
                                        referenceFloorArea={props.referenceFloorArea}
                                    />
                                ) : null}
                            </dl>
                        </dl>
                        <dl>
                            <dt>Perceeloppervlakte</dt>
                            <dl>
                                {props.plotArea === null ? '-' : props.plotArea + 'm²'}
                                {props.referencePlotArea !== null && props.plotArea !== null ? (
                                    <PlotAreaBadge
                                        plotArea={props.plotArea}
                                        referencePlotArea={props.referencePlotArea}
                                    />
                                ) : null}
                            </dl>
                        </dl>
                    </div>
                    <div className="col-sm-6">
                        <div className={classNames('detail-image', {'gray-scale': props.complete === false})}>
                            <div className={classNames('reference-logo', sourceToClassName(props.source ?? null))}>
                                &nbsp;
                            </div>
                            <ReferenceImageViewer
                                images={images}
                                appraisal={props.appraisal}
                                questionSet={props.questionSet}
                            />
                        </div>
                        {images.length <= 4 && !props.isEnhancing && props.complete && (
                            <span className="ion-md-alert alert-inline alert-info">
                                Via al onze databronnen hebben we helaas niet meer foto&apos;s kunnen vinden
                            </span>
                        )}
                        {props.isEnhancing && (
                            <div className="d-flex justify-content-center">
                                <Loader isSmall addSpace={false} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <dl>
                            <dt>Prijs per m²</dt>
                            <dl>
                                <FloorAreaPrice
                                    referenceObjectId={null}
                                    referenceSetPrice={props.valuation}
                                    referenceObjectFloorArea={props.floorArea}
                                    referenceObjectPriceRange={
                                        props.priceRange !== null ? String(props.priceRange) : null
                                    }
                                    referenceObjectIndexedPrice={
                                        props.indexedPriceRange !== null ? String(props.indexedPriceRange) : null
                                    }
                                    referenceSetFloorArea={props.referenceFloorArea}
                                />
                            </dl>
                        </dl>
                        <dl>
                            <dt>Bruto inhoud</dt>
                            <dl>
                                {props.volume === null ? '-' : 'ca. ' + props.volume + 'm³'}
                                {props.referenceVolume !== null && props.volume !== null ? (
                                    <VolumeBadge referenceVolume={props.referenceVolume} volume={props.volume} />
                                ) : null}
                            </dl>
                        </dl>
                        {props.ownership && (
                            <dl>
                                <dt>Eigendom</dt>
                                <dl>{props.ownership}</dl>
                            </dl>
                        )}
                        {props.rooms && (
                            <dl>
                                <dt>Aantal kamers</dt>
                                <dl>{props.rooms}</dl>
                            </dl>
                        )}
                        {props.energyLabel && (
                            <dl>
                                <dt>Energielabel</dt>
                                <dl>{props.energyLabel}</dl>
                            </dl>
                        )}
                        {props.brochureUrl && (
                            <a className="btn btn-sm" href={props.brochureUrl} target="_blank" rel="noreferrer">
                                {props.source === Source.ARCHIEF_NVM
                                    ? 'Stamkaart'
                                    : props.brochureUrl.includes('funda.nl')
                                    ? 'Funda'
                                    : 'Brochure'}
                            </a>
                        )}
                    </div>
                    <div className="col-6">
                        <h2>Verkoop details</h2>
                        <dl>
                            <dt>Prijsklasse</dt>
                            <dl>
                                <PriceRange range={props.priceRange !== null ? String(props.priceRange) : null} />
                            </dl>
                        </dl>
                        {props.indexedPriceRange && (
                            <dl>
                                <dt>Geïndexeerde prijsklasse</dt>
                                <dl>
                                    <PriceRange
                                        range={
                                            props.indexedPriceRange !== null ? String(props.indexedPriceRange) : null
                                        }
                                    />
                                </dl>
                            </dl>
                        )}
                        <dl>
                            <dt>Datum afmelding/verkocht op</dt>
                            <dl>{props.saleQuarter === null ? 'Onbekend' : props.saleQuarter}</dl>
                        </dl>
                        {props.transportDate && (
                            <dl>
                                <dt>Transportdatum</dt>
                                <dl>{format(new Date(props.transportDate), 'dd-MM-yyyy')}</dl>
                            </dl>
                        )}
                        {props.daysOpenForSale && (
                            <dl>
                                <dt>Aantal dagen te koop</dt>
                                <dl>
                                    {!isNaN(Number(props.daysOpenForSale))
                                        ? Number(props.daysOpenForSale) === 1
                                            ? `${Number(props.daysOpenForSale)} dag`
                                            : `${Number(props.daysOpenForSale)} dagen`
                                        : props.daysOpenForSale}
                                </dl>
                            </dl>
                        )}
                    </div>
                    <div className="col-12">
                        {Object.keys(props.additionalPropertiesByCategory ?? {}).length > 0 && (
                            <Collapsible title="Object-kenmerken" light defaultShown>
                                <div className="ml-1">
                                    <div className="row">
                                        {Object.entries(props.additionalPropertiesByCategory ?? {}).map(
                                            ([category, properties]) => (
                                                <div key={category} className="col-6">
                                                    <h2>{category}</h2>
                                                    {Object.entries(properties).map(([key, value]) => (
                                                        <dl key={key}>
                                                            <dt>{key}</dt>
                                                            <dl>{value}</dl>
                                                        </dl>
                                                    ))}
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </Collapsible>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        {props.highlights && props.highlights.length > 0 && (
                            <div>
                                <h2>Highlights</h2>
                                <ul>
                                    {props.highlights.map((highlight, index) => (
                                        <li key={highlight + '-' + index}>{highlight}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="col-6">
                        {overview.length > 0 && (
                            <div>
                                <h2>Overzicht</h2>
                                <ul>
                                    {overview.map((value, index) => (
                                        <li key={value + '-' + index}>{value}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {installationDates.length > 0 && (
                            <div>
                                <h2>Installatie datums</h2>
                                <ul>
                                    {installationDates.map((value, index) => (
                                        <li key={value + '-' + index}>{value}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    {props.saleText && (
                        <div className="col-12">
                            <Collapsible title="Verkoop tekst" light>
                                <p>{props.saleText}</p>
                            </Collapsible>
                        </div>
                    )}
                </div>
                {presenter.isRejected && (
                    <div className="row">
                        <div className="col-12">
                            <div
                                className={
                                    presenter.rejectMacros?.length !== 0 ? 'form-group has-macros' : 'form-group'
                                }
                            >
                                <div className="label-container">
                                    <span className="form-label-highlight">*</span>
                                    <label>Reden van afkeuren</label>
                                </div>
                                {presenter.rejectMacros && !props.isFrozen && (
                                    <Macros
                                        externalType={MacroExternalType.REFEFENCES_REJECT_REASON}
                                        parentAnswerUuid={null}
                                        hasAdvancedOptions={false}
                                        questionSet={props.questionSet}
                                        appraisal={props.appraisal}
                                        isActive={(contents) => macroIsActive(contents, presenter.rejectReason)}
                                        onUseMacro={(contents) =>
                                            presenter.onRejectReasonChange(
                                                toggleMacro(contents, presenter.rejectReason)
                                            )
                                        }
                                        onAdd={presenter.onAddAsRejectMacroClick}
                                        onRemove={presenter.onRemoveRejectMacroClick}
                                        onFavorite={presenter.onFavoriteRejectMacroClick}
                                        showAddButton={
                                            presenter.rejectReason !== '' &&
                                            presenter.rejectReason !== null &&
                                            !presenter.rejectMacros.some((m) => m.contents === presenter.rejectReason)
                                        }
                                    />
                                )}
                                <textarea
                                    className="form-control"
                                    rows={3}
                                    value={presenter.rejectReason ?? ''}
                                    onChange={(e) => presenter.onRejectReasonChange(e.target.value)}
                                    readOnly={props.isFrozen}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="modal-footer">
                {!presenter.isRejected && (
                    <button className="btn btn-secondary ion-md-close" type="button" onClick={() => props.onHide()}>
                        Sluiten
                    </button>
                )}
                {!presenter.isRejected && props.referenceObject !== null && (
                    <button
                        className="btn btn-delete ion-md-close-circle"
                        type="submit"
                        onClick={() => presenter.onReject()}
                    >
                        Afkeuren
                    </button>
                )}
                {props.onAdd !== null && props.canAdd && !presenter.isRejected && (
                    <button className="btn btn-primary ion-md-close" type="button" onClick={() => presenter.onAdd()}>
                        {presenter.isAdding ? (
                            <span>
                                <Loader />
                                Ophalen...
                            </span>
                        ) : (
                            <span>Toevoegen</span>
                        )}
                    </button>
                )}
                {presenter.isRejected && (
                    <button className="btn btn-primary ion-md-close" type="button" onClick={() => presenter.onSave()}>
                        Opslaan
                    </button>
                )}
            </div>
        </Modal>
    );
});
