import * as React from 'react';

import {EditableDayPickerInput} from '../../../../components/editable_day_picker_input';
import {format} from 'date-fns';
import {isEmpty} from '../../../../../../../../../support/util';
import {isMobile} from '../../../../../../../../../support/check_mobile';

interface OwnProps {
    name: string;
    label: string;
    value: string | null;
    isRequired?: boolean;
    disabled?: boolean;
    onChange: (e: string) => void;
}

export class DateInput extends React.Component<OwnProps> {
    private handleDateChange(date: string | undefined) {
        if (date !== undefined) {
            if (isEmpty(date)) {
                this.props.onChange('');
            } else {
                try {
                    this.props.onChange(new Date(date).toISOString());
                } catch (e) {
                    this.props.onChange('');
                }
            }
        } else {
            this.props.onChange('');
        }
    }

    private renderPicker() {
        const value =
            isEmpty(this.props.value) || this.props.value === null
                ? ''
                : format(new Date(this.props.value), 'yyyy-MM-dd');
        return (
            <div className="input-group">
                <div className="input-group-prepend">
                    <div className="input-group-text ion-md-calendar">&nbsp;</div>
                </div>
                {isMobile() ? (
                    <input type="date" value={value} onChange={(e) => this.props.onChange(e.target.value)} />
                ) : (
                    <EditableDayPickerInput value={value} onChange={(date) => this.handleDateChange(date)} />
                )}
            </div>
        );
    }

    private renderDisabled() {
        return <div className="input-group">{this.props.value !== null ? this.props.value : '-'}</div>;
    }

    public render() {
        return (
            <div className="form-group">
                <label htmlFor={this.props.name}>
                    {this.props.label}
                    {this.props.isRequired ? <span className="form-label-highlight">*</span> : null}
                </label>
                {this.props.disabled ? this.renderDisabled() : this.renderPicker()}
            </div>
        );
    }
}
