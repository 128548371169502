import * as React from 'react';

import {ActiveSetDefinition, PreselectedReferenceObject} from '../reference_objects_question_presenter';
import {SortingDirection, SortingMethod} from '../../../../../../../../enum/reference_objects_sorting';

import {Appraisal} from '../../../../../../../../models/appraisal';
import {Loader} from '../../../../../../components/loader';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {ReferenceObject} from '../models/reference_object';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {ReferenceObjectTile} from './reference_object_tile';
import {ReferenceObjectTilePlaceholder} from './reference_object_tile_placeholder';
import {SetType} from '../../../../../../../../models/reference_set/set_type';
import {VisibleReferenceObject} from '../reference_objects_question_presenter';
import {classNames} from '../../../../../../../../../support/classnames';
import {observer} from 'mobx-react';

interface OwnProps {
    questionSet: QuestionSet;
    appraisal: Appraisal;
    isComparing: boolean;
    isSavingAnswer: boolean;
    isUpdatingReferenceObjects: boolean;
    sortingMethod: SortingMethod;
    sortingDirection: SortingDirection;
    updateSortingMethod: (method: SortingMethod) => void;
    activeSetDefinition: ActiveSetDefinition;
    visibleReferenceObjects: VisibleReferenceObject[];
    preselectedReferenceObjects: PreselectedReferenceObject[];
    onAddAndOpenModal: (
        referenceObjectAnswer: ReferenceObjectAnswer,
        referenceObject?: ReferenceObject
    ) => Promise<void>;
    onRemove: (referenceObjectAnswer: ReferenceObjectAnswer) => Promise<void>;
    updateIsComparing: (isComparing: boolean) => void;
    canAdd: boolean;
    numVisible: number;
    showMoreReferenceObjects: () => void;
    showAnswerModal: (referenceObject: ReferenceObject, referenceObjectAnswer: ReferenceObjectAnswer) => void;
    showDetailsModal: (referenceObject: ReferenceObject) => void;

    hoveringReferenceObject: ReferenceObject | null;
    clickedReferenceObject: ReferenceObject | null;
    setHoveringReferenceObject: (ro: ReferenceObject | null) => void;
    setClickedReferenceObject: (ro: ReferenceObject | null) => void;
    children?: React.ReactNode;
}

@observer
export class ReferenceObjectGrid extends React.Component<OwnProps> {
    private renderSortingButtons(activeSetDefinition: ActiveSetDefinition) {
        const sortingMethods = [SortingMethod.ALPHABETICALLY];

        if (activeSetDefinition.setDefinition.type === SetType.SOLD) {
            sortingMethods.push(SortingMethod.SALES_DATE);
            sortingMethods.push(SortingMethod.DEVIATION_SCORE);

            if (this.props.appraisal.latitude !== null && this.props.appraisal.longitude !== null) {
                sortingMethods.push(SortingMethod.DISTANCE);
            }
        }

        return (
            <div className="row">
                <div className="col-12">
                    <ul className="nav-filter">
                        {sortingMethods.map((method: SortingMethod) => {
                            return (
                                <li
                                    key={method}
                                    className={classNames('nav-filter-item', {
                                        active: !this.props.isComparing && this.props.sortingMethod === method,
                                        'ion-md-arrow-dropup':
                                            !this.props.isComparing &&
                                            this.props.sortingMethod === method &&
                                            this.props.sortingDirection === SortingDirection.ASCENDING,
                                        'ion-md-arrow-dropdown':
                                            !this.props.isComparing &&
                                            this.props.sortingMethod === method &&
                                            this.props.sortingDirection === SortingDirection.DESCENDING,
                                    })}
                                    onClick={() => this.props.updateSortingMethod(method)}
                                >
                                    {method}
                                </li>
                            );
                        })}
                        <li
                            key="compare"
                            className={classNames('nav-filter-item', {
                                active: this.props.isComparing,
                                disabled: activeSetDefinition.referenceObjectAnswers.length === 0,
                            })}
                            onClick={() => this.props.updateIsComparing(true)}
                        >
                            Vergelijken
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    public render() {
        const preselection = this.props.visibleReferenceObjects.filter(({referenceObject}) =>
            this.props.preselectedReferenceObjects.some(
                (obj) => obj.id === referenceObject.id && obj.source === referenceObject.source
            )
        );

        const visibleReferenceObjects = this.props.visibleReferenceObjects.filter(
            (item) => !preselection.includes(item)
        );

        return (
            <div className="reference-object-grid-parent">
                {this.renderSortingButtons(this.props.activeSetDefinition)}

                <div
                    className={classNames('row grid reference-object-grid', {
                        'reference-object-grid-reload': this.props.isUpdatingReferenceObjects,
                    })}
                >
                    {preselection.length > 0 && (
                        <>
                            <h2>Voorselectie</h2>
                            {preselection.map(({referenceObject, referenceObjectAnswer}, index) => {
                                return (
                                    <ReferenceObjectTile
                                        key={referenceObject.id}
                                        appraisal={this.props.appraisal}
                                        questionSet={this.props.questionSet}
                                        activeSetDefinition={this.props.activeSetDefinition}
                                        buildYear={this.props.activeSetDefinition.setDefinition.buildYear}
                                        referenceObject={referenceObject}
                                        referenceObjectAnswer={referenceObjectAnswer}
                                        index={index}
                                        canAdd={this.props.canAdd}
                                        onAddAndOpenModal={this.props.onAddAndOpenModal}
                                        onRemove={this.props.onRemove}
                                        onHoverChange={this.props.setHoveringReferenceObject}
                                        onClickChange={this.props.setClickedReferenceObject}
                                        isMapHovering={this.props.hoveringReferenceObject === referenceObject}
                                        isMapSelected={this.props.clickedReferenceObject === referenceObject}
                                        showAnswerModal={this.props.showAnswerModal}
                                        showDetailsModal={this.props.showDetailsModal}
                                        isCompare={this.props.isComparing}
                                        isSavingAnswer={this.props.isSavingAnswer}
                                        isPreselected={true}
                                    />
                                );
                            })}
                            {visibleReferenceObjects.length > 0 && <h2>Referentieobjecten</h2>}
                        </>
                    )}
                    {visibleReferenceObjects.map(({referenceObject, referenceObjectAnswer}, index) => {
                        return (
                            <ReferenceObjectTile
                                key={referenceObject.id}
                                appraisal={this.props.appraisal}
                                questionSet={this.props.questionSet}
                                activeSetDefinition={this.props.activeSetDefinition}
                                buildYear={this.props.activeSetDefinition.setDefinition.buildYear}
                                referenceObject={referenceObject}
                                referenceObjectAnswer={referenceObjectAnswer}
                                index={index}
                                canAdd={this.props.canAdd}
                                onAddAndOpenModal={this.props.onAddAndOpenModal}
                                onRemove={this.props.onRemove}
                                onHoverChange={this.props.setHoveringReferenceObject}
                                onClickChange={this.props.setClickedReferenceObject}
                                isMapHovering={this.props.hoveringReferenceObject === referenceObject}
                                isMapSelected={this.props.clickedReferenceObject === referenceObject}
                                showAnswerModal={this.props.showAnswerModal}
                                showDetailsModal={this.props.showDetailsModal}
                                isCompare={this.props.isComparing}
                                isSavingAnswer={this.props.isSavingAnswer}
                                isPreselected={false}
                            />
                        );
                    })}
                    {(this.props.visibleReferenceObjects.length === 0 ||
                        (this.props.isUpdatingReferenceObjects && this.props.visibleReferenceObjects.length < 6)) && (
                        <ReferenceObjectTilePlaceholder
                            animated={this.props.activeSetDefinition.setDefinition.type === SetType.SOLD}
                            tiles={6 - this.props.visibleReferenceObjects.length}
                        />
                    )}
                </div>

                {this.props.isUpdatingReferenceObjects && <Loader icon="ion-md-pin" />}

                <div className="row">
                    <div className="col-12">
                        {this.props.activeSetDefinition.referenceObjectSetData &&
                        this.props.activeSetDefinition.referenceObjectSetData.referenceSales.length >
                            this.props.numVisible ? (
                            <button
                                disabled={this.props.isUpdatingReferenceObjects}
                                onClick={() => this.props.showMoreReferenceObjects()}
                                className="btn btn-sm ion-md-refresh float-left"
                            >
                                Laad meer
                            </button>
                        ) : null}
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
