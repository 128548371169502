import React from 'react';
import {usePresenter} from '../../../../support/presenter/use_presenter';
import {Appraisal} from '../../../models/appraisal';
import {QuestionSet} from '../../../models/question_set';
import {ImportExportPresenter} from './import_export_presenter';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export function Export(props: OwnProps) {
    const presenter = usePresenter(
        (component) =>
            new ImportExportPresenter(
                props.appraisal,
                component.business.answerController(props.appraisal, props.questionSet),
                component.business.flashMessageBroadcaster,
                component.business.networkStatusProvider
            )
    );

    return (
        <div className="row">
            <div className="col-12">
                <h2>Download ondersteuningsinformatie</h2>
                <p>
                    Download een data-bestand met ontwikkelaarsinformatie voor het oplossen van problemen binnen deze
                    taxatie.
                </p>
                <button
                    className="btn btn-sm btn-primary float-right ion-md-cloud-download"
                    onClick={() => presenter.export()}
                >
                    Download bestand
                </button>
            </div>
        </div>
    );
}
