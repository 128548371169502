import {debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {QuestionExtensionType} from '../../../../enum/question_extension_type';
import {TechnicalReference} from '../../../../enum/technical_reference';
import {QuestionSet} from '../../../../models/question_set';
import {AnswerController} from '../../../../business/answering/answer_controller';
import {UserSettingsInteractor} from '../../../../business/user_settings/user_settings_interactor';
import {QuestionEffectInteractor} from '../../../../business/conditions/question_effects_interactor';
import {Presenter} from '../../../../../support/presenter/presenter';
import {combineLatest} from 'rxjs';
import {isMcType, NormalQuestionType, RootGroupQuestionType} from '../../../../enum/question_type';
import {Answer} from '../../../../models/answer';
import {Question} from '../../../../models/question';

interface MissingEntry {
    amountRequired: number;
    amountCurrently: number;
    amountMissing: number;
    isCompleted: boolean;
}

export class PhotoRequirementsChecklistPresenter implements Presenter {
    private subscriptions = new CompositeSubscription();

    @observable.ref public isChecklistOpen = false;
    @observable.ref public missingPhotoLabels: Map<TechnicalReference, MissingEntry> | null = null;

    @computed
    public get progress() {
        if (this.missingPhotoLabels === null) {
            return null;
        }

        let total = 0;
        let selected = 0;
        for (const value of this.missingPhotoLabels.values()) {
            total += value.amountRequired;
            if (value.isCompleted) {
                selected += value.amountRequired;
            } else {
                selected += value.amountCurrently;
            }
        }
        return {total, selected};
    }

    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private userSettingsInteractor: UserSettingsInteractor,
        private questionEffectsInteractor: QuestionEffectInteractor
    ) {
        makeObservable(this);
    }

    public mount(): void {
        const requiredQuestions = this.questionSet.findQuestionsByExtensionType(
            QuestionExtensionType.PHOTO_REQUIREMENT
        );
        const photoTitleQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.VISUELE_OBJECT_REPRESENTATIE
        );
        //Also include the house group questions to trigger changes
        const houseQuestions = [
            ...this.questionSet.findQuestionsByType(RootGroupQuestionType.HOUSE_GROUP_COMPACT),
            ...this.questionSet.findQuestionsByType(RootGroupQuestionType.HOUSE_GROUP),
        ];

        const floorTypeQuestion = this.questionSet.findQuestionsByTechnicalReference(TechnicalReference.FLOOR_TYPE);

        if (photoTitleQuestion && requiredQuestions.length > 0) {
            const allChildQuestions = requiredQuestions.flatMap((parent) =>
                this.questionSet.flattenChildrenRecursively(parent.uuid)
            );
            const requiredQuestionsUuids = requiredQuestions.map((q) => q.uuid);
            const uuids = [
                ...requiredQuestionsUuids,
                ...houseQuestions.map((q) => q.uuid),
                ...floorTypeQuestion.map((q) => q.uuid),
                ...requiredQuestions.map((q) => q.parentUuid),
                ...allChildQuestions.map((q) => q.uuid),
            ].filter((uuid): uuid is string => uuid != null);

            this.subscriptions.add(
                this.answerController
                    .answersForQuestionUuidsStream(uuids)
                    .pipe(
                        switchMap(() => this.answerController.answersForQuestionUuidsStream(requiredQuestionsUuids)),
                        debounceTime(500),
                        switchMap((answers) => {
                            const a = this.answerController
                                .filterDeleted(answers)
                                .map((answer) =>
                                    this.questionEffectsInteractor
                                        .isHiddenStream(answer.questionUuid, answer.parentUuid)
                                        .pipe(map((isHidden) => ({isHidden, answer})))
                                );
                            return combineLatest(a).pipe(startWith([]));
                        }),
                        map((answers) => {
                            //Filter hidden & match questions to their answers
                            return answers
                                .filter((a) => a.isHidden === false)
                                .map((a) => a.answer)
                                .map((answer) => {
                                    return {
                                        question: this.questionSet.findQuestionByUuid(answer.questionUuid),
                                        answer: answer,
                                    };
                                });
                        }),
                        map((pairs) => {
                            //Count how many photos we need for a certain TechnicalReference
                            const countMap = new Map<TechnicalReference, number>();
                            for (const pair of pairs) {
                                if (pair.question) {
                                    for (const extension of pair.question.extensions) {
                                        if (extension.type === QuestionExtensionType.PHOTO_REQUIREMENT) {
                                            for (const exRef of extension.technicalReferences) {
                                                const count = this.getCheckedCount(pair.answer, pair.question);
                                                if (count > 0) {
                                                    countMap.set(exRef, (countMap.get(exRef) ?? 0) + count);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            return countMap;
                        }),
                        switchMap((countMap) => {
                            //Now fetch all the titles for photos (answer option)
                            return this.answerController.answersForQuestionUuidStream(photoTitleQuestion.uuid).pipe(
                                map((answers) => this.answerController.filterDeleted(answers)),
                                map((answers) =>
                                    answers.filter(
                                        (answer) =>
                                            !this.questionEffectsInteractor.isHidden(
                                                answer.questionUuid,
                                                answer.parentUuid
                                            )
                                    )
                                ),
                                map((answers) => ({
                                    photoTitleAnswers: answers,
                                    countMap: countMap,
                                }))
                            );
                        }),
                        map((data) => {
                            //Now that we have all the selected photo titles
                            //Count how many we have of each (the AnswerOptions their technicalReferences)
                            const selectedCountMap = new Map<TechnicalReference, number>();
                            for (const answer of data.photoTitleAnswers) {
                                const answerOption = photoTitleQuestion.answerOptions.find(
                                    (ao) => ao.id === answer.answerOptionId
                                );

                                if (answerOption) {
                                    for (const technicalReference of answerOption.technicalReference) {
                                        selectedCountMap.set(
                                            technicalReference,
                                            (selectedCountMap.get(technicalReference) ?? 0) + 1
                                        );
                                    }
                                }
                            }

                            return {
                                selectedCountMap: selectedCountMap,
                                countMap: data.countMap,
                            };
                        }),
                        map((data) => {
                            //Compare the required amount we need vs the amount currently selected
                            //If we dont have enough selected of a certain technicalReference,
                            //put the amount we are missin in the map
                            const missingAmounts = new Map<TechnicalReference, MissingEntry>();
                            for (const [technicalReference, requiredAmount] of data.countMap) {
                                const selected = data.selectedCountMap.get(technicalReference);
                                if (selected === undefined) {
                                    missingAmounts.set(technicalReference, {
                                        amountMissing: requiredAmount,
                                        amountCurrently: 0,
                                        amountRequired: requiredAmount,
                                        isCompleted: false,
                                    });
                                } else {
                                    const missingAmount = requiredAmount - selected;
                                    missingAmounts.set(technicalReference, {
                                        amountMissing: missingAmount,
                                        amountCurrently: selected,
                                        amountRequired: requiredAmount,
                                        isCompleted: missingAmount <= 0,
                                    });
                                }
                            }
                            return missingAmounts;
                        }),
                        switchMap((missingAmounts: Map<TechnicalReference, MissingEntry>) =>
                            this.userSettingsInteractor.getSettingsStream().pipe(
                                map((settings) => {
                                    const disabledRemoved = new Map(missingAmounts);
                                    for (const disabledPhotoRequirement of settings.disabledPhotoRequirements ?? []) {
                                        const existingEntry = disabledRemoved.get(disabledPhotoRequirement);
                                        if (existingEntry) {
                                            disabledRemoved.set(disabledPhotoRequirement, {
                                                ...existingEntry,
                                                isCompleted: true,
                                            });
                                        }
                                    }
                                    return disabledRemoved;
                                })
                            )
                        )
                    )
                    .subscribe((missingPhotoLabels) => {
                        runInAction(() => {
                            this.missingPhotoLabels = missingPhotoLabels.size === 0 ? null : missingPhotoLabels;
                        });
                    })
            );
        }
    }

    private getCheckedCount(answer: Answer, question: Question): number {
        if (question.type === NormalQuestionType.BOOLEAN) {
            return answer.contents === '1' ? 1 : 0;
        }
        if (isMcType(question.type)) {
            if (answer.answerOptionId) {
                const answerOption = question.answerOptions.find((ao) => ao.id === answer.answerOptionId);
                return answerOption?.contents
                    ? parseInt(answerOption?.contents.match(/\d+/)?.toString() ?? '1', 10)
                    : 1;
            }
            return 0;
        }
        return 1;
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    public setIsChecklistOpen = (isOpen: boolean) => {
        this.isChecklistOpen = isOpen;
    };

    public toggleOverride = (technicalReference: TechnicalReference, override: boolean) => {
        const settings = this.userSettingsInteractor.getSettings();
        const newOverride = settings.disabledPhotoRequirements.filter((tr) => tr !== technicalReference);
        if (override === true) {
            newOverride.push(technicalReference);
        }

        this.userSettingsInteractor.setSettings({disabledPhotoRequirements: newOverride});
    };
}
