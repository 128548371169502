export interface ReferenceSubscriptions {
    subscriptions: ReferenceSubscription[];
    promotions: ReferenceSubscriptionPromotion[];
    rates: {
        referenceSalefigure: number;
    };
}

export interface ReferenceSubscription {
    type: ReferenceSubscriptionType;
    name: string;
    enabled: boolean;
    available: boolean;
    usedForAppraisal: boolean;
    priceCents: number;
    originalPriceCents?: number;
}

export enum ReferenceSubscriptionType {
    ORTEC = 'ortec_references',
    BRAINBAY = 'brainbay_references',
    ALTUM = 'altum_references',
}

export type ReferenceSubscriptionPromotion = BrainbayReferenceSubscriptionPromotion;

export interface BrainbayReferenceSubscriptionPromotion {
    promotion:
        | ReferenceSubscriptionPromotionType.BRAINBAY_PROMOTION
        | ReferenceSubscriptionPromotionType.BRAINBAY_TRIAL;
    brainbayUsageCount: number;
    brainbayPromotionEndDate: string;
    brainbayAuthenticated: boolean;
}

export enum ReferenceSubscriptionPromotionType {
    BRAINBAY_TRIAL = 'brainbay-trial',
    BRAINBAY_PROMOTION = 'brainbay-promotion',
}
