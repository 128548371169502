import {SetType} from '../../../../../../../../models/reference_set/set_type';
import {ValidationInstituteReferentieObject} from '../../../../../../../../models/validation_institute_reference_object';
import {ReferenceObject} from '../models/reference_object';

export type PriceRangeType = [from: number, till: number];

export function getPriceOrPriceRange(priceOrPriceRange: string | number): number | PriceRangeType {
    if (typeof priceOrPriceRange === 'string') {
        if (priceOrPriceRange) {
            const [from, till] = priceOrPriceRange.split(' - ');

            if (!till) {
                return parseInt(from);
            }

            const rangePerM2: PriceRangeType = [parseInt(from), parseInt(till)];

            if (rangePerM2[0] === rangePerM2[1]) {
                return rangePerM2[0];
            }

            return rangePerM2;
        } else {
            return parseInt(priceOrPriceRange);
        }
    } else {
        return priceOrPriceRange;
    }
}

export function getPriceOrPriceRangeFloorArea(
    priceOrPriceRangeStr: string | number,
    floorArea: number
): number | PriceRangeType {
    const priceOrPriceRange = getPriceOrPriceRange(priceOrPriceRangeStr);

    if (Array.isArray(priceOrPriceRange)) {
        const [from, till] = priceOrPriceRange;
        return [Math.round(from / floorArea), Math.round(till / floorArea)];
    }

    return Math.round(priceOrPriceRange / floorArea);
}

export function getObjectPrice(setType: SetType, object: ValidationInstituteReferentieObject | null): number | null {
    return getObjectIndexedPrice(setType, object) ?? getObjectUnindexedPrice(setType, object);
}

export function getObjectIndexedPrice(
    setType: SetType,
    object: ValidationInstituteReferentieObject | null
): number | null {
    if (object === null) {
        return null;
    }
    switch (setType) {
        case SetType.SOLD: {
            return object.gecorrigeerdeVerkoopprijs ?? null;
        }
        case SetType.RENT: {
            return object.gecorrigeerdeHuurprijsPerMaand ?? null;
        }
        case SetType.SALE: {
            return object.vraagprijs ?? null;
        }
        default:
            return null;
    }
}

export function getObjectUnindexedPrice(
    setType: SetType,
    object: ValidationInstituteReferentieObject | null
): number | null {
    if (object === null) {
        return null;
    }
    switch (setType) {
        case SetType.SOLD: {
            return object.verkoopprijs ?? null;
        }
        case SetType.RENT: {
            return object.aanvangsHuurprijsPerMaand ?? null;
        }
        case SetType.SALE: {
            return object.oorspronkelijkeVraagprijs ?? null;
        }
        default:
        case SetType.NONE: {
            return null;
        }
    }
}

export function getReferenceObjectPrice(setType: SetType, object: ReferenceObject | null): string | null {
    return getReferenceIndexedObjectPrice(setType, object) ?? getReferenceUnindexedObjectPrice(setType, object);
}

export function getReferenceIndexedObjectPrice(setType: SetType, object: ReferenceObject | null): string | null {
    if (object === null) {
        return null;
    }
    if (setType === SetType.RENT) {
        return null;
    }
    return object.indexedPriceRange ?? null;
}

export function getReferenceUnindexedObjectPrice(setType: SetType, object: ReferenceObject | null): string | null {
    if (object === null) {
        return null;
    }
    if (setType === SetType.RENT) {
        return null;
    }
    return object.priceRange ?? null;
}
