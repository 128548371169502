import PhotoSwipeLightbox from 'photoswipe/lightbox';
import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {PhotoSwipeEventsMap} from 'photoswipe';
import {action, makeObservable, observable, runInAction} from 'mobx';
import {AttachmentUploadInteractor} from '../../../../../../../../../business/attachments/attachment_upload_interactor';
import {fetchReferenceObjectPhoto} from '../../questions/reference_object_photo/internal/fetch_reference_object_photo';
import {FlashMessageBroadcaster, Type} from '../../../../../../../../../business/flash_message_broadcaster';
import {AnswerController} from '../../../../../../../../../business/answering/answer_controller';

export class ChoosePhotoButtonPresenter implements Presenter {
    @observable public hidden = false;
    @observable public busy = false;
    @observable public activeIndex: number | null = null;
    @observable public currentImageUrl: string | null = null;

    constructor(
        private attachmentUploadInteractor: AttachmentUploadInteractor,
        private flashMessageBroadcaster: FlashMessageBroadcaster,
        private answerController: AnswerController,
        private photoAnswerUuid: string,
        private lightbox?: PhotoSwipeLightbox
    ) {
        makeObservable(this);
    }

    public mount() {
        if (this.lightbox) {
            this.lightbox.on('beforeZoomTo', this.beforeZoomTo);
            this.lightbox.on('zoomPanUpdate', this.zoomPanUpdate);
            this.lightbox.on('close', this.close);
            this.lightbox.on('slideActivate', this.slideActivate);

            if (this.lightbox.pswp?.currSlide) {
                const index = this.lightbox.pswp.currIndex;
                runInAction(() => {
                    this.activeIndex = index;
                });
            }
        }

        runInAction(() => {
            const answer = this.answerController.byUuid(this.photoAnswerUuid);
            if (answer?.file) {
                this.currentImageUrl = answer.file.url;
            }
        });
    }

    public unmount() {
        if (this.lightbox) {
            this.lightbox.off('beforeZoomTo', this.beforeZoomTo);
            this.lightbox.off('zoomPanUpdate', this.zoomPanUpdate);
            this.lightbox.off('close', this.close);
            this.lightbox.off('slideActivate', this.slideActivate);
        }
    }

    @action
    private beforeZoomTo = (ev: PhotoSwipeEventsMap['beforeZoomTo']) => {
        if (this.lightbox?.pswp?.currSlide) {
            this.hidden = ev.destZoomLevel > this.lightbox.pswp.currSlide.zoomLevels.initial;
        }
    };

    @action
    private zoomPanUpdate = (ev: PhotoSwipeEventsMap['zoomPanUpdate']) => {
        this.hidden = ev.slide.currZoomLevel > ev.slide.zoomLevels.initial;
    };

    @action
    private close = () => {
        this.activeIndex = null;
    };

    @action
    private slideActivate = () => {
        if (this.lightbox?.pswp) {
            this.activeIndex = this.lightbox.pswp.currIndex;
        }
    };

    public async pickCurrentPhoto(url: string) {
        runInAction(() => {
            this.busy = true;
        });

        try {
            const file = await fetchReferenceObjectPhoto(url);
            if (!file) {
                this.flashMessageBroadcaster.broadcast('Het kiezen van de foto is mislukt.', Type.Danger);
                return;
            }

            const result = await this.attachmentUploadInteractor.uploadForAnswer(this.photoAnswerUuid, file, {
                fileTypes: ['.png', '.jpg', '.jpeg', '.jfif'],
            });

            if (!result.succeeded) {
                this.flashMessageBroadcaster.broadcast(
                    result.message ?? 'Het kiezen van de foto is mislukt.',
                    Type.Danger
                );
            } else {
                this.currentImageUrl = url;
                this.flashMessageBroadcaster.broadcast('De foto voor de referentie is opgeslagen.', Type.Success);
            }
        } finally {
            runInAction(() => {
                this.busy = false;
            });
        }
    }
}
