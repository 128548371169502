import {makeObservable} from 'mobx';

import {SimpleQuestionPresenter} from './simple_question_presenter';
import {QuestionExtensionType} from '../../../../../enum/question_extension_type';
import {WidgetOpenerType} from '../../../../../enum/widget_opener_type';
import {AttachmentQuestionsInteractor} from '../../../../../business/attachment_questions_interactor';
import {TechnicalReference} from '../../../../../enum/technical_reference';
import {WidgetOpenerQuestionExtension} from '../../../../../models/question_extension';
import {getNewestAnswer} from '../../../../../../support/get_newest_answer';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';

export class GroupPesenter extends SimpleQuestionPresenter {
    private subscriptions = new CompositeSubscription();

    constructor(
        private attachmentQuestionsInteractor: AttachmentQuestionsInteractor,
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public mount() {
        super.mount();

        const widgetOpener = this.question.extensions.find((e) => e.type === QuestionExtensionType.WIDGET_OPENER);
        if (widgetOpener) {
            this.openWidget((widgetOpener as WidgetOpenerQuestionExtension).openerType);
        }
    }

    public unmount() {
        this.subscriptions.clear();
        super.unmount();
    }

    private openWidget(openerType: WidgetOpenerType) {
        if (openerType === WidgetOpenerType.ATTACHMENT_TRANSITIEVISIE_WARMTE) {
            const tvwQuestion = this.questionSet.findQuestionByTechnicalReference(
                TechnicalReference.ATTACHMENT_TRANSITIEVISIE_WARMTE
            );
            if (!tvwQuestion) {
                return;
            }

            const answer = getNewestAnswer(this.answerController.answersForQuestionUuid(tvwQuestion.uuid));
            if (!answer || answer.isDeleted || !answer.file) {
                return;
            }

            const modalInfo = {
                displayName: answer.file.originalFilename,
                url: answer.file.url,
            };

            this.subscriptions.add(this.attachmentQuestionsInteractor.showAttachmentModal(modalInfo));
        }
    }
}
