import {observer} from 'mobx-react';
import * as React from 'react';
import {Loader} from '../../../appraising/appraise/ui/components/loader';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {DecidePresenter} from './decide_presenter';
import {ReasoningInput} from './reasoning_input';

export const Decide = observer(({questionSetId}: {questionSetId: number}) => {
    const presenter = usePresenter(
        (container) =>
            new DecidePresenter(questionSetId, container.business.appraisalProvider, container.network.questionSetApi)
    );

    if (!presenter.questionSet || !presenter.appraisal) {
        return (
            <div className="empty-message">
                <Loader />
                <h3>Aan het laden...</h3>
            </div>
        );
    }

    return (
        <>
            <ReasoningInput
                questionSet={presenter.questionSet}
                appraisal={presenter.appraisal}
                onChange={presenter.onReasoningChange}
                showRequiredError={presenter.showReasoningRequiredError}
            />
            <footer className="d-flex justify-content-end">
                <div className="row">
                    <div className="col-6">
                        <button className="btn btn-secondary full ion-md-close" onClick={presenter.onReject}>
                            Afwijzen
                        </button>
                    </div>
                    <div className="col-6">
                        <button className="btn btn-primary full ion-md-checkmark" onClick={presenter.onAccept}>
                            Accepteren
                        </button>
                    </div>
                </div>
            </footer>
        </>
    );
});
