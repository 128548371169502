import {ControllerAppraiser} from '../../appraising/models/appraisal';
import {
    ApiControllerAppraiser,
    apiControllerAppraiserToControllerAppraiser,
} from '../../appraising/network/models/api_appraisal';
import {AjaxDriver} from '../../network/driver/ajax_driver';

export class ControllerAppraiserApi {
    constructor(private ajaxDriver: AjaxDriver) {}
    public requestControllerAppraiserData(email: string): Promise<ControllerAppraiser> {
        return new Promise<ControllerAppraiser>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/controller-appraiser/data`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        email: email,
                    }),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.status === 400) {
                        const data: {error?: string} = await result.json();
                        if (data && data.error) {
                            reject(data.error);
                        }
                    }
                    if (result.ok) {
                        const apiController: ApiControllerAppraiser = await result.json();
                        resolve(apiControllerAppraiserToControllerAppraiser(apiController));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
