import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {errorMessage, guard, LoadingStateType} from '../../../support/presenter/helpers';
import {Presenter} from '../../../support/presenter/presenter';
import {FlashMessageBroadcaster, Type} from '../../business/flash_message_broadcaster';
import {PagePartsSet} from '../../models/page_parts_set';
import {PagePartConfigurationType} from '../../models/page_part_configuration';
import {PagePartsSetInteractor} from '../../business/page_parts/page_parts_set_interactor';

export interface NewConfigurationModalState {
    isOpen: boolean;
    name: string;
    description: string;
    isShared: boolean;
}

export class ConfigListTabPresenter implements Presenter {
    @observable.ref public pagePartsSets: PagePartsSet[] | null = null;
    @observable public newConfigurationModalState: NewConfigurationModalState = {
        isOpen: false,
        name: '',
        description: '',
        isShared: false,
    };
    @observable public loadingState: LoadingStateType = LoadingStateType.LOADING;

    @computed
    public get visiblePagePartsSets() {
        return this.pagePartsSets?.filter((set) => set.config.isVisible === true) ?? [];
    }

    @computed
    public get hiddenPagePartsSets() {
        return this.pagePartsSets?.filter((set) => set.config.isVisible === false) ?? [];
    }

    @computed
    public get visibleFullSetsCount() {
        return (
            this.pagePartsSets?.filter(
                (set) => set.config.isVisible && set.config.type === PagePartConfigurationType.SYSTEM_COMPLETE
            )?.length ?? 0
        );
    }

    private subscriptions = new CompositeSubscription();

    constructor(
        private pagePartsSetsInteractor: PagePartsSetInteractor,
        private flashMessageBroadcaster: FlashMessageBroadcaster
    ) {
        makeObservable(this);
    }

    public async mount() {
        this.subscriptions.add(
            this.pagePartsSetsInteractor.pagePartsSetsStream().subscribe((pagePartsSets) => {
                runInAction(() => {
                    this.pagePartsSets = pagePartsSets.filter(
                        (set) => set.config.type !== PagePartConfigurationType.SYSTEM_DETAIL
                    );
                    this.loadingState = LoadingStateType.IDLE;
                });
            })
        );
    }

    public async unmount() {
        this.subscriptions.clear();
    }

    public hidePagePartsSet = async (pagePartsSet: PagePartsSet) => {
        if (pagePartsSet.config.type === PagePartConfigurationType.SYSTEM_COMPLETE) {
            const otherCompleteConfigs =
                this.pagePartsSets?.filter(
                    (set) =>
                        set.config.isVisible &&
                        set.config.type === PagePartConfigurationType.SYSTEM_COMPLETE &&
                        set.id !== pagePartsSet.id
                ) ?? [];
            if (otherCompleteConfigs.length === 0) {
                this.flashMessageBroadcaster.broadcast(
                    'Er moet minstens 1 actieve door taXapi aangeleverde configuratie actief zijn.',
                    Type.Danger
                );
                return;
            }
        }

        try {
            await guard(this, () => this.pagePartsSetsInteractor.setPagePartsSetVisibility(pagePartsSet, false));
        } catch (error) {
            this.flashMessageBroadcaster.broadcast(
                errorMessage(error) ?? 'Er is iets mis gegaan. Probeer het opnieuw.',
                Type.Danger
            );
        }
    };

    public showPagePartsSet = async (pagePartsSet: PagePartsSet) => {
        try {
            await guard(this, () => this.pagePartsSetsInteractor.setPagePartsSetVisibility(pagePartsSet, true));
        } catch (error) {
            this.flashMessageBroadcaster.broadcast(
                errorMessage(error) ?? 'Er is iets mis gegaan. Probeer het opnieuw.',
                Type.Danger
            );
        }
    };

    public duplicatePagePartsSet = async (pagePartsSet: PagePartsSet) => {
        try {
            const currentRanks =
                this.pagePartsSets?.map((set) => set.config.rank).filter((rank): rank is number => rank !== null) ?? [];
            const newRank = Math.max(0, ...currentRanks) + 1;
            await guard(this, () => this.pagePartsSetsInteractor.duplicateSet(pagePartsSet, {rank: newRank}));
        } catch (error) {
            this.flashMessageBroadcaster.broadcast(
                errorMessage(error) ?? 'Er is iets mis gegaan. Probeer het opnieuw.',
                Type.Danger
            );
        }
    };

    public deletePagePartsSet = async (pagePartsSet: PagePartsSet) => {
        if (confirm(`Weet je zeker dat je "${pagePartsSet.name}" wil verwijderen?`)) {
            try {
                await guard(this, () => this.pagePartsSetsInteractor.deleteSet(pagePartsSet));
            } catch (error) {
                this.flashMessageBroadcaster.broadcast(
                    errorMessage(error) ?? 'Er is iets mis gegaan. Probeer het opnieuw.',
                    Type.Danger
                );
            }
        }
    };
    public stopSharingPagePartsSet = async (pagePartsSet: PagePartsSet) => {
        if (
            confirm(
                `Weet je zeker dat je wil stoppen met het delen van "${pagePartsSet.name}"? Je collega's kunnen dan geen gebruik meer maken van deze configuratie.`
            )
        ) {
            try {
                await guard(this, () => this.pagePartsSetsInteractor.setPagePartsSetSharing(pagePartsSet, false));
            } catch (error) {
                this.flashMessageBroadcaster.broadcast(
                    errorMessage(error) ?? 'Er is iets mis gegaan. Probeer het opnieuw.',
                    Type.Danger
                );
            }
        }
    };

    public sharePagePartsSet = async (pagePartsSet: PagePartsSet) => {
        try {
            await guard(this, () => this.pagePartsSetsInteractor.setPagePartsSetSharing(pagePartsSet, true));
        } catch (error) {
            this.flashMessageBroadcaster.broadcast(
                errorMessage(error) ?? 'Er is iets mis gegaan. Probeer het opnieuw.',
                Type.Danger
            );
        }
    };

    @action
    public setIsAddConfigurationModalOpen = (isOpen: boolean) => {
        this.newConfigurationModalState.isOpen = isOpen;
    };

    @action
    public onNewConfigurationNameChange = (value: string) => {
        this.newConfigurationModalState.name = value;
    };

    @action
    public onNewConfigurationDescriptionChange = (value: string) => {
        this.newConfigurationModalState.description = value;
    };

    @action
    public onNewConfigurationIsSharedChange = (isShared: boolean) => {
        this.newConfigurationModalState.isShared = isShared;
    };

    public createnNewConfiguration = async () => {
        try {
            const newConfig = await guard(this, () =>
                this.pagePartsSetsInteractor.createNewSet(
                    this.pagePartsSets,
                    this.newConfigurationModalState.name,
                    this.newConfigurationModalState.description,
                    this.newConfigurationModalState.isShared,
                    Math.max(...(this.pagePartsSets?.map((pps) => pps.config.rank) ?? [0])) + 1
                )
            );
            runInAction(() => {
                this.newConfigurationModalState = {
                    isOpen: false,
                    name: '',
                    description: '',
                    isShared: false,
                };
            });
            window.location.href = `#/page-parts-configuration/${newConfig.id}/${newConfig.pageParts[0]?.uuid ?? ''}`;
        } catch (error) {
            console.error(error);
            this.flashMessageBroadcaster.broadcast(
                errorMessage(error) ?? 'Er is iets mis gegaan. Probeer het opnieuw.',
                Type.Danger
            );
        }
    };

    public updateConfigRank = async (newIndex: number, configId: number) => {
        try {
            await guard(this, () =>
                this.pagePartsSetsInteractor.updateSetsRanks(this.pagePartsSets ?? [], [{id: configId, rank: newIndex}])
            );
        } catch (error) {
            this.flashMessageBroadcaster.broadcast(
                errorMessage(error) ?? 'Er is iets mis gegaan. Probeer het opnieuw.',
                Type.Danger
            );
        }
    };
}
