import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from '../../../../../simple/simple_question_presenter';

import {ForcedSaleFilterRangesQuestionPresenter} from './forced_sale_filter_ranges_question_presenter';
import {observer} from 'mobx-react';
import {Slider} from '../../../../../../../components/slider';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const ForcedSaleFilterRangesQuestion: React.FC<OwnProps> = observer(function ForcedSaleFilterRangesQuestion(
    props
) {
    const presenter = usePresenter(
        (containter) =>
            new ForcedSaleFilterRangesQuestionPresenter(
                ...simpleQuestionPresenterConstructorParametersFactory(props, containter)
            )
    );

    if (presenter.loading) {
        return (
            <div className="empty-message appraise">
                <h3>Aan het laden</h3>
                <div className="loader">
                    <span>&nbsp;</span>
                </div>
            </div>
        );
    }

    function getBuildYearMarks() {
        const marks: {[key: number]: React.ReactNode | string} = {
            1949: '<1950',
        };

        const currentYear = new Date().getFullYear();
        for (let i = 1950; i < currentYear; i++) {
            if (i <= 2000 && i % 10 === 0) {
                marks[i] = <></>;
            } else if (i > 2000 && i < currentYear - 10 && i % 5 === 0) {
                marks[i] = <></>;
            } else if (i >= currentYear - 10) {
                marks[i] = <></>;
            }
        }

        return marks;
    }

    function getAskingPriceMarks() {
        const marks: {[key: number]: React.ReactNode | string} = {
            50_000: '€ 50.000',
        };

        for (let i = 75_000; i < 400_000; i += 25_000) {
            marks[i] = <></>;
        }

        for (let i = 400_000; i < 800_000; i += 50_000) {
            marks[i] = <></>;
        }

        for (let i = 800_000; i <= 1_000_000; i += 100_000) {
            marks[i] = <></>;
        }

        marks[1250000] = <></>;

        for (let i = 1_500_000; i <= 5_000_000; i += 500_000) {
            marks[i] = <></>;
        }

        marks[5_000_001] = '> € 5.000.000';

        return marks;
    }

    function getSurfaceAreaMarks() {
        const marks: {[key: number]: React.ReactNode | string} = {
            0: '0 m²',
        };

        for (let i = 25; i < 100; i += 25) {
            marks[i] = <></>;
        }

        for (let i = 100; i <= 500; i += 50) {
            marks[i] = <></>;
        }

        marks[501] = '> 500 m²';

        return marks;
    }

    function getPlotAreaMarks() {
        const marks: {[key: number]: React.ReactNode | string} = {
            0: '0 m²',
        };

        for (let i = 50; i < 300; i += 50) {
            marks[i] = <></>;
        }

        for (let i = 300; i < 500; i += 100) {
            marks[i] = <></>;
        }

        for (let i = 500; i < 3_000; i += 500) {
            marks[i] = <></>;
        }

        for (let i = 1000; i <= 10_000; i += 1_000) {
            marks[i] = <></>;
        }

        marks[10_001] = '> 10.000 m²';

        return marks;
    }

    return (
        <div className="row">
            <div className="col-12 col-md-6 pb-3">
                <h3>Afstand tot het adres</h3>
                <div className="col-12">
                    <Slider
                        range
                        handleRender={(origin, props) =>
                            React.cloneElement<React.HTMLAttributes<HTMLDivElement>>(origin, {
                                children: `${props.value / 1000} km`,
                            })
                        }
                        defaultValue={presenter.ranges['distance'] as [number, number]}
                        onAfterChange={(val) => presenter.onRangeChange('distance', val as [number, number])}
                        min={0}
                        max={50000}
                        step={1000}
                        marks={{
                            0: '0 km',
                            50000: '+50 km',
                        }}
                    />
                </div>
            </div>
            <div className="col-12 col-md-6 pb-3">
                <h3>Vraagprijs</h3>
                <div className="col-12">
                    <Slider
                        range
                        handleRender={(origin, props) =>
                            React.cloneElement<React.HTMLAttributes<HTMLDivElement>>(origin, {
                                children:
                                    props.value === 5_000_001
                                        ? `>€5M`
                                        : props.value >= 1_000_000
                                        ? `€${props.value / 1_000_000}M`
                                        : `€${props.value / 1000}K`,
                            })
                        }
                        defaultValue={presenter.ranges['askingPrice'] as [number, number]}
                        onAfterChange={(val) => presenter.onRangeChange('askingPrice', val as [number, number])}
                        step={null}
                        min={50_000}
                        max={5_000_001}
                        marks={getAskingPriceMarks()}
                    />
                </div>
            </div>
            <div className="col-12 col-md-6 pb-3">
                <h3>Gebruiksoppervlakte wonen</h3>
                <div className="col-12">
                    <Slider
                        range
                        handleRender={(origin, props) =>
                            React.cloneElement<React.HTMLAttributes<HTMLDivElement>>(origin, {
                                children: props.value === 501 ? `>500m²` : `${props.value}m²`,
                            })
                        }
                        step={null}
                        defaultValue={presenter.ranges['surfaceArea'] as [number, number]}
                        onAfterChange={(val) => presenter.onRangeChange('surfaceArea', val as [number, number])}
                        min={0}
                        max={501}
                        marks={getSurfaceAreaMarks()}
                    />
                </div>
            </div>
            <div className="col-12 col-md-6 pb-3">
                <h3>Perceeloppervlakte</h3>
                <div className="col-12">
                    <Slider
                        range
                        handleRender={(origin, props) =>
                            React.cloneElement<React.HTMLAttributes<HTMLDivElement>>(origin, {
                                children:
                                    props.value === 10_001
                                        ? '>10km²'
                                        : props.value >= 1000
                                        ? `${props.value / 1000}km²`
                                        : `${props.value}m²`,
                            })
                        }
                        defaultValue={presenter.ranges['plotArea'] as [number, number]}
                        onAfterChange={(val) => presenter.onRangeChange('plotArea', val as [number, number])}
                        step={null}
                        min={0}
                        max={10_001}
                        marks={getPlotAreaMarks()}
                    />
                </div>
            </div>
            <div className="col-12 col-md-6 pb-3">
                <h3>Bouwjaar</h3>
                <div className="col-12">
                    <Slider
                        range
                        handleRender={(origin, props) =>
                            React.cloneElement<React.HTMLAttributes<HTMLDivElement>>(origin, {
                                children: props.value === 1949 ? '<1950' : props.value,
                            })
                        }
                        defaultValue={presenter.ranges['buildYear'] as [number, number]}
                        onAfterChange={(val) => presenter.onRangeChange('buildYear', val as [number, number])}
                        min={1949}
                        max={new Date().getFullYear()}
                        step={null}
                        marks={getBuildYearMarks()}
                    />
                </div>
            </div>
            <div className="col-12 col-md-6 pb-3">
                <h3>Dagen in verkoop</h3>
                <div className="col-12">
                    <Slider
                        range
                        handleRender={(origin, props) =>
                            React.cloneElement<React.HTMLAttributes<HTMLDivElement>>(origin, {
                                children: props.value,
                            })
                        }
                        defaultValue={presenter.ranges['daysForSale'] as [number, number]}
                        onAfterChange={(val) => presenter.onRangeChange('daysForSale', val as [number, number])}
                        min={0}
                        max={30}
                        marks={{
                            0: '0 dagen',
                            30: '30 dagen',
                        }}
                    />
                </div>
            </div>
        </div>
    );
});
