import * as React from 'react';

import {ReferenceSale, sourceToClassName} from '../models/reference_sale';

import {Appraisal} from '../../../../../../../../models/appraisal';
import {AreaConclusion} from '../../../../../../../../models/area_conclusion';
import {EnergyConclusion} from '../../../../../../../../models/energy_conclusion';
import {FloorAreaBadgeAdapter} from '../badges/floor_area_badge_adapter';
import {FloorAreaPrice} from '../components/floor_area_price';
import {PriceRange} from '../components/price_range';
import {QuestionSet} from '../../../../../../../../models/question_set';
import ReferenceObjectAddress from '../components/reference_object_address';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {ReferenceObjectTilePresenter} from './reference_object_tile_presenter';
import {StatusDetailListForBuildYear} from '../../../../../../../../components/status_details_list_for_build_year';
import {StatusDetailListForEnergyLabel} from '../../../../../../../../components/status_details_list_for_energy_label';
import {StatusDetailListForReferenceValue} from '../../../../../../../../components/status_details_list_for_reference_value';
import {StatusDetailListForStatus} from '../../../../../../../../components/status_details_list_for_status';
import {VolumeBadgeAdapter} from '../badges/volume_badge_adapter';
import {classNames} from '../../../../../../../../../support/classnames';
import {format} from 'date-fns';
import {isEmpty} from '../../../../../../../../../support/util';
import {observer} from 'mobx-react';
import {referenceSaleImageUrlPairs} from '../internal/reference_sale_image_urls';
import {usePresenter} from '../../../../../../../../../support/presenter/use_presenter';
import {ImageViewer} from '../../../../../../../../components/image_viewer/image_viewer';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    referenceSale: ReferenceSale;
    referenceObjectAnswer: ReferenceObjectAnswer | null;
    index: number;
    areaConclusion: AreaConclusion | null;
    energyConclusion: EnergyConclusion | null;
    buildYear: number | null;
    valuation: string | null;
    isFrozen: boolean;
    isMapHovering: boolean;
    isMapSelected: boolean;
    isCompare: boolean;
    canAdd: boolean;
    onAdd: (referenceSale: ReferenceSale, referenceObjectAnswer: ReferenceObjectAnswer) => void;
    onRemove: (referenceObjectAnswer: ReferenceObjectAnswer) => void;
    onHoverChange: (referenceSale: ReferenceSale | null) => void;
    onClickChange: (referenceSale: ReferenceSale | null) => void;
    showAnswerModal: (referenceSale: ReferenceSale, referenceObjectAnswer: ReferenceObjectAnswer) => void;
    showDetailsModal: (referenceSale: ReferenceSale) => void;
}

export const ReferenceObjectTile: React.FC<OwnProps> = observer(function ReferenceObjectTile(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectTilePresenter(
                props.referenceSale,
                props.onAdd,
                container.business.referenceObjectProvider(props.appraisal, props.questionSet)
            )
    );

    const {referenceSale, referenceObjectAnswer} = props;
    const imageUrlPairs = referenceSaleImageUrlPairs(referenceSale, referenceObjectAnswer);

    let source = referenceSale.source;
    if (
        referenceObjectAnswer !== null &&
        referenceObjectAnswer.referenceObject !== null &&
        referenceObjectAnswer.referenceObject.bronGegevens
    ) {
        source = referenceObjectAnswer.referenceObject.bronGegevens;
    }

    // Slick slider refreshes when parent key changes.
    const sliderKey = JSON.stringify(imageUrlPairs);

    function renderButtons() {
        if (presenter.isAdding) {
            return <a className="ion-md-circle">Ophalen...</a>;
        }

        const referenceObjectAnswer = props.referenceObjectAnswer;
        return referenceObjectAnswer !== null ? (
            <>
                <a
                    className="ion-md-trash"
                    onClick={() => confirm('Weet je het zeker?') && props.onRemove(referenceObjectAnswer)}
                >
                    Verwijder
                </a>
                <a
                    className="ion-md-create"
                    onClick={() => props.showAnswerModal(props.referenceSale, referenceObjectAnswer)}
                >
                    Bewerk
                </a>
            </>
        ) : (
            <>
                <a className="ion-md-information-circle" onClick={() => props.showDetailsModal(props.referenceSale)}>
                    Details
                </a>
                <a
                    className={classNames('ion-md-add-circle', {disabled: !props.canAdd})}
                    aria-disabled={!props.canAdd}
                    onClick={() => props.canAdd && presenter.onAdd()}
                >
                    Voeg toe
                </a>
            </>
        );
    }

    function renderCompare(referenceObjectAnswer: ReferenceObjectAnswer) {
        return (
            <>
                <div className="col-6 text-left">
                    <dl>
                        <dt>Koopsom</dt>
                        <dd>
                            {referenceObjectAnswer.referenceObject.verkoopprijs !== null
                                ? `€ ${referenceObjectAnswer.referenceObject.verkoopprijs.toLocaleString('nl-NL')}`
                                : '-'}
                        </dd>
                    </dl>
                </div>
                <div className="col-6 text-right">
                    <dl>
                        <dt>Gecorr. koopsom</dt>
                        <dd>
                            {referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs !== null
                                ? `€ ${referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs.toLocaleString(
                                      'nl-NL'
                                  )}`
                                : '-'}
                        </dd>
                    </dl>
                </div>
                <div className="col-12">
                    <dl>
                        <dt>Verkoopdatum</dt>
                        <dd>
                            {referenceObjectAnswer.referenceObject.verkoopdatum !== null &&
                            !isEmpty(referenceObjectAnswer.referenceObject.verkoopdatum)
                                ? format(new Date(referenceObjectAnswer.referenceObject.verkoopdatum), 'dd-MM-yyyy')
                                : '-'}
                        </dd>
                    </dl>
                </div>
                <div className="col-12">
                    <StatusDetailListForStatus
                        label="Woningtype"
                        value={referenceObjectAnswer.referenceObject.woningType}
                        status={referenceObjectAnswer.referenceObject.woningTypeStatus}
                    />
                    <StatusDetailListForBuildYear
                        label="Bouwjaar"
                        value={referenceObjectAnswer.referenceObject.bouwjaar}
                        referenceValue={props.buildYear}
                    />
                    <StatusDetailListForReferenceValue
                        label="Gebruiksoppervlakte wonen"
                        value={
                            referenceObjectAnswer.referenceObject.gebruiksOppervlakte
                                ? referenceObjectAnswer.referenceObject.gebruiksOppervlakte
                                : null
                        }
                        referenceValue={
                            props.areaConclusion?.gebruiksoppervlakteWonen
                                ? props.areaConclusion?.gebruiksoppervlakteWonen
                                : null
                        }
                        unit="m²"
                    />
                    <StatusDetailListForReferenceValue
                        label="Perceeloppervlakte"
                        value={
                            referenceObjectAnswer.referenceObject.perceelOppervlakte
                                ? referenceObjectAnswer.referenceObject.perceelOppervlakte
                                : null
                        }
                        referenceValue={
                            props.areaConclusion?.perceelOppervlakte ? props.areaConclusion?.perceelOppervlakte : null
                        }
                        unit="m²"
                    />
                    <StatusDetailListForReferenceValue
                        label="Inhoud"
                        value={
                            referenceObjectAnswer.referenceObject.inhoud
                                ? referenceObjectAnswer.referenceObject.inhoud
                                : null
                        }
                        referenceValue={props.areaConclusion?.brutoInhoud ? props.areaConclusion?.brutoInhoud : null}
                        unit="m³"
                    />
                    <StatusDetailListForEnergyLabel
                        label="Energielabel"
                        value={referenceObjectAnswer.referenceObject.energielabel}
                        referenceValue={props.energyConclusion?.label ? props.energyConclusion?.label : null}
                    />
                    <StatusDetailListForStatus
                        label="Onderhoudssituatie"
                        value={referenceObjectAnswer.referenceObject.onderhoudsSituatieUitleg}
                        status={referenceObjectAnswer.referenceObject.onderhoudsSituatieStatus}
                        useSmallText={true}
                    />
                    {!isEmpty(referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus) &&
                        referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus !== undefined &&
                        referenceObjectAnswer.referenceObject.luxeDoelmatigheidUitleg !== undefined && (
                            <StatusDetailListForStatus
                                label="Mate van luxe en doelmatigheid"
                                value={referenceObjectAnswer.referenceObject.luxeDoelmatigheidUitleg}
                                status={referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus}
                                useSmallText={true}
                            />
                        )}
                    {!isEmpty(referenceObjectAnswer.referenceObject.mateVanLuxeStatus) &&
                        referenceObjectAnswer.referenceObject.mateVanLuxeStatus !== undefined &&
                        referenceObjectAnswer.referenceObject.mateVanLuxeUitleg !== undefined && (
                            <StatusDetailListForStatus
                                label="Mate van luxe"
                                value={referenceObjectAnswer.referenceObject.mateVanLuxeUitleg}
                                status={referenceObjectAnswer.referenceObject.mateVanLuxeStatus}
                                useSmallText={true}
                            />
                        )}
                    {!isEmpty(referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus) &&
                        referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus !== undefined &&
                        referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg !== undefined && (
                            <StatusDetailListForStatus
                                label="Mate van doelmatigheid"
                                value={referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg}
                                status={referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus}
                                useSmallText={true}
                            />
                        )}
                    {!isEmpty(referenceObjectAnswer.referenceObject.kwaliteitConditieStatus) &&
                        referenceObjectAnswer.referenceObject.kwaliteitConditieStatus !== undefined &&
                        referenceObjectAnswer.referenceObject.kwaliteitConditieUitleg !== undefined && (
                            <StatusDetailListForStatus
                                label="Kwaliteit / conditie"
                                value={referenceObjectAnswer.referenceObject.kwaliteitConditieUitleg}
                                status={referenceObjectAnswer.referenceObject.kwaliteitConditieStatus}
                                useSmallText={true}
                            />
                        )}
                    <StatusDetailListForStatus
                        label="Ligging"
                        value={referenceObjectAnswer.referenceObject.liggingUitleg}
                        status={referenceObjectAnswer.referenceObject.liggingStatus}
                        useSmallText={true}
                    />
                    <StatusDetailListForStatus
                        label="Bij-, op en/of aanbouwen"
                        value={referenceObjectAnswer.referenceObject.aanbouw}
                        status={referenceObjectAnswer.referenceObject.aanbouwStatus}
                        useSmallText={true}
                    />
                </div>
            </>
        );
    }

    function renderDefaultDetails(referenceObjectAnswer: ReferenceObjectAnswer | null, referenceSale: ReferenceSale) {
        const referenceObjectPrice =
            referenceObjectAnswer !== null &&
            referenceObjectAnswer.referenceObject !== null &&
            referenceObjectAnswer.referenceObject.verkoopprijs !== null
                ? referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs !== null
                    ? referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs.toString(10)
                    : referenceObjectAnswer.referenceObject.verkoopprijs.toString(10)
                : referenceSale.priceRange;

        const period =
            referenceObjectAnswer !== null &&
            referenceObjectAnswer.referenceObject !== null &&
            referenceObjectAnswer.referenceObject.verkoopdatum !== null &&
            !isEmpty(referenceObjectAnswer.referenceObject.verkoopdatum)
                ? referenceObjectAnswer.referenceObject.verkoopdatum.length > 8
                    ? format(new Date(referenceObjectAnswer.referenceObject.verkoopdatum), 'dd-MM-yyyy')
                    : 'Q' + referenceObjectAnswer.referenceObject.verkoopdatum
                : referenceSale.saleQuarter !== null && !isEmpty(referenceSale.saleQuarter)
                ? referenceSale.saleQuarter.length > 8
                    ? format(new Date(referenceSale.saleQuarter), 'dd-MM-yyyy')
                    : 'Q' + referenceSale.saleQuarter
                : '-';
        const volume = referenceObjectAnswer?.referenceObject.inhoud ?? null;

        return (
            <>
                <div className="col-6 text-left">
                    <dl>
                        <dt>Prijs per m²</dt>
                        <dd>
                            <FloorAreaPrice
                                floorArea={
                                    referenceObjectAnswer !== null && referenceObjectAnswer.referenceObject !== null
                                        ? referenceObjectAnswer.referenceObject.gebruiksOppervlakte
                                        : referenceSale.floorArea
                                }
                                valuation={props.valuation}
                                areaConclusion={props.areaConclusion}
                                price={referenceObjectPrice}
                                compact={true}
                                questionSet={props.questionSet}
                                appraisal={props.appraisal}
                            />
                        </dd>
                        <dt>Prijsklasse</dt>
                        <dd>
                            <PriceRange range={referenceObjectPrice} break={true} />
                        </dd>
                    </dl>
                </div>
                <div className="col-6 text-right">
                    <dl>
                        <dt>GBO wonen</dt>
                        <dd>
                            {props.areaConclusion !== null && referenceSale.floorArea !== null ? (
                                <FloorAreaBadgeAdapter
                                    compact
                                    areaConclusion={props.areaConclusion}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    floorArea={
                                        referenceObjectAnswer !== null &&
                                        referenceObjectAnswer.referenceObject !== null &&
                                        referenceObjectAnswer.referenceObject.gebruiksOppervlakte !== null
                                            ? referenceObjectAnswer.referenceObject.gebruiksOppervlakte
                                            : referenceSale.floorArea
                                    }
                                />
                            ) : null}
                            {referenceObjectAnswer !== null &&
                            referenceObjectAnswer.referenceObject !== null &&
                            referenceObjectAnswer.referenceObject.gebruiksOppervlakte !== null
                                ? referenceObjectAnswer.referenceObject.gebruiksOppervlakte + 'm²'
                                : referenceSale.floorArea === null
                                ? 'Onbekend'
                                : referenceSale.floorArea + 'm²'}
                        </dd>
                        {period !== null && (
                            <>
                                <dt>Periode</dt>
                                <dd>{period}</dd>
                            </>
                        )}
                        {volume !== null && (
                            <>
                                <dt>Bruto inhoud</dt>
                                <dd>
                                    {props.areaConclusion !== null && (
                                        <VolumeBadgeAdapter
                                            compact
                                            volume={volume}
                                            areaConclusion={props.areaConclusion}
                                            appraisal={props.appraisal}
                                            questionSet={props.questionSet}
                                        />
                                    )}
                                    ca. {volume} m³
                                </dd>
                            </>
                        )}
                    </dl>
                </div>
            </>
        );
    }

    return (
        <>
            <div
                className={classNames('col-12 col-sm-6 col-md-4 form-grid-item', {
                    selected: referenceObjectAnswer !== null,
                    hovering: props.isMapHovering,
                })}
                key={referenceSale.id}
                onMouseEnter={() => props.onHoverChange(referenceSale)}
                onMouseLeave={() => props.onHoverChange(null)}
            >
                <div className={classNames('reference-logo', sourceToClassName(source))}>&nbsp;</div>
                <div className="form-grid-item-img">
                    <div className="form-grid-item-img-content" key={sliderKey}>
                        <ImageViewer images={imageUrlPairs.map((pair) => pair.url)} />
                        <div
                            className={classNames('active-icon ion-md-checkmark', {
                                visible: referenceObjectAnswer !== null,
                            })}
                        >
                            &nbsp;
                        </div>
                    </div>
                </div>
                <div className="form-grid-item-body" onClick={() => props.onClickChange(referenceSale)}>
                    <div className="row">
                        <div className="col-12">
                            <div className="grid-item-title grid-item-title-with-avatar">
                                <div
                                    className={classNames('grid-item-title-avatar grid-item-title-avatar-icon-pin', {
                                        active: props.isMapSelected,
                                    })}
                                />
                                <p>
                                    {referenceObjectAnswer !== null && (
                                        <span className="badge-container">
                                            <span className="badge badge-success">#{props.index + 1}</span>
                                        </span>
                                    )}
                                    {
                                        <ReferenceObjectAddress
                                            referenceSale={referenceSale}
                                            referenceObjectAnswer={referenceObjectAnswer}
                                        />
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="col-12">
                            {props.referenceSale.hasWarning ? (
                                <span className="form-grid-item-alert">
                                    <span className="ion-md-alert">&nbsp;</span>
                                    {props.referenceSale.warning}
                                </span>
                            ) : null}
                        </div>
                        {referenceObjectAnswer !== null && props.isCompare
                            ? renderCompare(referenceObjectAnswer)
                            : renderDefaultDetails(referenceObjectAnswer, referenceSale)}
                    </div>
                </div>
                <div className="form-grid-item-footer d-flex justify-content-between">{renderButtons()}</div>
            </div>
        </>
    );
});
