import * as React from 'react';

import {Appraisal} from '../../../../../../../../../models/appraisal';
import {Modal} from '../../../../../components/modal';
import {PagePart} from '../../../../../../../../../models/page_part';
import {PagePartsSet} from '../../../../../../../../../models/page_parts_set';
import {QuestionContainer} from '../../../../../question_container';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {QuestionType} from '../../../../../../../../../enum/question_type';
import {RenderingContextType} from '../../../../../../../../../enum/rendering_context_type';
import {ValidationError} from '../../../../../../../../../models/validation_error';
import {createValidationMessagesMap} from '../../../../../../../../../../support/create_validation_messages_map';
import {observer} from 'mobx-react';
import {ReferenceObjectComparisonModalPresenter} from './reference_object_comparison_modal_presenter';
import {ReferenceObjectData} from '../../internal/create_reference_object_data';
import {QuestionTable} from '../../../../../../../components/question_table/question_table';
import {QuestionTableColumn} from '../../../../../../../components/question_table/question_table_column';
import {ReferenceObjectComparisonColumn} from './reference_object_comparison_column';
import {QuestionTableHeader} from '../../../../../../../components/question_table/question_table_header';
import {SetDefinition} from '../../reference_objects_question_presenter';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {TreeItem} from '../../../../../../../../../../support/generic_tree';
import {QuestionAnswerPair} from '../../../../../../../../../models/question_answer_pair';
import {ReferenceObjectComparisonModalContext} from './reference_object_comparison_model_context';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';
import {QuestionRenderingDataType} from '../../../../../../../../../enum/question_rendering_data_type';
import {QuestionRenderingData} from '../../../../../../../../../models/question_rendering_data';

interface OwnProps {
    onHide: () => void;
    visible: boolean;
    isFrozen: boolean;

    //QuestionContainer props
    appraisal: Appraisal;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    forceShowValidationMessages: boolean;
    hiddenQuestionTypes: QuestionType[];

    //Sidebar props
    gebruiksoppervlakteWonen: number | null;
    gebruiksOppervlakteBuitenruimte: number | null;
    overigeInpandigeRuimte: number | null;
    gebruiksOppervlakteExterneBergruimte: number | null;
    buildYear: number | null;
    perceelOppervlakte: number | null;

    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;

    referenceObjects: ReferenceObjectData[];

    referenceSets: SetDefinition[];
    activeSetDefinition: SetDefinition;
    showComparisonModal: (setDefinition: SetDefinition) => void;
    showAnswerModal: (referenceObjectAnswer: ReferenceObjectAnswer, treeItem: TreeItem<QuestionAnswerPair>) => void;

    children?: React.ReactNode;
}

export const ReferenceObjectComparisonModal: React.FC<OwnProps> = observer(function ReferenceObjectComparisonModal(
    props
) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectComparisonModalPresenter(
                props.referenceObjects,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.childQuestionValidationProvider(
                    props.appraisal,
                    props.questionSet,
                    props.validationErrors
                ),
                props.activeSetDefinition,
                props.referenceSets
            )
    );
    const rootQuestion =
        presenter.referenceObjectAnswers.length > 0 ? presenter.referenceObjectAnswers[0].question : null;
    const parentAnswerUuid =
        presenter.referenceObjectAnswers.length > 0 ? presenter.referenceObjectAnswers[0].parentAnswerUuid : null;

    return (
        <Modal
            forced={true}
            onHide={() => {
                props.onHide();
            }}
            visible={props.visible}
            wide
            stickyContainer
        >
            <div className="modal-header">
                <div className="row">
                    <div className="col-4">
                        {presenter.previousSet && (
                            <button
                                className="btn-text-link"
                                onClick={() =>
                                    presenter.previousSet && props.showComparisonModal(presenter.previousSet.set)
                                }
                            >
                                <span className="mr-1 ion-md-arrow-back" />
                                Naar referentievergelijker {presenter.previousSet.index + 1}
                            </button>
                        )}
                    </div>
                    <div className="col-4">
                        <h2>Referentieobjecten</h2>
                    </div>
                    <div className="col-4 text-right">
                        {presenter.nextSet && (
                            <button
                                className="btn-text-link"
                                onClick={() => presenter.nextSet && props.showComparisonModal(presenter.nextSet.set)}
                            >
                                Naar referentievergelijker {presenter.nextSet.index + 1}
                                <span className="ml-1 ion-md-arrow-forward" />
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div className="modal-body">
                <div className="reference-object-compare">
                    <QuestionTable questionSet={props.questionSet}>
                        {rootQuestion && parentAnswerUuid && (
                            <QuestionTableColumn columnKey="given_object">
                                <QuestionTableHeader>
                                    <h3 className="text-center">Getaxeerde object</h3>
                                </QuestionTableHeader>
                                <ReferenceObjectComparisonColumn
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    validationErrors={props.validationErrors}
                                    hiddenQuestionTypes={props.hiddenQuestionTypes}
                                    question={rootQuestion}
                                    parentAnswerUuid={parentAnswerUuid}
                                    activeSetDefinition={props.activeSetDefinition}
                                />
                            </QuestionTableColumn>
                        )}
                        {presenter.referenceObjectAnswers.map(
                            ({question, parentAnswerUuid, iteratorAnswerUuid, referenceObjectAnswer, treeItem}, i) => (
                                <QuestionTableColumn columnKey={iteratorAnswerUuid} key={i}>
                                    <QuestionTableHeader>
                                        <h3 className="d-flex justify-content-center align-items-center">
                                            Referentie {i + 1}
                                            <button
                                                className="btn btn-xs ion-md-expand ml-3"
                                                onClick={() => props.showAnswerModal(referenceObjectAnswer, treeItem)}
                                            />
                                        </h3>
                                    </QuestionTableHeader>
                                    <ReferenceObjectComparisonModalContext.Provider
                                        value={{
                                            openAnswerModal: () =>
                                                props.showAnswerModal(referenceObjectAnswer, treeItem),
                                        }}
                                    >
                                        <QuestionContainer
                                            key={iteratorAnswerUuid}
                                            appraisal={props.appraisal}
                                            question={question}
                                            questionSet={props.questionSet}
                                            parentAnswerUuid={parentAnswerUuid}
                                            validationErrors={props.validationErrors}
                                            forceShowValidationMessages={
                                                presenter.forceShowValidationMessages ||
                                                props.forceShowValidationMessages
                                            }
                                            validationMessages={createValidationMessagesMap(
                                                presenter.validationMessages[parentAnswerUuid] ?? []
                                            )}
                                            hiddenQuestionTypes={props.hiddenQuestionTypes}
                                            disabled={props.isFrozen}
                                            pagePartsSet={props.pagePartsSet}
                                            activePagePart={props.activePagePart}
                                            renderingContext={RenderingContextType.COMPACT_GRID_COLUMNS}
                                            questionRenderingData={{
                                                type: QuestionRenderingDataType.REFERENCE_OBJECTS,
                                                setDefinitionType: props.activeSetDefinition.type,
                                            }}
                                        />
                                    </ReferenceObjectComparisonModalContext.Provider>
                                </QuestionTableColumn>
                            )
                        )}
                    </QuestionTable>
                </div>

                {presenter.forceShowValidationMessages === true &&
                    Object.keys(presenter.validationMessages).length > 0 && (
                        <div className="alert-inline alert-hint ion-md-alert">
                            <strong>Er zijn nog meldingen bij onderstaande vragen:</strong>
                            <p className="pre-line">
                                {Object.values(presenter.validationMessages)
                                    .flat()
                                    .map((vm) => vm.fallbackMessage)
                                    .filter((message): message is string => message !== null)
                                    .join('\n')}
                            </p>
                        </div>
                    )}
            </div>

            <div className="modal-footer">
                {props.children}

                {Object.keys(presenter.validationMessages).length > 0 &&
                presenter.forceShowValidationMessages === false ? (
                    <button
                        className="btn btn-primary btn-icon-right ion-md-alert"
                        type="button"
                        onClick={() => presenter.onForceShowValidationMessages()}
                    >
                        Meldingen weergeven
                    </button>
                ) : (
                    <button
                        className="btn btn-primary btn-icon-right ion-md-checkmark"
                        type="button"
                        onClick={() => props.onHide()}
                    >
                        Opslaan & sluiten
                    </button>
                )}
            </div>
        </Modal>
    );
});
