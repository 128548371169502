import {SortingDirection} from '../../../../../../../../../../enum/reference_objects_sorting';
import {ReferenceObject} from '../../../models/reference_object';
import {ReferenceObjectSortingStrategy} from './reference_object_sorting_strategy';

export class ReferenceObjectSorterByAlphabet implements ReferenceObjectSortingStrategy {
    public sortReferenceObjects(referenceObjects: ReferenceObject[], sortingDirection: SortingDirection) {
        const referenceObjectsOrtec: ReferenceObject[] = [];
        const referenceObjectsNVM: ReferenceObject[] = [];
        const referenceObjectsDefault: ReferenceObject[] = [];

        for (const referenceObject of referenceObjects) {
            if (referenceObject.source !== null) {
                if (referenceObject.source.toLowerCase().includes('nvm')) {
                    referenceObjectsNVM.push(referenceObject);
                } else if (referenceObject.source.toLowerCase().includes('ortec')) {
                    referenceObjectsOrtec.push(referenceObject);
                } else {
                    referenceObjectsDefault.push(referenceObject);
                }
            } else {
                referenceObjectsDefault.push(referenceObject);
            }
        }

        const returnSortReferences: ReferenceObject[] = [];

        let sortedReferences = this.sortObjects(referenceObjectsNVM, sortingDirection);
        for (const sortedReference of sortedReferences) {
            returnSortReferences.push(sortedReference);
        }
        sortedReferences = this.sortObjects(referenceObjectsOrtec, sortingDirection);
        for (const sortedReference of sortedReferences) {
            returnSortReferences.push(sortedReference);
        }
        sortedReferences = this.sortObjects(referenceObjectsDefault, sortingDirection);
        for (const sortedReference of sortedReferences) {
            returnSortReferences.push(sortedReference);
        }

        return returnSortReferences;
    }

    private sortObjects(referenceObjects: ReferenceObject[], sortingDirection: SortingDirection) {
        return referenceObjects.sort((referenceObject1, referenceObject2) => {
            return this.getFullAddressForReferenceObject(referenceObject1) >
                this.getFullAddressForReferenceObject(referenceObject2)
                ? sortingDirection === SortingDirection.DESCENDING
                    ? -1
                    : 1
                : sortingDirection === SortingDirection.DESCENDING
                ? 1
                : -1;
        });
    }

    private getFullAddressForReferenceObject = (referenceObject: ReferenceObject) => {
        return (
            referenceObject.street.toLocaleLowerCase() +
            ' ' +
            referenceObject.houseNumber +
            ' ' +
            referenceObject.letter
        );
    };
}
