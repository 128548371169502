import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {questionSortCompareFn} from '../../../support/question_filtering';

type QuestionData<D> = {children: {[questionUuid: string]: NestedQuestions<D>}};
type NestedQuestions<D> = ({data: D} & QuestionData<D>) | QuestionData<D>;

/**
 * Sort the questions based on their expected order.
 * This makes sure that even if not all questions are rendered across all columns,
 * they are still aligned and in the right order.
 */
export function sortQuestionTableQuestions<D>(questionSet: QuestionSet, questions: {[questionKey: string]: D}): D[] {
    const nested: QuestionData<D> = {
        children: {},
    };

    // Convert the flat structure of the question key into a nested structure, that can be recursed into
    for (const [questionKey, data] of Object.entries(questions)) {
        const parentUuids = questionKey.split('.');
        const uuid = parentUuids.pop() as string; // Guaranteed to have at least 1 element by the split

        // Find the node in the nested structure that is the parent of the current question
        const parent = parentUuids.reduce((parent, uuid) => {
            if (!parent.children[uuid]) {
                parent.children[uuid] = {
                    children: {},
                };
            }

            return parent.children[uuid] as QuestionData<D>;
        }, nested);

        if (!parent.children[uuid]) {
            parent.children[uuid] = {
                children: {},
            };
        }
        (parent.children[uuid] as {data: D} & QuestionData<D>).data = data;
    }

    // Recurse into the nested structure and sort the questions
    return sortQuestionTableQuestionsRecursive(questionSet, nested);
}

function sortQuestionTableQuestionsRecursive<D>(questionSet: QuestionSet, questionData: QuestionData<D>): D[] {
    const headers: D[] = [];
    let sortedQuestions: Question[] = [];

    // Seperate headers and find question metadata
    for (const questionUuid of Object.keys(questionData.children)) {
        if (questionUuid === 'header' && 'data' in questionData.children[questionUuid]) {
            headers.push((questionData.children[questionUuid] as {data: D} & QuestionData<D>).data);
            continue;
        }
        const question = questionSet.findQuestionByUuid(questionUuid);

        if (!question) {
            continue;
        }

        sortedQuestions.push(question);
    }

    // Sort questions based on regular sort order
    sortedQuestions = sortedQuestions.sort(questionSortCompareFn);

    let sortedData: D[] = [...headers];
    for (const question of sortedQuestions) {
        const data = questionData.children[question.uuid];

        // If the node itself has data, add it to the array
        if ('data' in data) {
            sortedData.push(data.data);
        }

        // If the node has children, recurse into them
        if ('children' in data) {
            sortedData = [...sortedData, ...sortQuestionTableQuestionsRecursive(questionSet, data)];
        }
    }

    return sortedData;
}
