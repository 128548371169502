import {NetworkStatus, NetworkStatusProvider} from '../network_status_provider';

import {Answer} from '../../models/answer';
import {AnswerController} from './answer_controller';
import {AnswerStorageQueue} from './support/answer_storage_queue';
import {Appraisal} from '../../models/appraisal';
import {first} from 'rxjs/operators';

export interface AnswerInteractor {
    submit(forced?: boolean): Promise<void>;
}

export class DefaultAnswerInteractor implements AnswerInteractor {
    constructor(
        private _appraisal: Appraisal,
        private _answerController: AnswerController,
        private _answerStorageQueue: AnswerStorageQueue,
        private _networkStatusProvider: NetworkStatusProvider
    ) {}

    public async submit(forced = false): Promise<void> {
        const status = await this._networkStatusProvider.status().pipe(first()).toPromise();
        if (status === NetworkStatus.ONLINE) {
            const changedAnswers = this._answerController.answers().filter((answer) => answer.changed);
            if (changedAnswers.length > 0 || forced) {
                await this.store(changedAnswers, async (persistedAnswers) => {
                    this._answerController.pushMany(persistedAnswers);
                });
            }
        }
    }

    private async store(answers: Answer[], cleanupPromise?: (answers: Answer[]) => Promise<unknown>) {
        //Get the newest updatedAt from all the other answers
        const lastUnchangedUpdatedAt = this._answerController
            .answers()
            .filter((answer) => !answer.changed)
            .reduce<Date | null>((p, c) => {
                if (p === null || (c.updatedAt !== null && c.updatedAt.getTime() > p.getTime())) {
                    return c.updatedAt;
                }
                return p;
            }, null);

        //Get the oldest updatedAt from the currently persisting answers
        const lastUpdatedAtFromAnswers = answers.reduce<Date | null>((p, c) => {
            if (p === null || (c.updatedAt !== null && c.updatedAt.getTime() < p.getTime())) {
                return c.updatedAt;
            }
            return p;
        }, null);

        //Figure out which date is oldest, either from the other answers or the answers we are submitting atm
        let oldestLastUpdatedAt = lastUnchangedUpdatedAt ?? lastUpdatedAtFromAnswers;
        if (lastUpdatedAtFromAnswers && lastUpdatedAtFromAnswers.getTime() < (oldestLastUpdatedAt?.getTime() ?? 0)) {
            oldestLastUpdatedAt = lastUpdatedAtFromAnswers;
        }

        return await this._answerStorageQueue.storeAnswers(
            this._appraisal.id,
            answers,
            oldestLastUpdatedAt,
            cleanupPromise
        );
    }
}
