import {AppraisalRecentAction} from '../../models/appraisal_recent_action';

export interface ApiAppraisalRecentAction {
    user_name: string;
    question_content: string;
}

export function apiAppraisalRecentActionToAppraisalRecentAction(
    apiAppraisalRecentAction: ApiAppraisalRecentAction
): AppraisalRecentAction {
    return {
        userName: apiAppraisalRecentAction.user_name,
        questionContent: apiAppraisalRecentAction.question_content,
    };
}
