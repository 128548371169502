import {computed, makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../../../support/composite_subscription';
import {FloorInfo, FloorInteractor} from '../../../../../../business/floor_interactor';
import {Presenter} from '../../../../../../../support/presenter/presenter';

export class PagePartIteratorPresenter implements Presenter {
    @observable.ref public floorIteration: string | null | undefined = undefined;
    @observable.ref public floorInfos: FloorInfo[] = [];

    private subscriptions = new CompositeSubscription();

    constructor(private floorInteractor: FloorInteractor) {
        makeObservable(this);
    }

    public async mount() {
        this.subscriptions.add(
            this.floorInteractor.getFloorIteration().subscribe((floorIteration) => {
                runInAction(() => {
                    this.floorIteration = floorIteration;
                });
            })
        );

        this.subscriptions.add(
            this.floorInteractor.getFloorsStream().subscribe((floorInfos) => {
                if (floorInfos) {
                    runInAction(() => {
                        this.floorInfos = floorInfos;
                    });
                }
            })
        );
    }

    public async unmount() {
        this.subscriptions.clear();
    }

    @computed get floorInfo() {
        return this.floorInfos.find((floorInfo) => floorInfo.iteration === this.floorIteration);
    }
}
