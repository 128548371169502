import $ from 'jquery';
import {selectOption, UserType} from './index';

(() => {
    const form = $('form#organisation-user, form#organisation-invite-link');
    if (!form) {
        return;
    }

    const isEdit = form.data('purpose') === 'edit';

    // Open the roles container when role type is custom
    $('select[name="role"]').each(function () {
        $(this).on('change', function () {
            if ($(this).find('option:selected')?.attr('value') === 'custom') {
                $(form).find('#permissions-container').removeClass('d-none');
            } else {
                $(form).find('#permissions-container').addClass('d-none');
            }
        });
    });

    // Select the role "custom" and open the roles container when user type is junior appraiser
    $('select[name="user_type"]').each(function () {
        $(this).on('change', function () {
            const selectedUserType = $(this).find('option:selected')?.attr('value');
            if (selectedUserType === UserType.JUNIOR_APPRAISER) {
                const select = form.find('select[name="role"]') as JQuery<HTMLSelectElement>;
                selectOption(select, 'custom');
                $(form).find('#permissions-container').removeClass('d-none');
            }

            const mgNumberInput = form.find('#mg_number') as JQuery<HTMLInputElement>;
            if (mgNumberInput) {
                mgNumberInput.css('display', selectedUserType === UserType.JUNIOR_APPRAISER ? 'flex' : 'none');
            }
            const rtNumberInput = form.find('#rt_number') as JQuery<HTMLInputElement>;
            if (rtNumberInput && isEdit) {
                rtNumberInput.css('display', selectedUserType === UserType.APPRAISER ? 'flex' : 'none');
            }
        });
    });
})();
