import PhotoSwipeLightbox from 'photoswipe/lightbox';
import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {PhotoSwipeEventsMap} from 'photoswipe';
import {action, makeObservable, observable, runInAction} from 'mobx';
import {ModalConfigStackInteractor} from '../../../../../../../../../business/modal_config_stack_interactor';
import {ModalType} from '../../../../../../../../../models/modal_config';
import {ResultType} from '../../../../../../../../../business/generic_config_stack_interactor';
import {PhotoSet} from './photo_sets';

export class ImageViewerSelectorPresenter implements Presenter {
    @observable public hidden = false;
    @observable public activeIndex: number | null = null;

    constructor(private modalConfigStackInteractor: ModalConfigStackInteractor, private lightbox?: PhotoSwipeLightbox) {
        makeObservable(this);
    }

    public mount() {
        if (this.lightbox) {
            this.lightbox.on('beforeZoomTo', this.beforeZoomTo);
            this.lightbox.on('zoomPanUpdate', this.zoomPanUpdate);
            this.lightbox.on('close', this.close);
            this.lightbox.on('slideActivate', this.slideActivate);

            if (this.lightbox.pswp?.currSlide) {
                const index = this.lightbox.pswp.currIndex;
                runInAction(() => {
                    this.activeIndex = index;
                });
            }
        }
    }

    public unmount() {
        if (this.lightbox) {
            this.lightbox.off('beforeZoomTo', this.beforeZoomTo);
            this.lightbox.off('zoomPanUpdate', this.zoomPanUpdate);
            this.lightbox.off('close', this.close);
            this.lightbox.off('slideActivate', this.slideActivate);
        }
    }

    @action
    private beforeZoomTo = (ev: PhotoSwipeEventsMap['beforeZoomTo']) => {
        if (this.lightbox?.pswp?.currSlide) {
            this.hidden = ev.destZoomLevel > this.lightbox.pswp.currSlide.zoomLevels.initial;
        }
    };

    @action
    private zoomPanUpdate = (ev: PhotoSwipeEventsMap['zoomPanUpdate']) => {
        this.hidden = ev.slide.currZoomLevel > ev.slide.zoomLevels.initial;
    };

    @action
    private close = () => {
        this.activeIndex = null;
    };

    @action
    private slideActivate = () => {
        if (this.lightbox?.pswp) {
            this.activeIndex = this.lightbox.pswp.currIndex;
        }
    };

    public async filterLabelsModal(availableLabels: Map<string, string[]>, filteredLabels: string[] | null) {
        const selectedOptions = Array.from(availableLabels.entries())
            .filter(([, values]) => values.some((value) => filteredLabels?.includes(value)))
            .map(([key]) => key);

        const result = await this.modalConfigStackInteractor.insert<string[]>({
            id: 'reference-image-label-select',
            type: ModalType.REFERENCE_IMAGES_LABELS,
            options: Array.from(availableLabels.keys()),
            selected: selectedOptions,
        });

        if (result.type !== ResultType.SUCCEEDED) {
            return filteredLabels;
        }

        if (result.result.length === 0) {
            return null;
        }

        const newOptions = result.result;
        const newLabels = [];

        for (const [key, values] of availableLabels.entries()) {
            if (newOptions.includes(key)) {
                newLabels.push(...values);
            }
        }

        return newLabels;
    }

    public async photoSetModal(sets: PhotoSet[], activeIndex: number) {
        const result = await this.modalConfigStackInteractor.insert<number>({
            id: 'reference-image-photo-set-select',
            type: ModalType.REFERENCE_IMAGES_SETS,
            sets,
            activeIndex,
        });

        if (result.type !== ResultType.SUCCEEDED) {
            return activeIndex;
        }

        return result.result;
    }
}
