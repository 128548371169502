import {ConstructionCostsUnit} from '../../../../network/models/construction_costs';

export function humanizeUnit(unit: ConstructionCostsUnit): string {
    switch (unit) {
        case ConstructionCostsUnit.SINGLE:
            return 'p.st.';
        case ConstructionCostsUnit.METER:
            return 'm';
        case ConstructionCostsUnit.SQUARE_METER:
            return 'm²';
        case ConstructionCostsUnit.CUBIC_METER:
            return 'm³';
        case ConstructionCostsUnit.KILOGRAM:
            return 'kg';
        case ConstructionCostsUnit.DAY:
            return 'Dag';
        case ConstructionCostsUnit.HOUR:
            return 'Uur';
    }
}
