import * as React from 'react';

import {GeolocationPermission} from '../../support/geolocation/geolocation_permission';
import {Modal} from '../../appraising/appraise/ui/content/questions/components/modal';
import {requestCurrentGeolocation} from '../../support/geolocation/request_current_geolocation';

interface OwnProps {
    geolocationPermission: GeolocationPermission;
}

interface OwnState {
    hasGeolocationPermission: boolean;
    errorMessage: string | null;
}

export default class Root extends React.Component<OwnProps, OwnState> {
    public state = {
        hasGeolocationPermission: false,
        errorMessage: null,
    };

    public componentDidMount() {
        this.props.geolocationPermission.hasGeolocationPermission().then((hasPermission) => {
            this.setState({hasGeolocationPermission: hasPermission});
        });
    }

    private handleAllowLocation() {
        //Simply request the location once to activate the permission
        requestCurrentGeolocation()
            .then(() => {
                this.props.geolocationPermission.setGeolocationPermission(true);
                this.setState({
                    hasGeolocationPermission: true,
                });
            })
            .catch((error: GeolocationPositionError) => {
                this.setState({
                    hasGeolocationPermission: false,
                });

                if ('code' in error && error.code === error.PERMISSION_DENIED) {
                    this.setState({
                        errorMessage: `Je hebt het delen van je locatie al geblokkeerd voor taXapi. Geef eerst in je browser toestemming aan taxApi om je locatie te gebruiken.`,
                    });
                }
            });
    }

    private clearErrorMessage() {
        this.setState({
            errorMessage: null,
        });
    }

    public render() {
        return (
            <div>
                <div className="spacer" />
                <h2>Nabijgelegen taxatie object melding</h2>
                <p>
                    {this.state.hasGeolocationPermission
                        ? 'Je ontvangt momenteel een melding zodra als je in de buurt bent van een taxatie object.'
                        : 'Om een melding te krijgen van een nabijgelegen taxatie object, zal je eerst moeten toestaan om je locatie te delen met taXapi.'}
                </p>
                {!this.state.hasGeolocationPermission && (
                    <button className="btn btn-sm ion-ios-pin" onClick={this.handleAllowLocation.bind(this)}>
                        Locatie toestaan
                    </button>
                )}

                {
                    <Modal visible={this.state.errorMessage !== null} onHide={this.clearErrorMessage.bind(this)}>
                        <h2>Error</h2>
                        <div className="spacer" />
                        {this.state.errorMessage}
                    </Modal>
                }
            </div>
        );
    }
}
