import {FloorType, floorTypeToQuestionType} from '../../../enum/floor_type';
import {computed, makeObservable, observable, runInAction} from 'mobx';

import {Answer} from '../../../models/answer';
import {AnswerController} from '../../../business/answering/answer_controller';
import {ChildQuestionValidationProvider} from '../../../business/validation/child_validation_provider';
import {CompositeSubscription} from '../../../../support/composite_subscription';
import {Presenter} from '../../../../support/presenter/presenter';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {ValidationMessage} from '../../../business/validation/validation_message';
import {findChildrenForQuestion} from '../support/question_filtering';

export class FloorContentPresenter implements Presenter {
    @observable.ref public answer?: Answer;
    @observable.ref public validationMessages: ValidationMessage[] = [];

    @computed
    public get question(): Question | undefined {
        return this._questionSet.findQuestionsByType(floorTypeToQuestionType(this._floorType))[0] ?? undefined;
    }

    @computed
    public get children(): Question[] {
        const question = this.question;
        if (question === undefined) {
            return [];
        }
        return findChildrenForQuestion(question, this._questionSet);
    }

    private _floorType: FloorType;
    private _iteration: string;
    private _questionSet: QuestionSet;
    private _answerController: AnswerController;
    private _subscriptions = new CompositeSubscription();
    private _childValidationProvider: ChildQuestionValidationProvider;

    constructor(
        floorType: FloorType,
        iteration: string,
        questionSet: QuestionSet,
        answerController: AnswerController,
        childValidationProvider: ChildQuestionValidationProvider
    ) {
        makeObservable(this);
        this._floorType = floorType;
        this._iteration = iteration;
        this._questionSet = questionSet;
        this._answerController = answerController;
        this._childValidationProvider = childValidationProvider;
    }

    public mount(): void {
        if (this.question !== undefined) {
            this._subscriptions.add(
                this._answerController
                    .answerByIdentifiersStream(this.question.uuid, null, this._iteration)
                    .subscribe((answer) => {
                        runInAction(() => {
                            this.answer = answer;
                        });
                    })
            );
            this._subscriptions.add(
                this._childValidationProvider.validate(this.question.uuid, null).subscribe((validationMessages) => {
                    runInAction(() => {
                        this.validationMessages = validationMessages;
                    });
                })
            );
        }
    }

    public unmount(): void {
        this._subscriptions.clear();
    }
}
