import {PathNameProvider} from './child_questions_provider';
import {Observable, Observer} from 'rxjs';
import {History} from 'history';
import {startWith} from 'rxjs/operators';

export class DefaultPathNameProvider implements PathNameProvider {
    private _stream?: Observable<string>;

    constructor(private history: History) {}

    public get stream(): Observable<string> {
        if (this._stream === undefined) {
            this._stream = Observable.create((observer: Observer<string>) => {
                this.history.listen(() => observer.next(this.history.location.pathname));
            }).pipe(startWith(this.history.location.pathname)) as Observable<string>;
        }
        return this._stream;
    }
}
