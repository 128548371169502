import {action, makeObservable, observable} from 'mobx';

import {errorMessage, guard, LoadingStateType} from '../../../support/presenter/helpers';
import {Presenter} from '../../../support/presenter/presenter';
import {FlashMessageBroadcaster, Type} from '../../business/flash_message_broadcaster';
import {DefaultPagePartsSet, PagePartsSet} from '../../models/page_parts_set';
import {PagePartConfiguration, PagePartConfigurationType} from '../../models/page_part_configuration';
import {PagePartsSetInteractor} from '../../business/page_parts/page_parts_set_interactor';

export class ConfigCurrentTabPresenter implements Presenter {
    @observable public editableConfig: PagePartConfiguration | null = null;
    @observable public loadingState: LoadingStateType = LoadingStateType.LOADING;

    constructor(
        activePagePartSet: PagePartsSet,
        private pagePartsSetsInteractor: PagePartsSetInteractor,
        private flashMessageBroadcaster: FlashMessageBroadcaster
    ) {
        makeObservable(this);
        if (activePagePartSet?.config.isOwned && activePagePartSet?.config.type === PagePartConfigurationType.CUSTOM) {
            this.editableConfig = {...activePagePartSet?.config};
        } else {
            this.editableConfig = null;
        }
        this.loadingState = LoadingStateType.IDLE;
    }

    public async mount() {
        // Noop
    }

    public async unmount() {
        // Noop
    }

    @action
    public onNameChange = (value: string) => {
        if (this.editableConfig !== null) {
            this.editableConfig.name = value;
        }
    };

    @action
    public onDescriptChange = (value: string) => {
        if (this.editableConfig !== null) {
            this.editableConfig.description = value;
        }
    };

    @action
    public onIsSharedChange = (value: boolean) => {
        if (this.editableConfig !== null) {
            this.editableConfig.isShared = value;
        }
    };

    @action
    public onIsVisibleChange = (value: boolean) => {
        if (this.editableConfig !== null) {
            this.editableConfig.isVisible = value;
        }
    };

    public saveChanges = async () => {
        if (this.editableConfig !== null) {
            const config = {...this.editableConfig};
            if (config !== null) {
                try {
                    await guard(this, () => this.pagePartsSetsInteractor.updateSet(new DefaultPagePartsSet(config)));
                    this.flashMessageBroadcaster.broadcast('De wijzigingen zijn opgeslagen.', Type.Success);
                } catch (error) {
                    this.flashMessageBroadcaster.broadcast(
                        errorMessage(error) ?? 'Er is iets mis gegaan. Probeer het opnieuw.',
                        Type.Danger
                    );
                }
            }
        }
    };
}
