import {initFireworks} from './fireworks';

/**
 * Toggle fireworks in the interface for some time
 */
(() => {
    const shouldShow = document.getElementsByClassName('fireworks').length !== 0;

    if (shouldShow) {
        const container = document.createElement('div');
        container.setAttribute('id', 'animation-fireworks');
        document.body.appendChild(container);

        const fireworks = initFireworks(container);
        fireworks.start();

        const killed = setInterval(function () {
            fireworks.kill();
            clearInterval(killed);
        }, 8000);
    }
})();
