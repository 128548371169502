import React, {useMemo, useState} from 'react';
import {createRoot} from 'react-dom/client';
import {observer} from 'mobx-react';

import {usePresenter} from '../support/presenter/use_presenter';
import {RootPresenter} from './root_presenter';
import {PopupCard, PopupCardProps} from './popup_card';
import {ColoredHeaderCard} from './colored_header';
import {IconCard} from './icon_card';
import {AppraisalDetails} from './appraisal_details';

const Root = observer(() => {
    const presenter = usePresenter((component) => new RootPresenter(component.network.ajaxDriver));

    const cardContents = useMemo(() => {
        const cardContents: Partial<PopupCardProps>[] = presenter.data
            ? [
                  {
                      children: (
                          <ColoredHeaderCard>
                              Jouw {presenter.data.year}
                              <br /> in aantallen
                          </ColoredHeaderCard>
                      ),
                      whiteArrows: true,
                  },
                  {
                      children: (
                          <IconCard icon="ion-md-calculator">
                              <h1 className="popup-card-pop-in">
                                  Aantal taxaties
                                  <br />
                                  <span className="popup-card-statistic">{presenter.getStatistic('total')}</span>
                              </h1>
                              <h1 className="popup-card-pop-in">
                                  Gevalideerd
                                  <br />
                                  <span className="popup-card-statistic">{presenter.getStatistic('validated')}</span>
                              </h1>
                              <h1 className="popup-card-pop-in">
                                  Niet-gevalideerd
                                  <br />
                                  <span className="popup-card-statistic">
                                      {presenter.getStatistic('not_validated')}
                                  </span>
                              </h1>
                              <h1 className="popup-card-pop-in mt-5">
                                  Aantal verbetervoorstellen
                                  <br />
                                  <span className="popup-card-statistic">{presenter.data?.nrOfCorrections}</span>
                              </h1>
                              <h1 className="popup-card-pop-in">
                                  Ontkoppelingen
                                  <br />
                                  <span className="popup-card-statistic">
                                      {presenter.getStatistic('requested-correction-after-approval')}
                                  </span>
                              </h1>
                          </IconCard>
                      ),
                  },
                  {
                      children: (
                          <IconCard icon="ion-md-home">
                              <h1 className="popup-card-pop-in">
                                  Rij-woningen
                                  <br />
                                  <span className="popup-card-statistic">
                                      {presenter.getStatistic('total_row_objects')}
                                  </span>
                              </h1>
                              <h1 className="popup-card-pop-in">
                                  Vrijstaande woningen
                                  <br />
                                  <span className="popup-card-statistic">
                                      {presenter.getStatistic('total_freestanding_objects')}
                                  </span>
                              </h1>
                              <h1 className="popup-card-pop-in">
                                  Twee-onder-een-kap woningen
                                  <br />
                                  <span className="popup-card-statistic">
                                      {presenter.getStatistic('total_joined_objects')}
                                  </span>
                              </h1>
                              <h1 className="popup-card-pop-in">
                                  Appartementen
                                  <br />
                                  <span className="popup-card-statistic">
                                      {presenter.getStatistic('total_apartments')}
                                  </span>
                              </h1>
                          </IconCard>
                      ),
                  },
                  {
                      children: (
                          <IconCard icon="ion-md-stats" small>
                              <h1 className="popup-card-pop-in">
                                  Financiering
                                  <br />
                                  <span className="popup-card-statistic">
                                      {presenter.getStatistic('acquire_mortage')}
                                  </span>
                              </h1>
                              <h1 className="popup-card-pop-in">
                                  Aankoopbeslissing
                                  <br />
                                  <span className="popup-card-statistic">
                                      {presenter.getStatistic('insight_for_purchase')}
                                  </span>
                              </h1>
                              <h1 className="popup-card-pop-in">
                                  Verkoopbeslissing
                                  <br />
                                  <span className="popup-card-statistic">
                                      {presenter.getStatistic('insight_for_sale')}
                                  </span>
                              </h1>
                              <h1 className="popup-card-pop-in">
                                  Terugkoopbeslissing (WoCo)
                                  <br />
                                  <span className="popup-card-statistic">
                                      {presenter.getStatistic('insight_for_repurchase')}
                                  </span>
                              </h1>
                              <h1 className="popup-card-pop-in">
                                  Bijzonder Beheer
                                  <br />
                                  <span className="popup-card-statistic">{presenter.getStatistic('forced_sale')}</span>
                              </h1>
                              <h1 className="popup-card-pop-in">
                                  Overig
                                  <br />
                                  <span className="popup-card-statistic">{presenter.getStatistic('other')}</span>
                              </h1>
                          </IconCard>
                      ),
                  },
                  {
                      children: (
                          <IconCard icon="ion-md-medical">
                              <h1 className="popup-card-pop-in">
                                  Verbouwing
                                  <br />
                                  <span className="popup-card-statistic">
                                      {presenter.getStatistic('improvements_planned')}
                                  </span>
                              </h1>
                              <h1 className="popup-card-pop-in">
                                  Nieuwbouw
                                  <br />
                                  <span className="popup-card-statistic">
                                      {presenter.getStatistic('new_construction')}
                                  </span>
                              </h1>
                              <h1 className="popup-card-pop-in">
                                  Verhuurde staat
                                  <br />
                                  <span className="popup-card-statistic">{presenter.getStatistic('rented_state')}</span>
                              </h1>
                          </IconCard>
                      ),
                  },
              ]
            : [
                  {
                      children: (
                          <ColoredHeaderCard>
                              Jouw jaar
                              <br /> in aantallen
                          </ColoredHeaderCard>
                      ),
                      whiteArrows: true,
                  },
              ];

        if (
            presenter.data?.quickestAppraisal ||
            presenter.data?.mostCorrectedAppraisal ||
            presenter.data?.mostAnswersAppraisal
        ) {
            cardContents.push({
                children: <ColoredHeaderCard>Gedenkwaardige taxaties van {presenter.data.year}</ColoredHeaderCard>,
                whiteArrows: true,
            });
        }

        if (presenter.data?.quickestAppraisal) {
            cardContents.push({
                children: (
                    <IconCard icon="ion-md-timer" small>
                        <h1>Je snelste taxatie</h1>
                        <AppraisalDetails
                            appraisal={presenter.data.quickestAppraisal.appraisal}
                            imageUrl={presenter.data.quickestAppraisal.imageUrl}
                        />
                        <h1 className="popup-card-pop-in">
                            <span className="popup-card-statistic">
                                {Math.round(presenter.data.quickestAppraisal.creationToValidationSeconds / (60 * 60))}{' '}
                                uur
                            </span>
                            <br />
                            van aangemaakt tot gevalideerd
                        </h1>
                    </IconCard>
                ),
            });
        }

        if (presenter.data?.mostCorrectedAppraisal) {
            cardContents.push({
                children: (
                    <IconCard icon="ion-md-close-circle-outline" small>
                        <h1>Je meest irritante taxatie</h1>
                        <AppraisalDetails
                            appraisal={presenter.data.mostCorrectedAppraisal.appraisal}
                            imageUrl={presenter.data.mostCorrectedAppraisal.imageUrl}
                        />
                        <h1 className="popup-card-pop-in">
                            <span className="popup-card-statistic">
                                {presenter.data.mostCorrectedAppraisal.corrections}
                            </span>
                            <br />
                            {presenter.data.mostCorrectedAppraisal.corrections === 1
                                ? 'verbetervoorstel'
                                : 'verbetervoorstellen'}
                        </h1>
                    </IconCard>
                ),
            });
        }

        if (presenter.data?.mostAnswersAppraisal) {
            cardContents.push({
                children: (
                    <IconCard icon="ion-md-create" small>
                        <h1>De taxatie die het meeste werk kostte</h1>
                        <AppraisalDetails
                            appraisal={presenter.data.mostAnswersAppraisal.appraisal}
                            imageUrl={presenter.data.mostAnswersAppraisal.imageUrl}
                        />
                        <h1 className="popup-card-pop-in">
                            <span className="popup-card-statistic">
                                {presenter.data.mostAnswersAppraisal.answerCount}
                            </span>
                            <br /> ingevulde antwoorden
                        </h1>
                    </IconCard>
                ),
            });
        }

        cardContents.push({
            children: (
                <div className="popup-card-content popup-card-header">
                    <div>
                        <div className="row">
                            <div className="col-12 popup-card-pop-in">
                                <h2>Download overzicht</h2>
                                <p>
                                    Download de basisgegevens met de gegevens nodig voor de verplichte eigen verklaring
                                    van het NRVT.
                                </p>
                            </div>
                            <div className="col-12 popup-card-pop-in mb-3">
                                <a
                                    className="btn btn-sm ion-md-cloud-download"
                                    href="/overview/year/pdf"
                                    target="_blank"
                                >
                                    Download PDF
                                </a>
                            </div>
                            <div className="col-12 popup-card-pop-in">
                                <h2>Statistieken</h2>
                                <p>Bekijk meer gedetailleerde data van taxaties uit een specifieke periode.</p>
                            </div>
                            <div className="col-12 popup-card-pop-in mb-5">
                                <a className="btn btn-sm ion-md-stats" href="/account/me/export-statistics">
                                    Naar statistieken
                                </a>
                            </div>
                        </div>
                    </div>
                    <h1>Download je gegevens</h1>
                </div>
            ),
        });

        return cardContents;
    }, [presenter.data]);

    const [activeIndex, setActiveIndex] = useState(0);
    const cards: React.ReactNode[] = cardContents.map((content, index) => (
        <PopupCard
            key={index}
            active={index <= activeIndex && presenter.data !== null}
            goBack={index > 0 ? () => setActiveIndex(index - 1) : undefined}
            goForward={index < cardContents.length - 1 ? () => setActiveIndex(index + 1) : undefined}
            {...content}
        />
    ));

    if (presenter.error) {
        return (
            <div className="empty-message">
                <h3>Er is iets misgegaan...</h3>
            </div>
        );
    }

    return (
        <>
            {presenter.data === null && (
                <div className="card card-parent popup-card visible">
                    <div className="empty-message">
                        <div className="loader">
                            <span>&nbsp;</span>
                        </div>
                        <h3>Gegevens aan het verzamelen...</h3>
                    </div>
                </div>
            )}
            {cards}
        </>
    );
});

export function renderYearOverviewSearchInto(element: HTMLElement) {
    createRoot(element).render(<Root />);
}
