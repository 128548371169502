import * as React from 'react';

import {FloorQuestionType, QuestionType, RootGroupQuestionType} from '../../../enum/question_type';

import {Answer} from '../../../models/answer';
import {Appraisal} from '../../../models/appraisal';
import {FloorContentPresenter} from './floor_content_presenter';
import {FloorType} from '../../../enum/floor_type';
import {PagePart} from '../../../models/page_part';
import {PagePartsSet} from '../../../models/page_parts_set';
import {Question} from '../../../models/question';
import {QuestionContainer} from './questions/question_container';
import {QuestionSet} from '../../../models/question_set';
import {RenderingContextType} from '../../../enum/rendering_context_type';
import {ValidationError} from '../../../models/validation_error';
import {ValidationMessageMap} from '../../../business/validation/validation_message';
import {ValidationMessagesList} from './validation_messages_list';
import {isInEnum} from '../../../../support/is_in_enum';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../support/presenter/use_presenter';
import {QuestionRenderingData} from '../../../models/question_rendering_data';

interface OwnProps {
    floorType: FloorType;
    iteration: string;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    hiddenQuestionTypes: QuestionType[];

    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const FloorContent: React.FC<OwnProps> = observer(function FloorContent(props) {
    const presenter = usePresenter(
        (container) =>
            new FloorContentPresenter(
                props.floorType,
                props.iteration,
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.childQuestionValidationProvider(
                    props.appraisal,
                    props.questionSet,
                    props.validationErrors
                )
            )
    );

    function isRoot(question: Question) {
        return isInEnum(RootGroupQuestionType, question.type) || isInEnum(FloorQuestionType, question.type);
    }

    function renderChildren(answer: Answer) {
        return presenter.children.map((child) => (
            <QuestionContainer
                key={child.uuid}
                iteration={props.iteration}
                appraisal={props.appraisal}
                question={child}
                questionSet={props.questionSet}
                parentAnswerUuid={answer.uuid}
                validationErrors={props.validationErrors}
                validationMessages={props.validationMessages}
                forceShowValidationMessages={props.forceShowValidationMessages}
                hiddenQuestionTypes={props.hiddenQuestionTypes}
                pagePartsSet={props.pagePartsSet}
                activePagePart={props.activePagePart}
                renderingContext={props.renderingContext}
                questionRenderingData={props.questionRenderingData}
            />
        ));
    }

    function renderRoot(question: Question, answer: Answer) {
        return (
            <QuestionContainer
                key={question.uuid}
                iteration={props.iteration}
                appraisal={props.appraisal}
                question={question}
                questionSet={props.questionSet}
                parentAnswerUuid={answer.uuid}
                validationErrors={props.validationErrors}
                validationMessages={props.validationMessages}
                forceShowValidationMessages={props.forceShowValidationMessages}
                hiddenQuestionTypes={props.hiddenQuestionTypes}
                pagePartsSet={props.pagePartsSet}
                activePagePart={props.activePagePart}
                renderingContext={props.renderingContext}
                questionRenderingData={props.questionRenderingData}
            />
        );
    }

    if (presenter.question === undefined) {
        return <div>404 not found</div>;
    }
    if (presenter.answer === undefined) {
        return <div>Loading...</div>;
    }
    return (
        <span>
            <div className="card-header">{presenter.question.contents}</div>
            <div className="card-body">
                <ValidationMessagesList validationMessages={presenter.validationMessages} />
                {isRoot(presenter.question)
                    ? renderRoot(presenter.question, presenter.answer)
                    : renderChildren(presenter.answer)}
            </div>
        </span>
    );
});
