import {ExplanationAppraiserGenerator} from './explanation_generators/explanation_appraiser_generator';
import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';

export class ExplanationAppraiserPresenter implements Presenter {
    constructor(
        private onChange: (value: string) => void,
        private explanationGenerator: ExplanationAppraiserGenerator
    ) {}

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public onGenerateClick(referenceObjectAnswer: ReferenceObjectAnswer) {
        this.onChange(this.explanationGenerator.generate(referenceObjectAnswer));
    }
}
