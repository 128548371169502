import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';

import {Answer} from '../../../models/answer';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {AnswerController} from '../../answering/answer_controller';
import {AnswerValidator} from '../answer_validator';
import {Appraisal} from '../../../models/appraisal';
import {TechnicalReference} from '../../../enum/technical_reference';
import {getNewestAnswer} from '../../../../support/get_newest_answer';

export class KoopovereenkomstValidator implements AnswerValidator {
    constructor(
        private questionSet: QuestionSet,
        private appraisal: Appraisal,
        private answerController: AnswerController
    ) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (question.technicalReference !== TechnicalReference.ATTACHMENT_KOOPOVEREENKOMT) {
            return [];
        }

        if (answer?.file) {
            return [];
        }

        if (this.appraisal.recentlySoldAgreements) {
            return [
                {
                    type: ValidationMessageType.TAXAPI,
                    importance: ValidationMessageImportance.ERROR,
                    question: question,
                    answer: answer,
                    messages: [],
                    fallbackMessage:
                        'Bij het aanmaken van de taxatie is in de opdrachtwaarden aangegeven dat er bij de koop of verkoop van de woning afspraken zijn gemaakt die invloed kunnen hebben op de marktwaarde. Echter er is geen verplichte koopovereenkomst als bijlage toegevoegd.',
                    floorInfo: null,
                },
            ];
        }

        if (this.shouldHaveKoopovereenkomst()) {
            return [
                {
                    type: ValidationMessageType.TAXAPI,
                    importance: ValidationMessageImportance.ERROR,
                    question: question,
                    answer: answer,
                    messages: [],
                    fallbackMessage:
                        'Bij privaatrecht is aangegeven dat er een koopovereenkomst is geraadpleegd. Echter er is geen koopovereenkomst als bijlage toegevoegd.',
                    floorInfo: null,
                },
            ];
        }

        return [];
    }

    private shouldHaveKoopovereenkomst() {
        const consultedKoopovereenkomstQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.CONSULTED_KOOPOVEREENKOMST
        );

        if (!consultedKoopovereenkomstQuestion) {
            return false;
        }

        const answer = getNewestAnswer(
            this.answerController.filterDeleted(
                this.answerController.answersForQuestionUuid(consultedKoopovereenkomstQuestion.uuid)
            )
        );

        if (!answer) {
            return false;
        }

        return answer.contents === '1';
    }
}
