import {Macro, MacroExternalType} from '../../appraising/models/macro';
import {MacroSettings} from '../../appraising/models/macro_settings';
import {ApiMacro, apiMacroToMacro} from '../../appraising/network/models/api_macro';
import {ApiMacroSettings, apiMacroSettingsToMacroSettings} from '../../appraising/network/models/api_macro_settings';
import {AjaxDriver} from '../../network/driver/ajax_driver';

export class MacroApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public async getExternalMacros(
        macroExternalType: MacroExternalType
    ): Promise<{macros: Macro[]; settings: MacroSettings[]}> {
        const response = await this.ajaxDriver.fetch(`/ajax/macros/get-external?external_type=${macroExternalType}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        if (response.ok && response.status === 200) {
            const data = (await response.json()) as {macros: ApiMacro[]; user_macro_settings: ApiMacroSettings[]};
            const macros = data.macros.map((apiMacro) => apiMacroToMacro(apiMacro));
            const settings = data.user_macro_settings.map((apiMacroSettings) =>
                apiMacroSettingsToMacroSettings(apiMacroSettings)
            );
            return {macros, settings};
        }

        throw new Error(response.statusText);
    }

    public async storeExternal(contents: string, externalType: MacroExternalType): Promise<Macro> {
        const response = await this.ajaxDriver.fetch(`/ajax/macros/external`, {
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify({
                contents: contents,
                external_type: externalType,
            }),
            headers: {
                'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        if (response.ok && response.status === 200) {
            const data = await response.json();
            return apiMacroToMacro(data);
        }

        throw new Error(response.statusText);
    }

    public async toggleFavorite(id: number): Promise<MacroSettings> {
        const response = await this.ajaxDriver.fetch(`/ajax/macros/${id}/toggle-favorite`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        if (response.ok && response.status === 200) {
            const data = await response.json();
            return apiMacroSettingsToMacroSettings(data);
        }

        throw new Error(response.statusText);
    }

    public async destroy(id: number): Promise<void> {
        const response = await this.ajaxDriver.fetch(`/ajax/macros/${id}/destroy`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        if (response.ok && response.status === 200) {
            return;
        }

        throw new Error(response.statusText);
    }

    public async hideForUser(id: number): Promise<MacroSettings> {
        const response = await this.ajaxDriver.fetch(`/ajax/macros/${id}/hide-for-user`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        if (response.ok && response.status === 200) {
            const data = await response.json();
            return apiMacroSettingsToMacroSettings(data);
        }

        throw new Error(response.statusText);
    }
}
