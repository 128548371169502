import {BehaviorSubject, Observable} from 'rxjs';
import {NotificationsApi} from '../network/notifications_api';
import {NotificationMessage} from '../models/notification_message';
import {User} from '../support/component';

export interface NotificationsInteractor {
    stream(): Observable<NotificationMessage[]>;
    modalStream(): Observable<NotificationMessage | null>;

    onShowModal(id: string): void;
    onHideModal(): void;
    onReadModal(n: NotificationMessage): Promise<NotificationMessage | null>;

    onLoadNotifications(page: number, limit: number): Promise<void>;
    onRemind(id: string, date: Date): Promise<NotificationMessage | null>;
    onQuickRemind(id: string): Promise<NotificationMessage | null>;
}

export class DefaultNotificationsInteractor implements NotificationsInteractor {
    private notificationsSubject = new BehaviorSubject<NotificationMessage[]>([]);
    private modalSubject = new BehaviorSubject<NotificationMessage | null>(null);

    constructor(user: User | null, private notificationApi: NotificationsApi) {}

    public stream(): Observable<NotificationMessage[]> {
        return this.notificationsSubject.asObservable();
    }

    public modalStream(): Observable<NotificationMessage | null> {
        return this.modalSubject.asObservable();
    }

    public async onLoadNotifications(page: number, limit: number) {
        const notifications = await this.notificationApi.get(page, limit);
        this.notificationsSubject.next(notifications);
    }

    public async onShowModal(id: string) {
        const notification = await this.notificationApi.find(id);
        if (notification) {
            this.modalSubject.next(notification);
        }
    }

    public onHideModal() {
        this.modalSubject.next(null);
    }

    public async onReadModal(n: NotificationMessage) {
        const oldState = this.modalSubject.getValue();
        if (oldState) {
            const newState = {
                ...oldState,
                readAt: new Date(),
            };
            this.modalSubject.next(newState);
        }
        return await this.notificationApi.read(n.id);
    }

    public async onRemind(id: string, date: Date) {
        return await this.notificationApi.remind(id, date);
    }

    public async onQuickRemind(id: string) {
        return await this.notificationApi.quickRemind(id);
    }
}
