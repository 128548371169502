import * as React from 'react';

import {Checkbox} from '../../../inputs/checkbox';
import {FunctionComponent} from 'react';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';
import {Select} from '../../../inputs/select';
import {Textarea} from '../../../inputs/textarea';
import {isEmpty} from '../../../../../../../../../../../../support/util';
import {getPositiveNegativeMacros} from '../../../../internal/positive_negative_macros';

interface OwnProps {
    referenceObjectAnswer: ReferenceObjectAnswer;
    toggleOtherPropertySituation: () => void;
    hasOtherRights: (referenceObjectAnswer: ReferenceObjectAnswer) => boolean;
    hasOtherPropertySituation: boolean;
    validatesOwnershipMessage: string | null;
    onEigendomssituatieVolleEigendomChange: (value: boolean) => void;
    onEigendomssituatieRechtErfpachtChange: (value: boolean) => void;
    onEigendomssituatieRechtOndererfpachtChange: (value: boolean) => void;
    onEigendomssituatieRechtOpstalChange: (value: boolean) => void;
    onEigendomssituatieRechtGebruikBewoningChange: (value: boolean) => void;
    onEigendomssituatieRechtVruchtgebruikChange: (value: boolean) => void;
    onEigendomssituatieRechtAndersChange: (value: boolean) => void;
    onEigendomssituatieToelichtingChange: (value: string) => void;
    onEigendomssituatieStatusChange: (value: string) => void;
    onEigendomssituatieUitlegChange: (value: string) => void;
    onOnderhoudssituatieChange: (value: string) => void;
    onOnderhoudsSituatieStatusChange: (value: string) => void;
    onOnderhoudsSituatieUitlegChange: (value: string) => void;
    isFrozen: boolean;
}

export const Ownership: FunctionComponent<OwnProps> = ({
    referenceObjectAnswer,
    toggleOtherPropertySituation,
    hasOtherPropertySituation,
    hasOtherRights,
    validatesOwnershipMessage,
    onEigendomssituatieVolleEigendomChange,
    onEigendomssituatieRechtErfpachtChange,
    onEigendomssituatieRechtOndererfpachtChange,
    onEigendomssituatieRechtOpstalChange,
    onEigendomssituatieRechtGebruikBewoningChange,
    onEigendomssituatieRechtVruchtgebruikChange,
    onEigendomssituatieRechtAndersChange,
    onEigendomssituatieToelichtingChange,
    onEigendomssituatieStatusChange,
    onEigendomssituatieUitlegChange,
    onOnderhoudssituatieChange,
    onOnderhoudsSituatieStatusChange,
    onOnderhoudsSituatieUitlegChange,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-12">
                <h3>
                    <span style={{color: 'var(--color-primary)'}}>*</span> Eigendomssituatie
                </h3>
            </div>
            <div className="col-6">
                <Checkbox
                    label="Volle eigendom"
                    name="input-ownership-complete"
                    value={validationInstituteReferentieObject.eigendomssituatieVolleEigendom ?? true}
                    onChange={onEigendomssituatieVolleEigendomChange}
                    disabled={isFrozen}
                />
            </div>
            <div className="col-6">
                <span
                    className="btn btn-primary btn-sm ion-md-checkbox-outline pull-right"
                    onClick={() => toggleOtherPropertySituation()}
                >
                    {hasOtherPropertySituation ? 'Verberg meer opties' : 'Bekijk meer opties'}
                </span>
            </div>
            {hasOtherPropertySituation || hasOtherRights(referenceObjectAnswer) ? (
                <>
                    <div className="col-sm-6">
                        <Checkbox
                            label="Recht van erfpacht"
                            name="input-ownership-leasehold"
                            value={validationInstituteReferentieObject.eigendomssituatieRechtErfpacht}
                            onChange={onEigendomssituatieRechtErfpachtChange}
                            disabled={isFrozen}
                        />
                        <Checkbox
                            label="Recht van ondererfpacht"
                            name="input-ownership-under-leasehold"
                            value={validationInstituteReferentieObject.eigendomssituatieRechtOndererfpacht}
                            onChange={onEigendomssituatieRechtOndererfpachtChange}
                            disabled={isFrozen}
                        />
                        <Checkbox
                            label="Recht van opstal"
                            name="input-ownership-building"
                            value={validationInstituteReferentieObject.eigendomssituatieRechtOpstal}
                            onChange={onEigendomssituatieRechtOpstalChange}
                            disabled={isFrozen}
                        />
                    </div>
                    <div className="col-sm-6">
                        <Checkbox
                            label="Recht gebruik woning"
                            name="input-ownership-use"
                            value={validationInstituteReferentieObject.eigendomssituatieRechtGebruikBewoning}
                            onChange={onEigendomssituatieRechtGebruikBewoningChange}
                            disabled={isFrozen}
                        />
                        <Checkbox
                            label="Recht van vruchtgebruik"
                            name="input-ownership-usufruct"
                            value={validationInstituteReferentieObject.eigendomssituatieRechtVruchtgebruik}
                            onChange={onEigendomssituatieRechtVruchtgebruikChange}
                            disabled={isFrozen}
                        />
                        <Checkbox
                            label="Ander recht"
                            name="input-ownership-other"
                            value={validationInstituteReferentieObject.eigendomssituatieRechtAnders}
                            //todo add clearing value of eigendomssituatieToelichting
                            onChange={onEigendomssituatieRechtAndersChange}
                            disabled={isFrozen}
                        />
                    </div>
                    {validationInstituteReferentieObject.eigendomssituatieRechtAnders && (
                        <div className="col-12">
                            <Textarea
                                label="Toelichting ander recht"
                                name="input-ownership-explanation"
                                value={validationInstituteReferentieObject.eigendomssituatieToelichting ?? ''}
                                onChange={onEigendomssituatieToelichtingChange}
                                disabled={isFrozen}
                            />
                        </div>
                    )}
                </>
            ) : null}
            {!isEmpty(validatesOwnershipMessage) ? (
                <div className="col-12">
                    <span className="invalid-feedback">{validatesOwnershipMessage}</span>
                </div>
            ) : null}
            <div className="col-12">
                <Select
                    label="Eigendomsituatie verschil"
                    name="input-ownership"
                    options={['Veel beter', 'Beter', 'Vergelijkbaar', 'Minder', 'Veel minder']}
                    value={validationInstituteReferentieObject.eigendomssituatieStatus ?? ''}
                    onChange={onEigendomssituatieStatusChange}
                    disabled={isFrozen}
                />
                {validationInstituteReferentieObject.eigendomssituatieStatus &&
                validationInstituteReferentieObject.eigendomssituatieStatus !== 'Vergelijkbaar' ? (
                    <div className="row">
                        <div className="col-sm-12">
                            <Textarea
                                isRequired
                                label="Eigendomsituatie verschil toelichting"
                                name="input-object-type-explanation"
                                value={validationInstituteReferentieObject.eigendomssituatieUitleg ?? ''}
                                onChange={onEigendomssituatieUitlegChange}
                                disabled={isFrozen}
                                maxLength={1000}
                            />
                        </div>
                    </div>
                ) : null}
            </div>

            <div className="col-12">
                <Select
                    label="Onderhoudssituatie"
                    name="input-maintenance"
                    options={['Goed', 'Redelijk', 'Matig', 'Slecht']}
                    value={validationInstituteReferentieObject.onderhoudssituatie ?? 'Goed'}
                    onChange={onOnderhoudssituatieChange}
                    disabled={isFrozen}
                />
            </div>

            <div className="col-12">
                <Select
                    label="Onderhoudssituatie verschil"
                    name="input-maintenance-situtation"
                    options={['Veel beter', 'Beter', 'Vergelijkbaar', 'Minder', 'Veel minder']}
                    value={validationInstituteReferentieObject.onderhoudsSituatieStatus}
                    onChange={onOnderhoudsSituatieStatusChange}
                    disabled={isFrozen}
                />
            </div>

            {validationInstituteReferentieObject.onderhoudsSituatieStatus !== 'Vergelijkbaar' ? (
                <div className="col-sm-12">
                    <Textarea
                        isRequired
                        label="Onderhoudssituatie verschil toelichting"
                        name="input-maintenance-situtation-explanation"
                        value={validationInstituteReferentieObject.onderhoudsSituatieUitleg ?? ''}
                        onChange={onOnderhoudsSituatieUitlegChange}
                        macros={getPositiveNegativeMacros(
                            referenceObjectAnswer.referenceObject.onderhoudsSituatieStatus,
                            [
                                'Het onderhoud van het referentieobject is in zijn algemeenheid beter t.o.v. het getaxeerde object',
                            ],
                            [
                                'Het onderhoud van het referentieobject is in zijn algemeenheid minder t.o.v. het getaxeerde object',
                            ]
                        )}
                        disabled={isFrozen}
                        maxLength={1000}
                    />
                </div>
            ) : null}
        </div>
    );
};
