import {makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {Presenter} from '../../../support/presenter/presenter';
import {PagePartsSet} from '../../models/page_parts_set';
import {PagePartsSetInteractor} from '../../business/page_parts/page_parts_set_interactor';

export class ActivePagePartsSetLoaderPresenter implements Presenter {
    @observable public pagePartsSets: PagePartsSet[] | null = null;
    private subscriptions = new CompositeSubscription();

    constructor(private pagePartsSetInteractor: PagePartsSetInteractor) {
        makeObservable(this);
    }

    public async mount() {
        this.subscriptions.add(
            this.pagePartsSetInteractor.pagePartsSetsStream().subscribe((pagePartsSets) => {
                runInAction(() => {
                    this.pagePartsSets = pagePartsSets;
                });
            })
        );
    }

    public async unmount() {
        this.subscriptions.clear();
    }
}
