import {IteratorQuestionType} from '../enum/question_type';
import {
    QuestionAnswerPair,
    buildQuestionAnswerTree,
    buildQuestionAnswerTrees,
} from '../../support/question_answer_tree';
import {TreeItem, findChildRecursiveByPredicate} from '../../support/generic_tree';

import {Answer} from '../models/answer';
import {AnswerController} from './answering/answer_controller';
import {AnswerPathStubber} from './answering/answer_path_stubber';
import {Observable} from 'rxjs';
import {QuestionEffectInteractor} from './conditions/question_effects_interactor';
import {QuestionSet} from '../models/question_set';
import {TechnicalReference} from '../enum/technical_reference';
import {UserSettingsInteractor} from './user_settings/user_settings_interactor';
import {map, shareReplay} from 'rxjs/operators';
import {AnswerTouchState} from '../enum/answer_touch_state';

export interface FloorInfo {
    name: string | null;
    iteration: string;
    shortName: string;
    sortValue: number;
    floorType: FloorTypeAnswerOptionContents | null;
    floorTypeAnswerUuid: string | null;
    floorNumber: number | null;
}

export enum FloorTypeAnswerOptionContents {
    KELDER = 'Kelder',
    SOUTERRAIN = 'Souterrain',
    BEGANE_GROND = 'Begane grond',
    VERDIEPING = 'Verdieping',
    ZOLDER = 'Zolder',
}

export class FloorInteractor {
    constructor(
        private questionSet: QuestionSet,
        private userSettingsInteractor: UserSettingsInteractor,
        private answerController: AnswerController,
        private answerPathStubber: AnswerPathStubber,
        private questionEffectInteractor: QuestionEffectInteractor
    ) {}

    private _floorIterationObservable: Observable<string | null> | null = null;

    public getFloorIteration(): Observable<string | null> {
        if (this._floorIterationObservable === null) {
            this._floorIterationObservable = this.userSettingsInteractor.getSettingsStream().pipe(
                map((settings) => settings.floorIteration),
                shareReplay(1)
            );
        }
        return this._floorIterationObservable;
    }

    public setFloorIteration(floorIteration: string) {
        this.userSettingsInteractor.setSettings({floorIteration: floorIteration});
    }

    private floorInfoFromTree(tree: TreeItem<QuestionAnswerPair>): FloorInfo | null {
        const iteration = tree.item.answer?.iteration;
        const floorType = findChildRecursiveByPredicate(
            tree,
            (i) => i.question.technicalReference === TechnicalReference.FLOOR_TYPE
        );
        const floorNumber = findChildRecursiveByPredicate(
            tree,
            (i) => i.question.technicalReference === TechnicalReference.FLOOR_NUMBER
        );

        if (iteration) {
            const selectedFloorTypeAnswerOption = floorType?.item.question.answerOptions.find(
                (ao) => ao.id === floorType?.item.answer?.answerOptionId
            );
            const floorNumberAnswerOption = floorNumber?.item.question.answerOptions.find(
                (ao) => ao.id === floorNumber.item.answer?.answerOptionId
            );

            let name = `Verdieping - ${iteration}`;
            if (selectedFloorTypeAnswerOption) {
                name = selectedFloorTypeAnswerOption?.contents;
                if (floorNumberAnswerOption && name.toLowerCase() !== 'begane grond') {
                    name += ' - ' + floorNumberAnswerOption.contents;
                }
            }

            let shortName = iteration;
            if (name.toLowerCase() === 'begane grond') {
                shortName = 'BG';
            } else if (floorNumberAnswerOption) {
                if (name.toLowerCase().startsWith('verdieping - ')) {
                    shortName = floorNumberAnswerOption.contents;
                } else {
                    shortName = name[0].toUpperCase() + floorNumberAnswerOption.contents;
                }
            }

            let sortValue = parseInt(iteration, 10);
            if (shortName === 'BG') {
                sortValue = 0;
            } else if (floorNumberAnswerOption) {
                sortValue = parseInt(floorNumberAnswerOption.contents, 10);
            }

            return {
                name,
                iteration,
                shortName,
                sortValue,
                floorTypeAnswerUuid: floorType?.item.answer?.uuid ?? null,
                floorType: (selectedFloorTypeAnswerOption?.contents as FloorTypeAnswerOptionContents) ?? null,
                floorNumber: floorNumberAnswerOption?.contents ? parseInt(floorNumberAnswerOption.contents) : null,
            };
        }

        return null;
    }

    public getFloorsStream(): Observable<FloorInfo[]> {
        const floorTypeQuestion = this.questionSet.findQuestionByTechnicalReference(TechnicalReference.FLOOR_TYPE);
        const floorNumberQuestion = this.questionSet.findQuestionByTechnicalReference(TechnicalReference.FLOOR_NUMBER);
        if (!floorTypeQuestion || !floorNumberQuestion) {
            throw new Error('No FLOOR_TYPE question or no FLOOR_NUMBER question defined in questionSet');
        }

        const floorTypeParentPath = this.questionSet.findParentPathByPredicateRecursive(
            floorTypeQuestion,
            (q) => q.type === IteratorQuestionType.PAGE_PART_FLOORS
        );
        const floorNumberParentPath = this.questionSet.findParentPathByPredicateRecursive(
            floorNumberQuestion,
            (q) => q.type === IteratorQuestionType.PAGE_PART_FLOORS
        );
        if (!floorTypeParentPath || !floorNumberParentPath) {
            throw new Error('Either the FLOOR_TYPE or the FLOOR_NUMBER couldnt find their PAGE_PART_FLOORS parent.');
        }

        const questionUuids = [
            ...new Set([
                ...floorTypeParentPath.map((q) => q.uuid),
                ...floorNumberParentPath.map((q) => q.uuid),
            ]).values(),
        ];

        return this.answerController.answersForQuestionUuidsStream(questionUuids).pipe(map(() => this.getFloors()));
    }

    public getFloors(): FloorInfo[] {
        const floorTypeQuestion = this.questionSet.findQuestionByTechnicalReference(TechnicalReference.FLOOR_TYPE);
        if (!floorTypeQuestion) {
            throw new Error('No FLOOR_TYPE question or no FLOOR_NUMBER question defined in questionSet');
        }

        const floorTypeParentPath = this.questionSet.findParentPathByPredicateRecursive(
            floorTypeQuestion,
            (q) => q.type === IteratorQuestionType.PAGE_PART_FLOORS
        );
        if (!floorTypeParentPath) {
            throw new Error('The FLOOR_TYPE couldnt find their PAGE_PART_FLOORS parent.');
        }

        const floorInfos: FloorInfo[] = [];

        const answers = this.answerController.filterDeleted(this.answerController.answers()).filter((answer) => {
            return !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid);
        });
        const trees = buildQuestionAnswerTrees(this.questionSet, answers, floorTypeParentPath[0]);

        for (const tree of trees) {
            const floorInfo = this.floorInfoFromTree(tree);
            if (floorInfo) {
                floorInfos.push(floorInfo);
            }
        }

        return floorInfos
            .sort((a, b) => a.sortValue - b.sortValue)
            .sort((a, b) => this.floorSortValue(a.floorType) - this.floorSortValue(b.floorType));
    }

    private floorSortValue(floorType: FloorTypeAnswerOptionContents | null) {
        switch (floorType) {
            case FloorTypeAnswerOptionContents.KELDER:
                return 0;
            case FloorTypeAnswerOptionContents.SOUTERRAIN:
                return 1;
            case FloorTypeAnswerOptionContents.BEGANE_GROND:
                return 2;
            default:
            case null:
            case FloorTypeAnswerOptionContents.VERDIEPING:
                return 3;
            case FloorTypeAnswerOptionContents.ZOLDER:
                return 4;
        }
    }

    public addFloor(floorType: FloorTypeAnswerOptionContents, floorNumber?: string): string | null {
        const pagePartFloorsQuestions = this.questionSet.findQuestionsByType(IteratorQuestionType.PAGE_PART_FLOORS);
        if (pagePartFloorsQuestions.length !== 1) {
            throw new Error('Amount of IteratorQuestionType.PAGE_PART_FLOORS should be 1, not more no less!');
        }
        const pagePartFloorsQuestionChildren = this.questionSet.findChildQuestionsByParentUuid(
            pagePartFloorsQuestions[0].uuid
        );
        const floorTypeQuestion = pagePartFloorsQuestionChildren.find(
            (q) => q.technicalReference === TechnicalReference.FLOOR_TYPE
        );
        const floorNumberQuestion = pagePartFloorsQuestionChildren.find(
            (q) => q.technicalReference === TechnicalReference.FLOOR_NUMBER
        );
        if (!floorTypeQuestion || !floorNumberQuestion) {
            throw new Error(
                'Invalid questionSet, we need a IteratorQuestionType.PAGE_PART_FLOORS with atleast the following 2 children: TechnicalReference.FLOOR_TYPE & TechnicalReference.FLOOR_NUMBER'
            );
        }

        //Determine our new iteration number (not relevat to the actual floor #. It simple keeps incrementing)
        const floorIterationAnswers = this.answerController.answersForQuestionUuid(pagePartFloorsQuestions[0].uuid);
        const floorIterations = floorIterationAnswers
            .map((fia) => (fia.iteration ? parseInt(fia.iteration, 10) : null))
            .filter((i): i is number => i !== null);
        const highestFloorIteration = Math.max(0, ...floorIterations);
        const newIteration = String(highestFloorIteration + 1);

        //Stub the FLOOR_TYPE & FLOOR_NUMBER questions while creating the new floor iteration
        this.answerPathStubber.stubPathForQuestionUuid(
            floorTypeQuestion.uuid,
            [
                {
                    questionUuid: pagePartFloorsQuestions[0].uuid,
                    iteration: newIteration,
                },
            ],
            {
                [floorTypeQuestion.uuid]: floorType,
            },
            true
        );
        this.answerPathStubber.stubPathForQuestionUuid(
            floorNumberQuestion.uuid,
            [
                {
                    questionUuid: pagePartFloorsQuestions[0].uuid,
                    iteration: newIteration,
                },
            ],
            {
                [floorNumberQuestion.uuid]: floorNumber !== undefined ? floorNumber : undefined,
            },
            true
        );

        return newIteration;
    }

    public removeFloor(floorIteration: string) {
        const floorQuestions = this.questionSet.findQuestionsByType(IteratorQuestionType.PAGE_PART_FLOORS);
        const iteratorQuestions = this.questionSet.findQuestionsByType(IteratorQuestionType.PAGE_PART_ITERATOR);
        const questions = [...floorQuestions, ...iteratorQuestions];

        const possibleAnswers = this.answerController.answersForQuestionUuids(questions.map((q) => q.uuid));
        const floorIterationAnswers = possibleAnswers.filter((a) => a.iteration === floorIteration);
        const allAnswers = this.answerController.answers();

        for (const floorIterationAnswer of floorIterationAnswers) {
            const tree = buildQuestionAnswerTree(this.questionSet, allAnswers, floorIterationAnswer);
            this.answerController.deleteTree(tree);
        }
    }

    public changeFloorType(floorTypeAnswerUuid: string, floorType: FloorTypeAnswerOptionContents): Answer | null {
        const answer = this.answerController.byUuid(floorTypeAnswerUuid);
        if (answer) {
            const question = this.questionSet.findQuestionByUuid(answer.questionUuid);
            if (question?.technicalReference === TechnicalReference.FLOOR_TYPE) {
                const newAnswerOption = question.answerOptions.find((ao) => ao.contents === floorType);
                if (newAnswerOption) {
                    return this.answerController.onAnswerOptionChange(
                        floorTypeAnswerUuid,
                        newAnswerOption.id,
                        AnswerTouchState.TOUCHED
                    );
                }
            }
        }
        return null;
    }
}
