import * as React from 'react';

import {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import {classNames} from '../../../../support/classnames';

interface OwnProps {
    uuid: string;
    pagePartsConfigurationId: number;
    label: string;
    isActive: boolean;
    hasVisited: boolean;
    hasError: boolean;
    onDelete: () => void;
}

export const ChildSidebarItem: FunctionComponent<OwnProps> = ({
    uuid,
    pagePartsConfigurationId,
    label,
    isActive,
    hasVisited,
    hasError,
    onDelete,
}) => {
    return (
        <div title={label} className="child-item card-progress-small">
            <div className="card-progress-content">
                <Link
                    to={`/page-parts-configuration/${pagePartsConfigurationId}/${uuid}`}
                    className={classNames('child-item', {
                        active: isActive,
                        visited: hasVisited,
                        'has-error': hasError,
                    })}
                >
                    <span className="title">{label}</span>
                </Link>
                <button className="btn-delete-page-part btn btn-delete btn-sm ion-md-trash" onClick={onDelete} />
            </div>
        </div>
    );
};
