import {AnswerController} from '../../../../../business/answering/answer_controller';
import {IteratorQuestionType, NormalQuestionType} from '../../../../../enum/question_type';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {computed, makeObservable, observable, runInAction} from 'mobx';
import {Answer} from '../../../../../models/answer';
import {FileReference} from '../../../../../models/file_reference';
import {isSet} from '../../../../../../support/is_set';
import {Presenter} from '../../../../../../admin/support/presenter';
import {map, switchMap} from 'rxjs/operators';
import {sortByCreatedAt, sortByRank} from '../../../../../../support/sort_answer';
import {combineLatest, of} from 'rxjs';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';

export class PhotosViewerPresenter implements Presenter {
    @observable.ref private images: Answer[] = [];
    @computed public get fileReferences() {
        return this.images.map((img) => img.file).filter((file): file is FileReference => isSet(file));
    }

    private subscriptions = new CompositeSubscription();

    constructor(
        private question: Question,
        private questionSet: QuestionSet,
        private answerController: AnswerController
    ) {
        makeObservable(this);
    }

    public async mount() {
        /**
         * Retrieve all PHOTO_ITERATOR from the questionSet
         * Get all the answers on those iterators, filter deleted out and sort them
         * Get from those answers their questions -> their answers are the photos
         * Get all multiple choice child questions on the photo questions (the photo titles)
         * Make sure that for each photo there is at least one multiple choice answer,
         * that has a technical reference matching the technical reference of this photo viewer.
         */
        const photoIterators = this.questionSet.findQuestionsByType(IteratorQuestionType.PHOTO_ITERATOR);

        this.answerController
            .answersForQuestionUuidsStream(photoIterators.map((photoIterator) => photoIterator.uuid))
            .pipe(
                map((iteratorPhotoGroups: Answer[]) =>
                    iteratorPhotoGroups
                        .filter((iterator) => !iterator.isDeleted)
                        .sort(sortByCreatedAt)
                        .sort(sortByRank)
                ),
                switchMap((iteratorPhotoGroups) => {
                    if (iteratorPhotoGroups.length === 0) {
                        return of([]);
                    }

                    return combineLatest(
                        iteratorPhotoGroups.map((iteratorPhotoGroup) => {
                            const photoQuestion = this.questionSet
                                .findChildQuestionsByParentUuid(iteratorPhotoGroup.questionUuid)
                                .find((q) => q.type === NormalQuestionType.PHOTO);
                            if (!photoQuestion) {
                                return of(null);
                            }

                            return this.answerController.answerByIdentifiersStream(
                                photoQuestion.uuid,
                                iteratorPhotoGroup.uuid,
                                null
                            );
                        })
                    );
                }),
                map((photoAnswers) =>
                    photoAnswers.filter((answer): answer is Answer => {
                        if (answer === null) {
                            return false;
                        }

                        // Find all multiple choice child questions of the photo question
                        // This will be the selector to pick the title of the photo
                        const questions = this.questionSet
                            .findChildQuestionsByParentUuid(answer.questionUuid)
                            .filter((q) => q.type === NormalQuestionType.MC_SELECT);

                        if (questions.length === 0) {
                            return false;
                        }

                        // Get for each answer option of the multiple choice questions
                        // the allowed answers: answers where the technical reference equals the reference of the photo viewer
                        const allowedAnswerOptions = questions
                            .flatMap((q) => q.answerOptions)
                            .filter((option) =>
                                option.technicalReference.some((ref) => ref === this.question.technicalReference)
                            )
                            .map((o) => o.id);

                        // Get all child answers (answers to the multiple choice questions)
                        const children = this.answerController.answersByParentAnswerUuid(answer.uuid);

                        // Check that there is at least one multiple choice question that has a matching answer
                        return children.some(
                            (a) =>
                                questions.some((q) => q.uuid === a.questionUuid) &&
                                a.answerOptionId &&
                                allowedAnswerOptions.includes(a.answerOptionId)
                        );
                    })
                )
            )
            .subscribe((photoAnswers) => {
                runInAction(() => {
                    this.images = photoAnswers;
                });
            });
    }

    public async unmount() {
        this.subscriptions.clear();
    }
}
