import {ReferenceSaleImage} from '../../appraise/ui/content/questions/advanced/reference_objects_question/v1/models/reference_sale_image';

export interface ApiReferenceSaleImage {
    url: string | null;
    key: string | null;
    source: ReferenceSaleImageSource | null;
    date: string | null;
    label: string | null;
    confidence: number | null;
}

export enum ReferenceSaleImageSource {
    ALTUM = 'altum',
    MATRIXIAN = 'matrixian',
    JAAP = 'jaap',
    OOZO = 'oozo',
    BRAINBAY = 'brainbay',
}

export function apiReferenceSaleImagesToReferenceSaleImages(
    apiReferenceSaleImages: ApiReferenceSaleImage[]
): ReferenceSaleImage[] {
    return apiReferenceSaleImages.map((apiReferenceObject) =>
        apiReferenceSaleImageToReferenceSaleImage(apiReferenceObject)
    );
}

export function apiReferenceSaleImageToReferenceSaleImage(
    apiReferenceObject: ApiReferenceSaleImage
): ReferenceSaleImage {
    return {
        url: apiReferenceObject.url,
        key: apiReferenceObject.key,
        source: apiReferenceObject.source,
        date: apiReferenceObject.date ? new Date(apiReferenceObject.date) : null,
        label: apiReferenceObject.label,
        confidence: apiReferenceObject.confidence,
    };
}

export function referenceSaleImagesToApiReferenceSaleImages(
    referenceSaleImages: ReferenceSaleImage[]
): ApiReferenceSaleImage[] {
    return referenceSaleImages.map((referenceSaleImage) =>
        referenceSaleImageToApiReferenceSaleImage(referenceSaleImage)
    );
}

export function referenceSaleImageToApiReferenceSaleImage(
    referenceSaleImage: ReferenceSaleImage
): ApiReferenceSaleImage {
    return {
        url: referenceSaleImage.url,
        key: referenceSaleImage.key,
        source: referenceSaleImage.source,
        date: referenceSaleImage.date ? referenceSaleImage.date.toISOString() : null,
        label: referenceSaleImage.label,
        confidence: referenceSaleImage.confidence,
    };
}
