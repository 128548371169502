import React from 'react';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';
import {Loader} from '../../../../../../../components/loader';
import {Modal} from '../../../../../components/modal';
import {AltumPreferencesModalPresenter} from './altum_preferences_modal_presenter';
import {Appraisal} from '../../../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {AltumSettings} from '../../../../../../../../../network/preselected_reference_objects_api';
import {AltumPreferences} from '../altum_preferences';

interface OwnProps {
    appraisal?: Appraisal;
    questionSet?: QuestionSet;
    visible?: boolean;

    onClose: () => void;
    forceRequest: (preferences: AltumSettings) => void;
    changeObjectType?: () => void;
}

export const AltumPreferencesModal: React.FC<OwnProps> = ({
    onClose,
    forceRequest,
    appraisal,
    questionSet,
    visible,
    changeObjectType,
}) => {
    const presenter = usePresenter(
        (container) =>
            new AltumPreferencesModalPresenter(
                container.business.appraisalProvider,
                container.network.referenceObjectApi
            )
    );

    return (
        <Modal visible={visible ?? true} wide onHide={onClose}>
            <div className="modal-header">
                <h2>Voorkeuren taXinder aanpassen</h2>
            </div>
            <div className="modal-body" style={{minWidth: 500}}>
                <AltumPreferences
                    forceRequest={forceRequest}
                    updatePreferences={presenter.updatePreferences}
                    questionSet={questionSet}
                    appraisal={appraisal}
                    isInModal={true}
                    changeObjectType={changeObjectType}
                />
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary" onClick={() => onClose()}>
                    Sluiten
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() =>
                        presenter.savePreferences().then(() => {
                            onClose();

                            if (presenter.preferences) {
                                forceRequest(presenter.preferences);
                            }
                        })
                    }
                >
                    {presenter.saving ? <Loader /> : 'Opslaan'}
                </button>
            </div>
        </Modal>
    );
};
