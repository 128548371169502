import * as React from 'react';

interface OwnProps {
    name: string;
    label: string;
    options: string[] | {[key: string]: string};
    value: string | null;
    isRequired?: boolean;
    emptyPlaceholder?: string;
    badge?: JSX.Element | null;
    otherText?: string;
    disabled?: boolean;
    fallback?: string;
    onChange: (e: string) => void;
}

export class Select extends React.Component<OwnProps> {
    private toObject(options: string[]): {[key: string]: string} {
        const result: {[key: string]: string} = {};

        options.forEach((option) => (result[option] = option));

        return result;
    }

    private getValueForSelect(value: string | null, options: {[key: string]: string}) {
        if (value === null) {
            return '-1';
        }
        if (
            Object.keys(options)
                .map((key) => options[key])
                .indexOf(value) === -1 &&
            this.props.otherText
        ) {
            return '__other';
        }

        return value;
    }

    private renderOtherInput() {
        return (
            <>
                <span className="spacer-small" />
                <input
                    className="form-control"
                    type="text"
                    disabled={this.props.disabled}
                    value={this.props.value || undefined}
                    onChange={(e) => this.props.onChange(e.target.value)}
                />
            </>
        );
    }

    public render() {
        const options: {[key: string]: string} = Array.isArray(this.props.options)
            ? this.toObject(this.props.options)
            : this.props.options;

        const valueForSelect = this.getValueForSelect(this.props.value, options);
        const select = (
            <select
                name={this.props.name}
                id={this.props.name}
                className="form-control custom-select"
                value={valueForSelect}
                disabled={this.props.disabled === true}
                onChange={(e) => this.props.onChange(e.target.value === '__other' ? '' : e.target.value)}
            >
                {this.props.emptyPlaceholder ? (
                    <option disabled={this.props.value !== null}>{this.props.emptyPlaceholder}</option>
                ) : null}
                {Object.keys(options)
                    .map((key) => options[key])
                    .map((text) => (
                        <option key={text} value={text}>
                            {text}
                        </option>
                    ))}
                {this.props.otherText !== undefined ? <option value="__other">{this.props.otherText}</option> : null}
            </select>
        );
        return (
            <div className="form-group">
                <label htmlFor={this.props.name}>
                    {this.props.label}
                    {this.props.isRequired ? <span className="form-label-highlight">*</span> : null}
                </label>
                {this.props.badge === undefined ? (
                    select
                ) : (
                    <span className="row">
                        <span className="col-8">{select}</span>
                        <span className="col-4">
                            <span className="form-badge">{this.props.badge}</span>
                        </span>
                    </span>
                )}
                {this.props.otherText !== undefined && valueForSelect === '__other' ? this.renderOtherInput() : null}
            </div>
        );
    }
}
