import {ParentlessSetDefinition} from '../reference_objects_question_presenter';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';

export interface SuggestedReferenceObjectAnswer {
    setDefinition: ParentlessSetDefinition;
    answers: ReferenceObjectAnswer[];
}

export interface SuggestedReferenceObjectAnswersCalculator {
    get(
        referenceObjectAnswers: ReferenceObjectAnswer[],
        setDefinitions: ParentlessSetDefinition[],
        excludedValuationType: string
    ): SuggestedReferenceObjectAnswer[];
}

export class DefaultSuggestedReferenceObjectAnswersCalculator implements SuggestedReferenceObjectAnswersCalculator {
    public get(
        referenceObjectAnswers: ReferenceObjectAnswer[],
        setDefinitions: ParentlessSetDefinition[],
        excludedValuationType: string
    ): SuggestedReferenceObjectAnswer[] {
        const pairs = setDefinitions.map((setDefinition) => {
            return {
                setDefinition,
                answers: referenceObjectAnswers.filter((a) => a.valuationType === setDefinition.valuationType),
            };
        });

        return pairs.filter(
            (pair) => pair.answers.length > 0 && pair.setDefinition.valuationType !== excludedValuationType
        );
    }
}
