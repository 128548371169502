import {makeObservable, observable} from 'mobx';

import {Presenter} from '../../../../../../../../support/presenter/presenter';
import {PagePartsSet} from '../../../../../../../models/page_parts_set';
import {Question} from '../../../../../../../models/question';
import {QuestionSet} from '../../../../../../../models/question_set';
import {PagePartConfigurationApi} from '../../../../../../../network/page_part_configuration_api';
import {isCustomizableQuestion} from './customizations_container';

export class PagePartCustomizationsPresenter implements Presenter {
    @observable.ref public customizableQuestions: Question[] = [];

    constructor(
        private question: Question,
        private questionSet: QuestionSet,
        private pagePartsSet: PagePartsSet,
        private pagePartConfigurationApi: PagePartConfigurationApi
    ) {
        const allChildren = this.questionSet.flattenChildrenRecursively(this.question.uuid);
        this.customizableQuestions = allChildren.filter(isCustomizableQuestion);

        makeObservable(this);
    }

    public mount() {
        // No-op
    }

    public unmount() {
        // No-op
    }

    public async save() {
        await this.pagePartConfigurationApi.storeCustomizations(
            this.pagePartsSet.id,
            this.pagePartsSet.customizations.customizations
        );
    }
}
