import {makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../support/composite_subscription';
import {NotificationMessage} from '../models/notification_message';
import {NotificationsInteractor} from '../interactors/notifications_interactor';
import {NotificationsStackInteractor} from '../interactors/notifications_stack_interactor';
import {Presenter} from '../support/presenter';

export class NotificationsWidgetPresenter implements Presenter {
    @observable.ref public notifications: NotificationMessage[] = [];
    @observable public loading = true;
    @observable public page = 1;

    private subscriptions = new CompositeSubscription();
    constructor(
        private notificationsInteractor: NotificationsInteractor,
        private notificationsStackInteractor: NotificationsStackInteractor
    ) {
        makeObservable(this);
    }

    public async mount() {
        this.subscriptions.add(
            this.notificationsInteractor.stream().subscribe((notifications) => {
                runInAction(() => {
                    this.notifications = notifications ?? [];
                });
            })
        );

        this.subscriptions.add(
            this.notificationsStackInteractor.stream().subscribe((notifications) => {
                if (notifications.length > 0) {
                    this.notificationsInteractor.onLoadNotifications(1, 5);
                }
            })
        );

        try {
            await this.notificationsInteractor.onLoadNotifications(1, 5);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    public unmount() {
        this.subscriptions.clear();
    }

    public onShowModal(id: string) {
        this.notificationsInteractor.onShowModal(id);
    }

    public async onLoadNotificationsPage(page: number) {
        runInAction(() => {
            this.page = page;
        });
        await this.notificationsInteractor.onLoadNotifications(page, 5);
    }

    public async onQuickRemind(id: string) {
        await this.notificationsInteractor.onQuickRemind(id);
        this.onLoadNotificationsPage(this.page);
    }
}
