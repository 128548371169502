import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';

import {Label} from '../components/label';
import {Select} from '../../../components/select';
import {ValidationMessages} from '../components/validation_messages';
import {classNames} from '../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const MultipleChoiceSelectOptionalQuestion: React.FC<OwnProps> = observer(
    function MultipleChoiceSelectOptionalQuestion(props) {
        const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));

        if (presenter.isHidden) {
            return null;
        }

        const answer = presenter.answer;
        const value = answer && answer.answerOptionId !== null ? '' + answer.answerOptionId : '';

        const options = presenter.answerOptions.map((answerOption) => {
            return {
                value: String(answerOption.id),
                label: answerOption.contents,
            };
        });

        // Only add this empty value when there is no "not applicable" option.
        const hasNotApplicableOption =
            presenter.answerOptions.find((o) => o.contents.includes('niet van toepassing')) !== undefined;
        if (!hasNotApplicableOption) {
            options.unshift({
                value: '-1',
                label: '- niet van toepassing -',
            });
        }

        function handleChange(value: string | null) {
            const parsedValue = value ? parseInt(value, 10) : null;
            if (parsedValue !== -1) {
                presenter.onAnswerOptionChange(parsedValue);
            } else {
                // Try to set the "not applicable" option when available. Otherwise set null.
                const notApplicableOption = presenter.answerOptions.find((o) =>
                    o.contents.includes('niet van toepassing')
                );
                presenter.onAnswerOptionChange(notApplicableOption?.id ?? null);
            }
        }

        return (
            <div
                className={classNames('form-group', presenter.filledByAutomator, {
                    'automation-filled': presenter.filledByAutomator !== null,
                })}
                data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
            >
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <Select
                    id={presenter.name}
                    value={value || '-1'}
                    isDisabled={presenter.isDisabled || props.disabled === true}
                    onChange={(newValue) => handleChange(newValue)}
                    options={options}
                />

                <ValidationMessages
                    disabled={props.disabled || presenter.isDisabled}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    answer={presenter.answer}
                    validationMessages={presenter.validationMessages}
                />
            </div>
        );
    }
);
