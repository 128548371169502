import * as React from 'react';

export class DarkTheme extends React.Component {
    public render() {
        const cssString = `:root {
            --color-primary: #5F5F68;
            --color-primary-darken: #5A5A64;
            --color-primary-lighten: #707078;
            --color-secondary: #878790;
            --color-secondary-darken: #7B7B83;
            --color-secondary-lighten: #91919B;
            --color-primary-navbar: #5F5F68;
            --color-secondary-navbar: #878790;
        }`;
        return <style>{cssString}</style>;
    }
}
