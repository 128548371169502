import {filter, map, shareReplay, startWith} from 'rxjs/operators';

import {Answer} from '../../../models/answer';
import {AnswerRegistry} from './answer_registry';
import {DeletedAnswersFilter} from './deleted_answers_filter';
import {HasQuestionAnswerFilter} from './has_question_answer_filter';
import {ImageUploadState} from '../../../appraise/ui/content/questions/advanced/attachment_question_presenter';
import {ServerTimeProvider} from '../../../server_time/server_time_provider';
import {bugsnagClient} from '../../../../support/bugsnag_client';
import {Observable, combineLatest, concat, defer, fromEventPattern, of} from 'rxjs';
import {createAnswerHash} from '../../support/create_answer_hash';
import {floorSeconds} from '../../../../support/floor_seconds';
import {lazy} from '../../../../support/lazy';
import {parseImageAnswerContents} from '../../support/parse_image_answer_contents';
import {toJS} from 'mobx';
import {ObservableMemoryStorage, StorageEventType, StorageEvent} from 'indexed-memory-storage';
import {
    parentUuidAnswerIndex,
    questionUuidIndex,
} from '../../../../support/memory_storage/default_observable_answer_memory_storage_factory';
import {HouseFilter} from './house_filter';

type Listener = (event: StorageEvent<Answer>) => void;
export class DefaultAnswerRegistry implements AnswerRegistry {
    /**
     * We use a custom events setup here because registrating all subscribers to a single Subject within RxJS is really inefficient.
     * This is because subscribers to a subject are kept in an array in RxJS, which means that when removing a subscriber,
     * things get really slow, especially for the number of subscribers (>6000) that we regularly have
     */
    private _events = {
        listeners: new Set<Listener>(),
        addListener: (listener: Listener) => {
            this._events.listeners.add(listener);
        },
        removeListener: (listener: Listener) => {
            this._events.listeners.delete(listener);
        },
        dispatch: (event: StorageEvent<Answer>) => {
            for (const listener of this._events.listeners) {
                listener(event);
            }
        },
    };

    private _isPaused = false;

    constructor(
        initialAnswers: ReadonlyArray<Answer>,
        private deletedAnswersFilter: DeletedAnswersFilter,
        private hasQuestionAnswerFilter: HasQuestionAnswerFilter,
        private houseFilter: HouseFilter,
        private serverTimeProvider: ServerTimeProvider,
        private answerMemoryStorage: ObservableMemoryStorage<Answer>
    ) {
        this.replace(hasQuestionAnswerFilter.filter(initialAnswers.map((a) => toJS(a))));

        this.answerMemoryStorage.eventsStream.subscribe((event) => {
            this._events.dispatch(event);
        });
    }

    @lazy()
    public get stream(): Observable<Answer[]> {
        return fromEventPattern(this._events.addListener, this._events.removeListener).pipe(
            filter(() => !this.isPaused),
            map(() => this.answerMemoryStorage.all()),
            startWith(this.answerMemoryStorage.all()),
            shareReplay(1)
        );
    }

    public get list(): Answer[] {
        return this.answerMemoryStorage.all();
    }

    public push(answer: Answer): Answer | null {
        const updatedAnswer = this.filter(answer);
        if (updatedAnswer) {
            this.answerMemoryStorage.add(updatedAnswer);
        }
        return updatedAnswer;
    }

    public streamByQuestionUuid(questionUuid: string): Observable<Answer[]> {
        const stream = fromEventPattern<StorageEvent<Answer>>(
            this._events.addListener,
            this._events.removeListener
        ).pipe(
            filter((event) => {
                if (this.isPaused) {
                    return false;
                }
                switch (event.type) {
                    case StorageEventType.ADDED:
                    case StorageEventType.DELETED:
                    case StorageEventType.UPDATED:
                        return event.item.questionUuid === questionUuid;
                    case StorageEventType.ADDED_MULTIPLE:
                    case StorageEventType.DELETED_MULTIPLE:
                    case StorageEventType.UPDATED_MULTIPLE:
                        return event.items.some((a) => a?.questionUuid === questionUuid);
                    case StorageEventType.STORAGE_CLEARED:
                        return true;
                }
            }),
            map(() => this.getByQuestionUuid(questionUuid))
        );

        return concat(
            defer(() => of(this.getByQuestionUuid(questionUuid))),
            stream
        );
    }

    public streamByQuestionUuids(questionUuids: string[]): Observable<Answer[]> {
        return combineLatest(questionUuids.map((questionUuid) => this.streamByQuestionUuid(questionUuid))).pipe(
            map((answers) => answers.flat())
        );
    }

    public getByQuestionUuid(questionUuid: string, parentAnswerUuid?: string): Answer[] {
        if (parentAnswerUuid) {
            const byQuestionAndParentSet = this.answerMemoryStorage.getByMultipleIndices([
                {indices: questionUuidIndex, keys: [questionUuid]},
                {indices: parentUuidAnswerIndex, keys: [parentAnswerUuid]},
            ]);
            return byQuestionAndParentSet ? Array.from(byQuestionAndParentSet) : [];
        }

        const byQuestionUuidSet = this.answerMemoryStorage.getByIndex(questionUuidIndex, [questionUuid]);
        const resultArray = byQuestionUuidSet ? Array.from(byQuestionUuidSet) : [];
        return resultArray;
    }

    public getByQuestionUuids(questionUuids: string[], parentAnswerUuid?: string): Answer[] {
        return questionUuids
            .map((questionUuid) => {
                const answers = this.answerMemoryStorage.getByIndex(questionUuidIndex, [questionUuid]);
                if (answers === null) {
                    return [];
                }

                if (parentAnswerUuid !== undefined) {
                    return Array.from(answers).filter((a) => a.parentUuid === parentAnswerUuid);
                }
                return Array.from(answers);
            })
            .flat();
    }

    public replace(answers: Answer[]): Answer[] {
        const currentAnswersList = this.list;

        const sortedList = [...currentAnswersList, ...answers]
            .sort((a: Answer, b: Answer) => {
                const diff = (a.id ?? 0) - (b.id ?? 0);
                if (diff === 0) {
                    return 0;
                }
                return diff < 0 ? -1 : 1;
            })
            .sort((a: Answer, b: Answer) => {
                const diff = (a.updatedAt?.getTime() ?? 0) - (b.updatedAt?.getTime() ?? 0);
                if (diff === 0) {
                    return 0;
                }
                return diff < 0 ? -1 : 1;
            });

        const replacedAnswers = [];
        for (const answer of sortedList) {
            const replacedAnswer = this.filter(answer);
            if (replacedAnswer) {
                replacedAnswers.push(replacedAnswer);
            }
        }

        this.answerMemoryStorage.updateMultiple(replacedAnswers);
        return replacedAnswers;
    }

    public getByUuid(answerUuid: string): Answer | undefined {
        return this.answerMemoryStorage.get(answerUuid) ?? undefined;
    }

    public getByParentAnswerUuid(parentAnswerUuid: string | null): Answer[] {
        const set = this.answerMemoryStorage.getByIndex(parentUuidAnswerIndex, [parentAnswerUuid]);
        return set ? Array.from(set) : [];
    }

    public update(updatedAnswer: Answer): Answer | null {
        const result = this.filter(updatedAnswer);
        if (result) {
            this.answerMemoryStorage.update(result);
        }

        return result;
    }

    public updateMany(answers: Answer[]) {
        const updatedAnswers: Answer[] = [];
        for (const answer of answers) {
            const updatedAnswer = this.filter(answer);
            if (updatedAnswer) {
                updatedAnswers.push(updatedAnswer);
            }
        }

        if (updatedAnswers.length > 0) {
            this.answerMemoryStorage.updateMultiple(updatedAnswers);
        }

        return updatedAnswers;
    }

    public filterDeleted(answers: Answer[]) {
        return this.houseFilter.filter(
            this.deletedAnswersFilter.filter(answers, this.answerMemoryStorage),
            this.answerMemoryStorage
        );
    }

    public delete(uuid: string) {
        const answer = this.getByUuid(uuid);
        if (answer == null) {
            throw new Error('Unknown answer cannot be deleted');
        }

        const updatedAnswer: Answer = {
            ...answer,
            changed: true,
            isDeleted: true,
            updatedAt: this.serverTimeProvider.date,
        };
        const result = this.filter(updatedAnswer);
        if (result) {
            this.answerMemoryStorage.update(result);
        }

        return result;
    }

    public deleteMultiple(uuids: string[]) {
        const deletedAnswers: Answer[] = [];
        for (const uuid of uuids) {
            const answer = this.getByUuid(uuid);
            if (answer) {
                const updatedAnswer = {
                    ...answer,
                    changed: true,
                    isDeleted: true,
                    updatedAt: this.serverTimeProvider.date,
                };
                const deletedAnswer = this.filter(updatedAnswer);
                if (deletedAnswer) {
                    deletedAnswers.push(deletedAnswer);
                }
            }
        }
        if (deletedAnswers.length > 0) {
            this.answerMemoryStorage.updateMultiple(deletedAnswers);
        }

        return deletedAnswers;
    }

    public restore(uuid: string): Answer | null {
        const answer = this.getByUuid(uuid);
        if (answer === undefined) {
            throw new Error('Unknown answer cannot be restored');
        }

        const updatedAnswer = {
            ...answer,
            changed: true,
            isDeleted: false,
            updatedAt: this.serverTimeProvider.date,
        };

        const result = this.filter(updatedAnswer);
        if (result) {
            this.answerMemoryStorage.update(result);
        }
        return result;
    }

    public get isPaused(): boolean {
        return this._isPaused;
    }

    public pauseStreams() {
        this._isPaused = true;
    }

    public resumeStreams() {
        this._isPaused = false;
        // This will consider the entire storage as changed
        this._events.dispatch({type: StorageEventType.STORAGE_CLEARED});
    }

    private filter(answer: Answer): Answer | null {
        if (answer.isHardDeleted) {
            this.answerMemoryStorage.delete(answer.uuid);
            return null;
        }

        if (!this.hasQuestionAnswerFilter.hasQuestion(answer)) {
            const error = new Error('Tried to set answer with no corresponding question!');
            console.warn(error);
            bugsnagClient?.notify(error, {
                metaData: {
                    answer: answer,
                },
            });

            return null;
        }

        const existingAnswer = this.answerMemoryStorage.get(answer.uuid);
        if (existingAnswer) {
            if (
                existingAnswer &&
                existingAnswer.updatedAt !== null &&
                answer.updatedAt !== null &&
                //We are flooring to seconds because servers are creating timestamps without milliseconds by trimming
                //While internal numbers (and those in local storage) use milliseconds so they will never get
                //overwritten
                floorSeconds(existingAnswer.updatedAt.getTime()) > floorSeconds(answer.updatedAt.getTime())
            ) {
                if (process.env.NODE_ENV === 'development') {
                    console.warn(
                        "The existing answer was updated after the answer that we're trying to set",
                        existingAnswer,
                        answer
                    );
                }

                //The existing answer was created after the answer that we're trying to set
                return null;
            }

            if (
                existingAnswer &&
                existingAnswer.updatedAt !== null &&
                answer.updatedAt !== null &&
                floorSeconds(existingAnswer.updatedAt.getTime()) === floorSeconds(answer.updatedAt.getTime()) &&
                existingAnswer.contents !== null &&
                answer.contents !== null
            ) {
                try {
                    //Cheap check to see if it is actually json
                    if (answer.contents[0] === '{') {
                        const existingContent = parseImageAnswerContents(existingAnswer.contents);
                        const newAnswerContent = parseImageAnswerContents(answer.contents);

                        if (
                            'state' in existingContent &&
                            'state' in newAnswerContent &&
                            existingContent.state === ImageUploadState.SYNCED &&
                            (newAnswerContent.state === ImageUploadState.UNSYNCED ||
                                newAnswerContent.state === ImageUploadState.SYNCING)
                        ) {
                            if (process.env.NODE_ENV === 'development') {
                                console.warn(
                                    'Answers have both the same time and the "new answer" goes from synced -> unsynced/syncing',
                                    existingAnswer,
                                    answer
                                );
                            }

                            //Answers have both the same time and the "new answer" goes from synced -> unsynced/syncing
                            //This happens if the synced answer completed in the same second as it started syncing
                            //If we upload multiple images its possible we have a race condition where the backend returns an old state
                            return null;
                        }
                    }
                } catch {
                    // Noop
                }
            }
        }

        if (process.env.NODE_ENV === 'development') {
            if (
                answer.changed === true &&
                existingAnswer &&
                createAnswerHash(existingAnswer) === createAnswerHash(answer)
            ) {
                //Now check if they dont have the same updatedAt, this can happen if the backend sends back a "saved" version
                if (
                    !(
                        existingAnswer.updatedAt &&
                        answer.updatedAt &&
                        floorSeconds(existingAnswer.updatedAt.getTime()) === floorSeconds(answer.updatedAt.getTime())
                    )
                ) {
                    console.trace();
                    console.warn({...existingAnswer});
                    console.warn({...answer});
                    throw new Error('Setting answer with same content');
                }
            }
        }

        return answer;
    }
}
