import {ExtraReferenceSaleSet, ReferenceSale} from './models/reference_sale';
import {ModalState, ModalType} from './models/reference_object_question_modal_state';
import {NetworkStatus, NetworkStatusProvider} from '../../../../../../../business/network_status_provider';
import {Observable, combineLatest} from 'rxjs';
import {action, autorun, computed, makeObservable, observable, runInAction} from 'mobx';

import {Answer} from '../../../../../../../models/answer';
import {AnswerController} from '../../../../../../../business/answering/answer_controller';
import {AnswerInteractor} from '../../../../../../../business/answering/answer_interactor';
import {AnswerTouchState} from '../../../../../../../enum/answer_touch_state';
import {Appraisal} from '../../../../../../../models/appraisal';
import {AppraisalNavigator} from '../../../../../appraisal_navigator';
import {AppraisalProvider} from '../../../../../../../business/appraisal_provider';
import {AppraisalState} from '../../../../../../../enum/appraisal_state';
import {AppraisalValidationType} from '../../../../../../../enum/appraisal_validation_type';
import {AppraiseModel, isAppraiseModel, isAppraiseModelOrNewer} from '../../../../../../../enum/appraise_model';
import {AppraiseSecondaryConfigStackInteractor} from '../../../../../../../business/appraise_secondary_config_stack_interactor';
import {AppraiseSecondaryType} from '../../../../../../../models/appraise_secondary_config';
import {AreaConclusion} from '../../../../../../../models/area_conclusion';
import {CompositeSubscription} from '../../../../../../../../support/composite_subscription';
import {EnergyConclusion} from '../../../../../../../models/energy_conclusion';
import {Presenter} from '../../../../../../../../support/presenter/presenter';
import {Question} from '../../../../../../../models/question';
import {QuestionSet} from '../../../../../../../models/question_set';
import {ReferenceObjectAnswer} from './models/reference_object_answer';
import {ReferenceObjectProvider} from '../../../../../../../business/reference_object_provider';
import {ReferenceObjectsMapDataContainer} from './reference_objects_map_data_container';
import {ReferenceObjectsV1Provider} from './providers/reference_objects_provider';
import {ReferenceSet} from './models/reference_set';
import {TechnicalReference} from '../../../../../../../enum/technical_reference';
import {formatMoney} from '../../../../../support/format_money';
import {isEmpty} from '../../../../../../../../support/util';
import {map} from 'rxjs/operators';

export interface VisibleReferenceSale {
    referenceSale: ReferenceSale;
    referenceObjectAnswer: ReferenceObjectAnswer | null;
}

const secondaryAppraisalId = 'object-references-map';
export class ReferenceObjectsQuestionPresenter extends ReferenceObjectsMapDataContainer implements Presenter {
    @observable.ref public answer?: Answer;
    @observable public preconditionsAreMet = false;
    @observable public fetchingReferenceObjects = false;
    @observable public isUpdatingReferenceObjects = false;
    @observable.ref public extraReferenceSalesSets: ExtraReferenceSaleSet[] = [];

    @observable public modalState: ModalState = {
        type: ModalType.NONE,
    };

    @observable public networkStatus: NetworkStatus = NetworkStatus.ONLINE;
    @observable public defaultReferenceSalesRequired = true;
    @observable public valuationAnswersChanged = false;

    @observable private activeReferenceSaleSetId: null | string = null;
    @observable private valuation: string | null = null;
    @observable private buildYear: number | null = null;
    @observable private areaConclusion: AreaConclusion | null = null;
    @observable private energyConclusion: EnergyConclusion | null = null;
    @observable private referenceSales: ReferenceSale[] = [];

    @computed
    public get isFrozen(): boolean {
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return this.loading || (this.appraisal.status !== AppraisalState.PROCESSING && this.question.freezes);
        }

        return (
            this.appraisalProvider.appraisal.status === AppraisalState.APPROVED ||
            this.appraisalProvider.appraisal.status === AppraisalState.CANCELED ||
            this.appraisalProvider.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    @computed
    public get canAdd(): boolean {
        return (
            this.activeSet.referenceObjectAnswers.length <
            this.questionSet.reportDefintionConfig.maxReferenceObjectsInSet
        );
    }

    @computed
    public get activeSet(): ReferenceSet {
        if (this.activeReferenceSaleSetId !== null) {
            const activeReferenceSaleSet = this.extraReferenceSalesSets.find(
                (set) => set.id === this.activeReferenceSaleSetId
            );
            if (activeReferenceSaleSet === undefined) {
                throw new Error('Invalid active tab');
            }

            return {
                id: activeReferenceSaleSet.id,
                name: activeReferenceSaleSet.name,
                valuation: activeReferenceSaleSet.valuation,
                buildYear: activeReferenceSaleSet.buildYear,
                isExtraSet: true,
                isRequired: true,
                areaConclusion: activeReferenceSaleSet.areaConclusion,
                energyConclusion: activeReferenceSaleSet.energyConclusion,
                referenceObjects: activeReferenceSaleSet.referenceObjects,
                referenceObjectAnswers: this.referenceObjectAnswersForSetId(activeReferenceSaleSet.id),
                suggestedReferenceObjectAnswers: {
                    referenceSetName: this.defaultReferenceSalesRequired ? 'Marktwaarde' : null,
                    referenceObjectAnswers: this.defaultReferenceSalesRequired
                        ? this.referenceObjectAnswersForSetId(null)
                        : [],
                },
            };
        }

        return this.defaultSet;
    }

    @computed
    public get visibleReferenceSales(): VisibleReferenceSale[] {
        return this.getVisibleReferenceSales(this.activeSet);
    }

    @computed
    public get defaultSet(): ReferenceSet {
        return {
            id: null,
            name: 'Marktwaarde in huidige staat',
            valuation: this.valuation,
            buildYear: this.buildYear,
            isExtraSet: false,
            isRequired: this.defaultReferenceSalesRequired,
            areaConclusion: this.areaConclusion,
            energyConclusion: this.energyConclusion,
            referenceObjects: this.referenceSales,
            referenceObjectAnswers: this.referenceObjectAnswersForSetId(null),
            suggestedReferenceObjectAnswers: {
                referenceSetName:
                    this.defaultReferenceSalesRequired && this.extraReferenceSalesSets.length > 0
                        ? formatMoney(parseInt(this.extraReferenceSalesSets[0].valuation, 10))
                        : null,
                referenceObjectAnswers:
                    this.defaultReferenceSalesRequired && this.extraReferenceSalesSets.length > 0
                        ? this.referenceObjectAnswersForSetId(this.extraReferenceSalesSets[0].id)
                        : [],
            },
        };
    }

    @computed
    public get tooManyReferenceObjectsNotificationVisible() {
        //By default an appraiser gets 6 reference sales for an appraisal
        return this.referenceObjectAnswers.length >= 6;
    }

    @computed
    public get loading() {
        return this.answer === undefined;
    }

    @computed
    public get referenceObjectAnswers(): ReferenceObjectAnswer[] {
        if (this.answer === undefined) {
            return [];
        }

        return this.answer.contents === null ? [] : JSON.parse(this.answer.contents);
    }

    @action
    public async refreshReferenceObjects() {
        this.isUpdatingReferenceObjects = true;
        await this.answerInteractor.submit();
        const referenceObjects = await this.referenceObjectInteractor.getReferenceSales();
        this.referenceSales = referenceObjects.referenceSales;
        this.extraReferenceSalesSets = referenceObjects.extraReferenceSalesSets;
        this.isUpdatingReferenceObjects = false;
    }

    public hasVolumeQuestion = isAppraiseModel(this.appraisal, [AppraiseModel.MODEL2018, AppraiseModel.WOCO2016]);

    private subscriptions = new CompositeSubscription();

    constructor(
        appraisal: Appraisal,
        private question: Question,
        questionSet: QuestionSet,
        private appraisalProvider: AppraisalProvider,
        private parentAnswerUuid: string | undefined,
        private iteration: string | undefined,
        private answerController: AnswerController,
        private referenceObjectInteractor: ReferenceObjectProvider,
        private appraisalNavigator: AppraisalNavigator,
        private networkStatusProvider: NetworkStatusProvider,
        private answerInteractor: AnswerInteractor,
        private appraiseSecondaryConfigStackInteractor: AppraiseSecondaryConfigStackInteractor,
        private referenceObjectsProvider: ReferenceObjectsV1Provider
    ) {
        super(appraisal, questionSet);
        makeObservable(this);
    }

    public async mount(): Promise<void> {
        this.subscriptions.add(
            this.referenceObjectsProvider.preconditionsAreMetStream.subscribe((preconditionsAreMet) => {
                runInAction(() => {
                    this.preconditionsAreMet = preconditionsAreMet;
                });
            })
        );

        this.subscriptions.add(
            autorun(() => {
                //If the modal is open, dont update the secondary view, performance reasons
                if (this.modalState.type !== ModalType.NONE) {
                    return;
                }

                if (!this.preconditionsAreMet || isEmpty(this.appraisal.objectType)) {
                    const tempSecondaryAppraisalId = 'temp_' + secondaryAppraisalId;
                    this.appraiseSecondaryConfigStackInteractor.remove((c) => {
                        return c.id !== tempSecondaryAppraisalId && c.type === AppraiseSecondaryType.REFERENCE_MAP;
                    });
                    this.appraiseSecondaryConfigStackInteractor.upsert({
                        id: tempSecondaryAppraisalId,
                        type: AppraiseSecondaryType.REFERENCE_MAP,
                        onClose: () => {
                            //Noop
                        },
                        onClickChange: () => {
                            //Noop
                        },
                        onHoverChange: () => {
                            //Noop
                        },
                        clickedReferenceSale: null,
                        hoveringReferenceSale: null,
                        appraisal: this.appraisal,
                        showDetailsModal: this.showDetailsModal,
                        visibleReferenceSales: [],
                    });
                } else {
                    const loadedSecondaryAppraisalId = 'loaded_' + secondaryAppraisalId;
                    this.appraiseSecondaryConfigStackInteractor.remove((c) => {
                        return c.id !== loadedSecondaryAppraisalId && c.type === AppraiseSecondaryType.REFERENCE_MAP;
                    });

                    this.appraiseSecondaryConfigStackInteractor.upsert({
                        id: loadedSecondaryAppraisalId,
                        type: AppraiseSecondaryType.REFERENCE_MAP,
                        onClose: () => {
                            //Noop
                        },
                        onClickChange: (rs) => this.setClickedReferenceSale(rs),
                        onHoverChange: (rs) => this.setHoveringReferenceSale(rs),
                        clickedReferenceSale: this.clickedReferenceSale,
                        hoveringReferenceSale: this.hoveringReferenceSale,
                        appraisal: this.appraisal,
                        visibleReferenceSales: this.visibleReferenceSales,
                        showDetailsModal: this.showDetailsModal,
                    });
                }
            })
        );

        this.subscriptions.add(
            this.networkStatusProvider.status().subscribe((networkStatus) => {
                runInAction(() => {
                    this.networkStatus = networkStatus;
                });
            })
        );

        this.appraisalNavigator.addNextListener(this.navigateNext);

        this.subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid ?? null, this.iteration ?? null)
                .subscribe((answer) => {
                    runInAction(() => {
                        this.answer = answer;
                    });
                })
        );

        const valuationQuestion = this.questionSet.findQuestionByTechnicalReference(TechnicalReference.VALUATION);
        if (valuationQuestion === null) {
            console.error('No valuation question');
            return;
        }
        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidAndIteration(valuationQuestion.uuid, null)
                .subscribe((valuationAnswers) => {
                    runInAction(() => {
                        this.valuation =
                            valuationAnswers.length > 0 && !isEmpty(valuationAnswers[0].contents)
                                ? valuationAnswers[0].contents
                                : null;
                    });
                })
        );

        const valuationAnswerObservables = this.getValuationAnswerObservables();
        this.subscriptions.add(
            combineLatest(...valuationAnswerObservables)
                .pipe(
                    map((data: Answer[][]) => {
                        return data.reduce((acc, array) => acc + array.filter((a) => a.changed).length, 0) > 0;
                    })
                )
                .subscribe((answersChange) => {
                    runInAction(() => {
                        this.valuationAnswersChanged = answersChange;
                    });
                })
        );

        this.subscriptions.add(
            this.referenceObjectsProvider.referenceObjectsStream.subscribe((result) => {
                runInAction(() => {
                    this.fetchingReferenceObjects = result.loading;

                    if (result.referenceSaleData) {
                        this.energyConclusion = result.referenceSaleData.energyConclusion;
                        this.areaConclusion = result.referenceSaleData.areaConclusion;
                        this.defaultReferenceSalesRequired = result.referenceSaleData.defaultReferenceSalesRequired;
                        this.buildYear = result.referenceSaleData.buildYear;
                        this.referenceSales = result.referenceSaleData.referenceSales;
                        this.extraReferenceSalesSets = result.referenceSaleData.extraReferenceSalesSets;
                        this.activeReferenceSaleSetId = this.initActiveReferenceSaleSetId();
                    }
                });
            })
        );
    }

    public unmount(): void {
        this.appraisalNavigator.removeNextListener(this.navigateNext);
        this.subscriptions.clear();
        this.appraiseSecondaryConfigStackInteractor.remove((c) => String(c.id).endsWith(secondaryAppraisalId));
    }

    private initActiveReferenceSaleSetId(): string | null {
        if (!this.defaultReferenceSalesRequired && this.extraReferenceSalesSets.length !== 0) {
            return this.extraReferenceSalesSets[0].id;
        }
        return null;
    }

    private navigateNext = () => {
        if (this.extraReferenceSalesSets.length > 0) {
            if (this.activeReferenceSaleSetId === null) {
                //Navigate  to first active set
                this.activateReferenceSalesSet(this.extraReferenceSalesSets[0].id);
                return true;
            } else {
                //Navigate to next extra set
                for (let i = 0; i < this.extraReferenceSalesSets.length; i++) {
                    if (
                        this.activeReferenceSaleSetId === this.extraReferenceSalesSets[i].id &&
                        i + 1 <= this.extraReferenceSalesSets.length - 1
                    ) {
                        this.activateReferenceSalesSet(this.extraReferenceSalesSets[i + 1].id);
                        return true;
                    }
                }
            }
        }
        return false;
    };

    @action
    public onAddAndOpenModal = (
        referenceSale: ReferenceSale,
        toBeAddedReferenceObjectAnswer: ReferenceObjectAnswer
    ) => {
        this.onAdd(toBeAddedReferenceObjectAnswer);
        this.showAnswerModal(referenceSale);
    };

    @action
    public onAdd = (toBeAddedReferenceObjectAnswer: ReferenceObjectAnswer) => {
        if (this.answer === undefined) {
            return;
        }

        this.onModalHide();

        const referenceObjectAnswers = this.referenceObjectAnswers;

        referenceObjectAnswers.push({
            ...toBeAddedReferenceObjectAnswer,
            setId: this.activeSet.id,
        });

        this.answerController.onContentsChange(
            this.answer.uuid,
            JSON.stringify(referenceObjectAnswers),
            AnswerTouchState.TOUCHED
        );
    };

    @action
    public onAddMany = (toBeAddedReferenceObjectAnswers: ReferenceObjectAnswer[]) => {
        if (this.answer === undefined) {
            return;
        }

        this.onModalHide();

        const referenceObjectAnswers = this.referenceObjectAnswers;

        toBeAddedReferenceObjectAnswers.forEach((toBeAddedReferenceObjectAnswer) => {
            referenceObjectAnswers.push({
                ...toBeAddedReferenceObjectAnswer,
                setId: this.activeSet.id,
            });
        });

        this.answerController.onContentsChange(
            this.answer.uuid,
            JSON.stringify(referenceObjectAnswers),
            AnswerTouchState.TOUCHED
        );
    };

    @action
    public onChange = (referenceObject: ReferenceSale, updatedReferenceObjectAnswer: ReferenceObjectAnswer) => {
        if (this.answer === undefined) {
            return;
        }

        const updatedReferenceObjectAnswers = this.referenceObjectAnswers.map((referenceObjectAnswer) => {
            if (referenceObjectAnswer.id === referenceObject.id && referenceObjectAnswer.setId === this.activeSet.id) {
                return updatedReferenceObjectAnswer;
            }
            return referenceObjectAnswer;
        });

        this.answerController.onContentsChange(
            this.answer.uuid,
            JSON.stringify(updatedReferenceObjectAnswers),
            AnswerTouchState.TOUCHED
        );
    };

    @action
    public onRemove = (toBeRemovedReferenceObjectAnswer: ReferenceObjectAnswer) => {
        if (this.answer === undefined) {
            return;
        }

        const filteredReferenceObjectAnswers = this.referenceObjectAnswers.filter((referenceObjectAnswer) => {
            return !(
                referenceObjectAnswer.id === toBeRemovedReferenceObjectAnswer.id &&
                referenceObjectAnswer.setId === toBeRemovedReferenceObjectAnswer.setId
            );
        });

        this.answerController.onContentsChange(
            this.answer.uuid,
            JSON.stringify(filteredReferenceObjectAnswers),
            AnswerTouchState.TOUCHED
        );
    };

    @action
    public activateReferenceSalesSet(referenceSaleSetId: string | null): void {
        this.hoveringReferenceSale = null;
        this.clickedReferenceSale = null;
        this.activeReferenceSaleSetId = referenceSaleSetId;
    }

    @action
    public onAddCustomReferenceSaleButtonClick = () => {
        this.modalState = {
            type: ModalType.CUSTOM_REFERENCE_SALE,
        };
    };

    @action
    public showAnswerModal = (referenceSale: ReferenceSale) => {
        this.modalState = {
            type: ModalType.OBJECT_ANSWER,
            referenceSale,
        };
    };

    @action
    public showDetailsModal = (referenceSale: ReferenceSale) => {
        this.modalState = {
            type: ModalType.REFERENCE_OBJECT_DETAILS,
            referenceSale,
        };
    };

    @action
    public onModalHide = () => {
        this.modalState = {
            type: ModalType.NONE,
        };
    };

    private referenceObjectAnswersForSetId(setId: string | null): ReferenceObjectAnswer[] {
        return this.referenceObjectAnswers.filter((referenceObjectAnswer) => referenceObjectAnswer.setId === setId);
    }

    private getValuationAnswerObservables(): Array<Observable<Answer[]>> {
        const answerObservables: Array<Observable<Answer[]>> = [];

        const plotAreaQuestions = this.questionSet.findQuestionsByTechnicalReference(
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_PERCEEL_OPPERVLAKTE
        );

        for (const plotAreaQuestion of plotAreaQuestions) {
            answerObservables.push(
                this.answerController.answersForQuestionUuidAndIteration(plotAreaQuestion.uuid, null)
            );
        }

        const livingAreaQuestions = this.questionSet.findQuestionsByTechnicalReference(
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_GEBRUIKSOPPERVLAKTE_WONEN
        );
        for (const livingAreaQuestion of livingAreaQuestions) {
            answerObservables.push(
                this.answerController.answersForQuestionUuidAndIteration(livingAreaQuestion.uuid, null)
            );
        }

        const energyLabelQuestions = this.questionSet.findQuestionsByTechnicalReference(
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_ENERGY_LABEL
        );
        for (const energyLabelQuestion of energyLabelQuestions) {
            answerObservables.push(
                this.answerController.answersForQuestionUuidAndIteration(energyLabelQuestion.uuid, null)
            );
        }

        const buildYearQuestions = this.questionSet.findQuestionsByTechnicalReference(
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_BUILD_YEAR
        );
        for (const buildYearQuestion of buildYearQuestions) {
            answerObservables.push(
                this.answerController.answersForQuestionUuidAndIteration(buildYearQuestion.uuid, null)
            );
        }

        if (!isAppraiseModelOrNewer(this.appraisal.appraiseModel, AppraiseModel.MODEL2021)) {
            const volumeQuestions = this.questionSet.findQuestionsByTechnicalReference(
                TechnicalReference.SPECIAL_VALUE_ARGUMENT_BRUTO_INHOUD
            );
            for (const volumeQuestion of volumeQuestions) {
                answerObservables.push(
                    this.answerController.answersForQuestionUuidAndIteration(volumeQuestion.uuid, null)
                );
            }
        }

        return answerObservables;
    }
}
