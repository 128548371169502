import {QuestionSet} from '../models/question_set';
import {SidebarItemsProvider} from './sidebar_items_provider';
import {Question} from '../models/question';
import {SidebarItem} from './sidebar_tree_builder';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

export class SidebarItemForQuestionProvider {
    constructor(private questionSet: QuestionSet, private sidebarItemsProvider: SidebarItemsProvider) {}

    public find(question: Question): Observable<SidebarItem | null> {
        return this.sidebarItemsProvider.flattened().pipe(map((sidebarItems) => this.walk(question, sidebarItems)));
    }

    private walk(question: Question, sidebarItems: SidebarItem[]): SidebarItem | null {
        let cursor: Question | undefined = question;

        while (cursor !== undefined) {
            const sidebarItem = sidebarItems.find(
                (item) => item.question && cursor && item.question.uuid === cursor.uuid
            );
            if (sidebarItem) {
                return sidebarItem;
            }

            if (cursor.parentUuid) {
                const newCursor = this.questionSet.findQuestionByUuid(cursor.parentUuid);
                if (newCursor?.parentUuid === cursor.parentUuid) {
                    console.error('Invalid state! The newCursor parentUuid is the same for cursor newCursor');
                    return null;
                }
                if (newCursor?.parentUuid === cursor.uuid) {
                    console.error('Invalid state! The newCursor parentUuid is the same for cursor uuid');
                    return null;
                }
                cursor = newCursor;
            } else {
                cursor = undefined;
            }
        }

        return null;
    }
}
