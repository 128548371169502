import {QuestionAnswerPair, buildQuestionAnswerTree} from '../../../../../../../../../../support/question_answer_tree';
import {
    TreeItem,
    findChildRecursiveByPredicate,
    findChildrenRecursiveByPredicate,
    findParentByPredicateRecursive,
} from '../../../../../../../../../../support/generic_tree';
import {map, shareReplay} from 'rxjs/operators';

import {AnswerController} from '../../../../../../../../../business/answering/answer_controller';
import {AnswerOption} from '../../../../../../../../../models/answer_option';
import {NormalQuestionType} from '../../../../../../../../../enum/question_type';
import {EMPTY, Observable} from 'rxjs';
import {Question} from '../../../../../../../../../models/question';
import {QuestionEffectInteractor} from '../../../../../../../../../business/conditions/question_effects_interactor';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';
import {TechnicalReference} from '../../../../../../../../../enum/technical_reference';
import {formatMoney} from '../../../../../../../support/format_money';
import {Appraisal} from '../../../../../../../../../models/appraisal';
import {AppraiseModel, isAppraiseModel} from '../../../../../../../../../enum/appraise_model';

export interface V2ValuationSeedData {
    technicalReference: TechnicalReference | null;
    answerOption: AnswerOption | null;
    other: string | null;
}

export interface V2SetDefinition<T = TreeItem<QuestionAnswerPair>> {
    valuationType: string;
    valuationSeedData: V2ValuationSeedData;
    title: string;
    valuation: number;
    type: SetType;
    groupTree: T;
    name: string | null;
    description: string | null;
}

export interface SetDefinitionsData {
    tree: TreeItem<QuestionAnswerPair>;
    setDefinitions: Array<V2SetDefinition<TreeItem<QuestionAnswerPair>>>;
}

export interface V2SetDefinitionsProvider {
    getSetDefinitions(valuationGroupQuestion: Question, ignoreValuationTypeNone: boolean): SetDefinitionsData | null;
    findValidSetDefinitions(tree: TreeItem<QuestionAnswerPair>, ignoreValuationTypeNone: boolean): V2SetDefinition[];
    setDefinitions(): Observable<Array<V2SetDefinition<TreeItem<QuestionAnswerPair>>> | null>;
}

export class DefaultV2SetDefinitionsProvider implements V2SetDefinitionsProvider {
    private valuationGroupTechnicalReferences = [
        TechnicalReference.VALUATION_MARKET_VALUE_GROUP,
        TechnicalReference.VALUATION_MARKET_VALUE_AFTER_CONSTRUCTION_GROUP,
        TechnicalReference.VALUATION_MARKET_VALUE_AFTER_REALISATION_GROUP,
        TechnicalReference.VALUATION_FORCED_SALE_GROUP,
        TechnicalReference.VALUATION_SPECIAL_VALUE_GROUP,
        TechnicalReference.VALUATION_SPECIAL_VALUE_AFTER_CONSTRUCTION_GROUP,
        TechnicalReference.VALUATION_SPECIAL_VALUE_AFTER_REALISATION_GROUP,
    ];

    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor,
        private appraisal: Appraisal
    ) {}

    private collectQuestionUuids(valuationGroupQuestion: Question): string[] {
        const allChildren = this.questionSet.flattenChildrenRecursively(valuationGroupQuestion.uuid);
        const symlinks = this.questionSet.findQuestionsByTechnicalReference(TechnicalReference.SYMLINK_REFERENTIES);
        const symlinkChildren = symlinks
            .filter((q) => q.type !== NormalQuestionType.SYMLINK_LINK)
            .map((q) => this.questionSet.flattenChildrenRecursively(q.uuid))
            .flat();

        return [
            valuationGroupQuestion.uuid,
            ...allChildren.map((child) => child.uuid),
            ...symlinks.map((child) => child.uuid),
            ...symlinkChildren.map((child) => child.uuid),
        ];
    }

    private collectValuationSeedData(valuationGroup: TreeItem<QuestionAnswerPair>): V2ValuationSeedData {
        const data: V2ValuationSeedData = {
            technicalReference: valuationGroup.item.question.technicalReference,
            answerOption: null as null | AnswerOption,
            other: null as null | string,
        };

        const specialValuationIteratorParent = findParentByPredicateRecursive(
            valuationGroup,
            (item) => item.question.technicalReference === TechnicalReference.SPECIAL_VALUE_ARGUMENT_ITERATOR
        );
        if (specialValuationIteratorParent) {
            const dropdownItem = findChildRecursiveByPredicate(
                specialValuationIteratorParent,
                (item) => item.question.technicalReference === TechnicalReference.VALUATION_SPECIAL_EXPLANATION_MC
            );
            if (!dropdownItem) {
                throw new Error(
                    `Dropdown "${TechnicalReference.VALUATION_SPECIAL_EXPLANATION_MC}" not found for waardebegrip iterator.`
                );
            }
            const selectedAnswerOption = dropdownItem.item.question.answerOptions.find(
                (ao) => ao.id === dropdownItem.item.answer?.answerOptionId
            );
            if (!selectedAnswerOption) {
                throw new Error('No selected answer given for dropdown');
            }

            data.answerOption = selectedAnswerOption;

            if (selectedAnswerOption.contents.toLowerCase() === 'anders') {
                const openItem = findChildRecursiveByPredicate(
                    specialValuationIteratorParent,
                    (item) => item.question.technicalReference === TechnicalReference.VALUATION_SPECIAL_EXPLANATION_OPEN
                );
                data.other =
                    openItem?.item.answer?.contents
                        ?.toLowerCase()
                        .replace(/[^a-z0-9]/g, '')
                        .replace(/\s+/g, '') ?? '';
            }
        }

        return data;
    }

    private createValuationType(valuationSeedData: V2ValuationSeedData): string {
        let seed = String(valuationSeedData.technicalReference);

        if (valuationSeedData.answerOption !== null) {
            seed += '|' + valuationSeedData.answerOption.id;
        }
        if (valuationSeedData.other !== null) {
            seed += '|' + valuationSeedData.other;
        }

        return window.btoa(seed);
    }

    public findValidSetDefinitions(
        tree: TreeItem<QuestionAnswerPair>,
        ignoreValuationTypeNone: boolean
    ): V2SetDefinition[] {
        const valuationGroups = findChildrenRecursiveByPredicate(
            tree,
            (i) => this.valuationGroupTechnicalReferences.some((tr) => tr === i.question.technicalReference),
            true
        ).filter((group) => group.item.answer !== null);

        const setDefinitions: V2SetDefinition[] = [];
        for (const valuationGroup of valuationGroups) {
            const valuationSeedData = this.collectValuationSeedData(valuationGroup);
            const valuationType = this.createValuationType(valuationSeedData);

            const valuation = valuationGroup.children.find(
                (child) =>
                    child.item.question.technicalReference === TechnicalReference.VALUATION ||
                    child.item.question.technicalReference === TechnicalReference.SPECIAL_VALUE_ARGUMENT_PRICE
            );
            const dropdown = valuationGroup.children.find(
                (child) => child.item.question.type === NormalQuestionType.MC_SELECT
            );

            const valuationValue = valuation?.item.answer?.contents ?? null;
            const selectedDropdownAnswerOption =
                dropdown?.item.question.answerOptions.find((ao) => ao.id === dropdown.item.answer?.answerOptionId) ??
                null;

            if (selectedDropdownAnswerOption) {
                let type = SetType.SOLD;

                if (selectedDropdownAnswerOption && selectedDropdownAnswerOption.contents.toLowerCase() !== 'geen') {
                    switch (selectedDropdownAnswerOption.contents.toLowerCase()) {
                        case 'verhuurd':
                        case 'huur':
                            type = SetType.RENT;
                            break;
                        case 'te koop':
                        case 'koop':
                            type = SetType.SALE;
                            break;
                        case 'geen':
                            type = SetType.NONE;
                            break;
                        default:
                            type = SetType.SOLD;
                            break;
                    }
                }

                const shouldAdd = !ignoreValuationTypeNone || (ignoreValuationTypeNone && type !== SetType.NONE);
                const existingSameDefinition = setDefinitions.find((sd) => {
                    return (
                        sd.valuationSeedData.answerOption === valuationSeedData.answerOption &&
                        sd.valuationSeedData.technicalReference === valuationSeedData.technicalReference &&
                        sd.valuationSeedData.other === valuationSeedData.other
                    );
                });

                if (!existingSameDefinition && shouldAdd) {
                    setDefinitions.push({
                        valuationType: valuationType,
                        valuationSeedData: valuationSeedData,
                        title: valuationValue
                            ? formatMoney(parseInt(valuationValue, 10) ?? 0)
                            : this.getValuationName(valuationSeedData, true),
                        valuation: valuationValue ? parseInt(valuationValue, 10) ?? 0 : 0,
                        type: type,
                        groupTree: valuationGroup,
                        name: this.getValuationName(valuationSeedData),
                        description: this.getValuationDescription(
                            valuationSeedData,
                            this.valuationDescription(valuationGroup)
                        ),
                    });
                }
            }
        }
        return setDefinitions;
    }

    private valuationDescription(valuationGroup: TreeItem<QuestionAnswerPair>): string | null {
        const iteratorGroup: TreeItem<QuestionAnswerPair> | null = findParentByPredicateRecursive(
            valuationGroup,
            (q) => q.question.technicalReference === TechnicalReference.SPECIAL_VALUE_ARGUMENT_ITERATOR
        );

        const dropdown =
            iteratorGroup?.children.find(
                (child) =>
                    child.item.question.technicalReference === TechnicalReference.VALUATION_SPECIAL_EXPLANATION_MC
            ) ?? null;

        const answerOption =
            dropdown?.item.question.answerOptions.find((ao) => ao.id === dropdown.item.answer?.answerOptionId) ?? null;

        if (answerOption?.contents.toLowerCase() !== 'anders') {
            return answerOption?.contents ?? null;
        }

        const open =
            iteratorGroup?.children.find(
                (child) =>
                    child.item.question.technicalReference === TechnicalReference.VALUATION_SPECIAL_EXPLANATION_OPEN
            ) ?? null;

        return open?.item.answer?.contents ?? null;
    }

    private getValuationName(valuationSeedData: V2ValuationSeedData, useShort?: boolean): string {
        switch (valuationSeedData.technicalReference) {
            case TechnicalReference.VALUATION_MARKET_VALUE_GROUP:
            case TechnicalReference.VALUATION_MARKET_VALUE_AFTER_CONSTRUCTION_GROUP:
            case TechnicalReference.VALUATION_MARKET_VALUE_AFTER_REALISATION_GROUP:
                return useShort ? 'Marktw.' : 'Marktwaarde';
            case TechnicalReference.VALUATION_SPECIAL_VALUE_GROUP:
            case TechnicalReference.VALUATION_SPECIAL_VALUE_AFTER_CONSTRUCTION_GROUP:
            case TechnicalReference.VALUATION_SPECIAL_VALUE_AFTER_REALISATION_GROUP:
                return useShort ? 'Bijz. uit.' : 'Bijzonder uitgangspunt';
            case TechnicalReference.VALUATION_FORCED_SALE_GROUP:
                return useShort ? 'Gedw. verkoop' : 'Gedwongen verkoop';
            default:
                return '';
        }
    }

    private getValuationDescription(
        valuationSeedData: V2ValuationSeedData,
        specialValuationDescription: string | null
    ): string | null {
        switch (valuationSeedData.technicalReference) {
            case TechnicalReference.VALUATION_MARKET_VALUE_GROUP:
                return 'Marktwaarde in huidige staat';
            case TechnicalReference.VALUATION_MARKET_VALUE_AFTER_CONSTRUCTION_GROUP:
            case TechnicalReference.VALUATION_SPECIAL_VALUE_AFTER_CONSTRUCTION_GROUP:
                return 'Waarde na verbouwing' + (specialValuationDescription ? ' :' + specialValuationDescription : '');
            case TechnicalReference.VALUATION_MARKET_VALUE_AFTER_REALISATION_GROUP:
            case TechnicalReference.VALUATION_SPECIAL_VALUE_AFTER_REALISATION_GROUP:
                return 'Waarde na realisatie' + (specialValuationDescription ? ' :' + specialValuationDescription : '');
            case TechnicalReference.VALUATION_SPECIAL_VALUE_GROUP:
            case TechnicalReference.VALUATION_FORCED_SALE_GROUP:
                return 'Waarde' + (specialValuationDescription ? ' :' + specialValuationDescription : '');
            default:
                return null;
        }
    }

    public getSetDefinitions(
        valuationGroupQuestion: Question,
        ignoreValuationTypeNone: boolean
    ): SetDefinitionsData | null {
        const questionUuids = this.collectQuestionUuids(valuationGroupQuestion);

        const answersWithDeleted = questionUuids.flatMap((uuid) => this.answerController.answersForQuestionUuid(uuid));
        const answers = this.answerController
            .filterDeleted(answersWithDeleted)
            .filter((a) => !this.questionEffectInteractor.isHidden(a.questionUuid, a.uuid));

        const rootAnswer = answers.find((a) => a.questionUuid === valuationGroupQuestion.uuid);
        if (!rootAnswer) {
            return null;
        }

        const tree = buildQuestionAnswerTree(this.questionSet, answers, rootAnswer);
        const setDefinitions = this.findValidSetDefinitions(tree, ignoreValuationTypeNone);

        return {
            tree,
            setDefinitions,
        };
    }

    //Keep an cached version of the observable, this observable is quite performance intensive, caching the observable itself
    //Will result in it only executing once for every subscription (thanks to the shareReplay)
    //If we dont do this, a new observable is created every time the `setDefinitions` method is called
    private observableCache: Observable<Array<V2SetDefinition<TreeItem<QuestionAnswerPair>>> | null> | null = null;
    public setDefinitions(): Observable<Array<V2SetDefinition<TreeItem<QuestionAnswerPair>>> | null> {
        if (isAppraiseModel(this.appraisal, AppraiseModel.MODEL2024PLAUSIBILITY)) {
            // This set has no reference objects
            return EMPTY;
        }

        if (this.observableCache !== null) {
            return this.observableCache;
        }

        const valuationGroupQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.VALUATION_GROUP
        );

        if (!valuationGroupQuestion) {
            throw new Error(`No question with TechnicalReference "${TechnicalReference.VALUATION_GROUP}" found!`);
        }

        const questionUuids = this.collectQuestionUuids(valuationGroupQuestion);

        this.observableCache = this.answerController.answersForQuestionUuidsStream(questionUuids).pipe(
            map((answers) => this.answerController.filterDeleted(answers)),
            map((answers) =>
                answers.filter((answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid))
            ),
            map((answers) => {
                const rootAnswer = answers.find((a) => a.questionUuid === valuationGroupQuestion.uuid);
                if (rootAnswer) {
                    return buildQuestionAnswerTree(this.questionSet, answers, rootAnswer);
                }

                return null;
            }),
            map((tree) => {
                if (!tree) {
                    return null;
                }

                return this.findValidSetDefinitions(tree, false);
            }),
            shareReplay(1)
        );
        return this.observableCache;
    }
}
