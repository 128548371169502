import $ from 'jquery';

/**
 * Wizard to create layout code
 */
(() => {
    const primaryColor = $('#primary_color');
    const secondaryColor = $('#secondary_color');

    function updateEmailIframe() {
        $('#emailIframe').on(
            'load',
            (function (primaryColor, secondaryColor) {
                updateIframe('#emailIframe', primaryColor, secondaryColor);
            })(primaryColor.val(), secondaryColor.val())
        );
    }

    function updateInvoiceIframe() {
        $('#invoiceIframe').on(
            'load',
            (function (primaryColor, secondaryColor) {
                updateIframe('#invoiceIframe', primaryColor, secondaryColor);
            })(primaryColor.val(), secondaryColor.val())
        );
    }

    function updateIframe(rootIdentifier, primaryColor, secondaryColor) {
        const head = $(rootIdentifier).contents().find('head');
        const css = `<style> :root {
           --color-primary: ${primaryColor} !important;
           --color-secondary: ${secondaryColor} !important;
           }</style>`;
        $(head).append(css);
        // The image is not directly uploaded, so a timeout is added
        const uploadedImage = $('.image-upload-preview img');
        const image = $(rootIdentifier).contents().find('#header-img');
        if (image && uploadedImage) {
            $(image).replaceWith(
                `<img id="header-img" src="${uploadedImage.attr('src')}" alt="logo" style="max-width: 260px"/>`
            );
        }
    }

    document.addEventListener('uppy-upload-completed', function (e) {
        setTimeout(function () {
            updateEmailIframe();
            updateInvoiceIframe();
        }, 1000);
    });

    primaryColor.on('change', function () {
        updateInputGroupColor(this);
        updateEmailIframe();
        updateInvoiceIframe();
    });
    secondaryColor.on('change', function () {
        updateInputGroupColor(this);
        updateEmailIframe();
        updateInvoiceIframe();
    });

    function updateInputGroupColor(field) {
        const inputGroupColor = $(field).parent('.input-group').find('.input-group-color');
        if (inputGroupColor.length > 0) {
            inputGroupColor.css({background: $(field).val()});
        }
    }

    let emailCheckbox = $('#email_layout');
    let chEmailPreview = $('#chEmailPreview');
    let invoiceCheckbox = $('#invoice_layout');
    let chInvoicePreview = $('#chInvoicePreview');
    if (emailCheckbox.is(':checked')) {
        chEmailPreview.show();
    } else {
        chEmailPreview.hide();
    }

    emailCheckbox.on('click', function () {
        if ($(this).is(':checked')) {
            chEmailPreview.show();
        } else {
            chEmailPreview.hide();
        }
    });

    if (invoiceCheckbox.is(':checked')) {
        chInvoicePreview.show();
    } else {
        chInvoicePreview.hide();
    }

    invoiceCheckbox.on('click', function () {
        if ($(this).is(':checked')) {
            chInvoicePreview.show();
        } else {
            chInvoicePreview.hide();
        }
    });
})();
