import {action, makeObservable, observable, runInAction} from 'mobx';
import {MacroInteractor} from '../../../appraising/business/macro_interactor';
import {Macro, MacroExternalType} from '../../../appraising/models/macro';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {isEmpty} from '../../../support/util';
import {Presenter} from '../../../support/presenter/presenter';

export class ReasoningInputPresenter implements Presenter {
    @observable.ref public macros?: Macro[];
    @observable public precheckReasoning = '';

    private subscriptions = new CompositeSubscription();

    constructor(private macroInteractor: MacroInteractor, private onChange: (reasoning: string) => void) {
        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            this.macroInteractor.macrosForExternalType(MacroExternalType.PRECHECK_REASONING).subscribe((macros) => {
                runInAction(() => {
                    this.macros = macros;

                    const favoriteMacro = macros.find((m) => m.favorite);
                    if (favoriteMacro && isEmpty(this.precheckReasoning) && favoriteMacro.contents) {
                        this.onReasoningChange(favoriteMacro.contents);
                    }
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    public onReasoningChange = (reasoning: string) => {
        this.precheckReasoning = reasoning;
        this.onChange(reasoning);
    };

    public onAddAsMacroClick = async () => {
        if (this.precheckReasoning !== '') {
            try {
                await this.macroInteractor.storeExternal(this.precheckReasoning, MacroExternalType.PRECHECK_REASONING);
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };

    public onRemoveMacroClick = async (toBeRemovedMacro: Macro) => {
        if (this.macros) {
            try {
                const macro = this.macros.find((m) => m.id === toBeRemovedMacro.id);
                if (macro) {
                    if (!macro.isUserDefined) {
                        await this.macroInteractor.hideForUser(macro.id);
                    } else {
                        await this.macroInteractor.destroy(macro.id);
                    }
                }
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };

    public onFavoriteMacroClick = async (toBeFavoritedMacro: Macro) => {
        if (this.macros) {
            try {
                const macro = this.macros.find((m) => m.id === toBeFavoritedMacro.id);
                if (macro) {
                    await this.macroInteractor.toggleFavorite(macro.id);
                }
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };
}
