import {format} from 'date-fns';
import {TreeItem} from '../../../support/generic_tree';
import {buildQuestionAnswerTree, QuestionAnswerPair} from '../../../support/question_answer_tree';
import {sortAnswersByCreatedAt} from '../../../support/sort_answer';
import {isEmpty} from '../../../support/util';
import {MultipleChoiceSelectDynamicQuestionPresenter} from '../../appraise/ui/content/questions/advanced/multiple_choice_select_dynamic_question_presenter';
import {questionSortCompareFn} from '../../appraise/ui/support/question_filtering';
import {IteratorQuestionType, NormalQuestionType, QuestionType} from '../../enum/question_type';
import {Answer} from '../../models/answer';
import {QuestionSet} from '../../models/question_set';
import {AnswerController} from '../answering/answer_controller';
import {QuestionEffectInteractor} from '../conditions/question_effects_interactor';
import {TextAIContextBuilder} from './textai_context_builder';

export class DefaultTextAIAnswerContextBuilder implements TextAIContextBuilder {
    private readonly blacklistedQuestionTypes: QuestionType[] = [
        NormalQuestionType.WIDGET_GROUP,
        NormalQuestionType.HIDDEN,
        NormalQuestionType.REFERENCE_OBJECT_PHOTO,
        NormalQuestionType.REFERENCE_OBJECT_EDITABLE_ADDRESS,
        ...Object.values(IteratorQuestionType),
    ];

    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectsInteractor: QuestionEffectInteractor
    ) {}

    public getContext(answer?: Answer): {[key: string]: unknown} {
        if (!answer) {
            return {};
        }

        const tree = this.getApplicableQuestionAnswerTree(answer);

        if (tree === null || tree.parent === null) {
            return {};
        }

        // We do not care about the current question
        tree.parent.children = tree.parent.children.filter((child) => child !== tree);

        let root = tree;
        while (root.parent !== null) {
            root = root.parent;
        }

        return {
            Vragen: this.treeToSimpleObject(root),
        };
    }

    public getPromptForAnswer(answer: Answer): string | null {
        const question = this.questionSet.findQuestionByUuid(answer.questionUuid);
        if (question === undefined) {
            return null;
        }

        const questionName =
            this.questionSet
                .findParentPathByPredicateRecursive(question, (q) => q.parentUuid !== null)
                ?.map((q) => q.contents)
                .join(' > ') ?? question.contents;

        let prompt = `Schrijf een of meerdere zinnen (vanuit het perspectief van de taxateur) over: ${questionName}. Herhaal dit niet, geef enkel en allen het antwoord in de respons.`;
        if (!isEmpty(answer.contents)) {
            prompt += ` Vooraf is onderstaande al ingevuld. Neem dit niet letterlijk over.\n\n${answer.contents}`;
        }

        return prompt;
    }

    private getApplicableQuestionAnswerTree(answer: Answer) {
        const question = this.questionSet.findQuestionByUuid(answer.questionUuid);
        if (question === undefined) {
            return null;
        }

        const node: TreeItem<QuestionAnswerPair> = {
            item: {
                question,
                answer,
            },
            parent: null,
            children: [],
        };

        if (answer.parentUuid === null) {
            return node;
        }

        const parentAnswer = this.answerController.byUuid(answer.parentUuid);
        if (parentAnswer === null) {
            return node;
        }

        node.parent = this.getApplicableQuestionAnswerTree(parentAnswer);
        if (!node.parent) {
            return node;
        }

        const answers = this.answerController.answersByParentAnswerUuid(parentAnswer.uuid).sort(sortAnswersByCreatedAt);
        const answersByQuestionUuid = new Map<string, Answer>(answers.map((answer) => [answer.questionUuid, answer]));

        const siblingQuestions = this.questionSet
            .findChildQuestionsByParentUuid(parentAnswer.questionUuid)
            .sort(questionSortCompareFn);
        for (const question of siblingQuestions) {
            // We only add questions that come before the current question to the context
            if (question.uuid === answer.questionUuid) {
                break;
            }

            // Blacklisted question types
            if (this.blacklistedQuestionTypes.includes(question.type)) {
                continue;
            }

            const siblingAnswer = answersByQuestionUuid.get(question.uuid);
            if (siblingAnswer === undefined) {
                continue;
            }

            if (this.questionEffectsInteractor.isHidden(siblingAnswer.questionUuid, siblingAnswer.parentUuid)) {
                continue;
            }

            const item = this.prepareQuestionAnswerTree(
                buildQuestionAnswerTree(this.questionSet, this.answerController.answers(), siblingAnswer)
            );

            node.parent.children.push(item);
        }

        node.parent.children.push(node);

        return node;
    }

    private prepareQuestionAnswerTree(item: TreeItem<QuestionAnswerPair>) {
        item.children = item.children
            .filter(
                (child) =>
                    !this.questionEffectsInteractor.isHidden(
                        child.item.question.uuid,
                        child.item.answer?.parentUuid ?? null
                    ) && !this.blacklistedQuestionTypes.includes(child.item.question.type)
            )
            .map((child) => this.prepareQuestionAnswerTree(child))
            .sort((a, b) => questionSortCompareFn(a.item.question, b.item.question));

        return item;
    }

    private treeToSimpleObject(item: TreeItem<QuestionAnswerPair>): {[key: string]: unknown} {
        if (item.children.length === 0) {
            const content = this.questionAnswerToString(item.item);

            return {
                [item.item.question.contents]: isEmpty(content) ? '...' : content,
            };
        } else if (
            (item.item.question.type === NormalQuestionType.MULTIPLE_BOOLEAN_GROUP ||
                item.item.question.type === NormalQuestionType.CHECKLIST) &&
            item.children.length > 3
        ) {
            return {
                [item.item.question.contents]: item.children
                    .filter(
                        (c) => c.item.question.type !== NormalQuestionType.BOOLEAN || c.item.answer?.contents === '1'
                    )
                    .map((child) => this.treeToSimpleObject(child)),
            };
        } else {
            return {
                [item.item.question.contents]: item.children.map((child) => this.treeToSimpleObject(child)),
            };
        }
    }

    private questionAnswerToString(item: QuestionAnswerPair): string | null {
        switch (item.question.type) {
            case NormalQuestionType.MC:
            case NormalQuestionType.MC_SELECT:
            case NormalQuestionType.MC_SELECT_OPTIONAL:
            case NormalQuestionType.MC_SELECT_COMPARE: {
                const option = item.question.answerOptions.find((ao) => ao.id === item.answer?.answerOptionId);
                return option?.contents ?? item.answer?.contents ?? null;
            }
            case NormalQuestionType.BOOLEAN: {
                return item.answer?.contents === '1' ? 'Ja' : 'Nee';
            }
            case NormalQuestionType.MC_SELECT_DYNAMIC: {
                return item.answer ? MultipleChoiceSelectDynamicQuestionPresenter.format(item.answer.contents) : null;
            }
            case NormalQuestionType.DATE: {
                return item.answer?.contents ? format(new Date(item.answer.contents), 'dd-MM-yyyy') : null;
            }
            case NormalQuestionType.MULTIPLE_BOOLEAN_GROUP: {
                return item.question.contents ?? item.answer?.contents ?? null;
            }
            default: {
                return item.answer?.contents ?? null;
            }
        }
    }
}
