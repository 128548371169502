import {
    ApiAnswerAuditTrailItem,
    apiAnswerAuditTrailItemToAnswerAuditTrailItem,
} from './models/api_answer_audit_trail_item';

import {AjaxDriver} from '../../network/driver/ajax_driver';
import {AnswerAuditTrailItem} from '../models/answer_audit_trail_item';

export interface AnswerAuditTrailItemApi {
    getAuditTrailItems(appraisalId: number, answerUuids: string[]): Promise<AnswerAuditTrailItem[]>;
}

export class DefaultAnswerAuditTrailItemApi implements AnswerAuditTrailItemApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public async getAuditTrailItems(appraisalId: number, answerUuids: string[]): Promise<AnswerAuditTrailItem[]> {
        try {
            const searchParams = new URLSearchParams(answerUuids.map((uuid) => ['answer_uuids[]', uuid])).toString();
            const response = await this.ajaxDriver.fetch(
                `/ajax/appraisals/${appraisalId}/answer-audit-trail-items?${searchParams}`,
                {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.ok) {
                const data = await response.json();
                if (Array.isArray(data)) {
                    return data.map((item: ApiAnswerAuditTrailItem) =>
                        apiAnswerAuditTrailItemToAnswerAuditTrailItem(item)
                    );
                }
            }

            return [];
        } catch (e) {
            console.warn(e);
            return [];
        }
    }
}
