import {EffectState} from './conditions_interactor';

export function isHiddenBasedOnEffectStates(effectStates: EffectState[]): boolean {
    if (managesVisibility(effectStates)) {
        return !effectStates.some((effectState) => effectState.enabled && effectState.effect.task === 'show');
    }
    return false;
}

function managesVisibility(effectStates: EffectState[]) {
    return effectStates.some((effectState) => effectState.effect.task === 'show');
}
