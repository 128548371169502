import {Presenter} from '../../../../../support/presenter/presenter';
import {District} from '../../../../models/district';

export class MacroSettingsFormPresenter implements Presenter {
    constructor(public districts: District[]) {}

    public mount() {
        //Noop
    }

    public unmount() {
        //Noop
    }
}
