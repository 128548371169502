import {action, autorun, computed, makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../../../support/composite_subscription';
import {Macro, MacroExternalType, MacroType} from '../../../../../../models/macro';
import {MacroEffectInteractor} from '../../../../../../business/conditions/macro_effects_interactor';
import {Presenter} from '../../../../../../../support/presenter/presenter';
import {Observable, Subscription, combineLatest, map, of, switchMap} from 'rxjs';
import {MacroInteractor} from '../../../../../../business/macro_interactor';
import {TextAIInteractor} from '../../../../../../business/textai/textai_interactor';
import {AnswerController} from '../../../../../../business/answering/answer_controller';
import {TextAIContextBuilder} from '../../../../../../business/textai/textai_context_builder';
import {ModalType} from '../../../../../../models/modal_config';
import {AppraiseSecondaryType} from '../../../../../../models/appraise_secondary_config';
import {QuestionSet} from '../../../../../../models/question_set';
import {Appraisal} from '../../../../../../models/appraisal';
import {SuperMacroInteractor} from '../../../../../../business/super_macro_interactor';
import {ModalOrSecondaryConfigStackInteractor} from '../../../../../../business/modal_or_secondary_config_stack_interactor';
import {
    MacroSettingsPayload,
    MacroSettingsPayloadType,
} from '../../../../appraise_secondary/macro_settings/macro_settings_presenter';
import {QuestionExtensionType} from '../../../../../../enum/question_extension_type';
import {TextAIQuestionExtension} from '../../../../../../models/question_extension';
import {isCompact} from '../../../../../../../support/check_mobile';
import {Answer} from '../../../../../../models/answer';

export class MacrosPresenter implements Presenter {
    @observable.ref public visibleMacros: Macro[] = [];
    @observable.ref public superHiddenMacros: Macro[] = [];
    @observable public textAILoading = false;
    @observable public textAIResponse: string | null = null;
    @observable.ref private editableMacro: Macro | null = null;

    private secondaryWidgetId = 'macro-settings-widget';

    private subscriptions = new CompositeSubscription();
    private textAISubscription?: Subscription;
    private textAIRefreshSubscription?: Subscription;
    private onScreenObserver?: IntersectionObserver;

    constructor(
        private questionUuid: string | null,
        private externalType: MacroExternalType | null,
        private parentAnswerUuid: string | null,
        private questionSet: QuestionSet,
        private appraisal: Appraisal,
        private textAIContextBuilders: TextAIContextBuilder[],
        private macroInteractor: MacroInteractor,
        private macroEffectsInteractor: MacroEffectInteractor,
        private textAIInteractor: TextAIInteractor,
        private answerController: AnswerController,
        private modalOrSecondaryConfigStackInteractor: ModalOrSecondaryConfigStackInteractor,
        private superMacroInteractor: SuperMacroInteractor,
        private containerRef: React.RefObject<HTMLSpanElement>
    ) {
        makeObservable(this);
    }

    public async mount() {
        if (this.questionUuid !== null) {
            this.subscriptions.add(
                this.superMacroInteractor
                    .visibleMacrosStream(this.questionUuid, this.parentAnswerUuid)
                    .subscribe((result) => {
                        runInAction(() => {
                            this.visibleMacros = result
                                .filter((m) => !m.isNormalHidden && !m.isSuperHidden)
                                .map((m) => m.macro);
                            this.superHiddenMacros = result.filter((m) => m.isSuperHidden).map((m) => m.macro);
                        });
                    })
            );
        } else if (this.externalType !== null) {
            this.subscriptions.add(
                this.macroInteractor
                    .macrosForExternalType(this.externalType)
                    .pipe(
                        switchMap((macros) =>
                            macros.length === 0
                                ? of([])
                                : combineLatest(
                                      macros.map((macro) => {
                                          return this.macroEffectsInteractor
                                              .isHiddenStream(macro.conditionGroups, this.parentAnswerUuid ?? null)
                                              .pipe(
                                                  map((isHidden) => ({
                                                      macro,
                                                      isHidden,
                                                  }))
                                              );
                                      })
                                  )
                        )
                    )
                    .subscribe((result) => {
                        runInAction(() => {
                            this.visibleMacros = result.filter((m) => m.isHidden === false).map((m) => m.macro);
                        });
                    })
            );
        }
        this.subscriptions.add(
            autorun(() => {
                if (this.editableMacro !== null) {
                    const macroSecondaryWidgetId = this.secondaryWidgetId + this.editableMacro.id;
                    const payload: MacroSettingsPayload | null =
                        this.editableMacro.type === MacroType.DEFAULT
                            ? {
                                  type: MacroSettingsPayloadType.DEFAULT,
                                  macro: this.editableMacro,
                              }
                            : {
                                  type: MacroSettingsPayloadType.PERSISTED_SUPER,
                                  macro: this.editableMacro,
                              };

                    this.modalOrSecondaryConfigStackInteractor.upsert(
                        {
                            id: macroSecondaryWidgetId,
                            type: ModalType.MACRO_SETTINGS,
                            payload: payload,
                            appraisal: this.appraisal,
                            questionSet: this.questionSet,
                            onClose: () => {
                                runInAction(() => {
                                    this.editableMacro = null;
                                });
                            },
                        },
                        {
                            id: macroSecondaryWidgetId,
                            type: AppraiseSecondaryType.MACRO_SETTINGS,
                            payload: payload,
                            appraisal: this.appraisal,
                            questionSet: this.questionSet,
                            onClose: () => {
                                runInAction(() => {
                                    this.editableMacro = null;
                                });
                            },
                        }
                    );
                } else {
                    this.modalOrSecondaryConfigStackInteractor.removeByPredicate(
                        (c) => c.type === AppraiseSecondaryType.MACRO_SETTINGS,
                        (c) => c.type === ModalType.MACRO_SETTINGS
                    );
                }
            })
        );

        if (this.isTextAIEnabled && this.questionUuid) {
            const question = this.questionSet.findQuestionByUuid(this.questionUuid);
            const extension = question?.extensions.find((e) => e.type === QuestionExtensionType.TEXT_AI) as
                | TextAIQuestionExtension
                | undefined;

            if (extension?.shouldAutoTrigger && this.containerRef?.current) {
                let hasTriggered = false;
                // Auto trigger only when the element is on screen
                this.onScreenObserver = new IntersectionObserver(([entry]) => {
                    if (!entry.isIntersecting || hasTriggered) {
                        return;
                    }

                    hasTriggered = true;
                    this.promptAI(false);

                    // Deregister as we only need to trigger once
                    this.onScreenObserver?.disconnect();
                    this.onScreenObserver = undefined;
                });

                this.onScreenObserver.observe(this.containerRef.current);
            }
        }
    }

    public async unmount() {
        this.subscriptions.clear();
        this.textAISubscription?.unsubscribe();
        this.textAIRefreshSubscription?.unsubscribe();
        this.onScreenObserver?.disconnect();
        this.onScreenObserver = undefined;
    }

    @computed
    public get isTextAIEnabled() {
        if (!this.textAIInteractor.isAvailable() || !this.questionUuid || !this.parentAnswerUuid) {
            return false;
        }

        const question = this.questionSet.findQuestionByUuid(this.questionUuid);
        const extension = question?.extensions.find((e) => e.type === QuestionExtensionType.TEXT_AI) as
            | TextAIQuestionExtension
            | undefined;

        return extension !== undefined;
    }

    public promptAI = (allowChat = true) => {
        let stream: Observable<string> | undefined = undefined;
        let contextBuilders: TextAIContextBuilder[] = [];
        let answer: Answer | null = null;

        if (this.questionUuid && this.parentAnswerUuid) {
            const question = this.questionSet.findQuestionByUuid(this.questionUuid);
            answer = this.answerController.answerByIdentifiers(this.questionUuid, this.parentAnswerUuid, null);

            if (question && answer) {
                const textAIExtension = question?.extensions.find((e) => e.type === QuestionExtensionType.TEXT_AI) as
                    | TextAIQuestionExtension
                    | undefined;
                if (textAIExtension?.isChat && !isCompact() && allowChat) {
                    this.textAIInteractor.openChatWidget(answer);
                    return;
                } else {
                    contextBuilders = [
                        ...this.textAIInteractor.contextBuildersFor(answer),
                        ...this.textAIContextBuilders,
                    ];
                    const prompt = this.textAIInteractor.getPromptForAnswer(answer, contextBuilders);

                    if (prompt) {
                        stream = this.textAIInteractor.generate(prompt, contextBuilders, answer);
                    }
                }
            }
        }

        if (!stream && this.externalType) {
            contextBuilders = [this.textAIInteractor.contextBuilderForAppraisal(), ...this.textAIContextBuilders];
            stream = this.textAIInteractor.generate(this.getPromptForExternalType(this.externalType), contextBuilders);
        }

        if (this.textAISubscription) {
            this.textAISubscription.unsubscribe();
        }

        if (!stream) {
            runInAction(() => {
                this.textAIResponse = null;
                this.textAILoading = false;
            });
            return;
        }

        runInAction(() => {
            this.textAIResponse = '';
            this.textAILoading = true;
        });

        this.textAIRefreshSubscription?.unsubscribe();
        this.textAIRefreshSubscription = undefined;

        const refreshTriggers = contextBuilders
            .map((c) => c.getRefreshTriggerStream?.(answer ?? undefined))
            .filter((s): s is Observable<void> => s !== undefined);
        if (refreshTriggers.length > 0) {
            this.textAIRefreshSubscription = combineLatest(refreshTriggers).subscribe(() => {
                this.promptAI(false);
            });
        }

        this.textAISubscription = stream.subscribe(
            (result) => {
                runInAction(() => {
                    this.textAIResponse = this.textAIResponse + result;
                });
            },
            (err) => {
                console.error(err);

                runInAction(() => {
                    this.textAIResponse = null;
                    this.textAILoading = false;
                });
            },
            () => {
                runInAction(() => {
                    this.textAILoading = false;
                });

                this.textAISubscription = undefined;
            }
        );
    };

    private getPromptForExternalType(externalType: MacroExternalType) {
        switch (externalType) {
            case MacroExternalType.MODEL_VALUES_EXPLANATION:
                return 'Geef een onderbouwing voor een modelwaarde van dit taxatierapport.';
            case MacroExternalType.REFEFENCES_REJECT_REASON:
                return `Geef een reden voor het afwijzen van een referentie.`;
            case MacroExternalType.PRECHECK_REASONING:
                return 'Geef een onderbouwing voor het accepteren of weigeren van deze taxatie.';
            case MacroExternalType.APPRAISAL_NOTES:
                return 'Vul de extra notities voor deze taxatie in.';
            case MacroExternalType.OBJECT_OWNERSHIP:
                throw new Error('Not implemented yet');
        }
    }

    @action
    public toggleMacroSettingsWidget = (macro: Macro | null) => {
        this.modalOrSecondaryConfigStackInteractor.removeByPredicate(
            (c) => c.type === AppraiseSecondaryType.MACRO_SETTINGS,
            (c) => c.type === ModalType.MACRO_SETTINGS
        );
        this.editableMacro = macro;
    };
}
