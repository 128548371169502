import * as React from 'react';

import {AppraisalState} from '../enum/appraisal_state';
import {DetailName} from '../enum/detail_name';
import {PagePartsSetSelector} from './ui/components/page_part_set_selector/page_part_set_selector';
import {Loader} from './ui/components/loader';
import {RootPresenter} from './root_presenter';
import {UserType} from '../enum/user_type';
import {ActivePagePartsSetLoader} from './ui/active_page_parts_set_loader';
import {usePresenter} from '../../support/presenter/use_presenter';
import {observer} from 'mobx-react-lite';
import {Route, RouteComponentProps, Switch} from 'react-router-dom';
import {AppraisalComponent} from './ui/appraisal';
import {Appraisal} from '../models/appraisal';
import {QuestionSet} from '../models/question_set';
import {RenderingContextType} from '../enum/rendering_context_type';

export interface Global {
    state: AppraisalState;
    //If the appraisal area is rendered inline, for example for the client attachments
    inline?: boolean;
    //Simple back button url
    backUrl: string;
    //Url where the user should be redirected to after the very last question
    //or in the case of enriching by client, the page that registers that it is already enriched
    conclusionUrl?: string;
    //Define the user can go to the validation page
    gateAllowsValidation?: boolean;
    gateAllowsChangeAuditTrail?: boolean;
    objectImageUrl?: string;
    detailName?: DetailName | null;
    userType?: UserType;
    buildingInspectorEnabled: boolean;
    clientExtraFilesEnabled?: boolean;
}

interface OwnProps {
    appraisalId: number | null;
    questionSetId: number;
}

export const Root: React.FC<OwnProps> = observer(({appraisalId, questionSetId}) => {
    const presenter = usePresenter(
        (component) =>
            new RootPresenter(
                appraisalId,
                questionSetId,
                component.network.appraisalApi,
                component.business.answerLoader,
                component.network.questionSetApi,
                component.network.validationErrorApi,
                component.network.pagePartConfigurationApi,
                component.business.appraisalProvider,
                component.business.pagePartConfigurationsInteractor,
                component.serverTimeProvider,
                component.globalProvider,
                (appraisal: Appraisal, questionSet: QuestionSet) =>
                    component.business.userSettingsActivePagePartConfigurationIdInteractor(appraisal, questionSet)
            )
    );

    if (presenter.loading) {
        return (
            <div className="empty-message appraise">
                <Loader />
                <h3>Aan het laden...</h3>
            </div>
        );
    }

    const {global, error, renderingContextType, questionSet, rootQuestion, appraisal, validationErrors} = presenter;
    if (
        error ||
        renderingContextType === undefined ||
        questionSet === undefined ||
        rootQuestion === undefined ||
        appraisal === undefined ||
        validationErrors === undefined
    ) {
        return (
            <div className="empty-message appraise">
                <h3>Helaas, fout tijdens het laden</h3>
                <span className="spacer-gradient">&nbsp;</span>
            </div>
        );
    }

    return (
        <Switch>
            <Route
                path="/config-id/:pagePartConfigurationId"
                render={(routeProps: RouteComponentProps<{pagePartConfigurationId: string}>) => {
                    if (renderingContextType === RenderingContextType.APPRAISAL) {
                        return (
                            <div className="empty-message appraise">
                                <Loader />
                                <h3>Aan het laden...</h3>
                            </div>
                        );
                    }
                    return (
                        <>
                            {global.detailName === null && (
                                <div className="appraisal-header card-header d-flex align-items-center">
                                    {global.appraisalsUrl && <a href={global.appraisalsUrl}>Taxatie</a>}
                                    <div className="card-header-content">
                                        <PagePartsSetSelector
                                            pagePartConfigurationId={routeProps.match.params.pagePartConfigurationId}
                                            appraisal={appraisal}
                                            questionSet={questionSet}
                                            configuratorUrl={global.configurationUrl}
                                        />
                                    </div>
                                </div>
                            )}
                            <ActivePagePartsSetLoader
                                pagePartConfigurationId={routeProps.match.params.pagePartConfigurationId}
                                appraisal={appraisal}
                                onChange={(appraisal) => presenter.onChange(appraisal)}
                                questionSet={questionSet}
                                validationErrors={validationErrors}
                                validationMessages={{}}
                                hiddenQuestionTypes={[]}
                                renderingContext={renderingContextType}
                                questionRenderingData={null}
                                forceShowValidationMessages={false}
                            />
                        </>
                    );
                }}
            />
            <Route
                render={() => {
                    if (renderingContextType === RenderingContextType.PAGE_PARTS_APPRAISAL) {
                        return (
                            <div className="empty-message appraise">
                                <Loader />
                                <h3>Aan het laden...</h3>
                            </div>
                        );
                    }
                    return (
                        <>
                            {renderingContextType !== RenderingContextType.PLAUSIBILITY_CHECK && (
                                <div className="appraisal-header card-header d-flex align-items-center">
                                    {global.appraisalsUrl && <a href={global.appraisalsUrl}>Taxatie</a>}
                                </div>
                            )}
                            <AppraisalComponent
                                appraisal={appraisal}
                                onChange={(appraisal) => presenter.onChange(appraisal)}
                                questionSet={questionSet}
                                validationErrors={validationErrors}
                                validationMessages={{}}
                                hiddenQuestionTypes={[]}
                                pagePartsSet={null}
                                renderingContext={renderingContextType}
                                questionRenderingData={null}
                                forceShowValidationMessages={false}
                            />
                        </>
                    );
                }}
            />
        </Switch>
    );
});
