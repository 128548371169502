import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from '../../simple/simple_question_presenter';

import {Appraisal} from '../../../../../../models/appraisal';
import {BuildingCostsConditionPresenter} from './building_costs_condition_presenter';
import {BuildingCostsConditionSlider} from './building_costs_condition_slider';
import {Label} from '../../components/label';
import {Question} from '../../../../../../models/question';
import {QuestionSet} from '../../../../../../models/question_set';
import {ValidationError} from '../../../../../../models/validation_error';
import {buildingCostsLabels} from './building_costs_labels';
import {classNames} from '../../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {
    appraisal: Appraisal;
    question: Question;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    parentAnswerUuid?: string;
    iteration?: string;
    adaptedDefaultValue?: string;
    shouldShowCondition?: boolean;
    disabled?: boolean;
}

export const BuildingCostsCondition: React.FC<OwnProps> = observer(function BuildingCostsCondition(props) {
    const presenter = usePresenter(
        (container) =>
            new BuildingCostsConditionPresenter(
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );
    if (presenter.isHidden) {
        return null;
    }

    const color = props.disabled || presenter.isDisabled ? undefined : presenter.hex;

    return (
        <div
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
            className={classNames('form-group form-group-building-costs-range', {
                active: presenter.active,
            })}
        >
            <Label
                for={presenter.name}
                question={props.question}
                parentAnswerUuid={props.parentAnswerUuid}
                iteration={props.iteration}
                appraisal={props.appraisal}
                questionSet={props.questionSet}
                disabled={props.disabled || presenter.isDisabled}
                hideLabel={props.hideLabel}
                showLabel={props.question.showLabel}
                renderingContext={props.renderingContext}
            />

            <div className="form-range-container form-range-sm">
                <BuildingCostsConditionSlider
                    value={presenter.activeLabelValue}
                    onChange={(val) => presenter.onChange(val)}
                    onBlur={() => presenter.onTouched()}
                    disabled={props.disabled || presenter.isDisabled}
                    options={buildingCostsLabels}
                />
                {props.shouldShowCondition === undefined ||
                    (props.shouldShowCondition && (
                        <span className="form-range-value" style={{background: color}}>
                            {presenter.short}
                        </span>
                    ))}
                <button
                    disabled={props.disabled || presenter.isDisabled}
                    className="btn btn-sm form-range-value form-range-toggle-button"
                    ref={(e) => e?.style.setProperty('background', color ?? null, 'important')}
                    onClick={() => presenter.toggleActive()}
                >
                    {presenter.short}
                </button>
            </div>
        </div>
    );
});
