import * as React from 'react';

import {Appraisal} from '../../../../../../../../models/appraisal';
import {FloorAreaPriceBadge} from '../../../../../../../../components/badges/floor_area_price_badge';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {isEmpty} from '../../../../../../../../../support/util';
import {observer} from 'mobx-react';

interface OwnProps {
    referenceObjectFloorArea: number | null;
    referenceObjectPrice: string | number | null;
    referenceSetFloorArea: number | null;
    referenceSetPrice: number | null;
    compact?: boolean;
    questionSet: QuestionSet;
    appraisal: Appraisal;
}

@observer
export class FloorAreaPrice extends React.Component<OwnProps> {
    private formatPrice(from: number): string {
        return `€ ${from.toLocaleString('nl-NL')}/m²`;
    }

    private average(fromString: string, tilString: string | undefined): number {
        const from = parseInt(fromString, 10);
        const till = tilString !== undefined ? parseInt(tilString, 10) : from;

        return (from + till) / 2;
    }

    public render() {
        if (
            this.props.referenceObjectPrice === null ||
            isEmpty(String(this.props.referenceObjectPrice).trim()) ||
            this.props.referenceObjectFloorArea === null ||
            this.props.referenceObjectFloorArea === 0
        ) {
            return <>{'-'}</>;
        }

        let price;
        if (typeof this.props.referenceObjectPrice === 'string') {
            if (this.props.referenceObjectPrice.includes(' - ')) {
                const [from, till] = this.props.referenceObjectPrice.split(' - ');
                price = this.average(from, till);
            } else {
                price = parseInt(this.props.referenceObjectPrice, 10);
            }
        } else {
            price = this.props.referenceObjectPrice;
        }

        const referenceObjectPricePerM2 = Math.round(price / this.props.referenceObjectFloorArea);
        const referenceSetPricePerM2 =
            this.props.referenceSetPrice !== null &&
            this.props.referenceSetFloorArea !== null &&
            this.props.referenceSetFloorArea !== 0
                ? Math.round(this.props.referenceSetPrice / this.props.referenceSetFloorArea)
                : null;

        return (
            <>
                <span>{this.formatPrice(referenceObjectPricePerM2)}</span>
                {referenceSetPricePerM2 !== null ? (
                    <FloorAreaPriceBadge
                        floorAreaPrice={referenceObjectPricePerM2}
                        referenceSetFloorAreaPrice={referenceSetPricePerM2}
                        compact={this.props.compact}
                    />
                ) : null}
            </>
        );
    }
}
