import React, {useContext} from 'react';
import {Swiper, SwiperProps, SwiperRef, SwiperSlide} from 'swiper/react';
import {Navigation, Autoplay} from 'swiper/modules';
import {ImageViewerContext} from './image_viewer_context';
import {ZoomImage} from '../zoomable_image';

interface OwnProps extends SwiperProps {
    images: string[];
    zoomable?: boolean;
    autoplay?: boolean;
    renderImage?: (src: string, index: number) => React.ReactNode;
}

export const ImageSlider = React.forwardRef<SwiperRef, OwnProps>(function ImageSlider(
    {images, children, zoomable, renderImage, autoplay, ...restProps}: OwnProps,
    propRef
) {
    const {open} = useContext(ImageViewerContext);

    return (
        <Swiper
            navigation
            modules={[Navigation, Autoplay]}
            loop
            onClick={(slider) => {
                const img = slider.clickedSlide?.querySelector('img');

                if (img && img instanceof HTMLImageElement) {
                    open(img);
                }
            }}
            autoplay={
                autoplay
                    ? {
                          delay: 4000,
                          disableOnInteraction: false,
                      }
                    : false
            }
            ref={propRef}
            {...restProps}
        >
            {images.map((image, i) => (
                <SwiperSlide key={`${image}-${i}`}>
                    {(data) =>
                        data.isNext || data.isPrev || data.isVisible || data.isActive ? (
                            renderImage ? (
                                renderImage(image, i)
                            ) : (
                                <div className="d-flex justify-content-center w-100 h-100">
                                    {zoomable ? <ZoomImage src={image} /> : <img src={image} loading="lazy" />}
                                </div>
                            )
                        ) : null
                    }
                </SwiperSlide>
            ))}
            {children}
        </Swiper>
    );
});
