import {makeObservable, observable, runInAction} from 'mobx';

import {FileUploadInteractor} from '../../../../../../../../../business/attachments/attachment_upload_interactor';
import {ImageUrlPair} from '../../../../../../../../../models/image_url_pair';
import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {createFile} from '../../../../../../../../../../support/create_file';
import {ALLOWED_IMAGE_TYPES} from '../../../../../question_container';

export class ReferenceObjectAnswerImageUploadPresenter implements Presenter {
    private _fileTypes = ALLOWED_IMAGE_TYPES;

    @observable public showRetryButton = false;
    @observable public busy = false;
    @observable public finished = false;
    @observable.ref private file: File | null = null;
    @observable.ref public currentImageUrlPair: ImageUrlPair | null = null;

    constructor(
        imageUrlPairs: ImageUrlPair[],
        private onPathChange: (path: string, url: string, isOwnUpload?: boolean) => void,
        private fileUploadInteractor: FileUploadInteractor
    ) {
        makeObservable(this);
        this.currentImageUrlPair = imageUrlPairs[0];
    }

    public mount() {
        /* Noop */
    }

    public unmount() {
        /* Noop */
    }

    public setCurrentImageUrlPair(pair: ImageUrlPair) {
        runInAction(() => {
            this.currentImageUrlPair = pair;
        });
    }

    public async onSelectionChange() {
        this.busy = true;
        this.finished = false;

        try {
            this.showRetryButton = false;
            if (this.currentImageUrlPair === null || this.currentImageUrlPair.url.trim().length === 0) {
                return;
            }

            //See `editable_image.tsx` why we fetch the image this way
            const fetchedFile = await fetch(this.currentImageUrlPair.url, {
                headers: {
                    pragma: 'no-cache',
                    'cache-control': 'no-cache',
                },
            });
            if (fetchedFile.ok && fetchedFile.status >= 200 && fetchedFile.status < 300) {
                //Create our own File since IE cant use new File
                const fileBlob = (await fetchedFile.blob()) as File;
                const file = createFile(fileBlob, {
                    fileName:
                        fileBlob.name ??
                        this.currentImageUrlPair.url.split('/').pop()?.split('#').shift()?.split('?').shift() ??
                        '',
                    type: fileBlob.type ?? fetchedFile.headers.get('Content-Type') ?? 'image/jpeg',
                    lastModified: fileBlob.lastModified ?? Date.now(),
                });

                this.file = file;

                const result = await this.fileUploadInteractor.upload(file, {
                    fileTypes: this._fileTypes,
                });
                if (result.succeeded && result.file.meta.path !== null) {
                    this.onPathChange(result.file.meta.path, URL.createObjectURL(this.file));
                    this.finished = true;
                } else {
                    this.showRetryButton = true;
                }
            }
        } finally {
            this.busy = false;
        }
    }

    public async onChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.busy = true;

        try {
            this.showRetryButton = false;
            if (e.target.files === null || e.target.files.length === 0) {
                return;
            }
            const files = e.target.files;
            const file = files[0];

            this.file = file;

            const result = await this.fileUploadInteractor.upload(file, {
                fileTypes: this._fileTypes,
            });
            if (result.succeeded && result.file.meta.path !== null) {
                this.onPathChange(result.file.meta.path, URL.createObjectURL(this.file), true);
            } else {
                this.showRetryButton = true;
            }
        } finally {
            this.busy = false;
        }
    }
}
