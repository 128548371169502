import * as React from 'react';

import {ModalType, ObjectAnswerModalState} from './models/reference_object_question_modal_state';
import {AddCustomReferenceSaleInSaleModal} from './reference_objects_sale/add_custom_reference_sale_in_sale_modal';
import {Loader} from '../../../../../components/loader';
import {NetworkStatus} from '../../../../../../../business/network_status_provider';
import {ReferenceObjectAnswer} from './models/reference_object_answer';
import {ReferenceObjectDetailsModal} from './modals/reference_object_details_modal';
import {ReferenceObjectGrid} from './reference_object_grid';
import {ReferenceObjectInSaleAnswerModal} from './reference_objects_sale/reference_object_in_sale_answer_modal';
import {ReferenceObjectType} from '../../../../../../../enum/reference_object_type';
import {ReferenceObjectsSaleQuestionPresenter} from './reference_objects_sale_question_presenter';
import {ReferenceSale} from './models/reference_sale';
import {ReferenceSetSelector} from './reference_set_selector';
import {SimpleQuestionPresenterProps} from '../../../simple/simple_question_presenter';
import {TechnicalReference} from '../../../../../../../enum/technical_reference';
import {ValuationAnswersContainer} from './valuation_answers_container';
import {classNames} from '../../../../../../../../support/classnames';
import {isEmpty} from '../../../../../../../../support/util';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../../support/presenter/use_presenter';

export const ReferenceObjectsSaleQuestion: React.FunctionComponent<SimpleQuestionPresenterProps> = observer((props) => {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectsSaleQuestionPresenter(
                props.appraisal,
                props.question,
                props.questionSet,
                container.business.appraisalProvider,
                props.parentAnswerUuid,
                props.iteration,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.referenceObjectCurrentlyInSaleInteractor(props.appraisal),
                container.ui.appraisalNavigator,
                container.business.networkStatusProvider,
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.appraiseSecondaryConfigStackInteractor,
                container.business.referenceObjectsCurrentlyInSaleProvider(props.appraisal, props.questionSet)
            )
    );

    function renderContent() {
        if (!presenter.preconditionsAreMet || isEmpty(props.appraisal.objectType)) {
            return (
                <>
                    <div className="alert-inline alert-danger ion-md-close-circle">
                        <p>
                            Niet alle benodigde informatie is beschikbaar om referentieobjecten op te halen. Zorg ervoor
                            dat het object type, bouwjaar, de opnamedatum, gebruiksoppervlakte wonen en perceeloppervlak
                            (indien van toepassing) ingevuld zijn.
                        </p>
                        {presenter.networkStatus === NetworkStatus.OFFLINE ? (
                            <p>
                                <small>
                                    Deze functie is offline niet beschikbaar, zorg voor een stabiele internet verbinding
                                    en zorg ervoor dat alle data opgeslagen is.
                                </small>
                            </p>
                        ) : null}
                    </div>
                </>
            );
        }
        const objectAnswerModalAnswer =
            presenter.modalState.type === ModalType.OBJECT_ANSWER
                ? presenter.activeSet.referenceObjectAnswers.find(
                      (answer) => answer.id === (presenter.modalState as ObjectAnswerModalState).referenceSale.id
                  ) || null
                : null;

        return (
            <>
                <div className="row">
                    <ReferenceSetSelector
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        activeSet={presenter.activeSet}
                        defaultSet={presenter.defaultSet}
                        extraReferenceSalesSets={presenter.extraReferenceSalesSets}
                        defaultReferenceSalesRequired={presenter.defaultReferenceSalesRequired}
                        activateReferenceSalesSet={(referenceSaleSetId: string | null) =>
                            presenter.activateReferenceSalesSet(referenceSaleSetId)
                        }
                        isLoading={presenter.fetchingReferenceObjects}
                        referenceObjectAnswers={presenter.referenceObjectAnswers}
                    />

                    <ValuationAnswersContainer
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        validationErrors={props.validationErrors}
                        validationMessages={props.validationMessages}
                        forceShowValidationMessages={props.forceShowValidationMessages}
                        activeSet={presenter.activeSet}
                        defaultSet={presenter.defaultSet}
                        isLoading={presenter.fetchingReferenceObjects}
                        disabled={props.disabled}
                        extraReferenceSalesSets={presenter.extraReferenceSalesSets}
                        onAddMany={(referenceObjectAnswers: ReferenceObjectAnswer[]) => {
                            presenter.onAddMany(referenceObjectAnswers);
                        }}
                        valuationAnswersChanged={presenter.valuationAnswersChanged}
                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                        parentContainerTechnicalReference={TechnicalReference.SPECIAL_VALUE_ARGUMENT_GROUP_SALE}
                        pagePartsSet={props.pagePartsSet}
                        activePagePart={props.activePagePart}
                        renderingContext={props.renderingContext}
                        questionRenderingData={props.questionRenderingData}
                    />

                    <div className="col-12">
                        <ReferenceObjectGrid
                            key={presenter.activeSet.id || 'default'}
                            referenceObjectType={ReferenceObjectType.SALE}
                            referenceSaleSet={presenter.activeSet}
                            appraisal={props.appraisal}
                            questionSet={props.questionSet}
                            isUpdatingReferenceObjects={presenter.isUpdatingReferenceObjects}
                            isFrozen={presenter.isFrozen}
                            canAdd={presenter.canAdd}
                            onAdd={(referenceSale: ReferenceSale, referenceObjectAnswer: ReferenceObjectAnswer) => {
                                presenter.onAdd(referenceObjectAnswer);
                                presenter.showAnswerModal(referenceSale);
                            }}
                            onChange={(referenceSale: ReferenceSale, referenceObjectAnswer: ReferenceObjectAnswer) =>
                                presenter.onChange(referenceSale, referenceObjectAnswer)
                            }
                            onRemove={(referenceObjectAnswer: ReferenceObjectAnswer) =>
                                presenter.onRemove(referenceObjectAnswer)
                            }
                            showAnswerModal={presenter.showAnswerModal}
                            showDetailsModal={presenter.showDetailsModal}
                            fetchingReferenceObjects={presenter.fetchingReferenceObjects}
                            isComparing={presenter.isComparing}
                            sortingMethod={presenter.sortingMethod}
                            sortingDirection={presenter.sortingDirection}
                            updateSortingMethod={(method) => presenter.updateSortingMethod(method)}
                            updateIsComparing={(comparing) => presenter.updateIsComparing(comparing)}
                            visibleReferenceSales={presenter.visibleReferenceSales}
                            hoveringReferenceSale={presenter.hoveringReferenceSale}
                            clickedReferenceSale={presenter.clickedReferenceSale}
                            setHoveringReferenceSale={(rs) => presenter.setHoveringReferenceSale(rs)}
                            setClickedReferenceSale={(rs) => presenter.setClickedReferenceSale(rs)}
                            numVisible={presenter.numVisible}
                            showMoreReferenceSales={() => presenter.showMoreReferenceSales()}
                        >
                            {presenter.fetchingReferenceObjects || presenter.isFrozen ? null : (
                                <a
                                    className={classNames('btn btn-sm ion-md-add float-right', {
                                        disabled: !presenter.canAdd,
                                    })}
                                    aria-disabled={!presenter.canAdd}
                                    onClick={() => presenter.canAdd && presenter.onAddCustomReferenceSaleButtonClick()}
                                >
                                    Toevoegen
                                </a>
                            )}
                        </ReferenceObjectGrid>
                    </div>
                    {presenter.fetchingReferenceObjects ? (
                        <div className="col-12">
                            <div className="empty-message appraise">
                                <Loader />
                                <h3>
                                    {presenter.isFrozen || presenter.answer !== null
                                        ? 'Referentie objecten aan het laden'
                                        : 'Aan het zoeken naar referentie objecten'}
                                </h3>
                            </div>
                        </div>
                    ) : null}
                </div>

                {presenter.modalState.type === ModalType.CUSTOM_REFERENCE_SALE ? (
                    <AddCustomReferenceSaleInSaleModal
                        appraisal={props.appraisal}
                        onHide={() => presenter.onModalHide()}
                        onAdd={(referenceObjectAnswer: ReferenceObjectAnswer) => {
                            presenter.onAdd(referenceObjectAnswer);
                        }}
                        visible={presenter.modalState.type === ModalType.CUSTOM_REFERENCE_SALE}
                        referenceObjectsAnswers={presenter.activeSet.referenceObjectAnswers}
                        disabled={props.disabled}
                    />
                ) : null}

                {presenter.modalState.type === ModalType.OBJECT_ANSWER && objectAnswerModalAnswer !== null ? (
                    <ReferenceObjectInSaleAnswerModal
                        appraisal={props.appraisal}
                        referenceObjectType={ReferenceObjectType.SALE}
                        questionSet={props.questionSet}
                        referenceObjectAnswer={objectAnswerModalAnswer}
                        valuation={presenter.activeSet.valuation}
                        areaConclusion={presenter.activeSet.areaConclusion}
                        energyConclusion={presenter.activeSet.energyConclusion}
                        referenceSale={presenter.modalState.referenceSale}
                        visible={presenter.modalState.type === ModalType.OBJECT_ANSWER}
                        isFrozen={presenter.isFrozen}
                        onChange={(e) =>
                            presenter.onChange((presenter.modalState as ObjectAnswerModalState).referenceSale, e)
                        }
                        onHide={() => presenter.onModalHide()}
                    >
                        {presenter.isFrozen ? null : (
                            <button
                                className="btn btn-secondary ion-md-square-outline"
                                type="button"
                                onClick={() => {
                                    const answer = presenter.activeSet.referenceObjectAnswers.find(
                                        (a) =>
                                            a.id === (presenter.modalState as ObjectAnswerModalState).referenceSale.id
                                    );
                                    if (answer) {
                                        presenter.onRemove(answer);
                                    }
                                }}
                            >
                                Uitvinken
                            </button>
                        )}
                    </ReferenceObjectInSaleAnswerModal>
                ) : null}

                {presenter.modalState.type === ModalType.REFERENCE_OBJECT_DETAILS ? (
                    <ReferenceObjectDetailsModal
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        referenceObject={presenter.modalState.referenceSale}
                        referenceObjectAnswer={objectAnswerModalAnswer}
                        areaConclusion={presenter.activeSet.areaConclusion}
                        valuation={presenter.activeSet.valuation}
                        visible={presenter.modalState.type === ModalType.REFERENCE_OBJECT_DETAILS}
                        onHide={() => presenter.onModalHide()}
                        onAdd={(referenceSale: ReferenceSale, referenceObjectAnswer: ReferenceObjectAnswer) => {
                            presenter.onAdd(referenceObjectAnswer);
                        }}
                    />
                ) : null}
            </>
        );
    }

    return (
        <>
            <div className="reference-objects-btn-container">
                <button
                    className="btn btn-sm btn-default ion-md-refresh float-right"
                    onClick={() => presenter.refreshReferenceObjects()}
                    disabled={
                        presenter.isUpdatingReferenceObjects ||
                        presenter.fetchingReferenceObjects ||
                        (presenter.activeSet.id !== null && !presenter.valuationAnswersChanged)
                    }
                >
                    Ververs
                    <span
                        className="status-label status-label-beta"
                        data-toggle="popover"
                        tabIndex={0}
                        data-placement="top"
                        data-trigger="hover"
                        data-content="Ververs nu zelf de referentieobjecten"
                    >
                        <span className="ion-md-star"></span>
                        Nieuw
                    </span>
                    {presenter.isUpdatingReferenceObjects && <Loader />}
                </button>
            </div>
            <div className="form-group">{renderContent()}</div>
        </>
    );
});
