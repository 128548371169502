import * as React from 'react';

import {PriceRange} from '../price_range';
import {classNames} from '../../../../../../../../../../support/classnames';

interface OwnProps {
    lng: number;
    lat: number;
    className?: string;
    iconName?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onClick?: () => void;
    isHovering: boolean;
    isBaseObject: boolean;

    priceRange: number | string | null;
    isSelectedAnswer: boolean;
    imageUrl: string | null;
}

export class Pointer extends React.Component<OwnProps> {
    public render() {
        return (
            <div
                className={classNames('marker-container', {
                    hovering: this.props.isHovering,
                    selected: this.props.isSelectedAnswer,
                })}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                onClick={this.props.onClick}
            >
                {(this.props.imageUrl !== null || this.props.priceRange !== null) && (
                    <div className="pointer-tooltip">
                        {this.props.imageUrl !== null && (
                            <div className="pointer-tooltip-img-container">
                                {this.props.isSelectedAnswer && <div className="active-icon ion-md-checkmark" />}
                                <img src={this.props.imageUrl} />
                            </div>
                        )}
                        {this.props.priceRange !== null && (
                            <strong>
                                <PriceRange range={String(this.props.priceRange)} break={true} />
                            </strong>
                        )}
                    </div>
                )}

                <div
                    className={classNames(
                        this.props.className,
                        this.props.iconName,
                        {'pointer-object': this.props.isBaseObject},
                        'pointer'
                    )}
                />
            </div>
        );
    }
}
