import {computed, makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../support/composite_subscription';
import {GroupType} from '../../../enum/group_type';
import {Observable} from 'rxjs';
import {Presenter} from '../../../../support/presenter/presenter';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {map} from 'rxjs/operators';
import {ConstructionCostsProvider} from '../../../business/construction_costs_provider';

export interface ChildQuestionsProvider {
    stream: Observable<Question[]>;
}

export class ConstructionCostCounterPresenter implements Presenter {
    @observable public totalCostEuros: number | null = null;

    @computed
    public get visible() {
        return this.currentPathnameContainsConstructionCost;
    }

    @observable private currentPathnameContainsConstructionCost = false;

    private subscriptions = new CompositeSubscription();

    constructor(
        private questionSet: QuestionSet,
        private childQuestionsProvider: ChildQuestionsProvider,
        private constructionCostsProvider: ConstructionCostsProvider
    ) {
        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            this.childQuestionsProvider.stream
                .pipe(
                    //Find one that has our group in it
                    map<Question[], boolean>((questions) =>
                        questions.some((question) =>
                            question.group?.toString().includes(GroupType.CONSTRUCTION_COST.toString())
                        )
                    )
                )
                .subscribe((currentPathnameContainsConstructionCost) => {
                    runInAction(() => {
                        this.currentPathnameContainsConstructionCost = currentPathnameContainsConstructionCost;
                    });
                })
        );

        this.subscriptions.add(
            this.constructionCostsProvider.stream().subscribe((sum) => {
                runInAction(() => {
                    this.totalCostEuros = sum;
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
