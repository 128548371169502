import {combineLatest, Observable, of} from 'rxjs';

import {FloorQuestionType, NormalQuestionType, RootGroupQuestionType} from '../enum/question_type';
import {FloorType, floorTypeToQuestionType} from '../enum/floor_type';
import {distinctUntilChanged, map, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {findChildrenForQuestion, questionSortCompareFn} from '../appraise/ui/support/question_filtering';

import {Answer} from '../models/answer';
import {AnswerController} from './answering/answer_controller';
import {Appraisal} from '../models/appraisal';
import {AppraisalState} from '../enum/appraisal_state';
import {FloorSorting} from '../../enums/floor_sorting';
import {GlobalProvider} from '../../business/global_provider';
import {PagePart} from '../models/page_part';
import {PagePartsSet} from '../models/page_parts_set';
import {Question} from '../models/question';
import {QuestionEffectInteractor} from './conditions/question_effects_interactor';
import {QuestionSet} from '../models/question_set';
import {compactHouseGroupConfigurationFromAnswer} from '../appraise/ui/content/questions/support/house_group_question/compact_house_group_default_configuration';
import {houseGroupConfigurationFromAnswer} from '../appraise/ui/content/questions/support/house_group_question/house_group_default_configuration';
import {lazy} from '../../support/lazy';

export enum SidebarItemSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export interface SidebarItem {
    link: string;
    label: string;
    icon: string;
    iteration: string | null;
    sidebarItemSize: SidebarItemSize;
    disabled?: boolean;
    question?: Question;
    pagePart?: PagePart;
    children: SidebarItem[];
}

export class SidebarTreeBuilder {
    private sidebarQuestionTypes = [
        RootGroupQuestionType.PRE_GROUP,
        RootGroupQuestionType.POST_GROUP,
        RootGroupQuestionType.HOUSE_GROUP,
        RootGroupQuestionType.HOUSE_INSIDE,
        RootGroupQuestionType.HOUSE_GROUP_COMPACT,
        RootGroupQuestionType.PHOTO_GROUP,
        RootGroupQuestionType.BUILDING_COSTS_SHEET,
        RootGroupQuestionType.ENERGETIC_SHEET,
        RootGroupQuestionType.OUTSIDE_GROUP,
        RootGroupQuestionType.OBSERVATION_GROUP,
        RootGroupQuestionType.ROOT_GROUP,
        FloorQuestionType.FLOOR_GROUP_ATTIC,
        FloorQuestionType.FLOOR_GROUP_FLOOR,
        FloorQuestionType.FLOOR_GROUP_GROUND,
        FloorQuestionType.FLOOR_GROUP_BASEMENT,
        NormalQuestionType.GROUP,
        NormalQuestionType.FILES_GROUP,
    ];

    constructor(
        private questionSet: QuestionSet,
        private appraisal: Appraisal,
        private pagePartsSet: PagePartsSet | null,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor,
        private globalProvider: GlobalProvider
    ) {}

    @lazy()
    public get build(): Observable<SidebarItem[]> {
        if (this.pagePartsSet !== null) {
            return this.buildPagePartsSidebarItems(this.pagePartsSet);
        }
        return this.buildQuestionSetSidebarItems();
    }

    public buildStaticItems(pagePartsSet: PagePartsSet): SidebarItem[] {
        return [
            {
                link: `/config-id/${pagePartsSet.id}/validation-page/control`,
                label: 'Controle',
                icon: 'finished',
                iteration: null,
                sidebarItemSize: SidebarItemSize.LARGE,
                children: [],
            },
            {
                link: `/config-id/${pagePartsSet.id}/validation-page/concept-report`,
                label: 'Concept raport',
                icon: 'concept-report',
                iteration: null,
                sidebarItemSize: SidebarItemSize.LARGE,
                children: [],
            },
            {
                link: `/config-id/${pagePartsSet.id}/appraisal-copy`,
                label: 'Kopiëren',
                icon: 'copy',
                iteration: null,
                sidebarItemSize: SidebarItemSize.LARGE,
                children: [],
            },
        ];
    }

    private buildPagePartsSidebarItems(pagePartsSet: PagePartsSet) {
        const rootItems = pagePartsSet.rootItems;

        const sidebarRootItems = [];
        for (const rootItem of rootItems) {
            const question = rootItem.questionUuid
                ? this.questionSet.findQuestionByUuid(rootItem.questionUuid)
                : undefined;

            const item: SidebarItem = {
                link: `/config-id/${pagePartsSet.id}/page-part/${rootItem.uuid}`,
                label: rootItem.name ?? rootItem.uuid,
                icon: rootItem.icon ?? question?.type ?? '',
                pagePart: rootItem,
                question: question,
                iteration: null,
                sidebarItemSize: SidebarItemSize.LARGE,
                children: [],
            };

            if (rootItem.isIndexPage === true) {
                const children = pagePartsSet.getChildrenForUuid(rootItem.uuid);
                for (const child of children) {
                    const childQuestion = child.questionUuid
                        ? this.questionSet.findQuestionByUuid(child.questionUuid)
                        : undefined;

                    item.children.push({
                        link: `/config-id/${pagePartsSet.id}/page-part/${child.uuid}`,
                        label: child.name ?? child.uuid,
                        icon: child.icon ?? question?.type ?? '',
                        pagePart: child,
                        question: childQuestion,
                        iteration: null,
                        sidebarItemSize: SidebarItemSize.SMALL,
                        children: [],
                    });
                }
            }

            sidebarRootItems.push(item);
        }
        return this.processEffects(sidebarRootItems).pipe(shareReplay(1));
    }

    private buildQuestionSetSidebarItems() {
        const houseGroupQuestionType = this.questionSet.reportDefintionConfig.compactHouseGroupSelection
            ? RootGroupQuestionType.HOUSE_GROUP_COMPACT
            : RootGroupQuestionType.HOUSE_GROUP;

        const houseGroupQuestion = this.questionSet.findQuestionsByType(houseGroupQuestionType)[0] ?? undefined;
        if (houseGroupQuestion === undefined) {
            return this.getFallback();
        }

        return this.answerController.answerByIdentifiersStream(houseGroupQuestion.uuid, null, null).pipe(
            distinctUntilChanged(),
            map((houseGroupAnswer) => this.getItems(houseGroupAnswer)),
            switchMap((sidebarItems) => this.processEffects(sidebarItems)),
            map((sidebarItems) => this.mergeWithSpecials(sidebarItems)),
            shareReplay(1)
        );
    }

    private getItems(houseGroupAnswer: Answer): SidebarItem[] {
        return this.questionSet.questions
            .filter((q) => this.isAllowedInSidebar(q))
            .sort((a, b) => questionSortCompareFn(a, b))
            .map((question) => {
                switch (question.type) {
                    case RootGroupQuestionType.HOUSE_GROUP:
                    case RootGroupQuestionType.HOUSE_GROUP_COMPACT:
                    case RootGroupQuestionType.HOUSE_INSIDE:
                        return {
                            link: '/' + question.uuid,
                            label: question.contents,
                            icon: question.type,
                            question: question,
                            iteration: null,
                            sidebarItemSize: SidebarItemSize.LARGE,
                            children: this.houseGroupChildren(houseGroupAnswer),
                        };
                    case RootGroupQuestionType.OUTSIDE_GROUP:
                    case RootGroupQuestionType.CONCEPT_REPORT:
                        return {
                            link: '/' + question.uuid,
                            label: question.contents,
                            icon: question.type,
                            question: question,
                            iteration: null,
                            disabled: false,
                            sidebarItemSize: SidebarItemSize.LARGE,
                            children: [],
                        };
                    case RootGroupQuestionType.OBSERVATION_GROUP:
                        return {
                            link: '/' + question.uuid,
                            label: question.contents,
                            icon: question.type,
                            question: question,
                            iteration: null,
                            disabled: false,
                            sidebarItemSize: SidebarItemSize.LARGE,
                            children: this.children(question),
                        };
                    default:
                        return {
                            link: '/' + question.uuid,
                            label: question.contents,
                            icon: question.type,
                            question: question,
                            iteration: null,
                            sidebarItemSize: SidebarItemSize.LARGE,
                            children: this.children(question),
                        };
                }
            })
            .reduce((p: SidebarItem[], c: SidebarItem) => {
                if (c.question === undefined) {
                    return [...p, c];
                }
                switch (c.question.type) {
                    case RootGroupQuestionType.HOUSE_GROUP:
                    case RootGroupQuestionType.HOUSE_INSIDE:
                    case RootGroupQuestionType.HOUSE_GROUP_COMPACT:
                        //Move children from the house group to the root
                        return [...p, {...c, children: []}, ...c.children];
                    default:
                        return [...p, c];
                }
            }, []);
    }

    private isAllowedInSidebar(question: Question) {
        // This question is rendered inside a group but should be visible in the sidebar
        if (question.type === RootGroupQuestionType.HOUSE_GROUP_COMPACT) {
            //But only if there is not a `HOUSE_INSIDE` question present
            //This because then its renderered as a child of this question
            return this.questionSet.findQuestionsByType(RootGroupQuestionType.HOUSE_INSIDE).length === 0;
        }

        // By default only parent questions should be in the sidebar
        return question.parentUuid === null && this.sidebarQuestionTypes.some((type) => question.type === type);
    }

    private houseGroupChildren(answer: Answer): SidebarItem[] {
        let floors: SidebarItem[] = [];

        if (this.questionSet.reportDefintionConfig.compactHouseGroupSelection) {
            floors = this.renderCompactHouseGroup(answer);
        } else {
            floors = this.renderDefaultHouseGroup(answer);
        }

        if (this.globalProvider.global.floorSorting === FloorSorting.DOWN_TOP) {
            return [...floors.reverse()];
        }

        return floors;
    }

    private renderCompactHouseGroup(answer: Answer): SidebarItem[] {
        const houseGroupConfiguration = compactHouseGroupConfigurationFromAnswer(answer);
        return [
            ...this.renderFloorTypeItems(
                houseGroupConfiguration.numberOfFloors,
                'Woonlaag',
                FloorType.FLOOR,
                SidebarItemSize.MEDIUM
            ),
        ];
    }

    private renderDefaultHouseGroup(answer: Answer) {
        const houseGroupConfiguration = houseGroupConfigurationFromAnswer(answer);
        return [
            ...this.renderFloorTypeItems(
                houseGroupConfiguration.numberOfAttics,
                'Zolder',
                FloorType.ATTIC,
                SidebarItemSize.LARGE
            ),
            ...this.renderFloorTypeItems(
                houseGroupConfiguration.numberOfFloors,
                'Verdieping',
                FloorType.FLOOR,
                SidebarItemSize.LARGE
            ),
            ...this.renderFloorTypeItems(
                houseGroupConfiguration.numberOfGroundFloors,
                'Begane grond',
                FloorType.GROUND,
                SidebarItemSize.LARGE
            ),
            ...this.renderFloorTypeItems(
                houseGroupConfiguration.numberOfBasements,
                'Kelder',
                FloorType.BASEMENT,
                SidebarItemSize.LARGE
            ),
        ];
    }

    private renderFloorTypeItems(n: number, label: string, floorType: FloorType, size: SidebarItemSize): SidebarItem[] {
        const items: SidebarItem[] = [];

        const question = this.questionSet.findQuestionsByType(floorTypeToQuestionType(floorType))[0] ?? undefined;
        if (question) {
            for (let i = 0; i < n; i++) {
                const iteration = i + 1;

                let prefixedLabel = label;
                if (n > 1) {
                    prefixedLabel = iteration + 'e ' + label.toLowerCase();
                }

                let icon = 'floor-group-' + floorType;
                if (
                    floorType === FloorType.FLOOR &&
                    this.questionSet.reportDefintionConfig.compactHouseGroupSelection
                ) {
                    icon = 'floor-group-' + floorType + '-number-' + iteration;
                }

                items.push({
                    link: '/' + floorType + '/' + iteration,
                    label: prefixedLabel,
                    icon: icon,
                    question: question,
                    iteration: '' + iteration,
                    sidebarItemSize: size,
                    children: this.children(question, iteration),
                });
            }
        }

        return [...items.reverse()];
    }

    private children(parent: Question, iteration: number | null = null): SidebarItem[] {
        return findChildrenForQuestion(parent, this.questionSet).map((question) => {
            let link = '/' + question.uuid;
            if (iteration !== null) {
                link += '/' + iteration;
            }
            return {
                link: link,
                label: question.contents,
                icon: question.type,
                iteration: iteration === null ? null : '' + iteration,
                question: question,
                sidebarItemSize: SidebarItemSize.SMALL,
                children: [], //This is a child, so no children
            };
        });
    }

    private getFallback(): Observable<SidebarItem[]> {
        const items: SidebarItem[] = this.questionSet.questions
            .filter((question) => question.parentUuid === null)
            .sort((a, b) => questionSortCompareFn(a, b))
            .map((question) => {
                return {
                    link: '/' + question.uuid,
                    label: question.contents,
                    icon: question.type,
                    iteration: null,
                    question: question,
                    sidebarItemSize: SidebarItemSize.LARGE,
                    children: this.children(question),
                };
            });
        return Observable.create().pipe(startWith(items));
    }

    private mergeWithSpecials(sidebarItems: SidebarItem[]): SidebarItem[] {
        let sidebarItemsCopy = sidebarItems;
        if (
            this.appraisal.status === AppraisalState.REQUESTED_CORRECTION ||
            this.appraisal.status === AppraisalState.REQUESTED_CORRECTION_AFTER_APPROVAL
        ) {
            const validationInstituteValidationMessagesLink = {
                link: '/validation-messages',
                label: 'Validatiefouten',
                icon: 'validation-messages',
                iteration: null,
                sidebarItemSize: SidebarItemSize.LARGE,
                children: [],
            };

            sidebarItemsCopy = [validationInstituteValidationMessagesLink, ...sidebarItemsCopy];
        }

        const taxapiValidationMessagesLink = {
            link: '/validation-page',
            label: 'Controle',
            icon: 'finished',
            iteration: null,
            sidebarItemSize: SidebarItemSize.LARGE,
            children: [],
        };

        return [...sidebarItemsCopy, taxapiValidationMessagesLink];
    }

    private processEffects(items: SidebarItem[]): Observable<SidebarItem[]> {
        const questions = this.questionsFromTree(items);
        if (questions.length === 0) {
            return of(items);
        }

        return combineLatest(
            questions.map((question) => {
                return this.questionEffectInteractor.isHiddenStream(question.uuid, null).pipe(
                    map((isHidden) => {
                        return {
                            question,
                            isHidden,
                        };
                    })
                );
            })
        ).pipe(map((isHiddenStates) => this.filterHiddenSidebarItems(items, isHiddenStates)));
    }

    private filterHiddenSidebarItems(
        items: SidebarItem[],
        isHiddenStates: Array<{question: Question; isHidden: boolean}>
    ): SidebarItem[] {
        const isHiddenMap = this.createHiddenStateMap(isHiddenStates);
        const filtered = items.filter((item) => this.filterHiddenSidebarItem(item, isHiddenMap));

        return filtered.map((item) => {
            return {
                ...item,
                children: item.children.filter((child) => this.filterHiddenSidebarItem(child, isHiddenMap)),
            };
        });
    }

    private createHiddenStateMap(isHiddenStates: Array<{question: Question; isHidden: boolean}>) {
        const hiddenStateMap = new Map<string, boolean>();
        for (const isHiddenState of isHiddenStates) {
            hiddenStateMap.set(isHiddenState.question.uuid, isHiddenState.isHidden);
        }
        return hiddenStateMap;
    }

    private filterHiddenSidebarItem(item: SidebarItem, isHiddenMap: Map<string, boolean>): boolean {
        const question = item.question;
        if (!question) {
            return true;
        }

        return !(isHiddenMap.get(question.uuid) ?? false);
    }

    private questionsFromTree(items: SidebarItem[]) {
        const questions = [];

        for (const item of items) {
            if (item.question) {
                questions.push(item.question);
            }

            for (const childItem of item.children) {
                if (childItem.question) {
                    questions.push(childItem.question);
                }
            }
        }

        return questions;
    }
}
