import * as uuid from 'uuid';

import {FlashMessageBroadcaster, Type} from '../flash_message_broadcaster';
import {FloorQuestionType, NormalQuestionType, RootGroupQuestionType} from '../../enum/question_type';

import {Answer} from '../../models/answer';
import {AnswerController} from './answer_controller';
import {AnswerTouchState} from '../../enum/answer_touch_state';
import {Question} from '../../models/question';
import {QuestionSet} from '../../models/question_set';
import {RenderingContextType} from '../../enum/rendering_context_type';
import {isInEnum} from '../../../support/is_in_enum';
import {isIteratorQuestionType} from '../../../support/is_iterator_question_type';
import {isSet} from '../../../support/is_set';
import {FileReference} from '../../models/file_reference';
import v5 from 'uuid/v5';
import {QuestionEffectInteractor} from '../conditions/question_effects_interactor';
import {isAnswerOptionQuestionType} from '../../../support/is_answer_option_question_type';

interface QuestionAnswerPair {
    question: Question;
    answer: Answer;
}

export type StubberValuesMap = Map<string, StubberValueMapEntry>;

export type StubberValueMapEntry = {
    questionUuid: string;
    iteration: string | null;
    parentUuid: string | null;

    contents: string | null;
    answerOptionUuid: string | null;
    file: FileReference | null;
};

export interface QuestionUuidIteratorUuidPair {
    questionUuid: string;
    iteration: string;
}

export type AnswerContents = Record<
    string,
    undefined | string | boolean | number | {contents: null | string; file: FileReference}
>;

export class AnswerPathStubber {
    /**
     * Uuid V5 namespace for stubber map uuids
     */
    private NAMESPACE = 'cfe9debe-22ed-4630-8123-591250d49929';

    private CHECKED_VALUE = '1';
    private UNCHECKED_VALUE = '0';

    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private flashMessageBroadcaster: FlashMessageBroadcaster,
        private renderingContext: RenderingContextType,
        private questionEffectInteractor: QuestionEffectInteractor
    ) {}

    private skipParentAnswerUuid(question: Question, parentQuestion?: Question): boolean {
        //If we are stubbing inside a page_parts appraisal environment, all the logic about not doing parentUuid's goes out the window
        //This because page_part_group & page_part_iterator reset the parentUuid anyway
        if (this.renderingContext === RenderingContextType.PAGE_PARTS_APPRAISAL) {
            return false;
        }

        //In this method we check which questions/children dont need a parentAnswerUuid
        //This mainly happens if in the UI somehow we dont pass a parentAnswerUuid to the next child

        if (
            question.parentUuid === null &&
            (question.type === NormalQuestionType.GROUP || question.type === NormalQuestionType.GROUP_COMPACT)
        ) {
            //Its possible a group will render an IndexPage (doesnt have a parentAnswerUuid), see questionContainer case for GROUP/GROUP_COMPACT
            return true;
        }

        //These questionType's all render a IndexPage which doenst pass through a parentAnswerUuid
        if (
            question.type === RootGroupQuestionType.PHOTO_GROUP ||
            question.type === RootGroupQuestionType.PRE_GROUP ||
            question.type === RootGroupQuestionType.POST_GROUP ||
            question.type === FloorQuestionType.FLOOR_GROUP_ATTIC ||
            question.type === FloorQuestionType.FLOOR_GROUP_FLOOR ||
            question.type === FloorQuestionType.FLOOR_GROUP_GROUND ||
            question.type === FloorQuestionType.FLOOR_GROUP_BASEMENT
        ) {
            return true;
        }

        //If the parent is one of these, the children dont have a parentAnswerUuid either
        if (
            parentQuestion &&
            (parentQuestion.type === RootGroupQuestionType.PHOTO_GROUP ||
                parentQuestion.type === RootGroupQuestionType.PRE_GROUP ||
                parentQuestion.type === RootGroupQuestionType.POST_GROUP ||
                parentQuestion.type === FloorQuestionType.FLOOR_GROUP_ATTIC ||
                parentQuestion.type === FloorQuestionType.FLOOR_GROUP_FLOOR ||
                parentQuestion.type === FloorQuestionType.FLOOR_GROUP_GROUND ||
                parentQuestion.type === FloorQuestionType.FLOOR_GROUP_BASEMENT)
        ) {
            return true;
        }

        return false;
    }

    private getParentAnswerUuid(question: Question, previousPair?: QuestionAnswerPair): string | null {
        if (this.skipParentAnswerUuid(question, previousPair?.question)) {
            return null;
        }

        return previousPair?.answer.uuid ?? null;
    }

    private getIterator(
        question: Question,
        questionUuidIteratorUuidPairs: QuestionUuidIteratorUuidPair[],
        parentPair?: QuestionAnswerPair
    ): string | null {
        //We need a floor number if the iterator is a FLOOR_GROUP_FLOOR
        if (question.type === FloorQuestionType.FLOOR_GROUP_FLOOR) {
            const floorNumber = questionUuidIteratorUuidPairs.find((pair) => pair.questionUuid === question.uuid);
            if (!isSet(floorNumber)) {
                this.flashMessageBroadcaster.broadcast(
                    'De gekozen locatie bevindt zich op een woonlaag. Ga a.u.b. naar de juiste woonlaag zodat deze hier ingevuld kan worden.',
                    Type.Danger
                );
            } else {
                return floorNumber.iteration;
            }
        }

        //If this item is the direct child of a FLOOR_GROUP_FLOOR we also set the floor number in the iteration
        if (
            parentPair &&
            question.type === NormalQuestionType.GROUP &&
            parentPair.question.type === FloorQuestionType.FLOOR_GROUP_FLOOR
        ) {
            return parentPair.answer.iteration;
        }

        //Else we simply check if we have iteration uuid or simply create a new one
        return isIteratorQuestionType(question.type)
            ? questionUuidIteratorUuidPairs.find((pair) => pair.questionUuid === question.uuid)?.iteration ?? uuid.v4()
            : questionUuidIteratorUuidPairs.find((pair) => pair.questionUuid === question.uuid)?.iteration ?? null;
    }

    private setAnswerContents(
        question: Question,
        answer: Answer,
        answerContents: AnswerContents,
        answerTouchState = AnswerTouchState.UNTOUCHED
    ) {
        let answerContent = answerContents[answer.questionUuid];
        if (isSet(answerContent)) {
            if (answerContent === true) {
                answerContent = this.CHECKED_VALUE;
            }
            if (answerContent === false) {
                answerContent = this.UNCHECKED_VALUE;
            }
            if (
                typeof answerContent === 'object' &&
                answerContent !== null &&
                'file' in answerContent &&
                'contents' in answerContent
            ) {
                return this.answerController.attachExistingFile(
                    answer.uuid,
                    answerContent.contents,
                    answerContent.file
                );
            }

            if (question.type === NormalQuestionType.DATE) {
                const dateString = String(answerContent).includes('T')
                    ? String(answerContent).split('T')[0]
                    : String(answerContent);
                return this.answerController.onContentsChange(answer.uuid, dateString, answerTouchState);
            }

            if (
                question.answerOptions &&
                question.answerOptions.length > 0 &&
                isAnswerOptionQuestionType(question.type)
            ) {
                const answerOption = question.answerOptions.find((option) => {
                    return (
                        option.contents.toLocaleLowerCase().trim() ===
                            String(answerContent).toLocaleLowerCase().trim() ||
                        option.reportValue?.toLocaleLowerCase().trim() ===
                            String(answerContent).toLocaleLowerCase().trim()
                    );
                });
                if (answerOption) {
                    return this.answerController.onAnswerOptionChange(answer.uuid, answerOption.id, answerTouchState);
                } else if (answerContent === this.CHECKED_VALUE) {
                    const yesOption = question.answerOptions.find(
                        (option) => option.contents.toLocaleLowerCase().trim() === 'ja'
                    );
                    if (yesOption) {
                        return this.answerController.onAnswerOptionChange(answer.uuid, yesOption.id, answerTouchState);
                    }
                } else if (answerContent === this.UNCHECKED_VALUE) {
                    const noOption = question.answerOptions.find(
                        (option) => option.contents.toLocaleLowerCase().trim() === 'nee'
                    );
                    if (noOption) {
                        return this.answerController.onAnswerOptionChange(answer.uuid, noOption.id, answerTouchState);
                    }
                }
            }

            return this.answerController.onContentsChange(answer.uuid, String(answerContent), answerTouchState);
        }
    }

    public stubPathForQuestionUuid(
        questionUuid: string,
        questionUuidIteratorUuidPairs: QuestionUuidIteratorUuidPair[] = [],
        answerContents?: AnswerContents,
        resetDeleted?: boolean
    ): QuestionAnswerPair[] {
        //Find the question matching the provided questionUuid
        const question = this.questionSet.findQuestionByUuid(questionUuid);
        if (question === undefined) {
            throw new Error('No question found with uuid: ' + questionUuid);
        }

        //Get an array of all the parents from this question, first item in the array is the highest parent
        let parentTrace: Question[] | null = null;

        if (this.renderingContext === RenderingContextType.APPRAISAL) {
            parentTrace = this.questionSet.findParentPathByPredicateRecursive(
                question,
                (q) =>
                    q.type === RootGroupQuestionType.HOUSE_GROUP_COMPACT ||
                    isInEnum(RootGroupQuestionType, q.type) ||
                    q.parentUuid === null
            );

            if (parentTrace) {
                const rootQuestion = parentTrace[0];
                if (
                    rootQuestion.type !== RootGroupQuestionType.HOUSE_GROUP_COMPACT &&
                    rootQuestion.type !== RootGroupQuestionType.HOUSE_INSIDE &&
                    rootQuestion.type !== RootGroupQuestionType.OUTSIDE_GROUP &&
                    rootQuestion.type !== RootGroupQuestionType.CONCEPT_REPORT &&
                    rootQuestion.type !== RootGroupQuestionType.ROOT_GROUP &&
                    isInEnum(RootGroupQuestionType, rootQuestion.type)
                ) {
                    //Exclude the root question (those are the big circles in the sidebar, including the "woonlagen" HOUSE_GROUP_COMPACT)
                    //These arent supposed to have a answer
                    parentTrace.shift();
                }
            }
        } else {
            parentTrace = this.questionSet.findParentPathByPredicateRecursive(question, (q) => q.parentUuid === null);
        }
        if (parentTrace === null) {
            throw new Error('Root question not found for questionUuid: ' + questionUuid);
        }
        //Retrieve the answer for every parent, or stub it.
        //If we encounter a iterator, check if we provided a iteratorUuid for this questionUuid in the `questionUuidIteratorUuidPairs` else create a new iteratorUuid
        const pairs = parentTrace.reduce<QuestionAnswerPair[]>((acc, q, index) => {
            const previousPair = acc[index - 1] as QuestionAnswerPair | undefined;
            const parentAnswerUuid = this.getParentAnswerUuid(q, previousPair);
            const iteratorUuid = this.getIterator(q, questionUuidIteratorUuidPairs, previousPair);
            let answer = this.answerController.answerByIdentifiersOrStub(q.uuid, parentAnswerUuid, iteratorUuid);
            if (answer.isDeleted === true && resetDeleted === true) {
                const restoredAnswer = this.answerController.restore(answer.uuid);
                if (!restoredAnswer) {
                    throw new Error('Restoring answer failed!');
                }
                answer = restoredAnswer;
            }
            if (answerContents) {
                answer = this.setAnswerContents(q, answer, answerContents) ?? answer;
            }

            const questionAnswerPair: QuestionAnswerPair = {
                question: q,
                answer,
            };

            return [...acc, questionAnswerPair];
        }, []);

        return pairs;
    }

    public stubChildren(
        parentAnswer: Answer,
        answerContents: AnswerContents,
        questionUuidIteratorUuidPairs: QuestionUuidIteratorUuidPair[] = [],
        questionUuidFilter: string[] | null = null,
        overwriteAnswers = false,
        shouldSkipIterations: (
            questionUuid: string,
            parentAnswerUuid: string,
            iterationAnswers: Answer[]
        ) => boolean = (_, _2, a) => a.length > 0
    ): Answer[] {
        const answers: Answer[] = [];

        const childQuestions = this.questionSet.findChildQuestionsByParentUuid(parentAnswer.questionUuid);
        for (const childQuestion of childQuestions) {
            const symlinkParent = this.questionSet.findParentByPredicateRecursive(
                childQuestion,
                (q) =>
                    q.type === NormalQuestionType.SYMLINK_TARGET ||
                    q.type === NormalQuestionType.SYMLINK_TARGET_COPY ||
                    q.type === NormalQuestionType.SYMLINK_LINK
            );
            if (
                symlinkParent !== null ||
                childQuestion.type === NormalQuestionType.SYMLINK_TARGET ||
                childQuestion.type === NormalQuestionType.SYMLINK_TARGET_COPY ||
                childQuestion.type === NormalQuestionType.SYMLINK_LINK
            ) {
                //We dont wanna stub symlinks, since they get an iteration uuid conditionally
                continue;
            }

            const parentQuestion = this.questionSet.findQuestionByUuid(parentAnswer.questionUuid);
            let iteratorUuid = null;

            //If we encounter a iteration and it already has some answers we will stop stubbing
            //else we might get the situation that every stub call will result in a new iteration
            //since the stubber will create a new iteration UUID every time
            if (isIteratorQuestionType(childQuestion.type)) {
                const existingAnswers = this.answerController.answersForQuestionUuid(
                    childQuestion.uuid,
                    parentAnswer.uuid
                );
                const iterationsAnswers = existingAnswers.filter((a) => !a.isDeleted);
                const shouldSkip = shouldSkipIterations(childQuestion.uuid, parentAnswer.uuid, iterationsAnswers);
                if (shouldSkip) {
                    continue;
                }
            }
            if (parentQuestion) {
                iteratorUuid = this.getIterator(childQuestion, questionUuidIteratorUuidPairs, {
                    question: parentQuestion,
                    answer: parentAnswer,
                });
            } else {
                iteratorUuid = this.getIterator(childQuestion, questionUuidIteratorUuidPairs);
            }

            let answer = this.answerController.answerByIdentifiers(childQuestion.uuid, parentAnswer.uuid, iteratorUuid);
            if (answer) {
                if (overwriteAnswers === true) {
                    if (answer.isDeleted) {
                        answer = this.answerController.restore(answer.uuid) ?? answer;
                    }
                    if (answerContents[answer.questionUuid]) {
                        answer =
                            this.setAnswerContents(childQuestion, answer, answerContents, answer.touchState) ?? answer;
                    }
                }
                answers.push(answer);
            } else {
                if (questionUuidFilter === null || questionUuidFilter.includes(childQuestion.uuid)) {
                    answer = this.answerController.answerByIdentifiersOrStub(
                        childQuestion.uuid,
                        parentAnswer.uuid,
                        iteratorUuid
                    );
                    if (answerContents[answer.questionUuid]) {
                        answer = this.setAnswerContents(childQuestion, answer, answerContents) ?? answer;
                    }
                    answers.push(answer);
                }
            }
            if (answer) {
                const childAnswers = this.stubChildren(
                    answer,
                    answerContents,
                    questionUuidIteratorUuidPairs,
                    questionUuidFilter,
                    overwriteAnswers,
                    shouldSkipIterations
                );
                for (const childAnswer of childAnswers) {
                    answers.push(childAnswer);
                }
            }
        }

        return answers;
    }

    public buildStubberValuesMap(answerUuid: string) {
        // The stubber values map tries to build a minimal map containing all answer data
        // It tries to skip any answers which are not relevant to the map
        // For example if there are a few nested layers of group answers which have no content, then these are skipped, as the answer stubber can easily recreate these when needed
        // Only when there are iterations involved, it is important to keep track of which answer belongs to which parent.

        const map: StubberValuesMap = new Map();

        const processAnswer = (answerUuid: string, parentKey: string | null) => {
            const childAnswers = this.answerController
                .filterDeleted(this.answerController.answersByParentAnswerUuid(answerUuid))
                .filter((answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.parentUuid));

            for (const childAnswer of childAnswers) {
                const question = this.questionSet.findQuestionByUuid(childAnswer.questionUuid);
                if (!question) {
                    continue;
                }

                let key = parentKey ? `${parentKey}|${childAnswer.questionUuid}` : childAnswer.questionUuid;
                if (childAnswer.iteration) {
                    key = `${key}:${childAnswer.iteration}`;
                }

                const uuid = v5(key, this.NAMESPACE);

                map.set(uuid, {
                    questionUuid: childAnswer.questionUuid,
                    iteration: childAnswer.iteration ?? null,
                    parentUuid: parentKey ? v5(parentKey, this.NAMESPACE) : null,

                    contents: childAnswer.contents ?? null,
                    answerOptionUuid: childAnswer.answerOptionId
                        ? this.questionSet
                              .findQuestionByUuid(childAnswer.questionUuid)
                              ?.answerOptions.find((ao) => ao.id === childAnswer.answerOptionId)?.uuid ?? null
                        : null,
                    file: childAnswer.file,
                });

                const childrenNeedKey =
                    parentKey !== null || childAnswer.iteration !== null || isIteratorQuestionType(question.type);

                processAnswer(childAnswer.uuid, childrenNeedKey ? key : null);
            }
        };

        processAnswer(answerUuid, null);

        // Clean up map with unnecessary answers
        const uuidsWithContent = Array.from(map.entries())
            .filter(([, v]) => v.contents !== null || v.answerOptionUuid !== null || v.file !== null)
            .map(([uuid]) => uuid);

        // We use an explicited for loop here, because we want to add items to the array while iterating over it
        for (let i = 0; i < uuidsWithContent.length; i++) {
            const value = map.get(uuidsWithContent[i]);
            if (!value) {
                continue;
            }

            if (value.parentUuid && !uuidsWithContent.includes(value.parentUuid)) {
                // This is appended to the end of the array, so we will make sure to also process this newly added uuid
                uuidsWithContent.push(value.parentUuid);
            }
        }

        // Remove unnecessary entries (i.e. entries which are not needed for parents, and which have no contents)
        for (const [uuid] of map.entries()) {
            if (!uuidsWithContent.includes(uuid)) {
                map.delete(uuid);
            }
        }

        return map;
    }

    public stubValuesMap(parentAnswer: Answer, answerContents: StubberValuesMap, overwriteAnswers = false) {
        const processed = new Map<string, Answer | null>();
        const clearedIterations = new Set<string>();

        const processAnswer = (uuid: string, entry: StubberValueMapEntry): Answer | null => {
            if (processed.has(uuid)) {
                return processed.get(uuid) as Answer;
            }

            // Infinite recursion prevention (should not be needed)
            processed.set(uuid, null);

            const question = this.questionSet.findQuestionByUuid(entry.questionUuid);
            if (!question) {
                return null;
            }

            let entryParentAnswer = parentAnswer;

            if (entry.parentUuid) {
                const parentEntry = answerContents.get(entry.parentUuid);
                const answer = parentEntry ? processAnswer(entry.parentUuid, parentEntry) : null;
                if (!answer) {
                    return null;
                }

                entryParentAnswer = answer;
            }

            // Get the list of uuids we are allowed to stub (i.e. the questions on the path from parent to current)
            const uuidPath = (
                this.questionSet.findParentPathByPredicateRecursive(
                    question,
                    (q) => q.uuid !== entryParentAnswer.questionUuid
                ) ?? [question]
            ).map((q) => q.uuid);

            const values: AnswerContents = {};
            if (entry.file) {
                values[entry.questionUuid] = {
                    contents: entry.contents,
                    file: entry.file,
                };
            } else if (entry.contents) {
                values[entry.questionUuid] = entry.contents;
            } else if (entry.answerOptionUuid) {
                const answerOption = question.answerOptions.find((ao) => ao.uuid === entry.answerOptionUuid);
                values[entry.questionUuid] = answerOption?.contents ?? '';
            }

            const iterations: QuestionUuidIteratorUuidPair[] = [];
            if (entry.iteration) {
                iterations.push({questionUuid: entry.questionUuid, iteration: entry.iteration});
            }

            const createdAnswers = this.stubChildren(
                entryParentAnswer,
                values,
                iterations,
                uuidPath,
                overwriteAnswers,
                (questionUuid, parentUuid, iterationsAnswers) => {
                    // We always want to continue adding answers for iterations, but we just have to make sure we clear existing iterations the first time we encounter them
                    if (clearedIterations.has(`${questionUuid}|${parentUuid}`)) {
                        return false;
                    }

                    this.answerController.deleteMultiple(iterationsAnswers.map((a) => a.uuid));

                    clearedIterations.add(`${questionUuid}|${parentUuid}`);

                    return false;
                }
            );

            const answer = createdAnswers.find((a) => a.questionUuid === entry.questionUuid) ?? null;

            processed.set(uuid, answer);

            return answer;
        };

        // We aggregate updates to make sure the app doesn't perform an entire rerender for every answer that is stubbed or updated
        // This will temporarily pause the answer streams while we are performing our actions
        this.answerController.aggregateUpdates(() => {
            for (const [uuid, entry] of answerContents.entries()) {
                processAnswer(uuid, entry);
            }
        });

        return processed;
    }
}
