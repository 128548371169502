import {format} from 'date-fns';
import React from 'react';
import {ReferenceSale} from '../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v1/models/reference_sale';
import {FloorAreaPrice} from '../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v3/components/floor_area_price';
import {PriceRange} from '../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v3/components/price_range';
import {
    Source,
    sourceToClassName,
} from '../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v3/models/reference_object';
import {Modal} from '../appraising/appraise/ui/content/questions/components/modal';
import {classNames} from '../support/classnames';
import {ImageViewer} from '../appraising/components/image_viewer/image_viewer';
import {referenceObjectImages} from '../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/reference_sale_images';

export function SearchDetailsModal({referenceObject, onClose}: {referenceObject: ReferenceSale; onClose: () => void}) {
    const imageUrls = referenceObjectImages(referenceObject, null);

    return (
        <Modal visible>
            <div className="modal-header">
                <h2>
                    {referenceObject.street} {referenceObject.houseNumber}
                    {referenceObject.letter}
                </h2>
            </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-sm-6">
                        <h2>Object details</h2>
                        <dl>
                            <dt>Adres</dt>
                            <dl>
                                {referenceObject.street} {referenceObject.houseNumber}
                                {referenceObject.letter}
                                <br />
                                {referenceObject.postalCode} {referenceObject.city}
                            </dl>
                        </dl>
                        <dl>
                            <dt>Woningtype</dt>
                            <dd>{referenceObject.objectType ?? '-'}</dd>
                        </dl>
                        <dl>
                            <dt>Bouwjaar</dt>
                            <dl>{referenceObject.buildYear === null ? '-' : referenceObject.buildYear}</dl>
                        </dl>
                        <dl>
                            <dt>Energielabel</dt>
                            <dd>{referenceObject.energyLabel !== null ? referenceObject.energyLabel : '-'}</dd>
                        </dl>
                        <dl>
                            <dt>Gebruiksoppervlakte wonen</dt>
                            <dl>{referenceObject.floorArea === null ? '-' : referenceObject.floorArea + 'm²'}</dl>
                        </dl>
                        <dl>
                            <dt>Perceeloppervlakte</dt>
                            <dl>{referenceObject.plotArea === null ? '-' : referenceObject.plotArea + 'm²'}</dl>
                        </dl>
                    </div>
                    <div className="col-sm-6">
                        <div className="detail-image">
                            <div
                                className={classNames(
                                    'reference-logo',
                                    sourceToClassName(referenceObject.source ?? null)
                                )}
                            >
                                &nbsp;
                            </div>
                            <ImageViewer
                                images={imageUrls
                                    .map((imageUrl) => imageUrl.url)
                                    .filter((url): url is string => url !== null)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <dl>
                            <dt>Prijs per m²</dt>
                            <dl>
                                <FloorAreaPrice
                                    referenceSetPrice={null}
                                    referenceObjectFloorArea={referenceObject.floorArea}
                                    referenceObjectPriceRange={
                                        referenceObject.priceRange !== null ? String(referenceObject.priceRange) : null
                                    }
                                    referenceObjectIndexedPrice={
                                        referenceObject.indexedPriceRange !== null
                                            ? String(referenceObject.indexedPriceRange)
                                            : null
                                    }
                                    referenceSetFloorArea={null}
                                    referenceObjectId={null}
                                />
                            </dl>
                        </dl>
                        <dl>
                            <dt>Bruto inhoud</dt>
                            <dl>{referenceObject.volume === null ? '-' : 'ca. ' + referenceObject.volume + 'm³'}</dl>
                        </dl>
                        {referenceObject.ownership && (
                            <dl>
                                <dt>Eigendom</dt>
                                <dl>{referenceObject.ownership}</dl>
                            </dl>
                        )}
                        {referenceObject.rooms && (
                            <dl>
                                <dt>Aantal kamers</dt>
                                <dl>{referenceObject.rooms}</dl>
                            </dl>
                        )}
                        {referenceObject.energyLabel && (
                            <dl>
                                <dt>Energielabel</dt>
                                <dl>{referenceObject.energyLabel}</dl>
                            </dl>
                        )}
                        {referenceObject.brochureUrl && (
                            <a
                                className="btn btn-sm"
                                href={referenceObject.brochureUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {referenceObject.source === Source.ARCHIEF_NVM ? 'Stamkaart' : 'Brochure'}
                                <span
                                    className="status-label status-label-beta"
                                    data-toggle="popover"
                                    tabIndex={0}
                                    data-placement="top"
                                    data-trigger="hover"
                                    data-content={
                                        'Bekijk meer gegevens via de ' + referenceObject.source === Source.ARCHIEF_NVM
                                            ? 'stamkaart'
                                            : 'brochure'
                                    }
                                >
                                    <span className="ion-md-star"></span>
                                    Bèta
                                </span>
                            </a>
                        )}
                    </div>
                    <div className="col-6">
                        <h2>Verkoop details</h2>
                        <dl>
                            <dt>Prijsklasse</dt>
                            <dl>
                                <PriceRange
                                    range={
                                        referenceObject.priceRange !== null ? String(referenceObject.priceRange) : null
                                    }
                                />
                            </dl>
                        </dl>
                        {referenceObject.indexedPriceRange && (
                            <dl>
                                <dt>Geïndexeerde prijsklasse</dt>
                                <dl>
                                    <PriceRange
                                        range={
                                            referenceObject.indexedPriceRange !== null
                                                ? String(referenceObject.indexedPriceRange)
                                                : null
                                        }
                                    />
                                </dl>
                            </dl>
                        )}
                        <dl>
                            <dt>Datum afmelding/verkocht op</dt>
                            <dl>{referenceObject.saleQuarter === null ? 'Onbekend' : referenceObject.saleQuarter}</dl>
                        </dl>
                        {referenceObject.transportDate && (
                            <dl>
                                <dt>Transportdatum</dt>
                                <dl>{format(new Date(referenceObject.transportDate), 'dd-MM-yyyy')}</dl>
                            </dl>
                        )}
                        {referenceObject.daysOpenForSale && (
                            <dl>
                                <dt>Aantal dagen te koop</dt>
                                <dl>
                                    {!isNaN(Number(referenceObject.daysOpenForSale))
                                        ? Number(referenceObject.daysOpenForSale) === 1
                                            ? `${Number(referenceObject.daysOpenForSale)} dag`
                                            : `${Number(referenceObject.daysOpenForSale)} dagen`
                                        : referenceObject.daysOpenForSale}
                                </dl>
                            </dl>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        {referenceObject.highlights && referenceObject.highlights.length > 0 ? (
                            <div>
                                <h2>Highlights</h2>
                                <ul>
                                    {referenceObject.highlights.map((highlight, index) => (
                                        <li key={highlight + '-' + index}>{highlight}</li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button className="btn btn-primary btn-full ion-md-close" type="button" onClick={onClose}>
                    Sluiten
                </button>
            </div>
        </Modal>
    );
}
