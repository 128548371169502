import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {Link} from 'react-router-dom';
import {PagePart} from '../../../../models/page_part';
import {PagePartIndexPagePresenter} from './page_part_index_page_presenter';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {QuestionSet} from '../../../../models/question_set';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {usePresenter} from '../../../../../support/presenter/use_presenter';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    activePagePart: PagePart;
    pagePartsSet: PagePartsSet;
    iteration?: string;
    renderingContext: RenderingContextType;
}

export const PagePartIndexPage: React.FunctionComponent<OwnProps> = ({
    appraisal,
    questionSet,
    activePagePart,
    pagePartsSet,
    iteration,
    renderingContext,
}) => {
    const presenter = usePresenter(
        (component) =>
            new PagePartIndexPagePresenter(
                renderingContext,
                component.business.questionEffectsInteractor(appraisal, questionSet)
            )
    );
    return (
        <div>
            <ul className="list-simple">
                {presenter.children(activePagePart, pagePartsSet).map((child) => {
                    let to = '';
                    if (window.location.href.includes('page-parts-configuration')) {
                        to = `/page-parts-configuration/${pagePartsSet.id}/`;
                    } else {
                        to = `/config-id/${pagePartsSet.id}/page-part/`;
                    }
                    to += child.uuid;
                    if (iteration) {
                        to += '/' + iteration;
                    }
                    return (
                        <li key={child.uuid}>
                            <Link to={to}>{child.name}</Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
