import * as React from 'react';

import {Appraisal} from '../../../../../../../../../models/appraisal';
import {PlotAreaBadge} from '../../../../../../../../../components/badges/plot_area_badge';
import {QuestionSet} from '../../../../../../../../../models/question_set';

interface OwnProps {
    compact?: boolean;
    plotArea: number;
    perceelOppervlakte: number;
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export class PlotAreaBadgeAdapter extends React.Component<OwnProps> {
    public render() {
        return (
            <PlotAreaBadge
                compact={this.props.compact}
                plotArea={this.props.plotArea}
                referencePlotArea={this.props.perceelOppervlakte}
            />
        );
    }
}
