import {BbmiAreaProvider} from './bbmi_area_provider';
import {Observable} from 'rxjs';
import {TechnicalReference} from '../../enum/technical_reference';

export interface GebruiksOppervlakteExterneBergruimteProvider {
    stream(): Observable<number | null>;
}

export class DefaultGebruiksOppervlakteExterneBergruimteProvider
    implements GebruiksOppervlakteExterneBergruimteProvider
{
    constructor(private bbmiAreaProviderProvider: BbmiAreaProvider) {}

    public stream(): Observable<number | null> {
        return this.bbmiAreaProviderProvider.stream(
            TechnicalReference.BBMI_EXTERNE_BERGRUIMTE,
            TechnicalReference.AREA_EXTERNAL_STORAGE_SPACE
        );
    }
}
