function escapeHTML(text: string) {
    return $('<div>').text(text).html();
}

export function renderButton(text: string | null) {
    if (text === null) {
        return '';
    }
    return `<div class="d-flex justify-content-center"><a class="button button-primary" target="_blank" rel="noopener">${escapeHTML(
        text
    )}</a></div>`;
}
