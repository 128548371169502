import {ReferenceObjectsMetadataVersion} from '../../enum/reference_objects_metadata_version';
import {RawReferenceObjectsV3Metadata, ReferenceObjectsV3Metadata} from '../../models/reference_objects_metadata';
import {
    apiReferenceSalesToReferenceSales,
    referenceObjectToApiReferenceObject,
} from '../../network/models/api_reference_sale';
import {ReferenceObjectsMetadataManager} from './reference_objects_metadata_manager';

export class v3ReferenceObjectsMetadataManager
    implements ReferenceObjectsMetadataManager<RawReferenceObjectsV3Metadata, ReferenceObjectsV3Metadata>
{
    public readonly version = ReferenceObjectsMetadataVersion.V3;

    fromRaw(raw: RawReferenceObjectsV3Metadata): ReferenceObjectsV3Metadata {
        return {
            version: this.version,
            preselectedObjects: apiReferenceSalesToReferenceSales(raw.preselected_objects ?? []),
            rejectedObjects: apiReferenceSalesToReferenceSales(raw.rejected_objects ?? []),
        };
    }

    toRaw(metadata: ReferenceObjectsV3Metadata): RawReferenceObjectsV3Metadata {
        return {
            version: this.version,
            preselected_objects: metadata.preselectedObjects.map((referenceSale) =>
                referenceObjectToApiReferenceObject(referenceSale)
            ),
            rejected_objects: metadata.rejectedObjects.map((referenceSale) =>
                referenceObjectToApiReferenceObject(referenceSale)
            ),
        };
    }

    combine(...metadatas: ReferenceObjectsV3Metadata[]): ReferenceObjectsV3Metadata {
        return metadatas.reduce((a, b) => {
            const preselectedObjectIds = new Set(b.preselectedObjects.map((o) => `${o.id}-${o.source}`));
            const rejectedObjectIds = new Set(b.rejectedObjects.map((o) => `${o.id}-${o.source}`));

            return {
                version: this.version,
                preselectedObjects: [
                    ...a.preselectedObjects.filter((o) => !preselectedObjectIds.has(`${o.id}-${o.source}`)),
                    ...b.preselectedObjects,
                ],
                rejectedObjects: [
                    ...a.rejectedObjects.filter((o) => !rejectedObjectIds.has(`${o.id}-${o.source}`)),
                    ...b.rejectedObjects,
                ],
            };
        }, this.empty());
    }

    empty(): ReferenceObjectsV3Metadata {
        return {
            version: this.version,
            preselectedObjects: [],
            rejectedObjects: [],
        };
    }
}
