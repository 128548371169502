import {BuildingCostsLabel, buildingCostsLabels} from './building_costs_condition/building_costs_labels';
import {NormalQuestionType, RootGroupQuestionType} from '../../../../../enum/question_type';
import {buildQuestionAnswerTrees, QuestionAnswerPair} from '../../../../../../support/question_answer_tree';
import {
    findChildRecursiveByPredicate,
    findChildrenRecursiveByPredicate,
    TreeItem,
} from '../../../../../../support/generic_tree';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {debounceTime, map} from 'rxjs/operators';

import {AnswerController} from '../../../../../business/answering/answer_controller';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {FloorIterationProvider} from '../../../../../business/floor_iteration_provider';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {Question} from '../../../../../models/question';
import {QuestionEffectInteractor} from '../../../../../business/conditions/question_effects_interactor';
import {QuestionSet} from '../../../../../models/question_set';
import {TechnicalReference} from '../../../../../enum/technical_reference';
import {formatBuildingCostsReference} from '../../../support/building_costs_reference_formatter';
import {getNewestAnswer} from '../../../../../../support/get_newest_answer';
import {groupBy} from '../../../../../../support/array';
import {isNumeric} from '../../../../../../support/util';
import {isSet} from '../../../../../../support/is_set';
import {AnswerTouchState} from '../../../../../enum/answer_touch_state';
import {Appraisal} from '../../../../../models/appraisal';
import {AppraisalValidationType} from '../../../../../enum/appraisal_validation_type';
import {AppraisalState} from '../../../../../enum/appraisal_state';

export interface Section {
    title: string;
    subtitle: string;
    iteration: string | null;
    treeItem: TreeItem<QuestionAnswerPair>;
    differentLabel: string | null;
}

type SectionMap = Map<string, Section[]>;

export class BuildingCostsSheetPresenter implements Presenter {
    @observable.ref public sections: SectionMap = new Map() as SectionMap;
    @observable public isLoading = true;
    @observable private floorCount: number | null = null;
    @observable public setAllValue = 1;
    @observable public setAllActive = false;

    private subscriptions = new CompositeSubscription();

    @computed
    public get isDisabled(): boolean {
        if (!this.appraisal.isEditableAppraisal) {
            return true;
        }
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return this.appraisal.isFrozen;
        }
        return (
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor,
        private floorIterationProvider: FloorIterationProvider
    ) {
        makeObservable(this);
    }

    public shouldShowCosts(pair: QuestionAnswerPair | null) {
        if (pair && pair.answer && pair.answer.contents) {
            const options = ['redelijk', 'goed'];
            return !options.includes(pair.answer.contents);
        }
        return false;
    }

    public shouldShowPulse(pair: QuestionAnswerPair | null) {
        if (pair && pair.answer && pair.answer.contents) {
            const options = ['redelijk', 'goed', 'niet waarneembaar', 'nader onderzoek nodig'];
            return !options.includes(pair.answer.contents);
        }
        return false;
    }

    public isAvailable(tree: TreeItem<QuestionAnswerPair>) {
        const availabilityQuestions = findChildrenRecursiveByPredicate(
            tree,
            (i) => i.question.technicalReference === TechnicalReference.BUILDING_COSTS_GROUP_TYPE
        );
        return availabilityQuestions.some((ti) => this.hasPositiveAnswer(ti.item));
    }

    private hasPositiveAnswer(pair: QuestionAnswerPair) {
        const negativeAnswerOption = pair.question.answerOptions.find(
            (ao) => ao.contents.toLowerCase() === 'nee' || ao.reportValue === '0'
        );
        switch (pair.question.type) {
            case NormalQuestionType.BOOLEAN:
                return pair.answer?.contents === '1';
            case NormalQuestionType.MC:
                return isSet(pair.answer?.answerOptionId) && pair.answer?.answerOptionId !== negativeAnswerOption?.id;
            case NormalQuestionType.MC_SELECT:
            case NormalQuestionType.MC_SELECT_OPTIONAL:
                if (negativeAnswerOption) {
                    return (
                        isSet(pair.answer?.answerOptionId) && pair.answer?.answerOptionId !== negativeAnswerOption?.id
                    );
                }
                return pair.answer?.answerOptionId !== null;
            default:
                return (pair.answer?.contents ?? '').length > 0;
        }
    }

    private shouldShowIteration(iteration: number): boolean {
        return this.floorCount !== null && this.floorCount >= iteration;
    }

    public mount(): void {
        let floorQuestion = this.questionSet.findQuestionsByType(RootGroupQuestionType.HOUSE_GROUP_COMPACT)[0];
        if (floorQuestion === null) {
            floorQuestion = this.questionSet.findQuestionsByType(RootGroupQuestionType.HOUSE_GROUP)[0];
        }

        if (floorQuestion) {
            this.subscriptions.add(
                this.answerController.answersForQuestionUuidStream(floorQuestion.uuid).subscribe((answers) => {
                    const floorCount = JSON.parse(getNewestAnswer(answers)?.contents ?? '{}').numberOfFloors ?? null;
                    this.floorCount = floorCount !== null ? parseInt(floorCount, 10) : null;
                })
            );
        }

        this.subscriptions.add(
            this.answerController
                .answersStream()
                .pipe(
                    debounceTime(1000),
                    map((answers) => this.answerController.filterDeleted(answers)),
                    map((answers) =>
                        answers.filter(
                            (answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid)
                        )
                    ),
                    map((answers) => {
                        const groups = this.questionSet.findQuestionsByType(NormalQuestionType.BUILDING_COSTS_GROUP);
                        return groups.map((group) => buildQuestionAnswerTrees(this.questionSet, answers, group)).flat();
                    })
                )
                .subscribe((groupTrees) => {
                    const sections: Section[] = [];
                    let referenceParts = null;
                    for (const groupTree of groupTrees) {
                        if (groupTree.item.question.buildingCostsReference === null) {
                            continue;
                        }
                        referenceParts = groupTree.item.question.buildingCostsReference.split('.').map((rp) => {
                            if (isNumeric(rp[rp.length - 1])) {
                                return rp.slice(0, rp.length - 1);
                            }
                            return rp;
                        });
                        const isAvailable =
                            groupTree.children.filter((c) => c.item.answer).filter((c) => this.isAvailable(c)).length >
                            0;
                        let differentLabel = null;
                        groupTree.children
                            .filter(
                                (c) => c.item.question.contents.toLocaleLowerCase() === 'namelijk:'.toLocaleLowerCase()
                            )
                            .map((c) => {
                                differentLabel = c.item.answer?.contents;
                            });

                        if (isAvailable) {
                            const iteration = this.floorIterationProvider.findFloorNumber(groupTree.item.answer);
                            if (iteration === null || this.shouldShowIteration(iteration)) {
                                sections.push({
                                    title: formatBuildingCostsReference(referenceParts[0]),
                                    subtitle: formatBuildingCostsReference(referenceParts[1]),
                                    iteration: iteration ? iteration + 'e woonlaag' : null,
                                    treeItem: groupTree,
                                    differentLabel: differentLabel ?? null,
                                });
                            }
                        }
                    }

                    const groupedSections: SectionMap = groupBy(
                        sections.sort(this.sortByTitle).sort(this.sortByIteration),
                        (i) => i.title
                    );

                    runInAction(() => {
                        this.sections = groupedSections;
                        this.isLoading = false;
                    });
                })
        );
    }

    @action
    public onChangeSetAll(value: string) {
        this.setAllValue = Number(value);
    }

    @action
    public onToggleSelectAllActive() {
        this.setAllActive = !this.setAllActive;
    }

    public setConditionAll(value: number) {
        for (const section of Array.from(this.sections.values()).flat()) {
            const conditionChildPair = findChildRecursiveByPredicate(
                section.treeItem,
                (i) => i.question.type === NormalQuestionType.BUILDING_COSTS_CONDITION
            );

            if (!conditionChildPair || !conditionChildPair.item.answer) {
                continue;
            }

            this.answerController.onContentsChange(
                conditionChildPair.item.answer.uuid,
                this.valueToTextValue(conditionChildPair.item.question, value),
                AnswerTouchState.TOUCHED
            );
        }
    }

    private valueToTextValue(question: Question, value: number | null): string {
        const label = buildingCostsLabels.find((o: BuildingCostsLabel) => o.value === value);
        if (label) {
            return label.textValue;
        }
        if (question.defaultValue !== null) {
            const defaultValue = this.findDefaultValue(question);
            if (defaultValue !== null) {
                return defaultValue.textValue;
            }
        }
        return 'goed'; // default value
    }

    private findDefaultValue(question: Question) {
        return (
            buildingCostsLabels.find(
                (x) =>
                    x.textValue.toLowerCase() === question.defaultValue?.toLowerCase() ||
                    x.short.toLowerCase() === question.defaultValue?.toLowerCase()
            ) ?? null
        );
    }

    private sortByTitle(a: Section, b: Section) {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    }

    private sortByIteration(a: Section, b: Section) {
        if (a.iteration === null || b.iteration === null) {
            return 0;
        }
        if (a.iteration < b.iteration) {
            return -1;
        }
        if (a.iteration > b.iteration) {
            return 1;
        }
        return 0;
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
