import * as React from 'react';

import {ReferenceSetSelectorPresenter} from './reference_set_selector_presenter';

import {Appraisal} from '../../../../../../../models/appraisal';
import {ExtraReferenceSaleSet} from './models/reference_sale';
import {QuestionSet} from '../../../../../../../models/question_set';
import {ReferenceObjectAnswer} from './models/reference_object_answer';
import {ReferenceSet} from './models/reference_set';
import {classNames} from '../../../../../../../../support/classnames';
import {formatMoney} from '../../../../../support/format_money';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../../support/presenter/use_presenter';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    activeSet: ReferenceSet | null;
    defaultSet: ReferenceSet;
    extraReferenceSalesSets: ExtraReferenceSaleSet[];
    referenceObjectAnswers: ReferenceObjectAnswer[];
    defaultReferenceSalesRequired: boolean;
    isLoading: boolean;
    activateReferenceSalesSet: (referenceSaleSetId: string | null) => void;
}

export const ReferenceSetSelector: React.FC<OwnProps> = observer(function ReferenceSetSelector(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceSetSelectorPresenter(
                props.activateReferenceSalesSet,
                container.business.answerController(props.appraisal, props.questionSet),
                props.questionSet
            )
    );

    const options = presenter.buildOptions(
        props.defaultReferenceSalesRequired ? props.defaultSet : null,
        props.extraReferenceSalesSets,
        props.referenceObjectAnswers
    );

    return (
        <div className="reference-objects-nav-container">
            <ul className="reference-objects-nav">
                {props.isLoading ? (
                    <li className="reference-objects-nav-item active">
                        <span className="reference-objects-nav-item-placeholder-title">&nbsp;</span>
                    </li>
                ) : (
                    options.map((option, index) => {
                        return (
                            <li
                                key={option.setId ?? String(index)}
                                className={classNames('reference-objects-nav-item', {
                                    active: props.activeSet !== null && props.activeSet.id === option.setId,
                                })}
                                onClick={() => presenter.onSelect(option.setId)}
                            >
                                <span className="reference-objects-nav-item-title">
                                    <span className="reference-objects-nav-item-title-inner">
                                        {option.valuation ? formatMoney(parseInt(option.valuation, 10)) : '-'}
                                    </span>
                                    <span
                                        className={classNames('bubble', {
                                            'bubble-red': option.selectedCount < presenter.minReferenceObjects,
                                        })}
                                    >
                                        {option.selectedCount}/{presenter.minReferenceObjects}
                                    </span>
                                </span>
                            </li>
                        );
                    })
                )}
            </ul>
        </div>
    );
});
