export enum SortingMethod {
    ALPHABETICALLY = 'Alfabetisch',
    SALES_DATE = 'Verkoopdatum',
    DISTANCE = 'Afstand',
    DEVIATION_SCORE = 'Match score',
}

export enum SortingDirection {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}
