/**
 * Dismissing of modal
 */
(() => {
    for (const modal of document.querySelectorAll('.modal[data-modal-type]')) {
        const type = modal.getAttribute('data-modal-type');

        const dismissButton = modal.querySelector('#modal-dismiss-button');
        if (dismissButton) {
            dismissButton.onclick = () => {
                return window.fetch(`/ajax/modal/${type}/dismiss`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': document.head.querySelector('meta[name="csrf-token"]').content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                });
            };
        }
    }
})();
