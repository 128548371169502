import {action, makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {SimpleQuestionPresenter} from '../simple/simple_question_presenter';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {Question} from '../../../../../models/question';
import {getNewestAnswer} from '../../../../../../support/get_newest_answer';

export class CollapsableChecklistPresenter extends SimpleQuestionPresenter {
    @observable public hasSearchedChildren = false;
    @observable public expanded = false;
    @observable.ref public childQuestions: Question[] = [];

    private subscriptions = new CompositeSubscription();

    constructor(...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>) {
        super(...simpleQuestionPresenterParameters);
        this.childQuestions = findChildrenForQuestion(this.question, this.questionSet) ?? [];
        makeObservable(this);
    }

    public async mount() {
        super.mount();

        if (this.childQuestions[0] && this.answer) {
            this.subscriptions.add(
                this.answerController
                    .answersForQuestionUuidAndParentAnswerUuidInSameIterationStream(
                        this.childQuestions[0].uuid,
                        this.answer?.uuid
                    )
                    .subscribe((answers) => {
                        runInAction(() => {
                            // Expand when the first question is not checked
                            const answer = getNewestAnswer(answers);
                            if (answer !== null) {
                                this.expanded = answer.contents === '0';
                            }
                        });
                    })
            );
        }
    }

    public async unmount() {
        super.unmount();

        this.subscriptions.clear();
    }

    @action
    public expand() {
        this.expanded = !this.expanded;
    }
}
