import {AdaptedValueBuilder} from './adapted_value_builder';
import {AdaptedValueConfigProvider} from '../internal/adapted_value_config';
import {Answer} from '../../../models/answer';
import {AnswerContentBuilder} from '../internal/answer_content_builder';
import {AnswerController} from '../../answering/answer_controller';
import {Appraisal} from '../../../models/appraisal';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {SentenceBuilder} from '../internal/sentence_builder';
import {TechnicalReference} from '../../../enum/technical_reference';
import {isEmpty} from '../../../../support/util';
import {sortAnswersByUpdatedAt} from '../../../../support/sort_answer';

export class ObjectAppraiserExplanationAdaptedValueBuilder implements AdaptedValueBuilder {
    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private adaptedValueConfigProvider: AdaptedValueConfigProvider,
        private valuesBuilder: SentenceBuilder,
        private answerContentBuilder: AnswerContentBuilder
    ) {}

    public buildSentence(technicalReference: TechnicalReference): string {
        const sourceTechnicalReferences = this.adaptedValueConfigProvider.forTechnicalReference(technicalReference);

        // Get all questions from config
        const objectDescriptionQuestions: Question[] = [];
        const objectMaintenanceQuestions: Question[] = [];
        const objectLocationQuestions: Question[] = [];
        const objectLocationFactorsQuestions: Question[] = [];

        for (const sourceTechnicalReference of sourceTechnicalReferences) {
            const q = this.questionSet.findQuestionsByTechnicalReference(sourceTechnicalReference);
            if (q.length !== 0) {
                for (const question of q) {
                    if (
                        sourceTechnicalReference === TechnicalReference.OBJECT_OMSCHRIJVING ||
                        sourceTechnicalReference === TechnicalReference.OBJECT_OMSCHRIJVING_BIJGEBOUW
                    ) {
                        objectDescriptionQuestions.push(question);
                    } else if (sourceTechnicalReference === TechnicalReference.OBJECT_ONDERHOUDSITUATIE) {
                        objectMaintenanceQuestions.push(question);
                    } else if (
                        sourceTechnicalReference === TechnicalReference.OBJECT_LOCATIE ||
                        sourceTechnicalReference === TechnicalReference.OBJECT_LIGGING
                    ) {
                        objectLocationQuestions.push(question);
                    } else if (
                        sourceTechnicalReference === TechnicalReference.OBJECT_OMGEVINGSFACTOREN_WAARDEONTWIKKELING
                    ) {
                        objectLocationFactorsQuestions.push(question);
                    }
                }
            }
        }

        // Get all answers
        const objectDescriptionAnswers: string[] = [];
        for (const question of objectDescriptionQuestions) {
            const answers = this.answerController
                .filterDeleted(this.answerController.answersForQuestionUuid(question.uuid))
                .sort(this.sortByUpdatedAt);
            for (const answer of answers) {
                const content = this.answerContentBuilder.build(answer, question);
                if (content !== null && !isEmpty(content)) {
                    objectDescriptionAnswers.push(content);
                }
            }
        }

        const objectMaintenanceAnswers: string[] = [];
        for (const question of objectMaintenanceQuestions) {
            const answers = this.answerController
                .filterDeleted(this.answerController.answersForQuestionUuid(question.uuid))
                .sort(this.sortByUpdatedAt);
            for (const answer of answers) {
                const content = this.answerContentBuilder.build(answer, question);
                if (content !== null && !isEmpty(content)) {
                    objectMaintenanceAnswers.push(content);
                }
            }
        }

        const objectLocationAnswers: string[] = [];
        for (const question of objectLocationQuestions) {
            const answers = this.answerController
                .filterDeleted(this.answerController.answersForQuestionUuid(question.uuid))
                .sort(this.sortByUpdatedAt);
            for (const answer of answers) {
                const content = this.answerContentBuilder.build(answer, question);
                if (content !== null && !isEmpty(content)) {
                    objectLocationAnswers.push(content);
                }
            }
        }

        const objectLocationFactorsAnswers: string[] = [];
        for (const question of objectLocationFactorsQuestions) {
            const answers = this.answerController
                .filterDeleted(this.answerController.answersForQuestionUuid(question.uuid))
                .sort(this.sortByUpdatedAt);
            for (const answer of answers) {
                const content = this.answerContentBuilder.build(answer, question);
                if (content !== null && !isEmpty(content)) {
                    objectLocationFactorsAnswers.push(content);
                }
            }
        }

        // Combine as sentences
        const descriptions: string[] = [];

        descriptions.push(
            this.appraisal.objectType +
                ', ' +
                this.valuesBuilder.build(objectDescriptionAnswers) +
                ' en verdere aanhorigheden'
        );

        const objectMaintenanceDescription = this.valuesBuilder.build(objectMaintenanceAnswers);
        if (!isEmpty(objectMaintenanceDescription)) {
            descriptions.push(objectMaintenanceDescription);
        }

        const objectLocationDescription = this.valuesBuilder.build(objectLocationAnswers);
        if (!isEmpty(objectLocationDescription)) {
            descriptions.push(objectLocationDescription);
        }
        const objectLocationFactorsDescription = this.valuesBuilder.build(objectLocationFactorsAnswers);
        if (!isEmpty(objectLocationFactorsDescription)) {
            descriptions.push(objectLocationFactorsDescription);
        }

        return descriptions.join('. ');
    }

    private sortByUpdatedAt(a: Answer, b: Answer) {
        return sortAnswersByUpdatedAt(a, b);
    }
}
