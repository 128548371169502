import {makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../support/composite_subscription';
import {NotificationMessage} from '../models/notification_message';
import {NotificationsInteractor} from '../interactors/notifications_interactor';
import {NotificationsStackInteractor} from '../interactors/notifications_stack_interactor';
import {Presenter} from '../support/presenter';

export class NotificationsStackPresenter implements Presenter {
    @observable.ref public notifications: NotificationMessage[] = [];

    private subscriptions = new CompositeSubscription();
    constructor(
        private notificationsStackInteractor: NotificationsStackInteractor,
        private notificationsInteractor: NotificationsInteractor
    ) {
        makeObservable(this);
    }

    public mount() {
        this.subscriptions.add(
            this.notificationsStackInteractor.stream().subscribe((notifications) => {
                runInAction(() => {
                    this.notifications = notifications;
                });
            })
        );
    }

    public unmount() {
        this.subscriptions.clear();
    }

    public async onHide(id: string) {
        await this.notificationsStackInteractor.onHide(id);
    }

    public onShowModal(id: string) {
        this.notificationsInteractor.onShowModal(id);
    }
}
