import * as React from 'react';

import {CSS} from '@dnd-kit/utilities';
import {FunctionComponent, useState} from 'react';
import {PagePart} from '../../../../../../models/page_part';
import {PagePartsSet} from '../../../../../../models/page_parts_set';
import {QuestionContainer} from '../../question_container';
import {SimpleQuestionPresenterProps} from '../../simple/simple_question_presenter';
import {useSortable} from '@dnd-kit/sortable';
import {classNames} from '../../../../../../../support/classnames';

interface OwnProps extends SimpleQuestionPresenterProps {
    activePagePart: PagePart;
    pagePartsSet: PagePartsSet;
    onRemove: (pagePartsSetId: number, pagePartUuid: string) => void;
    onSetDraggingDisabled: (isDisabled: boolean) => void;
    onChangeExplanation: (pagePartsSetId: number, pagePartUuid: string, explanation: string) => void;
}

export const DraggableQuestionContainer: FunctionComponent<OwnProps> = ({
    activePagePart,
    pagePartsSet,
    onRemove,
    onChangeExplanation,
    ...props
}) => {
    const {attributes, listeners, setNodeRef, transform, transition, isDragging, active} = useSortable({
        transition: {
            duration: 150, // milliseconds
            easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
        },
        id: activePagePart.uuid,
    });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    const [isExplanationOpen, setExplanationOpen] = useState(false);
    const [explanation, setExplanation] = useState(activePagePart.explanation ?? '');

    function onClickRemove(): void {
        onRemove(pagePartsSet.id, activePagePart.uuid);
        props.onSetDraggingDisabled(false);
    }

    function onClickOpen(): void {
        const newIsExplanationOpen = !isExplanationOpen;
        setExplanationOpen(newIsExplanationOpen);
        props.onSetDraggingDisabled(newIsExplanationOpen);
    }

    function onClickStore(): void {
        onChangeExplanation(pagePartsSet.id, activePagePart.uuid, explanation);
        setExplanationOpen(false);
        props.onSetDraggingDisabled(false);
    }

    return (
        <div
            className={classNames('draggable-question-container ion-md-reorder', {
                'draggable-question-container-editable': isExplanationOpen,
                'draggable-question-container-disabled': pagePartsSet.config.isEditable === false,
                'draggable-question-container-dragging': isDragging,
                'draggable-question-container-other-dragging': isDragging == false && active !== null,
            })}
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            aria-disabled={isExplanationOpen}
        >
            {pagePartsSet.config.isEditable === true && (
                <>
                    <button
                        className="action-button delete-button btn btn-sm ion-md-trash"
                        onClick={() => onClickRemove()}
                    />
                    <button
                        className="action-button edit-button btn btn-sm ion-md-settings"
                        onClick={() => onClickOpen()}
                    />
                </>
            )}

            {isExplanationOpen && (
                <div className="draggable-question-container-form">
                    <div className="form-group">
                        <label>Uitleg toevoegen</label>
                        <textarea
                            rows={2}
                            className="form-control"
                            value={explanation}
                            onChange={(e) => setExplanation(e.target.value)}
                        ></textarea>
                    </div>
                    <div className="form-group">
                        <button className="btn btn-sm ion-md-checkmark float-right" onClick={() => onClickStore()}>
                            Opslaan
                        </button>
                    </div>
                </div>
            )}

            {isDragging && <h2>{props.question.contents}</h2>}

            {!isDragging && (
                <QuestionContainer
                    disabled={props.disabled}
                    appraisal={props.appraisal}
                    question={props.question}
                    onChange={props.onChange}
                    questionSet={props.questionSet}
                    validationErrors={props.validationErrors}
                    validationMessages={props.validationMessages}
                    parentAnswerUuid={undefined}
                    adaptedDefaultValues={props.adaptedDefaultValues}
                    disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                    hiddenQuestionTypes={props.hiddenQuestionTypes}
                    pagePartsSet={pagePartsSet}
                    activePagePart={activePagePart}
                    renderingContext={props.renderingContext}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    questionRenderingData={props.questionRenderingData}
                />
            )}
        </div>
    );
};
