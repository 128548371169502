import * as React from 'react';

import {Answer} from '../../../../../models/answer';
import {AnswerTouchState} from '../../../../../enum/answer_touch_state';
import {ValidationMessage} from '../../../../../business/validation/validation_message';
import {classNames} from '../../../../../../support/classnames';

interface OwnProps {
    alignLeft?: boolean;
    fullWidth?: boolean;
    disabled: boolean;
    validationMessages: ValidationMessage[] | null;
    answer: Answer | undefined;
    forceShowValidationMessages: boolean;
}

export class ValidationMessages extends React.Component<OwnProps> {
    public render() {
        if (
            this.props.forceShowValidationMessages === false &&
            this.props.answer?.touchState === AnswerTouchState.UNTOUCHED
        ) {
            return null;
        }

        if (
            this.props.disabled === true ||
            this.props.validationMessages === null ||
            this.props.validationMessages.length === 0
        ) {
            return null;
        }

        const messagesSet = new Set<string>();
        for (const validationMessage of this.props.validationMessages) {
            if (validationMessage.messages.length > 0) {
                for (const message of validationMessage.messages) {
                    messagesSet.add(message);
                }
            } else if (validationMessage.fallbackMessage !== null) {
                messagesSet.add(validationMessage.fallbackMessage);
            }
        }

        const messages = Array.from(messagesSet.values()).filter((message) => message !== '');
        if (messages.length === 0) {
            return null;
        }

        return (
            <span
                className={classNames('invalid-feedback', {
                    left: this.props.alignLeft,
                    'full-width': this.props.fullWidth,
                })}
            >
                {messages.map((message, key) => {
                    return <div key={key}>{message}</div>;
                })}
            </span>
        );
    }
}
