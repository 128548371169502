import {Observable} from 'rxjs';

import {distinctUntilChanged, map} from 'rxjs/operators';
import {PagePartConfigurationsInteractor} from './page_part_configurations_interactor';
import {GlobalProvider} from '../../../business/global_provider';
import {PagePart} from '../../models/page_part';
import {PagePartConfiguration, PagePartConfigurationType} from '../../models/page_part_configuration';
import {PagePartsSet, DefaultPagePartsSet} from '../../models/page_parts_set';
import {PagePartConfigurationApi} from '../../network/page_part_configuration_api';
import {PagePartsCleaner} from './page_parts_cleaner';

export interface PagePartsSetInteractor {
    selectedPagePartSetStream(configId: number): Observable<PagePartsSet | null>;
    pagePartsSetsStream(): Observable<PagePartsSet[]>;
    updateSetsRanks(pagePartsSets: PagePartsSet[], ranks: [{id: number; rank: number}]): Promise<void>;
    updatePagePartsRanks(configId: number, ranks: [{uuid: string; rank: number}]): void;
    updatePagePartsExplanation(configId: number, uuid: string, explanation: string): void;
    addPagePart(configId: number, newPagePart: PagePart): void;
    removePagePart(configId: number, pagePartUuid: string): void;
    setActiveConfigId(id: number): void;
    setPagePartsSetVisibility(pagePartsSet: PagePartsSet, isVisible: boolean): Promise<void>;
    setPagePartsSetSharing(pagePartsSet: PagePartsSet, isShared: boolean): Promise<void>;
    duplicateSet(pagePartsSet: PagePartsSet, partial: Partial<PagePartConfiguration>): Promise<void>;
    deleteSet(pagePartsSet: PagePartsSet): Promise<void>;
    createNewSet(
        pagePartsSets: PagePartsSet[] | null,
        name: string,
        description: string,
        isShared: boolean,
        rank: number
    ): Promise<PagePartConfiguration>;
    updateSet(pagePartsSet: PagePartsSet): Promise<void>;
}

export interface ActivePagePartConfigurationIdInteractor {
    activePagePartConfigurationIdStream: Observable<number | null>;
    setActiveConfigId(id: number): void;
}

export class DefaultPagePartsSetInteractor implements PagePartsSetInteractor {
    private pagePartsSetsObservable: Observable<PagePartsSet[]> = this.pagePartConfigurationsInteractor.stream.pipe(
        map((configs) => (configs ? Array.from(configs.values()).map((config) => new DefaultPagePartsSet(config)) : []))
    );

    constructor(
        private globalProvider: GlobalProvider,
        private pagePartConfigurationsInteractor: PagePartConfigurationsInteractor,
        private pagePartsCleaner: PagePartsCleaner,
        private activePagePartConfigurationIdInteractor: ActivePagePartConfigurationIdInteractor,
        private pagePartConfigurationApi: PagePartConfigurationApi
    ) {}

    public async duplicateSet(pagePartsSet: PagePartsSet, partial: Partial<PagePartConfiguration>): Promise<void> {
        await this.duplicateConfig(pagePartsSet.config, partial);
    }

    private async duplicateConfig(
        config: Omit<PagePartConfiguration, 'id'> & {id?: number},
        partial?: Partial<PagePartConfiguration>
    ): Promise<PagePartConfiguration> {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {id: _, ...configCopy} = config;
        const newConfig = {
            ...configCopy,
            ...(partial ?? {}),
            type: PagePartConfigurationType.CUSTOM,
            isShared: false,
        };
        const newConfiguration = await this.pagePartConfigurationApi.storeConfiguration(newConfig);
        if (newConfiguration.id) {
            await this.pagePartConfigurationApi.storePageParts(
                newConfiguration.id,
                newConfig.pageParts.map((pp) => ({...pp, pagePartConfigurationId: newConfiguration.id}))
            );
            const newConfigurationWithPagePartsAndCustomizations =
                await this.pagePartConfigurationApi.storeCustomizations(
                    newConfiguration.id,
                    newConfig.customizations.map((c) => ({...c, pagePartConfigurationId: newConfiguration.id}))
                );
            if (newConfigurationWithPagePartsAndCustomizations) {
                return this.pagePartConfigurationsInteractor.upsertConfiguration(
                    newConfigurationWithPagePartsAndCustomizations
                );
            }
        }
        return this.pagePartConfigurationsInteractor.upsertConfiguration(newConfiguration);
    }

    public async deleteSet(pagePartsSet: PagePartsSet): Promise<void> {
        await this.pagePartConfigurationApi.deleteConfiguration(pagePartsSet.id);
        return this.pagePartConfigurationsInteractor.removeConfiguration(pagePartsSet.id);
    }

    public async setPagePartsSetVisibility(pagePartSet: PagePartsSet, isVisible: boolean): Promise<void> {
        const updatedConfiguration = await this.pagePartConfigurationApi.storeConfiguration({
            ...pagePartSet.config,
            isVisible,
        });
        this.pagePartConfigurationsInteractor.upsertConfiguration(updatedConfiguration);
    }

    public async setPagePartsSetSharing(pagePartSet: PagePartsSet, isShared: boolean): Promise<void> {
        const updatedConfiguration = await this.pagePartConfigurationApi.storeConfiguration({
            ...pagePartSet.config,
            isShared,
        });
        this.pagePartConfigurationsInteractor.upsertConfiguration(updatedConfiguration);
    }

    public pagePartsSetsStream(): Observable<PagePartsSet[]> {
        return this.pagePartsSetsObservable;
    }

    public selectedPagePartSetStream(configId: number): Observable<PagePartsSet | null> {
        return this.pagePartConfigurationsInteractor.stream.pipe(
            map((configs) => configs?.get(configId)),
            distinctUntilChanged(),
            map((config) => {
                return config ? new DefaultPagePartsSet(config) : null;
            })
        );
    }

    public setActiveConfigId(id: number) {
        this.activePagePartConfigurationIdInteractor.setActiveConfigId(id);
    }

    public addPagePart(configId: number, newPagePart: PagePart) {
        const newConfigMap = new Map(this.pagePartConfigurationsInteractor.pagePartConfigurations);
        const config = newConfigMap.get(configId);
        if (!config) {
            throw new Error(`Config with id ${configId} not found.`);
        }

        newConfigMap.set(configId, {
            ...config,
            pageParts: [...config.pageParts, newPagePart],
        });
        this.pagePartConfigurationsInteractor.onChange(newConfigMap);
    }

    public removePagePart(configId: number, pagePartUuid: string) {
        const newConfigMap = new Map(this.pagePartConfigurationsInteractor.pagePartConfigurations);
        const config = newConfigMap.get(configId);
        if (!config) {
            throw new Error(`Config with id ${configId} not found.`);
        }

        newConfigMap.set(configId, {
            ...config,
            pageParts: this.pagePartsCleaner.cleanPageParts(config.pageParts.filter((pp) => pp.uuid !== pagePartUuid)),
        });

        this.pagePartConfigurationsInteractor.onChange(newConfigMap);
    }

    public updatePagePartsRanks(configId: number, ranks: [{uuid: string; rank: number}]) {
        const newConfigMap = new Map(this.pagePartConfigurationsInteractor.pagePartConfigurations);
        const config = newConfigMap.get(configId);
        if (!config) {
            throw new Error(`Config with id ${configId} not found.`);
        }

        const newSet = new DefaultPagePartsSet(config);

        const sortedRanks = ranks.sort((a, b) => a.rank - b.rank);
        for (const {uuid, rank} of sortedRanks) {
            const pagePart = newSet.getByUuid(uuid);
            if (pagePart) {
                const siblings = (
                    pagePart.parentUuid ? newSet.getChildrenForUuid(pagePart.parentUuid) : newSet.rootItems
                )
                    .filter((pp) => pp.uuid !== uuid)
                    .sort((a, b) => a.rank - b.rank);

                siblings.splice(rank, 0, pagePart);

                for (let index = 0; index < siblings.length; index++) {
                    const sibling = siblings[index];
                    sibling.rank = index;
                }
            }
        }

        newConfigMap.set(configId, {
            ...config,
            pageParts: newSet.pageParts,
        });
        this.pagePartConfigurationsInteractor.onChange(newConfigMap);
    }

    public updatePagePartsExplanation(configId: number, uuid: string, explanation: string) {
        const newConfigMap = new Map(this.pagePartConfigurationsInteractor.pagePartConfigurations);
        const config = newConfigMap.get(configId);
        if (!config) {
            throw new Error(`Config with id ${configId} not found.`);
        }

        newConfigMap.set(configId, {
            ...config,
            pageParts: config.pageParts.map((pp) => {
                if (pp.uuid === uuid) {
                    pp.explanation = explanation;
                }
                return pp;
            }),
        });
        this.pagePartConfigurationsInteractor.onChange(newConfigMap);
    }

    public async createNewSet(
        pagePartsSets: PagePartsSet[] | null,
        name: string,
        description: string,
        isShared: boolean,
        rank: number
    ): Promise<PagePartConfiguration> {
        const defaultSet = pagePartsSets?.find((set) => set.config.type === PagePartConfigurationType.DEFAULT);

        return this.duplicateConfig(defaultSet?.config ?? this.dummyConfig(name, description, rank, isShared), {
            name,
            description,
            rank,
        });
    }

    public dummyConfig(
        name?: string,
        description?: string,
        rank?: number,
        isShared?: boolean
    ): Omit<PagePartConfiguration, 'id'> {
        return {
            name: name ?? 'Configuratie',
            description: description ?? '',
            userName: null,
            isOwned: true,
            isShared: isShared ?? false,
            isVisible: true,
            isEditable: true,
            type: PagePartConfigurationType.CUSTOM,
            rank: rank ?? 0,
            pageParts: [],
            customizations: [],
        };
    }

    public async updateSet(pagePartsSet: PagePartsSet): Promise<void> {
        const newConfig = await this.pagePartConfigurationApi.storeConfiguration(pagePartsSet.config);
        this.pagePartConfigurationsInteractor.upsertConfiguration(newConfig);
    }

    public async updateSetsRanks(pagePartsSets: PagePartsSet[], ranks: [{id: number; rank: number}]) {
        const configListCopy = [...pagePartsSets.map((set) => ({...set.config}))];
        const sortedRanks = ranks.sort((a, b) => a.rank - b.rank);
        for (const {id, rank} of sortedRanks) {
            const config = configListCopy.find((c) => c.id === id);
            if (config) {
                const list = configListCopy.filter((set) => set.id !== id).sort((a, b) => a.rank - b.rank);
                list.splice(rank, 0, config);
                for (let index = 0; index < list.length; index++) {
                    const set = list[index];
                    set.rank = index;
                }
            }
        }
        this.pagePartConfigurationsInteractor.onChange(configListCopy);
        const newConfigs = await this.pagePartConfigurationApi.updateConfigurationRanks(configListCopy);
        this.pagePartConfigurationsInteractor.onChange(newConfigs);
    }

    public replaceConfig(config: PagePartConfiguration): PagePartConfiguration {
        return this.pagePartConfigurationsInteractor.upsertConfiguration(config);
    }
}
