import {observer} from 'mobx-react';
import React from 'react';
import {classNames} from '../../../../../../../../../support/classnames';
import {usePresenter} from '../../../../../../../../../support/presenter/use_presenter';
import {Appraisal} from '../../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {SetType} from '../../../../../../../../models/reference_set/set_type';
import {
    ReferenceSubscriptions,
    ReferenceSubscriptionType,
} from '../../../../../../../../models/reference_subscriptions';
import {formatMoney} from '../../../../../../support/format_money';
import {ActiveSetDefinition} from '../reference_objects_question_presenter';
import {AltumPreferencesModal} from './modals/altum_preferences_modal';
import {SubscriptionButtonsPresenter} from './subscription_buttons_presenter';

interface OwnProps {
    activeSetDefinition: ActiveSetDefinition;
    referenceSubscriptions: ReferenceSubscriptions | null;
    canAddSales: boolean;
    onAddCustomReferenceSaleButtonClick: () => void;
    forceRequest: (type: ReferenceSubscriptionType) => void;

    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export const SubscriptionButtons: React.FC<OwnProps> = observer(
    ({
        activeSetDefinition,
        referenceSubscriptions,
        canAddSales,
        onAddCustomReferenceSaleButtonClick,
        forceRequest,
        appraisal,
        questionSet,
    }) => {
        const presenter = usePresenter(() => new SubscriptionButtonsPresenter());

        return (
            <div className="reference-objects-nav-add-buttons">
                {activeSetDefinition.setDefinition.type === SetType.SOLD &&
                    referenceSubscriptions?.subscriptions
                        .filter((option) => option.available || option.usedForAppraisal)
                        .map((option, i) => {
                            const settings = presenter.getSubscriptionSettings(option.type);

                            return (
                                <button
                                    key={i}
                                    className={classNames({
                                        active: option.usedForAppraisal,
                                        'has-preferences-modal': settings.hasPreferencesModal,
                                    })}
                                    disabled={
                                        (option.usedForAppraisal || !canAddSales) && !settings.hasPreferencesModal
                                    }
                                    onClick={() =>
                                        option.usedForAppraisal
                                            ? settings.hasPreferencesModal
                                                ? presenter.openPreferencesModal(option.type)
                                                : null
                                            : forceRequest(option.type)
                                    }
                                >
                                    <span className="icon">
                                        {settings.logoClassName ? (
                                            <span className={settings.logoClassName}>&nbsp;</span>
                                        ) : (
                                            <label>{option.name.replace('referenties', '')}</label>
                                        )}
                                    </span>
                                    <span className="discount-original-price">
                                        {!option.usedForAppraisal &&
                                            option.originalPriceCents &&
                                            formatMoney(option.originalPriceCents / 100, {decimals: 2})}
                                    </span>
                                    <span className="button-title">
                                        {option.usedForAppraisal ? (
                                            settings.hasPreferencesModal ? (
                                                <span className="icon ion-md-settings"></span>
                                            ) : (
                                                <span className="ion-md-checkmark" />
                                            )
                                        ) : (
                                            formatMoney(option.priceCents / 100, {decimals: 2})
                                        )}
                                    </span>
                                </button>
                            );
                        })}
                <button disabled={!canAddSales} onClick={() => onAddCustomReferenceSaleButtonClick()}>
                    <span className="icon ion-md-add-circle"></span>
                    <span className="button-title">Eigen referentie</span>
                </button>
                {presenter.preferencesModalOpen === ReferenceSubscriptionType.ALTUM && (
                    <AltumPreferencesModal
                        onClose={() => presenter.openPreferencesModal(null)}
                        forceRequest={() => forceRequest(ReferenceSubscriptionType.ALTUM)}
                        appraisal={appraisal}
                        questionSet={questionSet}
                    />
                )}
            </div>
        );
    }
);
