import 'blueimp-canvas-to-blob';

import {bugsnagClient} from '../../support/bugsnag_client';
import {canvasToFile} from '../../support/create_file';
import loadImage from 'blueimp-load-image';
import {normalizeString} from '../../support/normalize_string';

export class ImageOrientationFixer {
    public fix(originalFile: File): Promise<File> {
        return new Promise<File>((resolve, reject) => {
            if (originalFile.type === undefined || !originalFile.type.includes('image')) {
                resolve(originalFile);
            } else {
                try {
                    loadImage(
                        originalFile,
                        async (image, data) => {
                            try {
                                const file = await canvasToFile(
                                    image,
                                    data,
                                    normalizeString(originalFile.name),
                                    originalFile.lastModified,
                                    1
                                );
                                if (file) {
                                    resolve(file);
                                } else {
                                    bugsnagClient?.notify(new Error('image_orientation_fixer: canvasToFile failed'), {
                                        metaData: {
                                            origin: 'image_orientation_fixer.ts fix()',
                                            img: image.toString(),
                                            typeofImg: typeof image,
                                        },
                                    });
                                    resolve(originalFile);
                                }
                            } catch (error) {
                                console.error('image_orientation_fixer', error);
                                bugsnagClient?.notify(error, {
                                    metaData: {
                                        origin: 'image_orientation_fixer.ts fix()',
                                        img: image.toString(),
                                        typeofImg: typeof image,
                                    },
                                });
                                resolve(originalFile);
                            }
                        },
                        {
                            maxWidth: 2500,
                            meta: true,
                            canvas: true,
                            orientation: true,
                            crossOrigin: 'Anonymous', //was `true` before but thats an invalid value, do we actually want crossOrigin here?
                        }
                    );
                } catch (error) {
                    console.error('image_orientation_fixer', error);
                    bugsnagClient?.notify(error, {
                        metaData: {
                            origin: 'image_orientation_fixer.ts fix() loadImage',
                        },
                    });
                    reject(originalFile);
                }
            }
        });
    }
}
