import {NetworkStatus, NetworkStatusProvider} from '../../../../business/network_status_provider';
import {makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {PhotoAnswerRetryInteractor, UnscynedState} from '../../../../business/photo_answer_retry_interactor';
import {Presenter} from '../../../../../support/presenter/presenter';

export class NotificationPillPresenter implements Presenter {
    private subscriptions = new CompositeSubscription();
    @observable.ref public networkStatus: NetworkStatus | null = null;
    @observable.ref public unsyncedState: UnscynedState | null = null;

    constructor(
        private photoAnswerRetryInteractor: PhotoAnswerRetryInteractor,
        private networkStatusProvider: NetworkStatusProvider
    ) {
        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            this.networkStatusProvider.status().subscribe((networkStatus) => {
                runInAction(() => {
                    this.networkStatus = networkStatus;
                });
            })
        );

        this.subscriptions.add(
            this.photoAnswerRetryInteractor.unscynedStateStream(1000).subscribe((unsyncedState) => {
                runInAction(() => {
                    this.unsyncedState = unsyncedState;
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
