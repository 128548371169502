import {Observable, Observer} from 'rxjs';
import {debounceTime, distinctUntilChanged, share, startWith} from 'rxjs/operators';

import {History} from 'history';
import {LocationProvider} from '../appraise/ui/components/sidebar/sidebar_item_presenter';

export class DefaultLocationProvider implements LocationProvider {
    constructor(private history: History) {}

    private historySubject = new Observable((observer: Observer<string>) => {
        this.history.listen(() => {
            observer.next(this.history.location.pathname);
        });
    }).pipe(startWith(this.history.location.pathname), distinctUntilChanged(), debounceTime(50), share());

    public stream(): Observable<string> {
        return this.historySubject;
    }
}
