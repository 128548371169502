import {GeoAddress} from '../../models/geo_address';

export interface ApiGeoAddress {
    postal_code: string;
    house_number: string;
    letter: string;
    street: string;
    city: string;
    formatted: string;
    lat: number;
    lng: number;
}

export function apiGeoAddressToGeoAddress(apiGeoAddress: ApiGeoAddress): GeoAddress {
    return {
        postalCode: apiGeoAddress.postal_code,
        houseNumber: apiGeoAddress.house_number,
        letter: apiGeoAddress.letter,
        street: apiGeoAddress.street,
        city: apiGeoAddress.city,
        formatted: apiGeoAddress.formatted,
        lat: apiGeoAddress.lat,
        lng: apiGeoAddress.lng,
    };
}

export function geoAddressToApiGeoAddress(geoAddress: GeoAddress): ApiGeoAddress {
    return {
        postal_code: geoAddress.postalCode,
        house_number: geoAddress.houseNumber,
        letter: geoAddress.letter,
        street: geoAddress.street,
        city: geoAddress.city,
        formatted: geoAddress.formatted,
        lat: geoAddress.lat,
        lng: geoAddress.lng,
    };
}
