import React from 'react';

export const QuestionTableContext = React.createContext<QuestionTableContextType>({
    portals: {},
    registerCell: () => null,
    destroyCell: () => null,

    collapsedKeys: [],
    collapse: () => null,
    expand: () => null,
});

export interface QuestionTableContextType {
    portals: {
        [columnKey: string]: {
            [questionUuid: string]: Element | null;
        };
    };

    registerCell: (columnKey: string, id: string) => void;
    destroyCell: (columnKey: string, id: string) => void;

    collapsedKeys: string[];
    collapse: (key: string) => void;
    expand: (key: string) => void;
}

export const QuestionTableChildContext = React.createContext<{
    columnKey?: string;
    parentKey?: string;
    collapsed?: boolean;
}>({});
