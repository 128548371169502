import {bugsnagClient} from '../support/bugsnag_client';

export default function register() {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', async () => {
            const swUrl = `/service-worker.js`;

            await unregisterInvalidSW(swUrl);
            await registerValidSW(swUrl);
        });
    }
}

async function unregisterInvalidSW(swUrl: string) {
    try {
        //Try to remove any invalid service-workers, e.g. if APP_URL is different
        const registrations = await navigator.serviceWorker.getRegistrations();
        const invalidRegistrations = registrations.filter((r) => {
            if (r.scope !== window.location.origin + '/') {
                return true;
            }
            if (r.active && r.active.scriptURL !== window.location.origin + swUrl) {
                return true;
            }
            return false;
        });

        for (const registration of invalidRegistrations) {
            bugsnagClient?.notify(
                new Error(
                    `Removing invalid service-worker "${registration.scope}", "${registration.active?.scriptURL}"`
                )
            );
            await registration.unregister();
        }
    } catch (error) {
        //Firefox error when people have cookies turned off, then serviceworkers arent allowed
        if (error instanceof Error && error.message === 'The operation is insecure.') {
            return;
        }

        console.error(error);
        bugsnagClient?.notify(error);
    }
}

export async function registerValidSW(swUrl: string) {
    try {
        const registration = await navigator.serviceWorker.register(swUrl);
        // if (registration.waiting) {
        //     showUpdateDialog();
        // }

        registerPwaOpeningHandler(registration).onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker) {
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            // showUpdateDialog();

                            console.log('New content is available; please refresh.');
                        } else {
                            console.log('Content is cached for offline use.');
                        }
                    }
                };
            }
        };
    } catch (error) {
        console.error('Error during service worker registration:', error);
    }
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
            registration.unregister();
        });
    }
}

// https://stackoverflow.com/questions/55581719/reactjs-pwa-not-updating-on-ios
// This function is called in the service worker registration promise, providing the ServiceWorkerRegistration instance
// Every time a user focuses the page (so going form inactive to focus e.g. opening the app) we force check a PWA update
function registerPwaOpeningHandler(registration: ServiceWorkerRegistration) {
    let hidden: keyof Document = 'hidden';
    let visibilityChange = 'visibilitychange';
    if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
    } else if (typeof (document as {msHidden?: unknown}).msHidden !== 'undefined') {
        hidden = 'msHidden' as keyof Document;
        visibilityChange = 'msvisibilitychange';
    } else if (typeof (document as {webkitHidden?: unknown}).webkitHidden !== 'undefined') {
        hidden = 'webkitHidden' as keyof Document;
        visibilityChange = 'webkitvisibilitychange';
    }

    window.document.addEventListener(visibilityChange, async () => {
        if (!document[hidden]) {
            try {
                if (registration.active !== null || registration.installing !== null || registration.waiting !== null) {
                    // manually force detection of a potential update when the pwa is opened
                    await registration.update();
                }
            } catch (error) {
                if (error instanceof Error && error.name === 'InvalidStateError') {
                    bugsnagClient?.notify(error, {
                        metaData: {
                            reason: 'Failure updating serviceWorker because of new app_url',
                        },
                    });
                } else {
                    bugsnagClient?.notify(error, {
                        metaData: {
                            reason: 'Failed updating service worker!',
                        },
                    });
                    throw error;
                }
            }
        }
    });

    return registration;
}
