import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {map, mergeMap} from 'rxjs/operators';
import {sortByCreatedAt, sortByRank} from '../../../../../../support/sort_answer';
import {UserSettings} from '../../../../../business/user_settings/user_settings_interactor';
import {NormalQuestionType} from '../../../../../enum/question_type';

import uuid from 'uuid';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {AnswerController} from '../../../../../business/answering/answer_controller';
import {AnswerInteractor} from '../../../../../business/answering/answer_interactor';
import {AttachmentUploadInteractor} from '../../../../../business/attachments/attachment_upload_interactor';
import {BlobCacheInteractor} from '../../../../../business/attachments/blob_cache_interactor';
import {AttachmentQuestionsInteractor, PhotoPair} from '../../../../../business/attachment_questions_interactor';
import {QuestionEffectInteractor} from '../../../../../business/conditions/question_effects_interactor';
import {UserInteractor} from '../../../../../business/user_interactor';
import {AppraisalState} from '../../../../../enum/appraisal_state';
import {AppraisalValidationType} from '../../../../../enum/appraisal_validation_type';
import {Answer} from '../../../../../models/answer';
import {Appraisal} from '../../../../../models/appraisal';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {AnswerTouchState} from '../../../../../enum/answer_touch_state';
import {EMPTY, combineLatest} from 'rxjs';
import {getNewestAnswer} from '../../../../../../support/get_newest_answer';

export class PhotoIteratorSmallPresenter implements Presenter {
    @computed
    public get iterations(): string[] | undefined {
        if (this.photoIterators === undefined) {
            return undefined;
        }

        return this.photoIterators
            .filter((answer) => !answer.isDeleted)
            .map((answer) => answer.iteration)
            .filter((iteration: string | null) => iteration !== null)
            .reduce((p: string[], c: string | null) => {
                return c !== null && p.indexOf(c) === -1 ? [...p, c] : p;
            }, []);
    }

    @computed
    public get viewerData() {
        if (this.photos === undefined) {
            return undefined;
        }

        return (
            this.photos
                ?.filter((answer) => answer?.file?.uncompressedUrl !== undefined)
                .map((answer) => ({
                    url: answer.file?.url as string,
                    uncompressedUrl: answer.file?.uncompressedUrl as string,
                })) ?? []
        );
    }

    @observable.ref private photoIterators?: Answer[];
    @observable.ref private photos?: Answer[];
    @observable private _isHidden = false;
    @observable.ref public settings: UserSettings | null = null;
    @observable public isAddPhotosOverlayVisible = false;
    @observable public isPhotoPickModalOpen = false;
    @observable.ref public pairs: PhotoPair[] = [];
    @observable public currentImageIteration?: string;

    @computed
    public get loading() {
        return this.iterations === undefined;
    }

    @computed
    public get isHidden(): boolean {
        return (
            this._isHidden ||
            (this.question.isAppraiserOnly &&
                !this.userInteractor.isAppraiser() &&
                !this.userInteractor.isEmployee() &&
                !this.userInteractor.isJuniorAppraiser())
        );
    }

    @computed
    public get isDisabled(): boolean {
        if (!this.appraisal.isEditableAppraisal) {
            return true;
        }
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return this.loading || (this.appraisal.status !== AppraisalState.PROCESSING && this.question.freezes);
        }
        return (
            this.loading ||
            (this.appraisal.status !== AppraisalState.PROCESSING && this.question.freezes) ||
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    private subscriptions = new CompositeSubscription();

    constructor(
        private fileTypes: string[],
        private question: Question,
        private appraisal: Appraisal,
        private parentAnswerUuid: string | undefined,
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectsInteractor: QuestionEffectInteractor,
        private userInteractor: UserInteractor,
        private attachmentUploadInteractor: AttachmentUploadInteractor,
        private blobCacheInteractor: BlobCacheInteractor,
        private answerInteractor: AnswerInteractor,
        private attachmentQuestionsInteractor: AttachmentQuestionsInteractor
    ) {
        this._isHidden = this.questionEffectsInteractor.isHidden(this.question.uuid, this.parentAnswerUuid ?? null);
        makeObservable(this);
    }

    public mount() {
        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidAndParentAnswerUuidStream(this.question.uuid, this.parentAnswerUuid ?? null)
                .pipe(map((answers: Answer[]) => answers.sort(sortByCreatedAt).sort(sortByRank)))
                .subscribe((answers) => {
                    runInAction(() => {
                        this.photoIterators = answers;
                    });
                })
        );

        const photoQuestion = this.questionSet.findChildByPredicateRecursive(
            this.question,
            (question) => question.type === NormalQuestionType.PHOTO_SMALL
        );

        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidAndParentAnswerUuidStream(this.question.uuid, this.parentAnswerUuid ?? null)
                .pipe(
                    map((answers: Answer[]) => answers.sort(sortByCreatedAt).sort(sortByRank)),
                    mergeMap((answers) => {
                        if (!photoQuestion) {
                            return EMPTY;
                        }

                        return combineLatest(
                            answers.map((a) =>
                                this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIterationOrNullStream(
                                    photoQuestion.uuid,
                                    a.uuid
                                )
                            )
                        );
                    }),
                    map((answers) =>
                        answers
                            .map((answers) => getNewestAnswer(answers ?? []))
                            .filter((data): data is Answer => data !== null)
                    )
                )
                .subscribe((answers) => {
                    runInAction(() => {
                        this.photos = answers;
                    });
                })
        );

        this.subscriptions.add(
            this.questionEffectsInteractor
                .isHiddenStream(this.question.uuid, this.parentAnswerUuid ?? null)
                .subscribe((isHidden) => {
                    runInAction(() => {
                        this._isHidden = isHidden;
                    });
                })
        );

        this.subscriptions.add(
            this.attachmentQuestionsInteractor.photoPairsStream().subscribe((photoAnswers) => {
                runInAction(() => {
                    this.pairs = photoAnswers.filter((p) => p.iteratorPhotoGroup.parentUuid !== this.parentAnswerUuid);
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public onDeleteClick(iteration: string) {
        const iterationAnswer = this.photoIterators?.find((a) => a.iteration === iteration && !a.isDeleted);

        if (iterationAnswer) {
            this.answerController.delete(iterationAnswer.uuid);
        }
    }

    public async onFilesChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files === null || e.target.files.length === 0) {
            return;
        }

        await this.onUploadFiles(e.target.files);
    }

    public onImagePicked = async (answer: Answer) => {
        const answerParent = answer.parentUuid != null ? this.answerController.byUuid(answer.parentUuid) : null;

        const photoQuestion = this.questionSet
            .findChildQuestionsByParentUuid(this.question.uuid)
            .find((question) => question.type === NormalQuestionType.PHOTO_SMALL);

        if (answer.contents && answerParent && photoQuestion !== undefined && this.parentAnswerUuid) {
            //Create a new iteration in said group where this photo will be placed under
            const iterableGroupAnswer = this.answerController.answerByIdentifiersOrStub(
                this.question.uuid,
                this.parentAnswerUuid,
                uuid.v4()
            );

            //Create the answer itself that represents the photo (inside the iteration)
            const photoAnswer = this.answerController.answerByIdentifiersOrStub(
                photoQuestion.uuid,
                iterableGroupAnswer.uuid,
                null
            );

            this.answerController.onContentsChange(photoAnswer.uuid, answer.contents, AnswerTouchState.TOUCHED, null);

            await this.answerInteractor.submit();
        }

        this.togglePhotoPickModal();
    };

    public async onUploadFiles(files: FileList) {
        const photoQuestion = this.questionSet
            .findChildQuestionsByParentUuid(this.question.uuid)
            .find((question) => question.type === NormalQuestionType.PHOTO_SMALL);
        if (photoQuestion === undefined || !this.parentAnswerUuid) {
            return;
        }

        for (const file of Array.from(files)) {
            //Create a new iteration in said group where this photo will be placed under
            const iterableGroupAnswer = this.answerController.answerByIdentifiersOrStub(
                this.question.uuid,
                this.parentAnswerUuid,
                uuid.v4()
            );

            //Create the answer itself that represents the photo (inside the iteration)
            const photoAnswer = this.answerController.answerByIdentifiersOrStub(
                photoQuestion.uuid,
                iterableGroupAnswer.uuid,
                null
            );

            const result = await this.attachmentUploadInteractor.uploadForAnswer(photoAnswer.uuid, file, {
                fileTypes: this.fileTypes,
            });

            if (!result.succeeded) {
                await this.blobCacheInteractor.remove(photoAnswer.uuid);
                this.answerController.clearFileAndContents(photoAnswer.uuid);

                this.throwAlert(file);
                return;
            }
        }

        if (this.isAddPhotosOverlayVisible) {
            this.toggleAddPhotos();
        }
    }

    @action
    public setCurrentImageIteration(iteration: string) {
        this.currentImageIteration = iteration;
    }

    protected throwAlert(file: File) {
        if (file !== null) {
            if (file.size > 15000000) {
                this.alertIncorrectSize();
            } else {
                this.alertIncorrectType();
            }
        }
    }

    private alertIncorrectSize() {
        alert('Fout bij het uploaden. De maximaal toegestane grote voor een bestand is 15 Mb.');
    }

    private alertIncorrectType() {
        alert(
            'Fout bij het uploaden. Is het een correct formaat? Voor afbeeldingen kun je .jpg en .png uploaden en voor bijlagen .pdf.'
        );
    }

    @action
    public toggleAddPhotos = () => {
        this.isAddPhotosOverlayVisible = !this.isAddPhotosOverlayVisible;
    };

    @action
    public togglePhotoPickModal = () => {
        this.isPhotoPickModalOpen = !this.isPhotoPickModalOpen;
    };
}
