export enum StringCompareSelectorType {
    ENERGY_LABEL = 'energy-label',
    OBJECT_TYPE = 'object-type',
}

export enum IntCompareSelectorType {
    BUILDYEAR = 'buildyear',
    SURFACE_AREA = 'surface-area', //gebruiksoppervlakte
    VOLUME = 'volume', //brutoinhoud
    PLOT_AREA = 'plot-area', //perceeloppervlakte
    SPECIAL_BUILDYEAR = 'special-buildyear',
    SPECIAL_SURFACE_AREA = 'special-surface-area',
    SPECIAL_VOLUME = 'special-volume',
    SPECIAL_PLOT_AREA = 'special-plot-area',
}

export enum SpecialCompareSelectorType {
    COMPARABILITY_OPTIONS = 'comparability-options',
}

export type CompareSelectorType = StringCompareSelectorType | IntCompareSelectorType | SpecialCompareSelectorType;
