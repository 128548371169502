import * as React from 'react';

import {
    simpleQuestionPresenterConstructorParametersFactory,
    SimpleQuestionPresenterProps,
} from '../../simple/simple_question_presenter';

import {observer} from 'mobx-react';
import {classNames} from '../../../../../../../support/classnames';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {CompactEditable} from '../../../../components/compact_editable';
import {filterValidationMessages} from '../../../../support/filter_validation_messages';
import {AlternativeAdaptedDefaultAnswer} from '../../components/alternative_adapted_default_answer';
import {Label} from '../../components/label';
import {ValidationMessages} from '../../components/validation_messages';
import {IntCompareQuestionBadge} from './int_compare_question_badge';
import {IntCompareQuestionInput} from './int_compare_question_input';
import {IntCompareQuestionPresenter} from './int_compare_question_presenter';

type OwnProps = SimpleQuestionPresenterProps;

export const IntCompareQuestion = observer((props: OwnProps) => {
    const presenter = usePresenter(
        (component) =>
            new IntCompareQuestionPresenter(
                component.business.compareValuesProvider(props.appraisal, props.questionSet),
                ...simpleQuestionPresenterConstructorParametersFactory(props, component)
            )
    );

    if (presenter.isHidden) {
        return null;
    }

    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'automation-filled': presenter.filledByAutomator !== null,
            })}
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
        >
            <CompactEditable
                renderingContext={props.renderingContext}
                compact={() => (
                    <>
                        <Label
                            for={presenter.name}
                            question={props.question}
                            parentAnswerUuid={props.parentAnswerUuid}
                            iteration={props.iteration}
                            appraisal={props.appraisal}
                            questionSet={props.questionSet}
                            disabled={props.disabled || presenter.isDisabled}
                            disableHistory
                            hideLabel={props.hideLabel}
                            showLabel={props.question.showLabel}
                            renderingContext={props.renderingContext}
                        />

                        <span className="row">
                            <span className="col-8">
                                {presenter.answer && presenter.answer.contents !== null ? (
                                    presenter.answer.contents
                                ) : (
                                    <i>Niet ingevuld</i>
                                )}
                            </span>
                            <span className="col-4">
                                <span className="form-badge">
                                    <IntCompareQuestionBadge compact presenter={presenter} question={props.question} />
                                </span>
                            </span>
                        </span>
                    </>
                )}
                expanded={() => (
                    <>
                        <Label
                            for={presenter.name}
                            question={props.question}
                            parentAnswerUuid={props.parentAnswerUuid}
                            iteration={props.iteration}
                            appraisal={props.appraisal}
                            questionSet={props.questionSet}
                            disabled={props.disabled || presenter.isDisabled}
                            hideLabel={props.hideLabel}
                            showLabel={props.question.showLabel}
                            renderingContext={props.renderingContext}
                        />

                        <AlternativeAdaptedDefaultAnswer
                            adaptedDefaultValue={props.adaptedDefaultValue}
                            currentAnswer={presenter.answer}
                            disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                            onChange={(value) => presenter.onChange(value)}
                        />
                        <span className="row">
                            <span className="col-12">
                                <IntCompareQuestionInput
                                    presenter={presenter}
                                    question={props.question}
                                    disabled={props.disabled}
                                />
                            </span>
                        </span>
                    </>
                )}
                question={props.question}
                answer={presenter.answer}
            >
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />

                <AlternativeAdaptedDefaultAnswer
                    adaptedDefaultValue={props.adaptedDefaultValue}
                    currentAnswer={presenter.answer}
                    disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                    onChange={(value) => presenter.onChange(value)}
                />
                <span className="row">
                    <span className="col-8">
                        <IntCompareQuestionInput
                            presenter={presenter}
                            question={props.question}
                            disabled={props.disabled}
                        />
                    </span>
                    <span className="col-4">
                        <span className="form-badge">
                            {' '}
                            <IntCompareQuestionBadge presenter={presenter} question={props.question} />
                        </span>
                    </span>
                </span>
            </CompactEditable>

            <ValidationMessages
                disabled={props.disabled || presenter.isDisabled}
                alignLeft
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={presenter.answer}
                validationMessages={filterValidationMessages(
                    props.question,
                    presenter.answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
        </div>
    );
});
