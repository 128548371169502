import * as React from 'react';
import {createRoot} from 'react-dom/client';
import Root from './root';
import {WindowLocalStorage} from '../../persistence/drivers/window_local_storage';
import {DefaultGeolocationPermission} from '../../support/geolocation/geolocation_permission';

const localStorage = new WindowLocalStorage();
const geolocationPermission = new DefaultGeolocationPermission(localStorage);

export function renderAccountGeolocationPermission(element: HTMLElement) {
    createRoot(element).render(<Root geolocationPermission={geolocationPermission} />);
}
