import {makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../../../support/composite_subscription';
import {SimpleQuestionPresenter} from '../../simple/simple_question_presenter';
import {CompareValuesProvider} from '../../../../../../business/compare_values/compare_values_provider';

export class IntCompareQuestionPresenter extends SimpleQuestionPresenter {
    @observable
    public compareValue: number | null = null;

    private subscriptions = new CompositeSubscription();

    constructor(
        private compareValuesProvider: CompareValuesProvider,
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public mount() {
        super.mount();

        this.subscriptions.add(
            this.compareValuesProvider
                .compareValueByAnswerIdentifiersStream(
                    this.question.uuid,
                    this.parentAnswerUuid ?? null,
                    this.iteration ?? null
                )
                .subscribe((value) => {
                    if (typeof value === 'string') {
                        return; // IntCompare should never expect a string compare value
                    }

                    runInAction(() => {
                        this.compareValue = value;
                    });
                })
        );
    }

    public unmount() {
        super.unmount();

        this.subscriptions.clear();
    }
}
