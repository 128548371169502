import * as React from 'react';

import {Appraisal} from '../../../../../models/appraisal';
import {ConceptReport} from '../../questions/advanced/concept_report';
import {Global} from '../../../../../../business/global_provider';
import {History} from 'history';
import {NetworkStatus} from '../../../../../business/network_status_provider';
import {PagePart} from '../../../../../models/page_part';
import {PagePartsSet} from '../../../../../models/page_parts_set';
import {QuestionSet} from '../../../../../models/question_set';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';
import {ValidationError} from '../../../../../models/validation_error';
import {ValidationErrorsConceptReportPresenter} from './validation_errors_concept_report_presenter';
import {ValidationMessageMap} from '../../../../../business/validation/validation_message';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {QuestionRenderingData} from '../../../../../models/question_rendering_data';

interface OwnProps {
    history: History;
    global: Global;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    validationMessages: ValidationMessageMap;
    validationErrors: ValidationError[];
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const ValidationErrorsConceptReport: React.FC<OwnProps> = observer(function ValidationErrorsConceptReport(
    props
) {
    const presenter = usePresenter(
        (container) =>
            new ValidationErrorsConceptReportPresenter(
                props.questionSet,
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.validationConclusionProvider(
                    props.appraisal,
                    props.questionSet,
                    props.pagePartsSet,
                    props.validationErrors,
                    props.renderingContext
                ),
                container.business.networkStatusProvider
            )
    );

    if (presenter.networkStatus === NetworkStatus.OFFLINE) {
        return (
            <div className="row">
                <div className="col-12">
                    <h2>Concept rapport</h2>
                    <p>Het is niet mogelijk om het concept rapport te bekijken omdat er geen internetverbinding is.</p>
                </div>
            </div>
        );
    }

    if (presenter.conceptReportQuestion !== null && !presenter.hasError) {
        return (
            <ConceptReport
                appraisal={props.appraisal}
                question={presenter.conceptReportQuestion}
                questionSet={props.questionSet}
                validationErrors={props.validationErrors}
                iteration={undefined}
                parentAnswerUuid={undefined}
                hiddenQuestionTypes={[]}
                renderingContext={props.renderingContext}
                validationMessages={props.validationMessages}
                pagePartsSet={props.pagePartsSet}
                activePagePart={props.activePagePart}
                forceShowValidationMessages={false}
                questionRenderingData={props.questionRenderingData}
            />
        );
    }

    if (presenter.conceptReportQuestion === null) {
        return (
            <div className="row">
                <div className="col-12">
                    <h2>Concept rapport</h2>
                    <p>Het is niet mogelijk om het concept rapport te bekijken.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-12">
                <h2>Concept rapport</h2>
                <p>Het is niet mogelijk om het concept rapport te bekijken omdat er nog controlefouten zijn.</p>
            </div>
        </div>
    );
});
