import {action, makeObservable, observable} from 'mobx';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {Presenter} from '../../../support/presenter/presenter';

export enum Tabs {
    LIST = 'list',
    CURRENT = 'current',
}

export class ConfigTabsContainerPresenter implements Presenter {
    @observable public activeTab = Tabs.LIST;
    private subscriptions = new CompositeSubscription();

    constructor() {
        makeObservable(this);
    }

    public async mount() {
        //this.subscriptions.add();
    }

    public async unmount() {
        this.subscriptions.clear();
    }

    @action
    public setActiveTab(tab: Tabs) {
        this.activeTab = tab;
    }
}
