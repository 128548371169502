import {
    AdaptedDefaultValuesInteractor,
    DefaultAdaptedDefaultValuesInteractor,
} from './adapted_default_values_interactor';
import {AdaptedValueProvider, DefaultAdaptedValueProvider} from './adapted_values/adapted_value_provider';
import {AnswerByLabelSubmitter, DefaultAnswerByLabelSubmitter} from './answering/answer_by_label_submitter';
import {AnswerInteractor, DefaultAnswerInteractor} from './answering/answer_interactor';
import {AnswerValidatorAdapter, DefaultAnswerValidatorAdapter} from './validation/answer_validator_adapter';
import {AppraisalProvider, DefaultAppraisalProvider} from './appraisal_provider';
import {
    AppraiseSecondaryConfigStackInteractor,
    DefaultAppraiseSecondaryConfigStackInteractor,
} from './appraise_secondary_config_stack_interactor';
import {AttachmentQuestionsInteractor, DefaultAttachmentQuestionsInteractor} from './attachment_questions_interactor';
import {BbmiAreaProvider, DefaultBbmiAreaProvider} from './bbmi_areas/bbmi_area_provider';
import {BlobCacheInteractor, DefaultBlobCacheInteractor} from './attachments/blob_cache_interactor';
import {
    BuildingInspectionAppointmentInteractor,
    DefaultBuildingInspectionAppointmentInteractor,
} from './building_inspection_appointment_interactor';
import {
    ChildQuestionValidationProvider,
    DefaultChildQuestionValidationProvider,
} from './validation/child_validation_provider';
import {ChildQuestionValidator, DefaultChildValidator} from './validation/child_validator';
import {
    ConstructionCostController,
    DefaultConstructionCostController,
} from '../../business/construction_costs_controller';
import {ConstructionCostsProvider, DefaultConstructionCostsProvider} from './construction_costs_provider';
import {
    ConstructionalDefectsGroupsProvider,
    DefaultConstructionalDefectsGroupsProvider,
} from './constructional_defects/constructional_defects_groups_provider';
import {DefaultDuplicationSourcesProvider, DuplicationSourcesProvider} from './duplication_sources_provider';
import {DefaultEnergyLabelProvider, EnergyLabelProvider} from './energy_label_provider';
import {DefaultFlashMessageBroadcaster, FlashMessageBroadcaster} from './flash_message_broadcaster';
import {DefaultGenericErrorsValidator, GenericErrorsValidator} from './validation/generic_errors_validator';
import {DefaultIsRequiredCalculator, IsRequiredCalculator} from './validation/internal/is_required_calculator';
import {
    DefaultIsVisitedRequiredCalculator,
    IsVisitedRequiredCalculator,
} from './validation/internal/is_visited_required_calculator';
import {
    DefaultIteratorFilesAttachmentInteractor,
    IteratorFilesAttachmentInteractor,
} from '../appraise/ui/content/questions/advanced/iterator_files/iterator_files_attachment_interactor';
import {DefaultMacroEffectInteractor, MacroEffectInteractor} from './conditions/macro_effects_interactor';
import {DefaultMacroInteractor, MacroInteractor} from './macro_interactor';
import {DefaultModalConfigStackInteractor, ModalConfigStackInteractor} from './modal_config_stack_interactor';
import {DefaultNetworkStatusProvider, NetworkStatusProvider} from './network_status_provider';
import {DefaultPhotoAnswerRetryInteractor, PhotoAnswerRetryInteractor} from './photo_answer_retry_interactor';
import {DefaultPhotoRecognitionHandler, PhotoRecognitionHandler} from './answering/photo_recognition_handler';
import {DefaultPlotAreaProvider, PlotAreaProvider} from './plot_area_provider';
import {DefaultQuestionEffectInteractor, QuestionEffectInteractor} from './conditions/question_effects_interactor';
import {DefaultQuestionValidationProvider, QuestionValidationProvider} from './validation/question_validation_provider';
import {DefaultQuestionValidator, QuestionValidator} from './validation/question_validator';
import {
    DefaultReferenceObjectCurrentlyInRentInteractor,
    ReferenceObjectCurrentlyInRentInteractor,
} from './reference_object_rent_interactor';
import {
    DefaultReferenceObjectCurrentlyInSaleInteractor,
    ReferenceObjectCurrentlyInSaleInteractor,
} from './reference_object_sale_interactor';
import {DefaultReferenceObjectProvider, ReferenceObjectProvider} from './reference_object_provider';
import {
    DefaultReferenceObjectsInteractor,
    ReferenceObjectsInteractor,
} from '../appraise/ui/content/questions/advanced/reference_objects_question/interactors/reference_objects_interactor';
import {
    DefaultReferenceObjectsV1Provider,
    ReferenceObjectsV1Provider,
} from '../appraise/ui/content/questions/advanced/reference_objects_question/v1/providers/reference_objects_provider';
import {DefaultSearchInteractor, SearchInteractor} from './search_interactor';
import {DefaultSupportComponent, SupportComponent} from './support/support_component';
import {DefaultSurfaceAreaProvider, SurfaceAreaProvider} from './support/surface_area_provider';
import {DefaultUnsyncedAnswerRetryInteractor, UnsyncedAnswerRetryInteractor} from './unsynced_answer_retry_interactor';
import {DefaultUserInteractor, UserInteractor} from './user_interactor';
import {DefaultUserSettingsInteractor, UserSettingsInteractor} from './user_settings/user_settings_interactor';
import {
    DefaultV2ReferenceSetsProvider,
    V2ReferenceSetsProvider,
} from '../appraise/ui/content/questions/advanced/reference_objects_question/v2/internal/reference_sets/reference_sets_provider';
import {
    DefaultV2SetDefinitionsProvider,
    V2SetDefinitionsProvider,
} from '../appraise/ui/content/questions/advanced/reference_objects_question/v2/internal/reference_sets/set_definitions_provider';
import {
    DefaultV3ReferenceSetsProvider,
    V3ReferenceSetsProvider,
} from '../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/reference_sets/reference_sets_provider';
import {
    DefaultV3SetDefinitionsProvider,
    V3SetDefinitionsProvider,
} from '../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/reference_sets/set_definitions_provider';
import {
    DefaultValidationConclusionProvider,
    ValidationConclusionProvider,
} from './validation/validation_conclusion_provider';
import {DefaultValuationDateProvider, ValuationDateProvider} from './valuation_date_provider';
import {
    DefaultVolatileValidationMessagesFromTreeCollector,
    VolatileValidationMessagesFromTreeCollector,
} from './validation/volatile_validation_messages_from_tree_collector';
import {DefaultVolumeProvider, VolumeProvider} from './volume_provider';

import {AnswerController} from './answering/answer_controller';
import {AnswerPathStubber} from './answering/answer_path_stubber';
import {AnswerRegistry} from './answering/support/answer_registry';
import {AnswerSelector} from './answering/support/answer_selector';
import {AnswerStorageQueue} from './answering/support/answer_storage_queue';
import {Appraisal} from '../models/appraisal';
import {AppraisalApi} from '../network/appraisal_api';
import {AppraisalFieldResolver} from './conditions/appraisal_field_resolver';
import {AttachmentUploadInteractor} from './attachments/attachment_upload_interactor';
import {AttachmentValidator} from './validation/question_validators/attachment_validator';
import {AutoMLVersion} from './ml/vision/automl_photo_content_predicter';
import {AutomlPhotoHandler} from './ml/vision/automl_photo_handler';
import {BuildYearProvider} from './build_year_provider';
import {ConceptReportProvider} from './concept_report_provider';
import {ConditionsInteractor} from './conditions/conditions_interactor';
import {DefaultAdaptedValueBuilder} from './adapted_values/builders/adapted_value_builder';
import {DefaultAdaptedValueConfigProvider} from './adapted_values/internal/adapted_value_config';
import {DefaultAnswerContentBuilder} from './adapted_values/internal/answer_content_builder';
import {DefaultAnswerController} from './answering/default_answer_controller';
import {DefaultAnswerRegistry} from './answering/support/default_answer_registry';
import {DefaultConditionsInteractor} from './conditions/default_conditions_interactor';
import {DefaultConstructionCostsApi} from '../../network/construction_costs_api';
import {DefaultGebruiksOppervlakteBuitenruimteProvider} from './bbmi_areas/gebruiks_oppervlakte_buitenruimte_provider';
import {DefaultGebruiksOppervlakteExterneBergruimteProvider} from './bbmi_areas/gebruiks_oppervlakte_externe_bergruimte_provider';
import {DefaultLocationProvider} from './location_provider';
import {DefaultOverigeInpandigeRuimteProvider} from './bbmi_areas/overige_inpandige_ruimte';
import {DefaultPhotoContentPredicter} from './ml/vision/photo_content_predicter';
import {DefaultSentenceBuilder} from './adapted_values/internal/sentence_builder';
import {DeletedAnswersFilter} from './answering/support/deleted_answers_filter';
import {EffectsCollectorFromAnswers} from './conditions/effects_collector_from_answers';
import {FloorInteractor} from './floor_interactor';
import {FloorIterationProvider} from './floor_iteration_provider';
import {FloorSurfaceAreaValidator} from './validation/answer_validators/floor_surface_area_validator';
import {FloorValidator} from './validation/answer_validators/floor_validator';
import {GeneralCommentsValidator} from './validation/answer_validators/general_comments_validator';
import {GlobalProvider} from '../../business/global_provider';
import {HasQuestionAnswerFilter} from './answering/support/has_question_answer_filter';
import {History} from 'history';
import {ImageCompressor} from './image_compressor';
import {ImageOrientationFixer} from './image_orientation_fixer';
import {ImageUploadInteractor} from './attachments/image_upload_interactor';
import {ImprovementsWorkQuestionValidator} from './validation/question_validators/improvements_work_validator';
import {IsVisitedValidator} from './validation/answer_validators/is_visited_validator';
import {IteratorReferenceObjectV3Validator} from './validation/answer_validators/iterator_reference_object_v3_validator';
import {IteratorValidator} from './validation/answer_validators/iterator_validator';
import {LocalStorageAnswerRegistry} from './answering/support/local_storage_answer_registry';
import {LocationProvider} from '../appraise/ui/components/sidebar/sidebar_item_presenter';
import {ModalOrSecondaryConfigStackInteractor} from './modal_or_secondary_config_stack_interactor';
import {MultipleBooleanGroupValidator} from './validation/answer_validators/multiple_boolean_group_validator';
import {NetworkBoundaryPhotoContentPredicter} from './ml/vision/network_boundary_photo_content_predicter';
import {NetworkComponent} from '../network/network_component';
import {ObjectAppraiserExplanationAdaptedValueBuilder} from './adapted_values/builders/object_appraiser_explanation_adapted_value_builder';
import {ObjectBuildingsAppraiserExplanationAdaptedValueBuilder} from './adapted_values/builders/object_buildings_appraiser_explanation_adapted_value_builder';
import {ObjectTypeValidator} from './validation/question_validators/object_type_validator';
import {PagePartsSet} from '../models/page_parts_set';
import {PersistenceComponent} from '../../persistence/persistence_component';
import {PhotoIteratorValidator} from './validation/question_validators/photo_iterator_validator';
import {QuestionSet} from '../models/question_set';
import {ReferenceObjectV3Validator} from './validation/answer_validators/reference_object_v3_validator';
import {ReferenceObjectValidator} from './validation/answer_validators/reference_object_validator';
import {RenderingContextType} from '../enum/rendering_context_type';
import {RequiredQuestionValidator} from './validation/answer_validators/required_question_validator';
import {RetryUploadInteractor} from './attachments/retry_upload_interactor';
import {S3UploadInteractor} from './attachments/s3_upload_interactor';
import {ServerTimeProvider} from '../server_time/server_time_provider';
import {SidebarItemsProvider} from './sidebar_items_provider';
import {SidebarTreeBuilder} from './sidebar_tree_builder';
import {TaskHelper} from './task_helper';
import {UploadInteractor} from './attachments/upload_interactor';
import {V2UniqueSpecialValueValidator} from './validation/answer_validators/v2_unique_special_value';
import {V3UniqueSpecialValueValidator} from './validation/answer_validators/v3_unique_special_value';
import {VVEValidator} from './validation/answer_validators/vve_validator';
import {ValidationError} from '../models/validation_error';
import {ValidationInstituteValidation} from './validation/answer_validators/validation_institute_validation';
import {ValuationDateValidator} from './validation/generic_validators/valuation_date_validator';
import {lazy} from '../../support/lazy';
import {KoopovereenkomstValidator} from './validation/answer_validators/koopovereenkomst_validator';
import {
    DefaultPagePartConfigurationsInteractor,
    PagePartConfigurationsInteractor,
} from './page_parts/page_part_configurations_interactor';
import {UserSettingsActivePagePartConfigurationInteractor} from './user_settings_active_page_part_configuration_interactor';
import {CompareValuesProvider, DefaultCompareValuesProvider} from './compare_values/compare_values_provider';
import {AnswerCompareValidator} from './validation/answer_validators/answer_compare_validator';
import {PagePartValidationProvider} from './validation/page_part_validation_provider';
import {QuestionValidationMessagesProvider} from './validation/question_validation_messages_provider';
import {DefaultDistanceProvider, DistanceProvider} from './distance_provider';
import {
    DefaultReferenceObjectsMetadataProvider,
    ReferenceObjectsMetadataProvider,
} from './reference_objects_metadata/reference_objects_metadata_provider';
import {v3ReferenceObjectsMetadataManager} from './reference_objects_metadata/v3_reference_objects_metadata_manager';
import {SuperMacroInteractor} from './super_macro_interactor';
import {SuperMacroFilter} from './super_macro_filter';
import {DefaultPagePartsSetInteractor, PagePartsSetInteractor} from './page_parts/page_parts_set_interactor';
import {PagePartsCleaner} from './page_parts/page_parts_cleaner';
import {DefaultTextAIAnswerContextBuilder} from './textai/textai_answer_context_builder';
import {TextAIContextBuilder} from './textai/textai_context_builder';
import {DefaultTextAIInteractor, TextAIInteractor} from './textai/textai_interactor';
import {DefaultTextAIAppraisalContextBuilder} from './textai/textai_appraisal_context_builder';
import {DefaultTextAIReferenceObjectContextBuilder} from './textai/textai_reference_object_context_builder';
import {DefaultExplanationDataProvider} from '../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/explanation_appraiser_generator/explanation_data_provider';
import {ValuationProvider} from './valuation_provider';
import {AutomatorInteractor, DefaultAutomatorInteractor} from './automator_interactor';
import {DefaultTextAITransitievisieWarmteContextBuilder} from './textai/textai_transitievisiewarmte_context_builder';
import {Answer} from '../models/answer';
import {createObservableAnswerMemoryStorage} from '../../support/memory_storage/default_observable_answer_memory_storage_factory';
import {ObservableMemoryStorage} from 'indexed-memory-storage';
import {AnswerLoader, DefaultAnswerLoader} from './answering/support/answer_loader';
import {
    DefaultIsSoftRequiredCalculator,
    IsSoftRequiredCalculator,
} from './validation/internal/is_soft_required_calculator';
import {SoftRequiredValidator} from './validation/answer_validators/soft_required_validator';
import {
    DefaultReferenceObjectsAnswerEnhancementInteractor,
    ReferenceObjectsAnswerEnhancementInteractor,
} from '../appraise/ui/content/questions/advanced/reference_objects_question/interactors/reference_objects_answer_enhancement_interactor';
import {HouseFilter} from './answering/support/house_filter';
import {GrondgebondenValidator} from './validation/answer_validators/grondgebonden_validator';
import {GrondgebondenAkteValidator} from './validation/answer_validators/grondgebonden_akte_validator';
export interface BusinessComponent {
    locationProvider(history: History): LocationProvider;

    sidebarTreeBuilder(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        pagePartsSet: PagePartsSet | null
    ): SidebarTreeBuilder;

    answerController(appraisal: Appraisal, questionSet: QuestionSet): AnswerController;

    observableMemoryStorage(appraisal: Appraisal): ObservableMemoryStorage<Answer>;

    answerInteractor(appraisal: Appraisal, questionSet: QuestionSet): AnswerInteractor;

    conditionsInteractor(appraisal: Appraisal, questionSet: QuestionSet): ConditionsInteractor;

    questionEffectsInteractor(appraisal: Appraisal, questionSet: QuestionSet): QuestionEffectInteractor;

    macroEffectsInteractor(appraisal: Appraisal, questionSet: QuestionSet): MacroEffectInteractor;

    superMacroInteractor(appraisal: Appraisal, questionSet: QuestionSet): SuperMacroInteractor;

    superMacroFilter(appraisal: Appraisal, questionSet: QuestionSet): SuperMacroFilter;

    v2ReferenceSetsProvider(appraisal: Appraisal, questionSet: QuestionSet): V2ReferenceSetsProvider;

    v3ReferenceSetsProvider(appraisal: Appraisal, questionSet: QuestionSet): V3ReferenceSetsProvider;

    unsyncedAnswerRetryInteractor(appraisal: Appraisal, questionSet: QuestionSet): UnsyncedAnswerRetryInteractor;

    photoAnswerRetryInteractor(appraisal: Appraisal, questionSet: QuestionSet): PhotoAnswerRetryInteractor;

    blobCacheInteractor(): BlobCacheInteractor;

    imageUploadInteractor(appraisal: Appraisal, questionSet: QuestionSet): ImageUploadInteractor;

    attachmentUploadInteractor(appraisal: Appraisal, questionSet: QuestionSet): AttachmentUploadInteractor;

    referenceObjectProvider(appraisal: Appraisal, questionSet: QuestionSet): ReferenceObjectProvider;

    referenceObjectsInteractor(appraisal: Appraisal, questionSet: QuestionSet): ReferenceObjectsInteractor;

    referenceObjectsAnswerEnhancementInteractor(
        appraisal: Appraisal,
        questionSet: QuestionSet
    ): ReferenceObjectsAnswerEnhancementInteractor;

    referenceObjectCurrentlyInSaleInteractor(appraisal: Appraisal): ReferenceObjectCurrentlyInSaleInteractor;

    referenceObjectCurrentlyInRentInteractor(appraisal: Appraisal): ReferenceObjectCurrentlyInRentInteractor;

    referenceObjectsProvider(appraisal: Appraisal, questionSet: QuestionSet): ReferenceObjectsV1Provider;

    referenceObjectsCurrentlyInSaleProvider(appraisal: Appraisal, questionSet: QuestionSet): ReferenceObjectsV1Provider;

    referenceObjectsCurrentlyInRentProvider(appraisal: Appraisal, questionSet: QuestionSet): ReferenceObjectsV1Provider;

    referenceObjectsMetadataProvider(appraisal: Appraisal, questionSet: QuestionSet): ReferenceObjectsMetadataProvider;

    adaptedDefaultValuesInteractor(appraisal: Appraisal): AdaptedDefaultValuesInteractor;

    adaptedValueProvider(appraisal: Appraisal, questionSet: QuestionSet): AdaptedValueProvider;

    bbmiAreaProvider(appraisal: Appraisal, questionSet: QuestionSet): BbmiAreaProvider;

    volumeProvider(appraisal: Appraisal, questionSet: QuestionSet): VolumeProvider;

    valuationDateProvider(appraisal: Appraisal, questionSet: QuestionSet): ValuationDateProvider;

    volatileValidationMessagesFromTreeCollector(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        pagePartsSet: PagePartsSet | null,
        validationErrors: ValidationError[],
        renderingContextType: RenderingContextType
    ): VolatileValidationMessagesFromTreeCollector;

    validationConclusionProvider(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        pagePartsSet: PagePartsSet | null,
        validationErrors: ValidationError[],
        renderingContextType: RenderingContextType
    ): ValidationConclusionProvider;

    questionValidationMessagesProvider(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        validationErrors: ValidationError[]
    ): QuestionValidationMessagesProvider;

    pagePartValidationProvider(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        renderingContextType: RenderingContextType,
        validationErrors: ValidationError[]
    ): PagePartValidationProvider;

    macroInteractor(questionSet: QuestionSet): MacroInteractor;

    searchInteractor(questionSet: QuestionSet): SearchInteractor;

    isRequiredCalculator(appraisal: Appraisal, questionSet: QuestionSet): IsRequiredCalculator;

    isVisitedRequiredCalculator(appraisal: Appraisal, questionSet: QuestionSet): IsVisitedRequiredCalculator;

    isSoftRequiredCalculator(appraisal: Appraisal, questionSet: QuestionSet): IsSoftRequiredCalculator;

    answerValidator(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        validationErrors: ValidationError[]
    ): AnswerValidatorAdapter;

    childQuestionValidator(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        validationErrors: ValidationError[]
    ): ChildQuestionValidator;

    questionValidator(appraisal: Appraisal, questionSet: QuestionSet): QuestionValidator;

    questionValidationProvider(appraisal: Appraisal, questionSet: QuestionSet): QuestionValidationProvider;

    childQuestionValidationProvider(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        validationErrors: ValidationError[]
    ): ChildQuestionValidationProvider;

    conceptReportProvider(taskHelper: TaskHelper, appraisalApi: AppraisalApi): ConceptReportProvider;

    plotAreaProvider(appraisal: Appraisal, questionSet: QuestionSet): PlotAreaProvider;

    surfaceAreaProvider(appraisal: Appraisal, questionSet: QuestionSet): SurfaceAreaProvider;

    buildYearProvider(appraisal: Appraisal, questionSet: QuestionSet): BuildYearProvider;

    valuationProvider(appraisal: Appraisal, questionSet: QuestionSet): ValuationProvider;

    duplicationSourcesProvider(appraisal: Appraisal, questionSet: QuestionSet): DuplicationSourcesProvider;

    energyLabelProvider(appraisal: Appraisal, questionSet: QuestionSet): EnergyLabelProvider;

    answerPathStubber(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        renderingContext: RenderingContextType
    ): AnswerPathStubber;

    constructionCostsProvider(appraisal: Appraisal, questionSet: QuestionSet): ConstructionCostsProvider;

    floorIterationProvider(appraisal: Appraisal, questionSet: QuestionSet): FloorIterationProvider;

    answerByLabelSubmitter(appraisal: Appraisal, questionSet: QuestionSet): AnswerByLabelSubmitter;

    photoRecognitionHandler(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        version: AutoMLVersion,
        renderingContext: RenderingContextType
    ): PhotoRecognitionHandler;

    constructionalDefectsGroupsProvider(
        appraisal: Appraisal,
        questionSet: QuestionSet
    ): ConstructionalDefectsGroupsProvider;

    userSettingsInteractor(appraisal: Appraisal, questionSet: QuestionSet): UserSettingsInteractor;

    floorInteractor(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        renderingContextType: RenderingContextType
    ): FloorInteractor;

    v2SetDefinitionProvider(appraisal: Appraisal, questionSet: QuestionSet): V2SetDefinitionsProvider;

    v3SetDefinitionProvider(appraisal: Appraisal, questionSet: QuestionSet): V3SetDefinitionsProvider;

    buildingInspectionAppointmentInteractor(
        appraisal: Appraisal,
        questionSet: QuestionSet
    ): BuildingInspectionAppointmentInteractor;

    attachmentQuestionsInteractor(appraisal: Appraisal, questionSet: QuestionSet): AttachmentQuestionsInteractor;

    iteratorFilesAttachmentInteractor(
        appraisal: Appraisal,
        questionSet: QuestionSet
    ): IteratorFilesAttachmentInteractor;

    userSettingsActivePagePartConfigurationIdInteractor(
        appraisal: Appraisal,
        questionSet: QuestionSet
    ): UserSettingsActivePagePartConfigurationInteractor;

    pagePartsSetInteractor(appraisal: Appraisal, questionSet: QuestionSet): PagePartsSetInteractor;

    compareValuesProvider(appraisal: Appraisal, questionSet: QuestionSet): CompareValuesProvider;

    pagePartsCleaner(questionSet: QuestionSet): PagePartsCleaner;

    automatorInteractor(appraisal: Appraisal, questionSet: QuestionSet): AutomatorInteractor;

    textAIInteractor(appraisal: Appraisal, questionSet: QuestionSet): TextAIInteractor;
    textAIAnswerContextBuilder(appraisal: Appraisal, questionSet: QuestionSet): TextAIContextBuilder;
    textAIReferenceObjectContextBuilder(appraisal: Appraisal, questionSet: QuestionSet): TextAIContextBuilder;
    textAIAppraisalContextBuilder: TextAIContextBuilder;
    textAITransitievisieWarmteContextBuilder(appraisal: Appraisal, questionSet: QuestionSet): TextAIContextBuilder;

    appraisalProvider: AppraisalProvider;
    answerLoader: AnswerLoader;
    pagePartConfigurationsInteractor: PagePartConfigurationsInteractor;
    networkStatusProvider: NetworkStatusProvider;
    imageOrientationFixer: ImageOrientationFixer;
    userInteractor: UserInteractor;
    flashMessageBroadcaster: FlashMessageBroadcaster;
    constructionCostController: ConstructionCostController;
    distanceProvider: DistanceProvider;

    support: SupportComponent;

    appraiseSecondaryConfigStackInteractor: AppraiseSecondaryConfigStackInteractor;
    modalConfigStackInteractor: ModalConfigStackInteractor;
    modalOrSecondaryConfigStackInteractor: ModalOrSecondaryConfigStackInteractor;
}

export class DefaultBusinessComponent implements BusinessComponent {
    private _sidebarTreeBuilders: {[key: string]: SidebarTreeBuilder} = {};
    private _answerControllers: {[appraisalId: number]: AnswerController} = {};
    private _answerMemoryStores: {[appraisalId: number]: ObservableMemoryStorage<Answer>} = {};
    private _answerInteractors: {[appraisalId: number]: AnswerInteractor} = {};
    private _conditionsInteractors: {[appraisalId: number]: ConditionsInteractor} = {};
    private _unsyncedAnswerRetryInteractor: {[appraisalId: number]: UnsyncedAnswerRetryInteractor} = {};
    private _questionEffectsInteractors: {[appraisalId: number]: QuestionEffectInteractor} = {};
    private _macroEffectsInteractors: {[appraisalId: number]: MacroEffectInteractor} = {};
    private _superMacroInteractor: {[appraisalId: number]: SuperMacroInteractor} = {};
    private _superMacroFilter: {[appraisalId: number]: SuperMacroFilter} = {};
    private _v2ReferenceSetsProvider: {[appraisalId: number]: V2ReferenceSetsProvider} = {};
    private _v3ReferenceSetsProvider: {[appraisalId: number]: V3ReferenceSetsProvider} = {};
    private _photoAnswerRetryInteractors: {[appraisalId: number]: PhotoAnswerRetryInteractor} = {};
    private _uploadInteractors: {[appraisalId: number]: UploadInteractor} = {};
    private _imageUploadInteractors: {[appraisalId: number]: ImageUploadInteractor} = {};
    private _blobCacheInteractor: BlobCacheInteractor | undefined;
    private _attachmentInteractors: {[appraisalId: number]: AttachmentUploadInteractor} = {};
    private _referenceObjectProviders: {[appraisalId: number]: ReferenceObjectProvider} = {};
    private _referenceObjectsInteractors: {[appraisalId: number]: ReferenceObjectsInteractor} = {};
    private _referenceObjectsAnswerEnhancementInteractors: {
        [appraisalId: number]: ReferenceObjectsAnswerEnhancementInteractor;
    } = {};
    private _referenceObjectCurrentlyInSaleInteractors: {
        [appraisalId: number]: ReferenceObjectCurrentlyInSaleInteractor;
    } = {};
    private _referenceObjectCurrentlyInRentInteractors: {
        [appraisalId: number]: ReferenceObjectCurrentlyInRentInteractor;
    } = {};
    private _referenceObjectsProviders: {
        [appraisalId: number]: ReferenceObjectsV1Provider;
    } = {};
    private _referenceObjectsCurrentlyInSaleProviders: {
        [appraisalId: number]: ReferenceObjectsV1Provider;
    } = {};
    private _referenceObjectsCurrentlyInRentProviders: {
        [appraisalId: number]: ReferenceObjectsV1Provider;
    } = {};
    private _adaptedDefaultValuesInteractors: {[appraisalId: number]: AdaptedDefaultValuesInteractor} = {};
    private _adaptedValueProvider: {[appraisalId: number]: AdaptedValueProvider} = {};
    private _bbmiAreaProvider: {[appraisalId: number]: BbmiAreaProvider} = {};
    private _volumeProvider: {[appraisalId: number]: VolumeProvider} = {};
    private _valuationDateProvider: {[appraisalId: number]: ValuationDateProvider} = {};
    private _isRequiredCalculator: {[appraisalId: number]: IsRequiredCalculator} = {};
    private _isVisitedRequiredCalculator: {[appraisalId: number]: IsVisitedRequiredCalculator} = {};
    private _isSoftRequiredCalculator: {[appraisalId: number]: IsSoftRequiredCalculator} = {};
    private _answerValidators: {[appraisalId: number]: AnswerValidatorAdapter} = {};
    private _childQuestionValidationProviders: {[appraisalId: number]: ChildQuestionValidationProvider} = {};
    private _childQuestionValidators: {[appraisalId: number]: ChildQuestionValidator} = {};
    private _questionValidator: {[appraisalId: number]: QuestionValidator} = {};
    private _questionValidationProviders: {[appraisalId: number]: QuestionValidationProvider} = {};
    private _v2SetDefinitionProviders: {[appraisalId: number]: V2SetDefinitionsProvider} = {};
    private _v3SetDefinitionProviders: {[appraisalId: number]: V3SetDefinitionsProvider} = {};
    private _appraisalProvider: AppraisalProvider | undefined;
    private _pagePartConfigurationsInteractor: PagePartConfigurationsInteractor | undefined;
    private _pagePartsSetInteractor: PagePartsSetInteractor | undefined;
    private _userSettingsActivePagePartConfigurationIdInteractor:
        | UserSettingsActivePagePartConfigurationInteractor
        | undefined;
    private _pagePartsCleaner: PagePartsCleaner | undefined;
    private _photoRecognitionHandlers: Partial<Record<AutoMLVersion, PhotoRecognitionHandler>> = {};
    private _constructionalDefectsGroupsProvider: ConstructionalDefectsGroupsProvider | undefined;
    private volatileValidationMessagesFromTreeCollectors: {
        [key: string]: VolatileValidationMessagesFromTreeCollector;
    } = {};
    private validationConclusionProviders: {
        [key: string]: ValidationConclusionProvider;
    } = {};
    private pagePartValidationProviders: {
        [appraisalId: number]: PagePartValidationProvider;
    } = {};
    private questionValidationMessagesProviders: {[appraisalId: number]: QuestionValidationMessagesProvider} = {};
    private _genericErrorsValidator: {
        [appraisalId: number]: GenericErrorsValidator;
    } = {};
    private _macroInteractors: {[questionSetId: number]: MacroInteractor} = {};
    private _searchInteractors: {[questionSetId: number]: SearchInteractor} = {};
    private _userSettingsInteractors: {
        [appraisalId: number]: UserSettingsInteractor;
    } = {};
    private _floorInteractors: {
        [appraisalId: number]: FloorInteractor;
    } = {};
    private _compareValuesProviders: {
        [appraisalId: number]: CompareValuesProvider;
    } = {};
    private _referenceObjectsMetadataProviders: {
        [appraisalId: number]: ReferenceObjectsMetadataProvider;
    } = {};
    private _plotAreaProviders: {
        [appraisalId: number]: PlotAreaProvider;
    } = {};
    private _surfaceAreaProviders: {
        [appraisalId: number]: SurfaceAreaProvider;
    } = {};
    private _answerLoader?: AnswerLoader;
    private _distanceProvider?: DistanceProvider;
    private _networkStatusProvider?: NetworkStatusProvider;
    private _userInteractor: UserInteractor | undefined;
    private _flashMessageBroadcaster: FlashMessageBroadcaster | undefined;

    private _persistenceComponent: PersistenceComponent;
    private _networkComponent: NetworkComponent;
    private _serverTimeProvider: ServerTimeProvider;
    private _globalProvider: GlobalProvider;
    private _conceptReportProvider: ConceptReportProvider | undefined;
    private _automatorInteractor: AutomatorInteractor | undefined;

    private _constructionCostController: ConstructionCostController | undefined;
    private _locationProvider: LocationProvider | undefined;
    private _buildingInspectionAppointmentInteractor: BuildingInspectionAppointmentInteractor | undefined;
    private _attachmentQuestionsInteractor: AttachmentQuestionsInteractor | undefined;
    private _iteratorFilesAttachmentInteractor: IteratorFilesAttachmentInteractor | undefined;
    private _constructionCostsProvider: ConstructionCostsProvider | undefined;
    private _textAIInteractor: TextAIInteractor | undefined;
    private _textAIAnswerContextBuilder: TextAIContextBuilder | undefined;
    private _textAIReferenceObjectContextBuilder: TextAIContextBuilder | undefined;
    private _textAIAppraisalContextBuilder: TextAIContextBuilder | undefined;
    private _textAITransitievisieWarmteContextBuilder: TextAIContextBuilder | undefined;

    constructor(
        persistenceComponent: PersistenceComponent,
        networkComponent: NetworkComponent,
        serverTimeProvider: ServerTimeProvider,
        globalProvider: GlobalProvider
    ) {
        this._persistenceComponent = persistenceComponent;
        this._networkComponent = networkComponent;
        this._serverTimeProvider = serverTimeProvider;
        this._globalProvider = globalProvider;
    }

    public sidebarTreeBuilder(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        pagePartsSet: PagePartsSet | null
    ): SidebarTreeBuilder {
        const key = `${appraisal.id}-${pagePartsSet?.id}`;
        if (this._sidebarTreeBuilders[key] === undefined) {
            this._sidebarTreeBuilders[key] = new SidebarTreeBuilder(
                questionSet,
                appraisal,
                pagePartsSet,
                this.answerController(appraisal, questionSet),
                this.questionEffectsInteractor(appraisal, questionSet),
                this._globalProvider
            );
        }

        return this._sidebarTreeBuilders[key];
    }

    public observableMemoryStorage(appraisal: Appraisal): ObservableMemoryStorage<Answer> {
        if (this._answerMemoryStores[appraisal.id] === undefined) {
            this._answerMemoryStores[appraisal.id] = createObservableAnswerMemoryStorage();
        }
        return this._answerMemoryStores[appraisal.id];
    }

    public answerController(appraisal: Appraisal, questionSet: QuestionSet): AnswerController {
        if (this._answerControllers[appraisal.id] === undefined) {
            let answerRegistry: AnswerRegistry = new DefaultAnswerRegistry(
                this.answerLoader.getForAppraisal(appraisal.id),
                new DeletedAnswersFilter(),
                new HasQuestionAnswerFilter(questionSet),
                new HouseFilter(questionSet),
                this._serverTimeProvider,
                this.observableMemoryStorage(appraisal)
            );

            //Local storage is only interesting when user is an appraiser, clients aren't that advanced
            if (
                (this.userInteractor.isAppraiser() ||
                    this.userInteractor.isEmployee() ||
                    this.userInteractor.isJuniorAppraiser()) &&
                appraisal.dummy !== true
            ) {
                answerRegistry = new LocalStorageAnswerRegistry(
                    appraisal,
                    answerRegistry,
                    this._persistenceComponent.localStorage()
                );
            }

            this._answerControllers[appraisal.id] = new DefaultAnswerController(
                answerRegistry,
                new AnswerSelector(
                    this._globalProvider.global,
                    answerRegistry,
                    appraisal,
                    questionSet,
                    this._serverTimeProvider,
                    this.macroInteractor(questionSet),
                    this.superMacroFilter(appraisal),
                    this._globalProvider
                ),
                this._serverTimeProvider,
                this._globalProvider
            );
        }

        return this._answerControllers[appraisal.id];
    }

    public answerInteractor(appraisal: Appraisal, questionSet: QuestionSet): AnswerInteractor {
        if (this._answerInteractors[appraisal.id] === undefined) {
            this._answerInteractors[appraisal.id] = new DefaultAnswerInteractor(
                appraisal,
                this.answerController(appraisal, questionSet),
                new AnswerStorageQueue(this._networkComponent.answerApi),
                this.networkStatusProvider
            );
        }

        return this._answerInteractors[appraisal.id];
    }

    public conditionsInteractor(appraisal: Appraisal, questionSet: QuestionSet): ConditionsInteractor {
        if (this._conditionsInteractors[appraisal.id] === undefined) {
            this._conditionsInteractors[appraisal.id] = new DefaultConditionsInteractor(
                questionSet,
                this.answerController(appraisal, questionSet),
                new EffectsCollectorFromAnswers(questionSet, new AppraisalFieldResolver(this.appraisalProvider))
            );
        }
        return this._conditionsInteractors[appraisal.id];
    }

    public questionEffectsInteractor(appraisal: Appraisal, questionSet: QuestionSet): QuestionEffectInteractor {
        if (this._questionEffectsInteractors[appraisal.id] === undefined) {
            this._questionEffectsInteractors[appraisal.id] = new DefaultQuestionEffectInteractor(
                questionSet,
                this.conditionsInteractor(appraisal, questionSet),
                this.answerController(appraisal, questionSet),
                this.appraisalProvider
            );
        }
        return this._questionEffectsInteractors[appraisal.id];
    }

    public macroEffectsInteractor(appraisal: Appraisal, questionSet: QuestionSet): MacroEffectInteractor {
        if (this._macroEffectsInteractors[appraisal.id] === undefined) {
            this._macroEffectsInteractors[appraisal.id] = new DefaultMacroEffectInteractor(
                this.conditionsInteractor(appraisal, questionSet),
                this.answerController(appraisal, questionSet),
                this.appraisalProvider
            );
        }
        return this._macroEffectsInteractors[appraisal.id];
    }

    public superMacroFilter(appraisal: Appraisal): SuperMacroFilter {
        if (this._superMacroFilter[appraisal.id] === undefined) {
            this._superMacroFilter[appraisal.id] = new SuperMacroFilter(appraisal, this._globalProvider.global);
        }
        return this._superMacroFilter[appraisal.id];
    }

    public superMacroInteractor(appraisal: Appraisal, questionSet: QuestionSet): SuperMacroInteractor {
        if (this._superMacroInteractor[appraisal.id] === undefined) {
            this._superMacroInteractor[appraisal.id] = new SuperMacroInteractor(
                appraisal,
                questionSet,
                this._globalProvider.global,
                this.macroInteractor(questionSet),
                this.answerController(appraisal, questionSet),
                this.macroEffectsInteractor(appraisal, questionSet),
                this.superMacroFilter(appraisal)
            );
        }
        return this._superMacroInteractor[appraisal.id];
    }

    public v2ReferenceSetsProvider(appraisal: Appraisal, questionSet: QuestionSet) {
        if (this._v2ReferenceSetsProvider[appraisal.id] === undefined) {
            this._v2ReferenceSetsProvider[appraisal.id] = new DefaultV2ReferenceSetsProvider(
                this.v2SetDefinitionProvider(appraisal, questionSet),
                this.buildYearProvider(appraisal, questionSet),
                this.surfaceAreaProvider(appraisal, questionSet),
                this.plotAreaProvider(appraisal, questionSet),
                new DefaultOverigeInpandigeRuimteProvider(this.bbmiAreaProvider(appraisal, questionSet)),
                new DefaultGebruiksOppervlakteBuitenruimteProvider(this.bbmiAreaProvider(appraisal, questionSet)),
                new DefaultGebruiksOppervlakteExterneBergruimteProvider(this.bbmiAreaProvider(appraisal, questionSet)),
                this.volumeProvider(appraisal, questionSet),
                this.energyLabelProvider(appraisal, questionSet),
                this.valuationDateProvider(appraisal, questionSet)
            );
        }
        return this._v2ReferenceSetsProvider[appraisal.id];
    }

    public v3ReferenceSetsProvider(appraisal: Appraisal, questionSet: QuestionSet) {
        if (this._v3ReferenceSetsProvider[appraisal.id] === undefined) {
            this._v3ReferenceSetsProvider[appraisal.id] = new DefaultV3ReferenceSetsProvider(
                appraisal,
                questionSet,
                this.v3SetDefinitionProvider(appraisal, questionSet),
                this.buildYearProvider(appraisal, questionSet),
                this.surfaceAreaProvider(appraisal, questionSet),
                this.plotAreaProvider(appraisal, questionSet),
                new DefaultOverigeInpandigeRuimteProvider(this.bbmiAreaProvider(appraisal, questionSet)),
                new DefaultGebruiksOppervlakteBuitenruimteProvider(this.bbmiAreaProvider(appraisal, questionSet)),
                new DefaultGebruiksOppervlakteExterneBergruimteProvider(this.bbmiAreaProvider(appraisal, questionSet)),
                this.volumeProvider(appraisal, questionSet),
                this.energyLabelProvider(appraisal, questionSet),
                this.valuationDateProvider(appraisal, questionSet),
                this.answerController(appraisal, questionSet),
                this.questionEffectsInteractor(appraisal, questionSet)
            );
        }
        return this._v3ReferenceSetsProvider[appraisal.id];
    }

    public unsyncedAnswerRetryInteractor(
        appraisal: Appraisal,
        questionSet: QuestionSet
    ): UnsyncedAnswerRetryInteractor {
        if (this._unsyncedAnswerRetryInteractor[appraisal.id] === undefined) {
            this._unsyncedAnswerRetryInteractor[appraisal.id] = new DefaultUnsyncedAnswerRetryInteractor(
                questionSet,
                this.answerController(appraisal, questionSet),
                this.answerInteractor(appraisal, questionSet)
            );
        }
        return this._unsyncedAnswerRetryInteractor[appraisal.id];
    }

    public photoAnswerRetryInteractor(appraisal: Appraisal, questionSet: QuestionSet): PhotoAnswerRetryInteractor {
        if (this._photoAnswerRetryInteractors[appraisal.id] === undefined) {
            this._photoAnswerRetryInteractors[appraisal.id] = new DefaultPhotoAnswerRetryInteractor(
                questionSet,
                this.answerController(appraisal, questionSet),
                this.blobCacheInteractor(),
                this.answerInteractor(appraisal, questionSet),
                this.imageUploadInteractor(appraisal, questionSet),
                this.questionEffectsInteractor(appraisal, questionSet)
            );
        }
        return this._photoAnswerRetryInteractors[appraisal.id];
    }

    private uploadInteractor(appraisal: Appraisal): UploadInteractor {
        if (this._uploadInteractors[appraisal.id] === undefined) {
            this._uploadInteractors[appraisal.id] = new RetryUploadInteractor(new S3UploadInteractor(appraisal));
        }
        return this._uploadInteractors[appraisal.id];
    }

    public blobCacheInteractor(): BlobCacheInteractor {
        if (this._blobCacheInteractor === undefined) {
            this._blobCacheInteractor = new DefaultBlobCacheInteractor();
        }
        return this._blobCacheInteractor;
    }

    public imageUploadInteractor(appraisal: Appraisal, questionSet: QuestionSet): ImageUploadInteractor {
        if (this._imageUploadInteractors[appraisal.id] === undefined) {
            this._imageUploadInteractors[appraisal.id] = new ImageUploadInteractor(
                this.uploadInteractor(appraisal),
                this.blobCacheInteractor(),
                this.answerController(appraisal, questionSet),
                this.answerInteractor(appraisal, questionSet),
                this.flashMessageBroadcaster,
                new ImageCompressor()
            );
        }
        return this._imageUploadInteractors[appraisal.id];
    }

    public attachmentUploadInteractor(appraisal: Appraisal, questionSet: QuestionSet): AttachmentUploadInteractor {
        if (this._attachmentInteractors[appraisal.id] === undefined) {
            this._attachmentInteractors[appraisal.id] = new AttachmentUploadInteractor(
                this.uploadInteractor(appraisal),
                this.blobCacheInteractor(),
                this.answerController(appraisal, questionSet),
                this.answerInteractor(appraisal, questionSet),
                this.flashMessageBroadcaster
            );
        }
        return this._attachmentInteractors[appraisal.id];
    }

    public referenceObjectProvider(appraisal: Appraisal, questionSet: QuestionSet): ReferenceObjectProvider {
        if (this._referenceObjectProviders[appraisal.id] === undefined) {
            this._referenceObjectProviders[appraisal.id] = new DefaultReferenceObjectProvider(
                appraisal,
                this._networkComponent.referenceObjectApi,
                new TaskHelper(this._networkComponent.ajaxDriver),
                this.answerInteractor(appraisal, questionSet),
                this.flashMessageBroadcaster
            );
        }
        return this._referenceObjectProviders[appraisal.id];
    }

    public referenceObjectsInteractor(appraisal: Appraisal, questionSet: QuestionSet): ReferenceObjectsInteractor {
        if (this._referenceObjectsInteractors[appraisal.id] === undefined) {
            this._referenceObjectsInteractors[appraisal.id] = new DefaultReferenceObjectsInteractor(
                this.referenceObjectProvider(appraisal, questionSet),
                new TaskHelper(this._networkComponent.ajaxDriver)
            );
        }
        return this._referenceObjectsInteractors[appraisal.id];
    }

    public referenceObjectsAnswerEnhancementInteractor(
        appraisal: Appraisal,
        questionSet: QuestionSet
    ): ReferenceObjectsAnswerEnhancementInteractor {
        if (this._referenceObjectsAnswerEnhancementInteractors[appraisal.id] === undefined) {
            this._referenceObjectsAnswerEnhancementInteractors[appraisal.id] =
                new DefaultReferenceObjectsAnswerEnhancementInteractor(
                    this.referenceObjectProvider(appraisal, questionSet)
                );
        }
        return this._referenceObjectsAnswerEnhancementInteractors[appraisal.id];
    }

    public referenceObjectCurrentlyInSaleInteractor(appraisal: Appraisal): ReferenceObjectCurrentlyInSaleInteractor {
        if (this._referenceObjectCurrentlyInSaleInteractors[appraisal.id] === undefined) {
            this._referenceObjectCurrentlyInSaleInteractors[appraisal.id] =
                new DefaultReferenceObjectCurrentlyInSaleInteractor(
                    appraisal,
                    this._networkComponent.referenceObjectSaleApi,
                    this._networkComponent.taskHelper
                );
        }
        return this._referenceObjectCurrentlyInSaleInteractors[appraisal.id];
    }

    public referenceObjectCurrentlyInRentInteractor(appraisal: Appraisal): ReferenceObjectCurrentlyInRentInteractor {
        if (this._referenceObjectCurrentlyInRentInteractors[appraisal.id] === undefined) {
            this._referenceObjectCurrentlyInRentInteractors[appraisal.id] =
                new DefaultReferenceObjectCurrentlyInRentInteractor(
                    appraisal,
                    this._networkComponent.referenceObjectRentApi,
                    this._networkComponent.taskHelper
                );
        }
        return this._referenceObjectCurrentlyInRentInteractors[appraisal.id];
    }

    public referenceObjectsProvider(appraisal: Appraisal, questionSet: QuestionSet) {
        if (this._referenceObjectsProviders[appraisal.id] === undefined) {
            this._referenceObjectsProviders[appraisal.id] = new DefaultReferenceObjectsV1Provider(
                appraisal,
                questionSet,
                this.appraisalProvider,
                this.answerController(appraisal, questionSet),
                this.referenceObjectProvider(appraisal, questionSet),
                this.surfaceAreaProvider(appraisal, questionSet),
                this.plotAreaProvider(appraisal, questionSet),
                this.buildYearProvider(appraisal, questionSet),
                this.userInteractor
            );
        }
        return this._referenceObjectsProviders[appraisal.id];
    }

    public referenceObjectsMetadataProvider(appraisal: Appraisal, questionSet: QuestionSet) {
        if (this._referenceObjectsMetadataProviders[appraisal.id] === undefined) {
            this._referenceObjectsMetadataProviders[appraisal.id] = new DefaultReferenceObjectsMetadataProvider(
                questionSet,
                this.answerController(appraisal, questionSet),
                [new v3ReferenceObjectsMetadataManager()]
            );
        }
        return this._referenceObjectsMetadataProviders[appraisal.id];
    }

    public referenceObjectsCurrentlyInSaleProvider(
        appraisal: Appraisal,
        questionSet: QuestionSet
    ): ReferenceObjectsV1Provider {
        if (this._referenceObjectsCurrentlyInSaleProviders[appraisal.id] === undefined) {
            this._referenceObjectsCurrentlyInSaleProviders[appraisal.id] = new DefaultReferenceObjectsV1Provider(
                appraisal,
                questionSet,
                this.appraisalProvider,
                this.answerController(appraisal, questionSet),
                this.referenceObjectCurrentlyInSaleInteractor(appraisal),
                this.surfaceAreaProvider(appraisal, questionSet),
                this.plotAreaProvider(appraisal, questionSet),
                this.buildYearProvider(appraisal, questionSet),
                this.userInteractor
            );
        }
        return this._referenceObjectsCurrentlyInSaleProviders[appraisal.id];
    }

    public referenceObjectsCurrentlyInRentProvider(
        appraisal: Appraisal,
        questionSet: QuestionSet
    ): ReferenceObjectsV1Provider {
        if (this._referenceObjectsCurrentlyInRentProviders[appraisal.id] === undefined) {
            this._referenceObjectsCurrentlyInRentProviders[appraisal.id] = new DefaultReferenceObjectsV1Provider(
                appraisal,
                questionSet,
                this.appraisalProvider,
                this.answerController(appraisal, questionSet),
                this.referenceObjectCurrentlyInRentInteractor(appraisal),
                this.surfaceAreaProvider(appraisal, questionSet),
                this.plotAreaProvider(appraisal, questionSet),
                this.buildYearProvider(appraisal, questionSet),
                this.userInteractor
            );
        }
        return this._referenceObjectsCurrentlyInRentProviders[appraisal.id];
    }

    public adaptedDefaultValuesInteractor(appraisal: Appraisal): AdaptedDefaultValuesInteractor {
        if (this._adaptedDefaultValuesInteractors[appraisal.id] === undefined) {
            this._adaptedDefaultValuesInteractors[appraisal.id] = new DefaultAdaptedDefaultValuesInteractor(
                appraisal,
                this._networkComponent.adaptedDefaultValuesApi,
                new TaskHelper(this._networkComponent.ajaxDriver)
            );
        }
        return this._adaptedDefaultValuesInteractors[appraisal.id];
    }

    public adaptedValueProvider(appraisal: Appraisal, questionSet: QuestionSet): AdaptedValueProvider {
        if (this._adaptedValueProvider[appraisal.id] === undefined) {
            this._adaptedValueProvider[appraisal.id] = new DefaultAdaptedValueProvider(
                questionSet,
                this.answerController(appraisal, questionSet),
                new DefaultAdaptedValueBuilder(
                    questionSet,
                    this.answerController(appraisal, questionSet),
                    new DefaultAdaptedValueConfigProvider(),
                    new DefaultSentenceBuilder(),
                    new DefaultAnswerContentBuilder()
                ),
                new ObjectAppraiserExplanationAdaptedValueBuilder(
                    appraisal,
                    questionSet,
                    this.answerController(appraisal, questionSet),
                    new DefaultAdaptedValueConfigProvider(),
                    new DefaultSentenceBuilder(),
                    new DefaultAnswerContentBuilder()
                ),
                new ObjectBuildingsAppraiserExplanationAdaptedValueBuilder(appraisal)
            );
        }
        return this._adaptedValueProvider[appraisal.id];
    }

    public bbmiAreaProvider(appraisal: Appraisal, questionSet: QuestionSet): BbmiAreaProvider {
        if (this._bbmiAreaProvider[appraisal.id] === undefined) {
            this._bbmiAreaProvider[appraisal.id] = new DefaultBbmiAreaProvider(
                questionSet,
                this.answerController(appraisal, questionSet),
                this.questionEffectsInteractor(appraisal, questionSet)
            );
        }
        return this._bbmiAreaProvider[appraisal.id];
    }

    public volumeProvider(appraisal: Appraisal, questionSet: QuestionSet): VolumeProvider {
        if (this._volumeProvider[appraisal.id] === undefined) {
            this._volumeProvider[appraisal.id] = new DefaultVolumeProvider(
                questionSet,
                this.answerController(appraisal, questionSet),
                this.questionEffectsInteractor(appraisal, questionSet),
                this.surfaceAreaProvider(appraisal, questionSet)
            );
        }
        return this._volumeProvider[appraisal.id];
    }

    public valuationDateProvider(appraisal: Appraisal, questionSet: QuestionSet): ValuationDateProvider {
        if (this._valuationDateProvider[appraisal.id] === undefined) {
            this._valuationDateProvider[appraisal.id] = new DefaultValuationDateProvider(
                questionSet,
                appraisal,
                this.answerController(appraisal, questionSet)
            );
        }
        return this._valuationDateProvider[appraisal.id];
    }

    public v2SetDefinitionProvider(appraisal: Appraisal, questionSet: QuestionSet): V2SetDefinitionsProvider {
        if (this._v2SetDefinitionProviders[appraisal.id] === undefined) {
            this._v2SetDefinitionProviders[appraisal.id] = new DefaultV2SetDefinitionsProvider(
                questionSet,
                this.answerController(appraisal, questionSet),
                this.questionEffectsInteractor(appraisal, questionSet),
                appraisal
            );
        }
        return this._v2SetDefinitionProviders[appraisal.id];
    }

    public v3SetDefinitionProvider(appraisal: Appraisal, questionSet: QuestionSet): V3SetDefinitionsProvider {
        if (this._v3SetDefinitionProviders[appraisal.id] === undefined) {
            this._v3SetDefinitionProviders[appraisal.id] = new DefaultV3SetDefinitionsProvider(
                questionSet,
                this.answerController(appraisal, questionSet),
                this.questionEffectsInteractor(appraisal, questionSet),
                appraisal
            );
        }
        return this._v3SetDefinitionProviders[appraisal.id];
    }

    public buildingInspectionAppointmentInteractor(
        appraisal: Appraisal,
        questionSet: QuestionSet
    ): BuildingInspectionAppointmentInteractor {
        if (this._buildingInspectionAppointmentInteractor === undefined) {
            this._buildingInspectionAppointmentInteractor = new DefaultBuildingInspectionAppointmentInteractor(
                appraisal,
                questionSet,
                this.answerController(appraisal, questionSet)
            );
        }
        return this._buildingInspectionAppointmentInteractor;
    }

    public attachmentQuestionsInteractor(
        appraisal: Appraisal,
        questionSet: QuestionSet
    ): AttachmentQuestionsInteractor {
        if (this._attachmentQuestionsInteractor === undefined) {
            this._attachmentQuestionsInteractor = new DefaultAttachmentQuestionsInteractor(
                questionSet,
                this._globalProvider,
                this.answerController(appraisal, questionSet),
                this.answerInteractor(appraisal, questionSet),
                this.questionEffectsInteractor(appraisal, questionSet),
                this.appraiseSecondaryConfigStackInteractor
            );
        }
        return this._attachmentQuestionsInteractor;
    }

    public isRequiredCalculator(appraisal: Appraisal, questionSet: QuestionSet): IsRequiredCalculator {
        if (this._isRequiredCalculator[appraisal.id] === undefined) {
            this._isRequiredCalculator[appraisal.id] = new DefaultIsRequiredCalculator(
                this.conditionsInteractor(appraisal, questionSet)
            );
        }
        return this._isRequiredCalculator[appraisal.id];
    }

    public isVisitedRequiredCalculator(appraisal: Appraisal, questionSet: QuestionSet): IsVisitedRequiredCalculator {
        if (this._isVisitedRequiredCalculator[appraisal.id] === undefined) {
            this._isVisitedRequiredCalculator[appraisal.id] = new DefaultIsVisitedRequiredCalculator(
                this.conditionsInteractor(appraisal, questionSet)
            );
        }
        return this._isVisitedRequiredCalculator[appraisal.id];
    }

    public isSoftRequiredCalculator(appraisal: Appraisal, questionSet: QuestionSet): IsSoftRequiredCalculator {
        if (this._isSoftRequiredCalculator[appraisal.id] === undefined) {
            this._isSoftRequiredCalculator[appraisal.id] = new DefaultIsSoftRequiredCalculator(
                this.conditionsInteractor(appraisal, questionSet)
            );
        }
        return this._isSoftRequiredCalculator[appraisal.id];
    }

    public answerValidator(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        validationErrors: ValidationError[]
    ): AnswerValidatorAdapter {
        if (this._answerValidators[appraisal.id] === undefined) {
            this._answerValidators[appraisal.id] = new DefaultAnswerValidatorAdapter(
                questionSet,
                [
                    new RequiredQuestionValidator(
                        this.isRequiredCalculator(appraisal, questionSet),
                        this.answerController(appraisal, questionSet)
                    ),
                    new IteratorValidator(this.answerController(appraisal, questionSet)),
                    new ValidationInstituteValidation(validationErrors),
                    new ReferenceObjectValidator(
                        questionSet,
                        this.isRequiredCalculator(appraisal, questionSet),
                        this.answerController(appraisal, questionSet)
                    ),
                    new FloorValidator(questionSet, this.answerController(appraisal, questionSet)),
                    new VVEValidator(questionSet, this.answerController(appraisal, questionSet)),
                    /* new BuildingCostsPhotoValidator(
                        questionSet,
                        appraisal,
                        this.answerController(appraisal, questionSet)
                    ), */
                    new KoopovereenkomstValidator(
                        questionSet,
                        appraisal,
                        this.answerController(appraisal, questionSet)
                    ),
                    new IsVisitedValidator(
                        this.answerController(appraisal, questionSet),
                        this.isVisitedRequiredCalculator(appraisal, questionSet)
                    ),
                    new SoftRequiredValidator(
                        this.answerController(appraisal, questionSet),
                        this.isSoftRequiredCalculator(appraisal, questionSet)
                    ),
                    new GeneralCommentsValidator(appraisal),
                    new V2UniqueSpecialValueValidator(
                        this.v2SetDefinitionProvider(appraisal, questionSet),
                        questionSet
                    ),
                    new V3UniqueSpecialValueValidator(
                        this.v3SetDefinitionProvider(appraisal, questionSet),
                        questionSet
                    ),
                    new IteratorReferenceObjectV3Validator(
                        appraisal,
                        questionSet,
                        this.v3SetDefinitionProvider(appraisal, questionSet)
                    ),
                    new ReferenceObjectV3Validator(
                        appraisal,
                        questionSet,
                        this.v3SetDefinitionProvider(appraisal, questionSet),
                        this.valuationDateProvider(appraisal, questionSet)
                    ),
                    new MultipleBooleanGroupValidator(
                        questionSet,
                        this.answerController(appraisal, questionSet),
                        this.isRequiredCalculator(appraisal, questionSet),
                        this.questionEffectsInteractor(appraisal, questionSet)
                    ),
                    new FloorSurfaceAreaValidator(
                        questionSet,
                        this.answerController(appraisal, questionSet),
                        this.surfaceAreaProvider(appraisal, questionSet)
                    ),
                    new AnswerCompareValidator(this.conditionsInteractor(appraisal, questionSet)),
                    new GrondgebondenAkteValidator(this.answerController(appraisal, questionSet), questionSet),
                    new GrondgebondenValidator(this.answerController(appraisal, questionSet), questionSet),
                ],
                this.answerController(appraisal, questionSet),
                this.userInteractor
            );
        }
        return this._answerValidators[appraisal.id];
    }

    public childQuestionValidator(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        validationErrors: ValidationError[]
    ): ChildQuestionValidator {
        if (this._childQuestionValidators[appraisal.id] === undefined) {
            this._childQuestionValidators[appraisal.id] = new DefaultChildValidator(
                questionSet,
                this.answerValidator(appraisal, questionSet, validationErrors),
                this.answerController(appraisal, questionSet),
                this.questionEffectsInteractor(appraisal, questionSet),
                this.questionValidator(appraisal, questionSet)
            );
        }
        return this._childQuestionValidators[appraisal.id];
    }

    public childQuestionValidationProvider(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        validationErrors: ValidationError[]
    ): ChildQuestionValidationProvider {
        if (this._childQuestionValidationProviders[appraisal.id] === undefined) {
            this._childQuestionValidationProviders[appraisal.id] = new DefaultChildQuestionValidationProvider(
                questionSet,
                this.childQuestionValidator(appraisal, questionSet, validationErrors),
                this.answerController(appraisal, questionSet),
                this.appraisalProvider
            );
        }
        return this._childQuestionValidationProviders[appraisal.id];
    }

    public questionValidator(appraisal: Appraisal, questionSet: QuestionSet): QuestionValidator {
        if (this._questionValidator[appraisal.id] === undefined) {
            this._questionValidator[appraisal.id] = new DefaultQuestionValidator([
                new PhotoIteratorValidator(
                    questionSet,
                    this.answerController(appraisal, questionSet),
                    this.questionEffectsInteractor(appraisal, questionSet),
                    this.appraisalProvider
                ),
                new ObjectTypeValidator(questionSet, this.appraisalProvider),
                new ImprovementsWorkQuestionValidator(
                    questionSet,
                    this.answerController(appraisal, questionSet),
                    this.constructionCostsProvider(appraisal, questionSet),
                    this.isRequiredCalculator(appraisal, questionSet)
                ),
                new AttachmentValidator(
                    questionSet,
                    this.answerController(appraisal, questionSet),
                    this.questionEffectsInteractor(appraisal, questionSet)
                ),
            ]);
        }
        return this._questionValidator[appraisal.id];
    }

    public questionValidationProvider(appraisal: Appraisal, questionSet: QuestionSet): QuestionValidationProvider {
        if (this._questionValidationProviders[appraisal.id] === undefined) {
            this._questionValidationProviders[appraisal.id] = new DefaultQuestionValidationProvider(
                questionSet,
                this.questionValidator(appraisal, questionSet),
                this.answerController(appraisal, questionSet),
                this.appraisalProvider
            );
        }
        return this._questionValidationProviders[appraisal.id];
    }

    public conceptReportProvider(taskHelper: TaskHelper, appraisalApi: AppraisalApi): ConceptReportProvider {
        if (this._conceptReportProvider === undefined) {
            this._conceptReportProvider = new ConceptReportProvider(taskHelper, appraisalApi);
        }
        return this._conceptReportProvider;
    }

    public plotAreaProvider(appraisal: Appraisal, questionSet: QuestionSet): PlotAreaProvider {
        if (this._plotAreaProviders[appraisal.id] === undefined) {
            this._plotAreaProviders[appraisal.id] = new DefaultPlotAreaProvider(
                appraisal,
                questionSet,
                this.answerController(appraisal, questionSet),
                this.questionEffectsInteractor(appraisal, questionSet)
            );
        }
        return this._plotAreaProviders[appraisal.id];
    }

    public surfaceAreaProvider(appraisal: Appraisal, questionSet: QuestionSet): SurfaceAreaProvider {
        if (this._surfaceAreaProviders[appraisal.id] === undefined) {
            this._surfaceAreaProviders[appraisal.id] = new DefaultSurfaceAreaProvider(
                questionSet,
                this.answerController(appraisal, questionSet),
                this.questionEffectsInteractor(appraisal, questionSet)
            );
        }
        return this._surfaceAreaProviders[appraisal.id];
    }

    public buildYearProvider(appraisal: Appraisal, questionSet: QuestionSet): BuildYearProvider {
        return new BuildYearProvider(questionSet, this.answerController(appraisal, questionSet), appraisal);
    }

    public valuationProvider(appraisal: Appraisal, questionSet: QuestionSet): ValuationProvider {
        return new ValuationProvider(questionSet, this.answerController(appraisal, questionSet));
    }

    public duplicationSourcesProvider(appraisal: Appraisal, questionSet: QuestionSet): DuplicationSourcesProvider {
        return new DefaultDuplicationSourcesProvider(
            questionSet,
            this.answerController(appraisal, questionSet),
            this.questionEffectsInteractor(appraisal, questionSet)
        );
    }

    public energyLabelProvider(appraisal: Appraisal, questionSet: QuestionSet): EnergyLabelProvider {
        return new DefaultEnergyLabelProvider(
            questionSet,
            this.answerController(appraisal, questionSet),
            this.questionEffectsInteractor(appraisal, questionSet),
            this._globalProvider
        );
    }

    public constructionCostsProvider(appraisal: Appraisal, questionSet: QuestionSet): ConstructionCostsProvider {
        if (this._constructionCostsProvider === undefined) {
            this._constructionCostsProvider = new DefaultConstructionCostsProvider(
                questionSet,
                this.answerController(appraisal, questionSet),
                this.questionEffectsInteractor(appraisal, questionSet)
            );
        }
        return this._constructionCostsProvider;
    }

    public answerPathStubber(appraisal: Appraisal, questionSet: QuestionSet, renderingContext: RenderingContextType) {
        return new AnswerPathStubber(
            questionSet,
            this.answerController(appraisal, questionSet),
            this.flashMessageBroadcaster,
            renderingContext,
            this.questionEffectsInteractor(appraisal, questionSet)
        );
    }

    public floorIterationProvider(appraisal: Appraisal, questionSet: QuestionSet): FloorIterationProvider {
        return new FloorIterationProvider(this.answerController(appraisal, questionSet));
    }

    public answerByLabelSubmitter(appraisal: Appraisal, questionSet: QuestionSet): AnswerByLabelSubmitter {
        return new DefaultAnswerByLabelSubmitter(questionSet, this.answerController(appraisal, questionSet));
    }

    public photoRecognitionHandler(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        version: AutoMLVersion,
        renderingContext: RenderingContextType
    ) {
        if (this._photoRecognitionHandlers[version] === undefined) {
            this._photoRecognitionHandlers[version] = new DefaultPhotoRecognitionHandler(
                questionSet,
                new DefaultPhotoContentPredicter(
                    new NetworkBoundaryPhotoContentPredicter(new AutomlPhotoHandler(version))
                ),
                this.answerController(appraisal, questionSet),
                this.answerPathStubber(appraisal, questionSet, renderingContext)
            );
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this._photoRecognitionHandlers[version]!;
    }

    public constructionalDefectsGroupsProvider(
        appraisal: Appraisal,
        questionSet: QuestionSet
    ): ConstructionalDefectsGroupsProvider {
        if (this._constructionalDefectsGroupsProvider === undefined) {
            this._constructionalDefectsGroupsProvider = new DefaultConstructionalDefectsGroupsProvider(
                questionSet,
                this.answerController(appraisal, questionSet),
                this.questionEffectsInteractor(appraisal, questionSet)
            );
        }
        return this._constructionalDefectsGroupsProvider;
    }

    public volatileValidationMessagesFromTreeCollector(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        pagePartsSet: PagePartsSet | null,
        validationErrors: ValidationError[],
        renderingContextType: RenderingContextType
    ): VolatileValidationMessagesFromTreeCollector {
        const key = `${appraisal.id}-${pagePartsSet?.id}`;
        if (this.volatileValidationMessagesFromTreeCollectors[key] === undefined) {
            this.volatileValidationMessagesFromTreeCollectors[key] =
                new DefaultVolatileValidationMessagesFromTreeCollector(
                    questionSet,
                    new SidebarItemsProvider(this.sidebarTreeBuilder(appraisal, questionSet, pagePartsSet)),
                    this.childQuestionValidator(appraisal, questionSet, validationErrors),
                    this.answerController(appraisal, questionSet),
                    this.questionEffectsInteractor(appraisal, questionSet),
                    this.pagePartValidationProvider(appraisal, questionSet, renderingContextType, validationErrors),
                    pagePartsSet,
                    this.v3SetDefinitionProvider(appraisal, questionSet)
                );
        }
        return this.volatileValidationMessagesFromTreeCollectors[key];
    }

    public genericErrorsValidator(appraisal: Appraisal, questionSet: QuestionSet): GenericErrorsValidator {
        if (this._genericErrorsValidator[appraisal.id] === undefined) {
            this._genericErrorsValidator[appraisal.id] = new DefaultGenericErrorsValidator([
                // Add new generic validators here
                new ValuationDateValidator(questionSet, this.appraisalProvider),
            ]);
        }
        return this._genericErrorsValidator[appraisal.id];
    }

    public validationConclusionProvider(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        pagePartsSet: PagePartsSet | null,
        validationErrors: ValidationError[],
        renderingContextType: RenderingContextType
    ): ValidationConclusionProvider {
        const key = `${appraisal.id}-${pagePartsSet?.id}`;
        if (this.validationConclusionProviders[key] === undefined) {
            this.validationConclusionProviders[key] = new DefaultValidationConclusionProvider(
                appraisal.id,
                questionSet,
                this.genericErrorsValidator(appraisal, questionSet),
                this.volatileValidationMessagesFromTreeCollector(
                    appraisal,
                    questionSet,
                    pagePartsSet,
                    validationErrors,
                    renderingContextType
                ),
                this._networkComponent.validationErrorApi,
                new TaskHelper(this._networkComponent.ajaxDriver),
                this._globalProvider
            );
        }
        return this.validationConclusionProviders[key];
    }

    public pagePartValidationProvider(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        renderingContextType: RenderingContextType,
        validationErrors: ValidationError[]
    ): PagePartValidationProvider {
        if (this.pagePartValidationProviders[appraisal.id] === undefined) {
            this.pagePartValidationProviders[appraisal.id] = new PagePartValidationProvider(
                questionSet,
                this.floorInteractor(appraisal, questionSet, renderingContextType),
                this.questionValidationMessagesProvider(appraisal, questionSet, validationErrors),
                this.answerController(appraisal, questionSet)
            );
        }
        return this.pagePartValidationProviders[appraisal.id];
    }

    public questionValidationMessagesProvider(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        validationErrors: ValidationError[]
    ): QuestionValidationMessagesProvider {
        if (this.questionValidationMessagesProviders[appraisal.id] === undefined) {
            this.questionValidationMessagesProviders[appraisal.id] = new QuestionValidationMessagesProvider(
                questionSet,
                this.answerController(appraisal, questionSet),
                this.childQuestionValidator(appraisal, questionSet, validationErrors),
                this.childQuestionValidationProvider(appraisal, questionSet, validationErrors),
                this.questionEffectsInteractor(appraisal, questionSet)
            );
        }
        return this.questionValidationMessagesProviders[appraisal.id];
    }

    public macroInteractor(questionSet: QuestionSet): MacroInteractor {
        if (this._macroInteractors[questionSet.id] === undefined) {
            this._macroInteractors[questionSet.id] = new DefaultMacroInteractor(
                questionSet,
                this._networkComponent.macroApi,
                this._globalProvider.global
            );
        }
        return this._macroInteractors[questionSet.id];
    }

    public userSettingsInteractor(appraisal: Appraisal, questionSet: QuestionSet) {
        if (this._userSettingsInteractors[questionSet.id] === undefined) {
            this._userSettingsInteractors[questionSet.id] = new DefaultUserSettingsInteractor(
                questionSet,
                this.answerController(appraisal, questionSet),
                this._globalProvider
            );
        }
        return this._userSettingsInteractors[questionSet.id];
    }

    public floorInteractor(appraisal: Appraisal, questionSet: QuestionSet, renderingContextType: RenderingContextType) {
        return (this._floorInteractors[questionSet.id] ??= new FloorInteractor(
            questionSet,
            this.userSettingsInteractor(appraisal, questionSet),
            this.answerController(appraisal, questionSet),
            this.answerPathStubber(appraisal, questionSet, renderingContextType),
            this.questionEffectsInteractor(appraisal, questionSet)
        ));
    }

    public searchInteractor(questionSet: QuestionSet): SearchInteractor {
        if (this._searchInteractors[questionSet.id] === undefined) {
            this._searchInteractors[questionSet.id] = new DefaultSearchInteractor(questionSet);
        }
        return this._searchInteractors[questionSet.id];
    }

    public userSettingsActivePagePartConfigurationIdInteractor(
        appraisal: Appraisal,
        questionSet: QuestionSet
    ): UserSettingsActivePagePartConfigurationInteractor {
        return (this._userSettingsActivePagePartConfigurationIdInteractor ??=
            new UserSettingsActivePagePartConfigurationInteractor(this.userSettingsInteractor(appraisal, questionSet)));
    }

    public pagePartsSetInteractor(appraisal: Appraisal, questionSet: QuestionSet): PagePartsSetInteractor {
        if (this._pagePartsSetInteractor === undefined) {
            this._pagePartsSetInteractor = new DefaultPagePartsSetInteractor(
                this._globalProvider,
                this.pagePartConfigurationsInteractor,
                this.pagePartsCleaner(questionSet),
                this.userSettingsActivePagePartConfigurationIdInteractor(appraisal, questionSet),
                this._networkComponent.pagePartConfigurationApi
            );
        }
        return this._pagePartsSetInteractor;
    }

    public compareValuesProvider(appraisal: Appraisal, questionSet: QuestionSet): CompareValuesProvider {
        if (this._compareValuesProviders[appraisal.id] === undefined) {
            this._compareValuesProviders[appraisal.id] = new DefaultCompareValuesProvider(
                questionSet,
                this.buildYearProvider(appraisal, questionSet),
                this.surfaceAreaProvider(appraisal, questionSet),
                this.volumeProvider(appraisal, questionSet),
                this.plotAreaProvider(appraisal, questionSet),
                this.v3ReferenceSetsProvider(appraisal, questionSet),
                this.energyLabelProvider(appraisal, questionSet),
                this.answerController(appraisal, questionSet),
                this.appraisalProvider
            );
        }
        return this._compareValuesProviders[appraisal.id];
    }

    public pagePartsCleaner(questionSet: QuestionSet): PagePartsCleaner {
        return (this._pagePartsCleaner ??= new PagePartsCleaner(questionSet));
    }

    get appraisalProvider(): AppraisalProvider {
        if (this._appraisalProvider === undefined) {
            this._appraisalProvider = new DefaultAppraisalProvider();
        }
        return this._appraisalProvider;
    }

    get answerLoader(): AnswerLoader {
        if (this._answerLoader === undefined) {
            this._answerLoader = new DefaultAnswerLoader(this._networkComponent.appraisalApi);
        }
        return this._answerLoader;
    }

    get pagePartConfigurationsInteractor(): PagePartConfigurationsInteractor {
        return (this._pagePartConfigurationsInteractor ??= new DefaultPagePartConfigurationsInteractor());
    }

    get networkStatusProvider(): NetworkStatusProvider {
        if (this._networkStatusProvider === undefined) {
            this._networkStatusProvider = new DefaultNetworkStatusProvider();
        }
        return this._networkStatusProvider;
    }

    public get imageOrientationFixer(): ImageOrientationFixer {
        return new ImageOrientationFixer();
    }

    public get userInteractor(): UserInteractor {
        if (this._userInteractor === undefined) {
            this._userInteractor = new DefaultUserInteractor(this._globalProvider);
        }
        return this._userInteractor;
    }

    public get flashMessageBroadcaster(): FlashMessageBroadcaster {
        if (this._flashMessageBroadcaster === undefined) {
            this._flashMessageBroadcaster = new DefaultFlashMessageBroadcaster();
        }
        return this._flashMessageBroadcaster;
    }

    @lazy()
    public get support(): SupportComponent {
        return new DefaultSupportComponent(this);
    }

    public locationProvider(history: History) {
        if (this._locationProvider === undefined) {
            this._locationProvider = new DefaultLocationProvider(history);
        }
        return this._locationProvider;
    }

    public get constructionCostController(): ConstructionCostController {
        if (this._constructionCostController === undefined) {
            this._constructionCostController = new DefaultConstructionCostController(
                new DefaultConstructionCostsApi(this._networkComponent.ajaxDriver)
            );
        }
        return this._constructionCostController;
    }

    public iteratorFilesAttachmentInteractor(
        appraisal: Appraisal,
        questionSet: QuestionSet
    ): IteratorFilesAttachmentInteractor {
        if (this._iteratorFilesAttachmentInteractor === undefined) {
            this._iteratorFilesAttachmentInteractor = new DefaultIteratorFilesAttachmentInteractor(
                this.answerController(appraisal, questionSet)
            );
        }
        return this._iteratorFilesAttachmentInteractor;
    }

    public textAIInteractor(appraisal: Appraisal, questionSet: QuestionSet): TextAIInteractor {
        if (this._textAIInteractor === undefined) {
            this._textAIInteractor = new DefaultTextAIInteractor(
                this._globalProvider,
                appraisal,
                questionSet,
                this._networkComponent.textAIApi,
                this.textAIAnswerContextBuilder(appraisal, questionSet),
                this.textAIAppraisalContextBuilder,
                this.textAITransitievisieWarmteContextBuilder(appraisal, questionSet),
                this.appraiseSecondaryConfigStackInteractor
            );
        }
        return this._textAIInteractor;
    }

    public textAIAnswerContextBuilder(appraisal: Appraisal, questionSet: QuestionSet): TextAIContextBuilder {
        if (this._textAIAnswerContextBuilder === undefined) {
            this._textAIAnswerContextBuilder = new DefaultTextAIAnswerContextBuilder(
                questionSet,
                this.answerController(appraisal, questionSet),
                this.questionEffectsInteractor(appraisal, questionSet)
            );
        }
        return this._textAIAnswerContextBuilder;
    }

    public textAIReferenceObjectContextBuilder(appraisal: Appraisal, questionSet: QuestionSet): TextAIContextBuilder {
        if (this._textAIReferenceObjectContextBuilder === undefined) {
            this._textAIReferenceObjectContextBuilder = new DefaultTextAIReferenceObjectContextBuilder(
                this.v3ReferenceSetsProvider(appraisal, questionSet),
                new DefaultExplanationDataProvider(
                    appraisal,
                    this.buildYearProvider(appraisal, questionSet),
                    this.valuationProvider(appraisal, questionSet)
                ),
                this.answerController(appraisal, questionSet),
                questionSet
            );
        }
        return this._textAIReferenceObjectContextBuilder;
    }

    public get textAIAppraisalContextBuilder(): TextAIContextBuilder {
        if (this._textAIAppraisalContextBuilder === undefined) {
            this._textAIAppraisalContextBuilder = new DefaultTextAIAppraisalContextBuilder(this.appraisalProvider);
        }
        return this._textAIAppraisalContextBuilder;
    }

    public automatorInteractor(appraisal: Appraisal, questionSet: QuestionSet): AutomatorInteractor {
        if (this._automatorInteractor === undefined) {
            this._automatorInteractor = new DefaultAutomatorInteractor(
                this._networkComponent.appraisalApi,
                new TaskHelper(this._networkComponent.ajaxDriver),
                this.answerInteractor(appraisal, questionSet)
            );
        }
        return this._automatorInteractor;
    }

    public textAITransitievisieWarmteContextBuilder(
        appraisal: Appraisal,
        questionSet: QuestionSet
    ): TextAIContextBuilder {
        if (this._textAITransitievisieWarmteContextBuilder === undefined) {
            this._textAITransitievisieWarmteContextBuilder = new DefaultTextAITransitievisieWarmteContextBuilder(
                questionSet,
                this.answerController(appraisal, questionSet),
                this._globalProvider
            );
        }
        return this._textAITransitievisieWarmteContextBuilder;
    }

    get distanceProvider(): DistanceProvider {
        if (this._distanceProvider === undefined) {
            this._distanceProvider = new DefaultDistanceProvider(this.appraisalProvider);
        }

        return this._distanceProvider;
    }

    @lazy()
    public get appraiseSecondaryConfigStackInteractor(): AppraiseSecondaryConfigStackInteractor {
        return new DefaultAppraiseSecondaryConfigStackInteractor();
    }

    @lazy()
    public get modalConfigStackInteractor(): ModalConfigStackInteractor {
        return new DefaultModalConfigStackInteractor();
    }

    @lazy()
    public get modalOrSecondaryConfigStackInteractor(): ModalOrSecondaryConfigStackInteractor {
        return new ModalOrSecondaryConfigStackInteractor(
            this.modalConfigStackInteractor,
            this.appraiseSecondaryConfigStackInteractor
        );
    }
}
