import {observer} from 'mobx-react';
import React from 'react';
import {Loader} from '../../../appraising/appraise/ui/components/loader';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {Images} from './images';
import {WidgetPresenter} from './widget_presenter';
import {WordCloud} from './word_cloud';

export const Widget: React.FC = observer(() => {
    const presenter = usePresenter(
        (component) => new WidgetPresenter(component.business.appraisalProvider, component.network.appraisalApi)
    );

    return (
        <div className="card pre-check-widget">
            <div className="photos">
                <div className="address">
                    <span className="icon-pin" />
                    {presenter.appraisal?.address} {presenter.appraisal?.houseNumber}
                    {presenter.appraisal?.letter}, {presenter.appraisal?.postalCode} {presenter.appraisal?.city}
                </div>
                <Images />
            </div>
            <div className="object-details">
                {presenter.objectSummary ? (
                    <>
                        {presenter.objectSummary.objectType && (
                            <span className="object-type">{presenter.objectSummary.objectType}</span>
                        )}
                        <div className="d-flex justify-content-between mt-4 mb-3 object-properties">
                            {presenter.objectSummary.buildYear ? (
                                <div className="col-3 d-flex justify-content-center">
                                    <span className={'badge badge-large d-flex align-items-center'}>
                                        <span className="c-icon-build-year pr-1" />
                                        <span>{presenter.objectSummary.buildYear}</span>
                                    </span>
                                </div>
                            ) : null}
                            {presenter.objectSummary.surfaceArea ? (
                                <div className="col-3 d-flex justify-content-center">
                                    <span className={'badge badge-large d-flex align-items-center'}>
                                        <span className="c-icon-floor-area pr-1" />
                                        <span>{presenter.objectSummary.surfaceArea} m²</span>
                                    </span>
                                </div>
                            ) : null}
                            {presenter.objectSummary.plotArea ? (
                                <div className="col-3 d-flex justify-content-center">
                                    <span className={'badge badge-large d-flex align-items-center'}>
                                        <span className="c-icon-plot-area pr-1" />
                                        <span>{presenter.objectSummary.plotArea} m²</span>
                                    </span>
                                </div>
                            ) : null}
                            {presenter.objectSummary.energyLabel ? (
                                <div className="col-3 d-flex justify-content-center">
                                    <span className={'badge badge-large d-flex align-items-center'}>
                                        <span className="ion-md-flash pr-1" style={{fontSize: 30}} />
                                        {presenter.objectSummary.energyLabel ?? '-'}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                        <WordCloud words={presenter.objectSummary.keywords ?? []} />
                    </>
                ) : (
                    <div className="d-flex justify-content-center">
                        <Loader isSmall />
                    </div>
                )}
            </div>
        </div>
    );
});
