import {computed, makeObservable, observable} from 'mobx';
import {distinctUntilChanged, first, map, switchMap} from 'rxjs/operators';

import {AnswerController} from '../../../../business/answering/answer_controller';
import {ChildQuestionValidationProvider} from '../../../../business/validation/child_validation_provider';
import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {Presenter} from '../../../../../support/presenter/presenter';
import {Question} from '../../../../models/question';
import {QuestionSet} from '../../../../models/question_set';
import {ValidationMessage} from '../../../../business/validation/validation_message';
import {findChildrenForQuestion} from '../../support/question_filtering';
import {getNewestAnswer} from '../../../../../support/get_newest_answer';

export class BuildingCostsAdvicePresenter implements Presenter {
    @observable.ref public question: Question | undefined;
    @observable.ref public validationMessages: ValidationMessage[] = [];

    @computed
    public get children(): Question[] {
        const question = this.question;
        if (question === undefined) {
            return [];
        }
        return findChildrenForQuestion(question, this.questionSet);
    }

    private subscriptions = new CompositeSubscription();

    constructor(
        private uuid: string | null,
        private parentAnswerUuid: string | null,
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private childValidationProvider: ChildQuestionValidationProvider
    ) {
        makeObservable(this);
    }

    public mount(): void {
        if (this.uuid === null) {
            return;
        }
        const question = this.questionSet.findQuestionByUuid(this.uuid);
        if (question === undefined) {
            return;
        }

        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidAndParentAnswerUuidStream(question.uuid, this.parentAnswerUuid)
                .pipe(
                    map((answers) => getNewestAnswer(answers)?.uuid ?? null),
                    distinctUntilChanged(),
                    switchMap((answerUuid) =>
                        this.childValidationProvider.validate(
                            question.uuid,
                            answerUuid === null ? answerUuid : answerUuid
                        )
                    ),
                    //We are not interested in any "new" validationMessages since only the
                    //`validation_institute_validation` sets the `messages` array
                    first()
                )
                .subscribe((validationMessages) => (this.validationMessages = validationMessages))
        );

        this.question = question;
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
