import {Presenter} from '../../../../../../support/presenter/presenter';
import {Question} from '../../../../../models/question';
import {SearchInteractor} from '../../../../../business/search_interactor';
import {action} from 'mobx';

export class SearchPresenter implements Presenter {
    constructor(private question: Question, private searchInteractor: SearchInteractor) {}

    public mount(): void {
        //Noop
    }

    public unmount(): void {
        //Noop
    }

    @action
    public onChange(value: string) {
        if (!this.question.parentUuid) {
            return;
        }

        this.searchInteractor.search(value, this.question.uuid);
    }
}
