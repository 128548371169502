import {NormalQuestionType, QuestionType} from '../appraising/enum/question_type';

export function isAnswerOptionQuestionType(questionType: QuestionType) {
    return (
        [
            NormalQuestionType.MC,
            NormalQuestionType.MC_SELECT,
            NormalQuestionType.MC_SELECT_OPTIONAL,
            NormalQuestionType.MC_SELECT_COMPARE,
            NormalQuestionType.CHECKLIST,
            NormalQuestionType.HIDDEN,
            NormalQuestionType.SURFACE_METHOD_SELECT,
        ] as QuestionType[]
    ).includes(questionType);
}
