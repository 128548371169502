import React, {useContext} from 'react';

import {classNames} from '../../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {ImageViewerContext} from '../../../../../../components/image_viewer/image_viewer_context';

interface OwnProps {
    url: string;
    isLoading: boolean;
    showRetryButton: boolean;
    isDisabled: boolean;
}

export const PhotoQuestionImage: React.FunctionComponent<OwnProps> = observer(function PhotoQuestionImage(props) {
    const context = useContext(ImageViewerContext);

    const {url, isLoading, showRetryButton, isDisabled} = props;

    return (
        <img
            src={url}
            loading="lazy"
            className={classNames(
                {
                    opaque: isLoading || showRetryButton || isDisabled,
                },
                {
                    blurred: isLoading || showRetryButton,
                }
            )}
            onClick={(e) => {
                e.preventDefault();
                context.open(e.target as HTMLImageElement);
            }}
        />
    );
});
