import {ReferenceObjectAnswer, referenceObjectAnswerFromSale} from '../models/reference_object_answer';
import {computed, makeObservable, observable} from 'mobx';

import {Presenter} from '../../../../../../../../../support/presenter/presenter';
import {ReferenceObject} from '../models/reference_object';
import {ReferenceObjectProvider} from '../../../../../../../../business/reference_object_provider';
import {AppraisalValidationType} from '../../../../../../../../enum/appraisal_validation_type';
import {AppraisalState} from '../../../../../../../../enum/appraisal_state';
import {Appraisal} from '../../../../../../../../models/appraisal';
import {SetType} from '../../../../../../../../models/reference_set/set_type';

export class ReferenceObjectTilePresenter implements Presenter {
    @observable public isAdding = false;

    constructor(
        private referenceObject: ReferenceObject,
        private onAddReferenceSale: (
            referenceObjectAnswer: ReferenceObjectAnswer,
            referenceObject?: ReferenceObject
        ) => Promise<void>,
        private referenceObjectInteractor: ReferenceObjectProvider,
        private appraisal: Appraisal
    ) {
        makeObservable(this);
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    @computed
    public get canDelete(): boolean {
        if (this.appraisal.isEditableAppraisal) {
            return true;
        }
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return !this.appraisal.isFrozen;
        }
        return !(
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    public async onAdd() {
        try {
            this.isAdding = true;
            const sale = await this.referenceObjectInteractor.getSale(this.referenceObject.id, SetType.SOLD);
            await this.onAddReferenceSale(referenceObjectAnswerFromSale(sale), this.referenceObject);
        } finally {
            this.isAdding = false;
        }
    }
}
