import {observer} from 'mobx-react';
import React from 'react';
import {isNumeric} from '../../../../../../../support/util';
import {BuildYearBadge} from '../../../../../../components/badges/build_year_badge';
import {FloorAreaBadge} from '../../../../../../components/badges/floor_area_badge';
import {PlotAreaBadge} from '../../../../../../components/badges/plot_area_badge';
import {VolumeBadge} from '../../../../../../components/badges/volume_badge';
import {IntCompareSelectorType} from '../../../../../../enum/compare_selector_type';
import {Question} from '../../../../../../models/question';
import {IntCompareQuestionPresenter} from './int_compare_question_presenter';

export const IntCompareQuestionBadge = observer(
    ({
        presenter,
        question,
        compact,
    }: {
        presenter: IntCompareQuestionPresenter;
        question: Question;
        compact?: boolean;
    }) => {
        if (
            presenter.answer?.contents &&
            isNumeric(presenter.answer.contents) &&
            presenter.compareValue &&
            isNumeric(presenter.compareValue)
        ) {
            const currentValue = parseInt(presenter.answer.contents, 10);
            switch (question.compareSelector) {
                case IntCompareSelectorType.BUILDYEAR:
                case IntCompareSelectorType.SPECIAL_BUILDYEAR:
                    return (
                        <BuildYearBadge
                            buildYear={currentValue}
                            referenceBuildYear={presenter.compareValue}
                            compact={compact}
                        />
                    );
                case IntCompareSelectorType.SURFACE_AREA:
                case IntCompareSelectorType.SPECIAL_SURFACE_AREA:
                    return (
                        <FloorAreaBadge
                            floorArea={currentValue}
                            referenceFloorArea={presenter.compareValue}
                            compact={compact}
                        />
                    );
                case IntCompareSelectorType.VOLUME:
                case IntCompareSelectorType.SPECIAL_VOLUME:
                    return (
                        <VolumeBadge volume={currentValue} referenceVolume={presenter.compareValue} compact={compact} />
                    );
                case IntCompareSelectorType.PLOT_AREA:
                case IntCompareSelectorType.SPECIAL_PLOT_AREA:
                    return (
                        <PlotAreaBadge
                            plotArea={currentValue}
                            referencePlotArea={presenter.compareValue}
                            compact={compact}
                        />
                    );
                default:
                    return null;
            }
        }

        return null;
    }
);
