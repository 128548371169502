import React from 'react';
import {
    ImageViewerComponent,
    ImageViewerComponentProps,
} from '../../../../../../../components/image_viewer/image_viewer';
import {usePresenter} from '../../../../../../../../support/presenter/use_presenter';
import {EditableImageViewerComponentPresenter} from './editable_image_viewer_component_presenter';

interface OwnProps {
    isEditable: boolean;
}

function EditableImageViewerButton({lightbox, isEditable}: ImageViewerComponentProps & OwnProps) {
    const presenter = usePresenter(
        (component) => new EditableImageViewerComponentPresenter(component.business.modalConfigStackInteractor)
    );

    return (
        <button onClick={() => lightbox && presenter.openModal(lightbox, isEditable)}>
            <span className="ion-md-create"></span>
        </button>
    );
}

export const getEditableImageViewerComponent: (props: OwnProps) => ImageViewerComponent = (props) => ({
    name: 'rotate-image',
    className: 'pwsp-menu-button',
    order: 9,
    component: <EditableImageViewerButton {...props} />,
});
