import * as React from 'react';

import {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';
import {classNames} from '../../../../support/classnames';

interface OwnProps {
    uuid: string;
    pagePartsConfigurationId: number;
    icon: string;
    label: string;
    isActive: boolean;
    children: React.ReactNode;
    onDelete: (uuid: string) => void;
}

export const RootSidebarItem: FunctionComponent<OwnProps> = ({
    uuid,
    pagePartsConfigurationId,
    icon,
    label,
    isActive,
    children,
    onDelete,
}) => {
    return (
        <div className="child-item card-progress-large">
            <div className="card-progress-content">
                <Link
                    to={`/page-parts-configuration/${pagePartsConfigurationId}/${uuid}`}
                    className={classNames(icon, {
                        active: isActive,
                    })}
                >
                    <span className="title">{label}</span>
                </Link>
                <button
                    className="btn-delete-page-part btn btn-delete btn-sm ion-md-trash"
                    onClick={() => onDelete(uuid)}
                />
            </div>
            <div className={classNames('sub', {active: isActive})}>{children}</div>
        </div>
    );
};
