import PhotoSwipeLightbox from 'photoswipe/dist/types/lightbox/lightbox';
import {Presenter} from '../../../../../../../../support/presenter/presenter';
import {ModalConfigStackInteractor} from '../../../../../../../business/modal_config_stack_interactor';
import {ModalType} from '../../../../../../../models/modal_config';
import {Answer} from '../../../../../../../models/answer';

interface ImageDataWithAnswer extends Omit<ImageData, 'data'> {
    data: {
        url: string;
        uncompressedUrl?: string;
        answer: Answer;
    };
}

export class EditableImageViewerComponentPresenter implements Presenter {
    constructor(private modalConfigStackInteractor: ModalConfigStackInteractor) {}

    public mount(): void {
        // Noop
    }

    public openModal(lightbox: PhotoSwipeLightbox, isEditable: boolean) {
        const currentSlide = lightbox.pswp?.currSlide?.data as ImageDataWithAnswer;
        if (!currentSlide || !currentSlide?.data?.answer) {
            return;
        }

        const answer = currentSlide.data.answer;

        this.modalConfigStackInteractor.insert({
            id: 'editable-image-viewer-modal',
            type: ModalType.EDITABLE_IMAGE,
            answer,
            isEditable,
        });

        lightbox.pswp?.close();
    }
}
