import * as React from 'react';

import {Appraisal} from '../../../../../../../../../../../models/appraisal';
import {AppraiseModel, isAppraiseModel} from '../../../../../../../../../../../enum/appraise_model';
import {DateInput} from '../../../inputs/date';
import {FunctionComponent} from 'react';
import {NumberWithCheckboxInput} from '../../../inputs/number_with_checkbox';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';
import {isEmpty} from '../../../../../../../../../../../../support/util';

interface OwnProps {
    appraisal: Appraisal;
    validatesDatesMessage: string | null;
    referenceObjectAnswer: ReferenceObjectAnswer;
    onVerkoopdatumChange: (value: string) => void;
    onTransportdatumChange: (value: string) => void;
    onVerkochtBinnenChange: (value: string) => void;
    isFrozen: boolean;
}

export const SellDate: FunctionComponent<OwnProps> = ({
    appraisal,
    validatesDatesMessage,
    referenceObjectAnswer,
    onVerkoopdatumChange,
    onTransportdatumChange,
    onVerkochtBinnenChange,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;
    return (
        <div className="row">
            <div className="col-12">
                <dl>
                    <span className="row">
                        <span className="col-sm-6">
                            <DateInput
                                name="verkoopperiode"
                                label="Datum transactie"
                                value={validationInstituteReferentieObject.verkoopdatum}
                                onChange={onVerkoopdatumChange}
                                disabled={isFrozen}
                            />
                        </span>
                        {/* This field is only required in model 2021 */}
                        {isAppraiseModel(appraisal, AppraiseModel.MODEL2021) && (
                            <span className="col-sm-6">
                                <DateInput
                                    name="transportdatum"
                                    label="Transportdatum"
                                    value={validationInstituteReferentieObject.transportdatum ?? ''}
                                    onChange={onTransportdatumChange}
                                    disabled={isFrozen}
                                />
                            </span>
                        )}
                        {!isEmpty(validatesDatesMessage) ? (
                            <div className="col-12">
                                <span className="invalid-feedback">{validatesDatesMessage}</span>
                            </div>
                        ) : null}
                        <span className="col-sm-6">
                            <NumberWithCheckboxInput
                                isRequired
                                name="verkochtbinnen"
                                label="Verkooptermijn (in dagen)"
                                value={Number(validationInstituteReferentieObject.verkochtBinnen)}
                                checkboxValue="Onbekend"
                                checkboxLabel="Onbekend"
                                checkboxChecked={validationInstituteReferentieObject.verkochtBinnen === 'Onbekend'}
                                onChange={onVerkochtBinnenChange}
                                disabled={isFrozen}
                            />
                        </span>
                    </span>
                </dl>
            </div>
        </div>
    );
};
