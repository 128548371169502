interface ObservableSubscription {
    unsubscribe(): void;
}

type Disposable = () => void;

type DisposableTypes = ObservableSubscription | Disposable;

export class CompositeSubscription {
    private _list: DisposableTypes[] = [];

    public add(disposable: DisposableTypes | DisposableTypes[]) {
        if (Array.isArray(disposable)) {
            this._list = [...this._list, ...disposable];
        } else {
            this._list.push(disposable);
        }
    }

    public clear() {
        this._list.forEach((disposable) => {
            if ('unsubscribe' in disposable) {
                disposable.unsubscribe();
            } else {
                disposable();
            }
        });

        this._list = [];
    }
}
