import {ReferenceSaleSetRequestData} from '../../../../../../../business/reference_object_provider';

export function hashSetDefinition(data: ReferenceSaleSetRequestData) {
    return [
        data.appraisalId,
        data.valuationTypeUuid,
        data.buildYear ?? 0,
        data.livingArea ?? 0,
        data.plotArea,
        JSON.stringify(data.filters ?? {}),
        JSON.stringify(data.forceUseSubscriptions ?? []),
    ].join('-');
}
