import {AppraisalProvider} from '../appraisal_provider';

export class AppraisalFieldResolver {
    constructor(private _appraisalProvider: AppraisalProvider) {}

    public resolve(appraisalField: string) {
        switch (appraisalField) {
            case 'appraisal.estimated_object_type':
            case 'appraisal.object_display_type':
                return this._appraisalProvider.appraisal.objectDisplayType;
            case 'appraisal.object_type':
                return this._appraisalProvider.appraisal.objectType;
            case 'appraisal.status':
                return this._appraisalProvider.appraisal.status.toString();
            case 'appraisal.appraisal_goal':
                return this._appraisalProvider.appraisal.appraisalGoal.toString();
            case 'appraisal.validation_type':
                return this._appraisalProvider.appraisal.validationType.toString();
            case 'appraisal.improvements_planned':
                return this._appraisalProvider.appraisal.improvementsPlanned ? '1' : '0';
            case 'appraisal.recently_sold':
                return this._appraisalProvider.appraisal.recentlySold ? '1' : '0';
            case 'appraisal.new_construction':
                return this._appraisalProvider.appraisal.newConstruction ? '1' : '0';
            case 'appraisal.is_woco':
                return this._appraisalProvider.appraisal.isWoco ? '1' : '0';
            case 'appraisal.object_usage_type':
                return this._appraisalProvider.appraisal.objectUsageType.value;
            case 'appraisal.object_ownership_type':
                return this._appraisalProvider.appraisal.objectOwnershipType.value;
            case 'appraisal.appraiser_is_prior_appraiser':
                return this._appraisalProvider.appraisal.appraiserIsPriorAppraiser ? '1' : '0';
        }
        return null;
    }
}
