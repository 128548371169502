import {AnswerController} from './answer_controller';
import {AnswerOption} from '../../models/answer_option';
import {AnswerTouchState} from '../../enum/answer_touch_state';
import {NormalQuestionType} from '../../enum/question_type';
import {Question} from '../../models/question';
import {QuestionSet} from '../../models/question_set';

export interface AnswerByLabelSubmitter {
    submit(label: string): Promise<void>;
}

export class DefaultAnswerByLabelSubmitter implements AnswerByLabelSubmitter {
    constructor(private questionSet: QuestionSet, private answerController: AnswerController) {}

    public async submit(label: string): Promise<void> {
        const questions = this.questionSet.findQuestionsByKeyword(label);
        for (const question of questions) {
            if (question.type === NormalQuestionType.BOOLEAN) {
                this.submitBoolean(question);
            } else if (
                question.type === NormalQuestionType.MC ||
                question.type === NormalQuestionType.MC_SELECT ||
                question.type === NormalQuestionType.MC_SELECT_OPTIONAL
            ) {
                this.submitAnswerOption(question, label);
            }
        }
    }

    private submitAnswerOption(question: Question, label: string) {
        const answerOption: AnswerOption | undefined = question.answerOptions.find((a) => {
            const classNameFormatted = label.toLocaleLowerCase().trim();
            if (a.labels !== null) {
                const isMatch: boolean = a.labels.toLocaleLowerCase().trim().includes(classNameFormatted);
                if (isMatch) {
                    return isMatch;
                }
            }
            return a.contents.toLocaleLowerCase().trim() === classNameFormatted;
        });

        if (answerOption) {
            const answers = this.answerController.answersForQuestionUuid(question.uuid);
            for (const answer of answers) {
                this.answerController.onAnswerOptionChange(answer.uuid, answerOption.id, AnswerTouchState.TOUCHED);
            }
        }
    }

    private submitBoolean(question: Question) {
        const answers = this.answerController.answersForQuestionUuid(question.uuid);
        for (const answer of answers) {
            this.answerController.onContentsChange(answer.uuid, '1', AnswerTouchState.TOUCHED);
        }
    }
}
