import {computed, makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../../../../../support/composite_subscription';
import {AnswerController} from '../../../../../../business/answering/answer_controller';
import {FloorQuestionType, NormalQuestionType} from '../../../../../../enum/question_type';
import {TechnicalReference} from '../../../../../../enum/technical_reference';
import {Answer} from '../../../../../../models/answer';
import {Question} from '../../../../../../models/question';
import {QuestionSet} from '../../../../../../models/question_set';
import {QuestionEffectInteractor} from '../../../../../../business/conditions/question_effects_interactor';
import {AnswerOption} from '../../../../../../models/answer_option';
import {isEmpty} from '../../../../../../../support/util';
import {BuildYearProvider} from '../../../../../../business/build_year_provider';
import {Presenter} from '../../../../../../../support/presenter/presenter';

export class EnergeticOverviewPresenter implements Presenter {
    private subscriptions = new CompositeSubscription();

    private groups: Record<string, (EnergeticOverviewGroup | null)[]>;

    @observable.ref private answers: Answer[] = [];

    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor,
        private buildYearProvider: BuildYearProvider
    ) {
        makeObservable(this);

        this.groups = {
            Isolatie: [
                this.getSimpleGroup(TechnicalReference.ENERGETIC_WALL_INSULATION, 'Gevelisolatie'),
                this.getSimpleGroup(TechnicalReference.ENERGETIC_WALL_PANELS, 'Gevelpanelen'),
                this.getSimpleGroup(TechnicalReference.ENERGETIC_ANGLED_ROOF_INSULATION, 'Dakisolatie hellend dak'),
                this.getSimpleGroup(TechnicalReference.ENERGETIC_FLAT_ROOF_INSULATION, 'Dakisolatie plat dak'),

                this.getSimpleGroup(TechnicalReference.ENERGETIC_FLOOR_INSULATION, 'Vloerisolatie', true),
                this.getSimpleGroup(TechnicalReference.ENERGETIC_GLASS, 'Glas', true),
                this.getSimpleGroup(TechnicalReference.ENERGETIC_PIPE_INSULATION, 'Leidingisolatie'),
                this.getSimpleGroup(TechnicalReference.ENERGETIC_FRAMES, 'Energiezuinige kozijnen'),
            ],
            Installaties: [
                this.getInstallationGroup(TechnicalReference.ENERGETIC_INSTALLATION, 'Verwarmingsinstallatie'),
                this.getSimpleGroup(TechnicalReference.ENERGETIC_HEATING_SYSTEM, 'Verwarmingssysteem', true),
                this.getInstallationGroup(TechnicalReference.ENERGETIC_WARM_WATER_INSTALLATION, 'Warmwaterinstallatie'),
                this.getBooleanGroup(TechnicalReference.ENERGETIC_HEAT_RECOVERING_SHOWER, 'Douche-warmteterugwinning'),
                this.getBooleanGroup(TechnicalReference.ENERGETIC_SOLAR_BOILER, 'Zonneboiler'),
                this.getSimpleGroup(TechnicalReference.ENERGETIC_VENTILATION, 'Ventilatie'),
                this.getSimpleGroup(TechnicalReference.ENERGETIC_COOLING, 'Koeling'),
            ],
            Energieopwekking: [
                this.getEnergyGroup(TechnicalReference.ENERGETIC_SOLAR, 'Zonnepanelen'),
                this.getEnergyGroup(TechnicalReference.ENERGETIC_WIND, 'Wind'),
                this.getEnergyGroup(TechnicalReference.ENERGETIC_OTHER_ENERGY, 'Overige energieopwekking'),
            ],
        };
    }

    public mount() {
        const questionUuids = Object.values(this.groups)
            .flat()
            .flatMap((group) => {
                if (!group) return [];

                switch (group.type) {
                    case 'simple':
                        return [
                            ...group.availabilityQuestions.map((q) => q.uuid),
                            ...group.installMomentQuestions.map((q) => q.uuid),
                            ...group.installYearQuestions.map((q) => q.uuid),
                            ...group.notesQuestions.map((q) => q.uuid),
                        ];
                    case 'installation':
                        return [
                            group.availabilityQuestion.uuid,
                            ...group.installYearQuestions.map((q) => q.uuid),
                            ...group.notesQuestions.map((q) => q.uuid),
                        ];
                    case 'boolean':
                        return group.availabilityQuestions.map((q) => q.uuid);
                    case 'energy':
                        return [
                            group.availabilityQuestion.uuid,
                            ...group.installMomentQuestions.map((q) => q.uuid),
                            ...group.installYearQuestions.map((q) => q.uuid),
                            ...group.notesQuestions.map((q) => q.uuid),
                            ...group.solarInputMethodQuestions.map((q) => q.uuid),
                            ...group.solarAmountQuestions.map((q) => q.uuid),
                            ...group.solarOrientationQuestions.map((q) => q.uuid),
                            ...group.ownershipQuestions.map((q) => q.uuid),
                        ];
                }
            });

        this.subscriptions.add(
            this.answerController.answersForQuestionUuidsStream(questionUuids).subscribe((answers) => {
                runInAction(() => {
                    this.answers = answers.filter(
                        (answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.parentUuid)
                    );
                });
            })
        );
    }

    @computed
    public get filledGroups() {
        const filled: Record<string, FilledGroup[]> = {};

        for (const [key, groups] of Object.entries(this.groups)) {
            filled[key] = groups.flatMap((group) => {
                if (!group) {
                    return [];
                }

                switch (group.type) {
                    case 'simple':
                        return this.mapSimpleGroup(group);
                    case 'installation':
                        return [this.mapInstallationGroup(group)];
                    case 'boolean':
                        return [this.mapBooleanGroup(group)];
                    case 'energy':
                        return [this.mapEnergyGroup(group)];
                }
            });
        }

        return filled;
    }

    public unmount() {
        this.subscriptions.clear();
    }

    private getSimpleGroup(
        technicalReference: TechnicalReference,
        label: string,
        perFloor = false
    ): SimpleEnergeticOverviewGroup {
        const availabilityQuestions = this.questionSet.findQuestionsByTechnicalReference(technicalReference);

        const installMomentQuestions = this.getMatchingGroupQuestion(
            availabilityQuestions,
            (question) => question.technicalReference === TechnicalReference.ENERGETIC_INSTALL_MOMENT
        );
        const installYearQuestions = this.getMatchingGroupQuestion(
            availabilityQuestions,
            (question) => question.technicalReference === TechnicalReference.ENERGETIC_INSTALL_YEAR
        );
        const notesQuestions = this.getMatchingGroupQuestion(
            availabilityQuestions,
            (question) => question.technicalReference === TechnicalReference.ENERGETIC_NOTES
        );

        return {
            type: 'simple',
            label,
            perFloor,
            availabilityQuestions,
            installMomentQuestions,
            installYearQuestions,
            notesQuestions,
        };
    }

    private getInstallationGroup(
        technicalReference: TechnicalReference,
        label: string
    ): InstallationEnergeticOverviewGroup | null {
        const availabilityQuestion = this.questionSet.findQuestionByTechnicalReference(technicalReference);

        if (!availabilityQuestion || availabilityQuestion.parentUuid === null) {
            return null;
        }

        const buildingCostGroupChildQuestions = this.questionSet
            .findChildQuestionsByParentUuid(availabilityQuestion.parentUuid)
            .filter((c) => c.type === NormalQuestionType.BUILDING_COSTS_GROUP)
            .flatMap((c) => this.questionSet.findChildQuestionsByParentUuid(c.uuid));

        const installYearQuestions = buildingCostGroupChildQuestions.filter((c) => c.type === NormalQuestionType.YEAR);
        const notesQuestions = buildingCostGroupChildQuestions.filter((c) => c.type === NormalQuestionType.OPEN);

        return {
            type: 'installation',
            label,
            availabilityQuestion,
            installYearQuestions,
            notesQuestions,
        };
    }

    private getBooleanGroup(technicalReference: TechnicalReference, label: string): BooleanEnergeticOverviewGroup {
        const availabilityQuestions = this.questionSet.findQuestionsByTechnicalReference(technicalReference);

        return {
            type: 'boolean',
            label,
            availabilityQuestions,
        };
    }

    private getEnergyGroup(technicalReference: TechnicalReference, label: string): EnergyEnergeticOverviewGroup | null {
        const availabilityQuestion = this.questionSet.findQuestionByTechnicalReference(technicalReference);

        if (!availabilityQuestion || availabilityQuestion.parentUuid === null) {
            return null;
        }

        const groupQuestion = this.questionSet.findQuestionByUuid(availabilityQuestion.parentUuid);

        if (!groupQuestion) {
            return null;
        }

        const installMomentQuestion = this.questionSet.findChildByPredicateRecursive(
            groupQuestion,
            (child) => child.technicalReference === TechnicalReference.ENERGETIC_INSTALL_MOMENT
        );
        const installYearQuestion = this.questionSet.findChildByPredicateRecursive(
            groupQuestion,
            (child) => child.technicalReference === TechnicalReference.ENERGETIC_INSTALL_YEAR
        );
        const notesQuestion = this.questionSet.findChildByPredicateRecursive(
            groupQuestion,
            (child) => child.technicalReference === TechnicalReference.ENERGETIC_NOTES
        );
        const ownershipQuestion = this.questionSet.findChildByPredicateRecursive(
            groupQuestion,
            (child) => child.technicalReference === TechnicalReference.ENERGETIC_OWNERSHIP
        );

        const solarInputMethodQuestion = this.questionSet.findChildByPredicateRecursive(
            groupQuestion,
            (child) => child.technicalReference === TechnicalReference.ENERGETIC_SOLAR_INPUT_METHOD
        );

        const solarAmountQuestion = this.questionSet.findChildByPredicateRecursive(
            groupQuestion,
            (child) => child.technicalReference === TechnicalReference.ENERGETIC_SOLAR_AMOUNT
        );

        const solarOrientationQuestion = this.questionSet.findChildByPredicateRecursive(
            groupQuestion,
            (child) => child.technicalReference === TechnicalReference.ENERGETIC_SOLAR_ORIENTATION
        );

        return {
            type: 'energy',
            label,
            availabilityQuestion,
            installMomentQuestions: installMomentQuestion ? [installMomentQuestion] : [],
            installYearQuestions: installYearQuestion ? [installYearQuestion] : [],
            ownershipQuestions: ownershipQuestion ? [ownershipQuestion] : [],
            notesQuestions: notesQuestion ? [notesQuestion] : [],
            solarInputMethodQuestions: solarInputMethodQuestion ? [solarInputMethodQuestion] : [],
            solarAmountQuestions: solarAmountQuestion ? [solarAmountQuestion] : [],
            solarOrientationQuestions: solarOrientationQuestion ? [solarOrientationQuestion] : [],
        };
    }

    private getMatchingGroupQuestion(baseQuestions: Question[], predicate: (question: Question) => boolean) {
        return baseQuestions
            .map((baseQuestion) => {
                if (baseQuestion.parentUuid === null) {
                    return null;
                }

                const matches = this.questionSet
                    .findChildQuestionsByParentUuid(baseQuestion.parentUuid)
                    .filter((child) => predicate(child))
                    // These questions should always appear after the base question
                    .filter((child) => child.order > baseQuestion.order)
                    .sort((a, b) => {
                        const aDistance = Math.abs(a.order - baseQuestion.order);
                        const bDistance = Math.abs(b.order - baseQuestion.order);

                        return aDistance - bDistance;
                    });

                return matches.length > 0 ? matches[0] : null;
            })
            .filter((q): q is Question => q !== null);
    }

    private mapSimpleGroup(group: SimpleEnergeticOverviewGroup, answers?: Answer[]): FilledGroup[] {
        if (group.perFloor && !answers) {
            const byFloor = this.groupAnswersByFloor(this.answers);

            const groups: FilledGroup[] = [];

            for (const [key, answers] of Object.entries(byFloor)) {
                const mappedGroups = this.mapSimpleGroup(group, answers);

                mappedGroups.forEach((g) => {
                    g.label = `${g.label} ${key}e woonlaag`;
                });

                groups.push(...mappedGroups);
            }

            return groups;
        }

        return [
            {
                label: group.label,
                availability: this.mapAvailability(group.availabilityQuestions, answers ?? this.answers),
                description: null,
                installMoment: this.mapInstallMoment(
                    group.installMomentQuestions,
                    group.installYearQuestions,
                    answers ?? this.answers
                ),
                notes: this.mapNotes(group.notesQuestions, answers ?? this.answers),
            },
        ];
    }

    private mapInstallationGroup(group: InstallationEnergeticOverviewGroup): FilledGroup {
        return {
            label: group.label,
            availability: this.mapAvailability([group.availabilityQuestion], this.answers),
            description: this.mapDescriptionFromAvailability(group.availabilityQuestion, this.answers),
            installMoment: this.mapInstallMoment(null, group.installYearQuestions, this.answers),
            notes: this.mapNotes(group.notesQuestions, this.answers),
        };
    }

    private mapBooleanGroup(group: BooleanEnergeticOverviewGroup): FilledGroup {
        return {
            label: group.label,
            availability: this.mapAvailability(group.availabilityQuestions, this.answers),
            description: null,
            installMoment: null,
            notes: null,
        };
    }

    private mapEnergyGroup(group: EnergyEnergeticOverviewGroup): FilledGroup {
        const descriptions = [
            this.mapDescriptionFromSolarAmount(
                group.solarInputMethodQuestions,
                group.solarAmountQuestions,
                this.answers
            ),
            this.mapDescriptionFromSolarOrientation(group.solarOrientationQuestions, this.answers),
            this.mapDescriptionFromOwnership(group.ownershipQuestions, this.answers),
        ].filter((d): d is string => d !== null);

        return {
            label: group.label,
            availability: this.mapAvailability([group.availabilityQuestion], this.answers),
            description: descriptions.length > 0 ? descriptions.join('\n') : null,
            installMoment: this.mapInstallMoment(
                group.installMomentQuestions,
                group.installYearQuestions,
                this.answers
            ),
            notes: this.mapNotes(group.notesQuestions, this.answers),
        };
    }

    private mapAvailability(availabilityQuestions: Question[], answers: Answer[]): string {
        let availability: EnergeticAvailability = EnergeticAvailability.NO;

        for (const question of availabilityQuestions) {
            const applicableAnswers = answers.filter((answer) => answer.questionUuid === question.uuid);

            switch (question.type) {
                case NormalQuestionType.BOOLEAN: {
                    const isPresent = applicableAnswers.some((answer) => answer.contents === '1');
                    if (isPresent) {
                        availability = EnergeticAvailability.YES;
                    }
                    break;
                }
                case NormalQuestionType.MC:
                case NormalQuestionType.MC_SELECT: {
                    const options = applicableAnswers
                        .map((a) =>
                            a.answerOptionId ? question.answerOptions.find((o) => o.id === a.answerOptionId) : null
                        )
                        .filter((o): o is AnswerOption => o !== null);

                    if (
                        options.some(
                            (o) =>
                                o.reportValue?.toLowerCase() === 'volledig' ||
                                o.reportValue?.toLowerCase() === 'ja' ||
                                o.reportValue?.toLowerCase() === '1'
                        ) &&
                        availability < EnergeticAvailability.YES
                    ) {
                        availability = EnergeticAvailability.YES;
                    }

                    if (
                        options.some((o) => o.reportValue?.toLowerCase() === 'gedeeltelijk') &&
                        availability < EnergeticAvailability.PARTIAL
                    ) {
                        availability = EnergeticAvailability.PARTIAL;
                    }

                    break;
                }
                case NormalQuestionType.MULTIPLE_BOOLEAN_GROUP: {
                    const childAnswers = applicableAnswers
                        .flatMap((a) => this.answerController.answersByParentAnswerUuid(a.uuid))
                        .filter((a) => a.contents === '1');
                    if (childAnswers.length > 0) {
                        availability = EnergeticAvailability.YES;
                    }

                    break;
                }
                default:
                    continue;
            }
        }

        switch (availability) {
            case EnergeticAvailability.YES:
                return 'Aanwezig';
            case EnergeticAvailability.PARTIAL:
                return 'Gedeeltelijk';
            case EnergeticAvailability.NO:
            default:
                return 'Afwezig';
        }
    }

    private mapInstallMoment(
        installMomentQuestions: Question[] | null,
        installYearQuestions: Question[],
        answers: Answer[]
    ): number | null {
        let year: number | null = null;

        for (const question of installYearQuestions) {
            const applicableAnswers = answers.filter((answer) => answer.questionUuid === question.uuid);

            for (const answer of applicableAnswers) {
                if (!isEmpty(answer.contents)) {
                    const answerYear = parseInt(answer.contents);

                    if (!isNaN(answerYear) && (year === null || answerYear < year)) {
                        year = answerYear;
                    }
                }
            }
        }

        if (year !== null || installMomentQuestions === null) {
            return year;
        }

        for (const question of installMomentQuestions) {
            const applicableAnswers = answers.filter((answer) => answer.questionUuid === question.uuid);

            for (const answer of applicableAnswers) {
                const answerOption = answer.answerOptionId
                    ? question.answerOptions.find((o) => o.id === answer.answerOptionId) ?? null
                    : null;
                if (
                    answerOption !== null &&
                    (answerOption.reportValue?.toLowerCase() === 'bouwjaar' ||
                        answerOption.reportValue?.toLowerCase() === 'bij bouw')
                ) {
                    return this.buildYearProvider.get();
                }
            }
        }

        return null;
    }

    private mapNotes(notesQuestions: Question[], answers: Answer[]) {
        const notes = notesQuestions
            .flatMap((question) => answers.filter((a) => a.questionUuid === question.uuid).map((a) => a.contents))
            .filter((note): note is string => note !== null);

        if (notes.length === 0) {
            return null;
        }

        return notes.join('\n');
    }

    private mapDescriptionFromAvailability(availabilityQuestion: Question, answers: Answer[]): string | null {
        const childAnswers = answers
            .filter((a) => a.questionUuid === availabilityQuestion.uuid)
            .flatMap((a) => this.answerController.answersByParentAnswerUuid(a.uuid))
            .filter((a) => a.contents === '1');

        if (childAnswers.length > 0) {
            return childAnswers
                .map((answer, i) => {
                    const title = this.questionSet.findQuestionByUuid(answer.questionUuid)?.contents ?? '';

                    if (i === 0) {
                        return title;
                    }

                    return title.toLowerCase();
                })
                .join(', ');
        }

        return null;
    }

    private mapDescriptionFromSolarAmount(
        solarInputMethodQuestions: Question[],
        solarAmountQuestions: Question[],
        answers: Answer[]
    ): string | null {
        const isWattPeakInput = solarInputMethodQuestions
            .flatMap((q) =>
                answers
                    .filter((a) => a.questionUuid === q.uuid)
                    .map((a) =>
                        a.answerOptionId ? q.answerOptions.find((o) => o.id === a.answerOptionId) ?? null : null
                    )
                    .filter((o): o is AnswerOption => o !== null)
            )
            .some((o) => o.reportValue === '0');

        let sum = 0;
        for (const question of solarAmountQuestions) {
            const applicableAnswers = answers.filter((answer) => answer.questionUuid === question.uuid);

            for (const answer of applicableAnswers) {
                if (!isEmpty(answer.contents)) {
                    const answerValue = parseFloat(answer.contents);

                    if (!isNaN(answerValue)) {
                        sum += answerValue;
                    }
                }
            }
        }

        if (sum === 0) {
            return null;
        } else if (isWattPeakInput) {
            return `${sum} Wp`;
        } else {
            return `Aantal: ${sum}`;
        }
    }

    private mapDescriptionFromSolarOrientation(
        solarOrientationQuestions: Question[],
        answers: Answer[]
    ): string | null {
        const uuids = solarOrientationQuestions.map((q) => q.uuid);

        const childAnswers = answers
            .filter((a) => uuids.includes(a.questionUuid))
            .flatMap((a) => this.answerController.answersByParentAnswerUuid(a.uuid))
            .filter((a) => a.contents === '1');

        if (childAnswers.length > 0) {
            return (
                'Orientatie: ' +
                childAnswers
                    .map((answer, i) => {
                        const title = this.questionSet.findQuestionByUuid(answer.questionUuid)?.contents ?? '';

                        if (i === 0) {
                            return title;
                        }

                        return title.toLowerCase();
                    })
                    .join(', ')
            );
        }

        return null;
    }

    private mapDescriptionFromOwnership(ownershipQuestions: Question[], answers: Answer[]): string | null {
        const answerOptions = ownershipQuestions.flatMap((q) =>
            answers
                .filter((a) => a.questionUuid === q.uuid)
                .map((a) => (a.answerOptionId ? q.answerOptions.find((o) => o.id === a.answerOptionId) ?? null : null))
                .filter((o): o is AnswerOption => o !== null)
        );

        if (answerOptions.length === 0) {
            return null;
        }

        return (
            'Eigendom: ' +
            answerOptions
                .map((option, i) => {
                    if (i === 0) {
                        return option.contents;
                    }

                    return option.contents.toLowerCase();
                })
                .join(', ')
        );
    }

    private groupAnswersByFloor(answers: Answer[]): Record<string, Answer[]> {
        // TODO: Implement for page part sets

        const byFloor: Record<string, Answer[]> = {};
        const questionUuids = this.questionSet
            .findQuestionsByType(FloorQuestionType.FLOOR_GROUP_FLOOR)
            .flatMap((q) => this.questionSet.findChildQuestionsByParentUuid(q.uuid))
            .map((q) => q.uuid);

        for (const answer of answers) {
            let cursorAnswer: Answer | null = answer;

            while (cursorAnswer && !questionUuids.includes(cursorAnswer.questionUuid) && cursorAnswer.parentUuid) {
                cursorAnswer = this.answerController.byUuid(cursorAnswer.parentUuid);
            }

            if (!cursorAnswer || !questionUuids.includes(cursorAnswer.questionUuid) || !cursorAnswer.iteration) {
                continue;
            }

            if (!byFloor[cursorAnswer.iteration]) {
                byFloor[cursorAnswer.iteration] = [];
            }

            byFloor[cursorAnswer.iteration].push(answer);
        }

        return byFloor;
    }
}

interface SimpleEnergeticOverviewGroup {
    type: 'simple';
    label: string;
    perFloor: boolean;
    availabilityQuestions: Question[];
    installMomentQuestions: Question[];
    installYearQuestions: Question[];
    notesQuestions: Question[];
}

interface InstallationEnergeticOverviewGroup {
    type: 'installation';
    label: string;
    availabilityQuestion: Question;
    installYearQuestions: Question[];
    notesQuestions: Question[];
}

interface BooleanEnergeticOverviewGroup {
    type: 'boolean';
    label: string;
    availabilityQuestions: Question[];
}

interface EnergyEnergeticOverviewGroup {
    type: 'energy';
    label: string;
    availabilityQuestion: Question;
    installMomentQuestions: Question[];
    installYearQuestions: Question[];
    notesQuestions: Question[];
    solarInputMethodQuestions: Question[];
    solarAmountQuestions: Question[];
    solarOrientationQuestions: Question[];
    ownershipQuestions: Question[];
}

interface FilledGroup {
    label: string;
    availability: string;
    description: string | null;
    installMoment: number | null;
    notes: string | null;
}

type EnergeticOverviewGroup =
    | SimpleEnergeticOverviewGroup
    | InstallationEnergeticOverviewGroup
    | BooleanEnergeticOverviewGroup
    | EnergyEnergeticOverviewGroup;

enum EnergeticAvailability {
    PARTIAL = 3,
    YES = 2,
    NO = 1,
}
