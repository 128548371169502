import {ApiReferenceSale} from '../../../../../../../../network/models/api_reference_sale';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';

export function getFromReferenceObjectAnswer(answer: ReferenceObjectAnswer): {
    month: number;
    year: number;
} | null {
    const validFormat = new RegExp('^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\+\\d{2}:\\d{2}$');
    if (answer.referenceObject.verkoopdatum === null || !validFormat.test(answer.referenceObject.verkoopdatum)) {
        return null;
    }

    const saleDateSplit = answer.referenceObject.verkoopdatum.split('-').map(Number);
    return {
        month: saleDateSplit[1],
        year: saleDateSplit[0],
    };
}

export function getFromApiReferenceObject(apiReferenceObject: ApiReferenceSale): {
    month: number;
    year: number;
} | null {
    const validFormat = new RegExp('^[1-4]-\\d{4}$');
    if (!validFormat.test(apiReferenceObject.sale_quarter)) {
        return null;
    }

    const saleQuarterSplit = apiReferenceObject.sale_quarter.split('-').map(Number);

    return {
        month: (saleQuarterSplit[0] - 1) * 3, // month is a number between 0 and 11
        year: saleQuarterSplit[1],
    };
}
