import {makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {ConfigWithCallbacks} from '../../../../business/generic_config_stack_interactor';
import {ModalConfig} from '../../../../models/modal_config';
import {ModalConfigStackInteractor} from '../../../../business/modal_config_stack_interactor';
import {Presenter} from '../../../../../support/presenter/presenter';

export class ModalsStackPresenter implements Presenter {
    @observable.ref
    public stack: Array<ConfigWithCallbacks<ModalConfig, unknown>> = [];

    private subscriptions = new CompositeSubscription();

    constructor(private modalsConfigStackInteractor: ModalConfigStackInteractor) {
        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            this.modalsConfigStackInteractor.stream().subscribe((stack) => {
                runInAction(() => {
                    this.stack = stack;
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
