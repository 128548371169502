import * as React from 'react';
import {Appraisal} from '../../../../../models/appraisal';
import {classNames} from '../../../../../../support/classnames';
import {Loader} from '../../../components/loader';
import {observer} from 'mobx-react';

interface OwnProps {
    appraisal: Appraisal;
    finished: boolean;
    loading: boolean;
    disabled: boolean;
    errors: boolean;
    onClick: () => void;
}

@observer
export class ClientFooter extends React.Component<OwnProps> {
    public render() {
        return (
            <>
                {this.props.errors && !this.props.finished && (
                    <dl className="alert-inline ion-md-alert alert-danger mb-3">
                        <dt>Ongeldige invoer</dt>
                        <dd>Vul alle velden volledig in om verder te gaan.</dd>
                    </dl>
                )}
                <footer className="d-flex flex-column justify-content-end">
                    <div className="d-flex justify-content-end w-100 overflow-hidden">
                        {this.props.finished ? this.renderFinishedButton() : this.renderSubmit()}
                    </div>
                </footer>
            </>
        );
    }

    private renderFinishedButton() {
        return (
            <a className="btn btn-primary ion-md-checkmark" href={`/appraisals/${this.props.appraisal.id}/details`}>
                Terug naar overzicht
            </a>
        );
    }

    private renderSubmit() {
        return (
            <button
                className={classNames('btn', 'btn-primary', {
                    'ion-md-checkmark': this.props.finished,
                })}
                disabled={this.props.loading || this.props.finished || this.props.disabled || this.props.errors}
                onClick={() => this.props.onClick()}
            >
                {this.props.loading ? <Loader /> : 'Verstuur naar taxateur'}
            </button>
        );
    }
}
