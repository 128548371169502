import * as React from 'react';

import {AddCustomReferenceSaleInSaleModalPresenter} from './add_custom_reference_sale_in_sale_modal_presenter';
import {Appraisal} from '../../../../../../../../models/appraisal';
import {Loader} from '../../../../../../components/loader';
import {Modal} from '../../../../components/modal';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../../../support/presenter/use_presenter';

interface OwnProps {
    appraisal: Appraisal;
    referenceObjectsAnswers: ReferenceObjectAnswer[];
    visible: boolean;
    onHide: () => void;
    onAdd: (referenceObjectAnswer: ReferenceObjectAnswer) => void;
    disabled?: boolean;
}

export const AddCustomReferenceSaleInSaleModal: React.FC<OwnProps> = observer(
    function AddCustomReferenceSaleInSaleModal(props) {
        const presenter = usePresenter(
            (container) =>
                new AddCustomReferenceSaleInSaleModalPresenter(
                    props.onAdd,
                    props.referenceObjectsAnswers,
                    container.business.referenceObjectCurrentlyInSaleInteractor(props.appraisal)
                )
        );
        return (
            <Modal onHide={() => props.onHide()} visible={props.visible}>
                <div className="modal-header">
                    <h2>Referentieobject toevoegen</h2>
                </div>
                <div className="modal-body">
                    {presenter.hasError ? <div className="alert alert-danger">{presenter.errorMessage}</div> : null}
                    <div className="row">
                        <div className="col-12 col-sm-4">
                            <div className="form-group">
                                <label htmlFor="input-postal-code">Postcode</label>
                                <input
                                    id="input-postal-code"
                                    className="form-control"
                                    type="text"
                                    disabled={props.disabled || presenter.isDisabled}
                                    value={presenter.postalCode}
                                    onChange={(e) => presenter.onPostalCodeChange(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className="form-group">
                                <label htmlFor="input-house-number">Huisnummer</label>
                                <input
                                    id="input-house-number"
                                    className="form-control"
                                    type="text"
                                    disabled={props.disabled || presenter.isDisabled}
                                    value={presenter.houseNumber}
                                    onChange={(e) => presenter.onHouseNumberChange(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className="form-group">
                                <label htmlFor="input-letter">Toevoeging</label>
                                <input
                                    id="input-letter"
                                    className="form-control"
                                    type="text"
                                    disabled={props.disabled || presenter.isDisabled}
                                    value={presenter.letter}
                                    onChange={(e) => presenter.onLetterChange(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-secondary ion-md-close" type="button" onClick={() => props.onHide()}>
                        Annuleren
                    </button>
                    <button
                        className="btn btn-primary ion-md-arrow-forward"
                        type="button"
                        onClick={() => presenter.onSubmitClick()}
                    >
                        {presenter.submitting ? <Loader /> : 'Toevoegen'}
                    </button>
                </div>
            </Modal>
        );
    }
);
