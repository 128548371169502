import {DefaultQuestionSet, ReportDefinitionConfig} from './question_set';

export class EmptyQuestionSet extends DefaultQuestionSet {
    public static emptyReportDefinitionConfig: ReportDefinitionConfig = {
        opnameDatumOutsideOfQuestionSet: true,
        preValidationMessages: false,
        conceptReport: false,
        maxReferenceObjectsInSet: 3,
        reportParagraphReferences: [],
        compactHouseGroupSelection: true,
        hasBuildingCosts: true,
        referenceObjectsVersion: 1,
    };

    constructor(id = -1) {
        super(id, [], EmptyQuestionSet.emptyReportDefinitionConfig);
    }
}
