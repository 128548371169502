import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {AttachmentsWidgetPresenter} from './attachments_widget_presenter';
import {QuestionSet} from '../../../../models/question_set';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';

interface OwnProps {
    questionSet: QuestionSet;
    appraisal: Appraisal;
}

export const AttachmentsWidget: React.FC<OwnProps> = observer(function AttachmentsWidget(props) {
    const presenter = usePresenter(
        (container) =>
            new AttachmentsWidgetPresenter(
                container.business.attachmentQuestionsInteractor(props.appraisal, props.questionSet),
                container.business.appraiseSecondaryConfigStackInteractor
            )
    );
    const pairs = presenter.questionAnswerPairs;

    return (
        <div className="col-12">
            <div className="widget">
                <div className="widget-title">
                    <h3>
                        <span className="ion-md-document" />
                        Bijlagen
                    </h3>
                </div>
                <div className="widget-grid">
                    {props.appraisal.termsOfEngagement.url !== null ? (
                        <button
                            key={props.appraisal.termsOfEngagement.url}
                            className="widget-grid-item"
                            onClick={() =>
                                presenter.setActiveAttachment(
                                    props.appraisal.termsOfEngagement.fileName,
                                    props.appraisal.termsOfEngagement.url
                                )
                            }
                        >
                            <div className="widget-grid-item-avatar">
                                <span className="ion-md-document" />
                            </div>
                            <div className="widget-grid-item-filename">Opdrachtvoorwaarden</div>
                        </button>
                    ) : null}
                    {props.appraisal.precheckReport !== null ? (
                        <button
                            key={props.appraisal.precheckReport.url}
                            className="widget-grid-item"
                            onClick={() =>
                                props.appraisal.precheckReport &&
                                presenter.setActiveAttachment(
                                    props.appraisal.precheckReport.originalFilename,
                                    props.appraisal.precheckReport.url
                                )
                            }
                        >
                            <div className="widget-grid-item-avatar">
                                <span className="ion-md-document" />
                            </div>
                            <div className="widget-grid-item-filename">Pre-check rapport</div>
                        </button>
                    ) : null}

                    {pairs.map((pair) => {
                        return (
                            <button
                                key={pair.answer.uuid}
                                className="widget-grid-item"
                                onClick={() =>
                                    presenter.setActiveAttachment(pair.displayName, pair.answer.file?.url ?? null, pair)
                                }
                            >
                                <div className="widget-grid-item-avatar">
                                    {pair.answer.file && !pair.answer.isVisited && (
                                        <span className="unread-notification"> </span>
                                    )}
                                    <span className="ion-md-document" />
                                </div>
                                <div className="widget-grid-item-filename">{pair.displayName}</div>
                            </button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
});
