import {load} from 'recaptcha-v3';

const getClosest = function (elem, selector) {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function (s) {
                var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                    i = matches.length;
                while (--i >= 0 && matches.item(i) !== this) {}
                return i > -1;
            };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
    }
    return null;
};

document.addEventListener(
    'DOMContentLoaded',
    function () {
        const recaptchaInputElement = document.getElementById('recaptcha');
        if (recaptchaInputElement) {
            if (process.env.RECAPTCHA_SITE_KEY === 'test') {
                return;
            }
            load(process.env.RECAPTCHA_SITE_KEY).then((recaptcha) => {
                const form = getClosest(recaptchaInputElement, 'form');
                if (form) {
                    form.addEventListener('submit', function (e) {
                        console.error('recaptcha submit');
                        e.preventDefault();
                        recaptcha
                            .execute('submit')
                            .then((token) => {
                                console.error('recaptcha token', token);
                                recaptchaInputElement.value = token;
                                this.submit();
                            })
                            .catch((error) => {
                                console.error('recaptcha error', error);
                            });
                    });
                }
            });
        }
    },
    false
);
