import React from 'react';
import {classNames} from '../support/classnames';

interface OwnProps {
    icon: string;
    small?: boolean;
    children?: React.ReactNode;
}

export function IconCard({icon, children, small}: OwnProps) {
    return (
        <div
            className={classNames('popup-card-content popup-card-icon-card', {
                'popup-card-icon-card-small': small,
            })}
        >
            <h1 className={classNames('popup-card-icon-header', icon)} />
            {children}
        </div>
    );
}
