import * as React from 'react';
import {MersenneTwister} from '../../../../../../../support/seeded_random';
import {useState} from 'react';
import {range} from '../../../../../../../support/array';

export const BouwkundigeStaatViewerDummy: React.FunctionComponent = React.memo(() => {
    const [random] = useState(new MersenneTwister(501));
    const genBetween = React.useCallback(
        (min: number, max: number) => {
            return random.randomBetweenFromArray([25, 50, 75, 100, 200, 300, 400, 500], min, max);
        },
        [random]
    );

    return (
        <div>
            <table className="full-width width-100 w-100">
                <tbody>
                    <tr className="placeholder-table-header">
                        <td>
                            <br />
                        </td>
                        <td />
                        <td />
                    </tr>
                    {range(0, 8).map((i) => (
                        <tr key={i}>
                            <td>
                                <div
                                    className={`placeholder d-block placeholder-width-${genBetween(
                                        75,
                                        400
                                    )} placeholder-opaque-40 mt-1`}
                                ></div>
                            </td>
                            <td>
                                <div
                                    className={`placeholder d-block placeholder-width-${genBetween(
                                        25,
                                        75
                                    )} placeholder-opaque-40 mt-1`}
                                ></div>
                            </td>
                            <td>
                                <div
                                    className={`placeholder d-block placeholder-width-${genBetween(
                                        50,
                                        200
                                    )} placeholder-opaque-40 mt-1`}
                                ></div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <br />
            <br />
            <table className="full-width width-100 w-100">
                <tbody>
                    <tr className="placeholder-table-header">
                        <td>
                            <br />
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>
                    {range(0, 25).map((i) => (
                        <tr key={i}>
                            <td>
                                <div
                                    className={`placeholder d-block placeholder-width-${genBetween(
                                        100,
                                        300
                                    )} placeholder-opaque-40 mt-1`}
                                ></div>
                            </td>
                            <td>
                                <div
                                    className={`placeholder d-block placeholder-width-${genBetween(
                                        25,
                                        75
                                    )} placeholder-opaque-40 mt-1`}
                                ></div>
                            </td>
                            <td>
                                <div
                                    className={`placeholder d-block placeholder-width-${genBetween(
                                        25,
                                        75
                                    )} placeholder-opaque-40 mt-1`}
                                ></div>
                            </td>
                            <td>
                                <div
                                    className={`placeholder d-block placeholder-width-${genBetween(
                                        25,
                                        75
                                    )} placeholder-opaque-40 mt-1`}
                                ></div>
                            </td>
                            <td>
                                <div
                                    className={`placeholder d-block placeholder-width-${genBetween(
                                        25,
                                        75
                                    )} placeholder-opaque-40 mt-1`}
                                ></div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
});

BouwkundigeStaatViewerDummy.displayName = 'BouwkundigeStaatViewerDummy';
