import * as React from 'react';

import {FunctionComponent} from 'react';
import {Root} from './root';

interface OwnProps {
    appraisalId: number | null;
    questionSetId: number;
}

export const Appraise: FunctionComponent<OwnProps> = ({appraisalId, questionSetId}) => {
    return <Root appraisalId={appraisalId} questionSetId={questionSetId} />;
};
