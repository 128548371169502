import {action, makeObservable, observable} from 'mobx';

import {AnswerController} from '../../../../../business/answering/answer_controller';
import {Appraisal} from '../../../../../models/appraisal';
import {IteratorPresenter} from './iterator_presenter';
import {Question} from '../../../../../models/question';
import {QuestionEffectInteractor} from '../../../../../business/conditions/question_effects_interactor';
import {QuestionSet} from '../../../../../models/question_set';
import {UserInteractor} from '../../../../../business/user_interactor';
import Uuid from 'uuid';
import {ChildQuestionValidationProvider} from '../../../../../business/validation/child_validation_provider';

export class IteratorCadastralPresenter extends IteratorPresenter {
    @observable public addCadastralPlotModalIteration: string | null = null;

    constructor(
        question: Question,
        appraisal: Appraisal,
        parentAnswerUuid: string | undefined,
        questionSet: QuestionSet,
        answerController: AnswerController,
        questionEffectsInteractor: QuestionEffectInteractor,
        userInteractor: UserInteractor,
        childQuestionValidationProvider: ChildQuestionValidationProvider
    ) {
        super(
            question,
            appraisal,
            parentAnswerUuid,
            questionSet,
            answerController,
            questionEffectsInteractor,
            userInteractor,
            childQuestionValidationProvider
        );
        makeObservable(this);
    }

    @action
    public onShow() {
        if (this.addCadastralPlotModalIteration === null) {
            this.addCadastralPlotModalIteration = Uuid.v4();
        }
        if (this.iterations === undefined) {
            this.iterations = [this.addCadastralPlotModalIteration];
        } else {
            this.iterations = [...this.iterations, this.addCadastralPlotModalIteration];
        }
    }

    @action
    public onHide() {
        this.addCadastralPlotModalIteration = null;
    }

    @action
    public onAdd() {
        this.addCadastralPlotModalIteration = null;
    }
}
