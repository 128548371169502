import {action, makeObservable, observable} from 'mobx';

import {Presenter} from '../../../../../../../../../support/presenter/presenter';
import {ReferenceSubscriptionType} from '../../../../../../../../models/reference_subscriptions';

export interface ReferenceSubscriptionSettings {
    logoClassName?: string;
    hasPreferencesModal?: boolean;
}

export class SubscriptionButtonsPresenter implements Presenter {
    @observable public preferencesModalOpen: ReferenceSubscriptionType | null = null;

    constructor() {
        makeObservable(this);
    }

    public mount(): void {
        //Noop
    }

    public unmount(): void {
        //Noop
    }

    @action
    public openPreferencesModal = (type: ReferenceSubscriptionType | null) => {
        this.preferencesModalOpen = type;
    };

    public getSubscriptionSettings(type: ReferenceSubscriptionType): ReferenceSubscriptionSettings {
        switch (type) {
            case ReferenceSubscriptionType.BRAINBAY:
                return {
                    logoClassName: 'logo-brainbay',
                };
            case ReferenceSubscriptionType.ALTUM:
                return {
                    hasPreferencesModal: true,
                };
            default:
                return {};
        }
    }
}
