import {Modifier} from '@dnd-kit/core';
import {getEventCoordinates} from '@dnd-kit/utilities';

export const snapToCursor: Modifier = ({activatorEvent, draggingNodeRect, transform}) => {
    if (draggingNodeRect && activatorEvent) {
        const activatorCoordinates = getEventCoordinates(activatorEvent);

        if (!activatorCoordinates) {
            return transform;
        }

        const offsetY = activatorCoordinates.y - draggingNodeRect.top;
        const height = draggingNodeRect.bottom - draggingNodeRect.top;

        return {
            ...transform,
            y: transform.y + offsetY - height / 2,
        };
    }

    return transform;
};
