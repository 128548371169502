import {SidebarItem, SidebarTreeBuilder} from './sidebar_tree_builder';

import {Observable} from 'rxjs';
import {RootGroupQuestionType} from '../enum/question_type';
import {map, shareReplay} from 'rxjs/operators';

export class SidebarItemsProvider {
    constructor(private sidebarTreeBuilder: SidebarTreeBuilder) {}

    /**
     * Get a flattened list of all sidebar items
     */
    public flattened(): Observable<SidebarItem[]> {
        return this.sidebarTreeBuilder.build.pipe(
            map((sidebarItems) => {
                return sidebarItems.reduce((p: SidebarItem[], c: SidebarItem) => {
                    //The house group itself can also have validation messages, so we want to include this root sidebar item as well
                    if (
                        c.question !== undefined &&
                        (c.question.type === RootGroupQuestionType.HOUSE_GROUP ||
                            c.question.type === RootGroupQuestionType.HOUSE_GROUP_COMPACT ||
                            c.question.type === RootGroupQuestionType.HOUSE_INSIDE)
                    ) {
                        return [...p, c, ...c.children];
                    }
                    return [...p, ...c.children];
                }, []);
            }),
            shareReplay(1)
        );
    }
}
