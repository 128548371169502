import {PagePart} from '../../../../../../../models/page_part';
import {PagePartsSet} from '../../../../../../../models/page_parts_set';
import {Question} from '../../../../../../../models/question';
import {QuestionSet} from '../../../../../../../models/question_set';

export function getPagePartChildren(
    pagePartUuid: string | null,
    pagePartsSet: PagePartsSet | null,
    questionSet: QuestionSet | null
) {
    if (pagePartUuid === null || pagePartsSet === null || questionSet === null) {
        return [];
    }

    const pagePartChildren = pagePartsSet.getChildrenForUuid(pagePartUuid);
    return pagePartChildren
        .map((pagePart) => {
            return {
                id: pagePart.uuid,
                pagePart,
                question: pagePart.questionUuid ? questionSet.findQuestionByUuid(pagePart.questionUuid) : undefined,
            };
        })
        .filter((data): data is {id: string; pagePart: PagePart; question: Question} => {
            return data.question !== undefined;
        })
        .sort((a, b) => a.pagePart.rank - b.pagePart.rank);
}
