import * as React from 'react';

import {ErrorInfo} from 'react';
import {bugsnagOwnClient} from '../../support/bugsnag_client';

interface OwnProps {
    children?: React.ReactNode;
}

interface State {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<OwnProps, State> {
    public state = {
        hasError: false,
    };

    public componentDidCatch(error: Error, info: ErrorInfo) {
        this.setState({hasError: true});

        if (bugsnagOwnClient !== null) {
            //Thank you https://github.com/bugsnag/bugsnag-js/blob/89366795e9743a1b84a22ff414078e78897baf26/packages/plugin-react/src/index.js
            const handledState = {
                severity: 'error',
                unhandled: true,
                severityReason: {
                    type: 'unhandledException',
                },
            };
            const event = bugsnagOwnClient.Event.create(error, true, handledState, info.componentStack ?? 'Unknown', 1);
            if (info && info.componentStack) {
                info.componentStack = formatComponentStack(info.componentStack);
            }
            event.addMetadata('react', info);

            bugsnagOwnClient._notify(event);
        }
    }

    public render() {
        if (this.state.hasError) {
            return (
                <div className="empty-message appraise">
                    <div className="card-icon-container">
                        <div className="card-icon card-icon-green ion-md-close" />
                    </div>
                    <h3>Sorry, er is iets fout gegaan</h3>
                    <span className="spacer-gradient">&nbsp;</span>
                    <a href="#" onClick={() => window.location.reload()}>
                        Vernieuw de pagina
                    </a>
                </div>
            );
        }

        return this.props.children;
    }
}

const formatComponentStack = (str: string) => {
    const lines = str.split(/\s*\n\s*/g);
    let ret = '';
    for (let line = 0, len = lines.length; line < len; line++) {
        if (lines[line].length) {
            ret += `${ret.length ? '\n' : ''}${lines[line]}`;
        }
    }
    return ret;
};
