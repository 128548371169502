import React, {useMemo} from 'react';

import {AdaptedDefaultValuesMap} from '../../../../../models/adapted_default_values_map';
import {Appraisal} from '../../../../../models/appraisal';
import {QuestionContainer} from '../question_container';
import {QuestionSet} from '../../../../../models/question_set';
import {createValidationMessagesMap} from '../../../../../../support/create_validation_messages_map';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {observer} from 'mobx-react';
import {QuestionTableCollapsible} from '../../../components/question_table/question_table_collapsible';
import {ComparabilityIndicator} from '../../../components/compare_group/comparability_indicator';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {
    simpleQuestionPresenterConstructorParametersFactory,
    SimpleQuestionPresenterProps,
} from '../simple/simple_question_presenter';
import {CompareGroupQuestionPresenter} from './compare_group_question_presenter';
import {QuestionTableCollapseButton} from '../../../components/question_table/question_table_collapse_button';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';
import {QuestionLoader} from '../../../components/question_loader';
import {shouldShowTitle} from '../../../../../../support/should_show_title';

interface OwnProps extends SimpleQuestionPresenterProps {
    questionSet: QuestionSet;
    onChange?: (appraisal: Appraisal) => void;
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
}

export const CompareGroupQuestion: React.FunctionComponent<OwnProps> = observer((props: OwnProps) => {
    const {question, questionSet, renderingContext} = props;
    const presenter = usePresenter(
        (component) =>
            new CompareGroupQuestionPresenter(
                component.business.compareValuesProvider(props.appraisal, props.questionSet),
                ...simpleQuestionPresenterConstructorParametersFactory(props, component)
            )
    );

    const childrenForQuestion = useMemo(() => findChildrenForQuestion(question, questionSet), [question, questionSet]);

    if (presenter.isHidden) {
        return null;
    } else if (presenter.answer === undefined) {
        return <QuestionLoader />;
    }

    const {answer} = presenter;

    return (
        <>
            <div className="question-table-seperator" />
            <div className="group-container group-container-compact">
                <div className="row">
                    <div className="col-8 d-flex align-items-center pr-0">
                        <ComparabilityIndicator className="mr-2" comparability={presenter.comparability} />
                        {shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel) && (
                            <h2 className="mt-0 mb-0">{question.contents}</h2>
                        )}
                    </div>
                    <div className="col-4 d-flex justify-content-center pr-0">
                        {renderingContext === RenderingContextType.COMPACT_GRID_COLUMNS && (
                            <QuestionTableCollapseButton />
                        )}
                    </div>
                </div>
                <QuestionTableCollapsible>
                    {childrenForQuestion.map((child) => (
                        <QuestionContainer
                            key={child.uuid}
                            disabled={props.disabled}
                            appraisal={props.appraisal}
                            question={child}
                            onChange={props.onChange}
                            questionSet={props.questionSet}
                            validationErrors={props.validationErrors}
                            validationMessages={createValidationMessagesMap(
                                presenter.validationMessages,
                                props.validationMessages
                            )}
                            forceShowValidationMessages={props.forceShowValidationMessages}
                            parentAnswerUuid={answer.uuid}
                            adaptedDefaultValues={props.adaptedDefaultValues}
                            disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                            hiddenQuestionTypes={props.hiddenQuestionTypes}
                            pagePartsSet={props.pagePartsSet}
                            activePagePart={props.activePagePart}
                            renderingContext={props.renderingContext}
                            questionRenderingData={props.questionRenderingData}
                        />
                    ))}
                </QuestionTableCollapsible>
            </div>
        </>
    );
});
