import {ApiTaskReference, isApiTaskReference} from './models/api_task_reference';

import {AjaxDriver} from '../../network/driver/ajax_driver';
import {ApiValidationError} from './models/api_validation_error';
import {TaskReference} from '../models/task_reference';

export interface ValidationErrorApi {
    get(appraisalId: number): Promise<ApiValidationError[]>;
    refresh(appraisalId: number): Promise<ApiValidationError[] | TaskReference>;
}

export class DefaultValidationErrorApi implements ValidationErrorApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public get(appraisalId: number): Promise<ApiValidationError[]> {
        return new Promise<ApiValidationError[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/validation-errors`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const apiValidationErrors: ApiValidationError[] = await result.json();
                        resolve(apiValidationErrors);
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public refresh(appraisalId: number): Promise<ApiValidationError[] | TaskReference> {
        return new Promise<ApiValidationError[] | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/validation-errors/refresh`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiValidationError[] | ApiTaskReference = await result.json();
                        if (isApiTaskReference(data)) {
                            resolve({
                                taskId: data.task_id,
                            });
                        } else {
                            resolve(data);
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
