import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {ConfigTabsContainerPresenter, Tabs} from './config_tabs_container_presenter';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {classNames} from '../../../support/classnames';
import {ConfigListTab} from './config_list_tab';
import {ConfigCurrentTab} from './config_current_tab';
import {PagePartsSet} from '../../models/page_parts_set';
import {Appraisal} from '../../models/appraisal';
import {QuestionSet} from '../../models/question_set';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    activePagePartSet: PagePartsSet;
}

export const ConfigTabsContainer: FunctionComponent<OwnProps> = observer(
    ({appraisal, questionSet, activePagePartSet}) => {
        const presenter = usePresenter(() => new ConfigTabsContainerPresenter());

        return (
            <div className="appraise-widget-container">
                <div className="col-12">
                    <ul className="nav nav-tabs no-top-border">
                        <li className={classNames('nav-item col-6', {active: presenter.activeTab === Tabs.LIST})}>
                            <button className="nav-link" onClick={() => presenter.setActiveTab(Tabs.LIST)}>
                                <span className="nav-icon ion-md-list"></span>
                                Mijn model configuraties
                            </button>
                        </li>
                        <li className={classNames('nav-item col-6', {active: presenter.activeTab === Tabs.CURRENT})}>
                            <button className="nav-link" onClick={() => presenter.setActiveTab(Tabs.CURRENT)}>
                                <span className="nav-icon ion-md-create"></span>
                                Huidige configuratie
                            </button>
                        </li>
                    </ul>
                    {presenter.activeTab === Tabs.LIST && (
                        <ConfigListTab appraisal={appraisal} questionSet={questionSet} />
                    )}
                    {presenter.activeTab === Tabs.CURRENT && (
                        <ConfigCurrentTab
                            key={activePagePartSet.id}
                            appraisal={appraisal}
                            questionSet={questionSet}
                            activePagePartSet={activePagePartSet}
                        />
                    )}
                </div>
            </div>
        );
    }
);
