import {SidebarItem, SidebarTreeBuilder} from '../../../../business/sidebar_tree_builder';

import {AnswerController} from '../../../../business/answering/answer_controller';
import {UnjudgedPageAction} from './unjudged_page_action';
import {map} from 'rxjs/operators';

export interface UnjudgedPage {
    sidebarItem: SidebarItem;
    message: string;
    action: 'visit' | 'mark-seen';
}

export class UnjudgedPagesProvider {
    constructor(
        private sidebarTreeBuilder: SidebarTreeBuilder,
        private answerController: AnswerController,
        private actions: UnjudgedPageAction[]
    ) {}

    public get() {
        return this.sidebarTreeBuilder.build.pipe(
            map((pages) => pages.reduce((p: SidebarItem[], c: SidebarItem) => [...p, ...c.children], [])),
            map((pages) => {
                return pages.filter((page: SidebarItem) => {
                    const question = page.question;
                    if (question === undefined) {
                        return false;
                    }

                    return this.answerController.answersForQuestionUuid(question.uuid).length === 0;
                });
            }),
            map((nonAnsweredPages) => {
                return nonAnsweredPages.map((page) => {
                    return this.actions.reduce<UnjudgedPage>(
                        (p: UnjudgedPage, action: UnjudgedPageAction) => {
                            const judgeResult = action.judge(page);

                            return judgeResult !== null ? judgeResult : p;
                        },
                        {
                            sidebarItem: page,
                            message:
                                'Deze pagina is niet geopend. Markeer als gezien indien akkoord met standaard antwoorden.',
                            action: 'mark-seen',
                        }
                    );
                });
            })
        );
    }
}
