import {makeObservable, observable, runInAction} from 'mobx';
import {combineLatest, Observable, of} from 'rxjs';
import {debounceTime, map, switchMap} from 'rxjs/operators';

import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {buildQuestionAnswerTree, flattenTree} from '../../../../../../support/question_answer_tree';
import {CompareValuesProvider} from '../../../../../business/compare_values/compare_values_provider';
import {
    averageComparabilityScore,
    badgeContextToComparabilityScore,
} from '../../../../../components/badges/badge_context_calculators';
import {Answer} from '../../../../../models/answer';
import {SimpleQuestionPresenter} from '../simple/simple_question_presenter';

export class CompareGroupQuestionPresenter extends SimpleQuestionPresenter {
    @observable public comparability = 1;

    private subscriptions = new CompositeSubscription();

    constructor(
        private compareValuesProvider: CompareValuesProvider,
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);

        makeObservable(this);
    }

    public async mount() {
        super.mount();

        const questionUuids = this.questionSet.flattenChildrenRecursively(this.question.uuid).map((q) => q.uuid);

        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidsStream(questionUuids)
                .pipe(
                    debounceTime(200),
                    map((answers) => this.answerController.filterDeleted(answers)),
                    map((answers) =>
                        answers.filter(
                            (answer) => !this.questionEffectsInteractor.isHidden(answer.questionUuid, answer.uuid)
                        )
                    ),
                    switchMap((answers) => {
                        if (!this.answer) {
                            return of([]);
                        }

                        // Use question answer tree to get only the answers that are children of the current answer
                        const childAnswers = flattenTree(
                            buildQuestionAnswerTree(this.questionSet, answers, this.answer)
                        )
                            .map((pair) => pair.answer)
                            .filter((a): a is Answer => a !== null);

                        return combineLatest(childAnswers.map((a) => this.getComparabilityScores(a)));
                    })
                )
                .subscribe((allScores) => {
                    runInAction(() => {
                        this.comparability = averageComparabilityScore(
                            allScores.flat().filter((s): s is number => s !== null)
                        );
                    });
                })
        );
    }

    public async unmount() {
        super.unmount();

        this.subscriptions.clear();
    }

    public getComparabilityScores(answer: Answer): Observable<number[]> {
        return this.compareValuesProvider
            .badgesByAnswerIdentifiersStream(answer.questionUuid, answer.parentUuid, answer.iteration)
            .pipe(
                map((badges) => {
                    return Object.values(badges)
                        .map((badge) => (badge !== null ? badgeContextToComparabilityScore(badge) : null))
                        .filter((s): s is number => s !== null);
                })
            );
    }
}
