import * as Uuid from 'uuid';

import {ValidationMessage, ValidationMessageMap} from '../../../../../business/validation/validation_message';
import {action, autorun, computed, makeObservable, observable, runInAction} from 'mobx';
import {first, switchMap} from 'rxjs/operators';

import {AppraiseSecondaryType} from '../../../../../models/appraise_secondary_config';
import {ChildQuestionValidationProvider} from '../../../../../business/validation/child_validation_provider';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {ModalType} from '../../../../../models/modal_config';
import {SimpleQuestionPresenter} from '../simple/simple_question_presenter';
import {ValidationError} from '../../../../../models/validation_error';
import {isEmpty} from '../../../../../../support/util';
import {buildQuestionAnswerTree, flattenTree} from '../../../../../../support/question_answer_tree';
import {isReadOnlyQuestionType} from '../../../../../../support/is_readonly_question_type';
import {PagePartsSet} from '../../../../../models/page_parts_set';
import {PagePart} from '../../../../../models/page_part';
import {ModalOrSecondaryConfigStackInteractor} from '../../../../../business/modal_or_secondary_config_stack_interactor';

export class WidgetGroupPresenter extends SimpleQuestionPresenter {
    @observable private widgetVisible = false;
    @observable.ref private childValidationMessages: ValidationMessage[] = [];

    private subscriptions = new CompositeSubscription();
    private secondaryWidgetId = `widget-group[${Uuid.v4()}]`;

    constructor(
        private validationErrors: ValidationError[],
        private validationMessagesMap: ValidationMessageMap,
        private pagePartsSet: PagePartsSet | null,
        private activePagePart: PagePart | null,
        private modalOrSecondaryConfigStackInteractor: ModalOrSecondaryConfigStackInteractor,
        private childValidationProvider: ChildQuestionValidationProvider,

        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public mount(): void {
        super.mount();

        this.subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid ?? null, this.iteration ?? null)
                .subscribe((answer) => {
                    runInAction(() => {
                        if (
                            this.answer === undefined &&
                            this.adaptedDefaultValue !== undefined &&
                            isEmpty(answer.contents) &&
                            isEmpty(answer.answerOptionId)
                        ) {
                            this.answer = answer;
                            this.onChange(this.adaptedDefaultValue, false);
                        } else {
                            this.answer = answer;
                        }
                    });
                })
        );

        this.subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid ?? null, this.iteration ?? null)
                .pipe(
                    first(),
                    switchMap((answer) => this.childValidationProvider.validate(this.question.uuid, answer.uuid, 2500))
                )
                .subscribe((validationMessages) => {
                    runInAction(() => {
                        this.childValidationMessages = validationMessages;
                    });
                })
        );

        if (window.location.href.includes('open=')) {
            this.subscriptions.add(
                this.answerController
                    .answerByIdentifiersStream(
                        this.question.uuid,
                        this.parentAnswerUuid ?? null,
                        this.iteration ?? null
                    )
                    .pipe(first())
                    .subscribe((answer) => {
                        try {
                            // Find Uuid v4 from URL
                            const search = window.location.href.match('open=\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12}') ?? [];
                            const openUuid = search[0]?.replace('open=', '');
                            if (
                                openUuid === this.question.uuid ||
                                openUuid === answer.uuid ||
                                openUuid === answer.parentUuid
                            ) {
                                runInAction(() => {
                                    this.widgetVisible = true;
                                });
                            }
                        } catch (error) {
                            console.warn(error);
                        }
                    })
            );
        }

        this.subscriptions.add(
            autorun(() => {
                if (this.widgetVisible && this.answer !== undefined && this.parentAnswerUuid !== undefined) {
                    this.modalOrSecondaryConfigStackInteractor.remove(this.secondaryWidgetId);
                    this.modalOrSecondaryConfigStackInteractor.upsert(
                        {
                            id: this.secondaryWidgetId,
                            type: ModalType.WIDGET_GROUP,
                            appraisal: this.appraisal,
                            questionSet: this.questionSet,
                            onClose: () => {
                                runInAction(() => {
                                    this.widgetVisible = false;
                                });
                            },
                            question: this.question,
                            parentAnswerUuid: this.parentAnswerUuid,
                            pagePartsSet: this.pagePartsSet,
                            activePagePart: this.activePagePart,
                            validationErrors: this.validationErrors,
                            validationMessages: this.validationMessagesMap,
                            forceShowValidationMessages: false,
                            hasOnlyReadonlyQuestions: this.hasOnlyReadonlyQuestions(),
                            questionRenderingData: this.questionRenderingData,
                        },
                        {
                            id: this.secondaryWidgetId,
                            type: AppraiseSecondaryType.WIDGET_GROUP,
                            appraisal: this.appraisal,
                            questionSet: this.questionSet,
                            onClose: () => {
                                runInAction(() => {
                                    this.widgetVisible = false;
                                });
                            },
                            question: this.question,
                            parentAnswerUuid: this.parentAnswerUuid,
                            pagePartsSet: this.pagePartsSet,
                            activePagePart: this.activePagePart,
                            validationErrors: this.validationErrors,
                            validationMessages: this.validationMessagesMap,
                            forceShowValidationMessages: false,
                            questionRenderingData: this.questionRenderingData,
                        }
                    );
                } else {
                    this.modalOrSecondaryConfigStackInteractor.remove(this.secondaryWidgetId);
                }
            })
        );
    }

    public unmount(): void {
        super.unmount();
        this.modalOrSecondaryConfigStackInteractor.remove(this.secondaryWidgetId);
        this.subscriptions.clear();
    }

    @computed
    public get shouldShowPulse() {
        return this.widgetVisible === false && this.childValidationMessages.length > 0;
    }

    private hasOnlyReadonlyQuestions(): boolean {
        if (!this.answer) {
            return false;
        }

        const items = flattenTree(buildQuestionAnswerTree(this.questionSet, [], this.answer));

        for (const item of items) {
            if (!isReadOnlyQuestionType(item.question.type)) {
                return false;
            }
        }

        return true;
    }

    @action
    public toggleWidgetContainer() {
        this.widgetVisible = !this.widgetVisible;
    }
}
