import {FloorQuestionType} from './question_type';

export enum FloorType {
    ATTIC = 'attic',
    FLOOR = 'floor',
    GROUND = 'ground',
    BASEMENT = 'basement',
}

export function floorTypeToQuestionType(floorType: FloorType): FloorQuestionType {
    switch (floorType) {
        case FloorType.ATTIC:
            return FloorQuestionType.FLOOR_GROUP_ATTIC;
        case FloorType.FLOOR:
            return FloorQuestionType.FLOOR_GROUP_FLOOR;
        case FloorType.GROUND:
            return FloorQuestionType.FLOOR_GROUP_GROUND;
        case FloorType.BASEMENT:
            return FloorQuestionType.FLOOR_GROUP_BASEMENT;
    }
}
