import * as React from 'react';

import {ReferenceObject, sourceToClassName} from '../../models/reference_object';

import {Appraisal} from '../../../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {ReferenceObjectAddress} from './reference_object_address';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {ReferenceObjectDetails} from './reference_object_details';
import {ReferenceObjectTilePresenter} from './reference_object_tile_presenter';
import {classNames} from '../../../../../../../../../../support/classnames';
import {format} from 'date-fns';
import {isEmpty} from '../../../../../../../../../../support/util';
import {observer} from 'mobx-react';
import {getReferenceIndexedObjectPrice, getReferenceUnindexedObjectPrice} from '../../internal/get_object_price';
import {SetDefinition} from '../../reference_objects_question_presenter';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';
import {referenceObjectImages} from '../../internal/reference_sale_images';
import {ReferenceObjectTileImageSlider} from './reference_object_tile_image_slider';

export enum ReferenceObjectTileStatus {
    PRESELECTED = 'preselected',
    REJECTED = 'rejected',
}

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    referenceObject: ReferenceObject;
    canAdd: boolean;
    status?: ReferenceObjectTileStatus;
    isCompact?: boolean;
    onAddAndOpenModal: (referenceObjectAnswer: ReferenceObjectAnswer) => Promise<void>;
    showDetailsModal: (referenceObject: ReferenceObject) => void;
    onImageSliderOpened: (referenceObject: ReferenceObject) => void;
    isMapHovering: boolean;
    isMapSelected: boolean;
    onHoverChange: (id: string | null) => void;
    onClickChange: (referenceObject: ReferenceObject | null) => void;
    onAlertResize: (alert: Element, height: number | null) => void;
    alertWrapperHeight: number;
    setDefinition: SetDefinition;
    teaser?: React.ReactNode | null;
}

export const ReferenceObjectTile: React.FC<OwnProps> = observer(function ReferenceObjectTile(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectTilePresenter(
                props.setDefinition,
                props.onAddAndOpenModal,
                container.business.referenceObjectProvider(props.appraisal, props.questionSet),
                container.business.referenceObjectsMetadataProvider(props.appraisal, props.questionSet)
            )
    );

    const alertRef = React.useRef(null);
    const alertResizeObserver = React.useRef<ResizeObserver | null>(null);
    const observedAlert = React.useRef<HTMLSpanElement | null>(null);

    const {referenceObject, isCompact} = props;
    if (referenceObject === null) {
        return null;
    }

    const images = referenceObjectImages(referenceObject, null);

    React.useEffect(() => {
        if (!alertRef.current) {
            return;
        }
        alertResizeObserver.current = new ResizeObserver((entries) => {
            for (const entry of entries) {
                props.onAlertResize(entry.target, entry.target.getBoundingClientRect().height + 15);
            }
        });

        alertResizeObserver.current.observe(alertRef.current);
        observedAlert.current = alertRef.current;

        return () => {
            if (alertResizeObserver.current) {
                alertResizeObserver.current.disconnect();
            }

            if (alertRef.current) {
                props.onAlertResize(alertRef.current, null);
            }
        };
    }, []);

    function renderButtons() {
        if (presenter.isAdding) {
            return <button className="ion-md-circle">Ophalen...</button>;
        }

        const referenceObject = props.referenceObject;
        if (referenceObject === null) {
            return;
        }
        if (props.status === ReferenceObjectTileStatus.REJECTED) {
            return (
                <>
                    <button
                        className={'ion-md-undo'}
                        onClick={() => props.canAdd && presenter.undoReject(referenceObject)}
                    >
                        Terugzetten
                    </button>
                    <button className="ion-md-create" onClick={() => props.showDetailsModal(referenceObject)}>
                        Bewerk
                    </button>
                </>
            );
        } else {
            return (
                <>
                    <button
                        className={classNames('ion-md-information-circle', {
                            disabled: props.teaser !== null,
                        })}
                        aria-disabled={props.teaser !== null}
                        onClick={() => (props.teaser !== null ? null : props.showDetailsModal(referenceObject))}
                    >
                        Details
                    </button>
                    <button
                        className={classNames('ion-md-add-circle', {
                            disabled: !props.canAdd || props.teaser !== null,
                        })}
                        aria-disabled={!props.canAdd || props.teaser !== null}
                        onClick={() =>
                            props.teaser !== null ? null : props.canAdd && presenter.onAdd(referenceObject)
                        }
                    >
                        Voeg toe
                    </button>
                </>
            );
        }
    }

    return (
        <>
            <div
                className={classNames('col-12 col-sm-6 col-md-4 form-grid-item', {
                    hovering: props.isMapHovering,
                    'preselected-tile': !isCompact && props.status === ReferenceObjectTileStatus.PRESELECTED,
                    'rejected-tile': !isCompact && props.status === ReferenceObjectTileStatus.REJECTED,
                })}
                key={referenceObject?.id ?? undefined}
                onMouseEnter={() => props.onHoverChange(referenceObject.id)}
                onMouseLeave={() => props.onHoverChange(null)}
            >
                <div className={classNames('reference-logo', sourceToClassName(referenceObject?.source ?? null))}>
                    &nbsp;
                </div>
                {referenceObject.matchingPercentage && (
                    <div className="reference-match-percentage">
                        <div className="floater floater-information">
                            <strong>{Math.round(referenceObject.matchingPercentage / 100)}%</strong> Match
                        </div>
                    </div>
                )}
                <div className="form-grid-item-img">
                    <ReferenceObjectTileImageSlider
                        images={images}
                        hasCheckmark={false}
                        grayScale={referenceObject.complete === false}
                        onOpened={() => props.onImageSliderOpened(referenceObject)}
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                    />
                </div>
                <div className="form-grid-item-body" onClick={() => props.onClickChange(referenceObject)}>
                    <div className="row">
                        <div className="col-12">
                            <div
                                className={classNames('grid-item-title', {
                                    'grid-item-title-with-avatar': !isCompact,
                                })}
                            >
                                {!isCompact && (
                                    <div
                                        className={classNames(
                                            'grid-item-title-avatar grid-item-title-avatar-icon-pin',
                                            {active: props.isMapSelected}
                                        )}
                                    />
                                )}
                                <p>
                                    <ReferenceObjectAddress
                                        referenceObject={referenceObject}
                                        referenceObjectAnswer={null}
                                    />
                                </p>
                            </div>
                        </div>
                        {props.teaser ? (
                            <div className="col-12">{props.teaser}</div>
                        ) : (
                            <>
                                <div className="col-12" style={{height: props.alertWrapperHeight}}>
                                    {referenceObject?.hasWarning ? (
                                        <span className="form-grid-item-alert" ref={alertRef}>
                                            <span className="ion-md-alert">&nbsp;</span>
                                            {referenceObject?.warning}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="col-12">
                                    <ReferenceObjectDetails
                                        appraisal={props.appraisal}
                                        questionSet={props.questionSet}
                                        referenceObjectId={
                                            referenceObject?.id ??
                                            referenceObject?.postalCode +
                                                referenceObject?.houseNumber +
                                                referenceObject?.letter
                                        }
                                        referenceSetType={props.setDefinition.type}
                                        referenceValuation={props.setDefinition.valuation}
                                        referenceSurfaceArea={props.setDefinition.surfaceArea}
                                        referencePlotArea={props.setDefinition.plotArea}
                                        referenceBuildYear={props.setDefinition.buildYear}
                                        referenceVolume={props.setDefinition.volume}
                                        referenceEnergyLabel={props.setDefinition.energyLabel}
                                        referenceObjectType={props.setDefinition.objectType}
                                        floorArea={referenceObject?.floorArea ?? null}
                                        plotArea={referenceObject?.plotArea ?? null}
                                        buildYear={referenceObject?.buildYear ?? null}
                                        objectType={referenceObject?.objectType ?? null}
                                        energyLabel={referenceObject?.energyLabel ?? null}
                                        volume={referenceObject?.volume ?? null}
                                        indexedPriceRange={
                                            props.setDefinition.type && referenceObject
                                                ? getReferenceIndexedObjectPrice(
                                                      props.setDefinition.type,
                                                      referenceObject
                                                  )
                                                : null
                                        }
                                        priceRange={
                                            props.setDefinition.type && referenceObject
                                                ? getReferenceUnindexedObjectPrice(
                                                      props.setDefinition.type,
                                                      referenceObject
                                                  )
                                                : null
                                        }
                                        period={
                                            referenceObject?.saleQuarter && !isEmpty(referenceObject.saleQuarter)
                                                ? referenceObject.saleQuarter.length > 8
                                                    ? format(new Date(referenceObject.saleQuarter), 'dd-MM-yyyy')
                                                    : 'Q' + referenceObject.saleQuarter
                                                : '-'
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {!isCompact && (
                    <div className="form-grid-item-footer d-flex justify-content-between">{renderButtons()}</div>
                )}
            </div>
        </>
    );
});
