import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';
import {isEmpty, isNumeric} from '../../../../../../support/util';

import {AlternativeAdaptedDefaultAnswer} from '../components/alternative_adapted_default_answer';
import {Label} from '../components/label';
import NumberFormat from 'react-number-format';
import {ValidationMessages} from '../components/validation_messages';
import {classNames} from '../../../../../../support/classnames';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {isMobile} from '../../../../../../support/check_mobile';
import {observer} from 'mobx-react';
import {CompactEditable} from '../../../components/compact_editable';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const IntUnknownQuestion: React.FC<OwnProps> = observer(function IntUnknownQuestion(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));
    if (presenter.isHidden) {
        return null;
    }

    function renderCompact() {
        return (
            <>
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    disableHistory
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <div>
                    {presenter.answer?.contents?.toLowerCase() === 'onbekend'
                        ? 'Onbekend'
                        : presenter.answer?.contents || <i>Niet ingevuld</i>}
                </div>
            </>
        );
    }

    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'automation-filled': presenter.filledByAutomator !== null,
            })}
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
        >
            <CompactEditable
                renderingContext={props.renderingContext}
                compact={() => renderCompact()}
                question={props.question}
                answer={presenter.answer}
            >
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <AlternativeAdaptedDefaultAnswer
                    adaptedDefaultValue={props.adaptedDefaultValue}
                    currentAnswer={presenter.answer}
                    disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                    onChange={(value) => presenter.onChange(value)}
                />
                {isMobile() ? (
                    <div className="row">
                        <div className="col-6">
                            <input
                                id={presenter.name}
                                name={presenter.name}
                                className="form-control"
                                type="number"
                                step="1"
                                pattern="\d*"
                                inputMode="numeric"
                                disabled={
                                    presenter.isDisabled || presenter.answer?.contents?.toLowerCase() === 'onbekend'
                                }
                                value={
                                    presenter.answer && presenter.answer.contents !== null
                                        ? presenter.answer.contents
                                        : ''
                                }
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (isEmpty(value)) {
                                        presenter.onChange(value, false);
                                    } else if (isNumeric(value)) {
                                        presenter.onChange(value, false);
                                    }
                                }}
                                onBlur={() => presenter.onTouched()}
                            />
                        </div>
                        <div className="col-6">
                            <input
                                id={'checkbox_' + presenter.name}
                                name={'checkbox_' + presenter.name}
                                className="form-control"
                                type="checkbox"
                                checked={presenter.answer?.contents?.toLowerCase() === 'onbekend'}
                                disabled={props.disabled || presenter.isDisabled}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        presenter.onChange('Onbekend');
                                    } else {
                                        presenter.onChange('');
                                    }
                                }}
                            />
                            <label htmlFor={'checkbox_' + presenter.name}>Onbekend</label>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-6">
                            <NumberFormat
                                value={
                                    presenter.answer && presenter.answer.contents !== null
                                        ? presenter.answer.contents
                                        : ''
                                }
                                displayType="input"
                                type="text"
                                className="form-control"
                                decimalScale={0}
                                allowNegative={false}
                                disabled={
                                    presenter.isDisabled || presenter.answer?.contents?.toLowerCase() === 'onbekend'
                                }
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    if (isEmpty(event.target.value)) {
                                        presenter.onChange(event.target.value, false);
                                    } else if (isNumeric(event.target.value)) {
                                        presenter.onChange(event.target.value, false);
                                    }
                                }}
                                onBlur={() => presenter.onTouched()}
                            />
                        </div>
                        <div className="col-6">
                            <input
                                id={'checkbox_' + presenter.name}
                                name={'checkbox_' + presenter.name}
                                className="form-control"
                                type="checkbox"
                                checked={presenter.answer?.contents?.toLowerCase() === 'onbekend'}
                                disabled={props.disabled || presenter.isDisabled}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        presenter.onChange('Onbekend');
                                    } else {
                                        presenter.onChange('');
                                    }
                                }}
                            />
                            <label htmlFor={'checkbox_' + presenter.name}>Onbekend</label>
                        </div>
                    </div>
                )}
            </CompactEditable>

            <ValidationMessages
                disabled={props.disabled || presenter.isDisabled}
                alignLeft
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={presenter.answer}
                validationMessages={filterValidationMessages(
                    props.question,
                    presenter.answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
        </div>
    );
});
