import React from 'react';
import {classNames} from '../support/classnames';

export interface PopupCardProps {
    goBack?: () => void;
    goForward?: () => void;
    active: boolean;
    children?: React.ReactNode;
    whiteArrows?: boolean;
}

export function PopupCard({goBack, goForward, active, children, whiteArrows}: PopupCardProps) {
    return (
        <div className={classNames('card card-parent popup-card', {visible: active})}>
            {children}
            <div className="popup-card-footer">
                {goBack ? (
                    <a
                        className={classNames('popup-card-back ion-md-arrow-round-back', {
                            'popup-card-white-arrow': whiteArrows,
                        })}
                        onClick={goBack}
                    />
                ) : null}
                {goForward ? (
                    <a
                        className={classNames('popup-card-forward ion-md-arrow-round-forward', {
                            'popup-card-white-arrow': whiteArrows,
                        })}
                        onClick={goForward}
                    />
                ) : null}
            </div>
        </div>
    );
}
