import {AddFileOptions, UploadResult, Uppy, UppyFile} from '@uppy/core';

import {Appraisal} from '../../models/appraisal';
import {AttachmentProps} from '../../appraise/ui/content/questions/advanced/attachment_question_presenter';
import AwsS3 from '@uppy/aws-s3';
import {UploadInteractor} from './upload_interactor';
import {bugsnagClient} from '../../../support/bugsnag_client';
import {requestSignedUrl} from '../../../network/signing';
import {DutchLocale} from './internal/uppy_locale';
import {getFileName} from '../../../support/file_name';

export class S3UploadInteractor implements UploadInteractor {
    //Some common mimeTypes we might use
    private mimeTypesMap: Record<string, string | undefined> = {
        '.png': 'image/png',
        '.jpg': 'image/jpeg',
        '.jpeg': 'image/jpeg',
        '.bmp': 'image/bmp',
        '.gif': 'image/gif',
        '.webp': 'image/webp',
        '.pdf': 'application/pdf',
    };

    constructor(private appraisal: Appraisal) {}

    public async upload(
        file: File,
        options: {
            fileTypes?: string[];
            progressCallback?: (progress: number) => void;
            metaData?: Record<string, unknown>;
        }
    ): Promise<UploadResult<AttachmentProps>> {
        const formattedFileTypes: string[] = [];
        // To support both .pdf and .PDF
        if (options.fileTypes) {
            for (const fileType of options.fileTypes) {
                formattedFileTypes.push(fileType);
                formattedFileTypes.push(fileType.toUpperCase());

                const mimeType = this.mimeTypesMap[fileType.toLowerCase()];
                if (mimeType) {
                    formattedFileTypes.push(mimeType);
                }
            }
        }

        const uppy: Uppy = new Uppy({
            id: 'appraisal-' + this.appraisal.id + '-' + file.name,
            debug: process.env.DEBUG === 'true',
            autoProceed: true,
            restrictions: {
                maxFileSize: 15728640, // 15 * 1,048,576 bytes.
                maxNumberOfFiles: null,
                minNumberOfFiles: null,
                allowedFileTypes: formattedFileTypes.length > 0 ? formattedFileTypes : null,
            },
            locale: DutchLocale,
        }).use(AwsS3, {
            getUploadParameters: async (uppyFile: UppyFile) => {
                try {
                    const params = await requestSignedUrl(uppyFile);
                    return {
                        ...params,
                        headers: {
                            'Cache-Control': 'public, max-age=604800, immutable',
                            'Content-Type': file.type,
                            ...(params.headers ?? {}),
                        },
                    };
                } catch (error) {
                    console.error('Generating presignedUrl failed', error);
                    bugsnagClient?.notify(error, {
                        metaData: {
                            name: getFileName(file),
                            data: file,
                            fileType: file.type,
                            meta: {
                                uuid: uppyFile.id,
                            },
                        },
                    });
                    throw error;
                }
            },
        });

        if (options.progressCallback) {
            uppy.on('upload-progress', (_, progress) => {
                if (progress.bytesTotal === 0) {
                    options.progressCallback?.(0);
                } else {
                    options.progressCallback?.(progress.bytesUploaded / progress.bytesTotal);
                }
            });
        }

        try {
            const uppyFile: AddFileOptions = {
                name: getFileName(file),
                data: file,
                meta: options.metaData,
            };
            if (file.type) {
                uppyFile.type = file.type;
            }
            uppy.addFile(uppyFile);
        } catch (error) {
            bugsnagClient?.notify(error, {
                metaData: {
                    name: getFileName(file),
                    data: file,
                    fileType: file.type,
                    meta: options.metaData,
                },
            });
            throw error;
        }

        const uploadResult = (await uppy.upload()) as UploadResult<AttachmentProps>;

        uppy.cancelAll();
        uppy.close();

        return uploadResult;
    }
}
