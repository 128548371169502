import {getUrlFromImageUrlPair, ImageUrlPair} from '../../../../../../../../models/image_url_pair';
import {ReferenceObject} from '../models/reference_object';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {buildImageUrls} from '../../interactors/image_urls';

export function referenceObjectImageUrlPairs(
    referenceObject: ReferenceObject,
    referenceObjectAnswer: ReferenceObjectAnswer | null
): ImageUrlPair[] {
    if (referenceObjectAnswer !== null) {
        return buildImageUrls(
            referenceObjectAnswer.photoUrl,
            referenceObjectAnswer.referenceObject.vooraanzicht,
            referenceObjectAnswer.referenceObject.adres.straat,
            referenceObjectAnswer.referenceObject.adres.huisnummer,
            referenceObjectAnswer.referenceObject.adres.plaats,
            referenceObject.source,
            null,
            referenceObject?.images ?? null,
            referenceObjectAnswer.id ?? referenceObject.id
        );
    } else if (referenceObject !== null) {
        return buildImageUrls(
            null,
            getUrlFromImageUrlPair(referenceObject.frontview),
            referenceObject.street,
            referenceObject.houseNumber,
            referenceObject.city,
            referenceObject.source,
            null,
            referenceObject.images,
            referenceObject.id
        );
    }

    return [];
}

export function referenceObjectInSaleImageUrlPairs(
    referenceObject: ReferenceObject,
    referenceObjectAnswer: ReferenceObjectAnswer | null
) {
    return referenceObjectImageUrlPairs(referenceObject, referenceObjectAnswer);
}

export function referenceObjectInRentImageUrls(
    referenceObject: ReferenceObject,
    referenceObjectAnswer: ReferenceObjectAnswer | null
) {
    return referenceObjectInSaleImageUrlPairs(referenceObject, referenceObjectAnswer);
}
