import * as React from 'react';
import {Appraisal} from '../../../../../../models/appraisal';
import {Question} from '../../../../../../models/question';
import {QuestionSet} from '../../../../../../models/question_set';
import {QuestionType} from '../../../../../../enum/question_type';
import {ValidationError} from '../../../../../../models/validation_error';
import {observer} from 'mobx-react';
import {AdaptedDefaultValuesMap} from '../../../../../../models/adapted_default_values_map';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {EnergeticOverviewPresenter} from './energetic_overview_presenter';
import {classNames} from '../../../../../../../support/classnames';

interface OwnProps {
    appraisal: Appraisal;
    question: Question;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    disabled?: boolean;
    iteration?: string;
    parentAnswerUuid?: string;
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
    disableAdaptedValueContainer?: boolean;
    hiddenQuestionTypes: QuestionType[];
}

export const EnergeticOverview: React.FC<OwnProps> = observer(function EnergeticOverview(props) {
    const presenter = usePresenter(
        (container) =>
            new EnergeticOverviewPresenter(
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.questionEffectsInteractor(props.appraisal, props.questionSet),
                container.business.buildYearProvider(props.appraisal, props.questionSet)
            )
    );

    return (
        <>
            {Object.entries(presenter.filledGroups).map(([group, filledGroup], i) => (
                <React.Fragment key={i}>
                    <h2>{group}</h2>
                    <ul className="list-circles">
                        {filledGroup.map((group, i) => (
                            <li key={i}>
                                <span className="list-avatar ion-md-hammer" />
                                <span className="list-title">
                                    <strong>{group.label}</strong>
                                    <span className="thin">
                                        &nbsp;{group.installMoment ? ` - ${group.installMoment}` : ''}
                                    </span>
                                </span>
                                <small className="list-details">
                                    <span style={{whiteSpace: 'pre-wrap'}}>{group.description}</span>
                                    {group.notes}
                                </small>
                                <span className="list-options">
                                    <span
                                        className={classNames('badge', {
                                            'badge-primary': group.availability !== 'Afwezig',
                                            'badge-secondary': group.availability === 'Afwezig',
                                        })}
                                    >
                                        {group.availability}
                                    </span>
                                </span>
                            </li>
                        ))}
                    </ul>
                </React.Fragment>
            ))}
        </>
    );
});
