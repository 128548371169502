export enum GroupType {
    CONSTRUCTION_COST = 'construction_cost',
    SURFACE_AREA_ADDITIVE = 'surface_area_additive',
    SURFACE_AREA_DEDUCTIVE = 'surface_area_deductive',
    DESCRIPTION_BATHROOM = 'description_bathroom',
    DESCRIPTION_BATHROOM_2 = 'description_bathroom_2',
    DESCRIPTION_BATHROOM_3 = 'description_bathroom_3',
    DESCRIPTION_KITCHEN = 'description_kitchen',
    DESCRIPTION_KITCHEN_2 = 'description_kitchen_2',
    DESCRIPTION_KITCHEN_3 = 'description_kitchen_3',
    OBJECT_PARTS_OBJECT_SUB_TYPE = 'object_parts_object_sub_type',
    OBJECT_PARTS_EXTERNAL = 'object_parts_external',
    OBJECT_PARTS_GARDEN = 'object_parts_garden',
    OBJECT_PARTS_UNUSUAL_OUTSIDE = 'object_parts_unusual_outside',
    OBJECT_PARTS_OTHER = 'object_parts_other',
    OBJECT_PARTS_BUILD_TYPE = 'object_parts_build_type',
    OBJECT_PARTS_FLOOR_TYPE = 'object_parts_floor_type',
    OBJECT_PARTS_FLOOR_NUMBER = 'object_parts_floor_number',
    OBJECT_PARTS_FLOOR_NAME = 'object_parts_floor_name',
    OBJECT_PARTS_WALLS_TYPE = 'object_parts_walls_type',
    OBJECT_PARTS_ROOF_TYPE = 'object_parts_roof_type',
    OBJECT_PARTS_ROOF_MATERIAL = 'object_parts_roof_material',
    OBJECT_PARTS_WINDOW_FRAME = 'object_parts_window_frame',
    OBJECT_PARTS_WINDOW_TYPE = 'object_parts_window_type',
    OBJECT_PARTS_HEATING_SYSTEM = 'object_parts_heating_system',
    OBJECT_PARTS_WATER_HEATING_SYSTEM = 'object_parts_water_heating_system',
    OBJECT_PARTS_HEATING_METHOD = 'object_parts_heating_method',
    OBJECT_PARTS_VENTILATION_SYSTEM = 'object_parts_ventilation_system',
    OBJECT_PARTS_VENTILATION_METHOD = 'object_parts_ventilation_method',
    OBJECT_PARTS_ELECTRIC = 'object_parts_electric',
    OBJECT_PARTS_ELECTRIC_EARTH_LEAKAGE = 'object_parts_electric_earth_leakage',
    OBJECT_PARTS_ELECTRIC_GROUPS = 'object_parts_electric_groups',
    OBJECT_PARTS_OTHER_PARTS = 'object_parts_other_parts',
    OBJECT_PARTS_OTHER_AMENITIES = 'object_parts_other_amenities',
    OBJECT_PARTS_ENERGY_SAVING_AMENITIES = 'object_parts_other_energy_saving_amenities',
    OBJECT_PARTS_CUSTOM = 'object_parts_custom',
    HOUSE_LAYOUT_ROOMS_1 = 'house_layout_rooms_1',
    HOUSE_LAYOUT_ROOMS_2 = 'house_layout_rooms_2',
    HOUSE_LAYOUT_ROOMS_3 = 'house_layout_rooms_3',
    HOUSE_LAYOUT_ROOMS_4 = 'house_layout_rooms_4',
    HOUSE_LAYOUT_ROOMS_5 = 'house_layout_rooms_5',
    HOUSE_LAYOUT_ROOMS_6 = 'house_layout_rooms_6',
    HOUSE_LAYOUT_ROOMS_7 = 'house_layout_rooms_7',
    HOUSE_LAYOUT_ROOMS_8 = 'house_layout_rooms_8',
    HOUSE_LAYOUT_SUB_TYPE = 'house_layout_sub_type',
    HOUSE_LAYOUT_SUB_STYLE = 'house_layout_sub_style',
    HOUSE_LAYOUT_SUB_HAS_SUPPLIES = 'house_layout_sub_has_supplies',
    HOUSE_LAYOUT_SUB_SUPPLIES = 'house_layout_sub_supplies',
    HOUSE_LAYOUT_ROOMS_CUSTOM = 'house_layout_rooms_custom',
}
