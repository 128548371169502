import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/i18n/nl-nl';
import '@toast-ui/editor/dist/toastui-editor.css';
import {EditorPlugin} from '@toast-ui/editor/types/editor';
import {ToolbarItemOptions} from '@toast-ui/editor/types/ui';
import {BlockTypes} from './blocks';
import {blockPlugin, blockToolbarButton} from './block_plugin';
import {colorPlugin} from './color_plugin';
import {fieldPlugin, fieldToolbarButton} from './field_plugin';

declare const EMAIL_MARKDOWN_EDITOR: EmailMarkdownEditorData;

export interface EmailMarkdownEditorData {
    template: {
        key: string;
        name: string;
        type: 'email' | 'sms';
        fields: TemplateField[];
        blocks: TemplateBlock[];
        allowDisable: boolean;
    };
}

export interface TemplateField {
    key: string;
    name: string;
    required: boolean;
}

export interface TemplateBlock {
    type: BlockTypes;
    key: string;
    name: string;
    required: boolean;
    defaultContent: string | null;
}

let changed = false;

export function renderEmailMarkdownEditor(element: HTMLElement) {
    const val = $('#content').val() ?? '';

    const toolbarItems: Array<Array<string | ToolbarItemOptions>> = [[]];
    const plugins: EditorPlugin[] = [blockPlugin, fieldPlugin];

    if (EMAIL_MARKDOWN_EDITOR.template.blocks.length > 0) {
        toolbarItems[0].push(blockToolbarButton(() => editor));
    }
    if (EMAIL_MARKDOWN_EDITOR.template.fields.length > 0) {
        toolbarItems[0].push(fieldToolbarButton(() => editor));
    }

    if (EMAIL_MARKDOWN_EDITOR.template.type !== 'sms') {
        toolbarItems.push(['heading', 'bold', 'italic', 'strike'], ['hr', 'ul', 'ol', 'indent', 'outdent']);
        plugins.push(colorPlugin);
    }

    const editor: Editor = new Editor({
        el: element,
        height: '500px',
        initialEditType: 'wysiwyg',
        hideModeSwitch: true,
        plugins,
        toolbarItems,
        language: 'nl-NL',
        initialValue: val as string,
    });

    editor.on('change', () => {
        let content = editor.getMarkdown();

        // Replace escaped underscores in field strings
        content = content.replace(/{{[^}]+}}/g, (str) => {
            return str.replace(/\\_/g, '_');
        });

        $('#content').val(content);
        changed = true;
    });

    window.onbeforeunload = () => {
        if (changed) {
            return 'Er zijn niet opgeslagen wijzingen, weet je zeker dat je weg wil navigeren?';
        }
    };
}

$('input[name="mode"]').on('change', function (radio) {
    changed = true;

    const val = $(radio.target).val();

    $('.mail-template-block').hide();
    $(`#mail-template-${val}`).show();

    window.parent.postMessage({mailTemplateMode: {key: EMAIL_MARKDOWN_EDITOR.template.key, mode: val}});
});

$('#frame-close-button').on('click', () => {
    window.parent.postMessage({close: true});
});

$('footer > a, footer > button').on('click', () => {
    changed = false;
});
