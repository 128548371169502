import {
    ReferenceObjectDataWithSet,
    createReferenceObjectDataForAnswer,
} from '../../internal/create_reference_object_data';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../../../../../../support/composite_subscription';
import {SimpleQuestionPresenter} from '../../../../../simple/simple_question_presenter';
import {V3ReferenceSetsProvider} from '../../internal/reference_sets/reference_sets_provider';
import {map} from 'rxjs/operators';
import {
    CompareValuesProvider,
    ReferenceObjectCompareValues,
} from '../../../../../../../../../business/compare_values/compare_values_provider';
import {BadgeContext} from '../../../../../../../../../enum/badge_context';

export class EditableAddressWithMeasurementDetailsQuestionPresenter extends SimpleQuestionPresenter {
    @observable public editing = false;
    @observable public referenceObjectDataWithSet: ReferenceObjectDataWithSet | null = null;
    @observable public compareValues: Record<ReferenceObjectCompareValues, string | number | null> | null = null;
    @observable public compareBadges: Record<ReferenceObjectCompareValues, BadgeContext | null> | null = null;

    private subscriptions = new CompositeSubscription();
    constructor(
        private referenceSetsProvider: V3ReferenceSetsProvider,
        private compareValuesProvider: CompareValuesProvider,
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public mount() {
        super.mount();

        this.subscriptions.add(
            this.referenceSetsProvider
                .referenceSets()
                .pipe(
                    map((referenceSets) =>
                        createReferenceObjectDataForAnswer(
                            referenceSets,
                            this.question.uuid,
                            this.parentAnswerUuid ?? null
                        )
                    )
                )
                .subscribe((referenceObjectDataWithSet) => {
                    runInAction(() => {
                        this.referenceObjectDataWithSet = referenceObjectDataWithSet;
                    });
                })
        );

        this.subscriptions.add(
            this.compareValuesProvider
                .compareValuesByAnswerIdentifiersStream(
                    this.question.uuid,
                    this.parentAnswerUuid ?? null,
                    this.iteration ?? null
                )
                .subscribe((compareValues) => {
                    runInAction(() => {
                        this.compareValues = compareValues;
                    });
                })
        );

        this.subscriptions.add(
            this.compareValuesProvider
                .badgesByAnswerIdentifiersStream(
                    this.question.uuid,
                    this.parentAnswerUuid ?? null,
                    this.iteration ?? null
                )
                .subscribe((badges) => {
                    runInAction(() => {
                        this.compareBadges = badges;
                    });
                })
        );
    }

    public unmount() {
        super.unmount();

        this.subscriptions.clear();
    }

    @action
    public activateEdit = () => {
        this.editing = true;
    };

    @action
    public deactivateEdit = () => {
        this.editing = false;
    };
}
