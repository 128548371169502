import {action, makeObservable, observable, runInAction} from 'mobx';
import {Presenter} from '../../support/presenter/presenter';
import {GeoApi} from './network/geo_api';
import {CreateAppraisalProps} from './support/component';
import GoogleMapReact, {Bounds} from 'google-map-react';
import {mapStyles} from './components/map_styles';
import {getCenterLocation, Location} from './components/center_location';
import {GeoAddress} from './models/geo_address';

declare let CREATE_APPRAISAL_PROPS: CreateAppraisalProps | null;
declare let CREATE_APPRAISAL_NEARBY_ADDRESS: GeoAddress | null;
declare let CREATE_APPRAISAL_COORDINATES: SelectedMapCoordinates | null;

export interface MapState {
    centerLocation: Location | null;
    defaultCenterLocation: Readonly<Location | null>;
    bounds: null | Bounds;
    selectedCoordinates: SelectedMapCoordinates | null;
    nearbyAddress: GeoAddress | null;
    draggable: boolean;
}

export interface SelectedAddressInput {
    postalCode: string | null;
    houseNumber: string | null;
    letter: string | null;
    street: string | null;
    projectName: string | null;
    plotNumber: string | null;
    city: string | null;
}

interface SelectedMapCoordinates {
    lat: number | null;
    lng: number | null;
}

interface GoogleMap {
    setZoom(zoom: number): void;
    panTo(coords: {lat: number; lng: number}): void;
}

export class AddressGeoMapPresenter implements Presenter {
    private createAppraisalProps: CreateAppraisalProps | null = CREATE_APPRAISAL_PROPS;
    private centerLocation = this.getCenterLocation();
    public mapRef: null | GoogleMap = null;

    @observable public isDisabled = false;
    @observable public errorMessage: string | null = null;
    @observable.ref public selectedAddressInput: SelectedAddressInput = {
        postalCode: null,
        houseNumber: null,
        letter: null,
        street: null,
        projectName: null,
        plotNumber: null,
        city: null,
    };
    @observable.ref public fetchedAddress?: GeoAddress;

    @observable.ref public mapState: MapState = {
        centerLocation: this.centerLocation,
        defaultCenterLocation: this.centerLocation,
        bounds: null,
        selectedCoordinates: CREATE_APPRAISAL_COORDINATES,
        nearbyAddress: null,
        draggable: true,
    };

    constructor(private geoApi: GeoApi) {
        makeObservable(this);

        runInAction(() => {
            this.isDisabled = CREATE_APPRAISAL_PROPS?.disabled ?? false;
            this.mapState = {
                ...this.mapState,
                nearbyAddress: CREATE_APPRAISAL_NEARBY_ADDRESS,
            };
            this.selectedAddressInput = {
                postalCode: CREATE_APPRAISAL_NEARBY_ADDRESS?.postalCode ?? null,
                houseNumber: CREATE_APPRAISAL_NEARBY_ADDRESS?.houseNumber ?? null,
                letter: CREATE_APPRAISAL_NEARBY_ADDRESS?.letter ?? null,
                street: CREATE_APPRAISAL_NEARBY_ADDRESS?.street ?? null,
                projectName: CREATE_APPRAISAL_PROPS?.projectName ?? null,
                plotNumber: CREATE_APPRAISAL_PROPS?.plotNumber ?? null,
                city: CREATE_APPRAISAL_NEARBY_ADDRESS?.city ?? null,
            };
            if (this.mapState.selectedCoordinates?.lat && this.mapState.selectedCoordinates?.lng) {
                this.changeMapState(
                    this.mapState.selectedCoordinates.lat,
                    this.mapState.selectedCoordinates.lng,
                    null,
                    null,
                    this.mapState.draggable
                );
            }
        });
    }

    public async onChangeCoordinate(lat: number, lng: number) {
        this.changeMapState(
            lat,
            lng,
            this.mapState.centerLocation?.zoom ?? null,
            this.mapState.bounds,
            this.mapState.draggable
        );

        const address = await this.geoApi.findByCoordinates(lat, lng);

        runInAction(() => {
            this.errorMessage = null;
            if (address !== null) {
                // We only change the postal code and city
                this.mapState.nearbyAddress = address;
                this.selectedAddressInput = {
                    ...this.selectedAddressInput,
                    postalCode: address.postalCode,
                    houseNumber: null,
                    letter: null,
                    street: address.street,
                    city: address.city,
                };
            } else {
                this.errorMessage = 'Er is geen adres voor deze locatie gevonden. Probeer a.u.b. opnieuw.';
            }
        });
    }

    public onMouseDown() {
        this.changeDraggableMapState(false);
    }

    public onMouseMove(lat: number, lng: number) {
        this.changeMapState(lat, lng, null, null, false);
    }

    public onMouseUp(lat: number, lng: number) {
        this.onChangeCoordinate(lat, lng);
        this.changeDraggableMapState(true);
    }

    public onSetNearbyAddress<TKey extends keyof SelectedAddressInput>(key: TKey, value: SelectedAddressInput[TKey]) {
        runInAction(() => {
            this.selectedAddressInput = {
                ...this.selectedAddressInput,
                [key]: value,
            };
        });
    }

    public async onChangeNearbyAddress() {
        if (
            this.selectedAddressInput?.postalCode === null ||
            (this.fetchedAddress?.houseNumber && !this.selectedAddressInput.houseNumber)
        ) {
            return;
        }

        const address = await this.geoApi.findByAddress(
            this.selectedAddressInput.postalCode,
            this.selectedAddressInput.houseNumber ?? '',
            this.selectedAddressInput.letter ?? ''
        );

        if (address === null) {
            // No errormessage to user, it should be possible to select a not found address.
            return;
        }

        runInAction(() => {
            this.errorMessage = null;
            this.mapState.nearbyAddress = address;
            this.selectedAddressInput = {
                ...this.selectedAddressInput,
                postalCode: address.postalCode,
                houseNumber: address.houseNumber,
                street: address.street,
                letter: address.letter,
                city: address.city,
            };

            this.fetchedAddress = address;

            this.changeMapState(
                address.lat,
                address.lng,
                this.mapState.centerLocation?.zoom ?? null,
                this.mapState.bounds,
                true
            );
            this.mapRef?.setZoom(19);
            this.mapRef?.panTo({lat: address.lat, lng: address.lng});
        });
    }

    public getCenterLocation() {
        return getCenterLocation(
            {
                lat: this.createAppraisalProps?.userLatitude ?? 0,
                lng: this.createAppraisalProps?.userLongitude ?? 0,
            },
            []
        );
    }

    @action
    public changeMapState(
        lat: number,
        lng: number,
        zoom: number | null,
        bounds: Bounds | null,
        draggable: boolean | null
    ) {
        this.mapState = {
            ...this.mapState,
            draggable: draggable ?? true,
            selectedCoordinates: {lat, lng},
            centerLocation: {
                center: {
                    lat: lat,
                    lng: lng,
                },
                zoom: zoom ?? 16,
            },
            bounds,
        };
    }

    @action
    public changeDraggableMapState(draggable: boolean) {
        this.mapState = {
            ...this.mapState,
            draggable: draggable,
        };
    }

    public createMapOptions(maps: GoogleMapReact.Maps) {
        return {
            styles: mapStyles,
            zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_CENTER,
                style: maps.ZoomControlStyle.SMALL,
            },
            mapTypeControl: false,
            panControl: true,
            disableDefaultUI: false,
            fullscreenControl: false,
            gestureHandling: 'greedy',
        };
    }

    public mount() {
        //
    }

    public unmount() {
        //
    }
}
