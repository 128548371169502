import {makeObservable, runInAction, observable} from 'mobx';
import {pollTaskStatusUrl} from '../../../app/task';
import {AppraisalProvider} from '../../../appraising/business/appraisal_provider';
import {Appraisal} from '../../../appraising/models/appraisal';
import {AppraisalAttachmentDetailItem} from '../../../appraising/models/attachment_details';
import {AppraisalApi} from '../../../appraising/network/appraisal_api';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {Presenter} from '../../../support/presenter/presenter';
import {AutomatorDispatchTime} from '../../preferences/appraisal_costs';

export class AttachmentsWidgetPresenter implements Presenter {
    @observable.ref public appraisal?: Appraisal;
    @observable.ref public attachments: AppraisalAttachmentDetailItem[] = [];
    @observable public loading = true;

    private subscriptions = new CompositeSubscription();

    constructor(private appraisalProvider: AppraisalProvider, private appraisalApi: AppraisalApi) {
        makeObservable(this);
    }

    public async mount(): Promise<void> {
        this.subscriptions.add(
            this.appraisalProvider.stream.subscribe((appraisal) =>
                runInAction(() => {
                    this.appraisal = appraisal;

                    this.fetchAttachments();
                })
            )
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public async markVisited(answerUuid: string) {
        if (!this.appraisal) return;

        await this.appraisalApi.markAttachmentVisited(this.appraisal.id, answerUuid);
    }

    private async fetchAttachments() {
        if (!this.appraisal) return;

        const isFinished = await this.updateAttachments();

        if (!isFinished) {
            let prevProgress = 0;
            pollTaskStatusUrl(
                `/ajax/tasks/${this.appraisal?.id}/automation/status?dispatch_time=${AutomatorDispatchTime.PRECHECK}`,
                (progress) => {
                    if (progress > prevProgress) {
                        this.updateAttachments();
                    }
                    prevProgress = progress;
                }
            )
                .then(() => {
                    this.fetchAttachments();
                })
                .catch(() => {
                    this.fetchAttachments();
                });
        }
    }

    private async updateAttachments() {
        if (!this.appraisal) return true;

        const {automationFinished, attachments} = await this.appraisalApi.getAttachments(this.appraisal.id);

        const attachmentDetails = attachments
            .filter((a) => a.detailItems.length > 0 && a.detailItems[a.detailItems.length - 1].currentFileUrl !== null)
            .map((a) => a.detailItems[a.detailItems.length - 1]);

        runInAction(() => {
            this.attachments = attachmentDetails;
            this.loading = !automationFinished;
        });

        return automationFinished;
    }
}
