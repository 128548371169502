import * as React from 'react';

import {FunctionComponent} from 'react';
import {classNames} from '../../../../support/classnames';

interface OwnProps {
    children: React.ReactNode;
}

const mediaQueryList = window.matchMedia('(max-width: 1199px)');

export const Sidebar: FunctionComponent<OwnProps> = ({children}) => {
    const [isOpen, setIsOpen] = React.useState(!mediaQueryList.matches);

    const onMediaQueryListChange = React.useCallback(
        (e: MediaQueryListEvent) => {
            setIsOpen(!e.matches);
        },
        [setIsOpen]
    );
    React.useEffect(() => {
        mediaQueryList.addEventListener('change', onMediaQueryListChange);
        return () => mediaQueryList.removeEventListener('change', onMediaQueryListChange);
    }, []);

    return (
        <div
            className={classNames('card-progress d-flex collapsable', {
                open: isOpen,
            })}
        >
            <div className="scrollable">
                <div id="sidebar" className="page-part-configurator-sidebar">
                    {children}
                </div>
            </div>
            <button onClick={() => setIsOpen(!isOpen)} className="toggle-bar">
                &nbsp;
            </button>
        </div>
    );
};
