import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';

import {AdaptedDefaultValuesMap} from '../../../../../models/adapted_default_values_map';
import {Modal} from '../components/modal';
import {QuestionContainer} from '../question_container';
import {QuestionSet} from '../../../../../models/question_set';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {observer} from 'mobx-react';
import {QuestionLoader} from '../../../components/question_loader';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {
    questionSet: QuestionSet;
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
    children?: React.ReactNode;
}

export const ModalQuestion: React.FC<OwnProps> = observer(function ModalQuestion(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));
    const [isVisible, setIsVisible] = React.useState(false);
    if (presenter.isHidden) {
        return null;
    }

    const answer = presenter.answer;
    if (answer === undefined) {
        return <QuestionLoader />;
    }

    return (
        <div className="modal-trigger-container">
            <button className="btn-text-link ion-md-open modal-trigger" onClick={() => setIsVisible(!isVisible)}>
                {props.question.contents}
            </button>
            <Modal visible={isVisible} onHide={() => setIsVisible(false)} onShow={() => setIsVisible(true)}>
                <div className="modal-header">
                    <h2>{props.question.contents}</h2>
                </div>
                <div>
                    {findChildrenForQuestion(props.question, props.questionSet).map((child) => (
                        <QuestionContainer
                            key={child.uuid}
                            appraisal={props.appraisal}
                            question={child}
                            questionSet={props.questionSet}
                            parentAnswerUuid={answer.uuid}
                            validationErrors={props.validationErrors}
                            validationMessages={props.validationMessages}
                            forceShowValidationMessages={props.forceShowValidationMessages}
                            adaptedDefaultValues={props.adaptedDefaultValues}
                            disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                            hiddenQuestionTypes={props.hiddenQuestionTypes}
                            pagePartsSet={props.pagePartsSet}
                            activePagePart={props.activePagePart}
                            renderingContext={props.renderingContext}
                            questionRenderingData={props.questionRenderingData}
                        />
                    ))}
                    <div className="modal-footer">
                        {props.children}
                        <button
                            className="btn btn-primary btn-full ion-md-checkbox-outline"
                            type="button"
                            onClick={() => setIsVisible(false)}
                        >
                            Sluiten
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
});
