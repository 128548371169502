import {ConstructionCostsGroup, FlatConstructionCost} from './models/construction_costs';

import {AjaxDriver} from './driver/ajax_driver';
import {ConstructionCostSettings} from './models/construction_costs_settings';

export interface ConstructionCostsApi {
    all(): Promise<ConstructionCostsGroup[]>;
    put(constructionCosts: FlatConstructionCost): Promise<void>;
    delete(id: number): Promise<void>;
    updateSettings(constructionCostSettings: ConstructionCostSettings): Promise<void>;
    getSettings(): Promise<ConstructionCostSettings>;
}

export class DefaultConstructionCostsApi implements ConstructionCostsApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public all(): Promise<ConstructionCostsGroup[]> {
        return new Promise<ConstructionCostsGroup[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/admin/v1/construction-costs/all`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(await result.json());
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public put(constructionCost: FlatConstructionCost): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/admin/v1/construction-costs/put`, {
                    method: 'PUT',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        id: constructionCost.id,
                        price_cents: constructionCost.price,
                        price_cents_2: constructionCost.price2,
                        price_cents_3: constructionCost.price3,
                        price_cents_4: constructionCost.price4,
                        price_cents_5: constructionCost.price5,
                        group: constructionCost.group,
                        sub_group: constructionCost.subGroup,
                        type: constructionCost.type,
                        sub_type: constructionCost.subType,
                        unit: constructionCost.unit,
                        description: constructionCost.description,
                        multipliable: constructionCost.multipliable,
                        labels: constructionCost.labels,
                    }),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(await result.json());
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public delete(id: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/admin/v1/construction-costs/delete`, {
                    method: 'PUT',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        id: id,
                    }),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(await result.json());
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getSettings(): Promise<ConstructionCostSettings> {
        return new Promise<ConstructionCostSettings>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/admin/v1/construction-costs/settings`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(await result.json());
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public updateSettings(constructionCostSettings: ConstructionCostSettings): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/admin/v1/construction-costs/settings`, {
                    method: 'PUT',
                    credentials: 'same-origin',
                    body: JSON.stringify(constructionCostSettings),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(await result.json());
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
