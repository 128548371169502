import {action, observable, makeObservable} from 'mobx';

import {Answer} from '../../../../../../../../../models/answer';
import {AnswerController} from '../../../../../../../../../business/answering/answer_controller';
import {BadgeContext} from '../../../../../../../../../enum/badge_context';
import {CompositeSubscription} from '../../../../../../../../../../support/composite_subscription';
import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {TechnicalReference} from '../../../../../../../../../enum/technical_reference';
import {isEmpty} from '../../../../../../../../../../support/util';

export class BuildyearBadgeAdapterPresenter implements Presenter {
    @observable public label: string | null = null;
    @observable public badgeContext: BadgeContext | null = null;
    @observable public buildYear: string | null = null;
    private _questionSet: QuestionSet;
    private _answerController: AnswerController;
    private subscriptions = new CompositeSubscription();

    constructor(questionSet: QuestionSet, answerController: AnswerController) {
        makeObservable(this);
        this._questionSet = questionSet;
        this._answerController = answerController;
    }

    public mount(): void {
        const buildyearQuestion = this._questionSet.findQuestionByTechnicalReference(
            TechnicalReference.OBJECT_BUILD_YEAR
        );

        if (buildyearQuestion !== null) {
            this.subscriptions.add(
                this._answerController
                    .answersForQuestionUuidStream(buildyearQuestion.uuid)
                    .subscribe((answers) => this.onAnswersUpdate(answers))
            );
        }
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    private onAnswersUpdate(answers: Answer[]) {
        const answer = answers[0];
        this.buildYear = null;
        if (answer !== undefined && !isEmpty(answer.contents)) {
            this.buildYear = answer.contents;
        }
    }
}
