import {NetworkStatus, NetworkStatusProvider} from '../../../../../business/network_status_provider';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {AdaptedDefaultValuesInteractor} from '../../../../../business/adapted_default_values_interactor';
import {AdaptedDefaultValuesMap} from '../../../../../models/adapted_default_values_map';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {SimpleQuestionPresenter} from '../simple/simple_question_presenter';
import {findChildrenForQuestion} from '../../../support/question_filtering';

export class ModalWithAdaptedDefaultValuesQuestionPresenter extends SimpleQuestionPresenter {
    @observable public isModalVisible = false;
    @observable.ref public childAdaptedDefaultValues: AdaptedDefaultValuesMap | null = null;
    private networkStatus: NetworkStatus = NetworkStatus.OFFLINE;
    private subscriptions = new CompositeSubscription();

    constructor(
        isInitialVisible: boolean,
        private adaptedDefaultValuesInteractor: AdaptedDefaultValuesInteractor,
        private networkStatusProvider: NetworkStatusProvider,
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);

        makeObservable(this);
        this.isModalVisible = isInitialVisible;
    }

    public async mount() {
        super.mount();
        this.subscriptions.add(
            this.networkStatusProvider.status().subscribe((networkStatus) => {
                runInAction(() => {
                    this.networkStatus = networkStatus;
                });
            })
        );
    }

    public unmount(): void {
        super.unmount();
        this.subscriptions.clear();
    }

    @action
    public setModalVisibility = async (visible: boolean) => {
        runInAction(() => {
            this.isModalVisible = visible;
            this.childAdaptedDefaultValues = null;
        });

        if (visible === true) {
            if (this.networkStatus === NetworkStatus.ONLINE) {
                const childQuestions = findChildrenForQuestion(this.question, this.questionSet);
                const adaptedDefaultValues = await this.adaptedDefaultValuesInteractor.getAdaptedDefaultValues(
                    childQuestions.map((q) => q.uuid)
                );
                runInAction(() => {
                    this.childAdaptedDefaultValues = adaptedDefaultValues.reduce(
                        (acc: AdaptedDefaultValuesMap, adaptedDefaultValue) => {
                            acc[adaptedDefaultValue.questionUuid] = adaptedDefaultValue.content;
                            return acc;
                        },
                        {}
                    );
                });
            } else {
                runInAction(() => {
                    this.childAdaptedDefaultValues = {};
                });
            }
        }
    };
}
