import {Condition} from '../../models/condition';

export interface ApiCondition {
    id: number;
    question_uuid: string | null;
    compare_question_uuid: string | null;
    appraisal_field: string | null;
    value: string;
    condition: '==' | '!=';
}

export function apiConditionToCondition(apiCondition: ApiCondition): Condition {
    return {
        id: apiCondition.id,
        questionUuid: apiCondition.question_uuid,
        compareQuestionUuid: apiCondition.compare_question_uuid,
        appraisalField: apiCondition.appraisal_field,
        condition: apiCondition.condition,
        value: apiCondition.value,
    };
}
