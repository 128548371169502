import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from './simple_question_presenter';

import {DateQuestionPresenter} from './date_question_presenter';
import {Label} from '../components/label';
import {ValidationMessages} from '../components/validation_messages';
import {classNames} from '../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {CompactEditable} from '../../../components/compact_editable';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const DateQuestion: React.FC<OwnProps> = observer(function DateQuestion(props) {
    const presenter = usePresenter(
        (container) =>
            new DateQuestionPresenter(...simpleQuestionPresenterConstructorParametersFactory(props, container))
    );

    if (presenter.isHidden) {
        return null;
    }

    function renderCompact() {
        return (
            <>
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    disableHistory
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <div>{presenter.initialValue || <i>Niet ingevuld</i>}</div>
            </>
        );
    }
    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'automation-filled': presenter.filledByAutomator !== null,
            })}
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
        >
            <CompactEditable
                renderingContext={props.renderingContext}
                compact={() => renderCompact()}
                question={props.question}
                answer={presenter.answer}
            >
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text ion-md-calendar">&nbsp;</div>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="DD-MM-JJJJ"
                        value={presenter.initialValue}
                        onChange={(e) => presenter.onChange(e.target.value, false)}
                        onBlur={() => presenter.onTouched()}
                        readOnly={presenter.isDisabled || props.disabled}
                    />
                </div>
            </CompactEditable>
            <ValidationMessages
                alignLeft
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={presenter.answer}
                validationMessages={presenter.allValidationMessages(props.validationMessages)}
            />
        </div>
    );
});
