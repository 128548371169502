import React, {useMemo} from 'react';
import {
    ImageViewerComponent,
    ImageViewerComponentProps,
} from '../../../../../../../../../components/image_viewer/image_viewer';
import {PhotoSet} from './photo_sets';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';
import {observer} from 'mobx-react';
import {ImageViewerSelectorPresenter} from './image_viewer_selector_presenter';

interface OwnProps {
    activeSet?: PhotoSet;
    filteredLabels: string[] | null;
    setFilteredLabels: (labels: string[] | null) => void;
}

function formatLabel(label: string | null) {
    if (label === null) {
        return null;
    }

    if (label === 'streetview_vooraanzicht' || label === 'uploaded_vooraanzicht') {
        return 'Vooraanzicht';
    }

    if (label === 'floorplan_2d' || label === 'floorplan_3d') {
        return 'Plattegrond';
    }

    return label.charAt(0).toUpperCase() + label.slice(1);
}

const LabelSelector: React.FC<ImageViewerComponentProps & OwnProps> = observer(function LabelSelector({
    lightbox,
    images,
    activeSet,
    filteredLabels,
    setFilteredLabels,
}) {
    const presenter = usePresenter(
        (container) => new ImageViewerSelectorPresenter(container.business.modalConfigStackInteractor, lightbox)
    );

    const activeImage = useMemo(() => {
        if (!activeSet || !images || presenter.activeIndex === null || !images[presenter.activeIndex]) {
            return null;
        }

        const image = images[presenter.activeIndex];
        const url = typeof image === 'string' ? image : 'url' in image ? image.url : null;

        if (!url) {
            return null;
        }

        const activeImage = activeSet.images.find((img) => img.url === url);

        return activeImage ?? null;
    }, [presenter.activeIndex, activeSet, images]);

    const labels = useMemo(() => {
        const labels = new Map<string, string[]>();

        for (const image of activeSet?.images ?? []) {
            const formattedLabel = formatLabel(image.label);

            if (!formattedLabel || !image.label) {
                continue;
            }

            const labelList = labels.get(formattedLabel) ?? [];
            if (!labelList.includes(image.label)) {
                labelList.push(image.label);
            }

            labels.set(formattedLabel, labelList);
        }

        return labels;
    }, [activeSet]);

    if (presenter.hidden) {
        return null;
    }

    const activeLabel = activeImage ? formatLabel(activeImage.label) : null;

    return (
        <div>
            {activeLabel ? (
                <button
                    className="btn btn-sm btn-primary ion-md-funnel"
                    onClick={() =>
                        presenter.filterLabelsModal(labels, filteredLabels).then((newLabels) => {
                            setFilteredLabels(newLabels);
                        })
                    }
                >
                    {activeLabel}
                </button>
            ) : null}
        </div>
    );
});

export const getLabelSelectorComponent: (props: OwnProps) => ImageViewerComponent = (props) => ({
    name: 'label-selector',
    appendTo: 'bar',
    order: 6,
    className: 'photo-sets-toolbar-button',
    component: <LabelSelector {...props} />,
});
