import React from 'react';

import {classNames} from '../../../support/classnames';
import {diffToBadgeContext} from './badge_context_calculators';
import {PriceRangeType} from '../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/get_object_price';
import {BadgeContext} from '../../enum/badge_context';

interface OwnProps {
    compact?: boolean;
    floorAreaPrice: number | PriceRangeType;
    referenceSetFloorAreaPrice: number;
}

const formatPrice = (from: number): string => {
    return `€ ${from.toLocaleString('nl-NL')}/m²`;
};

export function FloorAreaPriceBadge(props: OwnProps) {
    let badge: BadgeContext | null = null;
    let diff: number | null = null;

    const price = Array.isArray(props.floorAreaPrice)
        ? (props.floorAreaPrice[0] + props.floorAreaPrice[1]) / 2
        : props.floorAreaPrice;

    if (props.referenceSetFloorAreaPrice !== null && props.referenceSetFloorAreaPrice !== 0 && Math.abs(price) !== 0) {
        diff = (100 * (price - props.referenceSetFloorAreaPrice)) / props.referenceSetFloorAreaPrice;
        badge = diffToBadgeContext(diff ?? 0);
    }

    if (props.referenceSetFloorAreaPrice === null || props.referenceSetFloorAreaPrice === 0) {
        return null;
    }

    // Check for absolute value to avoid "-0%"
    const fixedValue = Math.abs(diff ?? 0).toFixed(0) === '0' ? '0' : diff?.toFixed(2);

    return (
        <span className="badge-container">
            {badge && <span className={classNames('badge', badge)}>{fixedValue + '%'}</span>}
            {!props.compact ? `(${formatPrice(props.referenceSetFloorAreaPrice)})` : null}
        </span>
    );
}
