export interface SentenceBuilder {
    build(values: string[]): string;
}

export class DefaultSentenceBuilder implements SentenceBuilder {
    public build(values: string[]): string {
        if (values.length === 0) {
            return '';
        } else if (values.length === 1) {
            return values[0];
        }
        return values.slice(0, values.length - 1).join(', ') + ' en ' + values.slice(-1);
    }
}
