import {Answer} from '../../../models/answer';
import {QuestionSet} from '../../../models/question_set';
import {FloorQuestionType, RootGroupQuestionType} from '../../../enum/question_type';
import {getTotalFloorCountFromHouseGroupConfiguration} from '../../../appraise/ui/content/questions/support/house_group_question/house_group_default_configuration';
import {IMemoryStorage} from 'indexed-memory-storage';
import {isNumeric} from '../../../../support/util';
import {questionUuidIndex} from '../../../../support/memory_storage/default_observable_answer_memory_storage_factory';
import {Question} from '../../../models/question';

export class HouseFilter {
    constructor(private questionSet: QuestionSet) {}

    public filter(toBeFilteredAnswers: Answer[], answerStorage: IMemoryStorage<Answer>): Answer[] {
        const houseGroupAnswer = this.getHouseGroupAnswer(answerStorage);
        if (!houseGroupAnswer) {
            return toBeFilteredAnswers;
        }

        const floorCount = getTotalFloorCountFromHouseGroupConfiguration(houseGroupAnswer);

        return toBeFilteredAnswers.filter((answer) => !this.isDeleted(answer, floorCount, answerStorage));
    }

    private isDeleted(answer: Answer, floorCount: number, answerStorage: IMemoryStorage<Answer>): boolean {
        const floorAnswerIteration = this.findFloorIteration(answer, answerStorage);
        if (floorAnswerIteration) {
            if (isNumeric(floorAnswerIteration.iteration)) {
                // Only numeric floor answers are used
                return parseInt(floorAnswerIteration.iteration) > floorCount;
            }
            if (floorAnswerIteration.iteration === null && floorAnswerIteration.isAutomated) {
                // When a floor answer iteration is empty it indicates it was automated and contains incorrect data
                return true;
            }
        }

        if (answer.parentUuid !== null) {
            const parent = this.getParent(answer, answerStorage);
            return parent === null || this.isDeleted(parent, floorCount, answerStorage);
        }

        return false;
    }

    private findFloorIteration(answer: Answer, answerStorage: IMemoryStorage<Answer>): Answer | null {
        // Answers with a parent as floor-question contain a numeric iteration which contains
        // information about the floor
        const question = this.questionSet.findQuestionByUuid(answer.questionUuid);
        const parentQuestion = question?.parentUuid ? this.questionSet.findQuestionByUuid(question?.parentUuid) : null;
        if (parentQuestion && this.isFloorType(parentQuestion)) {
            return answer;
        }
        const parent = this.getParent(answer, answerStorage);
        if (parent) {
            return this.findFloorIteration(parent, answerStorage);
        }
        return null;
    }

    private getParent(answer: Answer, answerStorage: IMemoryStorage<Answer>): Answer | null {
        if (answer.parentUuid === null) {
            return null;
        }

        return answerStorage.get(answer.parentUuid);
    }

    private getHouseGroupAnswer(answerStorage: IMemoryStorage<Answer>): Answer | null {
        const houseQuestion =
            this.questionSet.findQuestionsByType(RootGroupQuestionType.HOUSE_GROUP_COMPACT)[0] ??
            this.questionSet.findQuestionsByType(RootGroupQuestionType.HOUSE_GROUP)[0] ??
            null;

        if (houseQuestion) {
            const byQuestionUuidSet = answerStorage.getByIndex(questionUuidIndex, [houseQuestion.uuid]) ?? null;
            const resultArray = byQuestionUuidSet ? Array.from(byQuestionUuidSet) : [];
            return resultArray[0] ?? null;
        }
        return null;
    }

    private isFloorType(question: Question): boolean {
        const floorTypes = [
            FloorQuestionType.FLOOR_GROUP_ATTIC,
            FloorQuestionType.FLOOR_GROUP_FLOOR,
            FloorQuestionType.FLOOR_GROUP_GROUND,
            FloorQuestionType.FLOOR_GROUP_BASEMENT,
        ];

        return question && floorTypes.includes(question.type as FloorQuestionType);
    }
}
