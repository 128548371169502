import {IteratorQuestionType, NormalQuestionType} from '../../../../enum/question_type';
import {computed, makeObservable, observable, runInAction} from 'mobx';
import {combineLatest, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {sortByCreatedAt, sortByRank} from '../../../../../support/sort_answer';

import {Answer} from '../../../../models/answer';
import {AnswerController} from '../../../../business/answering/answer_controller';
import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {FileReference} from '../../../../models/file_reference';
import {Presenter} from '../../../../../support/presenter/presenter';
import {QuestionSet} from '../../../../models/question_set';
import {isSet} from '../../../../../support/is_set';

export class PhotosWidgetPresenter implements Presenter {
    @observable.ref private images: Answer[] = [];
    @computed public get fileReferences() {
        return this.images.map((img) => img.file).filter((file): file is FileReference => isSet(file));
    }

    private subscriptions = new CompositeSubscription();

    constructor(private questionSet: QuestionSet, private answerController: AnswerController) {
        makeObservable(this);
    }

    public async mount() {
        /**
         * Retrieve all PHOTO_ITERATOR from the questionSet
         * Get all the answers on those iterators, filter deleted out and sort them
         * Get from those answers their questions -> their answers are the photos
         */
        const photoIterators = this.questionSet.findQuestionsByType(IteratorQuestionType.PHOTO_ITERATOR);
        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidsStream(photoIterators.map((photoIterator) => photoIterator.uuid))
                .pipe(
                    map((iteratorPhotoGroups: Answer[]) =>
                        iteratorPhotoGroups
                            .filter((iterator) => !iterator.isDeleted)
                            .sort(sortByCreatedAt)
                            .sort(sortByRank)
                    ),
                    switchMap((iteratorPhotoGroups) => {
                        if (iteratorPhotoGroups.length === 0) {
                            return of([]);
                        }

                        return combineLatest(
                            iteratorPhotoGroups.map((iteratorPhotoGroup) => {
                                const photoQuestion = this.questionSet
                                    .findChildQuestionsByParentUuid(iteratorPhotoGroup.questionUuid)
                                    .find((q) => q.type === NormalQuestionType.PHOTO);
                                if (photoQuestion) {
                                    return this.answerController.answerByIdentifiersStream(
                                        photoQuestion.uuid,
                                        iteratorPhotoGroup.uuid,
                                        null
                                    );
                                } else {
                                    return of(null);
                                }
                            })
                        );
                    }),
                    map((photoAnswers) =>
                        photoAnswers.filter((photoAnswer): photoAnswer is Answer => photoAnswer !== null)
                    )
                )
                .subscribe((photoAnswers) => {
                    runInAction(() => {
                        this.images = photoAnswers;
                    });
                })
        );
    }

    public async unmount() {
        this.subscriptions.clear();
    }
}
