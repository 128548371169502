import {
    ConstructionCostDuration,
    DefinedConstructionCostsLine,
    ValidationInstituteConstructionalDefect,
} from '../../../../../models/validation_institute_constructional_defect';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {Answer} from '../../../../../models/answer';
import {AnswerController} from '../../../../../business/answering/answer_controller';
import {AnswerTouchState} from '../../../../../enum/answer_touch_state';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {ConstructionCostsLine} from '../../../../../models/construction_costs_summary';
import {ConstructionCostsUnit} from '../../../../../../network/models/construction_costs';
import {ConstructionDefectsWidgetContext} from '../construction_defects_widget_context';
import {GlobalProvider} from '../../../../../../business/global_provider';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {Question} from '../../../../../models/question';
import {UserType} from '../../../../../enum/user_type';
import {Appraisal} from '../../../../../models/appraisal';
import {AppraisalValidationType} from '../../../../../enum/appraisal_validation_type';
import {AppraisalState} from '../../../../../enum/appraisal_state';

export class ConstructionalDefectsQuestionPresenter implements Presenter {
    public OWN_INPUT_VALUE = 'own-input';
    private subscriptions = new CompositeSubscription();
    @observable public answer?: Answer;

    public get isBuildingInspectorButtonEnabled() {
        return (
            this.globalProvider.global.buildingInspectorSubscription !== false &&
            this.globalProvider.global.userType !== UserType.BUILDING_INSPECTOR
        );
    }

    @computed
    public get validationInstituteConstructionalDefect(): ValidationInstituteConstructionalDefect | null {
        if (this.answer && this.answer.contents) {
            return JSON.parse(this.answer.contents);
        }

        return null;
    }

    @action
    public toggleAdviceRequest(constructionCostsWidgetContext: ConstructionDefectsWidgetContext) {
        if (constructionCostsWidgetContext === ConstructionDefectsWidgetContext.NEW_REQUEST_DEFECT_WIDGET) {
            this.onChangeConstructionCostsWidgetContext(ConstructionDefectsWidgetContext.REQUEST_DEFECT_WIDGET);
        } else {
            this.onChangeConstructionCostsWidgetContext(ConstructionDefectsWidgetContext.NEW_REQUEST_DEFECT_WIDGET);
        }
    }

    @computed
    public get isDisabled(): boolean {
        if (!this.appraisal.isEditableAppraisal) {
            return true;
        }
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return this.appraisal.isFrozen;
        }
        return (
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    @observable public selectedNewConstructionCostLine: ConstructionCostsLine | null = null;

    constructor(
        private appraisal: Appraisal,
        private onClose: () => void,
        private parentAnswerUuid: string,
        private question: Question,
        private onChangeConstructionCostsWidgetContext: (c: ConstructionDefectsWidgetContext) => void,
        private initialValidationInstituteConstructionalDefect: ValidationInstituteConstructionalDefect | null = null,
        private answerController: AnswerController,
        private globalProvider: GlobalProvider
    ) {
        makeObservable(this);
    }

    public mount() {
        this.subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid, null)
                .subscribe((answer) => {
                    runInAction(() => {
                        this.answer = answer;
                        if (answer.contents === null) {
                            this.answerController.onContentsChange(
                                this.answer.uuid,
                                JSON.stringify(this.initValidationInstituteConstructionalDefect()),
                                AnswerTouchState.UNTOUCHED
                            );
                        }
                    });
                })
        );

        this.mergeInitValidationInstituteConstructionalDefectWithAnswer();
    }

    public async unmount() {
        this.subscriptions.clear();
    }

    public onClearAnswer() {
        if (this.answer !== undefined) {
            this.answerController.onContentsChange(
                this.answer.uuid,
                JSON.stringify(this.initValidationInstituteConstructionalDefect(true)),
                AnswerTouchState.TOUCHED
            );
        }
        this.onClose();
    }

    private initValidationInstituteConstructionalDefect(force = false): ValidationInstituteConstructionalDefect {
        if (!force && this.initialValidationInstituteConstructionalDefect) {
            return this.initialValidationInstituteConstructionalDefect;
        }

        return {
            reference: '',
            lines: [],
            costs5Years: 0,
            directCosts: 0,
            allocation: null,
        };
    }

    private mergeInitValidationInstituteConstructionalDefectWithAnswer(): void {
        const init = this.initValidationInstituteConstructionalDefect();
        // Only calculate the new lines when the module is init with lines
        if (init.lines.length > 0 && this.answer && this.answer.contents !== null) {
            const answerContents: ValidationInstituteConstructionalDefect = JSON.parse(this.answer.contents);
            for (const line of init.lines) {
                // Add init line when not existing in answer
                if (!answerContents.lines.find((l) => l.description === line.description)) {
                    answerContents.lines.push(line);
                }
            }
            // Calculate costs when there are lines
            if (answerContents.lines.length > 0) {
                const newCosts = this.calculateNewCosts(answerContents.lines);
                answerContents.directCosts = newCosts.directCosts;
                answerContents.costs5Years = newCosts.indirectCosts;
                this.answerController.onContentsChange(
                    this.answer.uuid,
                    JSON.stringify(answerContents),
                    AnswerTouchState.UNTOUCHED
                );
            }
        }
    }

    public onSelectChange(constructionCosts: ConstructionCostsLine[] | null, value: string) {
        let cc;
        if (value === this.OWN_INPUT_VALUE) {
            cc = {
                costsCents: 0,
                description: '',
                unit: ConstructionCostsUnit.SINGLE,
                labels: null,
                isCustom: true,
            };
        } else {
            cc = constructionCosts?.[parseInt(value, 10)];
        }
        if (cc) {
            this.setSelectedNewConstructionCost(cc);
            if (this.selectedNewConstructionCostLine) {
                this.addNewConstructionCost(this.selectedNewConstructionCostLine);
            }
        }
    }

    public onAddOwnInput() {
        const cc = {
            costsCents: 0,
            description: '',
            unit: ConstructionCostsUnit.SINGLE,
            labels: null,
            isCustom: true,
        };
        this.setSelectedNewConstructionCost(cc);
        if (this.selectedNewConstructionCostLine) {
            this.addNewConstructionCost(this.selectedNewConstructionCostLine);
        }
    }

    @action
    public setSelectedNewConstructionCost(constructionCostsLine: ConstructionCostsLine) {
        this.selectedNewConstructionCostLine = constructionCostsLine;
    }

    public addNewConstructionCost(constructionCostsLine: ConstructionCostsLine) {
        if (this.answer) {
            let answerContents: ValidationInstituteConstructionalDefect = this.answer.contents
                ? JSON.parse(this.answer.contents)
                : this.initValidationInstituteConstructionalDefect();

            if (!answerContents.lines) {
                answerContents = this.initValidationInstituteConstructionalDefect();
            }

            answerContents.lines.push({
                ...constructionCostsLine,
                amount: 0,
                duration: ConstructionCostDuration.INDIRECT,
            });

            this.answerController.onContentsChange(
                this.answer.uuid,
                JSON.stringify(answerContents),
                AnswerTouchState.TOUCHED
            );
        }
    }

    public deleteLine(index: number) {
        const answerContents = this.validationInstituteConstructionalDefect;

        if (this.answer && answerContents !== null) {
            answerContents.lines.splice(index, 1);

            const newCosts = this.calculateNewCosts(answerContents.lines);
            answerContents.directCosts = newCosts.directCosts;
            answerContents.costs5Years = newCosts.indirectCosts;

            this.answerController.onContentsChange(
                this.answer.uuid,
                JSON.stringify(answerContents),
                AnswerTouchState.TOUCHED
            );
        }
    }

    private calculateNewCosts(constructionCostsLines: DefinedConstructionCostsLine[]) {
        let directCosts = 0;
        let indirectCosts = 0;
        for (const line of constructionCostsLines) {
            if (line.duration === ConstructionCostDuration.DIRECT && line.amount !== null) {
                directCosts += line.amount * line.costsCents;
            }
            if (line.duration === ConstructionCostDuration.INDIRECT && line.amount !== null) {
                indirectCosts += line.amount * line.costsCents;
            }
            if (line.duration === ConstructionCostDuration.BOTH && line.amount !== null) {
                directCosts += line.amount * line.costsCents;
                indirectCosts += line.amount * line.costsCents;
            }
        }
        return {directCosts, indirectCosts};
    }

    public changeLine(index: number, updatedConstructionCostsLine: DefinedConstructionCostsLine) {
        const answerContents = this.validationInstituteConstructionalDefect;

        if (this.answer && answerContents !== null) {
            answerContents.lines[index] = updatedConstructionCostsLine;
            const newCosts = this.calculateNewCosts(answerContents.lines);
            answerContents.directCosts = newCosts.directCosts;
            answerContents.costs5Years = newCosts.indirectCosts;

            this.answerController.onContentsChange(
                this.answer.uuid,
                JSON.stringify(answerContents),
                AnswerTouchState.TOUCHED
            );
        }
    }

    public refreshDirectCosts() {
        const answerContents = this.validationInstituteConstructionalDefect;

        if (this.answer && answerContents !== null) {
            const newCosts = this.calculateNewCosts(answerContents.lines);
            answerContents.directCosts = newCosts.directCosts;

            this.answerController.onContentsChange(
                this.answer.uuid,
                JSON.stringify(answerContents),
                AnswerTouchState.TOUCHED
            );
        }
    }

    public refreshIndirectCosts() {
        const answerContents = this.validationInstituteConstructionalDefect;

        if (this.answer && answerContents !== null) {
            const newCosts = this.calculateNewCosts(answerContents.lines);
            answerContents.costs5Years = newCosts.indirectCosts;

            this.answerController.onContentsChange(
                this.answer.uuid,
                JSON.stringify(answerContents),
                AnswerTouchState.TOUCHED
            );
        }
    }

    public onDirectCostsChange(newValue: string) {
        const answerContents = this.validationInstituteConstructionalDefect;

        if (this.answer && answerContents !== null) {
            answerContents.directCosts = parseFloat(newValue) * 100;

            this.answerController.onContentsChange(
                this.answer.uuid,
                JSON.stringify(answerContents),
                AnswerTouchState.TOUCHED
            );
        }
    }

    public onIndirectCostsChange(newValue: string) {
        const answerContents = this.validationInstituteConstructionalDefect;

        if (this.answer && answerContents !== null) {
            answerContents.costs5Years = parseFloat(newValue) * 100;

            this.answerController.onContentsChange(
                this.answer.uuid,
                JSON.stringify(answerContents),
                AnswerTouchState.TOUCHED
            );
        }
    }
}
