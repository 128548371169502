import * as React from 'react';
import {observer} from 'mobx-react';

import {Modal} from '../../appraising/appraise/ui/content/questions/components/modal';
import {NotificationModalPresenter} from './notification_modal_presenter';
import {classNames} from '../../support/classnames';
import {usePresenter} from '../support/use_presenter';

interface OwnProps {
    children?: React.ReactNode;
}

export const NotificationModal: React.FunctionComponent<OwnProps> = observer(({children}) => {
    const presenter = usePresenter((container) => new NotificationModalPresenter(container.notificationsInteractor));
    const notification = presenter.notification;

    if (notification === null || notification === undefined) {
        return <></>;
    }

    return (
        <Modal
            visible={true}
            onHide={() => presenter.onHide(notification)}
            onShow={() => presenter.onShow(notification)}
        >
            <div className="modal-header">
                <button className="close ion-md-close" onClick={() => presenter.onHide(notification)}></button>
                <div className="row">
                    <div className="col-12 text-center">
                        <h2>
                            {!notification.readAt && <span className="bubble"></span>}
                            {notification.subject}
                        </h2>
                    </div>
                    <div className="col-12 text-center">
                        <small>
                            {notification.createdAt.toLocaleDateString('nl-NL')}{' '}
                            {notification.createdAt.toLocaleTimeString('nl-NL')}
                        </small>
                    </div>
                </div>
            </div>
            <div className="modal-body modal-body-wrap">
                <p>{notification.content}</p>
            </div>
            <div className="modal-footer">
                {children}
                <button
                    className={classNames(
                        {'btn-primary': notification.appraisalId === null},
                        {'btn-secondary': notification.appraisalId !== null},
                        'btn btn-full btn-icon-left ion-md-checkbox-outline'
                    )}
                    type="button"
                    onClick={() => presenter.onHide(notification)}
                >
                    Sluiten
                </button>
                {notification.appraisalId !== null && (
                    <button
                        className="btn btn-primary btn-full btn-icon-right ion-md-arrow-forward"
                        type="button"
                        onClick={() => presenter.onShowAppraisal(notification)}
                    >
                        Bekijk taxatie
                    </button>
                )}
            </div>
        </Modal>
    );
});
