import {computed, makeObservable, observable, runInAction} from 'mobx';
import {isCompact} from '../../../../../../support/check_mobile';
import {AppraiseSecondaryConfigStackInteractor} from '../../../../../business/appraise_secondary_config_stack_interactor';
import {AppraiseSecondaryType} from '../../../../../models/appraise_secondary_config';
import {ModalConfigStackInteractor} from '../../../../../business/modal_config_stack_interactor';
import {ModalType} from '../../../../../models/modal_config';
import {PagePartsSet} from '../../../../../models/page_parts_set';
import {Appraisal} from '../../../../../models/appraisal';
import {QuestionSet} from '../../../../../models/question_set';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {AnswerController} from '../../../../../business/answering/answer_controller';
import {Question} from '../../../../../models/question';
import {isApartment} from '../../../../../business/support/is_apartment_check';
import {UserInteractor} from '../../../../../business/user_interactor';
import {QuestionEffectInteractor} from '../../../../../business/conditions/question_effects_interactor';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {isEmpty} from '../../../../../../support/util';
import {AnswerTouchState} from '../../../../../enum/answer_touch_state';

export class EnergeticWidgetPresenter implements Presenter {
    @observable public _isHidden = false;

    private widgetVisible = false;
    private subscriptions = new CompositeSubscription();

    constructor(
        private appraiseSecondaryConfigStackInteractor: AppraiseSecondaryConfigStackInteractor,
        private modalConfigStackInteractor: ModalConfigStackInteractor,
        private pagePartsSet: PagePartsSet | null,
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private renderingContext: RenderingContextType,
        private question: Question,
        private parentAnswerUuid: string | null,
        private iteration: string | null,
        private answerController: AnswerController,
        private userInteractor: UserInteractor,
        private questionEffectsInteractor: QuestionEffectInteractor
    ) {
        makeObservable(this);
    }

    @computed
    public get isHidden(): boolean {
        return (
            this._isHidden ||
            (this.question.isAppraiserOnly &&
                !this.userInteractor.isAppraiser() &&
                !this.userInteractor.isEmployee() &&
                !this.userInteractor.isJuniorAppraiser())
        );
    }

    public mount() {
        let answer = this.answerController.answerByIdentifiers(
            this.question.uuid,
            this.parentAnswerUuid ?? null,
            this.iteration ?? null
        );

        const answerContents = answer && !isEmpty(answer.contents) ? JSON.parse(answer.contents) : {};

        if (!answer || ('opened' in answerContents && answerContents.opened === false)) {
            // First render, stub answer to only let this condition run once
            answer = this.answerController.answerByIdentifiersOrStub(
                this.question.uuid,
                this.parentAnswerUuid ?? null,
                this.iteration ?? null
            );

            answerContents.opened = true;
            this.answerController.onContentsChange(
                answer.uuid,
                JSON.stringify(answerContents),
                AnswerTouchState.TOUCHED
            );

            if (!isCompact() && this.getUnsupportedMessage() === null) {
                this.toggleWidget();
            }
        }

        this.subscriptions.add(
            this.questionEffectsInteractor
                .isHiddenStream(this.question.uuid, this.parentAnswerUuid ?? null)
                .subscribe((isHidden) => {
                    runInAction(() => {
                        this._isHidden = isHidden;
                    });
                })
        );
    }

    public unmount() {
        this.subscriptions.clear();
    }

    public getUnsupportedMessage() {
        if (!this.appraisal.objectType) {
            return null;
        }

        if (isApartment(this.appraisal.objectType)) {
            return 'Het automatisch ophalen van het duurzaamheidsadvies is helaas niet mogelijk voor appartementen. Wij werken eraan om dit in de toekomst wel mogelijk te maken.';
        }

        return null;
    }

    public toggleWidget() {
        this.widgetVisible = !this.widgetVisible;

        const secondaryWidgetId = `energetic-sheet`;
        const answer = this.answerController.answerByIdentifiersOrStub(
            this.question.uuid,
            this.parentAnswerUuid ?? null,
            this.iteration ?? null
        );

        if (this.widgetVisible) {
            const openExplanationModal = () => {
                this.modalConfigStackInteractor.upsert({
                    id: 'energetic-sheet-explanation',
                    type: ModalType.ENERGETIC_SHEET_EXPLANATION,
                });
            };

            if (isCompact()) {
                this.modalConfigStackInteractor.remove((c) => {
                    return c.type === ModalType.ENERGETIC_SHEET;
                });

                this.modalConfigStackInteractor.upsert({
                    id: secondaryWidgetId,
                    type: ModalType.ENERGETIC_SHEET,
                    renderingContext: this.renderingContext,
                    appraisal: this.appraisal,
                    questionSet: this.questionSet,
                    pagePartsSet: this.pagePartsSet,
                    answerUuid: answer.uuid,
                    onOpenExplanation: openExplanationModal,
                    onClose: () => {
                        if (this.widgetVisible) {
                            this.widgetVisible = false;
                            this.modalConfigStackInteractor.remove((c) => c.id === secondaryWidgetId);
                        }
                    },
                });
            } else {
                this.appraiseSecondaryConfigStackInteractor.remove((c) => {
                    return c.type === AppraiseSecondaryType.WIDGET_GROUP && c.id === secondaryWidgetId;
                });

                this.appraiseSecondaryConfigStackInteractor.upsert({
                    id: secondaryWidgetId,
                    type: AppraiseSecondaryType.ENERGETIC_SHEET,
                    renderingContext: this.renderingContext,
                    appraisal: this.appraisal,
                    questionSet: this.questionSet,
                    pagePartsSet: this.pagePartsSet,
                    answerUuid: answer.uuid,
                    onOpenExplanation: openExplanationModal,
                    onClose: () => {
                        if (this.widgetVisible) {
                            this.widgetVisible = false;
                            this.appraiseSecondaryConfigStackInteractor.remove((c) => c.id === secondaryWidgetId);
                        }
                    },
                });
            }
        } else {
            this.appraiseSecondaryConfigStackInteractor.remove((c) => c.id === secondaryWidgetId);
            this.modalConfigStackInteractor.remove((c) => c.id === secondaryWidgetId);
        }
    }
}
