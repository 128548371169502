import {ConditionsInteractor} from '../../conditions/conditions_interactor';
import {Question} from '../../../models/question';
import {Answer} from '../../../models/answer';

export interface IsVisitedRequiredCalculator {
    isVisitedRequired(question: Question, answer: Answer | null): boolean;
}

export class DefaultIsVisitedRequiredCalculator implements IsVisitedRequiredCalculator {
    constructor(private conditionsInteractor: ConditionsInteractor) {}

    public isVisitedRequired(question: Question, answer: Answer | null): boolean {
        return question.isVisitedRequired || this.isRequiredThroughConditions(question, answer);
    }

    private isRequiredThroughConditions(question: Question, answer: Answer | null) {
        if (answer === null) {
            return false;
        }
        const effectStates = this.conditionsInteractor.get(question.uuid, answer.parentUuid);
        return effectStates.reduce((isRequired, effectState) => {
            if (effectState.effect.task === 'visited_required' && effectState.enabled) {
                return true;
            }
            return isRequired;
        }, false);
    }
}
