import 'blueimp-canvas-to-blob';

import * as loadImage from 'blueimp-load-image';

import {bugsnagClient} from './bugsnag_client';
import piexif from 'piexifjs';
import {getFileName} from './file_name';

export async function canvasToFile(
    image: Event | HTMLCanvasElement | HTMLImageElement,
    data: loadImage.MetaData | undefined,
    fileName: string,
    lastModified: number,
    quality: number
): Promise<File | null> {
    try {
        if ('toDataURL' in image) {
            const dataURL = image.toDataURL('image/jpeg', quality);
            const imageWithExif = await transferExif(dataURL, data, fileName, lastModified);
            return imageWithExif;
        }

        if ('toBlob' in image) {
            const blob = await toBlob(image as unknown as HTMLCanvasElement, quality);
            if (blob) {
                const imageWithExif = await transferExif(blob, data, fileName, lastModified);
                return imageWithExif;
            } else {
                return null;
            }
        }

        const error = new Error('No `toDataURL` or `toBlob` on loaded image');
        console.error(error);
        bugsnagClient?.notify(error, {
            metaData: {
                typeofImg: typeof image,
                image,
            },
        });
        return null;
    } catch (error) {
        console.error(error);
        bugsnagClient?.notify(error, {
            metaData: {
                typeofImg: typeof image,
                image,
            },
        });
        return null;
    }
}

function toBlob(canvas: HTMLCanvasElement, quality: number): Promise<Blob | null> {
    return new Promise<Blob | null>((resolve) => {
        try {
            canvas.toBlob(
                async (blob) => {
                    try {
                        if (blob) {
                            resolve(blob);
                        } else {
                            console.error('toBlob failed, blob is null');
                            resolve(null);
                        }
                    } catch (error) {
                        console.error(error);
                        resolve(null);
                    }
                },
                'image/jpeg',
                quality
            );
        } catch (error) {
            console.error(error);
            bugsnagClient?.notify(error);
            resolve(null);
        }
    });
}

export function createFile(
    blob: BlobPart[] | Blob,
    properties: {fileName: string; type: string; lastModified: number}
): File {
    if (typeof File === 'function') {
        return new File(Array.isArray(blob) ? blob : [blob], getFileName({name: properties.fileName}), {
            lastModified: properties.lastModified,
            type: properties.type,
        });
    } else {
        const file = new Blob(Array.isArray(blob) ? blob : [blob], {
            type: properties.type,
        });
        (file as unknown as {name: string}).name = getFileName({name: properties.fileName});
        (file as unknown as {lastModified: number}).lastModified = properties.lastModified;
        return file as File;
    }
}

function createFileFromDataURL(dataURL: string, fileName: string, lastModified: number): File {
    const arr = dataURL.split(',');
    const mime = arr[0]?.match(/:(.*?);/)?.[1] ?? 'image/jpeg';
    const byteString = atob(arr[1]);
    let n = byteString.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = byteString.charCodeAt(n);
    }

    return createFile([u8arr], {
        type: mime,
        fileName: fileName,
        lastModified: lastModified,
    });
}

async function transferExif(
    correctedFile: Blob | string,
    data: loadImage.MetaData | undefined,
    fileName: string,
    lastModified: number
) {
    const exifToBeTransferred = getToBeTransferredExifData(data);

    const fileAsUrl = typeof correctedFile === 'string' ? correctedFile : await getFileAsUrl(correctedFile);
    const exifStr = piexif.dump({Exif: exifToBeTransferred});
    const correctedStrWithExif = piexif.insert(exifStr, fileAsUrl);

    return createFileFromDataURL(correctedStrWithExif, fileName, lastModified);
}

function getToBeTransferredExifData(
    data: undefined | {exif?: Record<number, loadImage.ExifTagValue | Record<number, loadImage.ExifTagValue>>}
) {
    const exifToBeTransferred: Record<number, loadImage.ExifTagValue> = {};

    if (data?.exif) {
        const exifTag = data.exif[piexif.ImageIFD.ExifTag];
        const dateTime = data.exif[piexif.ImageIFD.DateTime];

        //Copy over the `DateTimeOriginal`, if undefined, replace it with the `DateTime`
        if (!Array.isArray(exifTag) && typeof exifTag === 'object') {
            // data.exif[piexif.ImageIFD.ExifTag][piexif.ExifIFD.DateTimeOriginal] !== undefined
            const dateTimeOriginal = exifTag[piexif.ExifIFD.DateTimeOriginal];
            exifToBeTransferred[piexif.ExifIFD.DateTimeOriginal] = dateTimeOriginal;
        } else if (typeof dateTime === 'string') {
            exifToBeTransferred[piexif.ExifIFD.DateTimeOriginal] = dateTime;
        }
    }

    return exifToBeTransferred;
}

async function getFileAsUrl(file: Blob): Promise<string> {
    return await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            resolve((e.target?.result as string) ?? '');
        };
        reader.readAsDataURL(file);
    });
}
