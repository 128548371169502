import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {NotesWidgetPresenter} from './notes_widget_presenter';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {Macros} from '../../content/questions/advanced/macros/macros';
import {MacroExternalType} from '../../../../models/macro';
import {macroIsActive, toggleMacro} from '../../../../business/toggle_macro';
import {QuestionSet} from '../../../../models/question_set';
import {classNames} from '../../../../../support/classnames';

interface OwnProps {
    appraisal: Appraisal;
    disabled: boolean;
    questionSet: QuestionSet;
    showSavedIcon?: boolean;
}

export const NotesWidget: React.FC<OwnProps> = observer(function NotesWidget(props) {
    const presenter = usePresenter(
        (container) =>
            new NotesWidgetPresenter(
                props.appraisal,
                container.network.appraisalApi,
                container.business.macroInteractor(props.questionSet)
            )
    );

    const macros = presenter.macros || [];
    const notes = presenter.notes || '';

    return (
        <div className="col-12">
            <div className="widget">
                <div className="widget-title">
                    <h3>
                        <span className="ion-md-clipboard" />
                        Notities
                    </h3>
                </div>
                <div className="widget-text-area">
                    <div className={macros.length !== 0 ? 'form-group has-macros' : 'form-group'}>
                        {presenter.macros && (
                            <Macros
                                externalType={MacroExternalType.APPRAISAL_NOTES}
                                parentAnswerUuid={null}
                                questionSet={props.questionSet}
                                appraisal={props.appraisal}
                                isActive={(macro) => macroIsActive(macro, notes)}
                                onUseMacro={(macro) => presenter.onChangeNotes(toggleMacro(macro, notes))}
                                onAdd={presenter.onAddAsMacroClick}
                                onRemove={(macro) => presenter.onRemoveMacroClick(macro)}
                                showAddButton={
                                    presenter.notes !== '' &&
                                    !presenter.macros.some((m) => m.contents === presenter.notes)
                                }
                                hasAdvancedOptions={false}
                            />
                        )}
                        <textarea
                            placeholder="Vul hier notities in die je wil bewaren"
                            className="form-control"
                            rows={8}
                            readOnly={props.disabled}
                            value={presenter.notes}
                            onChange={(e) => presenter.onChangeNotes(e.target.value)}
                        />
                        {props.showSavedIcon && (
                            <button
                                className={classNames('btn btn-textarea-inset', {
                                    'ion-md-save': !presenter.isSaved,
                                    'ion-md-checkmark': presenter.isSaved,
                                })}
                                disabled
                            >
                                {presenter.isSaved ? 'Opgeslagen' : 'Opslaan...'}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});
