export interface Adres {
    straat: string;
    huisnummer: string;
    huisnummerToevoeging: string;
    postcode: string;
    plaats: string;
    land: string;
    latitude?: number | null;
    longitude?: number | null;
}

export interface ValidationInstituteReferentieObject {
    //These are internal fields to keep track
    _uuid?: string;
    _selected?: boolean;
    _matched?: boolean;

    //This is the schema we get from the validation institute
    adres: Adres;
    verkoopprijs: number | null;
    verkoopdatum: string | null;
    transportdatum?: string | null;
    aanvangsHuurprijsPerMaand?: number | null;
    gecorrigeerdeHuurprijsPerMaand?: number | null;
    ingangsdatumHuur?: string | null;
    gecorrigeerdeVerkoopprijs: number | null;
    verkochtBinnen: string;
    bronGegevens: string | null;
    woningType: string;
    woningTypeUitleg: string;
    bouwjaar: number | null;
    bouwjaarUitleg: string;
    gebruiksOppervlakte: number | null;
    gebruiksOppervlakteUitleg: string;
    inhoud: number | null;
    inhoudUitleg: string;
    perceelOppervlakte: number | null;
    perceelOppervlakteUitleg: string;
    energielabel: string;
    energielabelVoorlopig: boolean | null;
    energielabelGeldigTot?: string | null;
    aanbouw: string;
    aanbouwUitleg: string;
    liggingUitleg: string;
    onderhoudsSituatieUitleg: string;
    luxeDoelmatigheidUitleg?: string;
    kwaliteitConditieUitleg?: string;
    mateVanDoelmatigheidUitleg?: string;
    mateVanLuxeUitleg?: string;
    toelichtingGebruikReferentieObject: string;
    vooraanzicht: string;
    woningTypeStatus: string;
    onderhoudsSituatieStatus: string;
    luxeDoelmatigheidStatus?: string;
    liggingStatus: string;
    kwaliteitConditieStatus?: string;
    aanbouwStatus: string;
    mateVanDoelmatigheidStatus?: string;
    mateVanLuxeStatus?: string;
    onderhoudssituatie?: string;
    streetviewUrl: string | null;
    eigendomssituatieStatus?: string;
    eigendomssituatieUitleg?: string;
    eigendomssituatieVolleEigendom?: boolean;
    eigendomssituatieRechtErfpacht?: boolean;
    eigendomssituatieRechtOndererfpacht?: boolean;
    eigendomssituatieRechtOpstal?: boolean;
    eigendomssituatieRechtGebruikBewoning?: boolean;
    eigendomssituatieRechtVruchtgebruik?: boolean;
    eigendomssituatieRechtAnders?: boolean;
    eigendomssituatieToelichting?: string;
    andereWezenlijkeVerschillen?: boolean;
    andereWezenlijkeVerschillenUitleg?: string | null;
    highlights: string[];
    overview: Record<string, string> | null;
    installationDates: Record<string, string> | null;

    //Referentie Te koop
    vraagprijs?: number | null;
    oorspronkelijkeVraagprijs?: number | null;
    teKoopSinds?: string | null;
}

export function transformRecord(record: Record<string, string> | null): string[] {
    const items: string[] = [];
    if (record) {
        for (const [key, value] of Object.entries(record)) {
            items.push(transformRecordValue(key) + ': ' + value);
        }
    }
    return items;
}

function transformRecordValue(recordValue: string | null) {
    switch (recordValue) {
        // Overview items
        case 'BATHROOM':
            return 'Badkamer';
        case 'BEDROOM':
            return 'Slaapkamer';
        case 'KITCHEN':
            return 'Keuken';
        case 'LIVING_ROOM':
            return 'Woonkamer';
        case 'PARKINGSPOT':
            return 'Parkeerplaats';
        case 'GARAGE':
            return 'Garage';
        case 'SHED':
            return 'Schuur';
        // Installation items
        case 'RENOVATED':
            return 'Gerenoveerd';
        case 'BOILER':
            return 'Boiler';
        case 'TOILET':
            return 'Toilet';
        case 'SINK':
            return 'Wastafel';
        case 'SHOWER':
            return 'Douche';
        case 'QUOOKER':
            return 'Quooker';
        case 'DISHWASHER':
            return 'Vaatwasser';
        case 'OVEN':
            return 'Oven';
        case 'COMBIOVEN':
            return 'Combi-oven';
        case 'HOT_PLATE':
            return 'Gasfornuis';
        case 'STORAGE':
            return 'Bergruimte';
        case 'DORMER':
            return 'Dakkapel';
        case 'SKYLIGHT':
            return 'Skylight';
        case 'GARDEN':
            return 'Tuin';
        case 'FRONT_GARDEN':
            return 'Voortuin';
        case 'TERRAS':
            return 'Terras';
        case 'EXTERIOR_PAINTING':
            return 'Schilderwerk buiten';
        case 'INTERNAL_PAINTING':
            return 'Schilderwerk binnen';
        case 'SOLAR_PANELS':
            return 'Zonnepanelen';
        case 'ROOF':
            return 'Dak';
        case 'ROOF_ISOLATION':
            return 'Dak isolatie';
        case 'GUTTERS':
            return 'Dakgoot';
        case 'MECHANICAL_VENTILATION':
            return 'Mechanische ventellatie';
        case 'GAS_FIREPLACE':
            return 'Gashaard';
        case 'WINDOW_FRAMES':
            return 'Kozijnen';
        case 'FLOOR':
            return 'Vloer';
        case 'BUILDING_EXTENSION':
            return 'Uitbreiding';
        case 'STUCCO':
            return 'Stukwerk';
        default:
            return recordValue;
    }
}
