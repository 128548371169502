import {ApiQuestionSet, apiQuestionSetToQuestionSet} from './models/api_question_set';
import {QuestionSet} from '../models/question_set';
import {AjaxDriver} from '../../network/driver/ajax_driver';
import {GlobalProvider} from '../../business/global_provider';
import {ApiUserMacros, UserMacros, apiUserMacrosToUserMacros} from './models/api_macro';

export interface QuestionSetApi {
    get(id: number): Promise<QuestionSet>;
}

export class DefaultQuestionSetApi implements QuestionSetApi {
    constructor(private ajaxDriver: AjaxDriver, private globalProvider: GlobalProvider) {}

    public async get(id: number): Promise<QuestionSet> {
        const [questionSet, userMacros] = await Promise.all([
            new Promise<QuestionSet>((resolve, reject) => {
                this.ajaxDriver
                    .fetch(
                        `/ajax/question-sets/${id}/show?user_type=${encodeURIComponent(
                            // User Type is not used in the backend, but only for frontend caching purposes
                            this.globalProvider.global.userType ?? ''
                        )}/show`,
                        {
                            method: 'GET',
                            credentials: 'same-origin',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                        }
                    )
                    .then(async (result) => {
                        if (result.ok) {
                            const apiQuestionSet: ApiQuestionSet = await result.json();
                            resolve(apiQuestionSetToQuestionSet(apiQuestionSet));
                        } else {
                            reject();
                        }
                    })
                    .catch((error) => reject(error));
            }),
            new Promise<UserMacros>((resolve, reject) => {
                this.ajaxDriver
                    .fetch(`/ajax/question-sets/${id}/macros`, {
                        method: 'GET',
                        credentials: 'same-origin',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then(async (result) => {
                        if (result.ok) {
                            const apiMacros: ApiUserMacros = await result.json();

                            resolve(apiUserMacrosToUserMacros(apiMacros));
                        } else {
                            reject();
                        }
                    })
                    .catch((error) => reject(error));
            }),
        ]);

        questionSet.loadMacros(userMacros);

        return questionSet;
    }
}
