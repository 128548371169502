import {makeObservable, observable, runInAction} from 'mobx';
import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {ReferenceSale} from '../../../v1/models/reference_sale';
import {CompositeSubscription} from '../../../../../../../../../../support/composite_subscription';
import {ReferenceObject} from '../../models/reference_object';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {ReferenceObjectsAnswerEnhancementInteractor} from '../../../interactors/reference_objects_answer_enhancement_interactor';

export class ReferenceObjectMapDetailsPresenter implements Presenter {
    @observable public enhancedReferenceSale: ReferenceSale | null = null;

    private subscriptions = new CompositeSubscription();

    constructor(
        private referenceObject: ReferenceObject | null,
        private referenceObjectAnswer: ReferenceObjectAnswer | null,
        private referenceObjectsAnswerEnhancementInteractor: ReferenceObjectsAnswerEnhancementInteractor
    ) {
        makeObservable(this);
    }

    public async mount() {
        if (this.referenceObject === null && this.referenceObjectAnswer !== null) {
            this.subscriptions.add(
                this.referenceObjectsAnswerEnhancementInteractor
                    .streamForAnswer(this.referenceObjectAnswer)
                    .subscribe((enhancedObject) => {
                        runInAction(() => {
                            this.enhancedReferenceSale = enhancedObject;
                        });
                    })
            );
        }
    }

    public async unmount() {
        this.subscriptions.clear();
    }
}
