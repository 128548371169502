import {AjaxDriver} from '../../network/driver/ajax_driver';
import {apiMacroSettingsToMacroSettings} from './models/api_macro_settings';
import {Macro, MacroExternalType, SuperMacro, SuperMacroValueMap} from '../models/macro';
import {apiMacroToMacro} from './models/api_macro';
import {BuildPeriodType} from '../enum/build_period_type';
import {ObjectType} from '../enum/object_type';
import {AppraisalGoal} from '../enum/appraisal_goal';
import {MacroSettings} from '../models/macro_settings';
import {
    ApiSuperMacroValueMap,
    apiSuperMacroToSuperMacro,
    apiSuperMacroValueMapToSuperMacroValueMap,
} from './models/api_super_macro';
import {StubberValuesMap} from '../business/answering/answer_path_stubber';

export interface MacroApi {
    store(
        questionSetId: number,
        contents: string,
        questionUuid: string | null,
        externalType: MacroExternalType | null
    ): Promise<Macro>;

    destroy(id: number): Promise<void>;

    storeSettings(
        macroId: number,
        districtCodes?: string[] | null,
        buildPeriods?: BuildPeriodType[] | null,
        objectTypes?: ObjectType[] | null,
        appraisalGoals?: AppraisalGoal[] | null,
        newConstruction?: boolean | null
    ): Promise<MacroSettings>;

    storeSuper(
        questionSetId: number,
        name: string,
        values: StubberValuesMap,
        questionUuid: string,
        districtCodes?: string[] | null,
        buildPeriods?: BuildPeriodType[] | null,
        objectTypes?: ObjectType[] | null,
        appraisalGoals?: AppraisalGoal[] | null,
        newConstruction?: boolean | null
    ): Promise<SuperMacro>;

    getSettings(macroId: number): Promise<MacroSettings | null>;

    toggleFavorite(id: number): Promise<MacroSettings>;

    hideForUser(id: number): Promise<MacroSettings>;

    getAppraisalValues(appraisalId: number, questionUuids: string[]): Promise<SuperMacroValueMap[]>;
}

export class DefaultMacroApi implements MacroApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public store(
        questionSetId: number,
        contents: string,
        questionUuid: string | null,
        externalType: MacroExternalType | null
    ): Promise<Macro> {
        return new Promise<Macro>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/question-sets/${questionSetId}/macros`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        contents: contents,
                        question_uuid: questionUuid,
                        external_type: externalType,
                    }),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(apiMacroToMacro(await result.json()));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public storeSuper(
        questionSetId: number,
        name: string,
        values: StubberValuesMap,
        questionUuid: string,
        districtCodes?: string[] | null | undefined,
        buildPeriods?: BuildPeriodType[] | null | undefined,
        objectTypes?: ObjectType[] | null | undefined,
        appraisalGoals?: AppraisalGoal[] | null | undefined,
        newConstruction?: boolean | null | undefined
    ): Promise<SuperMacro> {
        return new Promise<SuperMacro>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/question-sets/${questionSetId}/super-macros`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        values: Array.from(values.entries()).map(([uuid, v]) => ({
                            uuid: uuid,
                            question_uuid: v.questionUuid,
                            parent_uuid: v.parentUuid,
                            iteration: v.iteration,
                            contents: v.contents,
                            answer_option_uuid: v.answerOptionUuid,
                            file_reference_id: v.file?.id ?? null,
                        })),
                        name: name,
                        question_uuid: questionUuid,
                        district_codes: districtCodes ?? null,
                        build_periods: buildPeriods ?? null,
                        object_types: objectTypes ?? null,
                        appraisal_goals: appraisalGoals ?? null,
                        new_construction: newConstruction ?? null,
                    }),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(apiSuperMacroToSuperMacro(await result.json()));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public destroy(id: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/macros/${id}/destroy`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve();
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public storeSettings(
        macroId: number,
        districtCodes?: string[] | null,
        buildPeriods?: BuildPeriodType[] | null,
        objectTypes?: ObjectType[] | null,
        appraisalGoals?: AppraisalGoal[] | null,
        newConstruction?: boolean | null
    ): Promise<MacroSettings> {
        return new Promise<MacroSettings>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/macros/${macroId}/settings`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        district_codes: districtCodes ?? null,
                        build_periods: buildPeriods ?? null,
                        object_types: objectTypes ?? null,
                        appraisal_goals: appraisalGoals ?? null,
                        new_construction: newConstruction ?? null,
                    }),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(apiMacroSettingsToMacroSettings(await result.json()));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getSettings(macroId: number): Promise<MacroSettings | null> {
        return new Promise<MacroSettings>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/macros/${macroId}/settings`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(apiMacroSettingsToMacroSettings(await result.json()));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public toggleFavorite(id: number): Promise<MacroSettings> {
        return new Promise<MacroSettings>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/macros/${id}/toggle-favorite`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(apiMacroSettingsToMacroSettings(await result.json()));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public hideForUser(id: number): Promise<MacroSettings> {
        return new Promise<MacroSettings>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/macros/${id}/hide-for-user`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(apiMacroSettingsToMacroSettings(await result.json()));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getAppraisalValues(appraisalId: number, questionUuids: string[]): Promise<SuperMacroValueMap[]> {
        return new Promise<SuperMacroValueMap[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/macros/appraisal-values/${appraisalId}/questions`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        question_uuids: questionUuids,
                    }),
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(
                            (await result.json()).map((value: ApiSuperMacroValueMap) =>
                                apiSuperMacroValueMapToSuperMacroValueMap(value)
                            )
                        );
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
