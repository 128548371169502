import * as React from 'react';

import {Appraisal} from '../../../../../../../../models/appraisal';
import {ImageUrlPair} from '../../../../../../../../models/image_url_pair';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {ReferenceObjectAnswerImageUploadPresenter} from './reference_object_answer_image_upload_presenter';
import {ReferenceObjectType} from '../../../../../../../../enum/reference_object_type';
import {classNames} from '../../../../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../../../support/presenter/use_presenter';
import {ImageViewer} from '../../../../../../../../components/image_viewer/image_viewer';
import {ImageSlider} from '../../../../../../../../components/image_viewer/image_slider';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    referenceObjectType: ReferenceObjectType;
    referenceObjectAnswerPhotoUrl: string | null;
    isOwnUpload: boolean | undefined;
    imageUrlPairs: ImageUrlPair[];
    onChange: (path: string, url: string, isOwnUpload?: boolean) => void;
    disabled?: boolean;
}

export const ReferenceObjectAnswerImageUpload: React.FC<OwnProps> = observer(function ReferenceObjectAnswerImageUpload(
    props
) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectAnswerImageUploadPresenter(
                props.imageUrlPairs,
                props.onChange,
                container.business.imageUploadInteractor(props.appraisal, props.questionSet)
            )
    );

    return (
        <div>
            <div
                className={classNames('detail-image', 'detail-image-answer-modal', {
                    opaque: presenter.busy,
                })}
            >
                <ImageViewer images={props.imageUrlPairs.map((pair) => pair.url)}>
                    <ImageSlider
                        images={props.imageUrlPairs.map((pair) => pair.url)}
                        onRealIndexChange={(slider) =>
                            presenter.setCurrentImageUrlPair(props.imageUrlPairs[slider.realIndex])
                        }
                    />
                </ImageViewer>
            </div>
            <div className="image-selector-buttons-container">
                <label
                    className={classNames(
                        'btn btn-sm ion-md-camera float-right',
                        props.imageUrlPairs.length > 0 ? 'btn-single-icon' : null
                    )}
                >
                    <input
                        type="file"
                        name="files[]"
                        style={{display: 'none'}}
                        onChange={(e) => presenter.onChange(e)}
                        disabled={props.disabled || presenter.busy}
                    />
                    {props.imageUrlPairs.length === 0 && 'Upload foto'}
                </label>
                {props.imageUrlPairs.length > 0 && props.isOwnUpload !== true && (
                    <button
                        className={classNames('btn btn-sm btn-primary ion-md-checkmark float-right', {
                            'ion-animate': presenter.finished,
                        })}
                        onClick={() => presenter.onSelectionChange()}
                        disabled={
                            presenter.currentImageUrlPair?.url === props.referenceObjectAnswerPhotoUrl ||
                            props.disabled ||
                            presenter.busy
                        }
                    >
                        {presenter.busy ? (
                            <span>
                                <span className="loader" />
                                <span>Kies deze foto</span>
                            </span>
                        ) : (
                            'Kies deze foto'
                        )}
                    </button>
                )}
            </div>
        </div>
    );
});
