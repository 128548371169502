import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';

import {QuestionSet} from '../../../models/question_set';
import {ConstructionCostsProvider} from '../../construction_costs_provider';
import {TechnicalReference} from '../../../enum/technical_reference';
import {QuestionValidator} from '../question_validator';
import {IsRequiredCalculator} from '../internal/is_required_calculator';
import {Question} from '../../../models/question';
import {getNewestAnswer} from '../../../../support/get_newest_answer';
import {Answer} from '../../../models/answer';
import {AnswerController} from '../../answering/answer_controller';

export class ImprovementsWorkQuestionValidator implements QuestionValidator {
    private iteratorTechnicalReferences = [
        TechnicalReference.PLANNED_CONSTRUCTION_ITERATOR,
        TechnicalReference.PLANNED_RENOVATION_ITERATOR,
    ];

    private booleanTechnicalReferences = [
        TechnicalReference.HAS_PLANNED_CONSTRUCTION,
        TechnicalReference.HAS_PLANNED_RENOVATION,
    ];

    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private constructionCostsProvider: ConstructionCostsProvider,
        private isRequiredCalculator: IsRequiredCalculator
    ) {}

    /**
     * This question validator checks based on some answers from related questions. Not completely what a
     * QuestionValidator should do. But since it should check not persisted answers (iterations) this is the only
     * way of checking it, because the QuestionValidator checks not only on answer changes.
     */
    public validate(questionUuid: string): ValidationMessage[] {
        const question = this.questionSet.findQuestionByUuid(questionUuid);

        if (
            question === undefined ||
            question.technicalReference === null ||
            !this.iteratorTechnicalReferences.includes(question.technicalReference) ||
            !this.isRequiredCalculator.isRequiredWithoutAnswer(question)
        ) {
            return [];
        }

        for (const technicalReference of this.booleanTechnicalReferences) {
            const validationMessage = this.check(question, technicalReference);
            if (validationMessage !== null) {
                return [validationMessage];
            }
        }

        return [];
    }

    private check(iteratorQuestion: Question, questionReference: TechnicalReference): ValidationMessage | null {
        const booleanQuestion = this.questionSet.findQuestionByTechnicalReference(questionReference);
        if (booleanQuestion) {
            const answer = getNewestAnswer(this.answerController.answersForQuestionUuid(booleanQuestion.uuid));
            const costs = this.constructionCostsProvider.get();
            if (this.isChecked(booleanQuestion, answer) && (costs === null || costs === 0)) {
                return {
                    type: ValidationMessageType.TAXAPI,
                    importance: ValidationMessageImportance.ERROR,
                    question: iteratorQuestion,
                    answer: answer,
                    messages: [],
                    fallbackMessage: `Wanneer "${booleanQuestion.contents}" geselecteerd is dienen er werkzaamheden opgevoerd te worden.`,
                    floorInfo: null,
                };
            }
        }
        return null;
    }

    private isChecked(question: Question, answer: Answer | null): boolean {
        if (question.answerOptions?.length > 0) {
            return (
                question.answerOptions.find((ao) => ao.contents === 'Ja' || ao.reportValue === 'ja')?.id ===
                answer?.answerOptionId
            );
        }
        return answer?.contents === '1';
    }
}
