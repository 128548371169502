import * as React from 'react';

import {NormalQuestionType, QuestionType} from '../../../../../enum/question_type';
import {
    simpleQuestionPresenterConstructorParametersFactory,
    SimpleQuestionPresenterProps,
} from '../simple/simple_question_presenter';

import {Answer} from '../../../../../models/answer';

import {BuildingCostsCondition} from './building_costs_condition/building_costs_condition';
import {BuildingCostsGroupPresenter} from './building_costs_group_presenter';
import {Question} from '../../../../../models/question';
import {QuestionContainer} from '../question_container';
import {TechnicalReference} from '../../../../../enum/technical_reference';
import {classNames} from '../../../../../../support/classnames';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {observer} from 'mobx-react';
import {QuestionLoader} from '../../../components/question_loader';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {shouldShowTitle} from '../../../../../../support/should_show_title';
import {SuperMacros, hasSuperMacros} from '../extensions/super_macros/super_macros';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const BuildingCostsGroup: React.FC<OwnProps> = observer(function BuildingCostsGroup(props) {
    const presenter = usePresenter(
        (container) =>
            new BuildingCostsGroupPresenter(...simpleQuestionPresenterConstructorParametersFactory(props, container))
    );

    if (presenter.isHidden) {
        return null;
    }

    const answer = presenter.answer;
    if (answer === undefined) {
        return <QuestionLoader />;
    }

    const childrenForQuestion = findChildrenForQuestion(props.question, props.questionSet);

    const children = childrenForQuestion.filter(
        (q) =>
            [
                NormalQuestionType.BUILDING_DEFECTS_COSTS,
                NormalQuestionType.BUILDING_DEFECTS,
                NormalQuestionType.BUILDING_COSTS_CONDITION,
            ].includes(q.type as NormalQuestionType) === false &&
            q.technicalReference !== TechnicalReference.BUILDING_COSTS_GROUP_RISK
    );

    let typeQuestion = childrenForQuestion.find(
        (q) => q.technicalReference === TechnicalReference.BUILDING_COSTS_GROUP_TYPE
    );
    if (typeQuestion === undefined) {
        typeQuestion = children[0] ?? undefined;
    }

    const questions =
        typeQuestion !== undefined
            ? children.filter((c) => typeQuestion !== undefined && c.uuid !== typeQuestion.uuid)
            : [];
    const otherQuestions = getOtherQuestions(questions);
    const modalQuestions = getModalQuestions(questions);

    const isCompact = typeQuestion && typeQuestion.answerOptions.length === 1;

    const superMacros = hasSuperMacros(props.question);

    function isNotRenderedAtTopLevel() {
        return props.parentAnswerUuid !== undefined;
    }

    function renderTitle() {
        const show =
            isNotRenderedAtTopLevel() &&
            shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel);

        if (superMacros) {
            return <SuperMacros {...props} title={show ? <h2>{props.question.contents}</h2> : undefined} />;
        }

        if (show) {
            return (
                <div>
                    <h2>{props.question.contents}</h2>
                </div>
            );
        }

        return null;
    }

    function renderBuildingCostsPair(questions: Question[], answer: Answer, typeQuestion?: Question, compact = false) {
        const buildingCosts = questions.find((q) =>
            [NormalQuestionType.BUILDING_DEFECTS, NormalQuestionType.BUILDING_DEFECTS_COSTS].includes(
                q.type as NormalQuestionType
            )
        );
        const buildingCondition = questions.find((q) => q.type === NormalQuestionType.BUILDING_COSTS_CONDITION);
        const buildingRisk = questions.find(
            (q) => q.technicalReference === TechnicalReference.BUILDING_COSTS_GROUP_RISK
        );

        return (
            <div
                className={classNames('form-building-costs-group', {
                    'form-building-costs-group-sm': typeQuestion !== undefined,
                })}
            >
                <div className="row">
                    {typeQuestion !== undefined && (
                        <div className="form-building-costs-group-col col-12 col-lg-6 flex-wrap">
                            <span className={classNames({'d-none': compact})}>
                                <QuestionContainer
                                    key={typeQuestion.uuid}
                                    appraisal={props.appraisal}
                                    question={typeQuestion}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={answer.uuid}
                                    validationErrors={props.validationErrors}
                                    validationMessages={props.validationMessages}
                                    forceShowValidationMessages={props.forceShowValidationMessages}
                                    adaptedDefaultValues={props.adaptedDefaultValues}
                                    disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                                    hiddenQuestionTypes={props.hiddenQuestionTypes}
                                    disabled={props.disabled}
                                    pagePartsSet={props.pagePartsSet}
                                    activePagePart={props.activePagePart}
                                    renderingContext={props.renderingContext}
                                    questionRenderingData={props.questionRenderingData}
                                />
                            </span>
                            {compact && (
                                <span className="form-group">
                                    <label className="d-none d-lg-flex">&nbsp;</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-placeholder"
                                        disabled
                                        value={props.question.contents}
                                    />
                                </span>
                            )}
                        </div>
                    )}
                    <div
                        className={classNames('col-12 d-flex flex-wrap', {
                            'form-building-costs-group-col col-lg-6': typeQuestion !== undefined,
                        })}
                    >
                        <div className="form-building-costs-pair">
                            {buildingCondition !== undefined && (
                                <BuildingCostsCondition
                                    key={buildingCondition.uuid}
                                    appraisal={props.appraisal}
                                    question={buildingCondition}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={answer.uuid}
                                    validationErrors={props.validationErrors}
                                    validationMessages={props.validationMessages}
                                    forceShowValidationMessages={props.forceShowValidationMessages}
                                    adaptedDefaultValues={props.adaptedDefaultValues}
                                    disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                                    hiddenQuestionTypes={props.hiddenQuestionTypes}
                                    disabled={props.disabled}
                                    pagePartsSet={props.pagePartsSet}
                                    activePagePart={props.activePagePart}
                                    renderingContext={props.renderingContext}
                                    questionRenderingData={props.questionRenderingData}
                                />
                            )}
                            {buildingRisk !== undefined && (
                                <span className="form-group-container">
                                    <QuestionContainer
                                        key={buildingRisk.uuid}
                                        appraisal={props.appraisal}
                                        question={buildingRisk}
                                        questionSet={props.questionSet}
                                        parentAnswerUuid={answer.uuid}
                                        validationErrors={props.validationErrors}
                                        validationMessages={props.validationMessages}
                                        forceShowValidationMessages={props.forceShowValidationMessages}
                                        adaptedDefaultValues={props.adaptedDefaultValues}
                                        disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                                        disabled={props.disabled}
                                        pagePartsSet={props.pagePartsSet}
                                        activePagePart={props.activePagePart}
                                        renderingContext={props.renderingContext}
                                        questionRenderingData={props.questionRenderingData}
                                    />
                                </span>
                            )}
                            {buildingCosts !== undefined && (
                                <span
                                    className={classNames('form-building-costs-container', {
                                        'pulse-input-building-cost': presenter.shouldShowPulse,
                                        'form-building-costs-costs-disabled': !presenter.shouldShowCosts,
                                    })}
                                >
                                    <QuestionContainer
                                        key={buildingCosts.uuid}
                                        appraisal={props.appraisal}
                                        question={buildingCosts}
                                        questionSet={props.questionSet}
                                        parentAnswerUuid={answer.uuid}
                                        validationErrors={props.validationErrors}
                                        validationMessages={props.validationMessages}
                                        forceShowValidationMessages={props.forceShowValidationMessages}
                                        adaptedDefaultValues={props.adaptedDefaultValues}
                                        disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                                        disabled={!presenter.shouldShowCosts}
                                        pagePartsSet={props.pagePartsSet}
                                        activePagePart={props.activePagePart}
                                        renderingContext={props.renderingContext}
                                        questionRenderingData={props.questionRenderingData}
                                    />
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function getOtherQuestions(questions: Question[]) {
        const otherQuestions = questions.filter(
            (q) =>
                !([NormalQuestionType.MODAL, NormalQuestionType.WIDGET_GROUP] as QuestionType[]).includes(q.type) ||
                q.technicalReference === TechnicalReference.BUILDING_COSTS_GROUP_OTHER_OWNERSHIP
        );
        otherQuestions.sort((a, b) => {
            if (a.technicalReference === TechnicalReference.BUILDING_COSTS_GROUP_OWNERSHIP) {
                return -1;
            } else if (b.technicalReference === TechnicalReference.BUILDING_COSTS_GROUP_OWNERSHIP) {
                return 1;
            } else {
                return 0;
            }
        });
        return otherQuestions;
    }

    function renderOtherQuestions(questions: Question[], answer: Answer) {
        return questions.map((child) => (
            <QuestionContainer
                key={child.uuid}
                appraisal={props.appraisal}
                question={child}
                questionSet={props.questionSet}
                parentAnswerUuid={answer.uuid}
                validationErrors={props.validationErrors}
                validationMessages={props.validationMessages}
                forceShowValidationMessages={props.forceShowValidationMessages}
                adaptedDefaultValues={props.adaptedDefaultValues}
                disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                hiddenQuestionTypes={props.hiddenQuestionTypes}
                disabled={props.disabled}
                pagePartsSet={props.pagePartsSet}
                activePagePart={props.activePagePart}
                renderingContext={props.renderingContext}
                questionRenderingData={props.questionRenderingData}
            />
        ));
    }

    function getModalQuestions(questions: Question[]) {
        return questions.filter((q) =>
            ([NormalQuestionType.MODAL, NormalQuestionType.WIDGET_GROUP] as QuestionType[]).includes(q.type)
        );
    }

    function renderModalQuestions(questions: Question[], answer: Answer) {
        return questions.map((child) => (
            <QuestionContainer
                key={child.uuid}
                appraisal={props.appraisal}
                question={child}
                questionSet={props.questionSet}
                parentAnswerUuid={answer.uuid}
                validationErrors={props.validationErrors}
                validationMessages={props.validationMessages}
                adaptedDefaultValues={props.adaptedDefaultValues}
                disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                hiddenQuestionTypes={props.hiddenQuestionTypes}
                disabled={props.disabled}
                pagePartsSet={props.pagePartsSet}
                activePagePart={props.activePagePart}
                renderingContext={props.renderingContext}
                forceShowValidationMessages={props.forceShowValidationMessages}
                questionRenderingData={props.questionRenderingData}
            />
        ));
    }

    return (
        <div className={classNames('group-container', {'super-macro-group': superMacros})}>
            {!isCompact && renderTitle()}
            {isCompact && superMacros && <SuperMacros {...props} />}
            <div className="group-container-content">
                {renderBuildingCostsPair(childrenForQuestion, answer, typeQuestion, isCompact)}
                <div className="form-building-costs-group-layout-only">
                    <div className="row">
                        {otherQuestions.length > 0 && (
                            <div className={classNames('col-12', {'col-lg-6': modalQuestions.length > 0})}>
                                {renderOtherQuestions(otherQuestions, answer)}
                            </div>
                        )}
                        {modalQuestions.length > 0 && (
                            <div
                                className={classNames('col-12', {
                                    'col-lg-6': otherQuestions.length > 0,
                                })}
                            >
                                <div className="form-button-container form-button-container-right">
                                    {renderModalQuestions(modalQuestions, answer)}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});
