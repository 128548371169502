import {AjaxDriver} from '../../network/driver/ajax_driver';
import {FetchDriver} from '../../network/driver/fetch_driver';
import {DefaultNotificationsApi, NotificationsApi} from '../network/notifications_api';
import {DefaultNotificationsInteractor, NotificationsInteractor} from '../interactors/notifications_interactor';
import {
    DefaultNotificationsStackInteractor,
    NotificationsStackInteractor,
} from '../interactors/notifications_stack_interactor';

export interface Component {
    notificationsApi: NotificationsApi;
    notificationsInteractor: NotificationsInteractor;
    notificationsStackInteractor: NotificationsStackInteractor;
}

export interface User {
    id: number;
}

declare let USER: User | null;

class ComponentImpl implements Component {
    private ajaxDriver: AjaxDriver = new FetchDriver();
    private _notificationsApi: NotificationsApi | undefined;
    private _notificationsInteractor: NotificationsInteractor | undefined;
    private _notificationsStackInteractor: NotificationsStackInteractor | undefined;

    public get notificationsApi(): NotificationsApi {
        return this.notificationsApiInstance;
    }

    public get notificationsInteractor(): NotificationsInteractor {
        if (this._notificationsInteractor === undefined) {
            this._notificationsInteractor = new DefaultNotificationsInteractor(USER, this.notificationsApiInstance);
        }
        return this._notificationsInteractor;
    }

    public get notificationsStackInteractor(): NotificationsStackInteractor {
        if (this._notificationsStackInteractor === undefined) {
            this._notificationsStackInteractor = new DefaultNotificationsStackInteractor(
                USER,
                this.notificationsApiInstance
            );
        }
        return this._notificationsStackInteractor;
    }

    private get notificationsApiInstance(): NotificationsApi {
        if (this._notificationsApi === undefined) {
            this._notificationsApi = new DefaultNotificationsApi(this.ajaxDriver);
        }
        return this._notificationsApi;
    }
}

let instance: Component;

export function component(): Component {
    if (instance === undefined) {
        instance = new ComponentImpl();
    }

    return instance;
}
