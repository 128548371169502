import * as React from 'react';

import {PriceRange} from '../components/price_range';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {ReferenceSale} from '../models/reference_sale';
import {classNames} from '../../../../../../../../../support/classnames';
import {format} from 'date-fns';
import {referenceSaleImageUrlPairs} from '../internal/reference_sale_image_urls';
import {ImageViewer} from '../../../../../../../../components/image_viewer/image_viewer';

interface VisibleReferenceSale {
    referenceSale: ReferenceSale;
    referenceObjectAnswer: ReferenceObjectAnswer | null;
}

interface OwnProps {
    referenceSale: ReferenceSale;
    visibleReferenceSales: VisibleReferenceSale[];
    onClickChange: (referenceSale: null) => void;

    showDetailsModal: (referenceSale: ReferenceSale) => void;
}

export class ReferenceObjectMapDetails extends React.Component<OwnProps> {
    public render() {
        const visibleReferenceSale = this.props.visibleReferenceSales.find(
            (vrs) => vrs.referenceSale.id === this.props.referenceSale.id
        );
        const referenceObjectAnswer = visibleReferenceSale ? visibleReferenceSale.referenceObjectAnswer : null;

        const imageUrlPairs = this.props.referenceSale
            ? referenceSaleImageUrlPairs(this.props.referenceSale, referenceObjectAnswer)
            : [];

        return (
            <div className="container container-wide reference-object-map-details-container">
                <div className="row row-wide">
                    <div className="col-5 offset-7 col-wide">
                        <div className="reference-object-map-details-container-inner">
                            <div className="reference-object-map-details">
                                <button
                                    className="reference-object-map-details-close close ion-md-close"
                                    onClick={() => this.props.onClickChange(null)}
                                />
                                <div className="reference-object-map-details-body">
                                    <div className="reference-object-map-details-img">
                                        <div className="reference-object-map-details-img-content">
                                            <ImageViewer images={imageUrlPairs.map((pair) => pair.url)} />
                                            <div
                                                className={classNames('active-icon ion-md-checkmark', {
                                                    visible: referenceObjectAnswer !== null,
                                                })}
                                            >
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <p>
                                                <strong>
                                                    {this.props.referenceSale.street}{' '}
                                                    {this.props.referenceSale.houseNumber}
                                                    {this.props.referenceSale.letter}
                                                </strong>
                                                <br />
                                                {this.props.referenceSale.postalCode} {this.props.referenceSale.city}
                                            </p>
                                        </div>
                                        <div className="col-6">
                                            <dl>
                                                <dt>Prijsklasse</dt>
                                                <dd>
                                                    <PriceRange
                                                        range={this.props.referenceSale.priceRange}
                                                        break={false}
                                                    />
                                                </dd>
                                            </dl>
                                        </div>
                                        <div className="col-3">
                                            <dl>
                                                <dt>GBO wonen</dt>
                                                <dd>
                                                    {this.props.referenceSale.floorArea === null
                                                        ? 'Onbekend'
                                                        : this.props.referenceSale.floorArea + 'm²'}
                                                </dd>
                                            </dl>
                                        </div>
                                        <div className="col-3">
                                            <dl>
                                                <dt>Periode</dt>
                                                <dd>
                                                    {this.props.referenceSale.saleQuarter !== null
                                                        ? this.props.referenceSale.saleQuarter.length > 8
                                                            ? format(
                                                                  new Date(this.props.referenceSale.saleQuarter),
                                                                  'dd-MM-yyyy'
                                                              )
                                                            : 'Q' + this.props.referenceSale.saleQuarter
                                                        : null}
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <div className="reference-object-map-details-footer">
                                    <a
                                        className="ion-md-information-circle"
                                        onClick={() => this.props.showDetailsModal(this.props.referenceSale)}
                                    >
                                        Details
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
