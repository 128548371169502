import * as React from 'react';

import {isEmpty, isNumeric} from '../../../../../../support/util';

interface OwnProps {
    name: string;
    isDisabled?: boolean;
    allowNegatives?: boolean;
    value: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
    className?: string;
}

interface OwnState {
    value: string;
}

export class DecimalTextInput extends React.Component<OwnProps, OwnState> {
    public state = {
        value: this.props.value,
    };

    public componentDidUpdate(prevProps: OwnProps) {
        if (prevProps.value !== this.props.value && this.state.value !== this.props.value) {
            this.setState({
                value: this.props.value,
            });
        }
    }

    private handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        const formattedValue = value.replace(',', '.');

        if (this.props.allowNegatives !== true && formattedValue.includes('-')) {
            // Block - symbol for negative numbers by ignoring the change (and hence not updating the value)
            return;
        }

        if (!isEmpty(formattedValue)) {
            if (isNumeric(formattedValue.replace('.', ''))) {
                if (formattedValue[formattedValue.length - 1] === '.') {
                    this.setState({value: formattedValue});
                } else {
                    const parsedValue = '' + parseFloat(formattedValue);
                    this.setState({value: parsedValue});
                    this.props.onChange(parsedValue);
                }
            } else if (!isNumeric(this.props.value)) {
                this.setState({value: ''});
                this.props.onChange('');
            }
        } else {
            this.setState({value: ''});
            this.props.onChange('');
        }
    }

    public render() {
        return (
            <input
                id={this.props.name}
                name={this.props.name}
                className={this.props.className}
                type="text"
                inputMode="numeric"
                disabled={this.props.isDisabled}
                value={this.state.value}
                onChange={(e) => this.handleOnChange(e)}
                onBlur={this.props.onBlur}
            />
        );
    }
}
