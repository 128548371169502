import * as React from 'react';

import {ValuationAnswerWithQuestion, ValuationAnswersContainerPresenter} from './valuation_answers_container_presenter';

import {AdaptedDefaultValuesMap} from '../../../../../../../models/adapted_default_values_map';
import {Appraisal} from '../../../../../../../models/appraisal';
import {ExtraReferenceSaleSet} from './models/reference_sale';
import {Loader} from '../../../../../components/loader';
import {PagePart} from '../../../../../../../models/page_part';
import {PagePartsSet} from '../../../../../../../models/page_parts_set';
import {QuestionContainer} from '../../../question_container';
import {QuestionSet} from '../../../../../../../models/question_set';
import {QuestionType} from '../../../../../../../enum/question_type';
import {ReferenceObjectAnswer} from './models/reference_object_answer';
import {ReferenceSet} from './models/reference_set';
import {RenderingContextType} from '../../../../../../../enum/rendering_context_type';
import {TechnicalReference} from '../../../../../../../enum/technical_reference';
import {ValidationError} from '../../../../../../../models/validation_error';
import {ValidationMessageMap} from '../../../../../../../business/validation/validation_message';
import {classNames} from '../../../../../../../../support/classnames';
import {findChildrenForQuestion} from '../../../../../support/question_filtering';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../../support/presenter/use_presenter';
import {QuestionRenderingData} from '../../../../../../../models/question_rendering_data';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    extraReferenceSalesSets: ExtraReferenceSaleSet[];
    defaultSet: ReferenceSet;
    activeSet: ReferenceSet;
    isLoading: boolean;
    valuationAnswersChanged: boolean;
    disabled?: boolean;
    onAddMany: (referenceObjectAnswers: ReferenceObjectAnswer[]) => void;
    hiddenQuestionTypes: QuestionType[];
    parentContainerTechnicalReference: TechnicalReference;
    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const ValuationAnswersContainer: React.FC<OwnProps> = observer(function ValuationAnswersContainer(props) {
    const presenter = usePresenter(
        (container) =>
            new ValuationAnswersContainerPresenter(
                container.business.answerController(props.appraisal, props.questionSet),
                props.questionSet,
                container.business.adaptedValueProvider(props.appraisal, props.questionSet),
                props.parentContainerTechnicalReference,
                container.business.plotAreaProvider(props.appraisal, props.questionSet),
                container.business.surfaceAreaProvider(props.appraisal, props.questionSet),
                container.business.buildYearProvider(props.appraisal, props.questionSet)
            )
    );

    if (props.isLoading) {
        return <div className="reference-set-valuation-questions-container">{renderMutedFieldsForActiveSet()}</div>;
    }

    const questionsForSets = presenter.valuationAnswerWithQuestions(props.extraReferenceSalesSets);
    const adaptedValuesMap = presenter.defaultAdaptedAnswers(props.defaultSet);

    function renderSet(questionsForSets: ValuationAnswerWithQuestion[], adaptedValuesMap: AdaptedDefaultValuesMap) {
        return (
            <>
                <div className="col-12">
                    <div className="reference-objects-valuation-answers-message-container">
                        {props.activeSet.id !== null && props.valuationAnswersChanged ? (
                            <small className="text-muted">
                                <span className="ion-md-information-circle"></span>&nbsp; Klik op de &quot;Ververs&quot;
                                knop om nieuwe objecten met deze gegevens op te halen
                            </small>
                        ) : null}
                    </div>
                </div>
                <div className="col-12">
                    {questionsForSets
                        .filter((valuationAnswerWithQuestion) => valuationAnswerWithQuestion.id === props.activeSet.id)
                        .map((valuationAnswerWithQuestionForActiveSet) => {
                            const childQuestions = findChildrenForQuestion(
                                valuationAnswerWithQuestionForActiveSet.question,
                                props.questionSet
                            );
                            return childQuestions.map((child) => {
                                return (
                                    <QuestionContainer
                                        key={
                                            child.uuid +
                                            '[' +
                                            valuationAnswerWithQuestionForActiveSet.iteration +
                                            ']' +
                                            'iteration'
                                        }
                                        iteration={
                                            valuationAnswerWithQuestionForActiveSet.iteration !== null
                                                ? valuationAnswerWithQuestionForActiveSet.iteration
                                                : undefined
                                        }
                                        appraisal={props.appraisal}
                                        question={child}
                                        questionSet={props.questionSet}
                                        validationErrors={props.validationErrors}
                                        validationMessages={props.validationMessages}
                                        forceShowValidationMessages={props.forceShowValidationMessages}
                                        parentAnswerUuid={valuationAnswerWithQuestionForActiveSet.parentAnswerUuid}
                                        disabled={props.disabled}
                                        adaptedDefaultValues={adaptedValuesMap}
                                        disableAdaptedValueContainer={true}
                                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                                        pagePartsSet={props.pagePartsSet}
                                        activePagePart={props.activePagePart}
                                        renderingContext={props.renderingContext}
                                        questionRenderingData={props.questionRenderingData}
                                    />
                                );
                            });
                        })}
                </div>
            </>
        );
    }

    function renderMutedFieldsForActiveSet() {
        return (
            <div className="col-12">
                <div>
                    <h3>Objectkenmerken</h3>
                </div>
                <div className="form-columns">
                    <div className="row">
                        {presenter.baseValuationQuestions(props.activeSet).map((q) => {
                            return (
                                <div key={q.question.uuid} className="col-12 col-md-6">
                                    <div className="form-group">
                                        <div className="label-container">
                                            <label>{q.question.contents}</label>
                                        </div>
                                        <div className="input-group input-group-no-prepend">
                                            <input
                                                type="text"
                                                disabled
                                                className="form-control disabled"
                                                value={!props.isLoading ? q.areaConclusionNumber ?? '-' : ''}
                                            />
                                            {props.isLoading ? <Loader isSmall={true} /> : null}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div
                className={classNames('col-12', {
                    'col-md-5':
                        props.activeSet.suggestedReferenceObjectAnswers.referenceObjectAnswers.length > 0 &&
                        props.activeSet.referenceObjectAnswers.length === 0,
                    'col-md-6': props.activeSet.suggestedReferenceObjectAnswers.referenceObjectAnswers.length === 0,
                })}
            >
                <h2 className="reference-objects-nav-title">
                    {props.activeSet.id === null ? 'Marktwaarde' : 'Bijzonder uitgangspunt'}
                </h2>
                <h3 className="reference-objects-nav-subtitle">{props.activeSet.name}</h3>
            </div>
            <div
                className={classNames('col-12', {
                    'col-md-7':
                        props.activeSet.suggestedReferenceObjectAnswers.referenceObjectAnswers.length > 0 &&
                        props.activeSet.referenceObjectAnswers.length === 0,
                    'col-md-6': props.activeSet.suggestedReferenceObjectAnswers.referenceObjectAnswers.length === 0,
                })}
            >
                <div className="reference-objects-nav-btn-container">
                    {props.activeSet.suggestedReferenceObjectAnswers.referenceObjectAnswers.length > 0 &&
                    props.activeSet.referenceObjectAnswers.length === 0 ? (
                        <button
                            className="btn btn-sm btn-secondary float-right ion-md-copy"
                            onClick={() =>
                                props.onAddMany(props.activeSet.suggestedReferenceObjectAnswers.referenceObjectAnswers)
                            }
                        >
                            Kopieër van {props.activeSet.suggestedReferenceObjectAnswers.referenceSetName}
                        </button>
                    ) : null}
                </div>
            </div>

            <div className="reference-set-valuation-questions-container">
                {props.activeSet.id === null
                    ? renderMutedFieldsForActiveSet()
                    : questionsForSets.length > 0
                    ? renderSet(questionsForSets, adaptedValuesMap)
                    : renderMutedFieldsForActiveSet()}
            </div>
        </>
    );
});
