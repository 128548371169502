import React from 'react';
import {QuestionTableChildContext} from './question_table_context';

export function QuestionTableColumn({columnKey, children}: {columnKey: string; children?: React.ReactNode}) {
    return (
        <QuestionTableChildContext.Provider
            value={{
                columnKey,
            }}
            key={columnKey}
        >
            {children}
        </QuestionTableChildContext.Provider>
    );
}
