import * as React from 'react';

import {Appraisal} from '../../../../../../../../../models/appraisal';
import {FloorAreaBadge} from '../../../../../../../../../components/badges/floor_area_badge';
import {FloorAreaPrice} from '../floor_area_price';
import {PriceRange} from '../price_range';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {VolumeBadge} from '../../../../../../../../../components/badges/volume_badge';
import {PlotAreaBadge} from '../../../../../../../../../components/badges/plot_area_badge';
import {BuildYearBadge} from '../../../../../../../../../components/badges/build_year_badge';
import {ObjectType} from '../../../../../../../../../enum/object_type';
import {ObjectTypeBadge} from '../../../../../../../../../components/badges/object_type_badge';
import {FloorAreaPriceRent} from '../floor_area_price_rent';
import {isEmpty} from '../../../../../../../../../../support/util';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';
import {SimpleTooltip} from '../../../../../../../../../components/tooltip';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;

    referenceSetType: SetType | null;
    referenceValuation: number | null;
    referenceSurfaceArea: number | null;
    referencePlotArea: number | null;
    referenceBuildYear: number | null;
    referenceVolume: number | null;
    referenceEnergyLabel: string | null;
    referenceObjectType: ObjectType | null;

    referenceObjectId: string;
    floorArea: number | null;
    plotArea: number | null;
    buildYear: number | null;
    objectType: string | null;
    energyLabel: string | null;

    priceRange: string | number | null;
    indexedPriceRange: string | number | null;
    period: string | null;
    volume: number | null;
}

export class ReferenceObjectDetails extends React.Component<OwnProps> {
    public render() {
        const {
            appraisal,
            questionSet,
            referenceSetType,
            referenceValuation,
            referenceSurfaceArea,
            referencePlotArea,
            referenceBuildYear,
            referenceVolume,
            referenceEnergyLabel,
            referenceObjectType,
            floorArea,
            plotArea,
            buildYear,
            objectType,
            energyLabel,
            priceRange,
            indexedPriceRange,
            period,
            volume,
        } = this.props;

        return (
            <>
                <div className="row">
                    <div className="col-12 text-left">
                        <dl>
                            <dt>Woningtype</dt>
                            <dd>
                                <SimpleTooltip placement="top" content="Dit betreft het referentie object.">
                                    <span data-type="info">{objectType ?? '-'}</span>
                                </SimpleTooltip>
                                {referenceObjectType && (
                                    <>
                                        <SimpleTooltip placement="top" content="Dit betreft het getaxeerde object.">
                                            <span data-type="info">
                                                <ObjectTypeBadge
                                                    objectType={objectType as ObjectType}
                                                    referenceObjectType={referenceObjectType}
                                                />
                                            </span>
                                        </SimpleTooltip>
                                    </>
                                )}
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 text-left">
                        <dl>
                            <dt>Bouwjaar</dt>
                            <dd>
                                <SimpleTooltip placement="top" content="Dit betreft het referentie object.">
                                    <span data-type="info">{buildYear !== null ? buildYear : '-'}</span>
                                </SimpleTooltip>
                                {referenceBuildYear !== null && buildYear !== null ? (
                                    <>
                                        <SimpleTooltip
                                            placement="top"
                                            content="Dit betreft het verschil van het getaxeerde object tov het referentie object."
                                        >
                                            <span data-type="info">
                                                <BuildYearBadge
                                                    compact
                                                    buildYear={buildYear}
                                                    referenceBuildYear={referenceBuildYear}
                                                />
                                            </span>
                                        </SimpleTooltip>
                                    </>
                                ) : null}
                            </dd>
                        </dl>
                    </div>
                    <div className="col-6 text-right">
                        <dl>
                            <dt>Energielabel</dt>
                            <dd>
                                <SimpleTooltip placement="top" content="Dit betreft het referentie object.">
                                    <span data-type="info">{energyLabel !== null ? energyLabel : '-'}</span>
                                </SimpleTooltip>{' '}
                                <SimpleTooltip placement="top" content="Dit betreft het getaxeerde object.">
                                    <span data-type="info">
                                        {referenceEnergyLabel !== null ? '(' + referenceEnergyLabel + ')' : ''}
                                    </span>
                                </SimpleTooltip>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 text-left">
                        <dl>
                            <dt>GBO wonen</dt>
                            <dd>
                                <SimpleTooltip placement="top" content="Dit betreft het referentie object.">
                                    <span data-type="info">{floorArea !== null ? floorArea + 'm²' : '-'}</span>
                                </SimpleTooltip>

                                {referenceSurfaceArea !== null && floorArea !== null ? (
                                    <>
                                        <SimpleTooltip
                                            placement="top"
                                            content="Dit betreft het verschil van het getaxeerde object tov het referentie object."
                                        >
                                            <span data-type="info">
                                                <FloorAreaBadge
                                                    compact
                                                    floorArea={floorArea}
                                                    referenceFloorArea={referenceSurfaceArea}
                                                />
                                            </span>
                                        </SimpleTooltip>
                                    </>
                                ) : null}
                            </dd>
                        </dl>
                    </div>
                    <div className="col-6 text-right">
                        <dl>
                            <dt>Perceelopp.</dt>
                            <dd>
                                <SimpleTooltip placement="top" content="Dit betreft het referentie object.">
                                    <span data-type="info">{plotArea !== null ? plotArea + 'm²' : '-'}</span>
                                </SimpleTooltip>
                                {referencePlotArea !== null && plotArea !== null ? (
                                    <>
                                        <SimpleTooltip
                                            placement="top"
                                            content="Dit betreft het verschil van het getaxeerde object tov het referentie object."
                                        >
                                            <span data-type="info">
                                                <PlotAreaBadge
                                                    compact
                                                    plotArea={plotArea}
                                                    referencePlotArea={referencePlotArea}
                                                />
                                            </span>
                                        </SimpleTooltip>
                                    </>
                                ) : null}
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 text-left">
                        <dl>
                            <dt>Bruto inhoud</dt>
                            <dd>
                                <SimpleTooltip placement="top" content="Dit betreft het referentie object.">
                                    <span data-type="info">{volume !== null ? 'ca. ' + volume + 'm³' : '-'}</span>
                                </SimpleTooltip>
                                {volume !== null && referenceVolume !== null && (
                                    <>
                                        <SimpleTooltip
                                            placement="top"
                                            content="Dit betreft het verschil van het getaxeerde object tov het referentie object."
                                        >
                                            <span data-type="info">
                                                <VolumeBadge
                                                    compact
                                                    volume={volume}
                                                    referenceVolume={referenceVolume}
                                                />
                                            </span>
                                        </SimpleTooltip>
                                    </>
                                )}
                            </dd>
                        </dl>
                    </div>
                    <div className="col-6 text-right">
                        {referenceSetType === SetType.RENT ? (
                            <dl>
                                <dt>Huurprijs per m²</dt>
                                <dd>
                                    <FloorAreaPriceRent
                                        referenceObjectFloorArea={floorArea}
                                        referenceObjectPriceRange={priceRange}
                                        referenceObjectIndexedPrice={indexedPriceRange}
                                        compact={true}
                                        questionSet={questionSet}
                                        appraisal={appraisal}
                                    />
                                </dd>
                            </dl>
                        ) : (
                            <dl>
                                <dt>Prijs per m²</dt>
                                <dd>
                                    <FloorAreaPrice
                                        referenceObjectId={this.props.referenceObjectId}
                                        referenceObjectFloorArea={floorArea}
                                        referenceSetPrice={referenceValuation}
                                        referenceSetFloorArea={referenceSurfaceArea}
                                        referenceObjectPriceRange={priceRange}
                                        referenceObjectIndexedPrice={indexedPriceRange}
                                        compact={true}
                                    />
                                </dd>
                            </dl>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 text-left">
                        <dl>
                            <dt>Prijsklasse</dt>
                            <dd>
                                <PriceRange
                                    range={
                                        !isEmpty(indexedPriceRange)
                                            ? String(indexedPriceRange)
                                            : priceRange !== null
                                            ? String(priceRange)
                                            : null
                                    }
                                    break={true}
                                />
                            </dd>
                        </dl>
                    </div>
                    <div className="col-6 text-right">
                        <dl>
                            <dt>Periode</dt>
                            <dd>{period ?? '-'}</dd>
                        </dl>
                    </div>
                </div>
            </>
        );
    }
}
