import {action, makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {Presenter} from '../../../../../support/presenter/presenter';
import {TextAIInteractor} from '../../../../business/textai/textai_interactor';
import {AnswerController} from '../../../../business/answering/answer_controller';
import {Answer} from '../../../../models/answer';
import {Subscription} from 'rxjs';
import {TextAIMessage, TextAIRole} from '../../../../network/text_ai_api';
import {FileReference} from '../../../../models/file_reference';
import {AnswerTouchState} from '../../../../enum/answer_touch_state';
import {isEmpty} from '../../../../../support/util';

export class TextAIChatWidgetPresenter implements Presenter {
    private subscriptions = new CompositeSubscription();

    private chatSubscription?: Subscription;

    @observable private answer: Answer;
    @observable public file: FileReference | null = null;
    @observable public loading = false;
    @observable public response: string | null = null;
    @observable public promptInput = '';
    @observable public messages: TextAIMessage[] = [];

    constructor(
        private answerUuid: string,
        private answerController: AnswerController,
        private textAIInteractor: TextAIInteractor
    ) {
        const answer = this.answerController.byUuid(this.answerUuid);
        if (!answer) {
            throw new Error('Question or answer not found');
        }

        this.answer = answer;

        makeObservable(this);
    }

    public mount() {
        this.subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(this.answer.questionUuid, this.answer.parentUuid, this.answer.iteration)
                .subscribe((answer) => {
                    this.answer = answer;
                })
        );

        const contextBuilders = this.textAIInteractor.contextBuildersFor(this.answer);
        let file: FileReference | null = null;
        for (const contextBuilder of contextBuilders) {
            file = contextBuilder.getFile?.(this.answer) ?? null;
            if (file) {
                break;
            }
        }

        runInAction(() => {
            this.messages = [];
            this.file = file;
        });

        // Auto initiate chat
        this.chat(null);
    }

    public unmount() {
        this.subscriptions.clear();
    }

    @action
    public setPromptInput(prompt: string) {
        this.promptInput = prompt;
    }

    public setAnswer(content: string) {
        if (!isEmpty(this.answer.contents) && this.answer.contents !== content) {
            if (
                !confirm(
                    'Weet je zeker dat je dit bericht in wil vullen als antwoord op de vraag? Hiermee wordt je huidige antwoord overschreven.'
                )
            ) {
                return;
            }
        }

        this.answerController.onContentsChange(this.answer.uuid, content, AnswerTouchState.TOUCHED);
    }

    public chat(prompt: string | null) {
        const contextBuilders = this.textAIInteractor.contextBuildersFor(this.answer);

        prompt = prompt ?? this.textAIInteractor.getPromptForAnswer(this.answer, contextBuilders);

        if (!prompt) return;

        const stream = this.textAIInteractor.generate(
            prompt,
            this.textAIInteractor.contextBuildersFor(this.answer),
            this.answer,
            {
                useFileReferences: true,
                previousMessages: this.messages,
            }
        );

        if (this.chatSubscription) {
            this.chatSubscription.unsubscribe();
        }

        runInAction(() => {
            this.response = '';
            this.promptInput = '';
            this.loading = true;
            this.messages.push({content: prompt as string, role: TextAIRole.USER});
        });

        this.chatSubscription = stream.subscribe({
            next: (result) => {
                runInAction(() => {
                    this.response = this.response + result;
                });
            },
            error: (err) => {
                console.error(err);

                runInAction(() => {
                    this.response = null;
                    this.loading = false;
                });
            },
            complete: () => {
                runInAction(() => {
                    this.loading = false;
                    if (this.response) {
                        this.messages.push({content: this.response, role: TextAIRole.ASSITANT});
                        this.response = null;
                    }
                });
            },
        });
    }
}
