import * as React from 'react';

import {FunctionComponent} from 'react';
import {ObjectType} from '../../../../../../../../../../../enum/object_type';
import {ObjectTypeSelect} from '../../../../../../../components/object_type_select';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';

interface OwnProps {
    referenceObjectAnswer: ReferenceObjectAnswer;
    onObjectTypeChange: (objectType: ObjectType) => void;
    isFrozen: boolean;
}

export const ObjectTypeComponent: FunctionComponent<OwnProps> = ({
    referenceObjectAnswer,
    onObjectTypeChange,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-12">
                <dl>
                    <div className="form-group">
                        <label htmlFor="object_type">
                            Woningtype
                            <span className="form-label-highlight">*</span>
                        </label>
                        <ObjectTypeSelect
                            value={validationInstituteReferentieObject.woningType}
                            onChange={onObjectTypeChange}
                            disabled={isFrozen}
                        />
                    </div>
                </dl>
            </div>
        </div>
    );
};
