import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from '../simple/simple_question_presenter';

import {HouseGroupQuestionPresenter} from './house_group_question_presenter';
import {classNames} from '../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {shouldShowTitle} from '../../../../../../support/should_show_title';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const HouseGroupQuestion: React.FC<OwnProps> = observer(function HouseGroupQuestion(props) {
    const presenter = usePresenter(
        (container) =>
            new HouseGroupQuestionPresenter(...simpleQuestionPresenterConstructorParametersFactory(props, container))
    );
    if (
        presenter.numberOfAttics === undefined ||
        presenter.numberOfFloors === undefined ||
        presenter.numberOfGroundFloors === undefined ||
        presenter.numberOfBasements === undefined
    ) {
        return <div>Loading..</div>;
    }

    return (
        <div className="form-group house-group">
            {shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel) && (
                <h2>Selecteer bouwlagen</h2>
            )}
            <div className="row">
                <div className="col-3 col-md-2 offset-md-1 d-flex align-items-center">
                    <a
                        className={classNames('btn btn-round ion-md-remove', {
                            disabled: presenter.numberOfAttics === 0 || props.disabled || presenter.isDisabled,
                        })}
                        onClick={() => presenter.decrementAttics()}
                    >
                        &nbsp;
                    </a>
                </div>
                <div className="col-6 col-md-6 d-flex align-items-center">
                    <div
                        className={classNames('house-part-attic', {
                            disabled: presenter.numberOfAttics === 0 || props.disabled || presenter.isDisabled,
                        })}
                    >
                        <span className="count">{presenter.numberOfAttics}</span>
                    </div>
                </div>
                <div className="col-3 col-md-2 d-flex align-items-center">
                    <a
                        className={classNames('btn btn-round ion-md-add', {
                            disabled: props.disabled || presenter.isDisabled,
                        })}
                        onClick={() => presenter.incrementAttics()}
                    >
                        &nbsp;
                    </a>
                </div>
                <div className="col-12">
                    <label>{presenter.numberOfAttics === 1 ? 'Zolder' : 'Zolders'} </label>
                </div>
                <div className="spacer-small">&nbsp;</div>
            </div>

            <div className="row">
                <div className="col-3 col-md-2 offset-md-1 d-flex align-items-center">
                    <a
                        className={classNames('btn btn-round ion-md-remove', {
                            disabled: presenter.numberOfFloors === 0 || props.disabled || presenter.isDisabled,
                        })}
                        onClick={() => presenter.decrementFloors()}
                    >
                        &nbsp;
                    </a>
                </div>
                <div className="col-6 col-md-6 d-flex align-items-center justify-content-center">
                    <div
                        className={classNames('house-part-floor', {
                            disabled: presenter.numberOfFloors === 0 || props.disabled || presenter.isDisabled,
                        })}
                    >
                        <span className="count">{presenter.numberOfFloors}</span>
                    </div>
                </div>
                <div className="col-3 col-md-2 d-flex align-items-center">
                    <a
                        className={classNames('btn btn-round ion-md-add', {
                            disabled: props.disabled || presenter.isDisabled,
                        })}
                        onClick={() => presenter.incrementFloors()}
                    >
                        &nbsp;
                    </a>
                </div>
                <div className="col-12">
                    <label>{presenter.numberOfFloors === 1 ? 'Verdieping' : 'Verdiepingen'}</label>
                </div>
                <div className="spacer-small">&nbsp;</div>
            </div>

            <div className="row">
                <div className="col-3 col-md-2 offset-md-1 d-flex align-items-center">
                    <a
                        className={classNames('btn btn-round ion-md-remove', {
                            disabled: presenter.numberOfGroundFloors === 0 || props.disabled || presenter.isDisabled,
                        })}
                        onClick={() => presenter.decrementGroundFloors()}
                    >
                        &nbsp;
                    </a>
                </div>
                <div className="col-6 col-md-6 d-flex align-items-center justify-content-center">
                    <div
                        className={classNames('house-part-floor', {
                            disabled: presenter.numberOfGroundFloors === 0 || props.disabled || presenter.isDisabled,
                        })}
                    >
                        <span className="count">{presenter.numberOfGroundFloors}</span>
                    </div>
                </div>
                <div className="col-3 col-md-2 d-flex align-items-center">
                    <a
                        className={classNames('btn btn-round ion-md-add', {
                            disabled: presenter.numberOfGroundFloors !== 0 || props.disabled || presenter.isDisabled,
                        })}
                        onClick={() => presenter.incrementGroundFloors()}
                    >
                        &nbsp;
                    </a>
                </div>
                <div className="col-12">
                    <label>Begane grond</label>
                </div>
                <div className="spacer-small">&nbsp;</div>
            </div>

            <div className="row">
                <div className="col-3 col-md-2 offset-md-1 d-flex align-items-center">
                    <a
                        className={classNames('btn btn-round ion-md-remove', {
                            disabled: presenter.numberOfBasements === 0 || props.disabled || presenter.isDisabled,
                        })}
                        onClick={() => presenter.decrementBasements()}
                    >
                        &nbsp;
                    </a>
                </div>
                <div className="col-6 col-md-6 d-flex align-items-center justify-content-center">
                    <div
                        className={classNames('house-part-floor', {
                            disabled: presenter.numberOfBasements === 0 || props.disabled || presenter.isDisabled,
                        })}
                    >
                        <span className="count">
                            <span>{presenter.numberOfBasements}</span>
                        </span>
                    </div>
                </div>
                <div className="col-3 col-md-2 d-flex align-items-center">
                    <a
                        className={classNames('btn btn-round ion-md-add', {
                            disabled: props.disabled || presenter.isDisabled,
                        })}
                        onClick={() => presenter.incrementBasements()}
                    >
                        &nbsp;
                    </a>
                </div>
                <div className="col-12">
                    <label>{presenter.numberOfBasements === 1 ? 'Kelder' : 'Kelders'}</label>
                </div>
            </div>
        </div>
    );
});
