import {IteratorQuestionType, NormalQuestionType} from '../../enum/question_type';
import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from './validation_message';

import {AnswerController} from '../answering/answer_controller';
import {Question} from '../../models/question';
import {ChildQuestionValidator} from './child_validator';
import {QuestionEffectInteractor} from '../conditions/question_effects_interactor';
import {QuestionSet} from '../../models/question_set';
import {SidebarItem} from '../sidebar_tree_builder';
import {SidebarItemsProvider} from '../sidebar_items_provider';
import {first} from 'rxjs/internal/operators/first';
import {getNewestAnswer} from '../../../support/get_newest_answer';
import {map} from 'rxjs/operators';
import {PagePartValidationProvider} from './page_part_validation_provider';
import {PagePartsSet} from '../../models/page_parts_set';
import {PagePart} from '../../models/page_part';
import {
    V3SetDefinition,
    V3SetDefinitionsProvider,
} from '../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/reference_sets/set_definitions_provider';

export type ValidationMap = {
    sidebarItem: SidebarItem;
} & Record<ValidationMessageImportance, ValidationMessage[]>;

export interface VolatileValidationMessagesFromTreeCollector {
    getMap(): Promise<ValidationMap[]>;
}

export class DefaultVolatileValidationMessagesFromTreeCollector implements VolatileValidationMessagesFromTreeCollector {
    private setDefinitions: V3SetDefinition[] | null = null;

    constructor(
        private questionSet: QuestionSet,
        private sidebarItemsProvider: SidebarItemsProvider,
        private childValidator: ChildQuestionValidator,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor,
        private pagePartValidationProvider: PagePartValidationProvider,
        private pagePartsSet: PagePartsSet | null,
        private setDefinitionsProvider: V3SetDefinitionsProvider
    ) {
        this.setDefinitionsProvider.setDefinitions().subscribe((setDefinitions) => {
            this.setDefinitions = setDefinitions;
        });
    }

    private async validateQuestion(
        questionUuid: string,
        iteration: string | null,
        blacklistedQuestionsUuids: string[]
    ): Promise<ValidationMessage[]> {
        const answers = await this.answerController
            .answersForQuestionUuidAndIteration(questionUuid, iteration)
            .pipe(
                map((a) => this.answerController.filterDeleted(a)),
                map((a) =>
                    a.filter((answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid))
                ),
                first()
            )
            .toPromise();
        const newestAnswer = answers ? getNewestAnswer(answers) : null;

        return this.childValidator.validate(
            questionUuid,
            newestAnswer === null ? null : newestAnswer.uuid,
            blacklistedQuestionsUuids
        );
    }

    private async validateSidebarItem(
        sidebarItem: SidebarItem,
        iteration: string | null,
        blacklistedQuestions: Question[],
        refsObjectsV3Parent: Question | null
    ): Promise<ValidationMessage[]> {
        if (sidebarItem.question === undefined) {
            return [];
        }
        let messages: ValidationMessage[] = [];

        messages = await this.validateQuestion(
            sidebarItem.question.uuid,
            iteration,
            blacklistedQuestions.map((q) => q.uuid)
        );

        if (sidebarItem.question.uuid === refsObjectsV3Parent?.uuid) {
            for (const blacklistedQuestion of blacklistedQuestions) {
                // blacklistedQuestion is the iterator question
                // setDefinitions will contain the parent group of the iterator question if the reference set is enabled
                if (
                    this.setDefinitions &&
                    !this.setDefinitions.some((s) => s.groupTree.item.question.uuid === blacklistedQuestion.parentUuid)
                ) {
                    continue;
                }

                const blacklistedAnswers = this.answerController.filterDeleted(
                    this.answerController
                        .answersForQuestionUuid(blacklistedQuestion.uuid)
                        .filter((answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid))
                );
                for (const answer of blacklistedAnswers) {
                    messages = [...messages, ...this.childValidator.validate(blacklistedQuestion.uuid, answer.uuid)];
                }
            }
        }

        return messages;
    }

    private messageToMap(sidebarItem: SidebarItem, messages: ValidationMessage[]): ValidationMap {
        return {
            sidebarItem,
            [ValidationMessageImportance.ERROR]: messages.filter(
                (message) =>
                    message.type !== ValidationMessageType.VALIDATION_INSTITUTE &&
                    message.importance === ValidationMessageImportance.ERROR
            ),
            [ValidationMessageImportance.WARNING]: messages.filter(
                (message) =>
                    message.type !== ValidationMessageType.VALIDATION_INSTITUTE &&
                    message.importance === ValidationMessageImportance.WARNING
            ),
            [ValidationMessageImportance.INFO]: messages.filter(
                (message) =>
                    message.type !== ValidationMessageType.VALIDATION_INSTITUTE &&
                    message.importance === ValidationMessageImportance.INFO
            ),
        };
    }

    private async getPagePartValidations(
        sidebarItems: SidebarItem[],
        pagePartsSet: PagePartsSet
    ): Promise<ValidationMap[]> {
        return Promise.all(
            sidebarItems.map(async (sidebarItem) => {
                const allPageParts = new Map<string, PagePart>();
                const pagePart = sidebarItem.pagePart;
                if (pagePart) {
                    allPageParts.set(pagePart.uuid, pagePart);
                    const childPageParts = pagePartsSet.getRecursiveChildrenForUuid(pagePart.uuid);
                    for (const childPagePart of childPageParts) {
                        allPageParts.set(childPagePart.uuid, childPagePart);
                    }
                }

                const validations =
                    (await this.pagePartValidationProvider
                        .getPagePartsValidationsStream(Array.from(allPageParts.values()), 0)
                        .pipe(first())
                        .toPromise()) ?? [];

                return this.messageToMap(sidebarItem, validations);
            })
        );
    }

    private async getOldValidation(sidebarItems: SidebarItem[]) {
        const result: ValidationMap[] = [];
        for (const sidebarItem of sidebarItems) {
            if (sidebarItem.question) {
                const blacklistedQuestions = this.questionSet.findQuestionsByType(
                    IteratorQuestionType.ITERATOR_REFERENCE_OBJECTS_V3
                );

                const refObjectsV3 = this.questionSet.findQuestionsByType(NormalQuestionType.REFERENCE_OBJECTS_V3)[0];
                const refsObjectsV3Parent =
                    refObjectsV3 && refObjectsV3.parentUuid
                        ? this.questionSet.findQuestionByUuid(refObjectsV3.parentUuid) ?? null
                        : null;

                const messages = await this.validateSidebarItem(
                    sidebarItem,
                    sidebarItem.iteration,
                    blacklistedQuestions,
                    refsObjectsV3Parent
                );

                result.push(this.messageToMap(sidebarItem, messages));
            }
        }
        return result;
    }

    public async getMap(): Promise<ValidationMap[]> {
        const sidebarItems = await this.sidebarItemsProvider.flattened().pipe(first()).toPromise();
        if (!sidebarItems) {
            return [];
        }

        if (this.pagePartsSet !== null && sidebarItems.some((i) => i.pagePart !== undefined)) {
            return this.getPagePartValidations(sidebarItems, this.pagePartsSet);
        } else {
            return this.getOldValidation(sidebarItems);
        }
    }
}
