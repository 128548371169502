export function humanizeArray(array: string[], separator = ', ', lastSeparator = ' en '): string {
    if (array.length === 0) {
        return '';
    }
    if (array.length === 1) {
        return array[0];
    }
    if (array.length >= 2) {
        return array.slice(0, -1).join(separator) + lastSeparator + array.slice(-1);
    }

    return array.join(separator);
}
