import {BehaviorSubject, Observable} from 'rxjs';

export enum NetworkStatus {
    OFFLINE = 0,
    ONLINE = 1,
}

export interface NetworkStatusProvider {
    status(): Observable<NetworkStatus>;
}

export class DefaultNetworkStatusProvider implements NetworkStatusProvider {
    private _subject = new BehaviorSubject<NetworkStatus>(
        navigator.onLine ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE
    );

    constructor() {
        window.addEventListener('online', () => this._subject.next(NetworkStatus.ONLINE));
        window.addEventListener('offline', () => this._subject.next(NetworkStatus.OFFLINE));
    }

    public status(): Observable<NetworkStatus> {
        return this._subject;
    }
}
