import {debounceTime, distinctUntilChanged, first, map, switchMap} from 'rxjs/operators';
import {makeObservable, observable, runInAction} from 'mobx';

import {AnswerController} from '../../../business/answering/answer_controller';
import {ChildQuestionValidationProvider} from '../../../business/validation/child_validation_provider';
import {CompositeSubscription} from '../../../../support/composite_subscription';
import {History} from 'history';
import {Presenter} from '../../../../support/presenter/presenter';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {SidebarItemForQuestionProvider} from '../../../business/sidebar_item_for_question_provider';
import {ValidationMessage} from '../../../business/validation/validation_message';
import {getNewestAnswer} from '../../../../support/get_newest_answer';

export class ContentPresenter implements Presenter {
    @observable.ref public question: Question | undefined;
    @observable.ref public validationMessages: ValidationMessage[] = [];

    private subscriptions = new CompositeSubscription();

    constructor(
        private uuid: string,
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private childValidationProvider: ChildQuestionValidationProvider,
        private history: History,
        private sidebarItemForQuestionProvider: SidebarItemForQuestionProvider
    ) {
        makeObservable(this);

        this.question = this.questionSet.findQuestionByUuid(this.uuid);
    }

    public mount(): void {
        const question = this.question;
        if (question === undefined) {
            return;
        }

        // The content will render the first found sidebar item. However, in some cases (detail views) there is no need
        // to render the sidbar item, but the specific url is enough.
        this.subscriptions.add(
            this.sidebarItemForQuestionProvider.find(question).subscribe((sidebarItem) => {
                if (sidebarItem && sidebarItem.question?.uuid !== this.uuid) {
                    this.history.push(sidebarItem.link);
                }
            })
        );

        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidStream(question.uuid)
                .pipe(
                    debounceTime(100),
                    map((answers) => getNewestAnswer(answers)?.uuid ?? null),
                    distinctUntilChanged(),
                    switchMap((answerUuid) =>
                        this.childValidationProvider.validate(
                            question.uuid,
                            answerUuid === null ? answerUuid : answerUuid
                        )
                    ),
                    //We are not interested in any "new" validationMessages since only the
                    //`validation_institute_validation` sets the `messages` array
                    first()
                )
                .subscribe((validationMessages) => {
                    runInAction(() => {
                        this.validationMessages = validationMessages;
                    });
                })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
