import {Appraisal} from '../../../models/appraisal';
import {observer} from 'mobx-react';
import * as React from 'react';
import {AppraisalRecentUserActionsPresenter} from './appraisal_recent_user_actions_presenter';
import {usePresenter} from '../../../../support/presenter/use_presenter';
import {SimpleTooltip} from '../../../components/tooltip';

interface OwnProps {
    appraisal: Appraisal;
}

export const AppraisalRecentUserActions: React.FC<OwnProps> = observer(function AppraisalRecentUserActions(props) {
    const presenter = usePresenter(
        (container) => new AppraisalRecentUserActionsPresenter(props.appraisal, container.network.appraisalApi)
    );

    if (presenter.appraisalRecentActions.length === 0) {
        return null;
    }

    return (
        <SimpleTooltip content={presenter.appraisalRecentActionsQuestionSentence} placement="top">
            <span className="badge badge-pill badge-secondary ml-2" data-type="info">
                <span className="ion-md-information-circle"></span> {presenter.appraisalRecentActionsSentence}
            </span>
        </SimpleTooltip>
    );
});
