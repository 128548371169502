import {computed, makeObservable} from 'mobx';

import {humanizeArray} from '../../../support/humanize_array';
import {SimpleQuestionPresenter} from '../simple/simple_question_presenter';

export interface SelectDynamicAnswerContents {
    options: string[];
    selected: string[];
    other: string | null;
}

export class MultipleChoiceSelectDynamicQuestionPresenter extends SimpleQuestionPresenter {
    @computed
    public get contents(): SelectDynamicAnswerContents {
        if (!this.answer || this.answer.contents === null) {
            return {
                options: [],
                selected: [],
                other: null,
            };
        }

        try {
            return JSON.parse(this.answer.contents);
        } catch (e) {
            console.warn('Failed to decode multiple-choice-select-dynamic answer. Falling back to other value', e);
            return {
                options: [],
                selected: [],
                other: this.answer.contents,
            };
        }
    }

    constructor(...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public onContentsChange(value: SelectDynamicAnswerContents) {
        return this.onChange(JSON.stringify(value));
    }

    public static format(answerContents: string | null): string | null {
        if (answerContents === null) {
            return null;
        }

        try {
            const content: SelectDynamicAnswerContents = JSON.parse(answerContents);

            const values = content.selected;
            if (content.other !== null) {
                values.push(content.other);
            }

            return humanizeArray(values);
        } catch (e) {
            console.error('Failed to decode multiple-choice-select-dynamic answer', e);
            return null;
        }
    }
}
