import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {ReferenceObjectWidgetItem} from './widget_item';
import {observer} from 'mobx-react';
import {ReferenceSale} from '../../../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v1/models/reference_sale';

interface OwnProps {
    title: string;
    objects: ReferenceSale[];
    deselect: (obj: ReferenceSale) => void;
}

@observer
export class ReferenceObjectWidget extends React.Component<OwnProps> {
    public render() {
        if (this.props.objects.length === 0) {
            return null;
        }

        return (
            <div className="widget">
                <div className="widget-title">
                    <h3>
                        <span className="icon-pin" />
                        {this.props.title}
                    </h3>
                </div>
                <div className="row px-5 pb-3">
                    {this.props.objects.map((obj) => (
                        <ReferenceObjectWidgetItem key={obj.id} object={obj} onClick={() => this.props.deselect(obj)} />
                    ))}
                </div>
                <div className="row d-flex justify-content-center pb-3">
                    <span className="status-label">Tip</span>
                    Klik op een referentie om deze te verwijderen.
                </div>
            </div>
        );
    }
}
