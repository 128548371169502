import {Observable, ReplaySubject} from 'rxjs';
import {
    ValidationMap,
    VolatileValidationMessagesFromTreeCollector,
} from './volatile_validation_messages_from_tree_collector';
import {ValidationMessage, ValidationMessageImportance} from './validation_message';
import {first, map} from 'rxjs/operators';

import {GlobalProvider} from '../../../business/global_provider';
import {QuestionSet} from '../../models/question_set';
import {TaskHelper} from '../task_helper';
import {TaskReference} from '../../models/task_reference';
import {ValidationError} from '../../models/validation_error';
import {ValidationErrorApi} from '../../network/validation_error_api';
import {lazy} from '../../../support/lazy';
import {GenericErrorsValidator} from './generic_errors_validator';

export interface ValidationConclusionProvider {
    stream: Observable<ValidationConclusion>;

    refresh(): Promise<void>;
    refreshShallow(): Promise<void>;
    setIgnoreValidationGate(ignore: boolean): Promise<void>;
}

export enum PreValidationConclusiongState {
    WAITING = 'waiting',
    LOADING = 'loading',
    PROVIDED = 'provided',
    ERRORED = 'errored',
}

interface PreValidationConclusion {
    state: PreValidationConclusiongState;
    errors: ValidationError[];
}

interface ValidationConclusionData {
    validationMap: ValidationMap[];
    genericValidationErrors: ValidationMessage[];
    preValidationErrors: PreValidationConclusion;
    gateAllowsValidation: boolean;
}

export interface ValidationConclusion extends ValidationConclusionData {
    hasValidationErrors: boolean;
    hasValidationMessages: boolean;
    hasPreValidationErrors: boolean;
}

export class DefaultValidationConclusionProvider implements ValidationConclusionProvider {
    private refreshing = false;
    private shouldRefresh = false;

    private subject = new ReplaySubject<ValidationConclusionData>(1);
    private ignoreValidationGate = false;

    @lazy()
    public get stream(): Observable<ValidationConclusion> {
        return this.subject.pipe(map((validationConclusionData) => this.toConclusion(validationConclusionData)));
    }

    constructor(
        private appraisalId: number,
        private questionSet: QuestionSet,
        private genericErrorsValidator: GenericErrorsValidator,
        private volatileValidationMessagesFromTreeCollector: VolatileValidationMessagesFromTreeCollector,
        private validationErrorApi: ValidationErrorApi,
        private taskHelper: TaskHelper,
        private globalProvider: GlobalProvider
    ) {}

    public async setIgnoreValidationGate(ignore: boolean) {
        this.ignoreValidationGate = ignore;
        await this.refreshShallow();
    }

    public async refreshShallow(): Promise<void> {
        const validationConclusion = await this.subject.pipe(first()).toPromise();

        if (!this.refreshing && validationConclusion) {
            const validationMap = await this.volatileValidationMessagesFromTreeCollector.getMap();
            const genericValidationErrors = this.genericErrorsValidator.validate();
            this.subject.next({
                validationMap: validationMap,
                genericValidationErrors: genericValidationErrors,
                preValidationErrors: validationConclusion.preValidationErrors,
                gateAllowsValidation: this.allowsValidation(),
            });
        }
    }

    public async refresh(): Promise<void> {
        if (this.refreshing) {
            this.shouldRefresh = true;
            return;
        }
        this.shouldRefresh = false;
        this.refreshing = true;
        const validationMap = await this.volatileValidationMessagesFromTreeCollector.getMap();
        const genericValidationErrors = this.genericErrorsValidator.validate();

        this.subject.next({
            validationMap: validationMap,
            genericValidationErrors: genericValidationErrors,
            preValidationErrors: {
                state: PreValidationConclusiongState.WAITING,
                errors: [],
            },
            gateAllowsValidation: this.allowsValidation(),
        });

        try {
            if (
                this.questionSet.reportDefintionConfig.preValidationMessages &&
                !(
                    genericValidationErrors.some(
                        (validationMessage) => validationMessage.importance === ValidationMessageImportance.ERROR
                    ) ||
                    validationMap.some(
                        (vM) =>
                            vM.sidebarItem.question !== undefined && vM[ValidationMessageImportance.ERROR].length > 0
                    )
                )
            ) {
                this.subject.next({
                    validationMap: validationMap,
                    genericValidationErrors: genericValidationErrors,
                    preValidationErrors: {
                        state: PreValidationConclusiongState.LOADING,
                        errors: [],
                    },
                    gateAllowsValidation: this.allowsValidation(),
                });

                try {
                    let preValidationErrors: ValidationError[] | TaskReference | null =
                        await this.validationErrorApi.refresh(this.appraisalId);
                    if (TaskHelper.isTaskReference(preValidationErrors)) {
                        preValidationErrors = await this.taskHelper.poll<ValidationError[]>(preValidationErrors.taskId);
                    }
                    if (preValidationErrors !== null) {
                        const filteredPreValidationErrors = preValidationErrors.filter(
                            //We're filtering this, since fetching model values is literally the next step after appraiser,
                            //So this message makes no sense in this location
                            (preValidationError) =>
                                !preValidationError.message.toLowerCase().includes('modelwaarde') &&
                                !preValidationError.field.toLowerCase().includes('modelwaarde')
                        );

                        this.subject.next({
                            validationMap: validationMap,
                            genericValidationErrors: genericValidationErrors,
                            preValidationErrors: {
                                state: PreValidationConclusiongState.PROVIDED,
                                errors: filteredPreValidationErrors,
                            },
                            gateAllowsValidation: this.allowsValidation(),
                        });
                    }
                } catch (error) {
                    console.warn(error);
                    this.subject.next({
                        validationMap: validationMap,
                        genericValidationErrors: genericValidationErrors,
                        preValidationErrors: {
                            state: PreValidationConclusiongState.ERRORED,
                            errors: [],
                        },
                        gateAllowsValidation: this.allowsValidation(),
                    });
                }
            }
        } finally {
            this.refreshing = false;
            if (this.shouldRefresh) {
                this.refresh();
            }
        }
    }

    private allowsValidation() {
        return this.ignoreValidationGate ? true : this.globalProvider.global.gateAllowsValidation ?? false;
    }

    private toConclusion(validationConclusionData: ValidationConclusionData): ValidationConclusion {
        return {
            ...validationConclusionData,
            hasValidationErrors:
                validationConclusionData.genericValidationErrors.some(
                    (validationMessage) => validationMessage.importance === ValidationMessageImportance.ERROR
                ) ||
                validationConclusionData.validationMap.some(
                    (validationMap) =>
                        validationMap.sidebarItem.question !== undefined &&
                        validationMap[ValidationMessageImportance.ERROR].length > 0
                ),
            hasValidationMessages:
                validationConclusionData.genericValidationErrors.length > 0 ||
                validationConclusionData.validationMap.some(
                    (validationMap) =>
                        (validationMap.sidebarItem.question !== undefined &&
                            validationMap[ValidationMessageImportance.ERROR].length > 0) ||
                        validationMap[ValidationMessageImportance.WARNING].length > 0 ||
                        validationMap[ValidationMessageImportance.INFO].length > 0
                ),
            hasPreValidationErrors:
                this.questionSet.reportDefintionConfig.preValidationMessages &&
                !(
                    validationConclusionData.preValidationErrors.state === PreValidationConclusiongState.PROVIDED &&
                    validationConclusionData.preValidationErrors.errors.length === 0
                ),
        };
    }
}
