import {makeObservable, observable, runInAction} from 'mobx';

import {AppraiseSecondaryConfig} from '../../../models/appraise_secondary_config';
import {AppraiseSecondaryConfigStackInteractor} from '../../../business/appraise_secondary_config_stack_interactor';
import {CompositeSubscription} from '../../../../support/composite_subscription';
import {Presenter} from '../../../../support/presenter/presenter';
import {debounce} from 'rxjs/operators';
import {timer} from 'rxjs';
import {ConfigWithCallbacks} from '../../../business/generic_config_stack_interactor';

export class AppraiseSecondaryPresenter implements Presenter {
    @observable.ref
    public stack: ConfigWithCallbacks<AppraiseSecondaryConfig, unknown>[] = [];

    private subscriptions = new CompositeSubscription();
    constructor(private appraiseSecondaryConfigStackInteractor: AppraiseSecondaryConfigStackInteractor) {
        makeObservable(this);
    }

    //Keep track if we just mounted, debounce longer so the initial page load is snappier
    private shouldDebounceLonger = true;
    private shouldDebounceTimer = setTimeout(() => {
        this.shouldDebounceLonger = false;
    }, 5000);

    public mount(): void {
        this.subscriptions.add(
            this.appraiseSecondaryConfigStackInteractor
                .stream()
                .pipe(debounce(() => timer(this.shouldDebounceLonger ? 800 : 100)))
                .subscribe((stack) => {
                    runInAction(() => {
                        this.stack = stack;
                    });
                })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
        clearTimeout(this.shouldDebounceTimer);
    }
}
