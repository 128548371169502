import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {ExplanationData} from './explanation_data';

export class ExplanationBuildYearGenerator {
    public generateDetailsSentence(
        explanationData: ExplanationData,
        referenceObjectAnswer: ReferenceObjectAnswer
    ): string {
        const result: string[] = [];
        if (referenceObjectAnswer.referenceObject.bouwjaar !== null && explanationData.object.buildYear !== null) {
            if (referenceObjectAnswer.referenceObject.bouwjaar > explanationData.object.buildYear) {
                result.push(
                    `${referenceObjectAnswer.referenceObject.bouwjaar - explanationData.object.buildYear} jaar nieuwer`
                );
            } else if (referenceObjectAnswer.referenceObject.bouwjaar < explanationData.object.buildYear) {
                result.push(
                    `${explanationData.object.buildYear - referenceObjectAnswer.referenceObject.bouwjaar} jaar ouder`
                );
            }
        }
        return result.join('');
    }

    public generateBuildyearStatus(referenceObjectAnswer: ReferenceObjectAnswer, explanationData: ExplanationData) {
        if (referenceObjectAnswer.referenceObject.bouwjaar !== null && explanationData.object.buildYear !== null) {
            const diff = this.getBuildyearDiff(referenceObjectAnswer, explanationData);
            if (diff !== null) {
                if (diff > 10 && diff <= 20) {
                    return 'Jonger';
                } else if (diff > 20) {
                    return 'Veel jonger';
                } else if (diff < -10 && diff >= -20) {
                    return 'Ouder';
                } else if (diff < -20) {
                    return 'Veel ouder';
                } else {
                    return 'Vergelijkbaar';
                }
            }
        }
        return 'Vergelijkbaar';
    }

    private getBuildyearDiff(
        referenceObjectAnswer: ReferenceObjectAnswer,
        explanationData: ExplanationData
    ): number | null {
        if (referenceObjectAnswer.referenceObject.bouwjaar === null || explanationData.object.buildYear === null) {
            return null;
        }

        return referenceObjectAnswer.referenceObject.bouwjaar - explanationData.object.buildYear;
    }
}
