import * as React from 'react';

import {FunctionComponent} from 'react';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';
import {Select} from '../../../inputs/select';

interface OwnProps {
    referenceObjectAnswer: ReferenceObjectAnswer;
    onExtensionDifferenceChange: (extensionDifference: string) => void;
    isFrozen: boolean;
}

export const ExtensionDifference: FunctionComponent<OwnProps> = ({
    referenceObjectAnswer,
    onExtensionDifferenceChange,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-12">
                <Select
                    label="Bij-, op en/of aanbouwen verschil"
                    name="input-extra-buildings"
                    options={['Veel beter', 'Beter', 'Vergelijkbaar', 'Minder', 'Veel minder']}
                    value={validationInstituteReferentieObject.aanbouwStatus}
                    onChange={onExtensionDifferenceChange}
                    disabled={isFrozen}
                />
            </div>
        </div>
    );
};
