import * as React from 'react';

import {classNames} from '../../support/classnames';
import {BadgeContext} from '../enum/badge_context';
import {statusTextToBadgeContext} from './badges/badge_context_calculators';

interface OwnProps {
    label: string;
    value: string | null;
    status: string | null;
    useSmallText?: boolean;
    hideTextNeutral?: boolean;
}

export class StatusDetailListForStatus extends React.Component<OwnProps> {
    public render() {
        if (this.props.status !== null) {
            const badgeContext = statusTextToBadgeContext(this.props.status);
            const badge = this.props.status;

            let text = this.props.value ?? '-';
            if (this.props.hideTextNeutral && badgeContext === BadgeContext.BadgeNeutral) text = '-';

            return (
                <dl>
                    <dt>{this.props.label}</dt>
                    <dd>
                        <span className={classNames('badge-container-wide', badgeContext)}>
                            {this.props.useSmallText !== undefined && this.props.useSmallText ? (
                                <small> {text}</small>
                            ) : (
                                text
                            )}
                            {badge.length > 0 ? (
                                <span className={classNames('badge', badgeContext)}>{badge}</span>
                            ) : null}
                        </span>
                    </dd>
                </dl>
            );
        }

        return (
            <dl>
                <dt>{this.props.label}</dt>
                <dd>
                    <span className={classNames('badge-container-wide')}>
                        {this.props.useSmallText !== undefined && this.props.useSmallText ? (
                            <small> {this.props.value || '-'}</small>
                        ) : (
                            this.props.value || '-'
                        )}
                    </span>
                </dd>
            </dl>
        );
    }
}
