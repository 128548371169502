import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {FunctionComponent} from 'react';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {ValidationMessageMap} from '../../business/validation/validation_message';
import {QuestionType} from '../../enum/question_type';
import {RenderingContextType} from '../../enum/rendering_context_type';
import {Appraisal} from '../../models/appraisal';
import {QuestionSet} from '../../models/question_set';
import {ValidationError} from '../../models/validation_error';
import {AppraisalComponent} from './appraisal';
import {Loader} from './components/loader';
import {ActivePagePartsSetLoaderPresenter} from './active_page_parts_set_loader_presenter';
import {QuestionRenderingData} from '../../models/question_rendering_data';

interface OwnProps {
    pagePartConfigurationId: string;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    onChange: (appraisal: Appraisal) => void;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    hiddenQuestionTypes: QuestionType[];
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const ActivePagePartsSetLoader: FunctionComponent<OwnProps> = observer(
    ({
        pagePartConfigurationId,
        appraisal,
        onChange,
        questionSet,
        validationErrors,
        validationMessages,
        hiddenQuestionTypes,
        renderingContext,
        questionRenderingData,
    }) => {
        const presenter = usePresenter(
            (component) =>
                new ActivePagePartsSetLoaderPresenter(component.business.pagePartsSetInteractor(appraisal, questionSet))
        );

        const selectedPagePartsSet =
            presenter.pagePartsSets?.find((pps) => pps.id === parseInt(pagePartConfigurationId, 10)) ?? null;

        if (presenter.pagePartsSets === null || selectedPagePartsSet === null) {
            return (
                <div className="empty-message appraise">
                    <Loader />
                    <h3>Aan het laden...</h3>
                </div>
            );
        }

        return (
            <AppraisalComponent
                appraisal={appraisal}
                onChange={(appraisal) => onChange(appraisal)}
                questionSet={questionSet}
                validationErrors={validationErrors}
                validationMessages={validationMessages}
                hiddenQuestionTypes={hiddenQuestionTypes}
                pagePartsSet={selectedPagePartsSet}
                renderingContext={renderingContext}
                questionRenderingData={questionRenderingData}
                forceShowValidationMessages={false}
            />
        );
    }
);
