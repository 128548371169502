$('.btn-copy').each((_, el) => {
    const input = document.getElementById(el.attributes['data-copy-input']?.value);
    if (input) {
        el.addEventListener('click', () => {
            input.select();
            input.setSelectionRange(0, 99999);

            navigator.clipboard.writeText(input.value);

            if (el.classList.contains('btn-copy-icon')) {
                el.classList.remove('ion-md-copy');
                el.classList.add('ion-md-checkmark');
                setTimeout(() => {
                    el.classList.remove('ion-md-checkmark');
                    el.classList.add('ion-md-copy');
                }, 1000);
            }
        });
    }
});
