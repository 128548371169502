import Driver from 'driver.js';
import {getTooltipSteps} from './tooltip_step_definitions';

(() => {
    // Selectors
    const selector = '[data-tooltip]';
    const loadedSelector = '#appraising-loaded';
    const startOnboardingSelector = '[data-tooltip-steps-start]';

    // Init
    const driver = new Driver({
        opacity: 0.5,
        animate: false,
        doneBtnText: 'Klaar',
        closeBtnText: 'Sluiten',
        nextBtnText: 'Volgende',
        prevBtnText: 'Vorige',
        allowClose: false,
    });

    if ($('#appraise').length !== 0) {
        // Wait for the appraisal environment to load
        const checkExist = setInterval(function () {
            if ($(loadedSelector).length) {
                clearInterval(checkExist);
                render();
            }
        }, 500);
    } else {
        // Show the tooltips in normal views
        render();
    }

    function shouldStartOnboarding(): boolean {
        return document.querySelectorAll(startOnboardingSelector).length !== 0;
    }

    /**
     * Start the onboarding on key combination CTRL+H
     */
    function startOnKeyCombination(d: Driver) {
        document.addEventListener('keydown', function (event) {
            if (event.key === 'F1') {
                event.preventDefault();
                d.start();
            }
        });
    }

    /**
     * Render the tooltips
     */
    function render() {
        const stepDefinitions = getTooltipSteps(selector);
        driver.defineSteps(stepDefinitions);

        if (shouldStartOnboarding()) {
            if (document.readyState === 'complete') {
                driver.start();
            } else {
                window.addEventListener('load', function () {
                    driver.start();
                });
            }
        }

        startOnKeyCombination(driver);
    }
})();
