import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {PagePartsSetSelectorPresenter} from './page_part_set_selector_presenter';
import {Popper} from '../../../../components/popper';
import {QuestionSet} from '../../../../models/question_set';
import {classNames} from '../../../../../support/classnames';
import {observer} from 'mobx-react-lite';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {Link} from 'react-router-dom';

export interface OwnProps {
    pagePartConfigurationId: string;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    configuratorUrl: string | null;
}

export const PagePartsSetSelector: React.FC<OwnProps> = observer(
    ({pagePartConfigurationId, appraisal, questionSet, configuratorUrl}) => {
        const presenter = usePresenter((component) => {
            return new PagePartsSetSelectorPresenter(component.business.pagePartsSetInteractor(appraisal, questionSet));
        });

        const selectedPagePartsSet =
            presenter.visiblePagePartsSets?.find((pps) => pps.id === parseInt(pagePartConfigurationId, 10)) ?? null;

        if (selectedPagePartsSet === null || presenter.visiblePagePartsSets === null) {
            return null;
        }

        const fullConfiguratorUrl = `${configuratorUrl}/${selectedPagePartsSet.id}${
            selectedPagePartsSet.rootItems.length > 0 ? `/${selectedPagePartsSet.rootItems[0].uuid}` : ''
        }`;

        return (
            <Popper
                isPopperOpen={presenter.isConfigSelectorOpen}
                setIsPopperOpen={presenter.setIsConfigSelectorOpen}
                className="config-selector"
                popperOffset={[0, 10]}
                toggle={
                    <button
                        className="btn-text-link ion-md-arrow-dropdown icon-right"
                        onClick={() => presenter.setIsConfigSelectorOpen(!presenter.isConfigSelectorOpen)}
                    >
                        {selectedPagePartsSet.name}
                    </button>
                }
                popperClassName="config-selector-dialog"
                popper={
                    <>
                        {presenter.visiblePagePartsSets.map((set) => (
                            <span className="config-row" key={set.id}>
                                <Link
                                    onClick={() => presenter.onConfigChange(set)}
                                    to={`/config-id/${set.id}/page-part/${set.rootItems[0]?.uuid ?? ''}`}
                                    className={classNames('config-row-link', {
                                        active: selectedPagePartsSet?.id === set.id,
                                    })}
                                >
                                    {set.name}
                                </Link>
                            </span>
                        ))}
                        {configuratorUrl && (
                            <span className="config-row" key="back">
                                <a href={fullConfiguratorUrl} className="config-row-link ion-md-list">
                                    Configuratiebeheer
                                </a>
                            </span>
                        )}
                    </>
                }
            />
        );
    }
);
