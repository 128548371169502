import {NotificationMessage} from '../../models/notification_message';
import {parseISO} from 'date-fns';

export interface ApiNotification {
    id: string;
    subject: string;
    content: string;
    read_at: string | null;
    dismissed_at: string | null;
    remind_at: string | null;
    created_at: string;
    file_id: number | null;
    appraisal_id: number | null;
    is_new: boolean;
    is_reminded: boolean;
    user_name: string | null;
}

export function apiNotificationToNotification(apiNotification: ApiNotification): NotificationMessage {
    return {
        id: apiNotification.id,
        subject: apiNotification.subject,
        content: apiNotification.content,
        readAt: apiNotification.read_at !== null ? parseISO(apiNotification.read_at) : null,
        dismissedAt: apiNotification.dismissed_at !== null ? parseISO(apiNotification.dismissed_at) : null,
        remindAt: apiNotification.remind_at !== null ? parseISO(apiNotification.remind_at) : null,
        createdAt: parseISO(apiNotification.created_at),
        fileId: apiNotification.file_id,
        appraisalId: apiNotification.appraisal_id,
        isNew: apiNotification.is_new,
        isReminded: apiNotification.is_reminded,
        userName: apiNotification.user_name,
    };
}

export function notificationToApiNotification(notification: NotificationMessage): ApiNotification {
    return {
        id: notification.id,
        subject: notification.subject,
        content: notification.content,
        read_at: notification.readAt !== null ? notification.readAt.toISOString() : null,
        dismissed_at: notification.dismissedAt !== null ? notification.dismissedAt.toISOString() : null,
        remind_at: notification.remindAt !== null ? notification.remindAt.toISOString() : null,
        created_at: notification.createdAt.toISOString(),
        file_id: notification.fileId,
        appraisal_id: notification.appraisalId,
        is_new: notification.isNew,
        is_reminded: notification.isReminded,
        user_name: notification.userName,
    };
}
