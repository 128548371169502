import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {Answer} from '../../../../../models/answer';
import {AnswerController} from '../../../../../business/answering/answer_controller';
import {AnswerTouchState} from '../../../../../enum/answer_touch_state';
import {Appraisal} from '../../../../../models/appraisal';
import {AppraisalState} from '../../../../../enum/appraisal_state';
import {AppraisalValidationType} from '../../../../../enum/appraisal_validation_type';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {Question} from '../../../../../models/question';
import {QuestionEffectInteractor} from '../../../../../business/conditions/question_effects_interactor';
import {UserInteractor} from '../../../../../business/user_interactor';
import {isNumeric} from '../../../../../../support/util';
import {normalizeNumber} from '../../../../../business/support/normalize_number';

export class SurfaceCalculatorQuestionPresenter implements Presenter {
    @observable public length = 0;
    @observable public width = 0;
    @observable public total = 0;
    @observable public method = 'Lengte keer breedte';
    private parentAnswerUuid: string | null;
    @computed private get data() {
        if (this.answer === undefined || this.answer.contents === null) {
            return null;
        } else {
            return JSON.parse(this.answer.contents) as
                | string
                | {
                      length: string;
                      width: string;
                      total: string;
                  };
        }
    }
    @observable.ref public answer?: Answer;
    @observable
    private subscriptions = new CompositeSubscription();
    @observable private _isHidden = false;

    constructor(
        private question: Question,
        private iteration: string | undefined,
        parentAnswerUuid: string | undefined,
        private appraisal: Appraisal,
        private userInteractor: UserInteractor,
        private questionEffectsInteractor: QuestionEffectInteractor,
        private answerController: AnswerController
    ) {
        makeObservable(this);
        this.parentAnswerUuid = parentAnswerUuid || null;
    }

    @computed
    public get isHidden(): boolean {
        return (
            this._isHidden ||
            (this.question.isAppraiserOnly &&
                !this.userInteractor.isAppraiser() &&
                !this.userInteractor.isEmployee() &&
                !this.userInteractor.isJuniorAppraiser())
        );
    }

    @computed
    public get loading() {
        return this.answer === undefined;
    }

    @computed
    public get isDisabled(): boolean {
        if (!this.appraisal.isEditableAppraisal) {
            return true;
        }
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return this.loading || (this.appraisal.isFrozen && this.question.freezes);
        }
        return (
            this.loading ||
            (this.appraisal.isFrozen && this.question.freezes) ||
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    @action
    public setLength(value: string | null) {
        if (value === null) {
            this.length = 0;
        } else {
            this.length = normalizeNumber(value, 2);
        }
        this.calculateTotal();
    }

    @action
    public setWidth(value: string | null) {
        if (value === null) {
            this.width = 0;
        } else {
            this.width = normalizeNumber(value, 2);
        }
        this.calculateTotal();
    }

    public calculateTotal() {
        if (this.width !== null && this.length !== null) {
            const width = normalizeNumber(this.width, 2);
            const length = normalizeNumber(this.length, 2);
            const normalized = normalizeNumber(width * length, 2);
            this.setTotal(normalized.toString());
        }
    }

    @action
    public setTotal(value: string | null) {
        let jsonObject;
        if (isNumeric(value) && value !== null && this.answer !== undefined) {
            this.total = normalizeNumber(value, 2);
            jsonObject = {
                total: this.total,
                length: this.length,
                width: this.width,
            };
        } else if (value === null && this.answer !== undefined) {
            this.total = 0;
            jsonObject = {
                total: this.total,
                length: this.length,
                width: this.width,
            };
        }
        if (jsonObject && this.answer) {
            this.answerController.onContentsChange(
                this.answer.uuid,
                JSON.stringify(jsonObject),
                AnswerTouchState.TOUCHED
            );
        }
    }

    public mount(): void {
        this.subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid ?? null, this.iteration ?? null)
                .subscribe((answer) => {
                    runInAction(() => {
                        this.answer = answer;
                    });
                })
        );

        runInAction(() => {
            if (this.data === undefined || this.data === null) {
                this.length = 0;
                this.width = 0;
                this.total = 0;
            } else if (isNumeric(this.data)) {
                this.total = parseFloat(this.data);
            } else {
                this.length = parseFloat(this.data.length);
                this.width = parseFloat(this.data.width);
                this.total = parseFloat(this.data.total);
            }
        });

        this.subscriptions.add(
            this.questionEffectsInteractor
                .isHiddenStream(this.question.uuid, this.parentAnswerUuid)
                .subscribe((isHidden) => {
                    runInAction(() => {
                        this._isHidden = isHidden;
                    });
                })
        );
    }

    public unmount(): void {
        if (this.subscriptions !== undefined) {
            this.subscriptions.clear();
        }
    }
}
