import React from 'react';
import {classNames} from '../../../support/classnames';

interface OwnProps {
    warning?: string;
    children?: React.ReactNode;
}

export const WarningWrapper: React.FC<OwnProps> = ({warning, children}: OwnProps) => {
    return (
        <div
            className={classNames('statistics-warning-wrapper', {
                'statistics-warning-wrapper-warning': warning !== undefined,
            })}
        >
            {children}

            {warning && (
                <div className="statistics-warning">
                    {warning}
                    <span className="ion-md-alert" />
                </div>
            )}
        </div>
    );
};
