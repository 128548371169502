import React, {useContext} from 'react';
import {ImageViewerContext} from './image_viewer_context';

export function Image(props: React.ImgHTMLAttributes<HTMLImageElement>) {
    const {open} = useContext(ImageViewerContext);

    return (
        <img
            {...props}
            onClick={(e) => {
                e.preventDefault();
                open(e.currentTarget);
            }}
        />
    );
}
