import {ArcElement, Chart, ChartDataset} from 'chart.js';
import React, {useEffect, useRef} from 'react';

type ChartDatasetWithLabels = ChartDataset & {
    labels: string[];
};

interface OwnProps {
    title: string;
    total?: number;
    data?: ChartDatasetWithLabels[];
    activeIndices?: number[];
}

export const DoughnutChart: React.FC<OwnProps> = ({title, total, data, activeIndices}: OwnProps) => {
    const ref = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const root = document.querySelector(':root');
        const style = root ? getComputedStyle(root) : null;

        const chart = new Chart(ref.current, {
            type: 'doughnut',
            data: {
                datasets:
                    data?.map((value) => ({
                        ...value,
                        backgroundColor: value.data.map((_, i) =>
                            getArcColor(i, activeIndices !== undefined ? !activeIndices.includes(i) : false, style)
                        ),
                    })) ?? [],
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: title,
                    },
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            label: function (context) {
                                if ('labels' in context.dataset) {
                                    return (
                                        (context.dataset as ChartDatasetWithLabels).labels[context.dataIndex] +
                                        ': ' +
                                        context.formattedValue
                                    );
                                }

                                return context.formattedValue;
                            },
                        },
                        enabled: false,
                        external: (context) => {
                            // Tooltip Element
                            let tooltipEl = document.getElementById('chartjs-tooltip');

                            // Create element on first render
                            if (!tooltipEl) {
                                tooltipEl = document.createElement('div');
                                tooltipEl.id = 'chartjs-tooltip';
                                tooltipEl.className = 'precheck-doughnut-chart-tooltip';
                                tooltipEl.innerHTML = '<table></table>';
                                document.body.appendChild(tooltipEl);
                            }

                            // Hide if no tooltip
                            const tooltipModel = context.tooltip;
                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = '0';
                                return;
                            }

                            // Set Text
                            if (tooltipModel.body) {
                                const bodyLines = tooltipModel.body.flatMap((bodyItem) => bodyItem.lines);

                                const tableRoot = tooltipEl.querySelector<HTMLTableElement>('table');
                                if (tableRoot !== null) {
                                    tableRoot.innerHTML = `<tbody>${bodyLines.map(
                                        (line) => `<tr><td><span>${line}</span></td></tr>`
                                    )}</tbody>`;
                                }
                            }

                            const position = context.chart.canvas.getBoundingClientRect();
                            tooltipEl.style.opacity = '1';
                            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                        },
                    },
                },
            },
            plugins: [
                {
                    id: 'centerText',
                    afterDatasetsDraw: (chart: Chart) => {
                        const {ctx} = chart;

                        const x = chart.getDatasetMeta(0)?.data[0]?.x ?? null;
                        const y = chart.getDatasetMeta(0)?.data[0]?.y ?? null;

                        if (x === null || y === null) {
                            return;
                        }

                        ctx.save();
                        ctx.textAlign = 'center';
                        ctx.font = 'bold 14px sans-serif';
                        ctx.fillText(total?.toString() ?? '', x, y + 5);
                        ctx.restore();
                    },
                },
                {
                    id: 'shadow',
                    beforeDraw: ({ctx}) => {
                        ctx.shadowColor = 'rgba(0, 0, 0, 0.10)';
                        ctx.shadowBlur = 10;
                        ctx.shadowOffsetX = 0;
                        ctx.shadowOffsetY = 0;
                    },
                },
                {
                    id: 'hoverColor',
                    beforeDraw: (chart) => {
                        const activeArc = (chart
                            .getActiveElements()
                            .map((e) => e.element)
                            .find((element) => element instanceof ArcElement) ?? null) as ArcElement | null;

                        const arcsForDatasets = chart
                            .getSortedVisibleDatasetMetas()
                            .map(
                                (dataset) =>
                                    dataset.data.filter(
                                        (element) => element instanceof ArcElement
                                    ) as unknown as ArcElement[]
                            );

                        arcsForDatasets.forEach((arcs) => {
                            arcs.forEach(
                                (arc, i) =>
                                    (arc.options.backgroundColor = getArcColor(
                                        activeArc !== null
                                            ? arc === activeArc
                                                ? 0
                                                : i
                                            : activeIndices !== undefined && activeIndices.includes(i)
                                            ? 0
                                            : i,
                                        activeArc !== null
                                            ? arc !== activeArc
                                            : activeIndices !== undefined
                                            ? !activeIndices.includes(i)
                                            : false,
                                        style
                                    ))
                            );
                        });
                    },
                },
            ],
        });

        return () => {
            chart.destroy();
        };
    }, [title, total, data, activeIndices]);

    return <canvas ref={ref} />;
};

function getArcColor(index: number, gray = false, style: CSSStyleDeclaration | null) {
    if (gray) {
        return `rgba(194, 194, 194, ${0.7 - index * 0.2})`;
    }

    const r = style?.getPropertyValue('--color-primaryR') ?? '45';
    const g = style?.getPropertyValue('--color-primaryG') ?? '227';
    const b = style?.getPropertyValue('--color-primaryB') ?? '219';

    return `rgba(${r}, ${g}, ${b}, ${1 - index * 0.4})`;
}
