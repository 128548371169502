import * as React from 'react';

import {isEmpty} from '../../../../../../../../../support/util';

interface OwnProps {
    range: string | null;
    break?: boolean;
}

export class PriceRange extends React.Component<OwnProps> {
    private formatPrice(from: string) {
        return `€ ${parseInt(from, 10).toLocaleString('nl-NL')}`;
    }

    public render() {
        if (this.props.range === null || isEmpty(this.props.range.trim())) {
            return <>{'Onbekend'}</>;
        }
        const [from, till] = this.props.range.split(' - ');

        if (from !== undefined && till === undefined) {
            return <span>{this.formatPrice(from)}</span>;
        }

        if (this.props.break === true) {
            return (
                <>
                    <span key="from">{this.formatPrice(from)}</span>
                    <br />
                    <span key="till">{this.formatPrice(till)}</span>
                </>
            );
        }

        return (
            <>
                <span key="from">{this.formatPrice(from)} - </span>
                <span key="till">{this.formatPrice(till)}</span>
            </>
        );
    }
}
