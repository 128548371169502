export function areMapsEqual<TKey, TValue>(
    a: Map<TKey, TValue>,
    b: Map<TKey, TValue>,
    valueCompare?: (a: TValue, b: TValue) => boolean
): boolean {
    if (a.size !== b.size) {
        return false;
    }

    for (const [key, aValue] of a) {
        if (!b.has(key)) {
            return false;
        }
        const bValue = b.get(key);
        if (!bValue) {
            return false;
        }

        const areValuesEqual = valueCompare ? valueCompare(aValue, bValue) : bValue === aValue;
        if (!areValuesEqual) {
            return false;
        }
    }
    return true;
}
