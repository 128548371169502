import * as React from 'react';

import {FunctionComponent} from 'react';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';
import {Textarea} from '../../../inputs/textarea';

interface OwnProps {
    referenceObjectAnswer: ReferenceObjectAnswer;
    onObjectTypeDifferenceExplanationChange: (objectTypeDifferenceExplanation: string) => void;
    isFrozen: boolean;
}

export const ObjectTypeDifferenceExplanation: FunctionComponent<OwnProps> = ({
    referenceObjectAnswer,
    onObjectTypeDifferenceExplanationChange,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-sm-12">
                <Textarea
                    isRequired
                    label="Woningtype verschil toelichting"
                    name="input-object-type-explanation"
                    value={validationInstituteReferentieObject.woningTypeUitleg}
                    onChange={onObjectTypeDifferenceExplanationChange}
                    disabled={isFrozen}
                />
            </div>
        </div>
    );
};
