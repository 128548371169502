import * as React from 'react';

import {Answer} from '../../models/answer';
import {Appraisal} from '../../models/appraisal';
import {ModelValuesAnswerDetails} from '../models/model_values_details_answer';
import {ModelValuesDetails} from '../models/model_values_details';
import {ModelValuesPresenter} from './model_values_presenter';
import {ReferentieObjectContainer} from './components/referentie_object_container';
import {ValidationInstituteReferentieObject} from '../../models/validation_institute_reference_object';
import {classNames} from '../../../support/classnames';
import {observer} from 'mobx-react';
import {QuestionSet} from '../../models/question_set';
import {usePresenter} from '../../../support/presenter/use_presenter';

interface OwnProps {
    answer: Answer;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    modelValuesAnswerDetails: ModelValuesAnswerDetails[];
    modelValuesDetails: ModelValuesDetails;
}

export const ModelValues: React.FC<OwnProps> = observer(function ModelValues(props) {
    const presenter = usePresenter(
        (container) =>
            new ModelValuesPresenter(
                props.appraisal,
                props.answer,
                props.modelValuesDetails,
                props.modelValuesAnswerDetails,
                container.network.answerApi,
                container.serverTimeProvider
            )
    );

    return (
        <>
            <div className="card-header">
                <a href={'/appraisals/' + props.appraisal.id}>Taxatie</a>
            </div>
            <div className="card d-flex">
                <div className="card-header">Modelobjecten</div>
                <div className="card-body">
                    <p className="leader">
                        Maak een keuze uit enkele van onderstaande objecten, en voorzie deze van een omschrijving waarom
                        deze model staan voor het te taxeren object. {presenter.instruction}
                    </p>
                    <div className="row grid d-inline-block">
                        <div className="col-12">
                            <div className="grid-title">
                                <h2>
                                    Eigen referenties{' '}
                                    <span className="grid-title-thin">
                                        (Marktwaarde: € {props.modelValuesDetails.market_value})
                                    </span>
                                </h2>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {props.modelValuesDetails.reference_objects.map((referenceObject, key) => {
                                        return (
                                            <dl key={key} className="text-left">
                                                <dd>
                                                    {referenceObject.streetview_url && (
                                                        <span className="detail-group-image">
                                                            <img src={referenceObject.streetview_url} />
                                                        </span>
                                                    )}
                                                    <strong>
                                                        <span>
                                                            {referenceObject.address.street}{' '}
                                                            {referenceObject.address.house_number}{' '}
                                                            {referenceObject.address.letter
                                                                ? referenceObject.address.letter
                                                                : null}
                                                        </span>
                                                    </strong>
                                                    <br />
                                                    <span className="uppercase">
                                                        {referenceObject.address.postal_code}{' '}
                                                        {referenceObject.address.city}
                                                    </span>
                                                </dd>
                                            </dl>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row grid d-inline-block">
                        {presenter.referenceObjectList !== null &&
                            presenter.referenceObjectList.map(
                                (modelWaardeDetail: {
                                    modelwaardeLeverancier: string;
                                    modelwaarde: string;
                                    referentieObjecten: ValidationInstituteReferentieObject[];
                                }) => (
                                    <div key={modelWaardeDetail.modelwaardeLeverancier} className="col-12">
                                        <div className="grid-title">
                                            <h2>
                                                {modelWaardeDetail.modelwaardeLeverancier}{' '}
                                                <span className="grid-title-thin">
                                                    € {modelWaardeDetail.modelwaarde}
                                                </span>
                                            </h2>
                                        </div>
                                        <div className="row">
                                            {modelWaardeDetail.referentieObjecten.map(
                                                (referentieObject: ValidationInstituteReferentieObject, i: number) => (
                                                    <ReferentieObjectContainer
                                                        key={i}
                                                        modelValuesDetails={props.modelValuesDetails}
                                                        appraisal={props.appraisal}
                                                        questionSet={props.questionSet}
                                                        referentieObject={referentieObject}
                                                        onSubmit={() => presenter.onSubmit()}
                                                        onChange={(changedReferentieObject) =>
                                                            presenter.onChange(changedReferentieObject)
                                                        }
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                )
                            )}
                    </div>
                </div>
                <footer className="d-flex justify-content-end">
                    <a href={'/appraisals/' + props.appraisal.id} className="btn btn-secondary">
                        Beëindigen
                    </a>
                    <div
                        className={classNames('btn btn-primary', {
                            disabled: !presenter.submitButtonEnabled,
                        })}
                        onClick={() => presenter.submitButtonEnabled && presenter.store()}
                    >
                        Opslaan & insturen ter validatie
                    </div>
                </footer>
            </div>
        </>
    );
});
