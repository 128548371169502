import * as React from 'react';
import {RootPresenter} from './root_presenter';
import {observer} from 'mobx-react';
import {ModelValues} from './ui/model_values';
import {Loader} from '../appraise/ui/components/loader';
import {usePresenter} from '../../support/presenter/use_presenter';

interface OwnProps {
    appraisalId: number | null;
    questionSetId: number | null;
}

export const Root: React.FC<OwnProps> = observer((props) => {
    const presenter = usePresenter(
        (container) =>
            new RootPresenter(
                props.appraisalId,
                props.questionSetId,
                container.network.appraisalApi,
                container.network.questionSetApi,
                container.serverTimeProvider,
                container.business.appraisalProvider,
                container.business.answerLoader
            )
    );

    if (presenter.loading) {
        return (
            <div className="empty-message appraise">
                <Loader />
                <h3>Aan het laden...</h3>
            </div>
        );
    }

    if (
        presenter.error ||
        presenter.appraisal === undefined ||
        presenter.questionSet === undefined ||
        presenter.answer === undefined
    ) {
        return (
            <div className="empty-message appraise">
                <h3>Helaas, fout tijdens het laden</h3>
                <span className="spacer-gradient">&nbsp;</span>
            </div>
        );
    }

    return (
        <ModelValues
            answer={presenter.answer}
            appraisal={presenter.appraisal}
            questionSet={presenter.questionSet}
            modelValuesAnswerDetails={presenter.modelValuesAnswerDetails}
            modelValuesDetails={presenter.modelValuesDetails}
        />
    );
});
