import {FindChildrenResult, TreeItem, buildTree} from './generic_tree';

import {Answer} from '../appraising/models/answer';
import {Question} from '../appraising/models/question';
import {QuestionSet} from '../appraising/models/question_set';
import {PagePartsSet} from '../appraising/models/page_parts_set';
import {PagePart} from '../appraising/models/page_part';
import {QuestionEffectInteractor} from '../appraising/business/conditions/question_effects_interactor';
import {buildAnswersByQuestionUuidMap} from './question_answer_tree';
import {NormalQuestionType} from '../appraising/enum/question_type';
import {SymlinkType} from '../appraising/appraise/ui/content/questions/advanced/symlink/symlink_link_presenter';

export interface QuestionAnswerPair {
    question: Question;
    answer: Answer | null;
    isHidden: boolean;
}

function getChildren(
    questionSet: QuestionSet,
    answersByQuestionUuidMap: Map<string, Answer[]>,
    questionParentAnswerMap: Map<string, Map<string, Answer[]>>,
    parent: QuestionAnswerPair,
    hadRootAnswer: boolean,
    questionEffectsInteractor: QuestionEffectInteractor
): FindChildrenResult<QuestionAnswerPair, boolean> {
    if (
        parent.question.type === NormalQuestionType.SYMLINK_LINK &&
        parent.question.technicalReference &&
        parent.answer?.contents
    ) {
        const otherQuestions = questionSet.findQuestionsByTechnicalReference(parent.question.technicalReference);
        const targetQuestion = otherQuestions.filter((q) => q.type !== NormalQuestionType.SYMLINK_LINK);

        const contents = JSON.parse(parent.answer.contents);
        let iteration = parent.question.uuid;
        if (contents?.type === SymlinkType.MIRROR && contents.targetIterationUuid) {
            iteration = contents.targetIterationUuid;
        }
        if (parent.answer) {
            iteration = `${parent.question.uuid}|${parent.answer.uuid}`;
        }

        const byTargetQuestion =
            answersByQuestionUuidMap.get(targetQuestion[0].uuid)?.filter((a) => a.iteration === iteration) ?? [];
        const symlinkTargets = byTargetQuestion.map((a) => {
            return {
                question: targetQuestion[0],
                answer: a,
                isHidden: questionEffectsInteractor.isHidden(targetQuestion[0].uuid, parent.answer?.uuid ?? null),
            };
        });

        return {
            children: symlinkTargets,
            metaData: hadRootAnswer ?? parent.answer !== null,
        };
    }

    const childrenQuestions = questionSet.findChildQuestionsByParentUuid(parent.question.uuid);
    const result: QuestionAnswerPair[] = [];
    for (const childQuestion of childrenQuestions) {
        let childrenAnswers: Answer[] = [];
        if (parent.answer === null) {
            if (!hadRootAnswer) {
                childrenAnswers = questionSet
                    .findChildQuestionsByParentUuid(parent.question.uuid)
                    .flatMap((question) => answersByQuestionUuidMap.get(question.uuid) ?? []);
            }
        } else {
            childrenAnswers = questionParentAnswerMap.get(childQuestion.uuid)?.get(parent.answer.uuid) ?? [];
        }

        const parentUuidForQuestion =
            childQuestion.type === NormalQuestionType.SYMLINK_LINK ? null : parent.answer?.uuid ?? null;
        if (childrenAnswers.length === 0) {
            result.push({
                question: childQuestion,
                answer: null,
                isHidden: questionEffectsInteractor.isHidden(childQuestion.uuid, parentUuidForQuestion),
            });
        } else {
            for (const childrenAnswer of childrenAnswers) {
                result.push({
                    question: childQuestion,
                    answer: childrenAnswer,
                    isHidden: questionEffectsInteractor.isHidden(childQuestion.uuid, parentUuidForQuestion),
                });
            }
        }
    }
    return {
        children: result,
        metaData: hadRootAnswer ?? parent.answer !== null,
    };
}

export function buildQuestionParentAnswerMap(answers: Answer[]) {
    const map = new Map<string, Map<string, Answer[]>>();
    for (const answer of answers) {
        if (answer.parentUuid) {
            const innerMap = map.get(answer.questionUuid);
            if (innerMap) {
                const answerList = innerMap.get(answer.parentUuid);
                if (answerList) {
                    answerList.push(answer);
                } else {
                    innerMap.set(answer.parentUuid, [answer]);
                }
            } else {
                map.set(answer.questionUuid, new Map<string, Answer[]>([[answer.parentUuid, [answer]]]));
            }
        }
    }
    return map;
}

export function buildPagePartTree(
    pagePartSet: PagePartsSet,
    questionSet: QuestionSet,
    answers: Answer[],
    questionEffectsInteractor: QuestionEffectInteractor
): Array<TreeItem<QuestionAnswerPair | PagePart>> {
    const answerByQuestionUuidMap = buildAnswersByQuestionUuidMap(answers);
    const questionParentAnswerMap = buildQuestionParentAnswerMap(answers);

    return pagePartSet.rootItems.map((pagePart) => {
        return buildTree<QuestionAnswerPair | PagePart, boolean>(pagePart, (parent) =>
            getPagePartChildren(
                pagePartSet,
                questionSet,
                questionEffectsInteractor,
                answerByQuestionUuidMap,
                questionParentAnswerMap,
                parent
            )
        );
    });
}

function getPagePartChildren(
    pagePartSet: PagePartsSet,
    questionSet: QuestionSet,
    questionEffectsInteractor: QuestionEffectInteractor,
    answersByQuestionUuidMap: Map<string, Answer[]>,
    questionParentAnswerMap: Map<string, Map<string, Answer[]>>,
    parent: QuestionAnswerPair | PagePart
): FindChildrenResult<QuestionAnswerPair | PagePart, boolean> {
    if ('uuid' in parent) {
        if (parent.isIndexPage === false && parent.questionUuid) {
            const rootAnswers = answersByQuestionUuidMap.get(parent.questionUuid) ?? [];
            const question = questionSet.findQuestionByUuid(parent.questionUuid);
            if (question) {
                if (rootAnswers.length === 0) {
                    return {
                        children: [
                            {
                                question: question,
                                answer: null,
                                isHidden: questionEffectsInteractor.isHidden(question.uuid, null),
                            },
                        ],
                        metaData: false,
                    };
                }
                return {
                    children: rootAnswers.map((answer) => ({
                        question: question,
                        answer: answer,
                        isHidden: questionEffectsInteractor.isHidden(question.uuid, null),
                    })),
                    metaData: true,
                };
            }
        } else {
            return {
                children: pagePartSet.getChildrenForUuid(parent.uuid),
                metaData: false,
            };
        }
    } else {
        return getChildren(
            questionSet,
            answersByQuestionUuidMap,
            questionParentAnswerMap,
            parent,
            false,
            questionEffectsInteractor
        );
    }

    return {
        children: [],
        metaData: false,
    };
}
