export function setupInvoiceFlowWidget(widgetContainer: HTMLElement) {
    const update = (selectElement: HTMLSelectElement) => {
        const selectedValue = selectElement.options[selectElement.selectedIndex].value;

        widgetContainer.querySelectorAll('.invoice-flow-widget-progress').forEach((element) => {
            const values = element.getAttribute('data-invoice-flow-type')?.split(',') ?? [];

            if (values.includes(selectedValue)) {
                element.classList.add('active');
            } else {
                element.classList.remove('active');
            }
        });
    };

    const selectElement = document.querySelector<HTMLSelectElement>('select[name="invoice_flow"]');

    if (selectElement === null) {
        return;
    }

    selectElement.addEventListener('change', () => {
        update(selectElement);
    });

    update(selectElement);
}
