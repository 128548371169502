import React, {useEffect, useRef, useState} from 'react';
import {classNames} from '../../../../support/classnames';

function getScrollableElement(node: HTMLElement): HTMLElement | null {
    if (node.scrollHeight > node.clientHeight) {
        return node;
    }

    if (node.parentElement) {
        return getScrollableElement(node.parentElement);
    }

    return null;
}

interface OwnProps extends React.HTMLProps<HTMLDivElement> {
    threshold?: number;
}

export function ScrollShrink({threshold, ...divProps}: OwnProps) {
    const [shrink, setShrink] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const scrollElement = getScrollableElement(ref.current);

        if (!scrollElement) {
            return;
        }

        const handleScroll = () => {
            setShrink(scrollElement.scrollTop > (threshold ?? 0));
        };

        scrollElement.addEventListener('scroll', handleScroll);

        handleScroll();

        return () => {
            scrollElement.removeEventListener('scroll', handleScroll);
        };
    }, [threshold]);

    return (
        <div
            {...divProps}
            ref={ref}
            // eslint-disable-next-line react/prop-types
            className={classNames(divProps.className, 'scroll-shrink', {
                grow: !shrink,
                shrink,
            })}
        />
    );
}
