import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {Appraisal} from '../../../../models/appraisal';
import {AppraisalApi} from '../../../../network/appraisal_api';
import {Presenter} from '../../../../../support/presenter/presenter';
import {Macro, MacroExternalType} from '../../../../models/macro';
import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {MacroInteractor} from '../../../../business/macro_interactor';

export class NotesWidgetPresenter implements Presenter {
    @observable public notes: string;
    @observable.ref public macros?: Macro[];

    private subscriptions = new CompositeSubscription();

    @observable
    private lastSavedNotes: string;
    private saveNotesTimeout: number | null = null;

    constructor(
        private appraisal: Appraisal,
        private appraisalApi: AppraisalApi,
        private macroInteractor: MacroInteractor
    ) {
        makeObservable(this);
        this.notes = appraisal.notes ?? '';
        this.lastSavedNotes = appraisal.notes;
    }

    public mount() {
        this.subscriptions.add(
            this.macroInteractor.macrosForExternalType(MacroExternalType.APPRAISAL_NOTES).subscribe((macros) => {
                runInAction(() => {
                    this.macros = macros;
                });
            })
        );
    }

    public unmount() {
        //Noop
    }

    @computed
    public get isSaved() {
        return this.lastSavedNotes === this.notes;
    }

    @action
    public onChangeNotes(value: string) {
        this.notes = value;

        if (this.saveNotesTimeout !== null) {
            clearTimeout(this.saveNotesTimeout);
        }
        this.saveNotesTimeout = window.setTimeout(async () => {
            if (this.notes === this.lastSavedNotes) {
                return;
            }
            const notes = this.notes;
            await this.appraisalApi.updateNotes(this.appraisal.id, this.notes);
            this.lastSavedNotes = notes;
        }, 500);
    }
    public onAddAsMacroClick = async () => {
        if (this.notes !== '') {
            try {
                await this.macroInteractor.storeExternal(this.notes, MacroExternalType.APPRAISAL_NOTES);
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };

    public onRemoveMacroClick = async (toBeRemovedMacro: Macro) => {
        if (this.macros !== undefined) {
            try {
                const macro = this.macros.find((m) => m.id === toBeRemovedMacro.id);
                if (macro !== undefined) {
                    await this.macroInteractor.destroy(macro.id);
                }
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };
}
