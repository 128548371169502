import {computed, makeObservable, observable, runInAction} from 'mobx';

import {ExplanationAppraiserGenerator} from '../../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/explanation_appraiser_generator/explanation_appraiser_generator';
import {ModelValuesDetails} from '../../models/model_values_details';
import {Presenter} from '../../../../support/presenter/presenter';
import {ValidationInstituteReferentieObject} from '../../../models/validation_institute_reference_object';
import {isEmpty} from '../../../../support/util';
import {MacroInteractor} from '../../../business/macro_interactor';
import {Macro, MacroExternalType} from '../../../models/macro';
import {CompositeSubscription} from '../../../../support/composite_subscription';
import {TechnicalReference} from '../../../enum/technical_reference';
import {getNewestAnswer} from '../../../../support/get_newest_answer';
import {AnswerController} from '../../../business/answering/answer_controller';
import {QuestionSet} from '../../../models/question_set';
import {GlobalProvider} from '../../../../business/global_provider';

export class ReferentieObjectContainerPresenter implements Presenter {
    @observable.ref public referentieObjectForModal: ValidationInstituteReferentieObject | null = null;
    @observable.ref public macros?: Macro[];
    @observable public energyLabel: string | null = null;

    private subscriptions = new CompositeSubscription();

    @computed
    public get canSave(): boolean {
        if (this.referentieObjectForModal === null) {
            return true;
        }
        return !isEmpty(this.referentieObjectForModal.toelichtingGebruikReferentieObject);
    }

    constructor(
        private referentieObject: ValidationInstituteReferentieObject,
        private modelValuesDetails: ModelValuesDetails,
        private onChange: (
            referentieObject: ValidationInstituteReferentieObject
        ) => ValidationInstituteReferentieObject,
        private onSubmit: () => Promise<void>,
        private explanationAppraiserGenerator: ExplanationAppraiserGenerator,
        private macroInteractor: MacroInteractor,
        private answerController: AnswerController,
        private questionSet: QuestionSet,
        private globalProvider: GlobalProvider
    ) {
        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            this.macroInteractor
                .macrosForExternalType(MacroExternalType.MODEL_VALUES_EXPLANATION)
                .subscribe((macros) => {
                    runInAction(() => {
                        this.macros = macros;
                    });
                })
        );
        const energyLabelQuestion = this.questionSet.findQuestionByTechnicalReference(TechnicalReference.ENERGY_LABEL);
        if (energyLabelQuestion) {
            this.subscriptions.add(
                this.answerController.answersForQuestionUuidStream(energyLabelQuestion.uuid).subscribe((answers) => {
                    runInAction(() => {
                        const answer = getNewestAnswer(answers);
                        if (answer && answer.answerOptionId) {
                            const answerOption = energyLabelQuestion.answerOptions.find(
                                (a) => a.id === answer.answerOptionId
                            );
                            this.energyLabel = answerOption?.contents ?? null;
                        } else {
                            this.energyLabel = answer?.contents ?? this.globalProvider.global.objectEnergyLabel ?? null;
                        }
                    });
                })
            );
        }
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public showDetailsOfReferentieObject(referentieObject: ValidationInstituteReferentieObject) {
        this.referentieObjectForModal = referentieObject;
    }

    public async onReferentieObjectDetailModalClose() {
        await this.onSubmit();
        this.referentieObjectForModal = null;
    }

    public async onAddClick() {
        this.referentieObject = this.onChange({
            ...this.referentieObject,
            _selected: true,
        });
        this.showDetailsOfReferentieObject(this.referentieObject);
        await this.onSubmit();
    }

    public async onDeleteClick() {
        this.referentieObject = this.onChange({
            ...this.referentieObject,
            _selected: false,
        });
        await this.onSubmit();
    }

    public onUncheckClick() {
        this.onDeleteClick();
        this.onReferentieObjectDetailModalClose();
    }

    public onToelichtingGebruikReferentieObject(value: string) {
        if (this.referentieObjectForModal === null) {
            return;
        }

        this.referentieObjectForModal = this.onChange({
            ...this.referentieObjectForModal,
            toelichtingGebruikReferentieObject: value,
        });
    }

    public onToelichtingGenerate() {
        if (this.referentieObjectForModal === null) {
            return;
        }

        let usableArea: number | null = parseInt(this.modelValuesDetails.usable_area, 10) ?? null;
        let lotArea: number | null = parseInt(this.modelValuesDetails.lot_area, 10) ?? null;
        let grossContents: number | null = parseInt(this.modelValuesDetails.gross_contents, 10) ?? null;

        if (Number.isNaN(usableArea)) {
            usableArea = null;
        }
        if (Number.isNaN(lotArea)) {
            lotArea = null;
        }
        if (Number.isNaN(grossContents)) {
            grossContents = null;
        }

        this.onToelichtingGebruikReferentieObject(
            this.explanationAppraiserGenerator.generate(
                {
                    id: '',
                    valuationType: null,
                    uploadedPhotoUrl: null,
                    imageUrlPairs: [],
                    source: null,
                    brochureUrl: null,
                    matchingPercentage: null,
                    hasWarning: false,
                    warning: null,
                    latitude: 0,
                    longitude: 0,
                    saleQuarter: null,
                    rooms: null,
                    frontview: null,
                    referenceObject: {
                        ...this.referentieObjectForModal,
                        woningTypeStatus:
                            this.referentieObjectForModal.woningType === this.modelValuesDetails.object_type
                                ? 'vergelijkbaar'
                                : '',
                    },
                },
                usableArea,
                lotArea,
                grossContents,
                this.energyLabel
            )
        );
    }

    public onAddAsMacroClick = async () => {
        if (
            this.referentieObjectForModal !== null &&
            this.referentieObjectForModal.toelichtingGebruikReferentieObject !== ''
        ) {
            try {
                await this.macroInteractor.storeExternal(
                    this.referentieObjectForModal.toelichtingGebruikReferentieObject,
                    MacroExternalType.MODEL_VALUES_EXPLANATION
                );
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };

    public onRemoveMacroClick = async (toBeRemovedMacro: Macro) => {
        if (this.macros !== undefined) {
            try {
                const macro = this.macros.find((m) => m.id === toBeRemovedMacro.id);
                if (macro !== undefined) {
                    if (!macro.isUserDefined) {
                        await this.macroInteractor.hideForUser(macro.id);
                    } else {
                        await this.macroInteractor.destroy(macro.id);
                    }
                }
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };

    public onFavoriteMacroClick = async (toBeFavoritedMacro: Macro) => {
        if (this.macros !== undefined) {
            try {
                const macro = this.macros.find((m) => m.id === toBeFavoritedMacro.id);
                if (macro !== undefined) {
                    await this.macroInteractor.toggleFavorite(macro.id);
                }
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };
}
