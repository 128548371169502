import {FlashMessageBroadcaster, Type} from '../../../business/flash_message_broadcaster';
import {NetworkStatus, NetworkStatusProvider} from '../../../business/network_status_provider';
import {PhotoAnswerRetryInteractor} from '../../../business/photo_answer_retry_interactor';
import {
    PreValidationConclusiongState,
    ValidationConclusion,
    ValidationConclusionProvider,
} from '../../../business/validation/validation_conclusion_provider';
import {SidebarItem, SidebarTreeBuilder} from '../../../business/sidebar_tree_builder';
import {computed, makeObservable, observable, runInAction, toJS} from 'mobx';
import {debounceTime, first, map} from 'rxjs/operators';
import {getCurrentWindowHash, hashChanges} from '../../../../support/hash_changes';

import {AnswerController} from '../../../business/answering/answer_controller';
import {AnswerInteractor} from '../../../business/answering/answer_interactor';
import {Appraisal} from '../../../models/appraisal';
import {AppraisalNavigator} from '../appraisal_navigator';
import {AppraisalState} from '../../../enum/appraisal_state';
import {ChildQuestionValidator} from '../../../business/validation/child_validator';
import {CompositeSubscription} from '../../../../support/composite_subscription';
import {History} from 'history';
import {Presenter} from '../../../../support/presenter/presenter';
import {UnsyncedAnswerRetryInteractor} from '../../../business/unsynced_answer_retry_interactor';
import {ValidationErrorsPageTab} from '../content/validation_errors_page';
import {ValidationMessageImportance} from '../../../business/validation/validation_message';
import {UnjudgedPage, UnjudgedPagesProvider} from '../content/validation_errors_page/unjudged_pages_provider';
import {GlobalProvider} from '../../../../business/global_provider';
import {RenderingContextType} from '../../../enum/rendering_context_type';
import {RootGroupQuestionType} from '../../../enum/question_type';

export class NextButtonPresenter implements Presenter {
    @observable public submitting = false;
    @observable public errorWhileSubmitting = false;
    @observable private numUnsyncedPhotos = 0;
    @observable public numUnsavedAnswers = 0;
    @observable.ref private tree: SidebarItem[] = [];
    @observable private currentUrlHash: string = getCurrentWindowHash();
    @observable private networkStatus: NetworkStatus = NetworkStatus.OFFLINE;
    private subscriptions = new CompositeSubscription();

    @observable.ref private validationConclusion: ValidationConclusion | null = null;
    @observable private currentPageHasValidationErrors = false;
    @observable private nextButtonTemporarilyDisabled = false;
    @observable private nextButtonHadFirstClick = false;

    @computed private get hasBlockingValidationErrors() {
        return (
            (this.validationConclusion?.hasValidationErrors || this.validationConclusion?.hasPreValidationErrors) ??
            false
        );
    }

    @observable.ref private unjudgedPages: UnjudgedPage[] = [];
    @computed private get hasWarningValidationErrors() {
        return (this.validationConclusion?.hasValidationMessages || this.unjudgedPages.length > 0) ?? false;
    }

    @computed
    public get isNextButtonDisabled() {
        if (this.isOnConceptReportPage) {
            return (
                //While submitting prevent navigating next
                this.submitting ||
                //If a appraisers skips soft-warnings, they need to press the button twice, between first and second we disabled it temporarily
                this.nextButtonTemporarilyDisabled ||
                //When a single question is rendered it isn't allowed to submit until no more validation messages are there
                (this.global.global.detailName !== null && this.currentPageHasValidationErrors)
            );
        }

        // The plausibility context has no control page, so disable the button on the last page if needed.
        if (
            this.renderingContext === RenderingContextType.PLAUSIBILITY_CHECK &&
            this.isOnLastTreeItem &&
            this.currentPageHasValidationErrors
        ) {
            return true;
        }

        return (
            //While submitting prevent navigating next
            this.submitting ||
            //If a appraisers skips soft-warnings, they need to press the button twice, between first and second we disabled it temporarily
            this.nextButtonTemporarilyDisabled ||
            //When a single question is rendered it isn't allowed to submit until no more validation messages are there
            (this.global.global.detailName !== null && this.currentPageHasValidationErrors) ||
            //One the last page we should solve everything before continuing to model values
            (this.isOnControlPage && this.validationConclusion?.gateAllowsValidation === false) ||
            // When there are blocking validation errors, it is not possible to go to the next step
            (this.isOnControlPage &&
                (this.networkStatus === NetworkStatus.OFFLINE ||
                    this.hasBlockingValidationErrors ||
                    this.numUnsyncedPhotos > 0 ||
                    this.numUnsavedAnswers > 0)) ||
            // When the validation is loading, it is not possible to go to the next step
            this.validationConclusion?.preValidationErrors.state === PreValidationConclusiongState.LOADING
        );
    }

    @computed
    public get submitButtonText() {
        if (this.isOnControlPage) {
            if (this.hasBlockingValidationErrors) {
                return 'Controleer de invoer';
            }
            if (this.hasWarningValidationErrors && !this.nextButtonHadFirstClick) {
                return 'Doorgaan met meldingen';
            }
            return 'Voltooien';
        }
        if (this.isOnDownloadConceptPage) {
            return 'Naar controle';
        }
        if (this.isOnConceptReportPage) {
            return 'Opslaan & naar controle';
        }
        if (this.isOnLastTreeItem && this.renderingContext === RenderingContextType.PLAUSIBILITY_CHECK) {
            return 'Verklaring verzenden';
        }

        return this.global.global.detailName !== null ? 'Verzenden' : 'Volgende';
    }

    @computed
    public get submitButtonType() {
        if (this.isOnControlPage && this.nextButtonHadFirstClick) {
            return 'danger';
        }
        return 'normal';
    }

    @computed
    private get isOnLastTreeItem() {
        const lastTreeItem = this.tree[this.tree.length - 1];
        return (
            lastTreeItem &&
            (this.currentUrlHash === lastTreeItem.link || this.currentUrlHash.startsWith(lastTreeItem.link + '/'))
        );
    }

    @computed
    private get isOnConceptReportPage() {
        return this.currentUrlHash.includes(ValidationErrorsPageTab.CONCEPT_REPORT);
    }

    @computed
    private get isOnControlPage() {
        return this.currentUrlHash.includes(ValidationErrorsPageTab.CONTROL);
    }

    @computed
    private get isOnDownloadConceptPage() {
        return this.currentUrlHash.includes(ValidationErrorsPageTab.DOWNLOAD_CONCEPT);
    }

    constructor(
        private appraisal: Appraisal,
        private history: History,
        private renderingContext: RenderingContextType,
        private appraisalNavigator: AppraisalNavigator,
        private sidebarTreeBuilder: SidebarTreeBuilder,
        private answerInteractor: AnswerInteractor,
        private photoAnswerRetryInteractor: PhotoAnswerRetryInteractor,
        private flashMessageBroadcaster: FlashMessageBroadcaster,
        private answerController: AnswerController,
        private networkStatusProvider: NetworkStatusProvider,
        private childValidator: ChildQuestionValidator,
        private validationConclusionProvider: ValidationConclusionProvider,
        private unsyncedAnswerRetryInteractor: UnsyncedAnswerRetryInteractor,
        private unjudgedPagesProvider: UnjudgedPagesProvider,
        private global: GlobalProvider
    ) {
        makeObservable(this);
        this.navigateNextNode = this.navigateNextNode.bind(this);
    }

    public mount(): void {
        this.appraisalNavigator.addNextListener(this.navigateNextNode);

        this.subscriptions.add(
            this.sidebarTreeBuilder.build.subscribe((tree) => {
                runInAction(() => {
                    this.tree = tree;
                    this.touchValidationErrorsOfCurrentPage();
                });
            })
        );

        this.subscriptions.add(
            hashChanges.subscribe((hash: string) => {
                runInAction(() => {
                    this.touchValidationErrorsOfCurrentPage();
                    this.currentUrlHash = hash;
                });
            })
        );

        this.subscriptions.add(
            this.validationConclusionProvider.stream.subscribe((validationConclusion) => {
                runInAction(() => {
                    this.validationConclusion = validationConclusion;
                });
            })
        );

        this.subscriptions.add(hashChanges.subscribe(() => this.validationConclusionProvider.refreshShallow()));

        this.subscriptions.add(
            this.photoAnswerRetryInteractor.numUnsyncedPhotos.subscribe((numUnsyncedPhotos) => {
                runInAction(() => {
                    this.numUnsyncedPhotos = numUnsyncedPhotos;
                });
            })
        );

        this.subscriptions.add(
            this.unsyncedAnswerRetryInteractor.numUnsyncedAnswers.subscribe((numUnsavedAnswers) => {
                runInAction(() => {
                    this.numUnsavedAnswers = numUnsavedAnswers;
                });
            })
        );

        this.subscriptions.add(
            this.answerController
                .answersStream()
                .pipe(
                    debounceTime(1000),
                    map((answers) => answers.filter((answer) => answer.changed))
                )
                .subscribe(() => this.touchValidationErrorsOfCurrentPage())
        );

        this.subscriptions.add(
            this.networkStatusProvider.status().subscribe((networkStatus) => {
                runInAction(() => {
                    this.networkStatus = networkStatus;
                });
            })
        );

        this.subscriptions.add(
            this.unjudgedPagesProvider.get().subscribe((unjugedPages) => {
                runInAction(() => {
                    this.unjudgedPages = unjugedPages;
                });
            })
        );
    }

    public unmount(): void {
        this.appraisalNavigator.removeNextListener(this.navigateNextNode);
        this.subscriptions.clear();
    }

    public async storeAnswersAndProceed() {
        if (this.submitting || this.isNextButtonDisabled) {
            return;
        }

        if (!this.nextButtonHadFirstClick && this.isOnControlPage && this.hasWarningValidationErrors) {
            runInAction(() => {
                this.nextButtonTemporarilyDisabled = true;
                this.nextButtonHadFirstClick = true;
            });

            setTimeout(() => {
                runInAction(() => {
                    this.nextButtonTemporarilyDisabled = false;
                });
            }, 500);

            return;
        }

        if (
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        ) {
            runInAction(() => {
                this.submitting = false;
            });
            if (this.isOnConceptReportPage || this.isOnDownloadConceptPage) {
                // On the concept report (download) we should go to the first tab
                await this.navigateToControlPage();
            } else {
                await this.appraisalNavigator.navigateNext();
            }
            this.scrollToTop();

            return;
        }

        try {
            runInAction(() => {
                this.submitting = true;
                this.errorWhileSubmitting = false;
            });

            await this.answerInteractor.submit();
            if (this.isOnConceptReportPage || this.isOnDownloadConceptPage) {
                // On the concept report (download) we should go to the first tab
                await this.navigateToControlPage();
            } else {
                await this.appraisalNavigator.navigateNext();
            }
            this.scrollToTop();
        } catch (e) {
            console.error(e);
            this.flashMessageBroadcaster.broadcast(
                'Er is iets fout gegaan tijdens het opslaan, probeer het later opnieuw.',
                Type.Danger
            );
            runInAction(() => {
                this.errorWhileSubmitting = true;
            });
        } finally {
            runInAction(() => {
                this.submitting = false;
            });
        }
    }

    private async navigateNextNode() {
        if (this.global.global.detailName === null) {
            const tree = await this.sidebarTreeBuilder.build.pipe(first()).toPromise();
            if (tree) {
                const flattened = tree
                    .reduce(
                        (p: SidebarItem[], c: SidebarItem) =>
                            c.question?.type === RootGroupQuestionType.ROOT_GROUP
                                ? [...p, c]
                                : [...p, c, ...c.children],
                        []
                    )
                    .map((sidebarItem) => sidebarItem.link);

                const indexOfCurrent = flattened.indexOf(this.history.location.pathname);
                if (indexOfCurrent >= 0) {
                    const next = flattened[indexOfCurrent + 1];
                    if (next !== undefined) {
                        this.history.push(next);
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private async navigateToControlPage() {
        if (this.history.location.pathname.includes('/validation-page/')) {
            const splitted = this.history.location.pathname.split('/');
            splitted.pop();
            this.history.push(splitted.join('/') + '/' + ValidationErrorsPageTab.CONTROL);
        } else {
            this.history.push(`/validation-page/${ValidationErrorsPageTab.CONTROL}`);
        }
    }

    /**
     * Force scrolling to top of screen since after filling in a page you want to start at the top of the next screen
     */
    private scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    private async touchValidationErrorsOfCurrentPage() {
        const current = toJS(this.tree)
            .reduce((p: SidebarItem[], c: SidebarItem) => [...p, c, ...c.children], [])
            .find((sidebarItem) => sidebarItem.link === this.currentUrlHash);

        if (current === undefined || current.question === undefined) {
            return false;
        }

        const answers = this.answerController.answersForQuestionUuid(current.question.uuid);
        const messages = this.childValidator.validate(
            current.question.uuid,
            answers.length > 0 ? answers[0].uuid : null
        );

        runInAction(() => {
            this.currentPageHasValidationErrors = messages.some(
                (message) => message.importance === ValidationMessageImportance.ERROR
            );
        });
    }
}
