import * as React from 'react';

import {Appraisal} from '../../../models/appraisal';
import {OnMountSaverPresenter} from './on_mount_saver_presenter';
import {QuestionSet} from '../../../models/question_set';
import {usePresenter} from '../../../../support/presenter/use_presenter';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export const OnMountSaver: React.FunctionComponent<React.PropsWithChildren<OwnProps>> = (props) => {
    usePresenter(
        (container) =>
            new OnMountSaverPresenter(container.business.answerInteractor(props.appraisal, props.questionSet))
    );

    return <>{props.children}</>;
};
