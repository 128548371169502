import * as React from 'react';

interface OwnProps {
    name: string;
    label: string;
    value: number | null;
    isRequired?: boolean;
    prepend?: string;
    badge?: React.ReactNode;
    disabled?: boolean;
    onChange: (e: string) => void;
}

export class NumberInput extends React.Component<OwnProps> {
    private renderInput() {
        const input = (
            <input
                id={this.props.name}
                name={this.props.name}
                className="form-control"
                type="number"
                step="1"
                pattern="\d*"
                inputMode="numeric"
                disabled={this.props.disabled}
                value={this.props.value || undefined}
                onChange={(e) => this.props.onChange(e.target.value)}
            />
        );

        if (this.props.prepend) {
            return (
                <span className="input-group">
                    <span className="input-group-prepend">
                        <span className="input-group-text">{this.props.prepend}</span>
                    </span>
                    {input}
                </span>
            );
        }
        return input;
    }

    private renderInputWithBadge() {
        return (
            <span className="row">
                <span className="col-8">{this.renderInput()}</span>
                <span className="col-4">
                    <span className="form-badge">{this.props.badge}</span>
                </span>
            </span>
        );
    }

    public render() {
        return (
            <span className="form-group">
                <label htmlFor={this.props.label}>
                    {this.props.label}
                    {this.props.isRequired ? <span className="form-label-highlight">*</span> : null}
                </label>
                {this.props.badge === undefined ? this.renderInput() : this.renderInputWithBadge()}
            </span>
        );
    }
}
