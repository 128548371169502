/**
 * Format something like bijlagen.overigeBijlagen.bestand to "Bijlagen overige bijlagen bestand"
 * @param field
 */
export const formatTviField = (field: string): string => {
    const parts = field
        .replace('>', '.')
        .split('.')
        .map((part) => part.split(/(?=[A-Z])/).join(' '));

    if (parts.length === 1) {
        return parts[0];
    }

    parts.shift();

    return parts.join(' ');
};
