import * as React from 'react';

import {CameraPresenter, CameraProgress, ProgressType} from './camera_presenter';

import {Appraisal} from '../../../models/appraisal';
import {AutoMLVersion} from '../../../business/ml/vision/automl_photo_content_predicter';
import {History} from 'history';
import {ImagesUploadInteractor} from './images_upload_interactor';
import {PhotoDetailsModal} from './photo_details_modal';
import {QuestionSet} from '../../../models/question_set';
import {QuestionType} from '../../../enum/question_type';
import {RenderingContextType} from '../../../enum/rendering_context_type';
import {ValidationError} from '../../../models/validation_error';
import {ValidationMessageMap} from '../../../business/validation/validation_message';
import {classNames} from '../../../../support/classnames';
import {observer} from 'mobx-react';
import {PagePartsSet} from '../../../models/page_parts_set';
import {usePresenter} from '../../../../support/presenter/use_presenter';

interface OwnProps {
    history: History;
    questionSet: QuestionSet;
    appraisal: Appraisal;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    onProgressChange: (progress: CameraProgress) => void;
    hiddenQuestionTypes: QuestionType[];
    renderingContext: RenderingContextType;
    pagePartsSet: PagePartsSet | null;
}

export const Camera: React.FC<OwnProps> = observer(function Camera(props) {
    const presenter = usePresenter(
        (container) =>
            new CameraPresenter(
                props.appraisal,
                props.questionSet,
                props.renderingContext,
                props.pagePartsSet,
                container.business.photoAnswerRetryInteractor(props.appraisal, props.questionSet),
                container.business.networkStatusProvider,
                props.onProgressChange,
                container.business.locationProvider(props.history),
                new ImagesUploadInteractor(
                    container.business.blobCacheInteractor(),
                    container.business.answerInteractor(props.appraisal, props.questionSet),
                    container.business.imageUploadInteractor(props.appraisal, props.questionSet),
                    container.business.photoRecognitionHandler(
                        props.appraisal,
                        props.questionSet,
                        AutoMLVersion.DEFAULT,
                        props.renderingContext
                    ),
                    container.business.flashMessageBroadcaster,
                    container.business.imageOrientationFixer,
                    container.business.answerController(props.appraisal, props.questionSet)
                )
            )
    );

    function renderProgress() {
        const radius = 26;
        const circumference = radius * 2 * Math.PI;
        const offset = circumference - presenter.progress * circumference;
        const style = {
            strokeDasharray: `${circumference} ${circumference}`,
            strokeDashoffset: offset,
        };

        return (
            <svg
                className={classNames('button-circle-loader circle', {
                    critical: presenter.progressType === ProgressType.CRITICAL,
                    recognition: presenter.progressType === ProgressType.RECOGNITION,
                })}
                viewBox="0 0 60 60"
                width="60"
                height="60"
            >
                <circle r="26" cx="30" cy="30" style={{stroke: 'black', opacity: 0.2}} />
                <circle r="26" cx="30" cy="30" style={style} />
            </svg>
        );
    }

    function renderFlash() {
        const radius = 34;
        const circumference = radius * 2 * Math.PI;
        const style = {
            strokeDasharray: `${circumference} ${circumference}`,
            strokeDashoffset: 0,
        };

        return (
            <svg className="button-circle-flash circle" width="60" height="60" viewBox="0 0 60 60" style={style}>
                <circle r="26" cx="30" cy="30" />
            </svg>
        );
    }

    function renderDetailsModal() {
        const detailsModalData = presenter.detailModalData;
        if (!detailsModalData) {
            return null;
        }

        return (
            <PhotoDetailsModal
                detailsModalData={detailsModalData}
                isDisabled={presenter.isDisabled}
                onClose={() => presenter.closeModal()}
                questionSet={props.questionSet}
                appraisal={props.appraisal}
                validationErrors={props.validationErrors}
                validationMessages={props.validationMessages}
                forceShowValidationMessages={props.forceShowValidationMessages}
                hiddenQuestionTypes={props.hiddenQuestionTypes}
                renderingContext={props.renderingContext}
            />
        );
    }

    return (
        <div
            className={classNames('btn-photo', {
                disabled: presenter.isDisabled,
            })}
            data-tooltip="btn-photo"
            data-tooltip-title="Foto maken"
            data-tooltip-description="Met deze knop kun je foto's toevoegen als je aan het taxeren bent. Ze worden automatisch geupload in de app. De foto's kan je ook achteraf toevoegen."
            data-tooltip-position="left-bottom"
        >
            <label onClick={() => presenter.onLabelClick()}>
                {presenter.isAvailable && !presenter.isDisabled && (
                    <input
                        //Since this an uncontrolled input it can happen that selecting two similar files one after the
                        //other the onChange handler isn't called, by having busy as a key this alternates between 0 and 1
                        //between uploads
                        key={'' + presenter.numUploadsProcessed}
                        type="file"
                        accept="image/*"
                        multiple={true}
                        onChange={(e) => presenter.onFilesChange(e)}
                        style={{display: 'none'}}
                        disabled={!presenter.isAvailable}
                        capture={presenter.shouldUseCapture}
                    />
                )}
                <svg
                    fill="currentColor"
                    preserveAspectRatio="xMidYMid meet"
                    height="1em"
                    width="1em"
                    viewBox="0 0 40 40"
                    style={{verticalAlign: 'middle'}}
                >
                    <g>
                        <path d="m20 28.4c4.6 0 8.4-3.8 8.4-8.4s-3.8-8.4-8.4-8.4-8.4 3.8-8.4 8.4 3.8 8.4 8.4 8.4z m-5-25h10l3 3.2h5.4c1.8 0 3.2 1.6 3.2 3.4v20c0 1.8-1.4 3.4-3.2 3.4h-26.8c-1.8 0-3.2-1.6-3.2-3.4v-20c0-1.8 1.4-3.4 3.2-3.4h5.4z m-0.3 16.6c0-3 2.3-5.3 5.3-5.3s5.3 2.3 5.3 5.3-2.3 5.3-5.3 5.3-5.3-2.3-5.3-5.3z"></path>
                    </g>
                </svg>
                {presenter.progressType !== ProgressType.IDLE && presenter.progressType !== ProgressType.FAILURE
                    ? renderProgress()
                    : null}
                {presenter.progressType === ProgressType.IDLE || presenter.progressType === ProgressType.FAILURE
                    ? renderFlash()
                    : null}
            </label>
            {renderDetailsModal()}
        </div>
    );
});
