import * as React from 'react';

import {Adres} from '../../../../../../../../../../../models/validation_institute_reference_object';
import {Appraisal} from '../../../../../../../../../../../models/appraisal';
import {EditableAddress} from '../../../inputs/editable_address';
import {FloorAreaPrice} from '../../../floor_area_price';
import {FunctionComponent} from 'react';
import {ImageUrlPair} from '../../../../../../../../../../../models/image_url_pair';
import {QuestionSet} from '../../../../../../../../../../../models/question_set';
import {ReferenceObject} from '../../../../models/reference_object';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';
import {ReferenceObjectAnswerImageUpload} from '../../../inputs/reference_object_answer_image_upload';
import {VolumePrice} from '../../../volume_price';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    isFrozen: boolean;
    imageUrlPairs: ImageUrlPair[];
    referenceObject: ReferenceObject;
    referenceObjectAnswer: ReferenceObjectAnswer;
    valuation: number | null;
    gebruiksOppervlakteWonen: number | null;
    brutoInhoud: number | null;
    onPhotoChange: (photoPath: string, photoUrl: string, isOwnUpload?: boolean) => void;
    onAdresChange: (value: Adres) => void;
}

export const ObjectDetails: FunctionComponent<OwnProps> = ({
    imageUrlPairs,
    appraisal,
    referenceObject,
    referenceObjectAnswer,
    onPhotoChange,
    isFrozen,
    questionSet,
    onAdresChange,
    valuation,
    gebruiksOppervlakteWonen,
    brutoInhoud,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-sm-12">
                <ReferenceObjectAnswerImageUpload
                    key={JSON.stringify(imageUrlPairs)}
                    appraisal={appraisal}
                    imageUrlPairs={imageUrlPairs}
                    referenceObjectAnswerPhotoUrl={referenceObjectAnswer.photoUrl}
                    isOwnUpload={referenceObjectAnswer.isOwnUpload}
                    onChange={onPhotoChange}
                    disabled={isFrozen}
                    questionSet={questionSet}
                    referenceObject={referenceObject}
                />
            </div>
            {referenceObject.hasWarning && (
                <div className="col-12">
                    <span className="spacer-sm"></span>
                    <span className="form-grid-item-alert">
                        <span className="ion-md-alert">&nbsp;</span>
                        {referenceObject.warning}
                    </span>
                </div>
            )}

            <div className="col-12">
                <h2>Object details</h2>
            </div>
            <div className="col-sm-6">
                <EditableAddress address={validationInstituteReferentieObject.adres} onChange={onAdresChange} />
            </div>

            <div className="col-sm-6">
                <dl>
                    <dt>Prijs per m²</dt>
                    <dd>
                        <FloorAreaPrice
                            referenceSetPrice={valuation}
                            referenceSetFloorArea={gebruiksOppervlakteWonen}
                            referenceObjectFloorArea={validationInstituteReferentieObject.gebruiksOppervlakte}
                            referenceObjectPrice={
                                validationInstituteReferentieObject.gecorrigeerdeVerkoopprijs?.toString(10) ??
                                validationInstituteReferentieObject.verkoopprijs?.toString(10) ??
                                null
                            }
                            appraisal={appraisal}
                            questionSet={questionSet}
                        />
                    </dd>
                </dl>
                <dl>
                    <dt>Prijs per m³</dt>
                    <dd>
                        <VolumePrice
                            valuation={valuation}
                            brutoInhoud={brutoInhoud}
                            volume={validationInstituteReferentieObject.inhoud}
                            price={
                                validationInstituteReferentieObject.gecorrigeerdeVerkoopprijs?.toString(10) ??
                                validationInstituteReferentieObject.verkoopprijs?.toString(10) ??
                                null
                            }
                            appraisal={appraisal}
                            questionSet={questionSet}
                        />
                    </dd>
                </dl>
            </div>
        </div>
    );
};
