import {AnswerOption} from '../../../models/answer_option';
import {AutoMLVersion} from './automl_photo_content_predicter';
import {PhotoContentHandler} from './automl_photo_handler';

export enum HowSureAreWeLevel {
    VerySure = 0,
    NotSoSure = 1,
    Unsure = 2,
}

interface UnsurePredictionResult {
    className: string | null;
    description: string | null;
    howSure: HowSureAreWeLevel.Unsure;
    answerOptionId: null;
}

interface MaybePredictionResult {
    className: string | null;
    description: string | null;
    howSure: HowSureAreWeLevel.NotSoSure | HowSureAreWeLevel.VerySure;
    answerOptionId: number;
}

export type PredictionResult = UnsurePredictionResult | MaybePredictionResult;

export interface Prediction {
    className: string;
    probability: number;
}

export interface PhotoContentPredicter {
    getPrediction(file: File): Promise<Prediction | null>;
    getVersionPrediction(version: AutoMLVersion, uploadedFile: File): Promise<Prediction | null>;
    getVersionPredictions(version: AutoMLVersion, uploadedFile: File): Promise<Prediction[] | null>;
    prepare(): Promise<void>;
}

export class DefaultPhotoContentPredicter {
    constructor(private photoContentPredicter: PhotoContentHandler) {}

    public async predict(
        file: File | string,
        answerOptions: AnswerOption[],
        verySureLevel: number,
        notToSureLevel: number
    ): Promise<PredictionResult> {
        const prediction = await this.photoContentPredicter.getRecognition(file);

        if (prediction === null || prediction.probability < notToSureLevel) {
            return {
                className: null,
                description: null,
                howSure: HowSureAreWeLevel.Unsure,
                answerOptionId: null,
            };
        }

        const answerOption = answerOptions.find((aO) => {
            return prediction.label !== null && this.isAnswerOptionMatch(aO, prediction.label);
        });
        if (answerOption === undefined) {
            //The model returned a prediction we can't match to an answer
            return {
                className: null,
                description: null,
                howSure: HowSureAreWeLevel.Unsure,
                answerOptionId: null,
            };
        }

        const classNames = prediction.label ? [prediction.label.toString()] : [];

        if (prediction && file instanceof File) {
            const details = await this.photoContentPredicter.getDetailsFromPhoto(prediction, file);
            for (const detail of details) {
                classNames.push(detail.className);
            }
        }

        return prediction.probability > verySureLevel
            ? {
                  className: classNames.join(','),
                  description: answerOption.contents.trim(),
                  howSure: HowSureAreWeLevel.VerySure,
                  answerOptionId: answerOption.id,
              }
            : {
                  className: classNames.join(','),
                  description: answerOption.contents.trim(),
                  howSure: HowSureAreWeLevel.NotSoSure,
                  answerOptionId: answerOption.id,
              };
    }

    private isAnswerOptionMatch(answerOption: AnswerOption, className: string) {
        const classNameFormatted = className.toLocaleLowerCase().trim();
        if (answerOption.labels) {
            const isMatch = answerOption.labels.toLocaleLowerCase().trim().includes(classNameFormatted);
            if (isMatch) {
                return isMatch;
            }
        }
        return answerOption.contents.toLocaleLowerCase().trim() === classNameFormatted;
    }

    public prepare() {
        this.photoContentPredicter.prepare();
    }
}
