import {computed, makeObservable, observable, runInAction} from 'mobx';
import {AppraisalProvider} from '../../appraising/business/appraisal_provider';
import {Appraisal} from '../../appraising/models/appraisal';
import {ApiAppraisal, apiAppraisalToAppraisal} from '../../appraising/network/models/api_appraisal';
import {CompositeSubscription} from '../../support/composite_subscription';
import {Presenter} from '../../support/presenter/presenter';

//This GLOBAL is different then the global used anywhere else in the app
declare let GLOBAL: {
    appraisal: ApiAppraisal;
};

export class RootPresenter implements Presenter {
    @observable.ref public appraisal?: Appraisal;

    private subscriptions = new CompositeSubscription();

    @computed
    public get loading(): boolean {
        return !this.appraisal;
    }

    constructor(private appraisalProvider: AppraisalProvider) {
        makeObservable(this);
    }

    public async mount(): Promise<void> {
        this.subscriptions.add(
            this.appraisalProvider.stream.subscribe((appraisal) => {
                runInAction(() => {
                    this.appraisal = appraisal;
                });
            })
        );

        if (!this.appraisalProvider.hasAppraisal) {
            this.appraisalProvider.onChange(apiAppraisalToAppraisal(GLOBAL.appraisal));
        }
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
