import * as React from 'react';

import {FloorAreaPriceBadge} from '../../../../../../../../components/badges/floor_area_price_badge';
import {isEmpty} from '../../../../../../../../../support/util';
import {observer} from 'mobx-react';
import {getPriceOrPriceRangeFloorArea, PriceRangeType} from '../internal/get_object_price';
import {SimpleTooltip} from '../../../../../../../../components/tooltip';

interface OwnProps {
    referenceObjectFloorArea: number | null;
    referenceObjectIndexedPrice: string | number | null;
    referenceObjectPriceRange: string | number | null;
    referenceSetFloorArea: number | null;
    referenceSetPrice: number | null;
    compact?: boolean;
    spaceBetween?: boolean;
    referenceObjectId: string | null;
}

@observer
export class FloorAreaPrice extends React.Component<OwnProps> {
    private formatPrice(priceOrPriceRange: number | PriceRangeType): string {
        if (Array.isArray(priceOrPriceRange)) {
            return `€ ${priceOrPriceRange[0].toLocaleString('nl-NL')} - ${priceOrPriceRange[1].toLocaleString(
                'nl-NL'
            )} / m²`;
        }
        return `€ ${priceOrPriceRange.toLocaleString('nl-NL')}/m²`;
    }

    public render() {
        let priceOrPriceRange: string | number | null = null;

        if (
            this.props.referenceObjectIndexedPrice !== null &&
            !isEmpty(String(this.props.referenceObjectIndexedPrice).trim())
        ) {
            priceOrPriceRange = this.props.referenceObjectIndexedPrice;
        } else if (
            this.props.referenceObjectPriceRange !== null &&
            !isEmpty(String(this.props.referenceObjectPriceRange).trim())
        ) {
            priceOrPriceRange = this.props.referenceObjectPriceRange;
        }

        if (
            priceOrPriceRange === null ||
            this.props.referenceObjectFloorArea === null ||
            this.props.referenceObjectFloorArea === 0
        ) {
            return <>{'-'}</>;
        }

        let referenceSetPrice = !isEmpty(this.props.referenceSetPrice) ? Number(this.props.referenceSetPrice) : null;
        if (Number.isNaN(referenceSetPrice)) referenceSetPrice = null;

        let referenceSetFloorArea = !isEmpty(this.props.referenceSetFloorArea)
            ? Number(this.props.referenceSetFloorArea)
            : null;
        if (Number.isNaN(referenceSetFloorArea)) referenceSetFloorArea = null;

        const referenceSetPricePerM2 =
            referenceSetPrice !== null && referenceSetFloorArea !== null && referenceSetFloorArea !== 0
                ? Math.round(referenceSetPrice / referenceSetFloorArea)
                : null;

        const priceOrPriceRangePerM2 = getPriceOrPriceRangeFloorArea(
            priceOrPriceRange,
            this.props.referenceObjectFloorArea
        );
        if (this.props.referenceObjectId === null) {
            if (this.props.spaceBetween) {
                return (
                    <span className="row">
                        <span className="col-8">{this.formatPrice(priceOrPriceRangePerM2)}</span>
                        <span className="col-4">
                            <span className="form-badge">
                                {referenceSetPricePerM2 !== null ? (
                                    <FloorAreaPriceBadge
                                        floorAreaPrice={priceOrPriceRangePerM2}
                                        referenceSetFloorAreaPrice={referenceSetPricePerM2}
                                        compact={this.props.compact}
                                    />
                                ) : null}
                            </span>
                        </span>
                    </span>
                );
            }

            return (
                <>
                    <span>{this.formatPrice(priceOrPriceRangePerM2)}</span>
                    {referenceSetPricePerM2 !== null ? (
                        <FloorAreaPriceBadge
                            floorAreaPrice={priceOrPriceRangePerM2}
                            referenceSetFloorAreaPrice={referenceSetPricePerM2}
                            compact={this.props.compact}
                        />
                    ) : null}
                </>
            );
        } else {
            if (this.props.spaceBetween) {
                return (
                    <span className="row">
                        <SimpleTooltip content="Dit betreft het referentie object." placement="top">
                            <span data-type="info" className="col-8">
                                {this.formatPrice(priceOrPriceRangePerM2)}
                            </span>
                        </SimpleTooltip>
                        <span className="col-4">
                            <span className="form-badge">
                                {referenceSetPricePerM2 !== null
                                    ? this.renderFloorAreaBadge(priceOrPriceRangePerM2, referenceSetPricePerM2)
                                    : null}
                            </span>
                        </span>
                    </span>
                );
            }

            return (
                <>
                    <SimpleTooltip content="Dit betreft het referentie object." placement="top">
                        <span data-type="info">{this.formatPrice(priceOrPriceRangePerM2)}</span>
                    </SimpleTooltip>
                    {referenceSetPricePerM2 !== null
                        ? this.renderFloorAreaBadge(priceOrPriceRangePerM2, referenceSetPricePerM2)
                        : null}
                </>
            );
        }
    }

    private renderFloorAreaBadge(priceOrPriceRangePerM2: number | PriceRangeType, referenceSetPricePerM2: number) {
        return (
            <>
                {' '}
                <SimpleTooltip
                    content="Dit betreft het verschil van het getaxeerde object tov het referentie object."
                    placement="top"
                >
                    <span data-type="info">
                        <FloorAreaPriceBadge
                            floorAreaPrice={priceOrPriceRangePerM2}
                            referenceSetFloorAreaPrice={referenceSetPricePerM2}
                            compact={this.props.compact}
                        />
                    </span>
                </SimpleTooltip>
            </>
        );
    }
}
