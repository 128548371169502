import {LocalStorage} from './local_storage';

export class WindowLocalStorage implements LocalStorage {
    public getItem<T>(key: string): T | undefined {
        const item = window.localStorage.getItem(this.prepareStorageKey(key));
        if (item) {
            return JSON.parse(item);
        }
        return undefined;
    }

    public setItem<T>(key: string, item: T): void {
        window.localStorage.setItem(this.prepareStorageKey(key), JSON.stringify(item));
    }

    private prepareStorageKey(key: string) {
        return window.location.hostname + '-' + key;
    }
}
