import {ValidationMessage, ValidationMessageMap} from '../appraising/business/validation/validation_message';

export function createValidationMessagesMap(
    validationMessages: ValidationMessage[],
    validationMap?: ValidationMessageMap
): ValidationMessageMap {
    const map: ValidationMessageMap = validationMap ? {...validationMap} : {};
    for (const validationMessage of validationMessages) {
        if (validationMessage.question) {
            if (
                map[validationMessage.question.uuid] &&
                map[validationMessage.question.uuid]?.includes(validationMessage) === false
            ) {
                map[validationMessage.question.uuid]?.push(validationMessage);
            } else {
                map[validationMessage.question.uuid] = [validationMessage];
            }
        }
    }
    return map;
}
