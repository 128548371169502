import * as React from 'react';

import {Appraisal} from '../../../../../../../../../models/appraisal';
import {DefaultExplanationDataProvider} from './explanation_generators/explanation_data_provider';
import {ExplanationAppraiserGenerator} from './explanation_generators/explanation_appraiser_generator';
import {ExplanationAppraiserPresenter} from './explanation_appraiser_presenter';
import {ExplanationBuildYearGenerator} from './explanation_generators/explanation_build_year_generator';
import {ExplanationBuildingTypeGenerator} from './explanation_generators/explanation_building_type_generator';
import {ExplanationBuildingsGenerator} from './explanation_generators/explanation_buildings_generator';
import {ExplanationFloorAreaGenerator} from './explanation_generators/explanation_floor_area_generator';
import {ExplanationPlotAreaGenerator} from './explanation_generators/explanation_plot_area_generator';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {Textarea} from '../inputs/textarea';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';

interface OwnProps {
    questionSet: QuestionSet;
    appraisal: Appraisal;
    gebruiksoppervlakteWonen: number | null;
    perceelOppervlakte: number | null;
    brutoInhoud: number | null;
    referenceObjectAnswer: ReferenceObjectAnswer;
    value: string;
    onChange: (value: string) => void;
    disabled: boolean;
    macros?: string[];
}

export const ExplanationAppraiser: React.FC<OwnProps> = observer(function ExplanationAppraiser(props) {
    const presenter = usePresenter(
        (container) =>
            new ExplanationAppraiserPresenter(
                props.onChange,
                new ExplanationAppraiserGenerator(
                    new DefaultExplanationDataProvider(
                        props.appraisal,
                        props.gebruiksoppervlakteWonen,
                        props.perceelOppervlakte,
                        props.brutoInhoud,
                        container.business.buildYearProvider(props.appraisal, props.questionSet),
                        container.business.valuationProvider(props.appraisal, props.questionSet)
                    ),
                    new ExplanationBuildYearGenerator(),
                    new ExplanationFloorAreaGenerator(),
                    new ExplanationPlotAreaGenerator(),
                    new ExplanationBuildingsGenerator(),
                    new ExplanationBuildingTypeGenerator()
                )
            )
    );

    return (
        <Textarea
            isRequired
            className="form-control-large"
            label="Toelichting taxateur"
            name="input-toelichting-taxateur"
            value={props.value}
            onChange={(value) => props.onChange(value)}
            disabled={props.disabled}
            macros={props.macros}
            maxLength={1000}
        >
            <button
                className="btn btn-textarea-inset ion-md-refresh"
                onClick={() => {
                    if (
                        props.value.trim() === '' ||
                        confirm('Weet je dit zeker? De huidige tekst zal overschreven worden.')
                    ) {
                        presenter.onGenerateClick(props.referenceObjectAnswer);
                    }
                }}
            >
                Genereer
            </button>
        </Textarea>
    );
});
