import {AjaxDriver} from '../../network/driver/ajax_driver';
import {FetchDriver} from '../../network/driver/fetch_driver';
import {ControllerAppraiserApi} from '../network/controller_appraiser_api';

export interface Component {
    controllerAppraiserApi: ControllerAppraiserApi;
}

class ComponentImpl implements Component {
    private ajaxDriver: AjaxDriver = new FetchDriver();
    private _controllerAppraiserApi: ControllerAppraiserApi | undefined;

    public get controllerAppraiserApi(): ControllerAppraiserApi {
        return (this._controllerAppraiserApi ??= new ControllerAppraiserApi(this.ajaxDriver));
    }
}

let instance: Component;

export function component(): Component {
    if (instance === undefined) {
        instance = new ComponentImpl();
    }

    return instance;
}
