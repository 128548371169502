import * as React from 'react';

import {NumberInput} from '../../../../components/number';
import {isEmpty} from '../../../../../../../../../support/util';

interface OwnProps {
    name: string;
    label: string;
    value: number | null;
    isRequired?: boolean;
    prepend?: string;
    badge?: JSX.Element | null;
    disabled?: boolean;
    onChange: (value: number | null) => void;
}

export class NullableNumberInput extends React.Component<OwnProps> {
    public render() {
        return (
            <NumberInput
                name={this.props.name}
                label={this.props.label}
                value={this.props.value}
                isRequired={this.props.isRequired}
                prepend={this.props.prepend}
                badge={this.props.badge}
                disabled={this.props.disabled}
                onChange={(value) => {
                    this.props.onChange(isEmpty(value) ? null : parseInt(value, 10));
                }}
            />
        );
    }
}
