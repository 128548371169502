import {AdaptedValueBuilder} from './adapted_value_builder';
import {Appraisal} from '../../../models/appraisal';

export class ObjectBuildingsAppraiserExplanationAdaptedValueBuilder implements AdaptedValueBuilder {
    constructor(private appraisal: Appraisal) {}

    public buildSentence(): string {
        if (this.appraisal.valuationDate !== null) {
            return 'Zoals waargenomen tijdens de opname op ' + this.appraisal.valuationDate;
        }

        return '';
    }
}
