import * as React from 'react';
import {createPortal} from 'react-dom';

import {ModalConfig, ModalType} from '../../../../models/modal_config';

import {AnswerTrailModal} from './modals/answer_trail_modal';
import {Appraisal} from '../../../../models/appraisal';
import {ConfigWithCallbacks} from '../../../../business/generic_config_stack_interactor';
import {ConstructionDefectsWidgetContext} from '../../appraise_secondary/construction_defects/construction_defects_widget_context';
import {ConstructionalDefectsModal} from '../../appraise_secondary/construction_defects/constructional_defects_modal';
import {LabelSelectModal} from './modals/label_select';
import {ModalsStackPresenter} from './modals_stack_presenter';
import {QuestionSet} from '../../../../models/question_set';
import {QuestionType} from '../../../../enum/question_type';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {ValidationError} from '../../../../models/validation_error';
import {WidgetsGroupQuestionModal} from '../../appraise_secondary/widgets_group_question/widgets_group_question_modal';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {MacroSettingsModal} from '../../appraise_secondary/macro_settings/macro_settings_modal';
import {EnergeticSheetModal} from '../../appraise_secondary/energetic_sheet/energetic_sheet_modal';
import {QuestionRenderingData} from '../../../../models/question_rendering_data';
import {EditableImageModal} from '../questions/advanced/photo_question/components/editable_image_modal';
import {EnergeticSheetExplanationModal} from '../../appraise_secondary/energetic_sheet/energetic_sheet_explanation_modal';
import {ReferencesPreferencesModal} from '../../appraise_secondary/reference_preferences_modal/references_preferences_modal';
import {ReferenceImagesLabelsModal} from '../../appraise_secondary/reference_images/reference_images_labels_modal';
import {ReferenceImagesSetsModal} from '../../appraise_secondary/reference_images/reference_images_sets_modal';
import {ALLOWED_IMAGE_TYPES} from '../questions/question_container';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    hiddenQuestionTypes: QuestionType[];
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const ModalsStack: React.FC<OwnProps> = observer(function ModalsStack(props) {
    const presenter = usePresenter(
        (container) => new ModalsStackPresenter(container.business.modalConfigStackInteractor)
    );

    function renderModal(modalConfig: ConfigWithCallbacks<ModalConfig, unknown>): JSX.Element {
        switch (modalConfig.type) {
            case ModalType.LABEL_SELECT:
                return (
                    <LabelSelectModal
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        validationErrors={props.validationErrors}
                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                        predictionResult={modalConfig.predictionResult}
                        onSucceed={modalConfig.onSucceed}
                        photoRecognized={modalConfig.photoRecognized}
                    />
                );
            case ModalType.WIDGET_GROUP:
                return (
                    <WidgetsGroupQuestionModal
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        onClose={modalConfig.onClose}
                        question={modalConfig.question}
                        iteration={modalConfig.iteration}
                        parentAnswerUuid={modalConfig.parentAnswerUuid}
                        pagePartsSet={modalConfig.pagePartsSet}
                        activePagePart={modalConfig.activePagePart}
                        validationErrors={modalConfig.validationErrors}
                        validationMessages={modalConfig.validationMessages}
                        forceShowValidationMessages={modalConfig.forceShowValidationMessages}
                        adaptedDefaultValues={modalConfig.adaptedDefaultValues}
                        hasOnlyReadonlyQuestions={modalConfig.hasOnlyReadonlyQuestions}
                        renderingContext={props.renderingContext}
                        questionRenderingData={props.questionRenderingData}
                    />
                );
            case ModalType.DEFECTS:
                return (
                    <ConstructionalDefectsModal
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        onClose={modalConfig.onClose}
                        question={modalConfig.question}
                        parentAnswerUuid={modalConfig.parentAnswerUuid}
                        validationInstituteConstructionalDefect={modalConfig.validationInstituteConstructionalDefect}
                        constructionDefectLabel={modalConfig.constructionDefectLabel}
                        validationErrors={modalConfig.validationErrors}
                        validationMessages={modalConfig.validationMessages}
                        forceShowValidationMessages={modalConfig.forceShowValidationMessages}
                        constructionDefectPhotoAnswerUuid={modalConfig.constructionDefectPhotoAnswerUuid}
                        constructionCostsWidgetContext={modalConfig.constructionCostsWidgetContext}
                        onChangeConstructionCostsWidgetContext={(c: ConstructionDefectsWidgetContext) =>
                            modalConfig.onChangeConstructionCostsWidgetContext(c)
                        }
                        pagePartsSet={modalConfig.pagePartsSet}
                        activePagePart={modalConfig.activePagePart}
                        renderingContext={props.renderingContext}
                        questionRenderingData={props.questionRenderingData}
                    />
                );
            case ModalType.ANSWER_TRAIL:
                return (
                    <AnswerTrailModal
                        auditTrailItems={modalConfig.auditTrailItems}
                        isLoading={modalConfig.isLoading}
                        isDisabled={modalConfig.isDisabled}
                        questionType={modalConfig.questionType}
                        onClose={modalConfig.onClose}
                        onHistoricAnswerClick={modalConfig.onHistoricAnswerClick}
                        getAnswerContents={modalConfig.getAnswerContents}
                    />
                );

            case ModalType.MACRO_SETTINGS:
                return (
                    <MacroSettingsModal
                        payload={modalConfig.payload}
                        questionSet={modalConfig.questionSet}
                        appraisal={modalConfig.appraisal}
                        onClose={modalConfig.onClose}
                    />
                );
            case ModalType.ENERGETIC_SHEET:
                return (
                    <EnergeticSheetModal
                        appraisal={props.appraisal}
                        renderingContext={props.renderingContext}
                        questionSet={props.questionSet}
                        pagePartsSet={modalConfig.pagePartsSet}
                        answerUuid={modalConfig.answerUuid}
                        onClose={modalConfig.onClose}
                        onOpenExplanation={modalConfig.onOpenExplanation}
                    />
                );
            case ModalType.ENERGETIC_SHEET_EXPLANATION:
                return <EnergeticSheetExplanationModal onClose={() => modalConfig.onSucceed(null)} />;
            case ModalType.REFERENCES_PREFERENCES:
                return <ReferencesPreferencesModal onClose={() => modalConfig.onSucceed(null)} />;
            case ModalType.REFERENCE_IMAGES_LABELS:
                return (
                    <ReferenceImagesLabelsModal
                        onClose={(selected) => modalConfig.onSucceed(selected)}
                        options={modalConfig.options}
                        selected={modalConfig.selected}
                    />
                );
            case ModalType.REFERENCE_IMAGES_SETS:
                return (
                    <ReferenceImagesSetsModal
                        onClose={(selected) => modalConfig.onSucceed(selected)}
                        sets={modalConfig.sets}
                        activeIndex={modalConfig.activeIndex}
                    />
                );
            case ModalType.EDITABLE_IMAGE:
                return (
                    <EditableImageModal
                        answer={modalConfig.answer}
                        fileTypes={ALLOWED_IMAGE_TYPES}
                        isEditable={modalConfig.isEditable}
                        onClose={() => modalConfig.onSucceed(null)}
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                    />
                );
        }
    }

    const stack = presenter.stack;
    if (!stack || stack.length === 0) {
        return null;
    }

    const modalsContainer = document.getElementById('modals-container');
    if (!modalsContainer) {
        console.warn('modals-container not found');
        return null;
    }

    return createPortal(
        <>
            {stack.map((config) => {
                return <React.Fragment key={config.id ?? config.type}>{renderModal(config)}</React.Fragment>;
            })}
        </>,
        modalsContainer
    );
});
