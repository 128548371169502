export function isImageFile(file: File, fileTypes: string[]) {
    return (
        (fileTypes.some((fileType) => (file.name ?? '').toLowerCase().endsWith(fileType)) ||
            (file.type ?? '').toLowerCase().includes('image/')) &&
        (file.type ?? '') !== 'image/heic' &&
        !(file.name ?? '').toLowerCase().endsWith('.heic')
    );
}
export function isCorrectFileType(file: File, fileTypes: string[]) {
    return (
        fileTypes.some((fileType) => (file.name ?? '').toLowerCase().endsWith(fileType)) ||
        fileTypes.includes((file.type ?? '').toLowerCase())
    );
}
