import {Answer} from '../appraising/models/answer';
import {SortOrder} from './sort_by_predicate';
import {sortByUpdatedAt} from './sort_answer';

export function getNewestAnswer(answers: Answer[]): Answer | null {
    if (answers.length === 0) {
        return null;
    }
    return answers.sort((a, b) => sortByUpdatedAt(a, b, SortOrder.DESC))[0] ?? null;
}
