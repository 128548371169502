import {ValidationMessage} from './validation_message';

export interface GenericErrorsValidator {
    validate(): ValidationMessage[];
}

export class DefaultGenericErrorsValidator implements GenericErrorsValidator {
    constructor(private validators: GenericErrorsValidator[]) {}

    public validate(): ValidationMessage[] {
        return this.validators.reduce<ValidationMessage[]>((p, c) => {
            return [...p, ...c.validate()];
        }, []);
    }
}
