import * as React from 'react';

import {Appraisal} from '../../../../../../../../../models/appraisal';
import {Modal} from '../../../../../components/modal';
import {PagePart} from '../../../../../../../../../models/page_part';
import {PagePartsSet} from '../../../../../../../../../models/page_parts_set';
import {Question} from '../../../../../../../../../models/question';
import {QuestionContainer} from '../../../../../question_container';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {QuestionType} from '../../../../../../../../../enum/question_type';
import {ReferenceObjectAnswerModalPresenter} from './reference_object_answer_modal_presenter';
import {ReferenceObjectAnswerModalSidebar} from './reference_object_answer_modal_sidebar';
import {RenderingContextType} from '../../../../../../../../../enum/rendering_context_type';
import {ValidationError} from '../../../../../../../../../models/validation_error';
import {createValidationMessagesMap} from '../../../../../../../../../../support/create_validation_messages_map';
import {observer} from 'mobx-react';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {isMobile} from '../../../../../../../../../../support/check_mobile';
import {SetDefinition} from '../../reference_objects_question_presenter';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';
import {QuestionRenderingData} from '../../../../../../../../../models/question_rendering_data';

interface OwnProps {
    onHide: () => void;
    showComparisonModal: (setDefinition: SetDefinition) => void;
    visible: boolean;
    isFrozen: boolean;

    //QuestionContainer props
    appraisal: Appraisal;
    question: Question;
    questionSet: QuestionSet;
    parentAnswerUuid: string;
    validationErrors: ValidationError[];
    forceShowValidationMessages: boolean;
    hiddenQuestionTypes: QuestionType[];

    //Sidebar props
    gebruiksoppervlakteWonen: number | null;
    gebruiksOppervlakteBuitenruimte: number | null;
    overigeInpandigeRuimte: number | null;
    gebruiksOppervlakteExterneBergruimte: number | null;
    buildYear: number | null;
    perceelOppervlakte: number | null;

    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;

    referenceObjectAnswer: ReferenceObjectAnswer;
    setDefinition: SetDefinition;

    children?: React.ReactNode;
}

export const ReferenceObjectAnswerModal: React.FC<OwnProps> = observer(function ReferenceObjectAnswerModal(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectAnswerModalPresenter(
                props.question,
                props.parentAnswerUuid,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.childQuestionValidationProvider(
                    props.appraisal,
                    props.questionSet,
                    props.validationErrors
                )
            )
    );

    return (
        <Modal
            forced={true}
            onHide={() => {
                if (presenter.validationMessages.length === 0) {
                    props.onHide();
                }
            }}
            visible={props.visible}
            sidebar={
                <ReferenceObjectAnswerModalSidebar
                    appraisal={props.appraisal}
                    surfaceArea={props.gebruiksoppervlakteWonen}
                    gebruiksOppervlakteBuitenruimte={props.gebruiksOppervlakteBuitenruimte}
                    overigeInpandigeRuimte={props.overigeInpandigeRuimte}
                    gebruiksOppervlakteExterneBergruimte={props.gebruiksOppervlakteExterneBergruimte}
                    buildYear={props.buildYear}
                    plotArea={props.perceelOppervlakte}
                />
            }
        >
            <div className="modal-header">
                <h2>Referentieobject</h2>
                {!isMobile() && (
                    <button
                        className="btn btn-xs ion-md-apps ml-3"
                        onClick={() => props.showComparisonModal(props.setDefinition)}
                    />
                )}
            </div>
            <div className="modal-body">
                <QuestionContainer
                    appraisal={props.appraisal}
                    question={props.question}
                    questionSet={props.questionSet}
                    parentAnswerUuid={props.parentAnswerUuid}
                    validationErrors={props.validationErrors}
                    forceShowValidationMessages={
                        presenter.forceShowValidationMessages || props.forceShowValidationMessages
                    }
                    validationMessages={createValidationMessagesMap(presenter.validationMessages)}
                    hiddenQuestionTypes={props.hiddenQuestionTypes}
                    disabled={props.isFrozen}
                    pagePartsSet={props.pagePartsSet}
                    activePagePart={props.activePagePart}
                    renderingContext={props.renderingContext}
                    questionRenderingData={props.questionRenderingData}
                />

                {presenter.forceShowValidationMessages === true && presenter.validationMessages.length > 0 && (
                    <div className="alert-inline alert-hint ion-md-alert">
                        <strong>Er zijn nog meldingen bij onderstaande vragen:</strong>
                        <p className="pre-line">
                            {presenter.validationMessages
                                .map((vm) => vm.fallbackMessage)
                                .filter((message): message is string => message !== null)
                                .join('\n')}
                        </p>
                    </div>
                )}
            </div>

            <div className="modal-footer">
                {props.children}

                {presenter.validationMessages.length > 0 && presenter.forceShowValidationMessages === false ? (
                    <button
                        className="btn btn-primary btn-icon-right ion-md-alert"
                        type="button"
                        onClick={() => presenter.onForceShowValidationMessages()}
                    >
                        Meldingen weergeven
                    </button>
                ) : (
                    <button
                        className="btn btn-primary btn-icon-right ion-md-checkmark"
                        type="button"
                        disabled={presenter.validationMessages.length > 0}
                        onClick={() => props.onHide()}
                    >
                        Opslaan & sluiten
                    </button>
                )}
            </div>
        </Modal>
    );
});
