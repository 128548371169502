$(document).ready(function () {
    if ($('.checkbox-a').is(':checked')) {
        $('.checkbox-a').siblings('label').html('Uitvinken');
        $('.option-a').addClass('highlight');
    }
    if ($('.checkbox-b').is(':checked')) {
        $('.checkbox-b').siblings('label').html('Uitvinken');
        $('.option-b').addClass('highlight');
    }
    if ($('.checkbox-c').is(':checked')) {
        $('.checkbox-c').siblings('label').html('Uitvinken');
        $('.option-c').addClass('highlight');
    }

    $('.checkbox-a').change(function () {
        if ($(this).is(':checked')) {
            $(this).siblings('label').html('Uitvinken');

            $('.option-a').addClass('highlight');
            if ($('.checkbox-b').is(':checked')) {
                $('.option-a').addClass('highlight-adjust-a');
            }
        } else {
            $(this).siblings('label').html('Selecteren');
            $('.option-a').removeClass('highlight');
            $('.option-a').removeClass('highlight-adjust-a');
        }
    });

    $('.checkbox-b').change(function () {
        if ($(this).is(':checked')) {
            $(this).siblings('label').html('Uitvinken');
            $('.option-b').addClass('highlight');
            if ($('.checkbox-a').is(':checked')) {
                $('.option-a').addClass('highlight-adjust-a');
            }
            if ($('.checkbox-c').is(':checked')) {
                $('.option-c').addClass('highlight-adjust-c');
            }
        } else {
            $(this).siblings('label').html('Selecteren');
            $('.option-b').removeClass('highlight');
            if ($('.checkbox-a').is(':checked')) {
                $('.option-a').removeClass('highlight-adjust-a');
            }
            if ($('.checkbox-c').is(':checked')) {
                $('.option-c').removeClass('highlight-adjust-c');
            }
        }
    });

    $('.checkbox-c').change(function () {
        if ($(this).is(':checked')) {
            $(this).siblings('label').html('Uitvinken');
            $('.option-c').addClass('highlight');
            if ($('.checkbox-b').is(':checked')) {
                $('.option-c').addClass('highlight-adjust-c');
            }
        } else {
            $(this).siblings('label').html('Selecteren');
            $('.option-c').removeClass('highlight');
            $('.option-c').removeClass('highlight-adjust-c');
        }
    });
});
