import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';

import {Answer} from '../../../../../models/answer';
import {ModalQuestion} from './modal_question';
import {NormalQuestionType} from '../../../../../enum/question_type';
import {ValidationMessages} from '../components/validation_messages';
import {classNames} from '../../../../../../support/classnames';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {observer} from 'mobx-react';
import {CompactEditable} from '../../../components/compact_editable';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {shouldShowTitle} from '../../../../../../support/should_show_title';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const BooleanQuestion: React.FC<OwnProps> = observer(function BooleanQuestion(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));
    if (presenter.isHidden) {
        return null;
    }

    const answer = presenter.answer;

    const CHECKED_VALUE = '1';
    const UNCHECKED_VALUE = '0';

    function renderSubmodal(answer: Answer) {
        return findChildrenForQuestion(props.question, props.questionSet)
            .filter((potentialChild) => potentialChild.type === NormalQuestionType.MODAL)
            .map((child) => (
                <ModalQuestion
                    key={child.uuid}
                    appraisal={props.appraisal}
                    question={child}
                    questionSet={props.questionSet}
                    validationErrors={props.validationErrors}
                    validationMessages={props.validationMessages}
                    parentAnswerUuid={answer.uuid}
                    hiddenQuestionTypes={props.hiddenQuestionTypes}
                    pagePartsSet={props.pagePartsSet}
                    activePagePart={props.activePagePart}
                    renderingContext={props.renderingContext}
                    questionRenderingData={props.questionRenderingData}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                >
                    <button
                        className="btn btn-secondary ion-md-square-outline"
                        type="button"
                        onClick={() => presenter.onChange(UNCHECKED_VALUE)}
                    >
                        Uitvinken
                    </button>
                </ModalQuestion>
            ));
    }

    function renderCompact() {
        const answer = presenter.answer;
        return (
            <>
                {shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel) ? (
                    <label htmlFor={presenter.name}>{props.question.contents}</label>
                ) : null}
                <div>{answer !== undefined && answer.contents === CHECKED_VALUE ? 'Ja' : 'Nee'}</div>
            </>
        );
    }

    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'automation-filled': presenter.filledByAutomator !== null,
            })}
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
        >
            <CompactEditable
                renderingContext={props.renderingContext}
                compact={() => renderCompact()}
                question={props.question}
                answer={presenter.answer}
            >
                <input
                    type="checkbox"
                    id={presenter.name}
                    name={presenter.name}
                    checked={answer !== undefined && answer.contents === CHECKED_VALUE}
                    disabled={props.disabled ?? presenter.isDisabled}
                    onChange={(e) => presenter.onChange(e.target.checked ? CHECKED_VALUE : UNCHECKED_VALUE)}
                />
                <label htmlFor={presenter.name}>{props.hideLabel !== true && props.question.contents}</label>
                {answer !== undefined && answer.contents === CHECKED_VALUE && renderSubmodal(answer)}
            </CompactEditable>

            <ValidationMessages
                alignLeft
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={answer}
                validationMessages={filterValidationMessages(
                    props.question,
                    answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
        </div>
    );
});
