import {QuestionAnswerPair, buildQuestionAnswerTrees} from '../../../support/question_answer_tree';
import {TreeItem, findChildRecursiveByPredicate} from '../../../support/generic_tree';
import {sortByCreatedAt, sortTreeItems} from '../../../support/sort_answer';

import {Answer} from '../../models/answer';
import {NormalQuestionType} from '../../enum/question_type';
import {Question} from '../../models/question';
import {QuestionSet} from '../../models/question_set';
import {partition} from '../../../support/partition_array';

export function groupPhotosByLabels(
    labelSortingOrder: string[],
    otherLabel: string,
    answers: Answer[],
    questionSet: QuestionSet,
    photoIteratorQuestion: Question
): Map<string, Array<TreeItem<QuestionAnswerPair>>> {
    let trees = buildQuestionAnswerTrees(questionSet, answers, photoIteratorQuestion);
    const labelsMap = new Map<string, Array<TreeItem<QuestionAnswerPair>>>();

    for (const label of labelSortingOrder) {
        const {matched, unmatched} = partition(trees, (tree) => {
            const labelItem = findChildRecursiveByPredicate(
                tree,
                (item) => item.question.type === NormalQuestionType.MC_SELECT
            );
            const selectedLabelAnswerOption = labelItem?.item.question.answerOptions.find(
                (ao) => ao.id === labelItem?.item.answer?.answerOptionId
            );
            return (
                selectedLabelAnswerOption !== undefined &&
                (selectedLabelAnswerOption.reportValue?.toLowerCase() === label ||
                    selectedLabelAnswerOption.contents.toLowerCase() === label)
            );
        });
        labelsMap.set(label, matched.sort(sortTreeItems(sortByCreatedAt)));
        trees = unmatched;
    }

    const otherLabels = labelsMap.get(otherLabel) ?? [];
    labelsMap.set(otherLabel, [...otherLabels, ...trees].sort(sortTreeItems(sortByCreatedAt)));

    return labelsMap;
}
