import {ApiFile, apiFileToFile} from './api_file';

import {AnswerAuditTrailItem} from '../../models/answer_audit_trail_item';

export interface ApiAnswerAuditTrailItem {
    uuid: string;
    question_uuid: string;
    answer_option_id: number;
    contents: string;
    file: ApiFile | null;
    is_deleted: boolean;
    is_visited: boolean;
    updated_at: string;
    created_at: string;
    filled_by_automator: string;
    is_automated: boolean;
    user_display_name: string | null;
}

export function apiAnswerAuditTrailItemToAnswerAuditTrailItem(
    apiAnswerAuditTrailItem: ApiAnswerAuditTrailItem
): AnswerAuditTrailItem {
    return {
        uuid: apiAnswerAuditTrailItem.uuid,
        questionUuid: apiAnswerAuditTrailItem.question_uuid,
        answerOptionId: apiAnswerAuditTrailItem.answer_option_id,
        contents: apiAnswerAuditTrailItem.contents,
        file: apiAnswerAuditTrailItem.file !== null ? apiFileToFile(apiAnswerAuditTrailItem.file) : null,
        isDeleted: apiAnswerAuditTrailItem.is_deleted,
        isVisited: apiAnswerAuditTrailItem.is_visited,
        updatedAt: apiAnswerAuditTrailItem.updated_at,
        createdAt: apiAnswerAuditTrailItem.created_at,
        filledByAutomator: apiAnswerAuditTrailItem.filled_by_automator,
        isAutomated: apiAnswerAuditTrailItem.is_automated,
        userDisplayName: apiAnswerAuditTrailItem.user_display_name,
    };
}
