import * as React from 'react';

import {Appraisal} from '../../../../../../../../models/appraisal';
import {AreaConclusion} from '../../../../../../../../models/area_conclusion';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {VolumePriceBadge} from '../../../../../../../../components/badges/volume_price_badge';
import {isEmpty} from '../../../../../../../../../support/util';
import {observer} from 'mobx-react';

interface OwnProps {
    compact?: boolean;
    volume: number | null;
    price: string | null;
    valuation: string | null;
    questionSet: QuestionSet;
    appraisal: Appraisal;
    areaConclusion: AreaConclusion | null;
}

@observer
export class VolumePrice extends React.Component<OwnProps> {
    private formatPrice(from: number): string {
        return `€ ${from.toLocaleString('nl-NL')}`;
    }

    private average(fromString: string, tilString: string | undefined): number {
        const from = parseInt(fromString, 10);
        const till = tilString !== undefined ? parseInt(tilString, 10) : from;

        return (from + till) / 2;
    }

    public render() {
        if (
            this.props.price === null ||
            isEmpty(this.props.price.trim()) ||
            this.props.volume === null ||
            this.props.volume === 0
        ) {
            return <>{'Onbekend'}</>;
        }
        const [from, till] = this.props.price.split(' - ');
        const price = this.props.price !== null ? parseInt(this.props.price, 10) : this.average(from, till);
        const pricePerM3 = Math.round(price / this.props.volume);

        const referencePricePerM3 =
            this.props.areaConclusion !== null &&
            this.props.areaConclusion.brutoInhoud !== null &&
            this.props.areaConclusion.brutoInhoud !== 0 &&
            this.props.valuation !== null
                ? Math.round(parseInt(this.props.valuation, 10) / this.props.areaConclusion.brutoInhoud)
                : null;

        return (
            <>
                {referencePricePerM3 !== null ? (
                    <VolumePriceBadge
                        volumePrice={pricePerM3}
                        referenceSetVolumePrice={referencePricePerM3}
                        compact={this.props.compact}
                    />
                ) : null}
                <span key="price_m3">{this.formatPrice(pricePerM3)}</span>
            </>
        );
    }
}
