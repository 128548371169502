import {makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {Presenter} from '../../../../../support/presenter/presenter';
import {ReferenceObjectsAnswerEnhancementInteractor} from '../../content/questions/advanced/reference_objects_question/interactors/reference_objects_answer_enhancement_interactor';
import {ReferenceSale} from '../../content/questions/advanced/reference_objects_question/v1/models/reference_sale';
import {ReferenceObject} from '../../content/questions/advanced/reference_objects_question/v3/models/reference_object';
import {ReferenceObjectAnswer} from '../../content/questions/advanced/reference_objects_question/v3/models/reference_object_answer';

export class ReferenceObjectWidgetTilePresenter implements Presenter {
    @observable public enhancedReferenceSale: ReferenceSale | null = null;

    private subscriptions = new CompositeSubscription();

    constructor(
        private referenceObject: ReferenceObject | null,
        private referenceObjectAnswer: ReferenceObjectAnswer | null,
        private referenceObjectsAnswerEnhancementInteractor: ReferenceObjectsAnswerEnhancementInteractor
    ) {
        makeObservable(this);
    }

    public async mount() {
        if (this.referenceObject === null && this.referenceObjectAnswer !== null) {
            this.subscriptions.add(
                this.referenceObjectsAnswerEnhancementInteractor
                    .streamForAnswer(this.referenceObjectAnswer)
                    .subscribe((enhancedObject) => {
                        runInAction(() => {
                            this.enhancedReferenceSale = enhancedObject;
                        });
                    })
            );
        }
    }

    public async unmount() {
        this.subscriptions.clear();
    }
}
