function hideAllSections() {
    $('#front-page-section-none').hide();
    $('#front-page-section-design').hide();
    $('#front-page-section-custom').hide();
    $('#front-page-section-organisation').hide();
}

function showSection(section) {
    $('#front-page-section-' + section).show();

    loadPreview(section);
}

function loadPreview(section) {
    if (section === 'none') {
        $('.front-page-preview, .front-page-preview-container').hide();
    } else if (section === 'custom') {
        const url = $('.front-page-preview').first().attr('data-custom-pdf');

        if (url) {
            $('.front-page-preview').attr('src', url);
            $('.front-page-preview, .front-page-preview-container').show();
        } else {
            $('.front-page-preview, .front-page-preview-container').hide();
        }
    } else if (section === 'design') {
        const selected = $('.form-control[type="radio"][name="design_selector"]:checked');
        if (selected.length > 0) {
            const design = selected[0].value;
            const url = new URL($('.front-page-preview').first().attr('data-preview-route'));

            url.search = '';
            url.searchParams.set('design', design);

            $('input[type="checkbox"][name^="component_"]:checked').each((_, c) =>
                url.searchParams.set(c.name, c.value)
            );

            url.searchParams.set('primary_color', $('#primary_color').val());
            url.searchParams.set('secondary_color', $('#secondary_color').val());
            url.searchParams.set('text_color', $('#text_color').val());

            $('.front-page-preview').attr('src', url.toString());
            $('.front-page-preview, .front-page-preview-container').show();

            $('input[type="hidden"][name="type"]').val(selected[0].value);
        } else {
            $('.front-page-preview, .front-page-preview-container').hide();
        }
    } else if (section === 'organisation') {
        const selected = $('.form-control[type="radio"][name="organisation_design_selector"]:checked');
        if (selected.length > 0) {
            const design = selected[0].value;
            const url = new URL($('.front-page-preview').first().attr('data-preview-route'));

            url.search = '';
            url.searchParams.set('design', design);

            $('.front-page-preview').attr('src', url.toString());
            $('.front-page-preview, .front-page-preview-container').show();

            $('input[type="hidden"][name="type"]').val(selected[0].value);
        } else {
            $('.front-page-preview, .front-page-preview-container').hide();
        }
    }
}

const inputs = $(
    '#front-page-type-none, #front-page-type-design, #front-page-type-custom, #front-page-type-organisation'
);
if (inputs.length > 0) {
    inputs.on('change', function (checkbox) {
        if (checkbox.target.checked) {
            $('input[type="hidden"][name="type"]').attr('value', checkbox.target.value);

            hideAllSections();
            showSection(checkbox.target.value);
        }
    });

    inputs.each(function () {
        if ($(this).is(':checked')) {
            hideAllSections();
            showSection(this.value);
        }
    });

    document.addEventListener(
        'uppy-upload-posted',
        /**
         * @param {CustomEvent} e
         */
        function (e) {
            if (e.detail && e.detail.thumbnail) {
                const image = document.createElement('img');
                image.src = e.detail.thumbnail;
                $('#uppy-preview-logo_image_id').empty().append(image);
            }

            if (e.detail && e.detail.pdf) {
                $('.front-page-preview').attr('data-custom-pdf', e.detail.pdf);
            }

            loadPreview('custom');
        }
    );

    document.addEventListener('uppy-upload-completed', function (e) {
        $('#uppy-target-logo_image_id').removeClass(['image-upload-empty', 'ion-md-document']);
        $('#uppy-preview-logo_image_id').append('<span class="loader"><span></span></span>');
    });

    $(
        '#primary_color, #secondary_color, #text_color, .form-control[type="radio"][name="design_selector"], input[type="checkbox"][name^="component_"], .form-control[type="radio"][name="organisation_design_selector"]'
    ).on('change', () => {
        loadPreview('design');
    });

    const primaryColor = $('#primary_color');
    const secondaryColor = $('#secondary_color');
    const textColor = $('#text_color');

    primaryColor.on('change', updateInputGroupColor);
    secondaryColor.on('change', updateInputGroupColor);
    textColor.on('change', updateInputGroupColor);

    const colorRegexp = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

    function updateInputGroupColor() {
        const inputGroupColor = $(this).parent('.input-group').find('.input-group-color');
        if (inputGroupColor.length > 0) {
            let value = $(this).val();

            if (!value.match(colorRegexp)) {
                $(this).addClass('is-invalid');
            } else {
                $(this).removeClass('is-invalid');
                inputGroupColor.css({background: value});
            }
        }
    }
}
