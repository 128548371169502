import {Observable, merge} from 'rxjs';
import {debounceTime, map, startWith} from 'rxjs/operators';

import {AnswerController} from '../answering/answer_controller';
import {AppraisalProvider} from '../appraisal_provider';
import {ChildQuestionValidator} from './child_validator';
import {IteratorQuestionType} from '../../enum/question_type';
import {QuestionSet} from '../../models/question_set';
import {ValidationMessage} from './validation_message';
import {findAllChildrenForQuestionUuid} from '../../appraise/ui/support/question_filtering';

export interface ChildQuestionValidationProvider {
    /**
     * For a certain answer/question combination get all validation messages of lower tree
     * @param questionUuid
     * @param answerUuid
     */
    validate(questionUuid: string, answerUuid: string | null, debounce?: number): Observable<ValidationMessage[]>;
}

export class DefaultChildQuestionValidationProvider implements ChildQuestionValidationProvider {
    constructor(
        private questionSet: QuestionSet,
        private childValidator: ChildQuestionValidator,
        private answerController: AnswerController,
        private appraisalProvider: AppraisalProvider
    ) {}

    public validate(questionUuid: string, answerUuid: string | null, debounce = 100): Observable<ValidationMessage[]> {
        const blacklistedQuestionsUuids = this.questionSet
            .findQuestionsByType(IteratorQuestionType.ITERATOR_REFERENCE_OBJECTS_V3)
            .map((q) => q.uuid);
        const questions = findAllChildrenForQuestionUuid(questionUuid, this.questionSet, []);

        return merge(
            this.answerController.answersForQuestionUuidsStream(questions.map((question) => question.uuid)),
            this.appraisalProvider.stream
        ).pipe(
            debounceTime(debounce),
            map(() => this.childValidator.validate(questionUuid, answerUuid, blacklistedQuestionsUuids)),
            startWith(this.childValidator.validate(questionUuid, answerUuid, blacklistedQuestionsUuids))
        );
    }
}
