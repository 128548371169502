import {ApiConditionGroup, apiConditionGroupToConditionGroup} from './api_condition_group';

import {Macro, MacroExternalType, MacroType, SuperMacro} from '../../models/macro';
import {ApiSuperMacro, apiSuperMacroToSuperMacro} from './api_super_macro';
import {ApiMacroSettings, apiMacroSettingsToMacroSettings} from './api_macro_settings';
import {MacroSettings} from '../../models/macro_settings';

export interface ApiMacro {
    id: number;
    is_user_defined: boolean;
    question_uuid: string;
    external_type: MacroExternalType;
    contents?: string | null;
    condition_groups: ApiConditionGroup[];
}

export interface ApiUserMacros {
    user_defined_macros: ApiMacro[];
    super_macros: ApiSuperMacro[];
    user_macro_settings: ApiMacroSettings[];
}

export interface UserMacros {
    userDefinedMacros: Macro[];
    superMacros: SuperMacro[];
    userMacroSettings: MacroSettings[];
}

export function apiMacroToMacro(apiMacro: ApiMacro): Macro {
    return {
        id: apiMacro.id,
        type: MacroType.DEFAULT,
        isUserDefined: apiMacro.is_user_defined,
        questionUuid: apiMacro.question_uuid,
        externalType: apiMacro.external_type,
        contents: apiMacro.contents ?? null,
        conditionGroups: apiMacro.condition_groups.map((apiConditionGroup) =>
            apiConditionGroupToConditionGroup(apiConditionGroup)
        ),
    };
}

export function apiUserMacrosToUserMacros(apiMacroResponse: ApiUserMacros): UserMacros {
    return {
        userDefinedMacros: apiMacroResponse.user_defined_macros.map((apiMacro) => apiMacroToMacro(apiMacro)),
        superMacros: apiMacroResponse.super_macros.map((apiSuperMacro) => apiSuperMacroToSuperMacro(apiSuperMacro)),
        userMacroSettings: apiMacroResponse.user_macro_settings.map((apiMacroSettings) =>
            apiMacroSettingsToMacroSettings(apiMacroSettings)
        ),
    };
}
