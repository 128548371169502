import Editor, {PluginInfo} from '@toast-ui/editor';
import {EmailMarkdownEditorData, TemplateField} from '.';

declare const EMAIL_MARKDOWN_EDITOR: EmailMarkdownEditorData;

let fields: TemplateField[] = [];

if (typeof EMAIL_MARKDOWN_EDITOR !== 'undefined') {
    fields = EMAIL_MARKDOWN_EDITOR.template.fields;
}

export function fieldPlugin(): PluginInfo {
    return {
        wysiwygCommands: {
            'insert-field': (payload, state, dispatch) => {
                const {selection, schema, tr} = state;

                if (!payload.field) {
                    return false;
                }

                tr.insert(selection.to, schema.text(`{{${payload.field}}}`));

                dispatch(tr);

                return true;
            },
        },
    };
}

export function fieldToolbarButton(getEditor: () => Editor) {
    const fieldList = document.createElement('ul');
    fieldList.setAttribute('aria-role', 'menu');

    for (const field of fields) {
        const fieldItem = document.createElement('li');
        fieldItem.innerText = field.name;

        fieldItem.onclick = () => {
            const editor = getEditor();

            editor.eventEmitter.emit('command', 'insert-field', {field: field.key});
            editor.eventEmitter.emit('closePopup');
        };

        fieldList.appendChild(fieldItem);
    }

    return {
        name: 'add-field',
        tooltip: 'Veld',
        className: `ion-md-code-working bg-transparent`,
        style: {fontSize: 20},
        popup: {
            className: `toastui-editor-popup-add-heading`,
            body: fieldList,
            style: {width: 'auto'},
        },
    };
}
