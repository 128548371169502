import {ConstructionalDefectsPhotoLabel, DefaultPhotoLabel, PhotoLabel} from './automl_photo_recognition';
import {AutoMLVersion} from './automl_photo_content_predicter';

export interface PhotoDetailRecognition {
    recognizeOn: PhotoLabel;
    recognizers: PhotoDetailRecognizer[];
}

export interface PhotoDetailRecognizer {
    recognizeVersion: AutoMLVersion;
    recognizeResult: PhotoDetailRecognizeResult;
    competingDetails: PhotoCompetingDetails[];
}

export enum PhotoDetailRecognizeResult {
    SINGLE = 'single',
    MULTIPLE = 'multiple',
}

export interface PhotoCompetingDetails {
    details: string[];
}

export abstract class ConfigPhotoRecognition {
    public static getConfig(label: DefaultPhotoLabel | ConstructionalDefectsPhotoLabel): PhotoDetailRecognition | null {
        switch (label) {
            default:
                // Todo: temporary disabled since 30-03-2022
                return null;
            // return {
            //     recognizeOn: DefaultPhotoLabel.BATHROOM,
            //     recognizers: [
            //         {
            //             recognizeVersion: AutoMLVersion.BATHROOM_STYLE,
            //             recognizeResult: PhotoDetailRecognizeResult.SINGLE,
            //             competingDetails: [],
            //         },
            //         {
            //             recognizeVersion: AutoMLVersion.BATHROOM_DETAILS,
            //             recognizeResult: PhotoDetailRecognizeResult.MULTIPLE,
            //             competingDetails: [
            //                 {
            //                     details: ['DubbeleWastafel', 'Wastafel'],
            //                 },
            //                 {
            //                     details: ['Inloopdouche', 'Douchecabine', 'Douchehoek'],
            //                 },
            //                 {
            //                     details: ['Jacuzzi', 'Ligbad'],
            //                 },
            //             ],
            //         },
            //     ],
            // };
        }
        return null;
    }
}
