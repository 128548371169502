import {BuildYearProvider} from '../../../../../../../../../../business/build_year_provider';
import {ReferenceObjectSortingStrategy} from './reference_object_sorting_strategy';
import {SortingDirection} from '../../../../../../../../../../enum/reference_objects_sorting';

export interface PercentageSortableObject {
    id: string;
    buildYear: number | null;
    plotArea: number | null;
    floorArea: number | null;
    matchingPercentage: number | null;
}

interface PercentageSortableObjectWithRequiredAttributes extends PercentageSortableObject {
    buildYear: number;
    plotArea: number;
    floorArea: number;
}

interface Pair<TUnkown, TSortableObject extends PercentageSortableObject> {
    referenceObject: TUnkown;
    sortableObject: TSortableObject;
}

export class ReferenceObjectSorterByDeviationScore implements ReferenceObjectSortingStrategy {
    constructor(
        private buildYearProvider: BuildYearProvider,
        private gebruiksoppervlakteWonen: number | null,
        private perceelOppervlakte: number | null
    ) {}

    public sortReferenceObjects<TUnkown, TSortableObject extends PercentageSortableObject>(
        referenceObjects: TUnkown[],
        sortableObjectTransformer: (item: TUnkown) => TSortableObject,
        sortingDirection: SortingDirection
    ): TUnkown[] {
        const pairs: Array<Pair<TUnkown, TSortableObject>> = referenceObjects.map((referenceObject) => {
            return {
                referenceObject,
                sortableObject: sortableObjectTransformer(referenceObject),
            };
        });

        // make array of custom reference sales
        const customReferenceObjects = pairs.filter((pair) => pair.sortableObject.id.includes('custom'));

        // make array of reference sales with all the required properties
        const referenceObjectsWithRequiredAttributes = pairs.filter(
            (pair): pair is Pair<TUnkown, TSortableObject & PercentageSortableObjectWithRequiredAttributes> =>
                !pair.sortableObject.id.includes('custom') &&
                pair.sortableObject.buildYear !== null &&
                pair.sortableObject.plotArea !== null &&
                pair.sortableObject.floorArea !== null
        );

        // make array of reference sales with at least one of the required attributes missing
        const referenceObjectsWithoutNormalizedSaleDate = pairs.filter(
            (pair) =>
                !pair.sortableObject.id.includes('custom') &&
                pair.sortableObject.buildYear === null &&
                pair.sortableObject.plotArea === null &&
                pair.sortableObject.floorArea === null
        );

        // sort the reference objects with all required attributes
        const sortedReferenceObjects = referenceObjectsWithRequiredAttributes.sort((pair1, pair2) => {
            return this.getMatchPercentageWithAppraisalForReferenceObject(pair1.sortableObject) >
                this.getMatchPercentageWithAppraisalForReferenceObject(pair2.sortableObject)
                ? sortingDirection === SortingDirection.ASCENDING
                    ? 1
                    : -1
                : sortingDirection === SortingDirection.ASCENDING
                ? -1
                : 1;
        });

        // put the invalid reference sales at the end
        return [
            ...customReferenceObjects.map((pair) => pair.referenceObject),
            ...sortedReferenceObjects.map((pair) => pair.referenceObject),
            ...referenceObjectsWithoutNormalizedSaleDate.map((pair) => pair.referenceObject),
        ];
    }

    // Returns number in range 0 - 1
    private getMatchPercentageWithAppraisalForReferenceObject<T extends PercentageSortableObject>(
        referenceObject: T & PercentageSortableObjectWithRequiredAttributes
    ): number {
        if (referenceObject.matchingPercentage !== null) {
            // Matching percentage (for Brainbay references) is stored as a number between 0 and 10000 (for example 8900 = 89%)
            return referenceObject.matchingPercentage / 10000;
        }

        const MAX_DEVIATION_SCORE = 15;

        const deviationScore = this.getDeviationScoreWithAppraisalForReferenceObject(referenceObject);
        return 1 - deviationScore / MAX_DEVIATION_SCORE;
    }

    private getDeviationScoreWithAppraisalForReferenceObject<T extends PercentageSortableObject>(
        referenceObject: T & PercentageSortableObjectWithRequiredAttributes
    ): number {
        let appraisalBuildYear = this.buildYearProvider.getDestructionYear();
        if (appraisalBuildYear === null) {
            appraisalBuildYear = this.buildYearProvider.get();
        }

        // cannot be null as the reference object view cannot be opened when build year is not known
        if (appraisalBuildYear === null || this.gebruiksoppervlakteWonen === null || this.perceelOppervlakte === null) {
            return 0;
        }

        const buildYearDeviationScore = this.getBuildYearDeviationScore(appraisalBuildYear, referenceObject.buildYear);
        const floorAreaDeviationScore = this.getAreaDeviationScore(
            this.gebruiksoppervlakteWonen,
            referenceObject.floorArea,
            9
        );
        const lotAreaDeviationScore = this.getAreaDeviationScore(this.perceelOppervlakte, referenceObject.plotArea, 3);

        return buildYearDeviationScore + floorAreaDeviationScore + lotAreaDeviationScore;
    }

    private getBuildYearDeviationScore(appraisalBuildYear: number, referenceObjectBuildYear: number): number {
        const difference = Math.abs(appraisalBuildYear - referenceObjectBuildYear);
        const yearWeight = 0.25;
        return Math.min(3, difference * yearWeight);
    }

    private getAreaDeviationScore(appraisalArea: number, referenceObjectArea: number, weight: number): number {
        const difference = Math.abs(appraisalArea - referenceObjectArea);
        const areaWeight = 0.166666666;
        return Math.min(weight, difference * areaWeight);
    }
}
