import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {PhotosWidgetPresenter} from './photos_widget_presenter';
import {QuestionSet} from '../../../../models/question_set';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {ImageViewer} from '../../../../components/image_viewer/image_viewer';

export interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export const PhotosWidget: React.FC<OwnProps> = observer(function PhotosWidget(props) {
    const presenter = usePresenter(
        (container) =>
            new PhotosWidgetPresenter(
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet)
            )
    );

    function isValidUrl(string: string): boolean {
        try {
            const url = new URL(string);
            return url.protocol === 'http:' || url.protocol === 'https:';
        } catch (_) {
            return false;
        }
    }

    return (
        <div className="col-12">
            <div className="widget widget-photos">
                <div className="widget-title">
                    <h3>
                        <span className="ion-md-image" />
                        Foto&apos;s
                    </h3>
                </div>
                <div className="widget-body">
                    <div className="row main-photo-container">
                        <ImageViewer
                            images={presenter.fileReferences
                                .filter((fileReference) =>
                                    fileReference.uncompressedUrl
                                        ? isValidUrl(fileReference.uncompressedUrl)
                                        : isValidUrl(fileReference.url)
                                )
                                .map((fileReference) => ({
                                    url: fileReference.url,
                                    uncompressedUrl: fileReference.uncompressedUrl,
                                }))}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});
