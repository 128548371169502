import * as React from 'react';

import {Appraisal} from '../../../models/appraisal';
import {ConstructionCostCounterPresenter} from './construction_cost_counter_presenter';
import {DefaultChildQuestionsProvider} from '../../../business/child_questions_provider';
import {DefaultPathNameProvider} from '../../../business/path_name_provider';
import {History} from 'history';
import {PagePartsSet} from '../../../models/page_parts_set';
import {QuestionSet} from '../../../models/question_set';
import {ValidationError} from '../../../models/validation_error';
import {formatMoney} from '../support/format_money';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../support/presenter/use_presenter';

interface OwnProps {
    history: History;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet | null;
    validationErrors: ValidationError[];
}

export const ConstructionCostCounter: React.FC<OwnProps> = observer(function ConstructionCostCounter(props) {
    const presenter = usePresenter(
        (container) =>
            new ConstructionCostCounterPresenter(
                props.questionSet,
                new DefaultChildQuestionsProvider(
                    props.questionSet,
                    container.business.sidebarTreeBuilder(props.appraisal, props.questionSet, props.pagePartsSet),
                    new DefaultPathNameProvider(props.history)
                ),
                container.business.constructionCostsProvider(props.appraisal, props.questionSet)
            )
    );

    if (presenter.totalCostEuros === null || !presenter.visible) {
        return null;
    }
    return (
        <div className="floater floater-information">
            Totale kosten verbouwing: <strong>{formatMoney(presenter.totalCostEuros)}</strong>
        </div>
    );
});
