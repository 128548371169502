import React, {useMemo} from 'react';
import {formatMoney} from '../../appraising/appraise/ui/support/format_money';
import {AppraisalCostLineWithNote} from './appraisal_costs';
import {isEmpty} from '../../support/util';

interface OwnProps {
    lines: AppraisalCostLineWithNote[];
}

export function CostsOverview({lines}: OwnProps) {
    const total = lines.reduce((total, line) => total + line.costCents, 0);

    const {groupedNotes, modifiedLines} = useMemo(() => {
        const groupedNotes: string[] = [];

        const modifiedLines = lines.map((line) => {
            if (!line.note) {
                return line;
            }

            let noteIndex = groupedNotes.indexOf(line.note);
            if (noteIndex === -1) {
                noteIndex = groupedNotes.length;
                groupedNotes.push(line.note);
            }

            return {
                ...line,
                note: '*'.repeat(noteIndex + 1),
            };
        });

        return {groupedNotes, modifiedLines};
    }, [lines]);

    return (
        <>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th>Beschrijving</th>
                        <th className="text-right">Tarief</th>
                    </tr>
                </thead>
                <tbody>
                    {modifiedLines.map((line) => (
                        <tr key={line.costType}>
                            <td>
                                {line.displayName}
                                {line.note ?? ''}
                            </td>
                            <td className="text-right">
                                {!isEmpty(line.originalCostCents) && line.originalCostCents > line.costCents && (
                                    <span className="discount-original-price pr-2">
                                        {formatMoney(line.originalCostCents / 100, {decimals: 2})}
                                    </span>
                                )}{' '}
                                {formatMoney(line.costCents / 100, {decimals: 2})}
                            </td>
                        </tr>
                    ))}
                </tbody>
                <thead>
                    <tr>
                        <th>Totaal (excl. BTW)</th>
                        <th className="text-right">{formatMoney(total / 100, {decimals: 2})}</th>
                    </tr>
                </thead>
            </table>
            {groupedNotes.map((note, index) => (
                <p key={index}>
                    {'*'.repeat(index + 1)} {note}
                </p>
            ))}
        </>
    );
}
