import {BusinessComponent, DefaultBusinessComponent} from '../business/business_component';
import {DefaultNetworkComponent, NetworkComponent} from '../network/network_component';
import {DefaultPersistenceComponent, PersistenceComponent} from '../../persistence/persistence_component';
import {DefaultUiComponent, UiComponent} from './ui/ui_component';
import {ServerTimeProvider, ServerTimeProviderImpl} from '../server_time/server_time_provider';
import {TimeProvider, TimeProviderImpl} from '../server_time/time_provider';

import {BestServerTimeInteractor} from '../server_time/best_server_time_interactor';
import {BurstServerTimeInteractor} from '../server_time/burst_server_time_interactor';
import {DefaultGlobalProvider, GlobalProvider} from '../../business/global_provider';
import {ServerTimeInteractor} from '../server_time/server_time_interactor';
import {SharedServerTimeInteractor} from '../server_time/shared_server_time_interactor';
import {SingleServerTimeInteractor} from '../server_time/single_server_time_interactor';
import {WeightedServerTimeInteractor} from '../server_time/weighted_server_time_interactor';

export interface Component {
    network: NetworkComponent;
    business: BusinessComponent;
    persistence: PersistenceComponent;
    ui: UiComponent;
    globalProvider: GlobalProvider;
    serverTimeProvider: ServerTimeProvider;
}

class ComponentImpl implements Component {
    private _network?: NetworkComponent;
    private _business?: BusinessComponent;
    private _persistence?: PersistenceComponent;
    private _ui?: UiComponent;
    private _globalProvider: GlobalProvider | undefined;
    private _serverTimeProvider: ServerTimeProvider | undefined;
    private _serverTimeInteractor: ServerTimeInteractor | undefined;
    private _timeProvider: TimeProvider | undefined;

    get network(): NetworkComponent {
        if (this._network === undefined) {
            this._network = new DefaultNetworkComponent(this.globalProvider);
        }
        return this._network;
    }

    get business(): BusinessComponent {
        if (this._business === undefined) {
            this._business = new DefaultBusinessComponent(
                this.persistence,
                this.network,
                this.serverTimeProvider,
                this.globalProvider
            );
        }
        return this._business;
    }

    get persistence(): PersistenceComponent {
        if (this._persistence === undefined) {
            this._persistence = new DefaultPersistenceComponent();
        }
        return this._persistence;
    }

    get ui(): UiComponent {
        if (this._ui === undefined) {
            this._ui = new DefaultUiComponent();
        }
        return this._ui;
    }

    get globalProvider(): GlobalProvider {
        if (this._globalProvider === undefined) {
            this._globalProvider = new DefaultGlobalProvider();
        }
        return this._globalProvider;
    }

    get serverTimeProvider(): ServerTimeProvider {
        if (this._serverTimeProvider === undefined) {
            this._serverTimeProvider = new ServerTimeProviderImpl(this.serverTimeInteractor, this.timeProvider);
        }
        return this._serverTimeProvider;
    }

    private get serverTimeInteractor(): ServerTimeInteractor {
        if (this._serverTimeInteractor === undefined) {
            this._serverTimeInteractor = new SharedServerTimeInteractor(
                new WeightedServerTimeInteractor(
                    new BestServerTimeInteractor(
                        new BurstServerTimeInteractor(new SingleServerTimeInteractor(), 3, 1, 300000)
                    )
                )
            );
        }
        return this._serverTimeInteractor;
    }

    private get timeProvider(): TimeProvider {
        if (this._timeProvider === undefined) {
            this._timeProvider = new TimeProviderImpl();
        }
        return this._timeProvider;
    }
}

let instance: Component;

export function component(): Component {
    if (instance === undefined) {
        instance = new ComponentImpl();
    }

    return instance;
}
