import * as React from 'react';

import {Appraisal} from '../../../../../models/appraisal';
import {ConstructionCostCalculator} from './construction_cost_calculator';
import {ConstructionCostsLine} from '../../../../../models/construction_costs_summary';
import {ConstructionDefectsWidgetContext} from '../construction_defects_widget_context';
import {ConstructionalDefectsQuestionPresenter} from './constructional_defects_question_presenter';
import {CostsInput} from './costs_input';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {ValidationInstituteConstructionalDefect} from '../../../../../models/validation_institute_constructional_defect';
import {classNames} from '../../../../../../support/classnames';
import {formatLabel} from '../../../support/building_costs_reference_formatter';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {SimpleTooltip} from '../../../../../components/tooltip';
import {NormalQuestionType} from '../../../../../enum/question_type';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    disabled?: boolean;
    question: Question;
    parentAnswerUuid: string;
    constructionCosts: ConstructionCostsLine[] | null;
    initialValidationInstituteConstructionalDefect?: ValidationInstituteConstructionalDefect;
    constructionCostsWidgetContext: ConstructionDefectsWidgetContext;
    onClose: () => void;
    onChangeConstructionCostsWidgetContext: (c: ConstructionDefectsWidgetContext) => void;
}

export const ConstructionalDefectsQuestion: React.FC<OwnProps> = observer(function ConstructionalDefectsQuestion(
    props
) {
    const presenter = usePresenter(
        (container) =>
            new ConstructionalDefectsQuestionPresenter(
                props.appraisal,
                props.onClose,
                props.parentAnswerUuid,
                props.question,
                props.onChangeConstructionCostsWidgetContext,
                props.initialValidationInstituteConstructionalDefect ?? null,
                container.business.answerController(props.appraisal, props.questionSet),
                container.globalProvider
            )
    );

    function isSelected(defect: ValidationInstituteConstructionalDefect | null, line: ConstructionCostsLine) {
        if (defect) {
            for (const selectedLine of defect.lines) {
                if (selectedLine.description === line.description) {
                    return true;
                }
            }
        }
        return false;
    }

    // This question has no costs
    if (props.question.type === NormalQuestionType.BUILDING_DEFECTS) {
        return null;
    }

    const hasConstructionCosts = props.constructionCosts !== null && props.constructionCosts.length > 0;

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <p>
                        Selecteer het type herstelwerk of voeg deze zelf toe. Daarna kunnen de kosten berekend worden.
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    {presenter.validationInstituteConstructionalDefect?.lines?.map((constructionCost, index) => {
                        return (
                            <ConstructionCostCalculator
                                isDisabled={presenter.isDisabled}
                                key={
                                    (constructionCost.isCustom ? 'custom' : constructionCost.description) + '_' + index
                                }
                                constructionCost={
                                    constructionCost.isCustom ? {...constructionCost, amount: 1} : constructionCost
                                }
                                onChangeLine={(line) => presenter.changeLine(index, line)}
                                onDelete={() => presenter.deleteLine(index)}
                            />
                        );
                    })}
                </div>
            </div>

            {props.constructionCosts ? (
                <div className="row">
                    <div className="col-12">
                        <div
                            className={classNames('form-group pulse-input-enabled pulse-input-on-load', {
                                'form-group-with-outside-button': presenter.isBuildingInspectorButtonEnabled,
                            })}
                        >
                            <div className="select-with-button form-control input-group append-btn">
                                <select
                                    className="custom-select"
                                    disabled={presenter.isDisabled}
                                    value={
                                        presenter.selectedNewConstructionCostLine
                                            ? props.constructionCosts.indexOf(presenter.selectedNewConstructionCostLine)
                                            : undefined
                                    }
                                    onChange={(e) => {
                                        presenter.onSelectChange(props.constructionCosts, e.target.value);
                                    }}
                                >
                                    <option selected disabled>
                                        Selecteer een herstelwerk
                                    </option>
                                    {props.constructionCosts.map((cc, index) => (
                                        <option value={index} key={cc.description + '_' + index}>
                                            {formatLabel(cc.description)}{' '}
                                            {isSelected(presenter.validationInstituteConstructionalDefect, cc)
                                                ? '(Al geselecteerd)'
                                                : null}
                                        </option>
                                    ))}
                                    <option value={presenter.OWN_INPUT_VALUE}>Eigen invoer</option>
                                </select>
                                <div
                                    className={classNames('input-group-append', {
                                        disable: presenter.selectedNewConstructionCostLine === null,
                                    })}
                                >
                                    <SimpleTooltip
                                        content="Er dient eerst een herstel geselecteerd te zijn"
                                        placement="top"
                                        disabled={
                                            presenter.selectedNewConstructionCostLine !== null && hasConstructionCosts
                                        }
                                    >
                                        <button
                                            className={classNames('btn btn-append', {
                                                disable:
                                                    presenter.selectedNewConstructionCostLine === null &&
                                                    hasConstructionCosts,
                                            })}
                                            onClick={() => {
                                                if (hasConstructionCosts === false) {
                                                    presenter.onAddOwnInput();
                                                } else if (presenter.selectedNewConstructionCostLine) {
                                                    presenter.addNewConstructionCost(
                                                        presenter.selectedNewConstructionCostLine
                                                    );
                                                }
                                            }}
                                        >
                                            <span className="ion-md-add"></span>
                                        </button>
                                    </SimpleTooltip>
                                </div>
                            </div>
                            {presenter.isBuildingInspectorButtonEnabled ? (
                                <span
                                    className="btn btn-pulse btn-secondary btn-sm btn-icon btn-advice btn-icon icon-advice"
                                    onClick={() => presenter.toggleAdviceRequest(props.constructionCostsWidgetContext)}
                                ></span>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="row">
                <div className="col-6">
                    <CostsInput
                        onChange={(value) => presenter.onDirectCostsChange(value)}
                        onRefresh={() => presenter.refreshDirectCosts()}
                        isDisabled={presenter.isDisabled}
                        value={
                            presenter.validationInstituteConstructionalDefect?.directCosts
                                ? String(presenter.validationInstituteConstructionalDefect.directCosts / 100)
                                : '0'
                        }
                        label="Directe kosten"
                        validationInstituteConstructionalDefect={presenter.validationInstituteConstructionalDefect}
                    />
                </div>
                <div className="col-6">
                    <CostsInput
                        onChange={(value) => presenter.onIndirectCostsChange(value)}
                        onRefresh={() => presenter.refreshIndirectCosts()}
                        isDisabled={presenter.isDisabled}
                        value={
                            presenter.validationInstituteConstructionalDefect?.costs5Years
                                ? String(presenter.validationInstituteConstructionalDefect.costs5Years / 100)
                                : '0'
                        }
                        label="Kosten tussen 1-5 jaar"
                        validationInstituteConstructionalDefect={presenter.validationInstituteConstructionalDefect}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 justify-content-start">
                    <button
                        disabled={presenter.answer === undefined || presenter.isDisabled}
                        onClick={() => {
                            presenter.onClearAnswer();
                        }}
                        className="btn btn-delete btn-sm ion-md-trash float-left"
                    >
                        Verwijderen
                    </button>
                </div>
            </div>
        </>
    );
});
