import {observer} from 'mobx-react';
import * as React from 'react';
import {Loader} from '../../../appraising/appraise/ui/components/loader';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {PrecheckWidget} from '../components/widget';
import {AttachmentsWidgetPresenter} from './widget_presenter';

export const AttachmentsWidget = observer(() => {
    const presenter = usePresenter(
        (container) =>
            new AttachmentsWidgetPresenter(container.business.appraisalProvider, container.network.appraisalApi)
    );

    if (!presenter.appraisal) {
        return (
            <div className="empty-message">
                <Loader />
                <h3>Aan het laden...</h3>
            </div>
        );
    }

    return (
        <PrecheckWidget
            id="attachments"
            title="Bijlagen"
            description="Bekijk de opgehaalde bijlagen."
            icon="ion-md-document"
        >
            {presenter.attachments.length > 0 ? (
                <div className="widget-list">
                    <ul className="list-simple">
                        {presenter.attachments.map((a, i) => (
                            <li key={i}>
                                <span className="row">
                                    <span className="col-4">
                                        <span className="ellipsis">{a.name}</span>
                                    </span>
                                    <span className="col-5">
                                        <span className="ellipsis">{a.currentFile}</span>
                                    </span>
                                </span>
                                <span className="list-options">
                                    <a
                                        className="ion-md-cloud-download"
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => (a.answerUuid ? presenter.markVisited(a.answerUuid) : null)}
                                        href={a.currentFileUrl ?? undefined}
                                    ></a>
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                !presenter.loading && (
                    <div className="widget-body">
                        <p>Er zijn nog geen bestanden opgehaald.</p>
                    </div>
                )
            )}
            {presenter.loading && (
                <div className="d-flex align-items-center flex-column">
                    <Loader addSpace={false} isSmall />
                    <p>De bijlagen worden opgehaald...</p>
                </div>
            )}
        </PrecheckWidget>
    );
});
