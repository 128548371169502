import {Presenter} from '../../../../../../support/presenter/presenter';
import {Appraisal} from '../../../../../models/appraisal';
import {Question} from '../../../../../models/question';
import {computed, makeObservable} from 'mobx';

export class ObjectUsageDetailsPresenter implements Presenter {
    private _appraisal: Appraisal;
    private _question: Question;

    constructor(appraisal: Appraisal, question: Question) {
        makeObservable(this);
        this._appraisal = appraisal;
        this._question = question;
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    @computed
    public get isDisabled(): boolean {
        return true;
    }
}
