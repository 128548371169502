import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';

import {Answer} from '../../../models/answer';
import {AnswerController} from '../../answering/answer_controller';
import {ImageUploadState} from '../../../appraise/ui/content/questions/advanced/attachment_question_presenter';
import {NormalQuestionType} from '../../../enum/question_type';
import {Question} from '../../../models/question';
import {QuestionEffectInteractor} from '../../conditions/question_effects_interactor';
import {QuestionSet} from '../../../models/question_set';
import {QuestionValidator} from '../question_validator';
import {parseImageAnswerContents} from '../../support/parse_image_answer_contents';

export class AttachmentValidator implements QuestionValidator {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor
    ) {}

    public validate(questionUuid: string): ValidationMessage[] {
        const question = this.questionSet.findQuestionByUuid(questionUuid);
        if (question === undefined) {
            return [];
        }

        if (question.type !== NormalQuestionType.ATTACHMENT) {
            return this.questionSet
                .findChildQuestionsByParentUuid(questionUuid)
                .reduce((p: ValidationMessage[], c: Question) => {
                    return [...p, ...this.validate(c.uuid)];
                }, []);
        }

        const answers = this.answerController
            .filterDeleted(this.answerController.answersForQuestionUuid(question.uuid))
            .filter((answer) => {
                return !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.parentUuid);
            });

        const messages: ValidationMessage[] = [];
        this.validateNotFailed(question, answers).forEach((m) => {
            messages.push(m);
        });
        return messages;
    }

    private validateNotFailed(question: Question, answers: Answer[]): ValidationMessage[] {
        return answers
            .filter((a) => a.contents)
            .map((a) => ({answer: a, content: parseImageAnswerContents(a.contents as string)}))
            .filter(
                (a) =>
                    a.content.state === ImageUploadState.FAILED ||
                    a.content.state === ImageUploadState.VALIDATION_INSTITUTE_UPLOAD_FAILED
            )
            .map((a) => ({
                type: ValidationMessageType.TAXAPI,
                importance: ValidationMessageImportance.ERROR,
                question: question,
                answer: a.answer,
                messages: [],
                fallbackMessage: `Er is iets mis met het bestand "${
                    a.answer.file?.originalFilename ?? a.content.name
                }". Upload een nieuw bestand.`,
                floorInfo: null,
            }));
    }
}
