import {ReferenceObjectAnswer, referenceObjectAnswerFromSale} from '../models/reference_object_answer';
import {makeObservable, observable} from 'mobx';

import {Presenter} from '../../../../../../../../../support/presenter/presenter';
import {ReferenceObjectProvider} from '../../../../../../../../business/reference_object_provider';
import {ReferenceSale} from '../models/reference_sale';
import {SetType} from '../../../../../../../../models/reference_set/set_type';

export class ReferenceObjectTilePresenter implements Presenter {
    @observable public isAdding = false;

    private _referenceSale: ReferenceSale;
    private _onAdd: (referenceSale: ReferenceSale, referenceObjectAnswer: ReferenceObjectAnswer) => void;
    private _referenceObjectInteractor: ReferenceObjectProvider;

    constructor(
        referenceSale: ReferenceSale,
        onAdd: (referenceSale: ReferenceSale, referenceObjectAnswer: ReferenceObjectAnswer) => void,
        referenceObjectInteractor: ReferenceObjectProvider
    ) {
        makeObservable(this);
        this._referenceSale = referenceSale;
        this._onAdd = onAdd;
        this._referenceObjectInteractor = referenceObjectInteractor;
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public async onAdd() {
        try {
            this.isAdding = true;
            const sale = await this._referenceObjectInteractor.getSale(this._referenceSale.id, SetType.SOLD);
            this._onAdd(this._referenceSale, referenceObjectAnswerFromSale(sale));
        } finally {
            this.isAdding = false;
        }
    }
}
