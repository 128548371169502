interface FormatMoneyOptions {
    decimals: number | null;
    currencySymbol: string | null;
    decPoint: string;
    thousandsPoint: string;
}

/**
 * From https://stackoverflow.com/questions/12820312/equivalent-to-php-function-number-format-in-jquery-javascript
 * @param number
 * @param decimals
 * @param decPoint
 * @param thousandsPoint
 * @returns {string}
 */
export function formatMoney(number: number | null, options: Partial<FormatMoneyOptions> = {}): string {
    options = {
        decimals: null,
        currencySymbol: '€',
        decPoint: ',',
        thousandsPoint: '.',
        ...options,
    };

    if (number === null || !isFinite(number)) {
        throw new TypeError('number is not valid');
    }

    if (options.decimals === null) {
        const len = number.toString().split('.').length;
        options.decimals = len > 1 ? len : 0;
    }

    if (!options.decPoint) {
        options.decPoint = '.';
    }

    if (!options.thousandsPoint) {
        options.thousandsPoint = ',';
    }

    let numberStr = parseFloat(number + '').toFixed(options.decimals);

    numberStr = numberStr.replace('.', options.decPoint);

    const splitNum = numberStr.split(options.decPoint);
    splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, options.thousandsPoint);
    numberStr = splitNum.join(options.decPoint);

    if (options.currencySymbol === null) {
        return numberStr;
    } else {
        return `${options.currencySymbol} ${numberStr}`;
    }
}
