import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';
import {isEmpty, isNumeric} from '../../../../support/util';

import {Answer} from '../../../models/answer';
import {AnswerValidator} from '../answer_validator';
import {Question} from '../../../models/question';
import {ValidationError} from '../../../models/validation_error';
import {formatTviField} from '../../../appraise/ui/support/format_tvi_field';

export class ValidationInstituteValidation implements AnswerValidator {
    constructor(private validationErrors: ValidationError[]) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        const reportFieldReference = question.reportFieldReference;

        if (reportFieldReference === null) {
            return [];
        }

        return this.validationErrors
            .filter((validationError) => !isEmpty(validationError.field) && !isEmpty(validationError.message))
            .filter((validationError) =>
                this.validationErrorFieldIsEqualsToReportFieldReference(validationError.field, reportFieldReference)
            )
            .map((validationError) => {
                return {
                    type: ValidationMessageType.VALIDATION_INSTITUTE,
                    importance: ValidationMessageImportance.ERROR,
                    question: question,
                    answer: answer,
                    title: formatTviField(validationError.field),
                    messages: [validationError.message],
                    fallbackMessage: null,
                    floorInfo: null,
                };
            });
    }

    private validationErrorFieldIsEqualsToReportFieldReference(field: string, reportFieldReference: string) {
        const normalizedField = field
            .replace('>', '.')
            .split('.')
            .filter((part) => part !== 'taxatiegegevens')
            .map((part) => this.mapWeirdTviParts(part))
            .join('.');

        const normalizedReportFieldReference = reportFieldReference
            .replace('>', '.')
            .split('.')
            .filter((part) => !isNumeric(part))
            .join('.');

        return normalizedField === normalizedReportFieldReference;
    }

    private mapWeirdTviParts(part: string): string {
        const map: {[key: string]: string} = {
            overigeBijlagen: 'verplichteBijlagen',
        };

        return map[part] !== undefined ? map[part] : part;
    }
}
