import {Answer} from '../models/answer';
import {AnswerController} from './answering/answer_controller';
import {isNumeric} from '../../support/util';

export class FloorIterationProvider {
    constructor(private answerController: AnswerController) {}

    public findFloorNumber(answer: Answer | null): number | null {
        if (answer === null) {
            return null;
        }
        const result = this.findParent(answer);
        if (result && result.iteration) {
            return parseInt(result.iteration, 10);
        }
        return null;
    }

    public findFloorNumberAnswer(answer: Answer): Answer | null {
        return this.findParent(answer);
    }

    private findParent(answer: Answer): Answer | null {
        if (answer.parentUuid === null && isNumeric(answer.iteration)) {
            return answer;
        }

        if (answer.parentUuid !== null) {
            const parent = this.answerController.byUuid(answer.parentUuid);
            if (parent) {
                return this.findParent(parent);
            }
        }
        return null;
    }
}
