import * as React from 'react';

import {FunctionComponent} from 'react';
import {NullableNumberInput} from '../../../inputs/nullable_number';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';

interface OwnProps {
    referenceObjectAnswer: ReferenceObjectAnswer;
    onVerkoopprijsChange: (value: number | null) => void;
    onGecorrigeerdeVerkoopprijsChange: (value: number | null) => void;
    isFrozen: boolean;
}

export const SellPriceDetails: FunctionComponent<OwnProps> = ({
    referenceObjectAnswer,
    onVerkoopprijsChange,
    onGecorrigeerdeVerkoopprijsChange,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <span className="col-12">
                <h2>Verkoop details</h2>
            </span>
            <div className="col-12">
                <dl>
                    <span className="row">
                        <span className="col-sm-6">
                            <NullableNumberInput
                                isRequired
                                name="verkoopprijs"
                                label="Koopsom"
                                value={validationInstituteReferentieObject.verkoopprijs}
                                prepend="&euro;"
                                onChange={onVerkoopprijsChange}
                                disabled={isFrozen}
                            />
                        </span>
                        <span className="col-sm-6">
                            <NullableNumberInput
                                isRequired
                                name="gecorrigeerdeVerkoopprijs"
                                label="Gecorrigeerde koopsom"
                                value={validationInstituteReferentieObject.gecorrigeerdeVerkoopprijs}
                                prepend="&euro;"
                                onChange={onGecorrigeerdeVerkoopprijsChange}
                                disabled={isFrozen}
                            />
                        </span>
                    </span>
                </dl>
            </div>
        </div>
    );
};
