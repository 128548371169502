import * as React from 'react';

import {Appraisal} from '../../../../../../../../models/appraisal';
import {AppraiseModel, isAppraiseModel} from '../../../../../../../../enum/appraise_model';
import {AreaConclusion} from '../../../../../../../../models/area_conclusion';
import {BuildyearBadge} from '../badges/buildyear_badge_adapter';
import {Checkbox} from '../inputs/checkbox';
import {DateInput} from '../inputs/date';
import {EditableAddress} from '../editable_address';
import {EnergyConclusion} from '../../../../../../../../models/energy_conclusion';
import {ExplanationAppraiser} from '../components/explanation_appraiser';
import {FloorAreaBadgeAdapter} from '../badges/floor_area_badge_adapter';
import {FloorAreaPrice} from '../components/floor_area_price';
import {Modal} from '../../../../components/modal';
import {NullableNumberInput} from '../inputs/nullable_number';
import {ObjectTypeSelect} from '../../../../components/object_type_select';
import {PlotAreaBadgeAdapter} from '../badges/plot_area_badge_adapter';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {ReferenceObjectAnswerImageUpload} from '../inputs/reference_object_answer_image_upload';
import {ReferenceObjectAnswerModalPresenter} from '../modals/reference_object_answer_modal_presenter';
import {ReferenceObjectType} from '../../../../../../../../enum/reference_object_type';
import {ReferenceSale} from '../models/reference_sale';
import {Select} from '../inputs/select';
import {Textarea} from '../inputs/textarea';
import {VolumePrice} from '../components/volume_price';
import {isApartment} from '../../../../../../../../business/support/is_apartment_check';
import {isEmpty} from '../../../../../../../../../support/util';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../../../support/presenter/use_presenter';

interface OwnProps {
    questionSet: QuestionSet;
    appraisal: Appraisal;
    referenceObjectAnswer: ReferenceObjectAnswer;
    areaConclusion: AreaConclusion | null;
    energyConclusion: EnergyConclusion | null;
    valuation: string | null;
    referenceSale: ReferenceSale;
    onChange: (referenceObjectAnswer: ReferenceObjectAnswer) => void;
    visible: boolean;
    isFrozen: boolean;
    onHide: () => void;
    referenceObjectType: ReferenceObjectType;
    children?: React.ReactNode;
}

export const ReferenceObjectInRentAnswerModal: React.FC<OwnProps> = observer(function ReferenceObjectInRentAnswerModal(
    props
) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectAnswerModalPresenter(
                props.referenceObjectAnswer,
                props.onChange,
                props.onHide,
                props.questionSet,
                props.appraisal,
                props.referenceObjectType,
                props.referenceSale,
                container.business.answerController(props.appraisal, props.questionSet)
            )
    );
    const referenceObjectAnswer = presenter.referenceObjectAnswer;
    const referenceObject = referenceObjectAnswer.referenceObject;
    const validatesOwnershipMessage = presenter.validatesOwnershipMessage(referenceObjectAnswer);

    return (
        <Modal key={referenceObjectAnswer.id} forced={true} onHide={() => props.onHide()} visible={props.visible}>
            <div className="modal-header">
                <h2>Nieuw referentieobject</h2>
            </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-sm-12">
                        <ReferenceObjectAnswerImageUpload
                            key={JSON.stringify(presenter.imageUrlPairs)}
                            appraisal={props.appraisal}
                            imageUrlPairs={presenter.imageUrlPairs}
                            referenceObjectAnswerPhotoUrl={referenceObjectAnswer.photoUrl}
                            isOwnUpload={referenceObjectAnswer.isOwnUpload}
                            onChange={presenter.onPhotoChange}
                            disabled={presenter.isFrozen}
                            referenceObjectType={props.referenceObjectType}
                            questionSet={props.questionSet}
                        />
                    </div>
                    <div className="col-12">
                        <h2>Object details</h2>
                    </div>
                    <div className="col-sm-6">
                        <EditableAddress
                            address={referenceObject.adres}
                            onChange={presenter.getMemoizedChangeHandler('adres')}
                        />
                        {props.referenceSale.hasWarning ? (
                            <span className="detail-group-alert">
                                <span className="ion-md-alert">&nbsp;</span>
                                {props.referenceSale.warning}
                            </span>
                        ) : null}
                    </div>
                    <div className="col-sm-6">
                        <dl>
                            <dt>Prijs per m²</dt>
                            <dd>
                                <FloorAreaPrice
                                    valuation={props.valuation}
                                    floorArea={referenceObject.gebruiksOppervlakte}
                                    price={
                                        referenceObject.gecorrigeerdeVerkoopprijs?.toString(10) ??
                                        referenceObject.verkoopprijs?.toString(10) ??
                                        null
                                    }
                                    areaConclusion={props.areaConclusion}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                />
                            </dd>
                        </dl>
                        <dl>
                            <dt>Prijs per m³</dt>
                            <dd>
                                <VolumePrice
                                    valuation={props.valuation}
                                    volume={referenceObject.inhoud}
                                    price={
                                        referenceObject.gecorrigeerdeVerkoopprijs?.toString(10) ??
                                        referenceObject.verkoopprijs?.toString(10) ??
                                        null
                                    }
                                    areaConclusion={props.areaConclusion}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                />
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <dl>
                            <NullableNumberInput
                                isRequired
                                name="bouwjaar"
                                label="Bouwjaar"
                                badge={
                                    referenceObject.bouwjaar !== null ? (
                                        <BuildyearBadge
                                            buildYear={referenceObject.bouwjaar}
                                            appraisal={props.appraisal}
                                            questionSet={props.questionSet}
                                        />
                                    ) : null
                                }
                                value={referenceObject.bouwjaar}
                                onChange={presenter.getMemoizedChangeHandler('bouwjaar')}
                                disabled={presenter.isFrozen}
                            />
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <dl>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Select
                                        isRequired
                                        name="energielabel"
                                        label="Energielabel"
                                        options={{
                                            A: 'A',
                                            B: 'B',
                                            C: 'C',
                                            D: 'D',
                                            E: 'E',
                                            F: 'F',
                                            G: 'G',
                                            Unknown: 'Geen energielabel',
                                        }}
                                        badge={
                                            props.energyConclusion !== null && props.energyConclusion.label !== null ? (
                                                <>{'(' + props.energyConclusion.label + ')'}</>
                                            ) : null
                                        }
                                        emptyPlaceholder="Selecteer energielabel"
                                        value={
                                            !isEmpty(referenceObject.energielabel) &&
                                            referenceObject.energielabel !== '-'
                                                ? referenceObject.energielabel
                                                : 'Geen energielabel'
                                        }
                                        onChange={presenter.getMemoizedChangeHandler('energielabel')}
                                        disabled={presenter.isFrozen}
                                    />
                                </div>
                                {/* This field is only required in model 2021 */}
                                {isAppraiseModel(props.appraisal, AppraiseModel.MODEL2021) &&
                                    referenceObject.energielabel !== 'Geen energielabel' && (
                                        <div className="col-sm-6">
                                            <DateInput
                                                isRequired
                                                name="energielabelGeldigTot"
                                                label="Energielabel geldig tot"
                                                value={referenceObject.energielabelGeldigTot ?? ''}
                                                onChange={presenter.getMemoizedChangeHandler('energielabelGeldigTot')}
                                                disabled={presenter.isFrozen}
                                            />
                                        </div>
                                    )}
                            </div>
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <dl>
                            <dt>
                                Woningtype
                                <span className="form-label-highlight">*</span>
                            </dt>
                            <dl>
                                <ObjectTypeSelect
                                    value={referenceObject.woningType}
                                    onChange={presenter.getMemoizedChangeHandler('woningType')}
                                    disabled={presenter.isFrozen}
                                />
                            </dl>
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <dl>
                            <Select
                                isRequired
                                name="bron-gegevens"
                                label="Bron gegevens"
                                options={{
                                    Ortec: 'Ortec',
                                    'Archief taxateur': 'Archief taxateur',
                                    'Archief NVM': 'Archief NVM',
                                    'Archief VastgoedPRO': 'Archief VastgoedPRO',
                                    'Archief VBO': 'Archief VBO',
                                    'Archief branchevereniging': 'Archief branchevereniging',
                                }}
                                otherText="Anders, namelijk:"
                                emptyPlaceholder="Selecteer bron gegevens"
                                value={referenceObject.bronGegevens}
                                onChange={presenter.getMemoizedChangeHandler('bronGegevens')}
                                disabled={presenter.isFrozen}
                            />
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <dl>
                            <span className="row">
                                <span className="col-sm-6">
                                    <NullableNumberInput
                                        isRequired
                                        name="gebruiksoppervlakte-wonen"
                                        label="Gebruiksoppervlakte wonen (m²)"
                                        badge={
                                            props.areaConclusion !== null &&
                                            referenceObject.gebruiksOppervlakte !== null ? (
                                                <FloorAreaBadgeAdapter
                                                    floorArea={referenceObject.gebruiksOppervlakte}
                                                    areaConclusion={props.areaConclusion}
                                                    appraisal={props.appraisal}
                                                    questionSet={props.questionSet}
                                                />
                                            ) : null
                                        }
                                        value={referenceObject.gebruiksOppervlakte}
                                        onChange={presenter.getMemoizedChangeHandler('gebruiksOppervlakte')}
                                        disabled={presenter.isFrozen}
                                    />
                                </span>
                            </span>
                        </dl>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <dl>
                            <span className="row">
                                <span className="col-sm-6">
                                    <NullableNumberInput
                                        isRequired={!isApartment(referenceObject.woningType)}
                                        name="perceeloppervlakte"
                                        label="Perceeloppervlakte (m²)"
                                        value={referenceObject.perceelOppervlakte}
                                        badge={
                                            props.areaConclusion !== null &&
                                            referenceObject.perceelOppervlakte !== null ? (
                                                <PlotAreaBadgeAdapter
                                                    plotArea={referenceObject.perceelOppervlakte}
                                                    areaConclusion={props.areaConclusion}
                                                    appraisal={props.appraisal}
                                                    questionSet={props.questionSet}
                                                />
                                            ) : null
                                        }
                                        onChange={presenter.getMemoizedChangeHandler('perceelOppervlakte')}
                                        disabled={presenter.isFrozen}
                                    />
                                </span>
                            </span>
                        </dl>
                    </div>
                </div>

                <div className="row">
                    <span className="col-12">
                        <h2>Huur details</h2>
                    </span>
                    <div className="col-12">
                        <dl>
                            <span className="row">
                                <span className="col-sm-6">
                                    <NullableNumberInput
                                        isRequired
                                        name="aanvangsHuurprijsPerMaand"
                                        label="Huurprijs / maand"
                                        value={referenceObject.aanvangsHuurprijsPerMaand ?? 0}
                                        prepend="&euro;"
                                        onChange={presenter.getMemoizedChangeHandler('aanvangsHuurprijsPerMaand')}
                                        disabled={presenter.isFrozen}
                                    />
                                </span>
                                <span className="col-sm-6">
                                    <NullableNumberInput
                                        isRequired
                                        name="gecorrigeerdeHuurprijsPerMaand"
                                        label="Gecorr. huurprijs / maand"
                                        value={referenceObject.gecorrigeerdeHuurprijsPerMaand ?? 0}
                                        prepend="&euro;"
                                        onChange={presenter.getMemoizedChangeHandler('gecorrigeerdeHuurprijsPerMaand')}
                                        disabled={presenter.isFrozen}
                                    />
                                </span>
                            </span>
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <dl>
                            <span className="row">
                                <span className="col-sm-6">
                                    <DateInput
                                        isRequired
                                        name="ingangsdatumHuur"
                                        label="Ingangsdatum huur"
                                        value={referenceObject.ingangsdatumHuur ?? null}
                                        onChange={presenter.getMemoizedChangeHandler('ingangsdatumHuur')}
                                        disabled={presenter.isFrozen}
                                    />
                                </span>
                            </span>
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>Eigendomssituatie</h3>
                    </div>
                    <div className="col-6">
                        <Checkbox
                            isRequired
                            label="Volle eigendom"
                            name="input-ownership-complete"
                            value={referenceObjectAnswer.referenceObject.eigendomssituatieVolleEigendom ?? true}
                            onChange={presenter.getMemoizedChangeHandler('eigendomssituatieVolleEigendom')}
                            disabled={presenter.isFrozen}
                        />
                    </div>
                    <div className="col-6">
                        <span
                            className="btn btn-primary btn-sm ion-md-checkbox-outline pull-right"
                            onClick={() => presenter.toggleOtherPropertySituation()}
                        >
                            {presenter.hasOtherPropertySituation ? 'Verberg meer opties' : 'Bekijk meer opties'}
                        </span>
                    </div>
                    {presenter.hasOtherPropertySituation || presenter.hasOtherRights(referenceObjectAnswer) ? (
                        <>
                            <div className="col-sm-6">
                                <Checkbox
                                    label="Recht van erfpacht"
                                    name="input-ownership-leasehold"
                                    value={referenceObjectAnswer.referenceObject.eigendomssituatieRechtErfpacht}
                                    onChange={presenter.getMemoizedChangeHandler('eigendomssituatieRechtErfpacht')}
                                    disabled={presenter.isFrozen}
                                />
                                <Checkbox
                                    label="Recht van ondererfpacht"
                                    name="input-ownership-under-leasehold"
                                    value={referenceObjectAnswer.referenceObject.eigendomssituatieRechtOndererfpacht}
                                    onChange={presenter.getMemoizedChangeHandler('eigendomssituatieRechtOndererfpacht')}
                                    disabled={presenter.isFrozen}
                                />
                                <Checkbox
                                    label="Recht van opstal"
                                    name="input-ownership-building"
                                    value={referenceObjectAnswer.referenceObject.eigendomssituatieRechtOpstal}
                                    onChange={presenter.getMemoizedChangeHandler('eigendomssituatieRechtOpstal')}
                                    disabled={presenter.isFrozen}
                                />
                            </div>
                            <div className="col-sm-6">
                                <Checkbox
                                    label="Recht gebruik woning"
                                    name="input-ownership-use"
                                    value={referenceObjectAnswer.referenceObject.eigendomssituatieRechtGebruikBewoning}
                                    onChange={presenter.getMemoizedChangeHandler(
                                        'eigendomssituatieRechtGebruikBewoning'
                                    )}
                                    disabled={presenter.isFrozen}
                                />
                                <Checkbox
                                    label="Recht van vruchtgebruik"
                                    name="input-ownership-usufruct"
                                    value={referenceObjectAnswer.referenceObject.eigendomssituatieRechtVruchtgebruik}
                                    onChange={presenter.getMemoizedChangeHandler('eigendomssituatieRechtVruchtgebruik')}
                                    disabled={presenter.isFrozen}
                                />
                                <Checkbox
                                    label="Ander recht"
                                    name="input-ownership-other"
                                    value={referenceObjectAnswer.referenceObject.eigendomssituatieRechtAnders}
                                    onChange={(value) => {
                                        presenter.getMemoizedChangeHandler('eigendomssituatieRechtAnders')(value);
                                        if (value !== true) {
                                            presenter.getMemoizedChangeHandler('eigendomssituatieToelichting')('');
                                        }
                                    }}
                                    disabled={presenter.isFrozen}
                                />
                            </div>
                            {referenceObjectAnswer.referenceObject.eigendomssituatieRechtAnders && (
                                <div className="col-12">
                                    <Textarea
                                        label="Toelichting ander recht"
                                        name="input-ownership-explanation"
                                        value={referenceObjectAnswer.referenceObject.eigendomssituatieToelichting ?? ''}
                                        onChange={presenter.getMemoizedChangeHandler('eigendomssituatieToelichting')}
                                        disabled={presenter.isFrozen}
                                    />
                                </div>
                            )}
                        </>
                    ) : null}
                    {!isEmpty(validatesOwnershipMessage) ? (
                        <div className="col-12">
                            <span className="invalid-feedback">{validatesOwnershipMessage}</span>
                        </div>
                    ) : null}
                    <div className="col-12">
                        <Select
                            label="Eigendomsituatie verschil"
                            name="input-ownership"
                            options={['Veel beter', 'Beter', 'Vergelijkbaar', 'Minder', 'Veel minder']}
                            value={referenceObjectAnswer.referenceObject.eigendomssituatieStatus ?? ''}
                            onChange={presenter.getMemoizedChangeHandler('eigendomssituatieStatus')}
                            disabled={presenter.isFrozen}
                        />
                        {referenceObjectAnswer.referenceObject.eigendomssituatieStatus &&
                        referenceObjectAnswer.referenceObject.eigendomssituatieStatus !== 'Vergelijkbaar' ? (
                            <div className="row">
                                <div className="col-sm-12">
                                    <Textarea
                                        isRequired
                                        label="Eigendomsituatie verschil toelichting"
                                        name="input-object-type-explanation"
                                        value={referenceObjectAnswer.referenceObject.eigendomssituatieUitleg ?? ''}
                                        onChange={presenter.getMemoizedChangeHandler('eigendomssituatieUitleg')}
                                        disabled={presenter.isFrozen}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                <Select
                    label="Onderhoudssituatie"
                    name="input-maintenance"
                    options={['Goed', 'Redelijk', 'Matig', 'Slecht']}
                    value={referenceObjectAnswer.referenceObject.onderhoudssituatie ?? 'Goed'}
                    onChange={presenter.getMemoizedChangeHandler('onderhoudssituatie')}
                    disabled={presenter.isFrozen}
                />

                <Select
                    label="Onderhoudssituatie verschil"
                    name="input-maintenance-situtation"
                    options={['Veel beter', 'Beter', 'Vergelijkbaar', 'Minder', 'Veel minder']}
                    value={referenceObjectAnswer.referenceObject.onderhoudsSituatieStatus}
                    onChange={presenter.getMemoizedChangeHandler('onderhoudsSituatieStatus')}
                    disabled={presenter.isFrozen}
                />
                {referenceObjectAnswer.referenceObject.onderhoudsSituatieStatus !== 'Vergelijkbaar' ? (
                    <div className="row">
                        <div className="col-sm-12">
                            <Textarea
                                isRequired
                                label="Onderhoudssituatie verschil toelichting"
                                name="input-maintenance-situtation-explanation"
                                value={referenceObjectAnswer.referenceObject.onderhoudsSituatieUitleg ?? ''}
                                onChange={presenter.getMemoizedChangeHandler('onderhoudsSituatieUitleg')}
                                macros={[
                                    'Het onderhoud van het referentieobject is in zijn algemeenheid beter',
                                    'Het onderhoud van het referentieobject is in zijn algemeenheid slechter',
                                ]}
                                disabled={presenter.isFrozen}
                            />
                        </div>
                    </div>
                ) : null}

                <Select
                    label="Woningtype verschil"
                    name="input-object-type"
                    options={['Veel beter', 'Beter', 'Vergelijkbaar', 'Minder', 'Veel minder']}
                    value={referenceObjectAnswer.referenceObject.woningTypeStatus}
                    onChange={presenter.getMemoizedChangeHandler('woningTypeStatus')}
                    disabled={presenter.isFrozen}
                />
                {referenceObjectAnswer.referenceObject.woningTypeStatus !== 'Vergelijkbaar' ? (
                    <div className="row">
                        <div className="col-sm-12">
                            <Textarea
                                isRequired
                                label="Woningtype verschil toelichting"
                                name="input-object-type-explanation"
                                value={referenceObjectAnswer.referenceObject.woningTypeUitleg}
                                onChange={presenter.getMemoizedChangeHandler('woningTypeUitleg')}
                                disabled={presenter.isFrozen}
                            />
                        </div>
                    </div>
                ) : null}
                <Select
                    label="Mate van luxe verschil"
                    name="input-luxury"
                    options={['Veel beter', 'Beter', 'Vergelijkbaar', 'Minder', 'Veel minder']}
                    value={referenceObjectAnswer.referenceObject.mateVanLuxeStatus ?? ''}
                    onChange={presenter.getMemoizedChangeHandler('mateVanLuxeStatus')}
                    disabled={presenter.isFrozen}
                />
                {referenceObjectAnswer.referenceObject.mateVanLuxeStatus !== 'Vergelijkbaar' ? (
                    <div className="row">
                        <div className="col-sm-12">
                            <Textarea
                                isRequired
                                label="Mate van luxe verschil toelichting"
                                name="input-luxury-explanation"
                                value={referenceObjectAnswer.referenceObject.mateVanLuxeUitleg ?? ''}
                                onChange={presenter.getMemoizedChangeHandler('mateVanLuxeUitleg')}
                                macros={[
                                    'Het referentieobject is minder luxe afgewerkt',
                                    'Het referentieobject is minder hoogwaardig afgewerkt',
                                    'Het referentieobject is eenvoudiger afgewerkt',
                                    'Het referentieobject is meer luxe afgewerkt',
                                    'Het referentieobject is hoogwaardiger afgewerkt',
                                ]}
                                disabled={presenter.isFrozen}
                            />
                        </div>
                    </div>
                ) : null}
                <Select
                    label="Mate van doelmatigheid verschil"
                    name="input-efficiency"
                    options={['Veel beter', 'Beter', 'Vergelijkbaar', 'Minder', 'Veel minder']}
                    value={referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus ?? ''}
                    onChange={presenter.getMemoizedChangeHandler('mateVanDoelmatigheidStatus')}
                    disabled={presenter.isFrozen}
                />
                {referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus !== 'Vergelijkbaar' ? (
                    <div className="row">
                        <div className="col-sm-12">
                            <Textarea
                                isRequired
                                label="Mate van doelmatigheid verschil toelichting"
                                name="input-efficiency-explanation"
                                value={referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg ?? ''}
                                onChange={presenter.getMemoizedChangeHandler('mateVanDoelmatigheidUitleg')}
                                macros={[
                                    'Het referentieobject is minder courant afgewerkt',
                                    'Het referentieobject is minder modern en courant afgewerkt',
                                    'Het referentieobject is meer gedateerd',
                                    'Het referentieobject is courant afgewerkt',
                                    'Het referentieobject is moderner en couranter afgewerkt',
                                    'Het referentieobject is minder gedateerd afgewerkt',
                                ]}
                                disabled={presenter.isFrozen}
                            />
                        </div>
                    </div>
                ) : null}

                <Select
                    label="Locatie verschil"
                    name="input-location"
                    options={['Veel beter', 'Beter', 'Vergelijkbaar', 'Minder', 'Veel minder']}
                    value={referenceObjectAnswer.referenceObject.liggingStatus}
                    onChange={presenter.getMemoizedChangeHandler('liggingStatus')}
                    disabled={presenter.isFrozen}
                />
                {referenceObjectAnswer.referenceObject.liggingStatus !== 'Vergelijkbaar' ? (
                    <div className="row">
                        <div className="col-sm-12">
                            <Textarea
                                isRequired
                                label="Locatie verschil toelichting"
                                name="input-location-explanation"
                                value={referenceObjectAnswer.referenceObject.liggingUitleg}
                                onChange={presenter.getMemoizedChangeHandler('liggingUitleg')}
                                macros={[
                                    'De locatie is in zijn algemeenheid beter',
                                    'De locatie is in zijn algemeenheid slechter',
                                ]}
                                disabled={presenter.isFrozen}
                            />
                        </div>
                    </div>
                ) : null}

                <div className="row">
                    <div className="col-sm-12">
                        <Textarea
                            isRequired
                            label="Bij-, op en/of aanbouwen"
                            name="input-aanbouw"
                            value={referenceObjectAnswer.referenceObject.aanbouw}
                            macros={[
                                'Aanbouw woonruimte',
                                'Berging / schuur (aangeb.)',
                                'Berging / schuur (vrijst.)',
                                'Balkon',
                                'Carport',
                                'Dakkapel',
                                'Dakterras',
                                'Garage (dubbel)',
                                'Garage (enkel)',
                                'Garage (enkel met kap)',
                                'Overkapping / luifel',
                                'Kelder / souterrain',
                                'Parkeerplaats',
                                'Serre',
                                'Tuinhuis',
                            ]}
                            onChange={presenter.getMemoizedChangeHandler('aanbouw')}
                            disabled={presenter.isFrozen}
                        />
                    </div>
                </div>

                <Select
                    label="Bij-, op en/of aanbouwen verschil"
                    name="input-extra-buildings"
                    options={['Veel beter', 'Beter', 'Vergelijkbaar', 'Minder', 'Veel minder']}
                    value={referenceObjectAnswer.referenceObject.aanbouwStatus}
                    onChange={presenter.getMemoizedChangeHandler('aanbouwStatus')}
                    disabled={presenter.isFrozen}
                />
                {referenceObjectAnswer.referenceObject.aanbouwStatus !== 'Vergelijkbaar' ? (
                    <div className="row">
                        <div className="col-sm-12">
                            <Textarea
                                isRequired
                                label="Bij-, op en/of aanbouwen verschil toelichting"
                                name="input-extra-buildings-explanation"
                                value={referenceObjectAnswer.referenceObject.aanbouwUitleg}
                                onChange={presenter.getMemoizedChangeHandler('aanbouwUitleg')}
                                disabled={presenter.isFrozen}
                            />
                        </div>
                    </div>
                ) : null}

                <div className="row">
                    <div className="col-sm-12">
                        <label>Andere wezenlijke verschillen</label>
                        <Checkbox
                            label="Er zijn andere wezenlijke verschillen"
                            name="input-other-differences"
                            value={referenceObjectAnswer.referenceObject.andereWezenlijkeVerschillen ?? false}
                            onChange={presenter.getMemoizedChangeHandler('andereWezenlijkeVerschillen')}
                            disabled={presenter.isFrozen}
                        />
                    </div>
                    {referenceObjectAnswer.referenceObject.andereWezenlijkeVerschillen ? (
                        <div className="col-sm-12">
                            <Textarea
                                isRequired
                                label="Andere wezenlijke verschillen toelichting"
                                name="input-other-differences-explanation"
                                value={referenceObjectAnswer.referenceObject.andereWezenlijkeVerschillenUitleg ?? ''}
                                onChange={presenter.getMemoizedChangeHandler('andereWezenlijkeVerschillenUitleg')}
                                disabled={presenter.isFrozen}
                            />
                        </div>
                    ) : null}
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <ExplanationAppraiser
                            value={referenceObjectAnswer.referenceObject.toelichtingGebruikReferentieObject}
                            onChange={presenter.getMemoizedChangeHandler('toelichtingGebruikReferentieObject')}
                            disabled={presenter.isFrozen}
                            areaConclusion={props.areaConclusion}
                            referenceObjectAnswer={referenceObjectAnswer}
                            appraisal={props.appraisal}
                            questionSet={props.questionSet}
                            macros={[
                                'Vergelijkbaar, zie alle overeenkomstige kenmerken.',
                                'Vergelijkbaar op basis van woningtype, ligging, bouwperiode, gebruiksoppervlakte, perceeloppervlakte en afwerking.',
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                {props.children}
                <button
                    className="btn btn-primary btn-full ion-md-close"
                    type="button"
                    disabled={!presenter.canCloseModal(referenceObjectAnswer)}
                    onClick={() => presenter.closeModal()}
                >
                    Opslaan & sluiten
                </button>
            </div>
        </Modal>
    );
});
