import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from './simple_question_presenter';

import {AlternativeAdaptedDefaultAnswer} from '../components/alternative_adapted_default_answer';
import {Label} from '../components/label';
import {ValidationMessages} from '../components/validation_messages';
import {observer} from 'mobx-react';
import {ConceptReportReplacementQuestionPresenter} from './concept_report_replacement_presenter';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const ConceptReportReplacement: React.FC<OwnProps> = observer(function ConceptReportReplacement(props) {
    const presenter = usePresenter(
        (container) =>
            new ConceptReportReplacementQuestionPresenter(
                container.business.conceptReportProvider(container.network.taskHelper, container.network.appraisalApi),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );

    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);
    const adjustTextareaHeight = React.useCallback(() => {
        if (textareaRef.current) {
            const textarea = textareaRef.current;
            textarea.style.height = 'auto';

            const tempTextarea = document.createElement('textarea');
            tempTextarea.style.position = 'absolute';
            tempTextarea.style.top = '-9999px';
            tempTextarea.style.left = '-9999px';
            tempTextarea.style.width = `${textarea.clientWidth}px`;
            tempTextarea.value = textarea.value;
            document.body.appendChild(tempTextarea);

            const adjustedHeight = tempTextarea.scrollHeight;
            textarea.style.height = adjustedHeight + 'px';

            document.body.removeChild(tempTextarea);
        }
    }, [textareaRef]);

    React.useEffect(() => {
        adjustTextareaHeight();
    }, [adjustTextareaHeight]);

    if (presenter.isHidden) {
        return null;
    }

    return (
        <div className="form-group">
            <Label
                for={presenter.name}
                question={props.question}
                parentAnswerUuid={props.parentAnswerUuid}
                iteration={props.iteration}
                appraisal={props.appraisal}
                questionSet={props.questionSet}
                disabled={props.disabled || presenter.isDisabled}
                hideLabel={props.hideLabel}
                showLabel={props.question.showLabel}
                renderingContext={props.renderingContext}
            />
            <AlternativeAdaptedDefaultAnswer
                adaptedDefaultValue={props.adaptedDefaultValue}
                currentAnswer={presenter.answer}
                disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                onChange={(value) => presenter.onChange(value)}
            />
            <textarea
                id={props.question.uuid}
                name={props.question.uuid}
                className="form-control"
                ref={textareaRef}
                readOnly={props.disabled || presenter.isDisabled}
                value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                onChange={(e) => {
                    adjustTextareaHeight();
                    presenter.onChange(e.target.value, false);
                }}
                onBlur={() => presenter.onTouched()}
                maxLength={props.question.maxCharacters}
                minLength={props.question.minCharacters}
                required={props.question.minCharacters > 0}
            />
            <ValidationMessages
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={presenter.answer}
                validationMessages={filterValidationMessages(
                    props.question,
                    presenter.answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
            {props.question.reportSelector !== null && (
                <div>
                    <button
                        className="btn btn-secondary btn-sm ion-md-refresh mt-3"
                        onClick={() => presenter.regenerate()}
                    >
                        Opnieuw genereren
                    </button>
                </div>
            )}
        </div>
    );
});
