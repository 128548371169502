import {IteratorQuestionType, NormalQuestionType} from '../../enum/question_type';
import {PagePart} from '../../models/page_part';
import {PagePartConfiguration} from '../../models/page_part_configuration';
import {QuestionSet} from '../../models/question_set';

export class PagePartsCleaner {
    constructor(private questionSet: QuestionSet) {}

    public cleanConfig(config: PagePartConfiguration): PagePartConfiguration {
        return {
            ...config,
            pageParts: this.cleanPageParts(config.pageParts),
        };
    }

    public cleanConfigs(configs: PagePartConfiguration[]): PagePartConfiguration[] {
        return configs.map((config) => this.cleanConfig(config));
    }

    public cleanPageParts(pageParts: PagePart[]): PagePart[] {
        const pagePartQuestions = [
            ...this.questionSet.findQuestionsByType(NormalQuestionType.PAGE_PART_GROUP),
            ...this.questionSet.findQuestionsByType(IteratorQuestionType.PAGE_PART_ITERATOR),
        ];

        const cleanedPageParts = pageParts.map((pagePart) => {
            if (pagePart.questionUuid === null) {
                return pagePart;
            }
            const hasCorrectQuestion = pagePartQuestions.some((ppq) => ppq.uuid === pagePart.questionUuid);
            if (hasCorrectQuestion) {
                return pagePart;
            } else {
                if (process.env.NODE_ENV === 'development') {
                    console.warn(
                        `PagePart "${pagePart.name}" has a non PagePart question assigned! Setting questionUuid to null`
                    );
                }
                return {
                    ...pagePart,
                    questionUuid: null,
                };
            }
        });

        // All navbar items with large bullet
        const rootPageParts = cleanedPageParts.filter((pp) => pp.parentUuid === null && !pp.isIndexPage);
        // All navbar items with large bullet, having a list with subpages
        const indexPageParts = cleanedPageParts.filter((pp) => pp.parentUuid === null && pp.isIndexPage);
        // All subpages from an index page
        const indexSubPageParts = cleanedPageParts.filter((pp) => {
            return pp.parentUuid && indexPageParts.some((indexPage) => indexPage.uuid === pp.parentUuid);
        });
        // All page parts on the content page
        const contentPageParts = cleanedPageParts.filter((pp) => {
            return (
                pp.parentUuid &&
                (rootPageParts.some((rootPage) => rootPage.uuid === pp.parentUuid) ||
                    indexSubPageParts.some((indexSubPage) => indexSubPage.uuid === pp.parentUuid))
            );
        });

        const withoutFloatingPageParts = cleanedPageParts.filter((pp) => {
            const isRootPagePart = rootPageParts.some((rootPagePart) => rootPagePart.uuid === pp.uuid);
            const isIndexPagePart = indexPageParts.some((indexPagePart) => indexPagePart.uuid === pp.uuid);
            const isIndexSubPagePart = indexSubPageParts.some((subPagePart) => subPagePart.uuid === pp.uuid);
            const isContentPagePart = contentPageParts.some((contentPagePart) => contentPagePart.uuid === pp.uuid);

            // All page parts including one of these conditions are okay
            if (isRootPagePart || isIndexPagePart || isIndexSubPagePart || isContentPagePart) {
                return true;
            }
            if (process.env.NODE_ENV === 'development') {
                console.warn(`"${pp.name}" is floating! Removed!`);
            }
            // All page parts not in the four conditions are flowing, and will be removed.
            return false;
        });

        if (process.env.NODE_ENV === 'development') {
            if (pageParts.length !== withoutFloatingPageParts.length) {
                console.warn(`Removed ${pageParts.length - withoutFloatingPageParts.length} floating PageParts!`);
            }
        }

        return withoutFloatingPageParts;
    }
}
