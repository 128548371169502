import {action, computed, makeObservable, observable} from 'mobx';
import {isEmpty, isNumeric} from '../../../../../../../../../support/util';
import {referenceSaleImageUrlPairs, referenceSaleInSaleImageUrlPairs} from '../internal/reference_sale_image_urls';

import {AnswerController} from '../../../../../../../../business/answering/answer_controller';
import {Appraisal} from '../../../../../../../../models/appraisal';
import {AppraisalState} from '../../../../../../../../enum/appraisal_state';
import {AppraisalValidationType} from '../../../../../../../../enum/appraisal_validation_type';
import {AppraiseModel, isAppraiseModelOrNewer} from '../../../../../../../../enum/appraise_model';
import {CompositeSubscription} from '../../../../../../../../../support/composite_subscription';
import {ImageUrlPair} from '../../../../../../../../models/image_url_pair';
import {Presenter} from '../../../../../../../../../support/presenter/presenter';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {ReferenceObjectType} from '../../../../../../../../enum/reference_object_type';
import {ReferenceSale} from '../models/reference_sale';
import {TechnicalReference} from '../../../../../../../../enum/technical_reference';
import {ValidationInstituteReferentieObject} from '../../../../../../../../models/validation_institute_reference_object';
import {isApartment} from '../../../../../../../../business/support/is_apartment_check';
import {map} from 'rxjs/operators';

type ChangeHandler<TKey extends keyof ValidationInstituteReferentieObject> = (
    value: ValidationInstituteReferentieObject[TKey]
) => void;
type ChangeHandlerMap<TKey extends keyof ValidationInstituteReferentieObject> = Partial<
    Record<TKey, ChangeHandler<TKey>>
>;

export class ReferenceObjectAnswerModalPresenter implements Presenter {
    @observable public buildYear: number | null = null;
    @observable public hasOtherPropertySituation = false;
    @observable.ref public referenceObjectAnswer: ReferenceObjectAnswer;

    @computed
    public get isFrozen(): boolean {
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return false;
        }

        return (
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    @observable
    public imageUrlPairs: ImageUrlPair[];

    private subscriptions = new CompositeSubscription();
    constructor(
        initialReferenceObjectAnswer: ReferenceObjectAnswer,
        private onChange: (referenceObjectAnswer: ReferenceObjectAnswer) => void,
        private onHide: () => void,
        private questionSet: QuestionSet,
        private appraisal: Appraisal,
        private referenceObjectType: ReferenceObjectType,
        private referenceSale: ReferenceSale,
        private answerController: AnswerController
    ) {
        makeObservable(this);
        this.referenceObjectAnswer = initialReferenceObjectAnswer;

        let imageUrlPairs = [];
        if (this.referenceObjectType === ReferenceObjectType.SALE) {
            imageUrlPairs = referenceSaleInSaleImageUrlPairs(this.referenceSale, this.referenceObjectAnswer);
        } else {
            imageUrlPairs = referenceSaleImageUrlPairs(this.referenceSale, this.referenceObjectAnswer);
        }

        if (imageUrlPairs.length === 0) {
            if (
                this.referenceObjectType === ReferenceObjectType.SALE ||
                this.referenceObjectType === ReferenceObjectType.RENT
            ) {
                imageUrlPairs = [
                    {
                        path: null,
                        url:
                            '/reference-objects/address/' +
                            this.referenceObjectAnswer.referenceObject.adres.straat +
                            '/' +
                            this.referenceObjectAnswer.referenceObject.adres.huisnummer +
                            '/' +
                            this.referenceObjectAnswer.referenceObject.adres.plaats +
                            '/streetview',
                    },
                ];
            } else {
                imageUrlPairs = [
                    {path: null, url: '/reference-objects/sale/' + this.referenceObjectAnswer.id + '/streetview'},
                ];
            }
        }

        this.imageUrlPairs = imageUrlPairs;
    }

    public mount(): void {
        const question = this.questionSet.findQuestionByTechnicalReference(TechnicalReference.OBJECT_BUILD_YEAR);
        if (question !== null) {
            this.subscriptions.add(
                this.answerController
                    .answersForQuestionUuidStream(question.uuid)
                    .pipe(map((answers) => answers[answers.length - 1]))
                    .subscribe((answer) => {
                        this.buildYear =
                            answer.contents !== null && answer.contents !== null ? parseInt(answer.contents, 10) : null;
                    })
            );
        }
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    public onPhotoChange = (photoPath: string, photoUrl: string, isOwnUpload?: boolean) => {
        this.referenceObjectAnswer = {
            ...this.referenceObjectAnswer,
            photoPath,
            photoUrl,
            isOwnUpload,
        };
        this.propagateChanges();
    };

    /**
     * Keep a memoized record of all the change handlers used in the modal
     * This should keep the small components (inputs) from unnecessary rerendering because the onChange handler changed
     */
    private changeHandlerMap: ChangeHandlerMap<keyof ValidationInstituteReferentieObject> = {};
    public getMemoizedChangeHandler<TKey extends keyof ValidationInstituteReferentieObject>(
        key: TKey
    ): ChangeHandler<TKey> {
        const changeHandler = this.changeHandlerMap[key];
        if (changeHandler) {
            return changeHandler;
        } else {
            return (this.changeHandlerMap[key] = (value) => {
                this.referenceObjectAnswer = {
                    ...this.referenceObjectAnswer,
                    referenceObject: {
                        ...this.referenceObjectAnswer.referenceObject,
                        [key]: value,
                    },
                };
                this.propagateChanges();
            });
        }
    }

    @action
    public onChangeReferenceObject<TKey extends keyof ValidationInstituteReferentieObject>(
        key: TKey,
        value: ValidationInstituteReferentieObject[TKey]
    ) {
        this.referenceObjectAnswer = {
            ...this.referenceObjectAnswer,
            referenceObject: {
                ...this.referenceObjectAnswer.referenceObject,
                [key]: value,
            },
        };
        this.propagateChanges();
    }

    private propagateDebounceTimer: NodeJS.Timeout | null = null;
    private propagateChanges() {
        if (this.propagateDebounceTimer) {
            clearTimeout(this.propagateDebounceTimer);
        }
        this.propagateDebounceTimer = setTimeout(() => {
            this.onChange(this.referenceObjectAnswer);
        }, 1000);
    }

    public closeModal() {
        this.onChange(this.referenceObjectAnswer);
        this.onHide();
    }

    public canCloseModal(referenceObjectAnswer: ReferenceObjectAnswer) {
        return this.validateReturn(referenceObjectAnswer).length === 0;
    }

    public cannotCloseModalBecause(referenceObjectAnswer: ReferenceObjectAnswer) {
        return this.validateReturn(referenceObjectAnswer).join(', ');
    }

    public toggleOtherPropertySituation() {
        this.hasOtherPropertySituation = !this.hasOtherPropertySituation;
    }

    public hasOtherRights(referenceObjectAnswer: ReferenceObjectAnswer) {
        return [
            referenceObjectAnswer.referenceObject.eigendomssituatieRechtErfpacht,
            referenceObjectAnswer.referenceObject.eigendomssituatieRechtOndererfpacht,
            referenceObjectAnswer.referenceObject.eigendomssituatieRechtOpstal,
            referenceObjectAnswer.referenceObject.eigendomssituatieRechtGebruikBewoning,
            referenceObjectAnswer.referenceObject.eigendomssituatieRechtVruchtgebruik,
            referenceObjectAnswer.referenceObject.eigendomssituatieRechtAnders,
        ].some((v) => v === true);
    }

    public validatesOwnershipMessage(referenceObjectAnswer: ReferenceObjectAnswer) {
        const other = [
            referenceObjectAnswer.referenceObject.eigendomssituatieRechtErfpacht,
            referenceObjectAnswer.referenceObject.eigendomssituatieRechtOndererfpacht,
            referenceObjectAnswer.referenceObject.eigendomssituatieRechtOpstal,
            referenceObjectAnswer.referenceObject.eigendomssituatieRechtGebruikBewoning,
            referenceObjectAnswer.referenceObject.eigendomssituatieRechtVruchtgebruik,
            referenceObjectAnswer.referenceObject.eigendomssituatieRechtAnders,
        ];
        if (!isAppraiseModelOrNewer(this.appraisal.appraiseModel, AppraiseModel.MODEL2021)) {
            return null;
        }

        if (referenceObjectAnswer.referenceObject.eigendomssituatieVolleEigendom && other.some((v) => v === true)) {
            return 'Wanneer volle eigendom geselecteerd is kunnen er geen andere rechten geselecteerd zijn.';
        } else if (
            !referenceObjectAnswer.referenceObject.eigendomssituatieVolleEigendom &&
            other.find((v) => v === true) === undefined
        ) {
            return 'Er moet ten minste 1 recht geselecteerd worden.';
        } else if (
            referenceObjectAnswer.referenceObject.eigendomssituatieRechtAnders &&
            isEmpty(referenceObjectAnswer.referenceObject.eigendomssituatieToelichting)
        ) {
            return 'Wanneer "Ander recht" geselecteerd is dient toelichting ingevuld te zijn.';
        }
        return null;
    }

    public validatesDatesMessage(referenceObjectAnswer: ReferenceObjectAnswer) {
        if (!isAppraiseModelOrNewer(this.appraisal.appraiseModel, AppraiseModel.MODEL2021)) {
            return null;
        }
        if (
            isEmpty(referenceObjectAnswer.referenceObject.verkoopdatum) &&
            isEmpty(referenceObjectAnswer.referenceObject.transportdatum)
        ) {
            return 'Er moet ten minste een datum transactie of transportdatum ingevuld worden.';
        } else if (
            referenceObjectAnswer.referenceObject.verkoopdatum === referenceObjectAnswer.referenceObject.transportdatum
        ) {
            return 'De datum transactie en transportdatum kunnen niet op dezelfde datum liggen.';
        }
        return null;
    }

    private validateReturn(referenceObjectAnswer: ReferenceObjectAnswer) {
        const validationFields = [];
        // Default validation check
        if (
            referenceObjectAnswer.referenceObject.woningTypeStatus !== 'Vergelijkbaar' &&
            isEmpty(referenceObjectAnswer.referenceObject.woningTypeUitleg)
        ) {
            validationFields.push('Woningtype uitleg');
        }

        if (
            referenceObjectAnswer.referenceObject.onderhoudsSituatieStatus !== 'Vergelijkbaar' &&
            isEmpty(referenceObjectAnswer.referenceObject.onderhoudsSituatieUitleg)
        ) {
            validationFields.push('Onderhoudssituatie uitleg');
        }
        if (
            referenceObjectAnswer.referenceObject.aanbouwStatus !== 'Vergelijkbaar' &&
            isEmpty(referenceObjectAnswer.referenceObject.aanbouwUitleg)
        ) {
            validationFields.push('Aanbouw uitleg');
        }
        if (
            referenceObjectAnswer.referenceObject.liggingStatus !== 'Vergelijkbaar' &&
            isEmpty(referenceObjectAnswer.referenceObject.liggingUitleg)
        ) {
            validationFields.push('Ligging uitleg');
        }
        if (isEmpty(referenceObjectAnswer.referenceObject.aanbouw)) {
            validationFields.push('Aanbouw');
        }
        if (isEmpty(referenceObjectAnswer.referenceObject.toelichtingGebruikReferentieObject)) {
            validationFields.push('Toelichting gebruik referentieobject');
        }
        if (isEmpty(referenceObjectAnswer.referenceObject.woningType)) {
            validationFields.push('Woning type');
        }
        if (isEmpty(referenceObjectAnswer.referenceObject.bronGegevens)) {
            validationFields.push('Bron gegevens');
        }
        if (
            !isApartment(referenceObjectAnswer.referenceObject.woningType) &&
            isEmpty(referenceObjectAnswer.referenceObject.perceelOppervlakte)
        ) {
            validationFields.push('Perceeloppervlakte');
        }
        if (isEmpty(referenceObjectAnswer.referenceObject.gebruiksOppervlakte)) {
            validationFields.push('Gebruiks oppervlakte');
        }
        // Validation check for default and sale
        if (this.referenceObjectType !== ReferenceObjectType.RENT) {
            if (
                referenceObjectAnswer.referenceObject.verkochtBinnen !== 'Onbekend' &&
                !isNumeric(referenceObjectAnswer.referenceObject.verkochtBinnen)
            ) {
                validationFields.push('Verkocht binnen');
            }
            if (isEmpty(referenceObjectAnswer.referenceObject.verkoopprijs)) {
                validationFields.push('Koopsom');
            }
            if (isEmpty(referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs)) {
                validationFields.push('Gecorrigeerde koopsom');
            }
            if (isEmpty(referenceObjectAnswer.referenceObject.verkochtBinnen)) {
                validationFields.push('Verkocht binnen');
            }
        }
        // Validation for rent
        if (this.referenceObjectType === ReferenceObjectType.RENT) {
            if (isEmpty(referenceObjectAnswer.referenceObject.aanvangsHuurprijsPerMaand)) {
                validationFields.push('Aanvangs huurprijs per maand');
            }
            if (isEmpty(referenceObjectAnswer.referenceObject.gecorrigeerdeHuurprijsPerMaand)) {
                validationFields.push('Gecorigeerde huurprijs per maand');
            }
            if (isEmpty(referenceObjectAnswer.referenceObject.ingangsdatumHuur)) {
                validationFields.push('Ingangsdatum huur');
            }
        }
        if (
            referenceObjectAnswer.referenceObject.eigendomssituatieRechtAnders &&
            isEmpty(referenceObjectAnswer.referenceObject.eigendomssituatieToelichting)
        ) {
            validationFields.push('Wanneer "Ander recht" geselecteerd is dient toelichting ingevuld te zijn.');
        }
        if (
            referenceObjectAnswer.referenceObject.eigendomssituatieVolleEigendom &&
            !isEmpty(referenceObjectAnswer.referenceObject.eigendomssituatieToelichting)
        ) {
            validationFields.push(
                'Er is een toelichting voor ander recht ingevuld, maar er is ook aangegeven dat er sprake is van volledig recht.'
            );
        }

        // Validate fields for models before model 2021
        if (
            referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus !== 'Vergelijkbaar' &&
            isEmpty(referenceObjectAnswer.referenceObject.luxeDoelmatigheidUitleg)
        ) {
            validationFields.push('Luxe en doelmatigheid uitleg');
        }
        if (
            referenceObjectAnswer.referenceObject.kwaliteitConditieStatus !== 'Vergelijkbaar' &&
            isEmpty(referenceObjectAnswer.referenceObject.kwaliteitConditieUitleg)
        ) {
            validationFields.push('Kwaliteit conditie uitleg');
        }

        // Validate fields for model 2021
        if (isAppraiseModelOrNewer(this.appraisal.appraiseModel, AppraiseModel.MODEL2021)) {
            if (
                referenceObjectAnswer.referenceObject.mateVanLuxeStatus !== 'Vergelijkbaar' &&
                isEmpty(referenceObjectAnswer.referenceObject.mateVanLuxeUitleg)
            ) {
                validationFields.push('Mate van luxe uitleg');
            }
            if (
                referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus !== 'Vergelijkbaar' &&
                isEmpty(referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg)
            ) {
                validationFields.push('Mate van doelmatigheid uitleg');
            }
            if (isEmpty(referenceObjectAnswer.referenceObject.onderhoudssituatie)) {
                validationFields.push('Onderhoudssituatie');
            }
            if (
                !referenceObjectAnswer.referenceObject.eigendomssituatieVolleEigendom &&
                !referenceObjectAnswer.referenceObject.eigendomssituatieRechtErfpacht &&
                !referenceObjectAnswer.referenceObject.eigendomssituatieRechtGebruikBewoning &&
                !referenceObjectAnswer.referenceObject.eigendomssituatieRechtOndererfpacht &&
                !referenceObjectAnswer.referenceObject.eigendomssituatieRechtOpstal &&
                !referenceObjectAnswer.referenceObject.eigendomssituatieRechtVruchtgebruik &&
                !referenceObjectAnswer.referenceObject.eigendomssituatieRechtAnders
            ) {
                validationFields.push('Eigendomssituatie');
            }

            if (
                referenceObjectAnswer.referenceObject.energielabel !== 'Geen energielabel' &&
                isEmpty(referenceObjectAnswer.referenceObject.energielabelGeldigTot)
            ) {
                validationFields.push('Energielabel geldig tot');
            }

            if (
                referenceObjectAnswer.referenceObject.andereWezenlijkeVerschillen !== undefined &&
                referenceObjectAnswer.referenceObject.andereWezenlijkeVerschillen &&
                isEmpty(referenceObjectAnswer.referenceObject.andereWezenlijkeVerschillenUitleg)
            ) {
                validationFields.push('Andere wezenlijke verschillen uitleg');
            }
            if (this.referenceObjectType === ReferenceObjectType.DEFAULT) {
                if (
                    isEmpty(referenceObjectAnswer.referenceObject.verkoopdatum) &&
                    isEmpty(referenceObjectAnswer.referenceObject.transportdatum)
                ) {
                    validationFields.push('Er dient minimaal een verkoopdatum of transport datum ingevuld te zijn');
                }
                if (
                    !isEmpty(
                        referenceObjectAnswer.referenceObject.verkoopdatum &&
                            !isEmpty(referenceObjectAnswer.referenceObject.transportdatum)
                    )
                ) {
                    if (
                        referenceObjectAnswer.referenceObject.verkoopdatum !== null &&
                        referenceObjectAnswer.referenceObject.transportdatum !== null &&
                        referenceObjectAnswer.referenceObject.transportdatum !== undefined
                    ) {
                        if (
                            !isEmpty(referenceObjectAnswer.referenceObject.verkoopdatum) &&
                            !isEmpty(referenceObjectAnswer.referenceObject.transportdatum) &&
                            referenceObjectAnswer.referenceObject.verkoopdatum >
                                referenceObjectAnswer.referenceObject.transportdatum
                        ) {
                            validationFields.push('Verkoopdatum ligt na transportdatum');
                        }
                        if (
                            referenceObjectAnswer.referenceObject.verkoopdatum ===
                            referenceObjectAnswer.referenceObject.transportdatum
                        ) {
                            validationFields.push('Verkoopdatum ligt op dezelfde dag als de transportdatum');
                        }
                    }
                }
            }
        }

        return validationFields;
    }
}
