export interface FlatConstructionCost {
    id?: number;
    description: string;
    group: string;
    subGroup: string;
    type: string;
    labels: string | null;
    subType?: string;
    price: number;
    price2?: number;
    price3?: number;
    price4?: number;
    price5?: number;
    unit: ConstructionCostsUnit;
    multipliable: boolean;
}

export interface ConstructionCostsGroup {
    name: string;
    subGroups: ConstructionCostsSubGroup[];
}

export interface ConstructionCostsSubGroup {
    name: string;
    types: ConstructionCostsType[];
}

export interface ConstructionCostsItem {
    name: string;
    lines: Line[];
}

export type ConstructionCostsType =
    | ConstructionCostsTypeWithoutSubType
    | ConstructionCostsTypeWithSubType
    | ConstructionCostsTypeEmpty;

export interface ConstructionCostsTypeWithSubType {
    name: string;
    subTypes: ConstructionCostsSubType[];
}

export interface ConstructionCostsTypeEmpty {
    name: string;
}

export type ConstructionCostsSubType = ConstructionCostsItem;
export type ConstructionCostsTypeWithoutSubType = ConstructionCostsItem;

export interface ConstructionCosts {
    type?: string;
    lines: Line[];
}

export interface Line {
    description: string;
    multipliable: boolean;
    price: number;
    price2: number;
    price3: number;
    price4: number;
    price5: number;
    unit: ConstructionCostsUnit;
    id?: number;
    labels: string | null;
}

export enum ConstructionCostsUnit {
    SINGLE = 'single',
    METER = 'meter',
    SQUARE_METER = 'square-meter',
    CUBIC_METER = 'cubic-meter',
    KILOGRAM = 'kilogram',
    DAY = 'day',
    HOUR = 'hour',
}
