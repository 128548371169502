import {GroupType} from '../enum/group_type';
import {Macro, SuperMacro} from './macro';
import {MacroSettings} from './macro_settings';
import {Question} from './question';
import {QuestionType} from '../enum/question_type';
import {ReportParagraphReference} from './report_paragraph_reference';
import {ReportSelectorType} from '../enum/report_selector_type';
import {TechnicalReference} from '../enum/technical_reference';
import {isSet} from '../../support/is_set';
import {CopyReferenceType} from '../enum/copy_reference_type';
import {UserMacros} from '../network/models/api_macro';
import {makeObservable} from 'mobx';
import {QuestionExtensionType} from '../enum/question_extension_type';

export interface QuestionSet {
    id: number;
    questions: Question[];
    userDefinedMacros: Macro[];
    superMacros: SuperMacro[];
    userMacroSettings: MacroSettings[];
    reportDefintionConfig: ReportDefinitionConfig;

    findQuestionByUuid(uuid: string): Question | undefined;

    findChildQuestionsByParentUuid(questionUuid: string): Question[];

    findChildQuestionsByParentUuids(questionUuids: string[]): Question[];

    findQuestionByReportParagraphReference(reference: string): Question | null;

    findQuestionByTechnicalReference(technicalReference: TechnicalReference): Question | null;

    findQuestionsByGroupType(groupType: GroupType): Question[];

    findQuestionsByTechnicalReference(technicalReference: TechnicalReference): Question[];

    findQuestionsByType(questionType: QuestionType): Question[];

    findQuestionsByReportSelector(reportSelector: ReportSelectorType): Question[];

    findParentByPredicateRecursive(question: Question, predicate: (question: Question) => boolean): Question | null;
    findParentPathByPredicateRecursive(
        question: Question,
        predicate: (question: Question) => boolean
    ): Question[] | null;

    findChildrenPathByPredicateRecursive(
        question: Question,
        predicate: (question: Question) => boolean
    ): Question[] | null;

    findChildByPredicateRecursive(question: Question, predicate: (question: Question) => boolean): Question | null;

    findQuestionsByKeyword(keyword: string | null): Question[];

    findQuestionsByCopyReference(copyReference: CopyReferenceType): Question[];

    findQuestionsByExtensionType(questionExtensionType: QuestionExtensionType): Question[];

    flattenChildrenRecursively(parentQuestionUuid: string, predicate?: (question: Question) => boolean): Question[];

    loadMacros(userMacros: UserMacros): void;
}

export interface ReportDefinitionConfig {
    opnameDatumOutsideOfQuestionSet: boolean;
    preValidationMessages: boolean;
    conceptReport: boolean;
    maxReferenceObjectsInSet: number;
    reportParagraphReferences: ReportParagraphReference[];
    compactHouseGroupSelection: boolean;
    hasBuildingCosts: boolean;
    referenceObjectsVersion: 1 | 2;
}

export class DefaultQuestionSet implements QuestionSet {
    public id: number;
    public questions: Question[];
    public userDefinedMacros: Macro[] = [];
    public superMacros: SuperMacro[] = [];
    public userMacroSettings: MacroSettings[] = [];
    public reportDefintionConfig: ReportDefinitionConfig;

    private questionMap: {[uuid: string]: Question} | null = null;
    private childQuestionMap: {[parentUuid: string]: Question[]} | null = null;
    private reportParagraphReferenceQuestionMap: {[reference: string]: Question} | null = null;
    private technicalReferenceQuestionMap: {[key in TechnicalReference]?: Question} | null = null;
    private technicalReferenceQuestionsMap: {[key in TechnicalReference]?: Question[]} | null = null;
    private groupTypeQuestionsMap: {[key in GroupType]?: Question[]} | null = null;
    private typeQuestionMap: {[key in QuestionType]?: Question[]} | null = null;
    private reportSelectorQuestionsMap: {[key in ReportSelectorType]?: Question[]} | null = null;
    private copyReferenceQuestionsMap: {[key in CopyReferenceType]?: Question[]} | null = null;
    private keywordQuestionMap: {[keyword: string]: Question[]} | null = null;
    private questionExtensionMap: {[key in QuestionExtensionType]?: Question[]} | null = null;

    constructor(id: number, questions: Question[], reportDefinitionConfig: ReportDefinitionConfig) {
        // We do not use MobX in this class, but this is a performance improvement for cases where this class is used in a MobX context, and MobX tries to do it's deep cloning to convert the entire questionset to be reactive
        // This will stop this from happening and will let MobX know that it doesnt need to observe this class
        makeObservable(this);
        this.id = id;
        this.questions = questions;
        this.reportDefintionConfig = reportDefinitionConfig;
    }
    public findQuestionByUuid(uuid: string): Question | undefined {
        if (this.questionMap === null) {
            this.questionMap = {};
            for (const question of this.questions) {
                this.questionMap[question.uuid] = question;
            }
        }

        return this.questionMap[uuid];
    }

    public findChildQuestionsByParentUuid(questionUuid: string): Question[] {
        if (this.childQuestionMap === null) {
            this.childQuestionMap = {};
            for (const question of this.questions) {
                if (question.parentUuid !== null) {
                    if (this.childQuestionMap[question.parentUuid] === undefined) {
                        this.childQuestionMap[question.parentUuid] = [];
                    }
                    this.childQuestionMap[question.parentUuid].push(question);
                }
            }
        }

        return this.childQuestionMap[questionUuid] ?? [];
    }

    public findChildQuestionsByParentUuids(questionUuids: string[]): Question[] {
        const results: Question[] = [];
        for (const questionUuid of questionUuids) {
            this.findChildQuestionsByParentUuid(questionUuid).forEach((q) => results.push(q));
        }
        return results;
    }

    public findQuestionByReportParagraphReference(reference: string): Question | null {
        if (this.reportParagraphReferenceQuestionMap === null) {
            this.reportParagraphReferenceQuestionMap = {};
            for (const question of this.questions) {
                if (question.reportParagraphReferences !== null && question.reportParagraphReferences.length > 0) {
                    for (const ref of question.reportParagraphReferences) {
                        this.reportParagraphReferenceQuestionMap[ref] = question;
                    }
                }
            }
        }

        return this.reportParagraphReferenceQuestionMap[reference] ?? null;
    }

    public findQuestionByTechnicalReference(technicalReference: TechnicalReference): Question | null {
        if (this.technicalReferenceQuestionMap === null) {
            this.technicalReferenceQuestionMap = {};
            for (const question of this.questions) {
                if (question.technicalReference !== null) {
                    this.technicalReferenceQuestionMap[question.technicalReference] = question;
                }
            }
        }

        return this.technicalReferenceQuestionMap[technicalReference] ?? null;
    }

    public findQuestionsByType(type: QuestionType): Question[] {
        if (this.typeQuestionMap === null) {
            this.typeQuestionMap = {};
            for (const question of this.questions) {
                if (this.typeQuestionMap[question.type] === undefined) {
                    this.typeQuestionMap[question.type] = [];
                }
                this.typeQuestionMap[question.type]?.push(question);
            }
        }

        return this.typeQuestionMap[type] ?? [];
    }

    public findQuestionsByTechnicalReference(technicalReference: TechnicalReference): Question[] {
        if (this.technicalReferenceQuestionsMap === null) {
            this.technicalReferenceQuestionsMap = {};
            for (const question of this.questions) {
                if (question.technicalReference !== null) {
                    if (this.technicalReferenceQuestionsMap[question.technicalReference] === undefined) {
                        this.technicalReferenceQuestionsMap[question.technicalReference] = [];
                    }
                    this.technicalReferenceQuestionsMap[question.technicalReference]?.push(question);
                }
            }
        }

        return this.technicalReferenceQuestionsMap[technicalReference] ?? [];
    }

    public findQuestionsByExtensionType(questionExtensionType: QuestionExtensionType): Question[] {
        if (this.questionExtensionMap === null) {
            this.questionExtensionMap = {};
            for (const question of this.questions) {
                if (question.extensions.some((ex) => ex.type === questionExtensionType)) {
                    if (this.questionExtensionMap[questionExtensionType] === undefined) {
                        this.questionExtensionMap[questionExtensionType] = [];
                    }
                    this.questionExtensionMap[questionExtensionType]?.push(question);
                }
            }
        }

        return this.questionExtensionMap[questionExtensionType] ?? [];
    }

    public findQuestionsByGroupType(groupType: GroupType): Question[] {
        if (this.groupTypeQuestionsMap === null) {
            this.groupTypeQuestionsMap = {};
            for (const question of this.questions) {
                if (question.group !== null && question.group.length > 0) {
                    for (const group of question.group) {
                        if (this.groupTypeQuestionsMap[group] === undefined) {
                            this.groupTypeQuestionsMap[group] = [];
                        }
                        this.groupTypeQuestionsMap[group]?.push(question);
                    }
                }
            }
        }

        return this.groupTypeQuestionsMap[groupType] ?? [];
    }

    public findQuestionsByReportSelector(reportSelector: ReportSelectorType): Question[] {
        if (this.reportSelectorQuestionsMap === null) {
            this.reportSelectorQuestionsMap = {};

            for (const question of this.questions) {
                if (question.reportSelector) {
                    if (this.reportSelectorQuestionsMap[question.reportSelector] === undefined) {
                        this.reportSelectorQuestionsMap[question.reportSelector] = [];
                    }
                    this.reportSelectorQuestionsMap[question.reportSelector]?.push(question);
                }
            }
        }

        return this.reportSelectorQuestionsMap[reportSelector] ?? [];
    }

    public findQuestionsByCopyReference(copyReference: CopyReferenceType): Question[] {
        if (this.copyReferenceQuestionsMap === null) {
            this.copyReferenceQuestionsMap = {};

            for (const question of this.questions) {
                if (question.copyReference) {
                    if (this.copyReferenceQuestionsMap[question.copyReference] === undefined) {
                        this.copyReferenceQuestionsMap[question.copyReference] = [];
                    }
                    this.copyReferenceQuestionsMap[question.copyReference]?.push(question);
                }
            }
        }

        return this.copyReferenceQuestionsMap[copyReference] ?? [];
    }

    public findChildrenPathByPredicateRecursive(
        question: Question,
        predicate: (question: Question) => boolean,
        trace: Question[] = []
    ): Question[] | null {
        const children = this.findChildQuestionsByParentUuid(question.uuid);

        for (const child of children) {
            if (predicate(child)) {
                return [...trace, child];
            }
            const deeperChildPath = this.findChildrenPathByPredicateRecursive(child, predicate, [...trace, child]);
            if (deeperChildPath) {
                return deeperChildPath;
            }
        }

        return null;
    }

    public findChildByPredicateRecursive(
        question: Question,
        predicate: (question: Question) => boolean,
        trace: Question[] = []
    ): Question | null {
        return this.findChildrenPathByPredicateRecursive(question, predicate, trace)?.find(predicate) ?? null;
    }

    public flattenChildrenRecursively(
        parentQuestionUuid: string,
        predicate?: (question: Question) => boolean
    ): Question[] {
        const children = [];

        const childrenQuestions = this.findChildQuestionsByParentUuid(parentQuestionUuid);
        for (const childrenQuestion of childrenQuestions) {
            if (!predicate || predicate(childrenQuestion)) {
                children.push(childrenQuestion);
                const subChildren = this.flattenChildrenRecursively(childrenQuestion.uuid, predicate);
                for (const subChild of subChildren) {
                    children.push(subChild);
                }
            }
        }

        return children;
    }

    public findParentPathByPredicateRecursive(
        question: Question,
        predicate: (question: Question) => boolean,
        trace: Question[] = []
    ): Question[] | null {
        if (question.parentUuid === null) {
            return null;
        }

        trace.push(question);
        let cursor: Question | null = this.findQuestionByUuid(question.parentUuid) ?? null;

        while (isSet(cursor)) {
            trace.push(cursor);

            const matches = predicate(cursor);
            if (matches) {
                return trace.reverse();
            }

            cursor = isSet(cursor.parentUuid) ? this.findQuestionByUuid(cursor.parentUuid) ?? null : null;
        }

        return null;
    }

    public findParentByPredicateRecursive(
        question: Question,
        predicate: (question: Question) => boolean
    ): Question | null {
        const trace = this.findParentPathByPredicateRecursive(question, predicate);
        return trace?.[0] ?? null;
    }

    public findQuestionsByKeyword(keyword: string | null): Question[] {
        if (keyword === null) {
            return [];
        }
        if (this.keywordQuestionMap === null) {
            this.keywordQuestionMap = {};

            for (const question of this.questions) {
                if (question.labels !== null) {
                    const labels = question.labels.split(',');
                    for (let label of labels) {
                        label = label.trim();
                        if (this.keywordQuestionMap[label] === undefined) {
                            this.keywordQuestionMap[label] = [];
                        }
                        this.keywordQuestionMap[label].push(question);
                    }
                } else {
                    if (question.reportValue !== null) {
                        const label = question.reportValue.trim();
                        if (this.keywordQuestionMap[label] === undefined) {
                            this.keywordQuestionMap[label] = [];
                        }
                        this.keywordQuestionMap[label].push(question);
                    }
                }
            }
        }
        return this.keywordQuestionMap[keyword.trim()] ?? [];
    }

    public loadMacros(userMacros: UserMacros): void {
        this.userDefinedMacros = userMacros.userDefinedMacros;
        this.superMacros = userMacros.superMacros;
        this.userMacroSettings = userMacros.userMacroSettings;
    }
}
