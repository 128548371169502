import {AltumSettingsComparability} from '../../../appraising/network/preselected_reference_objects_api';
import React from 'react';
import {Select} from '../../../appraising/appraise/ui/components/select';

interface OwnProps {
    value?: AltumSettingsComparability;
    onChange: (value: AltumSettingsComparability) => void;
    allText?: string;
    comparableText?: string;
    comparableBetterLesserText?: string;
}

export class AltumSettingsComparabilityDropdown extends React.Component<OwnProps> {
    public render() {
        return (
            <Select
                id="preselect"
                isDisabled={false}
                onChange={(value) => this.props.onChange(Number(value))}
                value={this.props.value?.toString() ?? AltumSettingsComparability.COMPARABLE.toString()}
                options={[
                    {
                        label: this.props.comparableText ?? 'Strikt vergelijkbaar',
                        value: AltumSettingsComparability.COMPARABLE.toString(),
                    },
                    {
                        label: this.props.comparableBetterLesserText ?? 'Breder vergelijkbaar',
                        value: AltumSettingsComparability.COMPARABLE_BETTER_LESSER.toString(),
                    },
                    {
                        label: this.props.allText ?? 'Alle vergelijkingen',
                        value: AltumSettingsComparability.ALL.toString(),
                    },
                ]}
            />
        );
    }
}
