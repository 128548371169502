import * as React from 'react';
import {createRoot} from 'react-dom/client';

interface OwnProps {
    input: HTMLInputElement;
}

interface State {
    addresses: string[];
    value: string;
}

class EmailAddressList extends React.Component<OwnProps, State> {
    public state: State = {
        addresses: this.props.input.value.split(',').filter((address) => address !== ''),
        value: '',
    };

    constructor(props: OwnProps) {
        super(props);

        this.handleAddClick = this.handleAddClick.bind(this);
    }

    private handleAddClick() {
        this.setState({
            addresses: [...this.state.addresses, this.state.value],
            value: '',
        });
    }

    private handleRemove(toBeRemovedAddress: string) {
        this.setState({
            addresses: this.state.addresses.filter((address) => address !== toBeRemovedAddress),
        });
    }

    public componentDidUpdate(): void {
        this.props.input.value = this.state.addresses.join(',');
    }

    public render() {
        return (
            <div>
                {this.state.addresses.map((address, index) => (
                    <div className="form-group" key={index}>
                        <input
                            type="email"
                            placeholder="E-mailadres"
                            className="form-control"
                            value={address}
                            disabled
                        />
                        <a onClick={() => this.handleRemove(address)} className="btn-remove-extra ion-ios-trash" />
                    </div>
                ))}
                <div className="form-group">
                    <input
                        type="email"
                        name="applicable_email_address"
                        placeholder="E-mailadres"
                        className="form-control"
                        value={this.state.value}
                        onChange={(e) => this.setState({value: e.target.value})}
                    />
                    <a className="btn-add-extra ion-ios-add float-right" onClick={this.handleAddClick}>
                        Voeg nog een e-mailadres toe
                    </a>
                </div>
            </div>
        );
    }
}

(Array.from(document.querySelectorAll('input[data-fancy-type="email-address-list"]')) as HTMLInputElement[]).forEach(
    (referenceNode) => {
        const mountingPoint = document.createElement('div');
        referenceNode.parentNode?.insertBefore(mountingPoint, referenceNode.nextSibling);
        createRoot(mountingPoint).render(<EmailAddressList input={referenceNode} />);
    }
);
