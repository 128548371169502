import * as React from 'react';
import {
    Placement,
    useFloating,
    autoUpdate,
    arrow,
    offset,
    flip,
    shift,
    useHover,
    useFocus,
    useDismiss,
    useRole,
    useInteractions,
    useMergeRefs,
    FloatingPortal,
} from '@floating-ui/react';

interface TooltipOptions {
    initialOpen?: boolean;
    placement?: Placement;
    open?: boolean;
    disabled?: boolean;
    onOpenChange?: (open: boolean) => void;
}

export function useTooltip({
    initialOpen = false,
    disabled = false,
    placement = 'top',
    open: controlledOpen,
    onOpenChange: setControlledOpen,
}: TooltipOptions = {}) {
    const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);
    const arrowRef = React.useRef<null | HTMLElement>(null);

    const open = controlledOpen ?? uncontrolledOpen;
    const setOpen = setControlledOpen ?? setUncontrolledOpen;

    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(5),
            flip({
                crossAxis: placement.includes('-'),
                fallbackAxisSideDirection: 'start',
                padding: 5,
            }),
            shift({padding: 5}),
            arrow({element: arrowRef}),
        ],
    });

    const context = data.context;

    const hover = useHover(context, {
        move: false,
        enabled: controlledOpen == null,
    });
    const focus = useFocus(context, {
        enabled: controlledOpen == null,
    });
    const dismiss = useDismiss(context);
    const role = useRole(context, {role: 'tooltip'});

    const interactions = useInteractions([hover, focus, dismiss, role]);

    return React.useMemo(
        () => ({
            open,
            setOpen,
            arrowRef,
            disabled,
            ...interactions,
            ...data,
        }),
        [open, disabled, setOpen, interactions, data]
    );
}

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipContext = () => {
    const context = React.useContext(TooltipContext);

    if (context == null) {
        throw new Error('Tooltip components must be wrapped in <Tooltip />');
    }

    return context;
};

export function Tooltip({children, ...options}: {children: React.ReactNode} & TooltipOptions) {
    // This can accept any props as options, e.g. `placement`,
    // or other positioning options.
    const tooltip = useTooltip(options);
    return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
}

export const TooltipTrigger = React.forwardRef<HTMLElement, React.HTMLProps<HTMLElement> & {asChild?: boolean}>(
    function TooltipTrigger({children, asChild = false, ...props}, propRef) {
        const context = useTooltipContext();
        const childrenRef = (children as unknown as {ref: React.Ref<unknown>})?.ref;
        const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

        // `asChild` allows the user to pass any element as the anchor
        if (asChild && React.isValidElement(children)) {
            return React.cloneElement(
                children,
                context.getReferenceProps({
                    ref,
                    ...props,
                    ...children.props,
                    'data-tooltip-state': context.open ? 'open' : 'closed',
                })
            );
        }

        return (
            <button
                className="tooltip-trigger"
                ref={ref}
                // The user can style the trigger based on the state
                data-tooltip-state={context.open ? 'open' : 'closed'}
                {...context.getReferenceProps(props)}
            >
                {children}
            </button>
        );
    }
);

export const TooltipContent = React.forwardRef<
    HTMLDivElement,
    React.HTMLProps<HTMLDivElement> & {style?: Record<string, string>}
>(function TooltipContent({style, ...props}, propRef) {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.open || context.disabled) {
        return null;
    }

    return (
        <FloatingPortal>
            <div
                className="tooltip-content"
                ref={ref}
                style={{
                    ...context.floatingStyles,
                    ...style,
                }}
            >
                <div {...context.getFloatingProps(props)} />
            </div>
        </FloatingPortal>
    );
});

export const TooltipArrow = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(function TooltipArrow(
    props,
    propRef
) {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.arrowRef, propRef]);

    if (!context.open) {
        return null;
    }

    const side = context.placement.split('-')[0];
    const arrowPlacement = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
    }[side];

    return (
        <div
            className="tooltip-arrow"
            ref={ref}
            style={{
                left: context.middlewareData.arrow?.x,
                top: context.middlewareData.arrow?.y,
            }}
            data-arrow-side={arrowPlacement ?? 'bottom'}
        />
    );
});

export function SimpleTooltip({
    children,
    content,
    ...options
}: {children: React.ReactNode; content: React.ReactNode | string | null} & TooltipOptions) {
    return (
        <Tooltip {...options} disabled={content == null || options.disabled}>
            <TooltipTrigger asChild={true}>{children}</TooltipTrigger>
            <TooltipContent>
                {content}
                <TooltipArrow />
            </TooltipContent>
        </Tooltip>
    );
}
