import {ReferenceSaleImage} from '../appraise/ui/content/questions/advanced/reference_objects_question/v1/models/reference_sale_image';

export interface ImageUrlPair {
    path: string | null;
    url: string;
}

export function getUrlFromImageUrlPair(image: ImageUrlPair | ReferenceSaleImage | string): string;
export function getUrlFromImageUrlPair(image: ImageUrlPair | ReferenceSaleImage | string | null): string | null;
export function getUrlFromImageUrlPair(image: ImageUrlPair | ReferenceSaleImage | string | null): string | null {
    return typeof image === 'string' ? image : image?.url ?? null;
}
