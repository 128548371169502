import {distinctUntilChanged, switchMap, tap} from 'rxjs/operators';

import $ from 'jquery';
import {AppraisalClientCountry} from '../enums/appraisal_client_country';
import {Subject} from 'rxjs';

(() => {
    interface Address {
        identifier: string;
        address: string;
        address_formatted: string;
        house_number: string;
        letter: string | null;
        city: string;
        country: string;
        latitude: number | null;
        longitude: number | null;
    }

    interface ObjectAddressResponse {
        addresses: Address[] | null;
        message: string | null;
    }

    const onCountrySelect = (countrySelect: HTMLElement | HTMLElement[], clearInputs: boolean) => {
        const $form = $(countrySelect).closest('form, .address-group');
        const $select = $form.find('select[data-autofill-name="country"]');
        const $address = $form.find('input[data-autofill-name="address"]');
        const $city = $form.find('input[data-autofill-name="city"]');

        if ($select.val() === AppraisalClientCountry.NETHERLANDS) {
            $address.attr('readonly', 'readonly');
            $address.attr('tabIndex', '-1');
            $address.css('pointer-events', 'none');
            $city.attr('readonly', 'readonly');
            $city.attr('tabIndex', '-1');
            $city.css('pointer-events', 'none');
        } else {
            $address.attr('readonly', null);
            $address.attr('tabIndex', null);
            $address.css('pointer-events', '');
            $city.attr('readonly', null);
            $city.attr('tabIndex', null);
            $city.css('pointer-events', '');
            if (clearInputs) {
                $address.val('');
                $city.val('');
            }
        }
    };

    const $countrySelect = $('select[data-autofill-name="country"]');

    $countrySelect.on('change', (event) => {
        onCountrySelect(event.currentTarget, true);
    });

    $(function () {
        onCountrySelect($countrySelect.get(), false);
    });

    $('input[data-autofill-name="postal_code"]').each(function () {
        const isObjectAddress = $(this).attr('data-address-type') === 'object';

        const $form = $(this).closest('form, .address-group');
        const $country = $form.find('select[data-autofill-name="country"]');
        const $select = $form.find('select[data-autofill-name="address-options"]');
        const $selectContainer = $form.find('#address-options-form');
        const addressOutOfRange = $form.find('input[id="address_out_of_range"]');
        const addressPreviouslyAppraised = $form.find('input[id="address_previously_appraised"]');
        const addressNotFound = $form.find('input[id="address_not_found"]');
        const autofillFields = ['address', 'city', 'country'];

        const input = new Subject<{
            postal_code: string;
            house_number: string;
            letter: string | null;
            validation_type: string | null;
        }>();
        const result = new Subject<Address[]>();

        const touch = () => {
            if ($country.val() === undefined || $country.val() === AppraisalClientCountry.NETHERLANDS) {
                input.next({
                    postal_code: $form.find('input[data-autofill-name="postal_code"]').val() as string,
                    house_number: $form.find('input[data-autofill-name="house_number"]').val() as string,
                    letter: $form.find('input[data-autofill-name="letter"]').val() as string,
                    validation_type:
                        ($form.find('input[name="validation_type"]:checked')?.val() as string | null) ??
                        ($form.find('input[name="default_validation_type"]')?.val() as string | null),
                });
            }
        };

        const clear = () => {
            $select.attr('disabled', 'disabled');
            $selectContainer.addClass('d-none');
            autofillFields.map((field) => {
                $form.find('input[data-autofill-name="' + field + '"]').val('');
            });
        };

        let pending = false;
        const showLoader = () => {
            pending = true;

            $form.find('input[data-autofill-name="address"]').attr('placeholder', 'bezig met zoeken...');
            $select.attr('disabled', 'disabled');
            $select.html(`<option value="" disabled selected>bezig met zoeken...</option>`);
            $select.attr('disabled');
        };
        const errorLoader = () => {
            pending = false;

            $form.find('input[data-autofill-name="address"]').attr('placeholder', 'adres niet gevonden.');
            $select.html(`<option value="" disabled selected>adres niet gevonden</option>`);
        };
        const hideLoader = () => {
            pending = false;

            $form.find('input[data-autofill-name="address"]').removeAttr('placeholder');
            $select.html(`<option value="" disabled selected>vul adresgegevens in om te zoeken...</option>`);
        };

        // Fill the fields on the page with the selected address
        const fillFields = (address: Address) => {
            $form.find('input[data-autofill-name="address"]').val(address.address);
            $form.find('input[data-autofill-name="city"]').val(address.city);
            $form.find('input[data-autofill-name="country"]').val(address.country);
            $form.find('input[data-autofill-name="house_number"]').val(address.house_number);
            $form.find('input[data-autofill-name="letter"]').val(address.letter !== null ? address.letter : '');
            $form.find('input[data-autofill-name="latitude"]').val(address.latitude ? address.latitude : '');
            $form.find('input[data-autofill-name="longitude"]').val(address.longitude ? address.longitude : '');
        };

        // Append the select in the view with results received
        const appendSelect = (addresses: Address[]) => {
            $select.html(`<option value="" disabled selected>-- selecteer een adres --</option>`);
            $select.removeAttr('disabled');
            addresses.forEach(function (address: Address) {
                $select.append(
                    `<option value="${address.identifier}">${address.address_formatted} ${address.city}</option>`
                );
            });
        };

        // Select the first select
        const fillFirstSelect = () => {
            $select.find('option[disabled]').removeAttr('selected');
            $select.find('option').last().attr('selected');
        };

        let foundAddresses: Address[] | null = null;

        input
            .pipe(
                distinctUntilChanged((a, b) => {
                    return (
                        a.postal_code === b.postal_code && a.house_number === b.house_number && a.letter === b.letter
                    );
                }),
                tap(clear),
                tap(() => showLoader()),
                switchMap((data) => {
                    return new Promise<ObjectAddressResponse | Address[]>((resolve) => {
                        if (isObjectAddress) {
                            return $.getJSON('/ajax/object-addresses', data)
                                .done((res) => {
                                    hideLoader();
                                    resolve(res);
                                })
                                .fail(() => errorLoader());
                        } else {
                            return $.getJSON('/ajax/addresses', data)
                                .done((res) => {
                                    hideLoader();
                                    resolve(res);
                                })
                                .fail(() => errorLoader());
                        }
                    });
                })
            )
            .subscribe((res) => {
                let addresses: Address[] | null;
                if (isObjectAddress && !Array.isArray(res)) {
                    //remove all notes for address
                    addressPreviouslyAppraised.val('false').trigger('change');
                    addressOutOfRange.val('false').trigger('change');
                    addressNotFound.val('false').trigger('change');

                    //check if there is a message in the response
                    if (res.message === 'out_of_range') {
                        addressOutOfRange.val('true').trigger('change');
                    } else if (res.message === 'not_found') {
                        addressNotFound.val('true').trigger('change');
                        addressOutOfRange.val('false').trigger('change');
                        $selectContainer.addClass('d-none');
                        errorLoader();
                    } else if (res.message === 'previously_appraised') {
                        addressPreviouslyAppraised.val('true').trigger('change');
                    }

                    addresses = res.addresses;
                } else {
                    addresses = res as Address[];
                }

                if (addresses !== null) {
                    result.next(addresses);
                }
            });

        result.subscribe((addresses) => {
            foundAddresses = addresses;
            appendSelect(addresses);
            addressNotFound.val('false').trigger('change');
            if (addresses.length === 1) {
                $selectContainer.addClass('d-none');
                fillFirstSelect();

                fillFields(addresses[0]);
            } else if (addresses.length > 1) {
                $selectContainer.removeClass('d-none');
            }
        });

        $form.find('input[data-autofill-name="postal_code"]').on('blur', touch);
        $form.find('input[data-autofill-name="house_number"]').on('blur', touch);
        $form.find('input[data-autofill-name="letter"]').on('blur', touch);

        $select.on('change', function () {
            if (foundAddresses !== null) {
                const address = foundAddresses.find((a) => a.identifier === $select.val());
                if (address) {
                    fillFields(address);
                }
            }
        });

        $form.find('.submit-wait-for-address').on('click', function (e) {
            if (!pending) {
                return;
            }

            e.preventDefault();
            result.subscribe((addresses) => {
                if (addresses.length === 1) {
                    this.click();
                }
            });
        });
    });
})();
