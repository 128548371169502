import {makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../../../../../../../../support/composite_subscription';
import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {ReferenceObjectsAnswerEnhancementInteractor} from '../../../interactors/reference_objects_answer_enhancement_interactor';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {ReferenceSale} from '../../../v1/models/reference_sale';

export class ReferenceObjectAnswerTilePresenter implements Presenter {
    @observable public enhancedReferenceSale: ReferenceSale | null = null;

    constructor(
        private referenceObjectsAnswerEnhancementInteractor: ReferenceObjectsAnswerEnhancementInteractor,
        private referenceObjectAnswer: ReferenceObjectAnswer
    ) {
        makeObservable(this);
    }

    private subscriptions = new CompositeSubscription();

    public mount() {
        this.subscriptions.add(
            this.referenceObjectsAnswerEnhancementInteractor
                .streamForAnswer(this.referenceObjectAnswer)
                .subscribe((enhancedObject) => {
                    runInAction(() => {
                        this.enhancedReferenceSale = enhancedObject;
                    });
                })
        );
    }

    public unmount() {
        this.subscriptions.clear();
    }
}
