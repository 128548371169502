import {combineLatest, Observable} from 'rxjs';
import {QuestionSet} from '../../models/question_set';
import {TechnicalReference} from '../../enum/technical_reference';
import {v3ValuationGroupTechnicalReferences} from '../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/reference_sets/set_definitions_provider';
import {Question} from '../../models/question';
import {AnswerController} from '../answering/answer_controller';
import {map} from 'rxjs/operators';
import {getNewestAnswer} from '../../../support/get_newest_answer';
import {Answer} from '../../models/answer';
import {ReferenceObjectsMetadataManager} from './reference_objects_metadata_manager';
import {RawReferenceObjectsMetadata, ReferenceObjectsMetadata} from '../../models/reference_objects_metadata';
import {AnswerTouchState} from '../../enum/answer_touch_state';
import {ReferenceObjectsMetadataVersion} from '../../enum/reference_objects_metadata_version';

export type ReferenceObjectsMetadataAnswerPair = {
    answer: Answer;
    metadata: ReferenceObjectsMetadata;
};

export interface ReferenceObjectsMetadataProvider {
    getMetadataByAnswerUuidStream(
        questionUuid: string,
        answerUuid: string
    ): Observable<ReferenceObjectsMetadata | null>;

    getGlobalMetadataStream(): Observable<ReferenceObjectsMetadata | null>;

    getAllMetadataByAnswerUuid(questionUuid: string, answerUuid: string): ReferenceObjectsMetadataAnswerPair[];

    getMetadataByAnswerUuid(questionUuid: string, answerUuid: string): ReferenceObjectsMetadataAnswerPair | null;

    storeMetadata(metadata: ReferenceObjectsMetadataAnswerPair): void;
}

export class DefaultReferenceObjectsMetadataProvider implements ReferenceObjectsMetadataProvider {
    private defaultMetadataVersion = ReferenceObjectsMetadataVersion.V3;

    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private managers: ReferenceObjectsMetadataManager<RawReferenceObjectsMetadata, ReferenceObjectsMetadata>[]
    ) {}

    getMetadataByAnswerUuidStream(
        questionUuid: string,
        answerUuid: string
    ): Observable<ReferenceObjectsMetadata | null> {
        const metadataQuestions = this.collectMetadataQuestions(questionUuid);

        return combineLatest(
            metadataQuestions.map((question) =>
                this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIterationOrNullStream(
                    question.uuid,
                    answerUuid
                )
            )
        ).pipe(map((answers) => this.processMetadataAnswers(answers)));
    }

    getGlobalMetadataStream(): Observable<ReferenceObjectsMetadata | null> {
        const valuationGroupQuestions = this.questionSet.findQuestionsByTechnicalReference(
            TechnicalReference.VALUATION_GROUP
        );

        const metadataQuestions = this.questionSet
            .findChildQuestionsByParentUuids(valuationGroupQuestions.map((question) => question.uuid))
            .filter((q) => q.technicalReference === TechnicalReference.REFERENCE_OBJECTS_METADATA);

        return combineLatest(
            metadataQuestions.map((question) => this.answerController.answersForQuestionUuidStream(question.uuid))
        ).pipe(map((answers) => this.processMetadataAnswers(answers)));
    }

    getAllMetadataByAnswerUuid(questionUuid: string, answerUuid: string): ReferenceObjectsMetadataAnswerPair[] {
        const metadataQuestions = this.collectMetadataQuestions(questionUuid);

        return metadataQuestions
            .map((question) => {
                const answers = this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIteration(
                    question.uuid,
                    answerUuid
                );

                const answer = getNewestAnswer(answers ?? []);

                if (!answer) {
                    return null;
                }

                const metadata = this.processMetadataAnswers([[answer]]);

                if (!metadata) {
                    return null;
                }

                return {
                    answer,
                    metadata,
                };
            })
            .filter((pair): pair is ReferenceObjectsMetadataAnswerPair => pair !== null);
    }

    getMetadataByAnswerUuid(questionUuid: string, answerUuid: string): ReferenceObjectsMetadataAnswerPair | null {
        const metadataQuestions = this.collectMetadataQuestions(questionUuid);
        const metadataQuestion = metadataQuestions.pop();

        if (!metadataQuestion || !metadataQuestion.parentUuid) {
            return null;
        }

        const parentAnswers = this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIteration(
            metadataQuestion.parentUuid,
            answerUuid
        );
        const parentAnswer = getNewestAnswer(parentAnswers ?? []);

        if (!parentAnswer) {
            return null;
        }

        const metadataAnswer = this.answerController.answerByIdentifiersOrStub(
            metadataQuestion.uuid,
            parentAnswer.uuid,
            parentAnswer.iteration
        );

        if (!metadataAnswer) {
            return null;
        }

        const metadata = this.processMetadataAnswers([[metadataAnswer]]);

        if (!metadata) {
            return null;
        }

        return {
            answer: metadataAnswer,
            metadata,
        };
    }

    storeMetadata(pair: ReferenceObjectsMetadataAnswerPair): void {
        const manager = this.managers.find((manager) => manager.version === pair.metadata.version);
        if (!manager) {
            return;
        }

        const raw = manager.toRaw(pair.metadata);
        this.answerController.onContentsChange(pair.answer.uuid, JSON.stringify(raw), AnswerTouchState.UNTOUCHED);
    }

    private processMetadataAnswers(answers: (Answer[] | null)[]) {
        const metadataAnswers = answers
            .filter((answers): answers is Answer[] => answers !== null)
            .map((answer) => getNewestAnswer(answer))
            .filter((a): a is Answer => a !== null);

        const metadatas = metadataAnswers
            .map((answer) => this.constructMetadata(answer))
            .filter((m): m is ReferenceObjectsMetadata => m !== null);

        if (metadatas.length === 0) {
            return null;
        }

        const versionToUse = metadatas[metadatas.length - 1].version;
        const metadatasForVersion = metadatas.filter((metadata) => metadata.version === versionToUse);

        if (metadatasForVersion.length !== metadatas.length) {
            console.warn('Multiple metadata versions are in use. This is not supported.');
        }

        const manager = this.managers.find((manager) => manager.version === versionToUse);
        if (!manager) {
            return null;
        }

        return manager.combine(...metadatasForVersion);
    }

    private collectMetadataQuestions(questionUuid: string): Question[] {
        const question = this.questionSet.findQuestionByUuid(questionUuid);
        if (!question) {
            return [];
        }

        const path = this.questionSet.findParentPathByPredicateRecursive(
            question,
            (parentQuestion) => parentQuestion.technicalReference === TechnicalReference.VALUATION_GROUP
        );
        if (!path) {
            return [];
        }

        const valuationQuestions = path.filter(
            (question) =>
                question.technicalReference !== null &&
                (question.technicalReference === TechnicalReference.VALUATION_GROUP ||
                    v3ValuationGroupTechnicalReferences.includes(question.technicalReference))
        );

        return this.questionSet
            .findChildQuestionsByParentUuids(valuationQuestions.map((question) => question.uuid))
            .filter((q) => q.technicalReference === TechnicalReference.REFERENCE_OBJECTS_METADATA);
    }

    private constructMetadata(answer: Answer): ReferenceObjectsMetadata | null {
        if (!answer.contents) {
            const version = this.determineDefaultVersion();
            const manager = this.managers.find((manager) => manager.version === version);
            if (!manager) {
                return null;
            }

            answer.contents = JSON.stringify(manager.toRaw(manager.empty()));
            this.answerController.onContentsChange(answer.uuid, answer.contents, AnswerTouchState.UNTOUCHED);
        }

        const rawMetadata = JSON.parse(answer.contents) as RawReferenceObjectsMetadata;

        for (const manager of this.managers) {
            if (manager.version === rawMetadata.version) {
                return manager.fromRaw(rawMetadata);
            }
        }

        return null;
    }

    private determineDefaultVersion() {
        const answers = this.answerController.answersForQuestionUuids(
            this.questionSet
                .findQuestionsByTechnicalReference(TechnicalReference.REFERENCE_OBJECTS_METADATA)
                .map((q) => q.uuid)
        );

        // Determine version by checking if there already exists some metadata with a version
        const defaultVersion = answers
            .filter((a) => a.contents !== null)
            .map((a) => JSON.parse(a.contents as string) as RawReferenceObjectsMetadata)
            .map((m) => m.version)
            .pop();

        if (!defaultVersion) {
            return this.defaultMetadataVersion;
        }

        return defaultVersion;
    }
}
