import {AnswerTrailModalProps} from '../appraise/ui/content/modals_stack/modals/answer_trail_modal';
import {Appraisal} from './appraisal';
import {ConstructionalDefectsProps} from '../appraise/ui/appraise_secondary/construction_defects/constructional_defects';
import {PredictionResult} from '../business/ml/vision/photo_content_predicter';
import {Question} from './question';
import {QuestionSet} from './question_set';
import {WidgetsGroupQuestionWidgetProps} from '../appraise/ui/appraise_secondary/widgets_group_question/widgets_group_question_modal';
import {MacroSettingsProps} from '../appraise/ui/appraise_secondary/macro_settings/macro_settings_modal';
import {MacroSettingsPayload} from '../appraise/ui/appraise_secondary/macro_settings/macro_settings_presenter';
import {EnergeticSheetModalProps} from '../appraise/ui/appraise_secondary/energetic_sheet/energetic_sheet_modal';
import {Answer} from './answer';
import {PhotoSet} from '../appraise/ui/content/questions/advanced/reference_objects_question/v3/components/photos/photo_sets';

export enum ModalType {
    DEFECTS = 'defects',
    WIDGET_GROUP = 'widget-group',
    LABEL_SELECT = 'label-select',
    ANSWER_TRAIL = 'answer-trail',
    MACRO_SETTINGS = 'macro-settings',
    ENERGETIC_SHEET = 'energetic-sheet',
    ENERGETIC_SHEET_EXPLANATION = 'energetic-sheet-explanation',
    EDITABLE_IMAGE = 'editable-image',
    REFERENCES_PREFERENCES = 'references-preferences',
    REFERENCE_IMAGES_LABELS = 'reference-images-labels',
    REFERENCE_IMAGES_SETS = 'reference-images-sets',
}

export interface ModalConfigBase {
    id: string | number;
    type: ModalType;
}

export interface WidgetGroupModalConfig
    extends ModalConfigBase,
        Omit<WidgetsGroupQuestionWidgetProps, 'renderingContext'> {
    type: ModalType.WIDGET_GROUP;
}

export interface DefectsModalConfig extends ModalConfigBase, Omit<ConstructionalDefectsProps, 'renderingContext'> {
    type: ModalType.DEFECTS;
}

export interface LabelSelectModalConfig extends ModalConfigBase {
    type: ModalType.LABEL_SELECT;
    parentAnswerUuid: string;
    questions: Question[];
    photoRecognized: boolean;
    predictionResult: PredictionResult | null;
}

export interface AnswerTrailConfig extends ModalConfigBase, AnswerTrailModalProps {
    type: ModalType.ANSWER_TRAIL;
}

export interface MacroSettingsConfig extends ModalConfigBase, MacroSettingsProps {
    type: ModalType.MACRO_SETTINGS;
    payload: MacroSettingsPayload;
    questionSet: QuestionSet;
    appraisal: Appraisal;
    onClose: () => void;
}

export interface EnergeticSheetModalConfig extends ModalConfigBase, EnergeticSheetModalProps {
    type: ModalType.ENERGETIC_SHEET;
}

export interface EnergeticSheetExplanationModalConfig extends ModalConfigBase {
    type: ModalType.ENERGETIC_SHEET_EXPLANATION;
}

export interface EditableImageModalConfig extends ModalConfigBase {
    type: ModalType.EDITABLE_IMAGE;
    answer: Answer;
    isEditable: boolean;
}

export interface ReferencesPreferencesModalConfig extends ModalConfigBase {
    type: ModalType.REFERENCES_PREFERENCES;
}

export interface ReferenceImagesLabelModalConfig extends ModalConfigBase {
    type: ModalType.REFERENCE_IMAGES_LABELS;
    options: string[];
    selected: string[];
}

export interface ReferenceImagesSetsModalConfig extends ModalConfigBase {
    type: ModalType.REFERENCE_IMAGES_SETS;
    sets: PhotoSet[];
    activeIndex: number;
}

export type ModalConfig =
    | LabelSelectModalConfig
    | DefectsModalConfig
    | WidgetGroupModalConfig
    | AnswerTrailConfig
    | MacroSettingsConfig
    | EnergeticSheetModalConfig
    | EnergeticSheetExplanationModalConfig
    | EditableImageModalConfig
    | ReferencesPreferencesModalConfig
    | ReferenceImagesLabelModalConfig
    | ReferenceImagesSetsModalConfig;
