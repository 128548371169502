import React from 'react';
import {ReferenceObject} from './models/reference_object';
import {ReferenceObjectAnswer} from './models/reference_object_answer';
import {ReferenceSaleImage} from '../v1/models/reference_sale_image';

export interface ReferenceObjectsQuestionContextType {
    showDetailsModal?: (referenceObject: ReferenceObject | ReferenceObjectAnswer) => void;
    showImagesModal?: (images: ReferenceSaleImage[], initialIndex?: number, photoAnswerUuid?: string) => void;
}

export const ReferenceObjectsQuestionContext = React.createContext<ReferenceObjectsQuestionContextType>({});
