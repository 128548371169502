import {makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../../../../../../../../support/composite_subscription';
import {SimpleQuestionPresenter} from '../../../../../simple/simple_question_presenter';
import {CompareValuesProvider} from '../../../../../../../../../business/compare_values/compare_values_provider';

export class MultipleChoiceSelectCompareQuestionPresenter extends SimpleQuestionPresenter {
    @observable
    public compareValue: string | null = null;

    private subscriptions = new CompositeSubscription();

    constructor(
        private compareValueProvider: CompareValuesProvider,
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public mount() {
        super.mount();

        this.subscriptions.add(
            this.compareValueProvider
                .compareValueByAnswerIdentifiersStream(
                    this.question.uuid,
                    this.parentAnswerUuid ?? null,
                    this.iteration ?? null
                )
                .subscribe((value) => {
                    if (typeof value === 'number') {
                        return; // MultipleChoiceSelectCompare should never expect a number compare value
                    }

                    runInAction(() => {
                        this.compareValue = value;
                    });
                })
        );
    }

    public unmount() {
        super.unmount();

        this.subscriptions.clear();
    }

    public get currentValue(): string | null {
        if (!this.answer?.answerOptionId) {
            return null;
        }

        return this.question.answerOptions.find((ao) => ao.id === this.answer?.answerOptionId)?.contents ?? null;
    }
}
