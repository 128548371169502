import React from 'react';

interface OwnProps {
    title: string;
    description?: string;
    labelLow?: string;
    labelHigh?: string;
    value: number;
    onChange: (value: number) => void;

    dropdown?: React.ReactNode;
}

export class AltumSettingsSlider extends React.Component<OwnProps> {
    public render() {
        return (
            <>
                <dl>
                    <div className="row">
                        <div className="col-6">
                            <h3 className="mb-0">{this.props.title}</h3>
                            <dd className="mt-0">{this.props.description ?? <>&nbsp;</>}</dd>
                        </div>
                        <div className="col-6 mt-2 mb-2 pl-0">{this.props.dropdown}</div>
                    </div>
                </dl>
                <div className="form-group mb-3">
                    <div className="row">
                        <div className="col-2 pr-0" style={{paddingTop: 1}}>
                            <strong>{this.props.value * 10}</strong> / 10
                        </div>
                        <div className="col-10 pl-0">
                            <div className="form-range-container form-range-compact">
                                <div className="form-range-slider-container pt-0 pr-0">
                                    <input
                                        id="range"
                                        type="range"
                                        min="0"
                                        max="10"
                                        value={this.props.value * 10}
                                        onChange={(e) => this.props.onChange(Number(e.target.value) / 10)}
                                        className="form-range form-range-colored"
                                        list="options"
                                    />
                                    <span
                                        className="form-range-label-container"
                                        style={{justifyContent: 'space-between'}}
                                    >
                                        <span className="form-range-label">
                                            {this.props.labelLow || 'Niet erg belangrijk'}
                                        </span>
                                        <span className="form-range-label">
                                            {this.props.labelHigh || 'Erg belangrijk'}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
