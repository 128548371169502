export interface TreeItemWithoutParents<T> {
    item: T;
    children: Array<TreeItemWithoutParents<T>>;
}

export interface TreeItem<T> extends TreeItemWithoutParents<T> {
    parent: TreeItem<T> | null;
    children: Array<TreeItem<T>>;
}

export interface FindChildrenResult<T, T2> {
    children: T[];
    metaData: T2;
}

//We "wrap" the treeItems in a class since mobx doesnt mind ciruclar references then
//Returning a normal object would create a stack overflow..
export class TreeItemClass<T> implements TreeItem<T> {
    constructor(public parent: TreeItemClass<T> | null, public item: T, public children: Array<TreeItemClass<T>>) {}
}

export function buildTree<T, T2>(
    rootItem: T,
    findChildren: (parent: T, metaData: T2 | null) => FindChildrenResult<T, T2>
): TreeItem<T> {
    function createTreeItem(parent: TreeItem<T> | null, item: T, metaData: T2 | null) {
        const childrenData = findChildren(item, metaData);
        const treeItem = new TreeItemClass(parent, item, []);
        treeItem.children = childrenData.children.map((childItem) =>
            createTreeItem(treeItem, childItem, childrenData.metaData)
        );
        return treeItem;
    }

    return createTreeItem(null, rootItem, null);
}

export function findParentByPredicateRecursive<T>(
    tree: TreeItem<T>,
    predicate: (node: T) => boolean
): TreeItem<T> | null {
    let cursor = tree;
    while (cursor !== null && cursor.parent) {
        const matches = predicate(cursor.parent.item);
        if (matches) {
            return cursor.parent;
        }
        cursor = cursor.parent;
    }
    return null;
}

type TreeValueType<T> = T extends TreeItemWithoutParents<infer E> ? E : never;
export function findChildRecursiveByPredicate<
    TTree extends TreeItemWithoutParents<unknown>,
    TValue extends TreeValueType<TTree>
>(tree: TTree, predicate: (node: TValue) => boolean): TTree | null {
    function walk(node: TTree): TTree | null {
        if (predicate(node.item as TValue)) {
            return node;
        }

        for (const child of node.children) {
            const childResult = walk(child as TTree);
            if (childResult !== null) {
                return childResult;
            }
        }

        return null;
    }

    return walk(tree) ?? null;
}

export function findChildrenRecursiveByPredicate<
    TTree extends TreeItemWithoutParents<unknown>,
    TValue extends TreeValueType<TTree>
>(tree: TTree, predicate: (node: TValue) => boolean, infinitelyDeep?: boolean): TTree[] {
    const result: TTree[] = [];

    function walk(node: TTree) {
        if (predicate(node.item as TValue)) {
            result.push(node);
        }

        if (result.length === 0 || infinitelyDeep) {
            for (const child of node.children) {
                walk(child as TTree);
            }
        }
    }

    walk(tree);
    return result;
}

export function findChildrenPathByPredicateRecursive<TTree extends TreeItemWithoutParents<unknown>>(
    tree: TTree,
    predicate: (node: TTree) => boolean,
    trace: TTree[] = []
): TTree[] | null {
    for (const child of tree.children) {
        if (predicate(child as TTree)) {
            return [...trace, child as TTree];
        }
        const deeperChildPath = findChildrenPathByPredicateRecursive(child as TTree, predicate, [
            ...trace,
            child as TTree,
        ]);
        if (deeperChildPath) {
            return deeperChildPath;
        }
    }

    return null;
}

export function walkTree<TTree extends TreeItemWithoutParents<unknown>>(
    tree: TTree,
    callback: (item: TTree, parent: TTree) => boolean | void
) {
    for (const child of tree.children) {
        if (callback(child as TTree, tree) === false) {
            break;
        } else {
            walkTree(child as TTree, callback);
        }
    }
}

interface ItemWithSiblings<T> {
    treeNode: T;
    treeSiblings: T[];
}

export function findChildrenAndSiblingsRecursiveByPredicate<
    TTree extends TreeItemWithoutParents<unknown>,
    TValue extends TreeValueType<TTree>
>(tree: TTree, predicate: (node: TValue) => boolean): Array<ItemWithSiblings<TTree>> {
    const result: Array<ItemWithSiblings<TTree>> = [];
    walkTree(tree, (treeNode, parentNode) => {
        if (predicate(treeNode.item as TValue)) {
            result.push({
                treeNode: treeNode,
                treeSiblings: parentNode.children.filter((c) => c !== treeNode) as TTree[],
            });
        }
    });
    return result;
}

export function removeParentsFromTree<T>(tree: TreeItem<T> | TreeItemClass<T>): TreeItemWithoutParents<T> {
    return {
        item: tree.item,
        children: tree.children.map((child) => removeParentsFromTree(child)),
    };
}

export function addParentsToTree<T>(tree: TreeItemWithoutParents<T>, parent?: TreeItem<T>): TreeItem<T> {
    const treeWithParents: TreeItem<T> = {
        item: tree.item,
        parent: parent ?? null,
        children: [],
    };
    treeWithParents.children = tree.children.map((child) => addParentsToTree(child, treeWithParents));

    return treeWithParents;
}
