import React from 'react';
import {
    ImageViewerComponent,
    ImageViewerComponentProps,
} from '../../../../../../../../../components/image_viewer/image_viewer';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';
import {observer} from 'mobx-react';
import {classNames} from '../../../../../../../../../../support/classnames';
import {ChoosePhotoButtonPresenter} from './choose_photo_button_presenter';
import {Appraisal} from '../../../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../../../models/question_set';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    photoAnswerUuid: string;
}

const ChoosePhotoButton: React.FC<ImageViewerComponentProps & OwnProps> = observer(function ChoosePhotoButton({
    lightbox,
    images,
    appraisal,
    questionSet,
    photoAnswerUuid,
}) {
    const presenter = usePresenter(
        (container) =>
            new ChoosePhotoButtonPresenter(
                container.business.attachmentUploadInteractor(appraisal, questionSet),
                container.business.flashMessageBroadcaster,
                container.business.answerController(appraisal, questionSet),
                photoAnswerUuid,
                lightbox
            )
    );

    if (presenter.hidden) {
        return null;
    }

    const shownImage = presenter.activeIndex !== null ? images?.[presenter.activeIndex] ?? null : null;
    const shownUrl = shownImage
        ? typeof shownImage === 'string'
            ? shownImage
            : shownImage.url ?? shownImage.uncompressedUrl
        : null;

    return (
        <div>
            <button
                className={classNames('btn btn-sm btn-primary ion-md-checkmark float-right', {
                    'ion-animate': !presenter.busy,
                })}
                onClick={() => {
                    if (!shownUrl) return;
                    presenter.pickCurrentPhoto(shownUrl);
                }}
                disabled={presenter.busy || presenter.currentImageUrl === shownUrl}
            >
                {presenter.busy ? (
                    <span>
                        <span className="loader" />
                        <span>Kies deze foto</span>
                    </span>
                ) : (
                    'Kies deze foto'
                )}
            </button>
        </div>
    );
});

export const getChoosePhotoButtonCompontent: (props: OwnProps) => ImageViewerComponent = (props) => ({
    name: 'choose-photo-button',
    appendTo: 'bar',
    order: 6,
    className: 'photo-sets-toolbar-button',
    component: <ChoosePhotoButton {...props} />,
});
