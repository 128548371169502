import {GlobalProvider} from '../../../business/global_provider';
import {getNewestAnswer} from '../../../support/get_newest_answer';
import {QuestionExtensionType} from '../../enum/question_extension_type';
import {TechnicalReference} from '../../enum/technical_reference';
import {Answer} from '../../models/answer';
import {FileReference} from '../../models/file_reference';
import {TextAIQuestionExtension} from '../../models/question_extension';
import {QuestionSet} from '../../models/question_set';
import {TextAISource} from '../../network/text_ai_api';
import {AnswerController} from '../answering/answer_controller';
import {TextAIContextBuilder} from './textai_context_builder';

export class DefaultTextAITransitievisieWarmteContextBuilder implements TextAIContextBuilder {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private globalProvider: GlobalProvider
    ) {}

    public getContext(): {[key: string]: unknown} {
        return {
            Buurtnaam: this.globalProvider.global.appraisalDistrict?.name ?? 'Onbekend',
            Wijknaam: this.globalProvider.global.appraisalDistrict?.quarter ?? 'Onbekend',
            Gemeente: this.globalProvider.global.appraisalDistrict?.municipality ?? 'Onbekend',
        };
    }

    public getFile(answer: Answer): FileReference | null {
        const question = this.questionSet.findQuestionByUuid(answer.questionUuid);

        const extension = question?.extensions.find((e) => e.type === QuestionExtensionType.TEXT_AI) as
            | TextAIQuestionExtension
            | undefined;
        if (!extension || !extension.sources.includes(TextAISource.TRANSITIEVISIE_WARMTE)) {
            return null;
        }

        const tvwQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.ATTACHMENT_TRANSITIEVISIE_WARMTE
        );
        if (!tvwQuestion) {
            return null;
        }

        const mostRecentAnswer = getNewestAnswer(
            this.answerController.answersForQuestionUuid(tvwQuestion.uuid).filter((a) => !a.isDeleted)
        );

        return mostRecentAnswer?.file ?? null;
    }

    public getPromptForAnswer(answer: Answer): string | null {
        const question = this.questionSet.findQuestionByUuid(answer.questionUuid);

        if (!question) {
            return null;
        }

        return `Geef aan de hand van de transitievisie warmte van de gemeente, en met specifieke aandacht voor de wijk van dit object, een antwoord op de volgende vraag: ${question.contents} Vat dit samen in maximaal 100 woorden.`;
    }
}
