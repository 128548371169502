import {drawOnCanvas} from './draw_on_canvas';

export function isEmptyImage(image: HTMLImageElement): boolean {
    if (image.complete === false) {
        return true;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
        drawOnCanvas(canvas, image, 0);
        const pixelsArray = context.getImageData(0, 0, image.width, image.height).data;
        for (let index = 0; index < pixelsArray.length; index += 4) {
            const red = pixelsArray[index];
            const green = pixelsArray[index + 1];
            const blue = pixelsArray[index + 2];

            if (red !== 0 || green !== 0 || blue !== 0) {
                return false;
            }
        }
    }
    return true;
}
