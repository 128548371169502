import * as React from 'react';

import {
    simpleQuestionPresenterConstructorParametersFactory,
    SimpleQuestionPresenterProps,
} from '../../../../../simple/simple_question_presenter';

import {EditableAddressWithMeasurementDetailsQuestionPresenter} from './editable_address_question_presenter';
import {FloorAreaPrice} from '../../components/floor_area_price';
import {VolumePrice} from '../../components/volume_price';
import {getObjectIndexedPrice, getObjectPrice, getObjectUnindexedPrice} from '../../internal/get_object_price';
import {observer} from 'mobx-react';
import {FloorAreaPriceRent} from '../../components/floor_area_price_rent';
import {VolumePriceRent} from '../../components/volume_price_rent';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';
import {RenderingContextType} from '../../../../../../../../../enum/rendering_context_type';
import {classNames} from '../../../../../../../../../../support/classnames';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';
import {ReferenceObjectsQuestionContext} from '../../referene_objects_question_context';

type OwnProps = SimpleQuestionPresenterProps;

export const EditableAddressQuestion: React.FC<OwnProps> = observer(function EditableAddressQuestion(props) {
    const presenter = usePresenter(
        (container) =>
            new EditableAddressWithMeasurementDetailsQuestionPresenter(
                container.business.v3ReferenceSetsProvider(props.appraisal, props.questionSet),
                container.business.compareValuesProvider(props.appraisal, props.questionSet),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );

    const context = React.useContext(ReferenceObjectsQuestionContext);

    const {referenceObjectDataWithSet} = presenter;
    if (referenceObjectDataWithSet === null) {
        return null;
    }
    const {adres} = referenceObjectDataWithSet.data.referenceObjectAnswer.referenceObject;
    const letter = presenter.answer?.contents ?? adres.huisnummerToevoeging;

    function renderEditableLetter() {
        if (presenter.editing) {
            return (
                <span className="form-group form-group-with-button">
                    <label>Huisnummer toevoeging bewerken</label>
                    <input
                        type="text"
                        className="form-control"
                        value={presenter.answer?.contents ?? ''}
                        onChange={(e) => presenter.onChange(e.target.value, false)}
                        onBlur={() => presenter.onTouched()}
                    />
                    <button
                        className="btn btn-sm btn-primary ion-md-checkmark btn-single-icon float-right"
                        onClick={() => presenter.deactivateEdit()}
                    >
                        &nbsp;
                    </button>
                </span>
            );
        }
        return null;
    }

    return (
        <div className="row">
            <div
                className={
                    props.renderingContext === RenderingContextType.COMPACT_GRID_COLUMNS ? 'col-sm-12' : 'col-sm-6'
                }
            >
                <dl>
                    <dt>Adres</dt>
                    <dd>
                        <span
                            className={presenter.editing ? 'ion-md-create disabled' : 'ion-md-create'}
                            onClick={() => presenter.activateEdit()}
                        >
                            &nbsp;
                        </span>
                        {adres.straat} {adres.huisnummer + (letter ? '-' + letter : '')}
                        <br />
                        {adres.postcode} {adres.plaats}
                        {renderEditableLetter()}
                    </dd>
                </dl>
            </div>
            <div
                className={
                    props.renderingContext === RenderingContextType.COMPACT_GRID_COLUMNS ? 'col-sm-12' : 'col-sm-6'
                }
            >
                {referenceObjectDataWithSet.referenceSet.type === SetType.RENT ? (
                    <>
                        <dl>
                            <dt>Huurprijs per m²</dt>
                            <dd>
                                <FloorAreaPriceRent
                                    referenceObjectFloorArea={
                                        referenceObjectDataWithSet.data.referenceObjectAnswer.referenceObject
                                            .gebruiksOppervlakte ?? null
                                    }
                                    referenceObjectPriceRange={getObjectUnindexedPrice(
                                        referenceObjectDataWithSet.referenceSet.type,
                                        referenceObjectDataWithSet.data.referenceObjectAnswer.referenceObject
                                    )}
                                    referenceObjectIndexedPrice={getObjectIndexedPrice(
                                        referenceObjectDataWithSet.referenceSet.type,
                                        referenceObjectDataWithSet.data.referenceObjectAnswer.referenceObject
                                    )}
                                    questionSet={props.questionSet}
                                    appraisal={props.appraisal}
                                />
                            </dd>
                        </dl>
                        <dl>
                            <dt>Huurprijs per m³</dt>
                            <dd>
                                <VolumePriceRent
                                    referenceObjectVolume={
                                        referenceObjectDataWithSet.data.referenceObjectAnswer.referenceObject.inhoud ??
                                        null
                                    }
                                    referenceObjectPrice={getObjectPrice(
                                        referenceObjectDataWithSet.referenceSet.type,
                                        referenceObjectDataWithSet.data.referenceObjectAnswer.referenceObject
                                    )}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                />
                            </dd>
                        </dl>
                    </>
                ) : (
                    <>
                        <dl>
                            <dt>Prijs per m²</dt>
                            <dd>
                                <FloorAreaPrice
                                    referenceObjectId={null}
                                    referenceSetPrice={referenceObjectDataWithSet.referenceSet.valuation}
                                    referenceSetFloorArea={referenceObjectDataWithSet.referenceSet.surfaceArea}
                                    referenceObjectFloorArea={
                                        referenceObjectDataWithSet.data.referenceObjectAnswer.referenceObject
                                            .gebruiksOppervlakte ?? null
                                    }
                                    referenceObjectPriceRange={getObjectUnindexedPrice(
                                        referenceObjectDataWithSet.referenceSet.type,
                                        referenceObjectDataWithSet.data.referenceObjectAnswer.referenceObject
                                    )}
                                    referenceObjectIndexedPrice={getObjectIndexedPrice(
                                        referenceObjectDataWithSet.referenceSet.type,
                                        referenceObjectDataWithSet.data.referenceObjectAnswer.referenceObject
                                    )}
                                    compact={props.renderingContext === RenderingContextType.COMPACT_GRID_COLUMNS}
                                    spaceBetween={props.renderingContext === RenderingContextType.COMPACT_GRID_COLUMNS}
                                />
                            </dd>
                        </dl>
                        <dl>
                            <dt>Prijs per m³</dt>
                            <dd>
                                <VolumePrice
                                    referenceSetPrice={referenceObjectDataWithSet.referenceSet.valuation}
                                    referenceSetVolume={referenceObjectDataWithSet.referenceSet.volume}
                                    referenceObjectVolume={
                                        referenceObjectDataWithSet.data.referenceObjectAnswer.referenceObject.inhoud ??
                                        null
                                    }
                                    referenceObjectPrice={getObjectPrice(
                                        referenceObjectDataWithSet.referenceSet.type,
                                        referenceObjectDataWithSet.data.referenceObjectAnswer.referenceObject
                                    )}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                />
                            </dd>
                        </dl>
                    </>
                )}
                {referenceObjectDataWithSet.data.referenceObjectAnswer.matchingPercentage && (
                    <dl>
                        <dt>Match score</dt>
                        <dd>
                            <span className="row">
                                <span className="col-8">
                                    {Math.round(
                                        referenceObjectDataWithSet.data.referenceObjectAnswer.matchingPercentage / 100
                                    )}
                                    %
                                </span>
                                <span className="col-4">
                                    {presenter.compareBadges?.match_score &&
                                        props.renderingContext === RenderingContextType.COMPACT_GRID_COLUMNS && (
                                            <span className="form-badge">
                                                <span className="badge-container">
                                                    <span
                                                        className={classNames(
                                                            'badge d-block',
                                                            presenter.compareBadges.match_score
                                                        )}
                                                    />
                                                </span>
                                            </span>
                                        )}
                                </span>
                            </span>
                        </dd>
                    </dl>
                )}
                {referenceObjectDataWithSet.data.referenceObjectAnswer.rooms && (
                    <dl>
                        <dt>Aantal kamers</dt>
                        <dd>{referenceObjectDataWithSet.data.referenceObjectAnswer.rooms}</dd>
                    </dl>
                )}
                {context.showDetailsModal && (
                    <button
                        className="btn btn-sm btn-secondary ion-md-list"
                        onClick={() =>
                            context.showDetailsModal?.(referenceObjectDataWithSet.data.referenceObjectAnswer)
                        }
                    >
                        Meer objectdetails
                    </button>
                )}
            </div>
        </div>
    );
});
