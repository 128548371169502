import {PagePart} from './page_part';
import {PagePartCustomization} from './page_part_customization';

export enum PagePartConfigurationType {
    DEFAULT = 'default',
    SYSTEM_COMPLETE = 'system-complete',
    SYSTEM_PARTIAL = 'system-partial',
    SYSTEM_DETAIL = 'system-detail',
    CUSTOM = 'custom',
}

export interface PagePartConfiguration {
    id: number;
    name: string;
    description: string | null;
    userName: string | null;
    isOwned: boolean;
    isShared: boolean;
    isVisible: boolean;
    isEditable: boolean;
    rank: number;
    type: PagePartConfigurationType;
    pageParts: PagePart[];
    customizations: PagePartCustomization[];
}
