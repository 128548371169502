import * as Uuid from 'uuid';

import {action, autorun, computed, makeObservable, observable, runInAction} from 'mobx';
import {combineLatest} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {MacroType, SuperMacro, SuperMacroItem, SuperMacroValueMap} from '../../../../../../models/macro';
import {ModalType} from '../../../../../../models/modal_config';
import {
    MacroSettingsPayloadType,
    PersistedSuperMacroPayload,
    UnpersistedSuperMacroPayload,
} from '../../../../appraise_secondary/macro_settings/macro_settings_presenter';
import {Presenter} from '../../../../../../../support/presenter/presenter';
import {AnswerController} from '../../../../../../business/answering/answer_controller';
import {Question} from '../../../../../../models/question';
import {Appraisal} from '../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../models/question_set';
import {MacroInteractor} from '../../../../../../business/macro_interactor';
import {AnswerPathStubber, StubberValuesMap} from '../../../../../../business/answering/answer_path_stubber';
import {Answer} from '../../../../../../models/answer';
import {CompositeSubscription} from '../../../../../../../support/composite_subscription';
import {SuperMacroInteractor, VisibleMacro} from '../../../../../../business/super_macro_interactor';
import {ModalOrSecondaryConfigStackInteractor} from '../../../../../../business/modal_or_secondary_config_stack_interactor';
import {areMapsEqual} from '../../../../../../../support/are_maps_equal';
import {AppraiseSecondaryType} from '../../../../../../models/appraise_secondary_config';
import {AppraisalApi} from '../../../../../../network/appraisal_api';
import {SearchAppraisal} from '../../../../../../models/search_appraisal';
import {FlashMessageBroadcaster, Type} from '../../../../../../business/flash_message_broadcaster';

export enum SuperMacroState {
    NONE = 'none',
    SELECTED = 'selected',
    ACTIVE = 'active',
}

interface SuperMacroWithStatus {
    state: SuperMacroState;
    macro: SuperMacro;
}

export class SuperMacrosPresenter implements Presenter {
    @observable.ref
    public superMacros: SuperMacroWithStatus[] = [];

    @observable
    public isPopperOpen = false;

    @observable
    public isAddModalOpen = false;

    @observable
    public isCopyModalOpen = false;

    @observable
    public isLoading = false;

    @observable
    public appraisalSearchQuery: string | null = null;

    @observable.ref
    public searchAppraisals: SearchAppraisal[] | null = null;

    @observable
    public isSearchingAppraisals = false;

    @observable
    public isLoadingAppraisalValues = false;

    @observable.ref
    public appraisalFillValues: SuperMacroValueMap[] | null = null;

    @observable
    public lastSelectedMacroId: number | null = null;

    @observable.ref private settingsPayload: UnpersistedSuperMacroPayload | PersistedSuperMacroPayload | null = null;

    private secondaryWidgetId = `macro-settings-widget-[${Uuid.v4()}]`;

    private subscriptions = new CompositeSubscription();

    @observable
    private appraisalSearchTimeout?: NodeJS.Timeout;

    constructor(
        private question: Question,
        private parentAnswerUuid: string | undefined,
        private iteration: string | undefined,
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private superMacroInteractor: SuperMacroInteractor,
        private macroInteractor: MacroInteractor,
        private modalOrSecondaryConfigStackInteractor: ModalOrSecondaryConfigStackInteractor,
        private answerController: AnswerController,
        private answerPathStubber: AnswerPathStubber,
        private appraisalApi: AppraisalApi,
        private flashMessageBroadcaster: FlashMessageBroadcaster
    ) {
        makeObservable(this);
    }

    public mount() {
        this.subscriptions.add(
            combineLatest([
                this.answerController
                    .answerByIdentifiersStream(
                        this.question.uuid,
                        this.parentAnswerUuid ?? null,
                        this.iteration ?? null
                    )
                    .pipe(
                        switchMap((parentAnswer) =>
                            this.answerController
                                .childrenAnswersForAnswerRecursivelyStream(parentAnswer)
                                .pipe(map((childAnswers) => ({parentAnswer, childAnswers})))
                        )
                    ),
                this.superMacroInteractor.visibleMacrosStream(this.question.uuid, this.parentAnswerUuid ?? null),
            ])
                .pipe(
                    map(([answers, visibleMacros]) => {
                        const visibleSuperMacros = visibleMacros.filter(
                            (m): m is VisibleMacro<SuperMacro> => m.macro.type === MacroType.SUPER
                        );

                        if (visibleSuperMacros.length === 0) {
                            return [];
                        }

                        const currentAnswersMap = this.answerPathStubber.buildStubberValuesMap(
                            answers.parentAnswer.uuid
                        );
                        const macros: SuperMacroWithStatus[] = visibleSuperMacros.map((m) => {
                            const macroAnswersMap = this.createMacrosMap(m.macro.items);
                            const areEqual = areMapsEqual(
                                currentAnswersMap,
                                macroAnswersMap,
                                (a, b) => a.answerOptionUuid === b.answerOptionUuid && a.contents === b.contents
                            );
                            const wasSelected = this.lastSelectedMacroId === m.macro.id;
                            return {
                                macro: m.macro,
                                state:
                                    areEqual && wasSelected
                                        ? SuperMacroState.SELECTED
                                        : areEqual
                                        ? SuperMacroState.ACTIVE
                                        : SuperMacroState.NONE,
                            };
                        });

                        return macros;
                    })
                )
                .subscribe((superMacrosWithState) => {
                    runInAction(() => {
                        this.superMacros = superMacrosWithState;
                    });
                })
        );

        this.subscriptions.add(
            autorun(() => {
                if (this.settingsPayload !== null) {
                    this.modalOrSecondaryConfigStackInteractor.upsert(
                        {
                            id: this.secondaryWidgetId,
                            type: ModalType.MACRO_SETTINGS,
                            payload: this.settingsPayload,
                            appraisal: this.appraisal,
                            questionSet: this.questionSet,
                            onClose: () => {
                                runInAction(() => {
                                    this.settingsPayload = null;
                                });
                            },
                        },
                        {
                            id: this.secondaryWidgetId,
                            type: AppraiseSecondaryType.MACRO_SETTINGS,
                            payload: this.settingsPayload,
                            appraisal: this.appraisal,
                            questionSet: this.questionSet,
                            onClose: () => {
                                runInAction(() => {
                                    this.settingsPayload = null;
                                });
                            },
                        }
                    );
                } else {
                    this.modalOrSecondaryConfigStackInteractor.remove(this.secondaryWidgetId);
                }
            })
        );
    }

    public unmount() {
        this.subscriptions.clear();
    }

    @computed
    public get isAppraisalSearchPending() {
        return this.appraisalSearchTimeout !== undefined;
    }

    @action
    public setPopperIsOpen(isOpen: boolean) {
        this.isPopperOpen = isOpen;
    }

    public async onSaveNewSuperMacro() {
        const answer = this.getAnswer();
        if (!answer) {
            return;
        }

        const answersMap = this.answerPathStubber.buildStubberValuesMap(answer.uuid);

        runInAction(() => {
            this.settingsPayload = {
                type: MacroSettingsPayloadType.UNPERSISTED_SUPER,
                values: answersMap,
                questionUuid: this.question.uuid,
            };
            this.isPopperOpen = false;
            this.isAddModalOpen = false;
        });
    }

    public async onEditSuperMacro(superMacro: SuperMacro) {
        runInAction(() => {
            this.settingsPayload = {
                type: MacroSettingsPayloadType.PERSISTED_SUPER,
                macro: superMacro,
            };
            this.isPopperOpen = false;
            this.isAddModalOpen = false;
        });
    }

    public async removeSuperMacro(superMacro: SuperMacro) {
        runInAction(() => {
            this.isLoading = true;
        });
        try {
            this.macroInteractor.destroy(superMacro.id);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    private createMacrosMap(macros: SuperMacroItem[]): StubberValuesMap {
        const map: StubberValuesMap = new Map();

        for (const macro of macros) {
            if (!macro.uuid || !macro.questionUuid) {
                continue;
            }

            map.set(macro.uuid, {
                questionUuid: macro.questionUuid,
                iteration: macro.iteration ?? null,
                parentUuid: macro.parentUuid ?? null,

                contents: macro.contents ?? null,
                answerOptionUuid: macro.answerOptionUuid ?? null,
                file: macro.file ?? null,
            });
        }

        return map;
    }

    private getAnswer(): Answer | null {
        return this.answerController.answerByIdentifiers(
            this.question.uuid,
            this.parentAnswerUuid ?? null,
            this.iteration ?? null
        );
    }

    @action
    public onSuperMacroSelect(superMacro: SuperMacro) {
        this.lastSelectedMacroId = superMacro.id;

        const macroAnswersMap = this.createMacrosMap(superMacro.items);
        this.fillValueMap(macroAnswersMap);

        this.isAddModalOpen = false;
    }

    private fillValueMap(values: StubberValuesMap) {
        const answer = this.getAnswer();
        if (!answer) {
            return;
        }

        this.answerPathStubber.stubValuesMap(answer, values, true);
    }

    @action
    public onSearchAppraisal(query?: string, force = false) {
        if (query !== undefined) {
            this.appraisalSearchQuery = query;
        }

        const search = () => {
            runInAction(() => {
                this.isSearchingAppraisals = true;
                clearTimeout(this.appraisalSearchTimeout);
                this.appraisalSearchTimeout = undefined;
            });
            this.appraisalApi
                .search(this.appraisalSearchQuery ?? '')
                .then((appraisals) => {
                    runInAction(() => {
                        this.searchAppraisals = appraisals.filter((a) => a.id !== this.appraisal.id);
                    });
                })
                .finally(() => {
                    runInAction(() => {
                        this.isSearchingAppraisals = false;
                    });
                });
        };

        if (this.appraisalSearchTimeout) {
            clearTimeout(this.appraisalSearchTimeout);
            this.appraisalSearchTimeout = undefined;
        }

        if (force) {
            search();
        } else {
            this.appraisalSearchTimeout = setTimeout(search, 500);
        }
    }

    public async getAppraisalValues(appraisalIdOrValues: number | SuperMacroValueMap) {
        if (typeof appraisalIdOrValues !== 'number') {
            this.fillValueMap(appraisalIdOrValues.values);
            this.flashMessageBroadcaster.broadcast('De antwoorden zijn succesvol ingevuld.', Type.Success);

            runInAction(() => {
                this.isLoadingAppraisalValues = false;
                this.isCopyModalOpen = false;
                this.appraisalFillValues = null;
            });
            return;
        }

        runInAction(() => {
            this.isLoadingAppraisalValues = true;
        });

        const values = await this.macroInteractor.getAppraisalValues(appraisalIdOrValues, [this.question.uuid]);

        if (values.length === 1) {
            this.fillValueMap(values[0].values);
            this.flashMessageBroadcaster.broadcast('De antwoorden zijn succesvol ingevuld.', Type.Success);
        } else if (values.length === 0) {
            this.flashMessageBroadcaster.broadcast(
                'We hebben helaas geen antwoorden gevonden voor deze vraag binnen de gekozen taxatie.',
                Type.Danger
            );
        }

        runInAction(() => {
            this.isLoadingAppraisalValues = false;

            if (values.length > 1) {
                this.appraisalFillValues = values;
            } else {
                this.isCopyModalOpen = false;
            }
        });
    }

    @action
    public setIsSuperMacrosModelOpen(isOpen: boolean) {
        this.isAddModalOpen = isOpen;
        this.isPopperOpen = false;
    }

    @action
    public setIsCopyModalOpen(isOpen: boolean) {
        this.isCopyModalOpen = isOpen;
        this.isPopperOpen = false;
    }
}
