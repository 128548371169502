import {NetworkStatus, NetworkStatusProvider} from '../../../../../business/network_status_provider';
import {
    ValidationConclusion,
    ValidationConclusionProvider,
} from '../../../../../business/validation/validation_conclusion_provider';
import {computed, makeObservable, observable, runInAction} from 'mobx';

import {AnswerInteractor} from '../../../../../business/answering/answer_interactor';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {RootGroupQuestionType} from '../../../../../enum/question_type';

export class ValidationErrorsConceptReportPresenter implements Presenter {
    public conceptReportQuestion: Question | null = null;

    @observable public canViewConceptReport = false;
    @observable.ref public validationConclusion: ValidationConclusion | null = null;
    @observable public networkStatus: NetworkStatus = NetworkStatus.OFFLINE;

    private subscriptions = new CompositeSubscription();

    constructor(
        private questionSet: QuestionSet,
        private answerInteractor: AnswerInteractor,
        private validationConclusionProvider: ValidationConclusionProvider,
        private networkStatusProvider: NetworkStatusProvider
    ) {
        this.conceptReportQuestion =
            this.questionSet.findQuestionsByType(RootGroupQuestionType.CONCEPT_REPORT)[0] ?? null;

        if (this.conceptReportQuestion === null) {
            this.canViewConceptReport = false;
        }
        makeObservable(this);
    }

    @computed
    public get hasError(): boolean {
        return this.validationConclusion?.hasValidationErrors ?? false;
    }

    public async mount(): Promise<void> {
        //Force submitting before trying to conclude anything
        await this.answerInteractor.submit();

        this.subscriptions.add(
            this.validationConclusionProvider.stream.subscribe((validationConclusion) => {
                runInAction(() => {
                    this.validationConclusion = validationConclusion;
                });
            })
        );

        this.subscriptions.add(
            this.networkStatusProvider.status().subscribe((networkStatus) => {
                runInAction(() => {
                    this.networkStatus = networkStatus;
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
