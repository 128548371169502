export enum ObjectUsageType {
    UNINHABITED = 'Onbewoond',
    RENTED = 'Verhuurd',
    IN_USE_WITH_OWNER = 'In gebruik bij de eigenaar',
    IN_USE_WITH_OTHER = 'Op een andere wijze in gebruik bij anderen dan de eigenaar',
    IN_USE_IN_UNKNOWN_MANNER = 'Op onbekende wijze in gebruik',
    PARTIALLY_IN_USE_WITH_OWNER = 'Deels bewoond door eigenaar en deels door anderen',
    RENTED_PER_ROOM = 'Per kamer verhuurd',
    IN_USE_WITH_OWNER_PARTIALLY_EMPTY = 'Deels bewoond door eigenaar en deels leeg',
    RENTED_PARTIALLY_EMPTY = 'Deels verhuurd en deels leeg',
}
