import * as React from 'react';

interface OwnProps {
    name: string;
    label: string;
    value: number | null;
    checkboxValue: string;
    checkboxLabel: string;
    checkboxChecked: boolean;
    isRequired?: boolean;
    prepend?: string;
    badge?: JSX.Element | null;
    disabled?: boolean;
    hint?: string;
    onChange: (e: string) => void;
}

export class NumberWithCheckboxInput extends React.Component<OwnProps> {
    private renderInput() {
        const input = (
            <span className="row">
                <span className="col-6">
                    <input
                        id={this.props.name}
                        name={this.props.name}
                        className="form-control"
                        type="number"
                        step="1"
                        pattern="\d*"
                        inputMode="numeric"
                        disabled={this.props.disabled || this.props.checkboxChecked}
                        value={this.props.value || 0}
                        onChange={(e) => this.props.onChange(e.target.value)}
                    />
                </span>
                <span className="col-6">
                    <input
                        id={this.props.checkboxLabel}
                        name={this.props.checkboxLabel}
                        className="form-control"
                        type="checkbox"
                        disabled={this.props.disabled}
                        checked={this.props.checkboxChecked}
                        value={this.props.checkboxValue}
                        onChange={(e) => this.props.onChange(e.target.checked ? e.target.value : String(0))}
                    />
                    <label htmlFor={this.props.checkboxLabel}>{this.props.checkboxLabel}</label>
                </span>
            </span>
        );

        if (this.props.prepend) {
            return (
                <span className="input-group">
                    <span className="input-group-prepend">
                        <span className="input-group-text">{this.props.prepend}</span>
                    </span>
                    {input}
                </span>
            );
        }
        return input;
    }

    private renderInputWithBadge() {
        return (
            <span className="row">
                <span className="col-8">{this.renderInput()}</span>
                <span className="col-4">
                    <span className="form-badge">{this.props.badge}</span>
                </span>
            </span>
        );
    }

    public render() {
        return (
            <span className="form-group">
                <label htmlFor={this.props.name}>
                    {this.props.label}
                    {this.props.isRequired ? <span className="form-label-highlight">*</span> : null}
                </label>
                {this.props.badge === undefined ? this.renderInput() : this.renderInputWithBadge()}
                {this.props.hint !== undefined ? (
                    <small className="d-inline-block text-secondary">{this.props.hint}</small>
                ) : null}
            </span>
        );
    }
}
