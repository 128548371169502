import React from 'react';

interface OwnProps {
    children?: React.ReactNode;
}

export function ColoredHeaderCard({children}: OwnProps) {
    return (
        <div className="popup-card-content popup-card-header popup-card-colored-header">
            <h1>{children}</h1>
        </div>
    );
}
