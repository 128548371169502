import {UppyFile} from '@uppy/core';
import {normalizeString} from '../support/normalize_string';

/**
 * Uppy signing request
 * @param file
 */
export async function requestSignedUrl(file: UppyFile) {
    const response = await window.fetch(`/upload-sign-request`, {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify({
            path: file.meta.path,
            filename: normalizeString(file.name),
            type: file.type,
        }),
        headers: {
            'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        const result = await response.json();
        return {
            method: result.method,
            url: result.url,
            fields: result.fields,
            headers: result.headers,
        };
    } else {
        throw new Error(response.status + '; ' + response.statusText);
    }
}
