import {filter, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ServerTime, ServerTimeInteractor} from './server_time_interactor';
import {FetchResult, rxFetch, SuccessFetchResult} from './support/rx_fetch';

interface ServerTimeResponse {
    time: number;
}

interface ServerTimeResult {
    offsetMillis: number;
    queryDurationMillis: number;
    weight: number;
}

export class SingleServerTimeInteractor implements ServerTimeInteractor {
    public serverTimes(): Observable<ServerTime> {
        return rxFetch<ServerTimeResponse>('/ajax/time', {
            method: 'GET',
        }).pipe(
            filter<FetchResult<ServerTimeResponse>, SuccessFetchResult<ServerTimeResponse>>(
                (
                    serverTimeResult: FetchResult<ServerTimeResponse>
                ): serverTimeResult is SuccessFetchResult<ServerTimeResponse> => {
                    return serverTimeResult.error === null && serverTimeResult.data !== null;
                }
            ),
            map((serverTimeResult: SuccessFetchResult<ServerTimeResponse>) => {
                const now = new Date().getTime();
                return {
                    offsetMillis: now - serverTimeResult.data.time + serverTimeResult.duration / 2,
                    queryDurationMillis: serverTimeResult.duration,
                    weight: 1,
                } as ServerTimeResult;
            })
        );
    }
}
