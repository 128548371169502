import {ConditionsInteractor} from '../../conditions/conditions_interactor';
import {Question} from '../../../models/question';
import {Answer} from '../../../models/answer';

export interface IsSoftRequiredCalculator {
    isSoftRequired(question: Question, answer: Answer | null): boolean;
}

export class DefaultIsSoftRequiredCalculator implements IsSoftRequiredCalculator {
    constructor(private conditionsInteractor: ConditionsInteractor) {}

    public isSoftRequired(question: Question, answer: Answer | null): boolean {
        return this.isSoftRequiredThroughConditions(question, answer);
    }

    private isSoftRequiredThroughConditions(question: Question, answer: Answer | null) {
        if (answer === null) {
            return false;
        }
        const effectStates = this.conditionsInteractor.get(question.uuid, answer.parentUuid);
        return effectStates.reduce((isRequired, effectState) => {
            if (effectState.effect.task === 'soft_required' && effectState.enabled) {
                return true;
            }
            return isRequired;
        }, false);
    }
}
