import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';

import {TechnicalReference} from '../../../enum/technical_reference';
import {Answer} from '../../../models/answer';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {AnswerController} from '../../answering/answer_controller';
import {AnswerValidator} from '../answer_validator';
import {buildQuestionAnswerTree, QuestionAnswerPair} from '../../../../support/question_answer_tree';
import {findChildRecursiveByPredicate, TreeItem} from '../../../../support/generic_tree';

export class VVEValidator implements AnswerValidator {
    constructor(private questionSet: QuestionSet, private answerController: AnswerController) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (question.technicalReference !== TechnicalReference.CADASTRAL_APPARTEMENTSRECHT) {
            return [];
        }

        const bijdrageQuestion = this.questionSet.findChildByPredicateRecursive(
            question,
            (q) => q.technicalReference === TechnicalReference.CADASTRAL_APPARTEMENTSRECHT_VVE_BIJDRAGE
        );

        const bijdrageNodigQuestion = this.questionSet.findChildByPredicateRecursive(
            question,
            (q) => q.technicalReference === TechnicalReference.CADASTRAL_APPARTEMENTSRECHT_VVE_BIJDRAGE_NODIG
        );

        const bijdrageNodigAnswerOption = bijdrageNodigQuestion?.answerOptions.find(
            (ao) => ao.contents === 'Ja' || ao.reportValue === 'ja'
        );

        if (!bijdrageQuestion || !bijdrageNodigQuestion || !bijdrageNodigAnswerOption || !answer) {
            return [];
        }

        const answerTree = buildQuestionAnswerTree(this.questionSet, this.answerController.answers(), answer);
        const bijdrageAnswer = findChildRecursiveByPredicate(
            answerTree,
            (n) => n.question.uuid === bijdrageQuestion.uuid
        );

        const bijdrageNodigAnswer = findChildRecursiveByPredicate(
            answerTree,
            (n) => n.question.uuid === bijdrageNodigQuestion.uuid
        );

        if (
            !this.isValidAnswerTree(bijdrageAnswer) ||
            !bijdrageNodigAnswer?.item.answer ||
            (bijdrageNodigAnswerOption.id === bijdrageNodigAnswer.item.answer.answerOptionId &&
                (!bijdrageAnswer?.item.answer ||
                    bijdrageAnswer.item.answer.isDeleted ||
                    bijdrageAnswer.item.answer.contents === null))
        ) {
            return [
                {
                    type: ValidationMessageType.TAXAPI,
                    importance: ValidationMessageImportance.ERROR,
                    question: question,
                    answer: answer,
                    messages: [],
                    fallbackMessage: 'De VvE gegevens zijn niet (volledig) ingevuld.',
                    floorInfo: null,
                },
            ];
        }

        return [];
    }

    private isValidAnswerTree(item: TreeItem<QuestionAnswerPair> | null): boolean {
        if (!item) {
            return true;
        }

        // If there is a question defined but no answer, then the question answer tree can do some weird things with iterations
        // But in this case, we know for certain that the VVE widget has not been opened yet
        if (item.item.question && !item.item.answer) {
            return false;
        }

        return this.isValidAnswerTree(item.parent);
    }
}
