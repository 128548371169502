import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from '../../simple/simple_question_presenter';

import {IteratorPhotoGroupSmallPresenter} from './iterator_photo_group_small_presenter';
import {QuestionContainer} from '../../question_container';
import {findChildrenForQuestion} from '../../../../support/question_filtering';
import {observer} from 'mobx-react';
import {QuestionLoader} from '../../../../components/question_loader';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const IteratorPhotoGroupSmall: React.FC<OwnProps> = observer(function IteratorPhotoGroupSmall(props) {
    const presenter = usePresenter(
        (container) =>
            new IteratorPhotoGroupSmallPresenter(
                container.business.blobCacheInteractor(),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );
    const answer = presenter.answer;
    if (answer === undefined) {
        return <QuestionLoader />;
    }

    return (
        <>
            {findChildrenForQuestion(props.question, props.questionSet).map((child) => (
                <QuestionContainer
                    key={child.uuid}
                    appraisal={props.appraisal}
                    question={child}
                    disabled={props.disabled}
                    questionSet={props.questionSet}
                    parentAnswerUuid={answer.uuid}
                    validationErrors={props.validationErrors}
                    validationMessages={props.validationMessages}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    adaptedDefaultValues={props.adaptedDefaultValues}
                    hiddenQuestionTypes={props.hiddenQuestionTypes}
                    pagePartsSet={props.pagePartsSet}
                    activePagePart={props.activePagePart}
                    renderingContext={props.renderingContext}
                    questionRenderingData={props.questionRenderingData}
                />
            ))}
        </>
    );
});
