import {combineLatest, Observable, of} from 'rxjs';

import {map, startWith} from 'rxjs/operators';
import {buildQuestionAnswerTrees} from '../../support/question_answer_tree';
import {sortByCreatedAt} from '../../support/sort_answer';
import {CopyReferenceType} from '../enum/copy_reference_type';
import {NormalQuestionType} from '../enum/question_type';
import {Answer} from '../models/answer';
import {Question} from '../models/question';
import {QuestionSet} from '../models/question_set';
import {AnswerController} from './answering/answer_controller';
import {QuestionEffectInteractor} from './conditions/question_effects_interactor';

export interface DuplicationSourcesProvider {
    stream(question: Question): Observable<DuplicationSource[]>;
    duplicate(source: DuplicationSource, parentAnswerUuid: string, targetQuestion: Question): boolean;
}

export interface DuplicationSource {
    type: DuplicationSourceType;
    label: string;
    answer: Answer;
}

export enum DuplicationSourceType {
    ITERATION = 'iteration',
    COPY_REFERENCE = 'copy-reference',
}

export class DefaultDuplicationSourcesProvider implements DuplicationSourcesProvider {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectsInteractor: QuestionEffectInteractor
    ) {}

    public stream(question: Question): Observable<DuplicationSource[]> {
        return combineLatest([this.streamDuplicableIterations(question), this.streamCopyReferences(question)]).pipe(
            map((duplicationSourceArrays) => duplicationSourceArrays.flat())
        );
    }

    public duplicate(source: DuplicationSource, parentAnswerUuid: string, targetQuestion: Question): boolean {
        const question = this.questionSet.findQuestionByUuid(source.answer.questionUuid);
        if (!question) {
            return false;
        }

        const allAnswers = this.answerController.answers();
        const trees = buildQuestionAnswerTrees(this.questionSet, allAnswers, question);
        const sourceTree = trees.find((tree) => tree.item.answer?.uuid === source.answer.uuid);

        if (!sourceTree) {
            return false;
        }

        if (source.type === DuplicationSourceType.ITERATION) {
            this.answerController.duplicateTree(sourceTree, parentAnswerUuid);
            return true;
        } else if (source.type === DuplicationSourceType.COPY_REFERENCE) {
            const newTree = this.answerController.copyTree(sourceTree, parentAnswerUuid, (origQuestion) => {
                if (origQuestion.copyReference !== null) {
                    if (targetQuestion.copyReference === origQuestion.copyReference) {
                        return targetQuestion;
                    }

                    return this.questionSet.findChildByPredicateRecursive(
                        targetQuestion,
                        (q) => q.copyReference === origQuestion.copyReference
                    );
                }

                const trace = this.questionSet.findChildrenPathByPredicateRecursive(
                    sourceTree.item.question,
                    (q) => q === origQuestion
                );

                if (trace === null) {
                    return null;
                }

                return this.traverseSimilarQuestions(targetQuestion, trace);
            });
            return newTree !== null;
        }

        return false;
    }

    private traverseSimilarQuestions(targetQuestion: Question, trace: Question[]): Question | null {
        if (trace.length === 0) {
            return targetQuestion;
        }

        const children = this.questionSet.findChildQuestionsByParentUuid(targetQuestion.uuid);
        const [sourceQuestion, ...childTrace] = trace;
        for (const question of children) {
            if (question.type === sourceQuestion.type && question.contents === sourceQuestion.contents) {
                const res = this.traverseSimilarQuestions(question, childTrace);
                if (res !== null) {
                    return res;
                }
            }
        }

        return null;
    }

    private streamDuplicableIterations(question: Question): Observable<DuplicationSource[]> {
        if (!question.isDuplicable) {
            return of([]);
        }

        return this.answerController.answersForQuestionUuidStream(question.uuid).pipe(
            startWith([]),
            map((answers) => this.answerController.filterDeleted(answers)),
            map((answers) =>
                answers.filter(
                    (answer) => !this.questionEffectsInteractor.isHidden(answer.questionUuid, answer.parentUuid)
                )
            ),
            map((answers) => answers.sort((a, b) => sortByCreatedAt(a, b))),
            map((answers) =>
                answers.map((a, index) => ({
                    type: DuplicationSourceType.ITERATION,
                    label: `${question.contents} ${index + 1}`,
                    answer: a,
                }))
            )
        );
    }

    private streamCopyReferences(question: Question): Observable<DuplicationSource[]> {
        if (!question.copyReference) {
            return of([]);
        }

        const copyReference = question.copyReference;
        const copyReferenceQuestions = this.questionSet.findQuestionsByCopyReference(copyReference);

        return this.answerController.answersForQuestionUuidsStream(copyReferenceQuestions.map((q) => q.uuid)).pipe(
            startWith([]),
            map((answers) => this.answerController.filterDeleted(answers)),
            map((answers) =>
                answers.filter(
                    (answer) => !this.questionEffectsInteractor.isHidden(answer.questionUuid, answer.parentUuid)
                )
            ),
            map((answers) => answers.sort((a, b) => sortByCreatedAt(a, b))),
            map((answers) =>
                answers.map((a) => ({
                    type: DuplicationSourceType.COPY_REFERENCE,
                    label: this.getCopyReferenceLabel(copyReference, a),
                    answer: a,
                }))
            )
        );
    }

    private getCopyReferenceLabel(copyReference: CopyReferenceType, answer: Answer): string {
        switch (copyReference) {
            case CopyReferenceType.GROUP_RECHT: {
                const question = this.questionSet.findQuestionByUuid(answer.questionUuid);
                let currentAnswer: Answer | null = answer;
                let currentQuestion: Question | undefined = question;
                while (currentAnswer !== null && currentAnswer?.parentUuid !== null) {
                    const parentAnswer = this.answerController.byUuid(currentAnswer.parentUuid);
                    const parentQuestion = parentAnswer
                        ? this.questionSet.findQuestionByUuid(parentAnswer.questionUuid)
                        : undefined;

                    if (parentQuestion?.type === NormalQuestionType.WIDGET_GROUP && currentQuestion !== undefined) {
                        const index = this.answerController
                            .answersForQuestionUuid(currentQuestion.uuid)
                            .sort(sortByCreatedAt)
                            .findIndex((a) => a.uuid === currentAnswer?.uuid);

                        return `${currentQuestion.contents} ${index + 1} > ${question?.contents ?? 'Onbekend'}`;
                    } else {
                        currentAnswer = parentAnswer;
                        currentQuestion = parentQuestion;
                    }
                }

                return 'Onbekend';
            }
            default: {
                return this.questionSet.findQuestionByUuid(answer.questionUuid)?.contents ?? 'Onbekend';
            }
        }
    }
}
