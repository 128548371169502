import * as React from 'react';

import {observer} from 'mobx-react';
import {Select} from '../../components/select';
import NumberFormat from 'react-number-format';

interface OwnProps {
    settings: EnergeticSheetSettings;
    updateSettings: (settings: EnergeticSheetSettings) => void;
}

export enum EnergeticSheetSearchCriteria {
    LOWEST_INVESTMENT = 'lowest_investment',
    LOWEST_ENERGY_COSTS = 'lowest_energy_costs',
    HIGHEST_FINANCIAL_RETURN = 'highest_financial_return',
    HIGHEST_BARE = 'highest_bare',
    LOWEST_BENG2 = 'lowest_beng2',
}

export interface EnergeticSheetSettings {
    targetLabel: string | null;
    maxInvestment: number | string | null;
    searchCriteria: EnergeticSheetSearchCriteria;
    inhabitants: number | string | null;
    gasUsage: number | string | null;
    electricityUsage: number | string | null;
}

const EnergyLabelOptions = ['A++++', 'A+++', 'A++', 'A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G'].map((v) => ({
    label: v,
    value: v,
}));

export const EnergeticSheetSettings: React.FC<OwnProps> = observer(({settings, updateSettings}) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <label>Doel kiezen</label>
                </div>
                <div className="col-sm-4 col-12">
                    <div className="form-group form-icon-radio">
                        <input
                            type="radio"
                            name="goal"
                            value="auto"
                            id="goal-auto"
                            checked={settings.targetLabel === null && settings.maxInvestment === null}
                            onChange={() => updateSettings({...settings, targetLabel: null, maxInvestment: null})}
                        />
                        <label htmlFor="goal-auto">
                            <span className="ion-md-podium"></span>
                            <small>Automatisch bepalen</small>
                        </label>
                    </div>
                </div>
                <div className="col-sm-4 col-12">
                    <div className="form-group form-icon-radio">
                        <input
                            type="radio"
                            name="goal"
                            value="energy-label"
                            id="goal-energy-label"
                            checked={settings.targetLabel !== null}
                            onChange={() =>
                                updateSettings({
                                    ...settings,
                                    maxInvestment: null,
                                    targetLabel: settings.targetLabel ?? 'A',
                                })
                            }
                        />
                        <label htmlFor="goal-energy-label">
                            <span className="ion-md-flash"></span>
                            <small>Een energielabel bereiken</small>
                        </label>
                    </div>
                </div>
                <div className="col-sm-4 col-12">
                    <div className="form-group form-icon-radio">
                        <input
                            type="radio"
                            name="goal"
                            value="investment"
                            id="goal-investment"
                            checked={settings.maxInvestment !== null}
                            onChange={() =>
                                updateSettings({
                                    ...settings,
                                    targetLabel: null,
                                    maxInvestment: settings.maxInvestment ?? 10000,
                                })
                            }
                        />
                        <label htmlFor="goal-investment">
                            <span className="ion-logo-euro"></span>
                            <small>Een budget benutten</small>
                        </label>
                    </div>
                </div>
                {settings.targetLabel !== null ? (
                    <div className="col-12">
                        <label>Te bereiken energielabel</label>
                        <Select
                            id="goal-target-label"
                            isDisabled={false}
                            value={settings.targetLabel}
                            onChange={(newValue) => updateSettings({...settings, targetLabel: newValue})}
                            options={EnergyLabelOptions}
                            isClearable={false}
                        />
                    </div>
                ) : null}
                {settings.maxInvestment !== null ? (
                    <div className="col-12">
                        <label>Maximaal te besteden bedrag</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">&euro;</div>
                            </div>
                            <NumberFormat
                                value={settings.maxInvestment}
                                displayType="input"
                                type="text"
                                className="form-control"
                                decimalScale={0}
                                allowNegative={false}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    updateSettings({...settings, maxInvestment: event.target.value})
                                }
                            />
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="row">
                <div className="col-12">
                    <label>Energieverbruik opgeven</label>
                </div>
                <div className="col-12">
                    <div className="answer-option">
                        <input
                            type="radio"
                            id="energy-auto-calc"
                            name="energy-calc"
                            value="auto-calc"
                            checked={
                                settings.inhabitants === null &&
                                settings.electricityUsage === null &&
                                settings.gasUsage === null
                            }
                            onChange={() =>
                                updateSettings({
                                    ...settings,
                                    inhabitants: null,
                                    electricityUsage: null,
                                    gasUsage: null,
                                })
                            }
                        />
                        <label htmlFor={'energy-auto-calc'}>Automatisch berekenen</label>
                    </div>
                </div>
                <div className="col-12">
                    <div className="answer-option">
                        <input
                            type="radio"
                            id="energy-calc-inhabitants"
                            name="energy-calc"
                            value="calc-inhabitants"
                            checked={settings.inhabitants !== null}
                            onChange={() =>
                                updateSettings({
                                    ...settings,
                                    inhabitants: '',
                                    electricityUsage: null,
                                    gasUsage: null,
                                })
                            }
                        />
                        <label htmlFor={'energy-calc-inhabitants'}>Berekenen op basis van aantal bewoners</label>
                    </div>
                </div>
                <div className="col-12">
                    <div className="answer-option">
                        <input
                            type="radio"
                            id="energy-manual-entry"
                            name="energy-calc"
                            value="manual-entry"
                            checked={settings.electricityUsage !== null && settings.gasUsage !== null}
                            onChange={() =>
                                updateSettings({
                                    ...settings,
                                    inhabitants: null,
                                    electricityUsage: '',
                                    gasUsage: '',
                                })
                            }
                        />
                        <label htmlFor={'energy-manual-entry'}>Handmatig invoeren</label>
                    </div>
                </div>
                {settings.inhabitants !== null ? (
                    <div className="col-12">
                        <label>Aantal bewoners</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text ion-md-people" />
                            </div>
                            <NumberFormat
                                value={settings.inhabitants}
                                displayType="input"
                                type="text"
                                className="form-control"
                                decimalScale={0}
                                allowNegative={false}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    updateSettings({...settings, inhabitants: event.target.value})
                                }
                            />
                        </div>
                    </div>
                ) : null}
                {settings.electricityUsage !== null ? (
                    <div className="col-12">
                        <label>Jaarverbruik Elektriciteit</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text ion-md-flash" />
                            </div>
                            <NumberFormat
                                value={settings.electricityUsage}
                                displayType="input"
                                type="text"
                                className="form-control"
                                decimalScale={0}
                                allowNegative={false}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    updateSettings({...settings, electricityUsage: event.target.value})
                                }
                            />
                            <div className="input-group-append">
                                <div className="input-group-text">kWh</div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {settings.gasUsage !== null ? (
                    <div className="col-12">
                        <label>Jaarverbruik Gas</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text ion-md-speedometer" />
                            </div>
                            <NumberFormat
                                value={settings.gasUsage}
                                displayType="input"
                                type="text"
                                className="form-control"
                                decimalScale={0}
                                allowNegative={false}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    updateSettings({...settings, gasUsage: event.target.value})
                                }
                            />
                            <div className="input-group-append">
                                <div className="input-group-text">m³</div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="row">
                <div className="col-12">
                    <label>Zoekgedrag</label>
                </div>
                <div className="col-sm-4 col-12">
                    <div className="form-group form-icon-radio">
                        <input
                            type="radio"
                            name="search-criteria"
                            value="auto"
                            id="search-criteria-auto"
                            checked={settings.searchCriteria === EnergeticSheetSearchCriteria.LOWEST_INVESTMENT}
                            onChange={() =>
                                updateSettings({
                                    ...settings,
                                    searchCriteria: EnergeticSheetSearchCriteria.LOWEST_INVESTMENT,
                                })
                            }
                        />
                        <label htmlFor="search-criteria-auto">
                            <span className="ion-md-cash"></span>
                            <small>
                                {settings.maxInvestment !== null ? 'Budget benutten' : 'Laagste investeringskosten'}
                            </small>
                        </label>
                    </div>
                </div>
                <div className="col-sm-4 col-12">
                    <div className="form-group form-icon-radio">
                        <input
                            type="radio"
                            name="search-criteria"
                            value="energy-label"
                            id="search-criteria-energy-label"
                            checked={settings.searchCriteria === EnergeticSheetSearchCriteria.LOWEST_ENERGY_COSTS}
                            onChange={() =>
                                updateSettings({
                                    ...settings,
                                    searchCriteria: EnergeticSheetSearchCriteria.LOWEST_ENERGY_COSTS,
                                })
                            }
                        />
                        <label htmlFor="search-criteria-energy-label">
                            <span className="ion-md-flame"></span>
                            <small>Laagste energiekosten</small>
                        </label>
                    </div>
                </div>
                <div className="col-sm-4 col-12">
                    <div className="form-group form-icon-radio">
                        <input
                            type="radio"
                            name="search-criteria"
                            value="investment"
                            id="search-criteria-investment"
                            checked={settings.searchCriteria === EnergeticSheetSearchCriteria.HIGHEST_FINANCIAL_RETURN}
                            onChange={() =>
                                updateSettings({
                                    ...settings,
                                    searchCriteria: EnergeticSheetSearchCriteria.HIGHEST_FINANCIAL_RETURN,
                                })
                            }
                        />
                        <label htmlFor="search-criteria-investment">
                            <span className="ion-md-trending-up"></span>
                            <small>Hoogste rendement</small>
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
});
