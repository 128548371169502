import {Observable, of} from 'rxjs';

import {AnswerController} from './answering/answer_controller';
import {AnswerOption} from '../models/answer_option';
import {GlobalProvider} from '../../business/global_provider';
import {QuestionEffectInteractor} from './conditions/question_effects_interactor';
import {QuestionSet} from '../models/question_set';
import {TechnicalReference} from '../enum/technical_reference';
import {getNewestAnswer} from '../../support/get_newest_answer';
import {map} from 'rxjs/operators';

export interface EnergyLabelProvider {
    stream(): Observable<string | null>;
}

export class DefaultEnergyLabelProvider implements EnergyLabelProvider {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor,
        private globalProvider: GlobalProvider
    ) {}

    public stream(): Observable<string | null> {
        const energyLabelQuestions = this.questionSet.findQuestionsByTechnicalReference(
            TechnicalReference.ENERGY_LABEL
        );
        if (energyLabelQuestions.length === 0) {
            return of(null);
        }

        const answerOptions = energyLabelQuestions.flatMap((q) => q.answerOptions);

        return this.answerController.answersForQuestionUuidsStream(energyLabelQuestions.map((q) => q.uuid)).pipe(
            //Filter deleted and hidden answers
            map((answers) => this.answerController.filterDeleted(answers)),
            map((answers) =>
                answers.filter((answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid))
            ),
            map((answers) => {
                const answer = getNewestAnswer(answers);
                if (answer && answer.answerOptionId) {
                    return answerOptions.find((a: AnswerOption) => a.id === answer.answerOptionId)?.contents ?? null;
                } else {
                    return answer?.contents ?? this.globalProvider.global.objectEnergyLabel ?? null;
                }
            })
        );
    }
}
