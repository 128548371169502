import {AttachmentsDetails} from '../../models/attachment_details';

export interface ApiAttachmentsDetails {
    automation_finished: boolean;
    attachments: {
        detail_items: {
            question_uuid: string | null;
            answer_uuid: string | null;
            answer_updated_at: string | null;
            parent_answer_uuid: string | null;
            name: string | null;
            current_file: string | null;
            current_file_url: string | null;
        }[];
    }[];
}

export function apiAttachmentsDetailsToAttachmentsDetails(
    apiValuationOverview: ApiAttachmentsDetails
): AttachmentsDetails {
    return {
        automationFinished: apiValuationOverview.automation_finished,
        attachments: apiValuationOverview.attachments.map((apiAttachment) => ({
            detailItems: apiAttachment.detail_items.map((apiDetailItem) => ({
                questionUuid: apiDetailItem.question_uuid,
                answerUuid: apiDetailItem.answer_uuid,
                answerUpdatedAt: apiDetailItem.answer_updated_at ? new Date(apiDetailItem.answer_updated_at) : null,
                parentAnswerUuid: apiDetailItem.parent_answer_uuid,
                name: apiDetailItem.name,
                currentFile: apiDetailItem.current_file,
                currentFileUrl: apiDetailItem.current_file_url,
            })),
        })),
    };
}
