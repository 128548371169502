import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';
import {isEmpty, isNumeric} from '../../../../../../support/util';

import {AlternativeAdaptedDefaultAnswer} from '../components/alternative_adapted_default_answer';
import {Label} from '../components/label';
import {ValidationMessages} from '../components/validation_messages';
import {classNames} from '../../../../../../support/classnames';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {isMobile} from '../../../../../../support/check_mobile';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const YearQuestion: React.FC<OwnProps> = observer(function YearQuestion(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));

    if (presenter.isHidden) {
        return null;
    }

    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'automation-filled': presenter.filledByAutomator !== null,
            })}
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
        >
            <Label
                for={presenter.name}
                question={props.question}
                parentAnswerUuid={props.parentAnswerUuid}
                iteration={props.iteration}
                appraisal={props.appraisal}
                questionSet={props.questionSet}
                disabled={props.disabled || presenter.isDisabled}
                hideLabel={props.hideLabel}
                showLabel={props.question.showLabel}
                renderingContext={props.renderingContext}
            />
            <AlternativeAdaptedDefaultAnswer
                adaptedDefaultValue={props.adaptedDefaultValue}
                currentAnswer={presenter.answer}
                disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                onChange={(value) => presenter.onChange(value)}
            />
            <div className="input-group">
                <div className="input-group-prepend">
                    <div className="input-group-text ion-md-calendar">&nbsp;</div>
                </div>
                {isMobile() ? (
                    <input
                        id={presenter.name}
                        name={presenter.name}
                        className="form-control"
                        type="number"
                        maxLength={4}
                        disabled={props.disabled || presenter.isDisabled}
                        value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                        onChange={(e) => presenter.onChange(e.target.value, false)}
                        onBlur={() => presenter.onTouched()}
                    />
                ) : (
                    <input
                        id={presenter.name}
                        name={presenter.name}
                        className="form-control"
                        type="text"
                        inputMode="numeric"
                        maxLength={4}
                        disabled={props.disabled || presenter.isDisabled}
                        value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                        onChange={(e) => {
                            const value = e.target.value;

                            if (value.includes('-')) {
                                // Block negatives by ignoring - input
                                return;
                            }

                            if (!isEmpty(value)) {
                                if (isNumeric(value)) {
                                    presenter.onChange('' + parseInt(value, 10), false);
                                } else if (!isNumeric(presenter.answer?.contents)) {
                                    presenter.onChange('', false);
                                }
                            } else {
                                presenter.onChange('', false);
                            }
                        }}
                        onBlur={() => presenter.onTouched()}
                    />
                )}
            </div>
            <ValidationMessages
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={presenter.answer}
                validationMessages={filterValidationMessages(
                    props.question,
                    presenter.answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
        </div>
    );
});
