import * as React from 'react';
import {useState} from 'react';
import {MersenneTwister} from '../../../../../../../support/seeded_random';
import {range} from '../../../../../../../support/array';

export const AreaSummaryDummy: React.FunctionComponent = React.memo(() => {
    const [random] = useState(new MersenneTwister(502));
    const genBetween = React.useCallback(
        (min: number, max: number) => {
            return random.randomBetweenFromArray([25, 50, 75, 100, 200, 300, 400, 500], min, max);
        },
        [random]
    );

    return (
        <div>
            <div className="placeholder d-block placeholder-width-200 placeholder-opaque-40 mt-1" />
            <br />
            <table className=" w-100">
                <tr>
                    <td>
                        <div className="placeholder d-block placeholder-width-100 placeholder-opaque-40 mt-1" />
                    </td>
                    <td>
                        <div className="placeholder d-block placeholder-width-100 placeholder-opaque-40 mt-1" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="placeholder d-block placeholder-width-200 placeholder-opaque-40 mt-1" />
                    </td>
                    <td>
                        <div className="placeholder d-block placeholder-width-100 placeholder-opaque-40 mt-1" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <br />
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="placeholder d-block placeholder-width-75 placeholder-opaque-40 mt-1" />
                    </td>
                    <td>
                        <div className="placeholder d-block placeholder-width-300 placeholder-opaque-40 mt-1" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="placeholder d-block placeholder-width-50 placeholder-opaque-40 mt-1" />
                    </td>
                    <td>
                        <div className="placeholder d-block placeholder-width-200 placeholder-opaque-40 mt-1" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="placeholder d-block placeholder-width-100 placeholder-opaque-40 mt-1" />
                    </td>
                    <td>
                        <div className="placeholder d-block placeholder-width-100 placeholder-opaque-40 mt-1" />
                    </td>
                </tr>
            </table>

            <br />
            <table className="w-100">
                <tbody>
                    <tr className="placeholder-table-header">
                        <td>
                            <br />
                        </td>
                        <td />
                        <td />
                        <td />
                    </tr>
                    {range(0, 8).map((i) => (
                        <tr key={i}>
                            <td>
                                <div
                                    className={`placeholder d-block placeholder-width-${genBetween(
                                        50,
                                        100
                                    )} placeholder-opaque-40 mt-1`}
                                ></div>
                            </td>
                            <td>
                                <div
                                    className={`placeholder d-block placeholder-width-${genBetween(
                                        100,
                                        400
                                    )} placeholder-opaque-40 mt-1`}
                                ></div>
                            </td>
                            <td>
                                <div
                                    className={`placeholder d-block placeholder-width-${genBetween(
                                        25,
                                        75
                                    )} placeholder-opaque-40 mt-1`}
                                ></div>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>
                            <br />
                        </td>
                    </tr>
                    <tr className="placeholder-table-header">
                        <td>
                            <br />
                        </td>
                        <td />
                        <td />
                        <td />
                    </tr>
                    {range(0, 6).map((i) => (
                        <tr key={i}>
                            <td>
                                <div
                                    className={`placeholder d-block placeholder-width-${genBetween(
                                        50,
                                        100
                                    )} placeholder-opaque-40 mt-1`}
                                ></div>
                            </td>
                            <td>
                                <div
                                    className={`placeholder d-block placeholder-width-${genBetween(
                                        100,
                                        400
                                    )} placeholder-opaque-40 mt-1`}
                                ></div>
                            </td>
                            <td>
                                <div
                                    className={`placeholder d-block placeholder-width-${genBetween(
                                        25,
                                        75
                                    )} placeholder-opaque-40 mt-1`}
                                ></div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
});
AreaSummaryDummy.displayName = 'AreaSummaryDummy';
