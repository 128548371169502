import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {GivenPreselectionReferenceObject} from '../../../appraising/network/preselected_reference_objects_api';

import {ReferencesWheel} from './wheel';
import React, {useCallback, useMemo, useState} from 'react';
import {ReferenceObjectWheelItem, WithDistance} from './wheel_item';
import {ReferenceSale} from '../../../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v1/models/reference_sale';
import {AltumPreferencesModal} from '../../../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v3/components/modals/altum_preferences_modal';
import {QuestionSet} from '../../../appraising/models/question_set';
import {Appraisal} from '../../../appraising/models/appraisal';
import {AltumPreferences} from '../../../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v3/components/altum_preferences';

interface OwnProps {
    appraisal?: Appraisal;
    questionSet?: QuestionSet;
    forceRequest: () => void;
    objects?: WithDistance<ReferenceSale>[];
    given?: GivenPreselectionReferenceObject;
    loading: boolean;
    error?: boolean;
    accepted: ReferenceSale[];
    accept: (obj: ReferenceSale) => void;
    rejected: ReferenceSale[];
    reject: (obj: ReferenceSale) => void;
    changeObjectType: () => void;
}

const DEFAULT_REFERENCE_OBJECTS_COUNT = 30;

export const ReferencesWheelContainer: React.FC<OwnProps> = ({
    appraisal,
    questionSet,
    forceRequest,
    objects,
    given,
    accepted,
    rejected,
    error,
    loading,
    accept,
    reject,
    changeObjectType,
}: OwnProps) => {
    const acceptedIndices = useMemo(() => {
        return objects
            ? accepted.map((a) => objects.findIndex((obj) => obj.id === a.id && obj.saleQuarter === a.saleQuarter))
            : [];
    }, [objects, accepted]);

    const rejectedIndices = useMemo(() => {
        return objects
            ? rejected.map((r) => objects.findIndex((obj) => obj.id === r.id && obj.saleQuarter === r.saleQuarter))
            : [];
    }, [objects, rejected]);

    const [showPreferences, setShowPreferences] = useState(false);

    const acceptCallback = useCallback(
        (index: number) => {
            if (objects) {
                accept(objects[index]);
            }
        },
        [objects, accept]
    );

    const rejectCallback = useCallback(
        (index: number) => {
            if (objects) {
                reject(objects[index]);
            }
        },
        [objects, reject]
    );

    if (error) {
        return (
            <div className="empty-message appraise">
                <h3>Helaas, fout tijdens het laden van de referenties</h3>
                <span className="spacer-gradient">&nbsp;</span>
            </div>
        );
    }

    let displayedObjects: Partial<WithDistance<ReferenceSale>>[] | undefined = objects;
    if (loading && (displayedObjects === undefined || displayedObjects.length === 0)) {
        displayedObjects = [];
        for (let i = 0; i < DEFAULT_REFERENCE_OBJECTS_COUNT; i++) {
            displayedObjects.push({});
        }
    } else if (objects !== undefined && objects.length === 0) {
        return (
            <div className="empty-message precheck-message appraise">
                <h3>Geen referenties gevonden</h3>
                <span className="spacer-gradient">&nbsp;</span>
                <AltumPreferences
                    appraisal={appraisal}
                    changeObjectType={changeObjectType}
                    questionSet={questionSet}
                    forceRequest={forceRequest}
                    isInModal={false}
                />
            </div>
        );
    }

    return (
        <>
            <div className="preselection-given-object-details">
                {given && (
                    <>
                        <div className="row">
                            <div className="col-12 text-center">
                                <strong>Gegevens taxatie-object</strong>
                                <a
                                    className="ml-2 ion-md-settings"
                                    onClick={() => setShowPreferences(!showPreferences)}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 col-md-2 d-flex justify-content-center">
                                <span className={'badge badge-large d-flex align-items-center'}>
                                    <span className="c-icon-build-year pr-1" />
                                    {given.buildYear}
                                </span>
                            </div>
                            <div className="col-4 col-md-2 d-flex justify-content-center">
                                <span className={'badge badge-large d-flex align-items-center'}>
                                    <span className="c-icon-floor-area pr-1" />
                                    {given.floorArea} m²
                                </span>
                            </div>
                            <div className="col-4 col-md-2 d-flex justify-content-center">
                                <span className={'badge badge-large d-flex align-items-center'}>
                                    <span className="c-icon-plot-area pr-1" />
                                    {given.plotArea} m²
                                </span>
                            </div>
                            <div className="col-6 col-md-4 d-flex justify-content-center">
                                <span className={'badge badge-large d-flex align-items-center'}>
                                    <span className="ion-md-home pr-1" style={{fontSize: 20}} />
                                    {given.objectType}
                                </span>
                            </div>
                            <div className="col-6 col-md-2 d-flex justify-content-center">
                                <span className={'badge badge-large d-flex align-items-center'}>
                                    <span className="ion-md-flash pr-1" style={{fontSize: 20}} />
                                    {given.energyLabel ?? '-'}
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <ReferencesWheel
                accepted={acceptedIndices}
                rejected={rejectedIndices}
                accept={acceptCallback}
                reject={rejectCallback}
                loading={loading}
            >
                {displayedObjects?.map((obj) => (
                    <ReferenceObjectWheelItem key={`${obj.id}-${obj.saleQuarter}`} object={obj} given={given} />
                )) ?? []}
            </ReferencesWheel>
            {objects !== undefined && objects.length > 0 && objects.length === accepted.length + rejected.length && (
                <div className="empty-message appraise pre-check-references-overlay-message">
                    <h3>Je hebt het vooronderzoek afgerond.</h3>
                    <span className="spacer-gradient">&nbsp;</span>
                    <h4>
                        <strong>{accepted.length}</strong> referentie{accepted.length === 1 ? '' : 's'} geaccepteerd
                    </h4>
                    <h4>
                        <strong>{rejected.length}</strong> referentie{rejected.length === 1 ? '' : 's'} afgekeurd
                    </h4>
                </div>
            )}
            {showPreferences && appraisal && questionSet && (
                <AltumPreferencesModal
                    appraisal={appraisal}
                    questionSet={questionSet}
                    forceRequest={forceRequest}
                    onClose={() => setShowPreferences(false)}
                    changeObjectType={() => {
                        changeObjectType();
                        setShowPreferences(false);
                    }}
                />
            )}
        </>
    );
};
