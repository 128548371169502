import React from 'react';

import {classNames} from '../../../support/classnames';
import {BadgeContext} from '../../enum/badge_context';
import {buildYearDiffToBadgeContext} from './badge_context_calculators';

interface OwnProps {
    compact?: boolean;
    buildYear: number;
    referenceBuildYear: number;
}

export function BuildYearBadge(props: OwnProps) {
    let badge: BadgeContext | null = null;
    let diff: number | null = null;

    if (props.referenceBuildYear !== null && props.referenceBuildYear !== 0 && Math.abs(props.buildYear) !== 0) {
        diff = props.buildYear - props.referenceBuildYear;
        badge = buildYearDiffToBadgeContext(diff);
    }

    if (props.referenceBuildYear === null || props.referenceBuildYear === 0) {
        return null;
    }

    return (
        <span className="badge-container">
            {badge && <span className={classNames('badge', badge)}>{diff?.toFixed(0)}</span>}
            {!props.compact ? `(${props.referenceBuildYear})` : null}
        </span>
    );
}
