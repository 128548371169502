import * as React from 'react';

import {
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable';

import {FunctionComponent} from 'react';
import {PagePart} from '../../../models/page_part';
import {SortableChild} from './sortable_child';
import {restrictToParentElement} from '@dnd-kit/modifiers';

interface OwnProps {
    pagePartsConfigurationId: number;
    childPageParts: PagePart[];
    activePagePartUuid: string | null;

    updateRank: (newRank: number, uuid: string) => void;
    onDelete: (uuid: string) => void;
}

export const SortableChildren: FunctionComponent<OwnProps> = ({
    pagePartsConfigurationId,
    childPageParts,
    activePagePartUuid,
    updateRank,
    onDelete,
}) => {
    const items = childPageParts.map((pp) => ({...pp, id: pp.uuid})).sort((a, b) => a.rank - b.rank);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragEnd(event: DragEndEvent) {
        const {active, over} = event;

        if (over && active.id !== over.id) {
            const newIndex = items.findIndex((pp) => pp.uuid === over.id);
            updateRank(newIndex, active.id as string);
        }
    }

    return (
        <DndContext
            modifiers={[restrictToParentElement]}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
                {items.map((childPagePart) => {
                    return (
                        <SortableChild
                            key={childPagePart.uuid}
                            pagePartsConfigurationId={pagePartsConfigurationId}
                            childPagePart={childPagePart}
                            activePagePartUuid={activePagePartUuid}
                            onDelete={() => onDelete(childPagePart.uuid)}
                        />
                    );
                })}
            </SortableContext>
        </DndContext>
    );
};
