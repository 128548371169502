import {makeObservable, observable, runInAction} from 'mobx';
import {drawOnCanvas} from '../internal/draw_on_canvas';
import {isCorsBlocked} from '../internal/is_cors_blocked';
import {isEmptyImage} from '../internal/is_empty_image';

export class EditableImageHelper {
    private image: HTMLImageElement | null = null;

    @observable public loaded = false;
    @observable public loadedEmptyImage = false;
    @observable public imageDegrees: 0 | 90 | 180 | 270 = 0;
    @observable public canvas: HTMLCanvasElement | null = document.createElement('canvas');

    constructor() {
        makeObservable(this);
    }

    public async load(src: string) {
        //Use fetch to load the image, if the image has already been fetched through a redirect (proxy)
        //inside an <img /> element, the browser will return the cached result instead of trying to fetch it with
        //CORS enabled, thus we will get a tainted canvas/CORS errors. To prevent this, fetch the image again
        //and force a `no-cache` so that it does a new fetch call and gets CORS headers back,
        //the reason why <img /> elements dont get CORS headers back is probably because those
        //requests dont send a `Origin` header, AWS S3 requires an `Origin` header else it will
        //not send any CORS headers back, hence this fix
        const response = await fetch(src, {
            headers: {
                pragma: 'no-cache',
                'cache-control': 'no-cache',
            },
        });
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        this.loadImg(imageUrl);
    }

    public async rotate() {
        if (this.loadedEmptyImage || !this.image || !this.canvas || isEmptyImage(this.image)) {
            return;
        }

        runInAction(() => {
            this.imageDegrees = ((this.imageDegrees + 90) % 360) as 0 | 90 | 180 | 270;
        });

        drawOnCanvas(this.canvas, this.image, this.imageDegrees);
    }

    public destroy() {
        this.image?.removeEventListener('load', this.onImageLoaded);
        this.image = null;
    }

    private loadImg(url: string) {
        runInAction(() => {
            this.loaded = false;
            this.loadedEmptyImage = false;
        });

        this.image?.removeEventListener('load', this.onImageLoaded);
        this.image = new Image();
        this.image.addEventListener('load', this.onImageLoaded, false);
        //Set crossOrigin='Anonymous', else we get a tainted canvas error when called toBlob
        //This is because the image is of a different origin then TaXapi itself
        this.image.crossOrigin = 'Anonymous';
        this.image.src = url;
    }

    private onImageLoaded = () => {
        if (!this.image) {
            return;
        }

        //Just in case this image will taint the canvas because of a CORS issues, fallback to http
        if (isCorsBlocked(this.image)) {
            console.warn('Image is not CORS compatible, fallback to plain HTTP!');
            this.image.src = this.image.src.replace('https://', 'http://');
            return;
        }

        if (isEmptyImage(this.image)) {
            runInAction(() => {
                this.loaded = true;
                this.loadedEmptyImage = true;
            });
            return;
        }

        if (this.canvas) {
            runInAction(() => {
                this.loaded = true;
                this.loadedEmptyImage = false;
                this.imageDegrees = 0;
            });

            drawOnCanvas(this.canvas, this.image, 0);
        }
    };
}
