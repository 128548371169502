/**
 * Select all checkboxes within a group
 */
(() => {
    document.querySelectorAll('.select-all').forEach((item) => {
        item.addEventListener('click', function (event) {
            document.querySelectorAll('input[type="checkbox"]:not(.select-all)').forEach((el) => {
                if (isInGroup(event.target as HTMLInputElement, el)) {
                    (event.target as HTMLInputElement).checked
                        ? el.setAttribute('checked', 'checked')
                        : el.removeAttribute('checked');
                }
            });
        });
    });

    function isInGroup(trigger: Element, el: Element) {
        return (
            trigger.getAttribute('data-group') !== undefined &&
            el.getAttribute('data-group') !== undefined &&
            trigger.getAttribute('data-group') === el.getAttribute('data-group')
        );
    }
})();
