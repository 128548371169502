import * as React from 'react';

import {FunctionComponent} from 'react';
import {Modal} from '../../../appraise/ui/content/questions/components/modal';
import {PagePart} from '../../../models/page_part';
import {observer} from 'mobx-react-lite';

export interface NewSubPageState {
    name: string;
    parent: PagePart;
}

interface OwnProps {
    newSubPageState: NewSubPageState;
    onChange: (value: string) => void;
    onHide: () => void;
    onSave: (name: NewSubPageState) => void;
}

export const NewSubPageModal: FunctionComponent<OwnProps> = observer(({newSubPageState, onChange, onHide, onSave}) => {
    return (
        <Modal visible={true} onHide={() => onHide()}>
            <div className="modal-header">
                <h2>Hoe heet de nieuwe sub-pagina?</h2>
            </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="new-page-name">Naam</label>
                            <input
                                id="new-page-name"
                                type="text"
                                className="form-control"
                                value={newSubPageState.name}
                                onChange={(e) => onChange(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary" onClick={() => onHide()}>
                    Sluiten
                </button>
                <button className="btn btn-primary" onClick={() => onSave(newSubPageState)}>
                    Opslaan
                </button>
            </div>
        </Modal>
    );
});
