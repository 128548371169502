import {ExplanationData} from '../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/explanation_appraiser_generator/explanation_data';
import {ExplanationDataProvider} from '../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/explanation_appraiser_generator/explanation_data_provider';
import {ObjectType} from '../enum/object_type';
import {Appraisal} from '../models/appraisal';
import {ModelValuesDetails} from './models/model_values_details';

export class ModelValuesExplanationDataProvider implements ExplanationDataProvider {
    constructor(private readonly modelValuesDetails: ModelValuesDetails, private readonly appraisal: Appraisal) {}

    get(
        gebruiksoppervlakteWonen: number | null,
        perceelOppervlakte: number | null,
        brutoInhoud: number | null
    ): ExplanationData {
        return {
            object: {
                objectType: this.modelValuesDetails.object_type as ObjectType,
                buildYear: this.modelValuesDetails.build_year ? Number(this.modelValuesDetails.build_year) : null,
                floorArea: gebruiksoppervlakteWonen ?? null,
                plotArea: perceelOppervlakte ?? null,
                volume: brutoInhoud ?? null,
            },
            appraisal: {
                valuation: this.modelValuesDetails.market_value ? Number(this.modelValuesDetails.market_value) : null,
                appraiseModel: this.appraisal.appraiseModel,
                addressLine: `${this.appraisal.address} ${this.appraisal.houseNumber} ${this.appraisal.letter}`,
            },
        };
    }
}
