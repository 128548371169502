import * as React from 'react';

import {FunctionComponent} from 'react';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';
import {Textarea} from '../../../inputs/textarea';
import {getPositiveNegativeMacros} from '../../../../internal/positive_negative_macros';

interface OwnProps {
    referenceObjectAnswer: ReferenceObjectAnswer;
    onLocationDifferenceExplanationChange: (locationDifferenceExplanation: string) => void;
    isFrozen: boolean;
}

export const LocationDifferenceExplanation: FunctionComponent<OwnProps> = ({
    referenceObjectAnswer,
    onLocationDifferenceExplanationChange,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-sm-12">
                <Textarea
                    isRequired
                    label="Locatie verschil toelichting"
                    name="input-location-explanation"
                    value={validationInstituteReferentieObject.liggingUitleg}
                    onChange={onLocationDifferenceExplanationChange}
                    macros={getPositiveNegativeMacros(
                        referenceObjectAnswer.referenceObject.liggingStatus,
                        ['De locatie is in zijn algemeenheid beter t.o.v. het getaxeerde object'],
                        ['De locatie is in zijn algemeenheid minder t.o.v. het getaxeerde object']
                    )}
                    disabled={isFrozen}
                />
            </div>
        </div>
    );
};
