import * as React from 'react';

import {observer} from 'mobx-react';
import {classNames} from '../../../../../../../support/classnames';
import {simpleQuestionPresenterConstructorParametersFactory} from '../../simple/simple_question_presenter';
import {AttachmentQuestionProps} from '../attachment_question_presenter';
import {SmallPhotoQuestionPresenter} from './small_photo_question_presenter';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {ImageViewer} from '../../../../../../components/image_viewer/image_viewer';
import {Image} from '../../../../../../components/image_viewer/image';
import {getEditableImageViewerComponent} from './components/editable_image_viewer_component';

export const SmallPhotoQuestion: React.FunctionComponent<AttachmentQuestionProps> = observer((props) => {
    const presenter = usePresenter(
        (container) =>
            new SmallPhotoQuestionPresenter(
                props.fileTypes,
                container.business.blobCacheInteractor(),
                container.business.imageUploadInteractor(props.appraisal, props.questionSet),
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.attachmentQuestionsInteractor(props.appraisal, props.questionSet),
                container.business.flashMessageBroadcaster,
                container.globalProvider,
                container.business.automatorInteractor(props.appraisal, props.questionSet),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );

    const {answer, uncompressedUrl, url} = presenter;

    const images = React.useMemo(
        () =>
            url
                ? [
                      {
                          url,
                          uncompressedUrl: uncompressedUrl ?? undefined,
                          answer,
                      },
                  ]
                : [],
        [url, uncompressedUrl, answer]
    );

    if (presenter.isHidden) {
        return null;
    }

    return (
        <div>
            <ImageViewer
                images={images}
                hideThumbnailSlider
                components={[
                    getEditableImageViewerComponent({
                        isEditable: !presenter.isDisabled && !props.disabled,
                    }),
                ]}
            >
                {url ? (
                    <Image
                        className={classNames(
                            {
                                opaque: presenter.loading || presenter.showRetryButton || props.disabled,
                            },
                            {
                                blurred: presenter.loading || presenter.showRetryButton,
                            },
                            {'d-flex': presenter.isImageLoaded},
                            {'d-none': !presenter.isImageLoaded}
                        )}
                        src={url}
                        onLoad={() => {
                            presenter.onImageLoad();
                        }}
                    />
                ) : null}
            </ImageViewer>

            {presenter.showError ? (
                <>
                    <span className="img-danger">
                        <span className="img-danger-content-split-top ion-md-alert">Missende foto</span>
                    </span>
                    <button className="btn-text-link img-retry-duplicate" onClick={() => presenter.retry()}>
                        <span className="img-retry-content-split-bottom ion-md-refresh">Opnieuw</span>
                    </button>
                </>
            ) : (
                presenter.showRetryButton && (
                    <button className="btn-text-link img-retry" onClick={() => presenter.retry()}>
                        <span className="img-retry-content ion-md-refresh">Opnieuw</span>
                    </button>
                )
            )}

            {!presenter.empty && presenter.loading && (
                <span className="img-uploading">
                    <span className="img-uploading-content ion-md-cloud-upload">Uploading</span>
                </span>
            )}
        </div>
    );
});
