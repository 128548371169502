import {AnswerController} from './answering/answer_controller';
import {QuestionSet} from '../models/question_set';
import {TechnicalReference} from '../enum/technical_reference';
import {getNewestAnswer} from '../../support/get_newest_answer';
import {isEmpty} from '../../support/util';

export class ValuationProvider {
    constructor(private _questionSet: QuestionSet, private answerController: AnswerController) {}

    public get(): number | null {
        const valuationQuestion = this._questionSet.findQuestionByTechnicalReference(TechnicalReference.VALUATION);
        if (valuationQuestion === null) {
            throw new Error('Question about the valuation could not be found');
        }

        const answer = getNewestAnswer(this.answerController.answersForQuestionUuid(valuationQuestion.uuid));

        if (answer !== null && !isEmpty(answer.contents)) {
            return parseInt(answer.contents, 10);
        }

        return null;
    }
}
