import * as React from 'react';
import {classNames} from '../../../support/classnames';

interface OwnProps {
    lng: number;
    lat: number;
    className?: string;
    iconName?: string;
    isBaseObject: boolean;
}

export class Pointer extends React.Component<OwnProps> {
    public render() {
        return (
            <div className={classNames('marker-container')}>
                <div
                    className={classNames(
                        this.props.className,
                        this.props.iconName,
                        {'pointer-object': this.props.isBaseObject},
                        'pointer'
                    )}
                />
            </div>
        );
    }
}
