import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';

import {Highlight} from '../components/highlight';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const AlertInlineQuestion: React.FC<OwnProps> = observer(function AlertInlineQuestion(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));

    if (presenter.isHidden) {
        return null;
    }

    return (
        <div className="alert-inline alert-info ion-md-information-circle">
            <Highlight searchText={presenter.searchText}>{props.question.contents}</Highlight>
        </div>
    );
});
