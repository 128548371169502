import * as React from 'react';

import {CameraProgress, ProgressType} from '../camera_presenter';

import {AnswerImage} from './answer_image';
import {Appraisal} from '../../../../models/appraisal';
import {QuestionSet} from '../../../../models/question_set';
import {UploadingImagesListPresenter} from './uploading_images_list_presenter';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {UnsyncedType} from '../../../../business/photo_answer_retry_interactor';

interface OwnProps {
    progress: CameraProgress | null;
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export const UploadingImagesList: React.FC<OwnProps> = observer(function UploadingImagesList(props) {
    const presenter = usePresenter(
        (container) =>
            new UploadingImagesListPresenter(
                container.business.photoAnswerRetryInteractor(props.appraisal, props.questionSet)
            )
    );

    const unsyncedState = presenter.unsyncedState;
    if (unsyncedState == null || unsyncedState.type === UnsyncedType.IDLE || unsyncedState.unsynced.length === 0) {
        return null;
    }

    return (
        <div className="uploading-images-list floater floater-information">
            {props.progress?.type === ProgressType.CASUAL && (
                <div className="uploading-progress" style={{width: 100 * props.progress.progress + '%'}}></div>
            )}

            <div className="images-container">
                {unsyncedState.unsynced.slice(0, 3).map((unsyncedImage) => {
                    return (
                        <AnswerImage
                            key={unsyncedImage.answer.uuid}
                            appraisal={props.appraisal}
                            questionSet={props.questionSet}
                            photoAnswerWithFile={unsyncedImage}
                        />
                    );
                })}
                {unsyncedState.unsynced.length > 3 && (
                    <div className="counter-container">+{unsyncedState.unsynced.length - 3}</div>
                )}
            </div>
            <span>
                {unsyncedState.unsynced.length} {unsyncedState.unsynced.length === 1 ? 'foto' : "foto's"}{' '}
                synchroniseren.
            </span>
        </div>
    );
});
