import * as React from 'react';

import {Appraisal} from '../../../../../../models/appraisal';
import {observer} from 'mobx-react';
import {RenderingContextType} from '../../../../../../enum/rendering_context_type';
import {ReportIframeViewer} from '../report_viewer/report_iframe_viewer';
import {GeneratedReportType} from '../../../../../../enum/generated_report';
import {QuestionSet} from '../../../../../../models/question_set';
import {EnergetischeStaatViewerDummy} from './energetische_staat_viewer_dummy';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    renderingContext: RenderingContextType;
}

export const EnergetischeStaatViewer: React.FunctionComponent<OwnProps> = observer((props) => {
    if (props.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
        return <EnergetischeStaatViewerDummy />;
    }
    return (
        <ReportIframeViewer
            questionSet={props.questionSet}
            appraisal={props.appraisal}
            reportType={GeneratedReportType.ENERGETISCHE_STAAT}
        />
    );
});
