import {computed, makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../../../../../support/composite_subscription';
import {Presenter} from '../../../../../../../support/presenter/presenter';
import {Question} from '../../../../../../models/question';
import {QuestionSet} from '../../../../../../models/question_set';
import {AnswerController} from '../../../../../../business/answering/answer_controller';
import {Answer} from '../../../../../../models/answer';
import {NormalQuestionType} from '../../../../../../enum/question_type';
import {RenderingContextType} from '../../../../../../enum/rendering_context_type';
import {map, switchMap} from 'rxjs/operators';
import {AnswerTouchState} from '../../../../../../enum/answer_touch_state';
import {QuestionAnswerPair, buildQuestionAnswerTree} from '../../../../../../../support/question_answer_tree';
import {TreeItem, findChildrenRecursiveByPredicate} from '../../../../../../../support/generic_tree';
import {TechnicalReference} from '../../../../../../enum/technical_reference';
import {QuestionEffectInteractor} from '../../../../../../business/conditions/question_effects_interactor';

export enum SymlinkType {
    UNDETERMINED = 'undetermined',
    CLEAN_SLATE = 'clean-slate',
    COPY = 'copy',
    MIRROR = 'mirror',
}

type SymlinkLinkAnswerContents =
    | {
          type: SymlinkType.UNDETERMINED;
          targetIterationUuid: null;
      }
    | {
          type: SymlinkType.CLEAN_SLATE;
          targetIterationUuid: null;
      }
    | {
          type: SymlinkType.COPY;
          targetIterationUuid: string;
      }
    | {
          type: SymlinkType.MIRROR;
          targetIterationUuid: string;
      };

interface CopyableLink {
    name: string;
    iteration: string;
    tree: TreeItem<QuestionAnswerPair>;
}

export class SymlinkLinkPresenter implements Presenter {
    @observable.ref public answer: Answer | null = null;
    @computed public get answerContents(): SymlinkLinkAnswerContents | null {
        if (this.answer) {
            return this.parseSymlinkLinkAnswer(this.answer);
        }
        return null;
    }
    @computed public get symlinkIteration(): string {
        const contents = this.answerContents;
        if (contents?.type === SymlinkType.MIRROR && contents.targetIterationUuid) {
            return contents.targetIterationUuid;
        }
        if (this.answer) {
            return `${this.question.uuid}|${this.answer.uuid}`;
        } else {
            return this.question.uuid;
        }
    }

    @observable.ref public targetQuestion: Question | null = null;
    @observable.ref public copyableLinks: Array<CopyableLink> | null = null;
    @observable public isHidden = false;

    private subscriptions = new CompositeSubscription();

    constructor(
        private question: Question,
        private questionSet: QuestionSet,
        private parentAnswerUuid: string | null,
        renderingContext: RenderingContextType,
        private answerController: AnswerController,
        protected questionEffectsInteractor: QuestionEffectInteractor
    ) {
        this.answer = this.answerController.answerByIdentifiers(this.question.uuid, parentAnswerUuid, null);
        this.isHidden = this.questionEffectsInteractor.isHidden(this.question.uuid, parentAnswerUuid);

        if (question.technicalReference) {
            if (process.env.NODE_ENV === 'development') {
                if (!question.technicalReference?.startsWith('symlink-')) {
                    throw new Error('SYMLINK_LINK needs a technical reference that starts with `symlink-`');
                }
            }

            const otherQuestions = questionSet.findQuestionsByTechnicalReference(question.technicalReference);
            const targetQuestions = otherQuestions.filter((q) => q.type !== NormalQuestionType.SYMLINK_LINK);
            if (targetQuestions.length !== 1) {
                if (
                    process.env.NODE_ENV === 'development' &&
                    renderingContext !== RenderingContextType.PAGE_PARTS_CONFIGURATOR
                ) {
                    console.error('targetQuestion:', targetQuestions);
                    throw new Error(
                        'SYMLINK_LINK its target question (defined by its technicalReference) needs a target question thats only once defined. So only 1 other question may have this technicalReference aside from other SYMLINK_LINK questions.'
                    );
                }
            }
            if (
                targetQuestions[0].type !== NormalQuestionType.SYMLINK_TARGET &&
                targetQuestions[0].type !== NormalQuestionType.SYMLINK_TARGET_COPY
            ) {
                console.error('targetQuestions:', targetQuestions);
                throw new Error('SYMLINK_LINK its target question needs to be a SYMLINK_TARGET.');
            }

            this.targetQuestion = targetQuestions[0];
        }

        makeObservable(this);
    }

    private parseSymlinkLinkAnswer(answer: Answer): SymlinkLinkAnswerContents {
        if (answer.contents?.startsWith('{')) {
            return JSON.parse(answer.contents) as SymlinkLinkAnswerContents;
        }
        return {
            type: SymlinkType.UNDETERMINED,
            targetIterationUuid: null,
        };
    }

    public async mount() {
        this.subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid, null)
                .subscribe((answer) => {
                    runInAction(() => {
                        if (answer !== this.answer) {
                            this.answer = answer;
                        }
                    });
                })
        );

        this.subscriptions.add(
            this.questionEffectsInteractor
                .isHiddenStream(this.question.uuid, this.parentAnswerUuid)
                .subscribe((isHidden) => {
                    runInAction(() => {
                        this.isHidden = isHidden;
                    });
                })
        );

        if (this.question.technicalReference && this.targetQuestion?.type === NormalQuestionType.SYMLINK_TARGET_COPY) {
            const allSymlinkQuestions = this.questionSet.findQuestionsByTechnicalReference(
                this.question.technicalReference
            );

            const allLinkQuestions = allSymlinkQuestions.filter((q) => q.type === NormalQuestionType.SYMLINK_LINK);
            const targetQuestion = allSymlinkQuestions.find((q) => q.type === NormalQuestionType.SYMLINK_TARGET_COPY);
            const allLinkQuestionUuids = allLinkQuestions.map((q) => q.uuid);

            if (targetQuestion && allLinkQuestions.length > 0) {
                const targetChildren = this.questionSet
                    .flattenChildrenRecursively(targetQuestion.uuid)
                    .map((q) => q.uuid);

                this.subscriptions.add(
                    this.answerController
                        .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid, null)
                        .pipe(
                            switchMap((ownAnswer) =>
                                this.answerController
                                    .answersForQuestionUuidsStream([...allLinkQuestionUuids, ...targetChildren])
                                    .pipe(
                                        map((answers) => {
                                            const parsedAnswers = answers
                                                .filter((a) => allLinkQuestionUuids.includes(a.questionUuid))
                                                .map((answer) => {
                                                    return {
                                                        linkQuestion: allLinkQuestions.find(
                                                            (q) => q.uuid === answer.questionUuid
                                                        ),
                                                        linkAnswer: answer,
                                                        linkAnswerContents: this.parseSymlinkLinkAnswer(answer),
                                                    };
                                                });

                                            const copyableLinks = parsedAnswers.filter(
                                                (a): a is typeof a & {linkQuestion: Question} => {
                                                    const linkIteration = `${a.linkQuestion?.uuid}|${a.linkAnswer.uuid}`;
                                                    const ownIteration = `${this.question.uuid}|${ownAnswer.uuid}`;

                                                    return (
                                                        a.linkQuestion !== undefined &&
                                                        a.linkAnswer.isDeleted === false &&
                                                        (a.linkAnswerContents.type === SymlinkType.CLEAN_SLATE ||
                                                            a.linkAnswerContents.type === SymlinkType.COPY) &&
                                                        linkIteration !== ownIteration
                                                    );
                                                }
                                            );
                                            return copyableLinks;
                                        }),
                                        map((data) => {
                                            const bla = data.map((d) => {
                                                const iteration =
                                                    d.linkAnswerContents.type === SymlinkType.MIRROR
                                                        ? d.linkAnswerContents.targetIterationUuid
                                                        : d.linkAnswer
                                                        ? `${d.linkQuestion?.uuid}|${d.linkAnswer.uuid}`
                                                        : d.linkQuestion?.uuid;

                                                return {
                                                    ...d,
                                                    targetAnswer: this.answerController.answerByIdentifiers(
                                                        targetQuestion.uuid,
                                                        null,
                                                        iteration ?? null
                                                    ),
                                                };
                                            });
                                            return bla;
                                        }),
                                        map((data) => {
                                            const answers = this.answerController.answers();
                                            const trees = data
                                                .map((d) => {
                                                    if (d.targetAnswer) {
                                                        return {
                                                            ...d,
                                                            tree: buildQuestionAnswerTree(
                                                                this.questionSet,
                                                                answers,
                                                                d.targetAnswer
                                                            ),
                                                        };
                                                    }
                                                })
                                                .filter((a): a is NonNullable<typeof a> => a !== undefined);
                                            return trees;
                                        }),
                                        map((data) => {
                                            return data.map((d, index) => {
                                                const namePairs = findChildrenRecursiveByPredicate(
                                                    d.tree,
                                                    (pair) =>
                                                        pair.question.technicalReference ===
                                                        TechnicalReference.SYMLINK_NAME
                                                );

                                                const nameStrings = namePairs
                                                    .map((pair) => pair.item.answer?.contents?.trim())
                                                    .filter((s) => (s ?? '').length > 0);
                                                const name =
                                                    nameStrings.length > 0
                                                        ? nameStrings.join(' ')
                                                        : `${targetQuestion.contents}-${index.toString()}`;

                                                return {
                                                    ...d,
                                                    name,
                                                };
                                            });
                                        })
                                    )
                            )
                        )
                        .subscribe((data) => {
                            runInAction(() => {
                                const copyableLinks = data.map((d) => {
                                    return {
                                        name: d.name,
                                        iteration: `${d.linkQuestion?.uuid}|${d.linkAnswer.uuid}`,
                                        tree: d.tree,
                                    };
                                });
                                this.copyableLinks = copyableLinks;
                                if (copyableLinks.length === 0 && this.answer && this.answer.contents === null) {
                                    this.setCleanSlateSource();
                                }
                            });
                        })
                );
            }
        } else if (this.targetQuestion?.type === NormalQuestionType.SYMLINK_TARGET) {
            if (this.answer && this.answer.contents === null) {
                this.setCleanSlateSource();
            }
        }
    }

    public async unmount() {
        this.subscriptions.clear();
    }

    public setCleanSlateSource() {
        if (this.answer) {
            const contents: SymlinkLinkAnswerContents = {
                type: SymlinkType.CLEAN_SLATE,
                targetIterationUuid: null,
            };
            this.answerController.onContentsChange(
                this.answer.uuid,
                JSON.stringify(contents),
                AnswerTouchState.TOUCHED
            );
        }
    }

    public setCopySource(copyableLink: CopyableLink) {
        if (this.answer) {
            const contents: SymlinkLinkAnswerContents = {
                type: SymlinkType.COPY,
                targetIterationUuid: copyableLink.iteration,
            };
            this.answerController.onContentsChange(
                this.answer.uuid,
                JSON.stringify(contents),
                AnswerTouchState.TOUCHED
            );

            this.answerController.copyTree(
                copyableLink.tree,
                null,
                (q) => q,
                `${this.question.uuid}|${this.answer.uuid}`
            );
        }
    }

    public setMirrorSource(targetIterationUuid: string) {
        if (this.answer) {
            const contents: SymlinkLinkAnswerContents = {
                type: SymlinkType.MIRROR,
                targetIterationUuid,
            };
            this.answerController.onContentsChange(
                this.answer.uuid,
                JSON.stringify(contents),
                AnswerTouchState.TOUCHED
            );
        }
    }
}
