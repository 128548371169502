import React, {useEffect} from 'react';

import {observer} from 'mobx-react';
import {Macros} from '../../../appraising/appraise/ui/content/questions/advanced/macros/macros';
import {macroIsActive, toggleMacro} from '../../../appraising/business/toggle_macro';
import {MacroExternalType} from '../../../appraising/models/macro';
import {Appraisal} from '../../../appraising/models/appraisal';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {RejectModalPresenter} from './reject_modal_presenter';
import {QuestionSet} from '../../../appraising/models/question_set';
import {Modal} from '../../../appraising/appraise/ui/content/questions/components/modal';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    modalContainer?: HTMLElement | React.RefObject<HTMLElement>;
    cancel: () => void;
    save: (reason: string) => void;
    onChange: (reason: string) => void;
}

export const RejectModal: React.FC<OwnProps> = observer((props) => {
    const presenter = usePresenter(
        (container) => new RejectModalPresenter(container.business.macroInteractor(props.questionSet))
    );

    useEffect(() => {
        props.onChange(presenter.rejectReason);
    }, [presenter.rejectReason]);

    return (
        <Modal visible={true} relativeContainer={props.modalContainer} restoreFocus={false}>
            <div className="modal-header">
                <h2>Referentie afkeuren</h2>
            </div>
            <div className="modal-body">
                <div className={presenter.macros?.length !== 0 ? 'form-group has-macros' : 'form-group'}>
                    <div className="label-container">
                        <span className="form-label-highlight">*</span>
                        <label>Reden van afkeuren</label>
                    </div>
                    {presenter.macros && (
                        <Macros
                            externalType={MacroExternalType.REFEFENCES_REJECT_REASON}
                            parentAnswerUuid={null}
                            hasAdvancedOptions={false}
                            questionSet={props.questionSet}
                            appraisal={props.appraisal}
                            isActive={(contents) => macroIsActive(contents, presenter.rejectReason)}
                            onUseMacro={(contents) =>
                                presenter.onRejectReasonChange(toggleMacro(contents, presenter.rejectReason))
                            }
                            onAdd={presenter.onAddAsMacroClick}
                            onRemove={presenter.onRemoveMacroClick}
                            onFavorite={presenter.onFavoriteMacroClick}
                            showAddButton={
                                presenter.rejectReason !== '' &&
                                !presenter.macros.some((m) => m.contents === presenter.rejectReason)
                            }
                        />
                    )}
                    <textarea
                        className="form-control"
                        rows={3}
                        value={presenter.rejectReason}
                        onChange={(e) => presenter.onRejectReasonChange(e.target.value)}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary" onClick={props.cancel}>
                    Annuleren
                </button>
                <button className="btn btn-primary" onClick={() => props.save(presenter.rejectReason)}>
                    Opslaan
                </button>
            </div>
        </Modal>
    );
});
