import {Type} from '../../../../../../business/flash_message_broadcaster';

import {BlobCacheInteractor} from '../../../../../../business/attachments/blob_cache_interactor';
import {NormalQuestionType} from '../../../../../../enum/question_type';
import {SimpleQuestionPresenter} from '../../simple/simple_question_presenter';
import {buildQuestionAnswerTree} from '../../../../../../../support/question_answer_tree';
import {findChildRecursiveByPredicate} from '../../../../../../../support/generic_tree';
import {parseImageAnswerContents} from '../../../../../../business/support/parse_image_answer_contents';

export class IteratorPhotoGroupPresenter extends SimpleQuestionPresenter {
    constructor(
        private blobCacheInteractor: BlobCacheInteractor,
        ...simpleQuestionPresenterArgument: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterArgument);
    }

    public mount() {
        super.mount();
    }

    public unmount() {
        super.unmount();
    }

    public async download() {
        if (this.answer) {
            const answers = this.answerController.answers();
            const tree = buildQuestionAnswerTree(this.questionSet, answers, this.answer);
            const photoPair = findChildRecursiveByPredicate(
                tree,
                (item) => item.question.type === NormalQuestionType.PHOTO
            );

            if (photoPair?.item.answer) {
                let url = null;
                let fileName = 'download';

                if (photoPair.item.answer.file) {
                    url = photoPair.item.answer.file.uncompressedUrl ?? photoPair.item.answer.file.url ?? null;
                }

                if (url === null) {
                    const blob = await this.blobCacheInteractor.find(photoPair.item.answer.uuid);
                    if (blob) {
                        url = URL.createObjectURL(blob);
                    }
                }

                if (photoPair.item.answer.contents) {
                    try {
                        const contents = parseImageAnswerContents(photoPair.item.answer.contents);
                        fileName = contents.name;
                    } catch {
                        //Noop
                    }
                }

                if (url !== null) {
                    // Construct the 'a' element
                    const link = document.createElement('a');
                    link.download = fileName;
                    link.target = '_blank';

                    // Construct the URI
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();

                    // Cleanup the DOM
                    document.body.removeChild(link);
                } else {
                    this.flashMessageBroadcaster.broadcast('Download van foto mislukt.', Type.Danger);
                }
            }
        }
    }
}
