import * as React from 'react';

import {makeObservable, observable} from 'mobx';

import {Adres} from '../../../../../../../../../models/validation_institute_reference_object';
import {observer} from 'mobx-react';

interface OwnProps {
    address: Adres;
    onChange: (value: Adres) => void;
}

@observer
export class EditableAddress extends React.Component<OwnProps> {
    @observable private editing = false;

    constructor(props: OwnProps) {
        super(props);
        makeObservable(this);
    }

    private onLetterChange(letter: string) {
        this.props.onChange({
            ...this.props.address,
            huisnummerToevoeging: letter,
        });
    }

    private activateEdit() {
        this.editing = true;
    }

    private deactivateEdit() {
        this.editing = false;
    }

    private renderEditableLetter() {
        if (this.editing) {
            return (
                <span className="form-group form-group-with-button">
                    <label>Huisnummer toevoeging bewerken</label>
                    <input
                        type="text"
                        className="form-control"
                        value={this.props.address.huisnummerToevoeging}
                        onChange={(e) => this.onLetterChange(e.target.value)}
                    />
                    <button
                        className="btn btn-sm btn-primary ion-md-checkmark btn-single-icon float-right"
                        onClick={() => this.deactivateEdit()}
                    >
                        &nbsp;
                    </button>
                </span>
            );
        }
        return null;
    }

    public render() {
        return (
            <dl>
                <dt>Adres</dt>
                <dd>
                    <span
                        className={this.editing ? 'ion-md-create disabled' : 'ion-md-create'}
                        onClick={() => this.activateEdit()}
                    >
                        &nbsp;
                    </span>
                    {this.props.address.straat}{' '}
                    {this.props.address.huisnummer +
                        (this.props.address.huisnummerToevoeging ? '-' + this.props.address.huisnummerToevoeging : '')}
                    <br />
                    {this.props.address.postcode} {this.props.address.plaats}
                    {this.renderEditableLetter()}
                </dd>
            </dl>
        );
    }
}
