import {ApiNotification, apiNotificationToNotification} from './models/api_notification';

import {AjaxDriver} from '../../network/driver/ajax_driver';
import {NotificationMessage} from '../models/notification_message';

export interface NotificationsApi {
    find(id: string): Promise<NotificationMessage | null>;
    dismiss(id: string): Promise<NotificationMessage | null>;
    read(id: string): Promise<NotificationMessage | null>;
    remind(id: string, date: Date): Promise<NotificationMessage | null>;
    quickRemind(id: string): Promise<NotificationMessage | null>;
    get(page: number, limit: number): Promise<NotificationMessage[]>;
}

export class DefaultNotificationsApi implements NotificationsApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public find(id: string): Promise<NotificationMessage | null> {
        return this.call(`/ajax/notifications/${id}/find`, {
            method: 'GET',
        });
    }

    public dismiss(id: string): Promise<NotificationMessage | null> {
        return this.call(`/ajax/notifications/${id}/dismiss`, {
            method: 'POST',
            body: null,
        });
    }

    public read(id: string): Promise<NotificationMessage | null> {
        return this.call(`/ajax/notifications/${id}/read`, {
            method: 'POST',
            body: null,
        });
    }

    public remind(id: string, date: Date): Promise<NotificationMessage | null> {
        return this.call(`/ajax/notifications/${id}/remind`, {
            method: 'POST',
            body: JSON.stringify({
                remind_at: date,
            }),
        });
    }

    public quickRemind(id: string): Promise<NotificationMessage | null> {
        return this.call(`/ajax/notifications/${id}/quick-remind`, {
            method: 'POST',
            body: null,
        });
    }

    public get(page: number, limit: number): Promise<NotificationMessage[]> {
        return (
            this.call(
                `/ajax/notifications/index?page=${page}&limit=${limit ?? 15}`,
                {
                    method: 'GET',
                },
                true
            ) ?? []
        );
    }

    private async call<T extends NotificationMessage | NotificationMessage[] | null>(
        url: string,
        init: RequestInit,
        isMulti = false
    ): Promise<T> {
        try {
            const requestInit: RequestInit = {
                ...init,
                credentials: 'same-origin',
                headers: {
                    'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            };

            const response = await this.ajaxDriver.fetch(url, requestInit);
            if (response.ok) {
                const data = await response.json();
                if (isMulti) {
                    return data.map((d: ApiNotification) => apiNotificationToNotification(d));
                }
                return apiNotificationToNotification(data) as T;
            }
            return null as T;
        } catch (e) {
            console.warn(e);
            return null as T;
        }
    }
}
