import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {FunctionComponent} from 'react';
import {LoadingStateType} from '../../../support/presenter/helpers';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {ConfigCurrentTabPresenter} from './config_current_tab_presenter';
import {PagePartsSet} from '../../models/page_parts_set';
import {Appraisal} from '../../models/appraisal';
import {QuestionSet} from '../../models/question_set';
import {QuestionLoader} from '../../appraise/ui/components/question_loader';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    activePagePartSet: PagePartsSet;
}

export const ConfigCurrentTab: FunctionComponent<OwnProps> = observer(({appraisal, questionSet, activePagePartSet}) => {
    const presenter = usePresenter(
        (component) =>
            new ConfigCurrentTabPresenter(
                activePagePartSet,
                component.business.pagePartsSetInteractor(appraisal, questionSet),
                component.business.flashMessageBroadcaster
            )
    );

    return (
        <>
            <div className="row">
                <h3>
                    <span className="ion-md-create"></span> Huidige configuratie
                </h3>
                <p>Hier kun je de gegevens waar onder titel en beschrijving aanpassen van de huidige configuratie.</p>
            </div>

            {presenter.loadingState === LoadingStateType.LOADING && presenter.editableConfig === null ? (
                <QuestionLoader />
            ) : presenter.editableConfig === null ? (
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>Selecteer eerst een configuratie die je zelf beheert.</h3>
                    </div>
                </div>
            ) : (
                <>
                    <div className="row">
                        <div className="form-group">
                            <label htmlFor="new-configuration-name">Naam</label>
                            <input
                                id="new-configuration-name"
                                type="text"
                                className="form-control"
                                value={presenter.editableConfig.name}
                                onChange={(e) => presenter.onNameChange(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group">
                            <label htmlFor="new-configuration-description">Beschrijving</label>
                            <textarea
                                id="new-configuration-description"
                                className="form-control"
                                value={presenter.editableConfig.description ?? ''}
                                onChange={(e) => presenter.onDescriptChange(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group">
                            <input
                                id="new-configuration-is-shared"
                                name="new-configuration-is-shared"
                                type="checkbox"
                                checked={presenter.editableConfig.isShared}
                                onChange={(e) => presenter.onIsSharedChange(e.target.checked)}
                            />
                            <label htmlFor="new-configuration-is-shared" className="col-form-label">
                                Configuratie is gedeeld met collega&apos;s
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group">
                            <input
                                id="new-configuration-is-visible"
                                name="new-configuration-is-visible"
                                type="checkbox"
                                checked={presenter.editableConfig.isVisible}
                                onChange={(e) => presenter.onIsVisibleChange(e.target.checked)}
                            />
                            <label htmlFor="new-configuration-is-visible" className="col-form-label">
                                Configuratie is zichtbaar in taxeer omgeving
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="d-flex justify-content-end w-100">
                            <button
                                disabled={presenter.loadingState === LoadingStateType.LOADING}
                                className="btn btn-primarty btn-sm ion-md-checkmark "
                                onClick={() => presenter.saveChanges()}
                            >
                                Opslaan
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
});
