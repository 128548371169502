import * as React from 'react';

import {FunctionComponent} from 'react';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';
import {Select} from '../../../inputs/select';

interface OwnProps {
    referenceObjectAnswer: ReferenceObjectAnswer;
    onSourceChange: (source: string) => void;
    isFrozen: boolean;
}

export const Source: FunctionComponent<OwnProps> = ({referenceObjectAnswer, onSourceChange, isFrozen}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-12">
                <dl>
                    <Select
                        isRequired
                        name="bron-gegevens"
                        label="Bron gegevens"
                        options={{
                            Ortec: 'Ortec',
                            'Archief taxateur': 'Archief taxateur',
                            'Archief NVM': 'Archief NVM',
                            'Archief VastgoedPRO': 'Archief VastgoedPRO',
                            'Archief VBO': 'Archief VBO',
                            'Archief branchevereniging': 'Archief branchevereniging',
                        }}
                        otherText="Anders, namelijk:"
                        emptyPlaceholder="Selecteer bron gegevens"
                        value={validationInstituteReferentieObject.bronGegevens}
                        onChange={onSourceChange}
                        disabled={isFrozen}
                    />
                </dl>
            </div>
        </div>
    );
};
