import * as React from 'react';

import {Appraisal} from '../../../../../../../../../models/appraisal';

export interface OwnProps {
    appraisal: Appraisal;
    surfaceArea: number | null;
    gebruiksOppervlakteBuitenruimte: number | null;
    overigeInpandigeRuimte: number | null;
    gebruiksOppervlakteExterneBergruimte: number | null;
    buildYear: number | null;
    plotArea: number | null;
}

export class ReferenceObjectAnswerModalSidebar extends React.Component<OwnProps> {
    public render() {
        return (
            <div className="modal-sidebar">
                <h3>Kerngegevens</h3>
                <p>
                    {this.props.appraisal.address} {this.props.appraisal.houseNumber} {this.props.appraisal.letter}
                    <br />
                    {this.props.appraisal.postalCode} {this.props.appraisal.city}
                </p>
                <div className="value-item">
                    <div className="value-circle">{this.props.surfaceArea ?? '-'}</div>
                    Gebruiksoppervlakte wonen (m²)
                </div>
                <div className="value-item">
                    <div className="value-circle">{this.props.gebruiksOppervlakteBuitenruimte ?? '-'}</div>
                    Gebouwgebonden buitenruimte (m²)
                </div>
                <div className="value-item">
                    <div className="value-circle">{this.props.overigeInpandigeRuimte ?? '-'}</div>
                    Overige inpandige ruimte (m²)
                </div>
                <div className="value-item">
                    <div className="value-circle">{this.props.gebruiksOppervlakteExterneBergruimte ?? '-'}</div>
                    Externe bergruimte (m²)
                </div>
                <div className="value-item">
                    <div className="value-circle">{this.props.buildYear ?? '-'}</div>
                    Bouwjaar
                </div>
                <div className="value-item">
                    <div className="value-circle">{this.props.plotArea ?? '-'}</div>
                    Perceel (m²)
                </div>
            </div>
        );
    }
}
