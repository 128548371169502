import {AppraisalApi} from './appraisal_api';

import {GeolocationPermission} from '../support/geolocation/geolocation_permission';
import {Presenter} from '../support/presenter/presenter';
import {observable, makeObservable} from 'mobx';
import {requestCurrentGeolocation} from '../support/geolocation/request_current_geolocation';
import {SearchAppraisal} from '../appraising/models/search_appraisal';

export class NearbyAppraisalPresenter implements Presenter {
    @observable.ref public nearbyAppraisal: SearchAppraisal | null = null;
    @observable public modalVisible = false;

    constructor(private _geolocatinPermission: GeolocationPermission, private _appraisalApi: AppraisalApi) {
        makeObservable(this);
    }

    public async mount(): Promise<void> {
        const hasGeolocationPermission = await this._geolocatinPermission.hasGeolocationPermission();

        if (hasGeolocationPermission) {
            try {
                const coordinates = await requestCurrentGeolocation();
                if (coordinates !== null) {
                    const nearestAppraisals = await this._appraisalApi.getNearby(
                        coordinates.latitude,
                        coordinates.longitude
                    );
                    if (nearestAppraisals.length > 0) {
                        this.nearbyAppraisal = nearestAppraisals[0];
                        this.modalVisible = true;
                    }
                }
            } catch (error) {
                if (
                    error instanceof Error &&
                    'code' in error &&
                    'PERMISSION_DENIED' in error &&
                    (error as unknown as GeolocationPositionError).code ===
                        (error as unknown as GeolocationPositionError).PERMISSION_DENIED
                ) {
                    this._geolocatinPermission.setGeolocationPermission(false);
                }
            }
        }
    }

    public unmount(): void {
        /* Noop*/
    }

    public hideModal = () => {
        this.modalVisible = false;
    };
}
