import * as React from 'react';

import {classNames} from '../../../../support/classnames';

export enum StatusLabelType {
    Secondary,
    Danger,
    Beta,
}

interface OwnProps {
    type: StatusLabelType;
    content: string;
}

export class StatusLabel extends React.Component<OwnProps> {
    public render() {
        return (
            <span
                className={classNames('status-label', {
                    secondary: this.props.type === StatusLabelType.Secondary,
                    danger: this.props.type === StatusLabelType.Danger,
                    beta: this.props.type === StatusLabelType.Beta,
                })}
            >
                {this.props.type === StatusLabelType.Beta ? <span className="ion-md-star"></span> : null}
                {this.props.content}
            </span>
        );
    }
}
