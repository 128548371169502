import {computed, makeObservable, observable, runInAction} from 'mobx';
import {combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {GlobalProvider} from '../../../../../../../../../../business/global_provider';

import {CompositeSubscription} from '../../../../../../../../../../support/composite_subscription';
import {
    findChildRecursiveByPredicate,
    findChildrenPathByPredicateRecursive,
} from '../../../../../../../../../../support/generic_tree';
import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {AnswerController} from '../../../../../../../../../business/answering/answer_controller';
import {AppraisalProvider} from '../../../../../../../../../business/appraisal_provider';
import {EnergyLabelProvider} from '../../../../../../../../../business/energy_label_provider';
import {VolumeProvider} from '../../../../../../../../../business/volume_provider';
import {ObjectType} from '../../../../../../../../../enum/object_type';
import {TechnicalReference} from '../../../../../../../../../enum/technical_reference';
import {Question} from '../../../../../../../../../models/question';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {V3ReferenceSetsProvider} from '../../internal/reference_sets/reference_sets_provider';
import {buildQuestionTree} from '../../../../../../../../../../support/question_answer_tree';
import {NormalQuestionType} from '../../../../../../../../../enum/question_type';
import {
    excludeDakkapellen,
    getDakkapellenCount,
    getNewestValidAnswer,
    getUniqueBijgebouwen,
} from '../../../../../../../../../business/support/bijgebouwen_as_string_provider';
import {getNewestAnswer} from '../../../../../../../../../../support/get_newest_answer';

type TechnicalReferenceQuestionKeys = {
    [k in TechnicalReference]?: TechnicalReferenceQuestionKeys;
};

export class ReferenceObjectComparisonColumnPresenter implements Presenter {
    @observable public frontviewUrl: string | null = null;
    @observable public buildYear: number | null = null;
    @observable public energyLabel: string | null = null;
    @observable public objectType: ObjectType | null = null;
    @observable public surfaceArea: number | null = null;
    @observable public plotArea: number | null = null;
    @observable public valuation: number | null = null;
    @observable public pricePerM2: number | null = null;
    @observable public volume: number | null = null;
    @observable public onderhoudssituatie: string | null = null;
    @observable public aanbouw: string | null = null;

    private subscriptions = new CompositeSubscription();

    constructor(
        private questionSet: QuestionSet,
        private question: Question,
        private parentAnswerUuid: string,
        private referenceSetsProvider: V3ReferenceSetsProvider,
        private answerController: AnswerController,
        private appraisalProvider: AppraisalProvider,
        private energyLabelProvider: EnergyLabelProvider,
        private volumeProvider: VolumeProvider,
        private global: GlobalProvider
    ) {
        makeObservable(this);

        this.frontviewUrl = this.global.global.objectImageUrl;
    }

    public mount(): void {
        this.subscriptions.add(
            this.getReferenceSetStream().subscribe((set) => {
                if (!set) {
                    return;
                }

                runInAction(() => {
                    this.buildYear = set.buildYear;
                    this.objectType = this.appraisalProvider.appraisal.objectType;
                    this.surfaceArea = set.surfaceArea;
                    this.plotArea = set.plotArea;
                    this.valuation = set.valuation;
                    this.onderhoudssituatie = set.onderhoudssituatie;

                    this.pricePerM2 =
                        set.valuation !== null && set.surfaceArea !== null && set.surfaceArea !== 0
                            ? Math.round(set.valuation / set.surfaceArea)
                            : null;
                });
            })
        );

        this.subscriptions.add(
            this.energyLabelProvider.stream().subscribe((energyLabel) => {
                runInAction(() => {
                    this.energyLabel = energyLabel;
                });
            })
        );

        this.subscriptions.add(
            this.volumeProvider.stream().subscribe((volume) => {
                runInAction(() => {
                    this.volume = volume;
                });
            })
        );

        runInAction(() => {
            const bijgebouwenQuestions = this.questionSet.findQuestionsByTechnicalReference(
                TechnicalReference.OBJECT_OMSCHRIJVING_BIJGEBOUW
            );
            const bijgebouwenAnswerOptions = bijgebouwenQuestions.flatMap((q) => q.answerOptions);

            const aanbouwenQuestions = this.questionSet.findQuestionsByTechnicalReference(
                TechnicalReference.OBJECT_OMSCHRIJVING_AANBOUW
            );

            const dakkapellenArray = bijgebouwenAnswerOptions
                .map((ao) =>
                    ao.technicalReference?.includes(TechnicalReference.OBJECT_OMSCHRIJVING_BIJGEBOUW_DAKKAPELLEN)
                        ? ao.contents
                        : null
                )
                .filter((ao) => ao !== null) as string[];

            const floorQuestions = this.questionSet.findQuestionsByTechnicalReference(
                TechnicalReference.FLOOR_BOUWDELEN_PARENT
            );

            const floorIterations = this.answerController.answersForQuestionUuids(floorQuestions.map((q) => q.uuid));

            const validFloorIterations = floorIterations.filter((answer) => {
                return !answer.isDeleted && answer.iteration !== null;
            });
            let uniqueBijgebouwenOnFloors = validFloorIterations.flatMap((floor) => {
                return getUniqueBijgebouwen(
                    bijgebouwenQuestions,
                    bijgebouwenAnswerOptions,
                    dakkapellenArray,
                    this.answerController,
                    floor
                );
            });

            let dakkapellen = '';
            if (uniqueBijgebouwenOnFloors.length > 0) {
                const {dakkapellenCount, bijgebouwenOnFloors} = getDakkapellenCount(
                    uniqueBijgebouwenOnFloors,
                    dakkapellenArray
                );

                uniqueBijgebouwenOnFloors = bijgebouwenOnFloors;

                if (dakkapellenCount > 1) {
                    dakkapellen = `${dakkapellenCount} dakkapellen`;
                } else if (dakkapellenCount === 1) {
                    dakkapellen = '1 dakkapel';
                }
            }

            const uniqueBijgebouwen = getUniqueBijgebouwen(
                bijgebouwenQuestions,
                bijgebouwenAnswerOptions,
                dakkapellenArray,
                this.answerController
            );

            const uniqueBijgebouwenWithoutDakkapellen = excludeDakkapellen(uniqueBijgebouwen, dakkapellenArray);

            const uniqueBijgebouwenWithoutDuplicates = uniqueBijgebouwenWithoutDakkapellen.filter(
                (bijgebouw) => bijgebouw && !uniqueBijgebouwenOnFloors.includes(bijgebouw)
            );

            const uniqueAanbouwen = getNewestValidAnswer(
                aanbouwenQuestions,
                this.answerController,
                (a) => a !== null && a.contents === '1'
            );

            const parkeerplaatsQuestions = this.questionSet.findQuestionsByTechnicalReference(
                TechnicalReference.OBJECT_OMSCHRIJING_PARKEERPLAATS
            );

            const parkeerplaatsAnswerOptions = parkeerplaatsQuestions.flatMap((q) => q.answerOptions);

            const parkeerplaatsAnswers = this.answerController.answersForQuestionUuids(
                parkeerplaatsQuestions.map((q) => q.uuid)
            );

            const newestParkeerplaatsAnswers = getNewestAnswer(parkeerplaatsAnswers);

            const parkeerplaatsAnswerOption = parkeerplaatsAnswerOptions.find(
                (ao) => ao.id === newestParkeerplaatsAnswers?.answerOptionId
            );

            if (parkeerplaatsAnswerOption) {
                uniqueBijgebouwenWithoutDuplicates.push(parkeerplaatsAnswerOption.contents);
            }

            const allBijgebouwen = [
                ...uniqueBijgebouwenWithoutDuplicates,
                ...uniqueAanbouwen,
                ...uniqueBijgebouwenOnFloors,
                dakkapellen,
            ]
                .map((bijgebouw) => bijgebouw?.toLocaleLowerCase())
                .filter((bijgebouw) => bijgebouw !== undefined);
            this.aanbouw = allBijgebouwen.join(', ');
        });
    }

    private getReferenceSetStream() {
        return combineLatest([
            this.referenceSetsProvider.referenceSets(),
            this.answerController.answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid ?? null, null),
        ]).pipe(
            map(([referenceSets, answer]) => {
                if (referenceSets === null) {
                    return null;
                }

                const thisReferenceSet = referenceSets.find((set) =>
                    findChildRecursiveByPredicate(
                        set.groupTree,
                        (item) => item.answer !== null && item.answer.uuid === answer.uuid
                    )
                );

                return thisReferenceSet ?? null;
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @computed
    public get pricePerM3() {
        if (this.volume === null || this.valuation === null || this.volume === 0) {
            return null;
        }

        return Math.round(this.valuation / this.volume);
    }

    @computed
    public get questionCellKeys() {
        const technicalReferences: TechnicalReferenceQuestionKeys = {
            [TechnicalReference.REFERENCE_OBJECT_PHOTO]: {},
            [TechnicalReference.REFERENCE_OBJECT_OBJECT_DETAILS_GROUP]: {
                [TechnicalReference.REFERENCE_OBJECT_ADRES]: {},
                [TechnicalReference.REFERENCE_OBJECT_BOUWJAAR]: {},
                [TechnicalReference.REFERENCE_OBJECT_ENERGIELABEL]: {},
                [TechnicalReference.REFERENCE_OBJECT_WONING_TYPE]: {},
                [TechnicalReference.REFERENCE_OBJECT_GEBRUIKS_OPPERVLAKTE]: {},
                [TechnicalReference.REFERENCE_OBJECT_INHOUD]: {},
                [TechnicalReference.REFERENCE_OBJECT_PERCEEL_OPPERVLAKTE]: {},
            },
            [TechnicalReference.REFERENCE_OBJECT_VERKOOP_DETAILS_GROUP]: {
                [TechnicalReference.REFERENCE_OBJECT_VERKOOPPRIJS]: {},
            },
            [TechnicalReference.REFERENCE_OBJECT_VERHUUR_DETAILS_GROUP]: {
                [TechnicalReference.REFERENCE_OBJECT_AANVANGS_HUURPRIJS_PER_MAAND]: {},
                [TechnicalReference.REFERENCE_OBJECT_GECORRIGEERDE_HUURPRIJS_PER_MAAND]: {},
                [TechnicalReference.REFERENCE_OBJECT_INGANGSDATUM_HUUR]: {},
            },
            [TechnicalReference.REFERENCE_OBJECT_VERSCHILLEN_GROUP]: {
                [TechnicalReference.REFERENCE_OBJECT_ONDERHOUDSSITUATIE]: {},
                [TechnicalReference.REFERENCE_OBJECT_AANBOUW_CHOICE]: {},
            },
        };

        const keys = {} as {[k in TechnicalReference]: string};

        const populate = (
            technicalReferences: TechnicalReferenceQuestionKeys,
            rootQuestion: Question,
            root = false
        ) => {
            for (const [technicalReference, children] of Object.entries(technicalReferences)) {
                const tree = buildQuestionTree(this.questionSet, rootQuestion);
                const childPath = findChildrenPathByPredicateRecursive(
                    tree,
                    (pair) => pair.item.question.technicalReference === technicalReference
                );

                if (childPath === null || childPath.length === 0) {
                    continue;
                }
                // Ignore the SYMLINK_TARGET and SYMLINK_TARGET_COPY because it is rendered in the symlink-link and is therefore not included in the parentKey created in the QuestionTableCell
                keys[technicalReference as TechnicalReference] = childPath
                    .filter(
                        (pair) =>
                            pair.item.question.type !== NormalQuestionType.SYMLINK_TARGET &&
                            pair.item.question.type !== NormalQuestionType.SYMLINK_TARGET_COPY
                    )
                    .map((pair) => pair.item.question.uuid)
                    .join('.');

                if (root) {
                    keys[technicalReference as TechnicalReference] =
                        rootQuestion.uuid + '.' + keys[technicalReference as TechnicalReference];
                }

                populate(children, childPath[childPath.length - 1].item.question);
            }
        };

        populate(technicalReferences, this.question, true);

        return keys;
    }
}
