import * as React from 'react';

import {observer} from 'mobx-react';
import {SimpleQuestionPresenterProps} from '../simple/simple_question_presenter';
import {PhotoIteratorSmallPresenter} from './photo_iterator_small_presenter';
import {PhotoPickModal} from './photo_question/photo_pick_modal';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {ImageViewer} from '../../../../../components/image_viewer/image_viewer';
import {ImageSlider} from '../../../../../components/image_viewer/image_slider';

interface OwnProps extends SimpleQuestionPresenterProps {
    fileTypes: string[];
}

export const PhotoIteratorSmall: React.FC<OwnProps> = observer(function PhotoIteratorSmall(props) {
    const presenter = usePresenter(
        (container) =>
            new PhotoIteratorSmallPresenter(
                props.fileTypes,
                props.question,
                props.appraisal,
                props.parentAnswerUuid,
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.questionEffectsInteractor(props.appraisal, props.questionSet),
                container.business.userInteractor,
                container.business.attachmentUploadInteractor(props.appraisal, props.questionSet),
                container.business.blobCacheInteractor(),
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.attachmentQuestionsInteractor(props.appraisal, props.questionSet)
            )
    );

    function renderPhotos() {
        if (presenter.loading || presenter.iterations === undefined) {
            return null;
        }
        if (presenter.iterations.length === 0) {
            return renderEmpty();
        }

        const iterations = presenter.iterations;
        const imageUrls = presenter.viewerData?.map((img) => img.url) ?? [];

        return (
            <div className="form-grid-item form-grid-item-rectangle">
                <div className="form-grid-item-img">
                    <div
                        className="form-grid-item-img-content form-grid-item-img-content-no-overlap"
                        style={{height: 150}}
                    >
                        <div className="img-wrap">
                            <ImageViewer images={presenter.viewerData ?? []}>
                                <ImageSlider
                                    key={imageUrls.toString()}
                                    images={imageUrls}
                                    onRealIndexChange={(slider) =>
                                        presenter.setCurrentImageIteration(iterations[slider.realIndex])
                                    }
                                    onInit={() => presenter.setCurrentImageIteration(iterations[0])}
                                />
                            </ImageViewer>

                            {presenter.isAddPhotosOverlayVisible && renderUploadOverlay()}
                        </div>
                    </div>
                </div>
                {!presenter.isDisabled && !props.disabled ? (
                    <div className="form-grid-item-footer d-flex justify-content-between">
                        <button
                            className="btn-link ion-md-trash"
                            onClick={() =>
                                presenter.currentImageIteration
                                    ? presenter.onDeleteClick(presenter.currentImageIteration)
                                    : null
                            }
                        />
                        <button className="btn-link ion-md-add" onClick={() => presenter.toggleAddPhotos()}>
                            {presenter.isAddPhotosOverlayVisible ? 'Annuleer' : "Foto's toevoegen"}
                        </button>
                    </div>
                ) : null}
                {presenter.isPhotoPickModalOpen && (
                    <PhotoPickModal
                        photoPairs={presenter.pairs}
                        loading={presenter.loading}
                        onImagePicked={(pair) => presenter.onImagePicked(pair.photoAnswer)}
                        onClose={() => presenter.togglePhotoPickModal()}
                    />
                )}
            </div>
        );
    }

    function renderUploadOverlay() {
        return (
            <div className="upload-overlay row h-100 pl-4 pr-4">
                <div className="col-6">
                    <label>
                        <input
                            type="file"
                            name="files[]"
                            style={{display: 'none'}}
                            disabled={presenter.isDisabled || props.disabled}
                            onChange={(e) => presenter.onFilesChange(e)}
                            multiple
                        />
                        <span className="ion-md-cloud-upload"></span>
                        <span>Upload foto&apos;s</span>
                    </label>
                </div>
                <div className="col-6">
                    <button onClick={() => presenter.togglePhotoPickModal()}>
                        <span className="ion-md-photos"></span>
                        <span>Kies foto&apos;s</span>
                    </button>
                </div>
            </div>
        );
    }

    function renderEmpty() {
        return (
            <>
                <div className={'form-grid-item form-grid-item-rectangle form-grid-item-rectangle-empty'}>
                    <div className="form-grid-item-img">
                        <div className="form-grid-item-img-content">
                            <div className="img-wrap">{renderUploadOverlay()}</div>
                        </div>
                    </div>
                </div>
                {presenter.isPhotoPickModalOpen && (
                    <PhotoPickModal
                        photoPairs={presenter.pairs}
                        loading={presenter.loading}
                        onImagePicked={(pair) => presenter.onImagePicked(pair.photoAnswer)}
                        onClose={() => presenter.togglePhotoPickModal()}
                    />
                )}
            </>
        );
    }

    return (
        <>
            <span className="label-container">
                <label>
                    {props.question.contents}
                    {props.question.isRequired ? <span className="form-label-highlight">*</span> : null}
                </label>
            </span>
            {renderPhotos()}
        </>
    );
});
