import {action, makeObservable, observable} from 'mobx';
import {DetailModalData} from '../../../photo_details_modal';
import {Uppy} from '@uppy/core';
import {ProgressType} from '../../../camera_presenter';
import {ImageUploadInteractor} from '../../../../../../business/attachments/image_upload_interactor';
import {SimpleQuestionPresenter} from '../../simple/simple_question_presenter';
import {Answer} from '../../../../../../models/answer';

export class SinglePhotoDropZonePresenter extends SimpleQuestionPresenter {
    @observable public uppy: Uppy;
    @observable public detailModalData: DetailModalData | null = null;
    @observable public isAvailable = true;
    @observable public progress: {percentage: number; type: ProgressType} = {
        percentage: 0,
        type: ProgressType.IDLE,
    };
    @observable public errorMessage: string | null = null;
    @observable public url: string | null = null;

    @observable private file: File | null = null;

    constructor(
        private imageUploadInteractor: ImageUploadInteractor,
        private fileTypes: string[],
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);

        if (this.answer !== undefined && this.answer.file !== null) {
            this.url = this.answer.file.url;
        }

        const uppy = new Uppy({
            restrictions: {
                maxNumberOfFiles: 1,
            },
        });

        uppy.on('files-added', (uppyFiles) => {
            const files = uppyFiles.map((uppyFile) => uppyFile.data).filter((file): file is File => 'name' in file);

            if (files.length > 0 && this.answer) {
                if (this.errorMessage != null) {
                    this.clearError();
                }
                this.upload(files, this.answer);
                this.uppy.reset();
            }
        });

        this.uppy = uppy;

        makeObservable(this);
    }

    @action
    private async upload(files: File[], answer: Answer) {
        const uploadResult = await this.imageUploadInteractor.uploadForAnswer(answer.uuid, files[0], {
            fileTypes: this.fileTypes,
            progressCallback: (progress) => {
                this.updateProgress(progress, progress < 1 ? ProgressType.CASUAL : ProgressType.IDLE);
            },
        });

        this.file = files[0];
        if (this.file) {
            this.url = URL.createObjectURL(this.file);
        } else if (this.answer?.file?.url) {
            this.url = this.answer.file.url;
        } else if (this.answer?.file?.uncompressedUrl) {
            this.url = this.answer.file.uncompressedUrl;
        }

        if (uploadResult.succeeded === false) {
            this.alertError();
        }
    }

    public async mount() {
        super.mount();
    }

    public async unmount() {
        this.uppy.close();
        super.unmount();
    }

    @action
    private updateProgress(progress: number, type: ProgressType) {
        this.progress = {percentage: progress, type};
    }

    @action
    private clearError() {
        this.errorMessage = null;
    }

    @action
    private alertError() {
        this.errorMessage =
            'Fout bij het uploaden. Is het een correct formaat? Voor afbeeldingen kun je .jpg en .png uploaden.';
    }
}
