import * as React from 'react';

import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {ReferenceObject} from '../models/reference_object';
import {observer} from 'mobx-react';

interface OwnProps {
    referenceObject: ReferenceObject;
    referenceObjectAnswer: ReferenceObjectAnswer | null;
}

@observer
export default class ReferenceObjectAddress extends React.Component<OwnProps> {
    public render() {
        if (this.props.referenceObjectAnswer !== null) {
            return (
                <>
                    <strong className="grid-item-address">
                        {this.props.referenceObjectAnswer.referenceObject.adres.straat}{' '}
                        <span className="grid-item-address-number">
                            {this.props.referenceObjectAnswer.referenceObject.adres.huisnummer +
                                (this.props.referenceObjectAnswer.referenceObject.adres.huisnummerToevoeging
                                    ? '-' + this.props.referenceObjectAnswer.referenceObject.adres.huisnummerToevoeging
                                    : '')}
                        </span>
                    </strong>
                    <br />
                    {this.props.referenceObjectAnswer.referenceObject.adres.postcode}{' '}
                    {this.props.referenceObjectAnswer.referenceObject.adres.plaats}
                </>
            );
        }
        return (
            <>
                <strong className="grid-item-address">
                    {this.props.referenceObject.street}{' '}
                    <span className="grid-item-address-number">
                        {this.props.referenceObject.houseNumber} {this.props.referenceObject.letter}
                    </span>
                </strong>
                <br />
                {this.props.referenceObject.postalCode} {this.props.referenceObject.city}
            </>
        );
    }
}
