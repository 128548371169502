import {makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {Presenter} from '../../../../../support/presenter/presenter';
import {TreeItem} from '../../../../../support/generic_tree';
import {QuestionAnswerPair, buildPagePartTree} from '../../../../../support/page_part_tree';
import {PagePart} from '../../../../models/page_part';
import {AnswerController} from '../../../../business/answering/answer_controller';
import {QuestionEffectInteractor} from '../../../../business/conditions/question_effects_interactor';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {QuestionSet} from '../../../../models/question_set';

export class TreeViewerPresenter implements Presenter {
    private subscriptions = new CompositeSubscription();

    @observable.ref public trees: Array<TreeItem<QuestionAnswerPair | PagePart>> | null = null;

    constructor(
        private pagePartsSet: PagePartsSet,
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectsInteractor: QuestionEffectInteractor
    ) {
        makeObservable(this);
    }

    private answers = this.answerController.answers();

    public async mount() {
        this.subscriptions.add(
            this.answerController.answersStream().subscribe((answers) => {
                runInAction(() => {
                    this.trees = buildPagePartTree(
                        this.pagePartsSet,
                        this.questionSet,
                        answers,
                        this.questionEffectsInteractor
                    );
                });
            })
        );
    }

    public async unmount() {
        this.subscriptions.clear();
    }
}
