import {ExplanationData} from './explanation_data';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {normalizeNumber} from '../../../../../../../../../business/support/normalize_number';

export class ExplanationVolumeGenerator {
    public generateDetailsSentence(
        explanationData: ExplanationData,
        referenceObjectAnswer: ReferenceObjectAnswer
    ): string {
        const result: string[] = [];
        const volumeDiffPercentage = this.getVolumeDiffPercentage(referenceObjectAnswer, explanationData);
        const volumeDiff = normalizeNumber(
            Math.abs((explanationData.object.volume ?? 0) - (referenceObjectAnswer.referenceObject.inhoud ?? 0))
        );
        if (volumeDiffPercentage !== null) {
            if (volumeDiffPercentage > 30 || volumeDiffPercentage > 10) {
                result.push(`${volumeDiff} m3 meer inhoud`);
            } else if (volumeDiffPercentage < -30 || volumeDiffPercentage < -10) {
                result.push(`${volumeDiff} m3 minder inhoud`);
            }
        }
        return result.join('');
    }

    public generateVolumeStatus(referenceObjectAnswer: ReferenceObjectAnswer, explanationData: ExplanationData) {
        const volumeDiffPercentage = this.getVolumeDiffPercentage(referenceObjectAnswer, explanationData);
        if (volumeDiffPercentage !== null) {
            if (volumeDiffPercentage > 30) {
                return 'Beduidend groter';
            } else if (volumeDiffPercentage > 10) {
                return 'Groter';
            } else if (volumeDiffPercentage < -30) {
                return 'Beduidend minder';
            } else if (volumeDiffPercentage < -10) {
                return 'Minder';
            } else {
                return 'Vergelijkbaar';
            }
        }
        return 'Vergelijkbaar';
    }

    private getVolumeDiffPercentage(
        referenceObjectAnswer: ReferenceObjectAnswer,
        explanationData: ExplanationData
    ): number | null {
        if (referenceObjectAnswer.referenceObject.inhoud === null || explanationData.object.volume === null) {
            return null;
        }

        return normalizeNumber(
            (100 * (referenceObjectAnswer.referenceObject.inhoud - explanationData.object.volume)) /
                referenceObjectAnswer.referenceObject.inhoud
        );
    }
}
