export interface BuildingCostsLabel {
    value: number;
    textValue: string;
    short: string;
    hex: string;
}

export const buildingCostsLabels: BuildingCostsLabel[] = [
    {value: 1, textValue: 'niet waarneembaar', short: 'NW', hex: '#2C7ADB'},
    {value: 2, textValue: 'nader onderzoek nodig', short: 'NO', hex: '#B275A4'},
    {value: 3, textValue: 'slecht', short: 'S', hex: '#FD7B76'},
    {value: 4, textValue: 'matig', short: 'M', hex: '#FCCA03'},
    {value: 5, textValue: 'redelijk', short: 'R', hex: '#97D069'},
    {value: 6, textValue: 'goed', short: 'G', hex: '#2BD7D8'},
];
