import * as React from 'react';

import {Appraisal} from '../../models/appraisal';
import {BootPresenter} from './boot_presenter';
import {Content} from './content/content';
import {Global} from '../../../business/global_provider';
import {History} from 'history';
import {Loader} from './components/loader';
import {PagePartsSet} from '../../models/page_parts_set';
import {QuestionSet} from '../../models/question_set';
import {RenderingContextType} from '../../enum/rendering_context_type';
import {SidebarItem} from '../../business/sidebar_tree_builder';
import {ValidationError} from '../../models/validation_error';
import {ValidationMessageMap} from '../../business/validation/validation_message';
import {observer} from 'mobx-react';
import {toJS} from 'mobx';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {QuestionRenderingData} from '../../models/question_rendering_data';

interface OwnProps {
    global: Global;
    history: History;
    appraisal: Appraisal;
    onChange: (appraisal: Appraisal) => void;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    iteration?: string;
    pagePartsSet: PagePartsSet | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const Boot: React.FC<OwnProps> = observer(function Boot(props) {
    const presenter = usePresenter(
        (container) =>
            new BootPresenter(
                container.business.sidebarTreeBuilder(props.appraisal, props.questionSet, props.pagePartsSet),
                container.business.answerInteractor(props.appraisal, props.questionSet)
            )
    );

    function renderSidebarItem(sidebarItem: SidebarItem) {
        if (!sidebarItem.question) {
            return;
        }
        return (
            <Content
                key={sidebarItem.question.uuid}
                iteration={sidebarItem.iteration === null ? undefined : sidebarItem.iteration}
                global={props.global}
                history={props.history}
                appraisal={props.appraisal}
                uuid={sidebarItem.question.uuid}
                questionSet={props.questionSet}
                validationErrors={props.validationErrors}
                validationMessages={props.validationMessages}
                activePagePart={null}
                pagePartsSet={null}
                forceShowValidationMessages={props.forceShowValidationMessages}
                onChange={() => {
                    /* Noop */
                }}
                renderingContext={props.renderingContext}
                questionRenderingData={props.questionRenderingData}
            />
        );
    }

    if (presenter.sidebar === null) {
        return <Loader />;
    }
    return (
        <>
            {presenter.sidebar
                .reduce((list: SidebarItem[], sidebarItem: SidebarItem) => {
                    //toJS so we can use the spread operator
                    return [...list, toJS(sidebarItem), ...toJS(sidebarItem).children];
                }, [])
                .map((sidebarItem) => renderSidebarItem(sidebarItem))}
        </>
    );
});
