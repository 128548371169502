import {EventSourceMessage, FetchEventSourceInit, fetchEventSource} from '@microsoft/fetch-event-source';
import {AjaxDriver} from './ajax_driver';
import {bugsnagClient} from '../../support/bugsnag_client';
import {Observable} from 'rxjs';

export class FetchDriver implements AjaxDriver {
    public async fetch(input: Request | string, init?: RequestInit): Promise<Response> {
        try {
            const response = await window.fetch(input, init);
            if (response.status === 401 || response.status === 403 || response.status === 419) {
                if (confirm('Wegens inactiviteit ben je uitgelogd, log opnieuw in om je data op te slaan.')) {
                    window.location.reload();
                    return response;
                }
            }
            return response;
        } catch (error) {
            bugsnagClient?.notify(error, {
                metaData: {
                    input,
                },
            });
            throw error;
        }
    }

    public fetchEventSource(input: Request | string, init?: FetchEventSourceInit) {
        return new Observable<EventSourceMessage>((observer) => {
            const ctrl = new AbortController();

            fetchEventSource(input, {
                ...(init ?? {}),
                onmessage(ev) {
                    observer.next(ev);
                    if (init?.onmessage) init.onmessage(ev);
                },
                onerror(err) {
                    bugsnagClient?.notify(err, {
                        metaData: {
                            input,
                        },
                    });

                    observer.error(err);
                    if (init?.onerror) init.onerror(err);

                    // Make sure fetchEventSource doesn't retry
                    throw err;
                },
                onclose() {
                    observer.complete();
                    if (init?.onclose) init.onclose();
                },
                signal: ctrl.signal,
            }).catch((err) => {
                bugsnagClient?.notify(err, {
                    metaData: {
                        input,
                    },
                });

                observer.error(err);
            });

            return () => {
                ctrl.abort();
            };
        });
    }
}
