import * as Uuid from 'uuid';

import {action, autorun, makeObservable, observable, runInAction} from 'mobx';

import {Answer} from '../../../../../models/answer';
import {AnswerAuditTrailItem} from '../../../../../models/answer_audit_trail_item';
import {AnswerAuditTrailItemApi} from '../../../../../network/answer_audit_trail_api';
import {AnswerController} from '../../../../../business/answering/answer_controller';
import {AnswerInteractor} from '../../../../../business/answering/answer_interactor';
import {AnswerTouchState} from '../../../../../enum/answer_touch_state';
import {Appraisal} from '../../../../../models/appraisal';
import {AppraiseSecondaryType} from '../../../../../models/appraise_secondary_config';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {GlobalProvider} from '../../../../../../business/global_provider';
import {ModalOrSecondaryConfigStackInteractor} from '../../../../../business/modal_or_secondary_config_stack_interactor';
import {ModalType} from '../../../../../models/modal_config';
import {NormalQuestionType} from '../../../../../enum/question_type';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {format} from 'date-fns';
import {isEmpty} from '../../../../../../support/util';
import {MultipleChoiceSelectDynamicQuestionPresenter} from '../advanced/multiple_choice_select_dynamic_question_presenter';

interface AuditTrailData {
    auditTrailItems: AnswerAuditTrailItem[][];
    visible: boolean;
    loading: boolean;
    disabled: boolean;
}

export class LabelPresenter implements Presenter {
    @observable.ref
    public answer: Answer | null = null;

    @observable.ref
    public auditTrailData: AuditTrailData = {
        auditTrailItems: [],
        visible: false,
        loading: false,
        disabled: false,
    };

    private secondaryWidgetId = `answer-trail-[${Uuid.v4()}]`;

    private subscriptions = new CompositeSubscription();

    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private question: Question,
        private parentAnswerUuid: string | undefined,
        private iteration: string | undefined,
        private answerInteractor: AnswerInteractor,
        private answerController: AnswerController,
        private answerAuditTrailItemApi: AnswerAuditTrailItemApi,
        private modalOrSecondaryConfigStackInteractor: ModalOrSecondaryConfigStackInteractor,
        private globalProvider: GlobalProvider
    ) {
        const answer = this.answerController.answerByIdentifiers(
            this.question.uuid,
            this.parentAnswerUuid ?? null,
            this.iteration ?? null
        );
        if (answer) {
            this.answer = answer;
        }

        makeObservable(this);
    }

    public mount() {
        this.subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid ?? null, this.iteration ?? null)
                .subscribe((answer) => {
                    runInAction(() => {
                        this.answer = answer;
                    });
                })
        );

        this.subscriptions.add(
            autorun(() => {
                if (this.auditTrailData.visible && this.answer !== undefined && this.parentAnswerUuid !== undefined) {
                    this.modalOrSecondaryConfigStackInteractor.upsert(
                        {
                            id: this.secondaryWidgetId,
                            type: ModalType.ANSWER_TRAIL,
                            auditTrailItems: this.auditTrailData.auditTrailItems,
                            isLoading: this.auditTrailData.loading,
                            isDisabled:
                                !this.globalProvider.global.gateAllowsChangeAuditTrail || this.auditTrailData.disabled,
                            questionType: this.question.type,
                            onClose: () => this.onHide(),
                            onHistoricAnswerClick: this.onHistoricAnswerClick,
                            getAnswerContents: this.getAnswerContents,
                        },
                        {
                            id: this.secondaryWidgetId,
                            type: AppraiseSecondaryType.ANSWER_TRAIL,
                            auditTrailItems: this.auditTrailData.auditTrailItems,
                            isLoading: this.auditTrailData.loading,
                            isDisabled:
                                !this.globalProvider.global.gateAllowsChangeAuditTrail || this.auditTrailData.disabled,
                            questionType: this.question.type,
                            onClose: () => this.onHide(),
                            onHistoricAnswerClick: this.onHistoricAnswerClick,
                            getAnswerContents: this.getAnswerContents,
                        }
                    );
                } else {
                    this.modalOrSecondaryConfigStackInteractor.remove(this.secondaryWidgetId);
                }
            })
        );
    }

    public unmount() {
        this.modalOrSecondaryConfigStackInteractor.remove(this.secondaryWidgetId);

        this.subscriptions.clear();
    }

    @action
    private onHide() {
        this.auditTrailData = {
            ...this.auditTrailData,
            visible: false,
        };
    }

    public onRevealClick = async (answerUuid: string, disabled: boolean) => {
        if (this.auditTrailData.loading === true) {
            return;
        }

        try {
            runInAction(() => {
                this.auditTrailData = {
                    ...this.auditTrailData,
                    loading: true,
                };
            });

            const state: AnswerAuditTrailItem[][] = [];
            if (this.question.type === NormalQuestionType.MULTIPLE_BOOLEAN_GROUP) {
                const childAnswers = this.answerController.answersByParentAnswerUuid(answerUuid);
                const response = await this.answerAuditTrailItemApi.getAuditTrailItems(
                    this.appraisal.id,
                    childAnswers.map((a) => a.uuid)
                );

                for (const auditItem of response) {
                    const previousState = state[0] ?? [];
                    const lastAnswer = previousState[previousState.length - 1];

                    if (lastAnswer && auditItem.updatedAt === lastAnswer.updatedAt) {
                        state[0] = [...previousState.filter((item) => item.uuid !== auditItem.uuid), auditItem];
                    } else {
                        const newState = [...previousState.filter((item) => item.uuid !== auditItem.uuid), auditItem];
                        state.unshift(newState);
                    }
                }
            } else {
                const response = await this.answerAuditTrailItemApi.getAuditTrailItems(this.appraisal.id, [answerUuid]);
                for (const item of response) {
                    state.unshift([item]);
                }
            }

            runInAction(() => {
                this.auditTrailData = {
                    auditTrailItems: state,
                    visible: true,
                    disabled,
                    loading: false,
                };
            });
        } catch (e) {
            console.warn(e);
            runInAction(() => {
                this.auditTrailData = {
                    ...this.auditTrailData,
                    loading: false,
                };
            });
        }
    };

    @action
    public onAuditTrailModalClose = () => {
        this.auditTrailData = {
            ...this.auditTrailData,
            visible: false,
        };
    };

    public onHistoricAnswerClick = async (items: AnswerAuditTrailItem[]) => {
        if (!this.globalProvider.global.gateAllowsChangeAuditTrail) {
            return;
        }
        if (this.answer) {
            try {
                runInAction(() => {
                    this.auditTrailData = {
                        ...this.auditTrailData,
                        loading: true,
                    };
                });

                switch (this.question.type) {
                    case NormalQuestionType.MC:
                    case NormalQuestionType.MC_SELECT:
                    case NormalQuestionType.MC_SELECT_OPTIONAL:
                    case NormalQuestionType.MC_SELECT_COMPARE: {
                        this.answerController.onAnswerOptionChange(
                            this.answer.uuid,
                            items[0].answerOptionId,
                            AnswerTouchState.TOUCHED
                        );
                        break;
                    }
                    case NormalQuestionType.MULTIPLE_BOOLEAN_GROUP: {
                        const childAnswers = this.answerController.answersByParentAnswerUuid(this.answer.uuid);
                        const differentAuditTrailItems = items.filter(
                            (item) =>
                                item.contents !==
                                childAnswers.find((childAnswer) => childAnswer.uuid === item.uuid)?.contents
                        );
                        const missingInTrail = childAnswers.filter(
                            (childAnswer) => items.some((item) => item.uuid === childAnswer.uuid) === false
                        );
                        for (const missingItem of missingInTrail) {
                            if (missingItem.contents === '1') {
                                this.answerController.onContentsChange(missingItem.uuid, '0', AnswerTouchState.TOUCHED);
                            }
                        }
                        for (const differentAuditTrailItem of differentAuditTrailItems) {
                            this.answerController.onContentsChange(
                                differentAuditTrailItem.uuid,
                                differentAuditTrailItem.contents,
                                AnswerTouchState.TOUCHED
                            );
                        }
                        break;
                    }
                    default: {
                        this.answerController.onContentsChange(
                            this.answer.uuid,
                            items[0].contents,
                            AnswerTouchState.TOUCHED
                        );
                        break;
                    }
                }

                await this.answerInteractor.submit();
                this.onAuditTrailModalClose();
            } finally {
                runInAction(() => {
                    this.auditTrailData = {
                        ...this.auditTrailData,
                        loading: false,
                    };
                });
            }
        }
    };

    public getAnswerContents = (trailItem: AnswerAuditTrailItem): string | null => {
        switch (this.question.type) {
            case NormalQuestionType.MC:
            case NormalQuestionType.MC_SELECT:
            case NormalQuestionType.MC_SELECT_OPTIONAL:
            case NormalQuestionType.MC_SELECT_COMPARE: {
                const option = this.question.answerOptions.find((ao) => ao.id === trailItem.answerOptionId);
                return option?.contents ?? trailItem.contents;
            }
            case NormalQuestionType.BOOLEAN: {
                return trailItem.contents === '1' ? 'Ja' : 'Nee';
            }
            case NormalQuestionType.MC_SELECT_DYNAMIC: {
                return MultipleChoiceSelectDynamicQuestionPresenter.format(trailItem.contents);
            }
            case NormalQuestionType.DATE: {
                return trailItem.contents ? format(new Date(trailItem.contents), 'dd-MM-yyyy') : null;
            }
            case NormalQuestionType.MULTIPLE_BOOLEAN_GROUP: {
                return this.questionSet.findQuestionByUuid(trailItem.questionUuid)?.contents ?? trailItem.contents;
            }
            case NormalQuestionType.BUILDING_COSTS_PHOTO: {
                if (isEmpty(trailItem.contents)) {
                    return null;
                }
                return trailItem.file?.url ?? trailItem.file?.uncompressedUrl ?? null;
            }
            default: {
                return trailItem.contents;
            }
        }
    };
}
