import {normalizeString} from './normalize_string';
import v5 from 'uuid/v5';

export function getFileName(file: File | {name: string}) {
    let name = file.name;

    try {
        const data = JSON.parse(atob(name));

        if (data.key) {
            name = data.key.substring(data.key.lastIndexOf('/') + 1);
        } else if (data.url) {
            name = v5(data.url, v5.URL);
        }
    } catch (e) {
        // Ignore, not a base64 encoded JSON string (cloudfront)
    }

    return normalizeString(name);
}

export function getUniqueFileNames<T extends {name: string}>(files: T[]): T[] {
    const uniqueFiles: T[] = [];

    files.forEach((file) => {
        const baseName = file.name.substring(0, file.name.lastIndexOf('.'));
        const extension = file.name.substring(file.name.lastIndexOf('.'));

        let i = 1;
        let newName = file.name;
        while (uniqueFiles.some(({name}) => name === newName)) {
            newName = baseName + ' (' + i + ')' + extension;
            i++;
        }

        uniqueFiles.push({...file, name: newName});
    });

    return uniqueFiles;
}
