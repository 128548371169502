import * as React from 'react';

import {Modal} from '../../content/questions/components/modal';
import {PhotoSet} from '../../content/questions/advanced/reference_objects_question/v3/components/photos/photo_sets';
import {classNames} from '../../../../../support/classnames';

interface OwnProps {
    onClose: (index: number) => void;
    sets: PhotoSet[];
    activeIndex: number;
}

export function ReferenceImagesSetsModal({onClose, sets, activeIndex}: OwnProps) {
    return (
        <Modal
            visible
            forced
            wide
            className={'photo-sets-set-modal'}
            backdropClassName={'photo-sets-set-modal-backdrop'}
        >
            <div className="photo-sets-selector">
                {sets.map((s, i) => {
                    const image = s.images.find((img) => img.label !== 'streetview_vooraanzicht') ?? s.images[0];

                    return (
                        <div
                            key={i}
                            className={classNames('photo-sets-preview', {
                                active: activeIndex === i,
                            })}
                            onClick={() => onClose(i)}
                        >
                            {image.url ? <img src={image.url} /> : null}
                            <div className="photo-sets-description">
                                Foto-set {i + 1}
                                <br />
                                Datum:{' '}
                                {s.quarterDate
                                    ? `Q${Math.floor(s.quarterDate.getMonth() / 3) + 1}-${s.quarterDate.getFullYear()}`
                                    : 'Onbekend'}
                                <br />
                                {s.images.length} foto{s.images.length === 1 ? '' : 's'}
                            </div>
                        </div>
                    );
                })}
            </div>
        </Modal>
    );
}
