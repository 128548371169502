import {action, makeObservable, observable, runInAction} from 'mobx';

import {Presenter} from '../../../../../support/presenter/presenter';
import {SortingDirection, SortingMethod} from '../../../../enum/reference_objects_sorting';
import {UserSettingsApi} from '../../../../network/user_settings_api';
import {GlobalProvider} from '../../../../../business/global_provider';

export class ReferencesPreferencesModalPresenter implements Presenter {
    @observable public sortingMethod: SortingMethod | null;
    @observable public sortingDirection: SortingDirection | null;
    @observable public isSaving = false;

    constructor(private userSettingsApi: UserSettingsApi, private globalProvider: GlobalProvider) {
        this.sortingMethod =
            this.globalProvider.global.referencePreferences?.sortMethod ?? SortingMethod.ALPHABETICALLY;
        this.sortingDirection =
            this.globalProvider.global.referencePreferences?.sortDirection ??
            this.getDefaultSortingDirection(this.sortingMethod);
        makeObservable(this);
    }

    public async mount() {
        // No-op
    }

    public async unmount() {
        // No-op
    }

    @action
    public setSortingMethod(sortingMethod: SortingMethod) {
        this.sortingMethod = sortingMethod;
        this.sortingDirection = this.getDefaultSortingDirection(sortingMethod);
    }

    @action
    public setSortingDirection(sortingDirection: SortingDirection) {
        this.sortingDirection = sortingDirection;
    }

    public async save() {
        runInAction(() => {
            this.isSaving = true;
        });

        const preferences = {
            sortMethod: this.sortingMethod,
            sortDirection: this.sortingDirection,
        };

        try {
            await this.userSettingsApi.storeReferencesPreferences(preferences);

            this.globalProvider.global.referencePreferences = preferences;
        } finally {
            runInAction(() => {
                this.isSaving = false;
            });
        }
    }

    private getDefaultSortingDirection(sortingMethod: SortingMethod): SortingDirection {
        switch (sortingMethod) {
            case SortingMethod.ALPHABETICALLY:
                return SortingDirection.ASCENDING;
            case SortingMethod.SALES_DATE:
                return SortingDirection.DESCENDING;
            case SortingMethod.DISTANCE:
                return SortingDirection.ASCENDING;
            case SortingMethod.DEVIATION_SCORE:
                return SortingDirection.DESCENDING;
        }
    }
}
