import {Observable, combineLatest, concat, from, of} from 'rxjs';
import {ReferenceObjectProvider, ReferenceSalesData} from '../../../../../../../../business/reference_object_provider';
import {debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap} from 'rxjs/operators';

import {Answer} from '../../../../../../../../models/answer';
import {AnswerController} from '../../../../../../../../business/answering/answer_controller';
import {Appraisal} from '../../../../../../../../models/appraisal';
import {AppraisalProvider} from '../../../../../../../../business/appraisal_provider';
import {AppraiseModel, isAppraiseModel} from '../../../../../../../../enum/appraise_model';
import {BuildYearProvider} from '../../../../../../../../business/build_year_provider';
import {PlotAreaProvider} from '../../../../../../../../business/plot_area_provider';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {SurfaceAreaProvider} from '../../../../../../../../business/support/surface_area_provider';
import {TechnicalReference} from '../../../../../../../../enum/technical_reference';
import {UserInteractor} from '../../../../../../../../business/user_interactor';
import {isEmpty} from '../../../../../../../../../support/util';
import {isApartment} from '../../../../../../../../business/support/is_apartment_check';

interface Result {
    loading: boolean;
    referenceSaleData: ReferenceSalesData | null;
}

export interface ReferenceObjectsV1Provider {
    preconditionsAreMetStream: Observable<boolean>;
    referenceObjectsStream: Observable<Result>;
    forceReferenceObjects(): Promise<Result>;
}

type ReferenceData = [Appraisal, number | null, number | null, number | null, Answer[][], Answer[][]];

export class DefaultReferenceObjectsV1Provider implements ReferenceObjectsV1Provider {
    private hasVolumeQuestion = isAppraiseModel(this.appraisal, [AppraiseModel.MODEL2018, AppraiseModel.WOCO2016]);

    private dataStream: Observable<ReferenceData> = combineLatest([
        this.appraisalProvider.stream,
        this.surfaceAreaProvider.surfaceArea(),
        this.plotAreaProvider.plotArea(),
        this.buildYearProvider.stream().pipe(startWith(null)),
        combineLatest(this.getAnswerObservables()).pipe(startWith([])),
        combineLatest(this.getOptionalAnswerObservables()).pipe(startWith([])),
    ]).pipe(shareReplay(1));

    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private appraisalProvider: AppraisalProvider,
        private answerController: AnswerController,
        private referenceObjectInteractor: ReferenceObjectProvider,
        private surfaceAreaProvider: SurfaceAreaProvider,
        private plotAreaProvider: PlotAreaProvider,
        private buildYearProvider: BuildYearProvider,
        private userInteractor: UserInteractor
    ) {}

    public preconditionsAreMetStream = this.dataStream.pipe(
        map(([appraisal, surfaceArea, plotArea, buildYear, answers]) =>
            this.preconditionsAreMet(appraisal, surfaceArea, plotArea, buildYear, answers)
        ),
        shareReplay(1)
    );

    public referenceObjectsStream = this.dataStream.pipe(
        debounceTime(500),
        filter(([appraisal, surfaceArea, plotArea, buildYear, answers]) =>
            this.preconditionsAreMet(appraisal, surfaceArea, plotArea, buildYear, answers)
        ),
        distinctUntilChanged<ReferenceData>(
            (
                [aAppraisal, aSurfaceArea, aPlotArea, aBuildYear, aAnswers, aOptionalAnswers],
                [bAppraisal, bSurfaceArea, bPlotArea, bBuildYear, bAnswers, bOptionalAnswers]
            ) => {
                if (
                    aAppraisal !== bAppraisal ||
                    aAnswers.length !== bAnswers.length ||
                    aOptionalAnswers.length !== bOptionalAnswers.length
                ) {
                    return false;
                }

                const someAnswersAreDifferent = this.hasChange(aAnswers, bAnswers);
                const someOptionalAnswersAreDifferent = this.hasChange(aOptionalAnswers, bOptionalAnswers);
                const surfaceAreaIsDifferent = aSurfaceArea !== bSurfaceArea;
                const plotAreaIsDifferent = aPlotArea !== bPlotArea;
                const buildYearIsDifferent = aBuildYear !== bBuildYear;

                if (
                    someAnswersAreDifferent ||
                    someOptionalAnswersAreDifferent ||
                    surfaceAreaIsDifferent ||
                    plotAreaIsDifferent ||
                    buildYearIsDifferent
                ) {
                    return false;
                }
                return true;
            }
        ),
        switchMap(() =>
            concat(
                of({loading: true, referenceSaleData: null}),
                from(this.referenceObjectInteractor.getReferenceSales()).pipe(
                    map((r) => ({loading: false, referenceSaleData: r}))
                )
            )
        ),
        shareReplay(1)
    );

    public async forceReferenceObjects(): Promise<Result> {
        // Todo: Force getting reference sales on load. Should be replaced with proper refresh logic
        const data = await this.referenceObjectInteractor.getReferenceSales();
        return {
            loading: false,
            referenceSaleData: data,
        } as Result;
    }

    private hasChange(aAnswers: Answer[][], bAnswers: Answer[][]) {
        return aAnswers.some((aArr, index1) => {
            // When there is a difference between the previous and current answers, register it as a change.
            if (aArr.length !== bAnswers[index1].length) {
                return true;
            }
            return aArr.some((aAnswer, index2) => {
                // An answer should have content, answer option or there are more answers for the same technical reference
                return (
                    aAnswer.contents !== bAnswers[index1][index2].contents ||
                    aAnswer.answerOptionId !== bAnswers[index1][index2].answerOptionId ||
                    aArr.filter((a) => !a.isDeleted).length !== bAnswers[index1].filter((a) => !a.isDeleted).length
                );
            });
        });
    }

    private isProvided(answers: Answer[]) {
        return (
            answers.length > 0 &&
            (!isEmpty(answers[answers.length - 1].contents) || answers[answers.length - 1].answerOptionId !== null) &&
            !answers[answers.length - 1].changed
        );
    }

    private preconditionsAreMet(
        appraisal: Appraisal,
        surfaceArea: number | null,
        plotArea: number | null,
        buildYear: number | null,
        answers: Answer[][]
    ): boolean {
        if (
            !this.userInteractor.isAppraiser() &&
            !this.userInteractor.isEmployee() &&
            !this.userInteractor.isJuniorAppraiser()
        ) {
            return false;
        }
        if (appraisal.objectType === null) {
            if (process.env.NODE_ENV === 'development') {
                console.warn('Objecttype is null');
            }
            return false;
        }

        if (!isApartment(appraisal.objectType) && plotArea === null) {
            if (process.env.NODE_ENV === 'development') {
                console.warn('PlotArea is null');
            }
            return false;
        }

        if (buildYear === null) {
            if (process.env.NODE_ENV === 'development') {
                console.warn('Buildyear is null');
            }
            return false;
        }

        if (surfaceArea === null) {
            if (process.env.NODE_ENV === 'development') {
                console.warn('Surfacearea is null');
            }
            return false;
        }

        const answersAreFilled = answers.every((a) => this.isProvided(a));
        if (!answersAreFilled) {
            if (process.env.NODE_ENV === 'development') {
                console.warn('Some answers are not filled', answers);
            }
        }

        if (this.questionSet.reportDefintionConfig.opnameDatumOutsideOfQuestionSet) {
            return answersAreFilled && !isEmpty(appraisal.valuationDate);
        }

        return answersAreFilled;
    }

    private getAnswerObservables(): Array<Observable<Answer[]>> {
        const technicalReferences = [];
        if (this.hasVolumeQuestion) {
            technicalReferences.push(TechnicalReference.OBJECT_VOLUME);
        }
        if (!this.questionSet.reportDefintionConfig.opnameDatumOutsideOfQuestionSet) {
            technicalReferences.push(TechnicalReference.PRICE_DATE);
        }
        const answerObservables: Array<Observable<Answer[]>> = [];

        for (const technicalReference of technicalReferences) {
            const requiredQuestions = this.questionSet.findQuestionsByTechnicalReference(technicalReference);
            if (requiredQuestions.length === 0) {
                throw new Error('No ' + technicalReference + ' question with correct references for reference objects');
            }
            for (const requiredQuestion of requiredQuestions) {
                answerObservables.push(this.answerController.answersForQuestionUuidStream(requiredQuestion.uuid));
            }
        }

        return answerObservables;
    }

    private getOptionalAnswerObservables(): Array<Observable<Answer[]>> {
        const answerObservables: Array<Observable<Answer[]>> = [];

        for (const optionalReference of [
            TechnicalReference.VALUATION_REQUIRES_REFERENCE_OBJECTS,
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_ITERATOR,
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_REQUIRES_REFERENCE_OBJECTS,
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_UITGANGSPUNT,
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_UITGANGSPUNT_VOOR_VERBOUWING_OF_REALISATIE,
            TechnicalReference.HAS_REFERENCE_OBJECTS_VALUATION,
            TechnicalReference.HAS_REFERENCE_OBJECTS_AFTER_CONSTRUCTION,
            TechnicalReference.HAS_REFERENCE_OBJECTS_AFTER_REALISATION,
            TechnicalReference.HAS_REFERENCE_OBJECTS_SPECIAL_VALUE_ARGUMENT,
        ]) {
            const optionalQuestions = this.questionSet.findQuestionsByTechnicalReference(optionalReference);
            for (const optionalQuestion of optionalQuestions) {
                answerObservables.push(this.answerController.answersForQuestionUuidStream(optionalQuestion.uuid));
            }
        }

        return answerObservables;
    }
}
