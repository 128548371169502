import * as React from 'react';

import {Loader} from './loader';

interface OwnProps {
    text?: string;
}

export const QuestionLoader: React.FC<OwnProps> = ({text}) => {
    return (
        <div className="row question-loader">
            <div className="col-12 text-center">
                <Loader isSmall />
                <p>{text ?? 'Aan het laden...'}</p>
            </div>
        </div>
    );
};
