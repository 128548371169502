import {ExplanationData} from './explanation_data';
import {ReferenceObjectAnswer} from '../../../models/reference_object_answer';

export class ExplanationBuildingTypeGenerator {
    public generateDetailsSentence(
        explanationData: ExplanationData,
        referenceObjectAnswer: ReferenceObjectAnswer
    ): string {
        const result: string[] = [];

        result.push(
            `heeft een ${this.formatStatus(referenceObjectAnswer.referenceObject.woningTypeStatus)} woningtype`
        );
        if (referenceObjectAnswer.referenceObject.woningType !== null) {
            result.push(` (${referenceObjectAnswer.referenceObject.woningType.toLocaleLowerCase()})`);
        }

        return result.join('');
    }

    private formatStatus(status: string): string {
        switch (status.toLocaleLowerCase()) {
            case 'veel minder':
                return 'veel slechter';
            case 'minder':
                return 'slechter';
            default:
                return status.toLocaleLowerCase();
        }
    }
}
