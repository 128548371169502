import {MacroSettings} from '../models/macro_settings';
import {getBuildPeriodRange} from '../enum/build_period_type';
import {Answer} from '../models/answer';
import {Appraisal} from '../models/appraisal';
import {Global} from '../../business/global_provider';

export class SuperMacroFilter {
    constructor(private appraisal: Appraisal, private global: Global) {}

    public isVisible(settings: MacroSettings | null, buildYearAnswer: Answer | null): boolean {
        if (settings === null) {
            // No settings means it is always visible
            return true;
        }

        if (settings.newConstruction !== null && settings.newConstruction !== this.appraisal.newConstruction) {
            return false;
        }

        if (
            settings.appraisalGoals !== null &&
            settings.appraisalGoals.length > 0 &&
            !settings.appraisalGoals?.includes(this.appraisal.appraisalGoal)
        ) {
            return false;
        }

        if (
            settings.districtCodes !== null &&
            settings.districtCodes.length > 0 &&
            this.global.appraisalDistrict !== null &&
            this.global.appraisalDistrict?.code &&
            !settings.districtCodes?.includes(this.global.appraisalDistrict.code)
        ) {
            return false;
        }

        if (
            settings.objectTypes !== null &&
            settings.objectTypes.length > 0 &&
            this.appraisal.objectType &&
            !settings.objectTypes?.includes(this.appraisal.objectType)
        ) {
            return false;
        }

        if (buildYearAnswer !== null && settings.buildPeriods !== null && settings.buildPeriods.length > 0) {
            const containsPeriod = settings.buildPeriods.some((period) => {
                const range = getBuildPeriodRange(period);
                return (
                    buildYearAnswer.contents &&
                    parseInt(buildYearAnswer.contents) >= range[0] &&
                    parseInt(buildYearAnswer.contents) <= range[1]
                );
            });
            if (!containsPeriod) {
                return false;
            }
        }

        return true;
    }
}
