import {QuestionSet} from '../../models/question_set';
import {AnswerController} from '../answering/answer_controller';
import {QuestionEffectInteractor} from '../conditions/question_effects_interactor';
import {FloorQuestionType, NormalQuestionType} from '../../enum/question_type';
import {Question} from '../../models/question';
import {isNumeric} from '../../../support/util';
import {groupBy} from '../../../support/array';
import {Answer} from '../../models/answer';
import {formatLabel} from '../../appraise/ui/support/building_costs_reference_formatter';
import * as Uuid from 'uuid';

export interface ConstructionalDefectsGroup {
    id: string;
    title: string;
    group: string;
    question: Question;
    parentQuestion: Question | null;
    floorAnswers: Answer[];
}

export interface ConstructionalDefectsGroupsProvider {
    build(): ConstructionalDefectsGroupMap;
}

type ConstructionalDefectsGroupMap = Map<string, ConstructionalDefectsGroup[]>;

export class DefaultConstructionalDefectsGroupsProvider implements ConstructionalDefectsGroupsProvider {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor
    ) {}

    public build(): ConstructionalDefectsGroupMap {
        const groups: ConstructionalDefectsGroup[] = this.questionSet
            .findQuestionsByType(NormalQuestionType.BUILDING_COSTS_GROUP)
            .filter((q) => !this.questionEffectInteractor.isHidden(q.uuid, null))
            .filter((q) => q.buildingCostsReference !== null && q.contents.toLowerCase().trim() !== 'anders')
            .map((question) => {
                const referenceParts =
                    question.buildingCostsReference?.split('.').map((rp) => {
                        if (isNumeric(rp[rp.length - 1])) {
                            return rp.slice(0, rp.length - 1);
                        }
                        return rp;
                    }) ?? [];

                const parent = question.parentUuid
                    ? this.questionSet.findQuestionByUuid(question.parentUuid) ?? null
                    : null;

                return {
                    id: Uuid.v4(),
                    title: question.contents,
                    group: formatLabel(referenceParts[0]),
                    question: question,
                    parentQuestion: parent,
                    floorAnswers: this.gatherFloorQuestions(question) ?? [],
                };
            });

        return groupBy(groups.sort(this.sort), (i) => i.group);
    }

    private gatherFloorQuestions(question: Question) {
        const floorParent = this.questionSet.findParentByPredicateRecursive(
            question,
            (q) => q.type === FloorQuestionType.FLOOR_GROUP_FLOOR
        );
        if (!floorParent) {
            return null;
        }

        // Todo: make observable??
        return this.answerController
            .filterDeleted(this.answerController.answersForQuestionUuid(floorParent.uuid))
            .filter((answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid))
            .sort(this.sortIteration);
    }

    private sort(a: ConstructionalDefectsGroup, b: ConstructionalDefectsGroup) {
        if (a.group < b.group) {
            return -1;
        }
        if (a.group > b.group) {
            return 1;
        }
        return 0;
    }

    private sortIteration(a: Answer, b: Answer) {
        if (a.iteration === null || b.iteration === null) {
            return 0;
        }
        if (a.iteration < b.iteration) {
            return -1;
        }
        if (a.iteration > b.iteration) {
            return 1;
        }
        return 0;
    }
}
