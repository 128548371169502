import {PagePart} from '../../../../models/page_part';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {Presenter} from '../../../../../support/presenter/presenter';
import {QuestionEffectInteractor} from '../../../../business/conditions/question_effects_interactor';
import {RenderingContextType} from '../../../../enum/rendering_context_type';

export class PagePartIndexPagePresenter implements Presenter {
    constructor(
        private renderingContext: RenderingContextType,
        private questionEffectInteractor: QuestionEffectInteractor
    ) {}

    public async mount() {
        /* Noop */
    }

    public async unmount() {
        /* Noop */
    }

    public children(activePagePart: PagePart, pagePartsSet: PagePartsSet): PagePart[] {
        const children = pagePartsSet.getChildrenForUuid(activePagePart.uuid);

        if (this.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
            return children;
        }

        return children.filter((child) => {
            if (child.questionUuid === null) {
                return true;
            }

            return !this.questionEffectInteractor.isHidden(child.questionUuid, null);
        });
    }
}
