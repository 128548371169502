import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';

import {AlternativeAdaptedDefaultAnswer} from '../components/alternative_adapted_default_answer';
import {CharacterCounter} from '../components/character_counter';
import {Label} from '../components/label';
import {ValidationMessages} from '../components/validation_messages';
import {classNames} from '../../../../../../support/classnames';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const OpenQuestionMaxCharacters: React.FC<OwnProps> = observer(function OpenQuestionMaxCharacters(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));

    if (presenter.isHidden) {
        return null;
    }

    return (
        <div className="pulse-input">
            <div
                className={classNames('form-group', presenter.filledByAutomator, {
                    'automation-filled': presenter.filledByAutomator !== null,
                })}
                data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
            >
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <AlternativeAdaptedDefaultAnswer
                    adaptedDefaultValue={props.adaptedDefaultValue}
                    currentAnswer={presenter.answer}
                    disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                    onChange={(value) => presenter.onChange(value)}
                />
                {props.question.maxCharacters < 100 ? (
                    <input
                        type="text"
                        id={presenter.name}
                        name={presenter.name}
                        className="form-control"
                        disabled={props.disabled || presenter.isDisabled}
                        value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                        maxLength={props.question.maxCharacters}
                        minLength={props.question.minCharacters}
                        placeholder={
                            props.question.minCharacters !== 0
                                ? 'Min. ' +
                                  props.question.minCharacters +
                                  ' en max. ' +
                                  props.question.maxCharacters +
                                  ' tekens'
                                : ' Max. ' + props.question.maxCharacters + ' tekens'
                        }
                        onChange={(e) => presenter.onChange(e.target.value, false)}
                        onBlur={() => presenter.onTouched()}
                        required={props.question.minCharacters > 0}
                    />
                ) : (
                    <textarea
                        id={presenter.name}
                        name={presenter.name}
                        className={classNames('form-control', {
                            'has-inset': presenter.showAddMacroButton,
                        })}
                        rows={3}
                        readOnly={props.disabled || presenter.isDisabled}
                        value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                        onChange={(e) => presenter.onChange(e.target.value, false)}
                        onBlur={() => presenter.onTouched()}
                        maxLength={props.question.maxCharacters}
                        minLength={props.question.minCharacters}
                        required={props.question.minCharacters > 0}
                    />
                )}

                <ValidationMessages
                    alignLeft
                    disabled={props.disabled || presenter.isDisabled}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    answer={presenter.answer}
                    validationMessages={filterValidationMessages(
                        props.question,
                        presenter.answer,
                        props.validationMessages,
                        presenter.validationMessages
                    )}
                />
                <CharacterCounter
                    max={props.question.maxCharacters}
                    min={props.question.minCharacters}
                    count={presenter.answer?.contents ? presenter.answer.contents.length : 0}
                />
            </div>
        </div>
    );
});
