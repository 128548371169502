import {Appraisal} from '../../../../../../../../../../models/appraisal';
import {BuildYearProvider} from '../../../../../../../../../../business/build_year_provider';
import {ExplanationData} from './explanation_data';
import {ValuationProvider} from '../../../../../../../../../../business/valuation_provider';

export interface ExplanationDataProvider {
    get(): ExplanationData;
}

export class DefaultExplanationDataProvider implements ExplanationDataProvider {
    constructor(
        private appraisal: Appraisal,
        private gebruiksoppervlakteWonen: number | null,
        private perceelOppervlakte: number | null,
        private brutoInhoud: number | null,
        private buildYearProvider: BuildYearProvider,
        private valuationProvider: ValuationProvider
    ) {}

    public get(): ExplanationData {
        return {
            object: {
                objectType: this.appraisal.objectType,
                buildYear: this.buildYearProvider.get(),
                floorArea: this.gebruiksoppervlakteWonen ?? null,
                plotArea: this.perceelOppervlakte ?? null,
                volume: this.brutoInhoud ?? null,
            },
            appraisal: {
                valuation: this.valuationProvider.get(),
                appraiseModel: this.appraisal.appraiseModel,
            },
        };
    }
}
