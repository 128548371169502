import {distinctUntilChanged, map, startWith} from 'rxjs/operators';

import {AnswerController} from '../answering/answer_controller';
import {ConditionGroup} from '../../models/condition_group';

export function collectAnswersObservablesForConditionGroups(
    answerController: AnswerController,
    conditionGroups: ConditionGroup[],
    parentAnswerUuid: string | null
) {
    const triggerQuestionUuids: string[] = [];
    for (const conditionGroup of conditionGroups) {
        for (const condition of conditionGroup.conditions) {
            if (condition.questionUuid) {
                triggerQuestionUuids.push(condition.questionUuid);
            }
        }
    }

    return triggerQuestionUuids.map((questionUuid) => {
        return answerController
            .answersForQuestionUuidAndParentAnswerUuidInSameIterationStream(questionUuid, parentAnswerUuid)
            .pipe(
                startWith(null),
                distinctUntilChanged(),
                map((answers) => ({
                    questionUuid,
                    answers,
                }))
            );
    });
}
