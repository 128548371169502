import React from 'react';
import {classNames} from '../../support/classnames';

interface OwnProps {
    id: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    disabled: boolean;
    forceActivated?: boolean;
    extra?: React.ReactNode;
    className?: string;
}

export function PreferencesCheckbox({
    id,
    checked,
    onChange,
    label,
    disabled,
    forceActivated,
    extra,
    className,
}: OwnProps) {
    return (
        <div className={classNames('form-group mb-0', className)}>
            <label className="toggle-checkbox">
                <input
                    type="checkbox"
                    name={id}
                    id={id}
                    onChange={onChange}
                    checked={checked}
                    disabled={disabled}
                    value={checked ? '1' : '0'}
                />
                <div className="toggle-checkbox__bullet">{forceActivated && <span className="ion-md-checkmark" />}</div>
            </label>
            <label htmlFor={id}>
                {label}
                {extra}
            </label>
            {disabled && <input type="hidden" name={id} value={checked ? '1' : '0'} />}
        </div>
    );
}
