import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';

import {AdaptedDefaultValuesMap} from '../../../../../models/adapted_default_values_map';
import {Answer} from '../../../../../models/answer';
import {NormalQuestionType} from '../../../../../enum/question_type';
import {Question} from '../../../../../models/question';
import {QuestionContainer} from '../question_container';
import {QuestionSet} from '../../../../../models/question_set';
import {createValidationMessagesMap} from '../../../../../../support/create_validation_messages_map';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {shouldShowTitle} from '../../../../../../support/should_show_title';

interface OwnProps extends SimpleQuestionPresenterProps {
    questionSet: QuestionSet;
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
}

export const QuestionRow: React.FC<OwnProps> = observer(function QuestionRow(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));

    if (presenter.isHidden) {
        return null;
    }

    const answer = presenter.answer;
    if (answer === undefined) {
        return <div>Loading questionRow...</div>;
    }

    const questionRowQuestions = findChildrenForQuestion(props.question, props.questionSet);

    function thisShouldShowTitle() {
        // undefined = top level, so no title
        if (props.parentAnswerUuid === undefined) {
            return false;
        }
        return shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel);
    }
    function renderTitle() {
        return thisShouldShowTitle() ? (
            <div>
                <h3>{props.question.contents}</h3>
            </div>
        ) : null;
    }

    function renderBuildingCostsRow(questionRowQuestions: Question[], answer: Answer) {
        const photoQuestions = questionRowQuestions.filter((q) => q.type === NormalQuestionType.BUILDING_COSTS_PHOTO);
        const otherQuestions = questionRowQuestions.filter((q) => q.type !== NormalQuestionType.BUILDING_COSTS_PHOTO);

        const shouldShowPhotoFullWidth = otherQuestions.every((otherQuestion) =>
            props.hiddenQuestionTypes.includes(otherQuestion.type)
        );
        if (shouldShowPhotoFullWidth) {
            return <div className="row">{renderColumn(photoQuestions, answer, 'col-12', 'col-12')}</div>;
        }

        const shouldShowOtherFullWidth = photoQuestions.every((photoQuestion) =>
            props.hiddenQuestionTypes.includes(photoQuestion.type)
        );
        if (shouldShowOtherFullWidth) {
            return <div className="row">{renderColumn(otherQuestions, answer, 'col-12', 'col-12')}</div>;
        }

        return (
            <div>
                {renderTitle()}
                <div className="form-columns">
                    <div className="row">
                        {renderColumn(otherQuestions, answer, 'col-12 col-md-7', 'col-12')}
                        {renderColumn(photoQuestions, answer, 'col-12 col-md-5', 'col-12')}
                    </div>
                </div>
            </div>
        );
    }

    function renderDefault(questionRowQuestions: Question[], answer: Answer) {
        return (
            <div>
                {renderTitle()}
                <div className="form-columns">
                    <div className="row">{renderColumn(questionRowQuestions, answer, 'col-12', 'col-12 col-md-6')}</div>
                </div>
            </div>
        );
    }

    function renderColumn(questions: Question[], answer: Answer, parentClassName: string, childClassName: string) {
        return (
            <div className={parentClassName}>
                <div className="row">
                    {questions.map((child) => {
                        return (
                            <div key={child.uuid} className={childClassName}>
                                <QuestionContainer
                                    key={child.uuid}
                                    disabled={props.disabled}
                                    appraisal={props.appraisal}
                                    question={child}
                                    questionSet={props.questionSet}
                                    parentAnswerUuid={answer.uuid}
                                    validationErrors={props.validationErrors}
                                    validationMessages={createValidationMessagesMap(
                                        presenter.validationMessages,
                                        props.validationMessages
                                    )}
                                    forceShowValidationMessages={props.forceShowValidationMessages}
                                    adaptedDefaultValues={props.adaptedDefaultValues}
                                    disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                                    hiddenQuestionTypes={props.hiddenQuestionTypes}
                                    onChange={props.onChange}
                                    pagePartsSet={props.pagePartsSet}
                                    activePagePart={props.activePagePart}
                                    renderingContext={props.renderingContext}
                                    questionRenderingData={props.questionRenderingData}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    if (props.question.type === NormalQuestionType.BUILDING_COSTS_ROW) {
        return renderBuildingCostsRow(questionRowQuestions, answer);
    }

    return renderDefault(questionRowQuestions, answer);
});
