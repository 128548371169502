import * as React from 'react';

import {ApiGlobal, Global} from '../../../../../business/global_provider';
import {Appraisal} from '../../../../models/appraisal';
import {BuildingCostsAdvicePresenter} from './building_costs_advice_presenter';
import {ConstructionDefectsWidgetContext} from '../../appraise_secondary/construction_defects/construction_defects_widget_context';
import {ConstructionalDefects} from '../../appraise_secondary/construction_defects/constructional_defects';
import {ErrorBoundary} from '../../../../components/error_boundary';
import {History} from 'history';
import {PagePart} from '../../../../models/page_part';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {QuestionSet} from '../../../../models/question_set';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {ValidationError} from '../../../../models/validation_error';
import {ValidationMessageMap} from '../../../../business/validation/validation_message';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {QuestionRenderingData} from '../../../../models/question_rendering_data';

declare const GLOBAL: ApiGlobal;

interface OwnProps {
    history: History;
    global: Global;
    appraisal: Appraisal;
    onChange: (appraisal: Appraisal) => void;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    forceShowValidationMessages: boolean;
    questionRenderingData: QuestionRenderingData | null;
}

export const BuildingCostsAdvice: React.FC<OwnProps> = observer(function BuildingCostsAdvice(props) {
    const presenter = usePresenter(
        (container) =>
            new BuildingCostsAdvicePresenter(
                GLOBAL.question_uuid ?? null,
                GLOBAL.parent_answer_uuid ?? null,
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.childQuestionValidationProvider(
                    props.appraisal,
                    props.questionSet,
                    props.validationErrors
                )
            )
    );

    if (presenter.question === undefined || !GLOBAL.parent_answer_uuid) {
        if (process.env.NODE_ENV === 'development') {
            console.warn('Not found in building_costs_advice.tsx');
        }
        return (
            <div className="empty-message appraise">
                <h3>Helaas, deze pagina is niet gevonden (404)</h3>
                <span className="spacer-gradient">&nbsp;</span>
            </div>
        );
    }

    return (
        <ErrorBoundary>
            <div>
                <div className="card-header">Second opinion</div>
                <div className="card-body">
                    <ConstructionalDefects
                        key={presenter.question.uuid}
                        appraisal={props.appraisal}
                        onClose={() => {
                            //Noop
                        }}
                        question={presenter.question}
                        questionSet={props.questionSet}
                        validationErrors={props.validationErrors}
                        validationMessages={props.validationMessages}
                        parentAnswerUuid={GLOBAL.parent_answer_uuid}
                        constructionCostsWidgetContext={ConstructionDefectsWidgetContext.ADVICE}
                        onChangeConstructionCostsWidgetContext={() => {
                            //Noop
                        }}
                        pagePartsSet={props.pagePartsSet}
                        activePagePart={props.activePagePart}
                        renderingContext={props.renderingContext}
                        forceShowValidationMessages={props.forceShowValidationMessages}
                        questionRenderingData={props.questionRenderingData}
                    />
                </div>
            </div>
        </ErrorBoundary>
    );
});
