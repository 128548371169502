import * as React from 'react';

import {CSS} from '@dnd-kit/utilities';
import {ChildSidebarItem} from './child_sidebar_item';
import {FunctionComponent} from 'react';
import {PagePart} from '../../../models/page_part';
import {useSortable} from '@dnd-kit/sortable';

interface OwnProps {
    pagePartsConfigurationId: number;
    activePagePartUuid: string | null;
    childPagePart: PagePart;
    onDelete: () => void;
}

export const SortableChild: FunctionComponent<OwnProps> = ({
    pagePartsConfigurationId,
    activePagePartUuid,
    childPagePart,
    onDelete,
}) => {
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: childPagePart.uuid});

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <ChildSidebarItem
                uuid={childPagePart.uuid}
                key={childPagePart.uuid}
                pagePartsConfigurationId={pagePartsConfigurationId}
                isActive={childPagePart.uuid === activePagePartUuid}
                label={childPagePart.name ?? childPagePart.uuid}
                hasError={false}
                hasVisited={false}
                onDelete={onDelete}
            />
        </div>
    );
};
