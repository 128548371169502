import * as React from 'react';

import {ModalState, ModalType} from '../../models/modal_state';
import {ReferenceObjectAnswer, referenceObjectAnswerFromSale} from '../../models/reference_object_answer';

import {AddCustomReferenceObjectModal} from './add_custom_reference_object_modal';
import {Appraisal} from '../../../../../../../../../models/appraisal';
import {PagePart} from '../../../../../../../../../models/page_part';
import {PagePartsSet} from '../../../../../../../../../models/page_parts_set';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {QuestionType} from '../../../../../../../../../enum/question_type';
import {ReferenceObjectAnswerModal} from './reference_object_answer_modal';
import {ReferenceObjectDetailsModal} from './reference_object_details_modal';
import {RenderingContextType} from '../../../../../../../../../enum/rendering_context_type';
import {Sale} from '../../../../../../../../../models/sale';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';
import {ValidationError} from '../../../../../../../../../models/validation_error';
import {getObjectPrice} from '../../internal/get_object_price';
import {isEmpty} from '../../../../../../../../../../support/util';
import {format} from 'date-fns';
import {ObjectType} from '../../../../../../../../../enum/object_type';
import {ReferenceObjectComparisonModal} from './reference_object_comparison_modal';
import {SetDefinition} from '../../reference_objects_question_presenter';
import {TreeItem} from '../../../../../../../../../../support/generic_tree';
import {QuestionAnswerPair} from '../../../../../../../../../models/question_answer_pair';
import {getUrlFromImageUrlPair} from '../../../../../../../../../models/image_url_pair';
import {QuestionRenderingData} from '../../../../../../../../../models/question_rendering_data';
import {QuestionRenderingDataType} from '../../../../../../../../../enum/question_rendering_data_type';
import {ConfigWithCallbacks} from '../../../../../../../../../business/generic_config_stack_interactor';
import {ReferenceImageViewer} from '../photos/image_viewer';

export interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    modalState: ConfigWithCallbacks<ModalState, unknown>[];
    isFrozen: boolean;
    disabled?: boolean;
    onModalHide: () => void;
    onAddAndOpenModal: (referenceObjectAnswer: ReferenceObjectAnswer) => Promise<void>;
    onRemove: (answerUuid: string) => void;
    showComparisonModal: (setDefinition: SetDefinition) => void;
    showAnswerModal: (referenceObjectAnswer: ReferenceObjectAnswer, treeItem: TreeItem<QuestionAnswerPair>) => void;
    validationErrors: ValidationError[];
    forceShowValidationMessages: boolean;
    hiddenQuestionTypes: QuestionType[];

    setDefinitionGebruiksOppervlakteBuitenruimte: number | null;
    setDefinitionOverigeInpandigeRuimte: number | null;
    setDefinitionGebruiksOppervlakteExterneBergruimte: number | null;
    setDefinitionBuildYear: number | null;
    setDefinitionPlotArea: number | null;
    setDefinitionValuation: number | null;
    setDefinitionSurfaceArea: number | null;
    setDefinitionType: SetType | null;
    setDefinitionVolume: number | null;
    setDefinitionEnergyLabel: string | null;
    setDefinitionObjectType: ObjectType | string | null;
    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const ReferenceObjectModals: React.FC<OwnProps> = function ReferenceObjectModals(props) {
    function renderModal(modal: ConfigWithCallbacks<ModalState, unknown>) {
        switch (modal.type) {
            case ModalType.CUSTOM_REFERENCE_SALE: {
                return (
                    <AddCustomReferenceObjectModal
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        onHide={props.onModalHide}
                        onAddAndOpenModal={props.onAddAndOpenModal}
                        setType={props.setDefinitionType ?? SetType.SOLD}
                        subscriptions={modal.subscriptions}
                        visible={true}
                        referenceObjectsAnswers={[]} //props.activeSetDefinition?.referenceObjectAnswers ?? []
                        disabled={props.disabled}
                    />
                );
            }
            case ModalType.OBJECT_ANSWER: {
                return (
                    <ReferenceObjectAnswerModal
                        onHide={() => props.onModalHide()}
                        visible={true}
                        isFrozen={props.isFrozen}
                        appraisal={props.appraisal}
                        question={modal.question}
                        questionSet={props.questionSet}
                        parentAnswerUuid={modal.parentAnswerUuid}
                        referenceObjectAnswer={modal.referenceObjectAnswer}
                        validationErrors={props.validationErrors}
                        forceShowValidationMessages={props.forceShowValidationMessages}
                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                        gebruiksoppervlakteWonen={props.setDefinitionSurfaceArea}
                        gebruiksOppervlakteBuitenruimte={props.setDefinitionGebruiksOppervlakteBuitenruimte}
                        overigeInpandigeRuimte={props.setDefinitionOverigeInpandigeRuimte}
                        gebruiksOppervlakteExterneBergruimte={props.setDefinitionGebruiksOppervlakteExterneBergruimte}
                        buildYear={props.setDefinitionBuildYear}
                        perceelOppervlakte={props.setDefinitionPlotArea}
                        pagePartsSet={props.pagePartsSet}
                        activePagePart={props.activePagePart}
                        renderingContext={props.renderingContext}
                        showComparisonModal={props.showComparisonModal}
                        setDefinition={modal.setDefinition}
                        questionRenderingData={{
                            type: QuestionRenderingDataType.REFERENCE_OBJECTS,
                            setDefinitionType: modal.setDefinition.type,
                        }}
                    >
                        {props.isFrozen ? null : (
                            <button
                                className="btn btn-secondary ion-md-square-outline"
                                type="button"
                                onClick={() => props.onRemove(modal.iteratorAnswerUuid)}
                            >
                                Uitvinken
                            </button>
                        )}
                    </ReferenceObjectAnswerModal>
                );
            }
            case ModalType.OBJECT_ANSWERS_COMPARISON: {
                return (
                    <ReferenceObjectComparisonModal
                        key={modal.activeSetDefinition.valuationType}
                        onHide={() => props.onModalHide()}
                        visible={true}
                        isFrozen={props.isFrozen}
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        referenceObjects={modal.referenceObjects}
                        validationErrors={props.validationErrors}
                        forceShowValidationMessages={props.forceShowValidationMessages}
                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                        gebruiksoppervlakteWonen={props.setDefinitionSurfaceArea}
                        gebruiksOppervlakteBuitenruimte={props.setDefinitionGebruiksOppervlakteBuitenruimte}
                        overigeInpandigeRuimte={props.setDefinitionOverigeInpandigeRuimte}
                        gebruiksOppervlakteExterneBergruimte={props.setDefinitionGebruiksOppervlakteExterneBergruimte}
                        buildYear={props.setDefinitionBuildYear}
                        perceelOppervlakte={props.setDefinitionPlotArea}
                        pagePartsSet={props.pagePartsSet}
                        activePagePart={props.activePagePart}
                        renderingContext={props.renderingContext}
                        questionRenderingData={props.questionRenderingData}
                        activeSetDefinition={modal.activeSetDefinition}
                        referenceSets={modal.referenceSets}
                        showComparisonModal={props.showComparisonModal}
                        showAnswerModal={props.showAnswerModal}
                    />
                );
            }
            case ModalType.REFERENCE_OBJECT_DETAILS: {
                const {referenceObject, referenceObjectAnswer} = modal;
                if (referenceObjectAnswer !== null) {
                    return (
                        <ReferenceObjectDetailsModal
                            appraisal={props.appraisal}
                            questionSet={props.questionSet}
                            valuation={props.setDefinitionValuation}
                            referenceBuildyear={props.setDefinitionBuildYear}
                            referencePlotArea={props.setDefinitionPlotArea}
                            referenceFloorArea={props.setDefinitionSurfaceArea}
                            referenceVolume={props.setDefinitionVolume}
                            referenceObjectType={props.setDefinitionObjectType}
                            referenceEnergyLabel={props.setDefinitionEnergyLabel}
                            visible={true}
                            onHide={() => props.onModalHide()}
                            canAdd={false}
                            onAdd={null}
                            isFrozen={props.isFrozen}
                            overview={referenceObjectAnswer.referenceObject.overview ?? null}
                            installationDates={referenceObjectAnswer.referenceObject.installationDates ?? null}
                            id={referenceObjectAnswer.id}
                            objectType={referenceObjectAnswer.referenceObject.woningType}
                            street={referenceObjectAnswer.referenceObject.adres.straat}
                            houseNumber={referenceObjectAnswer.referenceObject.adres.huisnummer}
                            letter={referenceObjectAnswer.referenceObject.adres.huisnummerToevoeging}
                            postalCode={referenceObjectAnswer.referenceObject.adres.postcode}
                            city={referenceObjectAnswer.referenceObject.adres.plaats}
                            buildYear={referenceObjectAnswer.referenceObject.bouwjaar}
                            floorArea={referenceObjectAnswer.referenceObject.gebruiksOppervlakte}
                            plotArea={referenceObjectAnswer.referenceObject.perceelOppervlakte}
                            volume={referenceObjectAnswer.referenceObject.inhoud}
                            referenceObjectAnswer={referenceObjectAnswer}
                            priceRange={
                                modal.setDefinition !== null
                                    ? getObjectPrice(modal.setDefinition.type, referenceObjectAnswer.referenceObject)
                                    : null
                            }
                            saleQuarter={
                                referenceObjectAnswer?.saleQuarter && !isEmpty(referenceObjectAnswer.saleQuarter)
                                    ? referenceObjectAnswer.saleQuarter.length > 8
                                        ? format(new Date(referenceObjectAnswer.saleQuarter), 'dd-MM-yyyy')
                                        : 'Q' + referenceObjectAnswer.saleQuarter
                                    : '-'
                            }
                            highlights={referenceObject?.highlights ?? null}
                            frontview={null}
                            additionalPropertiesByCategory={referenceObject?.additionalPropertiesByCategory ?? null}
                            saleText={referenceObject?.saleText ?? null}
                            energyLabel={referenceObjectAnswer.referenceObject.energielabel}
                            ownership={referenceObject?.ownership ?? null}
                            rooms={referenceObject?.rooms ?? null}
                            transportDate={referenceObjectAnswer.referenceObject.transportdatum ?? null}
                            daysOpenForSale={referenceObject?.daysOpenForSale ?? null}
                            indexedPriceRange={referenceObject?.indexedPriceRange ?? null}
                            brochureUrl={referenceObject?.brochureUrl ?? null}
                            source={referenceObjectAnswer.referenceObject.bronGegevens}
                            rejectReason={null}
                            complete={true}
                            setDefinition={modal.setDefinition}
                            referenceObject={null}
                            isEnhancing={modal.isEnhancing}
                        />
                    );
                } else if (referenceObject !== null) {
                    return (
                        <ReferenceObjectDetailsModal
                            appraisal={props.appraisal}
                            questionSet={props.questionSet}
                            valuation={props.setDefinitionValuation}
                            referenceBuildyear={props.setDefinitionBuildYear}
                            referencePlotArea={props.setDefinitionPlotArea}
                            referenceFloorArea={props.setDefinitionSurfaceArea}
                            referenceVolume={props.setDefinitionVolume}
                            referenceObjectType={props.setDefinitionObjectType}
                            referenceEnergyLabel={props.setDefinitionEnergyLabel}
                            visible={true}
                            onHide={() => props.onModalHide()}
                            isFrozen={props.isFrozen}
                            canAdd={modal.canAdd}
                            onAdd={async (sale: Sale) => {
                                const answer = referenceObjectAnswerFromSale(
                                    sale,
                                    referenceObject.saleQuarter,
                                    referenceObject.images
                                );
                                await props.onAddAndOpenModal(answer);
                            }}
                            overview={referenceObject.overview}
                            installationDates={referenceObject.installationDates}
                            id={referenceObject.id}
                            objectType={referenceObject.objectType}
                            street={referenceObject.street}
                            houseNumber={referenceObject.houseNumber}
                            letter={referenceObject.letter}
                            postalCode={referenceObject.postalCode}
                            city={referenceObject.city}
                            buildYear={referenceObject.buildYear}
                            floorArea={referenceObject.floorArea}
                            plotArea={referenceObject.plotArea}
                            volume={referenceObject.volume}
                            priceRange={referenceObject.priceRange}
                            indexedPriceRange={referenceObject.indexedPriceRange}
                            saleQuarter={referenceObject.saleQuarter}
                            highlights={referenceObject.highlights}
                            frontview={getUrlFromImageUrlPair(referenceObject.frontview)}
                            energyLabel={referenceObject.energyLabel}
                            ownership={referenceObject.ownership}
                            rooms={referenceObject.rooms}
                            transportDate={referenceObject.transportDate}
                            daysOpenForSale={referenceObject.daysOpenForSale}
                            brochureUrl={referenceObject.brochureUrl}
                            source={referenceObject.source}
                            complete={referenceObject.complete}
                            additionalPropertiesByCategory={referenceObject.additionalPropertiesByCategory}
                            saleText={referenceObject.saleText}
                            rejectReason={referenceObject.rejectReason}
                            setDefinition={modal.setDefinition}
                            referenceObject={referenceObject}
                            referenceObjectAnswer={null}
                            isEnhancing={modal.isEnhancing}
                        />
                    );
                } else {
                    return null;
                }
            }
            case ModalType.IMAGE_VIEWER: {
                return (
                    <ReferenceImageViewer
                        images={modal.images}
                        initialOpenIndex={modal.initialIndex}
                        onClose={() => props.onModalHide()}
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        photoAnswerUuid={modal.photoAnswerUuid}
                        hideSlider
                    />
                );
            }
            case ModalType.NONE:
            default: {
                return null;
            }
        }
    }

    const topModal = props.modalState[props.modalState.length - 1];

    return (
        <>
            {topModal ? (
                <React.Fragment key={topModal.id ?? topModal.type}>{renderModal(topModal)}</React.Fragment>
            ) : null}
        </>
    );
};
