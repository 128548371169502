import {observer} from 'mobx-react';
import React, {useMemo} from 'react';
import {AppraiserStatistic} from '../../../appraising/models/appraiser_statistic';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {DoughnutChart} from './dougnut_chart';
import {WidgetPresenter} from './widget_presenter';
import {ObjectType} from '../../../appraising/enum/object_type';
import {WarningWrapper} from '../components/warning_wrapper';
import {Collapsible} from '../../../appraising/appraise/ui/components/collapsible';
import {PrecheckWidget} from '../components/widget';

function getDataObjectByDistance(
    keys: string[],
    labels: string[],
    statistics: Record<string, AppraiserStatistic>,
    activeIndices?: number[],
    warningSuffix?: string
) {
    const datasets: {
        data: number[];
        labels: string[];
        weight: number;
    }[] = [];

    const d10Vals = keys.map((key) => statistics[`${key}_within_10_km`]?.total ?? 0);
    const d20Vals = keys.map((key) => statistics[`${key}_within_20_km`]?.total ?? 0);

    datasets.push({
        data: d20Vals.map((v, i) => v - d10Vals[i]),
        labels: labels.map((label) => `${label} - binnen 20 km`),
        weight: 3,
    });

    datasets.push({
        data: d10Vals,
        labels: labels.map((label) => `${label} - binnen 10 km`),
        weight: 4,
    });

    const overallTotal = statistics['total']?.total ?? 0;
    const activeTotal = activeIndices?.reduce(
        (accumulator, index) => accumulator + (statistics[keys[index]]?.total ?? 0),
        0
    );
    const activeD10Total = activeIndices?.reduce(
        (accumulator, index) => accumulator + (statistics[`${keys[index]}_within_10_km`]?.total ?? 0),
        0
    );
    const activeD20Total = activeIndices?.reduce(
        (accumulator, index) => accumulator + (statistics[`${keys[index]}_within_20_km`]?.total ?? 0),
        0
    );

    let warning: string | undefined;

    if (activeIndices && activeIndices.length > 0 && activeTotal !== undefined && warningSuffix) {
        if (activeTotal / overallTotal < 0.1) {
            warning = `Minder dan 10% van al je taxaties ${warningSuffix}`;
        } else if ((activeD20Total ?? Infinity) / (statistics['total_within_20_km']?.total ?? 0) < 0.1) {
            warning = `Minder dan 10% van je taxaties binnen 20 km ${warningSuffix}`;
        } else if ((activeD10Total ?? Infinity) / (statistics['total_within_10_km']?.total ?? 0) < 0.1) {
            warning = `Minder dan 10% van je taxaties binnen 10 km ${warningSuffix}`;
        }
    }

    return {
        data: datasets,
        total: activeTotal ?? overallTotal,
        activeIndices,
        warning,
    };
}

export const Widget: React.FC = observer(() => {
    const presenter = usePresenter(
        (component) => new WidgetPresenter(component.business.appraisalProvider, component.network.appraisalApi)
    );

    const objectTypeByDistanceData = useMemo(() => {
        let activeIndex = -1;
        if (presenter.objectSummary?.objectType) {
            switch (presenter.objectSummary.objectType) {
                case ObjectType.TUSSENWONING:
                case ObjectType.EINDWONING:
                case ObjectType.HOEKWONING: {
                    activeIndex = 0;
                    break;
                }
                case ObjectType.VRIJSTAAND:
                case ObjectType.HALFVRIJSTAANDE_WONING: {
                    activeIndex = 1;
                    break;
                }
                case ObjectType.TWEE_ONDER_EEN_KAPWONING:
                case ObjectType.GESCHAKELDE_TWEE_ONDER_EEN_KAPWONING:
                case ObjectType.GESCHAKELDE_WONING: {
                    activeIndex = 2;
                    break;
                }
                case ObjectType.CORRIDORFLAT:
                case ObjectType.PORTIEKWONING:
                case ObjectType.BOVENWONING:
                case ObjectType.GALERIJFLAT:
                case ObjectType.PORTIEKFLAT:
                case ObjectType.BENEDENWONING:
                case ObjectType.MAISONNETTE: {
                    activeIndex = 3;
                    break;
                }
            }
        }

        const keys = ['total_row_objects', 'total_freestanding_objects', 'total_joined_objects', 'total_apartments'];
        const labels = ['Rij-woningen', 'Vrijstaande woningen', 'Twee-onder-een-kap woningen', 'Appartementen'];
        const warningLabels = ['rij-woning', 'vrijstaande woning', 'twee-onder-een-kap woning', 'appartement'];

        return getDataObjectByDistance(
            keys,
            labels,
            presenter.appraiserStatisticsByKey,
            activeIndex === -1 ? undefined : [activeIndex],
            activeIndex === -1 ? undefined : `had betrekking op een ${warningLabels[activeIndex]}.`
        );
    }, [presenter.appraiserStatisticsByKey, presenter.objectSummary?.objectType]);

    const goalByDistanceData = useMemo(() => {
        const goals = [
            'acquire_mortage',
            'insight_for_purchase',
            'insight_for_sale',
            'insight_for_repurchase',
            'forced_sale',
            'other',
        ];

        const activeIndex = presenter.appraisal?.appraisalGoal ? goals.indexOf(presenter.appraisal.appraisalGoal) : -1;

        const labels = [
            'Financiering',
            'Aankoopbeslissing',
            'Verkoopbeslissing',
            'Terugkoopbeslissing (WoCo)',
            'Bijzonder Beheer',
            'Overig',
        ];

        const warningLabels = [
            'financiering',
            'aankoopbeslissing',
            'verkoopbeslissing',
            'terugkoopbeslissing',
            'bijzonder beheer',
        ];

        return getDataObjectByDistance(
            goals,
            labels,
            presenter.appraiserStatisticsByKey,
            activeIndex === -1 ? undefined : [activeIndex],
            activeIndex === -1 || !warningLabels[activeIndex]
                ? undefined
                : `had ${warningLabels[activeIndex]} als doel.`
        );
    }, [presenter.appraiserStatisticsByKey, presenter.appraisal?.appraisalGoal]);

    const specialPropertiesByDistanceData = useMemo(() => {
        const activeIndices = [];

        if (presenter.appraisal?.newConstruction) {
            activeIndices.push(0);
        }

        if (presenter.appraisal?.improvementsPlanned) {
            activeIndices.push(1);
        }

        if (presenter.appraisal?.otherValueConceptsNotes?.toLowerCase().includes('verhuurd')) {
            activeIndices.push(2);
        }

        if (activeIndices.length === 0) {
            activeIndices.push(3);
        }

        const keys = ['new_construction', 'improvements_planned', 'rented_state', 'no_special_properties'];
        const labels = ['Nieuwbouw', 'Verbouwing', 'Verhuurde staat', 'Geen'];
        const warningLabels = [
            'was nieuwbouw.',
            'had betrekking op een verbouwing.',
            'had verhuurde staat als bijzonder uitgangspunt.',
        ];

        return getDataObjectByDistance(
            keys,
            labels,
            presenter.appraiserStatisticsByKey,
            activeIndices,
            activeIndices.length === 0 || !warningLabels[activeIndices[0]] ? undefined : warningLabels[activeIndices[0]]
        );
    }, [presenter.appraiserStatisticsByKey, presenter.appraisal]);

    return (
        <PrecheckWidget
            id="statistics"
            title="Statistieken"
            icon="ion-md-stats"
            description="Bekijk je statistieken met betrekking tot deze taxatie."
            errorStatus={
                objectTypeByDistanceData.warning !== undefined ||
                goalByDistanceData.warning !== undefined ||
                specialPropertiesByDistanceData.warning !== undefined
            }
        >
            {presenter.appraiserStatistics && (
                <div className="widget-blocks">
                    <div className="row">
                        <div className="col-4 d-flex">
                            <WarningWrapper warning={objectTypeByDistanceData.warning}>
                                <DoughnutChart
                                    title="Objecttypes"
                                    total={objectTypeByDistanceData.total}
                                    data={objectTypeByDistanceData.data}
                                    activeIndices={objectTypeByDistanceData.activeIndices}
                                />
                            </WarningWrapper>
                        </div>
                        <div className="col-4 d-flex">
                            <WarningWrapper warning={goalByDistanceData.warning}>
                                <DoughnutChart
                                    title="Taxatiedoel"
                                    total={goalByDistanceData.total}
                                    data={goalByDistanceData.data}
                                    activeIndices={goalByDistanceData.activeIndices}
                                />
                            </WarningWrapper>
                        </div>
                        <div className="col-4 d-flex">
                            <WarningWrapper warning={specialPropertiesByDistanceData.warning}>
                                <DoughnutChart
                                    title="Bijzonderheden"
                                    total={specialPropertiesByDistanceData.total}
                                    data={specialPropertiesByDistanceData.data}
                                    activeIndices={specialPropertiesByDistanceData.activeIndices}
                                />
                            </WarningWrapper>
                        </div>
                        <div className="col-12">
                            <div className="precheck-statistics-tables">
                                <Collapsible title="Objecttypes">
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th style={{borderBottom: 'none'}}></th>
                                                <th style={{borderBottom: 'none'}} colSpan={4}>
                                                    Aantal taxaties
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>Type Object</th>
                                                <th>binnen 10 km</th>
                                                <th>binnen 20 km</th>
                                                <th>Totaal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Rij-woningen</td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'total_row_objects_within_10_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'total_row_objects_within_20_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'total_row_objects_within_5_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {presenter.appraiserStatisticsByKey['total_row_objects']?.total}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Vrijstaande woningen</td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'total_freestanding_objects_within_10_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'total_freestanding_objects_within_20_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'total_freestanding_objects_within_5_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey['total_freestanding_objects']
                                                            ?.total
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Twee-onder-een-kap woningen</td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'total_joined_objects_within_10_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'total_joined_objects_within_20_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'total_joined_objects_within_5_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {presenter.appraiserStatisticsByKey['total_joined_objects']?.total}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Appartementen</td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'total_apartments_within_10_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'total_apartments_within_20_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'total_apartments_within_5_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>{presenter.appraiserStatisticsByKey['total_apartments']?.total}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Collapsible>
                                <Collapsible title="Taxatiedoel">
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th style={{borderBottom: 'none'}}></th>
                                                <th style={{borderBottom: 'none'}} colSpan={4}>
                                                    Aantal taxaties
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>Doel</th>
                                                <th>binnen 10 km</th>
                                                <th>binnen 20 km</th>
                                                <th>Totaal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Financiering</td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'acquire_mortage_within_10_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'acquire_mortage_within_20_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'acquire_mortage_within_5_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>{presenter.appraiserStatisticsByKey['acquire_mortage']?.total}</td>
                                            </tr>
                                            <tr>
                                                <td>Aankoopbeslissing</td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'insight_for_purchase_within_10_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'insight_for_purchase_within_20_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'insight_for_purchase_within_5_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {presenter.appraiserStatisticsByKey['insight_for_purchase']?.total}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Verkoopbeslissing</td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'insight_for_sale_within_10_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'insight_for_sale_within_20_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'insight_for_sale_within_5_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>{presenter.appraiserStatisticsByKey['insight_for_sale']?.total}</td>
                                            </tr>
                                            <tr>
                                                <td>Terugkoopbeslissing (WoCo)</td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'insight_for_repurchase_within_10_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'insight_for_repurchase_within_20_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'insight_for_repurchase_within_5_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey['insight_for_repurchase']
                                                            ?.total
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Bijzonder Beheer</td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey['forced_sale_within_10_km']
                                                            ?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey['forced_sale_within_20_km']
                                                            ?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey['forced_sale_within_5_km']
                                                            ?.total
                                                    }
                                                </td>
                                                <td>{presenter.appraiserStatisticsByKey['forced_sale']?.total}</td>
                                            </tr>
                                            <tr>
                                                <td>Overig</td>
                                                <td>
                                                    {presenter.appraiserStatisticsByKey['other_within_10_km']?.total}
                                                </td>
                                                <td>
                                                    {presenter.appraiserStatisticsByKey['other_within_20_km']?.total}
                                                </td>
                                                <td>
                                                    {presenter.appraiserStatisticsByKey['other_within_5_km']?.total}
                                                </td>
                                                <td>{presenter.appraiserStatisticsByKey['other']?.total}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Collapsible>
                                <Collapsible title="Bijzonderheden">
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th style={{borderBottom: 'none'}}></th>
                                                <th style={{borderBottom: 'none'}} colSpan={4}>
                                                    Aantal taxaties
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>Doel</th>
                                                <th>binnen 10 km</th>
                                                <th>binnen 20 km</th>
                                                <th>Totaal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Nieuwbouw</td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'new_construction_within_10_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'new_construction_within_20_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'new_construction_within_5_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>{presenter.appraiserStatisticsByKey['new_construction']?.total}</td>
                                            </tr>
                                            <tr>
                                                <td>Verbouwing</td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'improvements_planned_within_10_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'improvements_planned_within_20_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey[
                                                            'improvements_planned_within_5_km'
                                                        ]?.total
                                                    }
                                                </td>
                                                <td>
                                                    {presenter.appraiserStatisticsByKey['improvements_planned']?.total}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Verhuurde staat</td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey['rented_state_within_10_km']
                                                            ?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey['rented_state_within_20_km']
                                                            ?.total
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        presenter.appraiserStatisticsByKey['rented_state_within_5_km']
                                                            ?.total
                                                    }
                                                </td>
                                                <td>{presenter.appraiserStatisticsByKey['rented_state']?.total}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Collapsible>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </PrecheckWidget>
    );
});
