import * as React from 'react';

import {Link, useHistory} from 'react-router-dom';

import {Appraisal} from '../../../../models/appraisal';
import {QuestionSet} from '../../../../models/question_set';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {SidebarItem} from '../../../../business/sidebar_tree_builder';
import {SidebarItemPresenter} from './sidebar_item_presenter';
import {ValidationError} from '../../../../models/validation_error';
import {classNames} from '../../../../../support/classnames';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {PagePartsSet} from '../../../../models/page_parts_set';

interface OwnProps {
    pagePartsSet: PagePartsSet | null;
    sidebarItem: SidebarItem;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    renderingContext: RenderingContextType;
}

export const SidebarChildItem: React.FC<OwnProps> = observer((props) => {
    const history = useHistory();
    const presenter = usePresenter(
        (container) =>
            new SidebarItemPresenter(
                props.questionSet,
                props.sidebarItem,
                props.pagePartsSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.locationProvider(history),
                container.business.floorInteractor(props.appraisal, props.questionSet, props.renderingContext),
                container.business.questionValidationMessagesProvider(
                    props.appraisal,
                    props.questionSet,
                    props.validationErrors
                )
            )
    );

    return (
        <li title={props.sidebarItem.label} className={'card-progress-' + props.sidebarItem.sidebarItemSize}>
            <Link
                to={props.sidebarItem.link}
                className={classNames({
                    active: presenter.isActive(props.sidebarItem),
                    visited: presenter.visited,
                    'has-error': presenter.hasError,
                })}
            >
                <span className="title">{props.sidebarItem.label}</span>
            </Link>
        </li>
    );
});
