import * as React from 'react';

import {DateInput} from '../../../inputs/date';
import {FunctionComponent} from 'react';
import {NullableNumberInput} from '../../../inputs/nullable_number';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';

interface OwnProps {
    referenceObjectAnswer: ReferenceObjectAnswer;
    onAanvangsHuurprijsPerMaandChange: (value: number | null) => void;
    onGecorrigeerdeHuurprijsPerMaandChange: (value: number | null) => void;
    onIngangsdatumHuurChange: (value: string) => void;
    isFrozen: boolean;
}

export const RentDetails: FunctionComponent<OwnProps> = ({
    referenceObjectAnswer,
    onAanvangsHuurprijsPerMaandChange,
    onGecorrigeerdeHuurprijsPerMaandChange,
    onIngangsdatumHuurChange,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <>
            <div className="row">
                <span className="col-12">
                    <h2>Huur details</h2>
                </span>
                <div className="col-12">
                    <dl>
                        <span className="row">
                            <span className="col-sm-6">
                                <NullableNumberInput
                                    isRequired
                                    name="aanvangsHuurprijsPerMaand"
                                    label="Huurprijs / maand"
                                    value={validationInstituteReferentieObject.aanvangsHuurprijsPerMaand ?? 0}
                                    prepend="&euro;"
                                    onChange={onAanvangsHuurprijsPerMaandChange}
                                    disabled={isFrozen}
                                />
                            </span>
                            <span className="col-sm-6">
                                <NullableNumberInput
                                    isRequired
                                    name="gecorrigeerdeHuurprijsPerMaand"
                                    label="Gecorr. huurprijs / maand"
                                    value={validationInstituteReferentieObject.gecorrigeerdeHuurprijsPerMaand ?? 0}
                                    prepend="&euro;"
                                    onChange={onGecorrigeerdeHuurprijsPerMaandChange}
                                    disabled={isFrozen}
                                />
                            </span>
                        </span>
                    </dl>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <dl>
                        <span className="row">
                            <span className="col-sm-6">
                                <DateInput
                                    isRequired
                                    name="ingangsdatumHuur"
                                    label="Ingangsdatum huur"
                                    value={validationInstituteReferentieObject.ingangsdatumHuur ?? null}
                                    onChange={onIngangsdatumHuurChange}
                                    disabled={isFrozen}
                                />
                            </span>
                        </span>
                    </dl>
                </div>
            </div>
        </>
    );
};
