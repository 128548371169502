type Listener = () => Promise<boolean> | boolean;

export class AppraisalNavigator {
    private listeners: Listener[] = [];

    public async navigateNext(): Promise<boolean> {
        for (let i = this.listeners.length - 1; i >= 0; i--) {
            if (await this.listeners[i]()) {
                return true;
            }
        }
        return false;
    }

    public addNextListener(callback: Listener) {
        this.listeners.push(callback);
    }

    public removeNextListener(callback: Listener) {
        this.listeners = this.listeners.filter((listener) => listener !== callback);
    }
}
