import {ApiNotification, apiNotificationToNotification} from '../network/models/api_notification';
import {BehaviorSubject, Observable} from 'rxjs';
import {NotificationsApi} from '../network/notifications_api';
import Echo from 'laravel-echo';
import {NotificationMessage} from '../models/notification_message';
import Pusher from 'pusher-js';
import {User} from '../support/component';

export interface NotificationsStackInteractor {
    stream(): Observable<NotificationMessage[]>;
    onHide(id: string): Promise<void>;
}

export class DefaultNotificationsStackInteractor implements NotificationsStackInteractor {
    private stackSubject = new BehaviorSubject<NotificationMessage[]>([]);

    constructor(user: User | null, private notificationApi: NotificationsApi) {
        if (user && process.env.MIX_PUSHER_APP_KEY) {
            const echo = new Echo({
                broadcaster: 'pusher',
                key: process.env.MIX_PUSHER_APP_KEY,
                cluster: process.env.MIX_PUSHER_APP_CLUSTER,
                forceTLS: false,
            });

            new Pusher(process.env.MIX_PUSHER_APP_KEY ?? '', {});

            echo.private(`users.${user.id}`).notification((n: ApiNotification) => {
                const oldState = this.stackSubject.getValue();
                const newState = [...oldState, apiNotificationToNotification(n)];
                this.stackSubject.next(newState);
                window.setTimeout(() => {
                    this.onHide(n.id);
                }, 20000);
            });
        }
    }

    public stream(): Observable<NotificationMessage[]> {
        return this.stackSubject.asObservable();
    }

    public async onHide(id: string) {
        const oldState = this.stackSubject.getValue();
        const newState = [...oldState];
        const indexOf = newState.findIndex((n) => n.id === id);

        const notification = await this.notificationApi.dismiss(id);

        newState[indexOf] = {
            ...newState[indexOf],
            dismissedAt: notification?.dismissedAt ?? new Date(),
        };
        this.stackSubject.next(newState);
    }
}
