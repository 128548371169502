import {ReferenceSale} from './reference_sale';

export enum ModalType {
    NONE = 'none',
    CUSTOM_REFERENCE_SALE = 'custom_reference_sale',
    OBJECT_ANSWER = 'object_answer',
    REFERENCE_OBJECT_DETAILS = 'reference_object_details',
}

export interface BaseModalState {
    type: ModalType;
}

export interface NoneModalState extends BaseModalState {
    type: ModalType.NONE;
}

export interface CustomReferenceSaleModalState extends BaseModalState {
    type: ModalType.CUSTOM_REFERENCE_SALE;
}

export interface ObjectAnswerModalState extends BaseModalState {
    type: ModalType.OBJECT_ANSWER;
    referenceSale: ReferenceSale;
}

export interface ReferenceObjectDetailsModalState extends BaseModalState {
    type: ModalType.REFERENCE_OBJECT_DETAILS;
    referenceSale: ReferenceSale;
}

export type ModalState =
    | NoneModalState
    | CustomReferenceSaleModalState
    | ObjectAnswerModalState
    | ReferenceObjectDetailsModalState;
