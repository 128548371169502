import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from '../../simple/simple_question_presenter';

import {IteratorPhotoGroupPresenter} from './iterator_photo_group_presenter';
import {QuestionContainer} from '../../question_container';
import {findChildrenForQuestion} from '../../../../support/question_filtering';
import {observer} from 'mobx-react';
import {QuestionLoader} from '../../../../components/question_loader';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const IteratorPhotoGroup: React.FC<OwnProps> = observer(function IteratorPhotoGroup(props) {
    const presenter = usePresenter(
        (container) =>
            new IteratorPhotoGroupPresenter(
                container.business.blobCacheInteractor(),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );
    const answer = presenter.answer;
    if (answer === undefined) {
        return <QuestionLoader />;
    }

    return (
        <div className="col-12 col-sm-6 col-md-4 form-grid-item">
            {findChildrenForQuestion(props.question, props.questionSet).map((child) => (
                <QuestionContainer
                    key={child.uuid}
                    appraisal={props.appraisal}
                    question={child}
                    disabled={props.disabled}
                    questionSet={props.questionSet}
                    parentAnswerUuid={answer.uuid}
                    validationErrors={props.validationErrors}
                    validationMessages={props.validationMessages}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    adaptedDefaultValues={props.adaptedDefaultValues}
                    hiddenQuestionTypes={props.hiddenQuestionTypes}
                    pagePartsSet={props.pagePartsSet}
                    activePagePart={props.activePagePart}
                    renderingContext={props.renderingContext}
                    questionRenderingData={props.questionRenderingData}
                />
            ))}
            {!presenter.isDisabled ? (
                <div className="form-grid-item-footer d-flex justify-content-between">
                    <a
                        className="ion-md-filing"
                        onClick={() =>
                            props.disabled === true ? presenter.onRestoreClick() : presenter.onDeleteClick()
                        }
                    >
                        {props.disabled === true ? 'Tonen in rapport' : 'Archiveren'}
                    </a>
                    <a className="ion-md-download" onClick={() => presenter.download()}>
                        Download
                    </a>
                </div>
            ) : null}
        </div>
    );
});
