import {SortingDirection} from '../../../../../../../../../../enum/reference_objects_sorting';
import {Appraisal} from '../../../../../../../../../../models/appraisal';
import {ReferenceObject} from '../../../models/reference_object';
import {ReferenceObjectSortingStrategy} from './reference_object_sorting_strategy';

export class ReferenceObjectSorterByDistance implements ReferenceObjectSortingStrategy {
    constructor(private appraisal: Appraisal) {}

    public sortReferenceObjects(
        referenceObjects: ReferenceObject[],
        sortingDirection: SortingDirection
    ): ReferenceObject[] {
        return referenceObjects.sort((referenceObject1, referenceObject2) => {
            return this.getDistanceToAppraisal(referenceObject1) > this.getDistanceToAppraisal(referenceObject2)
                ? sortingDirection === SortingDirection.DESCENDING
                    ? -1
                    : 1
                : sortingDirection === SortingDirection.DESCENDING
                ? 1
                : -1;
        });
    }

    private deg2rad(degrees: number) {
        return (degrees * Math.PI) / 180;
    }

    private getDistanceToAppraisal(referenceObject: ReferenceObject) {
        // if latitude or longitude is not set, distance can not be calculated
        // this also be checked in the view so a user cannot sort by distance if these properties aren't set
        // by setting all distances to zero the result will be unordered
        if (this.appraisal.latitude === null || this.appraisal.longitude === null) {
            return 0;
        }

        const earthRadius = 6371000;

        // convert from degrees to radians
        const latFrom = this.deg2rad(this.appraisal.latitude);
        const lonFrom = this.deg2rad(this.appraisal.longitude);
        const latTo = this.deg2rad(referenceObject.latitude);
        const lonTo = this.deg2rad(referenceObject.longitude);

        const latDelta = latTo - latFrom;
        const lonDelta = lonTo - lonFrom;

        const angle =
            2 *
            Math.asin(
                Math.sqrt(
                    Math.pow(Math.sin(latDelta / 2), 2) +
                        Math.cos(latFrom) * Math.cos(latTo) * Math.pow(Math.sin(lonDelta / 2), 2)
                )
            );

        return angle * earthRadius;
    }
}
