import * as React from 'react';

import {delinkify, linkifyChunks, linkifyString} from '../support/formatters/url_formatter';

import {observer} from 'mobx-react';
import {renderToStaticMarkup} from 'react-dom/server';

interface OwnProps {
    searchText?: string | null;
    children: string;
}

@observer
export class Highlight extends React.Component<OwnProps> {
    private generateRegExp() {
        if (!this.props.searchText) {
            return null;
        }
        return new RegExp(
            '(?: ?(?:' +
                this.props.searchText
                    .toLowerCase()
                    .split(' ')
                    .map(
                        // Escape special regex characters
                        (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                    )
                    .filter((str) => str)
                    .join('|') +
                ')+ ?)+',
            'gi'
        );
    }

    private generateText() {
        if (!this.props.searchText) {
            return linkifyString(this.props.children);
        }
        const delinkifiedText = delinkify(this.props.children);
        const urls = this.props.children.match(/(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim);
        const regex = this.generateRegExp();

        let chunks: Array<string | JSX.Element> = [];

        let last = 0;
        if (regex) {
            let match = regex.exec(delinkifiedText);
            while (match) {
                let start = match.index;
                let end = regex.lastIndex;

                if (start > last) {
                    chunks.push(delinkifiedText.substring(last, start));
                }

                if (end > start) {
                    if (delinkifiedText[start] === ' ') {
                        start++;
                        if (chunks.length > 0 && typeof chunks[chunks.length - 1] === 'string') {
                            chunks[chunks.length - 1] += ' ';
                        }
                    }

                    if (delinkifiedText[end - 1] === ' ') {
                        end--;
                    }

                    chunks.push(
                        renderToStaticMarkup(
                            <span key={start} className="highlighted-text">
                                {' '}
                                {delinkifiedText.substring(start, end)}{' '}
                            </span>
                        )
                    );
                }

                if (match.index === regex.lastIndex) {
                    regex.lastIndex++;
                }

                last = end;
                match = regex.exec(delinkifiedText);
            }
        }

        if (last < delinkifiedText.length) {
            chunks.push(delinkifiedText.substring(last));
        }
        chunks = linkifyChunks(chunks, urls);
        return chunks;
    }

    public render() {
        const generatedText = this.generateText();
        let htmlObject;
        let html;
        if (typeof generatedText === 'string') {
            htmlObject = {__html: generatedText};
            html = <div dangerouslySetInnerHTML={htmlObject}></div>;
        } else {
            console.error(generatedText);
            htmlObject = {__html: generatedText.join('')};
            html = <div dangerouslySetInnerHTML={htmlObject}></div>;
        }
        return html;
    }
}
