import {AjaxDriver} from '../../network/driver/ajax_driver';
import {ExternalAdvice} from '../models/external_advice';

export interface ApiBuildingInspectorInfo {
    single_costs_cents: number;
    monthly_costs_cents: number;
    terms_url: string;
}

export interface BuildingInspectorInfo {
    singleCostsCents: number;
    monthlyCostsCents: number;
    termsUrl: string | null;
}

export interface BuildingInspectorsApi {
    request(externalAdvice: ExternalAdvice): Promise<ExternalAdvice>;
    store(externalAdvice: ExternalAdvice): Promise<ExternalAdvice>;
    getByQuestionUuid(
        questionUuid: string,
        parentAnswerUuid: string,
        appraisalId: number
    ): Promise<ExternalAdvice | null>;
}

export class DefaultBuildingInspectorsApi implements BuildingInspectorsApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public request(externalAdvice: ExternalAdvice): Promise<ExternalAdvice> {
        return new Promise<ExternalAdvice>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/building-inspectors/request`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify(externalAdvice),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (response) => {
                    resolve(await response.json());
                })
                .catch((error) => reject(error));
        });
    }

    public store(externalAdvice: ExternalAdvice): Promise<ExternalAdvice> {
        return new Promise<ExternalAdvice>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/building-inspectors/store`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify(externalAdvice),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (response) => {
                    resolve(await response.json());
                })
                .catch((error) => reject(error));
        });
    }

    public getByQuestionUuid(
        questionUuid: string,
        parentAnswerUuid: string,
        appraisalId: number
    ): Promise<ExternalAdvice | null> {
        return new Promise<ExternalAdvice | null>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/building-inspectors/${appraisalId}/question/${questionUuid}/answer/${parentAnswerUuid}`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (response) => {
                    if (response.ok) {
                        resolve(await response.json());
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
