import {Appraisal} from '../../../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {ReferenceObjectSorterByAlphabet} from './strategies/reference_object_sorter_by_alphabet';
import {ReferenceObjectSorterByDeviationScore} from './strategies/reference_object_sorter_by_match_percentage';
import {ReferenceObjectSorterByDistance} from './strategies/reference_object_sorter_by_distance';
import {ReferenceObjectSorterBySalesDate} from './strategies/reference_object_sorter_by_sales_date';
import {ReferenceObjectSortingStrategy} from './strategies/reference_object_sorting_strategy';
import {SortingMethod} from '../../../../../../../../../enum/reference_objects_sorting';
import {component} from '../../../../../../../../component';

export class ReferenceObjectSortingStrategyProvider {
    public create(
        sortingMethod: SortingMethod,
        appraisal: Appraisal,
        questionSet: QuestionSet,
        gebruiksoppervlakteWonen: number | null,
        perceelOppervlakte: number | null
    ): ReferenceObjectSortingStrategy {
        switch (sortingMethod) {
            case SortingMethod.ALPHABETICALLY:
                return new ReferenceObjectSorterByAlphabet();
            case SortingMethod.SALES_DATE:
                return new ReferenceObjectSorterBySalesDate();
            case SortingMethod.DISTANCE:
                return new ReferenceObjectSorterByDistance(appraisal);
            case SortingMethod.DEVIATION_SCORE:
                return new ReferenceObjectSorterByDeviationScore(
                    component().business.buildYearProvider(appraisal, questionSet),
                    gebruiksoppervlakteWonen,
                    perceelOppervlakte
                );
        }
    }
}
