import * as React from 'react';
import {createPortal} from 'react-dom';
import {createRoot} from 'react-dom/client';

import {AltumSettings} from '../appraising/network/preselected_reference_objects_api';
import {AltumReferenceObjectSettings} from '../create_appraisal/pre_check/altum_reference_objects';
import {AltumReferenceObjectSettingsGraph} from '../create_appraisal/pre_check/altum_reference_objects/graph';

interface OwnProps {}

interface OwnState {
    value: AltumSettings;
    visible: boolean;
}

class AltumReferenceObjectSettingsContainer extends React.Component<OwnProps, OwnState> {
    public state: OwnState;

    constructor(props: OwnProps) {
        super(props);
        const input = document.getElementById('altum-settings-input');
        this.state = {
            value: JSON.parse((input as HTMLInputElement).value),
            visible: true,
        };
    }

    public componentDidMount() {
        const visibilityCheckbox = document.getElementById('reference_sale_source_altum') as HTMLInputElement;
        if (visibilityCheckbox) {
            visibilityCheckbox.addEventListener('change', this.onToggleVisibility);
            this.setVisible(visibilityCheckbox.checked);
        }
    }

    public componentWillUnmount() {
        const visibilityCheckbox = document.getElementById('reference_sale_source_altum');
        if (visibilityCheckbox) {
            visibilityCheckbox.removeEventListener('change', this.onToggleVisibility);
        }
    }

    private onToggleVisibility = (ev: Event) => {
        this.setVisible((ev.target as HTMLInputElement)?.checked);
    };

    private setVisible(visible: boolean) {
        if (visible) {
            this.setState({visible: true});

            Array.from(document.getElementsByClassName('hide-altum-references')).forEach((el) => {
                (el as HTMLElement).style.display = '';
            });
        } else {
            this.setState({visible: false});

            Array.from(document.getElementsByClassName('hide-altum-references')).forEach((el) => {
                (el as HTMLElement).style.display = 'none';
            });
        }
    }

    private onChange(value: AltumSettings) {
        this.setState({value});

        const input = document.getElementById('altum-settings-input');
        (input as HTMLInputElement).value = JSON.stringify(value);
    }

    public render() {
        if (!this.state.visible) {
            return null;
        }

        const graph = document.getElementById('reference-object-graph');
        return (
            <>
                <AltumReferenceObjectSettings value={this.state.value} onChange={(value) => this.onChange(value)} />
                {graph ? createPortal(<AltumReferenceObjectSettingsGraph value={this.state.value} />, graph) : null}
            </>
        );
    }
}

const elementById = document.getElementById('altum-settings');
const elementInput = document.getElementById('altum-settings-input');
if (elementById && elementInput) {
    createRoot(elementById).render(<AltumReferenceObjectSettingsContainer />);
}
