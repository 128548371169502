/**
 * Toggle button with icon class
 */
(() => {
    function handleClick(btn) {
        const inactiveClass = btn.getAttribute('data-inactive-class');
        const activeClass = btn.getAttribute('data-active-class');

        if (btn.classList.contains(inactiveClass)) {
            btn.classList.replace(inactiveClass, activeClass);
            return true;
        } else {
            btn.classList.replace(activeClass, inactiveClass);
            return false;
        }
    }

    function handleEmptyFields(btn, is_active) {
        const fieldNames = btn.getAttribute('data-inactive-empty-fields').split(',');

        fieldNames.forEach((fieldName) => {
            document.getElementsByName(fieldName)[0].value = null;
            if (fieldName !== 'secondary_client_first_name') {
                document.getElementsByName(fieldName)[0].required = is_active;
            }
        });
    }

    document.querySelectorAll('.btn[data-active-class]').forEach((btn) => {
        btn.addEventListener('click', (event) => {
            let is_active = handleClick(btn);
            handleEmptyFields(btn, is_active);
        });
    });
})();
