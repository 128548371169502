import {
    ApiExtraReferenceSaleSet,
    ApiReferenceSale,
    apiExtraReferenceSalesToReferenceSales,
    apiReferenceSalesToReferenceSales,
} from './api_reference_sale';

import {ReferenceSalesData} from '../../business/reference_object_provider';

export interface ApiReferenceSalesData {
    energy: {
        label: string | null;
        is_preliminary: boolean;
    };
    areas: {
        bruto_inhoud: number;
        perceel_oppervlakte: number;
        gebruiksoppervlakte_wonen: number;
        gebouwgebonden_buitenruimte?: number;
        overige_inpandige_ruimte?: number;
        externe_bergruimte?: number;
    };
    reference_objects_required: boolean;
    refresh_credentials: boolean;
    build_year: number | null;
    reference_objects: ApiReferenceSale[];
    extra_reference_objects_sets: ApiExtraReferenceSaleSet[];
}

export function apiReferenceSalesDataToReferenceSalesData(data: ApiReferenceSalesData) {
    const apiReferenceSales: ApiReferenceSale[] = data.reference_objects;
    const referenceSaleData: ReferenceSalesData = {
        energyConclusion: {
            label: data.energy.label,
            isPreliminary: data.energy.is_preliminary,
        },
        areaConclusion: {
            brutoInhoud: data.areas.bruto_inhoud ?? 0,
            perceelOppervlakte: data.areas.perceel_oppervlakte ?? 0,
            gebruiksoppervlakteWonen: data.areas.gebruiksoppervlakte_wonen ?? 0,
            gebouwgebondenBuitenruimte: data.areas.gebouwgebonden_buitenruimte ?? 0,
            overigeInpandigeRuimte: data.areas.overige_inpandige_ruimte ?? 0,
            externeBergruimte: data.areas.externe_bergruimte ?? 0,
        },
        defaultReferenceSalesRequired: data.reference_objects_required,
        refreshCredentials: data.refresh_credentials,
        buildYear: data.build_year,
        referenceSales: apiReferenceSalesToReferenceSales(apiReferenceSales),
        extraReferenceSalesSets: apiExtraReferenceSalesToReferenceSales(data.extra_reference_objects_sets),
    };
    return referenceSaleData;
}
