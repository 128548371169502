import {QuestionSet} from '../models/question_set';
import {TechnicalReference} from '../enum/technical_reference';
import {AnswerController} from './answering/answer_controller';
import {isEmpty} from '../../support/util';
import {Appraisal} from '../models/appraisal';
import {getNewestAnswer} from '../../support/get_newest_answer';
import moment from 'moment';

export interface ValuationDateProvider {
    valuation: Date | null;
}

export class DefaultValuationDateProvider implements ValuationDateProvider {
    constructor(
        private questionSet: QuestionSet,
        private appraisal: Appraisal,
        private answerController: AnswerController
    ) {}

    public get valuation(): Date | null {
        const valuationDateQuestion = this.questionSet.findQuestionByTechnicalReference(TechnicalReference.PRICE_DATE);
        if (valuationDateQuestion !== null) {
            const answer = getNewestAnswer(this.answerController.answersForQuestionUuid(valuationDateQuestion.uuid));
            if (answer !== null && answer.contents && !isEmpty(answer.contents)) {
                return new Date(answer.contents);
            }
        }

        if (!this.appraisal.valuationDate) {
            return null;
        }

        return moment(this.appraisal.valuationDate, 'D-M-YYYY H:m').toDate();
    }
}
