import * as React from 'react';

import {Modal} from '../../content/questions/components/modal';
import {Select} from '../../components/select';
import {SortingDirection, SortingMethod} from '../../../../enum/reference_objects_sorting';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {ReferencesPreferencesModalPresenter} from './references_preferences_modal_presenter';
import {observer} from 'mobx-react';
import {Loader} from '../../components/loader';

interface OwnProps {
    onClose: () => void;
}

export const ReferencesPreferencesModal: React.FC<OwnProps> = observer(function ReferencesPreferencesModal({
    onClose,
}: OwnProps) {
    const presenter = usePresenter(
        (container) =>
            new ReferencesPreferencesModalPresenter(container.network.userSettingsApi, container.globalProvider)
    );

    return (
        <Modal visible forced>
            <div className="modal-header">
                <h2>Voorkeuren referenties</h2>
            </div>
            <div className="modal-body">
                <strong>Sortering</strong>
                <p>
                    Kies de manier waarop de referenties standaard gesorteerd moeten worden binnen
                    referentievergelijker.
                </p>
                <div className="form-group">
                    <label htmlFor="sort-method">Sorteermethode</label>
                    <Select
                        id="sort-method"
                        onChange={(value) => {
                            if (value) {
                                presenter.setSortingMethod(value as SortingMethod);
                            }
                        }}
                        options={Object.values(SortingMethod).map((value) => ({
                            label: value,
                            value: value,
                        }))}
                        value={presenter.sortingMethod}
                        isDisabled={false}
                        isClearable={false}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="sort-direction">Sorteervolgorde</label>
                    <Select
                        id="sort-direction"
                        onChange={(value) => {
                            if (value) {
                                presenter.setSortingDirection(value as SortingDirection);
                            }
                        }}
                        options={Object.values(SortingDirection).map((value) => ({
                            label: value === SortingDirection.ASCENDING ? 'Oplopend' : 'Aflopend',
                            value: value,
                        }))}
                        value={presenter.sortingDirection}
                        isDisabled={false}
                        isClearable={false}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <button
                    className="btn btn-primary btn-full ion-md-close"
                    type="button"
                    onClick={() => presenter.save().then(() => onClose())}
                >
                    {presenter.isSaving ? <Loader /> : 'Opslaan'}
                </button>
            </div>
        </Modal>
    );
});
