import * as React from 'react';

import {macroIsActive, toggleMacro} from '../../../business/toggle_macro';

import {Macros} from '../../../appraise/ui/content/questions/advanced/macros/macros';
import {Appraisal} from '../../../models/appraisal';
import {Macro, MacroExternalType} from '../../../models/macro';
import {QuestionSet} from '../../../models/question_set';
import {observer} from 'mobx-react';

interface OwnProps {
    isRequired?: boolean;
    name: string;
    value: string;
    appraisal: Appraisal;
    questionSet: QuestionSet;

    macros?: Macro[];
    macroActions: {
        onAdd: () => void;
        onRemove: (macro: Macro) => void;
        onFavorite: (macro: Macro) => void;
    };

    onChange: (e: string) => void;
    onGenerateClick: () => void;
}

@observer
export class Textarea extends React.Component<OwnProps> {
    textareaRef = React.createRef<HTMLTextAreaElement>();

    componentDidMount() {
        this.updateTextareaHeight();
    }

    componentDidUpdate() {
        this.updateTextareaHeight();
    }

    updateTextareaHeight() {
        if (this.textareaRef.current) {
            this.textareaRef.current.style.height = '5px';
            this.textareaRef.current.style.height = this.textareaRef.current.scrollHeight + 40 + 'px';
        }
    }

    public render() {
        const macros = this.props.macros || [];
        return (
            <div className={macros.length !== 0 ? 'form-group has-macros' : 'form-group'}>
                {this.props.macros && (
                    <Macros
                        externalType={MacroExternalType.MODEL_VALUES_EXPLANATION}
                        parentAnswerUuid={null}
                        hasAdvancedOptions={false}
                        questionSet={this.props.questionSet}
                        appraisal={this.props.appraisal}
                        isActive={(contents) => macroIsActive(contents, this.props.value)}
                        onUseMacro={(contents) => this.props.onChange(toggleMacro(contents, this.props.value))}
                        onAdd={this.props.macroActions.onAdd}
                        onRemove={this.props.macroActions.onRemove}
                        onFavorite={this.props.macroActions.onFavorite}
                        showAddButton={
                            this.props.value !== '' && !this.props.macros.some((m) => m.contents === this.props.value)
                        }
                    />
                )}
                <button
                    className="btn btn-textarea-inset ion-md-refresh"
                    onClick={() => {
                        if (
                            (this.props.value ?? '').trim() === '' ||
                            confirm('Weet je dit zeker? De huidige tekst zal overschreven worden.')
                        ) {
                            this.props.onGenerateClick();
                        }
                    }}
                >
                    Genereer
                </button>
                <textarea
                    id={this.props.name}
                    name={this.props.name}
                    className="form-control"
                    rows={3}
                    value={this.props.value}
                    ref={this.textareaRef}
                    onChange={(e) => this.props.onChange(e.target.value)}
                />
            </div>
        );
    }
}
