import {ApiReferenceSalesData, apiReferenceSalesDataToReferenceSalesData} from './models/api_reference_sales_data';
import {ApiSale, apiSaleToSale} from './models/api_sale';
import {ApiTaskReference, isApiTaskReference} from './models/api_task_reference';

import {AjaxDriver} from '../../network/driver/ajax_driver';
import {ReferenceSalesData} from '../business/reference_object_provider';
import {Sale} from '../models/sale';
import {TaskReference} from '../models/task_reference';

export interface ReferenceObjectRentApi {
    getReferenceSales(appraisalId: number): Promise<ReferenceSalesData | TaskReference>;

    getSale(appraisalId: number, id: string): Promise<Sale>;

    getSaleByAddress(
        appraisalId: number,
        postalCode: string,
        houseNumber: string,
        letter: string
    ): Promise<Sale | TaskReference>;
}

export class DefaultReferenceObjectRentApi implements ReferenceObjectRentApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public getReferenceSales(appraisalId: number): Promise<ReferenceSalesData | TaskReference> {
        return new Promise<ReferenceSalesData | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/reference-sales-rent`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiReferenceSalesData | ApiTaskReference = await result.json();
                        if (!data) {
                            reject();
                        } else if (isApiTaskReference(data)) {
                            resolve({
                                taskId: data.task_id,
                            });
                        } else {
                            if (!data.reference_objects) {
                                reject();
                            } else {
                                const referenceSaleData = apiReferenceSalesDataToReferenceSalesData(data);
                                resolve(referenceSaleData);
                            }
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getSale(appraisalId: number, id: string): Promise<Sale> {
        return new Promise<Sale>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/sales-rent/${id}`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiSale = await result.json();
                        if (!data) {
                            reject();
                        } else {
                            resolve(apiSaleToSale(data));
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getSaleByAddress(
        appraisalId: number,
        postalCode: string,
        houseNumber: string,
        letter: string
    ): Promise<Sale | TaskReference> {
        const params = {
            postal_code: postalCode,
            house_number: houseNumber,
            letter: letter,
        };
        const query: string = Object.keys(params)
            .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k as keyof typeof params]))
            .join('&');

        return new Promise<Sale | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/sales-rent/by-address?${query}`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiSale | ApiTaskReference = await result.json();
                        if (!data) {
                            reject();
                        } else {
                            if (isApiTaskReference(data)) {
                                resolve({
                                    taskId: data.task_id,
                                });
                            } else {
                                resolve(apiSaleToSale(data));
                            }
                        }
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
