import * as React from 'react';

import {isEmpty} from '../../../../../../../../../../support/util';
import {DateFormat, parseDateToFormat} from '../../../../../../../support/format_date';
import {observer} from 'mobx-react';
import {makeObservable, observable, runInAction} from 'mobx';
import {format} from 'date-fns';

interface OwnProps {
    name: string;
    label: string;
    value: string | null;
    isRequired?: boolean;
    disabled?: boolean;
    onChange: (e: string) => void;
}

@observer
export class DateInput extends React.Component<OwnProps> {
    @observable private errorMessage: string | null = null;
    @observable private value: string | null = null;

    constructor(props: OwnProps) {
        super(props);
        makeObservable(this);
        runInAction(() => {
            if (this.props.value !== null && !isEmpty(this.props.value)) {
                const dateToString = format(new Date(this.props.value), DateFormat.TAXAPI) ?? '';
                this.value = parseDateToFormat(dateToString, DateFormat.TAXAPI, DateFormat.PICKER) ?? '';
            }
        });
    }

    private handleDateChange(value: string | undefined) {
        runInAction(() => {
            this.value = value ?? null;
        });

        const formattedDate = parseDateToFormat(value ?? '', DateFormat.PICKER, DateFormat.TAXAPI);

        if (isEmpty(value)) {
            this.props.onChange('');
        } else if (formattedDate !== undefined) {
            runInAction(() => {
                this.errorMessage = null;
            });
            this.props.onChange(formattedDate);
        } else if (value?.length >= 10) {
            runInAction(() => {
                this.errorMessage = 'De datum dient ingevuld te worden als "dd-mm-yyyy".';
            });
        }
    }

    private renderDate() {
        return (
            <div className="input-group">
                <div className="input-group-prepend">
                    <div className="input-group-text ion-md-calendar">&nbsp;</div>
                </div>
                <input
                    type="text"
                    className="form-control"
                    value={this.value ?? ''}
                    onChange={(e) => this.handleDateChange(e.target.value)}
                />
            </div>
        );
    }

    private renderDisabled() {
        return <div className="input-group">{this.props.value !== null ? this.props.value : '-'}</div>;
    }

    public render() {
        return (
            <div className="form-group">
                <label htmlFor={this.props.name}>
                    {this.props.label}
                    {this.props.isRequired ? <span className="form-label-highlight">*</span> : null}
                </label>
                {this.props.disabled ? this.renderDisabled() : this.renderDate()}
                {this.errorMessage !== null && <span className="invalid-feedback">{this.errorMessage}</span>}
            </div>
        );
    }
}
