import {Answer} from '../../../../../../models/answer';

export interface CompactHouseGroupConfiguration {
    numberOfFloors: number;
}

export function compactHouseGroupConfigurationFromAnswer(answer: Answer) {
    if (answer.contents === null) {
        return {
            numberOfFloors: 0,
        };
    }

    const value = JSON.parse(answer.contents);
    return {
        numberOfFloors: value.numberOfFloors,
    };
}

export function getDefaultCompactHouseGroupConfiguration(): CompactHouseGroupConfiguration {
    return {
        numberOfFloors: 0,
    };
}
