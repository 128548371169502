import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {ConstructionDefectsWidgetContext} from './construction_defects_widget_context';
import {ConstructionalDefects} from './constructional_defects';
import {Modal} from '../../content/questions/components/modal';
import {PagePart} from '../../../../models/page_part';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {Question} from '../../../../models/question';
import {QuestionSet} from '../../../../models/question_set';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {ValidationError} from '../../../../models/validation_error';
import {ValidationInstituteConstructionalDefect} from '../../../../models/validation_institute_constructional_defect';
import {ValidationMessageMap} from '../../../../business/validation/validation_message';
import {classNames} from '../../../../../support/classnames';
import {QuestionRenderingData} from '../../../../models/question_rendering_data';

export interface ConstructionalDefectsProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    onClose: () => void;
    question: Question;
    parentAnswerUuid: string;
    validationInstituteConstructionalDefect?: ValidationInstituteConstructionalDefect;
    constructionDefectLabel?: string;
    validationErrors?: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    constructionDefectPhotoAnswerUuid?: string;
    constructionCostsWidgetContext: ConstructionDefectsWidgetContext;
    onChangeConstructionCostsWidgetContext: (c: ConstructionDefectsWidgetContext) => void;
    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export class ConstructionalDefectsModal extends React.Component<ConstructionalDefectsProps> {
    public render() {
        const isRequestContext =
            this.props.constructionCostsWidgetContext === ConstructionDefectsWidgetContext.NEW_REQUEST;

        return (
            <Modal visible forced>
                <div className="modal-header">
                    <span
                        className={classNames(
                            'constructional-defects-title-icon',
                            {'constructional-defects-title-icon-defect': !isRequestContext},
                            {'constructional-defects-title-icon-advice': isRequestContext}
                        )}
                    >
                        &nbsp;
                    </span>
                    <h2>
                        {this.props.constructionCostsWidgetContext === ConstructionDefectsWidgetContext.NEW_REQUEST
                            ? 'Second opinion aanvragen'
                            : 'Bouwkundige opnamestaat'}
                    </h2>
                </div>
                <div className="modal-body">
                    <ConstructionalDefects {...this.props} />
                </div>
                <div className="modal-footer">
                    <button
                        className="btn btn-primary btn-full ion-md-arrow-forward"
                        type="button"
                        onClick={() => this.props.onClose()}
                    >
                        Opslaan
                    </button>
                </div>
            </Modal>
        );
    }
}
