import {Question} from '../../models/question';
import {AnswerOption} from '../../models/answer_option';
import {getNewestAnswer} from '../../../support/get_newest_answer';
import {Answer} from '../../models/answer';
import {AnswerController} from '../answering/answer_controller';
import {TechnicalReference} from '../../enum/technical_reference';

export function getUniqueBijgebouwen(
    questions: Question[],
    bijgebouwenAnswerOptions: AnswerOption[],
    dakkapellenArray: string[],
    answerController: AnswerController,
    floorAnswer: Answer | null = null
): string[] {
    const newestAnswers: Answer[] = [];

    if (floorAnswer) {
        const childrenAnswersForAnswer = answerController.childrenAnswersForAnswerRecursively(floorAnswer);
        for (const question of questions) {
            const answersForQuestion = childrenAnswersForAnswer.filter(
                (a) => a.questionUuid === question.uuid && a.answerOptionId !== null
            );
            const newestAnswer = getNewestAnswer(answersForQuestion);
            if (newestAnswer) {
                newestAnswers.push(newestAnswer);
            }
        }
    } else {
        for (const question of questions) {
            const newestAnswer = getNewestAnswer(answerController.answersForQuestionUuid(question.uuid));
            if (newestAnswer) {
                newestAnswers.push(newestAnswer);
            }
        }
    }

    const answersWithoutDuplicates = [...new Set(newestAnswers)];

    return answersWithoutDuplicates
        .filter((a) => {
            const question = questions.find((q) => q?.uuid === a?.questionUuid);
            if (question && question.technicalReference === TechnicalReference.OBJECT_OMSCHRIJVING_BIJGEBOUW_EXCLUDED) {
                return false;
            }
            return a && validBijgebouwAnswer(a, bijgebouwenAnswerOptions, dakkapellenArray);
        })
        .map((a) => {
            const question = questions.find((q) => q?.uuid === a?.questionUuid);
            const reportValue = question?.reportValue;
            const answerOption = bijgebouwenAnswerOptions.find((ao) => ao.id === a?.answerOptionId);
            if (answerOption) return answerOption.contents;
            if (reportValue) return reportValue;
            if (question?.contents) return question.contents;
            return '';
        });
}

export function getNewestValidAnswer(
    questions: Question[],
    answerController: AnswerController,
    validContentCheck: (answer: Answer | null) => boolean
): (string | undefined)[] {
    return questions
        .map((q) => getNewestAnswer(answerController.answersForQuestionUuid(q.uuid)))
        .filter((a) => validContentCheck(a))
        .map((a) => questions.find((q) => q?.uuid === a?.questionUuid)?.contents);
}

export function validBijgebouwAnswer(a: Answer, bijgebouwenAnswerOptions: AnswerOption[], dakkapellenArray: string[]) {
    if (a.contents === '1') return true;
    if (a.answerOptionId !== null) {
        const answerOption = bijgebouwenAnswerOptions.find((ao) => ao.id === a.answerOptionId);
        return answerOption && dakkapellenArray.includes(answerOption.contents);
    }
    return false;
}

export function getDakkapellenCount(bijgebouwenOnFloors: string[], dakkapellenArray: string[]) {
    let dakkapellenCount = 0;
    for (const bijgebouw of bijgebouwenOnFloors) {
        if (dakkapellenArray.includes(bijgebouw)) {
            dakkapellenCount += Number.parseInt(bijgebouw.split(' ')[0], 10);
            bijgebouwenOnFloors = bijgebouwenOnFloors.filter((b) => b !== bijgebouw);
        }
    }

    return {dakkapellenCount, bijgebouwenOnFloors};
}

export function excludeDakkapellen(bijgebouwenOnFloors: string[], dakkapellenArray: string[]) {
    return bijgebouwenOnFloors.filter((bijgebouw) => !dakkapellenArray.includes(bijgebouw));
}
