import {
    ConstructionCostDuration,
    ValidationInstituteConstructionalDefect,
} from '../../../../models/validation_institute_constructional_defect';
import {makeObservable, observable, runInAction} from 'mobx';

import {Answer} from '../../../../models/answer';
import {AnswerController} from '../../../../business/answering/answer_controller';
import {AnswerInteractor} from '../../../../business/answering/answer_interactor';
import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {ConstructionCostsLine} from '../../../../models/construction_costs_summary';
import {ConstructionCostsSummaryApi} from '../../../../network/construction_costs_api';
import {NormalQuestionType} from '../../../../enum/question_type';
import {Presenter} from '../../../../../support/presenter/presenter';
import {Question} from '../../../../models/question';
import {QuestionEffectInteractor} from '../../../../business/conditions/question_effects_interactor';
import {QuestionSet} from '../../../../models/question_set';
import {buildQuestionAnswerTree} from '../../../../../support/question_answer_tree';
import {findAllChildrenForQuestionUuid} from '../../support/question_filtering';
import {findChildRecursiveByPredicate} from '../../../../../support/generic_tree';
import {Global, GlobalProvider} from '../../../../../business/global_provider';

export class ConstructionalDefectsPresenter implements Presenter {
    @observable.ref public initialValidationInstituteConstructionalDefect?: ValidationInstituteConstructionalDefect;
    @observable public isLoading = false;
    @observable public showConstructionCosts = true;
    @observable.ref public constructionCosts: ConstructionCostsLine[] | null = null;
    @observable.ref public buildingCostsGroupQuestion: Question;
    @observable.ref public buildingCostsRowAnswer: Answer | undefined;
    @observable.ref public buildingCostsPhotoAnswer: Answer | undefined;
    @observable public buildingInspectorFormActive = false;

    private subscriptions = new CompositeSubscription();

    public global: Global;

    constructor(
        private question: Question,
        private questionSet: QuestionSet,
        private constructionCostsSummaryApi: ConstructionCostsSummaryApi,
        private answerController: AnswerController,
        private answerInteractor: AnswerInteractor,
        private questionEffectsInteractor: QuestionEffectInteractor,
        private parentAnswerUuid: string,
        globalProvider: GlobalProvider,
        validationInstituteConstructionalDefect?: ValidationInstituteConstructionalDefect,
        private constructionDefectLabel?: string,
        private constructionDefectPhotoAnswerUuid?: string
    ) {
        makeObservable(this);
        this.initialValidationInstituteConstructionalDefect = validationInstituteConstructionalDefect;

        if (
            [NormalQuestionType.BUILDING_DEFECTS_COSTS, NormalQuestionType.BUILDING_DEFECTS].includes(
                question.type as NormalQuestionType
            ) === false
        ) {
            throw new Error(`No valid question in construction defects widget.`);
        }
        const buildingCostsGroup = questionSet.findParentByPredicateRecursive(
            question,
            (q) => q.type === NormalQuestionType.BUILDING_COSTS_GROUP
        );
        if (buildingCostsGroup === null) {
            throw new Error(
                `No valid '${NormalQuestionType.BUILDING_COSTS_GROUP}' parent for building costs question.`
            );
        }
        this.buildingCostsGroupQuestion = buildingCostsGroup;

        this.global = globalProvider.global;
    }

    public async mount() {
        runInAction(() => {
            this.isLoading = true;
            const hasCosts = this.question.type === NormalQuestionType.BUILDING_DEFECTS_COSTS;
            this.showConstructionCosts = hasCosts;
            if (hasCosts) {
                this.initializeHasVveToebedeling();
            }
        });
        await this.initializeValidationInstituteConstructionalDefect();
        await this.initializePhotoAnswer();
        runInAction(() => {
            this.initializeCostLines();
            this.isLoading = false;
        });
    }

    public unmount() {
        this.subscriptions.clear();
    }

    private initializeHasVveToebedeling() {
        const selectVveQuestionPath = this.questionSet.findChildrenPathByPredicateRecursive(
            this.question,
            (q) =>
                q.type === NormalQuestionType.MC_SELECT &&
                q.answerOptions.some((ao) => ao.contents.toLowerCase() === 'vve')
        );

        if (selectVveQuestionPath) {
            this.subscriptions.add(
                this.answerController
                    .answersForQuestionUuidsStream([this.question.uuid, ...selectVveQuestionPath.map((q) => q.uuid)])
                    .subscribe((answers) => {
                        const rootAnswer = answers.find(
                            (a) => a.questionUuid === this.question.uuid && a.parentUuid === this.parentAnswerUuid
                        );
                        if (rootAnswer) {
                            const tree = buildQuestionAnswerTree(this.questionSet, answers, rootAnswer);
                            const vvePair = findChildRecursiveByPredicate(
                                tree,
                                (item) =>
                                    item.question.type === NormalQuestionType.MC_SELECT &&
                                    item.question.answerOptions.some((ao) => ao.contents.toLowerCase() === 'vve')
                            );
                            if (vvePair) {
                                const vveAnswerOption = vvePair.item.question.answerOptions.find(
                                    (ao) => ao.contents.toLowerCase() === 'vve'
                                );
                                if (vvePair.item.answer && vveAnswerOption) {
                                    const isHidden = this.questionEffectsInteractor.isHidden(
                                        vvePair.item.question.uuid,
                                        vvePair.item.answer.parentUuid
                                    );

                                    if (!isHidden) {
                                        runInAction(() => {
                                            this.showConstructionCosts = !(
                                                vvePair.item.answer?.answerOptionId === vveAnswerOption.id
                                            );
                                        });
                                    }
                                }
                            }
                        }
                    })
            );
        }
    }

    private async initializePhotoAnswer() {
        const questionGroupAnswer = this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIteration(
            this.question.uuid,
            this.parentAnswerUuid
        );

        const children = findAllChildrenForQuestionUuid(this.question.uuid, this.questionSet);
        const buildingCostsRowQuestion = children.find((q) => q.type === NormalQuestionType.BUILDING_COSTS_ROW) ?? null;

        // 1. Find the building costs row answer
        if (
            this.constructionDefectPhotoAnswerUuid &&
            questionGroupAnswer &&
            questionGroupAnswer.length === 1 &&
            buildingCostsRowQuestion
        ) {
            this.subscriptions.add(
                this.answerController
                    .answerByIdentifiersStream(buildingCostsRowQuestion.uuid, questionGroupAnswer[0].uuid, null)
                    .subscribe((answer) => {
                        runInAction(() => {
                            this.buildingCostsRowAnswer = answer;
                        });
                    })
            );

            // 2. Find the building costs photo answer
            if (this.buildingCostsRowAnswer) {
                const buildingCostsPhotoQuestion =
                    children.find((q) => q.type === NormalQuestionType.BUILDING_COSTS_PHOTO) ?? null;
                if (buildingCostsPhotoQuestion) {
                    this.subscriptions.add(
                        this.answerController
                            .answerByIdentifiersStream(
                                buildingCostsPhotoQuestion.uuid,
                                this.buildingCostsRowAnswer.uuid,
                                null
                            )
                            .subscribe((answer) => {
                                runInAction(() => {
                                    this.buildingCostsPhotoAnswer = answer;
                                });
                            })
                    );

                    // 3. Submit the photo answer with the temporary photo answer
                    const temporaryPhotoAnswer = this.answerController.byUuid(this.constructionDefectPhotoAnswerUuid);
                    if (
                        this.buildingCostsPhotoAnswer &&
                        temporaryPhotoAnswer &&
                        temporaryPhotoAnswer.contents &&
                        temporaryPhotoAnswer.file
                    ) {
                        this.answerController.attachExistingFile(
                            this.buildingCostsPhotoAnswer.uuid,
                            temporaryPhotoAnswer.contents,
                            temporaryPhotoAnswer.file
                        );
                        await this.answerInteractor.submit();
                    }
                }
            }
        }
    }

    private async initializeValidationInstituteConstructionalDefect() {
        if (
            this.initialValidationInstituteConstructionalDefect === null ||
            this.initialValidationInstituteConstructionalDefect === undefined
        ) {
            runInAction(() => {
                this.initialValidationInstituteConstructionalDefect =
                    this.createValidationInstituteConstructionalDefect();
            });
        }

        if (
            this.question.type === NormalQuestionType.BUILDING_DEFECTS_COSTS &&
            this.buildingCostsGroupQuestion.buildingCostsReference
        ) {
            const constructionCosts = await this.constructionCostsSummaryApi.get(
                this.buildingCostsGroupQuestion.buildingCostsReference
            );
            runInAction(() => {
                this.constructionCosts = constructionCosts;
            });
        } else {
            runInAction(() => {
                this.constructionCosts = [];
                this.isLoading = false;
            });
        }
    }

    private initializeCostLines() {
        if (
            this.initialValidationInstituteConstructionalDefect === null ||
            this.initialValidationInstituteConstructionalDefect === undefined
        ) {
            this.initialValidationInstituteConstructionalDefect = this.createValidationInstituteConstructionalDefect();
        }

        if (this.constructionCosts && this.constructionDefectLabel) {
            const lines = [];
            const constructionDefectLabels = this.constructionDefectLabel.split(',');
            for (const constructionDefectLabel of constructionDefectLabels) {
                const constructionDefectLabelFormatted = constructionDefectLabel.toLowerCase().trim();
                for (const constructionCostsLine of this.constructionCosts) {
                    if (constructionCostsLine.labels?.toLowerCase().trim().includes(constructionDefectLabelFormatted)) {
                        lines.push(constructionCostsLine);
                    }
                }
            }

            let costs = 0;
            for (const line of lines) {
                const definedLine = {...line, amount: 1, duration: ConstructionCostDuration.INDIRECT};
                costs += line.costsCents;
                this.initialValidationInstituteConstructionalDefect.lines.push(definedLine);
            }
            this.initialValidationInstituteConstructionalDefect.directCosts = costs;
        }
    }

    private createValidationInstituteConstructionalDefect(): ValidationInstituteConstructionalDefect {
        return {
            reference: '',
            lines: [],
            costs5Years: 0,
            directCosts: 0,
            allocation: null,
        };
    }
}
