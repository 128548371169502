import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {Loader} from '../../components/loader';
import {QuestionContainer} from '../../content/questions/question_container';
import {QuestionSet} from '../../../../models/question_set';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {ReportReplacementsPresenter} from './report_replacements_presenter';
import {ReportSelectorType} from '../../../../enum/report_selector_type';
import {ValidationError} from '../../../../models/validation_error';
import {findChildrenForQuestion} from '../../support/question_filtering';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {QuestionRenderingData} from '../../../../models/question_rendering_data';

export interface ReportReplacementsProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    onClose: () => void;
    onReferenceFocus: (reference: ReportSelectorType) => void;
    validationErrors?: ValidationError[];
    lastFocusedReference: ReportSelectorType | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const ReportReplacements: React.FunctionComponent<ReportReplacementsProps> = observer((props) => {
    const presenter = usePresenter(
        (container) =>
            new ReportReplacementsPresenter(
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet)
            )
    );

    const {lastFocusedReference, questionSet} = props;
    const lastFocusedReferenceRef = React.useRef(props.lastFocusedReference);
    React.useEffect(() => {
        if (lastFocusedReference !== null && lastFocusedReferenceRef.current !== lastFocusedReference) {
            const questions = questionSet.findQuestionsByReportSelector(lastFocusedReference);
            if (questions[0]) {
                const inputElement = document.getElementById(questions[0].uuid);
                if (inputElement) {
                    inputElement.focus();
                }
            }
        }
    }, [lastFocusedReference, questionSet]);

    const answer = presenter.answer;

    return (
        <div className="report-replacements">
            {presenter.question && answer ? (
                findChildrenForQuestion(presenter.question, props.questionSet).map((childQuestion) => (
                    <div
                        key={childQuestion.uuid}
                        onFocus={() => {
                            if (childQuestion.reportSelector) {
                                props.onReferenceFocus(childQuestion.reportSelector);
                            }
                        }}
                    >
                        <QuestionContainer
                            appraisal={props.appraisal}
                            question={childQuestion}
                            questionSet={props.questionSet}
                            parentAnswerUuid={answer.uuid}
                            validationErrors={[]}
                            validationMessages={{}}
                            forceShowValidationMessages={false}
                            hiddenQuestionTypes={[]}
                            pagePartsSet={null}
                            activePagePart={null}
                            renderingContext={props.renderingContext}
                            questionRenderingData={props.questionRenderingData}
                        />
                    </div>
                ))
            ) : (
                <div className="row">
                    <div className="col-12 text-center">
                        <Loader />
                        <h3>Vragen aan het laden...</h3>
                    </div>
                </div>
            )}
        </div>
    );
});
