import * as React from 'react';

import {EnergeticSheet, EnergeticSheetProps} from './energetic_sheet';

export interface EnergeticSheetWidgetProps extends EnergeticSheetProps {
    onClose: () => void;
    onOpenExplanation: () => void;
}

export function EnergeticSheetWidget({onClose, onOpenExplanation, ...restProps}: EnergeticSheetWidgetProps) {
    return (
        <div className="widget-overlay">
            <div className="widget-overlay-container">
                <div className="widget-overlay-title">
                    <span className="widget-overlay-title-icon ion-md-calculator">&nbsp;</span>
                    <div className="d-flex">
                        <h2 className="w-auto">Energetisch rapport</h2>
                        <a className="ml-2 ion-md-help-circle-outline" onClick={onOpenExplanation}>
                            Meer uitleg
                        </a>
                    </div>
                    <button className="widget-overlay-close" onClick={() => onClose()}>
                        <span className="ion-md-close">&nbsp;</span>
                    </button>
                </div>
                <EnergeticSheet {...restProps} />
            </div>
        </div>
    );
}
