import {PagePartCustomization} from '../../models/page_part_customization';

export interface ApiPagePartCustomization {
    page_part_configuration_id: number;
    question_uuid: string;
    data: unknown;
}

export function apiPagePartCustomizationToPagePartCustomization(
    apiPagePart: ApiPagePartCustomization
): PagePartCustomization {
    return {
        pagePartConfigurationId: apiPagePart.page_part_configuration_id,
        questionUuid: apiPagePart.question_uuid,
        data: apiPagePart.data,
    };
}

export function pagePartCustomizationToApiPagePartCustomization(
    pagePart: PagePartCustomization
): ApiPagePartCustomization {
    return {
        page_part_configuration_id: pagePart.pagePartConfigurationId,
        question_uuid: pagePart.questionUuid,
        data: pagePart.data,
    };
}
