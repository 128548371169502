import * as React from 'react';
import {createRoot} from 'react-dom/client';
import {Root} from './root';

export function renderAppraisalEnvironmentInto(element: HTMLElement) {
    const appraisalId = element.getAttribute('data-appraisal-id');
    if (appraisalId === null) {
        throw new Error('Trying to render appraisal environment without appraisal ID');
    }
    const questionSetId = element.getAttribute('data-question-set-id');
    if (questionSetId === null) {
        throw new Error('Trying to render appraisal environment without question set ID');
    }
    createRoot(element).render(
        <Root
            appraisalId={appraisalId ? parseInt(appraisalId, 10) : null}
            questionSetId={questionSetId ? parseInt(questionSetId, 10) : null}
        />
    );
}
