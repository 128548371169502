import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {combineLatest, of} from 'rxjs';
import {map} from 'rxjs/operators';

import {CompositeSubscription} from '../../../../../../../../../../support/composite_subscription';
import {findChildRecursiveByPredicate, TreeItem} from '../../../../../../../../../../support/generic_tree';
import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {AnswerController} from '../../../../../../../../../business/answering/answer_controller';
import {ChildQuestionValidationProvider} from '../../../../../../../../../business/validation/child_validation_provider';
import {ValidationMessage} from '../../../../../../../../../business/validation/validation_message';
import {TechnicalReference} from '../../../../../../../../../enum/technical_reference';
import {Question} from '../../../../../../../../../models/question';
import {QuestionAnswerPair} from '../../../../../../../../../models/question_answer_pair';
import {ReferenceObjectData} from '../../internal/create_reference_object_data';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {SetDefinition} from '../../reference_objects_question_presenter';

export interface ReferenceObjectAnswerData {
    referenceObjectAnswer: ReferenceObjectAnswer;
    treeItem: TreeItem<QuestionAnswerPair>;
    question: Question;
    iteratorAnswerUuid: string;
    parentAnswerUuid: string;
}

export class ReferenceObjectComparisonModalPresenter implements Presenter {
    @observable public validationMessages: {
        [parentAnswerUuid: string]: ValidationMessage[];
    } = {};
    @observable public forceShowValidationMessages = false;

    private subscriptions = new CompositeSubscription();

    constructor(
        private referenceObjects: ReferenceObjectData[],
        private answerController: AnswerController,
        private childValidationProvider: ChildQuestionValidationProvider,
        private activeSetDefinition: SetDefinition,
        private referenceSets: SetDefinition[]
    ) {
        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            combineLatest(
                this.referenceObjectAnswers.map(({question, parentAnswerUuid}) => {
                    const answer = this.answerController.answerByIdentifiers(question.uuid, parentAnswerUuid, null);

                    if (answer === null) {
                        return of(null);
                    }

                    return this.childValidationProvider
                        .validate(question.uuid, answer.uuid)
                        .pipe(map((messages) => ({parentAnswerUuid, messages})));
                })
            ).subscribe((validationMessages) => {
                const messages = validationMessages
                    .filter(
                        (
                            val
                        ): val is {
                            parentAnswerUuid: string;
                            messages: ValidationMessage[];
                        } => val !== null && val.messages.length > 0
                    )
                    .reduce(
                        (p, c) => ({...p, [c.parentAnswerUuid]: c.messages}),
                        {} as Record<string, ValidationMessage[]>
                    );

                runInAction(() => {
                    this.validationMessages = messages;
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @computed
    public get referenceObjectAnswers() {
        return this.referenceObjects
            .map(({treeItem, referenceObjectAnswer}) => {
                const groupItem = findChildRecursiveByPredicate(
                    treeItem,
                    (item) =>
                        item.question.technicalReference === TechnicalReference.REFERENCE_OBJECTS_V3_ITERATOR_GROUP
                );

                const iteratorAnswerUuid = groupItem?.parent?.item.answer?.uuid;
                const parentAnswerUuid = groupItem?.item.answer?.parentUuid;
                const question = groupItem?.item.question;

                if (!iteratorAnswerUuid || !parentAnswerUuid || !question) {
                    return null;
                }

                return {
                    referenceObjectAnswer,
                    treeItem,
                    question,
                    iteratorAnswerUuid,
                    parentAnswerUuid,
                };
            })
            .filter((r): r is ReferenceObjectAnswerData => r !== null);
    }

    @computed
    public get nextSet() {
        const index = this.referenceSets.findIndex(
            (set) => this.activeSetDefinition.valuationType === set.valuationType
        );

        if (index + 1 >= this.referenceSets.length) {
            return null;
        }

        const set = this.referenceSets[index + 1];

        if (set.amountSelected === 0) {
            return null;
        }

        return {
            set,
            index: index + 1,
        };
    }

    @computed
    public get previousSet() {
        const index = this.referenceSets.findIndex(
            (set) => this.activeSetDefinition.valuationType === set.valuationType
        );

        if (index - 1 < 0) {
            return null;
        }

        const set = this.referenceSets[index - 1];

        if (set.amountSelected === 0) {
            return null;
        }

        return {
            set,
            index: index - 1,
        };
    }

    @action
    public onForceShowValidationMessages() {
        this.forceShowValidationMessages = true;
    }
}
