import {Presenter} from '../../../../../../../support/presenter/presenter';
import {action, computed, observable, makeObservable, runInAction} from 'mobx';
import {CadastralPlotAddress} from '../../../../../../models/cadastral_plot_address';
import {Question} from '../../../../../../models/question';
import {QuestionSet} from '../../../../../../models/question_set';
import {findAllChildrenForQuestionUuid} from '../../../../support/question_filtering';
import {TechnicalReference} from '../../../../../../enum/technical_reference';
import {CompositeSubscription} from '../../../../../../../support/composite_subscription';
import {AnswerController} from '../../../../../../business/answering/answer_controller';
import {Answer} from '../../../../../../models/answer';
import {AdaptedDefaultValuesMap} from '../../../../../../models/adapted_default_values_map';
import {FileReference} from '../../../../../../models/file_reference';
import {Appraisal} from '../../../../../../models/appraisal';
import {AppraisalAutomationApi} from '../../../../../../network/appraisal_automation_api';
import {first, map} from 'rxjs/operators';
import {NetworkStatus, NetworkStatusProvider} from '../../../../../../business/network_status_provider';
import {isEmpty} from '../../../../../../../support/util';
import {GlobalProvider} from '../../../../../../../business/global_provider';

export class AddCadastralPlotModalPresenter implements Presenter {
    @observable public formVisible = false;
    @observable.ref public adaptedDefaultValuesMap: AdaptedDefaultValuesMap = {};
    @observable.ref public iteratorAnswer?: Answer;
    @observable public loading = false;
    @observable public error: string | null = null;
    @observable public runAutomations = false;
    @observable public kadasterCostsCents: number;

    private subscriptions = new CompositeSubscription();
    private deedOfDeliveryFileReference: FileReference | null = null;

    constructor(
        private appraisal: Appraisal,
        private question: Question,
        private questionSet: QuestionSet,
        private parentAnswerUuid: string | null,
        private iteration: string,
        private onAdd: () => void,
        private onHide: () => void,
        private answerController: AnswerController,
        private appraisalAutomationApi: AppraisalAutomationApi,
        private networkStatusProvider: NetworkStatusProvider,
        private globalProvider: GlobalProvider
    ) {
        this.kadasterCostsCents = globalProvider.global.rateAdditionalKadasterExcerpt ?? -1;

        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidStream(this.question.uuid)
                .pipe(
                    map((answers) => this.answerController.filterDeleted(answers)),
                    map((answers) => answers.filter((answer) => answer.iteration === this.iteration))
                )
                .subscribe((answers) => (this.iteratorAnswer = answers[0]))
        );

        this.formVisible = this.appraisal.cadastralPlotAddresses.length === 0;
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    public onHideModal() {
        if (this.iteratorAnswer !== undefined) {
            this.answerController.delete(this.iteratorAnswer.uuid);
        }
        this.onHide();
    }

    public onClickOption(cadastralPlotAddress: CadastralPlotAddress) {
        this.loading = true;
        this.formVisible = false;

        const adaptedValuesMap: AdaptedDefaultValuesMap = {};
        findAllChildrenForQuestionUuid(this.question.uuid, this.questionSet).map((question: Question) => {
            this.setAdaptedValues(adaptedValuesMap, cadastralPlotAddress, question);
        });

        this.adaptedDefaultValuesMap = adaptedValuesMap;
        this.loading = false;
        this.formVisible = true;
    }

    @action
    public onClickManual() {
        this.loading = false;
        this.formVisible = true;
    }

    @action
    public onCheckRunAutomations(value: boolean) {
        this.runAutomations = value;
    }

    @action
    public async onCheckAdd(): Promise<void> {
        this.loading = true;
        this.error = null;
        if (this.iteratorAnswer === undefined) {
            this.closeModal();
        } else {
            if (!this.runAutomations) {
                this.closeModal();
            } else {
                const status = await this.networkStatusProvider.status().pipe(first()).toPromise();
                if (status === NetworkStatus.ONLINE) {
                    await this.runCadastralPlotAutomation();
                } else {
                    confirm(
                        'Je bent offline, weet je zeker dat je verder wil? Het uittreksel kan dan niet automatisch bijgevoegd worden.'
                    );
                }
            }
        }
    }

    private async runCadastralPlotAutomation() {
        if (this.iteratorAnswer === undefined) {
            return;
        }

        const answers = this.answerController.filterDeleted(
            this.answerController.answersByParentAnswerUuidRecusively(this.iteratorAnswer.uuid)
        );
        const groupAnswer = this.findAnswerForTechnicalReference(answers, TechnicalReference.CADASTRAL_GROUP);
        if (groupAnswer === null) {
            this.closeModal();
            return;
        }

        const municipalAnswer = this.findAnswerForTechnicalReference(answers, TechnicalReference.CADASTRAL_MUNICIPAL);
        const sectionAnswer = this.findAnswerForTechnicalReference(answers, TechnicalReference.CADASTRAL_SECTION);
        const indexAnswer = this.findAnswerForTechnicalReference(answers, TechnicalReference.CADASTRAL_INDEX);

        // To support entering a plot when the object type is an apartment
        let numberAnswer = this.findAnswerForTechnicalReference(answers, TechnicalReference.CADASTRAL_NUMBER);
        if (numberAnswer === null) {
            numberAnswer = this.findAnswerForTechnicalReference(answers, TechnicalReference.CADASTRAL_COMPLEX);
        }

        if (!this.validate(municipalAnswer, sectionAnswer, numberAnswer)) {
            runInAction(() => {
                this.loading = false;
                this.error = 'Sommige velden zijn niet ingevuld. Probeer a.u.b. opnieuw.';
            });
        } else {
            const newAnswers = await this.appraisalAutomationApi.runCadastralAdditionalExcerptAutomation(
                this.appraisal.id,
                municipalAnswer !== null ? municipalAnswer.contents : '',
                sectionAnswer !== null ? sectionAnswer.contents : '',
                numberAnswer !== null ? numberAnswer.contents : '',
                indexAnswer !== null ? indexAnswer.contents : ''
            );
            if (newAnswers.length > 0) {
                this.answerController.pushMany(newAnswers);
                this.closeModal();
            } else {
                runInAction(() => {
                    this.loading = false;
                    this.error = 'Er ging wat fout met het opslaan van de gegevens. Probeer a.u.b. opnieuw.';
                });
            }
        }
    }

    @action
    private closeModal(): void {
        this.loading = false;
        this.runAutomations = false;
        this.error = null;
        this.onAdd();
    }

    private validate(
        municipalAnswer: Answer | null,
        sectionAnswer: Answer | null,
        numberAnswer: Answer | null
    ): boolean {
        // These fields are required, the index field is optional (when it is an apartment)
        return (
            municipalAnswer !== null &&
            !isEmpty(municipalAnswer.contents) &&
            sectionAnswer !== null &&
            !isEmpty(sectionAnswer.contents) &&
            numberAnswer !== null &&
            !isEmpty(numberAnswer.contents)
        );
    }

    private findAnswerForTechnicalReference(answers: Answer[], reference: TechnicalReference): Answer | null {
        return (
            answers.find((answer: Answer) => {
                const question = this.questionSet.findQuestionByUuid(answer.questionUuid);
                return question !== undefined && question.technicalReference === reference;
            }) ?? null
        );
    }

    @computed
    public get deedOfDeliveryFile(): FileReference | null {
        if (this.deedOfDeliveryFileReference !== null) {
            return this.deedOfDeliveryFileReference;
        }
        const excerptQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.ATTACHMENT_AKTE_VAN_LEVERING
        );
        if (excerptQuestion !== null) {
            const answer = this.answerController.answersForQuestionUuid(excerptQuestion.uuid)[0];
            if (answer === undefined || answer === null || answer.file === null) {
                return null;
            }
            this.deedOfDeliveryFileReference = answer.file;
            return this.deedOfDeliveryFileReference;
        }

        return null;
    }

    private setAdaptedValues(
        adaptedDefaultValuesMap: AdaptedDefaultValuesMap,
        cadastralPlotAddress: CadastralPlotAddress,
        question: Question
    ) {
        switch (question.technicalReference) {
            case TechnicalReference.CADASTRAL_MUNICIPAL:
                adaptedDefaultValuesMap[question.uuid] = cadastralPlotAddress.municipal || '';
                break;
            case TechnicalReference.CADASTRAL_NUMBER:
            case TechnicalReference.CADASTRAL_COMPLEX:
                adaptedDefaultValuesMap[question.uuid] = cadastralPlotAddress.cadastralPlotNumber || '';
                break;
            case TechnicalReference.CADASTRAL_SECTION:
                adaptedDefaultValuesMap[question.uuid] = cadastralPlotAddress.cadastralSection || '';
                break;
            case TechnicalReference.CADASTRAL_INDEX:
                adaptedDefaultValuesMap[question.uuid] = cadastralPlotAddress.apartmentRightsIndex || '';
                break;
            default:
                return adaptedDefaultValuesMap;
        }
    }
}
