import $ from 'jquery';

/**
 * Trigger the client details on select for object_ownership_type
 */
(() => {
    const clientDetails = $('#form-create-appraisal-rights-client-address');

    if (clientDetails.length > 0) {
        const select = $('select[name="object_ownership_type"]');
        const unknownAddress = $('input[name="is_unknown_address"]');

        toggleClientDetails(select.val());
        toggleRequired(clientDetails);

        select.on('change', function (input) {
            toggleClientDetails($(input.target).val());
            toggleRequired(clientDetails);
        });

        function toggleClientDetails(val) {
            clientDetails.show();
            const isUnkownAddress = unknownAddress.length > 0 ? unknownAddress.val() === '1' : false;
            if (!isUnkownAddress && val === 'Huidige eigenaar en bewoner') {
                clientDetails.hide();
            }
        }

        function toggleRequired(container) {
            if (container.length > 0) {
                const isRequired = container[0].clientHeight > 0;
                ['client_postal_code', 'client_number'].forEach((input) => {
                    const fields = document.getElementsByName(input);
                    if (fields.length > 0) {
                        fields[0].required = isRequired;
                    }
                });
            }
        }
    }
})();
