import {createFile} from '../../../../../../../../../../../support/create_file';

export async function fetchReferenceObjectPhoto(currentImageUrl: string): Promise<File | null> {
    //See `editable_image.tsx` why we fetch the image this way
    const fetchedFile = await fetch(currentImageUrl, {
        headers: currentImageUrl.includes('.cloudfront.net/')
            ? {}
            : {
                  pragma: 'no-cache',
                  'cache-control': 'no-cache',
              },
    });
    if (fetchedFile.ok && fetchedFile.status >= 200 && fetchedFile.status < 300) {
        //Create our own File since IE cant use new File
        const fileBlob = (await fetchedFile.blob()) as File;
        return createFile(fileBlob, {
            fileName: fileBlob.name ?? currentImageUrl.split('/').pop()?.split('#').shift()?.split('?').shift() ?? '',
            type: fileBlob.type ?? fetchedFile.headers.get('Content-Type') ?? 'image/jpeg',
            lastModified: fileBlob.lastModified ?? Date.now(),
        });
    }

    return null;
}
