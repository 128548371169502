import * as React from 'react';

import {Appraisal} from '../../../models/appraisal';
import {AppraisalValidationType} from '../../../enum/appraisal_validation_type';
import {PagePartsSet} from '../../../models/page_parts_set';
import {ParagraphReferencesList} from './validation_institute_validation_errors_page/paragraph_references_list';
import {QuestionSet} from '../../../models/question_set';
import {ValidationError} from '../../../models/validation_error';
import {ValidationErrorsList} from './validation_errors_list';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet | null;
    validationErrors: ValidationError[];
}

export class ValidationInstituteValidationErrorsPage extends React.Component<OwnProps> {
    public render() {
        return (
            <span>
                <div className="card-header">Validatiefouten</div>
                <div className="card-body">
                    <div className="group-container">
                        <div className="group-container-content">
                            {this.props.validationErrors.length > 0 ? (
                                <>
                                    <p>
                                        {this.props.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED
                                            ? 'Onderstaande meldingen dienen bekeken te worden voordat het rapport gegenereerd kan worden.'
                                            : 'Onderstaande meldingen dienen bekeken te worden. Eventuele nieuwe meldingen komen hier te staan na het insturen ter validatie.'}
                                    </p>
                                    <ValidationErrorsList
                                        validationErrors={this.props.validationErrors}
                                        questionSet={this.props.questionSet}
                                    />
                                </>
                            ) : (
                                <>
                                    <p>
                                        Het validatie-instituut heeft fouten geconstateerd, deze zijn naar het bij het
                                        NWWI bekende e-mail adres verzonden maar kun je ook vinden onder
                                        &quot;correspondentie&quot; in Taxatieweb.
                                    </p>
                                    <ParagraphReferencesList
                                        appraisal={this.props.appraisal}
                                        questionSet={this.props.questionSet}
                                        pagePartsSet={this.props.pagePartsSet}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </span>
        );
    }
}
