import {Observable, combineLatest} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {getNewestAnswer} from '../../../support/get_newest_answer';
import {NormalQuestionType, IteratorQuestionType} from '../../enum/question_type';
import {Question} from '../../models/question';
import {ValidationMessage} from './validation_message';
import {QuestionSet} from '../../models/question_set';
import {AnswerController} from '../answering/answer_controller';
import {ChildQuestionValidator} from './child_validator';
import {ChildQuestionValidationProvider} from './child_validation_provider';
import {QuestionEffectInteractor} from '../conditions/question_effects_interactor';

export class QuestionValidationMessagesProvider {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private childValidator: ChildQuestionValidator,
        private childValidationProvider: ChildQuestionValidationProvider,
        private questionEffectsInteractor: QuestionEffectInteractor
    ) {}

    public getStream(
        question: Question,
        iteration: string | null,
        debounceTimeMs: number
    ): Observable<ValidationMessage[]> {
        const refObjectsV3 = this.questionSet.findQuestionsByType(NormalQuestionType.REFERENCE_OBJECTS_V3)[0];
        const refsObjectsV3Parent =
            refObjectsV3 && refObjectsV3.parentUuid
                ? this.questionSet.findQuestionByUuid(refObjectsV3.parentUuid) ?? null
                : null;

        if (question.uuid !== refsObjectsV3Parent?.uuid) {
            return this.getNormalValidationObservable(question, iteration, debounceTimeMs);
        }
        return this.getRefsV3ValidationObservable(question, iteration, debounceTimeMs);
    }

    private getNormalValidationObservable(question: Question, iteration: string | null, debounceTimeMs: number) {
        return this.answerController.answersForQuestionUuidAndIteration(question.uuid, iteration).pipe(
            debounceTime(debounceTimeMs),
            map((answers) => getNewestAnswer(answers)?.uuid ?? null),
            distinctUntilChanged(),
            switchMap((answerUuid) => this.childValidationProvider.validate(question.uuid, answerUuid))
        );
    }

    private getRefsV3ValidationObservable(question: Question, iteration: string | null, debounceTimeMs: number) {
        const blacklistedQuestionsUuids = this.questionSet
            .findQuestionsByType(IteratorQuestionType.ITERATOR_REFERENCE_OBJECTS_V3)
            .map((q) => q.uuid);
        const blackListedAndChildUuids = [
            ...blacklistedQuestionsUuids,
            ...blacklistedQuestionsUuids.flatMap((uuid) =>
                this.questionSet.flattenChildrenRecursively(uuid).map((child) => child.uuid)
            ),
        ];
        return combineLatest([
            this.answerController.answersForQuestionUuidsStream(blackListedAndChildUuids).pipe(
                debounceTime(debounceTimeMs),
                map((answers) => this.answerController.filterDeleted(answers)),
                map((answers) =>
                    answers.filter(
                        (answer) => !this.questionEffectsInteractor.isHidden(answer.questionUuid, answer.parentUuid)
                    )
                ),
                map((answers) => {
                    return answers.flatMap((answer) => {
                        return this.childValidator.validate(answer.questionUuid, answer.uuid);
                    });
                })
            ),
            this.getNormalValidationObservable(question, iteration, debounceTimeMs),
        ]).pipe(map(([messages1, messages2]) => [...messages1, ...messages2]));
    }
}
