import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';

import {Label} from '../components/label';
import {Select} from '../../../components/select';
import {ValidationMessages} from '../components/validation_messages';
import {classNames} from '../../../../../../support/classnames';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {isEmpty} from '../../../../../../support/util';
import {observer} from 'mobx-react';
import {CompactEditable} from '../../../components/compact_editable';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const MultipleChoiceSelectQuestion: React.FC<OwnProps> = observer(function MultipleChoiceSelectQuestion(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));

    if (presenter.isHidden) {
        return null;
    }
    const answer = presenter.answer;
    const value = answer && answer.answerOptionId !== null ? '' + answer.answerOptionId : null;

    const options = presenter.answerOptions.map((answerOption) => {
        const option = {
            value: String(answerOption.id),
            label: answerOption.contents,
            isDisabled: false,
        };
        if (answerOption.isRequired) {
            option.label += '*';
        }
        if (answerOption.isUnique) {
            option.label += ' (max. 1)';
        }
        return option;
    });
    const hasDefaultValue = presenter.answerOptions.find((o) => o.isDefault) !== undefined;
    const hasNotApplicableOption =
        presenter.answerOptions.find((o) => o.contents.includes('niet van toepassing')) !== undefined;

    // Only add this empty value when there is no default value and no "not applicable" option.
    if (isEmpty(value) && !hasDefaultValue && !hasNotApplicableOption) {
        options.unshift({
            value: '-1',
            label: '- maak een keuze -',
            isDisabled: true,
        });
    }

    function handleChange(value: string | null) {
        const parsedValue = value ? parseInt(value, 10) : null;
        if (parsedValue !== -1 && parsedValue !== null) {
            presenter.onAnswerOptionChange(parsedValue);
        } else {
            const notApplicableOption = presenter.answerOptions.find((o) => o.contents.includes('niet van toepassing'));
            if (notApplicableOption) {
                // Try to set the "not applicable" option when available
                presenter.onAnswerOptionChange(notApplicableOption.id);
            } else {
                // Try to set the default value when available.
                const defaultOption = presenter.answerOptions.find((o) => o.isDefault);
                presenter.onAnswerOptionChange(defaultOption?.id ?? null);
            }
        }
    }

    function renderCompact() {
        const answer = presenter.answer;
        const value =
            answer && answer.answerOptionId !== null
                ? presenter.answerOptions.find((ao) => ao.id === answer.answerOptionId)?.contents
                : null;

        return (
            <>
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    disableHistory
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <div>{value ?? <i>Niet ingevuld</i>}</div>
            </>
        );
    }

    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'automation-filled': presenter.filledByAutomator !== null,
            })}
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
        >
            <CompactEditable
                renderingContext={props.renderingContext}
                compact={() => renderCompact()}
                question={props.question}
                answer={presenter.answer}
            >
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <Select
                    id={presenter.name}
                    className={classNames({
                        'pulse-multiple-choice-select': isEmpty(value),
                        'required-not-selected': isEmpty(value),
                    })}
                    value={value || '-1'}
                    isDisabled={presenter.isDisabled || props.disabled === true}
                    onChange={(newValue) => handleChange(newValue)}
                    options={options}
                    isClearable={!props.question.isRequired && hasDefaultValue}
                />
            </CompactEditable>

            <ValidationMessages
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={answer}
                validationMessages={filterValidationMessages(
                    props.question,
                    answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
        </div>
    );
});
