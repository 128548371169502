import {computed, makeObservable, observable, runInAction} from 'mobx';
import {Macro, MacroExternalType} from '../../appraising/models/macro';
import {MacroInteractor} from '../interactors/macro_interactor';
import {Presenter} from '../support/presenter';
import {MacroSettings} from '../../appraising/models/macro_settings';

export class ObjectOwnershipTypePresenter implements Presenter {
    @observable.ref macrosData: {
        macros: Macro[];
        settings: MacroSettings[];
    } | null = null;

    @computed public get macros() {
        return this.macroInteractor.mergeMacrosWithSettings(
            this.macrosData?.macros ?? [],
            this.macrosData?.settings ?? []
        );
    }

    constructor(private macroInteractor: MacroInteractor, private macroExternalType: MacroExternalType) {
        makeObservable(this);
    }

    public async mount() {
        const macros = await this.macroInteractor.getExternalMacros(this.macroExternalType);
        runInAction(() => {
            this.macrosData = macros;
        });
    }

    public unmount(): void {
        // Noop
    }

    public async storeMacro(contents: string) {
        const result = await this.macroInteractor.storeExternalMacro(contents, MacroExternalType.OBJECT_OWNERSHIP);
        runInAction(() => {
            this.macrosData = {
                macros: [...(this.macrosData?.macros ?? []), result],
                settings: this.macrosData?.settings ?? [],
            };
        });
    }

    public onFavoriteMacroClick = async (toBeFavoritedMacro: Macro) => {
        try {
            const macro = this.macrosData?.macros.find((m) => m.id === toBeFavoritedMacro.id);
            if (macro !== undefined) {
                const newSettings = await this.macroInteractor.toggleFavorite(macro.id);

                runInAction(() => {
                    this.macrosData = {
                        macros: this.macrosData?.macros ?? [],
                        settings: [
                            ...(this.macrosData?.settings.filter((ms) => ms.macroId !== macro.id) ?? []),
                            newSettings,
                        ],
                    };
                });
            }
        } catch (e) {
            /* Noop */
            console.warn(e);
        }
    };

    public onRemoveMacro = async (toBeRemovedMacro: Macro) => {
        if (this.macros !== undefined) {
            try {
                const macro = this.macros.find((m) => m.id === toBeRemovedMacro.id);
                if (macro !== undefined) {
                    if (!macro.isUserDefined) {
                        const newSettings = await this.macroInteractor.hideForUser(macro.id);
                        runInAction(() => {
                            this.macrosData = {
                                macros: this.macrosData?.macros ?? [],
                                settings: [
                                    ...(this.macrosData?.settings.filter((ms) => ms.macroId !== macro.id) ?? []),
                                    newSettings,
                                ],
                            };
                        });
                    } else {
                        await this.macroInteractor.destroy(macro.id);
                        runInAction(() => {
                            this.macrosData = {
                                macros: this.macrosData?.macros.filter((m) => m.id !== macro.id) ?? [],
                                settings: this.macrosData?.settings ?? [],
                            };
                        });
                    }
                }
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };
}
