import * as React from 'react';

import {
    simpleQuestionPresenterConstructorParametersFactory,
    SimpleQuestionPresenterProps,
} from './simple_question_presenter';

import {AdaptedDefaultValuesMap} from '../../../../../models/adapted_default_values_map';
import {DummyFilesGroup} from './dummy_files_group';
import {QuestionContainer} from '../question_container';
import {QuestionSet} from '../../../../../models/question_set';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {observer} from 'mobx-react';
import {TechnicalReference} from '../../../../../enum/technical_reference';
import {FilesGroupPresenter} from './files_group_presenter';
import {QuestionLoader} from '../../../components/question_loader';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {shouldShowTitle} from '../../../../../../support/should_show_title';
import {SimpleTooltip} from '../../../../../components/tooltip';
import {hasSuperMacros, SuperMacros} from '../extensions/super_macros/super_macros';
import {classNames} from '../../../../../../support/classnames';

interface OwnProps extends SimpleQuestionPresenterProps {
    questionSet: QuestionSet;
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
}

export const FilesGroup: React.FC<OwnProps> = observer(function FilesGroup(props) {
    if (props.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
        return <DummyFilesGroup />;
    }
    const presenter = usePresenter(
        (container) =>
            new FilesGroupPresenter(
                props.pagePartsSet,
                container.business.attachmentQuestionsInteractor(props.appraisal, props.questionSet),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );

    if (presenter.isHidden) {
        return null;
    }

    const answer = presenter.answer;
    if (answer === undefined) {
        return <QuestionLoader />;
    }
    function isNotRenderedAtTopLevel() {
        return props.parentAnswerUuid !== undefined;
    }

    function renderTitle() {
        const show =
            isNotRenderedAtTopLevel() &&
            shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel);

        if (superMacros) {
            return <SuperMacros {...props} title={show ? <h2>{props.question.contents}</h2> : undefined} />;
        }

        if (show) {
            return (
                <div>
                    <h2>{props.question.contents}</h2>
                </div>
            );
        }

        return null;
    }

    function renderTerms() {
        if (props.question.technicalReference !== TechnicalReference.BUILDING_INSPECTION_APPOINTMENT_ATTACHMENTS) {
            return (
                <div className="list-item-attachment list-item-attachment-multiple-avatar uploaded">
                    {props.appraisal.termsOfEngagement.url !== null ? (
                        <a
                            className="list-item-attachment-download"
                            href={props.appraisal.termsOfEngagement.url ?? ''}
                            onClick={(e) => presenter.onOpenTerms(e)}
                            target="_blank"
                            rel="noreferrer"
                        />
                    ) : null}
                    <SimpleTooltip content="Deze bijlage is toegevoegd door taXapi AI" placement="top">
                        <span className="list-avatar list-user-avatar c-icon-system" data-type="info">
                            &nbsp;
                        </span>
                    </SimpleTooltip>
                    <span className="list-avatar ion-md-cloud-download list-avatar-second list-avatar-active">
                        &nbsp;
                    </span>
                    <span className="list-title list-title-download">Opdrachtvoorwaarden</span>
                    <small className="list-details list-details-download">
                        {props.appraisal.termsOfEngagement.fileName ?? 'opdrachtvoorwaarden.pdf'}
                    </small>
                </div>
            );
        } else {
            return null;
        }
    }

    function renderPrecheckReport() {
        if (props.appraisal.precheckReport === null) {
            return null;
        }

        return (
            <div className="list-item-attachment list-item-attachment-multiple-avatar uploaded">
                <a
                    className="list-item-attachment-download"
                    href={props.appraisal.precheckReport.url ?? ''}
                    target="_blank"
                    rel="noreferrer"
                />
                <SimpleTooltip content="Deze bijlage is toegevoegd door taXapi AI" placement="top">
                    <span className="list-avatar list-user-avatar c-icon-system" data-type="info">
                        &nbsp;
                    </span>
                </SimpleTooltip>
                <span className="list-avatar ion-md-cloud-download list-avatar-second list-avatar-active">&nbsp;</span>
                <span className="list-title list-title-download">Pre-check rapport</span>
                <small className="list-details list-details-download">
                    {props.appraisal.precheckReport.originalFilename ?? 'pre-check rapport.pdf'}
                </small>
            </div>
        );
    }

    const superMacros = hasSuperMacros(props.question);

    return (
        <div
            className={classNames('group-container-files', {
                'super-macro-group': superMacros,
            })}
        >
            {renderTitle()}
            <div className="list-attachments">
                {props.appraisal.termsOfEngagement.url !== null ? renderTerms() : null}
                {renderPrecheckReport()}
                {findChildrenForQuestion(props.question, props.questionSet)
                    .sort((a, b) => presenter.sortChildren(a, b))
                    .map((child) => (
                        <QuestionContainer
                            key={child.uuid}
                            appraisal={props.appraisal}
                            question={child}
                            questionSet={props.questionSet}
                            validationErrors={props.validationErrors}
                            validationMessages={props.validationMessages}
                            forceShowValidationMessages={props.forceShowValidationMessages}
                            parentAnswerUuid={answer.uuid}
                            adaptedDefaultValues={props.adaptedDefaultValues}
                            hiddenQuestionTypes={props.hiddenQuestionTypes}
                            pagePartsSet={props.pagePartsSet}
                            activePagePart={props.activePagePart}
                            renderingContext={props.renderingContext}
                            questionRenderingData={props.questionRenderingData}
                        />
                    ))}
            </div>
        </div>
    );
});
