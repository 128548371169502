import {debounceTime, map} from 'rxjs/operators';

import {AnswerController} from './answering/answer_controller';
import {Observable} from 'rxjs';
import {Question} from '../models/question';
import {QuestionSet} from '../models/question_set';
import {TechnicalReference} from '../enum/technical_reference';
import {getNewestAnswer} from '../../support/get_newest_answer';
import {isEmpty} from '../../support/util';
import {AppraiseModel, isAppraiseModel} from '../enum/appraise_model';
import {Appraisal} from '../models/appraisal';

export class BuildYearProvider {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private appraisal: Appraisal
    ) {}

    public get(): number | null {
        const question = this.getQuestion(TechnicalReference.OBJECT_BUILD_YEAR);
        if (question) {
            return this.getForQuestion(question);
        }
        return null;
    }

    public getDestructionYear(): number | null {
        const question = this.getQuestion(TechnicalReference.OBJECT_BUILD_YEAR_DESTRUCTION);
        if (question) {
            return this.getForQuestion(question);
        }
        return null;
    }

    public stream(debounce = 100): Observable<number | null> {
        const question = this.getQuestion(TechnicalReference.OBJECT_BUILD_YEAR);
        if (question) {
            return this.getStreamForQuestion(question, debounce);
        }
        return new Observable();
    }

    public streamDestructionYear(debounce = 100): Observable<number | null> {
        const question = this.getQuestion(TechnicalReference.OBJECT_BUILD_YEAR_DESTRUCTION);
        if (question) {
            return this.getStreamForQuestion(question, debounce);
        }
        return new Observable();
    }

    private getForQuestion(question: Question) {
        const answer = this.answerController.answersForQuestionUuid(question.uuid)[0];
        if (answer !== undefined && !isEmpty(answer.contents)) {
            return parseInt(answer.contents, 10);
        }

        return null;
    }

    private getStreamForQuestion(question: Question, debounce: number) {
        return this.answerController.answersForQuestionUuidStream(question.uuid).pipe(
            debounceTime(debounce),
            map((answers) => {
                const answer = getNewestAnswer(answers);
                if (answer !== null && answer?.contents !== null && !isEmpty(answer.contents)) {
                    return parseInt(answer.contents, 10);
                }
                return null;
            })
        );
    }

    private getQuestion(technicalReference: TechnicalReference) {
        if (isAppraiseModel(this.appraisal, AppraiseModel.MODEL2024PLAUSIBILITY)) {
            // This set has no build year
            return null;
        }

        const question = this.questionSet.findQuestionByTechnicalReference(technicalReference);
        if (question === null) {
            console.error('Question ' + technicalReference + ' could not be found');
        }
        return question;
    }
}
