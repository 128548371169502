import {Answer} from '../../appraising/models/answer';
import {MemoryStorage, ObservableMemoryStorage} from 'indexed-memory-storage';

export const primaryAnswerIndex = (a: Answer) => a.uuid;
export const parentUuidAnswerIndex = [(a: Answer) => a.parentUuid];
const questionUuidGetter = (a: Answer) => a.questionUuid;
export const questionUuidIndex = [questionUuidGetter];

export function createObservableAnswerMemoryStorage(): ObservableMemoryStorage<Answer> {
    const memoryStore = new MemoryStorage<Answer>(primaryAnswerIndex, [parentUuidAnswerIndex, questionUuidIndex]);
    return new ObservableMemoryStorage<Answer>(memoryStore);
}
