import * as React from 'react';

import {IteratorGroup} from '../simple/iterator_group';
import {IteratorPresenter} from './iterator_presenter';
import {SimpleQuestionPresenterProps} from '../simple/simple_question_presenter';
import {observer} from 'mobx-react';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {ValidationMessages} from '../components/validation_messages';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {shouldShowTitle} from '../../../../../../support/should_show_title';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const Iterator: React.FC<OwnProps> = observer(function Iterator(props) {
    const presenter = usePresenter(
        (container) =>
            new IteratorPresenter(
                props.question,
                props.appraisal,
                props.parentAnswerUuid,
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.questionEffectsInteractor(props.appraisal, props.questionSet),
                container.business.userInteractor,
                container.business.childQuestionValidationProvider(
                    props.appraisal,
                    props.questionSet,
                    props.validationErrors
                )
            )
    );

    if (presenter.isHidden) {
        return null;
    }

    if (props.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
        const iteration = '1';
        return (
            <>
                {shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel) && (
                    <h2>{props.question.contents}</h2>
                )}
                <IteratorGroup
                    key={props.question.uuid + '[' + iteration + ']'}
                    iteration={iteration}
                    appraisal={props.appraisal}
                    question={props.question}
                    questionSet={props.questionSet}
                    validationErrors={props.validationErrors}
                    validationMessages={props.validationMessages}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    parentAnswerUuid={props.parentAnswerUuid}
                    adaptedDefaultValues={props.adaptedDefaultValues}
                    hiddenQuestionTypes={props.hiddenQuestionTypes}
                    pagePartsSet={props.pagePartsSet}
                    activePagePart={props.activePagePart}
                    renderingContext={props.renderingContext}
                    questionRenderingData={props.questionRenderingData}
                />
            </>
        );
    }

    return (
        <>
            {shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel) && (
                <h2>{props.question.contents}</h2>
            )}
            {presenter.loading || presenter.iterations === undefined || presenter.iterations.length === 0
                ? null
                : presenter.iterations.map((iteration) => {
                      return (
                          <IteratorGroup
                              key={props.question.uuid + '[' + iteration + ']'}
                              iteration={iteration}
                              appraisal={props.appraisal}
                              question={props.question}
                              questionSet={props.questionSet}
                              validationErrors={props.validationErrors}
                              validationMessages={props.validationMessages}
                              forceShowValidationMessages={props.forceShowValidationMessages}
                              parentAnswerUuid={props.parentAnswerUuid}
                              adaptedDefaultValues={props.adaptedDefaultValues}
                              hiddenQuestionTypes={props.hiddenQuestionTypes}
                              pagePartsSet={props.pagePartsSet}
                              activePagePart={props.activePagePart}
                              renderingContext={props.renderingContext}
                              questionRenderingData={props.questionRenderingData}
                          />
                      );
                  })}
            {!presenter.isDisabled && !props.disabled ? (
                <div className="iterator-buttons-container">
                    <button
                        disabled={props.disabled || presenter.loading}
                        onClick={() => presenter.addIteration()}
                        className="btn btn-default btn-sm ion-md-add-circle float-left"
                    >
                        Toevoegen
                    </button>
                </div>
            ) : null}
            <ValidationMessages
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={undefined}
                validationMessages={filterValidationMessages(
                    props.question,
                    undefined,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
        </>
    );
});
