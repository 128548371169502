import * as React from 'react';

import {Appraisal} from '../../../../../../../../../models/appraisal';
import {BuildyearBadge} from '../badges/buildyear_badge_adapter';
import {FloorAreaBadgeAdapter} from '../badges/floor_area_badge_adapter';
import {FloorAreaPrice} from '../floor_area_price';
import {Loader} from '../../../../../../../components/loader';
import {Modal} from '../../../../../components/modal';
import {PlotAreaBadgeAdapter} from '../badges/plot_area_badge_adapter';
import {PriceRange} from '../price_range';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {ReferenceObject} from '../../models/reference_object';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {ReferenceObjectDetailsModalPresenter} from './reference_object_details_modal_presenter';
import {classNames} from '../../../../../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {transformRecord} from '../../../../../../../../../models/validation_institute_reference_object';
import {referenceObjectImageUrlPairs} from '../../internal/reference_sale_image_urls';
import {sourceToClassName} from '../../../v3/models/reference_object';
import {VolumeBadgeAdapter} from '../badges/volume_badge_adapter';
import {isEmpty} from '../../../../../../../../../../support/util';
import {format} from 'date-fns';
import {ObjectTypeBadge} from '../../../../../../../../../components/badges/object_type_badge';
import {ObjectType} from '../../../../../../../../../enum/object_type';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';
import {ImageViewer} from '../../../../../../../../../components/image_viewer/image_viewer';

interface OwnProps {
    appraisal: Appraisal;
    perceelOppervlakte: number | null;
    gebruiksoppervlakteWonen: number | null;
    brutoInhoud: number | null;
    objectType: ObjectType | string | null;
    energieLabel: string | null;
    questionSet: QuestionSet;
    referenceObject: ReferenceObject;
    valuation: number | null;
    visible: boolean;
    onHide: () => void;
    onAdd: (referenceObject: ReferenceObject, referenceObjectAnswer: ReferenceObjectAnswer) => void;
}

export const ReferenceObjectDetailsModal: React.FC<OwnProps> = observer(function ReferenceObjectDetailsModal(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectDetailsModalPresenter(
                props.referenceObject,
                props.onAdd,
                container.business.referenceObjectProvider(props.appraisal, props.questionSet)
            )
    );

    const referenceObject = props.referenceObject;
    const overview = transformRecord(referenceObject.overview);
    const installationDates = transformRecord(referenceObject.installationDates);

    const period: string | null =
        referenceObject.saleQuarter !== null && !isEmpty(referenceObject.saleQuarter)
            ? referenceObject.saleQuarter.length > 8
                ? format(new Date(referenceObject.saleQuarter), 'dd-MM-yyyy')
                : 'Q' + referenceObject.saleQuarter
            : '-';

    return (
        <Modal key={referenceObject.id} onHide={() => props.onHide()} visible={props.visible}>
            <div className="modal-header">
                <h2>
                    {referenceObject.street} {referenceObject.houseNumber},{referenceObject.city}
                </h2>
            </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-sm-6">
                        <h2>Object details</h2>
                        <dl>
                            <dt>Adres</dt>
                            <dl>
                                {referenceObject.street} {referenceObject.houseNumber}
                                {referenceObject.letter}
                                <br />
                                {referenceObject.postalCode} {referenceObject.city}
                            </dl>
                        </dl>
                        <dl>
                            <dt>Woningtype</dt>
                            <dd>
                                {referenceObject.objectType ?? '-'}
                                {props.objectType && (
                                    <ObjectTypeBadge
                                        objectType={referenceObject.objectType as ObjectType}
                                        referenceObjectType={props.objectType as ObjectType}
                                    />
                                )}
                            </dd>
                        </dl>
                        <dl>
                            <dt>Bouwjaar</dt>
                            <dl>
                                {referenceObject.buildYear === null ? 'Onbekend' : referenceObject.buildYear}
                                {referenceObject.buildYear !== null ? (
                                    <BuildyearBadge
                                        buildYear={referenceObject.buildYear}
                                        appraisal={props.appraisal}
                                        questionSet={props.questionSet}
                                    />
                                ) : null}
                            </dl>
                        </dl>
                        <dl>
                            <dt>Energielabel</dt>
                            <dd>
                                {referenceObject.energyLabel !== null ? referenceObject.energyLabel : '-'}{' '}
                                {props.energieLabel !== null ? '(' + props.energieLabel + ')' : ''}
                            </dd>
                        </dl>
                        <dl>
                            <dt>Gebruiksoppervlakte wonen</dt>
                            <dl>
                                {referenceObject.floorArea === null ? 'Onbekend' : referenceObject.floorArea + 'm²'}
                                {props.gebruiksoppervlakteWonen !== null && referenceObject.floorArea !== null ? (
                                    <FloorAreaBadgeAdapter
                                        floorArea={referenceObject.floorArea}
                                        gebruiksoppervlakteWonen={props.gebruiksoppervlakteWonen}
                                        appraisal={props.appraisal}
                                        questionSet={props.questionSet}
                                    />
                                ) : null}
                            </dl>
                        </dl>
                        <dl>
                            <dt>Perceeloppervlakte</dt>
                            <dl>
                                {referenceObject.plotArea === null ? 'Onbekend' : referenceObject.plotArea + 'm²'}
                                {props.perceelOppervlakte !== null && referenceObject.plotArea !== null ? (
                                    <PlotAreaBadgeAdapter
                                        appraisal={props.appraisal}
                                        perceelOppervlakte={props.perceelOppervlakte}
                                        plotArea={referenceObject.plotArea}
                                        questionSet={props.questionSet}
                                    />
                                ) : null}
                            </dl>
                        </dl>
                    </div>
                    <div className="col-sm-6">
                        <div className="detail-image">
                            <div
                                className={classNames(
                                    'reference-logo',
                                    sourceToClassName(props.referenceObject?.source ?? null)
                                )}
                            >
                                &nbsp;
                            </div>
                            <ImageViewer
                                images={referenceObjectImageUrlPairs(referenceObject, null).map((pair) => pair.url)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <dl>
                            <dt>Prijs per m²</dt>
                            <dl>
                                <FloorAreaPrice
                                    referenceSetPrice={props.valuation}
                                    referenceObjectFloorArea={referenceObject.floorArea}
                                    referenceObjectPrice={referenceObject.priceRange}
                                    referenceSetFloorArea={props.gebruiksoppervlakteWonen}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                />
                            </dl>
                        </dl>
                        <dl>
                            <dt>Bruto inhoud</dt>
                            <dl>
                                {referenceObject.volume === null ? '-' : 'ca. ' + referenceObject.volume + 'm³'}
                                {props.brutoInhoud !== null && referenceObject.volume !== null ? (
                                    <VolumeBadgeAdapter
                                        appraisal={props.appraisal}
                                        brutoInhoud={props.brutoInhoud}
                                        volume={referenceObject.volume}
                                        questionSet={props.questionSet}
                                    />
                                ) : null}
                            </dl>
                        </dl>
                    </div>
                    <div className="col-6">
                        <h2>Verkoop details</h2>
                        <dl>
                            <dt>Prijsklasse</dt>
                            <dl>
                                <PriceRange range={referenceObject.priceRange} />
                            </dl>
                        </dl>
                        <dl>
                            <dt>Datum afmelding/verkocht op</dt>
                            <dl>{period}</dl>
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        {referenceObject.highlights.length > 0 ? (
                            <div>
                                <h2>Highlights</h2>
                                <ul>
                                    {referenceObject.highlights.map((highlight, index) => (
                                        <li key={highlight + index}>{highlight}</li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="col-6">
                        {overview.length > 0 && (
                            <div>
                                <h2>Overzicht</h2>
                                <ul>
                                    {overview.map((value, index) => (
                                        <li key={value + '-' + index}>{value}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {installationDates.length > 0 && (
                            <div>
                                <h2>Installatie datums</h2>
                                <ul>
                                    {installationDates.map((value, index) => (
                                        <li key={value + '-' + index}>{value}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary ion-md-close" type="button" onClick={() => props.onHide()}>
                    Sluiten
                </button>
                <button className="btn btn-primary ion-md-close" type="button" onClick={() => presenter.onAdd()}>
                    {presenter.isAdding ? (
                        <span>
                            <Loader />
                            Ophalen...
                        </span>
                    ) : (
                        <span>Toevoegen</span>
                    )}
                </button>
            </div>
        </Modal>
    );
});
