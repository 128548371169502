import {
    ReferenceObjectTreeItems,
    ReferenceObjectValues,
    createReferenceObjectData,
} from '../../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/create_reference_object_data';
import {TreeItem, findChildRecursiveByPredicate} from '../../../../support/generic_tree';
import {
    V3SetDefinition,
    V3SetDefinitionsProvider,
} from '../../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/reference_sets/set_definitions_provider';
import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';

import {Answer} from '../../../models/answer';
import {AnswerValidator} from '../answer_validator';
import {Appraisal} from '../../../models/appraisal';
import {AppraiseModel, isAppraiseModelOrNewer} from '../../../enum/appraise_model';
import {Question} from '../../../models/question';
import {QuestionAnswerPair} from '../../../../support/question_answer_tree';
import {QuestionSet} from '../../../models/question_set';
import {SetType} from '../../../models/reference_set/set_type';
import {TechnicalReference} from '../../../enum/technical_reference';
import {isApartment} from '../../support/is_apartment_check';
import {isEmpty} from '../../../../support/util';

export class IteratorReferenceObjectV3Validator implements AnswerValidator {
    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private setDefinitionsProvider: V3SetDefinitionsProvider
    ) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (answer === null || question.technicalReference !== TechnicalReference.REFERENCE_OBJECTS_V3_ITERATOR_GROUP) {
            return [];
        }

        const valuationGroupQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.VALUATION_GROUP
        );
        if (valuationGroupQuestion === null) {
            return [];
        }
        const result = this.setDefinitionsProvider.getSetDefinitions(valuationGroupQuestion, true);
        if (result === null) {
            return [];
        }

        let tree: TreeItem<QuestionAnswerPair> | null = null;
        let setDefinition: V3SetDefinition<TreeItem<QuestionAnswerPair>> | null = null;
        for (const innerSetDefinition of result.setDefinitions) {
            const thisObjectTree = findChildRecursiveByPredicate(
                innerSetDefinition.groupTree,
                (item) => item.answer?.uuid === answer.uuid
            );
            if (thisObjectTree) {
                setDefinition = innerSetDefinition;
                tree = thisObjectTree;
            }
        }
        if (tree === null || setDefinition === null) {
            return [];
        }

        const referenceObjectData = createReferenceObjectData(tree);
        if (referenceObjectData === null) {
            return [];
        }

        let messages: ValidationMessage[] = [];
        const {referenceObjectValues, referenceObjectTreeItems} = referenceObjectData;

        const ownershipMessage = validatesOwnershipMessage(
            this.appraisal,
            referenceObjectValues,
            referenceObjectTreeItems
        );
        if (ownershipMessage) {
            messages.push(ownershipMessage);
        }

        const otherMessages = validateRequiredAnswers(
            referenceObjectValues,
            referenceObjectTreeItems,
            setDefinition.type
        );
        if (otherMessages.length > 0) {
            messages = [...messages, ...otherMessages];
        }
        return messages;
    }
}

function validatesOwnershipMessage(
    appraisal: Appraisal,
    referenceObjectValues: ReferenceObjectValues,
    referenceObjectTreeItems: ReferenceObjectTreeItems
): ValidationMessage | null {
    const other = [
        referenceObjectValues.eigendomssituatieRechtErfpacht,
        referenceObjectValues.eigendomssituatieRechtOndererfpacht,
        referenceObjectValues.eigendomssituatieRechtOpstal,
        referenceObjectValues.eigendomssituatieRechtGebruikBewoning,
        referenceObjectValues.eigendomssituatieRechtVruchtgebruik,
        referenceObjectValues.eigendomssituatieRechtAnders,
    ];
    if (!isAppraiseModelOrNewer(appraisal.appraiseModel, AppraiseModel.MODEL2021)) {
        return null;
    }

    if (
        referenceObjectTreeItems.eigendomssituatieVolleEigendom &&
        referenceObjectValues.eigendomssituatieVolleEigendom === true &&
        other.some((v) => v === true)
    ) {
        return {
            type: ValidationMessageType.TAXAPI,
            importance: ValidationMessageImportance.ERROR,
            question: referenceObjectTreeItems.eigendomssituatieVolleEigendom.item.question,
            answer: referenceObjectTreeItems.eigendomssituatieVolleEigendom.item.answer,
            messages: [],
            fallbackMessage: 'Wanneer volle eigendom geselecteerd is kunnen er geen andere rechten geselecteerd zijn.',
            floorInfo: null,
        };
    } else if (
        referenceObjectTreeItems.eigendomssituatieVolleEigendom &&
        referenceObjectValues.eigendomssituatieVolleEigendom === false &&
        other.find((v) => v === true) === undefined
    ) {
        return {
            type: ValidationMessageType.TAXAPI,
            importance: ValidationMessageImportance.ERROR,
            question: referenceObjectTreeItems.eigendomssituatieVolleEigendom.item.question,
            answer: referenceObjectTreeItems.eigendomssituatieVolleEigendom.item.answer,
            messages: [],
            fallbackMessage: 'Er moet ten minste 1 recht geselecteerd worden.',
            floorInfo: null,
        };
    }
    return null;
}

function validateRequiredAnswers(
    referenceObjectValues: ReferenceObjectValues,
    referenceObjectTreeItems: ReferenceObjectTreeItems,
    setType: SetType
): ValidationMessage[] {
    const messages: ValidationMessage[] = [];

    if (
        referenceObjectTreeItems.perceelOppervlakte &&
        !isApartment(referenceObjectValues.woningType ?? undefined) &&
        isEmpty(referenceObjectValues.perceelOppervlakte)
    ) {
        messages.push({
            type: ValidationMessageType.TAXAPI,
            importance: ValidationMessageImportance.ERROR,
            question: referenceObjectTreeItems.perceelOppervlakte.item.question,
            answer: referenceObjectTreeItems.perceelOppervlakte.item.answer ?? null,
            messages: [],
            fallbackMessage: 'Wanneer woningtype geen apartement is is perceel oppervlakte verplicht.',
            floorInfo: null,
        });
    }

    if (
        referenceObjectTreeItems.eigendomssituatieToelichting &&
        referenceObjectValues.eigendomssituatieVolleEigendom === true &&
        !isEmpty(referenceObjectValues.eigendomssituatieToelichting)
    ) {
        messages.push({
            type: ValidationMessageType.TAXAPI,
            importance: ValidationMessageImportance.ERROR,
            question: referenceObjectTreeItems.eigendomssituatieToelichting.item.question,
            answer: referenceObjectTreeItems.eigendomssituatieToelichting.item.answer ?? null,
            messages: [],
            fallbackMessage:
                'Er is een toelichting voor ander recht ingevuld, maar er is ook aangegeven dat er sprake is van volledig recht.',
            floorInfo: null,
        });
    }

    if (setType === SetType.SOLD) {
        if (
            referenceObjectTreeItems.verkoopdatum &&
            isEmpty(referenceObjectValues.verkoopdatum) &&
            isEmpty(referenceObjectValues.transportdatum)
        ) {
            messages.push({
                type: ValidationMessageType.TAXAPI,
                importance: ValidationMessageImportance.ERROR,
                question: referenceObjectTreeItems.verkoopdatum.item.question,
                answer: referenceObjectTreeItems.verkoopdatum.item.answer ?? null,
                messages: [],
                fallbackMessage: 'Er dient minimaal een verkoopdatum of transport datum ingevuld te zijn.',
                floorInfo: null,
            });
        }

        if (
            referenceObjectTreeItems.transportdatum &&
            !isEmpty(referenceObjectValues.verkoopdatum && !isEmpty(referenceObjectValues.transportdatum))
        ) {
            if (
                referenceObjectValues.verkoopdatum !== null &&
                referenceObjectValues.transportdatum !== null &&
                referenceObjectValues.transportdatum !== undefined
            ) {
                if (
                    !isEmpty(referenceObjectValues.verkoopdatum) &&
                    !isEmpty(referenceObjectValues.transportdatum) &&
                    referenceObjectValues.verkoopdatum > referenceObjectValues.transportdatum
                ) {
                    messages.push({
                        type: ValidationMessageType.TAXAPI,
                        importance: ValidationMessageImportance.ERROR,
                        question: referenceObjectTreeItems.transportdatum.item.question,
                        answer: referenceObjectTreeItems.transportdatum.item.answer ?? null,
                        messages: [],
                        fallbackMessage: 'Datum transactie ligt na transportdatum.',
                        floorInfo: null,
                    });
                }

                if (referenceObjectValues.verkoopdatum === referenceObjectValues.transportdatum) {
                    messages.push({
                        type: ValidationMessageType.TAXAPI,
                        importance: ValidationMessageImportance.ERROR,
                        question: referenceObjectTreeItems.transportdatum.item.question,
                        answer: referenceObjectTreeItems.transportdatum.item.answer ?? null,
                        messages: [],
                        fallbackMessage: 'Datum transactie ligt op dezelfde dag als de transportdatum',
                        floorInfo: null,
                    });
                }
            }
        }
    }

    return messages;
}
