import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {ClientFilesFinishedContent} from './container/client_files_finished_content';
import {ClientFilesPresenter} from './client_files_presenter';
import {ClientFooter} from './container/client_footer';
import {ErrorBoundary} from '../../../../components/error_boundary';
import {Global} from '../../../../../business/global_provider';
import {History} from 'history';
import {MessagesStack} from '../../global/messages_stack';
import {ModalsStack} from '../modals_stack/modals_stack';
import {PagePart} from '../../../../models/page_part';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {QuestionSet} from '../../../../models/question_set';
import {QuestionType} from '../../../../enum/question_type';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {ValidationError} from '../../../../models/validation_error';
import {ValidationMessageMap} from '../../../../business/validation/validation_message';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {QuestionRenderingData} from '../../../../models/question_rendering_data';

interface OwnProps {
    history: History;
    global: Global;
    appraisal: Appraisal;
    onChange: (appraisal: Appraisal) => void;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    hiddenQuestionTypes: QuestionType[];
    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;

    children?: React.ReactNode;
}

export const ClientFiles: React.FC<OwnProps> = observer(function ClientFiles(props) {
    const presenter = usePresenter(
        (container) =>
            new ClientFilesPresenter(
                props.appraisal,
                props.questionSet,
                container.business.childQuestionValidator(props.appraisal, props.questionSet, []),
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.network.composedNotificationApi,
                container.business.flashMessageBroadcaster,
                container.business.answerController(props.appraisal, props.questionSet)
            )
    );

    return (
        <ErrorBoundary>
            <ModalsStack
                appraisal={props.appraisal}
                questionSet={props.questionSet}
                validationErrors={props.validationErrors}
                hiddenQuestionTypes={props.hiddenQuestionTypes}
                renderingContext={props.renderingContext}
                questionRenderingData={props.questionRenderingData}
            />
            <span id="appraising-loaded" />
            <MessagesStack
                history={props.history}
                appraisal={props.appraisal}
                questionSet={props.questionSet}
                validationErrors={props.validationErrors}
                cameraProgress={null}
                pagePartsSet={props.pagePartsSet}
            />
            {presenter.finished ? <ClientFilesFinishedContent appraisal={props.appraisal} /> : props.children}
            {props.global.clientExtraFilesEnabled && (
                <ClientFooter
                    appraisal={props.appraisal}
                    finished={presenter.finished}
                    loading={presenter.loading}
                    disabled={!presenter.hasChanges}
                    errors={presenter.hasErrors}
                    onClick={() => presenter.onClick()}
                />
            )}
        </ErrorBoundary>
    );
});
