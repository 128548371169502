import * as Throttle from 'promise-parallel-throttle';
import * as Uuid from 'uuid';

import {FlashMessageBroadcaster, Type} from '../../../business/flash_message_broadcaster';
import {HowSureAreWeLevel, PredictionResult} from '../../../business/ml/vision/photo_content_predicter';
import {CachedPhoto, AttachedPhoto, ProgressType} from './camera_presenter';

import {Answer} from '../../../models/answer';
import {AnswerInteractor} from '../../../business/answering/answer_interactor';
import {BlobCacheInteractor} from '../../../business/attachments/blob_cache_interactor';
import {DetailModalData} from './photo_details_modal';
import {ImageOrientationFixer} from '../../../business/image_orientation_fixer';
import {ImageUploadInteractor} from '../../../business/attachments/image_upload_interactor';
import {PhotoRecognitionHandler} from '../../../business/answering/photo_recognition_handler';
import {StatusLabelType} from '../container/status_label';
import {bugsnagClient} from '../../../../support/bugsnag_client';
import {isImageFile} from '../../../../support/file_type_check';
import pTimeout from 'p-timeout';
import {sum} from '../../../../support/sum';
import {Question} from '../../../models/question';
import {AnswerController} from '../../../business/answering/answer_controller';
import {ALLOWED_IMAGE_TYPES} from './questions/question_container';

interface Callbacks {
    updateProgress: (progress: number, type: ProgressType) => void;
    onDetailModalData: (data: null | DetailModalData) => void;
    alertNotAvailable: () => void;
    alertIncorrectType: () => void;
}

export class ImagesUploadInteractor {
    private fileTypes = ALLOWED_IMAGE_TYPES;

    constructor(
        private blobCacheInteractor: BlobCacheInteractor,
        private answerInteractor: AnswerInteractor,
        private imageUploadInteractor: ImageUploadInteractor,
        private photoRecognitionHandler: PhotoRecognitionHandler,
        private flashMessageBroadcaster: FlashMessageBroadcaster,
        private imageOrientationFixer: ImageOrientationFixer,
        private answerController: AnswerController
    ) {}

    public preparePhotoRecognition() {
        this.photoRecognitionHandler.prepare();
    }

    public async onFiles(files: File[], rootQuestion: Question | null, callbacks: Callbacks) {
        try {
            if (!(await this.blobCacheInteractor.canStore(files[0]))) {
                callbacks.alertNotAvailable();
                return;
            }

            const onlyHasImages = files.every((file) => isImageFile(file, this.fileTypes));
            if (onlyHasImages === false) {
                callbacks.alertIncorrectType();
                return;
            }

            //The question every answer gets attached to
            const photoQuestion = this.photoRecognitionHandler.getPhotoQuestion(rootQuestion);
            if (photoQuestion === null) {
                throw new Error('No valid photo question found to link photos to.');
            }

            //Preparing will run the progress bar till 20%
            //Processing will run it till 20% + 65% = 85%
            //Saving the answers will run from 85% till 100% (this one is instant since its an ajax call)
            const preparingFactor = 0.2;
            const processingFactor = 0.65;

            callbacks.updateProgress(0, ProgressType.CRITICAL);

            this.answerController.pauseStreams();
            //First we try to cache every photo, in case any photo fails the upload process they can be retried later on
            //See photo_answer_retry_interactor
            const attachedFiles = await Throttle.all(
                files.map((file) => () => this.cacheAndAttach(file, photoQuestion)),
                {
                    maxInProgress: 3,
                    failFast: false,
                    progressCallback: (result) => {
                        const progress = (result.amountDone / files.length) * preparingFactor;
                        callbacks.updateProgress(progress, ProgressType.CRITICAL);
                    },
                }
            );
            this.answerController.resumeStreams();

            //After every photo is cached, we resume to process the photo
            //1. Rotate it (is allowed to fail, will still upload)
            //2. Label it (is allowed to fail, will still upload)
            //3. Resize & upload it
            const progressMap = new Map<string, number>();
            const trulyAttachedFiles = attachedFiles.filter((file): file is AttachedPhoto => file != null);
            const result = await Throttle.raw(
                trulyAttachedFiles.map((attachedPhoto) => async () => {
                    return await pTimeout(
                        this.processFile(attachedPhoto, callbacks, attachedFiles.length === 1, (uuid, progress) => {
                            progressMap.set(uuid, progress);
                            const totalProcessProgress = sum(Array.from(progressMap.values())) / attachedFiles.length;
                            const totalProgress = preparingFactor + totalProcessProgress * processingFactor;
                            callbacks.updateProgress(totalProgress, ProgressType.CASUAL);
                        }),
                        3 * 60 * 1000
                    );
                }),
                {
                    maxInProgress: 3,
                    failFast: false,
                }
            );

            //Submit all the created answers
            await this.answerInteractor.submit();
            callbacks.updateProgress(1, ProgressType.CASUAL);

            if (trulyAttachedFiles.length != attachedFiles.length || result.amountRejected > 0) {
                callbacks.updateProgress(1, ProgressType.FAILURE);
                this.flashMessageBroadcaster.broadcast(
                    'Uploaden van sommige fotos gefaald, probeer het nogmaals.',
                    Type.Danger
                );
            } else {
                callbacks.updateProgress(1, ProgressType.IDLE);
            }
        } catch (e) {
            console.error('Uploading of files failed', e);
            bugsnagClient?.notify(e);
            callbacks.updateProgress(1, ProgressType.FAILURE);
            this.flashMessageBroadcaster.broadcast(
                'Uploaden van sommige fotos gefaald, probeer het nogmaals.',
                Type.Danger
            );
        }
    }

    private async processFile(
        attachedPhoto: AttachedPhoto,
        callbacks: Callbacks,
        showLabelModal: boolean,
        progressCallback: (uuid: string, progress: number) => void
    ) {
        let rotatedPhoto: AttachedPhoto = attachedPhoto;
        try {
            rotatedPhoto = {
                ...rotatedPhoto,
                file: await this.rotateImage(attachedPhoto.file),
            };
        } catch (error) {
            console.warn('Rotating failed');
        }

        try {
            await this.labelPhoto(rotatedPhoto, showLabelModal, callbacks);
        } catch (error) {
            console.warn('Labeling failed');
        }

        await this.upload(rotatedPhoto.file, rotatedPhoto.answer, callbacks, (progress) => {
            progressCallback(rotatedPhoto.answer.uuid, progress);
        });
    }

    private async cacheFile(uploadedFile: File): Promise<CachedPhoto> {
        const uuid = Uuid.v4();

        const isCached = await this.imageUploadInteractor.cache(uuid, uploadedFile);

        return {
            uuid,
            file: uploadedFile,
            isCached: isCached,
        };
    }

    private async cacheAndAttach(uploadedFile: File, photoQuestion: Question): Promise<AttachedPhoto | null> {
        const cachedPhoto = await this.cacheFile(uploadedFile);
        const attachedPhoto = await this.addFileToAnswer(cachedPhoto, photoQuestion);
        return attachedPhoto;
    }

    private async rotateImage(file: File) {
        //Fix rotation of image
        let rotatedFile: File | null = null;
        try {
            rotatedFile = await pTimeout(this.fixRotation(file), 10 * 1000);
        } catch (error) {
            rotatedFile = file;
        }
        return rotatedFile;
    }

    private async addFileToAnswer(cachedFile: CachedPhoto, photoQuestion: Question): Promise<AttachedPhoto | null> {
        try {
            const photoQuestionAnswerPair = this.photoRecognitionHandler.preparePhotoAnswers(photoQuestion);
            if (photoQuestionAnswerPair === null) {
                return null;
            }

            await this.imageUploadInteractor.attachToAnswer(
                cachedFile.uuid,
                photoQuestionAnswerPair.answer.uuid,
                cachedFile.file
            );

            return {
                file: cachedFile.file,
                question: photoQuestionAnswerPair.question,
                answer: photoQuestionAnswerPair.answer,
            };
        } catch (e) {
            console.error('Error while preparing file for upload', e);
            bugsnagClient?.notify(e, {
                metaData: {
                    origin: 'camera_presenter.ts prepare()',
                },
            });
            return null;
        }
    }

    private async labelPhoto(preparedPhoto: AttachedPhoto, shouldShowModal: boolean, callbacks: Callbacks) {
        try {
            //5. Do photo recognition
            let predictionResult: PredictionResult | null = null;
            try {
                predictionResult = await pTimeout(
                    this.photoRecognitionHandler.handle(
                        preparedPhoto.file,
                        preparedPhoto.question,
                        preparedPhoto.answer,
                        0.8,
                        0.5
                    ),
                    30 * 1000 //Extra long, because bathrooms load extra ML models
                );
            } catch (error) {
                console.warn('Photo prediction failed for image', preparedPhoto.file.name, error);
            }

            if (shouldShowModal) {
                //6. If recognized show flashmessage
                if (
                    predictionResult &&
                    predictionResult.howSure === HowSureAreWeLevel.VerySure &&
                    predictionResult.className !== null
                ) {
                    this.flashMessageBroadcaster.broadcast(
                        'Foto herkend als ' + predictionResult.className.toLocaleLowerCase() + '.',
                        Type.Success,
                        {
                            type: StatusLabelType.Beta,
                            content: 'Bèta',
                        }
                    );
                }

                //7. If not recognized show a modal with options
                if (!predictionResult || predictionResult.howSure !== HowSureAreWeLevel.VerySure) {
                    const url = URL.createObjectURL(preparedPhoto.file);

                    callbacks.onDetailModalData({
                        photoObjectUrl: url,
                        parentAnswerUuid: preparedPhoto.answer.uuid,
                        photoQuestion: preparedPhoto.question,
                        photoRecognized:
                            predictionResult !== null && predictionResult.howSure < HowSureAreWeLevel.Unsure,
                    });
                }
            }
        } catch (e) {
            //Swallow all error since recognition is secondary
            console.error(e);
            bugsnagClient?.notify(e);
            if (shouldShowModal) {
                const url = URL.createObjectURL(preparedPhoto.file);

                callbacks.onDetailModalData({
                    photoObjectUrl: url,
                    parentAnswerUuid: preparedPhoto.answer.uuid,
                    photoQuestion: preparedPhoto.question,
                    photoRecognized: false,
                });
            }
        }
    }

    private async fixRotation(uploadedFile: File) {
        try {
            return await this.imageOrientationFixer.fix(uploadedFile);
        } catch (e) {
            console.error(e);
            bugsnagClient?.notify(e, {
                metaData: {
                    origin: 'camera_presenter.ts fixRotation()',
                },
            });

            //We'll just ignore this error, apparently some iOS devices don't want to rotate
            return uploadedFile;
        }
    }

    private async upload(
        preparedFile: File,
        photoAnswer: Answer,
        callbacks: Callbacks,
        progressCallback: (progress: number) => void
    ) {
        try {
            const result = await this.imageUploadInteractor.uploadForAnswer(photoAnswer.uuid, preparedFile, {
                fileTypes: this.fileTypes,
                progressCallback,
                preventSubmittingAnswers: true,
            });
            if (!result.succeeded) {
                callbacks.onDetailModalData(null);
                callbacks.alertIncorrectType();
            }
        } catch (e) {
            console.error('Error triggering image upload', e);
            bugsnagClient?.notify(e);
        }
    }
}
