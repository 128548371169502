import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {classNames} from '../../../support/classnames';
import {ImageViewerComponentProps} from './image_viewer';
import {PhotoSwipeEventsMap} from 'photoswipe';
import {ImageViewerContext} from './image_viewer_context';

export function ThumbnailSlider({images, lightbox}: ImageViewerComponentProps) {
    const [hidden, setHidden] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const {open} = useContext(ImageViewerContext);
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!lightbox) return;

        const beforeZoomTo = (ev: PhotoSwipeEventsMap['beforeZoomTo']) => {
            if (lightbox.pswp?.currSlide) {
                setHidden(ev.destZoomLevel > lightbox.pswp?.currSlide?.zoomLevels.initial);
            }
        };

        const zoomPanUpdate = (ev: PhotoSwipeEventsMap['zoomPanUpdate']) => {
            setHidden(ev.slide.currZoomLevel > ev.slide.zoomLevels.initial);
        };

        const close = () => {
            setActiveIndex(null);
        };

        const slideActivate = () => {
            if (lightbox.pswp) {
                setActiveIndex(lightbox.pswp.currIndex);
            }
        };

        lightbox.on('beforeZoomTo', beforeZoomTo);
        lightbox.on('zoomPanUpdate', zoomPanUpdate);
        lightbox.on('close', close);
        lightbox.on('slideActivate', slideActivate);

        return () => {
            lightbox.off('beforeZoomTo', beforeZoomTo);
            lightbox.off('zoomPanUpdate', zoomPanUpdate);
            lightbox.off('close', close);
            lightbox.off('slideActivate', slideActivate);
        };
    }, [lightbox]);

    useEffect(() => {
        if (!lightbox?.pswp) {
            return;
        }

        if (activeIndex === null && lightbox.pswp.currSlide) {
            setActiveIndex(lightbox.pswp.currIndex);
        } else if (activeIndex !== null && lightbox.pswp.currIndex !== activeIndex && container.current) {
            open(activeIndex);
        }
    }, [activeIndex]);

    const urls = useMemo(() => {
        return images?.map((img) => (typeof img === 'string' ? img : 'url' in img ? img.url : null)) ?? [];
    }, [images]);

    if (hidden) {
        return null;
    }

    return (
        <div
            className={classNames('slider-track', {
                hidden: urls.length <= 3,
            })}
            style={
                {
                    '--selected-index': activeIndex,
                    '--slide-count': urls.length,
                } as React.CSSProperties
            }
            ref={container}
        >
            {urls.map((image, i) => (
                <div
                    className={classNames('img-wrap', {
                        active: i === activeIndex,
                    })}
                    key={typeof image === 'string' ? image : i}
                >
                    {image ? <img src={image} onClick={() => setActiveIndex(i)} loading="lazy" /> : null}
                </div>
            ))}
        </div>
    );
}
