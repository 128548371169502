import {Answer} from '../../../models/answer';
import {AppraisalApi} from '../../../network/appraisal_api';

export interface AnswerLoader {
    fetchForAppraisal(appraisalId: number): Promise<void>;

    getForAppraisal(appraisalId: number): Answer[];
}

export class DefaultAnswerLoader implements AnswerLoader {
    private answers = new Map<number, Promise<Answer[]> | Answer[]>();

    constructor(private readonly appraisalApi: AppraisalApi) {}

    public async fetchForAppraisal(appraisalId: number): Promise<void> {
        const existing = this.answers.get(appraisalId);
        if (existing) {
            if (existing instanceof Promise) {
                await existing;
            }

            return;
        }

        const answersPromise = this.appraisalApi.getAnswers(appraisalId);

        this.answers.set(appraisalId, answersPromise);

        const result = await answersPromise;

        this.answers.set(appraisalId, result);
    }

    public getForAppraisal(appraisalId: number): Answer[] {
        if (appraisalId === -1) {
            return []; // Dummy appraisal
        }

        const existing = this.answers.get(appraisalId);

        if (!existing) {
            console.error(`Answers for appraisal ${appraisalId} not loaded`);
            return [];
        }

        if (existing instanceof Promise) {
            console.error(`Answers for appraisal ${appraisalId} are still loading`);
            return [];
        }

        return existing;
    }
}
