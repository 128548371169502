import * as React from 'react';

import {Link} from 'react-router-dom';
import {QuestionSet} from '../../../models/question_set';
import {ValidationError} from '../../../models/validation_error';
import {ValidationErrorsListPresenter} from './validation_errors_list_presenter';
import {formatTviField} from '../support/format_tvi_field';
import {usePresenter} from '../../../../support/presenter/use_presenter';

interface OwnProps {
    validationErrors: ValidationError[];
    questionSet: QuestionSet;
}

export const ValidationErrorsList: React.FC<OwnProps> = function ValidationErrorsList(props) {
    const presenter = usePresenter(() => new ValidationErrorsListPresenter(props.questionSet, props.validationErrors));

    return (
        <>
            {presenter.validationMessages.map((validationError, i) => {
                return (
                    <dl key={validationError.field + i} className="alert-inline alert-hint ion-md-alert">
                        <dt>
                            {validationError.question !== undefined && validationError.question !== null ? (
                                <Link to={'/' + validationError.question.uuid}>
                                    {formatTviField(validationError.field)}{' '}
                                </Link>
                            ) : (
                                formatTviField(validationError.field)
                            )}
                        </dt>
                        <dd>{validationError.message}</dd>
                    </dl>
                );
            })}
        </>
    );
};
