import * as React from 'react';

import {ActiveSetDefinition, SetDefinition} from '../reference_objects_question_presenter';

import {Appraisal} from '../../../../../../../../models/appraisal';
import {classNames} from '../../../../../../../../../support/classnames';
import {observer} from 'mobx-react';

interface OwnProps {
    appraisal: Appraisal;
    isDisabled: boolean;
    referenceSets: SetDefinition[];
    activeSetDefinition: ActiveSetDefinition | null;
    setActiveSetDefinition: (setDefinition: SetDefinition) => Promise<void>;
    minReferenceObjects: number;
}

@observer
export class SetDefinitionTabs extends React.Component<OwnProps> {
    public render() {
        return (
            <div className="row">
                <div className="reference-objects-nav-container">
                    <ul className="reference-objects-nav">
                        {this.props.referenceSets === null ? (
                            <li className="reference-objects-nav-item active">
                                <span className="reference-objects-nav-item-placeholder-title">&nbsp;</span>
                            </li>
                        ) : (
                            this.props.referenceSets.map((set, index) => {
                                return (
                                    <li
                                        key={set.title ?? String(index)}
                                        className={classNames('reference-objects-nav-item', {
                                            active:
                                                this.props.activeSetDefinition?.setDefinition.valuationType ===
                                                set.valuationType,
                                            disabled: this.props.isDisabled,
                                        })}
                                        onClick={() => {
                                            if (this.props.isDisabled === false) {
                                                this.props.setActiveSetDefinition(set);
                                            }
                                        }}
                                    >
                                        <span className="reference-objects-nav-item-title">
                                            <span className="reference-objects-nav-item-title-inner">{set.title}</span>
                                            <span
                                                className={classNames('bubble', {
                                                    'bubble-red': set.amountSelected < this.props.minReferenceObjects,
                                                })}
                                            >
                                                {set.amountSelected}/{this.props.minReferenceObjects}
                                            </span>
                                        </span>
                                    </li>
                                );
                            })
                        )}
                    </ul>
                </div>
            </div>
        );
    }
}
