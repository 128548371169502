import {ApiQuestion, apiQuestionToQuestion} from './api_question';
import {DefaultQuestionSet, QuestionSet, ReportDefinitionConfig} from '../../models/question_set';

import {ApiReportParagraphReference} from './api_report_paragraph_reference';
import {ApiMacro} from './api_macro';

export interface ApiQuestionSet {
    id: number;
    questions: ApiQuestion[];
    user_defined_macros: ApiMacro[];
    report_definition_config: {
        opname_datum_outside_of_question_set: boolean;
        pre_validation_messages: boolean;
        concept_report: boolean;
        max_reference_objects_in_set: number;
        report_paragraph_references: ApiReportParagraphReference[];
        compact_house_group_selection: boolean;
        has_building_costs: boolean;
        reference_objects_version: 1 | 2;
    };
}

export function apiQuestionSetToQuestionSet(apiQuestionSet: ApiQuestionSet): QuestionSet {
    return new DefaultQuestionSet(
        apiQuestionSet.id,
        apiQuestionSet.questions.map((apiQuestion) => apiQuestionToQuestion(apiQuestion)),
        apiQuestionSetToReportDefinitionConfig(apiQuestionSet)
    );
}

export function apiQuestionSetToReportDefinitionConfig(apiQuestionSet: ApiQuestionSet): ReportDefinitionConfig {
    return {
        opnameDatumOutsideOfQuestionSet: apiQuestionSet.report_definition_config.opname_datum_outside_of_question_set,
        preValidationMessages: apiQuestionSet.report_definition_config.pre_validation_messages,
        conceptReport: apiQuestionSet.report_definition_config.concept_report,
        maxReferenceObjectsInSet: apiQuestionSet.report_definition_config.max_reference_objects_in_set,
        reportParagraphReferences: apiQuestionSet.report_definition_config.report_paragraph_references,
        compactHouseGroupSelection: apiQuestionSet.report_definition_config.compact_house_group_selection,
        hasBuildingCosts: apiQuestionSet.report_definition_config.has_building_costs,
        referenceObjectsVersion: apiQuestionSet.report_definition_config.reference_objects_version,
    };
}
