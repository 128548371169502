import * as Uuid from 'uuid';

import {ValidationMessage, ValidationMessageMap} from '../../../../../business/validation/validation_message';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {debounceTime, distinctUntilChanged, first, map} from 'rxjs/operators';

import {AdaptedDefaultValuesMap} from '../../../../../models/adapted_default_values_map';
import {Answer} from '../../../../../models/answer';
import {AnswerController} from '../../../../../business/answering/answer_controller';
import {AnswerInteractor} from '../../../../../business/answering/answer_interactor';
import {AnswerOption} from '../../../../../models/answer_option';
import {AnswerPathStubber} from '../../../../../business/answering/answer_path_stubber';
import {AnswerTouchState} from '../../../../../enum/answer_touch_state';
import {AnswerValidatorAdapter} from '../../../../../business/validation/answer_validator_adapter';
import {Appraisal} from '../../../../../models/appraisal';
import {AppraisalState} from '../../../../../enum/appraisal_state';
import {AppraisalValidationType} from '../../../../../enum/appraisal_validation_type';
import {Component} from '../../../../component';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {Macro} from '../../../../../models/macro';
import {MacroInteractor} from '../../../../../business/macro_interactor';
import {PagePart} from '../../../../../models/page_part';
import {PagePartsSet} from '../../../../../models/page_parts_set';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {Question} from '../../../../../models/question';
import {QuestionEffectInteractor} from '../../../../../business/conditions/question_effects_interactor';
import {QuestionSet} from '../../../../../models/question_set';
import {QuestionType} from '../../../../../enum/question_type';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';
import {SearchInteractor} from '../../../../../business/search_interactor';
import {TechnicalReference} from '../../../../../enum/technical_reference';
import {UserInteractor} from '../../../../../business/user_interactor';
import {ValidationError} from '../../../../../models/validation_error';
import {buildQuestionAnswerTrees} from '../../../../../../support/question_answer_tree';
import {isEmpty} from '../../../../../../support/util';
import {toggleMacro} from '../../../../../business/toggle_macro';
import {DuplicationSource, DuplicationSourcesProvider} from '../../../../../business/duplication_sources_provider';
import {FlashMessageBroadcaster, Type} from '../../../../../business/flash_message_broadcaster';
import {QuestionRenderingData} from '../../../../../models/question_rendering_data';

export interface SimpleQuestionPresenterProps {
    question: Question;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;

    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;

    disabled?: boolean;
    hideLabel?: boolean;
    iteration?: string;

    parentAnswerUuid?: string;
    adaptedDefaultValue?: string;
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
    disableAdaptedValueContainer?: boolean;
    hiddenQuestionTypes: QuestionType[];
    onChange?: (appraisal: Appraisal) => void;
}

export const simpleQuestionPresenterConstructorParametersFactory = (
    props: SimpleQuestionPresenterProps,
    component: Component
): ConstructorParameters<typeof SimpleQuestionPresenter> => {
    return [
        props.question,
        props.appraisal,
        props.questionSet,
        props.parentAnswerUuid,
        props.iteration,
        props.adaptedDefaultValue,
        props.adaptedDefaultValues,
        props.renderingContext,
        props.questionRenderingData,
        component.business.answerController(props.appraisal, props.questionSet),
        component.business.questionEffectsInteractor(props.appraisal, props.questionSet),
        component.business.macroInteractor(props.questionSet),
        component.business.userInteractor,
        component.business.answerValidator(props.appraisal, props.questionSet, props.validationErrors),
        component.business.searchInteractor(props.questionSet),
        component.business.answerPathStubber(props.appraisal, props.questionSet, props.renderingContext),
        component.business.answerInteractor(props.appraisal, props.questionSet),
        component.business.duplicationSourcesProvider(props.appraisal, props.questionSet),
        component.business.flashMessageBroadcaster,
    ];
};

export const simpleQuestionPresenterFactory = (props: SimpleQuestionPresenterProps, component: Component) => {
    return new SimpleQuestionPresenter(...simpleQuestionPresenterConstructorParametersFactory(props, component));
};

export class SimpleQuestionPresenter implements Presenter {
    public name = Uuid.v4();
    @observable.ref public answer?: Answer;
    @observable.ref public macros?: Macro[];
    @observable public searchText: string | null = null;
    @observable.ref public validationMessages: ValidationMessage[] = [];
    @observable public filledByAutomator: string | null = null;

    @observable.ref private _duplicationSources: DuplicationSource[] = [];
    @observable.ref private _duplicationSource: DuplicationSource | null = null;
    @observable private _isHidden = false;

    /* Used to compare this answer to potential other answers */
    @observable.ref private _answersForQuestion: Answer[] = [];

    @computed
    public get isHidden(): boolean {
        if (this.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
            return false;
        }

        return (
            this._isHidden ||
            (this.question.isAppraiserOnly &&
                !this.userInteractor.isAppraiser() &&
                !this.userInteractor.isEmployee() &&
                !this.userInteractor.isJuniorAppraiser())
        );
    }

    @computed public get duplicationSources() {
        return this._duplicationSources.map((s) => (s.answer.uuid === this.answer?.uuid ? null : s));
    }

    @computed public get duplicationSource() {
        if (this._duplicationSource) {
            return this._duplicationSource;
        }

        return this.duplicationSources.find((a) => a !== null) ?? null;
    }

    @computed
    public get isDisabled(): boolean {
        if (!this.appraisal.isEditableAppraisal) {
            return true;
        }
        if (
            this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED &&
            this.renderingContext !== RenderingContextType.PLAUSIBILITY_CHECK
        ) {
            return this.loading || (this.appraisal.isFrozen && this.question.freezes);
        }
        return (
            this.loading ||
            (this.appraisal.isFrozen && this.question.freezes) ||
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    @computed
    public get hasUniqueAnswerOptions() {
        return this.question.answerOptions.some((ao) => ao.isUnique);
    }

    @computed
    public get answerOptions(): AnswerOption[] {
        if (this.hasUniqueAnswerOptions) {
            const answerOptionUuidsForOtherIterations = this._answersForQuestion
                .filter((answer) => {
                    return this.answer === undefined || answer.uuid !== this.answer.uuid;
                })
                .map((answer) => answer.answerOptionId);

            return this.question.answerOptions.filter(
                (answerOption) =>
                    !answerOption.isUnique || answerOptionUuidsForOtherIterations.indexOf(answerOption.id) === -1
            );
        } else {
            return this.question.answerOptions;
        }
    }

    @computed
    public get showAddMacroButton(): boolean {
        if (this.isDisabled) {
            return false;
        }

        const answer = this.answer;

        return (
            answer !== undefined &&
            answer.contents !== '' &&
            answer.contents !== null &&
            this.macros !== undefined &&
            !this.macros.some((macro) => macro.contents === answer.contents)
        );
    }

    @computed
    public get loading() {
        return this.answer === undefined;
    }

    private _simpleQuestionPresenterSubscriptions = new CompositeSubscription();

    constructor(
        protected question: Question,
        protected appraisal: Appraisal,
        protected questionSet: QuestionSet,
        protected parentAnswerUuid: string | undefined,
        protected iteration: string | undefined,
        protected adaptedDefaultValue: string | undefined,
        protected adaptedDefaultValues: AdaptedDefaultValuesMap | undefined,
        protected renderingContext: RenderingContextType,
        protected questionRenderingData: QuestionRenderingData | null,
        protected answerController: AnswerController,
        protected questionEffectsInteractor: QuestionEffectInteractor,
        protected macroInteractor: MacroInteractor,
        protected userInteractor: UserInteractor,
        protected answerValidator: AnswerValidatorAdapter,
        protected searchInteractor: SearchInteractor,
        protected answerPathStubber: AnswerPathStubber,
        protected answerInteractor: AnswerInteractor,
        protected duplicationSourcesProvider: DuplicationSourcesProvider,
        protected flashMessageBroadcaster: FlashMessageBroadcaster
    ) {
        const answer = this.answerController.answerByIdentifiers(
            this.question.uuid,
            this.parentAnswerUuid ?? null,
            this.iteration ?? null
        );
        if (answer) {
            this.setAnswer(answer);
        }
        this._isHidden = this.questionEffectsInteractor.isHidden(this.question.uuid, this.parentAnswerUuid ?? null);
        this.macros = this.macroInteractor.getMacrosForQuestionUuid(this.question.uuid);
        this._answersForQuestion = this.answerController
            .filterDeleted(this.answerController.answersForQuestionUuid(this.question.uuid))
            .filter((answer) => !this.questionEffectsInteractor.isHidden(answer.questionUuid, answer.parentUuid));

        makeObservable(this);
    }

    private setAnswer(answer: Answer) {
        runInAction(() => {
            //Disabled validating v3_iterator_group, the modal already does this,
            //Also validating this would result in stale validations since we need to trigger on EVERY child update
            if (this.question.technicalReference !== TechnicalReference.REFERENCE_OBJECTS_V3_ITERATOR_GROUP) {
                this.validationMessages = this.answerValidator.validate(answer.questionUuid, answer.uuid);
            }

            if (
                this.answer === undefined &&
                this.adaptedDefaultValue !== undefined &&
                isEmpty(answer.contents) &&
                isEmpty(answer.answerOptionId)
            ) {
                this.answer = answer;

                const answerOption = this.question.answerOptions.find((option) => this.matchesAnswerOption(option));
                if (answerOption !== undefined) {
                    this.onAnswerOptionChange(answerOption.id);
                } else {
                    this.onChange(this.adaptedDefaultValue);
                }
            } else {
                this.answer = answer;
            }
            if (this.filledByAutomator === null && answer.filledByAutomator !== null) {
                this.filledByAutomator = answer.filledByAutomator;
            }
        });
    }

    public mount(): void {
        this._simpleQuestionPresenterSubscriptions.add(
            this.answerController
                .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid ?? null, this.iteration ?? null)
                .subscribe((answer) => {
                    runInAction(() => {
                        //Disabled validating v3_iterator_group, the modal already does this,
                        //Also validating this would result in stale validations since we need to trigger on EVERY child update
                        if (
                            this.question.technicalReference !== TechnicalReference.REFERENCE_OBJECTS_V3_ITERATOR_GROUP
                        ) {
                            this.validationMessages = this.answerValidator.validate(answer.questionUuid, answer.uuid);
                        }

                        this.setAnswer(answer);
                    });
                })
        );

        if (this.hasUniqueAnswerOptions) {
            this._simpleQuestionPresenterSubscriptions.add(
                this.answerController
                    .answersForQuestionUuidStream(this.question.uuid)
                    .pipe(
                        debounceTime(500),
                        map((answers) => this.answerController.filterDeleted(answers)),
                        map((answers) => {
                            const hiddenCache = {};
                            return answers.filter(
                                (answer) =>
                                    !this.questionEffectsInteractor.isHidden(
                                        answer.questionUuid,
                                        answer.parentUuid,
                                        hiddenCache
                                    )
                            );
                        }),
                        distinctUntilChanged()
                    )
                    .subscribe((answers) => {
                        runInAction(() => {
                            this._answersForQuestion = answers;
                        });
                    })
            );
        } else {
            this._answersForQuestion = [];
        }

        this._simpleQuestionPresenterSubscriptions.add(
            this.macroInteractor.macrosForQuestionUuid(this.question.uuid).subscribe((macros) => {
                runInAction(() => {
                    this.macros = macros;
                });
            })
        );

        this._simpleQuestionPresenterSubscriptions.add(
            this.searchInteractor
                .matchSearch(this.question.uuid, ({text}) => this.matchesSearch(text))
                .subscribe((text) => {
                    runInAction(() => {
                        this.searchText = text;
                    });
                })
        );

        this._simpleQuestionPresenterSubscriptions.add(
            this.questionEffectsInteractor
                .isHiddenStream(this.question.uuid, this.parentAnswerUuid ?? null)
                .subscribe((isHidden) => {
                    runInAction(() => {
                        this._isHidden = isHidden;
                    });
                })
        );

        this._simpleQuestionPresenterSubscriptions.add(
            this.duplicationSourcesProvider.stream(this.question).subscribe((sources) => {
                runInAction(() => {
                    this._duplicationSources = sources;
                });
            })
        );

        if (this.question.shouldStubChildren) {
            this.answerController
                .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid ?? null, this.iteration ?? null)
                .pipe(first())
                .subscribe((answer) => {
                    this.answerPathStubber.stubChildren(answer, this.adaptedDefaultValues ?? {});
                });
        }
    }

    public unmount(): void {
        this._simpleQuestionPresenterSubscriptions.clear();
    }

    @action
    public onDuplicationSourceChange(source: DuplicationSource) {
        this._duplicationSource = source;
    }

    public duplicateRecursively() {
        if (this.parentAnswerUuid && this.duplicationSource) {
            const res = this.duplicationSourcesProvider.duplicate(
                this.duplicationSource,
                this.parentAnswerUuid,
                this.question
            );

            if (res === false) {
                this.flashMessageBroadcaster.broadcast(
                    'Er is iets misgegaan tijdens het kopiëren van de gegevens.',
                    Type.Danger
                );
            }
        }
    }

    public clearRecursively() {
        if (this.parentAnswerUuid && this.answer) {
            const allAnswers = this.answerController.answers();
            const trees = buildQuestionAnswerTrees(this.questionSet, allAnswers, this.question);
            const thisAnswerTree = trees.find(
                (tree) => this.answer && tree.item.answer && tree.item.answer.uuid === this.answer.uuid
            );

            if (thisAnswerTree) {
                this.answerController.resetTree(thisAnswerTree);
            }
        }
    }

    public onDeleteClick() {
        if (this.answer === undefined) {
            throw new Error('Can not use undefined answer');
        }
        this.answerController.delete(this.answer.uuid);
    }

    public onRestoreClick() {
        if (this.answer === undefined) {
            throw new Error('Can not use undefined answer');
        }
        this.answerController.restore(this.answer.uuid);
    }

    @action
    public onChange(value: string, setTouchedState = true) {
        if (this.answer === undefined) {
            throw new Error('Can not use undefined answer');
        }
        this.filledByAutomator = null;
        this.answerController.onContentsChange(
            this.answer.uuid,
            value,
            setTouchedState ? AnswerTouchState.TOUCHED : AnswerTouchState.UNTOUCHED
        );
    }

    public onTouched() {
        if (this.answer === undefined) {
            throw new Error('Can not use undefined answer');
        }
        this.answerController.onTouchStateChange(this.answer.uuid, AnswerTouchState.TOUCHED);
    }

    @action
    public onMacroClick = (contents: string | null) => {
        if (this.answer === undefined) {
            throw new Error('Can not use undefined answer');
        }
        this.filledByAutomator = null;
        this.answerController.onContentsChange(
            this.answer.uuid,
            toggleMacro(contents, this.answer.contents),
            AnswerTouchState.TOUCHED
        );
    };

    @action
    public onAnswerOptionChange(answerOptionId: number | null) {
        if (this.answer === undefined) {
            throw new Error('Can not use undefined answer');
        }
        if (answerOptionId === -1) {
            throw new Error('Can not set a negative answer option id');
        }
        this.filledByAutomator = null;
        this.answerController.onAnswerOptionChange(this.answer.uuid, answerOptionId, AnswerTouchState.TOUCHED);
    }

    public onAddAsMacroClick = async () => {
        const answer = this.answer;
        if (answer !== undefined && answer.contents !== '' && answer.contents !== null) {
            try {
                await this.macroInteractor.store(answer.contents, this.question.uuid);
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };

    public onRemoveMacroClick = async (toBeRemovedMacro: Macro) => {
        if (this.macros !== undefined) {
            try {
                const macro = this.macros.find((m) => m.id === toBeRemovedMacro.id);
                if (macro !== undefined) {
                    if (!macro.isUserDefined) {
                        await this.macroInteractor.hideForUser(macro.id);
                    } else {
                        await this.macroInteractor.destroy(macro.id);
                    }
                }
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };

    public onFavoriteMacroClick = async (toBeFavoritedMacro: Macro) => {
        if (this.macros !== undefined) {
            try {
                const macro = this.macros.find((m) => m.id === toBeFavoritedMacro.id);
                if (macro !== undefined) {
                    await this.macroInteractor.toggleFavorite(macro.id);
                }
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };

    private matchesAnswerOption(option: AnswerOption) {
        return (
            option.contents.toLocaleLowerCase().trim() === this.adaptedDefaultValue?.toLocaleLowerCase().trim() ||
            (option.reportValue &&
                option.reportValue.toLocaleLowerCase().trim() === this.adaptedDefaultValue?.toLocaleLowerCase().trim())
        );
    }

    protected matchesSearch(search: string) {
        const words = search.toLowerCase().split(' ');
        const text = this.question.contents.toLowerCase();

        return words.every((w) => text.includes(w));
    }
}
