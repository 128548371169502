import * as React from 'react';

import {FunctionComponent} from 'react';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';
import {Textarea} from '../../../inputs/textarea';
import {getPositiveNegativeMacros} from '../../../../internal/positive_negative_macros';

interface OwnProps {
    referenceObjectAnswer: ReferenceObjectAnswer;
    onEfficiencyDifferenceExplanationChange: (efficiencyDifferenceExplanation: string) => void;
    isFrozen: boolean;
}

export const EfficiencyDifferenceExplanation: FunctionComponent<OwnProps> = ({
    referenceObjectAnswer,
    onEfficiencyDifferenceExplanationChange,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-sm-12">
                <Textarea
                    isRequired
                    label="Mate van doelmatigheid verschil toelichting"
                    name="input-efficiency-explanation"
                    value={validationInstituteReferentieObject.mateVanDoelmatigheidUitleg ?? ''}
                    onChange={onEfficiencyDifferenceExplanationChange}
                    macros={getPositiveNegativeMacros(
                        referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus,
                        [
                            'Het referentieobject is courant afgewerkt',
                            'Het referentieobject is moderner en couranter afgewerkt',
                            'Het referentieobject is minder gedateerd afgewerkt',
                        ],
                        [
                            'Het referentieobject is minder courant afgewerkt',
                            'Het referentieobject is minder modern en courant afgewerkt',
                            'Het referentieobject is meer gedateerd afgewerkt',
                        ]
                    )}
                    disabled={isFrozen}
                />
            </div>
        </div>
    );
};
