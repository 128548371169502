import {Answer} from '../../../models/answer';
import {AnswerRegistry} from './answer_registry';
import {Appraisal} from '../../../models/appraisal';
import {LocalStorage} from '../../../../persistence/drivers/local_storage';
import {Observable} from 'rxjs';

export class LocalStorageAnswerRegistry implements AnswerRegistry {
    private STORAGE_KEY = 'answers';

    public get list(): Answer[] {
        return this._registry.list;
    }

    public get stream(): Observable<Answer[]> {
        return this._registry.stream;
    }

    constructor(private _appraisal: Appraisal, private _registry: AnswerRegistry, private _localStorage: LocalStorage) {
        this.subscribeLocalStorage();
    }

    public delete(uuid: string): Answer | null {
        return this._registry.delete(uuid);
    }

    public deleteMultiple(uuids: string[]): Answer[] {
        return this._registry.deleteMultiple(uuids);
    }

    public restore(uuid: string): Answer | null {
        return this._registry.restore(uuid);
    }

    public filterDeleted(answers: Answer[]): Answer[] {
        return this._registry.filterDeleted(answers);
    }

    public getByQuestionUuid(questionUuid: string, parentAnswerUuid?: string): Answer[] {
        return this._registry.getByQuestionUuid(questionUuid, parentAnswerUuid);
    }

    public getByQuestionUuids(questionUuids: string[], parentAnswerUuid?: string): Answer[] {
        return this._registry.getByQuestionUuids(questionUuids, parentAnswerUuid);
    }

    public getByUuid(uuid: string): Answer | undefined {
        return this._registry.getByUuid(uuid);
    }

    public getByParentAnswerUuid(parentAnswerUuid: string | null): Answer[] {
        return this._registry.getByParentAnswerUuid(parentAnswerUuid);
    }

    public push(answer: Answer): Answer | null {
        return this._registry.push(answer);
    }

    public replace(answers: Answer[]): Answer[] {
        return this._registry.replace(answers);
    }

    public streamByQuestionUuid(questionUuid: string): Observable<Answer[]> {
        return this._registry.streamByQuestionUuid(questionUuid);
    }

    public streamByQuestionUuids(questionUuids: string[]): Observable<Answer[]> {
        return this._registry.streamByQuestionUuids(questionUuids);
    }

    public update(updatedAnswer: Answer) {
        return this._registry.update(updatedAnswer);
    }

    public updateMany(updatedAnswers: Answer[]): Answer[] {
        return this._registry.updateMany(updatedAnswers);
    }

    public get isPaused(): boolean {
        return this._registry.isPaused;
    }

    public pauseStreams(): void {
        return this._registry.pauseStreams();
    }

    public resumeStreams(): void {
        return this._registry.resumeStreams();
    }

    private subscribeLocalStorage() {
        const answersFromStorage = this._localStorage.getItem<Answer[]>(this.getStorageKey());
        if (answersFromStorage !== undefined) {
            this._registry.replace(
                answersFromStorage.map((answerFromStorage) => storedAnswerToAnswer(answerFromStorage))
            );
        }

        this._registry.stream.subscribe(() =>
            this._localStorage.setItem(
                this.getStorageKey(),
                this._registry.list.filter((answer) => answer.changed)
            )
        );
    }

    private getStorageKey() {
        return this._appraisal.id + '-' + this.STORAGE_KEY;
    }
}

export function storedAnswerToAnswer(answerFromStorage: Answer): Answer {
    return {
        ...answerFromStorage,
        createdAt: answerFromStorage.createdAt !== null ? new Date(answerFromStorage.createdAt) : null,
        updatedAt: answerFromStorage.updatedAt !== null ? new Date(answerFromStorage.updatedAt) : null,
    };
}
