import * as React from 'react';

import {BuildingCostsLabel} from './building_costs_labels';
import {observer} from 'mobx-react';

interface OwnProps {
    disabled: boolean;
    onChange: (value: string) => void;
    onBlur?: () => void;
    value: number;
    options: BuildingCostsLabel[];
}

@observer
export class BuildingCostsConditionSlider extends React.Component<OwnProps> {
    public render() {
        return (
            <div className="form-range-slider-container">
                <input
                    id="range"
                    name="range"
                    type="range"
                    min="1"
                    max="6"
                    value={this.props.value}
                    className="form-range form-range-condition"
                    onChange={(e) => this.props.onChange(e.target.value)}
                    onBlur={() => this.props.onBlur && this.props.onBlur()}
                    list="options"
                    disabled={this.props.disabled}
                />
                <span className="form-range-label-container">
                    {this.props.options.map((label) => {
                        return (
                            <span key={label.value} className="form-range-label">
                                {this.props.disabled ? (
                                    <label key={label.short} className="label-placeholder">
                                        &nbsp;
                                    </label>
                                ) : (
                                    label.short
                                )}
                            </span>
                        );
                    })}
                </span>
            </div>
        );
    }
}
