import * as loadImage from 'blueimp-load-image';

import {AttachmentProps} from '../../appraise/ui/content/questions/advanced/attachment_question_presenter';

import {ImageCompressor} from '../image_compressor';
import {UploadInteractor} from './upload_interactor';
import {bugsnagClient} from '../../../support/bugsnag_client';
import pTimeout from 'p-timeout';
import {AnswerController} from '../answering/answer_controller';
import {AnswerInteractor} from '../answering/answer_interactor';
import {AttachmentUploadInteractor, FileUploadInteractor, UploadResult} from './attachment_upload_interactor';
import {BlobCacheInteractor} from './blob_cache_interactor';
import {FlashMessageBroadcaster} from '../flash_message_broadcaster';

export class ImageUploadInteractor extends AttachmentUploadInteractor implements FileUploadInteractor {
    constructor(
        uploadInteractor: UploadInteractor,
        blobCacheInteractor: BlobCacheInteractor,
        answerController: AnswerController,
        answerInteractor: AnswerInteractor,
        flashMessageBroadcaster: FlashMessageBroadcaster,
        private imageCompressor: ImageCompressor
    ) {
        super(uploadInteractor, blobCacheInteractor, answerController, answerInteractor, flashMessageBroadcaster);
    }

    public override async uploadForAnswer(
        answerUuid: string,
        file: File,
        options: {
            fileTypes?: string[];
            contents?: AttachmentProps;
            progressCallback?: (progress: number) => void;
            preventSubmittingAnswers?: boolean;
        }
    ): Promise<UploadResult> {
        let compressedFile = file;
        try {
            if (file.type.includes('image/')) {
                compressedFile = await pTimeout(
                    this.imageCompressor.compress(file, {
                        quality: 0.8,
                        maxHeight: 1024,
                        maxWidth: 1024,
                        landscapeAspectRatio: 4 / 3,
                        portraitAspectRatio: 3 / 4,
                    }),
                    30 * 1000
                );
            }
        } catch (error) {
            console.error(error);
        }

        try {
            return super.uploadForAnswer(answerUuid, compressedFile, options);
        } catch (error) {
            console.error('image_upload_interactor', error);
            return {
                succeeded: false,
            };
        }
    }

    public override async upload(
        file: File,
        options: {
            fileTypes?: string[];
            progressCallback?: (progress: number) => void;
        }
    ): Promise<UploadResult> {
        let compressedFile = file;
        try {
            if (file.type.includes('image/')) {
                compressedFile = await pTimeout(
                    this.imageCompressor.compress(file, {
                        quality: 0.8,
                        maxHeight: 1024,
                        maxWidth: 1024,
                        landscapeAspectRatio: 4 / 3,
                        portraitAspectRatio: 3 / 4,
                    }),
                    10 * 1000
                );
            }
        } catch (error) {
            console.error(error);
        }

        try {
            return super.upload(compressedFile, options);
        } catch (error) {
            console.error('image_upload_interactor', error);
            return {
                succeeded: false,
            };
        }
    }

    protected override async getSyncedAttachmentProps(
        answerUuid: string,
        file: File,
        contents: AttachmentProps
    ): Promise<AttachmentProps> {
        return {
            ...(await super.getSyncedAttachmentProps(answerUuid, file, contents)),
            exif: contents.exif ? contents.exif : await this.getExifData(file),
        };
    }

    protected override async getFailedAttachmentProps(
        answerUuid: string,
        file: File,
        contents?: AttachmentProps
    ): Promise<AttachmentProps> {
        return {
            ...(await super.getFailedAttachmentProps(answerUuid, file)),
            exif: contents?.exif ? contents.exif : await this.getExifData(file),
        };
    }

    private getExifData(img: File | Blob): Promise<undefined | Record<string, unknown>> {
        return new Promise<undefined | Record<string, unknown>>((resolve) => {
            try {
                loadImage.parseMetaData(
                    img,
                    (data) => {
                        try {
                            if (data.exif === undefined) {
                                resolve(undefined);
                            } else {
                                const all = (
                                    data.exif as unknown as {getAll: () => Record<string, loadImage.ExifTagValue>}
                                ).getAll();

                                //Filter entries longer than 250 characters (MakerNote was 190000+ chars..)
                                const result: Record<string, loadImage.ExifTagValue> = {};
                                Object.entries(all).forEach(([key, value]) => {
                                    if (value !== undefined && String(value).length <= 250) {
                                        result[key] = value;
                                    }
                                });

                                resolve(result);
                            }
                        } catch (e) {
                            bugsnagClient?.notify(e);
                            resolve(undefined);
                        }
                    },
                    {
                        disableImageHead: true,
                    }
                );
            } catch (e) {
                bugsnagClient?.notify(e);
                resolve(undefined);
            }
        });
    }
}
