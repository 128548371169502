export function formatBuildingCostsReference(label: string) {
    // These labels should have a dash to split for new line
    switch (label) {
        case 'ElectrotechnischeInstallaties':
            return 'Electro-technische installaties';
        case 'Energieopwekking':
            return 'Energie-opwekking';
        case 'Verwarmingsinstallatie':
            return 'Verwarmings-installatie';
        case 'Verwarmingssysteem':
            return 'Verwarmings-systeem';
    }

    return formatLabel(label);
}

export function formatLabel(label: string) {
    // These labels should have a dash to split for new line
    let value = label.match(/[A-Z]*[a-z0-9()]+/g)?.join(' ') || '';

    // For labels which only contain uppercase letters
    if (value === '') {
        value = label;
    }
    const firstLetter = value.charAt(0).toUpperCase();
    const other = value.slice(1).toLowerCase();
    return firstLetter + other;
}
