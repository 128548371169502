import {Observable, merge} from 'rxjs';
import {debounceTime, map, startWith} from 'rxjs/operators';

import {AnswerController} from '../answering/answer_controller';
import {AppraisalProvider} from '../appraisal_provider';
import {QuestionSet} from '../../models/question_set';
import {ValidationMessage} from './validation_message';
import {QuestionValidator} from './question_validator';

export interface QuestionValidationProvider {
    validate(questionUuid: string, answerUuid: string | null, debounce?: number): Observable<ValidationMessage[]>;
}

export class DefaultQuestionValidationProvider implements QuestionValidationProvider {
    constructor(
        private questionSet: QuestionSet,
        private questionValidator: QuestionValidator,
        private answerController: AnswerController,
        private appraisalProvider: AppraisalProvider
    ) {}

    public validate(questionUuid: string, answerUuid: string | null, debounce = 100): Observable<ValidationMessage[]> {
        return merge(
            this.answerController.answersForQuestionUuidStream(questionUuid),
            this.appraisalProvider.stream
        ).pipe(
            debounceTime(debounce),
            map(() => this.questionValidator.validate(questionUuid)),
            startWith(this.questionValidator.validate(questionUuid))
        );
    }
}
