import * as React from 'react';
import {observer} from 'mobx-react';
import {usePresenter} from '../support/use_presenter';
import {Component} from '../support/component';
import {ControllerData, AppraisalControllerFormPresenter} from './appraisal_controller_form_presenter';
import {SalutationType} from '../../appraising/enum/salutation_type';
import {classNames} from '../../support/classnames';

interface OwnProps {
    salutation: SalutationType | null;
    initials: string | null;
    firstName: string | null;
    lastName: string | null;
    companyName: string | null;
    city: string | null;
    email: string | null;
}

export const AppraisalControllerForm: React.FC<OwnProps> = observer(function AppraisalControllerForm(props) {
    const presenter = usePresenter(
        (container: Component) =>
            new AppraisalControllerFormPresenter(
                props.salutation,
                props.initials,
                props.firstName,
                props.lastName,
                props.companyName,
                props.city,
                props.email,
                container.controllerAppraiserApi
            )
    );

    function renderInput(field: keyof ControllerData, fieldName: string) {
        return (
            <div className="input-group">
                <input
                    className="form-control pl-3"
                    type="text"
                    id={fieldName}
                    name={fieldName}
                    value={presenter.controllerData[field] ?? ''}
                    disabled={presenter.saving || (field !== 'email' && presenter.isDetailFieldsDisabled)}
                    onChange={(e) => presenter.onChange(field, e.target.value)}
                    onBlur={field === 'email' ? (e) => presenter.onRequestData(e.target.value) : () => null}
                />
                {field === 'email' && (
                    <div className="input-group-append">
                        <button
                            type="button"
                            className="btn btn-secondary btn-append"
                            disabled={!presenter.isDetailFieldsDisabled}
                            onClick={() => presenter.onRequestData(presenter.controllerData?.email ?? null)}
                        >
                            <span className="ion-md-checkmark"></span>
                        </button>
                    </div>
                )}
            </div>
        );
    }

    function renderSalutation(field: keyof ControllerData, fieldName: string) {
        return (
            <div className="input-group">
                <select
                    className="form-control custom-select"
                    value={presenter.controllerData[field] ?? ''}
                    id={fieldName}
                    name={fieldName}
                    disabled={presenter.saving || presenter.isDetailFieldsDisabled}
                    onChange={(e) => presenter.onChange(field, e.target.value)}
                >
                    {Object.values(SalutationType).map((val) => {
                        return (
                            <option value={val} key={val}>
                                {val}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }

    const fields = {
        email: ['E-mail', 'controller_email'],
        salutation: ['Aanhef', 'controller_saluation'],
        initials: ['Initialen', 'controller_initials'],
        lastName: ['Achternaam', 'controller_last_name'],
        companyName: ['Taxatiebedrijf', 'controller_company_name'],
        city: ['Vestigingslocatie', 'controller_city'],
    };

    return (
        <>
            <div className="row">
                {Object.entries(fields).map((field) => {
                    return (
                        <div
                            className={classNames(
                                'col-12',
                                {'col-md-6': field[0] !== 'email'},
                                {'d-none': field[0] !== 'email' && presenter.isDetailFieldsDisabled}
                            )}
                            key={field[0]}
                        >
                            <div className="form-group">
                                <label htmlFor={'controller-' + field[0]}>
                                    <span>{field[1][0]}</span>
                                    {presenter.requiredFieldNames.includes(field[0]) && (
                                        <>
                                            &nbsp;<span className="form-label-highlight">*</span>
                                        </>
                                    )}
                                </label>
                                {field[0] === 'salutation'
                                    ? renderSalutation(field[0] as keyof ControllerData, field[1][1])
                                    : renderInput(field[0] as keyof ControllerData, field[1][1])}
                                {presenter.emptyFields.includes(field[0]) && (
                                    <span className="invalid-feedback">Dit is een verplicht veld</span>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            {presenter.errorMessage && (
                <div className="row">
                    <div className="col-12">
                        <div className="alert-inline alert-danger ion-md-alert">
                            <p>{presenter.errorMessage}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});
