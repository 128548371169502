import * as React from 'react';

import {NormalQuestionType, QuestionType} from '../../../enum/question_type';
import {StatusLabel, StatusLabelType} from '../container/status_label';

import {Appraisal} from '../../../models/appraisal';
import {Modal} from './questions/components/modal';
import {Question} from '../../../models/question';
import {QuestionContainer} from './questions/question_container';
import {QuestionSet} from '../../../models/question_set';
import {RenderingContextType} from '../../../enum/rendering_context_type';
import {ValidationError} from '../../../models/validation_error';
import {ValidationMessageMap} from '../../../business/validation/validation_message';
import {findChildrenForQuestion} from '../support/question_filtering';

export interface DetailModalData {
    photoObjectUrl: string;
    parentAnswerUuid: string;
    photoQuestion: Question;
    photoRecognized: boolean;
}

interface OwnProps {
    detailsModalData: DetailModalData;
    isDisabled: boolean;
    onClose: () => void;
    questionSet: QuestionSet;
    appraisal: Appraisal;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    hiddenQuestionTypes: QuestionType[];
    children?: React.ReactNode;
    renderingContext: RenderingContextType;
}

export class PhotoDetailsModal extends React.Component<OwnProps> {
    public render() {
        const {
            detailsModalData,
            isDisabled,
            onClose,
            questionSet,
            appraisal,
            validationErrors,
            validationMessages,
            hiddenQuestionTypes,
            forceShowValidationMessages,
        } = this.props;

        return (
            <Modal visible forced>
                <div className="modal-header">
                    <h2>Details van de foto</h2>
                </div>
                <div className="modal-body">
                    <img
                        className="w-100 mb-3"
                        style={{maxHeight: 200, objectFit: 'contain'}}
                        src={detailsModalData.photoObjectUrl}
                    />
                    {detailsModalData.photoRecognized ? (
                        <div className="row">
                            <div className="col-12">
                                <div className="alert-inline alert-info ion-md-information-circle">
                                    <StatusLabel type={StatusLabelType.Beta} content="Bèta" /> Op basis van onze
                                    fotoherkenning hebben we waarschijnlijk herkend wat de foto weergeeft. Dit is alvast
                                    ingevuld.
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <p>Geef aan wat er op de foto te zien is.</p>
                    <div className="modal-answer-columns">
                        {findChildrenForQuestion(detailsModalData.photoQuestion, questionSet).map((child) => {
                            //Transforming type to a MC that shows all the options, since this is a "quick choice" modal
                            if (child.type === NormalQuestionType.MC_SELECT) {
                                child = {
                                    ...child,
                                    type: NormalQuestionType.MC,
                                };
                            }
                            return (
                                <QuestionContainer
                                    key={child.uuid}
                                    appraisal={appraisal}
                                    question={child}
                                    questionSet={questionSet}
                                    validationErrors={validationErrors}
                                    validationMessages={validationMessages}
                                    forceShowValidationMessages={forceShowValidationMessages}
                                    parentAnswerUuid={detailsModalData.parentAnswerUuid}
                                    hiddenQuestionTypes={hiddenQuestionTypes}
                                    pagePartsSet={null}
                                    activePagePart={null}
                                    renderingContext={this.props.renderingContext}
                                    questionRenderingData={null}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="modal-footer">
                    {this.props.children}
                    <button
                        className="btn btn-primary btn-full ion-md-arrow-forward"
                        type="button"
                        disabled={isDisabled}
                        onClick={() => onClose()}
                    >
                        Opslaan
                    </button>
                </div>
            </Modal>
        );
    }
}
