import React, {useMemo} from 'react';
import {formatMoney} from '../../../appraising/appraise/ui/support/format_money';

interface OwnProps {
    values: number[];
}

const getPinPosition = (value: number, barStart: number, barSize: number) => {
    return ((value - barStart) / barSize) * 100;
};

const getPinMargin = (values: number[], index: number, barStart: number, barSize: number): number => {
    if (index === 0) {
        return -5;
    }

    const pos = getPinPosition(values[index], barStart, barSize);
    const prevPos = getPinPosition(values[index - 1], barStart, barSize);

    if (pos - prevPos < 10) {
        return 15 + getPinMargin(values, index - 1, barStart, barSize);
    }

    return -5;
};

export const ValuationLine: React.FC<OwnProps> = ({values}) => {
    const [lower, upper, barStart, barEnd, barSize, sortedValues] = useMemo(() => {
        const Z = 1.65; // Z-score for 90% confidence interval

        // Calculate confidence interval based on Z and values
        const mean = values.reduce((a, b) => a + b, 0) / values.length;
        const std = Math.sqrt(values.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / values.length);
        const lower = Math.round(mean - Z * std);
        const upper = Math.round(mean + Z * std);
        const size = upper - lower;

        const barStart = Math.floor((lower - size * 0.3) / 10000) * 10000;
        const barEnd = Math.ceil((upper + size * 0.3) / 10000) * 10000;
        const barSize = barEnd - barStart;

        return [lower, upper, barStart, barEnd, barSize, values.sort()];
    }, [values]);

    return (
        <>
            <h3>Geschatte waarde</h3>
            <div className="valuation-bar">
                <div style={{width: `${((lower - barStart) / barSize) * 100}%`}} />
                <div className="valuation-bar-interval" style={{width: `${((upper - lower) / barSize) * 100}%`}} />
                <div style={{width: `${((barEnd - upper) / barSize) * 100}%`}} />
                {sortedValues.map((value, index) => (
                    <div
                        key={index}
                        className="valuation-bar-marker"
                        style={{left: `${getPinPosition(value, barStart, barSize)}%`}}
                    >
                        <div className="valuation-bar-marker-container">
                            <span
                                className="valuation-bar-value"
                                style={{
                                    marginBottom: getPinMargin(sortedValues, index, barStart, barSize),
                                }}
                            >
                                {formatMoney(value)}
                            </span>
                            <span className="ion-md-pin" />
                        </div>
                    </div>
                ))}
                <div className="valuation-bar-labels">
                    <span className="valuation-bar-label">{formatMoney(barStart)}</span>
                    <span className="valuation-bar-label">{formatMoney(barEnd)}</span>
                </div>
            </div>
        </>
    );
};
