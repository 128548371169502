import {CadastralPlotAddress, CadastralPlotAddressType} from '../../models/cadastral_plot_address';

export interface ApiCadastralPlotAddress {
    address: string | null;
    number: string | null;
    number_addition: string | null;
    letter: string | null;
    postal_code: string | null;
    city: string | null;
    municipal: string | null;
    cadastral_section: string | null;
    cadastral_plot_number: string | null;
    apartment_rights_index: string | null;
    identification_type: string | null;
    plot_type: CadastralPlotAddressType | null;
    plot_type_text: string | null;
}

export function apiCadastralPlotAddressToCadastralPlotAddress(
    apiCadastralPlotAddress: ApiCadastralPlotAddress
): CadastralPlotAddress {
    return {
        address: apiCadastralPlotAddress.address,
        number: apiCadastralPlotAddress.number,
        numberAddition: apiCadastralPlotAddress.number_addition,
        letter: apiCadastralPlotAddress.letter,
        postalCode: apiCadastralPlotAddress.postal_code,
        city: apiCadastralPlotAddress.city,
        municipal: apiCadastralPlotAddress.municipal,
        cadastralSection: apiCadastralPlotAddress.cadastral_section,
        cadastralPlotNumber: apiCadastralPlotAddress.cadastral_plot_number,
        apartmentRightsIndex: apiCadastralPlotAddress.apartment_rights_index,
        identificationType: apiCadastralPlotAddress.identification_type,
        plotType: apiCadastralPlotAddress.plot_type,
        plotTypeText: apiCadastralPlotAddress.plot_type_text,
    };
}
