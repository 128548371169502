import {BehaviorSubject, Observable} from 'rxjs';
import {PagePartConfiguration} from '../../models/page_part_configuration';

export interface PagePartConfigurationsInteractor {
    stream: Observable<Map<number, PagePartConfiguration> | null>;
    pagePartConfigurations: Map<number, PagePartConfiguration>;

    onChange(pagePartConfigurations: PagePartConfiguration[] | Map<number, PagePartConfiguration>): void;
    upsertConfiguration(pagePartConfiguration: PagePartConfiguration): PagePartConfiguration;
    removeConfiguration(id: number): void;
}

export class DefaultPagePartConfigurationsInteractor implements PagePartConfigurationsInteractor {
    public stream = new BehaviorSubject<Map<number, PagePartConfiguration> | null>(null);

    public get pagePartConfigurations(): Map<number, PagePartConfiguration> {
        const value = this.stream.value;
        if (value === null) {
            throw new Error('PagePartConfiguration provider called before initialization');
        }
        return value;
    }

    private toMap(pagePartConfigurations: PagePartConfiguration[]) {
        const map = new Map<number, PagePartConfiguration>();
        for (const config of pagePartConfigurations) {
            map.set(config.id, config);
        }
        return map;
    }

    public onChange(pagePartConfigurations: PagePartConfiguration[] | Map<number, PagePartConfiguration>): void {
        if (Array.isArray(pagePartConfigurations)) {
            this.stream.next(this.toMap(pagePartConfigurations));
        } else {
            this.stream.next(pagePartConfigurations);
        }
    }

    public upsertConfiguration(pagePartConfiguration: PagePartConfiguration): PagePartConfiguration {
        const newMap = new Map(this.stream.value);
        newMap.set(pagePartConfiguration.id, pagePartConfiguration);
        this.stream.next(newMap);
        return pagePartConfiguration;
    }

    public removeConfiguration(id: number): void {
        const newMap = new Map(this.stream.value);
        newMap.delete(id);
        this.stream.next(newMap);
    }
}
