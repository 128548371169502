import * as React from 'react';

import {AnswerAuditTrailItem} from '../../../../models/answer_audit_trail_item';
import {AnswerTrailList} from '../../components/answer_trail_list';
import {QuestionType} from '../../../../enum/question_type';

export interface AnswerTrailWidgetProps {
    auditTrailItems: AnswerAuditTrailItem[][];
    isLoading: boolean;
    isDisabled: boolean;
    questionType: QuestionType;
    onHistoricAnswerClick: (items: AnswerAuditTrailItem[]) => void;
    getAnswerContents: (item: AnswerAuditTrailItem) => string | null;
    onClose: () => void;
}

export class AnswerTrailWidget extends React.Component<AnswerTrailWidgetProps> {
    public render() {
        return (
            <div className="widget-overlay">
                <div className="widget-overlay-container">
                    <div className="widget-overlay-title">
                        <span className="widget-overlay-title-icon ion-md-timer">&nbsp;</span>
                        <h2>Antwoord geschiedenis</h2>
                        <button className="widget-overlay-close" onClick={() => this.props.onClose()}>
                            <span className="ion-md-close">&nbsp;</span>
                        </button>
                    </div>
                    <p>Overzicht van antwoorden. Selecteer een antwoord om deze te herstellen.</p>

                    <AnswerTrailList
                        auditTrailItems={this.props.auditTrailItems}
                        onHistoricAnswerClick={this.props.onHistoricAnswerClick}
                        isLoading={this.props.isLoading}
                        isDisabled={this.props.isDisabled}
                        questionType={this.props.questionType}
                        getAnswerContents={this.props.getAnswerContents}
                    />
                </div>
            </div>
        );
    }
}
