import {AjaxDriver} from '../../network/driver/ajax_driver';
import {FetchDriver} from '../../network/driver/fetch_driver';
import {MacroInteractor} from '../interactors/macro_interactor';
import {MacroApi} from '../network/macro_api';

export interface Component {
    macroApi: MacroApi;
    macroInteractor: MacroInteractor;
}

class ComponentImpl implements Component {
    private ajaxDriver: AjaxDriver = new FetchDriver();
    private _macroApi: MacroApi | undefined;
    private _macroInteractor: MacroInteractor | undefined;

    public get macroApi(): MacroApi {
        return (this._macroApi ??= new MacroApi(this.ajaxDriver));
    }

    public get macroInteractor(): MacroInteractor {
        return (this._macroInteractor ??= new MacroInteractor(this.macroApi));
    }
}

let instance: Component;

export function component(): Component {
    if (instance === undefined) {
        instance = new ComponentImpl();
    }

    return instance;
}
