import {GenericErrorsValidator} from '../generic_errors_validator';
import {isEmpty} from '../../../../support/util';
import {QuestionSet} from '../../../models/question_set';
import {AppraisalProvider} from '../../appraisal_provider';
import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';
import {AppraisalValidationType} from '../../../enum/appraisal_validation_type';

export class ValuationDateValidator implements GenericErrorsValidator {
    constructor(private questionSet: QuestionSet, private appraisalProvider: AppraisalProvider) {}

    public validate(): ValidationMessage[] {
        if (!this.questionSet.reportDefintionConfig.opnameDatumOutsideOfQuestionSet) {
            return [];
        }
        const appraisal = this.appraisalProvider.appraisal;

        if (isEmpty(appraisal.valuationDate)) {
            return this.createMessage(
                'Algemene validatiefouten',
                'De opnamedatum dient ingevuld zijn om het rapport te voltooien.',
                ValidationMessageImportance.ERROR
            );
        }

        // E.g. 15-12-2022
        if (appraisal.valuationDate.length <= 10 && appraisal.validationType === AppraisalValidationType.VALIDATED) {
            return this.createMessage(
                'Algemene validatiefouten',
                'Let op: er is geen tijd van de opname ingevuld.',
                ValidationMessageImportance.WARNING
            );
        }

        return [];
    }

    private createMessage(title: string, message: string, importance: ValidationMessageImportance) {
        return [
            {
                type: ValidationMessageType.TAXAPI,
                importance: importance,
                question: null,
                answer: null,
                messages: [],
                title: title,
                fallbackMessage: message,
                floorInfo: null,
            },
        ];
    }
}
