import {Observable} from 'rxjs';
import {AjaxDriver} from '../../network/driver/ajax_driver';
import {TaskReference} from '../models/task_reference';

interface Task<T> {
    status: string;
    result: string;
    chain_id?: number | null;
    next_id?: number | null;
    job_result: T | null;
}

export class TaskHelper {
    constructor(private ajaxDriver: AjaxDriver) {}

    public static isTaskReference(data: unknown): data is TaskReference {
        return (data as {[key: string]: unknown}).taskId !== undefined;
    }

    public async poll<T>(taskId: number, chain = false): Promise<T | null> {
        let response: Task<T> | null = null;
        do {
            await this.wait();
            response = (await this.fetch(taskId, chain)) as Task<T>;
        } while (response !== null && response.status !== 'finished');

        if (response === null || response.result !== 'finished') {
            throw new Error('Task failed');
        }

        if (response.chain_id) {
            return this.poll(response.chain_id, true);
        }

        if (response.next_id) {
            return this.poll(response.next_id);
        }

        return response.job_result;
    }

    public stream<T>(taskId: number): Observable<T> {
        return new Observable<T>((subscriber) => {
            const es = new EventSource(`/ajax/tasks/${taskId}/stream`);

            es.addEventListener('json-message', (ev) => {
                try {
                    const data = JSON.parse(ev.data);
                    subscriber.next(data);
                } catch (e) {
                    subscriber.error(e);
                    es.close();
                }
            });

            es.addEventListener('stop-message', () => {
                subscriber.complete();
                es.close();
            });

            es.addEventListener('error', (ev) => {
                subscriber.error(ev);
                es.close();
            });

            return () => {
                es.close();
            };
        });
    }

    private fetch(taskId: number, chain = false): Promise<unknown> {
        return new Promise<unknown>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/tasks/${taskId}/${chain ? 'chain' : 'status'}`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(await result.json());
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    private wait() {
        return new Promise((resolve) => setTimeout(resolve, 2000));
    }
}
