import $ from 'jquery';

/**
 * Bit 'n pieces to change elements based on selection
 */
(() => {
    const addressOutOfRangeNote = $('#form-create-appraisal-address-out-of-range');
    const addressOutOfRange = $('#address_out_of_range');
    const notValidatedNote = $('#form-create-appraisal-not-validated-note');
    const mockValidationNote = $('#form-create-appraisal-mock-validation-note');
    const addressNotFoundNote = $('#form-create-appraisal-address-not-found');
    const addressNotFound = $('#address_not_found');
    const addressPreviouslyAppraisedNote = $('#form-create-appraisal-address-previously-appraised');
    const addressPreviouslyAppraised = $('#address_previously_appraised');

    if (mockValidationNote.length > 0 || notValidatedNote.length > 0) {
        $('[name="validation_type"]').on('change', function (source) {
            if (mockValidationNote.length > 0) {
                showOnValue(source, mockValidationNote, 'validated');
            }
            if (notValidatedNote.length > 0) {
                showOnValue(source, notValidatedNote, 'not-validated');
            }
            if ($(source.target).val() !== 'not-validated') {
                addressOutOfRangeNote.hide();
                if (addressNotFound.val() === 'true') {
                    addressNotFoundNote.show();
                } else {
                    addressNotFoundNote.hide();
                }
            } else {
                if (addressOutOfRange.val() === 'true') {
                    addressOutOfRangeNote.show();
                    addressNotFoundNote.hide();
                } else {
                    addressOutOfRangeNote.hide();
                }
                if (addressNotFound.val() === 'true') {
                    addressNotFoundNote.hide();
                }
            }
        });
    }

    addressOutOfRange.on('change', function () {
        if (addressOutOfRange.val() === 'true') {
            if ($('[name="validation_type"]:checked').val() === 'not-validated') {
                addressOutOfRangeNote.show();
                addressNotFoundNote.hide();
            } else {
                addressOutOfRangeNote.hide();
            }
        }
    });
    addressPreviouslyAppraised.on('change', function () {
        if (addressPreviouslyAppraised.val() === 'true') {
            addressPreviouslyAppraisedNote.show();
        } else {
            addressPreviouslyAppraisedNote.hide();
        }
    });

    addressNotFound.on('change', function () {
        if (addressNotFound.val() === 'true') {
            addressNotFoundNote.show();
            addressOutOfRangeNote.hide();
        } else {
            addressNotFoundNote.hide();
        }
    });

    const referenceDate = $('#form-create-appraisal-reference-date');
    if (referenceDate.length > 0) {
        $('[name="has_reference_date"]').on('change', function (source) {
            showOnValue(source, referenceDate, '1');
        });
    }

    // Professional clients
    const professionalClients = $('#form-create-appraisal-rights-professional-client');
    if (professionalClients.length > 0) {
        $('input[name="client_type"]').on('change', function (source) {
            const isVisible = showOnValue(source, professionalClients, 'company');
            const labels = $('.form-label-highlight-toggle');
            labels.removeClass('d-none');
            if (isVisible) {
                labels.addClass('d-none');
            }
            makeRequiredOnValue(isVisible, ['client_company_name', 'client_coc'], professionalClients);
            const clientFields = $('.form-client-type-field');
            clientFields.show();
            if (isVisible) {
                clientFields.hide();
            }

            const isConsumer = $(source.target).val() === 'consumer';
            if (isConsumer) {
                $('#secondary-client-form, #secondary-client-form-button').css('display', '');
            } else {
                $('#secondary-client-form input, #secondary-client-form select').val('');
                $('#secondary-client-form, #secondary-client-form-button').hide();
            }
        });
        $('input[name="is_woco"]').on('change', function (source) {
            const result = showOnValue(source, professionalClients, '1');
            const labels = $('.form-label-highlight-toggle');
            labels.removeClass('d-none');
            if (result) {
                labels.addClass('d-none');
            }
            const wocoLender = $('#form-create-appraisal-woco-lender');
            const clientFields = $('.form-client-type-field');
            wocoLender.hide();
            clientFields.show();
            if (result) {
                wocoLender.show();
                clientFields.hide();
            }
        });
    }

    // Client type
    const clientType = $('#form-create-appraisal-client-type');
    if (clientType.length > 0) {
        $('input[name="is_woco"]').on('change', function (source) {
            showOnValue(source, clientType, '0');
        });
    }

    // Secondary client address
    const secondaryClientAddress = $('#form-create-appraisal-rights-secondary-client-address');
    if (secondaryClientAddress.length > 0) {
        const update = (source) => {
            const isVisible = showOnValue(source, secondaryClientAddress, '1');
            makeRequiredOnValue(
                isVisible,
                [
                    'secondary_client_country',
                    'secondary_client_postal_code',
                    'secondary_client_number',
                    'secondary_client_street',
                    'secondary_client_city',
                ],
                $('secondary-client-form')
            );
        };

        $('input[name="secondary_client_different_address"]').on('change', function (source) {
            update(source);
        });

        update({target: $('input[name="secondary_client_different_address"]:checked')});
    }

    // Select extended form for appraisal goal
    const goalNotes = $('#form-create-appraisal-goal-notes');
    if (goalNotes.length > 0) {
        $('[name="appraisal_goal"]').on('change', function (source) {
            showOnValue(source, goalNotes, 'other');
        });
    }

    // These fields should be hidden when the appraisal goal is not finance
    const financialGoalFields = $('.form-create-appraisal-finance-related');
    if (financialGoalFields.length > 0) {
        $('[name="appraisal_goal"]').on('change', function (source) {
            for (const financialGoalField of financialGoalFields) {
                showOnValue(source, $(financialGoalField), 'acquire_mortage');
            }

            if ($(source.target).val() !== 'acquire_mortage') {
                $('#recently-sold-no').attr('checked', true);
                $('#improvements-planned-no').attr('checked', true);
            }
        });

        if ($('[name="appraisal_goal"]').val() !== 'acquire_mortage') {
            $('#recently-sold-no').attr('checked', true);
            $('#improvements-planned-no').attr('checked', true);
        }
    }

    // These fields should be hidden when the appraisal goal is not finance
    const otherValueFields = $('.form-create-appraisal-other-value-related');
    if (otherValueFields.length > 0) {
        if ($('[name="appraisal_goal"]').val() === 'forced_sale') {
            $(otherValueFields).hide();
        }

        $('[name="appraisal_goal"]').on('change', function (source) {
            for (const financialGoalField of financialGoalFields) {
                hideOnValue(source, $(otherValueFields), 'forced_sale');
            }

            if ($(source.target).val() === 'forced_sale') {
                $('#other-value-concepts-no').attr('checked', true);
            }
        });
    }

    // Select extended form for other value concepts
    const otherValueConceptsNotes = $('#form-create-appraisal-other-value-concepts-notes');
    if (otherValueConceptsNotes.length > 0) {
        $('input[name="other_value_concepts"]').on('change', function (source) {
            showOnValue(source, otherValueConceptsNotes, '1');
        });
    }

    // Select extended form for recently sold
    const recentlySoldAgreements = $('#form-create-appraisal-recently-sold-agreements');
    if (recentlySoldAgreements.length > 0) {
        $('input[name="recently_sold"]').on('change', function (source) {
            showOnValue(source, recentlySoldAgreements, '1');
        });
    }

    // Select extended form for recently sold agreements
    const recentlySoldNotes = $('#form-create-appraisal-recently-sold-agreements-notes');
    if (recentlySoldNotes.length > 0) {
        $('input[name="recently_sold_agreements"]').on('change', function (source) {
            showOnValue(source, recentlySoldNotes, '1');
        });
    }

    // Select extended form for ownership type
    const objectOwnership = $('#form-create-appraisal-object-ownership-type-notes');
    if (objectOwnership.length > 0) {
        $('select[name="object_ownership_type"]').on('change', function (source) {
            showOnValue(source, objectOwnership, 'Anders');
        });
    }

    // Hide extended form for ownership type after new usage type selection
    $('select[name="object_usage_type"][data-edit="true"]').on('change', function (e) {
        // Delay until object_ownership_type options are updated
        setTimeout(() => {
            showOnValue($('select[name="object_ownership_type"]'), objectOwnership, 'Anders');
        }, 0);
    });

    // Rent contract
    const rentContract = $('#form-create-appraisal-has-rent-contract');
    if (rentContract.length > 0) {
        $('select[name="object_ownership_type"]').on('change', function (source) {
            const objectOwnershipOptions = [
                'Eigenaar en verhuurder',
                'Eigenaar en wil gaan verhuren',
                'Koper voor verhuur',
                'Anders',
            ];
            if (objectOwnershipOptions.includes($(source.target).val())) {
                rentContract.show();
            } else {
                rentContract.hide();
            }
        });
        $('select[name="object_usage_type"]').on('change', function (source) {
            const objectUsageOptions = ['Verhuurd', 'Per kamer verhuurd', 'Deels verhuurd en deels leeg'];
            if (rentContract.data('check-object-usage-type')) {
                objectUsageOptions.push('Op onbekende wijze in gebruik');
            }
            if (objectUsageOptions.includes($(source.target).val())) {
                rentContract.show();
            } else {
                rentContract.hide();
            }
        });
    }

    // Select extended form for payment methods
    const paymentMethods = $('#form-create-appraisal-invoice-flow-type');
    if (paymentMethods.length > 0) {
        const select = $('select[name="invoice_flow"]');
        showPaymentMethodDetails(select.val());
        changePaymentMomentOptions(select.val());
        changePaymentDays(select.val());

        select.on('change', function (input) {
            showPaymentMethodDetails($(input.target).val());
            changePaymentMomentOptions($(input.target).val());
            changePaymentDays($(input.target).val());
        });

        function showPaymentMethodDetails(val) {
            $(paymentMethods).find('.alert-hint-options').addClass('d-none');
            $(paymentMethods)
                .find('.alert-hint-options.' + val)
                .removeClass('d-none');

            const paymentMethodsForm = $('#form-create-appraisal-invoice-flow-type-details');
            const invoiceDetailsForm = $('#form-create-appraisal-invoice-details');

            paymentMethodsForm.hide();
            invoiceDetailsForm.hide();

            if (val === 'notary' || val === 'deferred') {
                paymentMethodsForm.show();
            }
            const notaryFullNameSelector = $('#notary_full_name');
            if (val === 'deferred') {
                notaryFullNameSelector.children("option[value='Nader te bepalen']").remove();
                notaryFullNameSelector.selectpicker('refresh');
            }
            if (val === 'notary' && notaryFullNameSelector.children("option[value='Nader te bepalen']").length === 0) {
                notaryFullNameSelector.prepend(
                    '<option value="Nader te bepalen" selected="">Nader te bepalen</option>'
                );
                notaryFullNameSelector.selectpicker('refresh');
            }
            if (val === 'appraiser') {
                invoiceDetailsForm.show();
            }
        }

        function changePaymentMomentOptions(val) {
            const paymentMomentSelector = $('select[name="payment_moment"]');

            if (paymentMomentSelector.length === 0 || paymentMomentSelector?.data('fill-invoice-flow') === undefined) {
                return;
            }

            const options = [
                {value: 'acceptance', name: 'Op het moment dat de opdrachtgever de taxatieopdracht accepteert'},
                {
                    value: 'before_sending',
                    name: 'Voordat het taxatierapport naar de opdrachtgever wordt toegestuurd',
                },
                {
                    value: 'after_sending',
                    name: 'Uiterlijk x aantal dagen nadat het taxatierapport naar de opdrachtgever is verstuurd',
                },
                {value: 'notary', name: 'Via de notaris'},
            ];

            if (val === 'acceptance' || val === 'client') {
                paymentMomentSelector.children("option[value='notary']").remove();
                paymentMomentSelector.children("option[value='after_sending']").remove();
                paymentMomentSelector.selectpicker('refresh');
            } else if (val === 'appraiser') {
                paymentMomentSelector.children("option[value='notary']").remove();
                paymentMomentSelector.children("option[value='before_sending']").remove();
                paymentMomentSelector.children("option[value='acceptance']").remove();
                for (const option of options.filter((option) => option.value === 'after_sending')) {
                    if (paymentMomentSelector.children(`option[value='${option.value}']`).length === 0) {
                        paymentMomentSelector.prepend(
                            `<option value="${option.value}" selected="">${option.name}</option>`
                        );
                    }
                }
                paymentMomentSelector.selectpicker('refresh');
            } else if (val === 'notary') {
                paymentMomentSelector.children().remove();
                for (const option of options.filter((option) => option.value === 'notary')) {
                    if (paymentMomentSelector.children(`option[value='${option.value}']`).length === 0) {
                        paymentMomentSelector.prepend(
                            `<option value="${option.value}" selected="">${option.name}</option>`
                        );
                    }
                }
                paymentMomentSelector.selectpicker('refresh');
            } else {
                for (const option of options) {
                    if (paymentMomentSelector.children(`option[value='${option.value}']`).length === 0) {
                        paymentMomentSelector.prepend(
                            `<option value="${option.value}" selected="">${option.name}</option>`
                        );
                    }
                }
                paymentMomentSelector.selectpicker('refresh');
            }

            switch (val) {
                case 'acceptance':
                case 'client':
                    for (const option of options) {
                        if (option.value === 'notary' || option.value === 'after_sending') {
                            paymentMomentSelector.children(`option[value='${option.value}']`).remove();
                        } else if (paymentMomentSelector.children(`option[value='${option.value}']`).length === 0) {
                            paymentMomentSelector.prepend(`<option value="${option.value}">${option.name}</option>`);
                        }
                    }
                    paymentMomentSelector.selectpicker('refresh');
                    break;
                case 'appraiser':
                    for (const option of options) {
                        if (
                            option.value === 'notary' ||
                            option.value === 'before_sending' ||
                            option.value === 'acceptance'
                        ) {
                            paymentMomentSelector.children(`option[value='${option.value}']`).remove();
                        } else if (paymentMomentSelector.children(`option[value='${option.value}']`).length === 0) {
                            paymentMomentSelector.prepend(
                                `<option value="${option.value}" selected="">${option.name}</option>`
                            );
                        }
                    }
                    paymentMomentSelector.selectpicker('refresh');
                    break;
                case 'notary':
                    for (const option of options) {
                        if (
                            option.value === 'after_sending' ||
                            option.value === 'before_sending' ||
                            option.value === 'acceptance'
                        ) {
                            paymentMomentSelector.children(`option[value='${option.value}']`).remove();
                        } else if (paymentMomentSelector.children(`option[value='${option.value}']`).length === 0) {
                            paymentMomentSelector.prepend(
                                `<option value="${option.value}" selected="">${option.name}</option>`
                            );
                        }
                    }
                    paymentMomentSelector.selectpicker('refresh');
                    break;
                default:
                    for (const option of options) {
                        if (paymentMomentSelector.children(`option[value='${option.value}']`).length === 0) {
                            paymentMomentSelector.prepend(
                                `<option value="${option.value}" selected="">${option.name}</option>`
                            );
                        }
                    }
                    paymentMomentSelector.selectpicker('refresh');
                    break;
            }

            // Hide element when there is only one option
            if (paymentMomentSelector.children('option').length === 1) {
                paymentMomentSelector.parents('.form-control')?.addClass('disabled');
            } else {
                paymentMomentSelector.parents('.form-control')?.removeClass('disabled');
            }
        }
        function changePaymentDays(val) {
            const paymentMomentSelector = $('select[name="payment_moment"]');
            if (paymentMomentSelector.length === 0 || paymentMomentSelector?.data('fill-invoice-flow') === undefined) {
                return;
            }

            const paymentMomentDays = $('#form-create-appraisal-payment-moment-days');

            switch (val) {
                case 'notary':
                    paymentMomentSelector.selectpicker('val', 'notary');
                    paymentMomentDays?.hide();
                    break;
                case 'deferred':
                case 'appraiser':
                case 'third-party':
                    paymentMomentSelector.selectpicker('val', 'after_sending');
                    paymentMomentDays?.show();
                    break;
                case 'acceptance':
                    paymentMomentSelector.selectpicker('val', 'acceptance');
                    paymentMomentDays?.hide();
                    break;
                default:
                    paymentMomentSelector.selectpicker('val', 'before_sending');
                    paymentMomentDays?.hide();
                    break;
            }
        }
    }

    // Select extended form for payment moment
    const paymentMomentDays = $('#form-create-appraisal-payment-moment-days');
    if (paymentMomentDays.length > 0) {
        const select = $('select[name="payment_moment"]');
        showPaymentDays(select.val());

        select.on('change', function (input) {
            showPaymentDays($(input.target).val());
        });

        function showPaymentDays(val) {
            if (val === 'after_sending') {
                paymentMomentDays.show();
            } else {
                paymentMomentDays.hide();
            }
        }
    }

    function showOnValue(input, target, val) {
        if ($(input.target ?? input).val() === val) {
            target.show();
            return true;
        } else {
            target.hide();
            return false;
        }
    }

    function hideOnValue(input, target, val) {
        if ($(input.target ?? input).val() !== val) {
            target.show();
            return true;
        } else {
            target.hide();
            return false;
        }
    }

    function makeRequiredOnValue(isVisible, fieldNames, container) {
        if (container.length > 0 && container[0].clientHeight > 0) {
            fieldNames.forEach((fieldName) => {
                const fields = document.getElementsByName(fieldName);
                if (fields.length > 0) {
                    fields[0].required = isVisible;
                }
            });
        }
    }
})();
