import {isSet} from './is_set';

export enum SortOrder {
    ASC = 'asc',
    DESC = 'desc',
}

export type SortableValue = number | Date | string | null | undefined;

export function sortByPredicate<T>(
    a: T,
    b: T,
    predicate: (item: T) => SortableValue,
    order = SortOrder.ASC
): 1 | 0 | -1 {
    const aValue = predicate(a);
    const bValue = predicate(b);

    if (!isSet(aValue) && !isSet(bValue)) {
        return 0;
    }
    if (!isSet(aValue)) {
        return order === SortOrder.ASC ? 1 : -1;
    }
    if (!isSet(bValue)) {
        return order === SortOrder.ASC ? -1 : 1;
    }
    if (aValue < bValue) {
        return order === SortOrder.ASC ? -1 : 1;
    }
    if (aValue > bValue) {
        return order === SortOrder.ASC ? 1 : -1;
    }

    return 0;
}
