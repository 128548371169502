import * as React from 'react';

import {Link, Route, RouteComponentProps, Switch} from 'react-router-dom';

import {Appraisal} from '../../../models/appraisal';
import {Global} from '../../../../business/global_provider';
import {History} from 'history';
import {PagePart} from '../../../models/page_part';
import {PagePartsSet} from '../../../models/page_parts_set';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {RenderingContextType} from '../../../enum/rendering_context_type';
import {RootGroupQuestionType} from '../../../enum/question_type';
import {ValidationError} from '../../../models/validation_error';
import {ValidationErrorsConceptReport} from './validation_errors_page/components/validation_errors_concept_report';
import {ValidationErrorsContent} from './validation_errors_page/components/validation_errors_content';
import {ValidationErrorsDownloadConceptReport} from './validation_errors_page/components/validation_errors_download_concept_report';
import {ValidationMessageMap} from '../../../business/validation/validation_message';
import {classNames} from '../../../../support/classnames';
import {QuestionRenderingData} from '../../../models/question_rendering_data';

interface OwnProps {
    history: History;
    global: Global;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    activePagePart: PagePart | null;
    pagePartsSet: PagePartsSet | null;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
    forceShowValidationMessages: boolean;
}

export enum ValidationErrorsPageTab {
    CONTROL = 'control',
    CONCEPT_REPORT = 'concept-report',
    DOWNLOAD_CONCEPT = 'download-concept',
}

export interface ValidationErrorsPageTabConfig {
    type: ValidationErrorsPageTab;
    title: string;
    icon: string;
}

export class ValidationErrorsPage extends React.Component<
    OwnProps & RouteComponentProps<Record<string, string | undefined>>
> {
    private getTabs(conceptReportQuestion: Question | null, canDownloadConceptReport: boolean) {
        const tabs: ValidationErrorsPageTabConfig[] = [
            {
                type: ValidationErrorsPageTab.CONTROL,
                title: 'Controle',
                icon: 'c-icon-finished',
            },
        ];

        if (conceptReportQuestion !== null) {
            tabs.push({
                type: ValidationErrorsPageTab.CONCEPT_REPORT,
                title: conceptReportQuestion.contents,
                icon: 'c-icon-concept-report',
            });
        }

        if (canDownloadConceptReport) {
            tabs.push({
                type: ValidationErrorsPageTab.DOWNLOAD_CONCEPT,
                title: 'Download concept',
                icon: 'ion-md-cloud-download',
            });
        }

        return tabs;
    }

    public render() {
        const conceptReportQuestion =
            this.props.questionSet.findQuestionsByType(RootGroupQuestionType.CONCEPT_REPORT)[0] ?? null;
        const canDownloadConceptReport = this.props.questionSet.reportDefintionConfig.conceptReport;
        const tabs = this.getTabs(conceptReportQuestion, canDownloadConceptReport);
        const matchPath = this.props.match.path === '/' ? '' : this.props.match.path;

        return (
            <>
                <span>
                    <div className="card-header">Controle</div>
                    <div className="card-body">
                        <div className="group-container">
                            <div className="group-container-content">
                                <ul className="nav nav-tabs">
                                    {tabs.map((tab) => {
                                        return (
                                            <li
                                                key={tab.type}
                                                className={classNames('nav-item', {
                                                    active: this.props.location.pathname.endsWith('/' + tab.type),
                                                    'col-6': tabs.length <= 2,
                                                    'col-4': tabs.length === 3,
                                                })}
                                            >
                                                <Link to={tab.type} className="nav-link">
                                                    <span className={classNames('nav-icon', tab.icon)}>&nbsp;</span>
                                                    {tab.title}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>

                                <Switch>
                                    <Route
                                        path={`${matchPath}/${ValidationErrorsPageTab.CONTROL}`}
                                        render={() => (
                                            <ValidationErrorsContent
                                                history={this.props.history}
                                                global={this.props.global}
                                                appraisal={this.props.appraisal}
                                                questionSet={this.props.questionSet}
                                                validationErrors={this.props.validationErrors}
                                                validationMessages={this.props.validationMessages}
                                                pagePartsSet={this.props.pagePartsSet}
                                                activePagePart={this.props.activePagePart}
                                                renderingContext={this.props.renderingContext}
                                                forceShowValidationMessages={this.props.forceShowValidationMessages}
                                                questionRenderingData={this.props.questionRenderingData}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${matchPath}/${ValidationErrorsPageTab.CONCEPT_REPORT}`}
                                        render={() => (
                                            <ValidationErrorsConceptReport
                                                history={this.props.history}
                                                global={this.props.global}
                                                appraisal={this.props.appraisal}
                                                questionSet={this.props.questionSet}
                                                validationErrors={this.props.validationErrors}
                                                validationMessages={this.props.validationMessages}
                                                pagePartsSet={this.props.pagePartsSet}
                                                activePagePart={this.props.activePagePart}
                                                renderingContext={this.props.renderingContext}
                                                questionRenderingData={this.props.questionRenderingData}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={`${matchPath}/${ValidationErrorsPageTab.DOWNLOAD_CONCEPT}`}
                                        render={() => (
                                            <ValidationErrorsDownloadConceptReport
                                                history={this.props.history}
                                                global={this.props.global}
                                                appraisal={this.props.appraisal}
                                                questionSet={this.props.questionSet}
                                                validationErrors={this.props.validationErrors}
                                            />
                                        )}
                                    />
                                    <Route
                                        render={() => {
                                            setTimeout(() => {
                                                this.props.history.replace(
                                                    `${this.props.match.url}/${ValidationErrorsPageTab.CONTROL}`
                                                );
                                            }, 1);

                                            return <div>Loading...</div>;
                                        }}
                                    />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </span>
            </>
        );
    }
}
