import {IteratorQuestionType, RootGroupQuestionType} from '../../../../enum/question_type';
import {Observable, combineLatest, merge} from 'rxjs';
import {computed, makeObservable, observable, runInAction} from 'mobx';
import {debounceTime, distinctUntilChanged, first, map, switchMap} from 'rxjs/operators';

import {AnswerController} from '../../../../business/answering/answer_controller';
import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {FloorInteractor} from '../../../../business/floor_interactor';
import {Presenter} from '../../../../../support/presenter/presenter';
import {Question} from '../../../../models/question';
import {QuestionSet} from '../../../../models/question_set';
import {SidebarItem} from '../../../../business/sidebar_tree_builder';
import {ValidationMessage} from '../../../../business/validation/validation_message';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {PagePart} from '../../../../models/page_part';
import {QuestionValidationMessagesProvider} from '../../../../business/validation/question_validation_messages_provider';

export interface LocationProvider {
    stream(): Observable<string>;
}

export class SidebarItemPresenter implements Presenter {
    @observable public visited = false;
    @observable.ref private validationMessages: ValidationMessage[] = [];
    @observable private location: string | null = null;

    @computed
    get hasError(): boolean {
        return this.validationMessages.length > 0 && this.visited && this.canHaveErrors();
    }

    private subscriptions = new CompositeSubscription();

    constructor(
        private questionSet: QuestionSet,
        private sidebarItem: SidebarItem,
        private pagePartsSet: PagePartsSet | null,
        private answerController: AnswerController,
        private locationProvider: LocationProvider,
        private floorInteractor: FloorInteractor,
        private questionValidationMessagesProvider: QuestionValidationMessagesProvider
    ) {
        makeObservable(this);
    }

    private subscriptionForOldSidebar(question: Question) {
        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidAndIteration(question.uuid, this.sidebarItem.iteration)
                .pipe(
                    map((answer) => answer.length > 0),
                    distinctUntilChanged()
                )
                .subscribe((visited) => {
                    runInAction(() => {
                        this.visited = visited;
                    });
                })
        );

        this.subscriptions.add(
            merge(
                this.locationProvider.stream().pipe(
                    map((location) => location === this.sidebarItem.link),
                    debounceTime(500),
                    switchMap((isCurrent: boolean) => {
                        return isCurrent
                            ? this.questionValidationMessagesProvider.getStream(
                                  question,
                                  this.sidebarItem.iteration,
                                  1250
                              )
                            : (new Observable() as Observable<ValidationMessage[]>);
                    })
                ),
                this.questionValidationMessagesProvider
                    .getStream(question, this.sidebarItem.iteration, 1250)
                    .pipe(first())
            ).subscribe((validationMessages: ValidationMessage[]) => {
                runInAction(() => {
                    this.validationMessages = validationMessages;
                });
            })
        );
    }

    private subscriptionsForPagePartSidebar(pagePart: PagePart, pagePartsSet: PagePartsSet) {
        const allPageParts = [pagePart, ...pagePartsSet.getRecursiveChildrenForUuid(pagePart.uuid)];
        const questions = allPageParts
            .map((pagePart) =>
                pagePart.questionUuid ? this.questionSet.findQuestionByUuid(pagePart.questionUuid) ?? null : null
            )
            .filter((q): q is Question => q !== null);

        this.subscriptions.add(
            this.floorInteractor
                .getFloorIteration()
                .pipe(
                    debounceTime(100),
                    switchMap((floor) => {
                        return combineLatest(
                            questions.map((question) => {
                                return this.answerController.answersForQuestionUuidStream(question.uuid).pipe(
                                    map((answers) => {
                                        return {answers, question};
                                    })
                                );
                            })
                        ).pipe(
                            map((pairs) => {
                                //We keep track of page-part-iterators and page-part-groups seperately
                                //if we encouter even a single iterator on a page, we ignore the page-part-group questions
                                //else the visisted will often be tree for a page since a page-part-group will stay the same
                                //even across different floors

                                let encounteredIterator = false;
                                let hadIteratorAnswer = false;
                                let hadPagePartGroupAnswer = false;

                                for (const pair of pairs) {
                                    if (pair.question.type === IteratorQuestionType.PAGE_PART_ITERATOR) {
                                        encounteredIterator = true;
                                        if (pair.answers.filter((a) => a.iteration === floor).length > 0) {
                                            hadIteratorAnswer = true;
                                            break; //No more checking needed
                                        }
                                    } else {
                                        if (pair.answers.length > 0) {
                                            hadPagePartGroupAnswer = true;
                                        }
                                    }
                                }

                                return encounteredIterator ? hadIteratorAnswer : hadPagePartGroupAnswer;
                            })
                        );
                    }),
                    debounceTime(100)
                )
                .subscribe((visited) => {
                    runInAction(() => {
                        this.visited = visited;
                    });
                })
        );

        this.subscriptions.add(
            this.floorInteractor
                .getFloorIteration()
                .pipe(
                    switchMap((floor) => {
                        return combineLatest(
                            questions.map((question) => {
                                if (question.type === IteratorQuestionType.PAGE_PART_ITERATOR) {
                                    return this.questionValidationMessagesProvider.getStream(question, floor, 1250);
                                } else {
                                    return this.questionValidationMessagesProvider.getStream(question, null, 1250);
                                }
                            })
                        ).pipe(map((messages) => messages.flat()));
                    })
                )
                .subscribe((validationMessages) => {
                    runInAction(() => {
                        this.validationMessages = validationMessages;
                    });
                })
        );
    }

    public mount(): void {
        this.subscriptions.add(
            this.locationProvider.stream().subscribe((location) => {
                runInAction(() => {
                    this.location = location;
                });
            })
        );

        const {question, pagePart} = this.sidebarItem;
        if (pagePart?.isIndexPage === false && this.pagePartsSet) {
            this.subscriptionsForPagePartSidebar(pagePart, this.pagePartsSet);
        } else if (question) {
            this.subscriptionForOldSidebar(question);
        }
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public isActive(sidebarItem: SidebarItem): boolean {
        return this.isSelfActive(sidebarItem) || this.hasActiveChild(sidebarItem);
    }

    private isSelfActive(sidebarItem: SidebarItem): boolean {
        // This type has no active state
        if (sidebarItem.question?.type === RootGroupQuestionType.HOUSE_GROUP_COMPACT) {
            return false;
        }

        return this.location === sidebarItem.link || this.location?.startsWith(sidebarItem.link + '/') || false;
    }

    private hasActiveChild(sidebarItem: SidebarItem) {
        return sidebarItem.children.some((child) => this.isActive(child));
    }

    private canHaveErrors() {
        if (this.sidebarItem.pagePart) {
            return true;
        }
        if (this.sidebarItem.question === undefined) {
            return false;
        }
        return this.sidebarItem.question.type !== RootGroupQuestionType.HOUSE_GROUP;
    }
}
