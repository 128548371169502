export enum ObjectType {
    VRIJSTAAND = 'Vrijstaande woning',
    TWEE_ONDER_EEN_KAPWONING = '2-onder-1-kapwoning',
    GESCHAKELDE_TWEE_ONDER_EEN_KAPWONING = 'Geschakelde 2-onder-1-kapwoning',
    GESCHAKELDE_WONING = 'Geschakelde woning',
    HALFVRIJSTAANDE_WONING = 'Halfvrijstaande woning',
    TUSSENWONING = 'Tussenwoning',
    HOEKWONING = 'Hoekwoning',
    EINDWONING = 'Eindwoning',
    GALERIJFLAT = 'Galerijflat',
    PORTIEKFLAT = 'Portiekflat',
    CORRIDORFLAT = 'Corridorflat',
    MAISONNETTE = 'Maisonnette',
    BENEDENWONING = 'Benedenwoning',
    BOVENWONING = 'Bovenwoning',
    PORTIEKWONING = 'Portiekwoning',
    WOONWAGEN = 'Woonwagen/stacaravan',
    WOONWAGENSTANDPLAATS = 'Woonwagenstandplaats/Stacaravanstandplaats',
    WOONBOOT = 'Woonboot',
    LIGPLAATS = 'Ligplaats',
    WATERWONING = 'Waterwoning',
    WINKELPAND = 'Woon-/winkelpand',
    GARAGE = 'Garage',
    PARKEERPLAATS = 'Parkeerplaats',
    BERGING = 'Berging',
}
