import * as React from 'react';
import {createRoot} from 'react-dom/client';

import {WindowLocalStorage} from '../persistence/drivers/window_local_storage';
import {DefaultGeolocationPermission} from '../support/geolocation/geolocation_permission';
import {NearbyAppraisalPresenter} from './nearby_appraisal_presenter';
import {NearbyAppraisalModal} from './nearby_appraisal';
import {DefaultAppraisalApi} from './appraisal_api';
import {FetchDriver} from '../network/driver/fetch_driver';

const localStorage = new WindowLocalStorage();
const geolocationPermission = new DefaultGeolocationPermission(localStorage);
const appraisalApi = new DefaultAppraisalApi(new FetchDriver());

export function renderGeolocationModal(element: HTMLElement) {
    const nearbyAppraisalPresenter = new NearbyAppraisalPresenter(geolocationPermission, appraisalApi);
    nearbyAppraisalPresenter.mount(); //Either this, or we gotta make our own `withPresenter` with our own component?

    createRoot(element).render(
        <NearbyAppraisalModal presenter={nearbyAppraisalPresenter} geolocationPermission={geolocationPermission} />
    );
}
