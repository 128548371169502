import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';

import {AppraisalProvider} from '../../appraisal_provider';
import {NormalQuestionType} from '../../../enum/question_type';
import {QuestionSet} from '../../../models/question_set';
import {QuestionValidator} from '../question_validator';
import {isEmpty} from '../../../../support/util';

export class ObjectTypeValidator implements QuestionValidator {
    constructor(private questionSet: QuestionSet, private appraisalProvider: AppraisalProvider) {}

    public validate(questionUuid: string): ValidationMessage[] {
        const question = this.questionSet.findQuestionByUuid(questionUuid);
        if (question === undefined || question.type !== NormalQuestionType.APPRAISAL_DETAILS) {
            return [];
        }

        const appraisal = this.appraisalProvider.appraisal;
        if (question.isRequired && isEmpty(appraisal.objectType)) {
            return [
                {
                    type: ValidationMessageType.TAXAPI,
                    importance: ValidationMessageImportance.ERROR,
                    question: question,
                    answer: null,
                    messages: [],
                    fallbackMessage: 'Het veld "' + question.contents + '" is nog niet ingevuld.',
                    floorInfo: null,
                },
            ];
        }

        return [];
    }
}
