/**
 * Bit 'n pieces to fill hidden elements on prior appraiser form
 */
(() => {
    const priorAppraiser = $('#form-prior-appraiser');
    if (priorAppraiser.length > 0) {
        for (const field of ['prior_appraiser_type', 'prior_appraiser_manner', 'prior_appraiser_considerations']) {
            $(`[name="${field}_placeholder"]`).on('change', function (input) {
                fillHiddenInput($(input.target).val(), $(`[name="${field}"]`));
            });
        }
        $('[name="is_prior_appraiser"]').on('change', function (checkbox) {
            showOnValue(checkbox, priorAppraiser, '1');
        });
        $('[name="is_prior_appraiser_placeholder"]').on('change', function (checkbox) {
            showOnValue(checkbox, priorAppraiser, '1');
            fillHiddenInput($(checkbox.target).val(), $('[name="is_prior_appraiser"]'));
        });
    }

    const largeTurnoverFromClient = $('#form-has-large-turnover-from-client');
    if (largeTurnoverFromClient.length > 0) {
        $('[name="has_large_turnover_from_client_placeholder"]').on('change', function (checkbox) {
            showOnValue(checkbox, largeTurnoverFromClient, '1');
            fillHiddenInput($(checkbox.target).val(), $('[name="has_large_turnover_from_client"]'));
        });
        $('[name="has_large_turnover_from_client"]').on('change', function (checkbox) {
            showOnValue(checkbox, largeTurnoverFromClient, '1');
        });
        $('[name="large_turnover_from_client_notes_placeholder"]').on('change', function (input) {
            fillHiddenInput($(input.target).val(), $('[name="large_turnover_from_client_notes"]'));
        });
    }

    function fillHiddenInput(value, target) {
        $(target).val(value);
    }

    function showOnValue(input, target, val) {
        if ($(input.target).val() === val) {
            target.show();
        } else {
            target.hide();
        }
    }
})();
