export enum SidebarIcons {
    FLOOR_GROUP_BASEMENT = 'floor-group-basement',
    FLOOR_GROUP_GROUND = 'floor-group-ground',
    FLOOR_GROUP_FLOOR = 'floor-group-floor',
    FLOOR_GROUP_ATTIC = 'floor-group-attic',
    PRE_GROUP = 'pre-group',
    POST_GROUP = 'post-group',
    OBSERVATION_GROUP = 'observation-group',
    OUTSIDE_GROUP = 'outside-group',
    FILES_GROUP = 'files-group',
    PHOTO_GROUP = 'photo-group',
    CONCEPT_REPORT = 'concept-report',
    HOUSE_GROUP = 'house-group',
    HOUSE_GROUP_COMPACT = 'house-group-compact',
    HOUSE_INSIDE = 'house-inside',
    GROUP = 'group',
    VALIDATION_MESSAGES = 'validation-messages',
    FINISHED = 'finished',
    ENERGETIC_SHEET = 'energetic-sheet',
    BUILDING_COSTS_GROUP = 'building-costs-group',
    ADVICE = 'advice',
}

export function isSidebarIcon(icon: string): icon is SidebarIcons {
    return Object.values(SidebarIcons).includes(icon as SidebarIcons);
}

export function classNameSidebarIcon(icon: string): string {
    return 'c-icon-' + icon;
}

export function humanizeSidebarIcon(icon: SidebarIcons) {
    switch (icon) {
        case SidebarIcons.FLOOR_GROUP_ATTIC:
            return 'Zolder';
        case SidebarIcons.FLOOR_GROUP_FLOOR:
            return 'Vloer';
        case SidebarIcons.FLOOR_GROUP_GROUND:
            return 'Begane grond';
        case SidebarIcons.FLOOR_GROUP_BASEMENT:
            return 'Kelder';
        case SidebarIcons.PRE_GROUP:
            return 'Pre-group';
        case SidebarIcons.POST_GROUP:
            return 'Post-group';
        case SidebarIcons.OBSERVATION_GROUP:
            return 'Observatie group';
        case SidebarIcons.OUTSIDE_GROUP:
            return 'Buiten';
        case SidebarIcons.FILES_GROUP:
            return 'Bijlagen';
        case SidebarIcons.PHOTO_GROUP:
            return 'Fotos';
        case SidebarIcons.CONCEPT_REPORT:
            return 'Concept rapport';
        case SidebarIcons.HOUSE_GROUP:
            return 'Huis';
        case SidebarIcons.HOUSE_GROUP_COMPACT:
            return 'Huis compact';
        case SidebarIcons.HOUSE_INSIDE:
            return 'Binnenzijde';
        case SidebarIcons.GROUP:
            return 'Groep';
        case SidebarIcons.VALIDATION_MESSAGES:
            return 'Validatieberichten';
        case SidebarIcons.FINISHED:
            return 'Afgerond';
        case SidebarIcons.BUILDING_COSTS_GROUP:
            return 'Bouwkosten';
        case SidebarIcons.ENERGETIC_SHEET:
            return 'Energetisch';
        case SidebarIcons.ADVICE:
            return 'Advies';
    }
}
