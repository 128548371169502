export interface ExternalAdvice {
    id?: number;
    appraisalId: number;
    questionUuid: string;
    status: ExternalAdviceStatus;
    parentAnswerUuid: string;
    externalUserId?: number;
    appraiserNote: string;
    externalNote: string;
    measurement: string;
}

export enum ExternalAdviceStatus {
    NEW = 'new',
    PENDING = 'pending',
    PROCESSING = 'processing',
    FINISHED = 'finished',
}
