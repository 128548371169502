export enum AnswerFilledByAutomator {
    KADASTER = 'kadaster',
    KADASTER_BAG = 'kadaster-bag',
    KVK = 'kvk',
    FUNDERINGSVIEWER = 'funderingsviewer',
    ENERGIELABEL = 'energielabel',
    ENERGIE_BESPARING = 'energie-besparing',
    BODEMLOKET = 'bodemloket',
    PLAN_VIEWER = 'planviewer',
    RUIMTELIJKEPLANNEN = 'ruimtelijkeplannen',
    VASTGOEDPRO_TEXT_EXTRACTOR = 'vastgoedpro-text-extractor',
    EXTERNAL_TEXT_EXTRACTOR = 'external-text-extractor',
    PHOTO_RECOGNITION = 'photo-recognition',
    WEATHER = 'weather',
    TAXAPI_AI = 'taxapi-ai',
    BOUWKUNDIGE_OPNAME_STAAT = 'bouwkundige-opnamestaat',
    PLAUSIBILITY = 'plausibility',
}
