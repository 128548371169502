import * as React from 'react';
import {observer} from 'mobx-react';
import {FlashMessagePresenter} from './flash_message_presenter';
import {Type} from '../../../business/flash_message_broadcaster';
import {StatusLabel} from './status_label';
import {usePresenter} from '../../../../support/presenter/use_presenter';

export const FlashMessage: React.FunctionComponent = observer(() => {
    const presenter = usePresenter(
        (container) => new FlashMessagePresenter(container.business.flashMessageBroadcaster)
    );

    if (presenter.message === null) {
        return null;
    }

    switch (presenter.message.type) {
        case Type.Danger:
            return (
                <div
                    key={presenter.message.date.getTime()}
                    className="flash-message wrapper alert alert-danger ion-md-close"
                >
                    {presenter.message.label && (
                        <>
                            <StatusLabel
                                type={presenter.message.label.type}
                                content={presenter.message.label.content}
                            />
                            &nbsp;
                        </>
                    )}
                    {presenter.message.content}
                </div>
            );
        case Type.Success:
            return (
                <div
                    key={presenter.message.date.getTime()}
                    className="flash-message wrapper alert alert-success ion-md-thumbs-up"
                >
                    {presenter.message.label && (
                        <>
                            <StatusLabel
                                type={presenter.message.label.type}
                                content={presenter.message.label.content}
                            />
                            &nbsp;
                        </>
                    )}
                    {presenter.message.content}
                </div>
            );
    }
});
