import React, {useContext, useEffect, useMemo} from 'react';
import {createPortal} from 'react-dom';
import {useCollapse} from '../../../../../support/collapse';
import {QuestionTableChildContext, QuestionTableContext} from './question_table_context';

export function QuestionTableCell({questionUuid, children}: {questionUuid: string; children?: React.ReactNode}) {
    const tableContext = useContext(QuestionTableContext);
    const childContext = useContext(QuestionTableChildContext);

    // The key equals parent key concatted with the current question uuid
    const key = useMemo(
        () => `${childContext.parentKey ?? ''}${childContext.parentKey ? '.' : ''}${questionUuid}`,
        [childContext.parentKey]
    );

    // Register the cell with the table context
    useEffect(() => {
        if (!childContext.columnKey) {
            return;
        }

        const columnKey = childContext.columnKey;

        tableContext.registerCell(columnKey, key);

        return () => {
            tableContext.destroyCell(columnKey, key);
        };
    }, [childContext.columnKey, childContext.parentKey, key]);

    // Prepare the child context
    const context = useMemo(
        () => ({
            ...childContext,
            parentKey: key,
        }),
        [childContext, key]
    );

    // Retrieve the portal from the context
    const portal = useMemo(
        () => (childContext.columnKey ? tableContext.portals[childContext.columnKey]?.[key] ?? null : null),
        [tableContext, childContext, key]
    );

    const collapse = useCollapse(childContext.collapsed ?? false);

    // Not in a table environment
    if (!childContext.columnKey) {
        return <>{children}</>;
    }

    // Wait for the portal to be created by the question table
    // When it exists, we can render into it
    if (!portal) {
        return null;
    }

    if (childContext.collapsed !== null) {
        children = (
            <div className={collapse.className} ref={collapse.ref}>
                {children}
            </div>
        );
    }

    return (
        <QuestionTableChildContext.Provider value={context} key={key}>
            {createPortal(children, portal)}
        </QuestionTableChildContext.Provider>
    );
}
