import * as React from 'react';
import {Appraisal} from '../../../../../../models/appraisal';
import {Question} from '../../../../../../models/question';
import {QuestionSet} from '../../../../../../models/question_set';
import {QuestionType} from '../../../../../../enum/question_type';
import {ValidationError} from '../../../../../../models/validation_error';
import {observer} from 'mobx-react';
import {CadastralDocumentQuestionPresenter} from './cadastral_document_question_presenter';
import {AdaptedDefaultValuesMap} from '../../../../../../models/adapted_default_values_map';
import {Loader} from '../../../../components/loader';
import {classNames} from '../../../../../../../support/classnames';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {formatMoney} from '../../../../support/format_money';

interface OwnProps {
    appraisal: Appraisal;
    question: Question;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    disabled?: boolean;
    iteration?: string;
    parentAnswerUuid?: string;
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
    disableAdaptedValueContainer?: boolean;
    hiddenQuestionTypes: QuestionType[];
    addIteration?: (iteration: string) => void;
}

export const CadastralDocumentQuestion: React.FC<OwnProps> = observer(function CadastralDocumentQuestion(props) {
    const presenter = usePresenter(
        (container) =>
            new CadastralDocumentQuestionPresenter(
                props.appraisal,
                props.question,
                props.questionSet,
                props.parentAnswerUuid !== undefined ? props.parentAnswerUuid : null,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.questionEffectsInteractor(props.appraisal, props.questionSet),
                container.network.appraisalAutomationApi,
                container.business.networkStatusProvider,
                container.globalProvider,
                props.addIteration
            )
    );
    if (presenter.isHidden) {
        return null;
    }

    function renderMessage() {
        return presenter.message !== null ? (
            <span
                className={
                    presenter.message.type === 'success'
                        ? 'alert-inline alert-success ion-md-checkmark'
                        : 'invalid-feedback'
                }
                role="alert"
            >
                {presenter.message.message}
            </span>
        ) : null;
    }

    function renderSelect() {
        return (
            <div className="row">
                <div
                    className={classNames('col-12', {
                        'd-none': presenter.openInputVisible || props.appraisal.cadastralDocuments.length === 0,
                    })}
                >
                    <div className="form-group">
                        <select
                            className="form-control"
                            onChange={(e) => presenter.onSelect(e.target.value)}
                            value={presenter.cadastralDocumentString ?? ''}
                        >
                            {props.appraisal.cadastralDocuments.map((d) => {
                                return (
                                    <option value={d.name} key={d.name}>
                                        {d.name}
                                    </option>
                                );
                            })}
                            <option value="-1" key={'-1'}>
                                Eigen invoer
                            </option>
                        </select>
                        {renderMessage()}
                    </div>
                </div>
            </div>
        );
    }

    function renderInput() {
        return (
            <div className="row">
                <div
                    className={classNames('col-12', {
                        'd-none': !presenter.openInputVisible && props.appraisal.cadastralDocuments.length !== 0,
                    })}
                >
                    <div className="form-group form-group-close">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Document nummer, zoals Hyp4-12356/13"
                            onChange={(e) => presenter.onChangeInput(e.target.value)}
                        />
                        {presenter.isCloseButtonVisible() ? (
                            <button
                                className="btn cadastral-btn btn-secondary ion-md-close"
                                onClick={() => presenter.hideOpenInput()}
                            >
                                &nbsp;
                            </button>
                        ) : null}
                        {renderMessage()}
                    </div>
                </div>
            </div>
        );
    }

    function renderButtons() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="btn-container float-right text-right">
                        <button className="btn btn-sm btn-secondary ion-md-close" onClick={() => presenter.closeForm()}>
                            Annuleren
                        </button>
                        <button className="btn btn-sm ion-md-checkmark" onClick={() => presenter.onSubmit()}>
                            {presenter.loading && <Loader isSmall={true} />}
                            Bestellen
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="iterator-group-form" data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}>
            <div className="row">
                <div className={classNames('col-12', {'d-none': presenter.formOpen})}>
                    {renderMessage()}
                    <div className="btn-container float-left text-right">
                        <button
                            className="btn btn-sm btn-secondary ion-md-document"
                            onClick={() => presenter.openForm()}
                        >
                            Document bestellen
                        </button>
                    </div>
                </div>
                <div className={classNames('col-12', {'d-none': !presenter.formOpen})}>
                    <h3>Document bestellen bij Kadaster</h3>
                    <p>
                        Bestel een document bij Kadaster. Hiervoor wordt{' '}
                        {formatMoney(presenter.kadasterCostsCents / 100)} in rekening gebracht.
                    </p>
                    {renderSelect()}
                    {renderInput()}
                    {renderButtons()}
                </div>
            </div>
        </div>
    );
});
