import React from 'react';
import {classNames} from '../../../support/classnames';
import {GivenPreselectionReferenceObject} from '../../../appraising/network/preselected_reference_objects_api';
import {ReferenceSale} from '../../../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v1/models/reference_sale';
import {getUrlFromImageUrlPair} from '../../../appraising/models/image_url_pair';

interface OwnProps {
    swipeLeft?: () => void;
    swipeRight?: () => void;
    onClick?: () => void;
    object: ReferenceSale;
    given?: GivenPreselectionReferenceObject;
}

interface OwnState {
    hovering: boolean;
}

export class ReferenceObjectWidgetItem extends React.Component<OwnProps, OwnState> {
    public state = {
        hovering: false,
    };

    private getPurchasePrice(purchasePrice: string | null) {
        if (!purchasePrice) {
            return '';
        }

        const range = purchasePrice.split('-');
        if (range.length === 1) {
            const price = Number(range[0]);
            if (Number.isNaN(price)) {
                return purchasePrice;
            }

            return `€ ${price.toLocaleString('nl-NL')}`;
        } else if (range.length !== 2) {
            return purchasePrice;
        }

        const from = Number(range[0]);
        const to = Number(range[1]);

        if (Number.isNaN(from) || Number.isNaN(to)) {
            return purchasePrice;
        }

        return `€ ${from.toLocaleString('nl-NL')} - € ${to.toLocaleString('nl-NL')}`;
    }

    public render() {
        const {object} = this.props;
        const purchasePrice = this.getPurchasePrice(object.priceRange);
        return (
            <div
                className="col-12 col-sm-6 col-md-3 form-grid-item m-1 pb-0 selected"
                onClick={this.props.onClick}
                onMouseEnter={() => this.setState({hovering: true})}
                onMouseLeave={() => this.setState({hovering: false})}
            >
                <div className="form-grid-item-img">
                    <div className="form-grid-item-img-content">
                        <img src={getUrlFromImageUrlPair(object.frontview) ?? undefined} />
                        <div
                            className={classNames('active-icon visible', {
                                'ion-md-checkmark': !this.state.hovering,
                                'ion-md-close active-icon-red': this.state.hovering,
                            })}
                        >
                            &nbsp;
                        </div>
                    </div>
                </div>
                <div className="form-grid-item-body flex-column">
                    <div className="row">
                        <div className="col-12 d-flex flex-column align-items-center">
                            <small className="highlight-text">
                                <strong>
                                    {[object.street, object.houseNumber + object.letter].filter((i) => i).join(' ')}
                                </strong>
                            </small>
                            <small className="highlight-text">
                                <strong>{purchasePrice}</strong>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
