import React, {useEffect, useRef, useState} from 'react';
import ReactSelect, {GroupBase, SelectInstance} from 'react-select';
import {isMobile, isTablet} from '../../../../support/check_mobile';

import {classNames} from '../../../../support/classnames';
import {timedScrollIntoViewIfNeeded} from '../../../../support/scroll_into_view_if_needed';

export interface Option<T> {
    label: string;
    value: T;
    isDisabled?: boolean;
    icon?: string;
}

interface OwnProps<T> {
    id: string;
    onChange(value: T | null): void;
    options: ReadonlyArray<Option<T>>;
    value: T;
    isDisabled: boolean;
    isSearchable?: boolean;
    className?: string;
    placeHolder?: string;
    isClearable?: boolean;
}

/**
 * We use a custom react-select instead of the native react because on iOS pinned apps sometimes dont show dropdowns
 * https://label305.atlassian.net/browse/TX-329
 */
export const Select = <T,>({
    id,
    value,
    onChange,
    options,
    className,
    isDisabled,
    placeHolder,
    isClearable,
    isSearchable,
}: OwnProps<T>) => {
    const selectRef = useRef<SelectInstance<Option<T>, false, GroupBase<Option<T>>> | null>(null);
    const [open, setOpen] = useState(false);

    const searchable = isSearchable ?? options.length > 5;

    useEffect(() => {
        if (!open || !searchable) {
            return;
        }
        const inputRef = selectRef.current?.inputRef;
        if (inputRef && (isMobile() || isTablet())) {
            const formControl = inputRef.closest('.form-control');
            const menu = formControl?.querySelector('.form-multiple-select__menu');
            timedScrollIntoViewIfNeeded(
                menu ?? formControl ?? inputRef,
                {
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                },
                {
                    top: -50,
                    right: 0,
                    bottom: -300,
                    left: 0,
                }
            );
        }
    }, [open]);

    return (
        <ReactSelect
            ref={selectRef}
            id={id}
            name={id}
            classNamePrefix="form-multiple-select"
            className={classNames('form-multiple-select', className)}
            isMulti={false}
            isClearable={isClearable ?? true}
            options={options}
            isSearchable={searchable}
            value={options.find((option) => option.value === value)}
            isDisabled={isDisabled}
            onChange={(newValue) => {
                onChange(newValue?.value ? newValue.value : null);
            }}
            closeMenuOnSelect={true}
            closeMenuOnScroll={false}
            menuShouldScrollIntoView={false}
            placeholder={placeHolder ?? '- niet van toepassing -'}
            menuPortalTarget={document.getElementById('react-select-portal')}
            onMenuOpen={() => setOpen(true)}
            onMenuClose={() => setOpen(false)}
            formatOptionLabel={(option) => {
                if (option.icon !== undefined) {
                    return (
                        <span className={classNames('form-multiple-select__option__icon', option.icon)}>
                            <span>{option.label}</span>
                        </span>
                    );
                }
                return option.label;
            }}
        />
    );
};
