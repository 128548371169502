import {
    Chart,
    Filler,
    Legend,
    LineElement,
    PointElement,
    RadarController,
    RadialLinearScale,
    Title,
    Tooltip,
} from 'chart.js';

import {AltumSettings} from '../../../appraising/network/preselected_reference_objects_api';
import React from 'react';

Chart.register(RadialLinearScale, RadarController, PointElement, LineElement, Filler, Legend, Title, Tooltip);

interface OwnProps {
    value: AltumSettings;
}

export class AltumReferenceObjectSettingsGraph extends React.Component<OwnProps> {
    private ref: React.RefObject<HTMLCanvasElement>;
    private chart?: Chart;

    constructor(props: OwnProps) {
        super(props);

        this.ref = React.createRef();
    }

    public componentDidMount() {
        const context = this.ref.current?.getContext('2d');
        if (context) {
            if (this.chart != null) {
                this.chart.destroy();
            }

            this.chart = new Chart(context, {
                type: 'radar',
                data: {
                    labels: ['Visueel', 'Bouwjaar', 'Gebruiksoppervlakte', 'Transactie datum', 'Afstand'],
                    datasets: [
                        {
                            label: 'Waarde',
                            data: [
                                this.props.value.visual_comparability_weight * 10,
                                this.props.value.buildyear_weight * 10,
                                this.props.value.objectarea_weight * 10,
                                this.props.value.transaction_date_weight * 10,
                                this.props.value.distance_weight * 10,
                            ],
                            fill: true,
                            backgroundColor: 'rgba(43, 218, 220, 0.4)',
                            borderWidth: 0,
                            hoverBorderWidth: 1,
                            hoverBorderColor: ['rgba(43, 218, 220, 1)'],
                            borderColor: ['rgba(43, 218, 220, 1)'],
                        },
                        {
                            label: 'Gemiddelde',
                            data: [5, 5, 5, 5, 5],
                            backgroundColor: 'rgb(169,169,169)',
                            fill: false,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    layout: {
                        padding: 20,
                    },
                    elements: {
                        line: {
                            borderWidth: 3,
                        },
                    },
                    scales: {
                        r: {
                            suggestedMin: 0,
                            suggestedMax: 10,
                            ticks: {
                                font: {
                                    family: 'Barlow',
                                    size: 12,
                                },
                                stepSize: 2,
                                display: false,
                            },
                            angleLines: {
                                color: 'grey',
                                lineWidth: 1,
                            },
                            grid: {
                                color: 'grey',
                            },
                            pointLabels: {
                                font: {
                                    family: 'barlow',
                                    size: 12,
                                },
                            },
                        },
                    },
                },
            });
        }
    }

    public componentDidUpdate() {
        if (this.chart) {
            this.chart.data.datasets[0].data = [
                this.props.value.visual_comparability_weight * 10,
                this.props.value.buildyear_weight * 10,
                this.props.value.objectarea_weight * 10,
                this.props.value.transaction_date_weight * 10,
                this.props.value.distance_weight * 10,
            ];

            this.chart.update();
        }
    }

    public render() {
        return (
            <div className="reference-object-graph">
                <canvas ref={this.ref} />
            </div>
        );
    }
}
