import {shareReplay} from 'rxjs/operators';
import {ServerTime, ServerTimeInteractor} from './server_time_interactor';
import {Observable} from 'rxjs';

export class SharedServerTimeInteractor implements ServerTimeInteractor {
    private _delegate: ServerTimeInteractor;

    private _observable: Observable<ServerTime> | undefined;

    constructor(serverTimeInteractor: ServerTimeInteractor) {
        this._delegate = serverTimeInteractor;
    }

    public serverTimes(): Observable<ServerTime> {
        if (this._observable === undefined) {
            this._observable = this._delegate.serverTimes().pipe(shareReplay(1));
        }
        return this._observable;
    }
}
