import {ConditionsInteractor, EffectState} from './conditions_interactor';

import {AnswerController} from '../answering/answer_controller';
import {ConditionGroup} from '../../models/condition_group';
import {EffectsCollectorFromAnswers} from './effects_collector_from_answers';
import {QuestionSet} from '../../models/question_set';

export class DefaultConditionsInteractor implements ConditionsInteractor {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private effectsCollectorFromAnswers: EffectsCollectorFromAnswers
    ) {}

    public get(questionUuid: string, parentAnswerUuid: string | null): EffectState[] {
        const question = this.questionSet.findQuestionByUuid(questionUuid);
        if (question === undefined) {
            //There is no question, so no effects
            return [];
        }

        return this.getForConditionGroups(question.conditionGroups, parentAnswerUuid);
    }

    public getForConditionGroups(conditionGroups: ConditionGroup[], parentAnswerUuid: string | null): EffectState[] {
        if (conditionGroups.length === 0) {
            //No conditions so no effects
            return [];
        }

        return conditionGroups.flatMap((conditionGroup) =>
            this.effectStatesForConditionGroup(conditionGroup, parentAnswerUuid)
        );
    }

    private effectStatesForConditionGroup(
        conditionGroup: ConditionGroup,
        parentAnswerUuid: string | null
    ): EffectState[] {
        const questionUuidsForConditions: string[] = [];
        const questionUuidsForConditionAnswers: string[] = [];
        for (const condition of conditionGroup.conditions) {
            if (condition.questionUuid !== null) {
                questionUuidsForConditions.push(condition.questionUuid);
            }
            if (condition.compareQuestionUuid) {
                questionUuidsForConditionAnswers.push(condition.compareQuestionUuid);
            }
        }

        const answersForQuestionUuid = this.getAnswersForQuestionUuids(questionUuidsForConditions, parentAnswerUuid);
        const answersForConditionAnswers = this.getAnswersForQuestionUuids(
            questionUuidsForConditionAnswers,
            parentAnswerUuid
        );

        return this.effectsCollectorFromAnswers.collect(
            conditionGroup,
            answersForQuestionUuid,
            answersForConditionAnswers
        );
    }

    private getAnswersForQuestionUuids(questionUuidsForConditions: string[], parentAnswerUuid: string | null) {
        return questionUuidsForConditions.map((questionUuid) => {
            const answersForQuestionUuidAndParentAnswerUuidInSameIteration =
                this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIteration(
                    questionUuid,
                    parentAnswerUuid
                );

            return {
                questionUuid,
                answers: answersForQuestionUuidAndParentAnswerUuidInSameIteration,
            };
        });
    }
}
