import {BbmiAreaProvider} from './bbmi_area_provider';
import {Observable} from 'rxjs';
import {TechnicalReference} from '../../enum/technical_reference';

export interface OverigeInpandigeRuimteProvider {
    stream(): Observable<number | null>;
}

export class DefaultOverigeInpandigeRuimteProvider implements OverigeInpandigeRuimteProvider {
    constructor(private bbmiAreaProviderProvider: BbmiAreaProvider) {}

    public stream(): Observable<number | null> {
        return this.bbmiAreaProviderProvider.stream(
            TechnicalReference.BBMI_OVERIGE_INPANDIGE_RUIMTE,
            TechnicalReference.AREA_OTHER_SPACE
        );
    }
}
