import {
    ReferenceObjectDataWithSet,
    createReferenceObjectDataForAnswer,
} from '../../internal/create_reference_object_data';
import {makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../../../../../../support/composite_subscription';
import {ExplanationAppraiserGenerator} from '../../internal/explanation_appraiser_generator/explanation_appraiser_generator';
import {SimpleQuestionPresenter} from '../../../../../simple/simple_question_presenter';
import {V3ReferenceSetsProvider} from '../../internal/reference_sets/reference_sets_provider';
import {map} from 'rxjs/operators';
import {TextAIContextBuilder} from '../../../../../../../../../business/textai/textai_context_builder';

export class ReferenceObjectExplanationPresenter extends SimpleQuestionPresenter {
    @observable public referenceObjectDataWithSet: ReferenceObjectDataWithSet | null = null;

    private subscriptions = new CompositeSubscription();
    constructor(
        private referenceSetsProvider: V3ReferenceSetsProvider,
        private explanationAppraiserGenerator: ExplanationAppraiserGenerator,
        public textAIReferenceObjectContextBuilder: TextAIContextBuilder,
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public mount() {
        super.mount();

        this.subscriptions.add(
            this.referenceSetsProvider
                .referenceSets()
                .pipe(
                    map((referenceSets) =>
                        createReferenceObjectDataForAnswer(
                            referenceSets,
                            this.question.uuid,
                            this.parentAnswerUuid ?? null
                        )
                    )
                )
                .subscribe((referenceObjectDataWithSet) => {
                    runInAction(() => {
                        this.referenceObjectDataWithSet = referenceObjectDataWithSet;
                    });
                })
        );
    }

    public unmount() {
        super.unmount();

        this.subscriptions.clear();
    }

    public onGenerateClick(referenceObjectDataWithSet: ReferenceObjectDataWithSet) {
        this.onChange(
            this.explanationAppraiserGenerator.generate(
                referenceObjectDataWithSet.data.referenceObjectAnswer,
                referenceObjectDataWithSet.referenceSet.surfaceArea,

                referenceObjectDataWithSet.referenceSet.plotArea,

                referenceObjectDataWithSet.referenceSet.volume,
                referenceObjectDataWithSet.referenceSet.energyLabel
            )
        );
    }
}
