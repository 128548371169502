import {FlashMessage, FlashMessageBroadcaster} from '../../../business/flash_message_broadcaster';
import {makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../support/composite_subscription';
import {Presenter} from '../../../../support/presenter/presenter';

export class FlashMessagePresenter implements Presenter {
    @observable.ref public message: FlashMessage | null = null;

    constructor(private flashMessageBroadcaster: FlashMessageBroadcaster) {
        makeObservable(this);
    }

    private subscriptions = new CompositeSubscription();
    private removeTimeout: NodeJS.Timeout | null = null;

    public mount(): void {
        this.subscriptions.add(
            this.flashMessageBroadcaster.stream.subscribe((message) => {
                runInAction(() => {
                    this.message = message;
                });

                if (this.removeTimeout) {
                    clearTimeout(this.removeTimeout);
                }

                // Remove message after animation
                this.removeTimeout = setTimeout(() => {
                    runInAction(() => {
                        this.message = null;
                    });

                    this.removeTimeout = null;
                }, 10000);
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
