import {first} from 'rxjs/operators';
import {Presenter} from '../../../../support/presenter/presenter';
import {AnswerController} from '../../../business/answering/answer_controller';
import {storedAnswerToAnswer} from '../../../business/answering/support/local_storage_answer_registry';
import {FlashMessageBroadcaster, Type} from '../../../business/flash_message_broadcaster';
import {NetworkStatus, NetworkStatusProvider} from '../../../business/network_status_provider';
import {Answer} from '../../../models/answer';
import {Appraisal} from '../../../models/appraisal';

interface Export {
    answers: Answer[];
}

export class ImportExportPresenter implements Presenter {
    constructor(
        private appraisal: Appraisal,
        private answerController: AnswerController,
        private flashMessageBroadcaster: FlashMessageBroadcaster,
        private networkStatusProvider: NetworkStatusProvider
    ) {}

    public mount() {
        // Noop
    }

    public unmount() {
        // Noop
    }

    public async export() {
        const data = await this.getDebugData();

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([data], {type: 'text/json'}));
        link.download = `${this.appraisal.id}-appraisal-dump.json`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    public async import(file: File) {
        const data = await new Promise<Export | null>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = (event) =>
                resolve(event.target?.result ? JSON.parse(event.target.result.toString()) : null);
            fileReader.onerror = (error) => reject(error);
            fileReader.readAsText(file);
        });

        if (data?.answers) {
            data.answers = data.answers.map((answer) => storedAnswerToAnswer(answer));

            const changedAnswers = data.answers.filter((answer: Answer) => answer.changed);

            if (changedAnswers.length > 0) {
                this.answerController.pushMany(changedAnswers);
                this.flashMessageBroadcaster.broadcast(
                    `${changedAnswers.length} gewijzigde antwoorden geimporteerd`,
                    Type.Success
                );

                return;
            }
        }

        this.flashMessageBroadcaster.broadcast(`Geen informatie om te importeren gevonden in het bestand`, Type.Danger);
    }

    private async getDebugData() {
        try {
            const status = await this.networkStatusProvider.status().pipe(first()).toPromise();

            return JSON.stringify(
                {
                    appraisal: {
                        ...this.appraisal,
                        answers: undefined,
                    },
                    answers: this.answerController.answers(),
                    device: {
                        userAgent: navigator.userAgent,
                        platform: navigator.platform,
                        isOnline: status === NetworkStatus.ONLINE,
                    },
                },
                null,
                4
            );
        } catch (e) {
            return JSON.stringify(
                {
                    error: e,
                },
                null,
                4
            );
        }
    }
}
