import {ApiReferenceSale, apiReferenceSalesToReferenceSales} from './api_reference_sale';

import {ReferenceSaleSetData} from '../../business/reference_object_provider';
import {ReferenceSubscriptionType, ReferenceSubscriptions} from '../../models/reference_subscriptions';

export interface ApiReferenceSaleSetData {
    valuation_type_uuid: string;
    refresh_credentials: ReferenceSubscriptionType[];
    reference_objects: ApiReferenceSale[];
    subscriptions: ReferenceSubscriptions | null;
    task_id: number | null;
}

export function apiReferenceSaleSetDataToReferenceSaleSetData(data: ApiReferenceSaleSetData) {
    const apiReferenceSales: ApiReferenceSale[] = data.reference_objects;
    const referenceSaleData: ReferenceSaleSetData = {
        valuationTypeUuid: data.valuation_type_uuid,
        refreshCredentials: data.refresh_credentials,
        referenceSales: apiReferenceSalesToReferenceSales(apiReferenceSales),
        subscriptions: data.subscriptions,
        taskId: data.task_id ?? null,
    };
    return referenceSaleData;
}
