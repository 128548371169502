import $ from 'jquery';
import {Subject} from 'rxjs';
import {distinctUntilChanged, switchMap, tap} from 'rxjs/operators';
import {disableField, disableSelect, RoleType, selectOption, UserType} from './index';

(() => {
    interface CreateOrganisationUserResponse {
        is_existing: boolean;
        email: string;
        initials: string | null;
        first_name: string | null;
        last_name: string | null;
        phone: string | null;
        role: string | null;
        user_type: string;
        rt_number: string | null;
        mg_number: string | null;
    }

    interface ErrorResponse {
        errors: string[];
    }

    const form = $('form#organisation-user');
    if (!form || form.length === 0) {
        return;
    }

    const input = new Subject<{email: string; organisation_id: string | null}>();
    const result = new Subject<CreateOrganisationUserResponse>();
    const autofillFields = ['initials', 'first_name', 'last_name', 'phone', 'user_type', 'rt_number', 'mg_number'];
    const canEditDetails = form.data('purpose') === 'create';

    $('input[data-autofill-name="email"]').each(function () {
        const touch = () => {
            const email = form.find('input[name="email"]').val() as string;
            if (email.length > 0) {
                input.next({
                    email: email,
                    organisation_id: $(form)?.attr('data-organisation-id') as string,
                });
            }
        };

        const clear = () => {
            autofillFields.map((field) => {
                form.find('[data-autofill-name="' + field + '"]').val('');
            });
        };

        const toggleLoader = (isDisabled: boolean) => {
            autofillFields.map((field) => {
                const formElement = form.find('[data-autofill-name="' + field + '"]');
                if (['user_type', 'role'].includes(field)) {
                    disableSelect(formElement as JQuery<HTMLSelectElement>, isDisabled);
                } else {
                    disableField(formElement as JQuery<HTMLInputElement>, isDisabled);
                }
            });
        };

        const fillFields = (response: CreateOrganisationUserResponse) => {
            Object.entries(response).forEach(([field, value]) => {
                const select = form.find(`select[data-autofill-name="${field}"]`) as JQuery<HTMLSelectElement>;
                if (field === 'user_type') {
                    const selectedValue = value ?? $(select).find('option')[0].value;
                    if (selectedValue) {
                        selectOption(select, selectedValue);
                    }

                    const mgNumberInput = form.find('#mg_number') as JQuery<HTMLInputElement>;
                    if (mgNumberInput) {
                        mgNumberInput.css('display', value === UserType.JUNIOR_APPRAISER ? 'flex' : 'none');
                    }
                    const rtNumberInput = form.find('#rt_number') as JQuery<HTMLInputElement>;
                    if (rtNumberInput) {
                        rtNumberInput.css('display', value === UserType.APPRAISER ? 'flex' : 'none');
                    }
                } else if (field === 'role') {
                    const selectedValue = value ?? $(select).find('option')[0].value;
                    if (selectedValue) {
                        selectOption(select, selectedValue);
                        if (selectedValue === RoleType.CUSTOM) {
                            $(form).find('#permissions-container').removeClass('d-none');
                        } else {
                            $(form).find('#permissions-container').addClass('d-none');
                        }
                    }
                } else {
                    form.find(`input[data-autofill-name="${field}"]`).val(value);
                }
            });
        };

        const fillErrors = (response: ErrorResponse) => {
            form.find('#invalid-feedback-errors').removeClass('d-none').html(response.errors.join('<br />'));
        };

        const clearErrors = () => {
            form.find('#invalid-feedback-errors').addClass('d-none').html('');
        };

        input
            .pipe(
                distinctUntilChanged((a, b) => a.email === b.email),
                tap(clear),
                tap(() => toggleLoader(true)),
                switchMap((data) => {
                    return new Promise<CreateOrganisationUserResponse>((resolve) => {
                        return $.getJSON('/ajax/organisation/check-user', data)
                            .done((res) => {
                                clearErrors();
                                toggleLoader(res.is_existing);
                                resolve(res);
                            })
                            .fail((res) => {
                                fillErrors(res.responseJSON as ErrorResponse);
                            });
                    });
                })
            )
            .subscribe((response) => {
                result.next(response);
            });

        result.subscribe((response) => {
            const createResponse = response as CreateOrganisationUserResponse;
            if (canEditDetails && !createResponse.is_existing) {
                autofillFields.map((field) => {
                    form.find('[data-autofill-name="' + field + '"]')
                        .removeAttr('readonly')
                        .removeClass('disabled');
                });
            }
            fillFields(createResponse);
        });

        form.find('input[data-autofill-name="email"]').on('blur', touch);
    });
})();
