import * as React from 'react';

import {GeolocationPermission} from '../support/geolocation/geolocation_permission';
import {Modal} from '../appraising/appraise/ui/content/questions/components/modal';
import {observer} from 'mobx-react';
import {NearbyAppraisalPresenter} from './nearby_appraisal_presenter';
import {SearchAppraisal} from '../appraising/models/search_appraisal';

interface OwnProps {
    geolocationPermission: GeolocationPermission;
    presenter: NearbyAppraisalPresenter;
}

const Appraisal = ({appraisal}: {appraisal: SearchAppraisal}) => {
    return (
        <div className="row">
            <div className="col-sm-6">
                <dl>
                    <dt>Adres</dt>
                    <dd>
                        {appraisal.address} {appraisal.houseNumber}
                        <br />
                        {appraisal.postalCode} {appraisal.city}
                    </dd>
                </dl>
                <dl>
                    <dt>Type</dt>
                    <dd>{appraisal.objectDisplayType ?? '-'}</dd>
                </dl>
            </div>
            <div className="col-sm-6">
                <div className="detail-image">
                    <img src={appraisal.streetviewUrl} />
                </div>
            </div>
        </div>
    );
};

export const NearbyAppraisalModal: React.FC<OwnProps> = observer(function NearbyAppraisal(props) {
    if (props.presenter.nearbyAppraisal === null) {
        return null;
    }
    return (
        <Modal visible={props.presenter.modalVisible}>
            <div className="modal-header">
                <h2>Nabijgelegen taxatie gevonden.</h2>
            </div>

            <div>
                <div className="spacer" />

                <Appraisal appraisal={props.presenter.nearbyAppraisal} />

                <div className="modal-footer">
                    <button
                        className="btn btn-secondary ion-md-close"
                        type="button"
                        onClick={props.presenter.hideModal}
                    >
                        Sluiten
                    </button>
                    <a
                        className="btn btn-primary ion-md-arrow-forward"
                        href={`/appraisals/${props.presenter.nearbyAppraisal.id}`}
                    >
                        Openen
                    </a>
                </div>
            </div>
        </Modal>
    );
});
