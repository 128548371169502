import {makeObservable, observable, runInAction} from 'mobx';
import {AppraisalProvider} from '../../../appraising/business/appraisal_provider';
import {Appraisal} from '../../../appraising/models/appraisal';
import {AppraisalMatchStatistic} from '../../../appraising/models/appraiser_statistic';
import {AppraisalApi} from '../../../appraising/network/appraisal_api';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {Presenter} from '../../../support/presenter/presenter';

export class WidgetPresenter implements Presenter {
    @observable.ref public appraisal?: Appraisal;
    @observable.ref public matchStatistics?: AppraisalMatchStatistic;

    private subscriptions = new CompositeSubscription();

    constructor(private appraisalProvider: AppraisalProvider, private appraisalApi: AppraisalApi) {
        makeObservable(this);
    }

    public async mount(): Promise<void> {
        this.subscriptions.add(
            this.appraisalProvider.stream.subscribe((appraisal) => {
                runInAction(() => {
                    this.appraisal = appraisal;

                    this.appraisalApi.getAppraisalMatchStatistics(appraisal.id).then((matchStatistics) => {
                        runInAction(() => {
                            this.matchStatistics = matchStatistics;
                        });
                    });
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
