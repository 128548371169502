import {computed, makeObservable, observable, runInAction} from 'mobx';

import {Answer} from '../../../../../models/answer';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {NormalQuestionType} from '../../../../../enum/question_type';
import {Question} from '../../../../../models/question';
import {SimpleQuestionPresenter} from '../simple/simple_question_presenter';
import {TechnicalReference} from '../../../../../enum/technical_reference';
import {getNewestAnswer} from '../../../../../../support/get_newest_answer';

export class BuildingCostsGroupPresenter extends SimpleQuestionPresenter {
    @observable.ref public conditionAnswer?: Answer;
    @observable.ref public riskAnswer?: Answer | null;
    @observable.ref public riskQuestion?: Question | null;

    private subscriptions = new CompositeSubscription();

    constructor(...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public mount(): void {
        super.mount();

        const conditionQuestion = this.questionSet
            .findChildQuestionsByParentUuid(this.question.uuid)
            .find((q) => q.type === NormalQuestionType.BUILDING_COSTS_CONDITION);
        if (conditionQuestion && this.answer) {
            this.subscriptions.add(
                this.answerController
                    .answersForQuestionUuidAndParentAnswerUuidInSameIterationStream(
                        conditionQuestion.uuid,
                        this.answer.uuid
                    )
                    .subscribe((answers) => {
                        runInAction(() => {
                            this.conditionAnswer = getNewestAnswer(answers) ?? undefined;
                        });
                    })
            );
        }

        this.riskQuestion = this.questionSet
            .findChildQuestionsByParentUuid(this.question.uuid)
            .find((q) => q.technicalReference === TechnicalReference.BUILDING_COSTS_GROUP_RISK);
        if (this.riskQuestion && this.answer) {
            this.subscriptions.add(
                this.answerController
                    .answersForQuestionUuidAndParentAnswerUuidInSameIterationStream(
                        this.riskQuestion.uuid,
                        this.answer.uuid
                    )
                    .subscribe((answers) => {
                        runInAction(() => {
                            this.riskAnswer = getNewestAnswer(answers) ?? undefined;
                        });
                    })
            );
        }
    }

    public unmount(): void {
        super.unmount();

        this.subscriptions.clear();
    }

    @computed
    public get shouldShowCosts() {
        if (this.conditionAnswer !== undefined && this.conditionAnswer.contents) {
            const options = ['niet waarneembaar', 'redelijk', 'goed'];
            return !options.includes(this.conditionAnswer.contents);
        }
        if (this.riskAnswer && this.riskQuestion !== undefined && this.riskAnswer.answerOptionId) {
            const answerOption = this.riskQuestion?.answerOptions.find(
                (ao) => ao.contents.toLowerCase() === 'ja' || ao.reportValue === '1'
            );
            return this.riskAnswer?.answerOptionId === answerOption?.id;
        }

        return false;
    }

    @computed
    public get shouldShowPulse() {
        if (this.conditionAnswer !== undefined && this.conditionAnswer.contents) {
            const options = ['redelijk', 'goed', 'niet waarneembaar', 'nader onderzoek nodig'];
            return !options.includes(this.conditionAnswer.contents);
        }
        if (this.riskAnswer && this.riskQuestion !== undefined && this.riskAnswer.answerOptionId) {
            const answerOption = this.riskQuestion?.answerOptions.find(
                (ao) => ao.contents.toLowerCase() === 'ja' || ao.reportValue === '1'
            );
            return this.riskAnswer?.answerOptionId === answerOption?.id;
        }
        return false;
    }
}
