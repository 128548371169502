import {NetworkStatus, NetworkStatusProvider} from '../../../business/network_status_provider';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {distinctUntilChanged, filter, mergeMap, skip, takeWhile} from 'rxjs/operators';

import {Answer} from '../../../models/answer';
import {Appraisal} from '../../../models/appraisal';
import {AppraisalState} from '../../../enum/appraisal_state';
import {CompositeSubscription} from '../../../../support/composite_subscription';
import {DetailModalData} from './photo_details_modal';
import {FeatureFlags} from '../../../../feature_flags';
import {ImagesUploadInteractor} from './images_upload_interactor';
import {LocationProvider} from '../components/sidebar/sidebar_item_presenter';
import {PhotoAnswerRetryInteractor} from '../../../business/photo_answer_retry_interactor';
import {Presenter} from '../../../../support/presenter/presenter';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {IteratorQuestionType, RootGroupQuestionType} from '../../../enum/question_type';
import {isSet} from '../../../../support/is_set';
import {AppraisalValidationType} from '../../../enum/appraisal_validation_type';
import {RenderingContextType} from '../../../enum/rendering_context_type';
import {PagePartsSet} from '../../../models/page_parts_set';
import {TechnicalReference} from '../../../enum/technical_reference';

export interface CameraProgress {
    progress: number;
    type: ProgressType;
}

export enum ProgressType {
    //Initial registration of upload
    CRITICAL = 'critical',
    //Busy with image recognition
    RECOGNITION = 'recognition',
    //Just uploading
    CASUAL = 'casual',
    //Done uploading
    IDLE = 'IDLE',
    FAILURE = 'failure',
}

export interface CachedPhoto {
    file: File;
    uuid: string;
    isCached: boolean;
}

export interface AttachedPhoto {
    file: File;
    question: Question;
    answer: Answer;
}

export class CameraPresenter implements Presenter {
    @observable public progress = 0;
    @observable public progressType = ProgressType.IDLE;
    @observable.ref public detailModalData: DetailModalData | null = null;
    @observable public isAvailable = true;
    @observable public numUploadsProcessed = 0;

    //Only set the capture property if the browser actually supports this attribute
    private captureIsSupported = 'capture' in document.createElement('input');
    @observable public isOnFilesPage = false;
    @computed public get shouldUseCapture() {
        return this.captureIsSupported && !this.isOnFilesPage ? 'environment' : undefined;
    }

    private _subscriptions = new CompositeSubscription();

    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private renderingContext: RenderingContextType,
        private pagePartsSet: PagePartsSet | null,
        private photoAnswerRetryInteractor: PhotoAnswerRetryInteractor,
        private networkStatusProvider: NetworkStatusProvider,
        private onProgressChangeListener: (progress: CameraProgress) => void,
        private locationProvider: LocationProvider,
        private imagesUploadInteractor: ImagesUploadInteractor
    ) {
        makeObservable(this);
    }

    public mount() {
        this._subscriptions.add(
            this.networkStatusProvider
                .status()
                .pipe(
                    skip(1),
                    distinctUntilChanged(),
                    filter((status) => status === NetworkStatus.ONLINE),
                    mergeMap(() =>
                        this.photoAnswerRetryInteractor
                            .attempt()
                            .pipe(takeWhile((photoAnswerRetryStatus) => photoAnswerRetryStatus.queued > 0))
                    )
                )
                .subscribe()
        );

        this._subscriptions.add(
            this.locationProvider.stream().subscribe((location) => {
                runInAction(() => {
                    this.isOnFilesPage = this.checkIsOnFilesPage(location);
                });
            })
        );
    }

    public unmount() {
        this._subscriptions.clear();
    }

    @action
    public closeModal() {
        this.detailModalData = null;
    }

    @computed
    public get isDisabled(): boolean {
        if (!this.appraisal.isEditableAppraisal) {
            return true;
        }
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return false;
        }
        return (
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    public async onFilesChange(e: React.ChangeEvent<HTMLInputElement>) {
        const files = e.target.files;
        if (files !== null) {
            runInAction(() => {
                this.numUploadsProcessed++;
            });

            let rootQuestion: Question | null =
                this.questionSet.findQuestionsByType(RootGroupQuestionType.PHOTO_GROUP)[0] ?? null;
            if (
                this.renderingContext === RenderingContextType.PAGE_PARTS_APPRAISAL ||
                this.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR
            ) {
                rootQuestion = this.questionSet.findQuestionByTechnicalReference(
                    TechnicalReference.PHOTO_ITERATOR_DEFAULT
                );
            }

            await this.imagesUploadInteractor.onFiles(Array.from(files), rootQuestion, {
                updateProgress: (progress, type) => this.updateProgress(progress, type),
                onDetailModalData: (data) => runInAction(() => (this.detailModalData = data)),
                alertNotAvailable: () => this.alertNotAvailable(),
                alertIncorrectType: () => this.alertIncorrectType(),
            });
        }
    }

    public onLabelClick() {
        if (!this.isAvailable) {
            this.alertNotAvailable();
        }
        if (FeatureFlags.photoRecognition) {
            //Instead of trying to load the entire model on mount (and in turn 2MB+ on every page load)
            //Only at the moment the appraiser intents to upload a photo we're preparing the predictor
            this.imagesUploadInteractor.preparePhotoRecognition();
        }
    }

    private alertIncorrectType() {
        alert('Fout bij het uploaden. Is een correct formaat? Voor afbeeldingen kun je .jpg en .png uploaden.');
    }

    @action
    private alertNotAvailable() {
        alert(
            "Offline opslag niet beschikbaar op dit apparaat, gebruik de systeem camera app om foto's te maken en voeg deze op een later moment toe."
        );
        this.isAvailable = false;
    }

    @action
    private updateProgress(progress: number, type: ProgressType) {
        this.progress = progress;
        this.progressType = type;
        this.onProgressChangeListener({
            progress,
            type,
        });
    }

    private checkIsOnFilesPage(location: string) {
        if (
            this.pagePartsSet &&
            (this.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR ||
                this.renderingContext === RenderingContextType.PAGE_PARTS_APPRAISAL)
        ) {
            const matches = RegExp(
                /\/page-part\/(?<uuid>[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/g
            ).exec(location);
            const pagePartUuid = matches?.groups?.uuid;
            if (pagePartUuid) {
                const childPageParts = this.pagePartsSet.getChildrenForUuid(pagePartUuid);
                this.isOnFilesPage = childPageParts.some((pagePart) => {
                    if (pagePart.questionUuid) {
                        const allQuestionChildren = this.questionSet.flattenChildrenRecursively(pagePart.questionUuid);
                        return allQuestionChildren.some((q) => q.type === IteratorQuestionType.PHOTO_ITERATOR);
                    }
                    return false;
                });
            }
            return false;
        }

        const photoGroups = this.questionSet.findQuestionsByType(RootGroupQuestionType.PHOTO_GROUP);
        const children = photoGroups
            .map((dossier) => this.questionSet.findChildQuestionsByParentUuid(dossier?.uuid ?? ''))
            .flat();
        const possibleUuids = [...photoGroups.map((d) => d.uuid), ...children.map((c) => c.uuid)];

        return possibleUuids.some((uuid) => isSet(uuid) && location.includes(uuid));
    }
}
