import * as React from 'react';

import {AdaptedDefaultValuesMap} from '../../../../models/adapted_default_values_map';
import {Appraisal} from '../../../../models/appraisal';
import {Modal} from '../../content/questions/components/modal';
import {NormalQuestionType} from '../../../../enum/question_type';
import {Question} from '../../../../models/question';
import {QuestionContainer} from '../../content/questions/question_container';
import {QuestionSet} from '../../../../models/question_set';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {ValidationError} from '../../../../models/validation_error';
import {ValidationMessageMap} from '../../../../business/validation/validation_message';
import {observer} from 'mobx-react';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {PagePart} from '../../../../models/page_part';
import {QuestionRenderingData} from '../../../../models/question_rendering_data';

export interface WidgetsGroupQuestionWidgetProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    onClose: () => void;
    question: Question;
    parentAnswerUuid: string;
    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    iteration?: string;
    validationErrors?: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
    hasOnlyReadonlyQuestions: boolean;
}

@observer
export class WidgetsGroupQuestionModal extends React.Component<WidgetsGroupQuestionWidgetProps> {
    public render() {
        const question = {
            ...this.props.question,
            type: NormalQuestionType.GROUP_COMPACT,
        };

        return (
            <Modal visible forced>
                <div className="modal-header">
                    <span className="widget-overlay-title-icon ion-md-document">&nbsp;</span>
                    <h2>{this.props.question.title ?? this.props.question.contents}</h2>
                </div>
                <div className="modal-body">
                    <QuestionContainer
                        key={question.uuid + '-modal'}
                        appraisal={this.props.appraisal}
                        question={question}
                        questionSet={this.props.questionSet}
                        iteration={this.props.iteration}
                        parentAnswerUuid={this.props.parentAnswerUuid}
                        validationErrors={this.props.validationErrors ?? []}
                        forceShowValidationMessages={this.props.forceShowValidationMessages}
                        validationMessages={this.props.validationMessages}
                        adaptedDefaultValues={this.props.adaptedDefaultValues}
                        hiddenQuestionTypes={[]}
                        pagePartsSet={this.props.pagePartsSet}
                        activePagePart={this.props.activePagePart}
                        renderingContext={this.props.renderingContext}
                        questionRenderingData={this.props.questionRenderingData}
                    />
                </div>
                <div className="modal-footer">
                    {this.props.hasOnlyReadonlyQuestions ? (
                        <button
                            className="btn btn-primary btn-full ion-md-close"
                            type="button"
                            onClick={() => this.props.onClose()}
                        >
                            Sluiten
                        </button>
                    ) : (
                        <button
                            className="btn btn-primary btn-full ion-md-arrow-forward"
                            type="button"
                            onClick={() => this.props.onClose()}
                        >
                            Opslaan
                        </button>
                    )}
                </div>
            </Modal>
        );
    }
}
