import {createRoot} from 'react-dom/client';
import * as React from 'react';
import {NotesWidget} from '../appraise/ui/appraise_secondary/notes_widget/notes_widget';
import {EmptyQuestionSet} from '../models/empty_question_set';
import {ApiAppraisal, apiAppraisalToAppraisal} from '../network/models/api_appraisal';
import {ApiMacro, apiMacroToMacro} from '../network/models/api_macro';
import {usePresenter} from '../../support/presenter/use_presenter';
import {Appraisal} from '../models/appraisal';
import {QuestionSet} from '../models/question_set';
import {NotesWidgetRootPresenter} from './notes_widget_root_presenter';
import {observer} from 'mobx-react';

declare const GLOBAL: {
    appraisal: ApiAppraisal;
    notes_macros: ApiMacro[];
};

export function renderAppraisalNotesWidgetIntoInto(element: HTMLElement) {
    const appraisal = apiAppraisalToAppraisal(GLOBAL.appraisal);
    const questionSet = new EmptyQuestionSet(appraisal.questionSetId);

    questionSet.userDefinedMacros = GLOBAL.notes_macros.map(apiMacroToMacro);

    createRoot(element).render(<Root appraisal={appraisal} questionSet={questionSet} />);
}

const Root = observer((props: {appraisal: Appraisal; questionSet: QuestionSet}) => {
    usePresenter((component) => new NotesWidgetRootPresenter(props.appraisal, component.business.appraisalProvider));

    return (
        <NotesWidget
            appraisal={props.appraisal}
            questionSet={props.questionSet}
            disabled={false}
            showSavedIcon={true}
        />
    );
});
