export enum BadgeContext {
    Primary = 'badge-primary',
    Secondary = 'badge-secondary',
    Success = 'badge-success',
    Danger = 'badge-danger',
    Warning = 'badge-warning',
    Info = 'badge-info',
    Light = 'badge-light',
    Dark = 'badge-dark',
    BadgeMuchBetter = 'badge-much-better',
    BadgeBetter = 'badge-better',
    BadgeMuchWorse = 'badge-much-worse',
    BadgeWorse = 'badge-worse',
    BadgeNeutral = 'badge-neutral',
}
