import {observer} from 'mobx-react';
import React, {useMemo, useState} from 'react';
import {Select} from '../../../appraising/appraise/ui/components/select';
import {buildImageUrls} from '../../../appraising/appraise/ui/content/questions/advanced/reference_objects_question/interactors/image_urls';
import {Modal} from '../../../appraising/appraise/ui/content/questions/components/modal';
import {ObjectType} from '../../../appraising/enum/object_type';
import {ImageViewer} from '../../../appraising/components/image_viewer/image_viewer';

interface OwnProps {
    visible?: boolean;
    street?: string;
    houseNumber?: string;
    letter?: string;
    postalCode?: string;
    city?: string;

    onClose: () => void;
    storeObjectType: (objectType: ObjectType) => void;
    error: boolean;
}

export const ObjectTypeModal: React.FC<OwnProps> = observer(
    ({onClose, storeObjectType, visible, street, houseNumber, letter, postalCode, city, error}) => {
        const [objectType, setObjectType] = useState<ObjectType | null>(null);
        const [showRequiredError, setShowRequiredError] = useState<boolean>(false);

        const imageUrls = useMemo(() => {
            if (street && houseNumber && city) {
                return buildImageUrls(null, null, street, houseNumber, city, null, null, null, null);
            } else {
                return [];
            }
        }, [street, houseNumber, city]);

        return (
            <Modal visible={visible ?? true} onHide={onClose}>
                <div className="modal-header">
                    <h2>Objecttype opgeven</h2>
                </div>
                <div className="modal-body" style={{minWidth: 500}}>
                    {imageUrls.length > 0 && (
                        <div className="row">
                            <div className="col-sm-6">
                                <dl>
                                    <dt>Adres</dt>
                                    <dl>
                                        {street} {houseNumber}
                                        {letter}
                                        <br />
                                        {postalCode} {city}
                                    </dl>
                                </dl>
                            </div>
                            <div className="col-sm-6">
                                <div className="detail-image">
                                    <ImageViewer images={imageUrls.map((imageUrl) => imageUrl.url)} />
                                </div>
                            </div>
                        </div>
                    )}
                    {error && (
                        <p>
                            Het is helaas niet gelukt om automatisch het objecttype van dit object te achterhalen. Geef
                            hieronder aan om wat voor object het gaat, zodat er referenties opgehaald kunnen worden.
                        </p>
                    )}
                    {!error && (
                        <p>
                            Geef hieronder aan om wat voor object het gaat, zodat er referenties opgehaald kunnen
                            worden.
                        </p>
                    )}
                    <div className="form-group">
                        <label>Objecttype</label>
                        <Select
                            id="altum-object-type"
                            isDisabled={false}
                            onChange={(value) => setObjectType(value as ObjectType)}
                            value={objectType as string}
                            options={Object.values(ObjectType).map((value) => ({
                                label: value,
                                value: value,
                            }))}
                        />
                        {showRequiredError && (
                            <span className="invalid-feedback" role="alert">
                                Dit veld is vereist.
                            </span>
                        )}
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-secondary" onClick={() => onClose()}>
                        Sluiten
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            if (objectType) {
                                setShowRequiredError(false);
                                onClose();
                                storeObjectType(objectType);
                            } else {
                                setShowRequiredError(true);
                            }
                        }}
                    >
                        Opslaan
                    </button>
                </div>
            </Modal>
        );
    }
);
