import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {QuestionSet} from '../models/question_set';
import {filter, finalize, map, share} from 'rxjs/operators';
import {Question} from '../models/question';

export interface SearchInteractor {
    search(text: string, searchBoxUuid: string): void;
    matchSearch(
        questionUuid: string,
        hasMatch: (data: {text: string; searchBox: Question; searchGroup: Question}) => boolean
    ): Observable<string | null>;
    getMatchingChildren(questionUuid: string): Observable<string[]>;
}

export class DefaultSearchInteractor implements SearchInteractor {
    private _searchEvents = new Subject<{text: string; searchBox: Question; searchGroup: Question}>();
    private _matchingQuestions = new BehaviorSubject<Set<string>>(new Set());

    constructor(private questionSet: QuestionSet) {}

    /**
     * Trigger a search update for the specified search box question
     *
     * @param text
     * @param searchBoxUuid
     * @returns
     */
    public search(text: string, searchBoxUuid: string) {
        const question = this.questionSet.findQuestionByUuid(searchBoxUuid);
        if (!question || !question.parentUuid) {
            return;
        }

        const group = this.questionSet.findQuestionByUuid(question.parentUuid);
        if (!group) {
            return;
        }

        this._searchEvents.next({text, searchBox: question, searchGroup: group});
    }

    /**
     * For a particular question, match againt a specified search string using the match callback.
     *
     * @param questionUuid Question which provides searchable content
     * @param match Match function to determine if there was a match
     * @returns Observable providing the search string if a match was found, or null otherwise.
     */
    public matchSearch(
        questionUuid: string,
        match: (data: {text: string; searchBox: Question; searchGroup: Question}) => boolean
    ) {
        const question = this.questionSet.findQuestionByUuid(questionUuid);
        return this._searchEvents.pipe(
            filter(({searchGroup}) => {
                // Check that the questionUuid is a child of the group that the search event applies on

                if (!question) {
                    return false;
                }
                return (
                    this.questionSet.findParentByPredicateRecursive(question, (q) => q.uuid === searchGroup.uuid) !==
                    null
                );
            }),
            map((data) => {
                // Call the match function and update the matchingQuestions set accordingly

                const hasMatch = data.text && match(data);

                const newSet = new Set(this._matchingQuestions.getValue());

                if (hasMatch) {
                    newSet.add(questionUuid);
                    this._matchingQuestions.next(newSet);
                } else {
                    newSet.delete(questionUuid);
                    this._matchingQuestions.next(newSet);
                }

                return hasMatch ? data.text : null;
            }),
            finalize(() => {
                // If a component stops subscribing, we remove it from the matching questions set
                const newSet = new Set(this._matchingQuestions.getValue());
                newSet.delete(questionUuid);
                this._matchingQuestions.next(newSet);
            }),
            share()
        );
    }

    /**
     * Retrieves the list of children of the current question that match the search string
     *
     * @param questionUuid
     * @returns Observable containing the list of matching children
     */
    public getMatchingChildren(questionUuid: string) {
        const children = this.questionSet.flattenChildrenRecursively(questionUuid).map((c) => c.uuid);
        return this._matchingQuestions.pipe(
            map((matching) => {
                return children.filter((c) => matching.has(c));
            })
        );
    }
}
