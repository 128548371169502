import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {IndexPagePresenter} from './index_page_presenter';
import {Link} from 'react-router-dom';
import {Question} from '../../../../models/question';
import {QuestionSet} from '../../../../models/question_set';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';

interface OwnProps {
    question: Question;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    iteration?: string;
}

export const IndexPage: React.FC<OwnProps> = observer(function IndexPage(props) {
    const presenter = usePresenter(
        (container) =>
            new IndexPagePresenter(
                props.question,
                props.questionSet,
                container.business.questionEffectsInteractor(props.appraisal, props.questionSet)
            )
    );

    return (
        <div>
            <ul className="list-simple">
                {presenter.children.map((child) => {
                    let to = '/' + child.uuid;
                    if (props.iteration) {
                        to += '/' + props.iteration;
                    }
                    return (
                        <li key={child.uuid}>
                            <Link to={to}>{child.contents}</Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
});
