import * as React from 'react';

import {Label} from '../components/label';
import {ValidationMessages} from '../components/validation_messages';
import {classNames} from '../../../../../../support/classnames';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {observer} from 'mobx-react';
import {MultipleChoiceSelectDynamicQuestionPresenter} from './multiple_choice_select_dynamic_question_presenter';
import {
    simpleQuestionPresenterConstructorParametersFactory,
    SimpleQuestionPresenterProps,
} from '../simple/simple_question_presenter';
import ReactSelect, {components, GroupBase, OptionProps} from 'react-select';
import {isMobile} from '@tensorflow/tfjs-core/dist/device_util';
import {isTablet} from '../../../../../../support/check_mobile';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

const OTHER_OPTION = 'other_option';

interface OptionType {
    value: string;
    label: string;
}

type SelectValueType = Array<OptionType> | OptionType | null | undefined;

export const MultipleChoiceSelectDynamicQuestion: React.FunctionComponent<SimpleQuestionPresenterProps> = observer(
    (props) => {
        const presenter = usePresenter(
            (container) =>
                new MultipleChoiceSelectDynamicQuestionPresenter(
                    ...simpleQuestionPresenterConstructorParametersFactory(props, container)
                )
        );

        if (presenter.isHidden) {
            return null;
        }

        function SelectOption(props: OptionProps<OptionType, boolean, GroupBase<OptionType>>) {
            const data = props.data as {label: string; value: string};
            const onDelete = () => {
                presenter.onContentsChange({
                    ...presenter.contents,
                    options: presenter.contents.options.filter((option) => {
                        return option !== data.value;
                    }),
                });
            };

            return (
                <components.Option {...props}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>{props.children}</div>
                        {data.value !== OTHER_OPTION ? (
                            <button
                                className={'btn btn-transparent-round ion-ios-trash'}
                                onClick={(e) => {
                                    onDelete();
                                    e.stopPropagation();
                                }}
                            />
                        ) : null}
                    </div>
                </components.Option>
            );
        }

        function handleOptionsChangeMobile(newValue: string[]) {
            presenter.onContentsChange({
                ...presenter.contents,
                selected: newValue.filter((v) => v !== OTHER_OPTION),
                other: newValue.includes(OTHER_OPTION) ? presenter.contents.other ?? '' : null,
            });
        }

        function handleOptionsChange(newValue: SelectValueType) {
            const selectedOptions = Array.isArray(newValue) ? newValue : [];

            const selectedValues = selectedOptions.map((option) => option.value);

            presenter.onContentsChange({
                ...presenter.contents,
                selected: selectedValues.filter((v) => v !== OTHER_OPTION),
                other: selectedValues.includes(OTHER_OPTION) ? presenter.contents.other ?? '' : null,
            });
        }

        function handleOtherChange(newValue: string) {
            presenter.onContentsChange({
                ...presenter.contents,
                other: newValue,
            });
        }

        function renderDefault() {
            const options = presenter.contents.options.map((o) => {
                return {
                    label: o,
                    value: o,
                };
            });

            options.push({
                label: 'Anders',
                value: OTHER_OPTION,
            });

            const value = presenter.contents.selected.map((o) => {
                return {
                    label: o,
                    value: o,
                };
            });

            if (presenter.contents.other !== null) {
                value.push({
                    label: 'Anders',
                    value: OTHER_OPTION,
                });
            }

            const components = {
                Option: SelectOption,
            };

            return (
                <ReactSelect
                    id={props.question.uuid}
                    name={props.question.uuid}
                    classNamePrefix="form-multiple-select"
                    className="form-multiple-select"
                    isMulti={true}
                    components={components}
                    isClearable={false}
                    options={options}
                    isSearchable={false}
                    value={value}
                    isDisabled={props.disabled || presenter.isDisabled}
                    onChange={(newValue) => handleOptionsChange(newValue as SelectValueType)}
                    closeMenuOnSelect={false}
                    closeMenuOnScroll={true}
                    menuPortalTarget={document.getElementById('react-select-portal')}
                    placeholder={'Maak een keuze'}
                />
            );
        }

        function renderMobile() {
            const value = [...presenter.contents.selected];
            if (presenter.contents.other !== null) {
                value.push(OTHER_OPTION);
            }

            return (
                <div>
                    <select
                        id={props.question.uuid}
                        name={props.question.uuid}
                        className="form-control custom-select"
                        onChange={(e) => {
                            handleOptionsChangeMobile(
                                Array.from(e.target.selectedOptions, (option) => {
                                    return option.value;
                                })
                            );
                        }}
                        multiple={true}
                        value={value}
                        disabled={props.disabled || presenter.isDisabled}
                    >
                        {presenter.contents.options.map((o) => (
                            <option key={o} value={o}>
                                {o}
                            </option>
                        ))}
                        <option value={OTHER_OPTION}>Anders</option>
                    </select>
                    <span className="mt-2 float-left">
                        <span>Geselecteerde opties: </span>
                        {value.map((v, i, arr) => (
                            <span key={'selected-value-' + v}>
                                {v === OTHER_OPTION ? 'Anders' : v}
                                {i !== arr.length - 1 ? ', ' : ''}
                            </span>
                        ))}
                    </span>
                </div>
            );
        }
        return (
            <div
                className={classNames('form-group', presenter.filledByAutomator, {
                    'automation-filled': presenter.filledByAutomator !== null,
                })}
                data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
            >
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                {presenter.contents.options.length > 0 && (isMobile() || isTablet() ? renderMobile() : renderDefault())}
                {(presenter.contents.other !== null || presenter.contents.options.length === 0) && (
                    <>
                        {presenter.contents.options.length > 0 && (
                            <div className="label-container">
                                <span className="form-label-highlight">*</span>
                                <label>Anders</label>
                            </div>
                        )}
                        <input
                            type="text"
                            id={presenter.name}
                            name={presenter.name}
                            className="form-control"
                            disabled={props.disabled || presenter.isDisabled}
                            value={presenter.contents.other ?? ''}
                            onChange={(e) => handleOtherChange(e.target.value)}
                        />
                    </>
                )}
                <ValidationMessages
                    disabled={props.disabled || presenter.isDisabled}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    answer={presenter.answer}
                    validationMessages={filterValidationMessages(
                        props.question,
                        presenter.answer,
                        props.validationMessages,
                        presenter.validationMessages
                    )}
                />
            </div>
        );
    }
);
