import {makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {AttachmentQuestionsInteractor} from '../../../../../business/attachment_questions_interactor';
import {NormalQuestionType} from '../../../../../enum/question_type';
import {PagePartsSet} from '../../../../../models/page_parts_set';
import {Question} from '../../../../../models/question';
import {FilesGroupCustomizationType} from './files_group_customizer_presenter';
import {SimpleQuestionPresenter} from './simple_question_presenter';

export class FilesGroupPresenter extends SimpleQuestionPresenter {
    private subscriptions = new CompositeSubscription();
    @observable.ref private sortingOrder?: string[];

    constructor(
        private pagePartsSet: PagePartsSet | null,
        private attachmentQuestionsInteractor: AttachmentQuestionsInteractor,
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public mount() {
        super.mount();

        if (this.pagePartsSet) {
            this.subscriptions.add(
                this.pagePartsSet.customizations
                    .streamByQuestionUuid<FilesGroupCustomizationType>(this.question.uuid)
                    .subscribe((customization) => {
                        runInAction(() => {
                            this.sortingOrder = customization?.order;
                        });
                    })
            );
        }
    }

    public sortChildren(a: Question, b: Question) {
        if (
            (a.type !== NormalQuestionType.ATTACHMENT && a.type !== NormalQuestionType.ATTACHMENT_WIDGET) ||
            (b.type !== NormalQuestionType.ATTACHMENT && b.type !== NormalQuestionType.ATTACHMENT_WIDGET) ||
            !this.sortingOrder
        ) {
            return 0;
        }
        const aIndex = this.sortingOrder.indexOf(a.contents);
        const bIndex = this.sortingOrder.indexOf(b.contents);

        if (aIndex === -1 || bIndex === -1) {
            return 0;
        }

        return aIndex - bIndex;
    }

    public onOpenTerms(event: React.MouseEvent) {
        if (!this.attachmentQuestionsInteractor.isAttachmentModalAvailable()) {
            return; // Default behaviour of link click
        }

        event.preventDefault();

        this.attachmentQuestionsInteractor.showAttachmentModal({
            displayName: 'Opdrachtvoorwaarden',
            url: this.appraisal.termsOfEngagement.url,
        });
    }
}
