import {Presenter} from '../../../../../../../../support/presenter/presenter';
import {Answer} from '../../../../../../../models/answer';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {BlobCacheInteractor} from '../../../../../../../business/attachments/blob_cache_interactor';
import {AttachmentUploadInteractor} from '../../../../../../../business/attachments/attachment_upload_interactor';
import {FlashMessageBroadcaster, Type} from '../../../../../../../business/flash_message_broadcaster';

export class EditableImageModalPresenter implements Presenter {
    @observable.ref public answer: Answer;
    @observable.ref public file: File | null = null;
    @observable public objectUrl: string | null = null;
    @observable public isBusy = false;

    constructor(
        answer: Answer,
        private fileTypes: string[],
        private blobCacheInteractor: BlobCacheInteractor,
        private attachmentUploadInteractor: AttachmentUploadInteractor,
        private flashMessageBroadcaster: FlashMessageBroadcaster
    ) {
        makeObservable(this);

        this.answer = answer;
    }

    public async mount() {
        const file = await this.blobCacheInteractor.find(this.answer.uuid);

        if (file) {
            this.file = file;
        }
    }

    @computed
    public get url(): string | null {
        if (this.answer.file !== null) {
            return this.answer.file.url;
        }
        if (this.objectUrl === null && this.file !== null && this.file.size > 0) {
            this.createObjectUrl();
        }
        return this.objectUrl;
    }

    @computed
    public get uncompressedUrl(): string | null {
        if (this.answer.file !== null) {
            return this.answer.file.uncompressedUrl;
        }
        if (this.objectUrl === null && this.file !== null && this.file.size > 0) {
            this.createObjectUrl();
        }
        return this.objectUrl;
    }

    @action
    protected createObjectUrl() {
        if (this.file !== null) {
            this.objectUrl = URL.createObjectURL(this.file);
        }
    }

    public async persist(file: File) {
        runInAction(() => {
            this.isBusy = true;
        });

        const result = await this.attachmentUploadInteractor.uploadForAnswer(this.answer.uuid, file, {
            fileTypes: this.fileTypes,
        });

        if (!result.succeeded) {
            this.flashMessageBroadcaster.broadcast(
                result.message ?? 'Er is iets misgegaan bij het opslaan van de afbeelding.',
                Type.Danger
            );
        }
        runInAction(() => {
            this.isBusy = false;
            this.objectUrl = null;
        });
    }
}
