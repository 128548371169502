import {AppraisalNavigator} from './appraisal_navigator';

export interface UiComponent {
    appraisalNavigator: AppraisalNavigator;
}

export class DefaultUiComponent implements UiComponent {
    private _appraisalNavigator: AppraisalNavigator | undefined;

    public get appraisalNavigator(): AppraisalNavigator {
        if (this._appraisalNavigator === undefined) {
            this._appraisalNavigator = new AppraisalNavigator();
        }

        return this._appraisalNavigator;
    }
}
