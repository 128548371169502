import * as React from 'react';

import {Macro} from '../../../../../../models/macro';
import {classNames} from '../../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {SimpleTooltip} from '../../../../../../components/tooltip';
import {TextAIContextBuilder} from '../../../../../../business/textai/textai_context_builder';

interface OwnProps {
    containerRef: React.RefObject<HTMLSpanElement> | null;
    allowSearch: boolean;
    showSearch: boolean;
    searchValue: string;
    setSearch: (value: string) => void;
    setShowSearch: (show: boolean) => void;
    showAddButton: boolean;
    allowAddingMacro: boolean;
    onAddMacro: () => void;
    superHiddenMacros: Macro[];
    setShowHiddenMacros: (show: boolean) => void;
    showHiddenMacros: boolean;
    useAI: boolean | TextAIContextBuilder[] | undefined;
    isTextAIEnabled: boolean;
    promptAI: () => void;
    textAIResponse: string | null;
    textAILoading: boolean;
    toggleMacroSettingsWidget: (macro: Macro) => void;

    macros: Macro[];
    isActive: (macro: string | null) => boolean;
    onUseMacro: (macro: string | null) => void;
    onFavorite?: (macro: Macro) => void;
    hasAdvancedOptions: boolean;
    onRemove?: (macro: Macro) => void;
}

export const MacrosComponent: React.FC<OwnProps> = observer((props) => {
    const {
        containerRef,
        allowSearch,
        showSearch,
        searchValue,
        setSearch,
        setShowSearch,
        showAddButton,
        allowAddingMacro,
        onAddMacro,
        superHiddenMacros,
        setShowHiddenMacros,
        showHiddenMacros,
        useAI,
        isTextAIEnabled,
        promptAI,
        textAIResponse,
        textAILoading,
        toggleMacroSettingsWidget,
        macros,
        isActive,
        onUseMacro,
        onFavorite,
        hasAdvancedOptions,
        onRemove,
    } = props;

    return (
        <span className="macros-container" ref={containerRef}>
            <div className="macros-header">
                <strong className="flex-grow-1">Macro&apos;s</strong>
                {allowSearch && (
                    <div className="input-group">
                        <input
                            className={classNames('form-control', {
                                active: showSearch,
                            })}
                            type="text"
                            placeholder="Zoeken"
                            value={searchValue}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                        />
                        <div className="input-group-append">
                            <button
                                type="button"
                                className="btn btn-secondary btn-append"
                                onClick={() => {
                                    setSearch('');
                                    setShowSearch(!showSearch);
                                }}
                            >
                                <span className={showSearch ? 'ion-md-close' : 'ion-md-search'}></span>
                            </button>
                        </div>
                    </div>
                )}
                {allowAddingMacro && (
                    <button
                        type="button"
                        className={classNames('btn btn-secondary btn-sm ion-md-save btn-macro', {
                            disabled: !showAddButton,
                            'btn-macro-small': showSearch,
                        })}
                        onClick={onAddMacro}
                        disabled={!showAddButton}
                    >
                        <span className="d-none d-md-inline">Maak macro</span>
                    </button>
                )}
                {superHiddenMacros.length > 0 && (
                    <button
                        type="button"
                        className={classNames('btn btn-secondary btn-sm ion-md-list btn-macro', {
                            'btn-macro-small': showSearch,
                        })}
                        onClick={() => setShowHiddenMacros(!showHiddenMacros)}
                    >
                        <span className="d-none d-md-inline">{showHiddenMacros ? 'Verberg' : 'Toon alle'}</span>
                    </button>
                )}
                {useAI && isTextAIEnabled && (
                    <SimpleTooltip content="Vul het antwoord in of aan met behulp van AI.">
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm btn-single-icon btn-macro ion-md-color-wand"
                            onClick={() => promptAI()}
                        />
                    </SimpleTooltip>
                )}
            </div>

            {macros.length > 0 && (
                <ul className="macros">
                    {searchValue.length > 0 &&
                    ((showHiddenMacros && superHiddenMacros.length === 0) || macros.length === 0) ? (
                        <span>Geen macro&apos;s gevonden</span>
                    ) : (
                        <>
                            {macros.map((macro) => renderMacro(macro))}
                            {showHiddenMacros && superHiddenMacros.map((macro) => renderMacro(macro))}
                        </>
                    )}
                </ul>
            )}

            {textAIResponse !== null && (
                <ul className="macros macros-ai">
                    <li
                        className={classNames({
                            active: !textAILoading && isActive(textAIResponse),
                            'ai-loading': textAILoading,
                        })}
                    >
                        <button
                            type="button"
                            className="macro ai-macro"
                            onClick={() => !textAILoading && onUseMacro(textAIResponse ?? '')}
                            title={textAIResponse}
                            style={{whiteSpace: 'pre-wrap'}}
                        >
                            {textAIResponse}
                            {textAILoading && <span className="ai-cursor">|</span>}
                        </button>
                        <span className="macro-ai-icon ion-md-color-wand" />
                    </li>
                </ul>
            )}
        </span>
    );

    function renderMacro(macro: Macro) {
        return (
            <li
                key={macro.id}
                className={classNames({
                    active: isActive(macro.contents),
                })}
            >
                <button
                    type="button"
                    className="macro"
                    onClick={() => onUseMacro(macro.contents)}
                    title={macro.contents ?? ''}
                >
                    {macro.contents}
                </button>
                <span className="macro-buttons">
                    {onFavorite && (
                        <SimpleTooltip
                            content={
                                macro.favorite
                                    ? 'Deze favoriete macro wordt automatisch ingevuld.'
                                    : 'Stel deze macro als favoriet in zodat deze automatisch ingevuld zal worden.'
                            }
                            placement="top"
                        >
                            <button
                                type="button"
                                className={classNames('ion-md-star', {
                                    active: macro.favorite,
                                })}
                                onClick={() => onFavorite?.(macro)}
                            />
                        </SimpleTooltip>
                    )}
                    {hasAdvancedOptions ? (
                        <button
                            type="button"
                            className="ion-md-create"
                            onClick={() => toggleMacroSettingsWidget(macro)}
                        />
                    ) : onRemove != null ? (
                        <button type="button" className="ion-md-close-circle" onClick={() => onRemove(macro)} />
                    ) : null}
                </span>
            </li>
        );
    }
});
