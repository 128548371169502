import {makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {NormalQuestionType} from '../../../../../enum/question_type';
import {PagePartsSet} from '../../../../../models/page_parts_set';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';

export type IteratorFilesCustomizationType = {
    order: string[];
};

export class IteratorFilesCustomizerPresenter implements Presenter {
    @observable public sortOrder: string[] | null = null;

    private subscriptions = new CompositeSubscription();

    constructor(private question: Question, private pagePartsSet: PagePartsSet, private questionSet: QuestionSet) {
        makeObservable(this);
    }

    public async mount(): Promise<void> {
        this.subscriptions.add(
            this.pagePartsSet.customizations
                .streamByQuestionUuid<IteratorFilesCustomizationType>(this.question.uuid)
                .subscribe((customization) => {
                    runInAction(() => {
                        this.sortOrder = customization?.order ?? [];

                        for (const element of this.getDefaultSortOrder()) {
                            if (!this.sortOrder.includes(element)) {
                                this.sortOrder.push(element);
                            }
                        }
                    });
                })
        );
    }

    private getDefaultSortOrder() {
        const answerOptions = this.questionSet
            .findChildQuestionsByParentUuid(this.question.uuid)
            .filter((q) => q.type === NormalQuestionType.MC_SELECT)
            .flatMap((q) => q.answerOptions);

        return new Set(answerOptions.map((o) => o.contents));
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public move(label: string, index: number) {
        if (!this.sortOrder) {
            return;
        }
        const order = [...this.sortOrder];

        const oldIndex = order.indexOf(label);
        if (oldIndex === -1) {
            return;
        }

        order.splice(oldIndex, 1);
        order.splice(index, 0, label);

        this.pagePartsSet.customizations.setForQuestionUuid<IteratorFilesCustomizationType>(this.question.uuid, {
            order,
        });
    }

    public getDisplayLabel(label: string): string {
        return label.charAt(0).toUpperCase() + label.slice(1);
    }
}
