import {makeObservable, observable, runInAction} from 'mobx';
import {AppraisalProvider} from '../../../appraising/business/appraisal_provider';
import {ObjectImage} from '../../../appraising/models/object_image';
import {AppraisalApi} from '../../../appraising/network/appraisal_api';
import {Presenter} from '../../../support/presenter/presenter';

const animations = ['pan-left', 'pan-right', 'zoom-in', 'zoom-out'] as const;

type WithAnimation<T> = T & {animation?: (typeof animations)[number]};

export class ImagesPresenter implements Presenter {
    @observable.ref public images?: WithAnimation<ObjectImage>[];

    constructor(private appraisalProvider: AppraisalProvider, private appraisalApi: AppraisalApi) {
        makeObservable(this);
    }

    public async mount(): Promise<void> {
        this.appraisalApi.getObjectImages(this.appraisalProvider.appraisal.id).then((images) => {
            runInAction(() => {
                // Animate when we have multiple images
                if (images.length > 1) {
                    this.images = images.map((image, i) => {
                        if (image.source === 'streetview') {
                            return {
                                ...image,
                                animation: 'zoom-in', // Panning doesn't work well with the streetview images
                            };
                        }

                        return {
                            ...image,
                            animation: animations[i % animations.length],
                        };
                    });
                } else {
                    this.images = images;
                }
            });
        });
    }

    public unmount(): void {
        /* Noop */
    }
}
