import * as React from 'react';

import {classNames} from '../../support/classnames';
import {energyLabelDiffToBadgeContext} from './badges/badge_context_calculators';

interface OwnProps {
    label: string;
    value: string | null;
    referenceValue: string | null;
}

export class StatusDetailListForEnergyLabel extends React.Component<OwnProps> {
    public render() {
        if (this.props.value !== null) {
            const badgeContext = energyLabelDiffToBadgeContext(this.props.value, this.props.referenceValue);
            const badge = this.props.referenceValue || '';

            return (
                <dl>
                    <dt>{this.props.label}</dt>
                    <dd>
                        <span className={classNames('badge-container-wide', badgeContext)}>
                            {this.props.value || '-'}
                            {badge.length > 0 ? (
                                <span className={classNames('badge', badgeContext)}>{badge}</span>
                            ) : null}
                        </span>
                    </dd>
                </dl>
            );
        }

        return (
            <dl>
                <dt>{this.props.label}</dt>
                <dd>
                    <span className={classNames('badge-container-wide')}>{this.props.value || '-'}</span>
                </dd>
            </dl>
        );
    }
}
