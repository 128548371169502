import {action, makeObservable, observable, runInAction} from 'mobx';
import {MacroInteractor} from '../../../appraising/business/macro_interactor';
import {Macro, MacroExternalType} from '../../../appraising/models/macro';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {isEmpty} from '../../../support/util';
import {Presenter} from '../../../support/presenter/presenter';

export class RejectModalPresenter implements Presenter {
    @observable.ref public macros?: Macro[];
    @observable public rejectReason = '';

    private subscriptions = new CompositeSubscription();

    constructor(private macroInteractor: MacroInteractor) {
        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            this.macroInteractor
                .macrosForExternalType(MacroExternalType.REFEFENCES_REJECT_REASON)
                .subscribe((macros) => {
                    runInAction(() => {
                        this.macros = macros;

                        const favoriteMacro = macros.find((m) => m.favorite);
                        if (favoriteMacro && isEmpty(this.rejectReason) && favoriteMacro.contents) {
                            this.rejectReason = favoriteMacro.contents;
                        }
                    });
                })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    public onRejectReasonChange = (rejectReason: string) => {
        this.rejectReason = rejectReason;
    };

    public onAddAsMacroClick = async () => {
        if (this.rejectReason !== '') {
            try {
                await this.macroInteractor.storeExternal(this.rejectReason, MacroExternalType.REFEFENCES_REJECT_REASON);
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };

    public onRemoveMacroClick = async (toBeRemovedMacro: Macro) => {
        if (this.macros) {
            try {
                const macro = this.macros.find((m) => m.id === toBeRemovedMacro.id);
                if (macro) {
                    if (!macro.isUserDefined) {
                        await this.macroInteractor.hideForUser(macro.id);
                    } else {
                        await this.macroInteractor.destroy(macro.id);
                    }
                }
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };

    public onFavoriteMacroClick = async (toBeFavoritedMacro: Macro) => {
        if (this.macros) {
            try {
                const macro = this.macros.find((m) => m.id === toBeFavoritedMacro.id);
                if (macro) {
                    await this.macroInteractor.toggleFavorite(macro.id);
                }
            } catch (e) {
                /* Noop */
                console.warn(e);
            }
        }
    };
}
