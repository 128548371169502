import * as React from 'react';

import {Appraisal} from '../../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {isEmpty} from '../../../../../../../../../support/util';
import {observer} from 'mobx-react';
import {getPriceOrPriceRangeFloorArea, PriceRangeType} from '../internal/get_object_price';

interface OwnProps {
    referenceObjectFloorArea: number | null;
    referenceObjectIndexedPrice: string | number | null;
    referenceObjectPriceRange: string | number | null;
    compact?: boolean;
    questionSet: QuestionSet;
    appraisal: Appraisal;
}

@observer
export class FloorAreaPriceRent extends React.Component<OwnProps> {
    private formatPrice(priceOrPriceRange: number | PriceRangeType): string {
        if (Array.isArray(priceOrPriceRange)) {
            return `€ ${priceOrPriceRange[0].toLocaleString('nl-NL')} - ${priceOrPriceRange[1].toLocaleString(
                'nl-NL'
            )} / m²`;
        }
        return `€ ${priceOrPriceRange.toLocaleString('nl-NL')}/m²`;
    }

    public render() {
        let priceOrPriceRange: string | number | null = null;

        if (
            this.props.referenceObjectIndexedPrice !== null &&
            !isEmpty(String(this.props.referenceObjectIndexedPrice).trim())
        ) {
            priceOrPriceRange = this.props.referenceObjectIndexedPrice;
        } else if (
            this.props.referenceObjectPriceRange !== null &&
            !isEmpty(String(this.props.referenceObjectPriceRange).trim())
        ) {
            priceOrPriceRange = this.props.referenceObjectPriceRange;
        }

        if (
            priceOrPriceRange === null ||
            this.props.referenceObjectFloorArea === null ||
            this.props.referenceObjectFloorArea === 0
        ) {
            return <>{'-'}</>;
        }

        const priceOrPriceRangePerM2 = getPriceOrPriceRangeFloorArea(
            priceOrPriceRange,
            this.props.referenceObjectFloorArea
        );

        return (
            <>
                <span>{this.formatPrice(priceOrPriceRangePerM2)}</span>
            </>
        );
    }
}
