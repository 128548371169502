import * as React from 'react';

import {ActiveSetDefinitionState, ReferenceObjectsQuestionPresenter} from './reference_objects_question_presenter';

import {DefaultSuggestedReferenceObjectAnswersCalculator} from './calculators/suggested_reference_object_answers_calculator';
import {Loader} from '../../../../../components/loader';
import {NetworkStatus} from '../../../../../../../business/network_status_provider';
import {ReferenceObjectGrid} from './components/reference_object_grid';
import {ReferenceObjectModals} from './components/reference_object_modals';
import {SetDefinitionTabs} from './components/set_definition_tabs';
import {SimpleQuestionPresenterProps} from '../../../simple/simple_question_presenter';
import {ValuationAnswersContainer} from './components/valuation_answers_container';
import {classNames} from '../../../../../../../../support/classnames';
import {humanizeArray} from '../../../../../support/humanize_array';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../../support/presenter/use_presenter';

export const ReferenceObjectsQuestion: React.FunctionComponent<SimpleQuestionPresenterProps> = observer((props) => {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectsQuestionPresenter(
                props.appraisal,
                props.question,
                props.questionSet,
                container.business.appraisalProvider,
                props.parentAnswerUuid,
                props.iteration,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.v2ReferenceSetsProvider(props.appraisal, props.questionSet),
                container.business.referenceObjectsInteractor(props.appraisal, props.questionSet),
                new DefaultSuggestedReferenceObjectAnswersCalculator(),
                container.business.appraiseSecondaryConfigStackInteractor,
                container.business.networkStatusProvider,
                container.business.answerInteractor(props.appraisal, props.questionSet)
            )
    );

    if (presenter.referenceSets === null) {
        return (
            <div className="row">
                <div className="col-12 text-center">
                    <Loader icon="ion-md-pin" />
                </div>
            </div>
        );
    }

    function renderContent() {
        if (presenter.networkStatus === NetworkStatus.OFFLINE) {
            return (
                <div className="col-12">
                    <div className="alert-inline alert-danger ion-md-close-circle">
                        <p>
                            <small>
                                Deze functie is offline niet beschikbaar, zorg voor een stabiele internet verbinding en
                                zorg ervoor dat alle data opgeslagen is.
                            </small>
                        </p>
                    </div>
                </div>
            );
        }

        if (presenter.activeSetDefinition) {
            return (
                <>
                    <ValuationAnswersContainer
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        validationErrors={props.validationErrors}
                        validationMessages={props.validationMessages}
                        forceShowValidationMessages={props.forceShowValidationMessages}
                        activeSetDefinition={presenter.activeSetDefinition}
                        disabled={presenter.activeSetDefinition.state === ActiveSetDefinitionState.DISABLED}
                        suggestedReferenceObjectAnswers={presenter.suggestedReferenceObjectAnswer}
                        onAddMany={presenter.onAddMany}
                        valuationAnswersChanged={false}
                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                        pagePartsSet={props.pagePartsSet}
                        activePagePart={props.activePagePart}
                        renderingContext={props.renderingContext}
                        questionRenderingData={props.questionRenderingData}
                    />
                    <div className="col-12">
                        {presenter.activeSetDefinition.missingPreconditions.length > 0 ? (
                            <div className="alert-inline alert-danger ion-md-close-circle">
                                <p>
                                    Niet alle benodigde informatie is beschikbaar om referentieobjecten op te halen.
                                    Controleer of de volgende vragen zijn beantwoord:{' '}
                                    {humanizeArray(presenter.activeSetDefinition.missingPreconditions)}.
                                </p>
                            </div>
                        ) : (
                            <ReferenceObjectGrid
                                questionSet={props.questionSet}
                                appraisal={props.appraisal}
                                isComparing={presenter.isComparing}
                                isSavingAnswer={presenter.isSavingAnswer}
                                isUpdatingReferenceObjects={
                                    presenter.activeSetDefinition.state === ActiveSetDefinitionState.DISABLED
                                }
                                sortingMethod={presenter.sortingMethod}
                                sortingDirection={presenter.sortingDirection}
                                updateSortingMethod={presenter.updateSortingMethod}
                                updateIsComparing={presenter.updateIsComparing}
                                activeSetDefinition={presenter.activeSetDefinition}
                                visibleReferenceObjects={presenter.visibleReferenceObjects}
                                preselectedReferenceObjects={[]}
                                canAdd={presenter.canAdd}
                                onAddAndOpenModal={presenter.onAddAndOpenModal}
                                onRemove={presenter.onRemove}
                                numVisible={presenter.numVisible}
                                showMoreReferenceObjects={presenter.showMoreReferenceObjects}
                                showAnswerModal={presenter.showAnswerModal}
                                showDetailsModal={presenter.showDetailsModal}
                                hoveringReferenceObject={presenter.hoveringReferenceObject}
                                clickedReferenceObject={presenter.clickedReferenceObject}
                                setHoveringReferenceObject={presenter.setHoveringReferenceObject}
                                setClickedReferenceObject={presenter.setClickedReferenceObject}
                            >
                                {presenter.isFrozen ? null : (
                                    <button
                                        className={classNames('btn btn-sm ion-md-add float-right', {
                                            disabled: !presenter.canAdd,
                                        })}
                                        aria-disabled={!presenter.canAdd}
                                        onClick={() =>
                                            presenter.canAdd && presenter.onAddCustomReferenceSaleButtonClick()
                                        }
                                    >
                                        Handmatig toevoegen
                                    </button>
                                )}
                            </ReferenceObjectGrid>
                        )}
                    </div>
                </>
            );
        }

        return (
            <div className="col-12">
                <div className="alert-inline alert-danger ion-md-close-circle">
                    <p>Er zijn nog geen waarderingen ingevoerd om referenties voor toe te voegen.</p>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="form-group">
                <div className="row">
                    <SetDefinitionTabs
                        appraisal={props.appraisal}
                        isDisabled={
                            presenter.activeSetDefinition === null ||
                            presenter.activeSetDefinition.state === ActiveSetDefinitionState.DISABLED
                        }
                        referenceSets={presenter.referenceSets}
                        activeSetDefinition={presenter.activeSetDefinition}
                        setActiveSetDefinition={presenter.setActiveSetDefinition}
                        minReferenceObjects={presenter.minReferenceObjects}
                    />

                    {renderContent()}
                </div>
            </div>

            <ReferenceObjectModals
                appraisal={props.appraisal}
                questionSet={props.questionSet}
                modalState={presenter.modalState}
                activeSetDefinition={presenter.activeSetDefinition}
                isFrozen={presenter.isFrozen}
                isSavingAnswer={presenter.isSavingAnswer}
                onModalHide={presenter.onModalHide}
                onAdd={presenter.onAdd}
                onAddAndOpenModal={presenter.onAddAndOpenModal}
                onRemove={presenter.onRemove}
                onChange={presenter.onChange}
                disabled={props.disabled}
            />
        </>
    );
});
