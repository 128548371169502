import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';

import * as React from 'react';

import {Appraisal} from '../../../../../../models/appraisal';
import {AutoMLVersion} from '../../../../../../business/ml/vision/automl_photo_content_predicter';
import {DragDrop} from '@uppy/react';
import {ImagesUploadInteractor} from '../../../images_upload_interactor';
import {PhotoDetailsModal} from '../../../photo_details_modal';
import {PhotoDropZonePresenter} from './photo_drop_zone_presenter';
import {PhotoProgressBar} from './photo_progress_bar';
import {ProgressType} from '../../../camera_presenter';
import {QuestionSet} from '../../../../../../models/question_set';
import {QuestionType} from '../../../../../../enum/question_type';
import {RenderingContextType} from '../../../../../../enum/rendering_context_type';
import {SimpleQuestionPresenterProps} from '../../simple/simple_question_presenter';
import {ValidationError} from '../../../../../../models/validation_error';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {
    questionSet: QuestionSet;
    appraisal: Appraisal;
    validationErrors: ValidationError[];
    hiddenQuestionTypes: QuestionType[];
    renderingContext: RenderingContextType;
}

export const PhotoDropZone: React.FC<OwnProps> = observer(function PhotoDropZone(props) {
    const presenter = usePresenter(
        (container) =>
            new PhotoDropZonePresenter(
                props.appraisal,
                props.questionSet,
                new ImagesUploadInteractor(
                    container.business.blobCacheInteractor(),
                    container.business.answerInteractor(props.appraisal, props.questionSet),
                    container.business.imageUploadInteractor(props.appraisal, props.questionSet),
                    container.business.photoRecognitionHandler(
                        props.appraisal,
                        props.questionSet,
                        AutoMLVersion.DEFAULT,
                        props.renderingContext
                    ),
                    container.business.flashMessageBroadcaster,
                    container.business.imageOrientationFixer,
                    container.business.answerController(props.appraisal, props.questionSet)
                ),
                props.renderingContext
            )
    );

    return (
        <>
            {presenter.detailModalData !== null && (
                <PhotoDetailsModal
                    detailsModalData={presenter.detailModalData}
                    isDisabled={props.disabled || presenter.isDisabled}
                    onClose={() => presenter.onCloseModal()}
                    questionSet={props.questionSet}
                    appraisal={props.appraisal}
                    validationErrors={props.validationErrors}
                    validationMessages={props.validationMessages}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    hiddenQuestionTypes={props.hiddenQuestionTypes}
                    renderingContext={props.renderingContext}
                />
            )}
            {!presenter.isDisabled &&
                !props.disabled &&
                presenter.isAvailable &&
                presenter.progress.type === ProgressType.IDLE && (
                    <div className="form-group">
                        <DragDrop
                            className="dropzone"
                            width="100%"
                            height="150px"
                            uppy={presenter.uppy}
                            locale={{
                                strings: {
                                    browse: 'blader',
                                    dropHereOr: "Sleep hier je foto's naartoe of %{browse}",
                                },
                            }}
                        />
                        {presenter.errorMessage != null && (
                            <span className="invalid-feedback">
                                <div>{presenter.errorMessage}</div>
                            </span>
                        )}
                    </div>
                )}
            {!presenter.isDisabled &&
                !props.disabled &&
                presenter.isAvailable &&
                presenter.progress.type !== ProgressType.IDLE && (
                    <PhotoProgressBar percentage={presenter.progress.percentage} type={presenter.progress.type} />
                )}
        </>
    );
});
