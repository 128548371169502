import * as React from 'react';

import {FunctionComponent} from 'react';
import {classNames} from '../../../../../../support/classnames';

export const DummyFileGroup: FunctionComponent<{className: string}> = ({className}) => {
    return (
        <div className={classNames(className, 'list-item-attachment')}>
            <span className="list-avatar">&nbsp;</span>
            <span className="list-title list-title-download">
                <span className="placeholder placeholder-width-100" />
            </span>
            <small className="list-details list-details-download">
                <span className="placeholder placeholder-width-200" />
            </small>
        </div>
    );
};

export const DummyFilesGroup: FunctionComponent = () => {
    return (
        <div className="list-attachments">
            <DummyFileGroup className="placeholder-opaque-80" />
            <DummyFileGroup className="placeholder-opaque-70" />
            <DummyFileGroup className="placeholder-opaque-60" />
            <DummyFileGroup className="placeholder-opaque-50" />
            <DummyFileGroup className="placeholder-opaque-40" />
            <DummyFileGroup className="placeholder-opaque-30" />
        </div>
    );
};
