import {DefaultPhotoContentPredicter, HowSureAreWeLevel, PredictionResult} from '../ml/vision/photo_content_predicter';
import {IteratorQuestionType, NormalQuestionType, RootGroupQuestionType} from '../../enum/question_type';

import {Answer} from '../../models/answer';
import {AnswerController} from './answer_controller';
import {AnswerTouchState} from '../../enum/answer_touch_state';
import {FeatureFlags} from '../../../feature_flags';
import {Question} from '../../models/question';
import {QuestionSet} from '../../models/question_set';
import {bugsnagClient} from '../../../support/bugsnag_client';
import {findChildrenForQuestion} from '../../appraise/ui/support/question_filtering';
import {AnswerPathStubber} from './answer_path_stubber';
import {TechnicalReference} from '../../enum/technical_reference';

export interface PhotoRecognitionHandler {
    prepare: () => void;
    getPhotoQuestion(photoGroupQuestion: Question | null): Question | null;
    preparePhotoAnswers(photoQuestion: Question): {question: Question; answer: Answer} | null;

    handle(
        file: File | string,
        photoQuestion: Question,
        photoAnswer: Answer,
        verySureLevel: number,
        notToSureLevel: number
    ): Promise<PredictionResult | null>;
}

export interface PhotoRecognitionResult {
    answerUuid: string | null;
    questionUuid: string | null;
    buildingCostsLabel: string | null;
}

export class DefaultPhotoRecognitionHandler implements PhotoRecognitionHandler {
    constructor(
        private questionSet: QuestionSet,
        private photoContentPredicter: DefaultPhotoContentPredicter,
        private answerController: AnswerController,
        private answerPathStubber: AnswerPathStubber
    ) {}

    public prepare() {
        //Loads the ML model for photo recognition
        this.photoContentPredicter.prepare();
    }

    public getPhotoQuestion(photoGroupQuestion: Question | null): Question | null {
        //The photo group consists of a nested set of question types:
        if (
            photoGroupQuestion === null ||
            (photoGroupQuestion.type !== RootGroupQuestionType.PHOTO_GROUP &&
                photoGroupQuestion.type !== NormalQuestionType.PHOTO_DROP_ZONE_SINGLE &&
                photoGroupQuestion.type !== NormalQuestionType.BUILDING_COSTS_PHOTO_GROUP &&
                photoGroupQuestion.type !== IteratorQuestionType.PHOTO_ITERATOR &&
                photoGroupQuestion.technicalReference !== TechnicalReference.PHOTO_ITERATOR_DEFAULT &&
                photoGroupQuestion.technicalReference !== TechnicalReference.PHOTO_ITERATOR_CONSTRUCTION)
        ) {
            throw new Error(
                `The given question ${
                    photoGroupQuestion?.uuid ?? 'is NULL'
                }, this should as one of these types: "photo-group", "building-costs-photo-group", "photo-iterator", "photo-drop-zone-single", of technical reference "photo-iterator-default" or "photo-iterator-construction".`
            );
        }

        if (!photoGroupQuestion) {
            return null;
        }

        if (photoGroupQuestion.type === NormalQuestionType.PHOTO_DROP_ZONE_SINGLE) {
            return photoGroupQuestion;
        }

        const questionPath = this.questionSet.findChildrenPathByPredicateRecursive(
            photoGroupQuestion,
            (question) =>
                question.type === NormalQuestionType.PHOTO || question.type === NormalQuestionType.BUILDING_COSTS_PHOTO
        );
        return questionPath?.at(-1) ?? null;
    }

    public preparePhotoAnswers(photoQuestion: Question): {question: Question; answer: Answer} | null {
        if (photoQuestion) {
            const answerPath = this.answerPathStubber.stubPathForQuestionUuid(photoQuestion.uuid);
            const lastPair = answerPath.at(-1);
            if (lastPair) {
                return {
                    question: lastPair.question,
                    answer: lastPair.answer,
                };
            }
        }
        return null;
    }

    public async handle(
        file: File | string,
        photoQuestion: Question,
        photoAnswer: Answer,
        verySureLevel: number,
        notToSureLevel: number
    ): Promise<PredictionResult | null> {
        if (FeatureFlags.photoRecognition) {
            return await this.handlePhotoRecognition(file, photoQuestion, photoAnswer, verySureLevel, notToSureLevel);
        }

        return null;
    }

    private async handlePhotoRecognition(
        file: File | string,
        photoQuestion: Question,
        photoAnswer: Answer,
        verySureLevel: number,
        notToSureLevel: number
    ): Promise<PredictionResult> {
        if (photoQuestion.type === NormalQuestionType.PHOTO_DROP_ZONE_SINGLE) {
            return {
                className: null,
                description: null,
                howSure: HowSureAreWeLevel.Unsure,
                answerOptionId: null,
            };
        }

        try {
            const mcPhotoQuestion = findChildrenForQuestion(photoQuestion, this.questionSet).find(
                (child) => child.type === NormalQuestionType.MC_SELECT
            );
            if (mcPhotoQuestion === undefined) {
                throw new Error('Could not find mc photo question');
            }

            const predictionResult = await this.photoContentPredicter.predict(
                file,
                mcPhotoQuestion.answerOptions,
                verySureLevel,
                notToSureLevel
            );

            //Register the answer for the label
            if (predictionResult.answerOptionId) {
                const mcAnswer = this.answerController.answerByIdentifiersOrStub(
                    mcPhotoQuestion.uuid,
                    photoAnswer.uuid,
                    null
                );

                this.answerController.onAnswerOptionChange(
                    mcAnswer.uuid,
                    predictionResult.answerOptionId,
                    AnswerTouchState.UNTOUCHED
                );
            }
            return predictionResult;
        } catch (e) {
            //Swallow all error since recognition is secondary
            console.error(e);
            bugsnagClient?.notify(e);

            return {
                className: null,
                description: null,
                howSure: HowSureAreWeLevel.Unsure,
                answerOptionId: null,
            };
        }
    }
}
