import * as React from 'react';

import {
    ConstructionCostDuration,
    DefinedConstructionCostsLine,
} from '../../../../../models/validation_institute_constructional_defect';
import {isEmpty, isNumeric} from '../../../../../../support/util';

import NumberFormat from 'react-number-format';
import {formatLabel} from '../../../support/building_costs_reference_formatter';
import {formatMoney} from '../../../support/format_money';
import {humanizeUnit} from '../../../support/humanize_unit';
import {isInEnum} from '../../../../../../support/is_in_enum';

export interface OwnProps {
    constructionCost: DefinedConstructionCostsLine;
    onChangeLine: (line: DefinedConstructionCostsLine) => void;
    onDelete: () => void;
    isDisabled: boolean;
}

interface OwnState {
    description: string;
}

export class ConstructionCostCalculator extends React.Component<OwnProps, OwnState> {
    public state: OwnState = {
        description: this.props.constructionCost.description,
    };

    public render() {
        return (
            <div className="form-group construction-cost-calculator">
                <span className="construction-cost-calculator__description">
                    {this.props.constructionCost.isCustom ? (
                        <div className="input-group input-group-description">
                            <input
                                className="form-control"
                                type="text"
                                value={this.state.description}
                                onChange={(e) => {
                                    this.setState({description: e.target.value});
                                }}
                                onBlur={() => {
                                    this.props.onChangeLine({
                                        ...this.props.constructionCost,
                                        description: this.state.description,
                                    });
                                }}
                            />
                        </div>
                    ) : (
                        formatLabel(this.props.constructionCost.description)
                    )}
                </span>

                {this.props.constructionCost.isCustom ? (
                    <div className="input-group input-group-has-prepend">
                        <div className="input-group-prepend">
                            <span className="input-group-text">€</span>
                        </div>
                        <NumberFormat
                            value={this.props.constructionCost.costsCents / 100}
                            displayType="input"
                            type="text"
                            className="form-control"
                            disabled={this.props.isDisabled}
                            decimalScale={0}
                            allowNegative={false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value;
                                if (isNumeric(value) && isNumeric(parseInt(value, 10))) {
                                    this.props.onChangeLine({
                                        ...this.props.constructionCost,
                                        costsCents: parseInt(value, 10) * 100,
                                    });
                                }
                            }}
                        />
                    </div>
                ) : (
                    <span>{formatMoney(this.props.constructionCost.costsCents / 100)}</span>
                )}

                {!this.props.constructionCost.isCustom && (
                    <div className="input-group input-group-has-append">
                        <NumberFormat
                            value={this.props.constructionCost.amount}
                            displayType="input"
                            type="text"
                            className="form-control"
                            decimalScale={0}
                            allowNegative={false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value;
                                if (!isEmpty(value)) {
                                    if (isNumeric(value) && isNumeric(parseInt(value, 10))) {
                                        this.props.onChangeLine({
                                            ...this.props.constructionCost,
                                            amount: parseInt(value, 10),
                                        });
                                    }
                                } else {
                                    this.props.onChangeLine({...this.props.constructionCost, amount: null});
                                }
                            }}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text">{humanizeUnit(this.props.constructionCost.unit)}</span>
                        </div>
                    </div>
                )}

                <select
                    className="form-control custom-select"
                    onChange={(e) => {
                        const value = e.target.value;
                        if (isInEnum(ConstructionCostDuration, value)) {
                            this.props.onChangeLine({...this.props.constructionCost, duration: value});
                        }
                    }}
                    value={this.props.constructionCost.duration}
                >
                    <option value={ConstructionCostDuration.DIRECT}>Direct</option>
                    <option value={ConstructionCostDuration.INDIRECT}>1-5 jr.</option>
                    <option value={ConstructionCostDuration.BOTH}>Beide</option>
                </select>

                <button
                    onClick={() => {
                        this.props.onDelete();
                    }}
                    className="btn btn-delete btn-sm ion-md-trash"
                ></button>
            </div>
        );
    }
}
