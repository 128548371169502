import {QuestionAnswerPair, buildQuestionAnswerTrees} from '../../support/question_answer_tree';
import {TreeItem, findChildRecursiveByPredicate} from '../../support/generic_tree';
import {debounceTime, first, map, shareReplay, switchMap} from 'rxjs/operators';

import {Answer} from '../models/answer';
import {AnswerController} from './answering/answer_controller';
import {Appraisal} from '../models/appraisal';
import {IteratorQuestionType} from '../enum/question_type';
import {Observable} from 'rxjs';
import {Question} from '../models/question';
import {QuestionEffectInteractor} from './conditions/question_effects_interactor';
import {QuestionSet} from '../models/question_set';
import {TechnicalReference} from '../enum/technical_reference';
import {isApartment} from './support/is_apartment_check';
import {normalizeNumber} from './support/normalize_number';

export interface PlotAreaProvider {
    plotArea(debounce?: number): Observable<number | null>;
}

export class DefaultPlotAreaProvider implements PlotAreaProvider {
    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor
    ) {}

    private observableCache: Observable<number | null> | null = null;
    public plotArea(debounce = 200): Observable<number | null> {
        if (this.observableCache !== null) {
            return this.observableCache;
        }

        const objectPlotAreaQuestions = this.questionSet.findQuestionsByTechnicalReference(
            TechnicalReference.OBJECT_PLOT_AREA
        );

        const mandeligQuestions = this.questionSet.findQuestionsByTechnicalReference(
            TechnicalReference.OBJECT_PLOT_AREA_MANDELIG
        );

        this.observableCache = this.answerController
            .answersForQuestionUuidsStream([...objectPlotAreaQuestions, ...mandeligQuestions].map((q) => q.uuid))
            .pipe(
                debounceTime(debounce),
                switchMap(() =>
                    this.answerController.answersStream().pipe(
                        first(),
                        //Filter deleted and hidden answers
                        map((answers) => this.answerController.filterDeleted(answers)),
                        map((answers) =>
                            answers.filter(
                                (answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid)
                            )
                        ),
                        map((answers) => {
                            return this.getPlotArea(objectPlotAreaQuestions, answers);
                        })
                    )
                ),
                shareReplay(1)
            );
        return this.observableCache;
    }

    private buildPlotAreaTrees(objectPlotAreaQuestions: Question[], answers: Answer[]) {
        return objectPlotAreaQuestions
            .map((objectPlotAreaQuestion) => {
                const iteratorParent = this.questionSet.findParentByPredicateRecursive(
                    objectPlotAreaQuestion,
                    (a) =>
                        a.type === IteratorQuestionType.ITERATOR || a.type === IteratorQuestionType.ITERATOR_CADASTRAL
                );

                return iteratorParent !== null
                    ? buildQuestionAnswerTrees(this.questionSet, answers, iteratorParent)
                    : null;
            })
            .filter((tree): tree is Array<TreeItem<QuestionAnswerPair>> => tree !== null);
    }

    private calculatePlotArea(trees: Array<Array<TreeItem<QuestionAnswerPair>>>) {
        let plotArea = 0.0;
        for (const tree of trees) {
            for (const iteratorQuestionTreeItem of tree) {
                const mandeligPair = findChildRecursiveByPredicate(
                    iteratorQuestionTreeItem,
                    (node) => node.question.technicalReference === TechnicalReference.OBJECT_PLOT_AREA_MANDELIG
                );
                const plotPair = findChildRecursiveByPredicate(
                    iteratorQuestionTreeItem,
                    (node) => node.question.technicalReference === TechnicalReference.OBJECT_PLOT_AREA
                );

                if (mandeligPair !== null) {
                    const answerOptionId = mandeligPair.item.answer?.answerOptionId;
                    const answerOption = mandeligPair.item.question.answerOptions.find(
                        (option) => option.id === answerOptionId
                    );
                    if (answerOption?.contents.toLowerCase() !== 'ja') {
                        plotArea += parseFloat(plotPair?.item.answer?.contents ?? '0');
                    }
                } else {
                    plotArea += parseFloat(plotPair?.item.answer?.contents ?? '0');
                }
            }
        }

        if (this.appraisal.objectType === null || (!isApartment(this.appraisal.objectType) && plotArea === 0)) {
            return null;
        }

        return normalizeNumber(plotArea, 0);
    }

    private getPlotArea(objectPlotAreaQuestions: Question[], answers: Answer[]) {
        const trees = this.buildPlotAreaTrees(objectPlotAreaQuestions, answers);
        return this.calculatePlotArea(trees);
    }
}
