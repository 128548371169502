import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {usePresenter} from '../../../../../../../../support/presenter/use_presenter';
import {RenderingContextType} from '../../../../../../../enum/rendering_context_type';
import {Appraisal} from '../../../../../../../models/appraisal';
import {PagePartsSet} from '../../../../../../../models/page_parts_set';
import {Question} from '../../../../../../../models/question';
import {QuestionSet} from '../../../../../../../models/question_set';
import {Modal} from '../../../components/modal';
import {CustomizationsContainer} from './customizations_container';
import {PagePartCustomizationsPresenter} from './page_part_customizations_presenter';

interface OwnProps {
    question: Question;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet;
    renderingContext: RenderingContextType;
}

export const PagePartCustomizations: React.FC<OwnProps> = observer(function PagePartCustomizations(props) {
    const presenter = usePresenter(
        (component) =>
            new PagePartCustomizationsPresenter(
                props.question,
                props.questionSet,
                props.pagePartsSet,
                component.network.pagePartConfigurationApi
            )
    );

    const [modalOpen, setModalOpen] = useState(false);

    if (presenter.customizableQuestions.length === 0) {
        return null;
    }

    return (
        <>
            <button className="action-button btn btn-sm ion-md-create" onClick={() => setModalOpen(true)} />
            <Modal visible={modalOpen} onHide={() => setModalOpen(false)}>
                <div className="modal-header">
                    <h2>Voorkeuren</h2>
                </div>
                <div className="modal-body">
                    {presenter.customizableQuestions.map((question) => (
                        <div key={question.uuid}>
                            <h2>{question.contents}</h2>
                            <CustomizationsContainer
                                question={question}
                                pagePartsSet={props.pagePartsSet}
                                questionSet={props.questionSet}
                            />
                        </div>
                    ))}
                </div>
                <div className="modal-footer">
                    <button
                        className="btn btn-secondary"
                        onClick={() => presenter.save().then(() => setModalOpen(false))}
                    >
                        Opslaan & sluiten
                    </button>
                </div>
            </Modal>
        </>
    );
});
