import $ from 'jquery';

/**
 * Appraisal create address page
 */
(() => {
    const newConstruction = $('#form-create-appraisal-new-construction');
    const addressContainer = $('#form-create-appraisal-address');
    const mapContainer = $('#form-create-appraisal-map');
    const unknownAddress = $('#form-create-appraisal-unknown-address');
    const improvementsPlanned = $('#form-create-appraisal-improvements-planned');
    const improvementsPlannedInput = $('input[name="improvements_planned"]');
    const newConstructionInput = $('input[name="new_construction"]');
    const unknownAddressInput = $('input[name="is_unknown_address"]');

    if (improvementsPlannedInput.length > 0) {
        improvementsPlannedInput.on('change', function (source) {
            const improvementsPlannedNotes = $('#form-create-appraisal-improvements-planned-notes');
            improvementsPlannedNotes.length > 0 && showOnValue(source, improvementsPlannedNotes, '1');
            newConstruction.length > 0 && showOnValue(source, newConstruction, '0');
        });
    }

    if (newConstructionInput.length > 0) {
        newConstructionInput.on('change', function (source) {
            improvementsPlanned.length > 0 && showOnValue(source, improvementsPlanned, '0');
            unknownAddress.length > 0 && showOnValue(source, unknownAddress, '1');
            const checkedUnknownAddressInput = $('input[name="is_unknown_address"]:checked');

            // Hide the map when there is no new construction
            if ($(source.target).val() === '0') {
                mapContainer.length > 0 && mapContainer.hide();
                addressContainer.length > 0 && addressContainer.show();
            } else if (checkedUnknownAddressInput.length > 0 && $(source.target).val() === '1') {
                if (mapContainer.length > 0) {
                    // When checkedUnknownAddressInput = 1 this means the address is unknown
                    checkedUnknownAddressInput.val() === '1' ? mapContainer.show() : mapContainer.hide();
                }
                if (addressContainer.length > 0) {
                    // When checkedUnknownAddressInput = 0 this means the address is known
                    checkedUnknownAddressInput.val() === '0' ? addressContainer.show() : addressContainer.hide();
                }
            }
        });
    }

    if (unknownAddressInput.length > 0) {
        unknownAddressInput.on('change', function (source) {
            addressContainer.length > 0 && showOnValue(source, addressContainer, '0');
            mapContainer.length > 0 && showOnValue(source, mapContainer, '1');
        });

        // This opens the map container and checks the radio inputs for new construction and unknwon address
        const unknownAddressTrigger = $('#address-open-unknown');
        if (unknownAddressTrigger.length > 0) {
            unknownAddressTrigger.on('click', function () {
                newConstruction.length > 0 && newConstruction.find('input').filter('[value="1"]').attr('checked', true);
                mapContainer.length > 0 && mapContainer.show();
                addressContainer.length > 0 && addressContainer.hide();
                unknownAddress.length > 0 && unknownAddress.show();
                unknownAddress.length > 0 && unknownAddress.find('input').filter('[value="1"]').attr('checked', true);
            });
        }
    }

    function showOnValue(input, target, val) {
        if ($(input.target).val() === val) {
            target.show();
            return true;
        } else {
            target.hide();
            return false;
        }
    }
})();
