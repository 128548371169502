import * as React from 'react';

import {FunctionComponent} from 'react';
import {RootPresenter} from './root_presenter';

import {observer} from 'mobx-react-lite';
import {Loader} from '../appraise/ui/components/loader';
import {usePresenter} from '../../support/presenter/use_presenter';
import {DarkTheme} from './components/dark_theme';
import {ActivePagePartsSetLoader} from './active_page_parts_set_loader';

interface OwnProps {
    appraisalId: number | null;
    questionSetId: number;
    pagePartConfigurationId: number;
    activePagePartUuid: string | null;
}

export const Root: FunctionComponent<OwnProps> = observer(
    ({questionSetId, pagePartConfigurationId, activePagePartUuid}) => {
        const presenter = usePresenter(
            (component) =>
                new RootPresenter(
                    questionSetId,
                    component.network.questionSetApi,
                    component.network.pagePartConfigurationApi,
                    component.serverTimeProvider,
                    component.business.appraisalProvider,
                    component.business.pagePartConfigurationsInteractor
                )
        );

        if (presenter.isLoading || presenter.appraisal === null || presenter.questionSet === null) {
            return (
                <div className="empty-message appraise">
                    <DarkTheme />
                    <Loader />
                    <h3>Aan het laden...</h3>
                </div>
            );
        }

        return (
            <>
                <DarkTheme />
                <ActivePagePartsSetLoader
                    key={pagePartConfigurationId}
                    appraisal={presenter.appraisal}
                    questionSet={presenter.questionSet}
                    pagePartConfigurationId={pagePartConfigurationId}
                    activePagePartUuid={activePagePartUuid}
                />
            </>
        );
    }
);
