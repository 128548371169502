import {Appraisal} from '../../../../../../../../../models/appraisal';
import {ReferenceObjectSortingStrategy} from './reference_object_sorting_strategy';
import {ReferenceSale} from '../../models/reference_sale';
import {SortingDirection} from '../../../../../../../../../enum/reference_objects_sorting';

export class ReferenceObjectSorterByDistance implements ReferenceObjectSortingStrategy {
    constructor(private appraisal: Appraisal) {}

    public sortReferenceObjects(referenceSales: ReferenceSale[], sortingDirection: SortingDirection): ReferenceSale[] {
        return referenceSales.sort((referenceSale1, referenceSale2) => {
            return this.getDistanceToAppraisal(referenceSale1) > this.getDistanceToAppraisal(referenceSale2)
                ? sortingDirection === SortingDirection.DESCENDING
                    ? -1
                    : 1
                : sortingDirection === SortingDirection.DESCENDING
                ? 1
                : -1;
        });
    }

    private deg2rad(degrees: number) {
        return (degrees * Math.PI) / 180;
    }

    private getDistanceToAppraisal(referenceSale: ReferenceSale) {
        // if latitude or longitude is not set, distance can not be calculated
        // this also be checked in the view so a user cannot sort by distance if these properties aren't set
        // by setting all distances to zero the result will be unordered
        if (this.appraisal.latitude === null || this.appraisal.longitude === null) {
            return 0;
        }

        const earthRadius = 6371000;

        // convert from degrees to radians
        const latFrom = this.deg2rad(this.appraisal.latitude);
        const lonFrom = this.deg2rad(this.appraisal.longitude);
        const latTo = this.deg2rad(referenceSale.latitude);
        const lonTo = this.deg2rad(referenceSale.longitude);

        const latDelta = latTo - latFrom;
        const lonDelta = lonTo - lonFrom;

        const angle =
            2 *
            Math.asin(
                Math.sqrt(
                    Math.pow(Math.sin(latDelta / 2), 2) +
                        Math.cos(latFrom) * Math.cos(latTo) * Math.pow(Math.sin(lonDelta / 2), 2)
                )
            );

        return angle * earthRadius;
    }
}
