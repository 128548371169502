import {distinctUntilChanged, first, map, switchMap} from 'rxjs/operators';
import {makeObservable, observable, runInAction} from 'mobx';

import {AnswerController} from '../../../business/answering/answer_controller';
import {ChildQuestionValidationProvider} from '../../../business/validation/child_validation_provider';
import {CompositeSubscription} from '../../../../support/composite_subscription';
import {FloorInteractor} from '../../../business/floor_interactor';
import {PagePart} from '../../../models/page_part';
import {PagePartsSet} from '../../../models/page_parts_set';
import {Presenter} from '../../../../support/presenter/presenter';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {ValidationMessage} from '../../../business/validation/validation_message';
import {getNewestAnswer} from '../../../../support/get_newest_answer';

export class PagePartContentPresenter implements Presenter {
    @observable.ref public activePagePart: PagePart | undefined;
    @observable public floorNumber: string | undefined;
    @observable.ref public question: Question | undefined;
    @observable.ref public validationMessages: ValidationMessage[] = [];

    private subscriptions = new CompositeSubscription();

    constructor(
        pagePartsUuid: string,
        pagePartsSet: PagePartsSet | null,
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private childValidationProvider: ChildQuestionValidationProvider,
        private floorInteractor: FloorInteractor,
        routePagePartFloorNumberIteration: string | null
    ) {
        makeObservable(this);

        if (pagePartsSet) {
            this.activePagePart = pagePartsSet.getByUuid(pagePartsUuid) ?? undefined;
            if (this.activePagePart?.questionUuid) {
                this.question = this.questionSet.findQuestionByUuid(this.activePagePart.questionUuid);
            }
            if (routePagePartFloorNumberIteration) {
                setTimeout(() => {
                    this.floorInteractor.setFloorIteration(routePagePartFloorNumberIteration);
                }, 1);
            }
        }
    }

    public mount() {
        this.subscriptions.add(
            this.floorInteractor.getFloorIteration().subscribe((floorNumber) => {
                runInAction(() => {
                    this.floorNumber = String(floorNumber);
                });
            })
        );

        const question = this.question;
        if (question !== undefined) {
            this.subscriptions.add(
                this.answerController
                    .answersForQuestionUuidStream(question.uuid)
                    .pipe(
                        map((answers) => getNewestAnswer(answers)?.uuid ?? null),
                        distinctUntilChanged(),
                        switchMap((answerUuid) =>
                            this.childValidationProvider.validate(
                                question.uuid,
                                answerUuid === null ? answerUuid : answerUuid
                            )
                        ),
                        //We are not interested in any "new" validationMessages since only the
                        //`validation_institute_validation` sets the `messages` array
                        first()
                    )
                    .subscribe((validationMessages) => {
                        runInAction(() => {
                            this.validationMessages = validationMessages;
                        });
                    })
            );
        }
    }

    public unmount() {
        this.subscriptions.clear();
    }
}
