/**
 * Copy code from wizard
 */
(() => {
    const copyBtn = $('#iframe-copy-code');

    if (copyBtn.length > 0) {
        copyBtn.bind('click', function (event) {
            const iframeCodeInput = $('#iframe-code');
            iframeCodeInput.focus();
            iframeCodeInput.select();

            try {
                var successful = document.execCommand('copy');
                alert(successful ? 'De tekst is gekopieerd.' : 'Er ging wat mis, de tekst is niet gekopieerd.');
            } catch (err) {
                alert('Er ging wat mis, de tekst is niet gekopieerd');
            }
        });
    }
})();
