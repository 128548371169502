import * as React from 'react';

import {SortingDirection, SortingMethod} from '../../../../../../../../enum/reference_objects_sorting';

import {ActiveSetDefinition} from '../reference_objects_question_presenter';
import {Appraisal} from '../../../../../../../../models/appraisal';
import {Loader} from '../../../../../../components/loader';
import {QuestionAnswerPair} from '../../../../../../../../../support/question_answer_tree';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {ReferenceObject} from '../models/reference_object';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {ReferenceObjectAnswerTile} from './reference_object_tile/reference_object_answer_tile';
import {ReferenceObjectTile, ReferenceObjectTileStatus} from './reference_object_tile/reference_object_tile';
import {ReferenceObjectTilePlaceholder} from './reference_object_tile/reference_object_tile_placeholder';
import {SetType} from '../../../../../../../../models/reference_set/set_type';
import {TreeItem} from '../../../../../../../../../support/generic_tree';
import {VisibleReferenceObject} from '../reference_objects_question_presenter';
import {classNames} from '../../../../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {
    ReferenceSubscriptions,
    ReferenceSubscriptionType,
} from '../../../../../../../../models/reference_subscriptions';
import {SimpleTooltip} from '../../../../../../../../components/tooltip';

interface OwnProps {
    questionSet: QuestionSet;
    appraisal: Appraisal;
    isComparing: boolean;
    isUpdatingReferenceObjects: boolean;
    sortingMethod: SortingMethod;
    sortingDirection: SortingDirection;
    updateSortingMethod: (method: SortingMethod) => void;
    activeSetDefinition: ActiveSetDefinition;
    visibleReferenceObjects: VisibleReferenceObject | null;
    onAddAndOpenModal: (referenceObjectAnswer: ReferenceObjectAnswer) => Promise<void>;
    onRemove: (iteratorAnswerUuid: string) => Promise<void>;
    updateIsComparing: (isComparing: boolean) => void;
    onImageSliderOpened: (referenceObject: ReferenceObject) => void;
    canAdd: boolean;
    canDelete: boolean;
    numVisible: number;
    showMoreReferenceObjects: () => void;
    subscriptions: ReferenceSubscriptions | null;

    shouldShowDeletedObjects: boolean;
    onToggleShowDeletedObjects: () => void;
    onRestore: (iteratorAnswerUuid: string) => Promise<void>;

    showAnswerModal: (referenceObjectAnswer: ReferenceObjectAnswer, treeItem: TreeItem<QuestionAnswerPair>) => void;
    showDetailsModal: (referenceObject: ReferenceObject) => void;

    hoveringReferenceObjectId: string | null;
    clickedReferenceObjectId: string | null;
    setHoveringReferenceObjectId: (id: string | null) => void;
    setClickedReferenceObject: (ro: ReferenceObject | ReferenceObjectAnswer | null) => void;
    openReferencePreferencesModal: () => void;
    shouldShowSortingMethodTooltip: boolean;
    children?: React.ReactNode;
}

interface OwnState {
    alertHeights: Map<Element, number>;
    alertWrapperHeight: number;
}

@observer
export class ReferenceObjectGrid extends React.Component<OwnProps, OwnState> {
    public state = {alertHeights: new Map(), alertWrapperHeight: 0};

    private updateAlertHeights = (alert: Element, height: number | null) => {
        const newHeights = new Map(this.state.alertHeights);

        if (height === null) {
            newHeights.delete(alert);
        } else {
            newHeights.set(alert, height);
        }

        this.setState({
            alertHeights: newHeights,
            alertWrapperHeight: Math.max(...Array.from(newHeights.values())),
        });
    };

    private renderSortingButtons(activeSetDefinition: ActiveSetDefinition) {
        const sortingMethods = [SortingMethod.ALPHABETICALLY];

        if (activeSetDefinition.setDefinition.type === SetType.SOLD) {
            sortingMethods.push(SortingMethod.SALES_DATE);
            sortingMethods.push(SortingMethod.DEVIATION_SCORE);

            if (this.props.appraisal.latitude !== null && this.props.appraisal.longitude !== null) {
                sortingMethods.push(SortingMethod.DISTANCE);
            }
        }

        return (
            <div className="row">
                <div className="col-12">
                    <ul className="nav-filter">
                        {sortingMethods.map((method: SortingMethod) => {
                            return (
                                <li
                                    key={method}
                                    className={classNames('nav-filter-item', {
                                        active: !this.props.isComparing && this.props.sortingMethod === method,
                                        'ion-md-arrow-dropup':
                                            !this.props.isComparing &&
                                            this.props.sortingMethod === method &&
                                            this.props.sortingDirection === SortingDirection.ASCENDING,
                                        'ion-md-arrow-dropdown':
                                            !this.props.isComparing &&
                                            this.props.sortingMethod === method &&
                                            this.props.sortingDirection === SortingDirection.DESCENDING,
                                    })}
                                    onClick={() => this.props.updateSortingMethod(method)}
                                >
                                    {method}
                                </li>
                            );
                        })}
                        <li
                            key="compare"
                            className={classNames('nav-filter-item', {
                                active: this.props.isComparing,
                                disabled: activeSetDefinition.setDefinition.amountSelected === 0,
                            })}
                            onClick={() => this.props.updateIsComparing(true)}
                        >
                            Vergelijken
                        </li>
                        <SimpleTooltip
                            content={'Pas de standaard-sorteervolgorde aan'}
                            placement="right"
                            open={this.props.shouldShowSortingMethodTooltip}
                        >
                            <li
                                key="settings"
                                className={classNames('nav-filter-item nav-filter-item-right-icon ion-md-settings', {
                                    disabled: activeSetDefinition.setDefinition.amountSelected === 0,
                                })}
                                onClick={() => this.props.openReferencePreferencesModal()}
                            />
                        </SimpleTooltip>
                    </ul>
                </div>
            </div>
        );
    }

    private renderReferenceObjectAnswerTile(
        referenceObjectAnswer: ReferenceObjectAnswer,
        treeItem: TreeItem<QuestionAnswerPair>,
        index: number,
        isDeleted: boolean
    ) {
        return (
            <ReferenceObjectAnswerTile
                key={referenceObjectAnswer.id + String(treeItem.item.answer?.uuid ?? '')}
                setType={this.props.activeSetDefinition.setDefinition.type}
                appraisal={this.props.appraisal}
                questionSet={this.props.questionSet}
                activeSetDefinition={this.props.activeSetDefinition}
                buildYear={this.props.activeSetDefinition.setDefinition.buildYear}
                referenceObjectAnswer={referenceObjectAnswer}
                treeItem={treeItem}
                index={index}
                onRemove={() => {
                    if (treeItem.parent && treeItem.parent.item.answer) {
                        this.props.onRemove(treeItem.parent.item.answer.uuid);
                    }
                }}
                onHoverChange={this.props.setHoveringReferenceObjectId}
                onClickChange={this.props.setClickedReferenceObject}
                isMapHovering={this.props.hoveringReferenceObjectId === referenceObjectAnswer.id}
                isMapSelected={this.props.clickedReferenceObjectId === referenceObjectAnswer.id}
                showAnswerModal={() => {
                    this.props.showAnswerModal(referenceObjectAnswer, treeItem);
                }}
                isCompare={this.props.isComparing}
                canDelete={this.props.canDelete}
                onAlertResize={this.updateAlertHeights}
                alertWrapperHeight={this.state.alertWrapperHeight}
                isDeleted={isDeleted}
                onRestore={() => {
                    if (treeItem.parent && treeItem.parent.item.answer) {
                        this.props.onRestore(treeItem.parent.item.answer.uuid);
                    }
                }}
            />
        );
    }

    private renderReferenceObjectTile(referenceObject: ReferenceObject, status?: ReferenceObjectTileStatus) {
        const altumSubscription = this.props.subscriptions?.subscriptions.find(
            (sub) => sub.type === ReferenceSubscriptionType.ALTUM
        );

        return (
            <ReferenceObjectTile
                key={referenceObject.id}
                appraisal={this.props.appraisal}
                questionSet={this.props.questionSet}
                setDefinition={this.props.activeSetDefinition.setDefinition}
                referenceObject={referenceObject}
                canAdd={this.props.canAdd}
                onAddAndOpenModal={this.props.onAddAndOpenModal}
                onHoverChange={this.props.setHoveringReferenceObjectId}
                onClickChange={this.props.setClickedReferenceObject}
                isMapHovering={this.props.hoveringReferenceObjectId === referenceObject.id}
                isMapSelected={this.props.clickedReferenceObjectId === referenceObject.id}
                showDetailsModal={this.props.showDetailsModal}
                onImageSliderOpened={this.props.onImageSliderOpened}
                status={status}
                onAlertResize={this.updateAlertHeights}
                alertWrapperHeight={this.state.alertWrapperHeight}
                teaser={
                    status === ReferenceObjectTileStatus.PRESELECTED &&
                    referenceObject.source === 'Altum' &&
                    altumSubscription?.available &&
                    !altumSubscription.usedForAppraisal ? (
                        <span className="form-grid-item-alert">
                            <span className="ion-md-checkmark-circle active mr-1">&nbsp;</span>
                            Activeer Taxinder om deze referentie te gebruiken.
                        </span>
                    ) : null
                }
            />
        );
    }

    public render() {
        return (
            <div className="reference-object-grid-parent">
                {this.renderSortingButtons(this.props.activeSetDefinition)}
                <div
                    className={classNames('row grid reference-object-grid', {
                        'reference-object-grid-reload': this.props.isUpdatingReferenceObjects,
                    })}
                >
                    {this.props.visibleReferenceObjects?.selectedPreselectedReferences.map(
                        ({referenceObjectAnswer, treeItem}, index) =>
                            this.renderReferenceObjectAnswerTile(referenceObjectAnswer, treeItem, index, false)
                    ) ?? null}
                    {this.props.visibleReferenceObjects?.selectedObjects.map(
                        ({referenceObjectAnswer, treeItem}, index) =>
                            this.renderReferenceObjectAnswerTile(
                                referenceObjectAnswer,
                                treeItem,
                                (this.props.visibleReferenceObjects?.selectedPreselectedReferences?.length ?? 0) +
                                    index,
                                false
                            )
                    ) ?? null}
                    {this.props.visibleReferenceObjects?.nonSelectedPreselectedReferences.map((referenceObject) =>
                        this.renderReferenceObjectTile(referenceObject, ReferenceObjectTileStatus.PRESELECTED)
                    )}
                    {this.props.visibleReferenceObjects?.nonSelectedObjects.map((referenceObject) =>
                        this.renderReferenceObjectTile(referenceObject)
                    ) ?? null}
                    {(this.props.visibleReferenceObjects === null ||
                        (this.props.isUpdatingReferenceObjects && this.props.visibleReferenceObjects.count < 6)) && (
                        <ReferenceObjectTilePlaceholder
                            animated={this.props.activeSetDefinition.setDefinition.type === SetType.SOLD}
                            tiles={6 - (this.props.visibleReferenceObjects?.count ?? 0)}
                        />
                    )}
                </div>

                {this.props.visibleReferenceObjects?.rejectedReferences &&
                    this.props.visibleReferenceObjects?.rejectedReferences.length > 0 && (
                        <>
                            <h2>Afgekeurd</h2>
                            <div
                                className={classNames('row grid reference-object-grid', {
                                    'reference-object-grid-reload': this.props.isUpdatingReferenceObjects,
                                })}
                            >
                                {this.props.visibleReferenceObjects?.rejectedReferences.map((referenceObject) =>
                                    this.renderReferenceObjectTile(referenceObject, ReferenceObjectTileStatus.REJECTED)
                                )}
                            </div>
                        </>
                    )}

                {this.props.isUpdatingReferenceObjects && <Loader icon="ion-md-pin" />}

                <div className="row">
                    <div className="col-12">
                        {this.props.activeSetDefinition.referenceObjectSetData &&
                        this.props.activeSetDefinition.referenceObjectSetData.referenceSales.length >
                            this.props.numVisible ? (
                            <button
                                disabled={this.props.isUpdatingReferenceObjects}
                                onClick={() => this.props.showMoreReferenceObjects()}
                                className="btn btn-sm ion-md-refresh float-left"
                            >
                                Laad meer
                            </button>
                        ) : null}
                        {this.props.children}
                    </div>
                </div>

                {this.props.shouldShowDeletedObjects && (
                    <div className="row mt-4">
                        <div className="col-12">
                            <h2>Verwijderde referenties</h2>
                            <div
                                className={classNames('row grid reference-object-grid', {
                                    'reference-object-grid-reload': this.props.isUpdatingReferenceObjects,
                                })}
                            >
                                {this.props.visibleReferenceObjects?.deletedSelectedObjects.map(
                                    ({referenceObjectAnswer, treeItem}, index) =>
                                        this.renderReferenceObjectAnswerTile(
                                            referenceObjectAnswer,
                                            treeItem,
                                            index,
                                            true
                                        )
                                ) ?? null}
                            </div>
                        </div>
                    </div>
                )}
                <div className="row mt-4">
                    <div className="col-12">
                        <button
                            disabled={
                                this.props.visibleReferenceObjects?.deletedSelectedObjects.length === 0 &&
                                this.props.shouldShowDeletedObjects === false
                            }
                            onClick={() => this.props.onToggleShowDeletedObjects()}
                            className={classNames('btn btn-secondary btn-sm', {
                                'ion-md-eye': this.props.shouldShowDeletedObjects === false,
                                'ion-md-eye-off': this.props.shouldShowDeletedObjects === true,
                            })}
                        >
                            Verwijderde referenties {this.props.shouldShowDeletedObjects ? 'verbergen' : 'weergeven'}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
