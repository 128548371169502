import * as React from 'react';

import {PriceRange} from '../price_range';
import {classNames} from '../../../../../../../../../../support/classnames';
import {format} from 'date-fns';
import {ReferenceImageViewer} from '../photos/image_viewer';
import {Appraisal} from '../../../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {ReferenceObject} from '../../models/reference_object';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';
import {ReferenceObjectMapDetailsPresenter} from './reference_object_map_details_presenter';
import {referenceObjectImages} from '../../internal/reference_sale_images';

interface OwnProps {
    isSelected: boolean;
    referenceObject: ReferenceObject | null;
    referenceObjectAnswer: ReferenceObjectAnswer | null;
    street: string;
    houseNumber: string;
    letter: string;
    postalCode: string;
    city: string;
    priceRange: string | number | null;
    floorArea: string | number | null;
    saleQuarter: string | null;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    onClick: () => void;
    onClose: () => void;
    showDetailsModal: () => void;
}

export const ReferenceObjectMapDetails: React.FC<OwnProps> = observer(function ReferenceObjectMapDetails(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectMapDetailsPresenter(
                props.referenceObject,
                props.referenceObjectAnswer,
                container.business.referenceObjectsAnswerEnhancementInteractor(props.appraisal, props.questionSet)
            )
    );

    const images = React.useMemo(() => {
        return referenceObjectImages(
            presenter.enhancedReferenceSale ?? props.referenceObject,
            props.referenceObjectAnswer
        );
    }, [props.referenceObject, props.referenceObjectAnswer, presenter.enhancedReferenceSale]);

    return (
        <div className="container container-wide reference-object-map-details-container">
            <div className="row row-wide">
                <div className="col-5 offset-7 col-wide">
                    <div className="reference-object-map-details-container-inner">
                        <div className="reference-object-map-details">
                            <button
                                className="reference-object-map-details-close close ion-md-close"
                                onClick={() => props.onClose()}
                            />
                            <div className="reference-object-map-details-body">
                                <div className="reference-object-map-details-img">
                                    <div className="reference-object-map-details-img-content">
                                        <ReferenceImageViewer
                                            images={images}
                                            appraisal={props.appraisal}
                                            questionSet={props.questionSet}
                                        />
                                        <div
                                            className={classNames('active-icon ion-md-checkmark', {
                                                visible: props.isSelected,
                                            })}
                                        >
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <p>
                                            <strong>
                                                {props.street} {props.houseNumber}
                                                {props.letter}
                                            </strong>
                                            <br />
                                            {props.postalCode} {props.city}
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <dl>
                                            <dt>Prijsklasse</dt>
                                            <dd>
                                                <PriceRange
                                                    range={props.priceRange !== null ? String(props.priceRange) : null}
                                                    break={false}
                                                />
                                            </dd>
                                        </dl>
                                    </div>
                                    <div className="col-3">
                                        <dl>
                                            <dt>GBO wonen</dt>
                                            <dd>{props.floorArea === null ? '-' : props.floorArea + 'm²'}</dd>
                                        </dl>
                                    </div>
                                    <div className="col-3">
                                        <dl>
                                            <dt>Periode</dt>
                                            <dd>
                                                {props.saleQuarter !== null
                                                    ? props.saleQuarter.length > 8
                                                        ? format(new Date(props.saleQuarter), 'dd-MM-yyyy')
                                                        : 'Q' + props.saleQuarter
                                                    : null}
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div className="reference-object-map-details-footer">
                                <button
                                    className="btn-text-link ion-md-information-circle"
                                    onClick={() => props.showDetailsModal()}
                                >
                                    Details
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
