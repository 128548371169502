import * as React from 'react';
import GoogleMapReact from 'google-map-react';

import {AddressGeoMapPresenter, MapState, SelectedAddressInput} from './address_geo_map_presenter';

import {CreateAppraisalProps} from './support/component';
import {Pointer} from './components/pointer';
import {observer} from 'mobx-react';
import {classNames} from '../../support/classnames';
import {usePresenter} from './support/use_presenter';

interface OwnProps {}

declare let CREATE_APPRAISAL_PROPS: CreateAppraisalProps | null;

export const AddressGeoMap: React.FunctionComponent<OwnProps> = observer(() => {
    const presenter = usePresenter((container) => new AddressGeoMapPresenter(container.geoApi));

    const mapState: MapState = presenter.mapState;
    const input: SelectedAddressInput = presenter.selectedAddressInput;

    return (
        <>
            <div className="form-geo-map">
                <div className={classNames({disabled: presenter.isDisabled}, 'form-geo-map-container')}>
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: CREATE_APPRAISAL_PROPS?.googleMapsApiKey ?? '',
                            language: 'nl',
                        }}
                        options={presenter.createMapOptions}
                        defaultZoom={mapState.defaultCenterLocation?.zoom}
                        defaultCenter={mapState.defaultCenterLocation?.center}
                        zoom={mapState.centerLocation?.zoom}
                        center={mapState.centerLocation?.center}
                        onClick={(e) => {
                            presenter.onChangeCoordinate(e.lat, e.lng);
                        }}
                        yesIWantToUseGoogleMapApiInternals={true}
                        shouldUnregisterMapOnUnmount={true}
                        onGoogleApiLoaded={({map}) => {
                            presenter.mapRef = map;
                        }}
                        draggable={presenter.mapState.draggable}
                        onChildMouseDown={() => {
                            presenter.onMouseDown();
                        }}
                        onChildMouseMove={(childKey, childProps, mouse) => {
                            presenter.onMouseMove(mouse.lat, mouse.lng);
                        }}
                        onChildMouseUp={(childKey, childProps, mouse) => {
                            presenter.onMouseUp(mouse.lat, mouse.lng);
                        }}
                    >
                        {mapState.nearbyAddress && (
                            <Pointer
                                lat={mapState.nearbyAddress?.lat}
                                lng={mapState.nearbyAddress?.lng}
                                isBaseObject={false}
                                key={'secondary'}
                            />
                        )}
                        {mapState.centerLocation && (
                            <Pointer
                                lat={mapState.centerLocation?.center.lat}
                                lng={mapState.centerLocation?.center.lng}
                                isBaseObject={true}
                                key={'main'}
                            />
                        )}
                    </GoogleMapReact>
                </div>

                <input type="hidden" name="map_address" value={input.street ?? ''} />
                <input type="hidden" name="map_postal_code" value={input.postalCode ?? ''} />
                <input type="hidden" name="map_project_name" value={input.projectName ?? ''} />
                <input type="hidden" name="map_plot_number" value={input.plotNumber ?? ''} />
                <input type="hidden" name="map_house_number" value={input.houseNumber ?? ''} />
                <input type="hidden" name="map_letter" value={input.letter ?? ''} />
                <input type="hidden" name="map_city" value={input.city ?? ''} />
                <input type="hidden" name="map_latitude" value={mapState.selectedCoordinates?.lat ?? ''} />
                <input type="hidden" name="map_longitude" value={mapState.selectedCoordinates?.lng ?? ''} />
            </div>
            <div className="row">
                <div className="col-12 col-md-8">
                    <div className="form-group">
                        <label>
                            Nabij gelegen postcode
                            <span className="form-label-highlight">*</span>
                        </label>
                        <input
                            type="text"
                            name="postal_code"
                            className="form-control"
                            value={input?.postalCode ?? ''}
                            disabled={presenter.isDisabled}
                            onChange={(e) => presenter.onSetNearbyAddress('postalCode', e.target.value)}
                            onBlur={() => presenter.onChangeNearbyAddress()}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6 col-md-8">
                    <div className="form-group">
                        <label>Projectnaam</label>
                        <input
                            type="text"
                            name="project_name"
                            className="form-control"
                            value={input?.projectName ?? ''}
                            disabled={presenter.isDisabled}
                            onChange={(e) => presenter.onSetNearbyAddress('projectName', e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-8">
                    <div className="form-group">
                        <label>Adres</label>
                        <input
                            type="text"
                            name="address"
                            className="form-control"
                            value={input.street ?? ''}
                            disabled={presenter.isDisabled}
                            onChange={(e) => presenter.onSetNearbyAddress('street', e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6 col-md-4">
                    <div className="form-group">
                        <label>Huisnummer</label>
                        <input
                            type="text"
                            name="number"
                            className="form-control"
                            value={input.houseNumber ?? ''}
                            disabled={presenter.isDisabled}
                            onChange={(e) => presenter.onSetNearbyAddress('houseNumber', e.target.value)}
                            onBlur={() => presenter.onChangeNearbyAddress()}
                        />
                    </div>
                </div>
                <div className="col-3 col-md-4">
                    <div className="form-group">
                        <label>Toevoeging</label>
                        <input
                            type="text"
                            name="letter"
                            className="form-control"
                            value={input.letter ?? ''}
                            disabled={presenter.isDisabled}
                            onChange={(e) => presenter.onSetNearbyAddress('letter', e.target.value)}
                            onBlur={() => presenter.onChangeNearbyAddress()}
                        />
                    </div>
                </div>
                <div className="col-3 col-md-4">
                    <div className="form-group">
                        <label>Bouw/kavelnummer</label>
                        <input
                            type="text"
                            name="plot_number"
                            className="form-control"
                            value={input?.plotNumber ?? ''}
                            disabled={presenter.isDisabled}
                            onChange={(e) => presenter.onSetNearbyAddress('plotNumber', e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-8">
                    <div className="form-group">
                        <label>
                            Plaats
                            <span className="form-label-highlight">*</span>
                        </label>
                        <input
                            type="text"
                            name="address"
                            className="form-control"
                            value={input.city ?? ''}
                            disabled={presenter.isDisabled}
                            onChange={(e) => presenter.onSetNearbyAddress('city', e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                {presenter.errorMessage && (
                    <div className="col-12">
                        <div className="form-group">
                            <span className="invalid-feedback left" role="alert">
                                {presenter.errorMessage}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
});
