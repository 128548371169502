import {computed, makeObservable, observable, runInAction} from 'mobx';
import {AppraisalProvider} from '../../../appraising/business/appraisal_provider';
import {Appraisal} from '../../../appraising/models/appraisal';
import {AppraiserStatistic} from '../../../appraising/models/appraiser_statistic';
import {ObjectSummary} from '../../../appraising/models/object_summary';
import {AppraisalApi} from '../../../appraising/network/appraisal_api';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {Presenter} from '../../../support/presenter/presenter';

export class WidgetPresenter implements Presenter {
    @observable.ref public appraisal?: Appraisal;
    @observable.ref public appraiserStatistics?: AppraiserStatistic[];
    @observable.ref public objectSummary?: ObjectSummary;

    @computed
    public get appraiserStatisticsByKey(): Record<string, AppraiserStatistic> {
        const result: Record<string, AppraiserStatistic> = {};

        for (const appraiserStatistic of this.appraiserStatistics ?? []) {
            result[appraiserStatistic.key] = appraiserStatistic;
        }

        return result;
    }

    private subscriptions = new CompositeSubscription();

    constructor(private appraisalProvider: AppraisalProvider, private appraisalApi: AppraisalApi) {
        makeObservable(this);
    }

    public async mount(): Promise<void> {
        this.subscriptions.add(
            this.appraisalProvider.stream.subscribe((appraisal) => {
                runInAction(() => {
                    this.appraisal = appraisal;

                    this.appraisalApi.getAppraiserStatistics(appraisal.id).then((appraiserStatistics) => {
                        runInAction(() => {
                            this.appraiserStatistics = appraiserStatistics;
                        });
                    });

                    this.appraisalApi.getObjectSummary(appraisal.id).then((summary) => {
                        runInAction(() => {
                            this.objectSummary = summary;
                        });
                    });
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
