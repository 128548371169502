import {Answer} from '../../../../models/answer';
import {AnswerController} from '../../../../business/answering/answer_controller';
import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {Presenter} from '../../../../../support/presenter/presenter';
import {Question} from '../../../../models/question';
import {QuestionSet} from '../../../../models/question_set';
import {RootGroupQuestionType} from '../../../../enum/question_type';
import {observable, makeObservable, runInAction} from 'mobx';

export class ReportReplacementsPresenter implements Presenter {
    @observable
    public question: Question | null = null;
    @observable
    public answer: Answer | null = null;

    private subscriptions = new CompositeSubscription();

    constructor(questionSet: QuestionSet, private answerController: AnswerController) {
        this.question = questionSet.findQuestionsByType(RootGroupQuestionType.CONCEPT_REPORT)[0];
        makeObservable(this);
    }

    public async mount() {
        if (this.question) {
            this.subscriptions.add(
                this.answerController.answerByIdentifiersStream(this.question.uuid, null, null).subscribe((answer) => {
                    runInAction(() => {
                        this.answer = answer;
                    });
                })
            );
        }
    }

    public async unmount() {
        this.subscriptions.clear();
    }
}
