import $ from 'jquery';

/**
 * Bit 'n pieces to fill hidden elements on prior appraiser form
 */
(() => {
    const appraiserId = $('#form-appraiser-id');
    if (appraiserId.length > 0) {
        $(`[name="appraiser_id_placeholder"]`).on('change', function (input) {
            fillHiddenInput($(input.target).val(), $(`[name="appraiser_id"]`));
        });
    }

    const companion = $('#form-companion');
    if (companion.length > 0) {
        $('[name="has_companion_placeholder"]').on('change', function (checkbox) {
            showOnValue(checkbox, companion, '1');
            fillHiddenInput($(checkbox.target).is(':checked') ? 1 : 0, $('[name="has_companion"]'));
        });
        $('[name="has_companion"]').on('change', function (checkbox) {
            showOnValue(checkbox, companion, '1');
        });
        for (const field of [
            'companion_company_name',
            'companion_saluation',
            'companion_initials',
            'companion_first_name',
            'companion_last_name',
            'junior_appraiser_id',
        ]) {
            $(`[name="${field}_placeholder"]`).on('change', function (input) {
                fillHiddenInput($(input.target).val(), $(`[name="${field}"]`));
            });
        }
    }

    const juniorAppraiserId = $('select[id="junior_appraiser_id"]');
    if (juniorAppraiserId.length > 0) {
        $(juniorAppraiserId).on('change', function (option) {
            const val = $(option.target).val();
            if (val.length > 0) {
                $('#form-companion-details').hide();
            } else {
                $('#form-companion-details').show();
            }
        });
    }

    const controller = $('#form-controller');
    if (controller.length > 0) {
        $('[name="has_controller_placeholder"]').on('change', function (checkbox) {
            showOnValue(checkbox, controller, '1');
            fillHiddenInput($(checkbox.target).is(':checked') ? 1 : 0, $('[name="has_controller"]'));
        });
        $('[name="has_controller"]').on('change', function (checkbox) {
            showOnValue(checkbox, controller, '1');
        });
        for (const field of [
            'controller_company_name',
            'controller_city',
            'controller_email',
            'controller_saluation',
            'controller_initials',
            'controller_first_name',
            'controller_last_name',
        ]) {
            $(`[name="${field}_placeholder"]`).on('change', function (input) {
                fillHiddenInput($(input.target).val(), $(`[name="${field}"]`));
            });
        }
    }

    $(`[name="appraise_days_placeholder"]`).on('change', function (input) {
        fillHiddenInput($(input.target).val(), $(`[name="appraise_days"]`));
    });

    function fillHiddenInput(value, target) {
        $(target).val(value);
    }

    function showOnValue(input, target, val) {
        if ($(input.target).val() === val) {
            target.show();
        } else {
            target.hide();
        }
    }
})();
