import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';

export const hashChanges = new Observable<string>((observer) => {
    const listener = () => {
        const newHash = getCurrentWindowHash();
        observer.next(newHash);
    };

    window.addEventListener('hashchange', listener, false);
    return () => window.removeEventListener('hashchange', listener);
}).pipe(share());

export function getCurrentWindowHash(): string {
    return window.location.hash[0] === '#' ? window.location.hash.substring(1) : window.location.hash;
}
