import $ from 'jquery';

(() => {
    const objectUsageTypeInput = $('select[name="object_usage_type"]');
    const objectOwnershipTypeInput = $('select[name="object_ownership_type"]');
    const defaultObjectUsageType = $('select[name="object_usage_type"] option:selected');

    let objectUsageTypes = null;
    let selectedOwnershipType = null;
    let selectedObjectUsageType = null;
    let newConstruction = false;
    let ownerAndLessor = null;
    try {
        // These data may not available
        selectedOwnershipType = SELECTED_OWNERSHIP_TYPE;
        selectedObjectUsageType = SELECTED_OBJECT_USAGE_TYPE;
        objectUsageTypes = OBJECT_USAGE_TYPES;
        newConstruction = NEW_CONSTRUCTION;
        ownerAndLessor = OWNER_AND_LESSOR_TYPE;
    } catch (e) {}

    if (objectUsageTypeInput && objectUsageTypes !== null) {
        setObjectOwnershipType(
            defaultObjectUsageType.val(),
            objectOwnershipTypeInput,
            objectUsageTypes,
            selectedOwnershipType,
            newConstruction,
            ownerAndLessor
        );
        objectUsageTypeInput.on('change', function (e) {
            setObjectOwnershipType(
                objectUsageTypeInput.val(),
                objectOwnershipTypeInput,
                objectUsageTypes,
                selectedOwnershipType,
                newConstruction,
                ownerAndLessor
            );
        });
    }
    if (objectUsageTypeInput !== null && selectedObjectUsageType !== null) {
        objectUsageTypeInput.val(selectedObjectUsageType);
    }
})();

function setObjectOwnershipType(
    value,
    objectOwnershipTypeInput,
    objectUsageTypes,
    selectedOwnershipType,
    newConstruction,
    ownerAndLessor
) {
    if (objectUsageTypes !== null) {
        const options = Object.entries(objectUsageTypes[value]);
        if (options.length > 0) {
            objectOwnershipTypeInput.empty();
            for (const [key, val] of options) {
                if (!newConstruction || (newConstruction && key !== ownerAndLessor)) {
                    if (selectedOwnershipType !== null && selectedOwnershipType === key) {
                        objectOwnershipTypeInput.append(`<option value="${key}" selected="selected">${val}</option>`);
                    } else {
                        objectOwnershipTypeInput.append(`<option value="${key}">${val}</option>`);
                    }
                }
            }
        }
    }
}
