import * as React from 'react';

import {Appraisal} from '../../../../../../../../../../models/appraisal';
import {AppraiseModel, isAppraiseModel} from '../../../../../../../../../../enum/appraise_model';
import {Area} from './rows/area';
import {BuildYear} from './rows/build_year';
import {EfficiencyDifference} from './rows/efficiency_difference';
import {EfficiencyDifferenceExplanation} from './rows/efficiency_difference_explanation';
import {EnergyLabel} from './rows/energy_label';
import {Extension} from './rows/extension';
import {ExtensionDifference} from './rows/extension_difference';
import {ExtensionDifferenceExplanation} from './rows/extension_difference_explanation';
import {LocationDifference} from './rows/location_difference';
import {LocationDifferenceExplanation} from './rows/location_difference_explanation';
import {LuxuryDifference} from './rows/luxury_difference';
import {LuxuryDifferenceExplanation} from './rows/luxury_difference_explanation';
import {Modal} from '../../../../../../components/modal';
import {ObjectDetails} from './rows/object_details';
import {ObjectTypeComponent} from './rows/object_type';
import {ObjectTypeDifference} from './rows/object_type_difference';
import {ObjectTypeDifferenceExplanation} from './rows/object_type_difference_explanation';
import {ObjectUsageExplanation} from './rows/object_usage_explanation';
import {OtherDifferences} from './rows/other_differences';
import {Ownership} from './rows/ownership';
import {QuestionSet} from '../../../../../../../../../../models/question_set';
import {ReferenceObject} from '../../../models/reference_object';
import {ReferenceObjectAnswer} from '../../../models/reference_object_answer';
import {ReferenceObjectAnswerModalPresenter} from '../reference_object_answer_modal_presenter';
import {ReferenceObjectAnswerModalSidebar} from '../reference_object_answer_modal_sidebar';
import {RentDetails} from './rows/rent_details';
import {SellDate} from './rows/sell_date';
import {SellPriceDetails} from './rows/sell_price_details';
import {SetType} from '../../../../../../../../../../models/reference_set/set_type';
import {Source} from './rows/source';
import {Volumes} from './rows/volumes';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../../../../../support/presenter/use_presenter';
import {SimpleTooltip} from '../../../../../../../../../../components/tooltip';

interface OwnProps {
    questionSet: QuestionSet;
    appraisal: Appraisal;
    setType: SetType;
    referenceObjectAnswer: ReferenceObjectAnswer;
    referenceObject: ReferenceObject;
    onChange: (referenceObjectAnswer: ReferenceObjectAnswer) => void;
    visible: boolean;
    isFrozen: boolean;
    onHide: () => void;
    gebruiksoppervlakteWonen: number | null;
    brutoInhoud: number | null;
    valuation: number | null;
    energyLabel: string | null;
    perceelOppervlakte: number | null;

    gebruiksOppervlakteBuitenruimte: number | null;
    overigeInpandigeRuimte: number | null;
    gebruiksOppervlakteExterneBergruimte: number | null;
    buildYear: number | null;
    children?: React.ReactNode;
}

export const ReferenceObjectAnswerModal: React.FC<OwnProps> = observer(function ReferenceObjectAnswerModal(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectAnswerModalPresenter(
                props.referenceObjectAnswer,
                props.onChange,
                props.onHide,
                props.questionSet,
                props.appraisal,
                props.setType,
                props.referenceObject,
                container.business.answerController(props.appraisal, props.questionSet)
            )
    );

    const referenceObjectAnswer = presenter.referenceObjectAnswer;
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;
    const validatesOwnershipMessage = presenter.validatesOwnershipMessage(referenceObjectAnswer);
    const validatesDatesMessage = presenter.validatesDatesMessage(referenceObjectAnswer);

    const getMemoizedChangeHandler = presenter.getMemoizedChangeHandler;
    const isFrozen = presenter.isFrozen;

    return (
        <Modal
            key={referenceObjectAnswer.id}
            forced={true}
            onHide={() => {
                if (presenter.canCloseModal(referenceObjectAnswer)) {
                    props.onHide();
                }
            }}
            visible={props.visible}
            sidebar={
                <ReferenceObjectAnswerModalSidebar
                    appraisal={props.appraisal}
                    surfaceArea={props.gebruiksoppervlakteWonen}
                    gebruiksOppervlakteBuitenruimte={props.gebruiksOppervlakteBuitenruimte}
                    overigeInpandigeRuimte={props.overigeInpandigeRuimte}
                    gebruiksOppervlakteExterneBergruimte={props.gebruiksOppervlakteExterneBergruimte}
                    buildYear={props.buildYear}
                    plotArea={props.perceelOppervlakte}
                />
            }
        >
            <div className="modal-header">
                <h2>Nieuw referentieobject</h2>
            </div>
            <div className="modal-body">
                <ObjectDetails
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    isFrozen={isFrozen}
                    imageUrlPairs={presenter.imagePairs}
                    referenceObject={props.referenceObject}
                    referenceObjectAnswer={referenceObjectAnswer}
                    valuation={props.valuation}
                    gebruiksOppervlakteWonen={props.gebruiksoppervlakteWonen}
                    brutoInhoud={props.brutoInhoud}
                    onPhotoChange={presenter.onPhotoChange}
                    onAdresChange={getMemoizedChangeHandler('adres')}
                />

                <BuildYear
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    referenceObjectAnswer={referenceObjectAnswer}
                    isFrozen={isFrozen}
                    onBuildYearChange={getMemoizedChangeHandler('bouwjaar')}
                />

                <EnergyLabel
                    appraisal={props.appraisal}
                    energyLabel={props.energyLabel}
                    referenceObjectAnswer={referenceObjectAnswer}
                    isFrozen={isFrozen}
                    onEnergyLabelChange={getMemoizedChangeHandler('energielabel')}
                    onEnergyLabelValidTillChange={getMemoizedChangeHandler('energielabelGeldigTot')}
                />

                <ObjectTypeComponent
                    referenceObjectAnswer={referenceObjectAnswer}
                    onObjectTypeChange={getMemoizedChangeHandler('woningType')}
                    isFrozen={isFrozen}
                />

                <Source
                    referenceObjectAnswer={referenceObjectAnswer}
                    onSourceChange={getMemoizedChangeHandler('bronGegevens')}
                    isFrozen={isFrozen}
                />

                <Volumes
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    gebruiksoppervlakteWonen={props.gebruiksoppervlakteWonen}
                    brutoInhoud={props.brutoInhoud}
                    referenceObjectAnswer={referenceObjectAnswer}
                    isFrozen={isFrozen}
                    onGebruiksOppervlakteChange={getMemoizedChangeHandler('gebruiksOppervlakte')}
                    onBrutoInhoudChange={getMemoizedChangeHandler('inhoud')}
                />

                <Area
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    perceelOppervlakte={props.perceelOppervlakte}
                    referenceObjectAnswer={referenceObjectAnswer}
                    onPerceelOppervlakteChange={getMemoizedChangeHandler('perceelOppervlakte')}
                    isFrozen={isFrozen}
                />

                {props.setType === SetType.RENT ? (
                    <RentDetails
                        referenceObjectAnswer={referenceObjectAnswer}
                        onAanvangsHuurprijsPerMaandChange={getMemoizedChangeHandler('aanvangsHuurprijsPerMaand')}
                        onGecorrigeerdeHuurprijsPerMaandChange={getMemoizedChangeHandler(
                            'gecorrigeerdeHuurprijsPerMaand'
                        )}
                        onIngangsdatumHuurChange={getMemoizedChangeHandler('ingangsdatumHuur')}
                        isFrozen={isFrozen}
                    />
                ) : (
                    <>
                        <SellPriceDetails
                            referenceObjectAnswer={referenceObjectAnswer}
                            onVerkoopprijsChange={getMemoizedChangeHandler('verkoopprijs')}
                            onGecorrigeerdeVerkoopprijsChange={getMemoizedChangeHandler('gecorrigeerdeVerkoopprijs')}
                            isFrozen={isFrozen}
                        />
                        <SellDate
                            appraisal={props.appraisal}
                            validatesDatesMessage={validatesDatesMessage}
                            referenceObjectAnswer={referenceObjectAnswer}
                            onVerkoopdatumChange={getMemoizedChangeHandler('verkoopdatum')}
                            onTransportdatumChange={getMemoizedChangeHandler('transportdatum')}
                            onVerkochtBinnenChange={getMemoizedChangeHandler('verkochtBinnen')}
                            isFrozen={isFrozen}
                        />
                    </>
                )}

                {/* This field is only required in model 2021 */}
                {isAppraiseModel(props.appraisal, AppraiseModel.MODEL2021) && (
                    <Ownership
                        referenceObjectAnswer={referenceObjectAnswer}
                        toggleOtherPropertySituation={presenter.toggleOtherPropertySituation}
                        hasOtherRights={presenter.hasOtherRights}
                        hasOtherPropertySituation={presenter.hasOtherPropertySituation}
                        validatesOwnershipMessage={validatesOwnershipMessage}
                        onEigendomssituatieVolleEigendomChange={getMemoizedChangeHandler(
                            'eigendomssituatieVolleEigendom'
                        )}
                        onEigendomssituatieRechtErfpachtChange={getMemoizedChangeHandler(
                            'eigendomssituatieRechtErfpacht'
                        )}
                        onEigendomssituatieRechtOndererfpachtChange={getMemoizedChangeHandler(
                            'eigendomssituatieRechtOndererfpacht'
                        )}
                        onEigendomssituatieRechtOpstalChange={getMemoizedChangeHandler('eigendomssituatieRechtOpstal')}
                        onEigendomssituatieRechtGebruikBewoningChange={getMemoizedChangeHandler(
                            'eigendomssituatieRechtGebruikBewoning'
                        )}
                        onEigendomssituatieRechtVruchtgebruikChange={getMemoizedChangeHandler(
                            'eigendomssituatieRechtVruchtgebruik'
                        )}
                        onEigendomssituatieRechtAndersChange={getMemoizedChangeHandler('eigendomssituatieRechtAnders')}
                        onEigendomssituatieToelichtingChange={getMemoizedChangeHandler('eigendomssituatieToelichting')}
                        onEigendomssituatieStatusChange={getMemoizedChangeHandler('eigendomssituatieStatus')}
                        onEigendomssituatieUitlegChange={getMemoizedChangeHandler('eigendomssituatieUitleg')}
                        onOnderhoudssituatieChange={getMemoizedChangeHandler('onderhoudssituatie')}
                        onOnderhoudsSituatieStatusChange={getMemoizedChangeHandler('onderhoudsSituatieStatus')}
                        onOnderhoudsSituatieUitlegChange={getMemoizedChangeHandler('onderhoudsSituatieUitleg')}
                        isFrozen={isFrozen}
                    />
                )}

                <ObjectTypeDifference
                    referenceObjectAnswer={referenceObjectAnswer}
                    onObjectTypeDifferenceChange={getMemoizedChangeHandler('woningTypeStatus')}
                    isFrozen={isFrozen}
                />
                {validationInstituteReferentieObject.woningTypeStatus !== 'Vergelijkbaar' && (
                    <ObjectTypeDifferenceExplanation
                        referenceObjectAnswer={referenceObjectAnswer}
                        onObjectTypeDifferenceExplanationChange={getMemoizedChangeHandler('woningTypeUitleg')}
                        isFrozen={isFrozen}
                    />
                )}

                <LuxuryDifference
                    referenceObjectAnswer={referenceObjectAnswer}
                    onLuxuryDifferenceChange={getMemoizedChangeHandler('mateVanLuxeStatus')}
                    isFrozen={isFrozen}
                />
                {validationInstituteReferentieObject.mateVanLuxeStatus !== 'Vergelijkbaar' && (
                    <LuxuryDifferenceExplanation
                        referenceObjectAnswer={referenceObjectAnswer}
                        onLuxuryDifferenceExplanationChange={getMemoizedChangeHandler('mateVanLuxeUitleg')}
                        isFrozen={isFrozen}
                    />
                )}

                <EfficiencyDifference
                    referenceObjectAnswer={referenceObjectAnswer}
                    onEfficiencyDifferenceChange={getMemoizedChangeHandler('mateVanDoelmatigheidStatus')}
                    isFrozen={isFrozen}
                />
                {validationInstituteReferentieObject.mateVanDoelmatigheidStatus !== 'Vergelijkbaar' && (
                    <EfficiencyDifferenceExplanation
                        referenceObjectAnswer={referenceObjectAnswer}
                        onEfficiencyDifferenceExplanationChange={getMemoizedChangeHandler('mateVanDoelmatigheidUitleg')}
                        isFrozen={isFrozen}
                    />
                )}

                <LocationDifference
                    referenceObjectAnswer={referenceObjectAnswer}
                    onLocationDifferenceChange={getMemoizedChangeHandler('liggingStatus')}
                    isFrozen={isFrozen}
                />
                {validationInstituteReferentieObject.liggingStatus !== 'Vergelijkbaar' && (
                    <LocationDifferenceExplanation
                        referenceObjectAnswer={referenceObjectAnswer}
                        onLocationDifferenceExplanationChange={getMemoizedChangeHandler('liggingUitleg')}
                        isFrozen={isFrozen}
                    />
                )}

                <Extension
                    referenceObjectAnswer={referenceObjectAnswer}
                    onExtensionChange={getMemoizedChangeHandler('aanbouw')}
                    isFrozen={isFrozen}
                />
                <ExtensionDifference
                    referenceObjectAnswer={referenceObjectAnswer}
                    onExtensionDifferenceChange={getMemoizedChangeHandler('aanbouwStatus')}
                    isFrozen={isFrozen}
                />
                {validationInstituteReferentieObject.aanbouwStatus !== 'Vergelijkbaar' && (
                    <ExtensionDifferenceExplanation
                        referenceObjectAnswer={referenceObjectAnswer}
                        onExtensionDifferenceExplanationChange={getMemoizedChangeHandler('aanbouwUitleg')}
                        isFrozen={isFrozen}
                    />
                )}

                {/* This field is only required in model 2021 */}
                {isAppraiseModel(props.appraisal, AppraiseModel.MODEL2021) && (
                    <OtherDifferences
                        referenceObjectAnswer={referenceObjectAnswer}
                        onOtherDifferencesChange={getMemoizedChangeHandler('andereWezenlijkeVerschillen')}
                        onOtherDifferencesExplanationChange={getMemoizedChangeHandler(
                            'andereWezenlijkeVerschillenUitleg'
                        )}
                        isFrozen={isFrozen}
                    />
                )}

                <ObjectUsageExplanation
                    referenceObjectAnswer={referenceObjectAnswer}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    onObjectUsageExplanationChange={getMemoizedChangeHandler('toelichtingGebruikReferentieObject')}
                    gebruiksoppervlakteWonen={props.gebruiksoppervlakteWonen}
                    perceelOppervlakte={props.perceelOppervlakte}
                    brutoInhoud={props.brutoInhoud}
                    isFrozen={isFrozen}
                />
            </div>
            <SimpleTooltip
                content={
                    'Enkele antwoorden welke verplicht zijn zijn nog niet ingevuld, dat zijn de volgende velden: ' +
                    presenter.cannotCloseModalBecause(referenceObjectAnswer) +
                    '.'
                }
                placement="top"
                disabled={presenter.canCloseModal(referenceObjectAnswer)}
            >
                <div className="modal-footer" data-type="error">
                    {props.children}
                    <button
                        className="btn btn-primary ion-md-close"
                        type="button"
                        disabled={!presenter.canCloseModal(referenceObjectAnswer)}
                        onClick={() => presenter.closeModal()}
                    >
                        Opslaan & sluiten
                    </button>
                </div>
            </SimpleTooltip>
        </Modal>
    );
});
