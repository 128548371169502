import {IMemoryStorage} from 'indexed-memory-storage';
import {Answer} from '../../../models/answer';

export class DeletedAnswersFilter {
    public filter(toBeFilteredAnswers: Answer[], answerStorage: IMemoryStorage<Answer>): Answer[] {
        return toBeFilteredAnswers.filter((answer) => !this.isDeleted(answer, answerStorage));
    }

    private isDeleted(answer: Answer, answerStorage: IMemoryStorage<Answer>): boolean {
        if (answer.isDeleted) {
            return true;
        }
        if (answer.parentUuid !== null) {
            const parent = this.getParent(answer, answerStorage);
            return parent === null || this.isDeleted(parent, answerStorage);
        }

        return false;
    }

    private getParent(answer: Answer, answerStorage: IMemoryStorage<Answer>): Answer | null {
        if (answer.parentUuid === null) {
            return null;
        }

        return answerStorage.get(answer.parentUuid);
    }
}
