import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {QuestionSet} from '../../../../models/question_set';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {ReportReplacements} from './report_replacements';
import {ReportSelectorType} from '../../../../enum/report_selector_type';
import {QuestionRenderingData} from '../../../../models/question_rendering_data';

export interface ReportReplacementsProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    onClose: () => void;
    onReferenceFocus: (reference: ReportSelectorType) => void;
    lastFocusedReference: ReportSelectorType | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export class ReportReplacementsWidget extends React.Component<ReportReplacementsProps> {
    public render() {
        return (
            <div className="report-replacements-widget">
                <div className="report-replacements-widget-container">
                    <div className="report-replacements-widget-title">
                        <h2>Concept rapport</h2>
                    </div>
                    <ReportReplacements {...this.props} />
                </div>
            </div>
        );
    }
}
