import {NetworkStatus, NetworkStatusProvider} from '../../../../../business/network_status_provider';
import {
    PhotoAnswerRetryInteractor,
    PhotoAnswerRetryStatus,
} from '../../../../../business/photo_answer_retry_interactor';
import {UnjudgedPage, UnjudgedPagesProvider} from '../unjudged_pages_provider';
import {
    ValidationConclusion,
    ValidationConclusionProvider,
} from '../../../../../business/validation/validation_conclusion_provider';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {AnswerInteractor} from '../../../../../business/answering/answer_interactor';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {Global} from '../../../../../../business/global_provider';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {SidebarItem} from '../../../../../business/sidebar_tree_builder';
import {UnsyncedAnswerRetryInteractor} from '../../../../../business/unsynced_answer_retry_interactor';
import {first} from 'rxjs/operators';
import {AppraisalApi} from '../../../../../network/appraisal_api';
import {TaskHelper} from '../../../../../business/task_helper';
import {Appraisal} from '../../../../../models/appraisal';
import {FlashMessageBroadcaster, Type} from '../../../../../business/flash_message_broadcaster';

export class ValidationErrorsContentPresenter implements Presenter {
    @observable public savingAnswers = false;

    @observable public networkStatus: NetworkStatus = NetworkStatus.OFFLINE;

    @observable.ref public validationConclusion: ValidationConclusion | null = null;

    @observable.ref public unjudgedPages: UnjudgedPage[] = [];
    @observable public numUnsavedAnswers: number | null = null;
    @observable public numUnsyncedPhotos: number | null = null;

    @observable public photoAnswerRetryStatus: PhotoAnswerRetryStatus | null = null;

    @observable public modalAcceptTermsVisible = false;
    @observable public acceptTermsManually = false;
    @observable public modalAcceptTermsShowErrors = false;
    @observable public loadingAcceptTerms = false;
    @observable public loadingRemindTerms = false;
    @observable public hasForcedAcceptedTerms = false;
    @observable public loadingCheckAttachments = false;
    @observable public hasCheckedAttachments = false;

    /**
     * Very special item, since filling of answers etc. is bound to the way our tree renders into view
     * (taking conditions etc. into account) it is really hard to mark something as seen, the most efficient
     * and reliable way is by simply rendering it into view..
     */
    @observable public hiddenRenderingSidebarItem: SidebarItem | null = null;

    @computed
    public get hasError(): boolean {
        return (
            this.numUnsyncedPhotos === null ||
            this.numUnsyncedPhotos > 0 ||
            this.numUnsavedAnswers === null ||
            this.numUnsavedAnswers > 0 ||
            this.unjudgedPages.length > 0 ||
            (this.validationConclusion?.hasValidationErrors ?? false)
        );
    }

    @computed
    public get hasPreValidationError(): boolean {
        return this.validationConclusion?.hasPreValidationErrors ?? false;
    }

    private subscriptions = new CompositeSubscription();

    constructor(
        global: Global,
        private photoAnswerRetryInteractor: PhotoAnswerRetryInteractor,
        private answerInteractor: AnswerInteractor,
        private networkStatusProvider: NetworkStatusProvider,
        private unjudgedPagesProvider: UnjudgedPagesProvider,
        private validationConclusionProvider: ValidationConclusionProvider,
        private unsyncedAnswerRetryInteractor: UnsyncedAnswerRetryInteractor,
        private appraisal: Appraisal,
        private appraisalApi: AppraisalApi,
        private taskHelper: TaskHelper,
        private flashMessageBroadcaster: FlashMessageBroadcaster
    ) {
        this.hasCheckedAttachments = global.attachmentsCheckedBy !== null;
        makeObservable(this);
    }

    public async mount(): Promise<void> {
        //Force submitting before trying to conclude anything
        await this.answerInteractor.submit();

        this.subscriptions.add(
            this.validationConclusionProvider.stream.subscribe((validationConclusion) => {
                runInAction(() => {
                    this.validationConclusion = validationConclusion;
                });
            })
        );

        this.subscriptions.add(
            this.photoAnswerRetryInteractor.numUnsyncedPhotos.subscribe((numUnsyncedPhotos) => {
                runInAction(() => {
                    this.numUnsyncedPhotos = numUnsyncedPhotos;
                });
            })
        );

        this.subscriptions.add(
            this.unsyncedAnswerRetryInteractor.numUnsyncedAnswers.subscribe((numUnsavedAnswers) => {
                runInAction(() => {
                    this.numUnsavedAnswers = numUnsavedAnswers;
                });
            })
        );

        this.subscriptions.add(
            this.networkStatusProvider.status().subscribe((networkStatus) => {
                runInAction(() => {
                    this.networkStatus = networkStatus;
                });
            })
        );

        this.subscriptions.add(
            this.unjudgedPagesProvider.get().subscribe((unjudgedPages) => {
                runInAction(() => {
                    this.unjudgedPages = unjudgedPages;
                });
            })
        );

        this.validationConclusionProvider.refresh();
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public onRetryPhotoUploadClick() {
        this.subscriptions.add(
            this.photoAnswerRetryInteractor.attempt().subscribe((photoAnswerRetryStatus) => {
                runInAction(() => {
                    this.photoAnswerRetryStatus = photoAnswerRetryStatus;
                });
            })
        );
    }

    public async onAnswerSyncClick() {
        try {
            runInAction(() => {
                this.savingAnswers = true;
            });

            const numUnsavedAnswers =
                (await this.unsyncedAnswerRetryInteractor.attempt().pipe(first()).toPromise()) ?? null;
            runInAction(() => {
                this.numUnsavedAnswers = numUnsavedAnswers;
            });
        } finally {
            runInAction(() => {
                this.savingAnswers = false;
            });
        }
    }

    public async onAnswerSyncCancelClick() {
        this.unsyncedAnswerRetryInteractor.ignore();
    }

    public async onMarkSeenClick(sidebarItem: SidebarItem) {
        runInAction(() => {
            this.hiddenRenderingSidebarItem = sidebarItem;
        });
        setTimeout(async () => {
            await this.answerInteractor.submit();

            this.validationConclusionProvider.refresh();

            const unjudgedPages = (await this.unjudgedPagesProvider.get().pipe(first()).toPromise()) ?? [];

            runInAction(() => {
                this.unjudgedPages = unjudgedPages;
                this.hiddenRenderingSidebarItem = null;
            });
        }, 1);
    }

    @action
    public setModalAcceptTermsVisible(visible: boolean) {
        this.modalAcceptTermsVisible = visible;

        if (!visible) {
            this.acceptTermsManually = false;
            this.loadingAcceptTerms = false;
            this.modalAcceptTermsShowErrors = false;
        }
    }

    @action
    public setAcceptTermsManually(accept: boolean) {
        this.acceptTermsManually = accept;
    }

    public async triggerAcceptTerms() {
        if (!this.acceptTermsManually) {
            runInAction(() => {
                this.modalAcceptTermsShowErrors = true;
            });

            return;
        }

        runInAction(() => {
            this.modalAcceptTermsShowErrors = false;
            this.loadingAcceptTerms = true;
        });

        const acceptTermsResult = await this.appraisalApi.forceAcceptTerms(this.appraisal.id);
        if (TaskHelper.isTaskReference(acceptTermsResult)) {
            await this.taskHelper.poll<string>(acceptTermsResult.taskId);
        }

        this.flashMessageBroadcaster.broadcast(
            'De opdrachtvoorwaarden zijn sucessvol geaccepteerd op naam van de klant.',
            Type.Success
        );

        runInAction(() => {
            this.loadingAcceptTerms = false;
            this.hasForcedAcceptedTerms = true;
            this.modalAcceptTermsVisible = false;
        });

        await this.ignoreValidationGate();
    }

    public async triggerAttachmentCheck() {
        runInAction(() => {
            this.loadingCheckAttachments = true;
        });

        const attachmentsCheckedResult = await this.appraisalApi.markAttachmentsChecked(this.appraisal.id);
        if (TaskHelper.isTaskReference(attachmentsCheckedResult)) {
            await this.taskHelper.poll<string>(attachmentsCheckedResult.taskId);
        }

        this.flashMessageBroadcaster.broadcast('De bijlagen zijn gemarkeerd als nagekeken.', Type.Success);

        runInAction(() => {
            this.loadingCheckAttachments = false;
            this.hasCheckedAttachments = true;
        });

        await this.ignoreValidationGate();
    }

    private async ignoreValidationGate() {
        await this.validationConclusionProvider.setIgnoreValidationGate(
            (this.validationConclusion?.gateAllowsValidation || this.hasForcedAcceptedTerms) &&
                this.hasCheckedAttachments
        );
    }

    public async triggerRemindTerms() {
        runInAction(() => {
            this.loadingRemindTerms = true;
        });

        await this.appraisalApi.remindClientTerms(this.appraisal.id);

        this.flashMessageBroadcaster.broadcast('Herinnering succesvol verzonden', Type.Success);

        runInAction(() => {
            this.loadingRemindTerms = false;
            this.modalAcceptTermsVisible = false;
        });
    }
}
