import * as React from 'react';
import {QuestionTableCell} from '../../../../../../../components/question_table/question_table_cell';
import {QuestionTableCollapseButton} from '../../../../../../../components/question_table/question_table_collapse_button';
import {QuestionTableCollapsible} from '../../../../../../../components/question_table/question_table_collapsible';

interface OwnProps {
    title: string;
    questionUuid: string;
    children?: React.ReactNode;
}

export class ReferenceObjectComparisonColumnGroup extends React.Component<OwnProps> {
    public render() {
        return (
            <QuestionTableCell questionUuid={this.props.questionUuid}>
                <div className="question-table-seperator" />
                <div className="group-container group-container-compact">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h2 className="mt-0">{this.props.title}</h2>
                        </div>
                        <QuestionTableCollapseButton />
                    </div>

                    <QuestionTableCollapsible>{this.props.children}</QuestionTableCollapsible>
                </div>
            </QuestionTableCell>
        );
    }
}
