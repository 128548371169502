import {isSet} from './is_set';

export function isInEnum<T extends Record<string, string | number>>(
    enumType: T,
    value: string | number | undefined | null
): value is T[keyof T] {
    if (!isSet(value)) {
        return false;
    }

    return Object.values(enumType).includes(value);
}
