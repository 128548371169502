import * as React from 'react';
import {Sustainability} from '../../../../models/sustainability';
import {observer} from 'mobx-react';
import {formatMoney} from '../../support/format_money';
import {EnergyLabelIcon} from '../../../../components/badges/energy_label_icon';
import {SuggestedMeasure} from './energetic_sheet_presenter';

interface EnergeticSheetResultProps {
    data: Sustainability;
    measures: SuggestedMeasure[];
    labelDiffers: boolean;

    fillMeasures: () => void;
}

export const EnergeticSheetResult: React.FC<EnergeticSheetResultProps> = observer(
    ({data, measures, labelDiffers, fillMeasures}) => {
        const footNotes = measures
            .map((m) => m.footNote)
            .filter((note): note is string => note !== undefined)
            .filter((note, i, arr) => arr.indexOf(note) === i); // Deduplicate

        return (
            <div className="widget-blocks widget-blocks-no-background mt-2">
                <div className="row">
                    <div className="col-6">
                        <div className="widget-block">
                            <h3>Potentiële ecowaarde</h3>
                            <div className="widget-block-number">
                                {data.financial.ecoValue !== null
                                    ? `+ ${formatMoney(data.financial.ecoValue)}`
                                    : 'Onbekend'}
                            </div>
                            <small className="widget-block-details">geschatte marktwaardestijging</small>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="widget-block">
                            <h3>Investering</h3>
                            <div className="widget-block-number">
                                {data.financial.totalInvestment !== null
                                    ? formatMoney(data.financial.totalInvestment)
                                    : 'Onbekend'}
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="widget-block">
                            <h3>Besparing</h3>
                            <div className="widget-block-number">
                                {data.financial.savingsMonthly !== null
                                    ? formatMoney(data.financial.savingsMonthly)
                                    : 'Onbekend'}
                            </div>
                            <small className="widget-block-details">maandelijks in energiekosten</small>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="widget-block">
                            <h3>Energielabelstijging</h3>
                            <div className="widget-block-number">
                                <EnergyLabelIcon
                                    energyLabel={data.label.current.value}
                                    withBackground={!labelDiffers}
                                />
                                <span>→</span>
                                <EnergyLabelIcon energyLabel={data.label.future.value} />
                            </div>
                            {labelDiffers && (
                                <small>
                                    Het huidige label is berekend op basis van de ingevoerde gegevens en kan afwijken
                                    van het werkelijke label.
                                </small>
                            )}
                        </div>
                    </div>

                    <div className="col-12">
                        <h2 className="w-50 mt-4">Maatregelen</h2>
                        <a className={'btn btn-sm btn-primary float-right ion-md-create mt-3'} onClick={fillMeasures}>
                            Invullen in opname
                        </a>
                    </div>
                    <ul className="list-circles">
                        {measures.map((measure, i) => (
                            <li key={i}>
                                <span className="list-avatar ion-md-construct" />
                                <span className="list-title">
                                    <strong>{measure.displayName}</strong>
                                    {measure.footNote ? '*'.repeat(footNotes.indexOf(measure.footNote) + 1) : ''}
                                    <span className="thin">&nbsp;- {formatMoney(measure.investment)}</span>
                                </span>
                                <small className="list-details">
                                    <strong>
                                        {measure.current.description} → {measure.future.description}
                                    </strong>
                                </small>
                            </li>
                        ))}
                    </ul>
                    <div className="col-12">
                        {footNotes.map((note, i) => (
                            <small key={i} className="text-muted">
                                {'*'.repeat(i + 1)}
                                {note}
                            </small>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
);
