import {
    apiReferenceObjectToReferenceObject,
    ApiReferenceSale,
    apiReferenceSalesToReferenceSales,
} from '../../../../../../../network/models/api_reference_sale';
import {
    ReferenceObjectProvider,
    ReferenceSaleSetData,
    ReferenceSaleSetRequestData,
} from '../../../../../../../business/reference_object_provider';

import {TaskHelper} from '../../../../../../../business/task_helper';
import {hashSetDefinition} from './hash_set_definition';
import {concat, defer, EMPTY, from, Observable, of} from 'rxjs';
import {catchError, map, scan, shareReplay, switchMap, throttleTime} from 'rxjs/operators';
import {ReferenceSale} from '../v1/models/reference_sale';

export interface ReferenceObjectsInteractor {
    request(data: ReferenceSaleSetRequestData): Promise<ReferenceSaleSetData | null>;

    requestEnhancement(taskId: number, data: ReferenceSaleSetRequestData): Promise<ReferenceSaleSetData | null>;

    requestEnhancementStream(
        taskId: number,
        data: ReferenceSaleSetRequestData
    ): Observable<ReferenceSaleSetData | null>;

    requestHighlightedReferenceSaleEnhancement(
        referenceSaleId: string,
        data: ReferenceSaleSetRequestData
    ): Promise<ReferenceSale | null>;

    clearCached(data: ReferenceSaleSetRequestData): void;
}

export class DefaultReferenceObjectsInteractor implements ReferenceObjectsInteractor {
    private cacheMap = new Map<string, Promise<ReferenceSaleSetData | null>>();
    private enhancementsCacheMap = new Map<number, Observable<ReferenceSaleSetData | null>>();
    private highlightedEnhancementsCacheMap = new Map<string, Promise<ReferenceSale | null>>();
    private queue: Promise<ReferenceSaleSetData | null> = Promise.resolve(null);

    constructor(private referenceObjectProvider: ReferenceObjectProvider, private taskHelper: TaskHelper) {}

    public request(data: ReferenceSaleSetRequestData): Promise<ReferenceSaleSetData | null> {
        const hash = hashSetDefinition(data);
        const valueInCache = this.cacheMap.get(hash);
        if (valueInCache) {
            return valueInCache;
        }

        // We only allow a maximum of 1 references request to run in parallel to prevent race conditions (to do with cost tracking) on the backend
        this.queue = this.queue.then(() => this.referenceObjectProvider.getReferenceSalesSet(data));

        this.cacheMap.set(hash, this.queue);

        return this.queue;
    }

    public async requestEnhancement(
        taskId: number,
        data: ReferenceSaleSetRequestData
    ): Promise<ReferenceSaleSetData | null> {
        const hash = hashSetDefinition(data);
        const valueInCache = this.cacheMap.get(hash);

        if (valueInCache) {
            const result: ApiReferenceSale[] | null = await this.taskHelper.poll(taskId);

            if (result) {
                const referenceSales = apiReferenceSalesToReferenceSales(result);
                const newSetData = {...valueInCache, referenceSales};
                this.cacheMap.set(hash, Promise.resolve(newSetData));
                return newSetData;
            }
        }

        return null;
    }

    public requestEnhancementStream(
        taskId: number,
        data: ReferenceSaleSetRequestData
    ): Observable<ReferenceSaleSetData | null> {
        if (this.enhancementsCacheMap.has(taskId)) {
            return this.enhancementsCacheMap.get(taskId) as Observable<ReferenceSaleSetData | null>;
        }

        const hash = hashSetDefinition(data);
        const valueInCache = this.cacheMap.get(hash);

        if (valueInCache) {
            const stream = from(valueInCache).pipe(
                map((val) => {
                    if (val === null) {
                        throw new Error('Reference set was not loaded');
                    }

                    return val;
                }),
                switchMap((referenceSales) =>
                    this.taskHelper.stream<ApiReferenceSale>(taskId).pipe(
                        map((object) => apiReferenceObjectToReferenceObject(object)),
                        scan((previousData, value) => {
                            const newData = {
                                ...previousData,
                                referenceSales: [...previousData.referenceSales],
                            };

                            const index = previousData.referenceSales.findIndex(
                                (s) => s.id === value.id && s.source === value.source
                            );
                            if (index === -1) {
                                newData.referenceSales.push(value);
                            } else {
                                newData.referenceSales[index] = value;
                            }

                            return newData;
                        }, referenceSales),
                        // Update a maximum of once every 1s with the aggregated data (for performance)
                        throttleTime(1000, undefined, {leading: true, trailing: true})
                    )
                ),
                catchError((err) => {
                    console.error(
                        'Caught error while loading enhancements stream. Falling back to task polling. Error: ',
                        err
                    );
                    // Returning an empty observable will immediately continue to the concatted deferred taskHelper.poll call
                    return EMPTY;
                }),
                (o) =>
                    concat(
                        o,
                        defer(async () => {
                            // After stream, load full task result using taskHelper.poll just to make sure we didn't miss any streaming updates
                            const result: ApiReferenceSale[] | null = await this.taskHelper.poll(taskId);
                            const cachedValue = await valueInCache;

                            if (result) {
                                const referenceSales = apiReferenceSalesToReferenceSales(result);
                                const newSetData = {...(cachedValue ?? {}), referenceSales} as ReferenceSaleSetData;
                                this.cacheMap.set(hash, Promise.resolve(newSetData));
                                return newSetData;
                            }

                            return null;
                        })
                    ),
                shareReplay(1)
            );

            this.enhancementsCacheMap.set(taskId, stream);

            return stream;
        }

        return of(null);
    }

    public async requestHighlightedReferenceSaleEnhancement(
        referenceSaleId: string,
        data: ReferenceSaleSetRequestData
    ): Promise<ReferenceSale | null> {
        const cacheEntry = this.highlightedEnhancementsCacheMap.get(referenceSaleId);
        if (cacheEntry) {
            return cacheEntry;
        }

        const promise = this.referenceObjectProvider.enhanceHighlightedReferenceSale(referenceSaleId, data);

        this.highlightedEnhancementsCacheMap.set(referenceSaleId, promise);

        return promise;
    }

    public clearCached(data: ReferenceSaleSetRequestData): void {
        const hash = hashSetDefinition(data);

        this.cacheMap.delete(hash);
    }
}
