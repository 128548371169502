import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';
import {findChildRecursiveByPredicate, findParentByPredicateRecursive} from '../../../../support/generic_tree';

import {Answer} from '../../../models/answer';
import {AnswerValidator} from '../answer_validator';
import {IteratorQuestionType} from '../../../enum/question_type';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {TechnicalReference} from '../../../enum/technical_reference';
import {V3SetDefinitionsProvider} from '../../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/reference_sets/set_definitions_provider';

export class V3UniqueSpecialValueValidator implements AnswerValidator {
    constructor(private setDefinitionsProvider: V3SetDefinitionsProvider, private questionSet: QuestionSet) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (answer === null || question.technicalReference !== TechnicalReference.VALUATION_SPECIAL_EXPLANATION_MC) {
            return [];
        }

        const valuationGroup = this.questionSet.findParentByPredicateRecursive(
            question,
            (parent) => parent.technicalReference === TechnicalReference.VALUATION_GROUP
        );
        if (valuationGroup === null) {
            return [];
        }

        const result = this.setDefinitionsProvider.getSetDefinitions(valuationGroup, false);
        if (result === null) {
            return [];
        }

        const {setDefinitions} = result;
        const thisSetDefinition = setDefinitions.find((setDefinition) => {
            const iteratorItem = findParentByPredicateRecursive(
                setDefinition.groupTree,
                (item) => item.question.type === IteratorQuestionType.ITERATOR
            );
            if (iteratorItem === null) {
                return false;
            }

            const explanationMcItem = findChildRecursiveByPredicate(
                iteratorItem,
                (item) => item.answer?.uuid === answer.uuid
            );

            return explanationMcItem !== null;
        });
        if (thisSetDefinition === undefined) {
            return [];
        }

        const othersWithSameValuation = setDefinitions.some(
            (setDefinition) =>
                setDefinition.valuationType === thisSetDefinition.valuationType && setDefinition !== thisSetDefinition
        );
        if (othersWithSameValuation === false) {
            return [];
        }

        return [
            {
                type: ValidationMessageType.TAXAPI,
                importance: ValidationMessageImportance.ERROR,
                question: question,
                answer: answer,
                messages: [],
                fallbackMessage:
                    'Het is niet toegestaan om meedere waardebegrippen te hebben met hetzelfde uitgangspunt.',
                floorInfo: null,
            },
        ];
    }
}
