import * as React from 'react';

import {Appraisal} from '../../models/appraisal';
import {FunctionComponent} from 'react';
import {Loader} from '../../appraise/ui/components/loader';
import {PagePartIndexPage} from '../../appraise/ui/content/questions/page_part_index_page';
import {PagePartsSet} from '../../models/page_parts_set';
import {Question} from '../../models/question';
import {QuestionContainer} from '../../appraise/ui/content/questions/question_container';
import {QuestionSet} from '../../models/question_set';
import {RenderingContextType} from '../../enum/rendering_context_type';
import {Select} from '../../appraise/ui/components/select';
import {SortablePagePartChildren} from '../../appraise/ui/content/questions/advanced/page_part/sortable_page_part_children';

interface OwnProps {
    appraisal: Appraisal | null;
    questionSet: QuestionSet | null;
    pagePartsSet: PagePartsSet | null;
    activePagePartUuid: string | null;
    unassignedPagePartQuestions: Question[];
    isLoading: boolean;
    onQuestionAdded: (parentPagePartUuid: string, questionUuid: string) => void;
    onSave: () => void;
    isLoadingLeave: boolean;
    onLeave: () => Promise<void>;
}

export const PageConfigurator: FunctionComponent<OwnProps> = ({
    appraisal,
    questionSet,
    pagePartsSet,
    activePagePartUuid,
    unassignedPagePartQuestions,
    isLoading,
    onQuestionAdded,
    onSave,
    isLoadingLeave,
    onLeave,
}) => {
    if (pagePartsSet === null || appraisal === null || questionSet === null) {
        return (
            <div className="empty-message appraise">
                <Loader />
                <h3>Aan het laden...</h3>
            </div>
        );
    }

    const activePagePart = activePagePartUuid ? pagePartsSet.getByUuid(activePagePartUuid) ?? null : null;
    if (activePagePart === null) {
        if (pagePartsSet.rootItems.length === 0) {
            return (
                <div className="empty-message appraise">
                    <h3>Begin met het aanmaken van een nieuwe pagina.</h3>
                    <span className="spacer-gradient">&nbsp;</span>
                </div>
            );
        }
        return (
            <div className="empty-message appraise">
                <h3>Helaas, fout tijdens het laden</h3>
                <span className="spacer-gradient">&nbsp;</span>
            </div>
        );
    }

    const question = activePagePart.questionUuid
        ? questionSet.findQuestionByUuid(activePagePart.questionUuid) ?? null
        : null;

    const canAddPagepart =
        pagePartsSet.config.isEditable &&
        activePagePart.isIndexPage === false &&
        unassignedPagePartQuestions.length > 0;

    return (
        <div className="card d-flex page-part-configurator">
            <span>
                <div className="card-header">{activePagePart.name}</div>
                <div className="card-body">
                    {!question && activePagePart.isIndexPage && (
                        <PagePartIndexPage
                            appraisal={appraisal}
                            questionSet={questionSet}
                            activePagePart={activePagePart}
                            pagePartsSet={pagePartsSet}
                            renderingContext={RenderingContextType.PAGE_PARTS_CONFIGURATOR}
                        />
                    )}
                    {question && (
                        <QuestionContainer
                            appraisal={appraisal}
                            question={question}
                            questionSet={questionSet}
                            validationErrors={[]}
                            validationMessages={{}}
                            hiddenQuestionTypes={[]}
                            onChange={() => {
                                //Noop
                            }}
                            pagePartsSet={pagePartsSet}
                            activePagePart={activePagePart}
                            disabled={true}
                            parentAnswerUuid={undefined}
                            renderingContext={RenderingContextType.PAGE_PARTS_CONFIGURATOR}
                            forceShowValidationMessages={false}
                            questionRenderingData={null}
                        />
                    )}
                    {!activePagePart.isIndexPage && (
                        <SortablePagePartChildren
                            pagePartUuid={activePagePart.uuid}
                            appraisal={appraisal}
                            questionSet={questionSet}
                            validationErrors={[]}
                            validationMessages={{}}
                            hiddenQuestionTypes={[]}
                            onChange={() => {
                                //Noop
                            }}
                            pagePartsSet={pagePartsSet}
                            activePagePart={activePagePart}
                            disabled={true}
                            parentAnswerUuid={undefined}
                            renderingContext={RenderingContextType.PAGE_PARTS_CONFIGURATOR}
                            forceShowValidationMessages={false}
                            questionRenderingData={null}
                        />
                    )}

                    {canAddPagepart && (
                        <>
                            <span className="label-container">
                                <label htmlFor="unassigned-questions">Vragen die nog ingedeeld moeten worden</label>
                            </span>
                            <Select
                                id="unassigned-questions"
                                value={'' || '-1'}
                                isDisabled={unassignedPagePartQuestions.length === 0}
                                placeHolder="Selecteer een vraag om toe te voegen"
                                onChange={(newValue) => {
                                    if (newValue) {
                                        onQuestionAdded(activePagePart.uuid, newValue);
                                    }
                                }}
                                options={unassignedPagePartQuestions.map((unassignedPagePartQuestion) => {
                                    return {
                                        label: unassignedPagePartQuestion.contents,
                                        value: unassignedPagePartQuestion.uuid,
                                    };
                                })}
                            />
                        </>
                    )}
                </div>
            </span>

            <footer className="d-flex justify-content-end w-100 overflow-hidden">
                <button disabled={isLoadingLeave} className="btn btn-secondary" onClick={onLeave}>
                    {isLoadingLeave ? <Loader /> : 'Terug naar opnameomgeving'}
                </button>
                {pagePartsSet.config.isEditable === true && (
                    <button disabled={isLoading || isLoadingLeave} className="btn btn-primary" onClick={onSave}>
                        {isLoading && !isLoadingLeave ? <Loader /> : 'Opslaan'}
                    </button>
                )}
            </footer>
        </div>
    );
};
