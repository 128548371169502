import * as React from 'react';

import {
    ActiveSetDefinitionState,
    PromotionModalType,
    ReferenceObjectsQuestionPresenter,
} from './reference_objects_question_presenter';

import {DummyReferenceObjectsQuestion} from './dummy_reference_objects_question';
import {Loader} from '../../../../../components/loader';
import {NetworkStatus} from '../../../../../../../business/network_status_provider';
import {ReferenceObjectGrid} from './components/reference_object_grid';
import {ReferenceObjectModals} from './components/modals/reference_object_modals';
import {RenderingContextType} from '../../../../../../../enum/rendering_context_type';
import {SetDefinitionTabs} from './components/set_definition_tabs';
import {SimpleQuestionPresenterProps} from '../../../simple/simple_question_presenter';
import {ValuationAnswersContainer} from './components/valuation_answers_container';
import {humanizeArray} from '../../../../../support/humanize_array';
import {observer} from 'mobx-react';
import {Modal} from '../../../components/modal';
import {Type} from '../../../../../../../business/flash_message_broadcaster';
import {ReferenceSubscriptionPromotionType} from '../../../../../../../models/reference_subscriptions';
import {usePresenter} from '../../../../../../../../support/presenter/use_presenter';
import {
    ReferenceObjectsQuestionContext,
    ReferenceObjectsQuestionContextType,
} from './referene_objects_question_context';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const ReferenceObjectsQuestion: React.FC<OwnProps> = observer(function ReferenceObjectsQuestion(props) {
    if (props.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
        return <DummyReferenceObjectsQuestion />;
    }

    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectsQuestionPresenter(
                props.appraisal,
                props.question,
                props.questionSet,
                container.business.appraisalProvider,
                props.parentAnswerUuid,
                props.iteration,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.v3ReferenceSetsProvider(props.appraisal, props.questionSet),
                container.business.referenceObjectsInteractor(props.appraisal, props.questionSet),
                container.business.appraiseSecondaryConfigStackInteractor,
                container.business.modalConfigStackInteractor,
                container.business.networkStatusProvider,
                container.business.buildYearProvider(props.appraisal, props.questionSet),
                container.business.answerPathStubber(props.appraisal, props.questionSet, props.renderingContext),
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.imageUploadInteractor(props.appraisal, props.questionSet),
                container.business.flashMessageBroadcaster,
                container.globalProvider,
                container.business.distanceProvider,
                container.business.referenceObjectsMetadataProvider(props.appraisal, props.questionSet),
                container.business.referenceObjectsAnswerEnhancementInteractor(props.appraisal, props.questionSet)
            )
    );

    const contextValue = React.useMemo<ReferenceObjectsQuestionContextType>(
        () => ({
            showDetailsModal: presenter.showDetailsModal,
            showImagesModal: presenter.showImagesModal,
        }),
        [presenter.showDetailsModal, presenter.showImagesModal]
    );

    if (presenter.referenceSets === null) {
        return (
            <div className="row">
                <div className="col-12 text-center">
                    <Loader icon="ion-md-pin" />
                </div>
            </div>
        );
    }

    function renderContent() {
        if (presenter.networkStatus === NetworkStatus.OFFLINE) {
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="alert-inline alert-danger ion-md-close-circle">
                            <p>
                                <small>
                                    Deze functie is offline niet beschikbaar, zorg voor een stabiele internet verbinding
                                    en zorg ervoor dat alle data opgeslagen is.
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            );
        }

        if (presenter.activeSetDefinition) {
            return (
                <>
                    <ValuationAnswersContainer
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        validationErrors={props.validationErrors}
                        validationMessages={props.validationMessages}
                        forceShowValidationMessages={props.forceShowValidationMessages}
                        activeSetDefinition={presenter.activeSetDefinition}
                        disabled={presenter.activeSetDefinition.state === ActiveSetDefinitionState.DISABLED}
                        suggestedReferenceObjects={presenter.suggestedReferenceObjects}
                        canAdd={presenter.canAdd}
                        onSearch={presenter.triggerSearchActiveSet}
                        onAdd={(objectData) =>
                            presenter.onAdd(
                                objectData.referenceObjectAnswer,
                                objectData.referenceObjectTreeItems.uploadedPhotoUrl?.item.answer?.contents ?? null,
                                objectData
                            )
                        }
                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                        pagePartsSet={props.pagePartsSet}
                        activePagePart={props.activePagePart}
                        renderingContext={props.renderingContext}
                        questionRenderingData={props.questionRenderingData}
                        referenceSubscriptions={presenter.referenceSubscriptions}
                        forceRequest={(type) => presenter.forceRequest(type)}
                        canAddSales={!presenter.isFrozen && presenter.canAdd}
                        onAddCustomReferenceSaleButtonClick={() =>
                            presenter.canAdd && presenter.onAddCustomReferenceSaleButtonClick()
                        }
                        filters={presenter.filters}
                        setFilters={presenter.setFilters}
                    />
                    <div className="row">
                        <div className="col-12">
                            {presenter.activeSetDefinition.missingPreconditions.length > 0 ? (
                                <div className="alert-inline alert-danger ion-md-close-circle">
                                    <p>
                                        Niet alle benodigde informatie is beschikbaar om referentieobjecten op te halen.
                                        Controleer of de volgende vragen zijn beantwoord:{' '}
                                        {humanizeArray(presenter.activeSetDefinition.missingPreconditions)}.
                                    </p>
                                </div>
                            ) : (
                                <ReferenceObjectGrid
                                    questionSet={props.questionSet}
                                    appraisal={props.appraisal}
                                    isComparing={presenter.isComparing}
                                    isUpdatingReferenceObjects={
                                        presenter.activeSetDefinition.state === ActiveSetDefinitionState.DISABLED
                                    }
                                    sortingMethod={presenter.sortingMethod}
                                    sortingDirection={presenter.sortingDirection}
                                    updateSortingMethod={presenter.updateSortingMethod}
                                    updateIsComparing={presenter.updateIsComparing}
                                    activeSetDefinition={presenter.activeSetDefinition}
                                    visibleReferenceObjects={presenter.visibleReferenceObjects}
                                    canAdd={presenter.canAdd}
                                    canDelete={!presenter.isFrozen}
                                    onAddAndOpenModal={presenter.onAddAndOpenModal}
                                    onRemove={presenter.onRemove}
                                    shouldShowDeletedObjects={presenter.shouldShowDeletedObjects}
                                    onToggleShowDeletedObjects={presenter.toggleShowDeletedObjects}
                                    onRestore={presenter.onRestore}
                                    numVisible={presenter.numVisible}
                                    showMoreReferenceObjects={presenter.showMoreReferenceObjects}
                                    showAnswerModal={presenter.showPreferredModal}
                                    showDetailsModal={presenter.showDetailsModal}
                                    hoveringReferenceObjectId={presenter.hoveringReferenceObjectId ?? null}
                                    clickedReferenceObjectId={presenter.clickedReferenceObject?.id ?? null}
                                    setHoveringReferenceObjectId={presenter.setHoveringReferenceObject}
                                    setClickedReferenceObject={presenter.setClickedReferenceObject}
                                    onImageSliderOpened={presenter.enhanceHighlightedReferenceSale}
                                    subscriptions={presenter.referenceSubscriptions}
                                    openReferencePreferencesModal={presenter.openReferencePreferencesModal}
                                    shouldShowSortingMethodTooltip={presenter.shouldShowSortingMethodTooltip}
                                />
                            )}
                        </div>
                    </div>
                </>
            );
        }

        return (
            <div className="col-12">
                <div className="alert-inline alert-danger ion-md-close-circle">
                    <p>Er zijn nog geen waarderingen ingevoerd om referenties voor toe te voegen.</p>
                </div>
            </div>
        );
    }

    function renderPromotion() {
        const brainbayPromotion = presenter.referenceSubscriptions?.promotions.find(
            (p) => p.promotion === ReferenceSubscriptionPromotionType.BRAINBAY_PROMOTION
        );
        const brainbayTrial = presenter.referenceSubscriptions?.promotions.find(
            (p) => p.promotion === ReferenceSubscriptionPromotionType.BRAINBAY_TRIAL
        );

        if (brainbayPromotion) {
            return (
                <dl className="alert-inline alert-success ion-md-information-circle">
                    <dt>Brainbay referenties</dt>
                    <dd>
                        Op dit moment maak je geen gebruik van de brainbay referentievergelijker.{' '}
                        {!brainbayPromotion.brainbayAuthenticated
                            ? 'De NVM-koppeling is niet actief.'
                            : 'Het Brainbay-abonnement is uitgeschakeld.'}
                    </dd>
                    <a
                        className="mt-2 btn btn-sm float-right"
                        onClick={() => presenter.setPromotionModalType(PromotionModalType.BRAINBAY_PROMOTION)}
                    >
                        Meer info
                    </a>
                </dl>
            );
        } else if (brainbayTrial) {
            return (
                <dl className="alert-inline alert-success ion-md-information-circle">
                    <dt>Brainbay referenties</dt>
                    <dd>
                        Je maakt gebruik van de proefperiode van Brainbay referenties. Je mag deze nog gratis proberen
                        voor {10 - brainbayTrial.brainbayUsageCount}{' '}
                        {10 - brainbayTrial.brainbayUsageCount === 1 ? 'taxatie' : 'taxaties'} tot uiterlijk{' '}
                        {new Date(brainbayTrial.brainbayPromotionEndDate).toLocaleDateString('nl-NL')}.
                    </dd>
                </dl>
            );
        }
    }

    function renderPromotionModal() {
        if (presenter.promotionModalType === PromotionModalType.BRAINBAY_PROMOTION) {
            const promotion = presenter.referenceSubscriptions?.promotions.find(
                (p) =>
                    p.promotion === ReferenceSubscriptionPromotionType.BRAINBAY_PROMOTION ||
                    p.promotion === ReferenceSubscriptionPromotionType.BRAINBAY_TRIAL
            );

            return (
                <Modal visible={true} forced={true}>
                    <div className="modal-header">
                        <h2>taXapi X Brainbay</h2>
                    </div>
                    <div className="modal-body">
                        <p>
                            De afgelopen tijd hebben vele taxateurs kunnen deelnemen aan de brainbay pilot. TaXapi en
                            brainbay hebben een unieke referentievergelijker ontwikkeld en taXapi betaalt voor het
                            algoritme achter deze referentievergelijker. Je krijgt dus niet zomaar referenties uit de
                            brainbay database, maar we zoeken gelijk de 10 meest geschikte voor je uit en we beschikken
                            over vele interieurfoto&apos;s.
                        </p>
                        <p>
                            Inmiddels hebben we deze referentievergelijker dusdanig geoptimaliseerd waardoor de pilot
                            binnenkort ten einde loopt. Daarom ontvang jij als NVM-lid van ons nog voor 10 taxaties
                            gratis de brainbay referentievergelijker (geldig tot 1 juni 2023) en daarna betaal je €4,95
                            extra per taxatie.
                        </p>
                        <p>
                            <strong>Let op</strong>: het is mogelijk om onze Classic referentievergelijker (Ortec) uit
                            te zetten, zodat de prijs per taxatie gelijk blijft. Kijk voor meer informatie in{' '}
                            <a href="/account/me/financial/subscriptions">Mijn Account - Abonnementen</a>.
                        </p>
                        {!promotion?.brainbayAuthenticated && (
                            <div className="detail-block">
                                <p>
                                    De NVM-koppeling is nog niet actief. Activeer de koppeling om gebruik te maken van
                                    Brainbay referenties.
                                </p>
                                <div className="text-right">
                                    <a className="btn btn-sm" href="/account/settings#nvm">
                                        <span className="ion-md-link mr-2"></span>
                                        Activeer koppeling
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary ion-md-close"
                            type="button"
                            onClick={() => presenter.setPromotionModalType(null)}
                        >
                            Nee, dank je
                        </button>
                        <a className="btn btn-primary ion-md-checkmark" href="/account/me/financial/subscriptions">
                            Ja, ga door
                        </a>
                    </div>
                </Modal>
            );
        } else if (presenter.promotionModalType === PromotionModalType.BRAINBAY_TRIAL_ENDED) {
            return (
                <Modal visible={true} forced={true}>
                    <div className="modal-header">
                        <h2>Brainbay Proefperiode afgelopen</h2>
                    </div>
                    <div className="modal-body">
                        <p>Wens je door te gaan met het gebruik van de brainbay referentievergelijker?</p>
                        <p>
                            Indien je gebruik wil blijven maken van brainbay word je doorverwezen naar de pagina
                            Abonnementen, waar je zelf kunt kiezen welke referentievergelijker(s) je wil gebruiken.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="btn btn-secondary ion-md-close"
                            type="button"
                            onClick={() => {
                                presenter.setPromotionModalType(null);
                                presenter.flashMessageBroadcaster.broadcast(
                                    'Vanaf nu maak je geen gebruik meer van de brainbay referentievergelijker. Dit kun je ten alle tijde wijzigen in jouw account.',
                                    Type.Success
                                );
                            }}
                        >
                            Nee, dank je
                        </button>
                        <a className="btn btn-primary ion-md-checkmark" href="/account/me/financial/subscriptions">
                            Ja, graag
                        </a>
                    </div>
                </Modal>
            );
        }
    }

    return (
        <ReferenceObjectsQuestionContext.Provider value={contextValue}>
            {renderPromotion()}
            {renderPromotionModal()}
            <div className="form-group">
                <SetDefinitionTabs
                    appraisal={props.appraisal}
                    isDisabled={
                        presenter.activeSetDefinition === null ||
                        presenter.activeSetDefinition.state === ActiveSetDefinitionState.DISABLED
                    }
                    referenceSets={presenter.referenceSets}
                    activeSetDefinition={presenter.activeSetDefinition}
                    setActiveSetDefinition={presenter.setActiveSetDefinition}
                    minReferenceObjects={ReferenceObjectsQuestionPresenter.minReferenceObjects}
                />

                {renderContent()}
            </div>

            <ReferenceObjectModals
                appraisal={props.appraisal}
                questionSet={props.questionSet}
                modalState={presenter.modalState}
                setDefinitionGebruiksOppervlakteBuitenruimte={
                    presenter.activeSetDefinition?.setDefinition.gebruiksOppervlakteBuitenruimte ?? null
                }
                setDefinitionOverigeInpandigeRuimte={
                    presenter.activeSetDefinition?.setDefinition.overigeInpandigeRuimte ?? null
                }
                setDefinitionGebruiksOppervlakteExterneBergruimte={
                    presenter.activeSetDefinition?.setDefinition.gebruiksOppervlakteExterneBergruimte ?? null
                }
                setDefinitionBuildYear={presenter.activeSetDefinition?.setDefinition.buildYear ?? null}
                setDefinitionPlotArea={presenter.activeSetDefinition?.setDefinition.plotArea ?? null}
                setDefinitionValuation={presenter.activeSetDefinition?.setDefinition.valuation ?? null}
                setDefinitionEnergyLabel={presenter.activeSetDefinition?.setDefinition.energyLabel ?? null}
                setDefinitionSurfaceArea={presenter.activeSetDefinition?.setDefinition.surfaceArea ?? null}
                setDefinitionType={presenter.activeSetDefinition?.setDefinition.type ?? null}
                setDefinitionVolume={presenter.activeSetDefinition?.setDefinition.volume ?? null}
                setDefinitionObjectType={presenter.activeSetDefinition?.setDefinition.objectType ?? null}
                isFrozen={presenter.isFrozen}
                onModalHide={presenter.onModalHide}
                onAddAndOpenModal={presenter.onAddAndOpenModal}
                onRemove={presenter.onRemove}
                showComparisonModal={presenter.showComparisonModal}
                showAnswerModal={presenter.showAnswerModal}
                validationErrors={props.validationErrors}
                hiddenQuestionTypes={props.hiddenQuestionTypes}
                pagePartsSet={props.pagePartsSet}
                activePagePart={props.activePagePart}
                disabled={props.disabled}
                renderingContext={props.renderingContext}
                forceShowValidationMessages={props.forceShowValidationMessages}
                questionRenderingData={props.questionRenderingData}
            />
        </ReferenceObjectsQuestionContext.Provider>
    );
});
