import {ImageUrlPair} from '../../../../../../../models/image_url_pair';
import {ReferenceSaleImageSource} from '../../../../../../../network/models/api_reference_sale_image';
import {ReferenceSaleImage} from '../v1/models/reference_sale_image';
import {Source} from '../v3/models/reference_object';

const emptyImage: ReferenceSaleImage = {
    key: null,
    url: null,
    source: null,
    date: null,
    label: null,
    confidence: null,
};

export function buildImages(
    uploadedPhotoUrl: string | null,
    frontviewUrl: string | null,
    street: string,
    houseNumber: string,
    city: string,
    source: string | null,
    answerImages: ReferenceSaleImage[] | null,
    answerImageUrlPairs: ImageUrlPair[] | null,
    referenceObjectImages: ReferenceSaleImage[] | null,
    saleQuarter: string | null
): ReferenceSaleImage[] {
    const pairs = new Map<string, ReferenceSaleImage>();

    let approxSaleDate: Date | null = null;
    if (saleQuarter !== null) {
        if (saleQuarter.length > 8) {
            approxSaleDate = new Date(saleQuarter);
        } else {
            const quarterRegex = /^(?:Q|)([1-4]{1})-([0-9]{4})$/;
            const match = saleQuarter.match(quarterRegex);
            if (match) {
                const quarter = parseInt(match[1]);
                const year = parseInt(match[2]);
                approxSaleDate = new Date(year, (quarter - 1) * 3);
            }
        }
    }

    if (frontviewUrl) {
        let url = frontviewUrl;
        if (url.startsWith('//')) {
            url = url.replace(/^\/\//, 'https://');
        }
        const frontviewSource =
            source === Source.ARCHIEF_NVM
                ? ReferenceSaleImageSource.BRAINBAY
                : source === 'Altum'
                ? ReferenceSaleImageSource.ALTUM
                : null;
        pairs.set(url, {
            ...emptyImage,
            url: url,
            label: 'vooraanzicht',
            confidence: 1,
            date: approxSaleDate,
            source: frontviewSource,
        });
    }

    // When the original source is Brainbay (NVM) it should not have streetview
    if (source !== Source.ARCHIEF_NVM) {
        const streetViewUrl = '/reference-objects/address/' + street + '/' + houseNumber + '/' + city + '/streetview';
        pairs.set(streetViewUrl, {
            ...emptyImage,
            url: streetViewUrl,
            label: 'streetview_vooraanzicht',
            confidence: 0.8,
            date: approxSaleDate,
        });
    }

    if (answerImages && answerImages.length > 0) {
        for (const image of answerImages) {
            if (image.url !== null) {
                pairs.set(image.url, image);
            }
        }
    }

    if (answerImageUrlPairs && answerImageUrlPairs.length > 0) {
        for (const pair of answerImageUrlPairs) {
            pairs.set(pair.url, {...emptyImage, url: pair.url, key: pair.path});
        }
    }

    if (referenceObjectImages && referenceObjectImages.length > 0) {
        for (const image of referenceObjectImages) {
            if (image.url !== null) {
                pairs.set(image.url, image);
            }
        }
    }

    let images = Array.from(pairs.values());

    // Ensure that the uploaded photo is always the first image
    if (uploadedPhotoUrl) {
        images = [
            {
                ...emptyImage,
                url: uploadedPhotoUrl,
                date: approxSaleDate,
                label: 'uploaded_vooraanzicht',
                confidence: 1,
            },
            ...images.filter((image) => image.url !== uploadedPhotoUrl),
        ];
    }

    return images;
}
