/**
 * Bit 'n pieces to fill hidden elements on TVI Contract sign page
 */
(() => {
    const agreeWithTermsPlaceholder = $('#agree_with_tvi_terms_placeholder');
    if (agreeWithTermsPlaceholder.length > 0) {
        $('[name="agree_with_tvi_terms_placeholder"]').change(function (checkbox) {
            fillHiddenInput($(checkbox.target).is(':checked') ? 1 : 0, $('#agree_with_tvi_terms'));
        });
        $('[name="bank_iban"]').change(function (input) {
            fillHiddenInput($(input.target).val(), $('#agree_bank_iban'));
        });
        $('[name="bank_account"]').change(function (input) {
            fillHiddenInput($(input.target).val(), $('#agree_bank_account'));
        });
    }

    function fillHiddenInput(value, target) {
        $(target).val(value);
    }
})();
