import React, {CSSProperties} from 'react';

interface OwnProps {
    energyLabel: string | null;
    withBackground?: boolean;
}

const ColorMap: {[key: string]: string} = {
    G: '#EB1A36',
    F: '#EC4028',
    E: '#F2911A',
    D: '#F6E014',
    C: '#A4D025',
    B: '#5DA533',
    A: '#387731',
};

export function EnergyLabelIcon({energyLabel, withBackground}: OwnProps) {
    return (
        <span
            className="energy-label-icon"
            style={
                {
                    '--color': withBackground !== false ? ColorMap[energyLabel?.charAt(0) ?? ''] : null,
                } as CSSProperties
            }
        >
            {energyLabel}
        </span>
    );
}
