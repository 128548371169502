import {AdaptedValueConfigProvider} from '../internal/adapted_value_config';
import {Answer} from '../../../models/answer';
import {AnswerContentBuilder} from '../internal/answer_content_builder';
import {AnswerController} from '../../answering/answer_controller';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {SentenceBuilder} from '../internal/sentence_builder';
import {TechnicalReference} from '../../../enum/technical_reference';
import {isEmpty} from '../../../../support/util';
import {sortAnswersByUpdatedAt} from '../../../../support/sort_answer';

export interface AdaptedValueBuilder {
    buildSentence(technicalReference: TechnicalReference): string;
}

export class DefaultAdaptedValueBuilder implements AdaptedValueBuilder {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private adaptedValueConfigProvider: AdaptedValueConfigProvider,
        private valuesBuilder: SentenceBuilder,
        private answerContentBuilder: AnswerContentBuilder
    ) {}

    public buildSentence(technicalReference: TechnicalReference): string {
        const sourceTechnicalReferences = this.adaptedValueConfigProvider.forTechnicalReference(technicalReference);

        // Get all questions from config
        const questions: Question[] = [];
        for (const sourceTechnicalReference of sourceTechnicalReferences) {
            const q = this.questionSet.findQuestionsByTechnicalReference(sourceTechnicalReference);
            for (const question of q) {
                questions.push(question);
            }
        }

        // Get all answers
        const values: string[] = [];
        for (const question of questions) {
            const answers = this.answerController
                .filterDeleted(this.answerController.answersForQuestionUuid(question.uuid))
                .sort(this.sortByUpdatedAt);
            for (const answer of answers) {
                const content = this.answerContentBuilder.build(answer, question);
                if (content !== null && !isEmpty(content)) {
                    values.push(content);
                }
            }
        }

        // Combine as sentence
        return this.valuesBuilder.build(values);
    }

    private sortByUpdatedAt(a: Answer, b: Answer) {
        return sortAnswersByUpdatedAt(a, b);
    }
}
