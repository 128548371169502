import {NormalQuestionType, QuestionType} from '../../../enum/question_type';
import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';

import {Answer} from '../../../models/answer';
import {AnswerController} from '../../answering/answer_controller';
import {AnswerValidator} from '../answer_validator';
import {IsVisitedRequiredCalculator} from '../internal/is_visited_required_calculator';
import {Question} from '../../../models/question';

export class IsVisitedValidator implements AnswerValidator {
    constructor(
        private answerController: AnswerController,
        private isVisitedRequiredCalculator: IsVisitedRequiredCalculator
    ) {}

    public validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (!this.hasApplicableQuestionType(question.type)) {
            return [];
        }

        if (
            !this.isVisitedRequiredCalculator.isVisitedRequired(question, answer) ||
            (answer !== null && answer.isVisited)
        ) {
            return [];
        }

        // When an answer is not iterated, and not the last, we don't validate
        if (answer !== null && answer.iteration === null) {
            const answersForQuestion = this.answerController.answersForQuestionUuid(question.uuid);
            if (answersForQuestion[answersForQuestion.length - 1].uuid !== answer.uuid) {
                return [];
            }
        }

        return [
            {
                type: ValidationMessageType.TAXAPI,
                importance: ValidationMessageImportance.WARNING,
                question: question,
                answer: answer,
                messages: [],
                fallbackMessage: '"' + question.contents + '" dient bekeken te worden.',
                floorInfo: null,
            },
        ];
    }

    private hasApplicableQuestionType(questionType: QuestionType) {
        return ([NormalQuestionType.ATTACHMENT] as QuestionType[]).includes(questionType);
    }
}
