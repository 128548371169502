import * as React from 'react';

import {observer} from 'mobx-react';
import {Appraisal} from '../../../../models/appraisal';
import {
    ActiveSetDefinition,
    SetDefinition,
} from '../../content/questions/advanced/reference_objects_question/v3/reference_objects_question_presenter';
import {classNames} from '../../../../../support/classnames';

interface OwnProps {
    appraisal: Appraisal;
    isDisabled: boolean;
    referenceSets: SetDefinition[];
    hasPreselection: boolean;
    activeSetDefinition: ActiveSetDefinition | 'preselection' | null;
    setActiveSetDefinition: (setDefinition: SetDefinition | 'preselection') => Promise<void>;
    minReferenceObjects: number;
}

@observer
export class ReferenceObjectWidgetTabs extends React.Component<OwnProps> {
    public render() {
        return (
            <>
                <div className="reference-objects-nav-container">
                    <ul className="reference-objects-nav">
                        <li className="reference-objects-nav-title">
                            <h3>
                                <span className="icon-pin" />
                                Referenties
                            </h3>
                        </li>
                        {this.props.hasPreselection && (
                            <li
                                key={'preselection'}
                                className={classNames('reference-objects-nav-item', {
                                    active: this.props.activeSetDefinition === 'preselection',
                                    disabled: this.props.isDisabled,
                                })}
                                onClick={() => {
                                    if (this.props.isDisabled === false) {
                                        this.props.setActiveSetDefinition('preselection');
                                    }
                                }}
                            >
                                <span className="reference-objects-nav-item-title">Voorselectie</span>
                            </li>
                        )}
                        {this.props.referenceSets === null ? (
                            <li className="reference-objects-nav-item active">
                                <span className="reference-objects-nav-item-placeholder-title">&nbsp;</span>
                            </li>
                        ) : (
                            this.props.referenceSets.map((set, index) => {
                                return (
                                    <li
                                        key={set.title ?? String(index)}
                                        className={classNames('reference-objects-nav-item', {
                                            active:
                                                this.props.activeSetDefinition !== 'preselection' &&
                                                this.props.activeSetDefinition?.setDefinition.valuationType ===
                                                    set.valuationType,
                                            disabled: this.props.isDisabled,
                                        })}
                                        onClick={() => {
                                            if (this.props.isDisabled === false) {
                                                this.props.setActiveSetDefinition(set);
                                            }
                                        }}
                                    >
                                        <span className="reference-objects-nav-item-title">{set.description}</span>
                                    </li>
                                );
                            })
                        )}
                    </ul>
                </div>
            </>
        );
    }
}
