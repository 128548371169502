import {ApiFile, apiFileToFile, fileToApiFile} from './api_file';

import {Answer} from '../../models/answer';
import {AnswerFilledByAutomator} from '../../enum/answer_filled_by_automator';
import {UserType} from '../../enum/user_type';
import {AnswerTouchState} from '../../enum/answer_touch_state';
import {parseISO} from 'date-fns';
import {UserRole} from '../../enum/user_role';

export interface ApiAnswer {
    id?: number;
    iteration: string;
    uuid: string;
    parent_uuid: string | null;
    question_uuid: string;
    answer_option_id: number | null;
    contents: string | null;
    is_deleted: boolean;
    is_hard_deleted: boolean;
    is_visited: boolean;
    updated_at: string | null;
    created_at: string | null;
    has_automator: boolean;
    filled_by_automator: AnswerFilledByAutomator | null;
    file: ApiFile | null;
    rank: number | null;
    is_automated?: boolean;
    created_by_user_id?: number | null;
    created_by_user_initials?: string | null;
    created_by_user_name?: string | null;
    created_by_user_type?: UserType | null;
    created_by_user_role?: UserRole | null;
    touch_state: AnswerTouchState;
}

function parseIsoDate(value: string, isoCache?: Record<string, Date>): Date {
    if (isoCache && isoCache[value]) {
        return isoCache[value];
    }

    const result = parseISO(value);
    if (isoCache) {
        isoCache[value] = result;
    }
    return result;
}

export function apiAnswerToAnswer(apiAnswer: ApiAnswer, isoCache?: Record<string, Date>): Answer {
    return {
        changed: false,
        iteration: apiAnswer.iteration === '' ? null : apiAnswer.iteration,
        id: apiAnswer.id,
        uuid: apiAnswer.uuid,
        parentUuid: apiAnswer.parent_uuid,
        questionUuid: apiAnswer.question_uuid,
        answerOptionId: apiAnswer.answer_option_id,
        contents: apiAnswer.contents,
        isDeleted: apiAnswer.is_deleted,
        isHardDeleted: apiAnswer.is_hard_deleted,
        isVisited: apiAnswer.is_visited,
        updatedAt: apiAnswer.updated_at !== null ? parseIsoDate(apiAnswer.updated_at, isoCache) : null,
        createdAt: apiAnswer.created_at !== null ? parseIsoDate(apiAnswer.created_at, isoCache) : null,
        hasAutomator: apiAnswer.has_automator,
        filledByAutomator: apiAnswer.filled_by_automator,
        file: apiAnswer.file !== null && apiAnswer.file !== undefined ? apiFileToFile(apiAnswer.file) : null,
        rank: apiAnswer.rank,
        isAutomated: apiAnswer.is_automated || false,
        createdByUserId: apiAnswer.created_by_user_id,
        createdByUserInitials: apiAnswer.created_by_user_initials,
        createdByUserName: apiAnswer.created_by_user_name,
        createdByUserType: apiAnswer.created_by_user_type,
        createdByUserRole: apiAnswer.created_by_user_role,
        touchState: apiAnswer.touch_state || AnswerTouchState.UNTOUCHED,
    };
}

export function answerToApiAnswer(answer: Answer): ApiAnswer {
    return {
        iteration: answer.iteration === null ? '' : answer.iteration,
        id: answer.id,
        uuid: answer.uuid,
        parent_uuid: answer.parentUuid,
        question_uuid: answer.questionUuid,
        answer_option_id: answer.answerOptionId,
        contents: answer.contents,
        is_deleted: answer.isDeleted,
        is_hard_deleted: answer.isHardDeleted,
        is_visited: answer.isVisited,
        updated_at: answer.updatedAt !== null ? answer.updatedAt.toISOString() : null,
        created_at: answer.createdAt !== null ? answer.createdAt.toISOString() : null,
        has_automator: answer.filledByAutomator !== null,
        filled_by_automator: answer.filledByAutomator,
        file: answer.file !== null ? fileToApiFile(answer.file) : null,
        rank: answer.rank,
        is_automated: answer.isAutomated,
        created_by_user_id: answer.createdByUserId,
        created_by_user_initials: answer.createdByUserInitials,
        created_by_user_name: answer.createdByUserName,
        created_by_user_type: answer.createdByUserType,
        created_by_user_role: answer.createdByUserRole,
        touch_state: answer.touchState,
    };
}
