import * as React from 'react';

import {AddCustomReferenceSaleModalPresenter} from './add_custom_reference_sale_modal_presenter';
import {Appraisal} from '../../../../../../../../models/appraisal';
import {Loader} from '../../../../../../components/loader';
import {Modal} from '../../../../components/modal';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../../../support/presenter/use_presenter';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    referenceObjectsAnswers: ReferenceObjectAnswer[];
    visible: boolean;
    onHide: () => void;
    onAdd: (referenceObjectAnswer: ReferenceObjectAnswer) => void;
    disabled?: boolean;
}

export const AddCustomReferenceSaleModal: React.FC<OwnProps> = observer(function AddCustomReferenceSaleModal(props) {
    const presenter = usePresenter(
        (container) =>
            new AddCustomReferenceSaleModalPresenter(
                props.onAdd,
                props.referenceObjectsAnswers,
                container.business.referenceObjectProvider(props.appraisal, props.questionSet)
            )
    );

    return (
        <Modal onHide={() => props.onHide()} visible={props.visible}>
            <div className="modal-header">
                <h2>Eigen referentieobject toevoegen</h2>
            </div>
            <div className="modal-body">
                {presenter.hasError ? <div className="alert alert-danger">{presenter.errorMessage}</div> : null}
                <div className="row">
                    <div className="col-12 col-sm-4">
                        <div className="form-group">
                            <label htmlFor="input-postal-code">Postcode</label>
                            <input
                                id="input-postal-code"
                                className="form-control"
                                type="text"
                                disabled={props.disabled || presenter.isDisabled}
                                value={presenter.postalCode}
                                onChange={(e) => presenter.onPostalCodeChange(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="form-group">
                            <label htmlFor="input-house-number">Huisnummer</label>
                            <input
                                id="input-house-number"
                                className="form-control"
                                type="text"
                                disabled={props.disabled || presenter.isDisabled}
                                value={presenter.houseNumber}
                                onChange={(e) => presenter.onHouseNumberChange(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="form-group">
                            <label htmlFor="input-letter">Toevoeging</label>
                            <input
                                id="input-letter"
                                className="form-control"
                                type="text"
                                disabled={props.disabled || presenter.isDisabled}
                                value={presenter.letter}
                                onChange={(e) => presenter.onLetterChange(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary ion-md-close" type="button" onClick={() => props.onHide()}>
                    Annuleren
                </button>
                <button
                    className="btn btn-primary ion-md-arrow-forward"
                    type="button"
                    onClick={() => presenter.onSubmitClick()}
                >
                    {presenter.submitting ? <Loader /> : 'Toevoegen'}
                </button>
            </div>
        </Modal>
    );
});
