import {DefaultPluginOptions, UIPlugin, Uppy} from '@uppy/core';

import {ImageCompressor} from './image_compressor';

export class UppyCompressPlugin extends UIPlugin {
    private compressor: ImageCompressor;

    constructor(uppy: Uppy, opts?: DefaultPluginOptions) {
        super(uppy, opts);
        this.id = opts?.id ?? 'UppyCompressPlugin';
        this.type = 'resizer';
        this.compressor = new ImageCompressor();

        (this as unknown as {defaultLocale: {strings: Record<string, string>}}).defaultLocale = {
            strings: {
                compressingImages: "Foto's comprimeren...",
            },
        };

        (this as unknown as {i18nInit: () => void}).i18nInit();
    }

    public prepareUpload = async (fileIDs: string[]) => {
        const promises = fileIDs.map(async (fileID) => {
            const uppyFile = this.uppy.getFile(fileID);
            this.uppy.emit('preprocess-progress', uppyFile, {
                mode: 'indeterminate',
                message: (this as unknown as {i18n: (text: string) => string}).i18n('compressingImages'),
            });

            if (!uppyFile.type?.startsWith('image/')) {
                return;
            }

            if ('name' in uppyFile.data) {
                const compressedFile = await this.compressor.compress(uppyFile.data, {
                    quality: 0.8,
                    maxHeight: 300,
                    maxWidth: 300,
                    contain: true,
                });
                this.uppy.setFileState(fileID, {data: compressedFile});
            }
        });

        const emitPreprocessCompleteForAll = () => {
            fileIDs.forEach((fileID) => {
                const file = this.uppy.getFile(fileID);
                this.uppy.emit('preprocess-complete', file);
            });
        };

        // Why emit `preprocess-complete` for all files at once, instead of
        // above when each is processed?
        // Because it leads to StatusBar showing a weird “upload 6 files” button,
        // while waiting for all the files to complete pre-processing.
        await Promise.all(promises);
        return emitPreprocessCompleteForAll();
    };

    public install() {
        this.uppy.addPreProcessor(this.prepareUpload);
    }

    public uninstall() {
        this.uppy.removePreProcessor(this.prepareUpload);
    }
}
