/**
 * Dismissing of release note
 */
(() => {
    const button = document.getElementById('release-note-dismiss-button');
    if (button) {
        button.onclick = () => {
            return window.fetch('/ajax/release-notes/dismiss', {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
        };
    }
})();
