import {map} from 'rxjs/operators';
import {UserSettingsInteractor} from './user_settings/user_settings_interactor';
import {ActivePagePartConfigurationIdInteractor} from './page_parts/page_parts_set_interactor';

export class UserSettingsActivePagePartConfigurationInteractor implements ActivePagePartConfigurationIdInteractor {
    constructor(private userSettingsInteractor: UserSettingsInteractor) {}

    private observable = this.userSettingsInteractor
        .getSettingsStream()
        .pipe(map((settings) => settings.pagePartConfigurationId ?? null));

    public get activePagePartConfigurationIdStream() {
        return this.observable;
    }

    public setActiveConfigId(id: number) {
        this.userSettingsInteractor.setSettings({pagePartConfigurationId: id});
    }
}
