import {action, makeObservable, observable} from 'mobx';

import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {SimpleQuestionPresenter} from '../simple/simple_question_presenter';

export class GroupCollapsiblePresenter extends SimpleQuestionPresenter {
    @observable public hasSearchedChildren = false;
    @observable public expanded = false;

    private subscriptions = new CompositeSubscription();

    constructor(...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>) {
        super(...simpleQuestionPresenterParameters);

        makeObservable(this);
    }

    public async mount() {
        super.mount();

        this.subscriptions.add(
            this.searchInteractor
                .matchSearch(this.question.uuid, ({text}) => this.matchesSearch(text))
                .subscribe((text) => {
                    this.searchText = text;
                    this.updateAutoSearchExpand();
                })
        );

        this.subscriptions.add(
            this.searchInteractor.getMatchingChildren(this.question.uuid).subscribe((matches) => {
                this.hasSearchedChildren = matches.length > 0;
                this.updateAutoSearchExpand();
            })
        );
    }

    public async unmount() {
        super.unmount();

        this.subscriptions.clear();
    }

    @action
    public expand() {
        this.expanded = true;
    }

    @action
    public collapse() {
        this.expanded = false;
    }

    @action
    private updateAutoSearchExpand() {
        this.expanded = this.searchText !== null || this.hasSearchedChildren;
    }
}
