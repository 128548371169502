import {Answer} from '../../models/answer';
import {AnswerController} from './answer_controller';
import {AnswerFilledByAutomator} from '../../enum/answer_filled_by_automator';
import {AnswerRegistry} from './support/answer_registry';
import {AnswerSelector} from './support/answer_selector';
import {AnswerTouchState} from '../../enum/answer_touch_state';
import {FileReference} from '../../models/file_reference';
import {ImageUploadState} from '../../appraise/ui/content/questions/advanced/attachment_question_presenter';
import {Observable} from 'rxjs';
import {Question} from '../../models/question';
import {QuestionAnswerPair} from '../../../support/question_answer_tree';
import {ServerTimeProvider} from '../../server_time/server_time_provider';
import {TreeItem} from '../../../support/generic_tree';
import {isEmpty} from '../../../support/util';
import {filter} from 'rxjs/operators';
import {GlobalProvider} from '../../../business/global_provider';

export class DefaultAnswerController implements AnswerController {
    constructor(
        private answerRegistry: AnswerRegistry,
        private answerSelector: AnswerSelector,
        private serverTimeProvider: ServerTimeProvider,
        private globalProvider: GlobalProvider
    ) {}

    public pauseStreams() {
        this.answerRegistry.pauseStreams();
    }

    public resumeStreams() {
        this.answerRegistry.resumeStreams();
    }

    public answersStream(): Observable<Answer[]> {
        return this.answerRegistry.stream;
    }

    public answerByIdentifiersStream(
        questionUuid: string,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Observable<Answer> {
        return this.answerSelector.answerByIdentifiersStream(questionUuid, parentAnswerUuid, iteration);
    }

    public answerByIdentifiers(
        questionUuid: string,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Answer | null {
        return this.answerSelector.answerByIdentifiers(questionUuid, parentAnswerUuid, iteration);
    }

    public childrenAnswersForAnswerRecursivelyStream(answer: Answer): Observable<Answer[]> {
        return this.answerSelector.childrenAnswersForAnswerRecursivelyStream(answer);
    }

    public childrenAnswersForAnswerRecursively(answer: Answer): Answer[] {
        return this.answerSelector.childrenAnswersForAnswer(answer);
    }

    public answerByIdentifiersOrStub(
        questionUuid: string,
        parentAnswerUuid: string | null,
        iteration: string | null
    ): Answer {
        return this.answerSelector.answerByIdentifiersOrStub(questionUuid, parentAnswerUuid, iteration);
    }

    public byUuid(uuid: string) {
        return this.answerRegistry.getByUuid(uuid) || null;
    }

    public answers(): Answer[] {
        return this.answerRegistry.list;
    }

    public answersForQuestionUuidAndParentAnswerUuidStream(
        questionUuid: string,
        parentAnswerUuid: string | null
    ): Observable<Answer[]> {
        return this.answerSelector.answersForQuestionUuidAndParentAnswerUuidStream(questionUuid, parentAnswerUuid);
    }

    public answersByParentAnswerUuid(parentAnswerUuid: string | null): Answer[] {
        return this.answerSelector.answersByParentAnswerUuid(parentAnswerUuid);
    }

    public answersByParentAnswerUuidRecusively(parentAnswerUuid: string | null): Answer[] {
        if (parentAnswerUuid === null) {
            return [];
        }
        return this.findChildAnswers(parentAnswerUuid);
    }

    private findChildAnswers(parentAnswerUuid: string): Answer[] {
        const children = this.answersByParentAnswerUuid(parentAnswerUuid);

        let result: Answer[] = [];
        for (const child of children) {
            result = [...result, child, ...this.findChildAnswers(child.uuid)];
        }
        return result;
    }

    public answersForQuestionUuidAndIteration(questionUuid: string, iteration: string | null): Observable<Answer[]> {
        return this.answerSelector.answersForQuestionUuidAndIteration(questionUuid, iteration);
    }

    public answersForQuestionUuidStream(questionUuid: string): Observable<Answer[]> {
        return this.answerSelector.answersForQuestionUuidStream(questionUuid);
    }

    public answersForQuestionUuid(questionUuid: string, parentAnswerUuid?: string): Answer[] {
        return this.answerSelector.answersForQuestionUuid(questionUuid, parentAnswerUuid);
    }

    public answersForQuestionUuids(questionUuids: string[], parentAnswerUuid?: string): Answer[] {
        return this.answerRegistry.getByQuestionUuids(questionUuids, parentAnswerUuid);
    }

    public answersForQuestionUuidsStream(questionUuids: string[], parentAnswerUuid?: string): Observable<Answer[]> {
        return this.answerSelector.answersForQuestionUuidsStream(questionUuids, parentAnswerUuid);
    }

    public answersForQuestionUuidAndParentAnswerUuidInSameIteration(
        questionUuid: string,
        parentAnswerUuid: string | null
    ): Answer[] | null {
        return this.answerSelector.answersForQuestionUuidAndParentAnswerUuidInSameIterationOrNull(
            questionUuid,
            parentAnswerUuid
        );
    }

    public answersForQuestionUuidAndParentAnswerUuidInSameIterationStream(
        questionUuid: string,
        parentAnswerUuid: string | null
    ): Observable<Answer[]> {
        return this.answerSelector
            .answersForQuestionUuidAndParentAnswerUuidInSameIterationOrNullStream(questionUuid, parentAnswerUuid)
            .pipe(filter((answers): answers is Answer[] => answers !== null));
    }

    public answersForQuestionUuidAndParentAnswerUuidInSameIterationOrNullStream(
        questionUuid: string,
        parentAnswerUuid: string | null
    ): Observable<Answer[] | null> {
        return this.answerSelector.answersForQuestionUuidAndParentAnswerUuidInSameIterationOrNullStream(
            questionUuid,
            parentAnswerUuid
        );
    }

    public aggregateUpdates(callback: () => void): void {
        // Already paused by parent aggregator, so ignore
        if (this.answerRegistry.isPaused) {
            callback();
            return;
        }

        try {
            this.answerRegistry.pauseStreams();
            callback();
        } finally {
            this.answerRegistry.resumeStreams();
        }
    }

    public onAnswerOptionChange(
        uuid: string,
        answerOptionId: number | null,
        touchState: AnswerTouchState,
        filledByAutomator?: AnswerFilledByAutomator | null
    ): Answer | null {
        const answer = this.answerRegistry.getByUuid(uuid);
        if (answer === undefined) {
            throw new Error('No answer to change');
        }

        return this.answerRegistry.update({
            ...answer,
            changed: true,
            answerOptionId: answerOptionId,
            updatedAt: this.serverTimeProvider.date,
            filledByAutomator: filledByAutomator !== undefined ? filledByAutomator : null,
            touchState,
        });
    }

    public onRankChange(uuid: string, rank: number | null): void {
        const answer = this.answerRegistry.getByUuid(uuid);
        if (answer === undefined) {
            throw new Error('No answer to change');
        }

        this.answerRegistry.update({
            ...answer,
            changed: true,
            rank: rank,
            updatedAt: this.serverTimeProvider.date,
            touchState: AnswerTouchState.TOUCHED,
        });
    }

    public markVisited(uuid: string): void {
        const answer = this.answerRegistry.getByUuid(uuid);
        if (answer === undefined) {
            throw new Error('No answer to change');
        }

        if (answer.isVisited || this.globalProvider.global.originalUserId !== null) {
            return;
        }

        this.answerRegistry.update({
            ...answer,
            changed: true,
            isVisited: true,
            updatedAt: this.serverTimeProvider.date,
        });
    }

    public onContentsChange(
        uuid: string,
        contents: string,
        touchState: AnswerTouchState,
        filledByAutomator?: AnswerFilledByAutomator | null
    ): Answer | null {
        const answer = this.answerRegistry.getByUuid(uuid);
        if (answer === undefined) {
            throw new Error('No answer to change');
        }
        return this.answerRegistry.update({
            ...answer,
            changed: true,
            contents: contents,
            updatedAt: this.serverTimeProvider.date,
            filledByAutomator: filledByAutomator !== undefined ? filledByAutomator : null,
            touchState,
        });
    }

    public onTouchStateChange(uuid: string, touchState: AnswerTouchState): Answer | null {
        const answer = this.answerRegistry.getByUuid(uuid);
        if (answer === undefined) {
            throw new Error('No answer to change');
        }
        if (answer.touchState === touchState) {
            return answer;
        }

        return this.answerRegistry.update({
            ...answer,
            changed: true,
            updatedAt: this.serverTimeProvider.date,
            touchState: touchState,
        });
    }

    public attachExistingFile(
        uuid: string,
        contents: string | null,
        file: FileReference,
        filledByAutomator?: AnswerFilledByAutomator | null
    ): Answer | null {
        const answer = this.answerRegistry.getByUuid(uuid);
        if (answer === undefined) {
            throw new Error('No answer to change');
        }

        if (isEmpty(contents)) {
            contents = JSON.stringify({
                name: file.originalFilename,
                path: file.path ?? null,
                type: file.contentType,
                state: ImageUploadState.SYNCED,
            });
        }

        return this.answerRegistry.update({
            ...answer,
            changed: true,
            contents: contents,
            file: file,
            updatedAt: this.serverTimeProvider.date,
            filledByAutomator: filledByAutomator !== undefined ? filledByAutomator : null,
        });
    }

    public clearFileAndContents(uuid: string): void {
        const answer = this.answerRegistry.getByUuid(uuid);
        if (answer === undefined) {
            throw new Error('No answer to change');
        }

        this.answerRegistry.update({
            ...answer,
            changed: true,
            contents: null,
            file: null,
            updatedAt: this.serverTimeProvider.date,
            rank: null,
        });
    }

    public delete(uuid: string): void {
        this.answerRegistry.delete(uuid);
    }

    public deleteMultiple(uuids: string[]): void {
        this.answerRegistry.deleteMultiple(uuids);
    }

    public restore(uuid: string): Answer | null {
        return this.answerRegistry.restore(uuid);
    }

    public pushMany(answers: Answer[]): void {
        this.answerRegistry.updateMany(answers);
    }

    public filterDeleted(answers: Answer[]): Answer[] {
        return this.answerRegistry.filterDeleted(answers);
    }

    public duplicateTree(
        tree: TreeItem<QuestionAnswerPair>,
        newParentUuid: string | null,
        newIteration?: string | null
    ): void {
        return this.answerSelector.duplicateTree(tree, newParentUuid, newIteration);
    }

    public copyTree(
        tree: TreeItem<QuestionAnswerPair>,
        newParentUuid: string | null,
        questionMatcher: (question: Question) => Question | null,
        newIteration?: string | null
    ): TreeItem<QuestionAnswerPair> | null {
        return this.answerSelector.copyTree(tree, newParentUuid, questionMatcher, newIteration);
    }

    public resetTree(tree: TreeItem<QuestionAnswerPair>, resetIsDeleted?: boolean): void {
        return this.answerSelector.resetTree(tree, resetIsDeleted);
    }

    public deleteTree(tree: TreeItem<QuestionAnswerPair>): void {
        return this.answerSelector.deleteTree(tree);
    }

    public getNearestIterationForAnswerUuid(answerUuid: string | null): string | null {
        return this.answerSelector.getNearestIterationForAnswerUuid(answerUuid);
    }
}
