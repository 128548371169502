import * as React from 'react';

import {classNames} from '../../../../../../support/classnames';

interface OwnProps {
    min?: number;
    max?: number;
    count: number;
}

export class CharacterCounter extends React.Component<OwnProps> {
    public render() {
        const {min, max, count} = this.props;

        const valid = (!min || count >= min) && (!max || count <= max);

        return (
            <div className={classNames('character-counter', {invalid: !valid})}>
                {count}
                {max ? '/' + max : ''}
            </div>
        );
    }
}
