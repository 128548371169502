import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {ReferenceObjectsWidgetPresenter} from './reference_objects_widget_presenter';
import {QuestionSet} from '../../../../models/question_set';
import {observer} from 'mobx-react';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {ActiveSetDefinitionState} from '../../content/questions/advanced/reference_objects_question/v2/reference_objects_question_presenter';
import {ReferenceObjectsQuestionPresenter} from '../../content/questions/advanced/reference_objects_question/v3/reference_objects_question_presenter';
import {ReferenceObjectWidgetTabs} from './reference_objects_widget_tabs';
import {ReferenceObjectModals} from '../../content/questions/advanced/reference_objects_question/v3/components/modals/reference_object_modals';
import {ReferenceObjectWidgetTile} from './reference_object_widget_tile';
import {ReferenceObjectWidgetTileDetails} from './reference_object_widget_tile_details';
import {formatMoney} from '../../support/format_money';
import {ReferenceSubscriptionType} from '../../../../models/reference_subscriptions';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {QuestionRenderingDataType} from '../../../../enum/question_rendering_data_type';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export const ReferenceObjectsWidget: React.FC<OwnProps> = observer(function ReferenceObjectsWidget(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectsWidgetPresenter(
                props.appraisal,
                container.business.referenceObjectsMetadataProvider(props.appraisal, props.questionSet),
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.support.surfaceAreaProvider(props.questionSet, props.appraisal),
                container.business.plotAreaProvider(props.appraisal, props.questionSet),
                container.business.compareValuesProvider(props.appraisal, props.questionSet),
                container.business.appraisalProvider,
                container.business.v3ReferenceSetsProvider(props.appraisal, props.questionSet),
                container.business.referenceObjectsInteractor(props.appraisal, props.questionSet),
                container.business.appraiseSecondaryConfigStackInteractor,
                container.business.modalConfigStackInteractor,
                container.business.networkStatusProvider,
                container.business.buildYearProvider(props.appraisal, props.questionSet),
                container.business.answerPathStubber(
                    props.appraisal,
                    props.questionSet,
                    RenderingContextType.APPRAISAL
                ),
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.imageUploadInteractor(props.appraisal, props.questionSet),
                container.business.flashMessageBroadcaster,
                container.business.distanceProvider,
                container.globalProvider,
                container.business.referenceObjectsAnswerEnhancementInteractor(props.appraisal, props.questionSet)
            )
    );

    if (!presenter.referenceObjectsV3Presenter) {
        return null;
    }

    const v3Presenter = presenter.referenceObjectsV3Presenter;

    if (!v3Presenter.referenceSets || !v3Presenter.activeSetDefinition) {
        return null;
    }

    const activeSet = v3Presenter.activeSetDefinition;

    const altumSubscription = presenter.referenceObjectsV3Presenter.referenceSubscriptions?.subscriptions.find(
        (sub) => sub.type === ReferenceSubscriptionType.ALTUM
    );
    const showAltumTeasers =
        presenter.showPreselection && altumSubscription?.available && !altumSubscription.usedForAppraisal;

    return (
        <div className="col-12">
            <div className="widget reference-objects">
                <div className="widget-title">
                    <ReferenceObjectWidgetTabs
                        appraisal={props.appraisal}
                        isDisabled={
                            v3Presenter.activeSetDefinition === null ||
                            v3Presenter.activeSetDefinition.state === ActiveSetDefinitionState.DISABLED
                        }
                        referenceSets={v3Presenter.referenceSets}
                        hasPreselection={presenter.preselectedObjects.length > 0}
                        activeSetDefinition={
                            presenter.showPreselection ? 'preselection' : v3Presenter.activeSetDefinition
                        }
                        setActiveSetDefinition={presenter.setActiveSetDefinition}
                        minReferenceObjects={ReferenceObjectsQuestionPresenter.minReferenceObjects}
                    />
                </div>
                <div className="widget-grid">
                    {presenter.showPreselection ? (
                        presenter.preselectedObjects.map((obj) => (
                            <ReferenceObjectWidgetTile
                                key={obj.id}
                                referenceObjectAnswer={null}
                                referenceObject={obj}
                                address={{
                                    straat: obj.street,
                                    huisnummer: obj.houseNumber,
                                    postcode: obj.postalCode,
                                    plaats: obj.city,
                                    huisnummerToevoeging: obj.letter,
                                    land: 'Nederland',
                                }}
                                appraisal={props.appraisal}
                                questionSet={props.questionSet}
                                price={obj.priceRange}
                            >
                                {obj.source === 'Altum' && showAltumTeasers ? (
                                    <span className="form-grid-item-alert mt-2">
                                        <span className="ion-md-checkmark-circle active mr-1">&nbsp;</span>
                                        Activeer Taxinder om deze referentie te gebruiken.
                                    </span>
                                ) : (
                                    <ReferenceObjectWidgetTileDetails
                                        objectType={obj.objectType}
                                        referenceObjectType={
                                            v3Presenter.activeSetDefinition?.setDefinition.objectType ?? null
                                        }
                                        buildYear={obj.buildYear}
                                        referenceBuildYear={presenter.buildYear}
                                        surfaceArea={obj.floorArea}
                                        referenceSurfaceArea={presenter.surfaceArea}
                                        plotArea={obj.plotArea}
                                        referencePlotArea={presenter.plotArea}
                                        energyLabel={obj.energyLabel}
                                        referenceEnergyLabel={
                                            v3Presenter.activeSetDefinition?.setDefinition.energyLabel ?? null
                                        }
                                        differencesComparability={null}
                                        overallComparability={null}
                                    />
                                )}
                            </ReferenceObjectWidgetTile>
                        ))
                    ) : (
                        <>
                            {v3Presenter.visibleReferenceObjects?.selectedPreselectedReferences.map((obj) => {
                                const comparability = presenter.referencesComparability.find(
                                    (c) => c.reference.referenceObjectAnswer.id === obj.referenceObjectAnswer.id
                                );

                                return (
                                    <ReferenceObjectWidgetTile
                                        key={obj.referenceObjectAnswer.id}
                                        address={obj.referenceObjectAnswer.referenceObject.adres}
                                        referenceObjectAnswer={obj.referenceObjectAnswer}
                                        referenceObject={null}
                                        price={
                                            obj.referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs ??
                                            obj.referenceObjectAnswer.referenceObject.verkoopprijs
                                                ? formatMoney(
                                                      obj.referenceObjectAnswer.referenceObject
                                                          .gecorrigeerdeVerkoopprijs ??
                                                          obj.referenceObjectAnswer.referenceObject.verkoopprijs
                                                  )
                                                : null
                                        }
                                        appraisal={props.appraisal}
                                        questionSet={props.questionSet}
                                    >
                                        <ReferenceObjectWidgetTileDetails
                                            objectType={obj.referenceObjectAnswer.referenceObject.woningType}
                                            referenceObjectType={
                                                v3Presenter.activeSetDefinition?.setDefinition.objectType ?? null
                                            }
                                            buildYear={obj.referenceObjectAnswer.referenceObject.bouwjaar}
                                            referenceBuildYear={
                                                v3Presenter.activeSetDefinition?.setDefinition.buildYear ?? null
                                            }
                                            surfaceArea={obj.referenceObjectAnswer.referenceObject.gebruiksOppervlakte}
                                            referenceSurfaceArea={
                                                v3Presenter.activeSetDefinition?.setDefinition.surfaceArea ?? null
                                            }
                                            plotArea={obj.referenceObjectAnswer.referenceObject.perceelOppervlakte}
                                            referencePlotArea={
                                                v3Presenter.activeSetDefinition?.setDefinition.plotArea ?? null
                                            }
                                            energyLabel={obj.referenceObjectAnswer.referenceObject.energielabel}
                                            referenceEnergyLabel={
                                                v3Presenter.activeSetDefinition?.setDefinition.energyLabel ?? null
                                            }
                                            differencesComparability={comparability?.differencesComparability ?? null}
                                            overallComparability={comparability?.overallComparability ?? null}
                                        />
                                    </ReferenceObjectWidgetTile>
                                );
                            })}
                            {v3Presenter.visibleReferenceObjects?.selectedObjects.map((obj) => {
                                const comparability = presenter.referencesComparability.find(
                                    (c) => c.reference.referenceObjectAnswer.id === obj.referenceObjectAnswer.id
                                );

                                return (
                                    <ReferenceObjectWidgetTile
                                        key={obj.referenceObjectAnswer.id}
                                        address={obj.referenceObjectAnswer.referenceObject.adres}
                                        referenceObjectAnswer={obj.referenceObjectAnswer}
                                        referenceObject={null}
                                        price={
                                            obj.referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs ??
                                            obj.referenceObjectAnswer.referenceObject.verkoopprijs
                                                ? formatMoney(
                                                      obj.referenceObjectAnswer.referenceObject
                                                          .gecorrigeerdeVerkoopprijs ??
                                                          obj.referenceObjectAnswer.referenceObject.verkoopprijs
                                                  )
                                                : null
                                        }
                                        appraisal={props.appraisal}
                                        questionSet={props.questionSet}
                                    >
                                        <ReferenceObjectWidgetTileDetails
                                            objectType={obj.referenceObjectAnswer.referenceObject.woningType}
                                            referenceObjectType={
                                                v3Presenter.activeSetDefinition?.setDefinition.objectType ?? null
                                            }
                                            buildYear={obj.referenceObjectAnswer.referenceObject.bouwjaar}
                                            referenceBuildYear={
                                                v3Presenter.activeSetDefinition?.setDefinition.buildYear ?? null
                                            }
                                            surfaceArea={obj.referenceObjectAnswer.referenceObject.gebruiksOppervlakte}
                                            referenceSurfaceArea={
                                                v3Presenter.activeSetDefinition?.setDefinition.surfaceArea ?? null
                                            }
                                            plotArea={obj.referenceObjectAnswer.referenceObject.perceelOppervlakte}
                                            referencePlotArea={
                                                v3Presenter.activeSetDefinition?.setDefinition.plotArea ?? null
                                            }
                                            energyLabel={obj.referenceObjectAnswer.referenceObject.energielabel}
                                            referenceEnergyLabel={
                                                v3Presenter.activeSetDefinition?.setDefinition.energyLabel ?? null
                                            }
                                            differencesComparability={comparability?.differencesComparability ?? null}
                                            overallComparability={comparability?.overallComparability ?? null}
                                        />
                                    </ReferenceObjectWidgetTile>
                                );
                            })}
                        </>
                    )}
                </div>
                {!presenter.showPreselection &&
                    ((v3Presenter.visibleReferenceObjects?.selectedObjects.length ?? 0) > 0 ||
                        (v3Presenter.visibleReferenceObjects?.selectedPreselectedReferences.length ?? 0) > 0) && (
                        <div className="widget-footer d-flex justify-content-end">
                            <button
                                className="ion-md-create btn-text-link"
                                onClick={() => v3Presenter.showComparisonModal(activeSet.setDefinition)}
                            >
                                Bewerken
                            </button>
                        </div>
                    )}
            </div>
            <ReferenceObjectModals
                appraisal={props.appraisal}
                questionSet={props.questionSet}
                modalState={v3Presenter.modalState}
                setDefinitionGebruiksOppervlakteBuitenruimte={activeSet.setDefinition.gebruiksOppervlakteBuitenruimte}
                setDefinitionOverigeInpandigeRuimte={activeSet.setDefinition.overigeInpandigeRuimte}
                setDefinitionGebruiksOppervlakteExterneBergruimte={
                    activeSet.setDefinition.gebruiksOppervlakteExterneBergruimte
                }
                setDefinitionBuildYear={activeSet.setDefinition.buildYear}
                setDefinitionPlotArea={activeSet.setDefinition.plotArea}
                setDefinitionValuation={activeSet.setDefinition.valuation}
                setDefinitionEnergyLabel={activeSet.setDefinition.energyLabel}
                setDefinitionSurfaceArea={activeSet.setDefinition.surfaceArea}
                setDefinitionType={activeSet.setDefinition.type}
                setDefinitionVolume={activeSet.setDefinition.volume}
                setDefinitionObjectType={activeSet.setDefinition.objectType}
                isFrozen={v3Presenter.isFrozen}
                onModalHide={v3Presenter.onModalHide}
                onAddAndOpenModal={v3Presenter.onAddAndOpenModal}
                onRemove={v3Presenter.onRemove}
                showComparisonModal={v3Presenter.showComparisonModal}
                showAnswerModal={v3Presenter.showAnswerModal}
                validationErrors={[]}
                hiddenQuestionTypes={[]}
                pagePartsSet={null}
                activePagePart={null}
                disabled={false}
                renderingContext={RenderingContextType.APPRAISAL}
                forceShowValidationMessages={false}
                questionRenderingData={{
                    type: QuestionRenderingDataType.REFERENCE_OBJECTS,
                    setDefinitionType: activeSet.setDefinition.type,
                }}
            />
        </div>
    );
});
