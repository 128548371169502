import {makeObservable, observable, runInAction} from 'mobx';

import {AnswerController} from '../../../../business/answering/answer_controller';
import {Appraisal} from '../../../../models/appraisal';
import {AppraisalProvider} from '../../../../business/appraisal_provider';
import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {GlobalProvider} from '../../../../../business/global_provider';
import {PlotAreaProvider} from '../../../../business/plot_area_provider';
import {Presenter} from '../../../../../support/presenter/presenter';
import {QuestionSet} from '../../../../models/question_set';
import {SurfaceAreaProvider} from '../../../../business/support/surface_area_provider';
import {TechnicalReference} from '../../../../enum/technical_reference';
import {getNewestAnswer} from '../../../../../support/get_newest_answer';
import {EnergyLabelProvider} from '../../../../business/energy_label_provider';
import {VolumeProvider} from '../../../../business/volume_provider';

export class AppraisalDetailsWidgetPresenter implements Presenter {
    @observable public buildYear: string | null = null;
    @observable public energyLabel: string | null = null;
    @observable public volume: number | null = null;
    @observable public surfaceArea: number | null = null;
    @observable public plotArea: number | null = null;
    @observable public appraisal: Appraisal;
    private subscriptions = new CompositeSubscription();

    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private surfaceAreaProvider: SurfaceAreaProvider,
        private plotAreaProvider: PlotAreaProvider,
        private energyLabelProvider: EnergyLabelProvider,
        private appraisalProvider: AppraisalProvider,
        private volumeProvider: VolumeProvider,
        private globalProvider: GlobalProvider
    ) {
        makeObservable(this);
        this.appraisal = appraisalProvider.appraisal;
    }

    public mount() {
        this.subscriptions.add(
            this.appraisalProvider.stream.subscribe((appraisal) => {
                runInAction(() => {
                    this.appraisal = appraisal;
                });
            })
        );

        const buildYearQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.OBJECT_BUILD_YEAR
        );
        if (buildYearQuestion) {
            this.subscriptions.add(
                this.answerController.answersForQuestionUuidStream(buildYearQuestion.uuid).subscribe((answers) => {
                    runInAction(() => {
                        this.buildYear = getNewestAnswer(answers)?.contents ?? null;
                    });
                })
            );
        }

        this.subscriptions.add(
            this.energyLabelProvider.stream().subscribe((energyLabel) => {
                runInAction(() => {
                    this.energyLabel = energyLabel;
                });
            })
        );

        this.subscriptions.add(
            this.volumeProvider.stream().subscribe((volume) => {
                runInAction(() => {
                    this.volume = volume;
                });
            })
        );

        this.subscriptions.add(
            this.surfaceAreaProvider.surfaceArea().subscribe((sum) => {
                runInAction(() => {
                    this.surfaceArea = sum;
                });
            })
        );

        this.subscriptions.add(
            this.plotAreaProvider.plotArea().subscribe((plotArea) => {
                runInAction(() => {
                    this.plotArea = plotArea;
                });
            })
        );
    }

    public unmount() {
        this.subscriptions.clear();
    }
}
