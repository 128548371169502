import * as React from 'react';

import {BuildingCostsSheetPresenter, Section} from './building_costs_sheet_presenter';
import {TreeItem, findChildRecursiveByPredicate} from '../../../../../../support/generic_tree';

import {BuildingCostsCondition} from './building_costs_condition/building_costs_condition';
import {BuildingCostsConditionSlider} from './building_costs_condition/building_costs_condition_slider';
import {DummyBuildingCostsSheet} from './dummy_building_costs_sheet';
import {Loader} from '../../../components/loader';
import {NormalQuestionType} from '../../../../../enum/question_type';
import {QuestionAnswerPair} from '../../../../../../support/question_answer_tree';
import {QuestionContainer} from '../question_container';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';
import {SimpleQuestionPresenterProps} from '../simple/simple_question_presenter';
import {buildingCostsLabels} from './building_costs_condition/building_costs_labels';
import {classNames} from '../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {SimpleTooltip} from '../../../../../components/tooltip';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const BuildingCostsSheet: React.FC<OwnProps> = observer(function BuildingCostsSheet(props) {
    const presenter = usePresenter(
        (container) =>
            new BuildingCostsSheetPresenter(
                props.appraisal,
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.questionEffectsInteractor(props.appraisal, props.questionSet),
                container.business.floorIterationProvider(props.appraisal, props.questionSet)
            )
    );

    if (props.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
        return <DummyBuildingCostsSheet />;
    }

    const sheetSections = Array.from(presenter.sections);
    if (presenter.isLoading) {
        return (
            <div className="constructional-defects-sheet">
                <div className="row">
                    <div className="col-12 text-center">
                        <Loader icon="c-icon c-icon-building-costs-sheet" />
                        <h3>{props.question.contents} aan het laden...</h3>
                    </div>
                </div>
            </div>
        );
    } else if (sheetSections.length === 0) {
        return (
            <div className="constructional-defects-sheet">
                <p>Er zijn (nog) geen bouwkundige aandachtspunten om te tonen.</p>
            </div>
        );
    }

    function renderThumb(tree: TreeItem<QuestionAnswerPair>) {
        const photoQuestion = findChildRecursiveByPredicate(
            tree,
            (i) => i.question.type === NormalQuestionType.BUILDING_COSTS_PHOTO
        );
        if (photoQuestion && photoQuestion.item.answer?.file) {
            return (
                <div
                    className="defect-line__avatar"
                    style={{backgroundImage: 'url(' + photoQuestion.item.answer.file.url + ')'}}
                >
                    &nbsp;
                </div>
            );
        }
        return <div className="defect-line__avatar">&nbsp;</div>;
    }

    function renderConditionWithCosts(tree: TreeItem<QuestionAnswerPair>) {
        const buildingCostsChildPair = findChildRecursiveByPredicate(tree, (i) =>
            [NormalQuestionType.BUILDING_DEFECTS_COSTS, NormalQuestionType.BUILDING_DEFECTS].includes(
                i.question.type as NormalQuestionType
            )
        );
        const conditionChildPair = findChildRecursiveByPredicate(
            tree,
            (i) => i.question.type === NormalQuestionType.BUILDING_COSTS_CONDITION
        );

        return (
            <div className="form-building-costs-pair">
                {conditionChildPair !== null && conditionChildPair.item.answer?.parentUuid ? (
                    <BuildingCostsCondition
                        appraisal={props.appraisal}
                        question={conditionChildPair.item.question}
                        questionSet={props.questionSet}
                        validationErrors={props.validationErrors}
                        validationMessages={props.validationMessages}
                        forceShowValidationMessages={props.forceShowValidationMessages}
                        disabled={false}
                        iteration={props.iteration}
                        parentAnswerUuid={conditionChildPair.item.answer.parentUuid}
                        adaptedDefaultValues={undefined}
                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                        shouldShowCondition={true}
                        pagePartsSet={props.pagePartsSet}
                        activePagePart={props.activePagePart}
                        renderingContext={props.renderingContext}
                        questionRenderingData={props.questionRenderingData}
                    />
                ) : null}
                {buildingCostsChildPair !== null && buildingCostsChildPair.item.answer?.parentUuid ? (
                    <span
                        className={classNames({
                            'pulse-input-building-cost':
                                conditionChildPair && presenter.shouldShowPulse(conditionChildPair.item),
                        })}
                    >
                        <span>
                            <QuestionContainer
                                appraisal={props.appraisal}
                                question={buildingCostsChildPair.item.question}
                                questionSet={props.questionSet}
                                validationErrors={props.validationErrors}
                                validationMessages={props.validationMessages}
                                forceShowValidationMessages={props.forceShowValidationMessages}
                                disabled={false}
                                iteration={props.iteration}
                                parentAnswerUuid={buildingCostsChildPair.item.answer.parentUuid}
                                adaptedDefaultValues={undefined}
                                hiddenQuestionTypes={props.hiddenQuestionTypes}
                                pagePartsSet={props.pagePartsSet}
                                activePagePart={props.activePagePart}
                                renderingContext={props.renderingContext}
                                questionRenderingData={props.questionRenderingData}
                            />
                        </span>
                    </span>
                ) : null}
            </div>
        );
    }

    function renderChild(section: Section) {
        return (
            <li
                key={section.treeItem.item.question.uuid + '_' + section.treeItem.item.answer?.uuid}
                className="defect-line"
            >
                {renderThumb(section.treeItem)}
                <div className="defect-line__description">
                    {section.differentLabel ? (
                        <strong>{section.differentLabel}</strong>
                    ) : (
                        <strong>{section.treeItem.item.question.contents}</strong>
                    )}
                    <small>{section.iteration}</small>
                </div>
                <div className="defect-line__spacer" />
                <div className="defect-line__components">
                    <div className="form-building-costs-group form-building-costs-group-sm">
                        {renderConditionWithCosts(section.treeItem)}
                    </div>
                </div>
            </li>
        );
    }

    function sortSections(sections: Section[]) {
        return [...sections].sort(function (a, b) {
            if (a.subtitle < b.subtitle) {
                return -1;
            }
            if (a.subtitle > b.subtitle) {
                return 1;
            }
            return 0;
        });
    }

    return (
        <div className="constructional-defects-sheet">
            <div className="constructional-defects-sheet-header form-building-costs-group form-building-costs-group-sm">
                <div className="form-building-costs-pair">
                    <div
                        className={classNames('form-group form-group-building-costs-range', {
                            active: presenter.setAllActive,
                        })}
                    >
                        <div className="form-range-container form-range-sm">
                            <BuildingCostsConditionSlider
                                disabled={presenter.isDisabled}
                                onChange={(value) => presenter.onChangeSetAll(value)}
                                options={buildingCostsLabels}
                                value={presenter.setAllValue}
                            />
                            <SimpleTooltip content="Stel alle condities in een keer in">
                                <button
                                    className={classNames(
                                        'btn btn-secondary btn-sm form-range-set-all ion-md-done-all'
                                    )}
                                    style={{paddingLeft: presenter.setAllActive ? 25 : 30, marginLeft: 15}}
                                    disabled={presenter.isDisabled}
                                    onClick={() => presenter.setConditionAll(presenter.setAllValue)}
                                />
                            </SimpleTooltip>
                            <SimpleTooltip
                                content="Stel alle condities in een keer in"
                                disabled={presenter.setAllActive}
                            >
                                <button
                                    className={classNames('btn btn-secondary btn-sm form-range-toggle-button', {
                                        'ion-md-done-all': !presenter.setAllActive,
                                        'ion-md-close': presenter.setAllActive,
                                    })}
                                    style={{paddingLeft: presenter.setAllActive ? 20 : 25}}
                                    disabled={presenter.isDisabled}
                                    onClick={() => presenter.onToggleSelectAllActive()}
                                />
                            </SimpleTooltip>
                        </div>
                    </div>
                </div>
            </div>
            {sheetSections.map((group: [title: string, sections: Section[]]) => {
                return (
                    <ul key={group[0]} className="section">
                        <li>
                            <h2>{group[0]}</h2>
                        </li>
                        {sortSections(group[1]).map((s) => renderChild(s))}
                    </ul>
                );
            })}
        </div>
    );
});
