import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {FloorSelectorPresenter, PopoverType} from './floor_selector_presenter';
import {FloorTypeAnswerOptionContents} from '../../../../business/floor_interactor';
import {Modal} from '../../content/questions/components/modal';
import {Popper} from '../../../../components/popper';
import {QuestionSet} from '../../../../models/question_set';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {Select} from '../select';
import {classNames} from '../../../../../support/classnames';
import {observer} from 'mobx-react-lite';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {TechnicalReference} from '../../../../enum/technical_reference';

export interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    collapsed: boolean;
    renderingContext: RenderingContextType;
}

export const FloorSelector: React.FC<OwnProps> = observer(({appraisal, questionSet, collapsed, renderingContext}) => {
    const presenter = usePresenter(
        (component) =>
            new FloorSelectorPresenter(
                component.business.floorInteractor(appraisal, questionSet, renderingContext),
                component.business.flashMessageBroadcaster
            )
    );

    const questionFloorNumbers = questionSet
        .findQuestionByTechnicalReference(TechnicalReference.FLOOR_NUMBER)
        ?.answerOptions.map((ao) => ({
            label: ao.contents,
            value: ao.contents,
            isDisabled: presenter.floorInfos.some((fi) => {
                return fi.floorType === presenter.ownSelectionFloorType && fi.floorNumber === parseInt(ao.contents);
            }),
        }));
    const fallbackFloorNumbers = Array.from({length: 55}).map((_, index) => {
        let value = index - 5;
        if (value >= 0) {
            value += 1;
        }
        return {
            label: String(value),
            value: String(value),
            isDisabled: presenter.floorInfos.some((fi) => {
                return fi.floorType === presenter.ownSelectionFloorType && fi.floorNumber === value;
            }),
        };
    });

    const floorNumbers = questionFloorNumbers ?? fallbackFloorNumbers;
    const popoverState = presenter.popoverState;

    return (
        <>
            <div className={classNames('floor-selector', {collapsed})}>
                {presenter.visibleFloorInfos(collapsed).map((floorInfo, index) => {
                    if (index === 0 && floorInfo === null) {
                        return <div key="floor-placeholder" className="floor-placeholder"></div>;
                    }
                    if (floorInfo) {
                        return (
                            <button
                                onClick={() => presenter.onFloorChange(floorInfo)}
                                className={classNames('btn-circle', 'floor-selector-button', {
                                    active: presenter.selectedFloorInfo?.iteration === floorInfo.iteration,
                                })}
                                key={floorInfo.iteration}
                                title={floorInfo.name ?? undefined}
                            >
                                {floorInfo.shortName}
                            </button>
                        );
                    }

                    return null;
                })}

                <Popper
                    toggle={
                        <button
                            onClick={() => presenter.setIsMoreMenuOpen(!(popoverState.type === PopoverType.moreMenu))}
                            className="btn-circle floor-selector-button"
                        >
                            <span className="ion-ios-more"></span>
                        </button>
                    }
                    placement="bottom"
                    popperOffset={[0, 5]}
                    isPopperOpen={popoverState.type === PopoverType.moreMenu}
                    setIsPopperOpen={(isOpen) => presenter.setIsMoreMenuOpen(isOpen)}
                    popper={
                        <div className="floor-selector-menu">
                            {presenter.hiddenFloorInfos(collapsed).map((hiddenFloorInfo) => {
                                return (
                                    <button
                                        key={hiddenFloorInfo.iteration}
                                        className="floor-selector-menu-item"
                                        onClick={() => presenter.onFloorChange(hiddenFloorInfo)}
                                    >
                                        <div className="btn-circle floor-selector-button">
                                            {hiddenFloorInfo.shortName}
                                        </div>
                                    </button>
                                );
                            })}
                            <button
                                className="floor-selector-menu-item"
                                onClick={() => presenter.setIsAddFloorModalOpen(true)}
                                title="Open venster om een woonlaag toe te voegen."
                            >
                                <div className="btn-circle floor-selector-button">
                                    <span className="ion-md-add"></span>
                                </div>
                            </button>
                            {presenter.floorInfos.length > 0 && (
                                <button
                                    className="floor-selector-menu-item"
                                    onClick={() => presenter.setIsOverviewFloorModalOpen(true)}
                                    title="Open venster om een woonlagen te bewerken."
                                >
                                    <div className="btn-circle floor-selector-button">
                                        <span className="ion-md-create"></span>
                                    </div>
                                </button>
                            )}
                        </div>
                    }
                />

                {popoverState.type === PopoverType.floorAdd && (
                    <Modal visible={true} onHide={() => presenter.setIsAddFloorModalOpen(false)}>
                        <div className="modal-header">
                            <h2>Woonlaag toevoegen</h2>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-6 d-flex flex-column align-items-center">
                                    <h2 className="text-center">Snelle selectie</h2>
                                    <div className="elevator-buttons">
                                        <button
                                            className="btn-circle elevator-button"
                                            onClick={() =>
                                                presenter.addFloor(FloorTypeAnswerOptionContents.VERDIEPING, '3')
                                            }
                                            disabled={presenter.floorInfos.some((fi) => fi.shortName === '3')}
                                        >
                                            3
                                        </button>
                                        <button
                                            className="btn-circle elevator-button"
                                            onClick={() =>
                                                presenter.addFloor(FloorTypeAnswerOptionContents.VERDIEPING, '4')
                                            }
                                            disabled={presenter.floorInfos.some((fi) => fi.shortName === '4')}
                                        >
                                            4
                                        </button>
                                        <button
                                            className="btn-circle elevator-button"
                                            onClick={() =>
                                                presenter.addFloor(FloorTypeAnswerOptionContents.VERDIEPING, '1')
                                            }
                                            disabled={presenter.floorInfos.some((fi) => fi.shortName === '1')}
                                        >
                                            1
                                        </button>
                                        <button
                                            className="btn-circle elevator-button"
                                            onClick={() =>
                                                presenter.addFloor(FloorTypeAnswerOptionContents.VERDIEPING, '2')
                                            }
                                            disabled={presenter.floorInfos.some((fi) => fi.shortName === '2')}
                                        >
                                            2
                                        </button>
                                        <button
                                            className="btn-circle elevator-button"
                                            onClick={() =>
                                                presenter.addFloor(FloorTypeAnswerOptionContents.KELDER, '-1')
                                            }
                                            disabled={presenter.floorInfos.some((fi) => fi.shortName === '-1')}
                                        >
                                            -1
                                        </button>
                                        <button
                                            className="btn-circle elevator-button"
                                            onClick={() =>
                                                presenter.addFloor(FloorTypeAnswerOptionContents.BEGANE_GROND, '0')
                                            }
                                            disabled={presenter.floorInfos.some((fi) => fi.shortName === 'BG')}
                                        >
                                            BG
                                        </button>
                                    </div>
                                </div>
                                <div className="col-6 d-flex flex-column align-items-center">
                                    <h2 className="text-center">Eigen selectie</h2>

                                    <div className="form-group">
                                        <label htmlFor="floor-type">Type woonlaag</label>
                                        <Select
                                            id="floor-type"
                                            onChange={(floorType) => {
                                                if (floorType) {
                                                    presenter.setOwnSelectionFloorType(
                                                        floorType as FloorTypeAnswerOptionContents
                                                    );
                                                }
                                            }}
                                            options={Object.values(FloorTypeAnswerOptionContents).map((value) => ({
                                                label: value,
                                                value: value,
                                            }))}
                                            value={presenter.ownSelectionFloorType}
                                            isDisabled={false}
                                            isClearable={false}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="floor-number">Woonlaag nummer</label>
                                        <Select
                                            id="floor-number"
                                            placeHolder="Selecteer een nummer"
                                            onChange={(floorNumber) => {
                                                if (floorNumber) {
                                                    presenter.setOwnSelectionFloorNumber(floorNumber);
                                                }
                                            }}
                                            options={floorNumbers}
                                            value={presenter.ownSelectionFloorNumber ?? ''}
                                            isDisabled={false}
                                            isClearable={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-secondary ion-md-close"
                                type="button"
                                onClick={() => presenter.setIsAddFloorModalOpen(false)}
                            >
                                Annuleren
                            </button>
                            <button
                                disabled={presenter.ownSelectionFloorNumber === null}
                                className="btn btn-primary btn-full ion-md-arrow-forward"
                                type="button"
                                onClick={() => {
                                    const floorNumber = presenter.ownSelectionFloorNumber;
                                    if (floorNumber) {
                                        presenter.addFloor(presenter.ownSelectionFloorType, floorNumber);
                                    }
                                }}
                            >
                                Toevoegen
                            </button>
                        </div>
                    </Modal>
                )}

                {popoverState.type === PopoverType.floorOverview && (
                    <Modal visible={true} onHide={() => presenter.setIsOverviewFloorModalOpen(false)}>
                        <div className="modal-header">
                            <h2>Woonlagen</h2>
                        </div>
                        <div className="modal-body">
                            <div className="floor-edit-list">
                                {presenter.floorInfos.map((fi) => {
                                    return (
                                        <div className="floor-edit-row" key={fi.iteration}>
                                            <strong className="btn-circle floor-selector-button">{fi.shortName}</strong>
                                            <div className="floor-name">{fi.name}</div>

                                            <button
                                                className="btn btn-delete btn-circle"
                                                onClick={() => presenter.removeFloor(fi)}
                                            >
                                                <span className="ion-md-trash"></span>
                                            </button>

                                            <button
                                                className="btn btn-secondary btn-circle"
                                                onClick={() => presenter.setIsEditFloorModalOpen(true, fi)}
                                            >
                                                <span className="ion-md-create"></span>
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-secondary ion-md-close"
                                type="button"
                                onClick={() => presenter.setIsOverviewFloorModalOpen(false)}
                            >
                                Sluiten
                            </button>
                        </div>
                    </Modal>
                )}

                {popoverState.type === PopoverType.floorEdit && (
                    <Modal visible={true} onHide={() => presenter.setIsOverviewFloorModalOpen(false)}>
                        <div className="modal-header">
                            <h2>Woonlaag bewerken</h2>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="floor-type">Type woonlaag</label>
                                <Select
                                    id="floor-type"
                                    onChange={(floorType) => {
                                        if (floorType) {
                                            presenter.setFloorEditFloorType(floorType as FloorTypeAnswerOptionContents);
                                        }
                                    }}
                                    options={Object.values(FloorTypeAnswerOptionContents).map((value) => {
                                        return {
                                            label: value,
                                            value: value,
                                            isDisabled: presenter.floorInfos.some((floorInfo) => {
                                                return (
                                                    floorInfo !== popoverState.floorInfo &&
                                                    floorInfo.floorNumber === popoverState.floorInfo.floorNumber &&
                                                    floorInfo.floorType === value
                                                );
                                            }),
                                        };
                                    })}
                                    value={popoverState.newFloorType}
                                    isDisabled={false}
                                    isClearable={false}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-secondary ion-md-close"
                                type="button"
                                onClick={() => presenter.setIsOverviewFloorModalOpen(false)}
                            >
                                Sluiten
                            </button>
                            <button
                                className="btn btn-primary ion-md-checkmark"
                                type="button"
                                onClick={() =>
                                    presenter.editFloorType(popoverState.floorInfo, popoverState.newFloorType)
                                }
                            >
                                Opslaan
                            </button>
                        </div>
                    </Modal>
                )}
            </div>
        </>
    );
});
