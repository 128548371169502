import {Answer} from '../../../models/answer';
import {QuestionSet} from '../../../models/question_set';
import {isSet} from '../../../../support/is_set';

export class HasQuestionAnswerFilter {
    constructor(private questionSet: QuestionSet) {}

    public filter(toBeFilteredAnswers: Answer[]): Answer[] {
        return toBeFilteredAnswers.filter((answer) => this.hasQuestion(answer));
    }

    public hasQuestion(answer: Answer): boolean {
        return isSet(this.questionSet.findQuestionByUuid(answer.questionUuid));
    }
}
