import {Observable, of} from 'rxjs';

import {AnswerController} from '../answering/answer_controller';
import {AnswerTouchState} from '../../enum/answer_touch_state';
import {GlobalProvider} from '../../../business/global_provider';
import {PhotoSorting} from '../../../enums/photo_sorting';
import {QuestionSet} from '../../models/question_set';
import {TechnicalReference} from '../../enum/technical_reference';
import {getNewestAnswer} from '../../../support/get_newest_answer';
import {map, shareReplay} from 'rxjs/operators';

export interface UserSettings {
    photoSorting: PhotoSorting;
    floorIteration: string | null;
    pagePartConfigurationId: number | null;
    disabledPhotoRequirements: TechnicalReference[];
}

export interface UserSettingsInteractor {
    getSettings(): UserSettings;
    getSettingsStream(): Observable<UserSettings>;
    setSettings(settings: Partial<UserSettings>): void;
}

export class DefaultUserSettingsInteractor implements UserSettingsInteractor {
    private defaultUserSettings: Readonly<UserSettings> = {
        photoSorting: this.globalProvider.global.photoSorting ?? PhotoSorting.OWN_SORTING,
        floorIteration: null,
        pagePartConfigurationId: null,
        disabledPhotoRequirements: [],
    };

    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private globalProvider: GlobalProvider
    ) {}

    public getSettings(): UserSettings {
        const question = this.questionSet.findQuestionByTechnicalReference(TechnicalReference.USER_SETTINGS);
        if (question) {
            const settingsAnswers = this.answerController.answersForQuestionUuid(question.uuid);
            if (settingsAnswers) {
                const latestAnswer = getNewestAnswer(settingsAnswers);
                return {
                    ...this.defaultUserSettings,
                    ...this.parseSettings(latestAnswer?.contents),
                };
            }
        }

        return this.defaultUserSettings;
    }

    private _settingsObservable: Observable<UserSettings> | null = null;

    public getSettingsStream(): Observable<UserSettings> {
        if (this._settingsObservable === null) {
            const question = this.questionSet.findQuestionByTechnicalReference(TechnicalReference.USER_SETTINGS);
            if (question) {
                this._settingsObservable = this.answerController.answersForQuestionUuidStream(question.uuid).pipe(
                    map((answers) => {
                        const latestAnswer = getNewestAnswer(answers);
                        return {
                            ...this.defaultUserSettings,
                            ...this.parseSettings(latestAnswer?.contents),
                        };
                    }),
                    shareReplay(1)
                );
            } else {
                this._settingsObservable = of(this.defaultUserSettings);
            }
        }

        return this._settingsObservable;
    }

    public setSettings(settings: Partial<UserSettings>) {
        const question = this.questionSet.findQuestionByTechnicalReference(TechnicalReference.USER_SETTINGS);
        if (question) {
            const answer = this.answerController.answerByIdentifiersOrStub(question.uuid, null, null);
            const newContents = JSON.stringify({
                ...this.defaultUserSettings,
                ...this.parseSettings(answer.contents),
                ...settings,
            });
            if (answer.contents !== newContents) {
                this.answerController.onContentsChange(answer.uuid, newContents, AnswerTouchState.TOUCHED);
            }
        }
    }

    private parseSettings(settings: string | null | undefined): UserSettings {
        if (!settings || settings[0] !== '{') {
            return this.defaultUserSettings;
        }
        return JSON.parse(settings);
    }
}
