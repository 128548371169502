/**
 * https://github.com/juliangruber/is-mobile
 */
const mobileRE =
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;
const tabletRE = /android|ipad|playbook|silk/i;

/**
 * Check if screen is in compact mode
 */
export function isCompact(): boolean {
    return isMobile() || window.innerWidth <= 1384;
}

export function isMobile(): boolean {
    let ua = navigator.userAgent as string | {headers: Record<string, string>};
    if (typeof ua === 'object' && ua.headers && typeof ua.headers['user-agent'] === 'string') {
        ua = ua.headers['user-agent'];
    }
    if (typeof ua !== 'string') {
        return false;
    }

    return mobileRE.test(ua);
}

export function isTablet() {
    return isIpad() || tabletRE.test(window.navigator.userAgent.toLowerCase());
}

export function isIos(): boolean {
    return isIpad() || /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
}

export function isIpad(): boolean {
    return (
        /ipad/.test(window.navigator.userAgent.toLowerCase()) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
}

export function isAndroid(): boolean {
    return /android/.test(window.navigator.userAgent.toLowerCase());
}
