import {ObjectType} from '../../enum/object_type';
import {AppraisalMatchStatistic, AppraiserStatistic} from '../../models/appraiser_statistic';

export interface ApiAppraiserStatistic {
    key: string;
    total: number;
    filters: {
        object_types: ObjectType[] | null;
        distance: number | null;
        past_years: number | null;
    };
}

export function apiAppraiserStatisticToAppraiserStatistic(
    apiAppraiserStatistic: ApiAppraiserStatistic
): AppraiserStatistic {
    return {
        key: apiAppraiserStatistic.key,
        total: apiAppraiserStatistic.total,
        filters: {
            objectTypes: apiAppraiserStatistic.filters.object_types,
            distance: apiAppraiserStatistic.filters.distance,
            pastYears: apiAppraiserStatistic.filters.past_years,
        },
    };
}

export interface ApiAppraisalMatchStatistic {
    same_client_count_last_calendar_year: number;
    same_address_last_2_years: number;
    same_lender_count_last_calendar_year: number | null;
    total_count_last_calendar_year: number;
}

export function apiAppraisalMatchStatisticToAppraisalMatchStatistic(
    apiStatistic: ApiAppraisalMatchStatistic
): AppraisalMatchStatistic {
    return {
        sameAddressLast2Years: apiStatistic.same_address_last_2_years,
        sameClientCountLastCalendarYear: apiStatistic.same_client_count_last_calendar_year,
        sameLenderCountLastCalendarYear: apiStatistic.same_lender_count_last_calendar_year,
        totalCountLastCalendarYear: apiStatistic.total_count_last_calendar_year,
    };
}
