export enum SystemModalType {
    NWWI_MAINTENANCE = 'nwwi_maintenance',
    VALIDATION = 'validation',
    RATE_DETAILS = 'rate_details',
    PAYMENT_DETAILS = 'payment_details',
    NWWI_CREDENTIALS = 'nwwi_credentials',
    RELEASE_NOTES = 'release_notes',
    ALTUM_REFERENCES = 'altum_references',
    BUILDING_INSPECTORS = 'building_inspectors',
    BUILDING_INSPECTORS_TRIAL_ENDED = 'building_inspectors_trial_ended',
    USER_INACTIVE = 'user_inactive',
    SUBSCRIPTION_NOTICE = 'subscription_notice',
    BUILDING_INSPECTOR_VIDEO = 'building_inspector_video',
}
