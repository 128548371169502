import {action, autorun, makeObservable, observable, runInAction} from 'mobx';

import {AnswerController} from '../../../../business/answering/answer_controller';
import {Appraisal} from '../../../../models/appraisal';
import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {PlotAreaProvider} from '../../../../business/plot_area_provider';
import {Presenter} from '../../../../../support/presenter/presenter';
import {QuestionSet} from '../../../../models/question_set';
import {SurfaceAreaProvider} from '../../../../business/support/surface_area_provider';
import {TechnicalReference} from '../../../../enum/technical_reference';
import {getNewestAnswer} from '../../../../../support/get_newest_answer';
import {NormalQuestionType} from '../../../../enum/question_type';
import {
    ReferenceObjectsQuestionPresenter,
    SetDefinition,
} from '../../content/questions/advanced/reference_objects_question/v3/reference_objects_question_presenter';
import {V3ReferenceSetsProvider} from '../../content/questions/advanced/reference_objects_question/v3/internal/reference_sets/reference_sets_provider';
import {ReferenceObjectsInteractor} from '../../content/questions/advanced/reference_objects_question/interactors/reference_objects_interactor';
import {AppraiseSecondaryConfigStackInteractor} from '../../../../business/appraise_secondary_config_stack_interactor';
import {NetworkStatusProvider} from '../../../../business/network_status_provider';
import {BuildYearProvider} from '../../../../business/build_year_provider';
import {AnswerPathStubber} from '../../../../business/answering/answer_path_stubber';
import {AnswerInteractor} from '../../../../business/answering/answer_interactor';
import {ImageUploadInteractor} from '../../../../business/attachments/image_upload_interactor';
import {AppraisalProvider} from '../../../../business/appraisal_provider';
import {GlobalProvider} from '../../../../../business/global_provider';
import {FlashMessageBroadcaster} from '../../../../business/flash_message_broadcaster';
import {ReferenceObjectData} from '../../content/questions/advanced/reference_objects_question/v3/internal/create_reference_object_data';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {CompareValuesProvider} from '../../../../business/compare_values/compare_values_provider';
import {flattenTree, QuestionAnswerPair} from '../../../../../support/question_answer_tree';
import {debounceTime, map} from 'rxjs/operators';
import {
    averageComparabilityScore,
    badgeContextToComparabilityScore,
    badgeContextToNumber,
} from '../../../../components/badges/badge_context_calculators';
import {BadgeContext} from '../../../../enum/badge_context';
import {findChildRecursiveByPredicate} from '../../../../../support/generic_tree';
import {ReferenceSale} from '../../content/questions/advanced/reference_objects_question/v1/models/reference_sale';
import {DistanceProvider} from '../../../../business/distance_provider';
import {ReferenceObjectsMetadataProvider} from '../../../../business/reference_objects_metadata/reference_objects_metadata_provider';
import {ReferenceObjectsMetadataVersion} from '../../../../enum/reference_objects_metadata_version';
import {ModalConfigStackInteractor} from '../../../../business/modal_config_stack_interactor';
import {ReferenceObjectsAnswerEnhancementInteractor} from '../../content/questions/advanced/reference_objects_question/interactors/reference_objects_answer_enhancement_interactor';

export class ReferenceObjectsWidgetPresenter implements Presenter {
    @observable public preselectedObjects: ReferenceSale[] = [];
    @observable public buildYear: number | null = null;
    @observable public surfaceArea: number | null = null;
    @observable public plotArea: number | null = null;
    @observable public showPreselection = false;
    @observable public referencesComparability: {
        reference: ReferenceObjectData;
        overallComparability: number;
        differencesComparability: number;
    }[] = [];

    private subscriptions = new CompositeSubscription();
    private referencesComparabilitySubscription: Subscription | null = null;
    public referenceObjectsV3Presenter: ReferenceObjectsQuestionPresenter | null = null;

    constructor(
        appraisal: Appraisal,
        private referenceObjectsMetadataProvider: ReferenceObjectsMetadataProvider,
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private surfaceAreaProvider: SurfaceAreaProvider,
        private plotAreaProvider: PlotAreaProvider,
        private compareValuesProvider: CompareValuesProvider,
        appraisalProvider: AppraisalProvider,
        referenceSetsProvider: V3ReferenceSetsProvider,
        referenceObjectsInteractor: ReferenceObjectsInteractor,
        appraiseSecondaryConfigStackInteractor: AppraiseSecondaryConfigStackInteractor,
        modalConfigStackInteractor: ModalConfigStackInteractor,
        networkStatusProvider: NetworkStatusProvider,
        buildYearProvider: BuildYearProvider,
        answerPathStubber: AnswerPathStubber,
        answerInteractor: AnswerInteractor,
        imageUploadInteractor: ImageUploadInteractor,
        flashMessageBroadcaster: FlashMessageBroadcaster,
        distanceProvider: DistanceProvider,
        globalProvider: GlobalProvider,
        referenceObjectsAnswerEnhancementInteractor: ReferenceObjectsAnswerEnhancementInteractor
    ) {
        makeObservable(this);

        const v3Questions = this.questionSet.findQuestionsByType(NormalQuestionType.REFERENCE_OBJECTS_V3);
        if (v3Questions.length === 1) {
            const answer = getNewestAnswer(this.answerController.answersForQuestionUuid(v3Questions[0].uuid));
            if (answer) {
                this.referenceObjectsV3Presenter = new ReferenceObjectsQuestionPresenter(
                    appraisal,
                    v3Questions[0],
                    questionSet,
                    appraisalProvider,
                    answer.parentUuid ?? undefined,
                    answer.iteration ?? undefined,
                    answerController,
                    referenceSetsProvider,
                    referenceObjectsInteractor,
                    appraiseSecondaryConfigStackInteractor,
                    modalConfigStackInteractor,
                    networkStatusProvider,
                    buildYearProvider,
                    answerPathStubber,
                    answerInteractor,
                    imageUploadInteractor,
                    flashMessageBroadcaster,
                    globalProvider,
                    distanceProvider,
                    referenceObjectsMetadataProvider,
                    referenceObjectsAnswerEnhancementInteractor,
                    true
                );
            }
        }
    }

    public async mount() {
        const buildYearQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.OBJECT_BUILD_YEAR
        );
        if (buildYearQuestion) {
            this.subscriptions.add(
                this.answerController.answersForQuestionUuidStream(buildYearQuestion.uuid).subscribe((answers) => {
                    this.updateBuildYear(getNewestAnswer(answers)?.contents ?? null);
                })
            );
        }

        this.subscriptions.add(
            this.surfaceAreaProvider.surfaceArea().subscribe((sum) => {
                runInAction(() => {
                    this.updateSurfaceArea(sum);
                });
            })
        );

        this.subscriptions.add(
            this.plotAreaProvider.plotArea().subscribe((plotArea) => {
                runInAction(() => {
                    this.updatePlotArea(plotArea);
                });
            })
        );

        this.subscriptions.add(autorun(this.getReferenceComparabilityScores));

        this.subscriptions.add(
            this.referenceObjectsMetadataProvider.getGlobalMetadataStream().subscribe((metadata) => {
                runInAction(() => {
                    if (!metadata || metadata.version !== ReferenceObjectsMetadataVersion.V3) {
                        return;
                    }

                    this.preselectedObjects = metadata.preselectedObjects ?? [];
                });
            })
        );

        await this.referenceObjectsV3Presenter?.mount();
    }

    public async unmount() {
        this.subscriptions.clear();
        this.referencesComparabilitySubscription?.unsubscribe();

        await this.referenceObjectsV3Presenter?.unmount();
    }

    private getReferenceComparabilityScores = () => {
        const visibleReferenceObjects = this.referenceObjectsV3Presenter?.visibleReferenceObjects;

        if (!visibleReferenceObjects) {
            return null;
        }

        const references = [
            ...visibleReferenceObjects.selectedPreselectedReferences,
            ...visibleReferenceObjects.selectedObjects,
        ];

        this.referencesComparabilitySubscription?.unsubscribe();

        const getBadgesForTreeItems = (items: QuestionAnswerPair[]) => {
            return items
                .map((item) => {
                    if (!item.answer) {
                        return null;
                    }

                    return this.compareValuesProvider
                        .badgesByAnswerIdentifiersStream(
                            item.question.uuid,
                            item.answer.parentUuid,
                            item.answer.iteration
                        )
                        .pipe(
                            map((badges) => {
                                return Object.values(badges).filter<BadgeContext>(
                                    (badge): badge is BadgeContext => badge !== null
                                );
                            })
                        );
                })
                .filter((item): item is Observable<BadgeContext[]> => item !== null);
        };

        this.referencesComparabilitySubscription = combineLatest(
            references.map((r) => {
                const differencesQuestion = findChildRecursiveByPredicate(
                    r.treeItem,
                    (item) => item.question.technicalReference === TechnicalReference.REFERENCE_OBJECT_VERSCHILLEN_GROUP
                );
                const differencesQuestions = differencesQuestion !== null ? flattenTree(differencesQuestion) : [];

                const otherQuestions = flattenTree(r.treeItem).filter((item) => !differencesQuestions.includes(item));

                return combineLatest(
                    combineLatest(getBadgesForTreeItems(otherQuestions)),
                    combineLatest(getBadgesForTreeItems(differencesQuestions))
                ).pipe(
                    debounceTime(1000),
                    map(([otherBadges, differencesBadges]) => {
                        const other = otherBadges.flat().map((badge) => badgeContextToNumber(badge) / 2);
                        const differences = differencesBadges.flat().map((badge) => badgeContextToNumber(badge) / 2);

                        return {
                            reference: r,
                            overallComparability: averageComparabilityScore(differences.concat(other)),
                            differencesComparability: averageComparabilityScore(
                                differencesBadges.flat().map((badge) => badgeContextToComparabilityScore(badge))
                            ),
                        };
                    })
                );
            })
        ).subscribe((referenceScores) => {
            runInAction(() => {
                this.referencesComparability = referenceScores;
            });
        });
    };

    @action
    public setActiveSetDefinition = async (setDefinition: SetDefinition | 'preselection') => {
        if (setDefinition === 'preselection') {
            this.showPreselection = true;
        } else {
            this.showPreselection = false;
            await this.referenceObjectsV3Presenter?.setActiveSetDefinition(setDefinition);
        }
    };

    @action
    private updateBuildYear(buildYear: string | null) {
        const num = Number(buildYear);
        if (Number.isNaN(num)) {
            return;
        }
        this.buildYear = num;
    }

    @action
    private updateSurfaceArea(surfaceArea: number | null) {
        this.surfaceArea = surfaceArea;
    }

    @action
    private updatePlotArea(plotArea: number | null) {
        this.plotArea = plotArea;
    }
}
