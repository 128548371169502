export function isCorsBlocked(imageElement: HTMLImageElement): boolean {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
        try {
            context.drawImage(imageElement, 0, 0);
            canvas.toDataURL();
            return false;
        } catch (err) {
            return true;
        }
    }

    return true;
}
