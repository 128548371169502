import * as React from 'react';

import {PriceRange} from '../price_range';
import {ReferenceObject} from '../../models/reference_object';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {classNames} from '../../../../../../../../../../support/classnames';
import {format} from 'date-fns';
import {referenceObjectImageUrlPairs} from '../../internal/reference_sale_image_urls';
import {ImageViewer} from '../../../../../../../../../components/image_viewer/image_viewer';

interface VisibleReferenceObject {
    referenceObject: ReferenceObject;
    referenceObjectAnswer: ReferenceObjectAnswer | null;
}

interface OwnProps {
    referenceObject: ReferenceObject;
    visibleReferenceObjects: VisibleReferenceObject[];
    onClickChange: (referenceObject: null) => void;

    showDetailsModal: (referenceObject: ReferenceObject) => void;
}

export class ReferenceObjectMapDetails extends React.Component<OwnProps> {
    public render() {
        const visibleReferenceObject = this.props.visibleReferenceObjects.find(
            (vrs) => vrs.referenceObject.id === this.props.referenceObject.id
        );
        const referenceObjectAnswer = visibleReferenceObject ? visibleReferenceObject.referenceObjectAnswer : null;

        const imageUrlPairs = this.props.referenceObject
            ? referenceObjectImageUrlPairs(this.props.referenceObject, referenceObjectAnswer)
            : [];

        return (
            <div className="container container-wide reference-object-map-details-container">
                <div className="row row-wide">
                    <div className="col-5 offset-7 col-wide">
                        <div className="reference-object-map-details-container-inner">
                            <div className="reference-object-map-details">
                                <button
                                    className="reference-object-map-details-close close ion-md-close"
                                    onClick={() => this.props.onClickChange(null)}
                                />
                                <div className="reference-object-map-details-body">
                                    <div className="reference-object-map-details-img">
                                        <div className="reference-object-map-details-img-content">
                                            <ImageViewer images={imageUrlPairs.map((pair) => pair.url)} />
                                            <div
                                                className={classNames('active-icon ion-md-checkmark', {
                                                    visible: referenceObjectAnswer !== null,
                                                })}
                                            >
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <p>
                                                <strong>
                                                    {this.props.referenceObject.street}{' '}
                                                    {this.props.referenceObject.houseNumber}
                                                    {this.props.referenceObject.letter}
                                                </strong>
                                                <br />
                                                {this.props.referenceObject.postalCode}{' '}
                                                {this.props.referenceObject.city}
                                            </p>
                                        </div>
                                        <div className="col-6">
                                            <dl>
                                                <dt>Prijsklasse</dt>
                                                <dd>
                                                    <PriceRange
                                                        range={this.props.referenceObject.priceRange}
                                                        break={false}
                                                    />
                                                </dd>
                                            </dl>
                                        </div>
                                        <div className="col-3">
                                            <dl>
                                                <dt>GBO wonen</dt>
                                                <dd>
                                                    {this.props.referenceObject.floorArea === null
                                                        ? 'Onbekend'
                                                        : this.props.referenceObject.floorArea + 'm²'}
                                                </dd>
                                            </dl>
                                        </div>
                                        <div className="col-3">
                                            <dl>
                                                <dt>Periode</dt>
                                                <dd>
                                                    {this.props.referenceObject.saleQuarter !== null
                                                        ? this.props.referenceObject.saleQuarter.length > 8
                                                            ? format(
                                                                  new Date(this.props.referenceObject.saleQuarter),
                                                                  'dd-MM-yyyy'
                                                              )
                                                            : 'Q' + this.props.referenceObject.saleQuarter
                                                        : null}
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <div className="reference-object-map-details-footer">
                                    <a
                                        className="ion-md-information-circle"
                                        onClick={() => this.props.showDetailsModal(this.props.referenceObject)}
                                    >
                                        Details
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
