import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {QuestionSet} from '../../../../models/question_set';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {observer} from 'mobx-react-lite';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {StaticButtonsPresenter} from './static_buttons_presenter';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {SidebarRootItem} from '../sidebar/sidebar_root_item';
import {ValidationError} from '../../../../models/validation_error';

export interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet | null;
    renderingContext: RenderingContextType;
    validationErrors: ValidationError[];
}

export const StaticButtons: React.FC<OwnProps> = observer(
    ({appraisal, questionSet, validationErrors, pagePartsSet, renderingContext}) => {
        const presenter = usePresenter(
            (component) =>
                new StaticButtonsPresenter(
                    component.business.pagePartsSetInteractor(appraisal, questionSet),
                    component.business.sidebarTreeBuilder(appraisal, questionSet, pagePartsSet)
                )
        );

        return (
            <ul className="card-sidebar-static-items d-none d-md-inline-flex">
                {presenter.staticItems.map((item) => (
                    <SidebarRootItem
                        key={item.link}
                        sidebarItem={item}
                        appraisal={appraisal}
                        questionSet={questionSet}
                        pagePartsSet={pagePartsSet}
                        validationErrors={validationErrors}
                        renderingContext={renderingContext}
                    />
                ))}
            </ul>
        );
    }
);
