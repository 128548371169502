export function normalizeString(value: string | null | undefined): string {
    if (!value) {
        return '';
    }

    let result = value;

    if (typeof result.normalize === 'function') {
        //Replace all diacritical characters with their simplified version
        //For example è -> e
        result = result.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    //Remove some remaining special chars that are not filesystem compatible
    return result.replace(/[^\w\s$*_+~.()'"!\-:@?]+/g, '');
}
