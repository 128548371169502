import {ConstructionCostsLine} from '../../models/construction_costs_summary';
import {ConstructionCostsUnit} from '../../../network/models/construction_costs';

export interface ApiConstructionCostsLine {
    costs_cents: number;
    description: string;
    unit: ConstructionCostsUnit;
    labels: string | null;
}

export function apiConstructionCostsLinesToConstructionCostsLines(
    apiConstructionCostsLines: ApiConstructionCostsLine[]
): ConstructionCostsLine[] {
    return apiConstructionCostsLines.map((c) => apiConstructionCostsLineToConstructionCostsLine(c));
}

export function apiConstructionCostsLineToConstructionCostsLine(
    apiConstructionCostsLine: ApiConstructionCostsLine
): ConstructionCostsLine {
    return {
        costsCents: apiConstructionCostsLine.costs_cents,
        description: apiConstructionCostsLine.description,
        unit: apiConstructionCostsLine.unit,
        labels: apiConstructionCostsLine.labels,
    };
}
