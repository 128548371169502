import {Appraisal} from '../models/appraisal';

export enum AppraiseModel {
    MODEL2018 = '2018',
    MODEL2021 = '2021',
    MODEL2024 = '2024',
    WOCO2016 = 'woco2016',
    DESK2021 = 'desk2021',
    MODEL2024PLAUSIBILITY = 'plausibility2024',
}

export function isAppraiseModelOrNewer(model: AppraiseModel, compareModel: AppraiseModel): boolean {
    const order = [
        AppraiseModel.WOCO2016,
        AppraiseModel.MODEL2018,
        AppraiseModel.DESK2021,
        AppraiseModel.MODEL2021,
        AppraiseModel.MODEL2024,
    ];

    return order.indexOf(model) >= order.indexOf(compareModel);
}

export function isAppraiseModel(appraisal: Appraisal | null, models: AppraiseModel | AppraiseModel[]): boolean {
    if (appraisal === null) {
        return false;
    }
    if (Array.isArray(models)) {
        for (const model of models) {
            if (appraisal.appraiseModel === model) {
                return true;
            }
        }
    }
    return appraisal.appraiseModel === models;
}
