interface Task {
    status_url: string;
    redirect_url: string;
    fail_redirect_url: string;
}

declare const TASK: Task | undefined;

enum State {
    CREATED = 'created',
    STARTED = 'started',
    FINISHED = 'finished',
}

enum TaskResult {
    CREATED = 'created',
    STARTED = 'started',
    FINISHED = 'finished',
    WARNING = 'warning',
    ERROR = 'error',
}

interface PollResult {
    status: State;
    result: TaskResult;
    progress: number;
}

export function redirectOnTaskComplete() {
    if (typeof TASK !== 'undefined') {
        pollAndRedirect(TASK);
    }
}

export function pollTaskStatusUrl(statusUrl: string, progressCallback?: (progress: number) => void) {
    return new Promise<void>((resolve, reject) => {
        let state: State | null = null;
        let result: TaskResult | null = null;

        const poll = async () => {
            try {
                const pollResult = await get(statusUrl);
                state = pollResult.status;
                result = pollResult.result;

                const progress = pollResult.progress;
                if (progress !== undefined && progressCallback !== undefined) {
                    progressCallback(progress);
                }
            } catch (e) {
                //Just keep trying when things go wrong
            }

            if (state === State.FINISHED && result === TaskResult.FINISHED) {
                resolve();
            } else if (result === TaskResult.ERROR || result === TaskResult.WARNING) {
                reject(new Error(`Task failed with result ${result}`));
            } else {
                setTimeout(() => {
                    poll();
                }, 4000);
            }
        };

        poll();
    });
}

async function pollAndRedirect(task: Task) {
    try {
        await pollTaskStatusUrl(task.status_url, (progress) => updateProgressInView(progress));

        window.location.href = task.redirect_url;
    } catch (e) {
        if (task.fail_redirect_url !== undefined) {
            window.location.href = task.fail_redirect_url;
        }
    }
}

function get(statusUrl: string) {
    return new Promise<PollResult>((resolve, reject) => {
        window
            .fetch(statusUrl, {
                method: 'GET',
                credentials: 'same-origin',
                headers: {
                    'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(async (result) => {
                if (result.ok) {
                    resolve(await result.json());
                } else {
                    reject();
                }
            })
            .catch((error) => reject(error));
    });
}

// Todo: possibly not the proper place to do this...
function updateProgressInView(data: number) {
    const element = document.getElementById('loader-total-progress');
    if (element) {
        element.setAttribute('style', 'width:' + data + '%');
    }
}
