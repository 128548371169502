import {BadgeContext} from '../../enum/badge_context';
import {ObjectType} from '../../enum/object_type';

export function buildYearDiffToBadgeContext(diff: number): BadgeContext {
    if (diff > 10 && diff <= 20) {
        return BadgeContext.BadgeBetter;
    } else if (diff > 20) {
        return BadgeContext.BadgeMuchBetter;
    } else if (diff < -10 && diff >= -20) {
        return BadgeContext.BadgeWorse;
    } else if (diff < -20) {
        return BadgeContext.BadgeMuchWorse;
    }
    return BadgeContext.BadgeNeutral;
}

export function diffToBadgeContext(diff: number): BadgeContext {
    if (diff > 20) {
        return BadgeContext.BadgeMuchBetter;
    } else if (diff > 10) {
        return BadgeContext.BadgeBetter;
    } else if (diff < -20) {
        return BadgeContext.BadgeMuchWorse;
    } else if (diff < -10) {
        return BadgeContext.BadgeWorse;
    }
    return BadgeContext.BadgeNeutral;
}

export function energyLabelDiffToBadgeContext(value: string | null, referenceValue: string | null) {
    const labels = ['A++++', 'A+++', 'A++', 'A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G'];
    if (referenceValue !== null && value !== null) {
        const index = labels.indexOf(value);
        const referenceIndex = labels.indexOf(referenceValue);
        const diff = index - referenceIndex;
        if (diff >= 2) {
            return BadgeContext.BadgeMuchWorse;
        } else if (diff >= 1) {
            return BadgeContext.BadgeWorse;
        } else if (diff <= -1) {
            return BadgeContext.BadgeBetter;
        } else if (diff <= -2) {
            return BadgeContext.BadgeMuchBetter;
        }
    }
    return BadgeContext.BadgeNeutral;
}

export function statusTextToBadgeContext(status: string) {
    switch (status.toLocaleLowerCase()) {
        case 'veel beter':
            return BadgeContext.BadgeMuchBetter;
        case 'beter':
            return BadgeContext.BadgeBetter;
        case 'minder':
            return BadgeContext.BadgeWorse;
        case 'veel minder':
            return BadgeContext.BadgeMuchWorse;
    }
    return BadgeContext.BadgeNeutral;
}

export function badgeContextToStatusText(status: string) {
    switch (status.toLocaleLowerCase()) {
        case BadgeContext.BadgeMuchBetter:
            return 'veel beter';
        case BadgeContext.BadgeBetter:
            return 'beter';
        case BadgeContext.BadgeWorse:
            return 'minder';
        case BadgeContext.BadgeMuchWorse:
            return 'veel minder';
    }
    return 'vergelijkbaar';
}

export function conditionToBadgeContext(status: string) {
    switch (status.toLocaleLowerCase()) {
        case 'goed':
            return BadgeContext.BadgeMuchBetter;
        case 'redelijk':
            return BadgeContext.BadgeNeutral;
        case 'matig':
            return BadgeContext.BadgeWorse;
        case 'slecht':
            return BadgeContext.BadgeMuchWorse;
    }
    return null;
}

export function badgeContextToNumber(status: string) {
    switch (status.toLocaleLowerCase()) {
        case BadgeContext.BadgeMuchBetter:
            return 2;
        case BadgeContext.BadgeBetter:
            return 1;
        case BadgeContext.BadgeWorse:
            return -1;
        case BadgeContext.BadgeMuchWorse:
            return -2;
    }
    return 0;
}

export function numberToBadgeContext(num: number) {
    num = Math.round(num);
    switch (num) {
        case 2:
            return BadgeContext.BadgeMuchBetter;
        case 1:
            return BadgeContext.BadgeBetter;
        case -1:
            return BadgeContext.BadgeWorse;
        case -2:
            return BadgeContext.BadgeMuchWorse;
    }
    return BadgeContext.BadgeNeutral;
}

export function badgeContextsToAverage(badgeContexts: BadgeContext[]) {
    let sum = 0;
    badgeContexts.forEach((badgeContext) => {
        sum += badgeContextToNumber(badgeContext);
    });
    return numberToBadgeContext(sum / badgeContexts.length);
}

export function badgeContextToComparabilityScore(badge: BadgeContext): number {
    switch (badge) {
        case BadgeContext.BadgeMuchWorse:
        case BadgeContext.BadgeMuchBetter:
            return 0;
        case BadgeContext.BadgeWorse:
        case BadgeContext.BadgeBetter:
            return 1;
        default:
            return 2;
    }
}

export function averageComparabilityScore(scores: number[]): number {
    const score = scores.reduce((acc, v) => acc + v, 0);
    const max = scores.length * 2; // 2 is the highest score

    return score / max;
}

/**
 * See: https://3.basecamp.com/3400849/buckets/8148687/todos/5024073194
 */
export function objectTypeDiffToBadgeContext(objectType: ObjectType | null, referenceObjectType: ObjectType | null) {
    switch (referenceObjectType) {
        case ObjectType.VRIJSTAAND:
            return getDiffForDetachedHouse(objectType);
        case ObjectType.TWEE_ONDER_EEN_KAPWONING:
        case ObjectType.GESCHAKELDE_TWEE_ONDER_EEN_KAPWONING:
            return getDiffForSemiDetachedHouse(objectType, referenceObjectType);
        case ObjectType.GESCHAKELDE_WONING:
            return getDiffForLinkedHouse(objectType);
        case ObjectType.HALFVRIJSTAANDE_WONING:
            return getDiffForHalfDetachedHouse(objectType);
        case ObjectType.TUSSENWONING:
        case ObjectType.EINDWONING:
            return getDiffForTerracedHouse(objectType, referenceObjectType);
        case ObjectType.HOEKWONING:
            return getDiffForCornerHouse(objectType);
        case ObjectType.GALERIJFLAT:
        case ObjectType.PORTIEKFLAT:
        case ObjectType.CORRIDORFLAT:
            return getDiffForApartment(objectType);
        case ObjectType.MAISONNETTE:
            return getDiffForMaisonette(objectType);
        case ObjectType.BENEDENWONING:
        case ObjectType.PORTIEKWONING:
            return getDiffForPorchHouse(objectType);
        case ObjectType.BOVENWONING:
            return getDiffForUpstairsApartment(objectType);
        default:
            return null;
    }
}

function getDiffForDetachedHouse(objectType: ObjectType | null): BadgeContext | null {
    switch (objectType) {
        case ObjectType.TWEE_ONDER_EEN_KAPWONING:
        case ObjectType.GESCHAKELDE_TWEE_ONDER_EEN_KAPWONING:
        case ObjectType.HALFVRIJSTAANDE_WONING:
            return BadgeContext.BadgeWorse;
        case ObjectType.GESCHAKELDE_WONING:
        case ObjectType.TUSSENWONING:
        case ObjectType.HOEKWONING:
        case ObjectType.EINDWONING:
            return BadgeContext.BadgeMuchWorse;
        default:
            return null;
    }
}

function getDiffForSemiDetachedHouse(
    objectType: ObjectType | null,
    referenceObjectType: ObjectType | null
): BadgeContext | null {
    switch (objectType) {
        case ObjectType.VRIJSTAAND:
            return BadgeContext.BadgeBetter;
        case ObjectType.GESCHAKELDE_WONING:
        case ObjectType.HOEKWONING:
        case ObjectType.EINDWONING:
            return BadgeContext.BadgeWorse;
        case ObjectType.TUSSENWONING:
            return referenceObjectType === ObjectType.TWEE_ONDER_EEN_KAPWONING
                ? BadgeContext.BadgeMuchWorse
                : BadgeContext.BadgeWorse;
        case ObjectType.TWEE_ONDER_EEN_KAPWONING:
        case ObjectType.GESCHAKELDE_TWEE_ONDER_EEN_KAPWONING:
        case ObjectType.HALFVRIJSTAANDE_WONING:
            return BadgeContext.BadgeNeutral;
        default:
            return null;
    }
}

function getDiffForLinkedHouse(objectType: ObjectType | null): BadgeContext | null {
    switch (objectType) {
        case ObjectType.VRIJSTAAND:
            return BadgeContext.BadgeMuchBetter;
        case ObjectType.TWEE_ONDER_EEN_KAPWONING:
        case ObjectType.GESCHAKELDE_TWEE_ONDER_EEN_KAPWONING:
            return BadgeContext.BadgeBetter;
        case ObjectType.TUSSENWONING:
        case ObjectType.HOEKWONING:
        case ObjectType.EINDWONING:
            return BadgeContext.BadgeWorse;
        case ObjectType.GESCHAKELDE_WONING:
        case ObjectType.HALFVRIJSTAANDE_WONING:
            return BadgeContext.BadgeNeutral;
        default:
            return null;
    }
}

function getDiffForHalfDetachedHouse(objectType: ObjectType | null): BadgeContext | null {
    switch (objectType) {
        case ObjectType.VRIJSTAAND:
        case ObjectType.GESCHAKELDE_WONING:
            return BadgeContext.BadgeBetter;
        case ObjectType.TUSSENWONING:
        case ObjectType.HOEKWONING:
        case ObjectType.EINDWONING:
            return BadgeContext.BadgeWorse;
        case ObjectType.TWEE_ONDER_EEN_KAPWONING:
        case ObjectType.GESCHAKELDE_TWEE_ONDER_EEN_KAPWONING:
        case ObjectType.HALFVRIJSTAANDE_WONING:
            return BadgeContext.BadgeNeutral;
        default:
            return null;
    }
}

function getDiffForTerracedHouse(
    objectType: ObjectType | null,
    referenceObjectType: ObjectType | null
): BadgeContext | null {
    switch (objectType) {
        case ObjectType.VRIJSTAAND:
            return BadgeContext.BadgeMuchBetter;
        case ObjectType.TWEE_ONDER_EEN_KAPWONING:
        case ObjectType.GESCHAKELDE_TWEE_ONDER_EEN_KAPWONING:
        case ObjectType.HALFVRIJSTAANDE_WONING:
            return BadgeContext.BadgeBetter;
        case ObjectType.HOEKWONING:
            return referenceObjectType === ObjectType.TUSSENWONING
                ? BadgeContext.BadgeBetter
                : BadgeContext.BadgeNeutral;
        case ObjectType.GESCHAKELDE_WONING:
        case ObjectType.TUSSENWONING:
        case ObjectType.EINDWONING:
            return BadgeContext.BadgeNeutral;
        default:
            return null;
    }
}

function getDiffForCornerHouse(objectType: ObjectType | null): BadgeContext | null {
    switch (objectType) {
        case ObjectType.VRIJSTAAND:
            return BadgeContext.BadgeMuchBetter;
        case ObjectType.TWEE_ONDER_EEN_KAPWONING:
        case ObjectType.GESCHAKELDE_TWEE_ONDER_EEN_KAPWONING:
        case ObjectType.HALFVRIJSTAANDE_WONING:
            return BadgeContext.BadgeBetter;
        case ObjectType.TUSSENWONING:
            return BadgeContext.BadgeWorse;
        case ObjectType.GESCHAKELDE_WONING:
        case ObjectType.HOEKWONING:
        case ObjectType.EINDWONING:
            return BadgeContext.BadgeNeutral;
        default:
            return null;
    }
}

function getDiffForApartment(objectType: ObjectType | null): BadgeContext | null {
    switch (objectType) {
        case ObjectType.MAISONNETTE:
        case ObjectType.BENEDENWONING:
        case ObjectType.PORTIEKWONING:
            return BadgeContext.BadgeBetter;
        case ObjectType.GALERIJFLAT:
        case ObjectType.PORTIEKFLAT:
        case ObjectType.CORRIDORFLAT:
        case ObjectType.BOVENWONING:
            return BadgeContext.BadgeNeutral;
        default:
            return null;
    }
}

function getDiffForMaisonette(objectType: ObjectType | null): BadgeContext | null {
    switch (objectType) {
        case ObjectType.BOVENWONING:
            return BadgeContext.BadgeBetter;
        case ObjectType.GALERIJFLAT:
        case ObjectType.PORTIEKFLAT:
        case ObjectType.CORRIDORFLAT:
            return BadgeContext.BadgeWorse;
        case ObjectType.MAISONNETTE:
        case ObjectType.BENEDENWONING:
        case ObjectType.PORTIEKWONING:
            return BadgeContext.BadgeNeutral;
        default:
            return null;
    }
}

function getDiffForPorchHouse(objectType: ObjectType | null): BadgeContext | null {
    switch (objectType) {
        case ObjectType.GALERIJFLAT:
        case ObjectType.PORTIEKFLAT:
        case ObjectType.CORRIDORFLAT:
            return BadgeContext.BadgeWorse;
        case ObjectType.MAISONNETTE:
        case ObjectType.BENEDENWONING:
        case ObjectType.PORTIEKWONING:
        case ObjectType.BOVENWONING:
            return BadgeContext.BadgeNeutral;
        default:
            return null;
    }
}

function getDiffForUpstairsApartment(objectType: ObjectType | null) {
    switch (objectType) {
        case ObjectType.MAISONNETTE:
            return BadgeContext.BadgeWorse;
        case ObjectType.GALERIJFLAT:
        case ObjectType.PORTIEKFLAT:
        case ObjectType.CORRIDORFLAT:
        case ObjectType.BENEDENWONING:
        case ObjectType.PORTIEKWONING:
        case ObjectType.BOVENWONING:
            return BadgeContext.BadgeNeutral;
        default:
            return null;
    }
}
