import {toJS} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {createPortal} from 'react-dom';
import {createRoot} from 'react-dom/client';
import {ReferenceSale} from '../../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v1/models/reference_sale';
import {TaskHelper} from '../../appraising/business/task_helper';
import {ApiReferenceSale, apiReferenceSalesToReferenceSales} from '../../appraising/network/models/api_reference_sale';
import {DefaultNetworkComponent} from '../../appraising/network/network_component';
import {
    PreselectedReferenceObjectsApi,
    AltumSettings,
} from '../../appraising/network/preselected_reference_objects_api';
import {ReferencesWheelContainer} from './altum_reference_objects/wheel_container';
import {RejectModal} from './altum_reference_objects/reject_modal';
import {ReferenceObjectWidget} from './altum_reference_objects/widget';
import {ReferenceObjectsPresenter} from './reference_objects_presenter';
import {ObjectTypeModal} from './altum_reference_objects/object_type_modal';
import {usePresenter} from '../../support/presenter/use_presenter';
import {DefaultGlobalProvider} from '../../business/global_provider';

declare const GLOBAL: {
    appraisalId: number;
    questionSetId: number;
    subscriptionsRoute: string;
    preselectedReferences: {
        altumReferenceSettings: AltumSettings;
        preselected: ApiReferenceSale[];
        rejected: ApiReferenceSale[];
    };
};

interface OwnProps {
    appraisalId: number;
    questionSetId: number;
    settings: AltumSettings;
    preselectReferenceObjectsApi: PreselectedReferenceObjectsApi;
    taskHelper: TaskHelper;
    preselected: ReferenceSale[];
    rejected: ReferenceSale[];
    subscriptionsRoute: string;
}

export const ReferenceObjects: React.FC<OwnProps> = observer(function ReferenceObjects(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectsPresenter(
                props.appraisalId,
                props.questionSetId,
                props.preselected,
                props.rejected,
                container.network.appraisalApi,
                container.network.questionSetApi,
                container.business.appraisalProvider,
                container.network.preselectedReferenceObjectsApi,
                container.network.taskHelper,
                container.business.distanceProvider,
                container.business.answerLoader
            )
    );

    const [isModalContainerVisible, setIsModalContainerVisible] = React.useState(false);

    const modalContainer = React.useRef<HTMLDivElement | null>(null);
    const checkContainerVisible = React.useCallback(() => {
        //If the container is hidden, dont render the secondary
        const appraiseSecondaryContainer = document.getElementById('secondary-content');
        const isContainerDisplayed = !(
            appraiseSecondaryContainer && window.getComputedStyle(appraiseSecondaryContainer, null).display === 'none'
        );

        if (isContainerDisplayed !== isModalContainerVisible) {
            setIsModalContainerVisible(isContainerDisplayed);
        }
    }, []);

    React.useEffect(() => {
        window.addEventListener('resize', checkContainerVisible);
        checkContainerVisible();

        return () => window.removeEventListener('resize', checkContainerVisible);
    }, [checkContainerVisible]);

    const widgetsContainer = document.getElementById('preselect-reference-objects-widgets');

    return (
        <>
            {presenter.appraisal && presenter.questionSet && presenter.referenceObjectToReject && (
                <RejectModal
                    key={`${presenter.referenceObjectToReject.id}-${presenter.referenceObjectToReject.saleQuarter}`}
                    modalContainer={isModalContainerVisible ? modalContainer : undefined}
                    appraisal={presenter.appraisal}
                    questionSet={presenter.questionSet}
                    cancel={() =>
                        presenter.referenceObjectToReject && presenter.undoReject(presenter.referenceObjectToReject)
                    }
                    save={(reason) =>
                        presenter.referenceObjectToReject &&
                        presenter.saveReject(presenter.referenceObjectToReject, reason)
                    }
                    onChange={(reason) =>
                        presenter.referenceObjectToReject &&
                        presenter.updateRejectReason(presenter.referenceObjectToReject, reason)
                    }
                />
            )}
            {widgetsContainer &&
                createPortal(
                    <>
                        <ReferenceObjectWidget
                            title="Geaccepteerde referenties"
                            objects={presenter.preselectedObjects}
                            deselect={(obj) => presenter.undoAccept(obj)}
                        />
                        <ReferenceObjectWidget
                            title="Afgekeurde referenties"
                            objects={presenter.rejectedObjects}
                            deselect={(obj) => presenter.undoReject(obj)}
                        />
                        <div ref={modalContainer} />
                    </>,
                    widgetsContainer
                )}
            <ObjectTypeModal
                visible={presenter.objectTypeModalDetails.visible}
                street={presenter.appraisal?.address}
                houseNumber={presenter.appraisal?.houseNumber}
                letter={presenter.appraisal?.letter ?? undefined}
                postalCode={presenter.appraisal?.postalCode}
                city={presenter.appraisal?.city}
                storeObjectType={(objectType) => {
                    presenter.setObjectType(objectType);
                    presenter.requestObjects();
                }}
                onClose={() => presenter.closeObjectTypeModal()}
                error={presenter.objectTypeModalDetails.error}
            />
            <ReferencesWheelContainer
                objects={toJS(presenter.completeReferenceObjects)}
                loading={toJS(presenter.loading)}
                error={toJS(presenter.loadError)}
                given={toJS(presenter.givenObject ?? undefined)}
                accepted={toJS(presenter.preselectedObjects)}
                rejected={toJS(presenter.rejectedObjects)}
                accept={presenter.accept}
                reject={presenter.reject}
                appraisal={presenter.appraisal}
                questionSet={presenter.questionSet}
                forceRequest={() => presenter.requestObjects()}
                changeObjectType={() => presenter.changeObjectType()}
            />
        </>
    );
});

export function renderPrecheckReferencesEnvironmentInto(element: HTMLElement) {
    const network = new DefaultNetworkComponent(new DefaultGlobalProvider());

    createRoot(element).render(
        <ReferenceObjects
            appraisalId={GLOBAL.appraisalId}
            questionSetId={GLOBAL.questionSetId}
            settings={GLOBAL.preselectedReferences.altumReferenceSettings}
            preselected={apiReferenceSalesToReferenceSales(GLOBAL.preselectedReferences.preselected)}
            rejected={apiReferenceSalesToReferenceSales(GLOBAL.preselectedReferences.rejected)}
            subscriptionsRoute={GLOBAL.subscriptionsRoute}
            preselectReferenceObjectsApi={network.preselectedReferenceObjectsApi}
            taskHelper={network.taskHelper}
        />
    );
}
