import * as React from 'react';

import {AttachmentQuestionProps} from '../attachment_question_presenter';
import {BuildingCostsPhotoPresenter} from './building_costs_photo_presenter';
import {Label} from '../../components/label';
import {classNames} from '../../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {simpleQuestionPresenterConstructorParametersFactory} from '../../simple/simple_question_presenter';
import {ValidationMessages} from '../../components/validation_messages';
import {filterValidationMessages} from '../../../../support/filter_validation_messages';
import {PhotoPickModal} from './photo_pick_modal';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {ImageViewer} from '../../../../../../components/image_viewer/image_viewer';
import {Image} from '../../../../../../components/image_viewer/image';
import {getEditableImageViewerComponent} from './components/editable_image_viewer_component';

export const BuildingCostsPhoto: React.FunctionComponent<AttachmentQuestionProps> = observer((props) => {
    const presenter = usePresenter(
        (container) =>
            new BuildingCostsPhotoPresenter(
                props.fileTypes,
                container.business.blobCacheInteractor(),
                container.business.imageUploadInteractor(props.appraisal, props.questionSet),
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.attachmentQuestionsInteractor(props.appraisal, props.questionSet),
                container.business.flashMessageBroadcaster,
                container.globalProvider,
                container.business.automatorInteractor(props.appraisal, props.questionSet),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );

    const {answer, url, uncompressedUrl} = presenter;

    const images = React.useMemo(
        () =>
            url
                ? [
                      {
                          url,
                          uncompressedUrl: uncompressedUrl ?? undefined,
                          answer,
                      },
                  ]
                : [],
        [url, uncompressedUrl, answer]
    );

    if (presenter.isHidden) {
        return null;
    }

    if (presenter.empty) {
        return (
            <>
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <div
                    className={classNames('form-grid-item form-grid-item-rectangle', {
                        'form-grid-item-rectangle-empty': !answer || !answer.file,
                    })}
                >
                    <div className="form-grid-item-img" data-answer-uuid={answer?.uuid ?? null}>
                        <div className="form-grid-item-img-content">
                            <div className="img-wrap">{renderUploadOverlay()}</div>
                        </div>
                    </div>
                    {presenter.isPhotoPickModalOpen && (
                        <PhotoPickModal
                            photoPairs={presenter.pairs}
                            loading={presenter.loading}
                            onImagePicked={(pair) => presenter.onImagePicked(pair.photoAnswer)}
                            onClose={() => presenter.setPhotoPickModalOpen(false)}
                        />
                    )}
                </div>
                <ValidationMessages
                    fullWidth
                    disabled={props.disabled || presenter.isDisabled}
                    validationMessages={filterValidationMessages(
                        props.question,
                        presenter.answer,
                        props.validationMessages,
                        presenter.validationMessages
                    )}
                    answer={presenter.answer}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                />
            </>
        );
    }

    function renderUploadOverlay() {
        return (
            <div className="upload-overlay row h-100 pl-4 pr-4">
                <div className="col-6">
                    <label>
                        <input
                            type="file"
                            name="files[]"
                            style={{display: 'none'}}
                            disabled={presenter.isDisabled || props.disabled}
                            onChange={(e) => presenter.onFileChange(e)}
                        />
                        <span className="ion-md-cloud-upload"></span>
                        <span>Upload een foto</span>
                    </label>
                </div>
                <div className="col-6">
                    <button onClick={() => presenter.setPhotoPickModalOpen(true)}>
                        <span className="ion-md-photos"></span>
                        <span>Kies een foto</span>
                    </button>
                </div>
            </div>
        );
    }

    return (
        <>
            <Label
                for={props.question.uuid}
                question={props.question}
                parentAnswerUuid={props.parentAnswerUuid}
                iteration={props.iteration}
                appraisal={props.appraisal}
                questionSet={props.questionSet}
                disabled={props.disabled || presenter.isDisabled}
                hideLabel={props.hideLabel}
                showLabel={props.question.showLabel}
                renderingContext={props.renderingContext}
            />
            <div
                className={classNames('form-grid-item form-grid-item-rectangle', {
                    'form-grid-item-rectangle-empty': presenter.isDisabled || props.disabled,
                })}
            >
                <div className="form-grid-item-img" data-answer-uuid={answer?.uuid ?? null}>
                    <div className="form-grid-item-img-content">
                        <div className="img-wrap">
                            <ImageViewer
                                images={images}
                                hideThumbnailSlider
                                components={[
                                    getEditableImageViewerComponent({
                                        isEditable: !presenter.isDisabled && !props.disabled,
                                    }),
                                ]}
                            >
                                {url ? (
                                    <Image
                                        className={classNames(
                                            {
                                                opaque:
                                                    presenter.loading || presenter.showRetryButton || props.disabled,
                                            },
                                            {
                                                blurred: presenter.loading || presenter.showRetryButton,
                                            },
                                            {'d-flex': presenter.isImageLoaded},
                                            {'d-none': !presenter.isImageLoaded}
                                        )}
                                        src={url}
                                        onLoad={() => {
                                            presenter.onImageLoad();
                                        }}
                                    />
                                ) : null}
                            </ImageViewer>

                            {presenter.showRetryButton && (
                                <button className="btn-text-link img-retry" onClick={() => presenter.retry()}>
                                    <span className="img-retry-content ion-md-refresh">Opnieuw</span>
                                </button>
                            )}

                            {!presenter.empty && presenter.loading && (
                                <span className="img-uploading">
                                    <span className="img-uploading-content ion-md-cloud-upload">Uploading</span>
                                </span>
                            )}

                            {presenter.isReplacePhotoOverlayVisible && renderUploadOverlay()}
                        </div>
                    </div>
                </div>
                {!presenter.empty && !presenter.isDisabled && !props.disabled ? (
                    <div className="form-grid-item-footer d-flex justify-content-between">
                        <button className="btn-link ion-md-trash" onClick={() => presenter.onClearClick()} />
                        <button className="btn-link ion-md-refresh" onClick={() => presenter.toggleReplacePhoto()}>
                            {presenter.isReplacePhotoOverlayVisible ? 'Annuleer' : 'Vervang foto'}
                        </button>
                    </div>
                ) : null}
                {presenter.isPhotoPickModalOpen && (
                    <PhotoPickModal
                        photoPairs={presenter.pairs}
                        loading={presenter.loading}
                        onImagePicked={(pair) => presenter.onImagePicked(pair.photoAnswer)}
                        onClose={() => presenter.setPhotoPickModalOpen(false)}
                    />
                )}
            </div>
            <ValidationMessages
                fullWidth
                disabled={props.disabled || presenter.isDisabled}
                validationMessages={filterValidationMessages(
                    props.question,
                    presenter.answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
                answer={presenter.answer}
                forceShowValidationMessages={props.forceShowValidationMessages}
            />
        </>
    );
});
