import {
    ApiReferenceSalesData,
    apiReferenceSalesDataToReferenceSalesData,
} from '../network/models/api_reference_sales_data';
import {ApiSale, apiSaleToSale} from '../network/models/api_sale';
import {
    ExtraReferenceSaleSet,
    ReferenceSale,
} from '../appraise/ui/content/questions/advanced/reference_objects_question/v1/models/reference_sale';

import {Appraisal} from '../models/appraisal';
import {AreaConclusion} from '../models/area_conclusion';
import {EnergyConclusion} from '../models/energy_conclusion';
import {ReferenceObjectSaleApi} from '../network/reference_objects_sale_api';
import {ReferenceSaleSetData, ReferenceSaleSetRequestData} from './reference_object_provider';
import {Sale} from '../models/sale';
import {TaskHelper} from './task_helper';

export interface ReferenceSalesData {
    energyConclusion: EnergyConclusion;
    areaConclusion: AreaConclusion;
    defaultReferenceSalesRequired: boolean;
    refreshCredentials: boolean;
    buildYear: number | null;
    referenceSales: ReferenceSale[];
    extraReferenceSalesSets: ExtraReferenceSaleSet[];
}

export interface ReferenceObjectCurrentlyInSaleInteractor {
    getReferenceSales(): Promise<ReferenceSalesData>;

    getReferenceSalesSet(data: ReferenceSaleSetRequestData): Promise<ReferenceSaleSetData>;

    getSale(id: string): Promise<Sale>;

    getSaleByAddress(postalCode: string, houseNumber: string, letter: string): Promise<Sale>;

    enhanceHighlightedReferenceSale(): Promise<ReferenceSale | null>;

    getEnhancedReferenceSale(
        id: string,
        source: string | null,
        setData: ReferenceSaleSetRequestData
    ): Promise<ReferenceSale | null>;
}

export class DefaultReferenceObjectCurrentlyInSaleInteractor implements ReferenceObjectCurrentlyInSaleInteractor {
    private _appraisal: Appraisal;
    private _referenceObjectApi: ReferenceObjectSaleApi;
    private _taskHelper: TaskHelper;

    constructor(appraisal: Appraisal, referenceObjectApi: ReferenceObjectSaleApi, taskHelper: TaskHelper) {
        this._appraisal = appraisal;
        this._referenceObjectApi = referenceObjectApi;
        this._taskHelper = taskHelper;
    }

    public async getReferenceSales(): Promise<ReferenceSalesData> {
        const referenceSales = await this._referenceObjectApi.getReferenceSales(this._appraisal.id);
        if (!TaskHelper.isTaskReference(referenceSales)) {
            return referenceSales;
        }

        const result = await this._taskHelper.poll<ApiReferenceSalesData>(referenceSales.taskId);

        if (result !== null) {
            return apiReferenceSalesDataToReferenceSalesData(result);
        }

        throw new Error('No result');
    }

    public async getReferenceSalesSet(): Promise<ReferenceSaleSetData> {
        throw new Error('Not implemented');
    }

    public getSale(id: string): Promise<Sale> {
        return this._referenceObjectApi.getSale(this._appraisal.id, id);
    }

    public async getSaleByAddress(postalCode: string, houseNumber: string, letter: string): Promise<Sale> {
        const saleByAddress = await this._referenceObjectApi.getSaleByAddress(
            this._appraisal.id,
            postalCode,
            houseNumber,
            letter
        );

        if (!TaskHelper.isTaskReference(saleByAddress)) {
            return saleByAddress;
        }

        const result = await this._taskHelper.poll<ApiSale>(saleByAddress.taskId);
        if (result !== null) {
            return apiSaleToSale(result);
        }

        throw new Error('No result');
    }

    public async enhanceHighlightedReferenceSale(): Promise<ReferenceSale | null> {
        throw new Error('Not implemented');
    }

    public async getEnhancedReferenceSale(): Promise<ReferenceSale | null> {
        throw new Error('Not implemented');
    }
}
