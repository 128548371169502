import * as React from 'react';

interface OwnProps {
    label: string;
    isRequired?: boolean;
    name: string;
    value?: boolean;
    macros?: string[];
    onChange: (e: boolean) => void;
    disabled?: boolean;
    maxLength?: number;
    className?: string;
}

export class Checkbox extends React.Component<OwnProps> {
    public render() {
        return (
            <div className={this.props.disabled ? 'form-group disabled' : 'form-group'}>
                <input
                    type="checkbox"
                    id={this.props.name}
                    name={this.props.name}
                    checked={this.props.value}
                    disabled={this.props.disabled}
                    onChange={(e) => this.props.onChange(e.target.checked)}
                />
                <label htmlFor={this.props.name}>
                    {this.props.label}
                    {this.props.isRequired ? <span className="form-label-highlight">*</span> : null}
                </label>
            </div>
        );
    }
}
