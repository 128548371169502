import {concat as observableConcat, interval as observableInterval, merge as observableMerge, Observable} from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import {ServerTime, ServerTimeInteractor} from './server_time_interactor';

export class BurstServerTimeInteractor implements ServerTimeInteractor {
    private _burstIntervalMs: number;
    private _initialBurstSize: number;
    private _burstSize: number;
    private _delegate: ServerTimeInteractor;

    private _burstN = 0;

    constructor(delegate: ServerTimeInteractor, initialBurstSize: number, burstSize: number, burstIntervalMs: number) {
        this._delegate = delegate;
        this._initialBurstSize = initialBurstSize;
        this._burstSize = burstSize;
        this._burstIntervalMs = burstIntervalMs;
    }

    public serverTimes(): Observable<ServerTime> {
        return observableMerge(
            this.burst(),
            observableInterval(this._burstIntervalMs).pipe(mergeMap(() => this.burst()))
        );
    }

    private burst(): Observable<ServerTime> {
        this._burstN++;
        return observableConcat(...this.range().map(() => this._delegate.serverTimes()));
    }

    private range(): number[] {
        const range = [];
        for (let i = 1; i <= this.getBurstSize(); i++) {
            range.push(i);
        }
        return range;
    }

    private getBurstSize() {
        if (this._burstN <= 1) {
            return this._initialBurstSize;
        }
        return this._burstSize;
    }
}
