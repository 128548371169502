import {
    ReferenceObjectProvider,
    ReferenceSaleSetData,
    ReferenceSaleSetRequestData,
} from '../../../../../../../business/reference_object_provider';

import {BehaviorSubject, EMPTY, Observable, filter, from, of, switchMap} from 'rxjs';
import {ReferenceSale} from '../v1/models/reference_sale';
import {ReferenceObjectAnswer} from '../v3/models/reference_object_answer';

export interface ReferenceObjectsAnswerEnhancementInteractor {
    storeLoadedSet(requestData: ReferenceSaleSetRequestData, data: ReferenceSaleSetData): void;

    streamForAnswer(answer: ReferenceObjectAnswer): Observable<ReferenceSale>;
}

export class DefaultReferenceObjectsAnswerEnhancementInteractor implements ReferenceObjectsAnswerEnhancementInteractor {
    private loadedSets = new BehaviorSubject<
        Map<string, {requestData: ReferenceSaleSetRequestData; data: ReferenceSaleSetData}>
    >(new Map());
    private cacheMap = new Map<string, Promise<ReferenceSale | null>>();

    constructor(private referenceObjectProvider: ReferenceObjectProvider) {}

    public storeLoadedSet(requestData: ReferenceSaleSetRequestData, data: ReferenceSaleSetData): void {
        const updatedMap = new Map(this.loadedSets.value);
        updatedMap.set(data.valuationTypeUuid, {
            requestData,
            data,
        });
        this.loadedSets.next(updatedMap);
    }

    public streamForAnswer(answer: ReferenceObjectAnswer): Observable<ReferenceSale> {
        return this.loadedSets.pipe(
            switchMap((sets) => {
                if (answer.valuationType === null) {
                    // Cannot enhance without a valuation type
                    return EMPTY;
                }

                const set = sets.get(answer.valuationType);
                if (set === undefined) {
                    // We return empty to not continue with the stream to wait for the set to be loaded
                    return EMPTY;
                }

                return of({
                    set,
                    referenceObject:
                        set.data.referenceSales.find(
                            (sale) => sale.id === answer.id && sale.source === answer.source
                        ) ?? null,
                });
            }),
            switchMap(({set, referenceObject}) => {
                if (referenceObject !== null) {
                    return of(referenceObject);
                } else {
                    return from(this.requestEnhancedReferenceSale(answer.id, answer.source, set.requestData));
                }
            }),
            filter((sale): sale is ReferenceSale => sale !== null)
        );
    }

    private requestEnhancedReferenceSale(
        id: string,
        source: string | null,
        requestData: ReferenceSaleSetRequestData
    ): Promise<ReferenceSale | null> {
        const key = `${id}-${source ?? ''}`;
        const valueInCache = this.cacheMap.get(key);

        if (valueInCache) {
            return valueInCache;
        }

        const promise = this.referenceObjectProvider.getEnhancedReferenceSale(id, source, requestData);

        this.cacheMap.set(key, promise);

        return promise;
    }
}
