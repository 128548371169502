import {observer} from 'mobx-react';
import React, {FunctionComponent} from 'react';
import {Modal} from '../../components/modal';
import {formatDate} from '../../../../support/format_date';
import {Loader} from '../../../../components/loader';
import {SearchAppraisal} from '../../../../../../models/search_appraisal';
import {SuperMacroValueMap} from '../../../../../../models/macro';

interface OwnProps {
    onClose: () => void;
    onSearchAppraisal: (query?: string, force?: boolean) => void;
    loadAppraisalValues: (appraisalId: number | SuperMacroValueMap) => void;
    isSearchingAppraisals: boolean;
    isAppraisalSearchPending: boolean;
    isLoadingAppraisalValues: boolean;
    searchAppraisals: SearchAppraisal[] | null;
    appraisalFillValues: SuperMacroValueMap[] | null;
}

export const SuperMacrosCopyModal: FunctionComponent<OwnProps> = observer((props) => {
    return (
        <Modal visible={true} onHide={props.onClose}>
            <div className="modal-header">
                <h2>Overnemen uit andere taxatie</h2>
            </div>
            <div className="modal-body">
                {props.isLoadingAppraisalValues ? (
                    <div className="empty-message mt-5">
                        <Loader />
                        <h3>Gegevens ophalen...</h3>
                    </div>
                ) : props.appraisalFillValues !== null ? (
                    <>
                        <ul
                            className="list-circles mt-2"
                            style={props.isAppraisalSearchPending ? {opacity: 0.6} : undefined}
                        >
                            {props.appraisalFillValues.map((values, i) => (
                                <li
                                    className="list-item ion-md-arrow-forward"
                                    key={i}
                                    onClick={() => props.loadAppraisalValues(values)}
                                >
                                    <span className="list-avatar ion-md-reorder" />
                                    <span className="list-title">
                                        <strong>{values.name}</strong>
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </>
                ) : (
                    <>
                        <div className="card-search">
                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder="Zoek op adres, postcode of plaats"
                                    className="form-control"
                                    onChange={(ev) => props.onSearchAppraisal(ev.target.value)}
                                    onKeyDown={(ev) =>
                                        ev.key === 'Enter' ? props.onSearchAppraisal(undefined, true) : null
                                    }
                                />
                            </div>
                            <button className="btn-search" onClick={() => props.onSearchAppraisal(undefined, true)}>
                                <span className="ion-md-search"></span>
                            </button>
                        </div>

                        {props.isSearchingAppraisals ? (
                            <div className="empty-message mt-5">
                                <Loader />
                                <h3>Zoeken naar taxaties...</h3>
                            </div>
                        ) : props.searchAppraisals !== null && props.searchAppraisals.length > 0 ? (
                            <>
                                <ul
                                    className="list-circles mt-2"
                                    style={props.isAppraisalSearchPending ? {opacity: 0.6} : undefined}
                                >
                                    {props.searchAppraisals.map((appraisal) => (
                                        <li
                                            className="list-item ion-md-arrow-forward"
                                            key={appraisal.id}
                                            onClick={() => props.loadAppraisalValues(appraisal.id)}
                                        >
                                            <span className="list-avatar">
                                                <img src={appraisal.streetviewUrl} />
                                            </span>
                                            <span className="list-title">
                                                <strong>
                                                    {appraisal.address} {appraisal.houseNumber} {appraisal.letter}
                                                </strong>
                                                <span className="thin">
                                                    - {appraisal.postalCode} {appraisal.city}
                                                </span>
                                            </span>
                                            <small className="list-details">
                                                <span>
                                                    Aangemaakt op {formatDate(appraisal.createdAt, 'dd-MM-yyyy')}
                                                </span>
                                            </small>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <div className="empty-message mt-5">
                                <h3>
                                    {props.searchAppraisals === null
                                        ? 'Voer een adres in om taxaties te zoeken'
                                        : 'Geen taxaties gevonden'}
                                </h3>
                                <span className="spacer-gradient"></span>
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="modal-footer">
                <button
                    disabled={props.isSearchingAppraisals || props.isLoadingAppraisalValues}
                    className="btn btn-secondary ion-md-close"
                    type="button"
                    onClick={() => props.onClose()}
                >
                    Sluiten
                </button>
            </div>
        </Modal>
    );
});
