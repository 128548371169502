import {LocalStorage} from './drivers/local_storage';
import {WindowLocalStorage} from './drivers/window_local_storage';

export interface PersistenceComponent {
    localStorage(): LocalStorage;
}

export class DefaultPersistenceComponent implements PersistenceComponent {
    private _localStorage?: WindowLocalStorage;

    public localStorage(): LocalStorage {
        if (this._localStorage === undefined) {
            this._localStorage = new WindowLocalStorage();
        }
        return this._localStorage;
    }
}
