import {ApiConditionGroup, apiConditionGroupToConditionGroup} from './api_condition_group';

import {MacroExternalType, MacroType, SuperMacro, SuperMacroItem, SuperMacroValueMap} from '../../models/macro';
import {ApiFile, apiFileToFile} from './api_file';

export interface ApiSuperMacro {
    id: number;
    is_user_defined: boolean;
    question_uuid: string;
    external_type: MacroExternalType;
    contents?: string | null;
    favorite?: boolean | null;
    condition_groups: ApiConditionGroup[];
    superMacroItems: ApiSuperMacroItem[];
}

export interface ApiSuperMacroItem {
    id: number;
    uuid: string;
    parent_uuid: string | null;
    question_uuid: string;
    iteration: string | null;
    contents: string | null;
    answer_option_uuid: string | null;
    file: ApiFile | null;
}

export function apiSuperMacroToSuperMacro(apiMacro: ApiSuperMacro): SuperMacro {
    return {
        id: apiMacro.id,
        type: MacroType.SUPER,
        isUserDefined: apiMacro.is_user_defined,
        questionUuid: apiMacro.question_uuid,
        externalType: apiMacro.external_type,
        contents: apiMacro.contents ?? null,
        favorite: apiMacro.favorite ?? false,
        conditionGroups: apiMacro.condition_groups.map((apiConditionGroup) =>
            apiConditionGroupToConditionGroup(apiConditionGroup)
        ),
        items: apiMacro.superMacroItems.map((child) => apiSuperMacroItemToSuperMacroItem(child)),
    };
}

export function apiSuperMacroItemToSuperMacroItem(apiItem: ApiSuperMacroItem): SuperMacroItem {
    return {
        id: apiItem.id,
        uuid: apiItem.uuid,
        parentUuid: apiItem.parent_uuid ?? null,
        questionUuid: apiItem.question_uuid,
        iteration: apiItem.iteration ?? null,
        contents: apiItem.contents ?? null,
        answerOptionUuid: apiItem.answer_option_uuid ?? null,
        file: apiItem.file ? apiFileToFile(apiItem.file) : null,
    };
}

export interface ApiSuperMacroValueMap {
    question_uuid: string;
    name: string | null;
    iteration_map: {[question_uuid: string]: string} | null;
    values: {
        uuid: string;
        question_uuid: string;
        parent_uuid: string | null;
        iteration: string | null;
        contents: string | null;
        answer_option_uuid: string | null;
        file: ApiFile | null;
    }[];
}

export function apiSuperMacroValueMapToSuperMacroValueMap(
    apiSuperMacroValueMap: ApiSuperMacroValueMap
): SuperMacroValueMap {
    return {
        questionUuid: apiSuperMacroValueMap.question_uuid,
        name: apiSuperMacroValueMap.name,
        iterationMap: apiSuperMacroValueMap.iteration_map,
        values: new Map(
            apiSuperMacroValueMap.values.map((value) => [
                value.uuid,
                {
                    questionUuid: value.question_uuid,
                    contents: value.contents ?? null,
                    answerOptionUuid: value.answer_option_uuid ?? null,
                    iteration: value.iteration ?? null,
                    parentUuid: value.parent_uuid ?? null,
                    file: value.file ? apiFileToFile(value.file) : null,
                },
            ])
        ),
    };
}
