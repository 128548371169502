import * as React from 'react';

import {Appraisal} from '../../../../../../../../../../../models/appraisal';
import {FloorAreaBadgeAdapter} from '../../../badges/floor_area_badge_adapter';
import {FunctionComponent} from 'react';
import {NullableNumberInput} from '../../../inputs/nullable_number';
import {QuestionSet} from '../../../../../../../../../../../models/question_set';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';
import {VolumeBadgeAdapter} from '../../../badges/volume_badge_adapter';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    gebruiksoppervlakteWonen: number | null;
    brutoInhoud: number | null;
    referenceObjectAnswer: ReferenceObjectAnswer;
    isFrozen: boolean;
    onGebruiksOppervlakteChange: (value: number | null) => void;
    onBrutoInhoudChange: (value: number | null) => void;
}

export const Volumes: FunctionComponent<OwnProps> = ({
    appraisal,
    questionSet,
    brutoInhoud,
    gebruiksoppervlakteWonen,
    referenceObjectAnswer,
    isFrozen,
    onGebruiksOppervlakteChange,
    onBrutoInhoudChange,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-12">
                <dl>
                    <span className="row">
                        <span className="col-sm-6">
                            <NullableNumberInput
                                isRequired
                                name="gebruiksoppervlakte-wonen"
                                label="Gebruiksoppervlakte wonen (m²)"
                                badge={
                                    gebruiksoppervlakteWonen !== null &&
                                    validationInstituteReferentieObject.gebruiksOppervlakte !== null ? (
                                        <FloorAreaBadgeAdapter
                                            floorArea={validationInstituteReferentieObject.gebruiksOppervlakte}
                                            gebruiksoppervlakteWonen={gebruiksoppervlakteWonen}
                                            appraisal={appraisal}
                                            questionSet={questionSet}
                                        />
                                    ) : null
                                }
                                value={validationInstituteReferentieObject.gebruiksOppervlakte}
                                onChange={onGebruiksOppervlakteChange}
                                disabled={isFrozen}
                            />
                        </span>
                        <span className="col-sm-6">
                            <NullableNumberInput
                                name="inhoud"
                                label="Inhoud (m³)"
                                value={validationInstituteReferentieObject.inhoud}
                                badge={
                                    brutoInhoud !== null && validationInstituteReferentieObject.inhoud !== null ? (
                                        <VolumeBadgeAdapter
                                            volume={validationInstituteReferentieObject.inhoud}
                                            brutoInhoud={brutoInhoud}
                                            appraisal={appraisal}
                                            questionSet={questionSet}
                                        />
                                    ) : null
                                }
                                onChange={onBrutoInhoudChange}
                                disabled={isFrozen}
                            />
                        </span>
                    </span>
                </dl>
            </div>
        </div>
    );
};
