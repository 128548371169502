import * as React from 'react';
import {createRoot} from 'react-dom/client';

import {ObjectOwnershipType} from './object_ownership_type/object_ownership_type';

const container = document.getElementById('object-ownership-type-container');
if (container !== undefined && container !== null) {
    const isInvalid = container.getAttribute('data-is-invalid') === 'true';
    const isDisabled = container.getAttribute('data-is-disabled') === 'true';
    const oldValue = container.getAttribute('data-old-value');

    const root = createRoot(container);
    root.render(<ObjectOwnershipType invalid={isInvalid} disabled={isDisabled} initialValue={oldValue} />);
}
