import {Observable} from 'rxjs';
import {QuestionSet} from '../models/question_set';
import {AnswerController} from './answering/answer_controller';
import {QuestionEffectInteractor} from './conditions/question_effects_interactor';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {isEmpty} from '../../support/util';
import {GroupType} from '../enum/group_type';
import {Answer} from '../models/answer';
import {TechnicalReference} from '../enum/technical_reference';

export interface ConstructionCostsProvider {
    stream(): Observable<number | null>;
    get(): number | null;
}

export class DefaultConstructionCostsProvider implements ConstructionCostsProvider {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor
    ) {}

    public stream(): Observable<number | null> {
        const iteratorQuestions = [
            ...this.questionSet.findQuestionsByTechnicalReference(TechnicalReference.PLANNED_CONSTRUCTION_ITERATOR),
            ...this.questionSet.findQuestionsByTechnicalReference(TechnicalReference.PLANNED_RENOVATION_ITERATOR),
        ];
        const childrenQuestions = iteratorQuestions
            .map((iq) => this.questionSet.flattenChildrenRecursively(iq.uuid))
            .flat();
        const allQuestions = [...iteratorQuestions, ...childrenQuestions];

        const constructionCostsQuestions = this.questionSet.findQuestionsByGroupType(GroupType.CONSTRUCTION_COST);

        return this.answerController.answersForQuestionUuidsStream(allQuestions.map((question) => question.uuid)).pipe(
            switchMap(() =>
                this.answerController.answersForQuestionUuidsStream(constructionCostsQuestions.map((q) => q.uuid)).pipe(
                    map((answers) => this.answerController.filterDeleted(answers)),
                    map((answers) =>
                        answers.filter(
                            (answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid)
                        )
                    ),
                    map((answers) => {
                        return answers.reduce((sum: number, answer: Answer) => {
                            if (answer.contents === null || isEmpty(answer.contents)) {
                                return sum;
                            }
                            return sum + parseInt(answer.contents, 10);
                        }, 0);
                    }),
                    distinctUntilChanged()
                )
            ),
            distinctUntilChanged()
        );
    }

    public get(): number | null {
        const qs = this.questionSet.findQuestionsByGroupType(GroupType.CONSTRUCTION_COST);

        return this.answerController
            .filterDeleted(this.answerController.answersForQuestionUuids(qs.map((question) => question.uuid)))
            .filter((answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid))
            .reduce((sum: number, answer: Answer) => {
                if (answer.contents === null || isEmpty(answer.contents)) {
                    return sum;
                }
                return sum + parseInt(answer.contents, 10);
            }, 0);
    }
}
