import {AppraiseModel, isAppraiseModelOrNewer} from '../../../../../../../../../enum/appraise_model';
import {ExplanationBuildYearGenerator} from './explanation_build_year_generator';
import {ExplanationBuildingTypeGenerator} from './explanation_building_type_generator';
import {ExplanationBuildingsGenerator} from './explanation_buildings_generator';
import {ExplanationData} from './explanation_data';
import {ExplanationDataProvider} from './explanation_data_provider';
import {ExplanationFloorAreaGenerator} from './explanation_floor_area_generator';
import {ExplanationPlotAreaGenerator} from './explanation_plot_area_generator';
import {ExplanationVolumeGenerator} from './explanation_volume_generator';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {isEmpty} from '../../../../../../../../../../support/util';

export enum DescriptionType {
    WONINGTYPE = 'woningtype',
    BOUWJAAR = 'bouwjaar',
    GEBRUIKSOPP = 'gebruiksoppervlakte',
    PERCEELOPP = 'perceeloppervlakte',
    INHOUD = 'inhoud',
    LIGGING = 'ligging',
    AANBOUW = 'aanbouw',
    LUXE_DOELMATIGHEID = 'luxedoelmatigheid',
    LUXE = 'luxe',
    DOELMATIGHEID = 'doelmatigheid',
    ONDERHOUD = 'onderhoud',
    KWALITEIT = 'kwaliteit',
}

export function getAllDescriptionTypes(explanationData: ExplanationData): DescriptionType[] {
    const types = [
        DescriptionType.WONINGTYPE,
        DescriptionType.BOUWJAAR,
        DescriptionType.GEBRUIKSOPP,
        DescriptionType.INHOUD,
        DescriptionType.LIGGING,
        DescriptionType.AANBOUW,
        DescriptionType.ONDERHOUD,
    ];

    if (isAppraiseModelOrNewer(explanationData.appraisal.appraiseModel, AppraiseModel.MODEL2021)) {
        types.push(DescriptionType.LUXE);
        types.push(DescriptionType.DOELMATIGHEID);
    } else {
        types.push(DescriptionType.LUXE_DOELMATIGHEID);
        types.push(DescriptionType.KWALITEIT);
    }

    if (explanationData.object.plotArea !== null && explanationData.object.plotArea > 0) {
        types.push(DescriptionType.PERCEELOPP);
    }

    return types;
}

export function getDescriptionForDescriptionType(type: DescriptionType): string | null {
    switch (type) {
        case DescriptionType.WONINGTYPE:
            return 'Woningtype';
        case DescriptionType.BOUWJAAR:
            return 'Bouwjaar';
        case DescriptionType.GEBRUIKSOPP:
            return 'Gebruiksoppervlakte';
        case DescriptionType.INHOUD:
            return 'Inhoud';
        case DescriptionType.PERCEELOPP:
            return 'Perceeloppervlakte';
        case DescriptionType.AANBOUW:
            return 'Bij-, op- en aanbouwen';
        case DescriptionType.LIGGING:
            return 'Ligging';
        case DescriptionType.ONDERHOUD:
            return 'Onderhoudsstatus';
        case DescriptionType.LUXE_DOELMATIGHEID:
            return 'Luxe en doelmatigheid';
        case DescriptionType.LUXE:
            return 'Luxe';
        case DescriptionType.DOELMATIGHEID:
            return 'Doelmatigheid';
        case DescriptionType.KWALITEIT:
            return 'Kwaliteit';
        default:
            return null;
    }
}

export class ExplanationAppraiserGenerator {
    constructor(
        private explanationDataProvider: ExplanationDataProvider,
        private explanationBuildYearGenerator: ExplanationBuildYearGenerator,
        private explanationFloorAreaGenerator: ExplanationFloorAreaGenerator,
        private explanationPlotAreaGenerator: ExplanationPlotAreaGenerator,
        private explanationVolumeGenerator: ExplanationVolumeGenerator,
        private explanationBuildingsGenerator: ExplanationBuildingsGenerator,
        private explanationBuildingTypeGenerator: ExplanationBuildingTypeGenerator
    ) {}

    public generate(referenceObjectAnswer: ReferenceObjectAnswer): string {
        const explanationData = this.explanationDataProvider.get();
        const result: Array<string | null> = [];

        // Gather all description fields with "Vergelijkbaar" value
        const equalValues = this.getDescriptionTypesForStatus('Vergelijkbaar', referenceObjectAnswer, explanationData);

        // Gather all description fields with another value then "Vergelijkbaar"
        const otherValues = getAllDescriptionTypes(explanationData).filter((descriptionItem: DescriptionType) => {
            return !equalValues.includes(descriptionItem);
        });

        // Example: Vergelijkbaar op basis van <gelijke kenmerken>.
        result.push(this.getFirstSentence(equalValues));

        // Example:  Deze woning heeft <verschillen in waarden>.
        result.push(this.getSecondSentence(otherValues, referenceObjectAnswer, explanationData));

        // Example:  De onderhoudsstaat is <waarde>, <toelichting>
        result.push(this.getThirdSentence(equalValues, referenceObjectAnswer));

        // Example:  De mate van luxe en doelmatigheid is <waarde>, <toelichting>
        result.push(this.getFourthSentence(explanationData, referenceObjectAnswer));

        // Example:  De ligging is <waarde>, <toelichting>
        result.push(this.getFifthSentence(equalValues, referenceObjectAnswer));

        return result
            .filter((part) => part !== null)
            .join(' ')
            .replace(/(.{997})..+/, '$1…')
            .replace(/\.\./g, '.');
    }

    // Example: Vergelijkbaar op basis van <gelijke kenmerken>.
    private getFirstSentence(descriptionTypesWithEqualStatus: DescriptionType[]): string | null {
        if (descriptionTypesWithEqualStatus.length === 0) {
            return null;
        }
        const result: string[] = [];
        result.push(`vergelijkbaar op basis van `);
        descriptionTypesWithEqualStatus.forEach((descriptionItem: DescriptionType, key) => {
            const descriptionText = getDescriptionForDescriptionType(descriptionItem);
            if (descriptionText !== null) {
                result.push(this.lowercaseFirstChar(descriptionText));
                if (key === descriptionTypesWithEqualStatus.length - 2) {
                    result.push(` en `);
                } else if (key !== descriptionTypesWithEqualStatus.length - 1) {
                    result.push(`, `);
                }
            }
        });

        return this.uppercaseFirstChar(result.join('') + '.');
    }

    // Example:  De <verschillen in waarden>.
    private getSecondSentence(
        descriptionTypesWithDifferentStatus: DescriptionType[],
        referenceObjectAnswer: ReferenceObjectAnswer,
        explanationData: ExplanationData
    ): string | null {
        // Create list with supported description types for this sentence
        const typesForSentence: DescriptionType[] = [];
        typesForSentence.push(DescriptionType.AANBOUW);
        if (explanationData.object.buildYear !== null) {
            typesForSentence.push(DescriptionType.BOUWJAAR);
        }
        if (explanationData.object.objectType !== null) {
            typesForSentence.push(DescriptionType.WONINGTYPE);
        }
        if (explanationData.object.floorArea !== null && explanationData.object.floorArea > 0) {
            typesForSentence.push(DescriptionType.GEBRUIKSOPP);
        }
        if (explanationData.object.plotArea !== null && explanationData.object.plotArea > 0) {
            typesForSentence.push(DescriptionType.PERCEELOPP);
        }
        if (explanationData.object.volume !== null && explanationData.object.volume > 0) {
            typesForSentence.push(DescriptionType.INHOUD);
        }

        // Filter the description types needed
        descriptionTypesWithDifferentStatus = descriptionTypesWithDifferentStatus.filter(
            (descriptionItem: DescriptionType) => {
                return typesForSentence.includes(descriptionItem);
            }
        );

        if (descriptionTypesWithDifferentStatus.length === 0) {
            return null;
        }

        const result: string[] = [];
        result.push('deze woning ');

        // Fill the sentence
        descriptionTypesWithDifferentStatus.forEach((descriptionItem: DescriptionType, key) => {
            switch (descriptionItem) {
                case DescriptionType.WONINGTYPE:
                    result.push(
                        this.explanationBuildingTypeGenerator.generateDetailsSentence(
                            explanationData,
                            referenceObjectAnswer
                        )
                    );
                    break;
                case DescriptionType.BOUWJAAR:
                    result.push(
                        `is ${this.explanationBuildYearGenerator.generateDetailsSentence(
                            explanationData,
                            referenceObjectAnswer
                        )}`
                    );
                    break;
                case DescriptionType.GEBRUIKSOPP:
                    result.push(
                        `heeft ${this.explanationFloorAreaGenerator.generateDetailsSentence(
                            explanationData,
                            referenceObjectAnswer
                        )}`
                    );
                    break;
                case DescriptionType.PERCEELOPP:
                    result.push(
                        `heeft ${this.explanationPlotAreaGenerator.generateDetailsSentence(
                            explanationData,
                            referenceObjectAnswer
                        )}`
                    );
                    break;
                case DescriptionType.INHOUD:
                    result.push(
                        `heeft ${this.explanationVolumeGenerator.generateDetailsSentence(
                            explanationData,
                            referenceObjectAnswer
                        )}`
                    );
                    break;
                case DescriptionType.AANBOUW:
                    result.push(
                        `heeft ${this.explanationBuildingsGenerator.generateDetailsSentence(referenceObjectAnswer)}`
                    );
                    break;
                default:
                    break;
            }
            if (key === descriptionTypesWithDifferentStatus.length - 2) {
                result.push(` en `);
            } else if (key !== descriptionTypesWithDifferentStatus.length - 1) {
                result.push(`, `);
            }
        });

        return this.uppercaseFirstChar(result.join('') + '.');
    }

    // Example:  De onderhoudsstaat is <waarde>, <toelichting>
    private getThirdSentence(
        descriptionTypesWithEqualStatus: DescriptionType[],
        referenceObjectAnswer: ReferenceObjectAnswer
    ): string | null {
        if (descriptionTypesWithEqualStatus.indexOf(DescriptionType.ONDERHOUD) > -1) {
            return null;
        }

        const result: string[] = [];
        result.push(
            `de onderhoudsstaat is ${this.formatStatus(referenceObjectAnswer.referenceObject.onderhoudsSituatieStatus)}`
        );
        if (
            !isEmpty(referenceObjectAnswer.referenceObject.onderhoudsSituatieUitleg) &&
            referenceObjectAnswer.referenceObject.onderhoudsSituatieStatus.toLocaleLowerCase() !== 'vergelijkbaar'
        ) {
            result.push(`, ${referenceObjectAnswer.referenceObject.onderhoudsSituatieUitleg.toLocaleLowerCase()}`);
        }

        return result.length === 0 ? null : this.uppercaseFirstChar(result.join('') + '.');
    }

    // Example:  De mate van luxe en doelmatigheid is <waarde>, <toelichting>
    private getFourthSentence(
        explanationData: ExplanationData,
        referenceObjectAnswer: ReferenceObjectAnswer
    ): string | null {
        const result: string[] = [];
        if (
            explanationData.appraisal.appraiseModel === AppraiseModel.MODEL2018 &&
            referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus !== undefined &&
            !isEmpty(referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus) &&
            referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus.toLocaleLowerCase() !== 'vergelijkbaar'
        ) {
            result.push(
                `De mate van luxe en doelmatigheid is ${this.formatStatus(
                    referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus
                )}`
            );
            if (
                referenceObjectAnswer.referenceObject.luxeDoelmatigheidUitleg !== undefined &&
                !isEmpty(referenceObjectAnswer.referenceObject.luxeDoelmatigheidUitleg)
            ) {
                result.push(`, ${referenceObjectAnswer.referenceObject.luxeDoelmatigheidUitleg.toLocaleLowerCase()}`);
            }
            return result.length === 0 ? null : this.uppercaseFirstChar(result.join('') + '.');
        } else {
            if (
                isAppraiseModelOrNewer(explanationData.appraisal.appraiseModel, AppraiseModel.MODEL2021) &&
                referenceObjectAnswer.referenceObject.mateVanLuxeStatus !== undefined &&
                !isEmpty(referenceObjectAnswer.referenceObject.mateVanLuxeStatus) &&
                referenceObjectAnswer.referenceObject.mateVanLuxeStatus.toLocaleLowerCase() !== 'vergelijkbaar'
            ) {
                result.push(
                    `De mate van luxe is ${this.formatStatus(referenceObjectAnswer.referenceObject.mateVanLuxeStatus)}`
                );
                if (
                    referenceObjectAnswer.referenceObject.mateVanLuxeUitleg !== undefined &&
                    !isEmpty(referenceObjectAnswer.referenceObject.mateVanLuxeUitleg)
                ) {
                    result.push(`, ${referenceObjectAnswer.referenceObject.mateVanLuxeUitleg.toLocaleLowerCase()}`);
                }
                result.push(`. `);
            }
            if (
                isAppraiseModelOrNewer(explanationData.appraisal.appraiseModel, AppraiseModel.MODEL2021) &&
                referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus !== undefined &&
                !isEmpty(referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus) &&
                referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus.toLocaleLowerCase() !== 'vergelijkbaar'
            ) {
                result.push(
                    `De mate van doelmatigheid is ${this.formatStatus(
                        referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus
                    )}`
                );
                if (
                    referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg !== undefined &&
                    !isEmpty(referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg)
                ) {
                    result.push(
                        `, ${referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg.toLocaleLowerCase()}`
                    );
                }
                result.push(`.`);
            }
        }

        return result.length === 0 ? null : this.uppercaseFirstChar(result.join(''));
    }

    // Example:  De ligging is <waarde>, <toelichting>
    private getFifthSentence(
        descriptionTypesWithEqualStatus: DescriptionType[],
        referenceObjectAnswer: ReferenceObjectAnswer
    ): string | null {
        if (descriptionTypesWithEqualStatus.indexOf(DescriptionType.LIGGING) > -1) {
            return null;
        }
        const result: string[] = [];
        result.push(`de ligging is ${this.formatStatus(referenceObjectAnswer.referenceObject.liggingStatus)}`);
        if (
            !isEmpty(referenceObjectAnswer.referenceObject.liggingUitleg) &&
            referenceObjectAnswer.referenceObject.liggingStatus.toLocaleLowerCase() !== 'vergelijkbaar'
        ) {
            result.push(`, ${referenceObjectAnswer.referenceObject.liggingUitleg.toLocaleLowerCase()}`);
        }

        return result.length === 0 ? null : this.uppercaseFirstChar(result.join('') + '.');
    }

    private getDescriptionTypesForStatus(
        status: string,
        referenceObjectAnswer: ReferenceObjectAnswer,
        explanationData: ExplanationData
    ): DescriptionType[] {
        const result: DescriptionType[] = [];
        getAllDescriptionTypes(explanationData).forEach((descriptionItem: DescriptionType) => {
            switch (descriptionItem) {
                case DescriptionType.WONINGTYPE:
                case DescriptionType.ONDERHOUD:
                case DescriptionType.LUXE_DOELMATIGHEID:
                case DescriptionType.DOELMATIGHEID:
                case DescriptionType.LUXE:
                case DescriptionType.LIGGING:
                case DescriptionType.AANBOUW: {
                    const simpleStatus = this.getSimpleStatus(descriptionItem, referenceObjectAnswer);
                    if (simpleStatus !== null && simpleStatus.toLocaleLowerCase() === status.toLocaleLowerCase()) {
                        result.push(descriptionItem);
                    }
                    break;
                }
                case DescriptionType.BOUWJAAR: {
                    if (
                        this.explanationBuildYearGenerator
                            .generateBuildyearStatus(referenceObjectAnswer, explanationData)
                            .toLocaleLowerCase() === status.toLocaleLowerCase()
                    ) {
                        result.push(descriptionItem);
                    }
                    break;
                }
                case DescriptionType.GEBRUIKSOPP: {
                    if (
                        this.explanationFloorAreaGenerator
                            .generateFloorAreaStatus(referenceObjectAnswer, explanationData)
                            .toLocaleLowerCase() === status.toLocaleLowerCase()
                    ) {
                        result.push(descriptionItem);
                    }
                    break;
                }
                case DescriptionType.PERCEELOPP: {
                    if (
                        explanationData.object.plotArea !== null &&
                        explanationData.object.plotArea > 0 &&
                        this.explanationPlotAreaGenerator
                            .generatePlotAreaStatus(referenceObjectAnswer, explanationData)
                            .toLocaleLowerCase() === status.toLocaleLowerCase()
                    ) {
                        result.push(descriptionItem);
                    }
                    break;
                }
                case DescriptionType.INHOUD: {
                    if (
                        this.explanationVolumeGenerator
                            .generateVolumeStatus(referenceObjectAnswer, explanationData)
                            .toLocaleLowerCase() === status.toLocaleLowerCase()
                    ) {
                        result.push(descriptionItem);
                    }
                    break;
                }
                default:
                    break;
            }
        });
        return result;
    }

    private uppercaseFirstChar(value: string): string {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    private lowercaseFirstChar(value: string): string {
        return value.charAt(0).toLowerCase() + value.slice(1);
    }

    private getSimpleStatus(
        descriptionItem: DescriptionType,
        referenceObjectAnswer: ReferenceObjectAnswer
    ): string | null {
        switch (descriptionItem) {
            case DescriptionType.WONINGTYPE:
                return referenceObjectAnswer.referenceObject.woningTypeStatus;
            case DescriptionType.LUXE_DOELMATIGHEID:
                return referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus ?? null;
            case DescriptionType.LUXE:
                return referenceObjectAnswer.referenceObject.mateVanLuxeStatus ?? null;
            case DescriptionType.DOELMATIGHEID:
                return referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus ?? null;
            case DescriptionType.LIGGING:
                return referenceObjectAnswer.referenceObject.liggingStatus;
            case DescriptionType.KWALITEIT:
                return referenceObjectAnswer.referenceObject.kwaliteitConditieStatus ?? null;
            case DescriptionType.AANBOUW:
                return referenceObjectAnswer.referenceObject.aanbouwStatus;
            case DescriptionType.ONDERHOUD:
                return referenceObjectAnswer.referenceObject.onderhoudsSituatieStatus;
            default:
                return null;
        }
    }

    private formatStatus(status: string): string {
        switch (status.toLocaleLowerCase()) {
            case 'veel minder':
                return 'veel slechter';
            case 'minder':
                return 'slechter';
            default:
                return status.toLocaleLowerCase();
        }
    }
}
