import * as React from 'react';

import {detect} from 'detect-browser';

interface OwnProps {
    children?: React.ReactNode;
}

export class CompatibilityCheck extends React.Component<OwnProps> {
    private browser = detect();

    private hasFancyMethods() {
        return typeof Promise !== 'undefined' && Promise.toString().indexOf('[native code]') !== -1;
    }

    private isSupported(): boolean {
        switch (this.browser?.name) {
            case 'chrome':
            case 'firefox':
            case 'safari':
            case 'edge':
            case 'edge-chromium':
            case 'ios':
            case 'crios':
            case 'opera':
                return this.hasFancyMethods();
            case 'ie':
                return false;
            default:
                return false;
        }
    }

    private isOldSafari(): boolean {
        //https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/58065241#58065241 maxTouchPoints is more than 2 for ipads, ipads tell the browser they are running macOS
        const isIpad = navigator.maxTouchPoints > 2;
        if (
            this.browser &&
            this.browser.name === 'safari' &&
            this.browser.os === 'Mac OS' &&
            this.browser.version &&
            !isIpad
        ) {
            return Number(this.browser.version.split('.')[0]) < 17;
        } else {
            return false;
        }
    }

    public render() {
        if (this.isOldSafari()) {
            return (
                <div className="empty-message appraise">
                    <h3>Je browser wordt niet ondersteund door taXapi</h3>
                    <span className="spacer-gradient">&nbsp;</span>
                    <p>
                        We maken gebruik van de laatste technologieën en veiligheidsvoorwaarden, zorg ervoor dat je
                        browser up-to-date is of installeer een moderne browser. Safari 17 of hoger wordt ondersteund.
                        Klik{' '}
                        <a target="_blank" href="https://support.apple.com/nl-nl/102665" rel="noreferrer">
                            hier
                        </a>{' '}
                        voor meer informatie.
                        <br />
                        Wordt uw Mac niet meer ondersteund? Installeer dan Google Chrome (
                        <a target="_blank" href="https://www.google.com/intl/nl/chrome/" rel="noreferrer">
                            download hier
                        </a>
                        )
                    </p>
                </div>
            );
        } else if (!this.isSupported()) {
            return (
                <div className="empty-message appraise">
                    <h3>Je browser wordt niet ondersteund door taXapi</h3>
                    <span className="spacer-gradient">&nbsp;</span>
                    <p>
                        We maken gebruik van de laatste technologieën en veiligheidsvoorwaarden, zorg ervoor dat je
                        browser up-to-date is of installeer een moderne browser.
                    </p>
                </div>
            );
        }
        return this.props.children;
    }
}
