import {Presenter} from '../../../support/presenter/presenter';
import {SidebarItem, SidebarTreeBuilder} from '../../business/sidebar_tree_builder';
import {first} from 'rxjs/operators';
import {observable, makeObservable} from 'mobx';
import {AnswerInteractor} from '../../business/answering/answer_interactor';

export class BootPresenter implements Presenter {
    @observable public sidebar: SidebarItem[] | null = null;

    constructor(private sidebarTreeBuilder: SidebarTreeBuilder, private answerInteractor: AnswerInteractor) {
        makeObservable(this);
    }

    public async mount(): Promise<void> {
        this.sidebar = (await this.sidebarTreeBuilder.build.pipe(first()).toPromise()) ?? null;

        await this.answerInteractor.submit();
    }

    public unmount(): void {
        /* Noop */
    }
}
