import * as Uuid from 'uuid';

import {action, autorun, computed, makeObservable, observable, runInAction} from 'mobx';

import {AppraiseSecondaryConfigStackInteractor} from '../../../../../business/appraise_secondary_config_stack_interactor';
import {AppraiseSecondaryType} from '../../../../../models/appraise_secondary_config';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {ConstructionDefectsWidgetContext} from '../../../appraise_secondary/construction_defects/construction_defects_widget_context';
import {ConstructionDefectsWidgetVisibility} from '../../../appraise_secondary/construction_defects/construction_defects_widget_visibility';
import {GlobalProvider} from '../../../../../../business/global_provider';
import {ModalConfigStackInteractor} from '../../../../../business/modal_config_stack_interactor';
import {ModalType} from '../../../../../models/modal_config';
import {SimpleQuestionPresenter} from '../simple/simple_question_presenter';
import {ValidationError} from '../../../../../models/validation_error';
import {ValidationInstituteConstructionalDefect} from '../../../../../models/validation_institute_constructional_defect';
import {ValidationMessageMap} from '../../../../../business/validation/validation_message';
import {first} from 'rxjs/operators';
import {isCompact} from '../../../../../../support/check_mobile';
import {isEmpty} from '../../../../../../support/util';

export class BuildingCostsPresenter extends SimpleQuestionPresenter {
    @observable.ref public widgetVisible: ConstructionDefectsWidgetVisibility = {
        visible: false,
        context: ConstructionDefectsWidgetContext.REQUEST_DEFECT_WIDGET,
    };

    public get buildingInspectorEnabled(): boolean {
        return this.globalProvider.global.buildingInspectorSubscription !== false;
    }

    public get shouldPulseBuildingInspectorButton(): boolean {
        return this.globalProvider.global.externalAdviceCount !== null
            ? this.globalProvider.global.externalAdviceCount <= 2
            : false;
    }

    public changeConstructionCostsWidgetContext(context: ConstructionDefectsWidgetContext) {
        this.widgetVisible = {
            visible: this.widgetVisible.visible,
            context: context,
        };
    }

    private subscriptions = new CompositeSubscription();
    private secondaryWidgetId = `building-cost-iterator-secondary-widget[${Uuid.v4()}]`;

    constructor(
        private validationErrors: ValidationError[],
        private validationMessagesMap: ValidationMessageMap,
        private modalConfigStackInteractor: ModalConfigStackInteractor,
        private appraiseSecondaryConfigStackInteractor: AppraiseSecondaryConfigStackInteractor,
        private globalProvider: GlobalProvider,
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public mount(): void {
        super.mount();
        if (this.parentAnswerUuid !== undefined) {
            this.subscriptions.add(
                this.answerController
                    .answerByIdentifiersStream(
                        this.question.uuid,
                        this.parentAnswerUuid ?? null,
                        this.iteration ?? null
                    )
                    .pipe(first())
                    .subscribe((answer) => {
                        try {
                            // Find Uuid v4 from URL
                            const search = window.location.href.match('open=\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12}') ?? [];
                            const openUuid = search[0]?.replace('open=', '');
                            if (
                                openUuid === this.question.uuid ||
                                openUuid === answer.uuid ||
                                openUuid === answer.parentUuid
                            ) {
                                runInAction(() => {
                                    this.widgetVisible = {
                                        visible: true,
                                        context: this.widgetVisible.context,
                                    };
                                });
                            }
                        } catch (error) {
                            console.warn(error);
                        }
                    })
            );
        }

        this.subscriptions.add(
            autorun(() => {
                if (this.widgetVisible.visible && this.answer !== undefined && this.parentAnswerUuid !== undefined) {
                    if (isCompact()) {
                        this.modalConfigStackInteractor.remove((c) => {
                            return c.type === ModalType.DEFECTS && c.id !== this.secondaryWidgetId;
                        });

                        this.modalConfigStackInteractor.upsert({
                            id: this.secondaryWidgetId,
                            type: ModalType.DEFECTS,
                            appraisal: this.appraisal,
                            questionSet: this.questionSet,
                            onClose: () => {
                                runInAction(() => {
                                    this.widgetVisible = {
                                        visible: false,
                                        context: this.widgetVisible.context,
                                    };
                                });
                            },
                            question: this.question,
                            parentAnswerUuid: this.parentAnswerUuid,
                            validationErrors: this.validationErrors,
                            validationMessages: this.validationMessagesMap,
                            forceShowValidationMessages: false,
                            constructionCostsWidgetContext: this.widgetVisible.context,
                            onChangeConstructionCostsWidgetContext: (c: ConstructionDefectsWidgetContext) => {
                                this.changeConstructionCostsWidgetContext(c);
                            },
                            pagePartsSet: null,
                            activePagePart: null,
                            questionRenderingData: this.questionRenderingData,
                        });
                    } else {
                        this.appraiseSecondaryConfigStackInteractor.remove((c) => {
                            return c.type === AppraiseSecondaryType.DEFECTS && c.id !== this.secondaryWidgetId;
                        });

                        this.appraiseSecondaryConfigStackInteractor.upsert({
                            id: this.secondaryWidgetId,
                            type: AppraiseSecondaryType.DEFECTS,
                            appraisal: this.appraisal,
                            questionSet: this.questionSet,
                            onClose: () => {
                                runInAction(() => {
                                    this.widgetVisible = {
                                        visible: false,
                                        context: this.widgetVisible.context,
                                    };
                                });
                            },
                            question: this.question,
                            parentAnswerUuid: this.parentAnswerUuid,
                            validationErrors: this.validationErrors,
                            validationMessages: this.validationMessagesMap,
                            forceShowValidationMessages: false,
                            constructionCostsWidgetContext: this.widgetVisible.context,
                            onChangeConstructionCostsWidgetContext: (c: ConstructionDefectsWidgetContext) => {
                                this.changeConstructionCostsWidgetContext(c);
                            },
                            pagePartsSet: null,
                            activePagePart: null,
                            questionRenderingData: this.questionRenderingData,
                        });
                    }
                } else {
                    this.appraiseSecondaryConfigStackInteractor.remove((c) => c.id === this.secondaryWidgetId);
                    this.modalConfigStackInteractor.remove((c) => c.id === this.secondaryWidgetId);
                }
            })
        );
    }

    public unmount(): void {
        super.unmount();

        this.appraiseSecondaryConfigStackInteractor.remove((c) => c.id === this.secondaryWidgetId);
        this.subscriptions.clear();
    }

    @action
    public toggleCostsWidgetContainer() {
        // Not opened with the same context, so leave it open
        if (this.widgetVisible.context !== ConstructionDefectsWidgetContext.REQUEST_DEFECT_WIDGET) {
            this.widgetVisible = {
                visible: true,
                context: ConstructionDefectsWidgetContext.REQUEST_DEFECT_WIDGET,
            };
        } else {
            this.widgetVisible = {
                visible: !this.widgetVisible.visible,
                context: ConstructionDefectsWidgetContext.REQUEST_DEFECT_WIDGET,
            };
        }
    }

    @action
    public toggleRequestWidgetContainer() {
        // Not opened with the same context, so leave it open
        if (this.widgetVisible.context !== ConstructionDefectsWidgetContext.NEW_REQUEST) {
            this.widgetVisible = {
                visible: true,
                context: ConstructionDefectsWidgetContext.NEW_REQUEST,
            };
        } else {
            this.widgetVisible = {
                visible: !this.widgetVisible.visible,
                context: ConstructionDefectsWidgetContext.NEW_REQUEST,
            };
        }
    }

    @action
    public toggleNotesWidgetContainer() {
        // Not opened with the same context, so leave it open
        if (this.widgetVisible.context !== ConstructionDefectsWidgetContext.NOTES) {
            this.widgetVisible = {
                visible: true,
                context: ConstructionDefectsWidgetContext.NOTES,
            };
        } else {
            this.widgetVisible = {
                visible: !this.widgetVisible.visible,
                context: ConstructionDefectsWidgetContext.NOTES,
            };
        }
    }

    @computed
    public get validationInstituteConstructionalDefect(): ValidationInstituteConstructionalDefect | null {
        if (this.answer !== undefined && this.answer.contents !== null && !isEmpty(this.answer.contents)) {
            return JSON.parse(this.answer.contents);
        }
        return null;
    }
}
