import {AnswerController} from '../answering/answer_controller';
import {AnswerValidatorAdapter} from './answer_validator_adapter';
import {QuestionEffectInteractor} from '../conditions/question_effects_interactor';
import {QuestionSet} from '../../models/question_set';
import {QuestionValidator} from './question_validator';
import {ValidationMessage} from './validation_message';
import {findAllChildrenForQuestionUuid} from '../../appraise/ui/support/question_filtering';

export interface ChildQuestionValidator {
    /**
     * For a certain answer/question combination get all validation messages of lower tree
     * @param questionUuid
     * @param answerUuid
     */
    validate(questionUuid: string, answerUuid: string | null, blacklistedQuestionUuids?: string[]): ValidationMessage[];
}

export class DefaultChildValidator implements ChildQuestionValidator {
    constructor(
        private questionSet: QuestionSet,
        private answerValidatorDelegator: AnswerValidatorAdapter,
        private answerController: AnswerController,
        private questionEffectsInteractor: QuestionEffectInteractor,
        private questionValidator: QuestionValidator
    ) {}

    public validate(
        questionUuid: string,
        answerUuid: string | null,
        blacklistedQuestionUuids?: string[]
    ): ValidationMessage[] {
        const ownMessages = this.questionValidator.validate(questionUuid);
        const ownAnswerMessages = this.answerValidatorDelegator.validate(questionUuid, answerUuid);
        const messagesFromAnswers =
            answerUuid !== null ? this.getMessagesFromAnswers(answerUuid, blacklistedQuestionUuids ?? []) : [];

        const questions = findAllChildrenForQuestionUuid(questionUuid, this.questionSet, blacklistedQuestionUuids);
        const messagesFromQuestions = questions.reduce<ValidationMessage[]>(
            (p, c) => [...p, ...this.questionValidator.validate(c.uuid)],
            []
        );

        return [...ownMessages, ...ownAnswerMessages, ...messagesFromAnswers, ...messagesFromQuestions];
    }

    private getMessagesFromAnswers(answerUuid: string, blacklistedQuestionsUuids: string[]): ValidationMessage[] {
        const answer = this.answerController.byUuid(answerUuid);
        if (answer === null) {
            throw new Error('Trying to get messages from non-existent answer uuid');
        }
        const question = this.questionSet.findQuestionByUuid(answer.questionUuid);
        if (question === undefined) {
            throw new Error('Answer has non-existent question');
        }
        if (blacklistedQuestionsUuids.includes(question.uuid)) {
            return [];
        }

        const childAnswers = this.answerController
            .filterDeleted(this.answerController.answersByParentAnswerUuid(answerUuid))
            .filter(
                (childAnswer) =>
                    !this.questionEffectsInteractor.isHidden(childAnswer.questionUuid, childAnswer.parentUuid)
            );

        let validationMessages: ValidationMessage[] = [];
        for (const childAnswer of childAnswers) {
            validationMessages = [
                ...validationMessages,
                ...this.answerValidatorDelegator.validate(childAnswer.questionUuid, childAnswer.uuid),
                ...this.getMessagesFromAnswers(childAnswer.uuid, blacklistedQuestionsUuids),
            ];
        }
        return validationMessages;
    }
}
