import {action, makeObservable, observable, runInAction} from 'mobx';
import {ReferenceSale} from '../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v1/models/reference_sale';
import {ObjectType} from '../appraising/enum/object_type';
import {ReferenceSubscriptionType} from '../appraising/models/reference_subscriptions';
import {AltumSettings} from '../appraising/network/preselected_reference_objects_api';
import {ReferenceObjectApi, ReferenceObjectsError} from '../appraising/network/reference_objects_api';
import {CompositeSubscription} from '../support/composite_subscription';
import {Presenter} from '../support/presenter/presenter';

interface SearchAddressDetails {
    postalCode: string;
    houseNumber: string;
    letter: string;

    street?: string;
    city?: string;
    objectType?: ObjectType;
}

export class SearchPresenter implements Presenter {
    @observable public preferences: AltumSettings | null = null;
    @observable public buildYear: number | null = null;
    @observable public surfaceArea: number | null = null;
    @observable public loading = false;
    @observable public referenceObjects: ReferenceSale[] = [];
    @observable public showObjectTypeModal = false;
    @observable public error = false;
    @observable public addressDetails: SearchAddressDetails = {
        postalCode: '',
        houseNumber: '',
        letter: '',
    };

    private readonly subscriptions = new CompositeSubscription();

    constructor(private readonly referenceObjectApi: ReferenceObjectApi) {
        makeObservable(this);
    }

    public mount(): void {
        this.referenceObjectApi
            .getUserSubscriptionPreferences<AltumSettings>(ReferenceSubscriptionType.ALTUM)
            .then((preferences) => {
                this.updatePreferences(preferences);
            });
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    public updatePreferences = (preferences: AltumSettings): void => {
        this.preferences = preferences;
    };

    @action
    public setShowObjectTypeModal = (show: boolean): void => {
        this.showObjectTypeModal = show;
    };

    @action
    public setAddressDetails = (addressDetails: Partial<SearchAddressDetails>): void => {
        const changedAddress =
            (addressDetails.postalCode && this.addressDetails.postalCode !== addressDetails.postalCode) ||
            (addressDetails.houseNumber && this.addressDetails.houseNumber !== addressDetails.houseNumber) ||
            (addressDetails.letter && this.addressDetails.letter !== addressDetails.letter);

        this.addressDetails = {
            ...this.addressDetails,
            ...addressDetails,
        };

        if (changedAddress) {
            this.addressDetails.street = undefined;
            this.addressDetails.city = undefined;
            this.addressDetails.objectType = undefined;
        }
    };

    public async request(): Promise<void> {
        if (!this.preferences || !this.addressDetails.postalCode || !this.addressDetails.houseNumber) {
            return;
        }

        runInAction(() => {
            this.loading = true;
        });

        try {
            const referenceSales = await this.referenceObjectApi.searchReferenceObjects(
                this.addressDetails.postalCode,
                this.addressDetails.houseNumber,
                this.addressDetails.letter,
                ReferenceSubscriptionType.ALTUM,
                {
                    ...this.preferences,
                    object_type: this.addressDetails.objectType,
                }
            );

            runInAction(() => {
                this.referenceObjects = referenceSales;
            });
        } catch (e) {
            if (e instanceof ReferenceObjectsError && e.message === 'unknown_object_type') {
                const address: {address: string; city: string} | null = e.data.address ?? null;
                this.error = true;

                runInAction(() => {
                    this.showObjectTypeModal = true;
                    this.addressDetails.street = address?.address ?? undefined;
                    this.addressDetails.city = address?.city ?? undefined;
                    this.addressDetails.objectType = undefined;
                });
            } else {
                runInAction(() => {
                    this.referenceObjects = [];
                });

                console.error(e);
            }
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }
}
