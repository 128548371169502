import * as React from 'react';

import ReactBootstrapModal from 'react-bootstrap/Modal';
import {classNames} from '../../../../../../support/classnames';
import {getScrollbarWidth} from '../../../../../../support/get_scrollbar_width';

interface OwnProps {
    visible: boolean;
    forced?: boolean;
    wide?: boolean;
    stickyContainer?: boolean;
    onHide?: () => unknown;
    onShow?: () => unknown;
    sidebar?: JSX.Element;
    relativeContainer?: HTMLElement | React.RefObject<HTMLElement>;
    backdrop?: boolean;
    restoreFocus?: boolean;
    className?: string;
    backdropClassName?: string;
    children?: React.ReactNode;
}

export class Modal extends React.Component<OwnProps> {
    private scrollbarWidth = getScrollbarWidth();

    public render() {
        return (
            <ReactBootstrapModal
                className={classNames(
                    {
                        wide: this.props.wide,
                        'sticky-container': this.props.stickyContainer,
                    },
                    this.props.className
                )}
                show={this.props.visible}
                onHide={this.props.onHide}
                onShow={this.props.onShow}
                backdrop={this.props.backdrop}
                backdropClassName={this.props.backdropClassName}
                container={this.props.relativeContainer}
                restoreFocus={this.props.restoreFocus}
            >
                <>
                    {this.props.sidebar !== undefined && (
                        <div className="modal-sidebar-container" style={{right: this.scrollbarWidth * 2 + 'px'}}>
                            {this.props.sidebar}
                        </div>
                    )}
                </>
                <>{this.props.visible ? this.props.children : null}</>
            </ReactBootstrapModal>
        );
    }
}
