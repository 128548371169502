/**
 * Create a range array
 * @param start
 * @param count
 */
export function range(start: number, count: number): number[] {
    return Array.apply(0, Array(count)).map(function (_, index: number) {
        return index + start;
    });
}

export function groupBy<T, K extends keyof T, U extends T[K]>(
    array: readonly T[],
    keySelector: K | ((item: T) => U)
): Map<U, T[]> {
    return array.reduce((acc, p) => {
        const key = typeof keySelector === 'function' ? keySelector(p) : p[keySelector];
        acc.set(key as U, [...(acc.get(key as U) || []), p]);
        return acc;
    }, new Map<U, T[]>());
}
