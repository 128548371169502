import * as React from 'react';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {ImagesPresenter} from './images_presenter';
import {ImageViewer} from '../../../appraising/components/image_viewer/image_viewer';
import {ImageSlider} from '../../../appraising/components/image_viewer/image_slider';
import {classNames} from '../../../support/classnames';

export const Images: React.FC = observer(() => {
    const presenter = usePresenter(
        (component) => new ImagesPresenter(component.business.appraisalProvider, component.network.appraisalApi)
    );

    if (!presenter.images || presenter.images.length === 0) {
        return null;
    }

    return (
        <ImageViewer images={presenter.images?.map((img) => img.url)}>
            <ImageSlider
                autoplay
                images={presenter.images?.map((img) => img.url) ?? []}
                renderImage={(src, i) => (
                    <img
                        loading="lazy"
                        src={src}
                        className={classNames(presenter.images?.[i].animation, 'w-100 h-100')}
                        style={{objectFit: 'cover'}}
                    />
                )}
            />
        </ImageViewer>
    );
});
