import {ExternalAdvice, ExternalAdviceStatus} from '../../../../../models/external_advice';
import {Global, GlobalProvider} from '../../../../../../business/global_provider';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {AnswerController} from '../../../../../business/answering/answer_controller';
import {AnswerInteractor} from '../../../../../business/answering/answer_interactor';
import {Appraisal} from '../../../../../models/appraisal';
import {AppraisalState} from '../../../../../enum/appraisal_state';
import {AppraisalValidationType} from '../../../../../enum/appraisal_validation_type';
import {BuildingInspectorsApi} from '../../../../../network/building_inspectors_api';
import {DetailName} from '../../../../../enum/detail_name';
import {IteratorQuestionType, NormalQuestionType} from '../../../../../enum/question_type';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {SystemModalApi} from '../../../../../network/system_modal_api';
import {SystemModalType} from '../../../../../models/system_modal';
import {getNewestAnswer} from '../../../../../../support/get_newest_answer';
import {isEmpty} from '../../../../../../support/util';

export interface ErrorMessage {
    field: 'appraiserNote' | 'externalNote' | 'measurement' | 'photo';
    message: string;
}

export class ExternalAdviseBuildingInspectorPresenter implements Presenter {
    @observable public busy = true;
    @observable public errorMessages: ErrorMessage[] = [];
    @observable private photoQuestion: Question | null = null;
    @observable public externalAdvice: ExternalAdvice = {
        appraisalId: this.appraisal.id,
        questionUuid: this.question.uuid,
        status: ExternalAdviceStatus.NEW,
        parentAnswerUuid: this.parentAnswerUuid,
        appraiserNote: '',
        externalNote: '',
        measurement: '',
    };
    @observable public confirmModalVisible = false;

    @observable public notificationVisible = false;

    @computed
    public get isDisabled(): boolean {
        if (!this.appraisal.isEditableAppraisal) {
            return true;
        }
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return this.appraisal.isFrozen && this.question.freezes;
        }
        return (
            (this.appraisal.isFrozen && this.question.freezes) ||
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    public get isAppraiserUser(): boolean {
        return this.global.userType === 'appraiser';
    }

    public get isAdvisorUser(): boolean {
        return this.global.userType === 'building_inspector';
    }

    public get shouldShowVideoNotification(): boolean {
        return this.notificationVisible;
    }

    @computed
    public get requestEnabled(): boolean {
        return !this.busy && (this.externalAdvice.status === ExternalAdviceStatus.NEW || this.isAdvisorUser);
    }

    @computed
    public get secondOpinionNew(): boolean {
        return this.externalAdvice.status === ExternalAdviceStatus.NEW;
    }

    @computed
    public get secondOpinionFinished(): boolean {
        return this.externalAdvice.status === ExternalAdviceStatus.FINISHED;
    }

    @computed
    public get secondOpinionRequested(): boolean {
        return (
            this.externalAdvice.status === ExternalAdviceStatus.PENDING ||
            this.externalAdvice.status === ExternalAdviceStatus.PROCESSING
        );
    }

    public get appraiserNotesEnabled(): boolean {
        return this.global.detailName === null;
    }

    public get externalNotesEnabled(): boolean {
        return this.global.detailName === DetailName.ADVICE;
    }

    @computed
    public get shouldShowConfirmModal(): boolean {
        return (
            this.global.buildingInspectorSubscription !== 'yearly' &&
            this.global.buildingInspectorSubscription !== 'monthly'
        );
    }

    public global: Global;

    constructor(
        private appraisal: Appraisal,
        private question: Question,
        private questionSet: QuestionSet,
        private parentAnswerUuid: string,
        private buildingInspectorsApi: BuildingInspectorsApi,
        private systemModalApi: SystemModalApi,
        private answerInteractor: AnswerInteractor,
        private answerController: AnswerController,
        globalProvider: GlobalProvider
    ) {
        makeObservable(this);

        this.global = globalProvider.global;
    }

    public async mount() {
        if (this.question.parentUuid) {
            const photoIterator = this.questionSet.findChildByPredicateRecursive(
                this.question,
                (q) => q.type === IteratorQuestionType.PHOTO_ITERATOR_SMALL
            );
            const hasPhotoIterator = photoIterator !== null;
            if (hasPhotoIterator) {
                this.photoQuestion = this.questionSet.findChildByPredicateRecursive(
                    photoIterator,
                    (q) => q.type === NormalQuestionType.PHOTO_SMALL
                );
            } else {
                const constructionCostsQuestion = this.questionSet.findQuestionByUuid(this.question.uuid);
                if (constructionCostsQuestion) {
                    runInAction(() => {
                        this.photoQuestion = this.questionSet.findChildByPredicateRecursive(
                            constructionCostsQuestion,
                            (q) => q.type === NormalQuestionType.BUILDING_COSTS_PHOTO
                        );
                    });
                }
            }
        }

        const showNotificationBasedOnAdviceCount =
            this.global.externalAdviceCount !== null ? this.global.externalAdviceCount <= 2 : false;
        try {
            const result = await this.systemModalApi.shouldShow(SystemModalType.BUILDING_INSPECTOR_VIDEO);
            runInAction(() => {
                if (result !== null) {
                    this.notificationVisible = result === 'true';
                } else {
                    this.notificationVisible = showNotificationBasedOnAdviceCount;
                }
            });
        } catch (e) {
            runInAction(() => {
                this.notificationVisible = showNotificationBasedOnAdviceCount;
            });
        }

        try {
            const externalAdvice = await this.buildingInspectorsApi.getByQuestionUuid(
                this.question.uuid,
                this.parentAnswerUuid,
                this.appraisal.id
            );
            if (externalAdvice !== null) {
                runInAction(() => {
                    this.externalAdvice = externalAdvice;
                });
            }
        } catch (e) {
            //
        }

        runInAction(() => {
            this.busy = false;
        });
    }

    public unmount() {
        // Noop
    }

    public async dismissNotification() {
        try {
            await this.systemModalApi.dismiss(SystemModalType.BUILDING_INSPECTOR_VIDEO);
        } finally {
            runInAction(() => {
                this.notificationVisible = false;
            });
        }
    }

    public async hideNotification() {
        try {
            await this.systemModalApi.seen(SystemModalType.BUILDING_INSPECTOR_VIDEO);
        } finally {
            runInAction(() => {
                this.notificationVisible = false;
            });
        }
    }

    public async showConfirmModal(visible = true) {
        if (visible) {
            runInAction(() => {
                this.errorMessages = [];
            });

            await this.answerInteractor.submit();

            runInAction(() => {
                this.errorMessages = this.validate();
                this.confirmModalVisible = this.errorMessages.length === 0;
            });
        } else {
            runInAction(() => {
                this.confirmModalVisible = visible;
            });
        }
    }

    @action
    public onChangeExternalAdvice = <TKey extends keyof ExternalAdvice>(key: TKey, value: ExternalAdvice[TKey]) => {
        this.errorMessages = this.errorMessages.filter((e) => e.field !== key);
        this.externalAdvice = {
            ...this.externalAdvice,
            [key]: value,
        };
    };

    public async onRequest() {
        runInAction(() => {
            this.busy = true;
            this.confirmModalVisible = false;
            this.errorMessages = [];
        });

        // Store the answers
        await this.answerInteractor.submit();

        // Start checking contents
        runInAction(() => {
            this.errorMessages = this.validate();
        });

        // Process results
        try {
            if (this.errorMessages.length > 0) {
                runInAction(() => {
                    this.busy = false;
                });
                return;
            }

            if (this.isAppraiserUser) {
                const externalAdvice = await this.buildingInspectorsApi.request(this.externalAdvice);
                runInAction(() => {
                    this.externalAdvice = externalAdvice;
                });
            } else {
                const externalAdvice = await this.buildingInspectorsApi.store(this.externalAdvice);
                runInAction(() => {
                    this.externalAdvice = externalAdvice;
                });
            }
        } finally {
            runInAction(() => {
                this.busy = false;
            });

            if (this.errorMessages.length === 0 && this.isAdvisorUser && this.global.conclusionUrl) {
                window.location.href = this.global.conclusionUrl;
            }
        }
    }

    private validate(): ErrorMessage[] {
        const errorMessages: ErrorMessage[] = [];

        if (isEmpty(this.externalAdvice.appraiserNote)) {
            errorMessages.push({
                field: 'appraiserNote',
                message: 'Het is verplicht om een omschrijving voor de adviseur in te vullen.',
            });
        }

        if (isEmpty(this.externalAdvice.measurement)) {
            errorMessages.push({
                field: 'measurement',
                message: 'Het is verplicht om een omschrijving over de maatvoering in te vullen.',
            });
        }

        if (this.photoQuestion) {
            const photoAnswer = getNewestAnswer(
                this.answerController.answersForQuestionUuidAndParentAnswerUuidInSameIteration(
                    this.photoQuestion.uuid,
                    this.parentAnswerUuid
                ) ?? []
            );
            if (photoAnswer === null || photoAnswer.file === null || photoAnswer.contents === null) {
                errorMessages.push({
                    field: 'photo',
                    message: 'Het is verplicht om een foto toe te voegen.',
                });
            }
        }

        return errorMessages;
    }
}
