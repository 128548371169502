export interface Sustainability {
    buildYear: number;
    innerSurface: number;
    buildingType: string;

    providedLabel: {
        temporary: string | null;
        definitive: string | null;
        definitiveType: string | null;
        definitiveValidity: string | null;
    };

    label: SustainabilityCurrentFuture<string | null>;
    beng2: SustainabilityCurrentFuture<number | null>;
    comfortScore: SustainabilityCurrentFuture<number | null>;
    co2: SustainabilityCurrentFuture<number | null>;
    energyUsage: SustainabilityCurrentFuture<number | null>;
    gasUsage: SustainabilityCurrentFuture<number | null>;

    financial: {
        totalInvestment: number | null;
        totalSavings: number | null;
        monthsToPayOff: number | null;
        ecoValue: number | null;
        savingsMonthly: number | null;
        energyCostMonthly: number | null;
        monthlyLoanPayment: number | null;
    };

    energyPrice: {
        variableGasPrice: number | null;
        variableElectricityPrice: number | null;
        solarpanelWattPeakPerM2: number | null;
    };

    wallInsulation: SustainabilityMeasure<SustainabilityInsulation | null>;
    roofInsulation: SustainabilityMeasure<SustainabilityInsulation | null>;
    floorInsulation: SustainabilityMeasure<SustainabilityInsulation | null>;
    livingRoomWindows: SustainabilityMeasure<SustainabilityGlass | null>;
    bedroomWindows: SustainabilityMeasure<SustainabilityGlass | null>;
    ventilation: SustainabilityMeasure<SustainabilityVentilation | null>;
    installation: SustainabilityMeasure<SustainabilityInstallation | null>;
    solarPanels: SustainabilityMeasure<number | null>;
    shower: SustainabilityMeasure<boolean | null>;
}

export interface SustainabilityCurrentFuture<T> {
    current: {
        value: T;
        description: string | null;
    };

    future: {
        value: T;
        description: string | null;
    };
}

export interface SustainabilityMeasure<T> extends SustainabilityCurrentFuture<T> {
    investment: number | null;
    savings: number | null;
    co2Reduce: number | null;
    beng2: number | null;
    gasSavings: number | null;
    electricitySavings: number | null;
}

export enum SustainabilityInsulation {
    NONE = 'none',
    MEDIOCRE = 'mediocre',
    GOOD = 'good',
    VERY_GOOD = 'very_good',
}

export enum SustainabilityGlass {
    SINGLE = 'single',
    DOUBLE = 'double',
    HR_PLUS_PLUS = 'hr_plus_plus',
    TRIPLE = 'triple',
}

export enum SustainabilityVentilation {
    NATURAL = 'natural',
    MECHANICAL = 'mechanical',
    BALANCED = 'balanced',
    DECENTRALIZED_MECANICAL = 'decentralized_mechanical',
    DEMAND_DRIVEN = 'demand_driven',
}

export enum SustainabilityInstallation {
    LOCAL_GAS_HEATING_AND_GEISER = 'local_gas_heating_geiser',
    VR_BOILER_AND_GEISER = 'vr_boiler_geiser',
    CR = 'cr',
    VR = 'vr',
    HR = 'hr',
    VR_WITH_SOLAR = 'vr_solar',
    HR_WITH_SOLAR = 'hr_solar',
    HRE = 'hre',
    CITY = 'city',
    BIOMASS = 'biomass',
    HP_SOIL = 'hp_soil',
    HP_AIR = 'hp_air',
    HP_HYBRID = 'hp_hybrid',
    HP_COLLECTIVE = 'hp_collective',
}
