import {SortingDirection} from '../../../../../../../../../../enum/reference_objects_sorting';
import {ReferenceObject} from '../../../models/reference_object';
import {ReferenceObjectSortingStrategy} from './reference_object_sorting_strategy';

export interface ReferenceObjectWithNormalizedSaleDate extends ReferenceObject {
    normalizedSaleDate: {
        year: number;
        month: number;
    };
}

export class ReferenceObjectSorterBySalesDate implements ReferenceObjectSortingStrategy {
    public sortReferenceObjects(
        referenceObjects: ReferenceObject[],
        sortingDirection: SortingDirection
    ): ReferenceObject[] {
        // make array of the reference sales with a valid normalized sale date
        const referenceObjectsWithNormalizedSaleDate = referenceObjects.filter(
            (referenceObject: ReferenceObject): referenceObject is ReferenceObjectWithNormalizedSaleDate =>
                referenceObject.normalizedSaleDate !== null
        );

        // make array of the reference sale without a normalized sales date
        const referenceObjectsWithoutNormalizedSaleDate = referenceObjects.filter(
            (referenceObject: ReferenceObject) => referenceObject.normalizedSaleDate === null
        );

        // sort the reference objects with a valid normalized sale date
        const sortedReferenceObjects: ReferenceObject[] = referenceObjectsWithNormalizedSaleDate.sort(
            (referenceObject1, referenceObject2) => {
                return this.getSaleDateForReferenceObjectWithNormalizedSaleDate(referenceObject1) >
                    this.getSaleDateForReferenceObjectWithNormalizedSaleDate(referenceObject2)
                    ? sortingDirection === SortingDirection.DESCENDING
                        ? -1
                        : 1
                    : sortingDirection === SortingDirection.DESCENDING
                    ? 1
                    : -1;
            }
        );

        // put the invalid reference sales at the end
        return [...sortedReferenceObjects, ...referenceObjectsWithoutNormalizedSaleDate];
    }

    private getSaleDateForReferenceObjectWithNormalizedSaleDate = (
        referenceObject: ReferenceObjectWithNormalizedSaleDate
    ): Date => {
        return new Date(referenceObject.normalizedSaleDate.year, referenceObject.normalizedSaleDate.month, 1);
    };
}
