import * as React from 'react';

import {classNames} from '../../support/classnames';
import {buildYearDiffToBadgeContext} from './badges/badge_context_calculators';

interface OwnProps {
    label: string;
    value: number | null;
    referenceValue: number | null;
}

export class StatusDetailListForBuildYear extends React.Component<OwnProps> {
    private badgeToText(diff: number) {
        if (diff < 0) {
            return 'jaar ouder';
        } else if (diff > 0) {
            return 'jaar jonger';
        }
        return 'jaar';
    }

    public render() {
        if (this.props.value !== null && this.props.value !== 0 && this.props.referenceValue !== null) {
            const diff = this.props.value - this.props.referenceValue;
            const badgeContext = buildYearDiffToBadgeContext(diff);
            const unit = this.badgeToText(diff);

            return (
                <dl>
                    <dt>{this.props.label}</dt>
                    <dd>
                        <span className={classNames('badge-container-wide', badgeContext)}>
                            {this.props.value || '-'}
                            {diff !== 0 ? (
                                <span className={classNames('badge', badgeContext)}>{Math.abs(diff) + ' ' + unit}</span>
                            ) : null}
                        </span>
                    </dd>
                </dl>
            );
        }

        return (
            <dl>
                <dt>{this.props.label}</dt>
                <dd>
                    <span className={classNames('badge-container-wide')}>{this.props.value || '-'}</span>
                </dd>
            </dl>
        );
    }
}
