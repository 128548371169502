export enum AppraisalState {
    WAITING_FOR_APPROVAL = 'waiting-for-approval',
    NEW_APPRAISAL = 'new-appraisal',
    PROCESSING = 'processing',
    SUBMITTED_FOR_VALIDATION = 'submitted-for-validation',
    /* When correction is requested Errors can be fetched */
    REQUESTED_CORRECTION = 'requested-correction',
    REQUESTED_CORRECTION_AFTER_APPROVAL = 'requested-correction-after-approval',
    /* When approved the report is available */
    APPROVED = 'approved',
    QUEUED = 'queued',
    CANCELED = 'canceled',
    TRASHED = 'trashed',
    UNKNOWN = 'unknown',
}
