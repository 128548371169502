import * as React from 'react';

import {AddCustomReferenceObjectModalPresenter} from './add_custom_reference_object_modal_presenter';
import {Appraisal} from '../../../../../../../../../models/appraisal';
import {Loader} from '../../../../../../../components/loader';
import {Modal} from '../../../../../components/modal';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';
import {observer} from 'mobx-react';
import {ReferenceSubscriptions} from '../../../../../../../../../models/reference_subscriptions';
import {formatMoney} from '../../../../../../../support/format_money';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    referenceObjectsAnswers: ReferenceObjectAnswer[];
    subscriptions: ReferenceSubscriptions | null;
    visible: boolean;
    setType: SetType;
    onHide: () => void;
    onAddAndOpenModal: (referenceObjectAnswer: ReferenceObjectAnswer) => void;
    disabled?: boolean;
}

export const AddCustomReferenceObjectModal: React.FC<OwnProps> = observer(function AddCustomReferenceObjectModal(
    props
) {
    const presenter = usePresenter(
        (container) =>
            new AddCustomReferenceObjectModalPresenter(
                props.onAddAndOpenModal,
                props.referenceObjectsAnswers,
                container.business.referenceObjectProvider(props.appraisal, props.questionSet),
                props.subscriptions,
                props.setType
            )
    );

    return (
        <Modal onHide={() => props.onHide()} visible={props.visible}>
            <div className="modal-header">
                <h2>Eigen referentieobject toevoegen</h2>
            </div>
            <div className="modal-body">
                {presenter.hasError ? <div className="alert alert-danger">{presenter.errorMessage}</div> : null}
                <div className="row">
                    <div className="col-12 col-sm-4">
                        <div className="form-group">
                            <label htmlFor="input-postal-code">Postcode</label>
                            <input
                                id="input-postal-code"
                                className="form-control"
                                type="text"
                                disabled={props.disabled || presenter.isDisabled}
                                value={presenter.postalCode}
                                onChange={(e) => presenter.onPostalCodeChange(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="form-group">
                            <label htmlFor="input-house-number">Huisnummer</label>
                            <input
                                id="input-house-number"
                                className="form-control"
                                type="text"
                                disabled={props.disabled || presenter.isDisabled}
                                value={presenter.houseNumber}
                                onChange={(e) => presenter.onHouseNumberChange(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="form-group">
                            <label htmlFor="input-letter">Toevoeging</label>
                            <input
                                id="input-letter"
                                className="form-control"
                                type="text"
                                disabled={props.disabled || presenter.isDisabled}
                                value={presenter.letter}
                                onChange={(e) => presenter.onLetterChange(e.target.value)}
                            />
                        </div>
                    </div>
                    {props.setType === SetType.SOLD && (
                        <div className="col-12">
                            <div className="form-group">
                                <input
                                    id="should-fetch-sale-details"
                                    type="checkbox"
                                    checked={presenter.fetchSaleDetails}
                                    disabled={props.disabled || presenter.isDisabled}
                                    onChange={(e) => presenter.onFetchSaleDetailsChange(e.target.checked)}
                                />
                                <label htmlFor="should-fetch-sale-details">
                                    Verkoopgegevens ophalen voor{' '}
                                    {formatMoney((props.subscriptions?.rates.referenceSalefigure ?? 0) / 100)}
                                </label>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary ion-md-close" type="button" onClick={() => props.onHide()}>
                    Annuleren
                </button>
                <button
                    className="btn btn-primary ion-md-arrow-forward"
                    type="button"
                    onClick={() => presenter.onSubmitClick()}
                >
                    {presenter.submitting ? <Loader /> : 'Toevoegen'}
                </button>
            </div>
        </Modal>
    );
});
