import * as React from 'react';

import {IteratorFilesListType} from './iterator_files/iterator_files_attachment_interactor';

import {CadastralDocumentQuestion} from './iterator_cadastral_document/cadastral_document_question';
import {DefaultIteratorFilesAttachmentProvider} from './iterator_files/iterator_files_attachment_provider';
import {DragDrop} from '@uppy/react';
import {IteratorFilesAttachment} from './iterator_files/iterator_files_attachment';
import {IteratorFilesPresenter} from './iterator_files_presenter';
import {NormalQuestionType} from '../../../../../enum/question_type';
import {SimpleQuestionPresenterProps} from '../simple/simple_question_presenter';
import {classNames} from '../../../../../../support/classnames';
import {findAllChildrenForQuestion} from '../../../support/question_filtering';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {ALLOWED_DOCUMENT_TYPES} from '../question_container';

interface OwnProps extends SimpleQuestionPresenterProps {
    fileTypes: string[];
}

export const IteratorFiles: React.FC<OwnProps> = observer(function IteratorFiles(props) {
    const presenter = usePresenter(
        (container) =>
            new IteratorFilesPresenter(
                props.fileTypes,
                container.business.attachmentUploadInteractor(props.appraisal, props.questionSet),
                new DefaultIteratorFilesAttachmentProvider(
                    props.question,
                    props.questionSet,
                    container.business.answerController(props.appraisal, props.questionSet),
                    container.business.iteratorFilesAttachmentInteractor(props.appraisal, props.questionSet)
                ),
                container.business.iteratorFilesAttachmentInteractor(props.appraisal, props.questionSet),
                container.globalProvider,
                container.business.flashMessageBroadcaster,
                props.pagePartsSet,
                props.question,
                props.appraisal,
                props.parentAnswerUuid,
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.questionEffectsInteractor(props.appraisal, props.questionSet),
                container.business.userInteractor,
                container.business.childQuestionValidationProvider(
                    props.appraisal,
                    props.questionSet,
                    props.validationErrors
                )
            )
    );

    if (presenter.isHidden || !presenter.isUploaderVisible) {
        return null;
    }

    const cadastralDocumentQuestion = findAllChildrenForQuestion(props.question, props.questionSet).find(
        (q) => q.type === NormalQuestionType.CADASTRAL_DOCUMENT
    );

    function renderIterator(iteration: string, iteratorFilesListType: IteratorFilesListType) {
        return (
            <IteratorFilesAttachment
                key={props.question.uuid + '[' + iteration + ']'}
                iteration={iteration}
                appraisal={props.appraisal}
                question={props.question}
                questionSet={props.questionSet}
                validationErrors={props.validationErrors}
                validationMessages={props.validationMessages}
                parentAnswerUuid={props.parentAnswerUuid}
                adaptedDefaultValues={props.adaptedDefaultValues}
                hiddenQuestionTypes={props.hiddenQuestionTypes}
                pagePartsSet={props.pagePartsSet}
                activePagePart={props.activePagePart}
                fileTypes={ALLOWED_DOCUMENT_TYPES}
                iteratorFilesListType={iteratorFilesListType}
                iterationProgresses={presenter.iteratorProgress}
                renderingContext={props.renderingContext}
                questionRenderingData={props.questionRenderingData}
                forceShowValidationMessages={props.forceShowValidationMessages}
            />
        );
    }

    return (
        <div className="group-container-files-iterator">
            <div className="list-attachments">
                {presenter.loading || presenter.completeIterations.length === 0
                    ? null
                    : presenter.completeIterations.map((iteration) =>
                          renderIterator(iteration, IteratorFilesListType.FILE_LIST)
                      )}
            </div>
            <div className="iterator-group iterator-group-uploader">
                <div className="form-group">
                    {presenter.isAvailable && (
                        <div
                            className={classNames('list-attachments-dropzone', {
                                'list-attachments-dropzone-has-items': presenter.incompleteIterations.length > 0,
                            })}
                        >
                            <DragDrop
                                className={classNames('dropzone', {
                                    disabled: presenter.isDisabled || !presenter.canAddIteration,
                                })}
                                width="100%"
                                height="150px"
                                uppy={presenter.uppy}
                                locale={{
                                    strings: {
                                        browse: 'blader',
                                        dropHereOr: "Sleep hier je bestanden's naartoe of %{browse}",
                                    },
                                }}
                            />
                        </div>
                    )}
                    {presenter.errorMessage != null && (
                        <span className="invalid-feedback">
                            <div>{presenter.errorMessage}</div>
                        </span>
                    )}
                    {!presenter.canAddIteration && (
                        <div className="alert-inline alert-info ion-md-information-circle">
                            <p>Je kan maximaal 10 extra bijlagen toevoegen.</p>
                        </div>
                    )}
                    <div className="list-attachments">
                        {presenter.loading || presenter.incompleteIterations.length === 0
                            ? null
                            : presenter.incompleteIterations.map((iteration) =>
                                  renderIterator(iteration, IteratorFilesListType.UPLOAD_LIST)
                              )}
                    </div>
                    {cadastralDocumentQuestion !== undefined && (
                        <CadastralDocumentQuestion
                            key={cadastralDocumentQuestion.uuid}
                            appraisal={props.appraisal}
                            question={cadastralDocumentQuestion}
                            questionSet={props.questionSet}
                            validationErrors={props.validationErrors}
                            parentAnswerUuid={props.parentAnswerUuid}
                            adaptedDefaultValues={props.adaptedDefaultValues}
                            hiddenQuestionTypes={props.hiddenQuestionTypes}
                            addIteration={(iteration) => presenter.addIteration(iteration)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});
