import {TechnicalReference} from '../../../enum/technical_reference';

export interface AdaptedValueConfigProvider {
    forTechnicalReference(technicalReference: TechnicalReference): TechnicalReference[];
}

export class DefaultAdaptedValueConfigProvider implements AdaptedValueConfigProvider {
    public forTechnicalReference(technicalReference: TechnicalReference): TechnicalReference[] {
        const map = new Map();
        map.set(TechnicalReference.ADAPTED_VALUE_BIJGEBOUWEN, [
            TechnicalReference.OBJECT_BIJGEBOUW,
            TechnicalReference.OBJECT_OMSCHRIJVING_BIJGEBOUW,
        ]);
        map.set(TechnicalReference.ADAPTED_VALUE_LIGGING, [TechnicalReference.OBJECT_LIGGING]);
        map.set(TechnicalReference.ADAPTED_VALUE_ONDERHOUDSITUATIE, [TechnicalReference.OBJECT_ONDERHOUDSITUATIE]);
        map.set(TechnicalReference.ADAPTED_VALUE_LUXE_EN_DOELMATIGHEID, [
            TechnicalReference.OBJECT_LUXE_EN_DOELMATIGHEID,
        ]);
        map.set(TechnicalReference.ADAPTED_VALUE_KWALITEIT_EN_CONTITIE, [
            TechnicalReference.OBJECT_KWALITEIT_EN_CONTITIE,
        ]);
        map.set(TechnicalReference.ADAPTED_VALUE_ONDERHOUDSITUATIE_TOELICHTING, [
            TechnicalReference.OBJECT_ONDERHOUDSITUATIE_TOELICHTING,
        ]);
        map.set(TechnicalReference.ADAPTED_VALUE_GETAXEERDE_OBJECT_TOELICHTING_TAXATEUR, [
            TechnicalReference.OBJECT_OMSCHRIJVING,
            TechnicalReference.OBJECT_OMSCHRIJVING_BIJGEBOUW,
            TechnicalReference.OBJECT_ONDERHOUDSITUATIE,
            TechnicalReference.OBJECT_LOCATIE,
            TechnicalReference.OBJECT_LIGGING,
            TechnicalReference.OBJECT_OMGEVINGSFACTOREN_WAARDEONTWIKKELING,
        ]);

        return map.get(technicalReference);
    }
}
