import * as React from 'react';
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {SuperMacrosPresenter, SuperMacroState} from './super_macros_presenter';
import {Popper} from '../../../../../../components/popper';
import {classNames} from '../../../../../../../support/classnames';
import {Question} from '../../../../../../models/question';
import {QuestionSet} from '../../../../../../models/question_set';
import {Appraisal} from '../../../../../../models/appraisal';
import {RenderingContextType} from '../../../../../../enum/rendering_context_type';
import {Modal} from '../../../questions/components/modal';
import {QuestionExtensionType} from '../../../../../../enum/question_extension_type';
import {SuperMacrosQuestionExtension} from '../../../../../../models/question_extension';
import {SuperMacrosCopyModal} from './super_macros_copy_modal';

interface OwnProps {
    question: Question;
    parentAnswerUuid?: string;
    iteration?: string;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    renderingContext: RenderingContextType;

    title?: React.ReactNode;
}

export function hasSuperMacros(question: Question): boolean {
    return question.extensions.some((extension) => extension.type === QuestionExtensionType.SUPER_MACROS);
}

export const SuperMacros: FunctionComponent<OwnProps> = observer((props) => {
    const presenter = usePresenter(
        (component) =>
            new SuperMacrosPresenter(
                props.question,
                props.parentAnswerUuid,
                props.iteration,
                props.appraisal,
                props.questionSet,
                component.business.superMacroInteractor(props.appraisal, props.questionSet),
                component.business.macroInteractor(props.questionSet),
                component.business.modalOrSecondaryConfigStackInteractor,
                component.business.answerController(props.appraisal, props.questionSet),
                component.business.answerPathStubber(props.appraisal, props.questionSet, props.renderingContext),
                component.network.appraisalApi,
                component.business.flashMessageBroadcaster
            )
    );

    const settings = props.question.extensions.find(
        (extension) => extension.type === QuestionExtensionType.SUPER_MACROS
    ) as SuperMacrosQuestionExtension | undefined;
    if (settings === undefined) {
        return null;
    }

    return (
        <>
            <div
                className={classNames('super-macro-group-title', {
                    'no-group-title': !props.title,
                })}
            >
                {props.title}
                <Popper
                    className="popper-dropdown"
                    placement="bottom-end"
                    toggle={
                        <button
                            disabled={presenter.isLoading}
                            onClick={() => presenter.setPopperIsOpen(!presenter.isPopperOpen)}
                            className="btn-circle popper-dropdown-button"
                        >
                            <span className="ion-ios-more"></span>
                        </button>
                    }
                    isPopperOpen={presenter.isPopperOpen}
                    setIsPopperOpen={(isOpen) => presenter.setPopperIsOpen(isOpen)}
                    popperClassName="super-macro-group-dropdown-menu popper-dropdown-menu chevron-right"
                    popper={
                        <>
                            {presenter.superMacros.slice(0, 4).map((superMacro, index) => {
                                return (
                                    <button
                                        disabled={presenter.isLoading}
                                        key={superMacro.macro.id}
                                        className={classNames('popper-dropdown-menu-item', {
                                            'macro-active': superMacro.state === SuperMacroState.ACTIVE,
                                            'ion-md-alert': superMacro.state === SuperMacroState.ACTIVE,
                                            'macro-selected': superMacro.state === SuperMacroState.SELECTED,
                                            'ion-md-checkmark-circle': superMacro.state === SuperMacroState.SELECTED,
                                            'ion-md-star': superMacro.state === SuperMacroState.NONE,
                                        })}
                                        onClick={() => presenter.onSuperMacroSelect(superMacro.macro)}
                                    >
                                        {superMacro.macro.contents || `Macro - ${index}`}
                                    </button>
                                );
                            })}
                            <button
                                disabled={presenter.isLoading}
                                className="popper-dropdown-menu-item ion-md-add"
                                onClick={() => presenter.onSaveNewSuperMacro()}
                                title="Nieuwe macro toevoegen."
                            >
                                Nieuwe macro toevoegen
                            </button>
                            {presenter.superMacros.length > 0 && (
                                <button
                                    disabled={presenter.isLoading}
                                    className="popper-dropdown-menu-item ion-ios-more"
                                    onClick={() => presenter.setIsSuperMacrosModelOpen(true)}
                                    title="Open macro overzicht popup."
                                >
                                    Alle macro&apos;s weergeven
                                </button>
                            )}
                            {settings.isAppraisalCopier && (
                                <button
                                    disabled={presenter.isLoading}
                                    className="popper-dropdown-menu-item ion-md-copy"
                                    onClick={() => presenter.setIsCopyModalOpen(true)}
                                    title="Kopieer antwoorden van een andere taxatie."
                                >
                                    Overnemen van andere taxatie
                                </button>
                            )}
                        </>
                    }
                />
            </div>

            {presenter.isAddModalOpen && (
                <Modal visible={true} onHide={() => presenter.setIsSuperMacrosModelOpen(false)}>
                    <div className="modal-header">
                        <h2>Macro&apos;s</h2>
                    </div>
                    <div className="modal-body">
                        <div className="macro-edit-list">
                            {presenter.superMacros.map((sm) => {
                                return (
                                    <button
                                        disabled={presenter.isLoading}
                                        className={classNames('macro-edit-row ion-md-checkmark-circle', {
                                            'macro-active': sm.state === SuperMacroState.ACTIVE,
                                            'ion-md-alert': sm.state === SuperMacroState.ACTIVE,
                                            'macro-selected': sm.state === SuperMacroState.SELECTED,
                                            'ion-md-checkmark-circle': sm.state === SuperMacroState.SELECTED,
                                            'ion-md-star': sm.state === SuperMacroState.NONE,
                                        })}
                                        key={sm.macro.id}
                                        onClick={() => presenter.onSuperMacroSelect(sm.macro)}
                                    >
                                        <div className="macro-name">{sm.macro.contents}</div>

                                        <button
                                            disabled={presenter.isLoading}
                                            className="btn btn-delete btn-circle"
                                            onClick={() => {
                                                if (confirm('Weet je zeker dat je deze macro wil verwijderen?')) {
                                                    presenter.removeSuperMacro(sm.macro);
                                                }
                                            }}
                                        >
                                            <span className="ion-md-trash"></span>
                                        </button>

                                        <button
                                            disabled={presenter.isLoading}
                                            className="btn btn-secondary btn-circle"
                                            onClick={() => presenter.onEditSuperMacro(sm.macro)}
                                        >
                                            <span className="ion-md-create"></span>
                                        </button>
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            disabled={presenter.isLoading}
                            className="btn btn-secondary ion-md-close"
                            type="button"
                            onClick={() => presenter.setIsSuperMacrosModelOpen(false)}
                        >
                            Sluiten
                        </button>
                    </div>
                </Modal>
            )}

            {presenter.isCopyModalOpen && (
                <SuperMacrosCopyModal
                    onClose={() => presenter.setIsCopyModalOpen(false)}
                    isAppraisalSearchPending={presenter.isAppraisalSearchPending}
                    isSearchingAppraisals={presenter.isSearchingAppraisals}
                    isLoadingAppraisalValues={presenter.isLoadingAppraisalValues}
                    loadAppraisalValues={(appraisalId) => presenter.getAppraisalValues(appraisalId)}
                    onSearchAppraisal={(search, immediate) => presenter.onSearchAppraisal(search, immediate)}
                    searchAppraisals={presenter.searchAppraisals}
                    appraisalFillValues={presenter.appraisalFillValues}
                />
            )}
        </>
    );
});
