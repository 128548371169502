import {ReferenceObjectAnswer, referenceObjectAnswerFromSale} from '../models/reference_object_answer';
import {computed, makeObservable, observable} from 'mobx';

import {Presenter} from '../../../../../../../../../support/presenter/presenter';
import {ReferenceObjectProvider} from '../../../../../../../../business/reference_object_provider';
import {SetType} from '../../../../../../../../models/reference_set/set_type';

export class AddCustomReferenceSaleModalPresenter implements Presenter {
    @observable public postalCode = '';
    @observable public houseNumber = '';
    @observable public letter = '';
    @observable public hasError = false;
    @observable public errorMessage = '';
    @observable public submitting = false;

    @computed
    public get isDisabled(): boolean {
        return this.submitting;
    }

    private _onAdd: (referenceObjectAnswer: ReferenceObjectAnswer) => void;
    private _referenceObjectsAnswers: ReferenceObjectAnswer[];
    private _referenceObjectInteractor: ReferenceObjectProvider;

    constructor(
        onAdd: (referenceObjectAnswer: ReferenceObjectAnswer) => void,
        referenceObjectsAnswers: ReferenceObjectAnswer[],
        referenceObjectInteractor: ReferenceObjectProvider
    ) {
        makeObservable(this);
        this._onAdd = onAdd;
        this._referenceObjectsAnswers = referenceObjectsAnswers;
        this._referenceObjectInteractor = referenceObjectInteractor;
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public async onSubmitClick() {
        try {
            this.submitting = true;
            this.hasError = false;
            this.errorMessage = '';

            if (!this.houseNumberValidates(this.houseNumber)) {
                this.hasError = true;
                this.errorMessage = 'Het ingevoerde huisnummer is onjuist. Deze dient numeriek te zijn.';
            } else {
                await this.addReferenceObject();
            }
        } catch (e) {
            this.hasError = true;
            this.errorMessage =
                'Er kon in het register geen referentieverkoop gevonden worden voor het ingevoerde adres.';
        } finally {
            this.submitting = false;
        }
    }

    public onPostalCodeChange(value: string) {
        this.postalCode = value;
    }

    public onHouseNumberChange(value: string) {
        this.houseNumber = value;
    }

    public onLetterChange(value: string) {
        this.letter = value;
    }

    private async addReferenceObject() {
        const sale = await this._referenceObjectInteractor.getSaleByAddress(
            this.postalCode,
            this.houseNumber,
            this.letter,
            SetType.SOLD
        );

        const existingInSet = this._referenceObjectsAnswers.find(
            (a) =>
                a.referenceObject.adres.postcode === sale.postalCode &&
                a.referenceObject.adres.huisnummer === sale.houseNumber &&
                a.referenceObject.adres.huisnummerToevoeging === sale.letter
        );

        if (!existingInSet) {
            this._onAdd(referenceObjectAnswerFromSale(sale));
        } else {
            this.hasError = true;
            this.errorMessage =
                'De referentieverkoop die je probeert toe te voegen is al toegevoegd aan deze waardering.';
        }
    }

    private houseNumberValidates(value: string): boolean {
        return value.match(/^\d+$/i) !== null;
    }
}
