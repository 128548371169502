import * as React from 'react';

import {Appraisal} from '../../../../../../../../../models/appraisal';
import {FloorAreaBadge} from '../../../../../../../../../components/badges/floor_area_badge';
import {FloorAreaPrice} from '../floor_area_price';
import {PriceRange} from '../price_range';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {VolumeBadge} from '../../../../../../../../../components/badges/volume_badge';
import {PlotAreaBadge} from '../../../../../../../../../components/badges/plot_area_badge';
import {BuildYearBadge} from '../../../../../../../../../components/badges/build_year_badge';
import {ObjectType} from '../../../../../../../../../enum/object_type';
import {ObjectTypeBadge} from '../../../../../../../../../components/badges/object_type_badge';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;

    referenceSetType: SetType | null;
    referenceValuation: number | null;
    referenceSurfaceArea: number | null;
    referencePlotArea: number | null;
    referenceBuildYear: number | null;
    referenceVolume: number | null;
    referenceEnergyLabel: string | null;
    referenceObjectType: ObjectType | null;

    floorArea: number | null;
    plotArea: number | null;
    buildYear: number | null;
    objectType: string | null;
    energyLabel: string | null;

    priceRange: string | number | null;
    period: string | null;
    volume: number | null;
}

export class ReferenceObjectDetails extends React.Component<OwnProps> {
    public render() {
        const {
            appraisal,
            questionSet,
            referenceSetType,
            referenceValuation,
            referenceSurfaceArea,
            referencePlotArea,
            referenceBuildYear,
            referenceVolume,
            referenceEnergyLabel,
            referenceObjectType,
            floorArea,
            plotArea,
            buildYear,
            objectType,
            energyLabel,
            priceRange,
            period,
            volume,
        } = this.props;

        return (
            <>
                <div className="row">
                    <div className="col-12 text-left">
                        <dl>
                            <dt>Woningtype</dt>
                            <dd>
                                {objectType ?? '-'}
                                {referenceObjectType && (
                                    <ObjectTypeBadge
                                        objectType={objectType as ObjectType}
                                        referenceObjectType={referenceObjectType}
                                    />
                                )}
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 text-left">
                        <dl>
                            <dt>Bouwjaar</dt>
                            <dd>
                                {buildYear !== null ? buildYear : '-'}
                                {referenceBuildYear !== null && buildYear !== null ? (
                                    <BuildYearBadge
                                        compact
                                        buildYear={buildYear}
                                        referenceBuildYear={referenceBuildYear}
                                    />
                                ) : null}
                            </dd>
                        </dl>
                    </div>
                    <div className="col-6 text-right">
                        <dl>
                            <dt>Energielabel</dt>
                            <dd>
                                {energyLabel !== null ? energyLabel : '-'}{' '}
                                {referenceEnergyLabel !== null ? '(' + referenceEnergyLabel + ')' : ''}
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 text-left">
                        <dl>
                            <dt>GBO wonen</dt>
                            <dd>
                                {floorArea !== null ? floorArea + 'm²' : '-'}
                                {referenceSurfaceArea !== null && floorArea !== null ? (
                                    <FloorAreaBadge
                                        compact
                                        floorArea={floorArea}
                                        referenceFloorArea={referenceSurfaceArea}
                                    />
                                ) : null}
                            </dd>
                        </dl>
                    </div>
                    <div className="col-6 text-right">
                        <dl>
                            <dt>Perceelopp.</dt>
                            <dd>
                                {plotArea !== null ? plotArea + 'm²' : '-'}
                                {referencePlotArea !== null && plotArea !== null ? (
                                    <PlotAreaBadge compact plotArea={plotArea} referencePlotArea={referencePlotArea} />
                                ) : null}
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 text-left">
                        <dl>
                            <dt>Bruto inhoud</dt>
                            <dd>
                                {volume !== null ? 'ca. ' + volume + 'm³' : '-'}
                                {volume !== null && referenceVolume !== null && (
                                    <VolumeBadge compact volume={volume} referenceVolume={referenceVolume} />
                                )}
                            </dd>
                        </dl>
                    </div>
                    <div className="col-6 text-right">
                        <dl>
                            <dt>Prijs per m²</dt>
                            {referenceSetType !== SetType.RENT ? (
                                <dd>
                                    <FloorAreaPrice
                                        referenceObjectFloorArea={floorArea}
                                        referenceSetPrice={referenceValuation}
                                        referenceSetFloorArea={referenceSurfaceArea}
                                        referenceObjectPrice={priceRange}
                                        compact={true}
                                        questionSet={questionSet}
                                        appraisal={appraisal}
                                    />
                                </dd>
                            ) : (
                                <dd>n.v.t.</dd>
                            )}
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 text-left">
                        <dl>
                            <dt>Prijsklasse</dt>
                            <dd>
                                <PriceRange range={priceRange !== null ? String(priceRange) : null} break={true} />
                            </dd>
                        </dl>
                    </div>
                    <div className="col-6 text-right">
                        <dl>
                            <dt>Periode</dt>
                            <dd>{period ?? '-'}</dd>
                        </dl>
                    </div>
                </div>
            </>
        );
    }
}
