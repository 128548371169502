import * as React from 'react';

import {Appraisal} from '../../../models/appraisal';
import {Loader} from '../components/loader';
import {Modal} from './questions/components/modal';
import {NotesPresenter} from './notes_presenter';
import {observer} from 'mobx-react';
import {isTablet} from '../../../../support/check_mobile';
import {usePresenter} from '../../../../support/presenter/use_presenter';

interface OwnProps {
    appraisal: Appraisal;
}

export const Notes: React.FC<OwnProps> = observer(function Notes(props) {
    const presenter = usePresenter((container) => new NotesPresenter(props.appraisal, container.network.appraisalApi));

    function renderModal() {
        return (
            <Modal visible={presenter.modalVisible} onHide={() => presenter.onModalClose()}>
                <div className="modal-header">
                    <h2>Notities</h2>
                </div>
                <div className="modal-body">
                    <textarea
                        className="form-control"
                        rows={isTablet() ? 18 : 12}
                        readOnly={presenter.saving}
                        value={presenter.notes}
                        onChange={(e) => presenter.onChange(e.target.value)}
                    />
                </div>
                <div className="modal-footer">
                    <button
                        className="btn btn-primary btn-full ion-md-arrow-forward"
                        type="button"
                        onClick={() => presenter.onSaveClick()}
                    >
                        {presenter.saving ? <Loader /> : 'Opslaan'}
                    </button>
                </div>
            </Modal>
        );
    }

    return (
        <div
            className="btn-notes"
            data-tooltip="btn-notes"
            data-tooltip-title="Notities maken"
            data-tooltip-description={
                'Met deze knop kun je notities maken terwijl je met het taxatierapport bezig bent.'
            }
            data-tooltip-position="right-bottom"
        >
            <label onClick={() => presenter.onClick()}>
                <svg
                    fill="currentColor"
                    preserveAspectRatio="xMidYMid meet"
                    height="1em"
                    width="1em"
                    viewBox="0 0 40 40"
                    style={{verticalAlign: 'middle'}}
                >
                    <g>
                        <path d="m31.6 33.4v-26.8h-3.2v5h-16.8v-5h-3.2v26.8h23.2z m-11.6-30c-0.9 0-1.6 0.7-1.6 1.6s0.7 1.6 1.6 1.6 1.6-0.7 1.6-1.6-0.7-1.6-1.6-1.6z m11.6 0c1.8 0 3.4 1.4 3.4 3.2v26.8c0 1.8-1.6 3.2-3.4 3.2h-23.2c-1.8 0-3.4-1.4-3.4-3.2v-26.8c0-1.8 1.6-3.2 3.4-3.2h6.9c0.7-2 2.5-3.4 4.7-3.4s4 1.4 4.7 3.4h6.9z"></path>
                    </g>
                </svg>
            </label>
            {renderModal()}
        </div>
    );
});
