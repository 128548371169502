export async function requestCurrentGeolocation(): Promise<GeolocationCoordinates | null> {
    return new Promise<GeolocationCoordinates | null>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                resolve(position.coords);
            },
            (positionError) => {
                reject(positionError);
            },
            {
                timeout: 30 * 1000, //abort after half a minute
            }
        );
    });
}
