export function renderAppraisalStartEnvironment() {
    const box = document.getElementById('notify-no-client-box');

    if (box !== null) {
        box.style.display = 'none';
    }

    const inputFields = document.querySelectorAll("[showOnChange='notify-no-client-box']");
    for (let i = 0; i < inputFields.length; i++) {
        inputFields[i].addEventListener(
            'focus',
            function () {
                if (box !== null) {
                    box.style.display = 'block';
                }
            },
            false
        );
    }
}
