import {FlashMessageBroadcaster, Type} from '../../../../../business/flash_message_broadcaster';
import {NetworkStatus, NetworkStatusProvider} from '../../../../../business/network_status_provider';
import {makeObservable, observable, runInAction} from 'mobx';

import {AnswerInteractor} from '../../../../../business/answering/answer_interactor';
import {Appraisal} from '../../../../../models/appraisal';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {ConceptReportProvider} from '../../../../../business/concept_report_provider';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {RootGroupQuestionType} from '../../../../../enum/question_type';

export class ValidationErrorsDownloadConceptReportPresenter implements Presenter {
    public conceptReportQuestion: Question | null = null;
    public canDownloadConceptReport = false;

    @observable public isDownloadingReport = false;
    @observable public conceptReportUrl: string | null = null;
    @observable public networkStatus: NetworkStatus = NetworkStatus.OFFLINE;

    private subscriptions = new CompositeSubscription();

    constructor(
        private questionSet: QuestionSet,
        private appraisal: Appraisal,
        private conceptReportProvider: ConceptReportProvider,
        private answerInteractor: AnswerInteractor,
        private flashMessageBroadcaster: FlashMessageBroadcaster,
        private networkStatusProvider: NetworkStatusProvider
    ) {
        this.conceptReportQuestion =
            this.questionSet.findQuestionsByType(RootGroupQuestionType.CONCEPT_REPORT)[0] ?? null;
        this.canDownloadConceptReport = questionSet.reportDefintionConfig.conceptReport;
        makeObservable(this);
    }

    public async mount(): Promise<void> {
        //Force submitting before trying to conclude anything
        await this.answerInteractor.submit();

        this.subscriptions.add(
            this.networkStatusProvider.status().subscribe((networkStatus) => {
                runInAction(() => {
                    this.networkStatus = networkStatus;
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public async downloadConceptReport(): Promise<void> {
        runInAction(() => {
            this.conceptReportUrl = null;
        });
        try {
            runInAction(() => {
                this.isDownloadingReport = true;
            });
            const conceptReportUrl = await this.conceptReportProvider.getConceptReportUrl(this.appraisal.id);
            runInAction(() => {
                this.conceptReportUrl = conceptReportUrl;
            });
        } catch {
            runInAction(() => {
                this.isDownloadingReport = false;
                this.flashMessageBroadcaster.broadcast('Genereren concept rapport mislukt.', Type.Danger);
            });
        } finally {
            runInAction(() => {
                this.isDownloadingReport = false;
            });
        }
    }
}
