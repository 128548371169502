import {Macro, MacroExternalType} from '../../appraising/models/macro';
import {MacroSettings} from '../../appraising/models/macro_settings';
import {MacroApi} from '../network/macro_api';

export class MacroInteractor {
    constructor(private macroApi: MacroApi) {}

    public getExternalMacros(
        macroExternalType: MacroExternalType
    ): Promise<{macros: Macro[]; settings: MacroSettings[]}> {
        return this.macroApi.getExternalMacros(macroExternalType);
    }

    public storeExternalMacro(contents: string, type: MacroExternalType): Promise<Macro> {
        return this.macroApi.storeExternal(contents, type);
    }

    public toggleFavorite(id: number): Promise<MacroSettings> {
        return this.macroApi.toggleFavorite(id);
    }

    public async hideForUser(id: number): Promise<MacroSettings> {
        return await this.macroApi.hideForUser(id);
    }

    public destroy(id: number): Promise<void> {
        return this.macroApi.destroy(id);
    }

    public mergeMacrosWithSettings(macros: Macro[], settings: MacroSettings[]): Macro[] {
        const favoriteMacros: Macro[] = [];
        const nonFavoriteMacros: Macro[] = [];
        for (const macro of macros) {
            const setting = settings.find((s) => s.macroId === macro.id);
            if (setting?.hidden) {
                continue;
            }

            if (setting?.favorite) {
                favoriteMacros.push({
                    ...macro,
                    favorite: true,
                });
            } else {
                nonFavoriteMacros.push(macro);
            }
        }

        return favoriteMacros.concat(nonFavoriteMacros);
    }
}
