import {ApiCondition, apiConditionToCondition} from './api_condition';
import {ApiEffect} from './api_effect';
import {ConditionGroup} from '../../models/condition_group';

export interface ApiConditionGroup {
    id: number;
    type: 'and' | 'or';
    conditions: ApiCondition[];
    effects: ApiEffect[];
}

export function apiConditionGroupToConditionGroup(apiConditionGroup: ApiConditionGroup): ConditionGroup {
    return {
        id: apiConditionGroup.id,
        type: apiConditionGroup.type,
        conditions: apiConditionGroup.conditions.map((apiCondition) => apiConditionToCondition(apiCondition)),
        effects: apiConditionGroup.effects,
    };
}
