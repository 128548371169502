import * as React from 'react';
import {FunctionComponent} from 'react';
import {TreeItem} from '../../../../../support/generic_tree';
import {PagePart} from '../../../../models/page_part';
import {QuestionAnswerPair} from '../../../../../support/page_part_tree';
import {Appraisal} from '../../../../models/appraisal';
import {QuestionSet} from '../../../../models/question_set';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {TreeViewerPresenter} from './tree_viewer_presenter';
import {createPortal} from 'react-dom';
import {observer} from 'mobx-react';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet;
}

function questionAnswerRow(item: QuestionAnswerPair) {
    return (
        <div style={{display: 'inline-block'}}>
            Q: {item.question.uuid + ' '}
            {item.isHidden ? <i className="ion-md-eye-off"></i> : <i className="ion-md-eye"></i>}{' '}
            {item.question.contents} - {item.question.type}
            {item.question.technicalReference && (
                <>
                    <br />
                    TR: {item.question.technicalReference}
                </>
            )}
            <br />
            {item.answer?.uuid && <>A: {item.answer.uuid}</>}
            {item.answer?.contents && <> c: {item.answer.contents}</>}
            {item.answer?.answerOptionId && (
                <> o: {item.question.answerOptions.find((ao) => ao.id === item.answer?.answerOptionId)?.contents}</>
            )}
            {item.answer?.iteration && (
                <>
                    <br />
                    I: {item.answer.iteration}
                </>
            )}
            {item.answer?.parentUuid && (
                <>
                    <br />
                    PUUID: {item.answer.parentUuid}
                </>
            )}
        </div>
    );
}

function renderTrees(trees: Array<TreeItem<QuestionAnswerPair | PagePart>>) {
    return (
        <ul style={{padding: '2px 2px 2px 10px'}}>
            {trees.map((treeItem) => {
                if ('uuid' in treeItem.item) {
                    return (
                        <li key={`pp-tree-item-${treeItem.item.uuid}`}>
                            <details>
                                <summary>
                                    <div style={{display: 'inline-block'}}>PP: {treeItem.item.name}</div>
                                </summary>
                                {renderTrees(treeItem.children)}
                            </details>
                        </li>
                    );
                } else {
                    const item = treeItem.item;
                    return (
                        <li
                            style={{border: '1px solid gray', padding: '2px'}}
                            key={`qa-tree-item-${item.answer?.uuid}-${item.answer?.iteration}-${item.question.uuid}`}
                        >
                            {treeItem.children.length > 0 ? (
                                <details>
                                    <summary>{questionAnswerRow(item)}</summary>
                                    {renderTrees(treeItem.children)}
                                </details>
                            ) : (
                                <div style={{marginLeft: '17px'}}>{questionAnswerRow(item)}</div>
                            )}
                        </li>
                    );
                }
            })}
        </ul>
    );
}

export const TreeViewer: FunctionComponent<OwnProps> = observer((props) => {
    const presenter = usePresenter(
        (container) =>
            new TreeViewerPresenter(
                props.pagePartsSet,
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.questionEffectsInteractor(props.appraisal, props.questionSet)
            )
    );
    if (presenter.trees === null || presenter.trees.length === 0) {
        return null;
    }

    return createPortal(
        <div
            style={{
                position: 'fixed',
                overflow: 'auto',
                right: '0px',
                top: '0px',
                zIndex: 9999,
                background: 'rgba(255,255,255,0.9)',
                maxHeight: '100vh',
            }}
        >
            {renderTrees(presenter.trees)}
        </div>,
        window.document.body
    );
});
