import {action, makeObservable, observable} from 'mobx';
import {
    getDefaultHouseGroupConfiguration,
    houseGroupConfigurationFromAnswer,
} from '../support/house_group_question/house_group_default_configuration';

import {Answer} from '../../../../../models/answer';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {SimpleQuestionPresenter} from '../simple/simple_question_presenter';

export class HouseGroupQuestionPresenter extends SimpleQuestionPresenter {
    @observable public numberOfAttics?: number;
    @observable public numberOfFloors?: number;
    @observable public numberOfGroundFloors?: number;
    @observable public numberOfBasements?: number;

    private subscriptions = new CompositeSubscription();

    constructor(...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public mount(): void {
        super.mount();

        this.subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(this.question.uuid, null, this.iteration ?? null)
                .subscribe((answer) => {
                    this.fillFromAnswer(answer);
                })
        );
    }

    public unmount(): void {
        super.unmount();

        this.subscriptions.clear();
    }

    @action
    public incrementAttics() {
        if (!this.numberOfAttics) {
            this.numberOfAttics = 0;
        }
        this.numberOfAttics++;
        this.onChange();
    }

    @action
    public decrementAttics() {
        if (!this.numberOfAttics) {
            this.numberOfAttics = 0;
        }
        if (this.numberOfAttics > 0) {
            this.numberOfAttics--;
        }
        this.onChange();
    }

    @action
    public incrementFloors() {
        if (!this.numberOfFloors) {
            this.numberOfFloors = 0;
        }
        this.numberOfFloors++;
        this.onChange();
    }

    @action
    public decrementFloors() {
        if (!this.numberOfFloors) {
            this.numberOfFloors = 0;
        }
        if (this.numberOfFloors > 0) {
            this.numberOfFloors--;
        }
        this.onChange();
    }

    @action
    public incrementGroundFloors() {
        if (!this.numberOfGroundFloors) {
            this.numberOfGroundFloors = 0;
        }
        this.numberOfGroundFloors = 1;
        this.onChange();
    }

    @action
    public decrementGroundFloors() {
        if (!this.numberOfGroundFloors) {
            this.numberOfGroundFloors = 0;
        }
        this.numberOfGroundFloors = 0;
        this.onChange();
    }

    @action
    public incrementBasements() {
        if (!this.numberOfBasements) {
            this.numberOfBasements = 0;
        }
        this.numberOfBasements++;
        this.onChange();
    }

    @action
    public decrementBasements() {
        if (!this.numberOfBasements) {
            this.numberOfBasements = 0;
        }
        if (this.numberOfBasements > 0) {
            this.numberOfBasements--;
        }
        this.onChange();
    }

    public onChange() {
        const value = JSON.stringify({
            numberOfAttics: this.numberOfAttics,
            numberOfFloors: this.numberOfFloors,
            numberOfGroundFloors: this.numberOfGroundFloors,
            numberOfBasements: this.numberOfBasements,
        });
        if (this.answer === undefined) {
            throw new Error('Can not use undefined answer');
        }
        super.onChange(value);
    }

    @action
    private fillFromAnswer(answer: Answer) {
        const houseGroupConfiguration =
            answer.contents === null ? getDefaultHouseGroupConfiguration() : houseGroupConfigurationFromAnswer(answer);
        this.numberOfAttics = houseGroupConfiguration.numberOfAttics;
        this.numberOfFloors = houseGroupConfiguration.numberOfFloors;
        this.numberOfGroundFloors = houseGroupConfiguration.numberOfGroundFloors;
        this.numberOfBasements = houseGroupConfiguration.numberOfBasements;
    }
}
