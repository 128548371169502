import React, {useEffect, useState, useMemo, useRef} from 'react';
import {animated, useSprings, to} from '@react-spring/web';

import {useDrag} from '@use-gesture/react';
import {classNames} from '../../../support/classnames';

function setSpringPositions(diameter: number, availableIndices: number[], count: number) {
    return (i: number) => {
        const availablePos = availableIndices.indexOf(i);

        const pos = {
            x: 0,
            y: -0.5 * diameter,
            opacity: 1,
            rotate: (360 * i) / count,
            config: {
                friction: 50,
                tension: 200,
            },
        };

        if (availablePos === -1) {
            pos.x = window.innerWidth;
            pos.opacity = 0;
        }

        return pos;
    };
}

function rotateTo(targetRotation: number, currentRotation: number): number {
    let diff = 0;
    diff = targetRotation - currentRotation;
    if (diff < -180) {
        diff += 360;
    } else if (diff > 180) {
        diff -= 360;
    }

    return currentRotation + diff;
}

function getSelected(current: number, availableIndices: number[], count: number): number {
    if (availableIndices.includes(current)) {
        return current;
    }

    for (let i = current; i < count; i++) {
        if (availableIndices.includes(i)) {
            return i;
        }
    }

    for (let i = 0; i < current; i++) {
        if (availableIndices.includes(i)) {
            return i;
        }
    }

    return current;
}

const cardWidth = 360;
const cardHeight = 500;
const containerHeight = 650;

export const ReferencesWheel = ({
    children,
    accepted,
    rejected,
    accept,
    reject,
    loading,
}: {
    children: React.ReactNode[];
    accepted: number[];
    rejected: number[];
    accept: (index: number) => void;
    reject: (index: number) => void;
    loading: boolean;
}) => {
    const availableIndices = useMemo(() => {
        const availableIndices: number[] = [];

        for (let i = 0; i < children.length; i++) {
            if (!accepted.includes(i) && !rejected.includes(i)) {
                availableIndices.push(i);
            }
        }

        return availableIndices;
    }, [accepted, rejected, children.length]);

    const {innerDiameter, outerDiameter, radius} = useMemo(() => {
        if (children.length <= 2) {
            return {
                innerDiameter: cardHeight * 0.25,
                outerDiameter: cardHeight * 2.6,
                radius: 179.9,
            };
        }

        const cardCount = children.length;
        const cardAngle = 360 / cardCount;

        const cardWidthWithPadding = cardWidth + 75;

        const innerRadius = (0.5 * cardWidthWithPadding) / Math.sin((cardAngle / 2) * (Math.PI / 180));

        const cardHeightCorrection = Math.cos((cardAngle / 2) * (Math.PI / 180)) * cardHeight + 80;

        const outerRadius = innerRadius / Math.cos((cardAngle / 2) * (Math.PI / 180)) + cardHeightCorrection;

        return {
            innerDiameter: innerRadius * 2,
            outerDiameter: outerRadius * 2,
            radius: cardAngle,
        };
    }, [children.length]);

    const fullCircleScale = useMemo(() => {
        return (containerHeight - 10) / outerDiameter;
    }, [outerDiameter]);

    const [rawSelected, setSelected] = useState<number | null>(() => null);

    const selected = useMemo(() => {
        const selected = getSelected(rawSelected ?? 0, availableIndices, children.length);

        if (selected !== rawSelected) {
            setSelected(selected);
        }

        return selected;
    }, [rawSelected, loading, availableIndices, children.length]);

    const [animatedCardProps, cardSpring] = useSprings(
        children.length,
        setSpringPositions(innerDiameter, availableIndices, children.length)
    );

    const bind = useDrag(({args: [index], active, movement: [mx], velocity: [vx], event}) => {
        const trigger = (vx > 0.1 && Math.abs(mx) > 60) || Math.abs(mx) > 200;

        event.stopPropagation();
        event.preventDefault();

        if (!active && trigger && mx < 0) {
            cardSpring.start((i) => {
                if (index !== i) {
                    return;
                }

                reject(index);

                return {
                    x: -400,
                };
            });
        } else if (!active && trigger && mx > 0) {
            cardSpring.start((i) => {
                if (index !== i) {
                    return;
                }

                accept(index);

                return {
                    x: window.innerWidth,
                };
            });
        } else {
            cardSpring.start((i) => {
                if (index !== i) {
                    return;
                }

                return {
                    x: active ? mx : 0,
                    config: {
                        friction: 50,
                        tension: 500,
                    },
                };
            });
        }
    });

    const prevRotation = useRef(0);
    const rotation = useMemo(() => {
        const newRotation = rotateTo((selected / children.length) * 360, prevRotation.current);
        prevRotation.current = newRotation;
        return newRotation;
    }, [selected]);

    useEffect(() => {
        cardSpring.start(setSpringPositions(innerDiameter, availableIndices, children.length));
    }, [availableIndices]);

    if (children.length === 0) {
        return null;
    }

    return (
        <div className="reference-object-wheel">
            <div className={classNames('reference-object-wheel-container', {loading})}>
                <div
                    className="position-relative"
                    style={
                        {
                            '--circle-full-scale': fullCircleScale,
                            '--circle-diameter': `${outerDiameter}px`,
                            '--circle-rotation': `${rotation}deg`,
                            '--card-height': `${cardHeight}px`,
                        } as React.CSSProperties
                    }
                >
                    {animatedCardProps.map(({x, y, rotate, opacity}, i) => (
                        <animated.div
                            key={i}
                            style={{
                                transform: to([rotate, y], (rotate, y) => `rotate(${rotate}deg) translateY(${y}px)`),
                                position: 'absolute',
                                left: 'calc(50% - 180px)',
                                bottom: 0,
                                transformOrigin: 'bottom center',
                                zIndex: selected === i ? 1 : 0,
                            }}
                        >
                            <animated.div {...bind(i)} style={{x, opacity}}>
                                {React.isValidElement(children[i])
                                    ? React.cloneElement(children[i] as React.ReactElement, {
                                          swipeLeft: () => {
                                              reject(i);
                                          },
                                          swipeRight: () => {
                                              accept(i);
                                          },
                                      })
                                    : children[i]}
                            </animated.div>
                        </animated.div>
                    ))}
                    <div
                        className="reference-object-wheel-background"
                        style={
                            {
                                '--diameter': `${outerDiameter}px`,
                                '--rotation': `${radius}deg`,
                            } as React.CSSProperties
                        }
                    >
                        <div className="position-relative">
                            {(children.length <= 2 ? ['a', 'b'] : children).map((_, i) => (
                                <div
                                    key={i}
                                    className="reference-object-wheel-background-triangle"
                                    style={
                                        {
                                            '--index': i,
                                        } as React.CSSProperties
                                    }
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div
                    className="reference-object-wheel-button button-back ion-md-arrow-back"
                    onMouseDown={() => {
                        let index = selected - 1;
                        if (index < 0) {
                            index = children.length - 1;
                        }

                        for (let i = index; i >= 0; i--) {
                            if (!accepted.includes(i) && !rejected.includes(i)) {
                                setSelected(i);
                                return;
                            }
                        }

                        // No unaccepted items available before current item, so we try from the last item backwards
                        for (let i = children.length - 1; i >= 0; i--) {
                            if (!accepted.includes(i) && !rejected.includes(i)) {
                                setSelected(i);
                                return;
                            }
                        }

                        // No items left
                        setSelected(-1);
                    }}
                />
                <div
                    className="reference-object-wheel-button button-forward ion-md-arrow-forward"
                    onMouseDown={() => {
                        let index = selected + 1;
                        if (index >= children.length) {
                            index = 0;
                        }

                        for (let i = index; i < children.length; i++) {
                            if (!accepted.includes(i) && !rejected.includes(i)) {
                                setSelected(i);
                                return;
                            }
                        }

                        // No unaccepted items available after current item, so we try from the first item fowards
                        for (let i = 0; i < children.length; i++) {
                            if (!accepted.includes(i) && !rejected.includes(i)) {
                                setSelected(i);
                                return;
                            }
                        }

                        // No unaccepted items available
                        setSelected(-1);
                    }}
                />
            </div>
        </div>
    );
};
