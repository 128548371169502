import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import * as React from 'react';
import {createRoot} from 'react-dom/client';

import {HashRouter, Route, RouteComponentProps, Switch} from 'react-router-dom';

import {Appraise} from './appraise';
import {CompatibilityCheck} from './components/compatibility';
import {ErrorBoundary} from './components/error_boundary';
import {PagePartsConfigurator} from './page_parts_configurator';

function getIds(element: HTMLElement) {
    const stringAppraisalId = element.getAttribute('data-appraisal-id');
    const appraisalId = stringAppraisalId ? parseInt(stringAppraisalId, 10) : null;
    const stringQuestionSetId = element.getAttribute('data-question-set-id');
    const questionSetId = stringQuestionSetId ? parseInt(stringQuestionSetId, 10) : null;

    return {
        appraisalId,
        questionSetId,
    };
}

export function renderEnvironmentInto(element: HTMLElement) {
    const {appraisalId, questionSetId} = getIds(element);
    if (questionSetId === null) {
        throw new Error('Trying to render appraisal environment without question set ID');
    }

    const root = (
        <React.StrictMode>
            <ErrorBoundary>
                <CompatibilityCheck>
                    <HashRouter>
                        <Switch>
                            <Route
                                exact
                                path="/page-parts-configuration/:pagePartConfigurationId"
                                render={(routeProps: RouteComponentProps<{pagePartConfigurationId: string}>) => (
                                    <PagePartsConfigurator
                                        appraisalId={appraisalId}
                                        questionSetId={questionSetId}
                                        pagePartConfigurationId={parseInt(
                                            routeProps.match.params.pagePartConfigurationId,
                                            10
                                        )}
                                        activePagePartUuid={null}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/page-parts-configuration/:pagePartConfigurationId/:uuid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})"
                                render={(
                                    routeProps: RouteComponentProps<{pagePartConfigurationId: string; uuid: string}>
                                ) => {
                                    return (
                                        <PagePartsConfigurator
                                            appraisalId={appraisalId}
                                            questionSetId={questionSetId}
                                            pagePartConfigurationId={parseInt(
                                                routeProps.match.params.pagePartConfigurationId,
                                                10
                                            )}
                                            activePagePartUuid={routeProps.match.params.uuid ?? null}
                                        />
                                    );
                                }}
                            />
                            <Route
                                render={() => <Appraise appraisalId={appraisalId} questionSetId={questionSetId} />}
                            />
                        </Switch>
                    </HashRouter>
                </CompatibilityCheck>
            </ErrorBoundary>
        </React.StrictMode>
    );

    createRoot(element).render(root);
}
