import {ReferenceObjectAnswer, referenceObjectAnswerFromSale} from '../../models/reference_object_answer';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {ReferenceObjectProvider} from '../../../../../../../../../business/reference_object_provider';
import {isEmpty} from '../../../../../../../../../../support/util';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';
import {
    ReferenceSubscriptions,
    ReferenceSubscriptionType,
} from '../../../../../../../../../models/reference_subscriptions';

// Both ortec and altum (via ortec) support fetching sale details by address
const subscriptionTypesWithSaleByAddress = [ReferenceSubscriptionType.ORTEC, ReferenceSubscriptionType.ALTUM];

export class AddCustomReferenceObjectModalPresenter implements Presenter {
    @observable public postalCode = '';
    @observable public houseNumber = '';
    @observable public letter = '';
    @observable public fetchSaleDetails = false;
    @observable public hasError = false;
    @observable public errorMessage = '';
    @observable public submitting = false;

    @computed
    public get isDisabled(): boolean {
        return this.submitting;
    }

    @computed
    public get showSaleDetailsOption() {
        const hasSaleDetailsSubscription =
            this.subscriptions?.subscriptions.some((s) => subscriptionTypesWithSaleByAddress.includes(s.type)) ?? false;
        return this.setType === SetType.SOLD && !hasSaleDetailsSubscription;
    }

    constructor(
        private propsOnAddAndOpenModal: (referenceObjectAnswer: ReferenceObjectAnswer) => void,
        private referenceObjectsAnswers: ReferenceObjectAnswer[],
        private referenceObjectInteractor: ReferenceObjectProvider,
        private subscriptions: ReferenceSubscriptions | null,
        private setType: SetType
    ) {
        makeObservable(this);
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public async onSubmitClick() {
        try {
            runInAction(() => {
                this.submitting = true;
                this.hasError = false;
                this.errorMessage = '';
            });

            if (!this.containsOnlyNumbers(this.houseNumber) && this.letter === '') {
                runInAction(() => {
                    const regex = /^(?<houseNumber>\d+)\D??(?<addition>[a-zA-Z0-9]*)$/;
                    const match = this.houseNumber.match(regex);
                    if (
                        match &&
                        match.groups &&
                        !isEmpty(match.groups['houseNumber']) &&
                        !isEmpty(match.groups['addition'])
                    ) {
                        this.houseNumber = match.groups['houseNumber'];
                        this.letter = match.groups['addition'];
                    }
                });
            }

            const sale = await this.referenceObjectInteractor.getSaleByAddress(
                this.postalCode,
                this.houseNumber,
                this.letter,
                this.setType,
                this.fetchSaleDetails
            );

            const existingInSet = this.referenceObjectsAnswers.find(
                (a) =>
                    a.referenceObject.adres.postcode === sale.postalCode &&
                    a.referenceObject.adres.huisnummer === sale.houseNumber &&
                    a.referenceObject.adres.huisnummerToevoeging === sale.letter
            );

            if (!existingInSet) {
                this.propsOnAddAndOpenModal(referenceObjectAnswerFromSale(sale, null, sale.images ?? null));
            } else {
                runInAction(() => {
                    this.hasError = true;
                    this.errorMessage =
                        'De referentieverkoop die je probeert toe te voegen is al toegevoegd aan deze waardering.';
                });
            }
        } catch (e) {
            runInAction(() => {
                this.hasError = true;
                if (e === 'address not found') {
                    this.errorMessage = 'Dit adres is helaas niet bekend.';
                } else {
                    this.errorMessage =
                        'Er kon in het register geen referentieverkoop gevonden worden voor het ingevoerde adres.';
                }
            });
        } finally {
            runInAction(() => {
                this.submitting = false;
            });
        }
    }

    @action
    public onPostalCodeChange(value: string) {
        this.postalCode = value;
    }

    @action
    public onHouseNumberChange(value: string) {
        this.houseNumber = value;
    }

    @action
    public onLetterChange(value: string) {
        this.letter = value;
    }

    @action
    public onFetchSaleDetailsChange(value: boolean) {
        this.fetchSaleDetails = value;
    }

    private containsOnlyNumbers(input: string) {
        return /^\d+$/.test(input);
    }
}
