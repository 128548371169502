import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from './simple_question_presenter';

import {AdaptedDefaultValuesMap} from '../../../../../models/adapted_default_values_map';
import {Answer} from '../../../../../models/answer';
import {Appraisal} from '../../../../../models/appraisal';
import {NormalQuestionType} from '../../../../../enum/question_type';
import {Question} from '../../../../../models/question';
import {QuestionContainer} from '../question_container';
import {QuestionSet} from '../../../../../models/question_set';
import {createValidationMessagesMap} from '../../../../../../support/create_validation_messages_map';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {isSet} from '../../../../../../support/is_set';
import {observer} from 'mobx-react';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';
import {QuestionTableCollapsible} from '../../../components/question_table/question_table_collapsible';
import {QuestionTableCollapseButton} from '../../../components/question_table/question_table_collapse_button';
import {QuestionLoader} from '../../../components/question_loader';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {shouldShowTitle} from '../../../../../../support/should_show_title';
import {SuperMacros, hasSuperMacros} from '../extensions/super_macros/super_macros';
import {classNames} from '../../../../../../support/classnames';
import {GroupPesenter} from './group_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {
    questionSet: QuestionSet;
    onChange?: (appraisal: Appraisal) => void;
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
    showTitle?: boolean;
}

export const Group: React.FC<OwnProps> = observer(function Group(props) {
    const presenter = usePresenter(
        (container) =>
            new GroupPesenter(
                container.business.attachmentQuestionsInteractor(props.appraisal, props.questionSet),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );

    if (presenter.isHidden) {
        return null;
    }

    const answer = presenter.answer;
    if (answer === undefined) {
        return <QuestionLoader />;
    }

    const superMacros = hasSuperMacros(props.question);

    function thisShouldShowTitle() {
        if (props.question.type === NormalQuestionType.GROUP_COMPACT) {
            return false;
        }
        // undefined = top level, so no title
        if (props.parentAnswerUuid === undefined) {
            return false;
        }
        return shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel);
    }

    function renderTitle() {
        const title = thisShouldShowTitle() ? (
            props.renderingContext === RenderingContextType.COMPACT_GRID_COLUMNS ? (
                <h2 className="mt-0 mb-0">{props.question.contents}</h2>
            ) : (
                <h2>{props.question.contents}</h2>
            )
        ) : null;

        if (superMacros) {
            return <SuperMacros {...props} title={title ?? undefined} />;
        }

        return title;
    }

    function renderDefault(childrenForQuestion: Question[], answer: Answer) {
        return (
            <div className={classNames('group-container', {'super-macro-group': superMacros})}>
                {renderTitle()}
                <div className="group-duplication-content">
                    {presenter.duplicationSources.length > 0 && presenter.duplicationSource !== null && (
                        <div
                            className="form-group"
                            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
                        >
                            <label htmlFor="duplication-target">Neem antwoorden over van:</label>
                            <div className="input-group">
                                <select
                                    className="form-control custom-select"
                                    name="duplication-target"
                                    id="duplication-target"
                                    value={presenter.duplicationSource.answer.uuid}
                                    disabled={props.disabled}
                                    onChange={(e) => {
                                        const uuid = e.target.value;
                                        const target = presenter.duplicationSources.find(
                                            (s) => s && s.answer.uuid === uuid
                                        );
                                        if (target) {
                                            presenter.onDuplicationSourceChange(target);
                                        }
                                    }}
                                >
                                    {presenter.duplicationSources.map((target) => {
                                        if (target === null) {
                                            return null;
                                        }

                                        return (
                                            <option key={target.answer.uuid} value={target.answer.uuid}>
                                                {target.label}
                                            </option>
                                        );
                                    })}
                                </select>
                                <button
                                    className="btn btn-sm"
                                    title={
                                        presenter.duplicationSource !== null
                                            ? `Neem de antwoorden van ${presenter.duplicationSource.label} over.`
                                            : undefined
                                    }
                                    disabled={props.disabled}
                                    onClick={() => presenter.duplicateRecursively()}
                                >
                                    Overnemen
                                </button>
                                <div className="separator"></div>
                                <button
                                    className="btn btn-sm"
                                    title={`Wis alle antwoorden van ${props.question.contents}`}
                                    disabled={props.disabled ?? presenter.answer === undefined}
                                    onClick={() => presenter.clearRecursively()}
                                >
                                    Wissen
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="group-container-content">
                    {childrenForQuestion.map((child) => (
                        <QuestionContainer
                            key={child.uuid}
                            disabled={props.disabled}
                            appraisal={props.appraisal}
                            question={child}
                            onChange={props.onChange}
                            questionSet={props.questionSet}
                            validationErrors={props.validationErrors}
                            validationMessages={createValidationMessagesMap(
                                presenter.validationMessages,
                                props.validationMessages
                            )}
                            forceShowValidationMessages={props.forceShowValidationMessages}
                            parentAnswerUuid={answer.uuid}
                            adaptedDefaultValues={props.adaptedDefaultValues}
                            disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                            hiddenQuestionTypes={props.hiddenQuestionTypes}
                            pagePartsSet={props.pagePartsSet}
                            activePagePart={props.activePagePart}
                            renderingContext={props.renderingContext}
                            questionRenderingData={props.questionRenderingData}
                        />
                    ))}
                </div>
            </div>
        );
    }

    function renderCompactGrid(childrenForQuestion: Question[], answer: Answer) {
        const title = renderTitle();
        return (
            <>
                {title && <div className="question-table-seperator" />}
                <div
                    className={classNames('group-container group-container-compact', {
                        'super-macro-group': superMacros,
                    })}
                >
                    {title && (
                        <div className="row">
                            <div className="col-8 d-flex align-items-center pr-0">{title}</div>
                            <div className="col-4 d-flex justify-content-center pr-0">
                                <QuestionTableCollapseButton />
                            </div>
                        </div>
                    )}
                    {superMacros && <SuperMacros {...props} />}
                    <QuestionTableCollapsible>
                        {childrenForQuestion.map((child) => (
                            <QuestionContainer
                                key={child.uuid}
                                disabled={props.disabled}
                                appraisal={props.appraisal}
                                question={child}
                                onChange={props.onChange}
                                questionSet={props.questionSet}
                                validationErrors={props.validationErrors}
                                validationMessages={createValidationMessagesMap(
                                    presenter.validationMessages,
                                    props.validationMessages
                                )}
                                forceShowValidationMessages={props.forceShowValidationMessages}
                                parentAnswerUuid={answer.uuid}
                                adaptedDefaultValues={props.adaptedDefaultValues}
                                disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                                hiddenQuestionTypes={props.hiddenQuestionTypes}
                                pagePartsSet={props.pagePartsSet}
                                activePagePart={props.activePagePart}
                                renderingContext={props.renderingContext}
                                questionRenderingData={props.questionRenderingData}
                            />
                        ))}
                    </QuestionTableCollapsible>
                </div>
            </>
        );
    }

    function renderBuildingCostsGroup(childrenForQuestion: Question[], answer: Answer) {
        const children = childrenForQuestion.filter(
            (q) =>
                [
                    NormalQuestionType.BUILDING_DEFECTS_COSTS,
                    NormalQuestionType.BUILDING_DEFECTS,
                    NormalQuestionType.BUILDING_COSTS_CONDITION,
                ].includes(q.type as NormalQuestionType) === false
        );
        const type = children[0];
        const other = type !== undefined ? children.filter((c) => c.uuid !== type.uuid) : [];
        const buildingCosts = childrenForQuestion.find((q) =>
            [NormalQuestionType.BUILDING_DEFECTS_COSTS, NormalQuestionType.BUILDING_DEFECTS].includes(
                q.type as NormalQuestionType
            )
        );
        const buildingCondition = childrenForQuestion.find(
            (q) => q.type === NormalQuestionType.BUILDING_COSTS_CONDITION
        );

        return (
            <>
                <div className="form-building-costs-group">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <QuestionContainer
                                key={type.uuid}
                                disabled={props.disabled}
                                appraisal={props.appraisal}
                                question={type}
                                questionSet={props.questionSet}
                                parentAnswerUuid={answer.uuid}
                                validationErrors={props.validationErrors}
                                validationMessages={createValidationMessagesMap(
                                    presenter.validationMessages,
                                    props.validationMessages
                                )}
                                forceShowValidationMessages={props.forceShowValidationMessages}
                                adaptedDefaultValues={props.adaptedDefaultValues}
                                disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                                hiddenQuestionTypes={props.hiddenQuestionTypes}
                                pagePartsSet={props.pagePartsSet}
                                activePagePart={props.activePagePart}
                                renderingContext={props.renderingContext}
                                questionRenderingData={props.questionRenderingData}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-building-costs-pair">
                                {isSet(buildingCondition) && (
                                    <QuestionContainer
                                        key={buildingCondition.uuid}
                                        disabled={props.disabled}
                                        appraisal={props.appraisal}
                                        question={buildingCondition}
                                        questionSet={props.questionSet}
                                        parentAnswerUuid={answer.uuid}
                                        validationErrors={props.validationErrors}
                                        validationMessages={createValidationMessagesMap(
                                            presenter.validationMessages,
                                            props.validationMessages
                                        )}
                                        forceShowValidationMessages={props.forceShowValidationMessages}
                                        adaptedDefaultValues={props.adaptedDefaultValues}
                                        disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                                        pagePartsSet={props.pagePartsSet}
                                        activePagePart={props.activePagePart}
                                        renderingContext={props.renderingContext}
                                        questionRenderingData={props.questionRenderingData}
                                    />
                                )}
                                {isSet(buildingCosts) && (
                                    <QuestionContainer
                                        key={buildingCosts.uuid}
                                        disabled={props.disabled}
                                        appraisal={props.appraisal}
                                        question={buildingCosts}
                                        questionSet={props.questionSet}
                                        parentAnswerUuid={answer.uuid}
                                        validationErrors={props.validationErrors}
                                        validationMessages={createValidationMessagesMap(
                                            presenter.validationMessages,
                                            props.validationMessages
                                        )}
                                        forceShowValidationMessages={props.forceShowValidationMessages}
                                        adaptedDefaultValues={props.adaptedDefaultValues}
                                        disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                                        pagePartsSet={props.pagePartsSet}
                                        activePagePart={props.activePagePart}
                                        renderingContext={props.renderingContext}
                                        questionRenderingData={props.questionRenderingData}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {other.map((child) => (
                        <div key={child.uuid} className="col-12">
                            <QuestionContainer
                                key={child.uuid}
                                disabled={props.disabled}
                                appraisal={props.appraisal}
                                question={child}
                                questionSet={props.questionSet}
                                parentAnswerUuid={answer.uuid}
                                validationErrors={props.validationErrors}
                                validationMessages={createValidationMessagesMap(
                                    presenter.validationMessages,
                                    props.validationMessages
                                )}
                                forceShowValidationMessages={props.forceShowValidationMessages}
                                adaptedDefaultValues={props.adaptedDefaultValues}
                                disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                                hiddenQuestionTypes={props.hiddenQuestionTypes}
                                pagePartsSet={props.pagePartsSet}
                                activePagePart={props.activePagePart}
                                renderingContext={props.renderingContext}
                                questionRenderingData={props.questionRenderingData}
                            />
                        </div>
                    ))}
                </div>
            </>
        );
    }

    let component: React.ReactNode = null;

    const childrenForQuestion = findChildrenForQuestion(props.question, props.questionSet);
    if (props.question.type === NormalQuestionType.BUILDING_COSTS_GROUP) {
        component = renderBuildingCostsGroup(childrenForQuestion, answer);
    } else if (props.renderingContext === RenderingContextType.COMPACT_GRID_COLUMNS) {
        component = renderCompactGrid(childrenForQuestion, answer);
    } else {
        component = renderDefault(childrenForQuestion, answer);
    }

    return component;
});
