import {AutoMLVersion} from './automl_photo_content_predicter';
import {PhotoContentHandler} from './automl_photo_handler';
import {PhotoRecognizedAs} from './automl_photo_recognition';
import {Prediction} from './photo_content_predicter';

export class NetworkBoundaryPhotoContentPredicter implements PhotoContentHandler {
    private prepared = false;

    constructor(private delegate: PhotoContentHandler) {}

    public async getPrediction(file: File): Promise<Prediction | null> {
        if (this.recognitionEnabled()) {
            return this.delegate.getPrediction(file);
        }
        return null;
    }

    public getVersionPrediction(version: AutoMLVersion, uploadedFile: File): Promise<Prediction | null> {
        return this.delegate.getVersionPrediction(version, uploadedFile);
    }

    public async prepare(): Promise<void> {
        if (this.recognitionEnabled()) {
            await this.delegate.prepare();
            this.prepared = true;
        }
    }

    public getRecognition(file: File): Promise<PhotoRecognizedAs | null> {
        return this.delegate.getRecognition(file);
    }

    public getDetailsFromPhoto(photo: PhotoRecognizedAs, file: File): Promise<Prediction[]> {
        return this.delegate.getDetailsFromPhoto(photo, file);
    }

    private recognitionEnabled() {
        //When the appraisal environment was prepared at an earlier stage we can still use the model
        if (this.prepared) {
            return true;
        }

        if (!navigator.onLine) {
            return false;
        }

        const connection =
            (navigator as unknown as {connection?: {effectiveType: string}}).connection ||
            (navigator as unknown as {mozConnection?: {effectiveType: string}}).mozConnection ||
            (navigator as unknown as {webkitConnection?: {effectiveType: string}}).webkitConnection;
        if (!connection) {
            //We're probably on iOS, so just force them to download it
            return true;
        }
        const type = connection.effectiveType;

        return type === '4g'; //WiFi connections are registered as 4g
    }
}
