import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';
import {isEmpty, isNumeric} from '../../../../../../support/util';

import {AlternativeAdaptedDefaultAnswer} from '../components/alternative_adapted_default_answer';
import {Label} from '../components/label';
import NumberFormat from 'react-number-format';
import {ValidationMessages} from '../components/validation_messages';
import {classNames} from '../../../../../../support/classnames';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {observer} from 'mobx-react';
import {CompactEditable} from '../../../components/compact_editable';
import {formatMoney} from '../../../support/format_money';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {
    step: string;
    negative: boolean;
}

export const CurrencyQuestion: React.FC<OwnProps> = observer(function CurrencyQuestion(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));

    if (presenter.isHidden) {
        return null;
    }

    function renderRounded() {
        return (
            <NumberFormat
                value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                displayType="input"
                type="text"
                className="form-control"
                disabled={props.disabled ?? presenter.isDisabled}
                decimalScale={0}
                allowNegative={props.negative}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value;
                    if (isEmpty(value)) {
                        presenter.onChange(value, false);
                    } else if (isNumeric(value) && isNumeric(parseInt(value, 10))) {
                        presenter.onChange('' + parseInt(value, 10), false);
                    } else if (value === '-') {
                        presenter.onChange(value, false);
                    }
                }}
                onBlur={() => presenter.onTouched()}
            />
        );
    }

    function renderFree() {
        return (
            <NumberFormat
                id={presenter.name}
                name={presenter.name}
                value={
                    presenter.answer && presenter.answer.contents !== null
                        ? presenter.answer.contents.replace('.', ',')
                        : ''
                }
                decimalSeparator=","
                displayType="input"
                type="text"
                className="form-control"
                decimalScale={2}
                disabled={props.disabled || presenter.isDisabled}
                allowNegative={props.negative}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = event.target.value?.replace(',', '.'); // Replace comma with dot to get a valid number
                    if (!isEmpty(value)) {
                        if (isNumeric(value)) {
                            presenter.onChange(value, false);
                        } else if (value === '-') {
                            presenter.onChange(value, false);
                        }
                    } else {
                        presenter.onChange('', false);
                    }
                }}
                onBlur={() => presenter.onTouched()}
            />
        );
    }

    function renderCompact() {
        return (
            <>
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    disableHistory
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <div>
                    {presenter.answer?.contents ? formatMoney(Number(presenter.answer.contents)) : <i>Niet ingevuld</i>}
                </div>
            </>
        );
    }

    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'automation-filled': presenter.filledByAutomator !== null,
            })}
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
        >
            <CompactEditable
                renderingContext={props.renderingContext}
                compact={() => renderCompact()}
                question={props.question}
                answer={presenter.answer}
            >
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <AlternativeAdaptedDefaultAnswer
                    adaptedDefaultValue={props.adaptedDefaultValue}
                    currentAnswer={presenter.answer}
                    disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                    onChange={(value) => presenter.onChange(value)}
                />
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text">&euro;</div>
                    </div>
                    {props.step === '1' ? renderRounded() : renderFree()}
                </div>
            </CompactEditable>
            <ValidationMessages
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={presenter.answer}
                validationMessages={filterValidationMessages(
                    props.question,
                    presenter.answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
        </div>
    );
});
