import * as React from 'react';

import {FunctionComponent} from 'react';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';
import {Textarea} from '../../../inputs/textarea';

interface OwnProps {
    referenceObjectAnswer: ReferenceObjectAnswer;
    onExtensionChange: (extension: string) => void;
    isFrozen: boolean;
}

export const Extension: FunctionComponent<OwnProps> = ({referenceObjectAnswer, onExtensionChange, isFrozen}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-sm-12">
                <Textarea
                    isRequired
                    label="Bij-, op en/of aanbouwen"
                    name="input-aanbouw"
                    value={validationInstituteReferentieObject.aanbouw}
                    macros={[
                        'Aanbouw woonruimte',
                        'Berging / schuur (aangeb.)',
                        'Berging / schuur (vrijst.)',
                        'Balkon',
                        'Carport',
                        'Dakkapel',
                        'Dakterras',
                        'Garage (dubbel)',
                        'Garage (enkel)',
                        'Garage (enkel met kap)',
                        'Overkapping / luifel',
                        'Kelder / souterrain',
                        'Parkeerplaats',
                        'Serre',
                        'Tuinhuis',
                    ]}
                    onChange={onExtensionChange}
                    disabled={isFrozen}
                />
            </div>
        </div>
    );
};
