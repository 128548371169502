import * as React from 'react';
import {observer} from 'mobx-react';

import {Loader} from '../../appraising/appraise/ui/components/loader';
import {NotificationMessage} from '../models/notification_message';
import {NotificationsWidgetPresenter} from './notifications_widget_presenter';
import {classNames} from '../../support/classnames';
import {usePresenter} from '../support/use_presenter';

interface OwnProps {}

export const NotificationsWidget: React.FunctionComponent<OwnProps> = observer(() => {
    const presenter = usePresenter(
        (container) =>
            new NotificationsWidgetPresenter(container.notificationsInteractor, container.notificationsStackInteractor)
    );

    if (presenter.loading) {
        return (
            <span className="row">
                <span className="col-12 text-center">
                    <Loader isSmall icon={'ion-md-notifications'} />
                </span>
            </span>
        );
    }
    if (
        presenter.notifications === undefined ||
        presenter.notifications === null ||
        presenter.notifications.length === 0
    ) {
        return (
            <ul className="list-simple">
                <li>Er zijn geen berichten.</li>
            </ul>
        );
    }

    return (
        <ul className="list-simple">
            {presenter.notifications.map((notification: NotificationMessage) => {
                return (
                    <li
                        key={notification.id}
                        className={classNames(
                            {'list-simple-gray': !notification.isNew && !notification.isReminded},
                            {'list-simple-plus': notification.isNew},
                            {'list-simple-middle': notification.isReminded}
                        )}
                    >
                        <a href={`/account/me/notifications/${notification.id}/show`}>
                            <span className="row">
                                <span className="col-11">
                                    <span className="ellipsis">
                                        <strong
                                            className={classNames({
                                                normal: !notification.isNew && !notification.isReminded,
                                            })}
                                        >
                                            {notification.userName && (
                                                <>
                                                    <span className="badge badge-secondary">
                                                        Aan: {notification.userName}
                                                    </span>{' '}
                                                </>
                                            )}
                                            <span>{notification.subject} </span>
                                            <span className="thin text-muted">
                                                {notification.createdAt.toLocaleDateString('nl-NL')}{' '}
                                                {notification.createdAt.toLocaleTimeString('nl-NL')}
                                            </span>
                                        </strong>
                                    </span>
                                </span>
                                <span className="col-11">
                                    <p className="ellipsis">{notification.content}</p>
                                </span>
                            </span>
                        </a>

                        <div className="list-options">
                            <div className="list-options">
                                <div className="dropdown dropdown-action-menu">
                                    <div
                                        className="dropdown-toggle"
                                        data-toggle="dropdown"
                                        role="button"
                                        aria-expanded="true"
                                        id="dropdown-notification-{{$notification->id}}"
                                    >
                                        <span className="ion-md-more"></span>
                                    </div>
                                    <ul
                                        className="dropdown-menu dropdown-menu-right"
                                        aria-labelledby="dropdown-notification-{{$notification->id}}"
                                    >
                                        <li className="dropdown-item">
                                            <a href={`/account/me/notifications/${notification.id}/show`}>
                                                <span className="ion-md-eye"></span>
                                                Bekijk bericht
                                            </a>
                                        </li>
                                        <li className="dropdown-item">
                                            <button onClick={() => presenter.onQuickRemind(notification.id)}>
                                                <span className="ion-md-notifications-off"></span>
                                                Herinner over 2 dagen
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
});
