import * as React from 'react';

import {ReferenceObject, sourceToClassName} from '../models/reference_object';

import {ActiveSetDefinition} from '../reference_objects_question_presenter';
import {Appraisal} from '../../../../../../../../models/appraisal';
import {Loader} from '../../../../../../components/loader';
import {QuestionSet} from '../../../../../../../../models/question_set';
import ReferenceObjectAddress from '../components/reference_object_address';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {ReferenceObjectTilePresenter} from './reference_object_tile_presenter';
import {StatusDetailListForBuildYear} from '../../../../../../../../components/status_details_list_for_build_year';
import {StatusDetailListForEnergyLabel} from '../../../../../../../../components/status_details_list_for_energy_label';
import {StatusDetailListForReferenceValue} from '../../../../../../../../components/status_details_list_for_reference_value';
import {StatusDetailListForStatus} from '../../../../../../../../components/status_details_list_for_status';
import {classNames} from '../../../../../../../../../support/classnames';
import {format} from 'date-fns';
import {isEmpty} from '../../../../../../../../../support/util';
import {observer} from 'mobx-react';

import {referenceObjectImageUrlPairs} from '../internal/reference_sale_image_urls';
import {getObjectPrice, getReferenceObjectPrice} from '../internal/get_object_price';
import {ReferenceObjectDetails} from './reference_object_tile/reference_object_details';
import {AppraiseModel, isAppraiseModel} from '../../../../../../../../enum/appraise_model';
import {usePresenter} from '../../../../../../../../../support/presenter/use_presenter';
import {ImageViewer} from '../../../../../../../../components/image_viewer/image_viewer';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    referenceObject: ReferenceObject;
    activeSetDefinition: ActiveSetDefinition;
    referenceObjectAnswer: ReferenceObjectAnswer | null;
    index: number;
    buildYear: number | null;
    isMapHovering: boolean;
    isMapSelected: boolean;
    isCompare: boolean;
    isSavingAnswer: boolean;
    isPreselected: boolean;
    canAdd: boolean;
    onAddAndOpenModal: (
        referenceObjectAnswer: ReferenceObjectAnswer,
        referenceObject?: ReferenceObject
    ) => Promise<void>;
    onRemove: (referenceObjectAnswer: ReferenceObjectAnswer) => void;
    onHoverChange: (referenceObject: ReferenceObject | null) => void;
    onClickChange: (referenceObject: ReferenceObject | null) => void;
    showAnswerModal: (referenceObject: ReferenceObject, referenceObjectAnswer: ReferenceObjectAnswer) => void;
    showDetailsModal: (referenceObject: ReferenceObject) => void;
}

export const ReferenceObjectTile: React.FC<OwnProps> = observer(function ReferenceObjectTile(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectTilePresenter(
                props.referenceObject,
                props.onAddAndOpenModal,
                container.business.referenceObjectProvider(props.appraisal, props.questionSet),
                props.appraisal
            )
    );

    const {referenceObject, referenceObjectAnswer} = props;
    const pairs = referenceObjectImageUrlPairs(referenceObject, referenceObjectAnswer);

    let source = referenceObject.source;
    if (
        referenceObjectAnswer !== null &&
        referenceObjectAnswer.referenceObject !== null &&
        referenceObjectAnswer.referenceObject.bronGegevens
    ) {
        source = referenceObjectAnswer.referenceObject.bronGegevens;
    }

    // Slick slider refreshes when parent key changes.
    const sliderKey = JSON.stringify(pairs);

    function renderButtons() {
        if (presenter.isAdding) {
            return (
                <button className="ion-md-circle">
                    <Loader isSmall />
                    Ophalen...
                </button>
            );
        } else if (props.isSavingAnswer) {
            return (
                <button className="ion-md-circle">
                    <Loader isSmall />
                    Opslaan...
                </button>
            );
        }

        const referenceObjectAnswer = props.referenceObjectAnswer;
        return referenceObjectAnswer !== null ? (
            <>
                <button
                    className="ion-md-trash"
                    onClick={() => confirm('Weet je het zeker?') && props.onRemove(referenceObjectAnswer)}
                    disabled={props.isSavingAnswer || !presenter.canDelete}
                >
                    Verwijder
                </button>
                <button
                    className="ion-md-create"
                    onClick={() => props.showAnswerModal(props.referenceObject, referenceObjectAnswer)}
                    disabled={props.isSavingAnswer}
                >
                    Bewerk
                </button>
            </>
        ) : (
            <>
                <button
                    className="ion-md-information-circle"
                    onClick={() => props.showDetailsModal(props.referenceObject)}
                    disabled={props.isSavingAnswer}
                >
                    Details
                </button>
                <button
                    className={classNames('ion-md-add-circle', {
                        disabled: !props.canAdd || props.isSavingAnswer,
                    })}
                    aria-disabled={!props.canAdd || props.isSavingAnswer}
                    onClick={() => props.canAdd && presenter.onAdd()}
                    disabled={props.isSavingAnswer}
                >
                    Voeg toe
                </button>
            </>
        );
    }

    function renderCompare(referenceObjectAnswer: ReferenceObjectAnswer) {
        return (
            <>
                <div className="col-6 text-left">
                    <dl>
                        <dt>Koopsom</dt>
                        <dd>
                            {referenceObjectAnswer.referenceObject.verkoopprijs !== null
                                ? `€ ${referenceObjectAnswer.referenceObject.verkoopprijs.toLocaleString('nl-NL')}`
                                : '-'}
                        </dd>
                    </dl>
                </div>
                <div className="col-6 text-right">
                    <dl>
                        <dt>Gecorr. koopsom</dt>
                        <dd>
                            {referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs !== null
                                ? `€ ${referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs.toLocaleString(
                                      'nl-NL'
                                  )}`
                                : '-'}
                        </dd>
                    </dl>
                </div>
                <div className="col-12">
                    <dl>
                        <dt>Verkoopdatum</dt>
                        <dd>
                            {referenceObjectAnswer.referenceObject.verkoopdatum !== null &&
                            !isEmpty(referenceObjectAnswer.referenceObject.verkoopdatum)
                                ? format(new Date(referenceObjectAnswer.referenceObject.verkoopdatum), 'dd-MM-yyyy')
                                : '-'}
                        </dd>
                    </dl>
                </div>
                <div className="col-12">
                    <StatusDetailListForStatus
                        label="Woningtype"
                        value={referenceObjectAnswer.referenceObject.woningType}
                        status={referenceObjectAnswer.referenceObject.woningTypeStatus}
                    />
                    <StatusDetailListForBuildYear
                        label="Bouwjaar"
                        value={referenceObjectAnswer.referenceObject.bouwjaar}
                        referenceValue={props.buildYear}
                    />
                    <StatusDetailListForReferenceValue
                        label="Gebruiksoppervlakte wonen"
                        value={
                            referenceObjectAnswer.referenceObject.gebruiksOppervlakte
                                ? referenceObjectAnswer.referenceObject.gebruiksOppervlakte
                                : null
                        }
                        referenceValue={props.activeSetDefinition.setDefinition.surfaceArea}
                        unit="m²"
                    />
                    <StatusDetailListForReferenceValue
                        label="Perceeloppervlakte"
                        value={
                            referenceObjectAnswer.referenceObject.perceelOppervlakte
                                ? referenceObjectAnswer.referenceObject.perceelOppervlakte
                                : null
                        }
                        referenceValue={props.activeSetDefinition.setDefinition.plotArea}
                        unit="m²"
                    />
                    <StatusDetailListForReferenceValue
                        label="Inhoud"
                        value={
                            referenceObjectAnswer.referenceObject.inhoud
                                ? referenceObjectAnswer.referenceObject.inhoud
                                : null
                        }
                        referenceValue={props.activeSetDefinition.setDefinition.volume}
                        unit="m³"
                    />
                    <StatusDetailListForEnergyLabel
                        label="Energielabel"
                        value={referenceObjectAnswer.referenceObject.energielabel}
                        referenceValue={props.activeSetDefinition.setDefinition.energyLabel}
                    />
                    <StatusDetailListForStatus
                        label="Onderhoudssituatie"
                        value={referenceObjectAnswer.referenceObject.onderhoudsSituatieUitleg}
                        status={referenceObjectAnswer.referenceObject.onderhoudsSituatieStatus}
                        useSmallText={true}
                    />
                    {isAppraiseModel(props.appraisal, [AppraiseModel.WOCO2016, AppraiseModel.MODEL2018]) &&
                        !isEmpty(referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus) &&
                        referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus !== undefined &&
                        referenceObjectAnswer.referenceObject.luxeDoelmatigheidUitleg !== undefined && (
                            <StatusDetailListForStatus
                                label="Mate van luxe en doelmatigheid"
                                value={referenceObjectAnswer.referenceObject.luxeDoelmatigheidUitleg}
                                status={referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus}
                                useSmallText={true}
                            />
                        )}
                    {isAppraiseModel(props.appraisal, [AppraiseModel.MODEL2021, AppraiseModel.DESK2021]) &&
                        !isEmpty(referenceObjectAnswer.referenceObject.mateVanLuxeStatus) &&
                        referenceObjectAnswer.referenceObject.mateVanLuxeStatus !== undefined &&
                        referenceObjectAnswer.referenceObject.mateVanLuxeUitleg !== undefined && (
                            <StatusDetailListForStatus
                                label="Mate van luxe"
                                value={referenceObjectAnswer.referenceObject.mateVanLuxeUitleg}
                                status={referenceObjectAnswer.referenceObject.mateVanLuxeStatus}
                                useSmallText={true}
                            />
                        )}
                    {isAppraiseModel(props.appraisal, [AppraiseModel.MODEL2021, AppraiseModel.DESK2021]) &&
                        !isEmpty(referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus) &&
                        referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus !== undefined &&
                        referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg !== undefined && (
                            <StatusDetailListForStatus
                                label="Mate van doelmatigheid"
                                value={referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg}
                                status={referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus}
                                useSmallText={true}
                            />
                        )}
                    {isAppraiseModel(props.appraisal, [AppraiseModel.WOCO2016, AppraiseModel.MODEL2018]) &&
                        !isEmpty(referenceObjectAnswer.referenceObject.kwaliteitConditieStatus) &&
                        referenceObjectAnswer.referenceObject.kwaliteitConditieStatus !== undefined &&
                        referenceObjectAnswer.referenceObject.kwaliteitConditieUitleg !== undefined && (
                            <StatusDetailListForStatus
                                label="Kwaliteit / conditie"
                                value={referenceObjectAnswer.referenceObject.kwaliteitConditieUitleg}
                                status={referenceObjectAnswer.referenceObject.kwaliteitConditieStatus}
                                useSmallText={true}
                            />
                        )}
                    <StatusDetailListForStatus
                        label="Ligging"
                        value={referenceObjectAnswer.referenceObject.liggingUitleg}
                        status={referenceObjectAnswer.referenceObject.liggingStatus}
                        useSmallText={true}
                    />
                    <StatusDetailListForStatus
                        label="Bij-, op en/of aanbouwen"
                        value={referenceObjectAnswer.referenceObject.aanbouw}
                        status={referenceObjectAnswer.referenceObject.aanbouwStatus}
                        useSmallText={true}
                    />
                </div>
            </>
        );
    }

    function renderDefaultDetails(
        referenceObjectAnswer: ReferenceObjectAnswer | null,
        referenceObject: ReferenceObject
    ) {
        const period: string | null =
            referenceObjectAnswer !== null &&
            referenceObjectAnswer.referenceObject !== null &&
            referenceObjectAnswer.referenceObject.verkoopdatum !== null &&
            !isEmpty(referenceObjectAnswer.referenceObject.verkoopdatum)
                ? referenceObjectAnswer.referenceObject.verkoopdatum.length > 8
                    ? format(new Date(referenceObjectAnswer.referenceObject.verkoopdatum), 'dd-MM-yyyy')
                    : 'Q' + referenceObjectAnswer.referenceObject.verkoopdatum
                : referenceObject.saleQuarter !== null && !isEmpty(referenceObject.saleQuarter)
                ? referenceObject.saleQuarter.length > 8
                    ? format(new Date(referenceObject.saleQuarter), 'dd-MM-yyyy')
                    : 'Q' + referenceObject.saleQuarter
                : referenceObjectAnswer !== null &&
                  referenceObjectAnswer.referenceObject !== null &&
                  referenceObjectAnswer.referenceObject.ingangsdatumHuur !== null &&
                  referenceObjectAnswer.referenceObject.ingangsdatumHuur !== undefined &&
                  !isEmpty(referenceObjectAnswer.referenceObject.ingangsdatumHuur)
                ? format(new Date(referenceObjectAnswer.referenceObject.ingangsdatumHuur), 'dd-MM-yyyy')
                : '-';

        let priceRange: string | number | null = getObjectPrice(
            props.activeSetDefinition.setDefinition.type,
            referenceObjectAnswer?.referenceObject ?? null
        );
        if (isEmpty(priceRange)) {
            priceRange = getReferenceObjectPrice(props.activeSetDefinition.setDefinition.type, referenceObject ?? null);
        }

        return (
            <div className="col-12">
                <ReferenceObjectDetails
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    referenceSetType={props.activeSetDefinition.setDefinition.type}
                    referenceValuation={props.activeSetDefinition.setDefinition.valuation}
                    referenceSurfaceArea={props.activeSetDefinition.setDefinition.surfaceArea}
                    referencePlotArea={props.activeSetDefinition.setDefinition.plotArea}
                    referenceBuildYear={props.buildYear}
                    referenceVolume={props.activeSetDefinition.setDefinition.volume}
                    referenceEnergyLabel={props.activeSetDefinition.setDefinition.energyLabel}
                    referenceObjectType={props.appraisal.objectType ?? null}
                    floorArea={
                        referenceObjectAnswer?.referenceObject?.gebruiksOppervlakte ?? referenceObject?.floorArea
                    }
                    plotArea={referenceObjectAnswer?.referenceObject?.perceelOppervlakte ?? referenceObject?.plotArea}
                    buildYear={referenceObjectAnswer?.referenceObject?.bouwjaar ?? referenceObject?.buildYear}
                    objectType={referenceObjectAnswer?.referenceObject?.woningType ?? referenceObject?.objectType}
                    energyLabel={referenceObjectAnswer?.referenceObject?.energielabel ?? referenceObject?.energyLabel}
                    priceRange={priceRange}
                    period={period}
                    volume={referenceObjectAnswer?.referenceObject.inhoud ?? referenceObject?.volume}
                />
            </div>
        );
    }

    return (
        <>
            <div
                className={classNames('col-12 col-sm-6 col-md-4 form-grid-item', {
                    selected: referenceObjectAnswer !== null,
                    hovering: props.isMapHovering,
                    'partial-selected': props.isPreselected,
                })}
                key={referenceObject.id}
                onMouseEnter={() => props.onHoverChange(referenceObject)}
                onMouseLeave={() => props.onHoverChange(null)}
            >
                <div className={classNames('reference-logo', sourceToClassName(source))}>&nbsp;</div>
                <div className="form-grid-item-img">
                    <div className="form-grid-item-img-content" key={sliderKey}>
                        <ImageViewer images={pairs.map((pair) => pair.url)} />
                        <div
                            className={classNames('active-icon ion-md-checkmark', {
                                visible: referenceObjectAnswer !== null,
                            })}
                        >
                            &nbsp;
                        </div>
                    </div>
                </div>
                <div className="form-grid-item-body" onClick={() => props.onClickChange(referenceObject)}>
                    <div className="row">
                        <div className="col-12">
                            <div className="grid-item-title grid-item-title-with-avatar">
                                <div
                                    className={classNames('grid-item-title-avatar', {
                                        active: props.isMapSelected,
                                        'grid-item-title-avatar-icon-pin': !props.isPreselected,
                                        'grid-item-title-avatar-icon-gray': props.isPreselected,
                                        'ion-md-heart': props.isPreselected,
                                    })}
                                />
                                <p>
                                    {referenceObjectAnswer !== null && (
                                        <span className="badge-container">
                                            <span className="badge badge-success">#{props.index + 1}</span>
                                        </span>
                                    )}
                                    {
                                        <ReferenceObjectAddress
                                            referenceObject={referenceObject}
                                            referenceObjectAnswer={referenceObjectAnswer}
                                        />
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="col-12">
                            {props.referenceObject.hasWarning ? (
                                <span className="form-grid-item-alert">
                                    <span className="ion-md-alert">&nbsp;</span>
                                    {props.referenceObject.warning}
                                </span>
                            ) : null}
                        </div>
                        {referenceObjectAnswer !== null && props.isCompare
                            ? renderCompare(referenceObjectAnswer)
                            : renderDefaultDetails(referenceObjectAnswer, referenceObject)}
                    </div>
                </div>
                <div className="form-grid-item-footer d-flex justify-content-between">{renderButtons()}</div>
            </div>
        </>
    );
});
