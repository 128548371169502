import $ from 'jquery';

(() => {
    const form = $(
        'form#organisation-user, form#organisation-invite-link, #appraisers-filter-modal, form#edit-appraiser-preferences-form, form#edit-preferences-form'
    );
    if (!form) {
        return;
    }

    $<HTMLInputElement>('#all_appraisers_filter').on('change', function (e) {
        const isChecked = e.target.checked;

        const otherFilters = $(
            'input[name="dashboard_appraiser_id_filter[]"], input[name="dashboard_appraiser_id[]"], input[name="appraiser_id[]"]'
        );

        if (isChecked) {
            otherFilters.prop('checked', true);
            otherFilters.prop('disabled', true);
        } else {
            otherFilters.prop('checked', false);
            otherFilters.prop('disabled', false);
        }
    });
})();
