export interface ExternalAppointment {
    id?: number;
    appointmentType: AppointmentType;
    paymentByType: PaymentByType;
    appraisalId: number;
    requestedByUserId?: number;
    createdAt?: Date | null;
    appointmentDate?: string | null;
    comment: string;
}

export enum AppointmentType {
    BUILDING_INSPECTOR = 'building_inspector',
}

export enum PaymentByType {
    CLIENT = 'client',
    APPRAISER = 'appraiser',
    OWNER = 'owner',
}
