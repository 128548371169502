import $ from 'jquery';
import {buildQueryStringFromInputs} from './build_query_string_from_inputs';

/**
 * Wizard to create iframe code
 */
(() => {
    const iframeCodeInput = $('#iframe-code');

    if (iframeCodeInput.length > 0) {
        // Update on page load
        updateFormContents();

        // Update on change
        $('#iframe-wizard [data-settings-type]').on('change', function () {
            updateFormContents();
            console.log('change');
            updateInputGroupColor(this);
        });
    }

    function updateFormContents() {
        const urlWithQueryString = buildUrlWithQueryString();
        $('#iframe-preview').attr('src', urlWithQueryString + '&fresh=1');
        iframeCodeInput.val(`<iframe src="${urlWithQueryString}" width="100%" height="600"></iframe>`);
    }

    function updateInputGroupColor(field) {
        const inputGroupColor = $(field).parent('.input-group').find('.input-group-color');
        if (inputGroupColor.length > 0) {
            const val = !$(field).val().includes('#') ? '#' + $(field).val() : $(field).val();
            inputGroupColor.css({background: val});
        }
    }

    function buildUrlWithQueryString() {
        const inputTokenField = $('[data-settings-type="token"]');
        const inputUserField = $('[data-settings-type="intermediary-token"]');
        const inputSettingsFields = $('[data-settings-type="settings"]');
        const baseUrl = iframeCodeInput.attr('data-url');
        const urlParts = baseUrl.split('/');

        if (inputUserField) {
            inputSettingsFields.push(inputUserField);
        }

        let url = baseUrl;
        if (inputTokenField.length > 0 && inputTokenField.val() !== undefined) {
            url = baseUrl.replace(urlParts[urlParts.length - 1], inputTokenField.val());
        }

        return url + buildQueryStringFromInputs(inputSettingsFields);
    }
})();
