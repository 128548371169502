import * as React from 'react';

import {BuildingCostsConditionSlider} from './building_costs_condition/building_costs_condition_slider';
import {FunctionComponent} from 'react';
import {buildingCostsLabels} from './building_costs_condition/building_costs_labels';
import {classNames} from '../../../../../../support/classnames';

const DummyDefectLine: FunctionComponent<{className: string}> = ({className}) => {
    return (
        <li className={classNames(className, 'defect-line')}>
            <div className="defect-line__avatar">&nbsp;</div>
            <div className="defect-line__description">
                <strong>
                    <span className="placeholder placeholder-width-200"></span>
                </strong>
                <small>
                    <span className="placeholder placeholder-width-100 placeholder-opaque-15"></span>
                </small>
            </div>
            <div className="defect-line__spacer"></div>
            <div className="defect-line__components">
                <div className="form-building-costs-group">
                    <div className="form-building-costs-pair">
                        <div data-test-box="conditie" className="form-group form-group-building-costs-range">
                            <div className="form-range-container form-range-sm">
                                <BuildingCostsConditionSlider
                                    disabled={true}
                                    onChange={() => {
                                        //Noop
                                    }}
                                    options={buildingCostsLabels}
                                    value={0}
                                />
                            </div>
                        </div>
                        <span>
                            <div className="form-group form-group-buttons form-group-buttons-double">
                                <button className="btn btn-secondary btn-sm btn-building-costs"></button>
                                <button className="btn btn-secondary btn-sm btn-building-costs"></button>
                                <button className="btn btn-secondary btn-sm btn-building-costs"></button>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </li>
    );
};

export const DummyBuildingCostsSheet: FunctionComponent = () => {
    return (
        <div className="constructional-defects-sheet">
            <ul className="section">
                <li>
                    <h2>
                        <span className="placeholder placeholder-width-200"></span>
                    </h2>
                </li>
                <DummyDefectLine className="placeholder-opaque-80" />
            </ul>
            <ul className="section">
                <li>
                    <h2>
                        <span className="placeholder placeholder-width-100 placeholder-opaque-15"></span>
                    </h2>
                </li>
                <DummyDefectLine className="placeholder-opaque-70" />
                <DummyDefectLine className="placeholder-opaque-60" />
            </ul>
            <ul className="section">
                <li>
                    <h2>
                        <span className="placeholder placeholder-width-200 placeholder-opaque-10"></span>
                    </h2>
                </li>
                <DummyDefectLine className="placeholder-opaque-50" />
            </ul>
            <ul className="section">
                <li>
                    <h2>
                        <span className="placeholder placeholder-width-200 placeholder-opaque-5"></span>
                    </h2>
                </li>
                <DummyDefectLine className="placeholder-opaque-40" />
                <DummyDefectLine className="placeholder-opaque-30" />
            </ul>
        </div>
    );
};
