import React, {useCallback, useEffect, useRef} from 'react';
import {animated, useSpring} from 'react-spring';
import {isMobile, isTablet} from '../support/check_mobile';

import {createRoot} from 'react-dom/client';
import {useDrag} from '@use-gesture/react';

function SwipeComponent({children}: {children: Element[]}) {
    const [values, api] = useSpring(() => ({x: 0}));
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Copy children of element into React element tree
        if (ref.current && children) {
            children.forEach((c) => {
                if (c !== ref.current) {
                    ref.current?.appendChild(c);
                }
            });
        }
    }, []);

    const getSnapPoint = useCallback(() => {
        if (!ref.current) {
            return 0;
        }

        let snapPoint = ref.current.clientWidth;
        Array.from(ref.current.children).forEach((c) => {
            snapPoint -= c.clientWidth;
        });

        return snapPoint;
    }, []);

    const bind = useDrag(
        ({down, movement: [x], velocity: [vx], direction: [xDir]}) => {
            if (down) {
                api.start({x});
            } else {
                const snapPoint = getSnapPoint();

                if (x < 0.6 * snapPoint || (vx > 0.3 && xDir < 0)) {
                    api.start({x: snapPoint});
                } else {
                    api.start({x: 0});
                }
            }
        },
        {axis: 'x'}
    );

    return (
        <animated.div
            {...bind()}
            style={{
                x: values.x,
                display: 'flex',
                width: '100%',
            }}
            ref={ref}
        />
    );
}

$('.list-item-swipe').each((_, el) => {
    if ($(el).hasClass('list-item-swipe-mobile') && !isMobile() && !isTablet()) {
        return;
    }

    createRoot(el).render(<SwipeComponent>{Array.from(el.children)}</SwipeComponent>);
});
