import * as React from 'react';
import {createRoot} from 'react-dom/client';

import {NotificationsStack} from './notifications_stack';

const elementById = document.getElementById('notifications-stack');
if (elementById !== undefined && elementById !== null) {
    const root = createRoot(elementById);
    root.render(<NotificationsStack />);
}
