import {ReferenceObjectAnswer, referenceObjectAnswerFromReferenceSale} from '../models/reference_object_answer';
import {makeObservable, observable} from 'mobx';

import {Presenter} from '../../../../../../../../../support/presenter/presenter';
import {ReferenceSale} from '../models/reference_sale';

export class ReferenceObjectCurrentlyInSaleTilePresenter implements Presenter {
    @observable public isAdding = false;

    private _referenceSale: ReferenceSale;
    private _onAdd: (referenceSale: ReferenceSale, referenceObjectAnswer: ReferenceObjectAnswer) => void;

    constructor(
        referenceSale: ReferenceSale,
        onAdd: (referenceSale: ReferenceSale, referenceObjectAnswer: ReferenceObjectAnswer) => void
    ) {
        makeObservable(this);
        this._referenceSale = referenceSale;
        this._onAdd = onAdd;
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public onAdd() {
        try {
            this.isAdding = true;
            this._onAdd(this._referenceSale, referenceObjectAnswerFromReferenceSale(this._referenceSale));
        } finally {
            this.isAdding = false;
        }
    }
}
