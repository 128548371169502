import PullToRefresh from 'pulltorefreshjs';
import {detect} from 'detect-browser';

/**
 * Scroll to refresh for mobile users
 */
(() => {
    const browser = detect();
    if (browser.os !== 'Android OS') {
        PullToRefresh.init({
            mainElement: 'body',
            instructionsPullToRefresh: 'Swipe naar beneden voor vernieuwen',
            instructionsReleaseToRefresh: 'Laat los om te vernieuwen',
            instructionsRefreshing: 'Aan het laden',
            onRefresh() {
                window.location.reload();
            },
            shouldPullToRefresh: () => {
                if (document.body.classList.contains('empty')) {
                    return false;
                }

                //Return the "default" shouldPullToRefresh
                return !window.scrollY;
            },
        });
    }
})();
