import {AjaxDriver} from '../../network/driver/ajax_driver';
import {PagePart} from '../models/page_part';
import {PagePartConfiguration} from '../models/page_part_configuration';
import {
    ApiPagePartConfiguration,
    apiPagePartConfigurationsToPagePartConfigurations,
    apiPagePartConfigurationToPagePartConfiguration,
    pagePartConfigurationToApiPagePartConfiguration,
} from './models/api_page_part_configuration';
import {pagePartToApiPagePart} from './models/api_page_part';
import {PagePartCustomization} from '../models/page_part_customization';
import {pagePartCustomizationToApiPagePartCustomization} from './models/api_page_part_customization';

export interface PagePartConfigurationApi {
    getConfigurations(): Promise<PagePartConfiguration[]>;
    getConfigurationsForQuestionSet(questionSetId: number): Promise<PagePartConfiguration[]>;
    deleteConfiguration(id: number): Promise<void>;
    storeConfiguration(config: Omit<PagePartConfiguration, 'id'> & {id?: number}): Promise<PagePartConfiguration>;
    updateConfigurationRanks(pagePartConfigurations: PagePartConfiguration[]): Promise<PagePartConfiguration[]>;
    storePageParts(configurationId: number, pageParts: PagePart[]): Promise<PagePartConfiguration | null>;
    storeCustomizations(
        configurationId: number,
        customizations: PagePartCustomization[]
    ): Promise<PagePartConfiguration | null>;
}

export class DefaultPagePartConfigurationApi implements PagePartConfigurationApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public async getConfigurations(): Promise<PagePartConfiguration[]> {
        const response = await this.ajaxDriver.fetch(`/ajax/page-parts/configurations`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            const data = await response.json();
            return apiPagePartConfigurationsToPagePartConfigurations(data);
        } else {
            throw new Error(response.statusText);
        }
    }

    public async getConfigurationsForQuestionSet(questionSetId: number): Promise<PagePartConfiguration[]> {
        const response = await this.ajaxDriver.fetch(`/ajax/page-parts/configurations/question-set/${questionSetId}`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: {
                'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            const data = await response.json();
            return apiPagePartConfigurationsToPagePartConfigurations(data);
        } else {
            throw new Error(response.statusText);
        }
    }

    public async storeConfiguration(
        config: Omit<PagePartConfiguration, 'id'> & {id?: number}
    ): Promise<PagePartConfiguration> {
        const response = await this.ajaxDriver.fetch(`/ajax/page-parts/configurations/store`, {
            method: 'POST',
            body: JSON.stringify(pagePartConfigurationToApiPagePartConfiguration(config)),
            credentials: 'same-origin',
            headers: {
                'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            const data = await response.json();
            return apiPagePartConfigurationToPagePartConfiguration(data);
        } else {
            throw new Error(response.statusText);
        }
    }

    public async deleteConfiguration(id: number): Promise<void> {
        const response = await this.ajaxDriver.fetch(`/ajax/page-parts/configurations/${id}/delete`, {
            method: 'DELETE',
            credentials: 'same-origin',
            headers: {
                'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    }

    public async updateConfigurationRanks(
        pagePartConfigurations: PagePartConfiguration[]
    ): Promise<PagePartConfiguration[]> {
        const response = await this.ajaxDriver.fetch(`/ajax/page-parts/configurations/ranks`, {
            method: 'POST',
            body: JSON.stringify({
                page_part_configurations: pagePartConfigurations.map((pagePartConfiguration) =>
                    pagePartConfigurationToApiPagePartConfiguration(pagePartConfiguration)
                ),
            }),
            credentials: 'same-origin',
            headers: {
                'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            const data = await response.json();
            return data.map((config: ApiPagePartConfiguration) =>
                apiPagePartConfigurationToPagePartConfiguration(config)
            );
        } else {
            throw new Error(response.statusText);
        }
    }

    public async storePageParts(configurationId: number, pageParts: PagePart[]): Promise<PagePartConfiguration | null> {
        const response = await this.ajaxDriver.fetch(`/ajax/page-parts/${configurationId}/store`, {
            method: 'POST',
            body: JSON.stringify({
                page_parts: pageParts.map((pagePart) => pagePartToApiPagePart(pagePart)),
            }),
            credentials: 'same-origin',
            headers: {
                'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            const data = await response.json();
            return apiPagePartConfigurationToPagePartConfiguration(data);
        } else {
            throw new Error(response.statusText);
        }
    }

    public async storeCustomizations(
        configurationId: number,
        customizations: PagePartCustomization[]
    ): Promise<PagePartConfiguration | null> {
        const response = await this.ajaxDriver.fetch(`/ajax/page-parts/${configurationId}/store-customizations`, {
            method: 'POST',
            body: JSON.stringify({
                customizations: customizations.map((customization) =>
                    pagePartCustomizationToApiPagePartCustomization(customization)
                ),
            }),
            credentials: 'same-origin',
            headers: {
                'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            const data = await response.json();
            return apiPagePartConfigurationToPagePartConfiguration(data);
        } else {
            throw new Error(response.statusText);
        }
    }
}
