import * as Uuid from 'uuid';

import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {Answer} from '../../../../../models/answer';
import {AnswerController} from '../../../../../business/answering/answer_controller';
import {Appraisal} from '../../../../../models/appraisal';
import {AppraisalState} from '../../../../../enum/appraisal_state';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {Presenter} from '../../../../../../support/presenter/presenter';
import {Question} from '../../../../../models/question';
import {QuestionEffectInteractor} from '../../../../../business/conditions/question_effects_interactor';
import {QuestionSet} from '../../../../../models/question_set';
import {UserInteractor} from '../../../../../business/user_interactor';
import {map} from 'rxjs/operators';
import {sortByCreatedAt} from '../../../../../../support/sort_answer';
import {AppraisalValidationType} from '../../../../../enum/appraisal_validation_type';
import {ValidationMessage} from '../../../../../business/validation/validation_message';
import {ChildQuestionValidationProvider} from '../../../../../business/validation/child_validation_provider';

export class IteratorPresenter implements Presenter {
    @observable.ref public iterations?: string[];
    @observable public _isHidden = false;
    @observable.ref public validationMessages: ValidationMessage[] = [];

    @computed
    public get loading() {
        return this.iterations === undefined;
    }

    @computed
    public get isHidden(): boolean {
        return (
            this._isHidden ||
            (this.question.isAppraiserOnly &&
                !this.userInteractor.isAppraiser() &&
                !this.userInteractor.isEmployee() &&
                !this.userInteractor.isJuniorAppraiser())
        );
    }

    @computed
    public get isDisabled(): boolean {
        if (!this.appraisal.isEditableAppraisal) {
            return true;
        }
        if (this.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return this.loading || (this.appraisal.status !== AppraisalState.PROCESSING && this.question.freezes);
        }
        return (
            this.loading ||
            (this.appraisal.status !== AppraisalState.PROCESSING && this.question.freezes) ||
            this.appraisal.status === AppraisalState.APPROVED ||
            this.appraisal.status === AppraisalState.CANCELED ||
            this.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    protected subscriptions = new CompositeSubscription();

    constructor(
        protected question: Question,
        protected appraisal: Appraisal,
        protected parentAnswerUuid: string | undefined,
        protected questionSet: QuestionSet,
        protected answerController: AnswerController,
        protected questionEffectsInteractor: QuestionEffectInteractor,
        protected userInteractor: UserInteractor,
        protected childQuestionValidationProvider: ChildQuestionValidationProvider
    ) {
        this.iterations = this.mapAnswers(
            this.answerController.answersForQuestionUuid(this.question.uuid, this.parentAnswerUuid ?? undefined)
        );
        this._isHidden = this.questionEffectsInteractor.isHidden(this.question.uuid, this.parentAnswerUuid ?? null);

        makeObservable(this);
    }

    private mapAnswers(answers: Answer[]) {
        return answers
            .sort((a, b) => sortByCreatedAt(a, b))
            .filter((answer) => !answer.isDeleted)
            .map((answer) => answer.iteration)
            .filter((iteration: string | null) => iteration !== null)
            .reduce((p: string[], c: string | null) => {
                if (c !== null && !p.includes(c)) {
                    return [...p, c];
                }
                return p;
            }, []);
    }

    public async mount(): Promise<void> {
        this.subscriptions.add(
            this.answerController
                .answersForQuestionUuidAndParentAnswerUuidStream(this.question.uuid, this.parentAnswerUuid ?? null)
                .pipe(
                    map((answers: Answer[]) => ({
                        hasAtLeastOnePossiblyDeleted: answers.some((answer) => answer.iteration),
                        iterations: this.mapAnswers(answers),
                    }))
                )
                .subscribe(({iterations, hasAtLeastOnePossiblyDeleted}) => {
                    if (!hasAtLeastOnePossiblyDeleted && this.question.shouldCreateFirstIteration) {
                        iterations.push(Uuid.v4());
                    }

                    runInAction(() => {
                        this.iterations = iterations;
                    });
                })
        );

        this.subscriptions.add(
            this.questionEffectsInteractor
                .isHiddenStream(this.question.uuid, this.parentAnswerUuid ?? null)
                .subscribe((isHidden) => {
                    runInAction(() => {
                        this._isHidden = isHidden;
                    });
                })
        );

        // Subscribe to specific iterator question validation messages
        this.subscriptions.add(
            this.childQuestionValidationProvider.validate(this.question.uuid, null).subscribe((validationMessages) => {
                runInAction(() => {
                    this.validationMessages = validationMessages;
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    public addIteration() {
        if (this.iterations === undefined) {
            this.iterations = [];
        }
        this.iterations = [...this.iterations, Uuid.v4()];
    }
}
