import {Global, GlobalProvider} from '../../business/global_provider';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {Appraisal} from '../models/appraisal';
import {AppraisalApi} from '../network/appraisal_api';
import {AppraisalProvider} from '../business/appraisal_provider';
import {AppraisalState} from '../enum/appraisal_state';
import {CompositeSubscription} from '../../support/composite_subscription';
import {DetailName} from '../enum/detail_name';
import {Presenter} from '../../support/presenter/presenter';
import {Question} from '../models/question';
import {QuestionSet} from '../models/question_set';
import {QuestionSetApi} from '../network/question_set_api';
import {ServerTimeProvider} from '../server_time/server_time_provider';
import {ValidationError} from '../models/validation_error';
import {ValidationErrorApi} from '../network/validation_error_api';
import {createDummyAppraisal} from '../business/dummy_appraisal';
import {first} from 'rxjs/operators';
import {PagePartConfigurationApi} from '../network/page_part_configuration_api';
import {PagePartConfigurationsInteractor} from '../business/page_parts/page_part_configurations_interactor';
import {RenderingContextType} from '../enum/rendering_context_type';
import {UserSettingsActivePagePartConfigurationInteractor} from '../business/user_settings_active_page_part_configuration_interactor';
import {PagePartConfiguration, PagePartConfigurationType} from '../models/page_part_configuration';
import {AnswerLoader} from '../business/answering/support/answer_loader';
import {AppraiseModel, isAppraiseModel} from '../enum/appraise_model';

export class RootPresenter implements Presenter {
    @observable public rootQuestion?: Question | null;
    @observable public questionSet?: QuestionSet;
    @observable public appraisal?: Appraisal;
    @observable public error = false;
    @observable public isDoneLoading = false;
    @observable public validationErrors?: ValidationError[];
    @observable public global: Global;
    @observable public renderingContextType?: RenderingContextType;

    @computed
    public get loading(): boolean {
        if (this.error) {
            return false;
        }

        if (this.isDoneLoading === false) {
            return true;
        }

        return (
            this.renderingContextType === undefined ||
            this.questionSet === undefined ||
            this.appraisal === undefined ||
            this.validationErrors === undefined
        );
    }

    private subscriptions = new CompositeSubscription();

    constructor(
        private appraisalId: number | null,
        private questionSetId: number,
        private appraisalApi: AppraisalApi,
        private answerLoader: AnswerLoader,
        private questionSetApi: QuestionSetApi,
        private validationErrorApi: ValidationErrorApi,
        private pagePartConfigurationApi: PagePartConfigurationApi,
        private appraisalProvider: AppraisalProvider,
        private pagePartConfigurationsInteractor: PagePartConfigurationsInteractor,
        private serverTimeProvider: ServerTimeProvider,
        globalProvider: GlobalProvider,
        private userSettingsInteractorBuilder: (
            appraisal: Appraisal,
            questionSet: QuestionSet
        ) => UserSettingsActivePagePartConfigurationInteractor
    ) {
        this.global = globalProvider.global;

        makeObservable(this);
    }

    public async mount(): Promise<void> {
        try {
            const questionSetPromise = this.questionSetApi.get(this.questionSetId);
            const appraisalPromise =
                this.appraisalId !== null
                    ? this.appraisalApi.get(this.appraisalId)
                    : Promise.resolve(createDummyAppraisal());

            const answersPromise =
                this.appraisalId !== null ? this.answerLoader.fetchForAppraisal(this.appraisalId) : Promise.resolve();

            const pagePartConfigurationsPromise = this.pagePartConfigurationApi.getConfigurationsForQuestionSet(
                this.questionSetId
            );

            const validationErrorsPromise =
                this.appraisalId !== null &&
                this.global.detailName === null &&
                this.global.state === AppraisalState.REQUESTED_CORRECTION
                    ? this.validationErrorApi.get(this.appraisalId)
                    : Promise.resolve([]);

            //This one is in here to force hydration
            const serverTimePromise = this.serverTimeProvider.dates().pipe(first()).toPromise();

            const [questionSet, appraisal, validationErrors, pagePartConfigurations] = await Promise.all([
                questionSetPromise,
                appraisalPromise,
                validationErrorsPromise,
                pagePartConfigurationsPromise,
                serverTimePromise,
                answersPromise,
            ]);

            let renderingContextType =
                pagePartConfigurations.length > 0
                    ? RenderingContextType.PAGE_PARTS_APPRAISAL
                    : isAppraiseModel(appraisal, AppraiseModel.MODEL2024PLAUSIBILITY)
                    ? RenderingContextType.PLAUSIBILITY_CHECK
                    : RenderingContextType.APPRAISAL;
            let rootQuestion: Question | null = null;

            if (this.global.detailName === DetailName.ADVICE_BUILDING_COSTS) {
                window.location.replace('#/advice-building-costs');
                rootQuestion =
                    questionSet.questions.find((question) => question.uuid === this.global.questionUuid) ?? null;

                // Even when in page parts question set, we render building inspectors as normal question set, as we need to zoom in on a specific root question
                // This question should always be within a page part, so we shouldn't be affected by page part rendering
                renderingContextType = RenderingContextType.APPRAISAL;
            } else if (renderingContextType === RenderingContextType.PAGE_PARTS_APPRAISAL) {
                if (!window.location.href.includes('/config-id/')) {
                    const config = await this.choosePagePartConfig(appraisal, questionSet, pagePartConfigurations);

                    const firstPagePartUuid = config.pageParts.find((pp) => pp.parentUuid === null)?.uuid;
                    if (firstPagePartUuid) {
                        window.location.replace(`#/config-id/${config.id}/page-part/${firstPagePartUuid}`);
                    } else {
                        window.location.replace(`#/config-id/${config.id}`);
                    }
                }
            } else if (this.global.detailName !== null) {
                rootQuestion =
                    questionSet.questions.find((question) => question.detailName === this.global.detailName) ?? null;

                if (rootQuestion) {
                    window.location.replace(`#/${rootQuestion.uuid}`);
                }
            }

            runInAction(() => {
                this.questionSet = questionSet;
                this.appraisalProvider.onChange(appraisal);
                this.pagePartConfigurationsInteractor.onChange(pagePartConfigurations);
                this.appraisal = appraisal;
                this.renderingContextType = renderingContextType;
                this.validationErrors = validationErrors;
                this.isDoneLoading = true;
                this.rootQuestion = rootQuestion;
            });
        } catch (e) {
            console.error(e);
            runInAction(() => {
                this.error = true;
            });
        }
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    public onChange(appraisal: Appraisal) {
        this.appraisalProvider.onChange(appraisal);
        this.appraisal = appraisal;
    }

    private async choosePagePartConfig(
        appraisal: Appraisal,
        questionSet: QuestionSet,
        pagePartConfigurations: PagePartConfiguration[]
    ) {
        if (this.global.detailName !== null) {
            const detailConfig = pagePartConfigurations.find(
                (config) =>
                    config.type === PagePartConfigurationType.SYSTEM_DETAIL && config.name === this.global.detailName
            );

            if (!detailConfig) {
                throw new Error(
                    `No PagePartConfiguration found with type SYSTEM_DETAIL and name ${this.global.detailName}.`
                );
            }

            return detailConfig;
        } else {
            const lastSelectedConfigId = await this.userSettingsInteractorBuilder(appraisal, questionSet)
                .activePagePartConfigurationIdStream.pipe(first())
                .toPromise();

            const systemCompleteConfig = pagePartConfigurations.find(
                (config) => config.type === PagePartConfigurationType.SYSTEM_COMPLETE
            );
            if (!systemCompleteConfig) {
                throw new Error(
                    'No PagePartConfiguration found with PagePartConfigurationType.SYSTEM_COMPLETE. We need a SYSTEM_COMPLETE config to do certain things like validation. Create a config in the configurator with this type!'
                );
            }

            return pagePartConfigurations.find((config) => config.id == lastSelectedConfigId) ?? systemCompleteConfig;
        }
    }
}
