/**
 * Build a query string form input elements
 * ?name=value
 */

export function buildQueryStringFromInputs(inputFields) {
    let query = '?';
    inputFields.each(function (i) {
        if ($(this).val() !== undefined && $(this).val() !== null && $(this).val() !== '') {
            query += $(this).attr('name') + '=' + encodeURIComponent($(this).val().replace('#', ''));

            if (i + 1 < inputFields.length) {
                query += '&';
            }
        }
    });
    return query;
}
