import * as React from 'react';

import {AddCadastralPlotModal} from './iterator_cadastral/add_cadastral_plot_modal';
import {IteratorCadastralPresenter} from './iterator_cadastral_presenter';
import {IteratorQuestionType} from '../../../../../enum/question_type';
import {QuestionContainer} from '../question_container';
import {SimpleQuestionPresenterProps} from '../simple/simple_question_presenter';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {shouldShowTitle} from '../../../../../../support/should_show_title';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const IteratorCadastral: React.FC<OwnProps> = observer(function IteratorCadastral(props) {
    const presenter = usePresenter(
        (container) =>
            new IteratorCadastralPresenter(
                props.question,
                props.appraisal,
                props.parentAnswerUuid,
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.questionEffectsInteractor(props.appraisal, props.questionSet),
                container.business.userInteractor,
                container.business.childQuestionValidationProvider(
                    props.appraisal,
                    props.questionSet,
                    props.validationErrors
                )
            )
    );
    if (presenter.isHidden) {
        return null;
    }

    return (
        <>
            {shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel) && (
                <h2>{props.question.contents}</h2>
            )}
            {presenter.loading || presenter.iterations === undefined || presenter.iterations.length === 0
                ? null
                : presenter.iterations.map((iteration) => {
                      const child = {
                          ...props.question,
                          type: IteratorQuestionType.ITERATOR_GROUP,
                      };
                      return presenter.addCadastralPlotModalIteration === iteration ? (
                          <AddCadastralPlotModal
                              key={child.uuid + '[' + iteration + ']' + 'modal'}
                              question={child}
                              appraisal={props.appraisal}
                              iteration={iteration}
                              questionSet={props.questionSet}
                              validationErrors={props.validationErrors}
                              validationMessages={props.validationMessages}
                              forceShowValidationMessages={props.forceShowValidationMessages}
                              parentAnswerUuid={props.parentAnswerUuid}
                              visible={presenter.addCadastralPlotModalIteration === iteration}
                              onHide={() => presenter.onHide()}
                              onAdd={() => presenter.onAdd()}
                              hiddenQuestionTypes={props.hiddenQuestionTypes}
                              pagePartsSet={props.pagePartsSet}
                              activePagePart={props.activePagePart}
                              renderingContext={props.renderingContext}
                              questionRenderingData={props.questionRenderingData}
                          />
                      ) : (
                          <QuestionContainer
                              key={child.uuid + '[' + iteration + ']' + 'iterator'}
                              iteration={iteration}
                              appraisal={props.appraisal}
                              question={child}
                              questionSet={props.questionSet}
                              validationErrors={props.validationErrors}
                              validationMessages={props.validationMessages}
                              forceShowValidationMessages={props.forceShowValidationMessages}
                              parentAnswerUuid={props.parentAnswerUuid}
                              adaptedDefaultValues={props.adaptedDefaultValues}
                              hiddenQuestionTypes={props.hiddenQuestionTypes}
                              pagePartsSet={props.pagePartsSet}
                              activePagePart={props.activePagePart}
                              renderingContext={props.renderingContext}
                              questionRenderingData={props.questionRenderingData}
                          />
                      );
                  })}
            {!presenter.isDisabled && !props.disabled ? (
                <div className="iterator-buttons-container">
                    <button
                        disabled={presenter.loading}
                        onClick={() => presenter.onShow()}
                        className="btn btn-default btn-sm ion-md-add-circle float-left"
                    >
                        Toevoegen
                    </button>
                </div>
            ) : null}
        </>
    );
});
