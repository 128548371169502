import * as Throttle from 'promise-parallel-throttle';

import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, flatMap, map, switchMap} from 'rxjs/operators';

import {Answer} from '../models/answer';
import {AnswerController} from './answering/answer_controller';
import {AnswerInteractor} from './answering/answer_interactor';
import {BlobCacheInteractor} from './attachments/blob_cache_interactor';
import {ImageUploadInteractor} from './attachments/image_upload_interactor';
import {ImageUploadState} from '../appraise/ui/content/questions/advanced/attachment_question_presenter';
import {NormalQuestionType} from '../enum/question_type';
import {QuestionEffectInteractor} from './conditions/question_effects_interactor';
import {QuestionSet} from '../models/question_set';
import {first} from 'rxjs/internal/operators/first';
import {merge} from 'rxjs/internal/observable/merge';
import {parseImageAnswerContents} from './support/parse_image_answer_contents';
import {scan} from 'rxjs/internal/operators/scan';
import {ALLOWED_IMAGE_TYPES} from '../appraise/ui/content/questions/question_container';

export interface PhotoAnswerRetryStatus {
    done: number;
    failed: number;
    queued: number;
    total: number;
}

export interface PhotoAnswerWithFile {
    answer: Answer;
    file: File;
}

interface PhotoAnswerWithoutFile {
    answer: Answer;
    file: null;
}

export enum UnsyncedType {
    IDLE = 'idle',
    UNSCYNED = 'unsynced',
}

interface IdleUnscynedState {
    type: UnsyncedType.IDLE;
}

interface UnsyncedUnsyncedState {
    type: UnsyncedType.UNSCYNED;
    missing: PhotoAnswerWithoutFile[];
    unsynced: PhotoAnswerWithFile[];
}

export type UnscynedState = IdleUnscynedState | UnsyncedUnsyncedState;

export interface PhotoAnswerRetryInteractor {
    numUnsyncedPhotos: Observable<number>;
    unsyncedPhotoAnswersStream(debounce?: number): Observable<Answer[]>;
    unsyncedPhotoAnswersWithFileStream(
        debounce?: number
    ): Observable<Array<PhotoAnswerWithFile | PhotoAnswerWithoutFile>>;
    unscynedStateStream(debounce?: number): Observable<UnscynedState>;

    attempt(): Observable<PhotoAnswerRetryStatus>;
}

export class DefaultPhotoAnswerRetryInteractor implements PhotoAnswerRetryInteractor {
    private _fileTypes = ALLOWED_IMAGE_TYPES;

    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private blobCacheInteractor: BlobCacheInteractor,
        private answerInteractor: AnswerInteractor,
        private imageUploadInteractor: ImageUploadInteractor,
        private questionEffectsInteractor: QuestionEffectInteractor
    ) {}

    public unscynedStateStream(debounce?: number): Observable<UnscynedState> {
        return this.unsyncedPhotoAnswersWithFileStream(debounce).pipe(
            map((photoAnswers) => {
                if (photoAnswers.length === 0) {
                    return {
                        type: UnsyncedType.IDLE,
                    };
                }

                const missingPhotos = photoAnswers.filter((a): a is PhotoAnswerWithoutFile => a.file == null);
                const unsyncedPhotos = photoAnswers.filter((a): a is PhotoAnswerWithFile => a.file != null);

                return {
                    type: UnsyncedType.UNSCYNED,
                    missing: missingPhotos,
                    unsynced: unsyncedPhotos,
                };
            })
        );
    }

    public unsyncedPhotoAnswersWithFileStream(
        debounce?: number
    ): Observable<Array<PhotoAnswerWithFile | PhotoAnswerWithoutFile>> {
        return this.unsyncedPhotoAnswersStream(debounce).pipe(
            switchMap((answers) => {
                return Promise.all(
                    answers.map(async (answer) => {
                        return {
                            answer,
                            file: await this.blobCacheInteractor.find(answer.uuid),
                        };
                    })
                );
            })
        );
    }

    public get numUnsyncedPhotos(): Observable<number> {
        return this.unsyncedPhotoAnswersStream().pipe(
            map((answers) => answers.length),
            distinctUntilChanged()
        );
    }

    public attempt(): Observable<PhotoAnswerRetryStatus> {
        return this.unsyncedPhotoAnswersStream(0).pipe(
            first(), //Since we submit answers, otherwise it will go out of hand
            switchMap((photoAnswers) => {
                if (photoAnswers.length === 0) {
                    return merge(
                        Promise.resolve({
                            result: true,
                            answer: undefined,
                            total: 0,
                        })
                    );
                }

                const result = new Subject<{result: boolean; answer?: Answer; total: number}>();

                void Throttle.all(
                    photoAnswers.map((answer) => {
                        return async () => {
                            const innerResult = await this.retryUpload(answer);
                            await this.answerInteractor.submit();
                            result.next({
                                result: innerResult,
                                answer: answer,
                                total: photoAnswers.length,
                            });
                        };
                    }),
                    {maxInProgress: 1}
                );

                return result;
            }),
            scan(
                (status: PhotoAnswerRetryStatus, result: {result: boolean; answer?: Answer; total: number}) => {
                    if (result.total === 0) {
                        return {failed: 0, done: 0, queued: 0, total: 0};
                    }

                    if (result.result) {
                        status.done++;
                    } else {
                        status.failed++;
                    }
                    status.queued = result.total - status.done - status.failed;
                    status.total = result.total;

                    return status;
                },
                {failed: 0, done: 0, queued: 0, total: 0}
            ),
            flatMap(async (status) => {
                if (status.queued === 0) {
                    await this.answerInteractor.submit();
                }
                return status;
            })
        );
    }

    public unsyncedPhotoAnswersStream(debounce = 100): Observable<Answer[]> {
        const questions = [
            ...this.questionSet.findQuestionsByType(NormalQuestionType.PHOTO),
            ...this.questionSet.findQuestionsByType(NormalQuestionType.BUILDING_COSTS_PHOTO),
        ];
        const photoQuestionUuids = questions.map((q) => q.uuid);

        return this.answerController.answersForQuestionUuidsStream(photoQuestionUuids).pipe(
            debounceTime(debounce),
            map((answers) => this.answerController.filterDeleted(answers)),
            map((answers) => {
                const isHiddenCache = {};
                return answers.filter(
                    (answer) =>
                        !this.questionEffectsInteractor.isHidden(answer.questionUuid, answer.parentUuid, isHiddenCache)
                );
            }),
            map((answers) => answers.filter((a) => this.requiresSync(a)))
        );
    }

    private requiresSync(answer: Answer): boolean {
        if (answer.contents === null) {
            return false;
        }

        const contents = parseImageAnswerContents(answer.contents);

        return (
            contents.state !== ImageUploadState.SYNCED &&
            contents.state !== ImageUploadState.VALIDATION_INSTITUTE_UPLOAD_FAILED
        );
    }

    private async retryUpload(answer: Answer): Promise<boolean> {
        try {
            if (answer.contents === null) {
                //Not ready for an upload
                return false;
            }

            const contents = parseImageAnswerContents(answer.contents);
            if (contents.state === ImageUploadState.SYNCED) {
                //Already synchronized
                return true;
            }
            const file = await this.blobCacheInteractor.find(answer.uuid);
            if (file === null) {
                //Unknown file, probably on another computer
                return true;
            }

            const result = await this.imageUploadInteractor.uploadForAnswer(answer.uuid, file, {
                fileTypes: this._fileTypes,
                contents,
            });
            return result.succeeded;
        } catch (e) {
            return false;
        }
    }
}
