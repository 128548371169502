import * as React from 'react';
import {useLocation} from 'react-router';

import {Appraisal} from '../../../models/appraisal';
import {ErrorBoundary} from '../../../components/error_boundary';
import {Global} from '../../../../business/global_provider';
import {IteratorQuestionType} from '../../../enum/question_type';
import {PagePartContentPresenter} from './page_part_content_presenter';
import {PagePartIndexPage} from './questions/page_part_index_page';
import {PagePartsSet} from '../../../models/page_parts_set';
import {QuestionContainer} from './questions/question_container';
import {QuestionSet} from '../../../models/question_set';
import {RenderingContextType} from '../../../enum/rendering_context_type';
import {ValidationError} from '../../../models/validation_error';
import {ValidationMessageMap} from '../../../business/validation/validation_message';
import {ValidationMessagesList} from './validation_messages_list';
import {getPagePartChildren} from './questions/advanced/page_part/support/get_page_part_children';
import {usePresenter} from '../../../../support/presenter/use_presenter';
import {QuestionLoader} from '../components/question_loader';
import {QuestionRenderingData} from '../../../models/question_rendering_data';

interface OwnProps {
    pagePartsUuid: string;
    global: Global;
    appraisal: Appraisal;
    onChange: (appraisal: Appraisal) => void;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    iteration?: string;
    pagePartsSet: PagePartsSet | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
    forceShowValidationMessages: boolean;
}

export const PagePartContent: React.FC<OwnProps> = (props) => {
    const location = useLocation<{pagePartFloorNumberIteration?: string}>();
    const presenter = usePresenter(
        (container) =>
            new PagePartContentPresenter(
                props.pagePartsUuid,
                props.pagePartsSet,
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.childQuestionValidationProvider(
                    props.appraisal,
                    props.questionSet,
                    props.validationErrors
                ),
                container.business.floorInteractor(props.appraisal, props.questionSet, props.renderingContext),
                location.state?.pagePartFloorNumberIteration ?? null
            )
    );
    const {activePagePart, question, floorNumber, validationMessages} = presenter;
    const iteration =
        question?.type === IteratorQuestionType.PAGE_PART_ITERATOR ? floorNumber ?? props.iteration : props.iteration;

    const pagePartChildren = getPagePartChildren(activePagePart?.uuid ?? null, props.pagePartsSet, props.questionSet);

    if (question?.type === IteratorQuestionType.PAGE_PART_ITERATOR && iteration === undefined) {
        return <QuestionLoader />;
    }

    if (props.pagePartsSet && activePagePart?.isIndexPage === true) {
        return (
            <ErrorBoundary>
                <span>
                    {props.global.inline !== true ? <div className="card-header">{activePagePart.name}</div> : null}
                    <div className="card-body">
                        <ValidationMessagesList validationMessages={validationMessages} />
                        <PagePartIndexPage
                            appraisal={props.appraisal}
                            questionSet={props.questionSet}
                            iteration={iteration}
                            activePagePart={activePagePart}
                            pagePartsSet={props.pagePartsSet}
                            renderingContext={props.renderingContext}
                        />
                    </div>
                </span>
            </ErrorBoundary>
        );
    }

    if (!activePagePart || (!question && pagePartChildren.length === 0)) {
        if (process.env.NODE_ENV === 'development') {
            if (!activePagePart) {
                console.warn('Not found in page_part_content.tsx (no active page part)');
            } else {
                console.warn('Not found in page_part_content.tsx (no question & no pagepart children)');
            }
        }
        return (
            <div className="empty-message appraise">
                <h3>Helaas, deze pagina is niet gevonden (404)</h3>
                <span className="spacer-gradient">&nbsp;</span>
            </div>
        );
    }

    return (
        <ErrorBoundary>
            <span>
                {props.global.inline !== true ? <div className="card-header">{activePagePart.name}</div> : null}
                <div className="card-body">
                    <ValidationMessagesList validationMessages={validationMessages} />
                    {question && (
                        <QuestionContainer
                            key={question.uuid + ' ' + iteration + ' ' + floorNumber}
                            appraisal={props.appraisal}
                            onChange={props.onChange}
                            question={question}
                            questionSet={props.questionSet}
                            validationErrors={props.validationErrors}
                            validationMessages={props.validationMessages}
                            iteration={iteration}
                            hiddenQuestionTypes={[]}
                            pagePartsSet={props.pagePartsSet}
                            activePagePart={activePagePart}
                            renderingContext={props.renderingContext}
                            forceShowValidationMessages={props.forceShowValidationMessages}
                            questionRenderingData={props.questionRenderingData}
                        />
                    )}
                    {pagePartChildren.map((child) => {
                        return (
                            <QuestionContainer
                                key={child.pagePart.uuid + ' ' + iteration + ' ' + floorNumber}
                                appraisal={props.appraisal}
                                onChange={props.onChange}
                                question={child.question}
                                questionSet={props.questionSet}
                                validationErrors={props.validationErrors}
                                validationMessages={props.validationMessages}
                                iteration={iteration}
                                hiddenQuestionTypes={[]}
                                pagePartsSet={props.pagePartsSet}
                                activePagePart={child.pagePart}
                                renderingContext={props.renderingContext}
                                forceShowValidationMessages={props.forceShowValidationMessages}
                                questionRenderingData={props.questionRenderingData}
                            />
                        );
                    })}
                </div>
            </span>
        </ErrorBoundary>
    );
};
