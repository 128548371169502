import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import {ReferenceSale} from '../../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v1/models/reference_sale';
import {AppraisalProvider} from '../../appraising/business/appraisal_provider';
import {DistanceProvider} from '../../appraising/business/distance_provider';
import {TaskHelper} from '../../appraising/business/task_helper';
import {ObjectType} from '../../appraising/enum/object_type';
import {Appraisal} from '../../appraising/models/appraisal';
import {QuestionSet} from '../../appraising/models/question_set';
import {AppraisalApi} from '../../appraising/network/appraisal_api';
import {apiReferenceSalesToReferenceSales} from '../../appraising/network/models/api_reference_sale';
import {
    GivenPreselectionReferenceObject,
    PreselectedReferenceObjectApiData,
    PreselectedReferenceObjectsApi,
} from '../../appraising/network/preselected_reference_objects_api';
import {QuestionSetApi} from '../../appraising/network/question_set_api';
import {ReferenceObjectsError} from '../../appraising/network/reference_objects_api';
import {Presenter} from '../../support/presenter/presenter';
import {WithDistance} from './altum_reference_objects/wheel_item';
import {AnswerLoader} from '../../appraising/business/answering/support/answer_loader';

export class ReferenceObjectsPresenter implements Presenter {
    @observable public appraisal?: Appraisal;
    @observable public questionSet?: QuestionSet;
    @observable private loadingReferenceObjects = false;
    @observable private referenceObjects: ReferenceSale[] = [];
    @observable public loadError = false;
    @observable public givenObject: GivenPreselectionReferenceObject | null = null;
    @observable public preselectedObjects: ReferenceSale[] = [];
    @observable public rejectedObjects: ReferenceSale[] = [];
    @observable public referenceObjectToReject: ReferenceSale | null = null;
    @observable public objectTypeModalDetails: {
        visible: boolean;
        objectType: ObjectType | null;
        error: boolean;
    } = {
        visible: false,
        objectType: null,
        error: false,
    };

    @computed
    public get loading(): boolean {
        return !this.appraisal || !this.questionSet || this.loadingReferenceObjects;
    }

    @computed
    public get completeReferenceObjects(): WithDistance<ReferenceSale>[] {
        if (this.loading) {
            return [];
        }

        return this.referenceObjects.map((referenceObject) => {
            return {
                ...referenceObject,
                distance: this.distanceProvider.getDistanceToAppraisal(referenceObject),
            };
        });
    }

    constructor(
        private appraisalId: number,
        private questionSetId: number,
        preselectedObjects: ReferenceSale[],
        rejectedObjects: ReferenceSale[],
        private appraisalApi: AppraisalApi,
        private questionSetApi: QuestionSetApi,
        private appraisalProvider: AppraisalProvider,
        private preselectedReferenceObjectsApi: PreselectedReferenceObjectsApi,
        private taskHelper: TaskHelper,
        private distanceProvider: DistanceProvider,
        private answerLoader: AnswerLoader
    ) {
        this.preselectedObjects = preselectedObjects;
        this.rejectedObjects = rejectedObjects;

        makeObservable(this);
    }

    public async mount(): Promise<void> {
        this.requestObjects();

        const [questionSet, appraisal] = await Promise.all([
            this.questionSetApi.get(this.questionSetId),
            this.appraisalApi.get(this.appraisalId),
            this.answerLoader.fetchForAppraisal(this.appraisalId),
        ]);

        runInAction(() => {
            this.questionSet = questionSet;
            this.appraisal = appraisal;

            this.appraisalProvider.onChange(appraisal);
        });
    }

    public unmount(): void {
        /* Noop */
    }

    public changeObjectType = () => {
        this.objectTypeModalDetails = {
            visible: true,
            objectType: null,
            error: false,
        };
        this.loadingReferenceObjects = false;
        console.log('attempting to open modal');
    };

    public requestObjects() {
        runInAction(() => {
            this.loadingReferenceObjects = true;
            this.loadError = false;
        });

        this.preselectedReferenceObjectsApi
            .getReferenceSales(this.appraisalId, this.objectTypeModalDetails.objectType ?? undefined)
            .then(async (data) => {
                if (TaskHelper.isTaskReference(data)) {
                    return this.taskHelper.poll<PreselectedReferenceObjectApiData>(data.taskId);
                }

                return Promise.resolve(data);
            })
            .then((data) => {
                runInAction(() => {
                    this.loadingReferenceObjects = false;
                    this.loadError = false;

                    if (data) {
                        this.givenObject = data.given;
                        this.referenceObjects = apiReferenceSalesToReferenceSales(data.objects);
                    } else {
                        this.referenceObjects = [];
                        this.givenObject = null;
                    }
                });
            })
            .catch((err) => {
                if (err instanceof ReferenceObjectsError && err.message === 'unknown_object_type') {
                    runInAction(() => {
                        this.loadingReferenceObjects = false;
                        this.loadError = true;
                        this.objectTypeModalDetails = {
                            visible: true,
                            objectType: null,
                            error: true,
                        };
                    });
                    return;
                }

                console.error(err);
                runInAction(() => {
                    this.loadingReferenceObjects = false;
                    this.loadError = true;
                });
            });
    }

    private async updatePreselection() {
        try {
            await this.preselectedReferenceObjectsApi.savePreselection(
                this.appraisalId,
                this.preselectedObjects,
                this.rejectedObjects
            );
        } catch (e) {
            console.error(e);
            runInAction(() => {
                this.loadError = true;
            });
        }
    }

    @action
    public accept = (object: ReferenceSale) => {
        if (this.referenceObjectToReject !== null) {
            this.saveReject(this.referenceObjectToReject);
        }

        this.preselectedObjects.push(object);
        this.updatePreselection();
    };

    @action
    public undoAccept(object: ReferenceSale) {
        this.preselectedObjects = this.preselectedObjects.filter(
            (val) => val.id !== object.id && val.saleQuarter !== object.saleQuarter
        );
        this.updatePreselection();
    }

    @action
    public reject = (object: ReferenceSale) => {
        this.referenceObjectToReject = object;
        this.rejectedObjects.push(object);

        this.updatePreselection();
    };

    @action
    public undoReject(object: ReferenceSale) {
        this.rejectedObjects = this.rejectedObjects.filter(
            (val) => val.id !== object.id && val.saleQuarter !== object.saleQuarter
        );

        if (
            this.referenceObjectToReject?.id === object.id &&
            this.referenceObjectToReject?.saleQuarter === object.saleQuarter
        ) {
            this.referenceObjectToReject = null;
        }

        this.updatePreselection();
    }

    @action
    public saveReject(object: ReferenceSale, reason?: string) {
        if (
            this.referenceObjectToReject?.id === object.id &&
            this.referenceObjectToReject?.saleQuarter === object.saleQuarter
        ) {
            this.referenceObjectToReject = null;
        }

        const rejectObject = this.rejectedObjects.find(
            (val) => val.id === object.id && val.saleQuarter === object.saleQuarter
        );
        if (rejectObject && reason) {
            rejectObject.rejectReason = reason;
        }

        this.updatePreselection();
    }

    @action
    public updateRejectReason(object: ReferenceSale, reason: string) {
        const rejectObject = this.rejectedObjects.find(
            (val) => val.id === object.id && val.saleQuarter === object.saleQuarter
        );
        if (rejectObject) {
            rejectObject.rejectReason = reason;
        }
    }

    @action
    public closeObjectTypeModal() {
        this.objectTypeModalDetails.visible = false;
    }

    @action
    public setObjectType(objectType: ObjectType) {
        this.objectTypeModalDetails.objectType = objectType;
    }
}
