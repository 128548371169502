import * as React from 'react';

import {observer} from 'mobx-react';
import {Modal} from '../../content/questions/components/modal';
import {MacroSettingsPresenter, MacroSettingsPayload, MacroSettingsPayloadType} from './macro_settings_presenter';
import {QuestionSet} from '../../../../models/question_set';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {Appraisal} from '../../../../models/appraisal';
import {MacroSettingsForm} from './macro_settings_form';

export interface MacroSettingsProps {
    payload: MacroSettingsPayload;
    questionSet: QuestionSet;
    appraisal: Appraisal;
    onClose: () => void;
}

export const MacroSettingsModal: React.FC<MacroSettingsProps> = observer((props) => {
    const presenter = usePresenter(
        (container) =>
            new MacroSettingsPresenter(
                props.payload,
                props.onClose,
                container.business.macroInteractor(props.questionSet)
            )
    );

    return (
        <Modal visible forced>
            <div className="modal-header">
                <h2>Instellingen voor macro {`"${presenter.macroSettings.name}"`}</h2>
                <button className="close" onClick={() => props.onClose()}>
                    <span className="ion-md-close">&nbsp;</span>
                </button>
            </div>
            <div className="modal-body">
                <p className="leading">
                    Via deze instellingen kun je bepalen wanneer de macro getoond wordt om te selecteren.
                </p>

                <MacroSettingsForm
                    macroSettings={presenter.macroSettings}
                    isLoading={presenter.isLoading}
                    onChangeNewConstruction={(value) => presenter.onChangeNewConstruction(value)}
                    onNameChange={(value) => presenter.onNameChange(value)}
                    onAddDistrict={(value) => presenter.onAddDistrict(value)}
                    onRemoveDistrict={(value) => presenter.onRemoveDistrict(value)}
                    onAddBuildPeriod={(value) => presenter.onAddBuildPeriod(value)}
                    onRemoveBuildPeriod={(value) => presenter.onRemoveBuildPeriod(value)}
                    onAddObjectType={(value) => presenter.onAddObjectType(value)}
                    onRemoveObjectType={(value) => presenter.onRemoveObjectType(value)}
                    onAddAppraisalGoal={(value) => presenter.onAddAppraisalGoal(value)}
                    onRemoveAppraisalGoal={(value) => presenter.onRemoveAppraisalGoal(value)}
                />
            </div>
            <div className="modal-footer">
                {presenter.macroSettings.type !== MacroSettingsPayloadType.UNPERSISTED_SUPER && (
                    <button className="btn btn-secondary ion-md-trash" onClick={() => presenter.onRemoveClick()}>
                        Verwijderen
                    </button>
                )}
                <button
                    disabled={presenter.macroSettings.name.trim().length === 0 || presenter.isLoading}
                    className="btn btn-primary btn-full ion-md-arrow-forward"
                    onClick={() => presenter.onSave()}
                >
                    Opslaan & sluiten
                </button>
            </div>
        </Modal>
    );
});
