import * as React from 'react';

import {classNames} from '../../support/classnames';
import {diffToBadgeContext} from './badges/badge_context_calculators';

interface OwnProps {
    label: string;
    value: number | null;
    referenceValue: number | null;
    unit?: string;
}

export class StatusDetailListForReferenceValue extends React.Component<OwnProps> {
    public render() {
        const unit = this.props.unit !== undefined ? ' ' + this.props.unit : '';

        if (this.props.value !== null && this.props.value !== 0 && this.props.referenceValue !== null) {
            const diff = (100 * (this.props.value - this.props.referenceValue)) / this.props.referenceValue;
            // Check for absolute value to avoid "-0%"
            const badge = Math.abs(diff).toFixed(0) === '0' ? '0' : diff.toFixed(2);
            const badgeContext = diffToBadgeContext(diff);

            return (
                <dl>
                    <dt>{this.props.label}</dt>
                    <dd>
                        <span className={classNames('badge-container-wide', badgeContext)}>
                            {this.props.value + unit || '-'}
                            {badge.length ? (
                                <span className={classNames('badge', badgeContext)}>{badge + '%'}</span>
                            ) : null}
                        </span>
                    </dd>
                </dl>
            );
        }

        return (
            <dl>
                <dt>{this.props.label}</dt>
                <dd>
                    <span className={classNames('badge-container-wide')}>{this.props.value + unit || '-'}</span>
                </dd>
            </dl>
        );
    }
}
