import Editor, {CustomHTMLRenderer, MdNode, PluginInfo} from '@toast-ui/editor';
import {EmailMarkdownEditorData, TemplateBlock} from '.';

import {HTMLToken} from '@toast-ui/editor/types/toastmark';
import {renderBlock} from './blocks';

declare const EMAIL_MARKDOWN_EDITOR: EmailMarkdownEditorData;

let blocks: TemplateBlock[] = [];

if (typeof EMAIL_MARKDOWN_EDITOR !== 'undefined') {
    blocks = EMAIL_MARKDOWN_EDITOR.template.blocks;
}

export function blockPlugin(): PluginInfo {
    const toHTMLRenderers: CustomHTMLRenderer = {};

    for (const block of blocks) {
        toHTMLRenderers[block.name] = (node: MdNode) => {
            return [
                {type: 'openTag', tagName: 'div', outerNewLine: true},
                {type: 'html', content: renderBlock(block.type, node.literal)},
                {type: 'closeTag', tagName: 'div', outerNewLine: true},
            ] as HTMLToken[];
        };
    }

    return {
        toHTMLRenderers,
        wysiwygCommands: {
            'insert-block': (payload, state, dispatch) => {
                const {selection, schema, tr} = state;

                const block = payload as TemplateBlock;

                tr.insert(
                    selection.to,
                    state.schema.nodes.customBlock.create({info: block.name}, schema.text(block.defaultContent ?? ''))
                );

                dispatch(tr);

                return true;
            },
        },
    };
}

export function blockToolbarButton(getEditor: () => Editor) {
    const blockList = document.createElement('ul');
    blockList.setAttribute('aria-role', 'menu');

    for (const block of blocks) {
        const blockItem = document.createElement('li');
        blockItem.innerText = block.name;

        blockItem.onclick = () => {
            const editor = getEditor();

            editor.eventEmitter.emit('command', 'insert-block', block);
            editor.eventEmitter.emit('closePopup');
        };

        blockList.appendChild(blockItem);
    }

    return {
        name: 'add-block',
        tooltip: 'Blok',
        className: `ion-md-add bg-transparent`,
        style: {fontSize: 20},
        popup: {
            className: `toastui-editor-popup-add-heading`,
            body: blockList,
            style: {width: 'auto'},
        },
    };
}
