import * as React from 'react';
import {createRoot} from 'react-dom/client';

import {Root} from '../root';
import {AttachmentsWidget} from './widget';

export function renderPrecheckAttachmentsEnvironmentInto(element: HTMLElement) {
    const appraisalId = element.getAttribute('data-appraisal-id');
    if (appraisalId === null) {
        throw new Error('Trying to render pre-check environment without appraisal ID');
    }

    createRoot(element).render(
        <Root appraisalId={parseInt(appraisalId, 10)}>
            <AttachmentsWidget />
        </Root>
    );
}
