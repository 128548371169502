/**
 * Copy copy
 */
(() => {
    const copyBtn = $('.copy-code');

    if (copyBtn.length > 0) {
        copyBtn.bind('click', function (event) {
            const id = $(this).data('copy');
            if (!id) {
                return;
            }
            const codeInput = $(id);
            codeInput.focus();
            codeInput.select();

            try {
                var successful = document.execCommand('copy');
                alert(successful ? 'De tekst is gekopieerd.' : 'Er ging wat mis, de tekst is niet gekopieerd.');
            } catch (err) {
                alert('Er ging wat mis, de tekst is niet gekopieerd');
            }
        });
    }
})();
