import {SidebarItem, SidebarTreeBuilder} from '../../../business/sidebar_tree_builder';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../support/composite_subscription';
import {Presenter} from '../../../../support/presenter/presenter';

export class SidebarPresenter implements Presenter {
    @observable.ref public sidebarItems: SidebarItem[] = [];
    @observable private shouldCollapse = false;
    @observable public collapsed = this.shouldCollapse;

    private subscriptions = new CompositeSubscription();
    private mediaQueryList = window.matchMedia('(max-width: 1199px)');

    constructor(private sidebarTreeBuilder: SidebarTreeBuilder) {
        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            this.sidebarTreeBuilder.build.subscribe((sidebarItems) => {
                runInAction(() => {
                    this.sidebarItems = sidebarItems;
                });
            })
        );

        window.addEventListener('hashchange', this.onHashChange, false);

        // Todo: Refactor changing collapse sidebar on specific width
        this.onWidthThresholdChange(this.mediaQueryList.matches);
        if (this.mediaQueryList.addEventListener !== undefined) {
            this.mediaQueryList.addEventListener('change', this.onMediaQueryListChange);
        }
    }

    public unmount(): void {
        // Todo: Refactor changing collapse sidebar on specific width
        if (this.mediaQueryList.removeEventListener !== undefined) {
            this.mediaQueryList.removeEventListener('change', this.onMediaQueryListChange);
        }
        window.removeEventListener('hashchange', this.onHashChange);
        this.subscriptions.clear();
    }

    @action
    public toggle() {
        this.collapsed = !this.collapsed;
    }

    @action
    private onHashChange = () => {
        if (this.shouldCollapse) {
            this.collapsed = true;
        }
    };

    private onMediaQueryListChange = (e: MediaQueryListEvent) => {
        this.onWidthThresholdChange(e.matches);
    };

    @action
    private onWidthThresholdChange = (matches: boolean) => {
        this.shouldCollapse = this.collapsed = matches;
    };
}
