import React, {useCallback, useMemo, useRef, useState} from 'react';
import {QuestionSet} from '../../../../models/question_set';
import {QuestionTableContext} from './question_table_context';
import {sortQuestionTableQuestions} from './support/sorter';

interface TableRowData {
    /**
     * Question key is a concatenated key of all nested questions, e.g. "parent_uuid.parent2_uuid.question_uuid"
     * A special key value 'header' exists which will be used for the header row
     */
    questionKey: string;

    /**
     * The columns for this row, keyed by column key
     */
    columns: {[columnKey: string]: React.ReactNode};
}

export function QuestionTable({children, questionSet}: {children?: React.ReactNode; questionSet: QuestionSet}) {
    const [portals, setPortals] = useState<{[columnKey: string]: {[questionUuid: string]: HTMLElement | null}}>({});

    /**
     * This function is called (via context) when a question requests a new cell to be added to the table
     * We update the portals state with a new null value, which will later be replaced
     * by a reference to the HTML element for the cell, when it has been created.
     */
    const registerCell = useCallback(
        (columnKey: string, questionKey: string) => {
            setPortals((portals) => {
                const columnPortals = {...(portals[columnKey] ?? {})};
                if (questionKey in columnPortals) {
                    throw new Error(`Question already registered in column: ${questionKey}`);
                }
                columnPortals[questionKey] = null;

                return {
                    ...portals,
                    [columnKey]: columnPortals,
                };
            });
        },
        [setPortals]
    );

    /**
     * This function is called (via context) and will clear the table cell from the portals state.
     */
    const destroyCell = useCallback(
        (columnKey: string, questionKey: string) => {
            setPortals((portals) => {
                const columnPortals = {...(portals[columnKey] ?? {})};

                delete columnPortals[questionKey];

                return {
                    ...portals,
                    [columnKey]: columnPortals,
                };
            });
        },
        [setPortals]
    );

    /**
     * Main state that is used for rendering the table and creating table cells
     */
    const rows = useRef<{
        questions: {
            [questionKey: string]: TableRowData;
        };
        ordered: TableRowData[];
        columnKeys: string[];
    }>({questions: {}, ordered: [], columnKeys: []});

    useMemo(() => {
        for (const [columnKey, questions] of Object.entries(portals)) {
            for (const [questionKey, element] of Object.entries(questions)) {
                // Skip if portal already exists
                if (rows.current.questions[questionKey]?.columns[columnKey]) {
                    continue;
                }

                // Create a new table cell for the requested question
                const row = rows.current.questions[questionKey] ?? {
                    questionKey,
                    columns: {},
                };
                row.columns[columnKey] = (
                    <td
                        key={`${questionKey}-${columnKey}`}
                        ref={(el) => {
                            if (el === element) {
                                return;
                            }

                            // Populate the portals state with a reference to the element
                            setPortals((portals) => {
                                if (!portals[columnKey] || !(questionKey in portals[columnKey])) {
                                    return portals;
                                }

                                const columnPortals = portals[columnKey] ?? {};
                                columnPortals[questionKey] = el;

                                return {
                                    ...portals,
                                    [columnKey]: columnPortals,
                                };
                            });
                        }}
                    />
                );
                rows.current.questions[questionKey] = row;
            }
        }

        for (const [questionKey, row] of Object.entries(rows.current.questions)) {
            // Clean-up if question no longer used
            if (!Object.values(portals).some((columnPortals) => questionKey in columnPortals)) {
                delete rows.current.questions[questionKey];
                continue;
            }

            for (const columnKey of Object.keys(row)) {
                if (!(columnKey in portals) || !(questionKey in portals[columnKey])) {
                    // Clean-up if column no longer exists
                    delete rows.current.questions[questionKey].columns[columnKey];
                }
            }
        }

        // Sort the questions based on their expected order
        rows.current.ordered = sortQuestionTableQuestions(questionSet, rows.current.questions);
        rows.current.columnKeys = Object.keys(portals);

        return rows;
    }, [portals, setPortals, rows]);

    const [collapsedKeys, setCollapsedKeys] = useState<string[]>([]);

    const collapse = useCallback(
        (key: string) => {
            setCollapsedKeys((orig) => {
                if (orig.includes(key)) {
                    return orig;
                }
                return [...orig, key];
            });
        },
        [setCollapsedKeys]
    );

    const expand = useCallback(
        (key: string) => {
            setCollapsedKeys((orig) => {
                if (!orig.includes(key)) {
                    return orig;
                }
                return orig.filter((k) => k !== key);
            });
        },
        [setCollapsedKeys]
    );

    const contextValue = useMemo(
        () => ({
            portals,
            registerCell,
            destroyCell,
            collapsedKeys,
            collapse,
            expand,
        }),
        [registerCell, destroyCell, portals, collapsedKeys, collapse, expand]
    );

    const table = (
        <table key="table" className="question-table">
            <tbody>
                {rows.current.ordered.map(({questionKey, columns}) => (
                    <tr key={questionKey}>
                        {rows.current.columnKeys.map((k) =>
                            columns[k] ? (
                                <React.Fragment key={`${questionKey}-${k}`}>{columns[k]}</React.Fragment>
                            ) : (
                                <td key={`${questionKey}-${k}`} />
                            )
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    );

    return (
        <QuestionTableContext.Provider value={contextValue}>
            {table}
            {children}
        </QuestionTableContext.Provider>
    );
}
