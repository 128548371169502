import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';

import {AdaptedDefaultValuesMap} from '../../../../../models/adapted_default_values_map';
import {QuestionContainer} from '../question_container';
import {QuestionSet} from '../../../../../models/question_set';
import {ValidationError} from '../../../../../models/validation_error';
import {ValidationMessages} from '../components/validation_messages';
import {createValidationMessagesMap} from '../../../../../../support/create_validation_messages_map';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {observer} from 'mobx-react';
import {QuestionLoader} from '../../../components/question_loader';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
    showDeleteButton?: boolean;
}

export const IteratorGroup: React.FC<OwnProps> = observer(function IteratorGroup(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));

    const answer = presenter.answer;
    const showDeleteButton = props.showDeleteButton === undefined ? true : props.showDeleteButton;

    if (answer === undefined) {
        return <QuestionLoader />;
    }

    return (
        <div className="group-container iterator-group">
            <div className="iterator-group-children">
                {findChildrenForQuestion(props.question, props.questionSet).map((child) => (
                    <QuestionContainer
                        key={child.uuid}
                        appraisal={props.appraisal}
                        question={child}
                        questionSet={props.questionSet}
                        validationErrors={props.validationErrors}
                        validationMessages={createValidationMessagesMap(
                            presenter.validationMessages,
                            props.validationMessages
                        )}
                        forceShowValidationMessages={props.forceShowValidationMessages}
                        parentAnswerUuid={answer.uuid}
                        adaptedDefaultValues={props.adaptedDefaultValues}
                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                        pagePartsSet={props.pagePartsSet}
                        activePagePart={props.activePagePart}
                        renderingContext={props.renderingContext}
                        questionRenderingData={props.questionRenderingData}
                    />
                ))}
            </div>
            {showDeleteButton && !presenter.isDisabled && !props.disabled ? (
                <button className="btn btn-delete btn-sm ion-md-trash" onClick={() => presenter.onDeleteClick()} />
            ) : null}
            <ValidationMessages
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={answer}
                validationMessages={filterValidationMessages(
                    props.question,
                    answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
        </div>
    );
});
