import {action, computed, makeObservable} from 'mobx';
import {CompositeSubscription} from '../../../../../../../../../../support/composite_subscription';

import {ForcedSaleFilterRanges} from '../../../../../../../../../models/reference_set/forced_sale_filter_ranges';
import {SimpleQuestionPresenter} from '../../../../../simple/simple_question_presenter';

export class ForcedSaleFilterRangesQuestionPresenter extends SimpleQuestionPresenter {
    private subscriptions = new CompositeSubscription();

    constructor(...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public mount() {
        super.mount();

        this.subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid ?? null, this.iteration ?? null)
                .subscribe((answer) => {
                    if (answer && answer.contents === null) {
                        this.updateRanges(this.ranges); // Store default ranges
                    }
                })
        );
    }

    public unmount() {
        super.unmount();
        this.subscriptions.clear();
    }

    @computed public get ranges(): ForcedSaleFilterRanges {
        if (this.answer === undefined || this.answer.contents === null) {
            return {
                distance: [0, 50000],
                askingPrice: [50_000, 5_000_001],
                surfaceArea: [0, 501],
                plotArea: [0, 10_001],
                buildYear: [1949, new Date().getFullYear()],
                daysForSale: [0, 30],
            };
        } else {
            const ranges = JSON.parse(this.answer.contents);

            if (ranges.buildYear[0] === null) {
                ranges.buildYear[0] = 1949;
            }

            if (ranges.askingPrice[1] === null) {
                ranges.askingPrice[1] = 5_000_001;
            }

            if (ranges.surfaceArea[1] === null) {
                ranges.surfaceArea[1] = 501;
            }

            if (ranges.plotArea[1] === null) {
                ranges.plotArea[1] = 10_001;
            }

            return ranges;
        }
    }

    public onRangeChange = (range: keyof ForcedSaleFilterRanges, values: [number, number]) => {
        const ranges = {...this.ranges};
        ranges[range] = values as [number, number];
        this.updateRanges(ranges);
    };

    @action
    private updateRanges(ranges: ForcedSaleFilterRanges) {
        if (ranges.buildYear[0] === 1949) {
            ranges.buildYear[0] = null;
        }

        if (ranges.askingPrice[1] === 5_000_001) {
            ranges.askingPrice[1] = null;
        }

        if (ranges.surfaceArea[1] === 501) {
            ranges.surfaceArea[1] = null;
        }

        if (ranges.plotArea[1] === 10_001) {
            ranges.plotArea[1] = null;
        }

        this.onChange(JSON.stringify(ranges));
    }
}
