import React from 'react';

import {classNames} from '../../../support/classnames';
import {BadgeContext} from '../../enum/badge_context';
import {energyLabelDiffToBadgeContext} from './badge_context_calculators';

interface OwnProps {
    compact?: boolean;
    energyLabel: string | null;
    referenceEnergyLabel: string | null;
}

export function EnergyLabelBadge(props: OwnProps) {
    let badge: BadgeContext | null = null;
    if (props.energyLabel !== null && props.referenceEnergyLabel !== null) {
        badge = energyLabelDiffToBadgeContext(props.energyLabel, props.referenceEnergyLabel);
    }

    if (props.energyLabel === null || props.referenceEnergyLabel === null) {
        return null;
    }

    return (
        <span className="badge-container">
            <span className={classNames('badge', 'd-inline-block', badge)}>
                {props.compact ? '' : props.referenceEnergyLabel}
            </span>
        </span>
    );
}
