import * as React from 'react';

import {FunctionComponent} from 'react';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';
import {Textarea} from '../../../inputs/textarea';

interface OwnProps {
    referenceObjectAnswer: ReferenceObjectAnswer;
    onExtensionDifferenceExplanationChange: (extensionDifferenceExplanation: string) => void;
    isFrozen: boolean;
}

export const ExtensionDifferenceExplanation: FunctionComponent<OwnProps> = ({
    referenceObjectAnswer,
    onExtensionDifferenceExplanationChange,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-sm-12">
                <Textarea
                    isRequired
                    label="Bij-, op en/of aanbouwen verschil toelichting"
                    name="input-extra-buildings-explanation"
                    value={validationInstituteReferentieObject.aanbouwUitleg}
                    onChange={onExtensionDifferenceExplanationChange}
                    disabled={isFrozen}
                    maxLength={1000}
                />
            </div>
        </div>
    );
};
