import {computed, makeObservable, observable, runInAction} from 'mobx';

import {Appraisal} from '../models/appraisal';
import {AppraisalProvider} from '../business/appraisal_provider';
import {CompositeSubscription} from '../../support/composite_subscription';
import {PagePartConfigurationApi} from '../network/page_part_configuration_api';
import {Presenter} from '../../support/presenter/presenter';
import {QuestionSet} from '../models/question_set';
import {QuestionSetApi} from '../network/question_set_api';
import {ServerTimeProvider} from '../server_time/server_time_provider';
import {createDummyAppraisal} from '../business/dummy_appraisal';
import {first} from 'rxjs/operators';
import {PagePartConfigurationsInteractor} from '../business/page_parts/page_part_configurations_interactor';
import {PagePartsCleaner} from '../business/page_parts/page_parts_cleaner';

export class RootPresenter implements Presenter {
    @observable.ref public appraisal: Appraisal | null = null;
    @observable.ref public questionSet: QuestionSet | null = null;
    @observable public error = false;
    @observable private doneLoading = false;

    @computed
    public get isLoading(): boolean {
        return (this.appraisal === null || this.questionSet === null) && this.doneLoading === true;
    }

    private subscriptions = new CompositeSubscription();

    constructor(
        private questionSetId: number,
        private questionSetApi: QuestionSetApi,
        private pagePartConfigurationApi: PagePartConfigurationApi,
        private serverTimeProvider: ServerTimeProvider,
        private appraisalProvider: AppraisalProvider,
        private pagePartConfigurationsProvider: PagePartConfigurationsInteractor
    ) {
        makeObservable(this);
    }

    public async mount() {
        try {
            const questionSetPromise = this.questionSetApi.get(this.questionSetId);

            const pagePartConfigurationsPromise = this.pagePartConfigurationApi.getConfigurations();

            //This one is in here to force hydration
            const serverTimePromise = this.serverTimeProvider.dates().pipe(first()).toPromise();

            const [questionSet, pagePartConfigurations] = await Promise.all([
                questionSetPromise,
                pagePartConfigurationsPromise,
                serverTimePromise,
            ]);

            //Hydration for appraisal
            const appraisal = createDummyAppraisal();
            this.appraisalProvider.onChange(appraisal);

            //Hydration for page part configs
            const cleanedConfigs = new PagePartsCleaner(questionSet).cleanConfigs(pagePartConfigurations);
            this.pagePartConfigurationsProvider.onChange(cleanedConfigs);

            runInAction(() => {
                this.appraisal = appraisal;
                this.questionSet = questionSet;
                this.doneLoading = true;
            });
        } catch (error) {
            console.warn(error);
            runInAction(() => {
                this.error = true;
            });
        }
    }

    public async unmount() {
        this.subscriptions.clear();
    }
}
