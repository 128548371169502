import {apiGeoAddressToGeoAddress} from './models/api_geo_address';

import {AjaxDriver} from '../../../network/driver/ajax_driver';
import {GeoAddress} from '../models/geo_address';

export interface GeoApi {
    findByCoordinates(lat: number, lng: number): Promise<GeoAddress | null>;
    findByAddress(postalCode: string, number: string, letter: string): Promise<GeoAddress | null>;
}

export class DefaultGeoApi implements GeoApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public findByCoordinates(lat: number, lng: number): Promise<GeoAddress | null> {
        return this.call(`/ajax/geo-address/coordinates`, {
            method: 'POST',
            body: JSON.stringify({
                lat: lat,
                lng: lng,
            }),
        });
    }

    public findByAddress(postalCode: string, number: string, letter: string): Promise<GeoAddress | null> {
        return this.call(`/ajax/geo-address/address`, {
            method: 'POST',
            body: JSON.stringify({
                postal_code: postalCode,
                number: number,
                letter: letter,
            }),
        });
    }

    private async call(url: string, init: RequestInit): Promise<GeoAddress | null> {
        try {
            const requestInit: RequestInit = {
                ...init,
                credentials: 'same-origin',
                headers: {
                    'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            };

            const response = await this.ajaxDriver.fetch(url, requestInit);

            if (response.ok) {
                const data = await response.json();
                if (data !== null) {
                    return apiGeoAddressToGeoAddress(data);
                }
            }

            return null;
        } catch (e) {
            console.warn(e);
            return null;
        }
    }
}
