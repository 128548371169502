import * as React from 'react';

import {observer} from 'mobx-react';
import {QuestionType} from '../../../../../../../../../enum/question_type';
import {TechnicalReference} from '../../../../../../../../../enum/technical_reference';
import {Appraisal} from '../../../../../../../../../models/appraisal';
import {Question} from '../../../../../../../../../models/question';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {ValidationError} from '../../../../../../../../../models/validation_error';
import {QuestionTableCell} from '../../../../../../../components/question_table/question_table_cell';
import {ReferenceObjectComparisonColumnPresenter} from './reference_object_comparison_column_presenter';
import {formatMoney} from '../../../../../../../support/format_money';
import {ReferenceObjectComparisonColumnGroup} from './reference_object_comparison_column_group';
import {ScrollShrink} from '../../../../../../../components/scroll_shrink';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';
import {SetDefinition} from '../../reference_objects_question_presenter';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';

interface OwnProps {
    //QuestionContainer props
    appraisal: Appraisal;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    hiddenQuestionTypes: QuestionType[];

    question: Question;
    parentAnswerUuid: string;

    activeSetDefinition: SetDefinition;
}

export const ReferenceObjectComparisonColumn: React.FC<OwnProps> = observer(function ReferenceObjectComparisonColumn(
    props
) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectComparisonColumnPresenter(
                props.questionSet,
                props.question,
                props.parentAnswerUuid,
                container.business.v3ReferenceSetsProvider(props.appraisal, props.questionSet),
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.appraisalProvider,
                container.business.energyLabelProvider(props.appraisal, props.questionSet),
                container.business.volumeProvider(props.appraisal, props.questionSet),
                container.globalProvider
            )
    );

    return (
        <>
            {presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_PHOTO] && presenter.frontviewUrl && (
                <QuestionTableCell questionUuid={presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_PHOTO]}>
                    <ScrollShrink
                        className="detail-image detail-image-answer-modal detail-image-compact"
                        threshold={150}
                    >
                        <div className="shrink-slider-wrapper">
                            <div className="hide-grow shrink-details">
                                <span>
                                    {props.appraisal.address} {props.appraisal.houseNumber} {props.appraisal.letter}
                                </span>
                            </div>
                            <img src={presenter.frontviewUrl} />
                        </div>
                    </ScrollShrink>
                </QuestionTableCell>
            )}
            {presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_OBJECT_DETAILS_GROUP] && (
                <ReferenceObjectComparisonColumnGroup
                    title="Object details"
                    questionUuid={presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_OBJECT_DETAILS_GROUP]}
                >
                    {presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_ADRES] &&
                        presenter.frontviewUrl && (
                            <QuestionTableCell
                                questionUuid={presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_ADRES]}
                            >
                                <dl>
                                    <dt>Adres</dt>
                                    <dd>
                                        {props.appraisal.address} {props.appraisal.houseNumber} {props.appraisal.letter}
                                        <br />
                                        {props.appraisal.postalCode} {props.appraisal.city}
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>Prijs per m²</dt>
                                    <dd>
                                        {presenter.pricePerM2 !== null
                                            ? `€ ${presenter.pricePerM2.toLocaleString('nl-NL')}/m²`
                                            : 'Onbekend'}
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>Prijs per m³</dt>
                                    <dd>
                                        {presenter.pricePerM3 !== null
                                            ? `€ ${presenter.pricePerM3.toLocaleString('nl-NL')}/m³`
                                            : 'Onbekend'}
                                    </dd>
                                </dl>
                            </QuestionTableCell>
                        )}
                    {presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_BOUWJAAR] && (
                        <QuestionTableCell
                            questionUuid={presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_BOUWJAAR]}
                        >
                            <div className="form-group">
                                <label>Bouwjaar</label>
                                <span className="row">
                                    <span className="col-8">{presenter.buildYear}</span>
                                </span>
                            </div>
                        </QuestionTableCell>
                    )}
                    {presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_ENERGIELABEL] && (
                        <QuestionTableCell
                            questionUuid={presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_ENERGIELABEL]}
                        >
                            <div className="form-group">
                                <label>Energielabel</label>
                                <span className="row">
                                    <span className="col-8">{presenter.energyLabel}</span>
                                </span>
                            </div>
                        </QuestionTableCell>
                    )}
                    {presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_WONING_TYPE] && (
                        <QuestionTableCell
                            questionUuid={presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_WONING_TYPE]}
                        >
                            <div className="form-group">
                                <label>Woningtype</label>
                                <span className="row">
                                    <span className="col-8">{presenter.objectType}</span>
                                </span>
                            </div>
                        </QuestionTableCell>
                    )}
                    {presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_GEBRUIKS_OPPERVLAKTE] && (
                        <QuestionTableCell
                            questionUuid={
                                presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_GEBRUIKS_OPPERVLAKTE]
                            }
                        >
                            <div className="form-group">
                                <label>Gebruiksoppervlakte Wonen (m²)</label>
                                <span className="row">
                                    <span className="col-8">{presenter.surfaceArea}</span>
                                </span>
                            </div>
                        </QuestionTableCell>
                    )}
                    {presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_INHOUD] && (
                        <QuestionTableCell
                            questionUuid={presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_INHOUD]}
                        >
                            <div className="form-group">
                                <label>Inhoud (m³)</label>
                                <span className="row">
                                    <span className="col-8">{presenter.volume ?? 'Onbekend'}</span>
                                </span>
                            </div>
                        </QuestionTableCell>
                    )}
                    {presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_PERCEEL_OPPERVLAKTE] && (
                        <QuestionTableCell
                            questionUuid={
                                presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_PERCEEL_OPPERVLAKTE]
                            }
                        >
                            <div className="form-group">
                                <label>Perceeloppervlakte (m²)</label>
                                <span className="row">
                                    <span className="col-8">{presenter.plotArea}</span>
                                </span>
                            </div>
                        </QuestionTableCell>
                    )}
                </ReferenceObjectComparisonColumnGroup>
            )}
            {[SetType.SALE, SetType.SOLD].includes(props.activeSetDefinition.type) &&
                presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_VERKOOP_DETAILS_GROUP] && (
                    <ReferenceObjectComparisonColumnGroup
                        title="Verkoop details"
                        questionUuid={
                            presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_VERKOOP_DETAILS_GROUP]
                        }
                    >
                        {presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_VERKOOPPRIJS] && (
                            <QuestionTableCell
                                questionUuid={
                                    presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_VERKOOPPRIJS]
                                }
                            >
                                <div className="form-group">
                                    <label>Marktwaarde</label>
                                    <span className="row">
                                        <span className="col-8">
                                            {presenter.valuation && formatMoney(presenter.valuation)}
                                        </span>
                                    </span>
                                </div>
                            </QuestionTableCell>
                        )}
                        {presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_GECORRIGEERDE_VERKOOPPRIJS] && (
                            <QuestionTableCell
                                questionUuid={
                                    presenter.questionCellKeys[
                                        TechnicalReference.REFERENCE_OBJECT_GECORRIGEERDE_VERKOOPPRIJS
                                    ]
                                }
                            >
                                <div className="form-group">
                                    <label>Gecorr. marktwaarde</label>
                                    <span className="row">
                                        <span className="col-8">-</span>
                                    </span>
                                </div>
                            </QuestionTableCell>
                        )}
                    </ReferenceObjectComparisonColumnGroup>
                )}
            {props.activeSetDefinition.type === SetType.RENT &&
                presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_VERHUUR_DETAILS_GROUP] && (
                    <ReferenceObjectComparisonColumnGroup
                        title="Verhuur details"
                        questionUuid={
                            presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_VERHUUR_DETAILS_GROUP]
                        }
                    >
                        {presenter.questionCellKeys[
                            TechnicalReference.REFERENCE_OBJECT_AANVANGS_HUURPRIJS_PER_MAAND
                        ] && (
                            <QuestionTableCell
                                questionUuid={
                                    presenter.questionCellKeys[
                                        TechnicalReference.REFERENCE_OBJECT_AANVANGS_HUURPRIJS_PER_MAAND
                                    ]
                                }
                            >
                                <div className="form-group">
                                    <label>Huurprijs / maand</label>
                                    <span className="row">
                                        <span className="col-8">
                                            <span>-</span>
                                            <br />
                                            <small>
                                                Marktwaarde: {presenter.valuation && formatMoney(presenter.valuation)}
                                            </small>
                                        </span>
                                    </span>
                                </div>
                            </QuestionTableCell>
                        )}
                        {presenter.questionCellKeys[
                            TechnicalReference.REFERENCE_OBJECT_GECORRIGEERDE_HUURPRIJS_PER_MAAND
                        ] && (
                            <QuestionTableCell
                                questionUuid={
                                    presenter.questionCellKeys[
                                        TechnicalReference.REFERENCE_OBJECT_GECORRIGEERDE_HUURPRIJS_PER_MAAND
                                    ]
                                }
                            >
                                <div className="form-group">
                                    <label>Gecorr. huurprijs / maand</label>
                                    <span className="row">
                                        <span className="col-8">-</span>
                                    </span>
                                </div>
                            </QuestionTableCell>
                        )}
                        {presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_INGANGSDATUM_HUUR] && (
                            <QuestionTableCell
                                questionUuid={
                                    presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_INGANGSDATUM_HUUR]
                                }
                            >
                                <div className="form-group">
                                    <label>Ingangsdatum huur</label>
                                    <span className="row">
                                        <span className="col-8">-</span>
                                    </span>
                                </div>
                            </QuestionTableCell>
                        )}
                    </ReferenceObjectComparisonColumnGroup>
                )}
            {presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_VERSCHILLEN_GROUP] && (
                <ReferenceObjectComparisonColumnGroup
                    title="Verschillen"
                    questionUuid={presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_VERSCHILLEN_GROUP]}
                >
                    {presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_ONDERHOUDSSITUATIE] && (
                        <QuestionTableCell
                            questionUuid={
                                presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_ONDERHOUDSSITUATIE]
                            }
                        >
                            <div className="form-group">
                                <label>Onderhoudssituatie</label>
                                <span className="row">
                                    <span className="col-8">{presenter.onderhoudssituatie}</span>
                                </span>
                            </div>
                        </QuestionTableCell>
                    )}
                    {presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_AANBOUW_CHOICE] && (
                        <QuestionTableCell
                            questionUuid={
                                presenter.questionCellKeys[TechnicalReference.REFERENCE_OBJECT_AANBOUW_CHOICE]
                            }
                        >
                            <div className="form-group">
                                <label>Bij-, op en/of aanbouwen</label>
                                <span className="row">
                                    <span className="col-8">{presenter.aanbouw}</span>
                                </span>
                            </div>
                        </QuestionTableCell>
                    )}
                </ReferenceObjectComparisonColumnGroup>
            )}
        </>
    );
});
