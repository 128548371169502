import {observer} from 'mobx-react';
import React, {useEffect, useMemo, useState} from 'react';
import {classNames} from '../../../support/classnames';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {WarningWrapper} from '../components/warning_wrapper';
import {PrecheckWidget} from '../components/widget';
import {WidgetPresenter} from './widget_presenter';

export const Widget: React.FC = observer(() => {
    const presenter = usePresenter(
        (component) => new WidgetPresenter(component.business.appraisalProvider, component.network.appraisalApi)
    );

    const [isPriorAppraiser, setIsPriorAppraiser] = useState(false);
    const [hasLargeTurnoverFromClient, setHasLargeTurnoverFromClient] = useState(false);

    const expectedLargeTurnoverFromClient = useMemo(() => {
        if (!presenter.matchStatistics) {
            return false;
        }

        const clientPercentage =
            presenter.matchStatistics.sameClientCountLastCalendarYear /
            presenter.matchStatistics.totalCountLastCalendarYear;
        const lenderPercentage =
            (presenter.matchStatistics.sameLenderCountLastCalendarYear ?? 0) /
            presenter.matchStatistics.totalCountLastCalendarYear;

        return clientPercentage > 0.25 || lenderPercentage > 0.25;
    }, [
        presenter.matchStatistics?.totalCountLastCalendarYear,
        presenter.matchStatistics?.sameClientCountLastCalendarYear,
        presenter.matchStatistics?.sameLenderCountLastCalendarYear,
    ]);

    useEffect(() => {
        const update = () => {
            const isPriorAppraiser = document.querySelector<HTMLInputElement>(
                'input[name="is_prior_appraiser"]:checked'
            )?.value;
            const hasLargeTurnoverFromClient = document.querySelector<HTMLInputElement>(
                'input[name="has_large_turnover_from_client"]:checked'
            )?.value;

            setIsPriorAppraiser(isPriorAppraiser === '1');
            setHasLargeTurnoverFromClient(hasLargeTurnoverFromClient === '1');
        };

        const cleanup: (() => void)[] = [];

        document
            .querySelectorAll<HTMLInputElement>(
                'input[name="is_prior_appraiser"], input[name="has_large_turnover_from_client"]'
            )
            .forEach((element) => {
                element.addEventListener('change', update);
                cleanup.push(() => element.removeEventListener('change', update));
            });

        update();

        return () => {
            cleanup.forEach((callback) => callback());
        };
    }, []);

    return (
        <PrecheckWidget
            id="match-statistics"
            title="Eerdere betrokkenheid"
            description="Bekijk je betrokkenheid bij deze opdrachtgever of geldverstrekker, en dit adres."
            icon="ion-md-stats"
            loading={presenter.matchStatistics === undefined}
            errorStatus={
                expectedLargeTurnoverFromClient !== hasLargeTurnoverFromClient ||
                (presenter.matchStatistics !== undefined &&
                    presenter.matchStatistics.sameAddressLast2Years > 0 !== isPriorAppraiser)
            }
        >
            <div className="widget-blocks">
                {presenter.matchStatistics && (
                    <div className="row">
                        <div
                            className={classNames({
                                'col-4': presenter.matchStatistics.sameLenderCountLastCalendarYear === null,
                                'col-8': presenter.matchStatistics.sameLenderCountLastCalendarYear !== null,
                            })}
                        >
                            <WarningWrapper
                                warning={
                                    expectedLargeTurnoverFromClient !== hasLargeTurnoverFromClient
                                        ? expectedLargeTurnoverFromClient
                                            ? 'Volgens onze gegevens is een groot deel van je taxaties voor deze opdrachtgever of geldverstrekker.'
                                            : 'Volgens onze gegevens is het aantal taxaties voor deze opdrachtgever of geldverstrekker minder dan 25%.'
                                        : undefined
                                }
                            >
                                <div className="row">
                                    <div
                                        className={classNames({
                                            'col-12':
                                                presenter.matchStatistics.sameLenderCountLastCalendarYear === null,
                                            'col-6': presenter.matchStatistics.sameLenderCountLastCalendarYear !== null,
                                        })}
                                    >
                                        <div className="widget-block">
                                            <h3>Aantal taxaties voor opdrachtgever</h3>
                                            <div className="widget-block-number">
                                                {presenter.matchStatistics.sameClientCountLastCalendarYear}
                                            </div>
                                            <small className="widget-block-details">afgelopen kalenderjaar</small>
                                        </div>
                                    </div>
                                    {presenter.matchStatistics.sameLenderCountLastCalendarYear !== null && (
                                        <div className="col-6">
                                            <div className="widget-block">
                                                <h3>Aantal taxaties voor opdrachtverstrekker</h3>
                                                <div className="widget-block-number">
                                                    {presenter.matchStatistics.sameLenderCountLastCalendarYear}
                                                </div>
                                                <small className="widget-block-details">afgelopen kalenderjaar</small>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </WarningWrapper>
                        </div>
                        <div
                            className={classNames({
                                'col-6': presenter.matchStatistics.sameLenderCountLastCalendarYear === null,
                                'col-4': presenter.matchStatistics.sameLenderCountLastCalendarYear !== null,
                            })}
                        >
                            <WarningWrapper
                                warning={
                                    presenter.matchStatistics.sameAddressLast2Years > 0 !== isPriorAppraiser
                                        ? presenter.matchStatistics.sameAddressLast2Years > 0
                                            ? 'Volgens onze gegevens ben je eerder betrokken geweest bij deze woning.'
                                            : 'Volgens onze gegevens ben je niet eerder betrokken geweest bij deze woning.'
                                        : undefined
                                }
                            >
                                <div className="widget-block">
                                    <h3>Aantal taxaties voor adres</h3>
                                    <div className="widget-block-number">
                                        {presenter.matchStatistics.sameAddressLast2Years}
                                    </div>
                                    <small className="widget-block-details">afgelopen 2 jaar</small>
                                </div>
                            </WarningWrapper>
                        </div>
                    </div>
                )}
            </div>
        </PrecheckWidget>
    );
});
