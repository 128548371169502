import {StubberValuesMap} from '../business/answering/answer_path_stubber';
import {ConditionGroup} from './condition_group';
import {FileReference} from './file_reference';

export enum MacroType {
    DEFAULT = 'default',
    SUPER = 'super',
}

export enum MacroExternalType {
    MODEL_VALUES_EXPLANATION = 'model-values-explanation',
    REFEFENCES_REJECT_REASON = 'references-reject-reason',
    PRECHECK_REASONING = 'precheck-reasoning',
    APPRAISAL_NOTES = 'appraisal-notes',
    OBJECT_OWNERSHIP = 'object-ownership',
}

interface MacroBase {
    type: MacroType;
    id: number;
    isUserDefined: boolean;
    questionUuid: string | null;
    externalType: MacroExternalType | null;
    contents: string | null;
    favorite?: boolean;
    conditionGroups: ConditionGroup[];
}

export interface SuperMacroItem {
    id: number;
    questionUuid: string;
    uuid: string | null;
    iteration: string | null;
    parentUuid: string | null;

    contents: string | null;
    answerOptionUuid: string | null;
    file: FileReference | null;
}

export interface DefaultMacro extends MacroBase {
    type: MacroType.DEFAULT;
}

export interface SuperMacro extends MacroBase {
    type: MacroType.SUPER;
    items: SuperMacroItem[];
}

export type Macro = DefaultMacro | SuperMacro;

export interface SuperMacroValueMap {
    questionUuid: string;
    iterationMap: {[questionUuid: string]: string} | null;
    name: string | null;
    values: StubberValuesMap;
}
