import * as React from 'react';

import {Modal} from '../../content/questions/components/modal';

interface OwnProps {
    onClose: (selected: string[]) => void;
    options: string[];
    selected: string[];
}

export function ReferenceImagesLabelsModal({onClose, options, selected: initialSelected}: OwnProps) {
    const [selected, setSeletected] = React.useState<string[]>(initialSelected);

    return (
        <Modal
            visible
            forced
            className={'photo-sets-label-modal'}
            backdropClassName={'photo-sets-label-modal-backdrop'}
        >
            <div className="modal-header">
                <h2>Kies Foto&apos;s</h2>
            </div>
            <div className="modal-body">
                <div className="form-checklist">
                    {options.map((option, i) => (
                        <div className="form-group" key={i}>
                            <input
                                type="checkbox"
                                id={`${option}-${i}`}
                                name={`${option}-${i}`}
                                checked={selected.includes(option)}
                                onChange={() => {
                                    if (selected.includes(option)) {
                                        setSeletected(selected.filter((s) => s !== option));
                                    } else {
                                        setSeletected([...selected, option]);
                                    }
                                }}
                            />
                            <label htmlFor={`${option}-${i}`}>{option}</label>
                        </div>
                    ))}
                </div>
            </div>
            <div className="modal-footer">
                <button
                    className="btn btn-primary btn-full ion-md-close"
                    type="button"
                    onClick={() => onClose(selected)}
                >
                    Opslaan
                </button>
            </div>
        </Modal>
    );
}
