import React from 'react';

import {IteratorQuestionType, NormalQuestionType, QuestionType} from '../../../../../../../enum/question_type';
import {PagePartsSet} from '../../../../../../../models/page_parts_set';
import {Question} from '../../../../../../../models/question';
import {QuestionSet} from '../../../../../../../models/question_set';
import {FilesGroupCustomizer} from '../../../simple/files_group_customizer';
import {IteratorFilesCustomizer} from '../../iterator_files_customizer';
import {PhotoIteratorCustomizer} from '../../photo_iterator/photo_iterator_customizer';

export const CustomizableQuestionTypes = [
    IteratorQuestionType.PHOTO_ITERATOR,
    NormalQuestionType.FILES_GROUP,
    IteratorQuestionType.ITERATOR_FILES,
] as const;

export function isCustomizableQuestion(question: Question): boolean {
    return (CustomizableQuestionTypes as readonly QuestionType[]).includes(question.type);
}

interface OwnProps {
    question: Question;
    pagePartsSet: PagePartsSet;
    questionSet: QuestionSet;
}

export function CustomizationsContainer(props: OwnProps) {
    const questionType = props.question.type as (typeof CustomizableQuestionTypes)[number];

    switch (questionType) {
        case IteratorQuestionType.PHOTO_ITERATOR:
            return <PhotoIteratorCustomizer pagePartsSet={props.pagePartsSet} question={props.question} />;
        case NormalQuestionType.FILES_GROUP:
            return (
                <FilesGroupCustomizer
                    pagePartsSet={props.pagePartsSet}
                    question={props.question}
                    questionSet={props.questionSet}
                />
            );
        case IteratorQuestionType.ITERATOR_FILES:
            return (
                <IteratorFilesCustomizer
                    pagePartsSet={props.pagePartsSet}
                    question={props.question}
                    questionSet={props.questionSet}
                />
            );
        default:
            return null;
    }
}
