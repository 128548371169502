import {SortingDirection, SortingMethod} from '../../enum/reference_objects_sorting';
import {ReferencePreferences} from '../../models/reference_preferences';

export interface ApiReferencePreferences {
    sort_method: SortingMethod | null;
    sort_direction: SortingDirection | null;
}

export function referencePreferencesToApiReferencePreferences(
    referencePreferences: ReferencePreferences
): ApiReferencePreferences {
    return {
        sort_method: referencePreferences.sortMethod,
        sort_direction: referencePreferences.sortDirection,
    };
}
