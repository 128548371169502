import * as React from 'react';

import {DummyPhotoIterator} from '../dummy_photo_iterator';
import {PhotoIteratorPresenter} from './photo_iterator_presenter';
import {PhotoSorting} from '../../../../../../../enums/photo_sorting';
import {QuestionContainer} from '../../question_container';
import {ReactSortable} from 'react-sortablejs';
import {RenderingContextType} from '../../../../../../enum/rendering_context_type';
import {SimpleQuestionPresenterProps} from '../../simple/simple_question_presenter';
import type Sortable from 'sortablejs';
import {isMobile} from '../../../../../../../support/check_mobile';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {ImageViewer} from '../../../../../../components/image_viewer/image_viewer';
import {getEditableImageViewerComponent} from '../photo_question/components/editable_image_viewer_component';
import {StatusLabel, StatusLabelType} from '../../../../container/status_label';

interface OwnProps extends SimpleQuestionPresenterProps {
    fileTypes: string[];
}

export const PhotoIterator: React.FC<OwnProps> = observer(function PhotoIterator(props) {
    if (props.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
        return <DummyPhotoIterator />;
    }

    const presenter = usePresenter(
        (container) =>
            new PhotoIteratorPresenter(
                props.fileTypes,
                props.question,
                props.appraisal,
                props.parentAnswerUuid,
                props.questionSet,
                props.pagePartsSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.questionEffectsInteractor(props.appraisal, props.questionSet),
                container.business.userInteractor,
                container.business.userSettingsInteractor(props.appraisal, props.questionSet),
                container.business.attachmentUploadInteractor(props.appraisal, props.questionSet),
                container.business.blobCacheInteractor(),
                container.business.flashMessageBroadcaster
            )
    );

    //Keep a reference to the sortable instance so we can destroy it when this component unmounts
    //Else this will result in a memory leak since sortablejs keeps a reference to the underlaying dom-node
    //Probably for event listeners for dragging etc.
    const sortableRef = React.useRef<Sortable | null>(null);
    React.useEffect(() => {
        return () => sortableRef.current?.destroy();
    }, []);

    function renderPhotos() {
        if (presenter.loading || presenter.iterations === undefined) {
            return null;
        }
        if (presenter.iterations.length === 0) {
            return <div className="photo-group-placeholder" />;
        }

        const iterations = presenter.iterations.map((iteration) => {
            return (
                <QuestionContainer
                    key={iteration}
                    disabled={props.disabled || presenter.isDisabled}
                    iteration={iteration}
                    appraisal={props.appraisal}
                    question={presenter.iteratorPhotoGroupQuestion}
                    questionSet={props.questionSet}
                    validationErrors={props.validationErrors}
                    validationMessages={props.validationMessages}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    parentAnswerUuid={props.parentAnswerUuid}
                    adaptedDefaultValues={props.adaptedDefaultValues}
                    hiddenQuestionTypes={props.hiddenQuestionTypes}
                    pagePartsSet={props.pagePartsSet}
                    activePagePart={props.activePagePart}
                    renderingContext={props.renderingContext}
                    questionRenderingData={props.questionRenderingData}
                />
            );
        });

        // Only render sortable on non-mobile
        return isMobile() ? (
            <div className="row">{iterations}</div>
        ) : (
            <ReactSortable
                ref={(reactSortable) => {
                    if (reactSortable && 'sortable' in reactSortable) {
                        sortableRef.current = (reactSortable as unknown as {sortable: Sortable}).sortable;
                    }
                }}
                className="row"
                list={presenter.iterations.map((iteration) => ({
                    id: iteration,
                }))}
                setList={(updatedList, sortable) => {
                    if (sortable) {
                        presenter.onRanksOfIterationsChange(updatedList.map((item) => item.id));
                    }
                }}
            >
                {iterations}
            </ReactSortable>
        );
    }

    return (
        <>
            <div className="photo-extra-options-container">
                <label className="toggle-checkbox">
                    Automatische sortering
                    <input
                        type="checkbox"
                        checked={presenter.settings?.photoSorting === PhotoSorting.TAXAPI_ORDER}
                        disabled={props.disabled || presenter.isDisabled}
                        onChange={(e) =>
                            presenter.setSort(e.target.checked ? PhotoSorting.TAXAPI_ORDER : PhotoSorting.OWN_SORTING)
                        }
                    />
                    <div className="toggle-checkbox__bullet" />
                </label>
                {presenter.hasAvailablePhotos && (
                    <button
                        className="btn btn-sm ion-md-download ml-3"
                        onClick={async () => {
                            await presenter.downloadZip();
                        }}
                    >
                        Export
                        <StatusLabel type={StatusLabelType.Beta} content="Bèta" />
                    </button>
                )}
            </div>
            <div className="row grid photo-iterator-grid">
                <ImageViewer
                    images={presenter.viewerDetails}
                    components={[
                        getEditableImageViewerComponent({
                            isEditable: !presenter.isDisabled && !props.disabled,
                        }),
                    ]}
                >
                    <div className="col-12">{renderPhotos()}</div>
                </ImageViewer>
            </div>
            <div className="row grid photo-iterator-grid">
                <div className="col-12">
                    <h2>Niet getoond in rapport</h2>
                </div>
                <ImageViewer
                    images={presenter.deletedViewerDetails}
                    components={[
                        getEditableImageViewerComponent({
                            isEditable: !presenter.isDisabled && !props.disabled,
                        }),
                    ]}
                >
                    {!presenter.loading &&
                        !(presenter.deletedIterations === undefined) &&
                        presenter.deletedIterations.map((iteration) => {
                            return (
                                <QuestionContainer
                                    key={presenter.iteratorPhotoGroupQuestion.uuid + '[' + iteration + ']'}
                                    disabled={true}
                                    iteration={iteration}
                                    appraisal={props.appraisal}
                                    question={presenter.iteratorPhotoGroupQuestion}
                                    questionSet={props.questionSet}
                                    validationErrors={props.validationErrors}
                                    validationMessages={props.validationMessages}
                                    forceShowValidationMessages={props.forceShowValidationMessages}
                                    parentAnswerUuid={props.parentAnswerUuid}
                                    adaptedDefaultValues={props.adaptedDefaultValues}
                                    hiddenQuestionTypes={props.hiddenQuestionTypes}
                                    pagePartsSet={props.pagePartsSet}
                                    activePagePart={props.activePagePart}
                                    renderingContext={props.renderingContext}
                                    questionRenderingData={props.questionRenderingData}
                                />
                            );
                        })}
                </ImageViewer>
            </div>
        </>
    );
});
