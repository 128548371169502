import React from 'react';

import {classNames} from '../../../support/classnames';
import {BadgeContext} from '../../enum/badge_context';
import {diffToBadgeContext} from './badge_context_calculators';

interface OwnProps {
    compact?: boolean;
    plotArea: number;
    referencePlotArea: number;
}

export function PlotAreaBadge(props: OwnProps) {
    let badge: BadgeContext | null = null;
    let diff: number | null = null;

    if (props.referencePlotArea !== null && props.referencePlotArea !== 0 && Math.abs(props.plotArea) !== 0) {
        diff = (100 * (props.plotArea - props.referencePlotArea)) / props.referencePlotArea;
        badge = diffToBadgeContext(diff);
    }

    if (props.referencePlotArea === null || props.referencePlotArea === 0) {
        return null;
    }

    // Check for absolute value to avoid "-0%"
    const fixedValue = Math.abs(diff ?? 0).toFixed(0) === '0' ? '0' : diff?.toFixed(props.compact ? 0 : 2);

    return (
        <span className="badge-container">
            {badge && <span className={classNames('badge', badge)}>{fixedValue + '%'}</span>}
            {!props.compact ? `(${props.referencePlotArea}m²)` : null}
        </span>
    );
}
