import * as React from 'react';
import {Appraisal} from '../../../../../models/appraisal';
import {observer} from 'mobx-react';

interface OwnProps {
    appraisal: Appraisal;
}

@observer
export class ClientFilesFinishedContent extends React.Component<OwnProps> {
    public render() {
        return (
            <div className="empty-background empty-background-inline">
                <div className="row">
                    <div className="card-icon-container card-icon-container-bg">
                        <div className="card-icon card-icon-animated card-icon-green ion-md-thumbs-up"></div>
                    </div>
                    <div className="col-12 text-center">
                        <h3>Bestanden verstuurd naar taxateur</h3>
                    </div>
                </div>
            </div>
        );
    }
}
