import {AnswerController} from '../answering/answer_controller';
import {AnswerValidator} from './answer_validator';
import {QuestionSet} from '../../models/question_set';
import {UserInteractor} from '../user_interactor';
import {ValidationMessage} from './validation_message';
import {bugsnagClient} from '../../../support/bugsnag_client';

export interface AnswerValidatorAdapter {
    validate(questionUuid: string, answerUuid: string | null): ValidationMessage[];
}

export class DefaultAnswerValidatorAdapter implements AnswerValidatorAdapter {
    constructor(
        private questionSet: QuestionSet,
        private validators: AnswerValidator[],
        private answerController: AnswerController,
        private userInteractor: UserInteractor
    ) {}

    public validate(questionUuid: string, answerUuid: string | null): ValidationMessage[] {
        const answer = answerUuid != null ? this.answerController.byUuid(answerUuid) : null;
        const question = this.questionSet.findQuestionByUuid(questionUuid);

        if (question === undefined) {
            //Only throw an error if we are the appraiser, non-appraiser users dont get the whole questionSet
            if (
                this.userInteractor.isAppraiser() ||
                this.userInteractor.isEmployee() ||
                this.userInteractor.isJuniorAppraiser()
            ) {
                const error = new Error(`Trying to validate non-existent question`);
                bugsnagClient?.notify(error, {
                    metaData: {
                        answer,
                        questionUuid,
                        answerUuid,
                    },
                });
                throw error;
            }

            return [];
        }

        return this.validators.reduce<ValidationMessage[]>((messages, validator) => {
            try {
                return [...messages, ...validator.validate(question, answer)];
            } catch (error) {
                console.warn('Error in validator:', error);
                return messages;
            }
        }, []);
    }
}
