import React from 'react';
import {
    ImageViewerComponent,
    ImageViewerComponentProps,
} from '../../../../../../../../../components/image_viewer/image_viewer';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';
import {observer} from 'mobx-react';
import {ImageViewerSelectorPresenter} from './image_viewer_selector_presenter';
import {classNames} from '../../../../../../../../../../support/classnames';
import {PhotoSet} from './photo_sets';

interface OwnProps {
    activeSet: number;
    setActiveSet: (set: number) => void;
    sets: PhotoSet[];
}

const PhotoSetSelector: React.FC<ImageViewerComponentProps & OwnProps> = observer(function PhotoSetSelector({
    lightbox,
    activeSet,
    setActiveSet,
    sets,
}) {
    const presenter = usePresenter(
        (container) => new ImageViewerSelectorPresenter(container.business.modalConfigStackInteractor, lightbox)
    );

    if (presenter.hidden) {
        return null;
    }

    const setDate = sets[activeSet].quarterDate;

    if (setDate === null && sets.length <= 1) return null;

    return (
        <div>
            <button
                className={classNames('btn btn-sm ion-md-calendar', {
                    'btn-primary': sets.length > 1,
                    'btn-secondary': sets.length <= 1,
                })}
                disabled={sets.length <= 1}
                onClick={() =>
                    sets.length > 1
                        ? presenter.photoSetModal(sets, activeSet).then((newIndex) => {
                              setActiveSet(newIndex);
                          })
                        : null
                }
            >
                {setDate ? `Q${Math.floor(setDate.getMonth() / 3) + 1}-${setDate.getFullYear()}` : 'Onbekend'}
            </button>
        </div>
    );
});

export const getPhotoSetSelectorComponent: (props: OwnProps) => ImageViewerComponent = (props) => ({
    name: 'photo-set-selector',
    appendTo: 'bar',
    order: 6,
    className: 'photo-sets-toolbar-button',
    component: <PhotoSetSelector {...props} />,
});
