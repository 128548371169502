export enum AppraisalGoal {
    ACQUIRE_MORTAGE = 'acquire_mortage',
    INSIGHT_FOR_PURCHASE = 'insight_for_purchase',
    INSIGHT_FOR_SALE = 'insight_for_sale',
    INSIGHT_FOR_REPURCHASE = 'insight_for_repurchase',
    FORCED_SALE = 'forced_sale',
    OTHER = 'other',
}

export function appraisalGoalText(value: AppraisalGoal) {
    switch (value) {
        case AppraisalGoal.ACQUIRE_MORTAGE:
            return 'Een lening krijgen of aanpassen (financiering)';
        case AppraisalGoal.INSIGHT_FOR_PURCHASE:
            return 'De woning kopen (aankoopbeslissing)';
        case AppraisalGoal.INSIGHT_FOR_SALE:
            return 'De woning verkopen (verkoopbeslissing)';
        case AppraisalGoal.INSIGHT_FOR_REPURCHASE:
            return 'De woning terug verkopen (terugkoopbeslissing)';
        case AppraisalGoal.FORCED_SALE:
            return 'De woning gedwongen verkopen (Bijzonder Beheer)';
        case AppraisalGoal.OTHER:
            return 'Een andere reden, namelijk';
    }
}
