import * as React from 'react';

import {PagePart} from '../../../../../../models/page_part';
import {PagePartGroup} from './page_part_group';
import {PagePartIteratorPresenter} from './page_part_iterator_presenter';
import {PagePartsSet} from '../../../../../../models/page_parts_set';
import {QuestionSet} from '../../../../../../models/question_set';
import {SimpleQuestionPresenterProps} from '../../simple/simple_question_presenter';
import {observer} from 'mobx-react-lite';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {RenderingContextType} from '../../../../../../enum/rendering_context_type';
import {QuestionLoader} from '../../../../components/question_loader';

interface OwnProps extends SimpleQuestionPresenterProps {
    questionSet: QuestionSet;
    activePagePart: PagePart;
    pagePartsSet: PagePartsSet;
}

export const PagePartIterator: React.FC<OwnProps> = observer(function PagePartIterator(props) {
    const presenter = usePresenter(
        (component) =>
            new PagePartIteratorPresenter(
                component.business.floorInteractor(props.appraisal, props.questionSet, props.renderingContext)
            )
    );

    if (presenter.floorIteration === undefined) {
        return <QuestionLoader />;
    }

    let floorIteration = presenter.floorIteration;
    if (floorIteration === null) {
        if (props.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
            floorIteration = '1';
        } else {
            return <div>Maak eerst een woonlaag aan voordat je deze vraag kan beantwoorden.</div>;
        }
    }

    return (
        <PagePartGroup
            {...props}
            iteration={floorIteration}
            key={floorIteration}
            title={
                props.question.contents && presenter.floorInfo ? (
                    <>
                        {props.question.contents}{' '}
                        <span className="question-floor-label">{presenter.floorInfo.name}</span>
                    </>
                ) : undefined
            }
        />
    );
});
