import {Presenter} from '../../../../support/presenter/presenter';
import {QuestionSet} from '../../../models/question_set';
import {ValidationError} from '../../../models/validation_error';
import {isEmpty} from '../../../../support/util';

export class ValidationErrorsListPresenter implements Presenter {
    public get validationMessages() {
        return this.getValidationMessagesWithQuestion().filter(this.filterInvalid);
    }

    constructor(private questionSet: QuestionSet, private validationErrors: ValidationError[]) {}

    public mount(): void {
        //Noop
    }

    public unmount(): void {
        //Noop
    }

    private getValidationMessagesWithQuestion() {
        const validationErrors: ValidationError[] = [];

        this.validationErrors.forEach((validationError: ValidationError) => {
            if (validationError.section !== null && validationError.section !== undefined) {
                const question = this.questionSet.findQuestionByReportParagraphReference(validationError.section);
                if (question !== null) {
                    validationError.question = question;
                }
            }
            validationErrors.push(validationError);
        });

        return validationErrors;
    }

    private filterInvalid(validationError: ValidationError) {
        return !isEmpty(validationError.field) && !isEmpty(validationError.message);
    }
}
