import * as React from 'react';

import {PagePart} from '../../../../../../models/page_part';
import {PagePartsSet} from '../../../../../../models/page_parts_set';
import {QuestionSet} from '../../../../../../models/question_set';
import {SimpleQuestionPresenterProps} from '../../simple/simple_question_presenter';
import {observer} from 'mobx-react-lite';
import {NormalQuestionType} from '../../../../../../enum/question_type';
import {PagePartGroup} from '../page_part/page_part_group';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {SymlinkLinkPresenter, SymlinkType} from './symlink_link_presenter';
import {Select, Option} from '../../../../components/select';
import {classNames} from '../../../../../../../support/classnames';
import {QuestionLoader} from '../../../../components/question_loader';
import {shouldShowTitle} from '../../../../../../../support/should_show_title';

interface OwnProps extends SimpleQuestionPresenterProps {
    questionSet: QuestionSet;
    activePagePart: PagePart;
    pagePartsSet: PagePartsSet;
}

export const SymlinkLink: React.FC<OwnProps> = observer(function SymlinkLink(props) {
    const presenter = usePresenter(
        (container) =>
            new SymlinkLinkPresenter(
                props.question,
                props.questionSet,
                props.parentAnswerUuid ?? null,
                props.renderingContext,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.questionEffectsInteractor(props.appraisal, props.questionSet)
            )
    );

    if (presenter.answer === null || presenter.answerContents === null) {
        return <QuestionLoader />;
    }

    if (
        props.question.type !== NormalQuestionType.SYMLINK_LINK ||
        props.question.technicalReference === null ||
        presenter.targetQuestion === null ||
        presenter.isHidden
    ) {
        return null;
    }

    const dropdownOptions: Option<string>[] = [{label: 'Antwoorden zelf invullen.', value: 'clean|-|0'}];
    if (presenter.copyableLinks) {
        for (const copyableLink of presenter.copyableLinks) {
            dropdownOptions.push({
                label: `Antwoorden overnemen van "${copyableLink.name}" en bewerken.`,
                value: 'copy|-|' + copyableLink.iteration,
            });
            dropdownOptions.push({
                label: `Antwoorden overnemen van "${copyableLink.name}".`,
                value: 'mirror|-|' + copyableLink.iteration,
            });
        }
    }
    let currentDropdownValue = '-1';
    if (presenter.answerContents) {
        if (presenter.answerContents.type === SymlinkType.MIRROR) {
            currentDropdownValue = `mirror|-|${presenter.answerContents.targetIterationUuid}`;
        } else if (presenter.answerContents.type === SymlinkType.COPY) {
            currentDropdownValue = `copy|-|${presenter.answerContents.targetIterationUuid}`;
        } else if (presenter.answerContents.type === SymlinkType.CLEAN_SLATE) {
            currentDropdownValue = 'clean|-|0';
        }
    }

    return (
        <div
            className={classNames('symlink-link', {
                'include-border': presenter.targetQuestion?.type === NormalQuestionType.SYMLINK_TARGET_COPY,
            })}
        >
            {shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel) && (
                <div className="symlink-link-title">
                    <h2>{props.question.contents}</h2>
                </div>
            )}

            {dropdownOptions.length > 1 && (
                <Select
                    id="symlink-behavior"
                    value={currentDropdownValue}
                    isDisabled={false}
                    isClearable={false}
                    placeHolder="Selecteer een optie."
                    onChange={(newValue) => {
                        if (newValue) {
                            const [operation, uuid] = newValue.split('|-|');
                            if (operation === 'clean') {
                                presenter.setCleanSlateSource();
                            } else if (operation === 'copy') {
                                const copyableLink = presenter.copyableLinks?.find((cl) => cl.iteration === uuid);
                                if (copyableLink) {
                                    presenter.setCopySource(copyableLink);
                                }
                            } else if (operation === 'mirror') {
                                presenter.setMirrorSource(uuid);
                            }
                        }
                    }}
                    options={dropdownOptions}
                />
            )}

            {presenter.answerContents.type === SymlinkType.MIRROR && (
                <div className="alert-inline alert-info ion-md-information-circle">
                    Deze groep vragen is gespiegeld. Pas de originele groep vragen aan en deze antwoorden zullen
                    automatisch mee veranderen.
                </div>
            )}

            <PagePartGroup
                key={`${presenter.targetQuestion.uuid}-${presenter.symlinkIteration}-${presenter.answerContents.type}`}
                {...props}
                parentAnswerUuid={undefined}
                disabled={
                    presenter.answerContents.type === SymlinkType.MIRROR ||
                    presenter.answerContents.type === SymlinkType.UNDETERMINED
                }
                limitRenderedQuestions={presenter.answerContents.type === SymlinkType.UNDETERMINED}
                question={presenter.targetQuestion}
                iteration={presenter.symlinkIteration}
                hideTitle={true}
            />
        </div>
    );
});
