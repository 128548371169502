import {action, computed, makeObservable, observable, runInAction} from 'mobx';
import uuid from 'uuid';
import {SidebarIcons} from '../../enums/sidebar_icons';
import {CompositeSubscription} from '../../support/composite_subscription';
import {Presenter} from '../../support/presenter/presenter';
import {PagePart} from '../models/page_part';
import {PagePartsSet} from '../models/page_parts_set';
import {Question} from '../models/question';
import {QuestionSet} from '../models/question_set';
import {NewSubPageState} from './components/new_page_modal/new_sub_page_modal';
import {NewRootPageState} from './components/new_root_page_modal/new_root_page_modal';
import {Global, GlobalProvider} from '../../business/global_provider';
import {PagePartConfigurationApi} from '../network/page_part_configuration_api';
import {FlashMessageBroadcaster, Type as FlashMessageType} from '../business/flash_message_broadcaster';
import {IteratorQuestionType, NormalQuestionType} from '../enum/question_type';
import {PagePartConfigurationsInteractor} from '../business/page_parts/page_part_configurations_interactor';
import {PagePartsSetInteractor} from '../business/page_parts/page_parts_set_interactor';
import {PagePartsCleaner} from '../business/page_parts/page_parts_cleaner';

export enum ModalTypes {
    NONE = 'none',
    NEW_ROOT_PAGE = 'new-root-page',
    NEW_SUB_PAGE = 'new-sub-page',
}

interface NoModalState {
    type: ModalTypes.NONE;
}

interface NewSubPageModalState extends NewSubPageState {
    type: ModalTypes.NEW_SUB_PAGE;
}

interface NewRootPageModalState extends NewRootPageState {
    type: ModalTypes.NEW_ROOT_PAGE;
}

enum LoadingStateType {
    IDLE = 'idle',
    PERSISTING = 'persisting',
    ERRORED = 'errored',
}

type ModalState = NoModalState | NewRootPageModalState | NewSubPageModalState;

export class ActivePagePartsSetLoaderPresenter implements Presenter {
    @observable.ref private pagePartQuestions: Question[] = [];
    @observable.ref public global: Global;
    @observable public isLoadingLeave = false;

    @observable public modalState: ModalState = {
        type: ModalTypes.NONE,
    };

    @observable.ref public pagePartsSets: PagePartsSet[] | null = null;

    @computed
    public get pagePartsSet(): PagePartsSet | null {
        return this.pagePartsSets?.find((pps) => pps.id === this.pagePartConfigurationId) ?? null;
    }

    @observable public loadingState = LoadingStateType.IDLE;

    @computed
    public get visiblePagePartsSets(): PagePartsSet[] | null {
        return this.pagePartsSets?.filter((set) => set.config.isVisible === true) ?? null;
    }

    @computed
    public get isLoading(): boolean {
        return this.loadingState === LoadingStateType.PERSISTING;
    }

    @computed
    public get unassignedPagePartQuestions() {
        if (this.pagePartsSet === null) {
            return this.pagePartQuestions;
        }

        const allPageParts = this.pagePartsSet.pageParts;
        const existingQuestions = new Set(allPageParts.map((pp) => pp.questionUuid));
        return this.pagePartQuestions.filter((q) => !existingQuestions.has(q.uuid));
    }

    private subscriptions = new CompositeSubscription();

    constructor(
        private pagePartConfigurationId: number,
        private questionSet: QuestionSet,
        private pagePartConfigurationApi: PagePartConfigurationApi,
        private pagePartConfigurationsInteractor: PagePartConfigurationsInteractor,
        private flashMessageBroadcaster: FlashMessageBroadcaster,
        private pagePartsSetInteractor: PagePartsSetInteractor,
        private pagePartsCleaner: PagePartsCleaner,
        globalProvider: GlobalProvider
    ) {
        makeObservable(this);

        this.global = globalProvider.global;

        this.pagePartQuestions = [
            ...questionSet.findQuestionsByType(NormalQuestionType.PAGE_PART_GROUP),
            ...questionSet.findQuestionsByType(IteratorQuestionType.PAGE_PART_ITERATOR),
        ];
    }

    public async mount() {
        this.subscriptions.add(
            this.pagePartsSetInteractor.pagePartsSetsStream().subscribe((pagePartsSets) => {
                runInAction(() => {
                    this.pagePartsSets = pagePartsSets;
                });
            })
        );
    }

    public async unmount() {
        this.subscriptions.clear();
    }

    public onSave = async () => {
        if (this.pagePartsSet) {
            try {
                runInAction(() => {
                    this.loadingState = LoadingStateType.PERSISTING;
                });

                const cleanedPageParts = this.pagePartsCleaner.cleanPageParts(this.pagePartsSet.pageParts);
                const result = await this.pagePartConfigurationApi.storePageParts(
                    this.pagePartsSet.id,
                    cleanedPageParts
                );
                if (result) {
                    this.pagePartConfigurationsInteractor.upsertConfiguration(result);
                    runInAction(() => {
                        this.loadingState = LoadingStateType.IDLE;
                    });
                    this.flashMessageBroadcaster.broadcast('Configuratie opgeslagen.', FlashMessageType.Success);
                } else {
                    throw new Error('Something went wrong');
                }
            } catch (error) {
                runInAction(() => {
                    this.loadingState = LoadingStateType.ERRORED;
                });
                this.flashMessageBroadcaster.broadcast(
                    'Er is iets fout gegaan tijdens het opslaan.',
                    FlashMessageType.Danger
                );
            }
        }
    };

    @action
    public onLeave = async () => {
        runInAction(() => (this.isLoadingLeave = true));
        await this.onSave();
        window.location.href = this.global.appraiseUrl ?? '#';
    };

    public updateRank = (newRank: number, uuid: string) => {
        if (this.pagePartsSet) {
            this.pagePartsSetInteractor.updatePagePartsRanks(this.pagePartsSet.id, [{rank: newRank, uuid}]);
        }
    };

    @action
    public onNewSubPageChange = (value: string) => {
        if (this.modalState.type === ModalTypes.NEW_SUB_PAGE) {
            this.modalState.name = value;
        }
    };

    @action
    public onShowNewSubPageModal = (parent: PagePart) => {
        this.modalState = {
            type: ModalTypes.NEW_SUB_PAGE,
            name: '',
            parent: parent,
        };
    };

    public onNewSubPage = (newSubPageState: NewSubPageState) => {
        if (this.modalState.type === ModalTypes.NEW_SUB_PAGE) {
            this.addNewPagePart({
                parentUuid: newSubPageState.parent.uuid,
                name: newSubPageState.name,
                questionUuid: null,
            });
            this.onHideModals();
        }
    };

    @action
    public onNewRootPageChange = (state: NewRootPageState) => {
        if (this.modalState.type === ModalTypes.NEW_ROOT_PAGE) {
            this.modalState = {
                ...this.modalState,
                ...state,
            };
        }
    };

    @action
    public onShowNewRootPageModal = () => {
        this.modalState = {
            type: ModalTypes.NEW_ROOT_PAGE,
            name: '',
            isIndexPage: false,
            icon: SidebarIcons.GROUP,
        };
    };

    public onNewRootPage = (newRootPageState: NewRootPageState) => {
        if (this.modalState.type === ModalTypes.NEW_ROOT_PAGE) {
            this.addNewPagePart({
                parentUuid: null,
                name: newRootPageState.name,
                questionUuid: null,
                icon: newRootPageState.icon,
                isIndexPage: newRootPageState.isIndexPage,
            });
            this.onHideModals();
        }
    };

    @action
    public onHideModals = () => {
        this.modalState = {
            type: ModalTypes.NONE,
        };
    };

    public onDeletePagePart = (uuid: string, activePagePartUuid: string | null) => {
        if (this.pagePartsSet && confirm('Weet je het zeker?')) {
            this.pagePartsSetInteractor.removePagePart(this.pagePartsSet.id, uuid);
            this.navigateAfterDelete(uuid, activePagePartUuid);
        }
    };

    private navigateAfterDelete(uuid: string, activePagePartUuid: string | null) {
        if (this.pagePartsSet && activePagePartUuid === uuid) {
            const rootItems = this.pagePartsSet.rootItems;
            window.location.href = `#/page-parts-configuration/${this.pagePartsSet.id}/${rootItems[0].uuid}`;
        }
    }

    public onQuestionAdded = (pagePartUuid: string, questionUuid: string) => {
        this.addNewPagePart({
            parentUuid: pagePartUuid,
            questionUuid,
        });
    };

    private addNewPagePart = (
        partialPagePart: Partial<PagePart> & Pick<PagePart, 'parentUuid'> & Pick<PagePart, 'questionUuid'>
    ) => {
        if (this.pagePartsSet) {
            const siblings = partialPagePart.parentUuid
                ? this.pagePartsSet.getChildrenForUuid(partialPagePart.parentUuid)
                : this.pagePartsSet.rootItems;
            const rank = Math.max(...(siblings ?? []).map((item) => item.rank), 0) + 1;
            const question = partialPagePart.questionUuid
                ? this.questionSet.findQuestionByUuid(partialPagePart.questionUuid) ?? null
                : null;

            const newPagePart: PagePart = {
                pagePartConfigurationId: this.pagePartsSet.id ?? -1,
                uuid: uuid(),
                rank: rank,
                name: question?.contents ?? '',
                icon: null,
                explanation: null,
                isIndexPage: false,
                ...partialPagePart,
            };
            this.pagePartsSetInteractor.addPagePart(this.pagePartsSet.id, newPagePart);
        }
    };
}
