import {action, computed, makeObservable, observable} from 'mobx';

import React from 'react';
import {Presenter} from '../../../../../../../../../support/presenter/presenter';
import {ActiveSetDefinition} from '../reference_objects_question_presenter';
import {DistanceProvider} from '../../../../../../../../business/distance_provider';

export interface ReferenceSubscriptionSettings {
    logoClassName?: string;
    hasPreferencesModal?: boolean;
}

export class ReferenceFiltersPresenter implements Presenter {
    @observable.ref private activeSet: ActiveSetDefinition;

    constructor(activeSet: ActiveSetDefinition, private distanceProvider: DistanceProvider) {
        this.activeSet = activeSet;

        makeObservable(this);
    }

    public mount(): void {
        //Noop
    }

    public unmount(): void {
        //Noop
    }

    @action
    public setActiveSet = (activeSet: ActiveSetDefinition) => {
        this.activeSet = activeSet;
    };

    @computed
    public get maxDistanceToAddress() {
        const maximum =
            this.activeSet.referenceObjectSetData?.referenceSales.reduce((max, sale) => {
                const distance = this.distanceProvider.getDistanceToAppraisal(sale);
                if (distance === null) {
                    return max;
                }
                return Math.max(max, distance);
            }, -Infinity) ?? -Infinity;

        if (maximum === -Infinity) {
            return 20000;
        }

        return Math.ceil(maximum / 1000) * 1000;
    }

    @computed
    public get minSurfaceArea() {
        const minimum =
            this.activeSet.referenceObjectSetData?.referenceSales.reduce(
                (min, sale) => (sale.floorArea !== null ? Math.min(min, sale.floorArea) : min),
                Infinity
            ) ?? Infinity;

        if (minimum === Infinity) {
            return 0;
        }

        return Math.floor(minimum / 10) * 10;
    }

    @computed
    public get maxSurfaceArea() {
        const maximum =
            this.activeSet.referenceObjectSetData?.referenceSales.reduce(
                (max, sale) => (sale.floorArea !== null ? Math.max(max, sale.floorArea) : max),
                -Infinity
            ) ?? -Infinity;

        if (maximum === -Infinity) {
            return 100;
        }

        return Math.ceil(maximum / 10) * 10;
    }

    @computed
    public get minPlotArea() {
        const minimum =
            this.activeSet.referenceObjectSetData?.referenceSales.reduce(
                (min, sale) => (sale.plotArea !== null ? Math.min(min, sale.plotArea) : min),
                Infinity
            ) ?? Infinity;

        if (minimum === Infinity) {
            return 0;
        }

        return Math.floor(minimum / 10) * 10;
    }

    @computed
    public get maxPlotArea() {
        const maximum =
            this.activeSet.referenceObjectSetData?.referenceSales.reduce(
                (max, sale) => (sale.plotArea !== null ? Math.max(max, sale.plotArea) : max),
                -Infinity
            ) ?? -Infinity;

        if (maximum === -Infinity) {
            return 500;
        }

        return Math.ceil(maximum / 10) * 10;
    }

    @computed
    public get minBuildYear() {
        const minimum =
            this.activeSet.referenceObjectSetData?.referenceSales.reduce(
                (min, sale) => (sale.buildYear !== null ? Math.min(min, sale.buildYear) : min),
                Infinity
            ) ?? Infinity;

        if (minimum === Infinity) {
            return 1950;
        }

        return Math.floor(minimum / 5) * 5;
    }

    @computed
    public get maxBuildYear() {
        const maximum =
            this.activeSet.referenceObjectSetData?.referenceSales.reduce(
                (max, sale) => (sale.buildYear !== null ? Math.max(max, sale.buildYear) : max),
                -Infinity
            ) ?? -Infinity;

        const currentYear = new Date().getFullYear();
        if (maximum === -Infinity) {
            return currentYear;
        }

        return Math.min(Math.ceil(maximum / 5) * 5, currentYear);
    }

    @computed
    public get buildYearMarks() {
        const marks: {[key: number]: React.ReactNode | string} = {};

        for (let i = this.minBuildYear; i < this.maxBuildYear; i += 5) {
            if (i === this.minBuildYear) {
                marks[i] = this.minBuildYear;
            } else {
                marks[i] = <></>;
            }
        }

        marks[this.maxBuildYear] = this.maxBuildYear;

        return marks;
    }
}
