import {ApiPagePart, apiPagePartToPagePart, pagePartToApiPagePart} from './api_page_part';
import {PagePartConfiguration, PagePartConfigurationType} from '../../models/page_part_configuration';
import {
    ApiPagePartCustomization,
    apiPagePartCustomizationToPagePartCustomization,
    pagePartCustomizationToApiPagePartCustomization,
} from './api_page_part_customization';

export interface ApiPagePartConfiguration {
    id: number;
    name: string;
    description: string | null;
    user_name: string | null;
    is_owned: boolean;
    is_shared: boolean;
    is_visible: boolean;
    is_editable: boolean;
    rank: number | null;
    type: PagePartConfigurationType;
    page_parts: ApiPagePart[];
    customizations: ApiPagePartCustomization[];
}

export function apiPagePartConfigurationsToPagePartConfigurations(
    apiPagePartConfigurations: ApiPagePartConfiguration[]
): PagePartConfiguration[] {
    return apiPagePartConfigurations.map((c) => apiPagePartConfigurationToPagePartConfiguration(c));
}
export function apiPagePartConfigurationToPagePartConfiguration(
    apiPagePartConfiguration: ApiPagePartConfiguration
): PagePartConfiguration {
    return {
        id: apiPagePartConfiguration.id,
        name: apiPagePartConfiguration.name,
        description: apiPagePartConfiguration.description,
        userName: apiPagePartConfiguration.user_name,
        rank: apiPagePartConfiguration.rank ?? 0,
        isOwned: apiPagePartConfiguration.is_owned,
        isShared: apiPagePartConfiguration.is_shared,
        isVisible: apiPagePartConfiguration.is_visible,
        isEditable: apiPagePartConfiguration.is_editable,
        type: apiPagePartConfiguration.type,
        pageParts: apiPagePartConfiguration.page_parts.map((apiPagePart) => apiPagePartToPagePart(apiPagePart)),
        customizations: apiPagePartConfiguration.customizations.map((customization) =>
            apiPagePartCustomizationToPagePartCustomization(customization)
        ),
    };
}

export function pagePartConfigurationToApiPagePartConfiguration(
    pagePartConfiguration: Omit<PagePartConfiguration, 'id'> & {id?: number}
): Omit<ApiPagePartConfiguration, 'id'> & {id?: number} {
    return {
        id: pagePartConfiguration.id,
        name: pagePartConfiguration.name,
        description: pagePartConfiguration.description,
        user_name: pagePartConfiguration.userName,
        rank: pagePartConfiguration.rank,
        is_owned: pagePartConfiguration.isOwned,
        is_shared: pagePartConfiguration.isShared,
        is_visible: pagePartConfiguration.isVisible,
        is_editable: pagePartConfiguration.isEditable,
        type: pagePartConfiguration.type,
        page_parts: pagePartConfiguration.pageParts.map((pagePart) => pagePartToApiPagePart(pagePart)),
        customizations: pagePartConfiguration.customizations.map((customization) =>
            pagePartCustomizationToApiPagePartCustomization(customization)
        ),
    };
}
