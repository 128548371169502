import * as React from 'react';

import {Appraisal} from '../../../../../../../../models/appraisal';
import {AreaConclusion} from '../../../../../../../../models/area_conclusion';
import {BuildyearBadge} from '../badges/buildyear_badge_adapter';
import {FloorAreaBadgeAdapter} from '../badges/floor_area_badge_adapter';
import {FloorAreaPrice} from '../components/floor_area_price';
import {Loader} from '../../../../../../components/loader';
import {Modal} from '../../../../components/modal';
import {PlotAreaBadgeAdapter} from '../badges/plot_area_badge_adapter';
import {PriceRange} from '../components/price_range';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {ReferenceObjectDetailsModalPresenter} from './reference_object_details_modal_presenter';
import {ReferenceSale} from '../models/reference_sale';
import {observer} from 'mobx-react';
import {transformRecord} from '../../../../../../../../models/validation_institute_reference_object';
import {usePresenter} from '../../../../../../../../../support/presenter/use_presenter';
import {ImageViewer} from '../../../../../../../../components/image_viewer/image_viewer';

interface OwnProps {
    appraisal: Appraisal;
    areaConclusion: AreaConclusion | null;
    questionSet: QuestionSet;
    referenceObject: ReferenceSale;
    referenceObjectAnswer: ReferenceObjectAnswer | null;
    valuation: string | null;
    visible: boolean;
    onHide: () => void;
    onAdd: (referenceSale: ReferenceSale, referenceObjectAnswer: ReferenceObjectAnswer) => void;
}

export const ReferenceObjectDetailsModal: React.FC<OwnProps> = observer(function ReferenceObjectDetailsModal(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectDetailsModalPresenter(
                props.referenceObject,
                props.onAdd,
                container.business.referenceObjectProvider(props.appraisal, props.questionSet)
            )
    );

    const referenceObject = props.referenceObject;
    const overview = transformRecord(referenceObject.overview);
    const installationDates = transformRecord(referenceObject.installationDates);

    return (
        <Modal key={referenceObject.id} onHide={() => props.onHide()} visible={props.visible}>
            <div className="modal-header">
                <h2>
                    {referenceObject.street} {referenceObject.houseNumber},{referenceObject.city}
                </h2>
            </div>
            <div className="modal-body">
                <div className="row">
                    <div className="col-sm-6">
                        <h2>Object details</h2>
                        <dl>
                            <dt>Adres</dt>
                            <dl>
                                {referenceObject.street} {referenceObject.houseNumber}
                                {referenceObject.letter}
                                <br />
                                {referenceObject.postalCode} {referenceObject.city}
                            </dl>
                        </dl>
                        <dl>
                            <dt>Bouwjaar</dt>
                            <dl>
                                {referenceObject.buildYear === null ? 'Onbekend' : referenceObject.buildYear}
                                {referenceObject.buildYear !== null ? (
                                    <BuildyearBadge
                                        buildYear={referenceObject.buildYear}
                                        appraisal={props.appraisal}
                                        questionSet={props.questionSet}
                                    />
                                ) : null}
                            </dl>
                        </dl>
                        <dl>
                            <dt>Gebruiksoppervlakte wonen</dt>
                            <dl>
                                {referenceObject.floorArea === null ? 'Onbekend' : referenceObject.floorArea + 'm²'}
                                {props.areaConclusion !== null && referenceObject.floorArea !== null ? (
                                    <FloorAreaBadgeAdapter
                                        floorArea={referenceObject.floorArea}
                                        areaConclusion={props.areaConclusion}
                                        appraisal={props.appraisal}
                                        questionSet={props.questionSet}
                                    />
                                ) : null}
                            </dl>
                        </dl>
                        <dl>
                            <dt>Perceeloppervlakte</dt>
                            <dl>
                                {referenceObject.plotArea === null ? 'Onbekend' : referenceObject.plotArea + 'm²'}
                                {props.areaConclusion !== null && referenceObject.plotArea !== null ? (
                                    <PlotAreaBadgeAdapter
                                        appraisal={props.appraisal}
                                        areaConclusion={props.areaConclusion}
                                        plotArea={referenceObject.plotArea}
                                        questionSet={props.questionSet}
                                    />
                                ) : null}
                            </dl>
                        </dl>
                    </div>
                    <div className="col-sm-6">
                        <div className="detail-image">
                            <ImageViewer
                                images={[
                                    '/reference-objects/address/' +
                                        referenceObject.street +
                                        '/' +
                                        referenceObject.houseNumber +
                                        '/' +
                                        referenceObject.city +
                                        '/streetview',
                                    ...referenceObject.images
                                        .map((pair) => pair.url)
                                        .filter((url): url is string => url !== null),
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <dl>
                            <dt>Prijs per m²</dt>
                            <dl>
                                <FloorAreaPrice
                                    valuation={props.valuation}
                                    floorArea={referenceObject.floorArea}
                                    price={referenceObject.priceRange}
                                    areaConclusion={props.areaConclusion}
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                />
                            </dl>
                        </dl>
                    </div>
                    <div className="col-6">
                        <h2>Verkoop details</h2>
                        <dl>
                            <dt>Prijsklasse</dt>
                            <dl>
                                <PriceRange range={referenceObject.priceRange} />
                            </dl>
                        </dl>
                        <dl>
                            <dt>Datum afmelding/verkocht op</dt>
                            <dl>{referenceObject.saleQuarter === null ? 'Onbekend' : referenceObject.saleQuarter}</dl>
                        </dl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        {referenceObject.highlights.length > 0 ? (
                            <div>
                                <h2>Highlights</h2>
                                <ul>
                                    {referenceObject.highlights.map((highlight, index) => (
                                        <li key={highlight + index}>{highlight}</li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="col-6">
                        {overview.length > 0 && (
                            <div>
                                <h2>Overzicht</h2>
                                <ul>
                                    {overview.map((value, index) => (
                                        <li key={value + '-' + index}>{value}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {installationDates.length > 0 && (
                            <div>
                                <h2>Installatie datums</h2>
                                <ul>
                                    {installationDates.map((value, index) => (
                                        <li key={value + '-' + index}>{value}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary ion-md-close" type="button" onClick={() => props.onHide()}>
                    Sluiten
                </button>
                <button className="btn btn-primary ion-md-close" type="button" onClick={() => presenter.onAdd()}>
                    {presenter.isAdding ? (
                        <span>
                            <Loader />
                            Ophalen...
                        </span>
                    ) : (
                        <span>Toevoegen</span>
                    )}
                </button>
            </div>
        </Modal>
    );
});
