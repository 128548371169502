import {ValidationInstituteReferentieObject} from '../../../../../../../../models/validation_institute_reference_object';
import {ReferenceObject} from '../models/reference_object';
import {isEmpty} from '../../../../../../../../../support/util';
import {SetType} from '../../../../../../../../models/reference_set/set_type';

export function getObjectPrice(setType: SetType, object: ValidationInstituteReferentieObject | null): number | null {
    if (object === null) {
        return null;
    }

    if (setType === SetType.RENT) {
        return !isEmpty(object.gecorrigeerdeHuurprijsPerMaand)
            ? object.gecorrigeerdeHuurprijsPerMaand
            : object.aanvangsHuurprijsPerMaand ?? null;
    }
    return !isEmpty(object.gecorrigeerdeVerkoopprijs) ? object.gecorrigeerdeVerkoopprijs : object.verkoopprijs ?? null;
}

export function getReferenceObjectPrice(setType: SetType, object: ReferenceObject | null): string | null {
    if (object === null) {
        return null;
    }

    if (setType === SetType.RENT) {
        return null;
    }
    return !isEmpty(object.indexedPriceRange) ? object.indexedPriceRange : object.priceRange ?? null;
}
