import {combineLatest, Observable, of} from 'rxjs';

import {AnswerController} from './answering/answer_controller';
import {QuestionEffectInteractor} from './conditions/question_effects_interactor';
import {QuestionSet} from '../models/question_set';
import {TechnicalReference} from '../enum/technical_reference';
import {map} from 'rxjs/operators';
import {normalizeNumber} from './support/normalize_number';
import {SurfaceAreaProvider} from './support/surface_area_provider';

export interface VolumeProvider {
    stream(): Observable<number | null>;
}

export class DefaultVolumeProvider implements VolumeProvider {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private questionEffectInteractor: QuestionEffectInteractor,
        private surfaceAreaProvider: SurfaceAreaProvider
    ) {}

    public stream(): Observable<number | null> {
        const volumeQuestions = this.questionSet.findQuestionsByTechnicalReference(TechnicalReference.OBJECT_VOLUME);
        if (volumeQuestions.length === 0) {
            return of(null);
        }

        return combineLatest(
            this.answerController.answersForQuestionUuidsStream(volumeQuestions.map((vq) => vq.uuid)).pipe(
                //Filter deleted and hidden answers
                map((answers) => this.answerController.filterDeleted(answers)),
                map((answers) =>
                    answers.filter(
                        (answer) => !this.questionEffectInteractor.isHidden(answer.questionUuid, answer.uuid)
                    )
                ),
                map((answers) => {
                    let volume = 0;

                    for (const answer of answers) {
                        if (answer.contents) {
                            const value = parseFloat(answer.contents);
                            if (!Number.isNaN(value) && typeof value === 'number') {
                                volume += value;
                            }
                        }
                    }

                    if (!(volume > 0)) {
                        return null;
                    }

                    return normalizeNumber(volume, 0);
                })
            ),
            this.surfaceAreaProvider.surfaceArea()
        ).pipe(
            map(([volume, surfaceArea]) => {
                if (volume === null || surfaceArea === null) {
                    return null;
                }

                const expectedVolume = surfaceArea * 3.35;

                // Check for deviation of more than 30%
                if (volume < expectedVolume * 0.7 || volume > expectedVolume * 1.3) {
                    return null;
                }

                return volume;
            })
        );
    }
}
