import * as React from 'react';

import {observer} from 'mobx-react';
import {PhotoRequirementsChecklistPresenter} from './photo_requirements_checklist_presenter';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {QuestionSet} from '../../../../models/question_set';
import {Appraisal} from '../../../../models/appraisal';
import {classNames} from '../../../../../support/classnames';
import {Modal} from '../questions/components/modal';
import {translateRequiredImageTechnicalReference} from '../../../../enum/technical_reference';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export const PhotoRequirementsChecklist: React.FC<OwnProps> = observer(function PhotoRequirementsChecklist(props) {
    const presenter = usePresenter(
        (container) =>
            new PhotoRequirementsChecklistPresenter(
                props.questionSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.userSettingsInteractor(props.appraisal, props.questionSet),
                container.business.questionEffectsInteractor(props.appraisal, props.questionSet)
            )
    );

    const {missingPhotoLabels, progress} = presenter;
    if (missingPhotoLabels === null) {
        return null;
    }

    const radius = 26;
    const circumference = radius * 2 * Math.PI;
    const offset =
        progress && progress.total !== progress.selected
            ? circumference - (progress.selected / progress.total) * circumference
            : null;
    const style = offset
        ? {
              strokeDasharray: `${circumference} ${circumference}`,
              strokeDashoffset: offset,
          }
        : null;

    return (
        <>
            <Modal visible={presenter.isChecklistOpen} onHide={() => presenter.setIsChecklistOpen(false)}>
                <div className="modal-header">
                    <h2>Foto&apos;s checklist</h2>
                </div>
                <div className="modal-body">
                    <div className="photo-requirements-checklist-list">
                        {Array.from(missingPhotoLabels.entries()).map(([a, b]) => {
                            return (
                                <div key={a} className="checklist-row">
                                    <strong className="btn-circle photo-amount">
                                        {b.amountCurrently}/{b.amountRequired}
                                    </strong>
                                    <div className="photo-name">{translateRequiredImageTechnicalReference(a)}</div>
                                    <div className="photo-checklist">
                                        <input
                                            id={`${a}-checkbox`}
                                            name={`${a}-checkbox`}
                                            type="checkbox"
                                            checked={b.isCompleted}
                                            onChange={(e) => presenter.toggleOverride(a, e.target.checked)}
                                        />
                                        <label htmlFor={`${a}-checkbox`}></label>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="btn btn-secondary ion-md-close"
                        type="button"
                        onClick={() => presenter.setIsChecklistOpen(false)}
                    >
                        Sluiten
                    </button>
                </div>
            </Modal>

            <div
                className={classNames('btn-photo-requirement-checklist', {
                    disabled: false,
                })}
                data-tooltip="btn-photo-requirement-checklist"
                data-tooltip-title="Checklist voor verplichte fotos"
                data-tooltip-description="Geef een checklist weer met een lijst van missende fotos."
                data-tooltip-position="right-bottom"
            >
                <label onClick={() => presenter.setIsChecklistOpen(!presenter.isChecklistOpen)}>
                    <svg
                        fill="currentColor"
                        preserveAspectRatio="xMidYMid meet"
                        height="1em"
                        width="1em"
                        viewBox="0 0 32 32"
                        style={{verticalAlign: 'middle'}}
                    >
                        <g fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1.8">
                            <path d="m15 16h13" />
                            <path d="m5 16 2 2 4-4" />
                            <path d="m15 8h13" />
                            <path d="m5 8 2 2 4-4" />
                            <path d="m15 24h13" />
                            <path d="m5 24 2 2 4-4" />
                        </g>
                    </svg>
                    {style && (
                        <svg
                            className={classNames('button-circle-loader circle')}
                            viewBox="0 0 60 60"
                            width="60"
                            height="60"
                        >
                            <circle r="26" cx="30" cy="30" style={{stroke: 'black', opacity: 0.2}} />
                            <circle r="26" cx="30" cy="30" style={style} />
                        </svg>
                    )}
                </label>
            </div>
        </>
    );
});
