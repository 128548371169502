import * as React from 'react';
import {createRoot} from 'react-dom/client';

import {Root} from '../root';
import {Widget} from './widget';

export function renderPrecheckAppraisalMatchStatisticsEnvironmentInto(element: HTMLElement) {
    const appraisalId = element.getAttribute('data-appraisal-id');
    if (appraisalId === null) {
        throw new Error('Trying to render pre-check environment without appraisal ID');
    }
    createRoot(element).render(
        <Root appraisalId={appraisalId ? parseInt(appraisalId, 10) : null}>
            <Widget />
        </Root>
    );
}
