import {makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {PhotoAnswerRetryInteractor, UnscynedState} from '../../../../business/photo_answer_retry_interactor';
import {Presenter} from '../../../../../support/presenter/presenter';

export class UploadingImagesListPresenter implements Presenter {
    @observable.ref
    public unsyncedState: UnscynedState | null = null;

    private subscriptions = new CompositeSubscription();

    constructor(private photoAnswerRetryInteractor: PhotoAnswerRetryInteractor) {
        makeObservable(this);
    }

    public mount() {
        this.subscriptions.add(
            this.photoAnswerRetryInteractor.unscynedStateStream(250).subscribe((unsyncedState) => {
                runInAction(() => {
                    this.unsyncedState = unsyncedState;
                });
            })
        );
    }

    public unmount() {
        this.subscriptions.clear();
    }
}
