import {ImageUrlPair} from '../../../../../../../../models/image_url_pair';
import {ReferenceSaleImageSource} from '../../../../../../../../network/models/api_reference_sale_image';

export interface ReferenceSaleImage {
    url: string | null;
    key: string | null;
    source: ReferenceSaleImageSource | null;
    date: Date | null;
    label: string | null;
    confidence: number | null;
}

export function referenceSaleImagesToImageUrlPairs(referenceSaleImages: ReferenceSaleImage[]): ImageUrlPair[] {
    return referenceSaleImages
        .map((referenceSaleImage) => referenceSaleImageToUrlPair(referenceSaleImage))
        .filter((imageUrlPair): imageUrlPair is ImageUrlPair => imageUrlPair !== null);
}

export function referenceSaleImageToUrlPair(referenceSaleImage: ReferenceSaleImage): ImageUrlPair | null {
    if (referenceSaleImage.url === null) {
        return null;
    }

    return {
        path: referenceSaleImage.key,
        url: referenceSaleImage.url,
    };
}
