import * as React from 'react';

import {NormalQuestionType, QuestionType} from '../../../enum/question_type';

import {AnswerAuditTrailItem} from '../../../models/answer_audit_trail_item';
import {classNames} from '../../../../support/classnames';
import {format} from 'date-fns';

export interface OwnProps {
    auditTrailItems: AnswerAuditTrailItem[][];
    onHistoricAnswerClick: (items: AnswerAuditTrailItem[]) => void;
    isLoading: boolean;
    isDisabled: boolean;
    questionType: QuestionType;
    getAnswerContents: (item: AnswerAuditTrailItem) => string | null;
}

export class AnswerTrailList extends React.Component<OwnProps> {
    private renderAnswerContents(index: number, items: AnswerAuditTrailItem[]) {
        if (this.props.questionType === NormalQuestionType.MULTIPLE_BOOLEAN_GROUP) {
            if (items.length === 0) {
                return <span className="content placeholder" />;
            }
            return (
                <span className="content">
                    {items
                        .filter((i) => i.contents === '1')
                        .map((i) => (
                            <span key={`${index}-${i.uuid}`} className="badge badge-pill badge-secondary">
                                {this.props.getAnswerContents(i)}
                            </span>
                        ))}
                </span>
            );
        }

        const contents = this.props.getAnswerContents(items[0]);
        if (!contents) {
            return <span className="content placeholder" />;
        }

        if (this.props.questionType === NormalQuestionType.BUILDING_COSTS_PHOTO) {
            return <img src={contents}></img>;
        }

        return contents;
    }

    public render() {
        return (
            <ol className="history-list">
                {this.props.auditTrailItems.map((items, index) => {
                    //Last mutated item in this trail
                    const item = items[items.length - 1];
                    return (
                        <li
                            className={classNames({
                                active: index === 0,
                                disabled: this.props.isLoading === true || this.props.isDisabled === true,
                            })}
                            key={`${index}-${item.questionUuid}-${item.updatedAt}`}
                            onClick={() => this.props.onHistoricAnswerClick(items)}
                        >
                            <span className="author">
                                {item.isAutomated
                                    ? item.filledByAutomator !== null
                                        ? `Geautomatiseerd door: "${item.filledByAutomator}"`
                                        : 'Geautomatiseerd antwoord'
                                    : item.userDisplayName}
                            </span>
                            {this.renderAnswerContents(index, items)}
                            <span className="date">{format(new Date(item.updatedAt), 'dd-MM-yyyy HH:mm')}</span>
                        </li>
                    );
                })}
            </ol>
        );
    }
}
