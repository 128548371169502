import * as React from 'react';

import {DecimalTextInput} from '../../../content/questions/components/decimal_text_input';
import {ValidationInstituteConstructionalDefect} from '../../../../../models/validation_institute_constructional_defect';

interface OwnProps {
    onChange: (value: string) => void;
    onRefresh: () => void;
    value: string;
    label: string;
    isDisabled: boolean;
    validationInstituteConstructionalDefect: ValidationInstituteConstructionalDefect | null;
}

export class CostsInput extends React.Component<OwnProps> {
    public render() {
        return (
            <div className="form-group">
                <label>{this.props.label}</label>
                <div className="input-group append-btn">
                    <div className="input-group-prepend">
                        <div className="input-group-text ion-logo-euro">&nbsp;</div>
                    </div>
                    <DecimalTextInput
                        name={this.props.label.toLowerCase()}
                        className="form-control"
                        isDisabled={this.props.isDisabled}
                        value={String(this.props.value ?? 0)}
                        allowNegatives
                        onChange={(value) => this.props.onChange(value)}
                    />
                    <div className="input-group-append">
                        <button
                            className="btn btn-append"
                            disabled={this.props.isDisabled}
                            onClick={() => this.props.onRefresh()}
                        >
                            <span className="ion-md-refresh"></span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
