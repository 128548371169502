import {Observable, of} from 'rxjs';

import {Answer} from '../models/answer';
import {AnswerController} from './answering/answer_controller';
import {AnswerFilledByAutomator} from '../enum/answer_filled_by_automator';
import {AnswerOption} from '../models/answer_option';
import {AnswerTouchState} from '../enum/answer_touch_state';
import {Appraisal} from '../models/appraisal';
import {NormalQuestionType} from '../enum/question_type';
import {Question} from '../models/question';
import {QuestionSet} from '../models/question_set';
import {TechnicalReference} from '../enum/technical_reference';
import {getNewestAnswer} from '../../support/get_newest_answer';
import {map} from 'rxjs/operators';

export interface BuildingReportRequiredPair {
    isRequired: boolean;
    answer: Answer | null;
}

export interface BuildingInspectionAppointmentInteractor {
    meetsRequiredConditionsStream(): Observable<boolean>;
    buildingReportRequiredStream(): Observable<BuildingReportRequiredPair>;
    updateBuildingReportRequiredQuestion(answer: Answer): void;
}

export class DefaultBuildingInspectionAppointmentInteractor implements BuildingInspectionAppointmentInteractor {
    private buildingReportRequiredQuestion: Question | null = this.questionSet.findQuestionByTechnicalReference(
        TechnicalReference.BUILDING_INSPECTION_REQUIRED
    );
    private buildingCostConditionQuestions: Question[] = this.questionSet.findQuestionsByType(
        NormalQuestionType.BUILDING_COSTS_CONDITION
    );

    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private answerController: AnswerController
    ) {}

    public meetsRequiredConditionsStream(): Observable<boolean> {
        return this.answerController
            .answersForQuestionUuidsStream(this.buildingCostConditionQuestions.map((question) => question.uuid))
            .pipe(
                map((answers) => {
                    const mediocreBadCount = answers.filter((answer) => {
                        return answer.contents && ['matig', 'slecht'].includes(answer.contents);
                    });
                    const furtherInvestigationCount = answers.filter((answer) => {
                        return answer.contents === 'nader onderzoek nodig';
                    });
                    return (
                        mediocreBadCount.length > 2 ||
                        furtherInvestigationCount.length > 2 ||
                        mediocreBadCount.length + furtherInvestigationCount.length >= 3
                    );
                })
            );
    }

    public buildingReportRequiredStream(): Observable<BuildingReportRequiredPair> {
        const answerOption = this.findBuildingReportRequiredQuestionAnswerOption('Ja');
        if (this.buildingReportRequiredQuestion && answerOption) {
            return this.answerController.answersForQuestionUuidStream(this.buildingReportRequiredQuestion.uuid).pipe(
                map((answers) => {
                    const answer = getNewestAnswer(answers);
                    return {
                        isRequired: answer !== null && answer.answerOptionId === answerOption.id,
                        answer: answer,
                    };
                })
            );
        }

        return of({
            isRequired: false,
            answer: null,
        });
    }

    public updateBuildingReportRequiredQuestion(answer: Answer): void {
        const answerOption = this.findBuildingReportRequiredQuestionAnswerOption('Ja');
        if (answerOption && answer.answerOptionId !== answerOption.id && answer.filledByAutomator !== null) {
            this.answerController.onAnswerOptionChange(
                answer.uuid,
                answerOption.id,
                AnswerTouchState.UNTOUCHED,
                AnswerFilledByAutomator.TAXAPI_AI
            );
        }
    }

    private findBuildingReportRequiredQuestionAnswerOption(value: string): AnswerOption | null {
        if (this.buildingReportRequiredQuestion) {
            return (
                this.buildingReportRequiredQuestion.answerOptions.find(
                    (a) => a.contents.toLowerCase() === value.toLowerCase()
                ) ?? null
            );
        }
        return null;
    }
}
