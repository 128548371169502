import * as React from 'react';

import {Appraisal} from '../../../models/appraisal';
import {DefaultChildQuestionsProvider} from '../../../business/child_questions_provider';
import {DefaultPathNameProvider} from '../../../business/path_name_provider';
import {History} from 'history';
import {PagePartsSet} from '../../../models/page_parts_set';
import {QuestionSet} from '../../../models/question_set';
import {SurfaceAreaCounterPresenter} from './surface_area_counter_presenter';
import {ValidationError} from '../../../models/validation_error';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../support/presenter/use_presenter';

interface OwnProps {
    history: History;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet | null;
    validationErrors: ValidationError[];
}

export const SurfaceAreaCounter: React.FC<OwnProps> = observer(function SurfaceAreaCounter(props) {
    const presenter = usePresenter(
        (container) =>
            new SurfaceAreaCounterPresenter(
                props.questionSet,
                new DefaultChildQuestionsProvider(
                    props.questionSet,
                    container.business.sidebarTreeBuilder(props.appraisal, props.questionSet, props.pagePartsSet),
                    new DefaultPathNameProvider(props.history)
                ),
                container.business.support.surfaceAreaProvider(props.questionSet, props.appraisal)
            )
    );

    if (presenter.surfaceArea === null || !presenter.visible) {
        return null;
    }
    return (
        <div className="floater floater-information">
            Gebruiksoppervlakte (M²): <strong>{presenter.surfaceArea}</strong>
        </div>
    );
});
