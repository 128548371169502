import {map} from 'rxjs/operators';
import {Presenter} from '../../../../../support/presenter/presenter';
import {SidebarItem, SidebarTreeBuilder} from '../../../../business/sidebar_tree_builder';
import {PagePartConfigurationType} from '../../../../models/page_part_configuration';
import {makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {PagePartsSetInteractor} from '../../../../business/page_parts/page_parts_set_interactor';

export class StaticButtonsPresenter implements Presenter {
    @observable.ref
    public staticItems: SidebarItem[] = [];

    private subscriptions = new CompositeSubscription();

    constructor(
        private pagePartsSetInteractor: PagePartsSetInteractor,
        private sidebarTreeBuilder: SidebarTreeBuilder
    ) {
        makeObservable(this);
    }

    public async mount() {
        this.subscriptions.add(
            this.pagePartsSetInteractor
                .pagePartsSetsStream()
                .pipe(
                    map((pagePartsSets) => {
                        const completeConfig = pagePartsSets.find(
                            (pps) =>
                                pps.config.isVisible === true &&
                                pps.config.type === PagePartConfigurationType.SYSTEM_COMPLETE
                        );
                        if (completeConfig) {
                            return this.sidebarTreeBuilder.buildStaticItems(completeConfig);
                        }
                        return null;
                    })
                )
                .subscribe((staticItems) => {
                    if (staticItems) {
                        runInAction(() => {
                            this.staticItems = staticItems;
                        });
                    }
                })
        );
    }

    public async unmount() {
        this.subscriptions.clear();
    }
}
