import * as React from 'react';

import {Appraisal} from '../../../../../../models/appraisal';
import {Loader} from '../../../../components/loader';
import {TaskHelper} from '../../../../../../business/task_helper';
import {ReportIframeViewerPresenter} from './report_iframe_viewer_presenter';
import {QuestionSet} from '../../../../../../models/question_set';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {observer} from 'mobx-react';
import {GeneratedReportType} from '../../../../../../enum/generated_report';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    reportType: GeneratedReportType;
}

export const ReportIframeViewer: React.FunctionComponent<OwnProps> = observer((props) => {
    const presenter = usePresenter(
        (container) =>
            new ReportIframeViewerPresenter(
                props.reportType,
                container.business.answerInteractor(props.appraisal, props.questionSet),
                props.appraisal,
                container.network.appraisalApi,
                new TaskHelper(container.network.ajaxDriver)
            )
    );

    if (presenter.pdfUrl) {
        return (
            <div className="row">
                <div className="col-12 d-flex justify-content-center mt-5">
                    <a className="btn btn-sm btn-primary float-right ion-md-cloud-download" href={presenter.pdfUrl}>
                        Download .pdf
                    </a>
                </div>
            </div>
        );
    }

    return presenter.html !== null ? (
        <div>
            <div className="d-flex justify-content-end mb-3">
                <button type="button" className="ion-md-refresh mr-2 btn btn-sm" onClick={() => presenter.getHtml()}>
                    Vernieuwen
                </button>
                <button
                    type="button"
                    className="ion-md-cloud-download btn btn-sm"
                    onClick={() => presenter.downloadPdf()}
                >
                    Download
                </button>
            </div>
            <div className="row">
                <iframe
                    className="embed-responsive-item"
                    srcDoc={presenter.html}
                    frameBorder={0}
                    width="100%"
                    height="810px"
                />
            </div>
        </div>
    ) : (
        <div className="row">
            <div className="col-12 text-center">
                <Loader icon="c-icon c-icon-building-costs-sheet" addSpace />
                <h3>{presenter.loadingFile ? 'PDF-bestand aan het genereren...' : 'Bijlage aan het genereren...'}</h3>
            </div>
        </div>
    );
});
