import {SortOrder, sortByPredicate} from './sort_by_predicate';

import {Answer} from '../appraising/models/answer';
import {QuestionAnswerPair} from './question_answer_tree';
import {TreeItem} from './generic_tree';

export function sortTreeItems(answerSorter: (a: Answer, b: Answer) => number) {
    return (a: TreeItem<QuestionAnswerPair>, b: TreeItem<QuestionAnswerPair>) => {
        if (a.item.answer && b.item.answer) {
            return answerSorter(a.item.answer, b.item.answer);
        }
        if (a.item.answer) {
            return 1;
        }
        if (b.item.answer) {
            return -1;
        }
        return 0;
    };
}

export function sortByUpdatedAt(a: Answer, b: Answer, order = SortOrder.ASC) {
    return sortByPredicate(a, b, (v) => v.updatedAt, order);
}

export function sortByCreatedAt(a: Answer, b: Answer, order = SortOrder.ASC) {
    return sortByPredicate(a, b, (v) => v.createdAt, order);
}

export function sortByRank(a: Answer, b: Answer, order = SortOrder.ASC) {
    return sortByPredicate(a, b, (v) => v.rank, order);
}

export function sortAnswersByUpdatedAt(a: Answer, b: Answer, order = SortOrder.ASC) {
    if (a.updatedAt === null && b.updatedAt === null) {
        return 0;
    }
    if (a.updatedAt === null) {
        return order === SortOrder.ASC ? -1 : 1;
    }
    if (b.updatedAt === null) {
        return order === SortOrder.ASC ? 1 : -1;
    }
    if (a.updatedAt < b.updatedAt) {
        return order === SortOrder.ASC ? -1 : 1;
    }
    if (a.updatedAt > b.updatedAt) {
        return order === SortOrder.ASC ? 1 : -1;
    }
    return 0;
}

export function sortAnswersByCreatedAt(a: Answer | null, b: Answer | null, order = SortOrder.ASC) {
    if (a === null && b === null) {
        return 0;
    }
    if (a === null) {
        return order === SortOrder.ASC ? -1 : 1;
    }
    if (b === null) {
        return order === SortOrder.ASC ? 1 : -1;
    }
    if (a.createdAt === null && b.createdAt === null) {
        return 0;
    }
    if (a.createdAt === null) {
        return order === SortOrder.ASC ? -1 : 1;
    }
    if (b.createdAt === null) {
        return order === SortOrder.ASC ? 1 : -1;
    }
    if (a.createdAt < b.createdAt) {
        return order === SortOrder.ASC ? -1 : 1;
    }
    if (a.createdAt > b.createdAt) {
        return order === SortOrder.ASC ? 1 : -1;
    }
    return 0;
}
