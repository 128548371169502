import * as React from 'react';

import {ValidationMessage} from '../../../business/validation/validation_message';

interface OwnProps {
    validationMessages: ValidationMessage[];
}

interface GroupedValidationMessage {
    group: string;
    messages: string[];
    labels: string[];
}

export class ValidationMessagesList extends React.Component<OwnProps> {
    private grouped(validationMessages: ValidationMessage[]) {
        return validationMessages.reduce((p: GroupedValidationMessage[], c: ValidationMessage) => {
            if (
                c.question !== null &&
                !p.some((group) => c.question !== null && group.group === c.question.reportFieldReference) &&
                c.question.reportFieldReference !== null
            ) {
                p.push({
                    group: c.question.reportFieldReference,
                    messages: [],
                    labels: [],
                });
            }

            return p.map((group) => {
                if (c.question !== null && group.group === c.question.reportFieldReference) {
                    c.messages.forEach((message) => {
                        if (group.messages.indexOf(message) === -1) {
                            group.messages.push(message);
                        }
                    });
                    const label = !c.title ? c.question.contents : c.title;
                    if (group.labels.indexOf(label) === -1) {
                        group.labels.push(label);
                    }
                }

                return group;
            });
        }, []);
    }

    public render() {
        const maxLabelLength = 100;
        const grouped = this.grouped(this.props.validationMessages);

        return (
            <>
                {grouped
                    .filter((group) => group.messages.length > 0)
                    .map((group) => {
                        const label = group.labels.join('\\');
                        return (
                            <dl key={group.group} className="alert-inline alert-hint ion-md-alert">
                                <dt>
                                    {label.length > maxLabelLength
                                        ? label.substring(0, maxLabelLength - 3) + '...'
                                        : label}
                                </dt>
                                <dd>{group.messages.join('\n')}</dd>
                            </dl>
                        );
                    })}
            </>
        );
    }
}
