import {BuildYearProvider} from '../../../../../../../../../business/build_year_provider';
import {ReferenceObjectSorterByAlphabet} from './strategies/reference_object_sorter_by_alphabet';
import {ReferenceObjectSorterByDeviationScore} from './strategies/reference_object_sorter_by_match_percentage';
import {ReferenceObjectSorterByDistance} from './strategies/reference_object_sorter_by_distance';
import {ReferenceObjectSorterBySalesDate} from './strategies/reference_object_sorter_by_sales_date';
import {ReferenceObjectSortingStrategy} from './strategies/reference_object_sorting_strategy';
import {SortingMethod} from '../../../../../../../../../enum/reference_objects_sorting';
import {DistanceProvider} from '../../../../../../../../../business/distance_provider';

export class ReferenceObjectSortingStrategyProvider {
    constructor(private buildYearProvider: BuildYearProvider, private distanceProvider: DistanceProvider) {}

    public create(
        sortingMethod: SortingMethod,
        gebruiksoppervlakteWonen: number | null,
        perceelOppervlakte: number | null
    ): ReferenceObjectSortingStrategy {
        switch (sortingMethod) {
            case SortingMethod.ALPHABETICALLY:
                return new ReferenceObjectSorterByAlphabet();
            case SortingMethod.SALES_DATE:
                return new ReferenceObjectSorterBySalesDate();
            case SortingMethod.DISTANCE:
                return new ReferenceObjectSorterByDistance(this.distanceProvider);
            case SortingMethod.DEVIATION_SCORE:
                return new ReferenceObjectSorterByDeviationScore(
                    this.buildYearProvider,
                    gebruiksoppervlakteWonen,
                    perceelOppervlakte
                );
        }
    }
}
