import * as React from 'react';

import {Appraisal} from '../../../models/appraisal';
import {AppraisalDetailsWidget} from './appraisal_details_widget/appraisal_details_widget';
import {AttachmentsWidget} from './attachments_widget/attachments_widget';
import {AttachmentsWidgetType} from './attachments_widget/attachments_widget_presenter';
import {NotesWidget} from './notes_widget/notes_widget';
import {NwwiControlReportWidget} from './nwwi_control_report_widget/nwwi_control_report_widget';
import {PagePartsSet} from '../../../models/page_parts_set';
import {PhotosWidget} from './photos_widget/photos_widget';
import {ReferenceObjectsWidget} from './reference_objects_widget/reference_objects_widget';
import {QuestionSet} from '../../../models/question_set';
import {UserWidget} from '../../../enum/user_widget';
import {UserWidgetsModal} from './user_widgets_modal';
import {ValidationError} from '../../../models/validation_error';
import {WidgetsContainerPresenter} from './widgets_container_presenter';
import {observer} from 'mobx-react';
import {RenderingContextType} from '../../../enum/rendering_context_type';
import {usePresenter} from '../../../../support/presenter/use_presenter';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet | null;
    validationErrors: ValidationError[];
    renderingContext: RenderingContextType;
}

export const WidgetsContainer: React.FC<OwnProps> = observer(function WidgetsContainer(props) {
    const presenter = usePresenter(
        (container) => new WidgetsContainerPresenter(container.globalProvider, container.network.userWidgetsApi)
    );

    if (presenter.global.detailName) {
        return null;
    }

    return (
        <div className="appraise-widget-container">
            {presenter.isEditUserWidgetsModalOpen && (
                <UserWidgetsModal
                    onHide={presenter.toggleEditUserWidgetsModal}
                    initialUserWidgets={presenter.userWidgets}
                    onSave={(userWidgets) => presenter.onChangeUserWidgets(userWidgets)}
                    isSaving={presenter.saving}
                />
            )}

            <div className="row">
                {presenter.userWidgets.includes(UserWidget.APPRAISE_NWWI_CONTROL_REPORT) && (
                    <NwwiControlReportWidget
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        pagePartsSet={props.pagePartsSet}
                        validationErrors={props.validationErrors}
                        renderingContext={props.renderingContext}
                    />
                )}
                {presenter.userWidgets.includes(UserWidget.APPRAISE_OBJECT_DETAILS) && (
                    <AppraisalDetailsWidget
                        global={presenter.global}
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                    />
                )}
                {presenter.userWidgets.includes(UserWidget.APPRAISE_PHOTOS) && (
                    <PhotosWidget appraisal={props.appraisal} questionSet={props.questionSet} />
                )}
                {presenter.userWidgets.includes(UserWidget.APPRAISE_NOTES) && (
                    <NotesWidget
                        questionSet={props.questionSet}
                        disabled={presenter.saving}
                        appraisal={props.appraisal}
                    />
                )}
                {presenter.userWidgets.includes(UserWidget.APPRAISE_FILES) && (
                    <>
                        <AttachmentsWidget
                            appraisal={props.appraisal}
                            questionSet={props.questionSet}
                            key={AttachmentsWidgetType.ATTACHMENTS}
                        />
                    </>
                )}
                {presenter.userWidgets.includes(UserWidget.APPRAISE_REFERENCE_OBJECTS) && (
                    <ReferenceObjectsWidget appraisal={props.appraisal} questionSet={props.questionSet} />
                )}
                <div className="col-12 d-flex justify-content-end">
                    {presenter.userWidgets.length === 0 ? (
                        <button
                            className="btn btn-secondary btn-sm ion-md-add"
                            onClick={() => presenter.toggleEditUserWidgetsModal()}
                        >
                            Widgets toevoegen
                        </button>
                    ) : (
                        <button
                            className="btn btn-secondary btn-sm ion-md-create"
                            onClick={() => presenter.toggleEditUserWidgetsModal()}
                        >
                            Bewerk widgets
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
});
