import * as React from 'react';

import {Appraisal} from '../../../../../../../../../../../models/appraisal';
import {ExplanationAppraiser} from '../../../explanation_appraiser/explanation_appraiser';
import {FunctionComponent} from 'react';
import {QuestionSet} from '../../../../../../../../../../../models/question_set';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';

interface OwnProps {
    referenceObjectAnswer: ReferenceObjectAnswer;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    onObjectUsageExplanationChange: (objectUsageExplanation: string) => void;
    gebruiksoppervlakteWonen: number | null;
    perceelOppervlakte: number | null;
    brutoInhoud: number | null;
    isFrozen: boolean;
}

export const ObjectUsageExplanation: FunctionComponent<OwnProps> = ({
    referenceObjectAnswer,
    appraisal,
    questionSet,
    onObjectUsageExplanationChange,
    gebruiksoppervlakteWonen,
    perceelOppervlakte,
    brutoInhoud,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-sm-12">
                <ExplanationAppraiser
                    value={validationInstituteReferentieObject.toelichtingGebruikReferentieObject}
                    onChange={onObjectUsageExplanationChange}
                    disabled={isFrozen}
                    referenceObjectAnswer={referenceObjectAnswer}
                    gebruiksoppervlakteWonen={gebruiksoppervlakteWonen}
                    perceelOppervlakte={perceelOppervlakte}
                    brutoInhoud={brutoInhoud}
                    appraisal={appraisal}
                    questionSet={questionSet}
                    macros={[
                        'Vergelijkbaar, zie alle overeenkomstige kenmerken.',
                        brutoInhoud !== null &&
                        brutoInhoud > 0 &&
                        referenceObjectAnswer.referenceObject.inhoud !== null &&
                        referenceObjectAnswer.referenceObject.inhoud > 0
                            ? 'Vergelijkbaar op basis van woningtype, ligging, bouwperiode, inhoud, gebruiksoppervlakte, perceeloppervlakte en afwerking.'
                            : 'Vergelijkbaar op basis van woningtype, ligging, bouwperiode, gebruiksoppervlakte, perceeloppervlakte en afwerking.',
                        'De taxateur is zich ervan bewust dat enkele kenmerken van dit object verschillen met het getaxeerde object. De taxateur heeft voor dit object gekozen, omdat dit object van de beschikbare referentiepanden het meest passend is.',
                        'Niet vergelijkbaar. De kengetallen wijken te veel af om als goede referentie te kunnen dienen.',
                    ]}
                />
            </div>
        </div>
    );
};
