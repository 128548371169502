import {BbmiAreaProvider} from './bbmi_area_provider';
import {Observable} from 'rxjs';
import {TechnicalReference} from '../../enum/technical_reference';

export interface GebruiksOppervlakteBuitenruimteProvider {
    stream(): Observable<number | null>;
}

export class DefaultGebruiksOppervlakteBuitenruimteProvider implements GebruiksOppervlakteBuitenruimteProvider {
    constructor(private bbmiAreaProviderProvider: BbmiAreaProvider) {}

    public stream(): Observable<number | null> {
        return this.bbmiAreaProviderProvider.stream(
            TechnicalReference.BBMI_GEBOUWGEBONDEN_BUITENRUIMTE,
            TechnicalReference.AREA_USABLE_OUTDOOR
        );
    }
}
