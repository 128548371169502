import {ReferenceObjectSortingStrategy} from './reference_object_sorting_strategy';
import {SortingDirection} from '../../../../../../../../../../enum/reference_objects_sorting';

export interface AlphabetSortableObject {
    source: string | null;
    street: string;
    houseNumber: string;
    letter: string;
}

interface Pair<TUnkown, TSortableObject extends AlphabetSortableObject> {
    referenceObject: TUnkown;
    sortableObject: TSortableObject;
}

export class ReferenceObjectSorterByAlphabet implements ReferenceObjectSortingStrategy {
    public sortReferenceObjects<TUnkown, TSortableObject extends AlphabetSortableObject>(
        referenceObjects: TUnkown[],
        sortableObjectTransformer: (item: TUnkown) => TSortableObject,
        sortingDirection: SortingDirection
    ): TUnkown[] {
        const referenceObjectsOrtec: Array<Pair<TUnkown, TSortableObject>> = [];
        const referenceObjectsNVM: Array<Pair<TUnkown, TSortableObject>> = [];
        const referenceObjectsDefault: Array<Pair<TUnkown, TSortableObject>> = [];

        for (const referenceObject of referenceObjects) {
            const pair: Pair<TUnkown, TSortableObject> = {
                referenceObject,
                sortableObject: sortableObjectTransformer(referenceObject),
            };

            // const sortableObject = sortableObjectTransformer(referenceObject);
            if (pair.sortableObject.source !== null) {
                if (pair.sortableObject.source.toLowerCase().includes('nvm')) {
                    referenceObjectsNVM.push(pair);
                } else if (pair.sortableObject.source.toLowerCase().includes('ortec')) {
                    referenceObjectsOrtec.push(pair);
                } else {
                    referenceObjectsDefault.push(pair);
                }
            } else {
                referenceObjectsDefault.push(pair);
            }
        }

        const returnSortReferences: TUnkown[] = [];

        let sortedReferences = this.sortObjects(referenceObjectsNVM, sortingDirection);
        for (const sortedReference of sortedReferences) {
            returnSortReferences.push(sortedReference.referenceObject);
        }
        sortedReferences = this.sortObjects(referenceObjectsOrtec, sortingDirection);
        for (const sortedReference of sortedReferences) {
            returnSortReferences.push(sortedReference.referenceObject);
        }
        sortedReferences = this.sortObjects(referenceObjectsDefault, sortingDirection);
        for (const sortedReference of sortedReferences) {
            returnSortReferences.push(sortedReference.referenceObject);
        }

        return returnSortReferences;
    }

    private sortObjects<TUnkown, TSortableObject extends AlphabetSortableObject>(
        pairs: Array<Pair<TUnkown, TSortableObject>>,
        sortingDirection: SortingDirection
    ) {
        return pairs.sort((referenceObject1, referenceObject2) => {
            return this.getFullAddressForReferenceObject(referenceObject1.sortableObject) >
                this.getFullAddressForReferenceObject(referenceObject2.sortableObject)
                ? sortingDirection === SortingDirection.DESCENDING
                    ? -1
                    : 1
                : sortingDirection === SortingDirection.DESCENDING
                ? 1
                : -1;
        });
    }

    private getFullAddressForReferenceObject = (referenceObject: AlphabetSortableObject) => {
        return (
            referenceObject.street.toLocaleLowerCase() +
            ' ' +
            referenceObject.houseNumber +
            ' ' +
            referenceObject.letter
        );
    };
}
