export function normalizeNumber(value: string | number | null, decimals = 1): number {
    if (value !== null) {
        const normalized = parseInt(Math.round(parseFloat(value.toString(10)) * 100).toString(), 10) / 100;
        if (decimals === 0) {
            // Round half up
            return Math.round(normalized);
        }
        return parseFloat(normalized.toFixed(decimals));
    }
    return 0;
}
