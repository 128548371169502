import {Presenter} from '../../../../../support/presenter/presenter';
import {Question} from '../../../../models/question';
import {QuestionEffectInteractor} from '../../../../business/conditions/question_effects_interactor';
import {QuestionSet} from '../../../../models/question_set';
import {findChildrenForQuestion} from '../../support/question_filtering';

export class IndexPagePresenter implements Presenter {
    constructor(
        private question: Question,
        private questionSet: QuestionSet,
        private questionEffectInteractor: QuestionEffectInteractor
    ) {}

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public get children(): Question[] {
        return findChildrenForQuestion(this.question, this.questionSet).filter(
            (question) => !this.questionEffectInteractor.isHidden(question.uuid, null)
        );
    }
}
