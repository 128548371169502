import {GlobalProvider} from '../../../business/global_provider';
import {isEmpty} from '../../../support/util';
import {QuestionExtensionType} from '../../enum/question_extension_type';
import {Answer} from '../../models/answer';
import {Appraisal} from '../../models/appraisal';
import {AppraiseSecondaryType} from '../../models/appraise_secondary_config';
import {TextAIQuestionExtension} from '../../models/question_extension';
import {QuestionSet} from '../../models/question_set';
import {TextAIApi, TextAIOptions, TextAISource} from '../../network/text_ai_api';
import {AppraiseSecondaryConfigStackInteractor} from '../appraise_secondary_config_stack_interactor';
import {TextAIContextBuilder} from './textai_context_builder';
import {Observable} from 'rxjs';

export interface TextAIInteractor {
    generate(
        prompt: string,
        contextBuilders: TextAIContextBuilder[],
        answer?: Answer,
        options?: TextAIOptions
    ): Observable<string>;

    getPromptForAnswer(answer: Answer, contextBuilders: TextAIContextBuilder[]): string | null;

    openChatWidget(answer: Answer): void;
    isAvailable(): boolean;

    contextBuilderForAnswer(): TextAIContextBuilder;
    contextBuilderForAppraisal(): TextAIContextBuilder;
    contextBuilderForTransitievisieWarmte(): TextAIContextBuilder;

    contextBuildersFor(answer: Answer): TextAIContextBuilder[];
}

export class DefaultTextAIInteractor implements TextAIInteractor {
    constructor(
        private globalProvider: GlobalProvider,
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private textAIAPI: TextAIApi,
        private textAIAnswerContextBuilder: TextAIContextBuilder,
        private textAIAppraisalContextBuilder: TextAIContextBuilder,
        private textAITransitievisieWarmteContextBuilder: TextAIContextBuilder,
        private appraiseSecondaryConfigStackInteractor: AppraiseSecondaryConfigStackInteractor
    ) {}

    public generate(
        prompt: string,
        contextBuilders: TextAIContextBuilder[],
        answer?: Answer,
        options: TextAIOptions = {}
    ): Observable<string> {
        const context = contextBuilders.reduce(
            (a, b) => ({
                ...a,
                ...b.getContext(answer),
            }),
            {} as {[key: string]: unknown}
        );

        if (answer && !options.filePath) {
            let filePath: string | null = null;
            for (const contextBuilder of contextBuilders) {
                filePath = contextBuilder.getFile?.(answer)?.path ?? null;
                if (!isEmpty(filePath)) {
                    break;
                }
            }

            if (filePath) {
                options.filePath = filePath;
            }
        }

        return this.textAIAPI.prompt(prompt, context, options);
    }

    public getPromptForAnswer(answer: Answer, contextBuilders: TextAIContextBuilder[]): string | null {
        const question = this.questionSet.findQuestionByUuid(answer.questionUuid);
        const extension = question?.extensions.find((e) => e.type === QuestionExtensionType.TEXT_AI) as
            | TextAIQuestionExtension
            | undefined;

        if (extension?.prompt) {
            return extension.prompt;
        } else {
            for (const contextBuilder of [...contextBuilders].reverse()) {
                const prompt = contextBuilder.getPromptForAnswer?.(answer) ?? undefined;
                if (!isEmpty(prompt)) {
                    return prompt;
                }
            }
        }

        return null;
    }

    public openChatWidget(answer: Answer) {
        const widgetId = `textai-chat-${answer.uuid}`;

        this.appraiseSecondaryConfigStackInteractor.remove((c) => {
            return c.type === AppraiseSecondaryType.TEXTAI_CHAT;
        });

        let wasClosed = false;
        this.appraiseSecondaryConfigStackInteractor.upsert({
            id: widgetId,
            type: AppraiseSecondaryType.TEXTAI_CHAT,
            answerUuid: answer.uuid,
            appraisal: this.appraisal,
            questionSet: this.questionSet,
            onClose: () => {
                if (!wasClosed) {
                    wasClosed = true;
                    this.appraiseSecondaryConfigStackInteractor.remove((c) => c.id === widgetId);
                }
            },
        });
    }

    public isAvailable() {
        return this.globalProvider.global.gateAllowsTextAI ?? false;
    }

    public contextBuilderForAnswer() {
        return this.textAIAnswerContextBuilder;
    }

    public contextBuilderForAppraisal() {
        return this.textAIAppraisalContextBuilder;
    }

    public contextBuilderForTransitievisieWarmte() {
        return this.textAITransitievisieWarmteContextBuilder;
    }

    public contextBuildersFor(answer: Answer): TextAIContextBuilder[] {
        const question = this.questionSet.findQuestionByUuid(answer.questionUuid);
        const extension = question?.extensions.find((e) => e.type === QuestionExtensionType.TEXT_AI) as
            | TextAIQuestionExtension
            | undefined;

        // Default
        if (!extension || extension.sources.length === 0) {
            return [this.contextBuilderForAppraisal(), this.contextBuilderForAnswer()];
        }

        const builders: TextAIContextBuilder[] = [];

        if (extension.sources.includes(TextAISource.TRANSITIEVISIE_WARMTE)) {
            builders.push(this.contextBuilderForTransitievisieWarmte());
        }

        return builders;
    }
}
