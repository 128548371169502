import * as React from 'react';

import {Checkbox} from '../../../inputs/checkbox';
import {FunctionComponent} from 'react';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';
import {Textarea} from '../../../inputs/textarea';

interface OwnProps {
    referenceObjectAnswer: ReferenceObjectAnswer;
    onOtherDifferencesChange: (otherDifferences: boolean) => void;
    onOtherDifferencesExplanationChange: (otherDifferencesExplanation: string) => void;
    isFrozen: boolean;
}

export const OtherDifferences: FunctionComponent<OwnProps> = ({
    referenceObjectAnswer,
    onOtherDifferencesChange,
    onOtherDifferencesExplanationChange,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-sm-12">
                <label>Andere wezenlijke verschillen</label>
                <Checkbox
                    label="Er zijn andere wezenlijke verschillen"
                    name="input-other-differences"
                    value={validationInstituteReferentieObject.andereWezenlijkeVerschillen ?? false}
                    onChange={onOtherDifferencesChange}
                    disabled={isFrozen}
                />
            </div>
            {validationInstituteReferentieObject.andereWezenlijkeVerschillen && (
                <div className="col-sm-12">
                    <Textarea
                        isRequired
                        label="Andere wezenlijke verschillen toelichting"
                        name="input-other-differences-explanation"
                        value={validationInstituteReferentieObject.andereWezenlijkeVerschillenUitleg ?? ''}
                        onChange={onOtherDifferencesExplanationChange}
                        disabled={isFrozen}
                    />
                </div>
            )}
        </div>
    );
};
