import * as React from 'react';

import {EndButtonPresenter} from './end_button_presenter';
import {Global} from '../../../../business/global_provider';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../support/presenter/use_presenter';

interface OwnProps {
    global: Global;
}

export const EndButton: React.FC<OwnProps> = observer(function EndButton(props) {
    const presenter = usePresenter(
        (container) => new EndButtonPresenter(props.global, container.business.networkStatusProvider)
    );

    return (
        <a
            onClick={() => presenter.onClick()}
            className="btn btn-secondary"
            data-tooltip="btn-footer-submit"
            data-tooltip-title="Taxatie afsluiten"
            data-tooltip-description={
                'Met deze knop sluit je de taxatie af. Alle veranderingen zijn automatisch opgeslagen.'
            }
            data-tooltip-position="top-center"
        >
            Annuleren
        </a>
    );
});
