import * as React from 'react';

import {Link, useHistory} from 'react-router-dom';

import {Appraisal} from '../../../../models/appraisal';
import {QuestionSet} from '../../../../models/question_set';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {SidebarChildItem} from './sidebar_child_item';
import {SidebarItem} from '../../../../business/sidebar_tree_builder';
import {SidebarItemPresenter} from './sidebar_item_presenter';
import {ValidationError} from '../../../../models/validation_error';
import {classNames} from '../../../../../support/classnames';
import {observer} from 'mobx-react';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {usePresenter} from '../../../../../support/presenter/use_presenter';

interface OwnProps {
    pagePartsSet: PagePartsSet | null;
    sidebarItem: SidebarItem;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    renderingContext: RenderingContextType;
}

export const SidebarRootItem: React.FC<OwnProps> = observer((props) => {
    const history = useHistory();
    const presenter = usePresenter(
        (container) =>
            new SidebarItemPresenter(
                props.questionSet,
                props.sidebarItem,
                props.pagePartsSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.locationProvider(history),
                container.business.floorInteractor(props.appraisal, props.questionSet, props.renderingContext),
                container.business.questionValidationMessagesProvider(
                    props.appraisal,
                    props.questionSet,
                    props.validationErrors
                )
            )
    );

    const isActive = presenter.isActive(props.sidebarItem);

    return (
        <li title={props.sidebarItem.label} className={'card-progress-' + props.sidebarItem.sidebarItemSize}>
            {props.sidebarItem.disabled ? (
                <span
                    className={classNames('c-icon-' + props.sidebarItem.icon, {
                        active: isActive,
                    })}
                >
                    <span className="title">{props.sidebarItem.label}</span>
                </span>
            ) : (
                <Link
                    to={props.sidebarItem.link}
                    className={classNames('c-icon-' + props.sidebarItem.icon, {
                        active: isActive,
                    })}
                >
                    <span className="title">{props.sidebarItem.label}</span>
                </Link>
            )}
            <ul className={classNames('sub', {active: isActive})}>
                {props.sidebarItem.children.map((child) => (
                    <SidebarChildItem
                        key={child.label}
                        sidebarItem={child}
                        appraisal={props.appraisal}
                        pagePartsSet={props.pagePartsSet}
                        questionSet={props.questionSet}
                        validationErrors={props.validationErrors}
                        renderingContext={props.renderingContext}
                    />
                ))}
            </ul>
        </li>
    );
});
