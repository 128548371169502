import React from 'react';

import {classNames} from '../../../support/classnames';
import {BadgeContext} from '../../enum/badge_context';
import {ObjectType} from '../../enum/object_type';
import {objectTypeDiffToBadgeContext} from './badge_context_calculators';

interface OwnProps {
    compact?: boolean;
    objectType: ObjectType | null;
    referenceObjectType: ObjectType | null;
}

export function ObjectTypeBadge(props: OwnProps) {
    let badge: BadgeContext | null = null;
    if (props.objectType !== null && props.referenceObjectType !== null) {
        badge = objectTypeDiffToBadgeContext(props.objectType, props.referenceObjectType) ?? BadgeContext.Secondary;
    }

    if (props.objectType === null || props.referenceObjectType === null) {
        return null;
    }

    return (
        <span className="badge-container">
            <span className={classNames('badge', 'd-inline-block', badge)}>
                {props.compact ? '' : props.referenceObjectType}
            </span>
        </span>
    );
}
