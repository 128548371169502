import type {UploadResult, UppyFile} from '@uppy/core';

import {UppyCompressPlugin} from '../appraising/business/uppy_compressor_plugin';

(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Uppy = require('@uppy/core');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const AwsS3 = require('@uppy/aws-s3');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const FileInput = require('@uppy/file-input');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const ProgressBar = require('@uppy/progress-bar');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const requestSignedUrl = require('../network/signing').requestSignedUrl;

    $('.uppy').each(function () {
        const inputName = $(this).attr('data-name');
        if (inputName === undefined) {
            throw new Error('No form name not available for uploader');
        }

        const postUrl = $(this).attr('data-url');
        if (postUrl === undefined) {
            throw new Error('No post url not available for uploader');
        }

        const formattedFileTypes: string[] = [];
        const fileTypes: string | null | undefined = $(this).attr('data-extensions');
        if (fileTypes !== undefined && fileTypes !== null) {
            // To support both .jpg and .JPG
            JSON.parse(fileTypes).forEach((fileType: string) => {
                formattedFileTypes.push(fileType);
                formattedFileTypes.push(fileType.toUpperCase());
            });
        }

        const attributeMaxFileSize = $(this).attr('data-max-file-size');
        const maxFileSizeBytes = attributeMaxFileSize === undefined ? null : parseInt(attributeMaxFileSize, 10);
        const container = $('[data-name="' + inputName + '"]');
        const errorContainer = $('#uppy-error-' + inputName);

        const uppy = new Uppy({
            debug: process.env.DEBUG === 'true',
            autoProceed: true,
            restrictions: {
                maxFileSize: maxFileSizeBytes,
                minNumberOfFiles: 1,
                allowedFileTypes: formattedFileTypes.length !== 0 ? formattedFileTypes : null,
            },
            locale: {
                strings: {
                    exceedsSize: '%{file} overschrijdt de maximaal toegelaten grootte van %{size}',
                },
            },
        })
            .use(FileInput, {
                target: '#uppy-target-' + inputName,
                pretty: true,
                replaceTargetContent: true,
                locale: {
                    strings: {
                        chooseFiles:
                            maxFileSizeBytes === null
                                ? 'Kies een bestand'
                                : `Kies een bestand (max. ${maxFileSizeBytes / 1000000}MB)`,
                    },
                },
            })
            .use(ProgressBar, {
                target: '#uppy-progress-' + inputName,
                fixed: false,
                hideAfterFinish: true,
            })
            .use(AwsS3, {
                getUploadParameters: (file: UppyFile) => requestSignedUrl(file),
            });

        if ($(this).attr('data-compression') !== 'false') {
            uppy.use(UppyCompressPlugin);
        }

        uppy.on('file-added', () => {
            container.removeClass('error');
            errorContainer.addClass('d-none');
        });

        uppy.on('upload-error', (file: UppyFile, error: string) => {
            container.addClass('error');
            errorContainer.removeClass('d-none').html(error);
        });

        uppy.on('restriction-failed', (file: UppyFile, error: string) => {
            container.addClass('error');
            errorContainer.removeClass('d-none').html(error);
        });

        uppy.on('complete', (result: UploadResult) => {
            if (result.successful.length > 0) {
                $('#uppy-filename-' + inputName).html(result.successful[0].meta.name);
                if (
                    result.successful[0].meta.type === 'image/png' ||
                    result.successful[0].meta.type === 'image/jpg' ||
                    result.successful[0].meta.type === 'image/jpeg'
                ) {
                    $('#uppy-preview-' + inputName).html(
                        $('<img />').attr(
                            'src',
                            URL.createObjectURL(result.successful[0].data)
                        ) as unknown as HTMLElement
                    );
                } else {
                    $('#uppy-preview-' + inputName).html('');
                }

                if (result.successful.length !== 0) {
                    postResultToUrl(
                        postUrl,
                        (result.successful[0].meta as Record<string, string>).path,
                        result.successful[0].name,
                        result.successful[0].type
                    ).then((res) => {
                        document.dispatchEvent(
                            new CustomEvent('uppy-upload-posted', {
                                detail: res,
                            })
                        );
                    });
                }
                document.dispatchEvent(new Event('uppy-upload-completed'));
            } else if (result.failed.length > 0) {
                container.addClass('error');
                errorContainer.removeClass('d-none').html(result.failed[0].error);
            }
        });
    });

    function postResultToUrl(url: string, path: string, name: string, type: string | undefined) {
        return new Promise<unknown>((resolve, reject) => {
            window
                .fetch(url, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        path: path,
                        name: name,
                        type: type,
                    }),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(await result.json());
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
})();
