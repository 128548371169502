import {ReferenceSaleImageSource} from '../../../../../../../../../network/models/api_reference_sale_image';
import {ReferenceSaleImage} from '../../../v1/models/reference_sale_image';

export interface PhotoSet {
    quarterDate: Date | null;
    source: ReferenceSaleImageSource | null;
    images: ReferenceSaleImage[];
}

const CONFIDENCE_CUTOFF = 0.8;
const LABEL_ORDER = [
    'uploaded_vooraanzicht',
    'vooraanzicht',
    'streetview_vooraanzicht',
    'woonkamer',
    'keuken',
    'badkamer',
    'slaapkamer',
    'tuin',
    'straatbeeld',
];
const SOURCE_ORDER = [
    ReferenceSaleImageSource.BRAINBAY,
    ReferenceSaleImageSource.ALTUM,
    ReferenceSaleImageSource.MATRIXIAN,
    ReferenceSaleImageSource.JAAP,
    ReferenceSaleImageSource.OOZO,
];

export function buildPhotoSets(images: ReferenceSaleImage[]) {
    const setsByKey: {[key: string]: PhotoSet} = {};
    const uploadedImages: ReferenceSaleImage[] = [];

    for (const image of images) {
        if (image.label === 'uploaded_vooraanzicht') {
            uploadedImages.push(image);
            continue;
        }

        const quarterDate = image.date
            ? new Date(image.date.getFullYear(), Math.floor(image.date.getMonth() / 3) * 3)
            : null;
        const key = `${quarterDate?.toISOString()}-${image.source}`;

        if (!setsByKey[key]) {
            setsByKey[key] = {
                quarterDate,
                source: image.source,
                images: [],
            };
        }

        setsByKey[key].images.push(image);
    }

    const sets = Object.values(setsByKey);

    sets.sort((a, b) => {
        if (a.quarterDate && b.quarterDate) {
            return b.quarterDate.getTime() - a.quarterDate.getTime();
        }

        if (a.quarterDate) {
            return -1;
        }

        if (b.quarterDate) {
            return 1;
        }

        if (a.source && b.source) {
            return SOURCE_ORDER.indexOf(a.source) - SOURCE_ORDER.indexOf(b.source);
        }

        if (a.source) {
            return -1;
        }

        if (b.source) {
            return 1;
        }

        return 0;
    });

    while (
        sets.length > 1 &&
        sets[0].images.every((img) => img.label === 'vooraanzicht' || img.label === 'streetview_vooraanzicht') &&
        (sets[0].source === null || sets[1].source === null || sets[0].source === sets[1].source)
    ) {
        sets[1].images = sets[0].images.concat(sets[1].images);
        sets.shift();
    }

    for (const set of sets) {
        const groupedRecognizedImages: {[label: string]: ReferenceSaleImage[]} = {};
        const groupedUnrecognizedImages: ReferenceSaleImage[] = [];

        for (const image of set.images) {
            if (image.label !== null && image.confidence !== null && image.confidence >= CONFIDENCE_CUTOFF) {
                if (!groupedRecognizedImages[image.label]) {
                    groupedRecognizedImages[image.label] = [];
                }
                groupedRecognizedImages[image.label].push(image);
            } else {
                groupedUnrecognizedImages.push(image);
            }
        }

        for (const images of Object.values(groupedRecognizedImages)) {
            images.sort((a, b) => (a.confidence ?? 0) - (b.confidence ?? 0));
        }

        set.images = LABEL_ORDER.flatMap((label) => groupedRecognizedImages[label] ?? []).concat(
            Object.entries(groupedRecognizedImages)
                .filter(([label]) => !LABEL_ORDER.includes(label))
                .flatMap(([, images]) => images),
            groupedUnrecognizedImages
        );
    }

    if (sets.length === 0 && uploadedImages.length > 0) {
        sets.push({
            quarterDate: null,
            source: null,
            images: uploadedImages,
        });
    } else if (uploadedImages.length > 0) {
        sets[0].images = uploadedImages.concat(sets[0].images);
    }

    return sets;
}
