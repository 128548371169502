import {Global, GlobalProvider} from '../../../../business/global_provider';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {Presenter} from '../../../../support/presenter/presenter';
import {UserWidget} from '../../../enum/user_widget';
import {UserWidgetApi} from '../../../network/user_widget_api';

export class WidgetsContainerPresenter implements Presenter {
    @observable public saving = false;
    @observable public global: Global;
    @observable.ref public userWidgets: UserWidget[] = [];
    @observable public isEditUserWidgetsModalOpen = false;

    constructor(globalProvider: GlobalProvider, private userWidgetApi: UserWidgetApi) {
        this.global = globalProvider.global;
        this.userWidgets = this.global.userWidgets;

        makeObservable(this);
    }

    public mount() {
        //Noop
    }

    public unmount() {
        //Noop
    }

    @action
    public toggleEditUserWidgetsModal = () => {
        this.isEditUserWidgetsModalOpen = !this.isEditUserWidgetsModalOpen;
    };

    public onChangeUserWidgets = async (userWidgets: UserWidget[]) => {
        try {
            runInAction(() => {
                this.saving = true;
            });
            const storedUserWidgets = await this.userWidgetApi.store(userWidgets);
            runInAction(() => {
                this.userWidgets = storedUserWidgets;
            });
        } catch (e) {
            alert('Er ging iets mis met opslaan van de widgets');
        } finally {
            runInAction(() => {
                this.isEditUserWidgetsModalOpen = false;
                this.saving = false;
            });
        }
    };
}
