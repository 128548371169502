import * as React from 'react';
import {ImageViewer} from '../../../../../../../../../components/image_viewer/image_viewer';
import {ReferenceSaleImage} from '../../../v1/models/reference_sale_image';
import {getLabelSelectorComponent} from './label_selector';
import {getPhotoSetSelectorComponent} from './photo_set_selector';
import {detect} from 'detect-browser';
import {buildPhotoSets} from './photo_sets';
import {imagesToKey} from '../reference_object_tile/reference_object_tile_image_slider';
import {Appraisal} from '../../../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {getChoosePhotoButtonCompontent} from './choose_photo_button';

export function ReferenceImageViewer({
    images,
    initialOpenIndex,
    hideSlider,
    appraisal,
    questionSet,
    photoAnswerUuid,
    onOpened,
    onClose,
}: {
    images: ReferenceSaleImage[];
    initialOpenIndex?: number;
    hideSlider?: boolean;
    appraisal?: Appraisal;
    questionSet?: QuestionSet;
    photoAnswerUuid?: string;
    onOpened?: () => void;
    onClose?: () => void;
}) {
    const photoSets = React.useMemo(() => buildPhotoSets(images), [imagesToKey(images)]);

    const [activeSet, setActiveSet] = React.useState(0);
    const [filteredLabels, setFilteredLabels] = React.useState<string[] | null>(null);

    const filteredImages = React.useMemo(() => {
        const urls: string[] =
            photoSets[activeSet]?.images
                .filter((i) => !filteredLabels || (i.label && filteredLabels.includes(i.label)))
                .map((i) => i.url)
                .filter((url): url is string => url !== null) ?? [];

        return urls;
    }, [photoSets, activeSet, filteredLabels]);

    const hideThumbnailSlider = React.useMemo(() => {
        const browser = detect();
        return browser?.name === 'safari' || browser?.name === 'ios';
    }, []);

    const openIndex = React.useMemo(() => {
        if (initialOpenIndex !== undefined && images[initialOpenIndex]?.url) {
            const index = filteredImages.indexOf(images[initialOpenIndex].url as string);
            if (index !== -1) {
                return index;
            } else {
                return 0;
            }
        }

        return undefined;
    }, []);

    const components = [
        getLabelSelectorComponent({
            activeSet: photoSets[activeSet],
            filteredLabels,
            setFilteredLabels,
        }),
        getPhotoSetSelectorComponent({
            activeSet,
            setActiveSet,
            sets: photoSets,
        }),
    ];

    if (photoAnswerUuid && appraisal && questionSet)
        components.push(
            getChoosePhotoButtonCompontent({
                appraisal,
                questionSet,
                photoAnswerUuid,
            })
        );

    return (
        <ImageViewer
            images={filteredImages}
            hideThumbnailSlider={hideThumbnailSlider}
            openImmediateIndex={openIndex}
            onOpened={onOpened}
            onClosed={() => {
                setFilteredLabels(null);
                onClose?.();
            }}
            components={components}
        >
            {hideSlider ? <></> : undefined}
        </ImageViewer>
    );
}
