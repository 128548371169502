import {ConstructionCostsGroup, FlatConstructionCost} from '../network/models/construction_costs';
import {Observable, ReplaySubject} from 'rxjs';

import {ConstructionCostSettings} from '../network/models/construction_costs_settings';
import {ConstructionCostsApi} from '../network/construction_costs_api';

export interface ConstructionCostController {
    refresh(): void;
    constructionCosts: Observable<ConstructionCostsGroup[]>;
    storeOrUpdate(constructionCosts: FlatConstructionCost): void;
    delete(id: number): void;
    settings: Observable<ConstructionCostSettings>;
    setSettings(constructionCostSettings: ConstructionCostSettings): void;
}

export class DefaultConstructionCostController implements ConstructionCostController {
    private subject = new ReplaySubject<ConstructionCostsGroup[]>();
    private settingsSubject = new ReplaySubject<ConstructionCostSettings>();

    constructor(private constructionCostsApi: ConstructionCostsApi) {}

    public async refresh() {
        try {
            const groups = await this.constructionCostsApi.all();
            this.subject.next(groups);
        } catch (error) {
            console.error(error);
        }
    }

    public get constructionCosts(): Observable<ConstructionCostsGroup[]> {
        this.refresh();

        return this.subject;
    }

    public async storeOrUpdate(constructionCosts: FlatConstructionCost): Promise<void> {
        await this.constructionCostsApi.put(constructionCosts);
        await this.refresh();
    }

    public async delete(id: number): Promise<void> {
        await this.constructionCostsApi.delete(id);
        await this.refresh();
    }

    public get settings(): Observable<ConstructionCostSettings> {
        this.refreshSettings();

        return this.settingsSubject;
    }

    public async setSettings(constructionCostSettings: ConstructionCostSettings) {
        await this.constructionCostsApi.updateSettings(constructionCostSettings);
        await this.refreshSettings();
    }

    private async refreshSettings() {
        try {
            const settings = await this.constructionCostsApi.getSettings();
            this.settingsSubject.next(settings);
        } catch (error) {
            console.error(error);
        }
    }
}
