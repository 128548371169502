import * as React from 'react';

import {ApiGlobal} from '../../../../../../business/global_provider';
import {MultipleChoiceSelectQuestion} from './multiple_choice_select_question';
import {SimpleQuestionPresenterProps} from './simple_question_presenter';
import {observer} from 'mobx-react';

declare let GLOBAL: ApiGlobal;
@observer
export class SurfaceMethodSelectQuestion extends React.Component<SimpleQuestionPresenterProps> {
    public render() {
        const props = {
            ...this.props,
            adaptedDefaultValue: GLOBAL.user_surface_input_method,
        };
        return <MultipleChoiceSelectQuestion {...props} />;
    }
}
