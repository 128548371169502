import {getStringIndices} from '../appraise/ui/support/string';

export function macroIsActive(macroValue: string | null, currentContents: string | null) {
    if (currentContents === null || macroValue === null) {
        return false;
    }
    return getStringIndices(currentContents, macroValue).start > -1;
}

export function toggleMacro(macroValue: string | null, currentContents: string | null): string {
    if (macroValue === null) {
        return currentContents ?? '';
    }

    let newContents = '';

    if (currentContents === null || currentContents === '') {
        newContents = macroValue;
    } else {
        const {start, end} = getStringIndices(currentContents, macroValue);
        if (start > -1 && end > -1) {
            // Create two substrings with the start and end indexes
            // which results in a new string except the part between the start and end index
            newContents = (currentContents.substring(0, start) + currentContents.substring(end + 1)).trim();
        } else {
            newContents = currentContents + '\n' + macroValue;
        }
    }

    return newContents;
}
