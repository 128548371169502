import * as React from 'react';

import {FunctionComponent} from 'react';
import {classNames} from '../../../../../../../../../../support/classnames';
import {ReferenceImageViewer} from '../photos/image_viewer';
import {ReferenceSaleImage} from '../../../v1/models/reference_sale_image';
import {Appraisal} from '../../../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../../../models/question_set';

interface OwnProps {
    images: ReferenceSaleImage[];
    hasCheckmark: boolean;
    appraisal?: Appraisal;
    questionSet?: QuestionSet;
    grayScale?: boolean;
    photoAnswerUuid?: string;
    onOpened?: () => void;
}

export function imagesToKey(images: ReferenceSaleImage[]) {
    return images.map(({url}) => url).join(',');
}

export const ReferenceObjectTileImageSlider: FunctionComponent<OwnProps> = React.memo(
    function ReferenceObjectTileImageSliderComponent({
        images,
        hasCheckmark,
        grayScale,
        onOpened,
        appraisal,
        questionSet,
        photoAnswerUuid,
    }: OwnProps) {
        return (
            <div
                className={classNames('form-grid-item-img-content', {
                    'gray-scale': grayScale,
                })}
            >
                <ReferenceImageViewer
                    images={images}
                    onOpened={onOpened}
                    appraisal={appraisal}
                    questionSet={questionSet}
                    photoAnswerUuid={photoAnswerUuid}
                />
                {hasCheckmark && <div className="active-icon ion-md-checkmark visible">&nbsp;</div>}
            </div>
        );
    },
    (a, b) =>
        imagesToKey(a.images) === imagesToKey(b.images) && a.grayScale === b.grayScale && a.onOpened === b.onOpened
);
