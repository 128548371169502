/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import {classNames} from '../../../../../support/classnames';
import {BuildYearBadge} from '../../../../components/badges/build_year_badge';
import {EnergyLabelBadge} from '../../../../components/badges/energy_label_badge';
import {FloorAreaBadge} from '../../../../components/badges/floor_area_badge';
import {ObjectTypeBadge} from '../../../../components/badges/object_type_badge';
import {PlotAreaBadge} from '../../../../components/badges/plot_area_badge';
import {ObjectType} from '../../../../enum/object_type';
import {ComparabilityIndicator} from '../../components/compare_group/comparability_indicator';

interface OwnProps {
    objectType: string | null;
    referenceObjectType: ObjectType | null;
    buildYear: number | null;
    referenceBuildYear: number | null;
    surfaceArea: number | null;
    referenceSurfaceArea: number | null;
    plotArea: number | null;
    referencePlotArea: number | null;
    energyLabel: string | null;
    referenceEnergyLabel: string | null;
    overallComparability: number | null;
    differencesComparability: number | null;
}

export function ReferenceObjectWidgetTileDetails({
    objectType,
    referenceObjectType,
    buildYear,
    referenceBuildYear,
    surfaceArea,
    referenceSurfaceArea,
    plotArea,
    referencePlotArea,
    energyLabel,
    referenceEnergyLabel,
    overallComparability,
    differencesComparability,
}: OwnProps) {
    const comparabilityClassName = useMemo(() => {
        if (overallComparability === null) {
            return null;
        }

        if (overallComparability > 0.3) {
            return 'compare-much-better';
        } else if (overallComparability > 0.15) {
            return 'compare-better';
        } else if (overallComparability < -0.15) {
            return 'compare-worse';
        } else if (overallComparability < -0.3) {
            return 'compare-much-worse';
        } else {
            return 'compare-neutral';
        }
    }, [overallComparability]);

    const comparabilityText = useMemo(() => {
        if (overallComparability === null) {
            return null;
        }

        if (overallComparability > 0.3) {
            return 'Veel beter';
        } else if (overallComparability > 0.15) {
            return 'Beter';
        } else if (overallComparability < -0.15) {
            return 'Slechter';
        } else if (overallComparability < -0.3) {
            return 'Veel slechter';
        } else {
            return 'Vergelijkbaar';
        }
    }, [overallComparability]);

    return (
        <>
            <div className="row">
                {objectType ? (
                    <>
                        <div className="col-10">
                            <span className="reference-object-detail ion-md-home">{objectType ?? '-'}</span>
                        </div>
                        <div className="col-2 pl-0 col-badge">
                            {referenceObjectType && (
                                <ObjectTypeBadge
                                    objectType={objectType as ObjectType}
                                    referenceObjectType={referenceObjectType}
                                    compact
                                />
                            )}
                        </div>
                    </>
                ) : null}
                {buildYear ? (
                    <>
                        <div className="col-10">
                            <span className="reference-object-detail c-icon-build-year">{buildYear ?? '-'}</span>
                        </div>
                        <div className="col-2 pl-0 col-badge">
                            {referenceBuildYear && (
                                <BuildYearBadge buildYear={buildYear} referenceBuildYear={referenceBuildYear} compact />
                            )}
                        </div>
                    </>
                ) : null}
                {surfaceArea ? (
                    <>
                        <div className="col-4 pr-0">
                            <span className="reference-object-detail c-icon-floor-area">{surfaceArea ?? '-'}</span>
                        </div>
                        <div className="col-2 pl-0 col-badge col-badge-small">
                            {referenceSurfaceArea && (
                                <FloorAreaBadge
                                    floorArea={surfaceArea}
                                    referenceFloorArea={referenceSurfaceArea}
                                    compact
                                />
                            )}
                        </div>
                    </>
                ) : null}
                {plotArea ? (
                    <>
                        <div className="col-4 pr-0">
                            <span className="reference-object-detail c-icon-plot-area">{plotArea ?? '-'}</span>
                        </div>
                        <div className="col-2 pl-0 col-badge col-badge-small">
                            {referencePlotArea && (
                                <PlotAreaBadge plotArea={plotArea} referencePlotArea={referencePlotArea} compact />
                            )}
                        </div>
                    </>
                ) : null}
                {energyLabel ? (
                    <>
                        <div className="col-10">
                            <span className="reference-object-detail ion-md-flash">{energyLabel ?? '-'}</span>
                        </div>
                        <div className="col-2 pl-0 col-badge">
                            {referenceEnergyLabel && (
                                <EnergyLabelBadge
                                    energyLabel={energyLabel}
                                    referenceEnergyLabel={referenceEnergyLabel}
                                    compact
                                />
                            )}
                        </div>
                    </>
                ) : null}
                {differencesComparability ? (
                    <div className="col-12">
                        <span className="reference-object-detail icon-checklist" />
                        <ComparabilityIndicator
                            className="d-inline-block"
                            comparability={differencesComparability}
                            style={{marginBottom: -5, marginLeft: 5}}
                        />
                    </div>
                ) : null}
            </div>
            {overallComparability && (
                <div className={classNames('reference-comparability-bar', comparabilityClassName)}>
                    {comparabilityText}
                </div>
            )}
        </>
    );
}
