import {action, makeObservable, observable, runInAction} from 'mobx';
import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {AppraisalProvider} from '../../../../../../../../../business/appraisal_provider';
import {ReferenceSubscriptionType} from '../../../../../../../../../models/reference_subscriptions';
import {AltumSettings} from '../../../../../../../../../network/preselected_reference_objects_api';
import {ReferenceObjectApi} from '../../../../../../../../../network/reference_objects_api';

export class AltumPreferencesModalPresenter implements Presenter {
    @observable public preferences: AltumSettings | null = null;
    @observable public saving = false;

    constructor(
        private readonly appraisalProvider: AppraisalProvider,
        private readonly referenceObjectApi: ReferenceObjectApi
    ) {
        makeObservable(this);
    }

    public mount(): void {
        //nop
    }

    public unmount(): void {
        //nop
    }

    @action
    public updatePreferences = (preferences: AltumSettings): void => {
        this.preferences = preferences;
    };

    public savePreferences = (): Promise<void> => {
        if (!this.preferences || !this.appraisalProvider.hasAppraisal) {
            return Promise.resolve();
        }

        runInAction(() => {
            this.saving = true;
        });

        return this.referenceObjectApi
            .updateSubscriptionPreferences(
                this.appraisalProvider.appraisal.id,
                ReferenceSubscriptionType.ALTUM,
                this.preferences
            )
            .then(() => {
                runInAction(() => {
                    this.saving = false;
                });
            });
    };
}
