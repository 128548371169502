import {Observable, ReplaySubject} from 'rxjs';
import {Appraisal} from '../models/appraisal';

export interface AppraisalProvider {
    stream: Observable<Appraisal>;
    appraisal: Appraisal;
    hasAppraisal: boolean;

    onChange(appraisal: Appraisal): void;
}

export class DefaultAppraisalProvider implements AppraisalProvider {
    private _appraisal: Appraisal | null = null;

    get appraisal(): Appraisal {
        if (this._appraisal === null) {
            throw new Error('Appraisal provider called before initialization');
        }
        return this._appraisal;
    }

    get hasAppraisal(): boolean {
        return this._appraisal !== null;
    }

    public stream = new ReplaySubject<Appraisal>(1);

    public onChange(appraisal: Appraisal): void {
        this._appraisal = appraisal;
        this.stream.next(appraisal);
    }
}
