import {action, makeObservable, observable, override, runInAction} from 'mobx';

import {GlobalProvider} from '../../../../../../../business/global_provider';
import {CompositeSubscription} from '../../../../../../../support/composite_subscription';
import {isSet} from '../../../../../../../support/is_set';
import {AnswerInteractor} from '../../../../../../business/answering/answer_interactor';
import {BlobCacheInteractor} from '../../../../../../business/attachments/blob_cache_interactor';
import {ImageUploadInteractor} from '../../../../../../business/attachments/image_upload_interactor';
import {AttachmentQuestionsInteractor, PhotoPair} from '../../../../../../business/attachment_questions_interactor';
import {FlashMessageBroadcaster, Type} from '../../../../../../business/flash_message_broadcaster';
import {parseImageAnswerContents} from '../../../../../../business/support/parse_image_answer_contents';
import {Answer} from '../../../../../../models/answer';
import {SimpleQuestionPresenter} from '../../simple/simple_question_presenter';
import {ImageUploadState} from '../attachment_question_presenter';
import {PhotoQuestionPresenter} from './photo_question_presenter';
import {AutomatorInteractor} from '../../../../../../business/automator_interactor';

export class BuildingCostsPhotoPresenter extends PhotoQuestionPresenter {
    @observable.ref public pairs: PhotoPair[] = [];

    @observable public isPhotoPickModalOpen = false;
    @observable public isReplacePhotoOverlayVisible = false;

    @observable private isUpdatingFile = false;

    @override
    public override get loading() {
        if (this.isUpdatingFile) {
            return true;
        }

        if (!isSet(this.answer)) {
            return true;
        }

        if (!isSet(this.answer.contents)) {
            return false;
        }

        const contents = parseImageAnswerContents(this.answer.contents);
        return contents.state === ImageUploadState.UNSYNCED || contents.state === ImageUploadState.SYNCING;
    }

    private subscriptions = new CompositeSubscription();

    constructor(
        fileTypes: string[],
        blobCacheInteractor: BlobCacheInteractor,
        imageUploadInteractor: ImageUploadInteractor,
        answerInteractor: AnswerInteractor,
        attachmentQuestionsInteractor: AttachmentQuestionsInteractor,
        flashMessageBroadcaster: FlashMessageBroadcaster,
        globalProvider: GlobalProvider,
        automatorInteractor: AutomatorInteractor,
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(
            fileTypes,
            blobCacheInteractor,
            imageUploadInteractor,
            answerInteractor,
            attachmentQuestionsInteractor,
            flashMessageBroadcaster,
            globalProvider,
            automatorInteractor,
            ...simpleQuestionPresenterParameters
        );
        makeObservable(this);
    }

    public mount() {
        super.mount();

        this.subscriptions.add(
            this.attachmentQuestionsInteractor.photoPairsStream().subscribe((photoAnswers) => {
                runInAction(() => {
                    this.pairs = photoAnswers;
                });
            })
        );
    }

    public unmount() {
        super.unmount();

        this.subscriptions.clear();
    }

    @action
    public setPhotoPickModalOpen = (isOpen: boolean) => {
        this.isPhotoPickModalOpen = isOpen;
    };

    public onImagePicked = async (answer: Answer) => {
        const answerParent = answer.parentUuid != null ? this.answerController.byUuid(answer.parentUuid) : null;

        if (answer.contents && answerParent) {
            try {
                runInAction(() => {
                    this.isImageLoaded = true;
                });

                this.onChange(answer.contents);
                await this.answerInteractor.submit();
            } catch (error) {
                console.error(error);
                this.flashMessageBroadcaster.broadcast(
                    'Updaten van de afbeelding mislukt, probeer het later opnieuw.',
                    Type.Danger
                );
            } finally {
                runInAction(() => {
                    this.isImageLoaded = false;
                    this.isPhotoPickModalOpen = false;
                    this.isReplacePhotoOverlayVisible = false;
                });
            }
        }
    };

    @action
    public toggleReplacePhoto = () => {
        this.isReplacePhotoOverlayVisible = !this.isReplacePhotoOverlayVisible;
    };
}
