import {FileReference} from '../../models/file_reference';

export interface ApiFile {
    id: number;
    content_type: string;
    original_filename: string;
    url: string;
    uncompressed_url: string;
    path?: string;
}

export function apiFileToFile(apiFile: ApiFile): FileReference {
    return {
        id: apiFile.id,
        contentType: apiFile.content_type,
        originalFilename: apiFile.original_filename,
        url: apiFile.url,
        uncompressedUrl: apiFile.uncompressed_url,
        path: apiFile.path,
    };
}

export function fileToApiFile(file: FileReference): ApiFile {
    return {
        id: file.id,
        content_type: file.contentType,
        original_filename: file.originalFilename,
        url: file.url,
        uncompressed_url: file.uncompressedUrl,
        path: file.path,
    };
}
