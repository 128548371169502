import {observer} from 'mobx-react';
import React from 'react';
import {AltumSettingsHouseType, AltumSettings} from '../../../appraising/network/preselected_reference_objects_api';

const HouseTypeMap: {[key: string]: AltumSettingsHouseType[]} = {
    Vrijstaand: [
        AltumSettingsHouseType.VRIJSTAAND,
        AltumSettingsHouseType.VRIJSTAAND_DOELGROEP,
        AltumSettingsHouseType.VRIJSTAAND_RECREATIE,
    ],
    '2-onder-1-kap': [
        AltumSettingsHouseType.TWEE_ONDER_EEN_KAP,
        AltumSettingsHouseType.GESCHAKELD_TWEE_ONDER_EEN_KAP,
        AltumSettingsHouseType.GESCHAKELD,
    ],
    'Tussen/rijwoning': [AltumSettingsHouseType.TUSSENWONING, AltumSettingsHouseType.TUSSEN_DOELGROEP],
    Hoekwoning: [AltumSettingsHouseType.HOEKWONING],
    Eindwoning: [AltumSettingsHouseType.EINDWONING],
    Appartement: [
        AltumSettingsHouseType.GALERIJFLAT,
        AltumSettingsHouseType.PORTIEKFLAT,
        AltumSettingsHouseType.CORRIDORFLAT,
        AltumSettingsHouseType.BENEDENWONING,
        AltumSettingsHouseType.BOVENWONING,
        AltumSettingsHouseType.PORTIEKWONING,
        AltumSettingsHouseType.MAISONNETTE,
    ],
};

interface OwnProps {
    value: AltumSettings;
    onChange: (value: AltumSettings) => void;
    givenValues?: {
        type?: string;
    };
}

@observer
export class ReferenceObjectTypeSettings extends React.Component<OwnProps> {
    private onChange(key: keyof typeof HouseTypeMap) {
        const values = HouseTypeMap[key];
        const checked = values.some((v) => this.props.value.housetype_to_search.includes(v));
        if (checked) {
            this.props.onChange({
                ...this.props.value,
                housetype_to_search: this.props.value.housetype_to_search.filter((t) => !values.includes(t)),
            });
        } else {
            this.props.onChange({
                ...this.props.value,
                housetype_to_search: [...this.props.value.housetype_to_search, ...values],
            });
        }
    }

    public render() {
        return (
            <>
                <dl>
                    <div className="row">
                        <div className="col-12">
                            <h3 className="mb-0">Woningtype</h3>
                            <dd className="mt-0 pb-2">{this.props.givenValues?.type}</dd>
                        </div>
                    </div>
                </dl>
                <div className="form-checklist">
                    {Object.entries(HouseTypeMap).map(([key, value]) => (
                        <div className="form-group" key={key}>
                            <input
                                type="checkbox"
                                id={key}
                                name={key}
                                checked={value.some((v) => this.props.value.housetype_to_search.includes(v))}
                                onChange={() => this.onChange(key)}
                            />
                            <label htmlFor={key}>{key}</label>
                        </div>
                    ))}
                </div>
            </>
        );
    }
}
