import * as React from 'react';

import {ConceptReportPresenter} from './concept_report_presenter';
import {DummyConceptReport} from './dummy_concept_report';
import {Loader} from '../../../components/loader';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';
import {ReportSelectorType} from '../../../../../enum/report_selector_type';
import {SimpleQuestionPresenterProps} from '../simple/simple_question_presenter';
import {TaskHelper} from '../../../../../business/task_helper';
import {isInEnum} from '../../../../../../support/is_in_enum';
import {observer} from 'mobx-react';
import {usePresenter, PresenterProps} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

interface OwnState {
    iframe: HTMLIFrameElement | null;
}

@observer
class ConceptReportComponent extends React.Component<OwnProps & PresenterProps<ConceptReportPresenter>, OwnState> {
    public state: OwnState = {
        iframe: null,
    };

    private onMessage = (message: MessageEvent) => {
        if (message.data && typeof message.data === 'string' && message.data.startsWith('data-reference:')) {
            const reference = message.data.split('data-reference:')[1];
            if (isInEnum(ReportSelectorType, reference)) {
                this.props.presenter.onReplacementClick(reference);
            }
        }
    };

    public componentDidMount() {
        window.addEventListener('message', this.onMessage, false);
    }

    public componentWillUnmount() {
        window.removeEventListener('message', this.onMessage);
    }

    public getSnapshotBeforeUpdate() {
        if (this.state.iframe) {
            return this.state.iframe.contentWindow?.scrollY;
        }
        return null;
    }
    /**
     * Called immediately after updating occurs. Not called for the initial render.
     *
     * The snapshot is only present if getSnapshotBeforeUpdate is present and returns non-null.
     */
    public componentDidUpdate(
        prevProps: OwnProps & PresenterProps<ConceptReportPresenter>,
        prevState: OwnState,
        snapshot?: number
    ) {
        if (snapshot && this.state.iframe) {
            const iframe = this.state.iframe;
            iframe.onload = () => {
                iframe.contentWindow?.scrollTo(0, snapshot);
            };
        }
    }

    public render() {
        if (this.props.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
            return <DummyConceptReport />;
        }

        return (
            <div className="concept-report d-flex">
                <div className="row">
                    {this.props.presenter.initialConceptReport !== null ? (
                        <iframe
                            srcDoc={this.props.presenter.initialConceptReport}
                            ref={(iframe) => {
                                if (iframe && iframe !== this.props.presenter.htmlIFrameElement) {
                                    iframe.onload = () => {
                                        this.props.presenter.onIframeRefupdate(iframe);
                                    };
                                }
                            }}
                            frameBorder={0}
                            width="100%"
                            height="100%"
                        />
                    ) : (
                        <div className="row">
                            <div className="col-12 text-center">
                                <Loader icon="c-icon c-icon-concept-report" addSpace />
                                <h3>Concept rapport aan het genereren...</h3>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export const ConceptReport: React.FC<OwnProps> = observer(function ConceptReport(props) {
    const presenter = usePresenter(
        (container) =>
            new ConceptReportPresenter(
                props.appraisal,
                props.questionSet,
                container.business.conceptReportProvider(
                    new TaskHelper(container.network.ajaxDriver),
                    container.network.appraisalApi
                ),
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.appraiseSecondaryConfigStackInteractor
            )
    );
    return <ConceptReportComponent {...props} presenter={presenter} />;
});
