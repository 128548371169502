import {ExplanationData} from './explanation_data';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';

export class ExplanationBuildingTypeGenerator {
    public generateDetailsSentence(
        explanationData: ExplanationData,
        referenceObjectAnswer: ReferenceObjectAnswer
    ): string {
        const result: string[] = [];

        if (referenceObjectAnswer.referenceObject.woningTypeStatus) {
            const status = referenceObjectAnswer.referenceObject.woningTypeStatus.toLocaleLowerCase();
            result.push(`heeft een ${status === 'vergelijkbaar' ? status : 'ander'} woningtype`);
        }
        if (referenceObjectAnswer.referenceObject.woningType !== null) {
            result.push(`, namelijk ${referenceObjectAnswer.referenceObject.woningType.toLocaleLowerCase()}`);
        }

        return result.join('');
    }
}
