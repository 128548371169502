/**
 * Split a array in two based on the predicate
 */
export function partition<T>(array: T[], predicate: (item: T) => boolean) {
    return array.reduce(
        ({unmatched, matched}, elem) => {
            if (predicate(elem)) {
                return {
                    matched: [...matched, elem],
                    unmatched: unmatched,
                };
            } else {
                return {
                    matched: matched,
                    unmatched: [...unmatched, elem],
                };
            }
        },
        {
            unmatched: [] as T[],
            matched: [] as T[],
        }
    );
}
