import {GlobalProvider} from '../../../../../business/global_provider';
import {Presenter} from '../../../../../support/presenter/presenter';
import {SustainabilityInfo} from '../../../../network/sustainability_api';

export class EnergeticSheetExplanationPresenter implements Presenter {
    public subscriptionInfo: SustainabilityInfo;

    constructor(globalProvider: GlobalProvider) {
        this.subscriptionInfo = globalProvider.global.sustainabilityInfo;
    }

    public mount() {
        // No-op
    }
}
