import {observer} from 'mobx-react';
import * as React from 'react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {simpleQuestionPresenterFactory, SimpleQuestionPresenterProps} from './simple_question_presenter';
import {TechnicalReference} from '../../../../../enum/technical_reference';
import {SetType} from '../../../../../models/reference_set/set_type';
import {QuestionRenderingDataType} from '../../../../../enum/question_rendering_data_type';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {ReferenceObjectQuestionRenderingData} from '../../../../../models/question_rendering_data';
import {QuestionContainer} from '../question_container';
import {createValidationMessagesMap} from '../../../../../../support/create_validation_messages_map';
import {QuestionLoader} from '../../../components/question_loader';

export const GroupReferenceObjectDetails: React.FC<SimpleQuestionPresenterProps> = observer(
    /**
     * This question is for specific use in the reference object details group, and shows or hides child questions
     * based on the set definition type. This information is received via the questionRenderingData property.
     */
    function GroupReferenceObjectDetails(props) {
        const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));
        if (presenter.isHidden) {
            return null;
        }

        const answer = presenter.answer;
        if (answer === undefined) {
            return <QuestionLoader />;
        }

        let childrenForQuestion = findChildrenForQuestion(props.question, props.questionSet);

        if (
            props.questionRenderingData?.type === QuestionRenderingDataType.REFERENCE_OBJECTS &&
            'setDefinitionType' in props.questionRenderingData
        ) {
            const questionRenderingData = props.questionRenderingData as ReferenceObjectQuestionRenderingData;
            childrenForQuestion = childrenForQuestion.filter((question) => {
                if (question.technicalReference === TechnicalReference.REFERENCE_OBJECT_VERHUUR_DETAILS_GROUP) {
                    return questionRenderingData.setDefinitionType === SetType.RENT;
                } else if (question.technicalReference === TechnicalReference.REFERENCE_OBJECT_VERKOOP_DETAILS_GROUP) {
                    return [SetType.SOLD, SetType.SALE].includes(questionRenderingData.setDefinitionType);
                }
                return true;
            });
        }

        return (
            <>
                {childrenForQuestion.map((child) => (
                    <QuestionContainer
                        key={child.uuid}
                        disabled={props.disabled}
                        appraisal={props.appraisal}
                        question={child}
                        onChange={props.onChange}
                        questionSet={props.questionSet}
                        validationErrors={props.validationErrors}
                        validationMessages={createValidationMessagesMap(
                            presenter.validationMessages,
                            props.validationMessages
                        )}
                        forceShowValidationMessages={props.forceShowValidationMessages}
                        parentAnswerUuid={answer.uuid}
                        adaptedDefaultValues={props.adaptedDefaultValues}
                        disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                        pagePartsSet={props.pagePartsSet}
                        activePagePart={props.activePagePart}
                        renderingContext={props.renderingContext}
                        questionRenderingData={props.questionRenderingData}
                    />
                ))}
            </>
        );
    }
);
