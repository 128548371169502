import {LayoutQuestionType, NormalQuestionType, QuestionType} from '../appraising/enum/question_type';
import {isRootQuestionType} from './is_root_question_type';

export function isReadOnlyQuestionType(questionType: QuestionType): boolean {
    return (
        isRootQuestionType(questionType) ||
        (
            [
                // Groups,
                NormalQuestionType.GROUP,
                NormalQuestionType.PAGE_PART_GROUP,
                NormalQuestionType.SYMLINK_LINK,
                NormalQuestionType.SYMLINK_TARGET,
                NormalQuestionType.SYMLINK_TARGET_COPY,
                NormalQuestionType.WIDGET_GROUP,
                NormalQuestionType.GROUP_COMPACT,
                NormalQuestionType.GROUP_COLLAPSIBLE,
                NormalQuestionType.FILES_GROUP,
                NormalQuestionType.BUILDING_COSTS_GROUP,
                NormalQuestionType.BUILDING_COSTS_PHOTO_GROUP,
                LayoutQuestionType.HORIZONTAL_GROUP,
                NormalQuestionType.COMPARE_GROUP,

                // Modals
                NormalQuestionType.MODAL,

                // Other
                LayoutQuestionType.QUESTION_ROW,
                LayoutQuestionType.QUESTION_ROW_COMPACT,
                NormalQuestionType.SEARCH,
                NormalQuestionType.BOUWKUNDIGE_STAAT_VIEWER,
                NormalQuestionType.CONSTRUCTION_SPECIFICATION_VIEWER,
                NormalQuestionType.AREA_SUMMARY,
                NormalQuestionType.HIDDEN,
                NormalQuestionType.DESCRIPTION,
                NormalQuestionType.DESCRIPTION_IMAGE,
                NormalQuestionType.ALERT_INLINE,
                NormalQuestionType.PHOTO_VIEWER,
            ] as QuestionType[]
        ).includes(questionType)
    );
}
