import * as React from 'react';

import {observer} from 'mobx-react';
import {
    simpleQuestionPresenterConstructorParametersFactory,
    SimpleQuestionPresenterProps,
} from '../../simple/simple_question_presenter';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {QuestionSet} from '../../../../../../models/question_set';
import {Appraisal} from '../../../../../../models/appraisal';
import {ValidationError} from '../../../../../../models/validation_error';
import {QuestionType} from '../../../../../../enum/question_type';
import {RenderingContextType} from '../../../../../../enum/rendering_context_type';
import {ProgressType} from '../../../camera_presenter';
import {DragDrop} from '@uppy/react';
import {PhotoProgressBar} from './photo_progress_bar';
import {Question} from '../../../../../../models/question';
import {SinglePhotoDropZonePresenter} from './single_photo_drop_zone_presenter';
import {classNames} from '../../../../../../../support/classnames';
import {Label} from '../../components/label';

interface OwnProps extends SimpleQuestionPresenterProps {
    question: Question;
    questionSet: QuestionSet;
    appraisal: Appraisal;
    fileTypes: string[];
    validationErrors: ValidationError[];
    hiddenQuestionTypes: QuestionType[];
    renderingContext: RenderingContextType;
}

export const SinglePhotoDropZoneQuestion: React.FC<OwnProps> = observer((props) => {
    const presenter = usePresenter(
        (container) =>
            new SinglePhotoDropZonePresenter(
                container.business.imageUploadInteractor(props.appraisal, props.questionSet),
                props.fileTypes,
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );

    return (
        <>
            {!presenter.isDisabled &&
                !props.disabled &&
                presenter.isAvailable &&
                presenter.progress.type === ProgressType.IDLE && (
                    <div className="form-group">
                        <Label
                            for={presenter.name}
                            question={props.question}
                            parentAnswerUuid={props.parentAnswerUuid}
                            iteration={props.iteration}
                            appraisal={props.appraisal}
                            questionSet={props.questionSet}
                            disabled={props.disabled || presenter.isDisabled}
                            disableHistory
                            hideLabel={props.hideLabel}
                            showLabel={props.question.showLabel}
                            renderingContext={props.renderingContext}
                        />
                        <div className="dropzone-container">
                            <DragDrop
                                className={classNames('dropzone', {'dropzone-preview': presenter.url !== null})}
                                width="100%"
                                height="100"
                                uppy={presenter.uppy}
                                allowMultipleFiles={false}
                                locale={{
                                    strings: {
                                        browse: 'blader',
                                        dropHereOr: 'Sleep hier je afbeelding naartoe of %{browse}',
                                    },
                                }}
                            />
                            {presenter.url && (
                                <img className="dropzone-preview-img" src={presenter.url} alt="preview" />
                            )}
                            {presenter.errorMessage != null && (
                                <span className="invalid-feedback">
                                    <div>{presenter.errorMessage}</div>
                                </span>
                            )}
                        </div>
                    </div>
                )}

            {!presenter.isDisabled &&
                !props.disabled &&
                presenter.isAvailable &&
                presenter.progress.type !== ProgressType.IDLE && (
                    <PhotoProgressBar percentage={presenter.progress.percentage} type={presenter.progress.type} />
                )}
        </>
    );
});
