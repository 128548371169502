import {DateFormat, parseDateToFormat} from '../../../support/format_date';
import {
    ValidationMessage,
    ValidationMessageImportance,
    ValidationMessageMap,
    ValidationMessageType,
} from '../../../../../business/validation/validation_message';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {SimpleQuestionPresenter} from './simple_question_presenter';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {isEmpty} from '../../../../../../support/util';

export class DateQuestionPresenter extends SimpleQuestionPresenter {
    @observable.ref private errorMessage: ValidationMessage | null = null;
    @observable private value: string | null = null;

    constructor(...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public mount(): void {
        super.mount();
    }

    public unmount(): void {
        super.unmount();
    }

    public get initialValue(): string {
        if (this.value === null) {
            return parseDateToFormat(this.answer?.contents ?? '', DateFormat.TAXAPI, DateFormat.PICKER) ?? '';
        }
        return this.value ?? '';
    }

    public onChange(value: string | undefined, setTouchedState = false) {
        runInAction(() => {
            this.value = value ?? null;
        });

        const formattedDate = parseDateToFormat(this.value ?? '', DateFormat.PICKER, DateFormat.TAXAPI);

        if (isEmpty(this.value)) {
            super.onChange('', setTouchedState);
        } else if (formattedDate !== undefined) {
            this.clearErrorMessage();
            super.onChange(formattedDate, setTouchedState);
        } else if (this.value.length >= 10) {
            this.setErrorMessage('De datum dient ingevuld te worden als "dd-mm-yyyy".');
        }
    }

    public allValidationMessages(validationMessagesMap: ValidationMessageMap): ValidationMessage[] {
        const validationMessages =
            filterValidationMessages(this.question, this.answer, validationMessagesMap, this.validationMessages) ?? [];
        if (this.errorMessage !== null) {
            validationMessages.push(this.errorMessage);
        }
        return validationMessages;
    }

    @action
    public setErrorMessage(error: string) {
        this.errorMessage = {
            type: ValidationMessageType.TAXAPI,
            importance: ValidationMessageImportance.WARNING,
            question: this.question,
            answer: this.answer ?? null,
            messages: [],
            fallbackMessage: error,
            floorInfo: null,
        };
    }

    @action
    public clearErrorMessage() {
        this.errorMessage = null;
    }
}
