import * as React from 'react';

import {Modal} from '../../content/questions/components/modal';
import {EnergeticSheet, EnergeticSheetProps} from './energetic_sheet';

export interface EnergeticSheetModalProps extends EnergeticSheetProps {
    onClose: () => void;
    onOpenExplanation: () => void;
}

export function EnergeticSheetModal({onClose, onOpenExplanation, ...restProps}: EnergeticSheetModalProps) {
    return (
        <Modal visible forced>
            <div className="modal-header">
                <span className="widget-overlay-title-icon ion-md-calculator">&nbsp;</span>
                <h2>Energetisch rapport</h2>
                <a
                    className="ion-md-help-circle-outline position-absolute"
                    style={{right: 0}}
                    onClick={onOpenExplanation}
                >
                    Meer uitleg
                </a>
            </div>
            <div className="modal-body">
                <EnergeticSheet {...restProps} />
            </div>
            <div className="modal-footer">
                <button
                    className="btn btn-primary btn-full ion-md-arrow-forward"
                    type="button"
                    onClick={() => onClose()}
                >
                    Sluiten
                </button>
            </div>
        </Modal>
    );
}
