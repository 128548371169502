import * as React from 'react';

import {Appraisal} from '../../../../../models/appraisal';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {SearchPresenter} from './search_presenter';
import {ValidationError} from '../../../../../models/validation_error';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps {
    appraisal: Appraisal;
    question: Question;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    iteration?: string;
    parentAnswerUuid?: string;
    adaptedDefaultValue?: string;
}

export const Search: React.FC<OwnProps> = observer(function Search(props) {
    const presenter = usePresenter(
        (container) => new SearchPresenter(props.question, container.business.searchInteractor(props.questionSet))
    );

    return (
        <input
            type="text"
            className="form-control"
            placeholder={props.question.contents}
            onChange={(e) => presenter.onChange(e.target.value)}
        />
    );
});
