import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PhotoSwipe from 'photoswipe/lightbox';
import type PhotoSwipeLightbox from 'photoswipe/dist/types/lightbox/lightbox';
import {AugmentedEvent, PhotoSwipeFiltersMap, SlideData, UIElementData} from 'photoswipe';
import {createPortal} from 'react-dom';
import {ThumbnailSlider} from './thumbnail_slider';
import {ImageSlider} from './image_slider';
import {ImageViewerContext} from './image_viewer_context';
import {SwiperRef} from 'swiper/react';

export interface ImageViewerComponent extends Omit<UIElementData, 'onInit'> {
    component?: React.ReactNode;
    name: string;
    onInit?(lightbox: PhotoSwipeLightbox): void;
}

export interface ImageViewerComponentProps {
    images?: ImageType[];
    lightbox?: PhotoSwipeLightbox;
}

export type ImageType = string | {url: string; uncompressedUrl?: string};

interface OwnProps {
    images?: ImageType[];
    components?: ImageViewerComponent[];
    hideThumbnailSlider?: boolean;
    openImmediateIndex?: number;
    children?: React.ReactNode;
    onOpened?: () => void;
    onClosed?: () => void;
}

interface ImageData extends SlideData {
    compressedSrc?: string | null;

    data?: ImageType;
}

// ImageViewer is the container component providing full screen image viewing functionality.
export function ImageViewer({
    children,
    images,
    hideThumbnailSlider,
    components,
    onOpened,
    onClosed,
    openImmediateIndex,
}: OwnProps) {
    const id = useMemo(() => {
        return `pswp-gallery-${Math.random().toString(36).substring(2, 9)}`;
    }, []);

    const getDataSource = useCallback(
        () =>
            images?.map<ImageData>((val) => ({
                src: typeof val === 'string' ? val : val.uncompressedUrl ?? val.url,
                compressedSrc: typeof val === 'string' || !val.uncompressedUrl ? null : val.url,
                data: val,
            })) ?? [],
        [images]
    );

    const lightbox = useMemo(() => {
        const dataSource = getDataSource();
        return new (PhotoSwipe as typeof PhotoSwipeLightbox)({
            gallery: '#' + id,
            dataSource,
            pswpModule: () => import('photoswipe'),
            getViewportSizeFn() {
                return {
                    x: window.innerWidth,
                    y: window.innerHeight - (hideThumbnailSlider ? 0 : 120),
                };
            },
            secondaryZoomLevel: 2,
            errorMsg: 'De afbeelding kon niet worden geladen.',
        });
    }, []);

    useEffect(() => {
        if (!lightbox.pswp) {
            return;
        }

        const dataSource = getDataSource();

        let index = 0;
        if (lightbox.pswp.currSlide?.data) {
            const srcIndex = dataSource.findIndex(
                (data) => data.src && data.src === lightbox.pswp?.currSlide?.data.src
            );
            if (srcIndex !== -1) {
                index = srcIndex;
            }
        }

        lightbox.preload(index, dataSource);
        lightbox.pswp.options.dataSource = dataSource;
        dataSource.forEach((data, i) => lightbox.pswp?.refreshSlideContent(i));
    }, [getDataSource]);

    const [componentElements, setComponentElements] = useState<Map<string, HTMLElement>>(new Map());

    useEffect(() => {
        const disposers: (() => void)[] = [];

        const register = (toRegister: ImageViewerComponent[]) => {
            for (const component of toRegister) {
                lightbox.pswp?.ui?.registerElement({
                    ...component,
                    onInit: (el) => {
                        setComponentElements((prev) => new Map(prev).set(component.name, el));

                        disposers.push(() => {
                            setComponentElements((prev) => {
                                const newMap = new Map(prev);
                                newMap.delete(component.name);
                                return newMap;
                            });

                            el.remove();
                        });

                        component.onInit?.(lightbox);
                    },
                });
            }
        };

        const toRegister = [...(components ?? [])];

        if (!hideThumbnailSlider) {
            toRegister.push({
                name: 'thumbnail-slider',
                className: 'image-viewer-slider',
                appendTo: 'wrapper',
            });
        }

        if (lightbox.pswp?.ui) {
            register(toRegister);
        } else {
            const uiRegister = () => {
                register(toRegister);
            };

            lightbox.on('uiRegister', uiRegister);

            disposers.push(() => lightbox.off('uiRegister', uiRegister));
        }

        return () => {
            disposers.forEach((disposer) => disposer());
        };
    }, [hideThumbnailSlider, lightbox]);

    const thumbRef = useRef<HTMLImageElement | null>();
    const sliderRef = useRef<SwiperRef>(null);

    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        // Disable native click event handler, as this doesn't work with the image_slider component
        const clickedIndex: PhotoSwipeFiltersMap['clickedIndex'] = () => -1;

        const thumbEl: PhotoSwipeFiltersMap['thumbEl'] = (orig, itemData: ImageData) => {
            if (
                thumbRef.current &&
                (thumbRef.current.src === itemData.src || thumbRef.current.src === itemData.compressedSrc)
            ) {
                return thumbRef.current;
            }
            return orig as HTMLElement;
        };

        lightbox.addFilter('clickedIndex', clickedIndex);
        lightbox.addFilter('thumbEl', thumbEl);

        const beforeOpen = () => {
            setIsShown(true);
            onOpened?.();
        };

        const close = () => {
            setIsShown(false);
            onClosed?.();
        };

        const onSlideActivate = () => {
            if (lightbox.pswp && sliderRef.current) {
                sliderRef.current.swiper.slideTo(lightbox.pswp.currIndex);
            }
        };

        const onLoadComplete = (e: AugmentedEvent<'loadComplete'>) => {
            if (e.content.element instanceof HTMLImageElement) {
                e.content.width = e.content.element.naturalWidth;
                e.content.height = e.content.element.naturalHeight;
                e.slide.width = e.content.element.naturalWidth;
                e.slide.height = e.content.element.naturalHeight;
                e.slide.pswp.updateSize(true);
            }
        };

        lightbox.on('beforeOpen', beforeOpen);
        lightbox.on('close', close);
        lightbox.on('slideActivate', onSlideActivate);
        lightbox.on('loadComplete', onLoadComplete);
        lightbox.on('contentLoadImage', (ev) => {
            if (ev.content.element instanceof HTMLImageElement) {
                ev.content.element.decoding = 'async';
                if (ev.isLazy) {
                    ev.content.element.loading = 'lazy';
                } else {
                    ev.content.element.loading = 'eager';
                }
            }
        });

        lightbox.init();

        return () => {
            lightbox.removeFilter('clickedIndex', clickedIndex);
            lightbox.removeFilter('thumbEl', thumbEl);
            lightbox.off('beforeOpen', beforeOpen);
            lightbox.off('close', close);
            lightbox.off('slideActivate', onSlideActivate);
            lightbox.off('loadComplete', onLoadComplete);
            lightbox.destroy();
        };
    }, [lightbox]);

    const portals = useMemo(() => {
        const result: React.ReactNode[] = [];

        const createComponent = (comp?: React.ReactNode) => {
            return React.Children.map(comp, (child) =>
                React.isValidElement(child)
                    ? React.cloneElement<ImageViewerComponentProps>(
                          child as React.ReactElement<ImageViewerComponentProps>,
                          {images, lightbox}
                      )
                    : child
            );
        };

        for (const [name, element] of componentElements) {
            const comp = components?.find((comp) => comp.name === name);
            if (comp) {
                result.push(createPortal(createComponent(comp.component), element, name));
            } else if (name === 'thumbnail-slider') {
                result.push(createPortal(createComponent(<ThumbnailSlider />), element, name));
            }
        }

        return result;
    }, [components, componentElements, images, lightbox]);

    const uncompressedUrls = useMemo(() => {
        return (
            images
                ?.map((val) => (typeof val === 'string' ? val : val.uncompressedUrl ?? val.url))
                .filter((val): val is string => val !== null) ?? []
        );
    }, [images]);

    const context = useMemo(
        () => ({
            open: (elOrIndex: HTMLImageElement | number) => {
                if (!images) {
                    return;
                }

                let index: number;
                if (typeof elOrIndex !== 'number') {
                    // Extract attribute to get unresolved url (in case of streetview URLs without domain specified)
                    const url = elOrIndex.getAttribute('src');
                    if (!url) {
                        return;
                    }

                    index = images.findIndex((img) =>
                        typeof img === 'string'
                            ? img === url
                            : img.url === url || (img.uncompressedUrl && img.uncompressedUrl === url)
                    );
                    if (index === -1) {
                        return;
                    }

                    thumbRef.current = elOrIndex;
                } else {
                    index = elOrIndex;
                }

                if (!lightbox.pswp) {
                    lightbox.loadAndOpen(index, getDataSource());
                    return;
                }

                if (lightbox.pswp.currIndex !== index) {
                    lightbox.pswp.goTo(index);
                }
            },
        }),
        [images, getDataSource, lightbox]
    );

    useEffect(() => {
        if (openImmediateIndex !== undefined) {
            context.open(openImmediateIndex);
        }
    }, []);

    return (
        <ImageViewerContext.Provider value={context}>
            <div className="pswp-gallery" id={id}>
                {children ?? <ImageSlider images={uncompressedUrls} ref={sliderRef} />}
                {isShown && portals}
            </div>
        </ImageViewerContext.Provider>
    );
}
