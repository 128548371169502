import Pikaday from 'pikaday';

/**
 * Pikaday date picker
 */
(() => {
    document.querySelectorAll('.date-picker').forEach((field) => {
        new Pikaday({
            field: field,
            format: 'DD-MM-YYYY',
            firstDay: 1,
            i18n: {
                previousMonth: 'Vorige maand',
                nextMonth: 'Volgende maand',
                months: [
                    'Januari',
                    'Februari',
                    'Maart',
                    'April',
                    'Mei',
                    'Juni',
                    'Juli',
                    'Augustus',
                    'September',
                    'Oktober',
                    'November',
                    'December',
                ],
                weekdays: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
                weekdaysShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
            },
        });
    });
})();
