import {observer} from 'mobx-react';
import React from 'react';
import NumberFormat from 'react-number-format';
import {isMobile} from '../../../../../../../support/check_mobile';
import {isEmpty, isNumeric} from '../../../../../../../support/util';
import {Question} from '../../../../../../models/question';
import {IntCompareQuestionPresenter} from './int_compare_question_presenter';

export const IntCompareQuestionInput = observer(
    ({
        presenter,
        question,
        disabled,
    }: {
        presenter: IntCompareQuestionPresenter;
        question: Question;
        disabled?: boolean;
    }) => {
        if (isMobile()) {
            return (
                <input
                    key={'int-compare-' + question.uuid}
                    id={presenter.name}
                    name={presenter.name}
                    className="form-control"
                    type="number"
                    step="1"
                    pattern="\d*"
                    inputMode="numeric"
                    disabled={disabled || presenter.isDisabled}
                    value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (isEmpty(value)) {
                            presenter.onChange(value, false);
                        } else if (isNumeric(value)) {
                            presenter.onChange(value, false);
                        }
                    }}
                    onBlur={() => presenter.onTouched()}
                />
            );
        }

        return (
            <NumberFormat
                key={'int-compare-' + question.uuid}
                value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                displayType="input"
                type="text"
                className="form-control"
                decimalScale={0}
                allowNegative={false}
                disabled={disabled || presenter.isDisabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (isEmpty(event.target.value)) {
                        presenter.onChange(event.target.value, false);
                    } else if (isNumeric(event.target.value)) {
                        presenter.onChange(event.target.value, false);
                    }
                }}
                onBlur={() => presenter.onTouched()}
            />
        );
    }
);
