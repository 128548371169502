import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from '../../simple/simple_question_presenter';

import {PagePart} from '../../../../../../models/page_part';
import {PagePartChildren} from './page_part_children';
import {PagePartsSet} from '../../../../../../models/page_parts_set';
import {QuestionContainer} from '../../question_container';
import {QuestionSet} from '../../../../../../models/question_set';
import {RenderingContextType} from '../../../../../../enum/rendering_context_type';
import {SortablePagePartChildren} from './sortable_page_part_children';
import {createValidationMessagesMap} from '../../../../../../../support/create_validation_messages_map';
import {findChildrenForQuestion} from '../../../../support/question_filtering';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {PagePartCustomizations} from './customizations/page_part_customizations';
import {QuestionLoader} from '../../../../components/question_loader';
import {SimpleTooltip} from '../../../../../../components/tooltip';
import {SuperMacros, hasSuperMacros} from '../../extensions/super_macros/super_macros';
import {classNames} from '../../../../../../../support/classnames';

interface OwnProps extends SimpleQuestionPresenterProps {
    questionSet: QuestionSet;
    activePagePart: PagePart;
    pagePartsSet: PagePartsSet;
    limitRenderedQuestions?: boolean;
    hideTitle?: boolean;
    title?: React.ReactNode;
}

export const PagePartGroup: React.FC<OwnProps> = observer(function PagePartGroup(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));

    if (presenter.isHidden && props.renderingContext !== RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
        return null;
    }

    const answer = presenter.answer;
    if (!answer) {
        return <QuestionLoader />;
    }

    let pagePartQuestions = findChildrenForQuestion(props.question, props.questionSet);
    const questionLimit = 5;
    if (
        (props.limitRenderedQuestions === true ||
            props.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR) &&
        pagePartQuestions.length > questionLimit
    ) {
        pagePartQuestions = pagePartQuestions.slice(0, questionLimit);
    }

    const superMacros = hasSuperMacros(props.question);

    const title =
        props.activePagePart !== undefined && props.hideTitle !== true && props.question.showLabel ? (
            <div>
                <h2>
                    {props.activePagePart.explanation != null && props.activePagePart.explanation.trim().length > 0 && (
                        <>
                            <SimpleTooltip content={props.activePagePart.explanation}>
                                <i className="ion-md-help-circle-outline" data-type="info" />
                            </SimpleTooltip>
                            &nbsp;
                        </>
                    )}
                    {props.title ?? props.question.contents}
                </h2>
            </div>
        ) : null;

    return (
        <>
            {props.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR && (
                <PagePartCustomizations
                    appraisal={props.appraisal}
                    question={props.question}
                    questionSet={props.questionSet}
                    renderingContext={props.renderingContext}
                    pagePartsSet={props.pagePartsSet}
                />
            )}
            <div>
                <div className={classNames('group-container', {'super-macro-group': superMacros})}>
                    {superMacros ? <SuperMacros {...props} title={title ?? undefined} /> : title}
                    <div className="group-duplication-content">
                        {presenter.duplicationSources.length > 0 && presenter.duplicationSource !== null && (
                            <div className="form-group">
                                <label htmlFor="duplication-target">Neem antwoorden over van:</label>
                                <div className="input-group">
                                    <select
                                        className="form-control custom-select"
                                        name="duplication-target"
                                        id="duplication-target"
                                        value={presenter.duplicationSource.answer.uuid}
                                        disabled={props.disabled}
                                        onChange={(e) => {
                                            const uuid = e.target.value;
                                            const target = presenter.duplicationSources.find(
                                                (s) => s && s.answer.uuid === uuid
                                            );
                                            if (target) {
                                                presenter.onDuplicationSourceChange(target);
                                            }
                                        }}
                                    >
                                        {presenter.duplicationSources.map((target) => {
                                            if (target === null) {
                                                return null;
                                            }

                                            return (
                                                <option key={target.answer.uuid} value={target.answer.uuid}>
                                                    {target.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <button
                                        className="btn btn-sm"
                                        title={
                                            presenter.duplicationSource !== null
                                                ? `Neem de antwoorden van ${presenter.duplicationSource.label} over.`
                                                : undefined
                                        }
                                        disabled={props.disabled}
                                        onClick={() => presenter.duplicateRecursively()}
                                    >
                                        Overnemen
                                    </button>
                                    <div className="separator"></div>
                                    <button
                                        className="btn btn-sm"
                                        title={`Wis alle antwoorden van ${props.question.contents}`}
                                        disabled={props.disabled ?? presenter.answer === undefined}
                                        onClick={() => presenter.clearRecursively()}
                                    >
                                        Wissen
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="group-container-content">
                        {pagePartQuestions.map((child) => (
                            <QuestionContainer
                                key={child.uuid + '-' + props.iteration}
                                disabled={props.disabled}
                                appraisal={props.appraisal}
                                question={child}
                                onChange={props.onChange}
                                questionSet={props.questionSet}
                                validationErrors={props.validationErrors}
                                validationMessages={createValidationMessagesMap(
                                    presenter.validationMessages,
                                    props.validationMessages
                                )}
                                parentAnswerUuid={answer.uuid}
                                adaptedDefaultValues={props.adaptedDefaultValues}
                                disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                                hiddenQuestionTypes={props.hiddenQuestionTypes}
                                pagePartsSet={props.pagePartsSet}
                                activePagePart={props.activePagePart}
                                renderingContext={props.renderingContext}
                                forceShowValidationMessages={props.forceShowValidationMessages}
                                questionRenderingData={props.questionRenderingData}
                            />
                        ))}
                        {props.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR && (
                            <div className="overflow-gradient" />
                        )}
                    </div>
                </div>
                {props.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR ? (
                    <SortablePagePartChildren
                        {...props}
                        validationMessages={createValidationMessagesMap(
                            presenter.validationMessages,
                            props.validationMessages
                        )}
                        parentAnswerUuid={answer.uuid}
                        pagePartUuid={props.activePagePart.uuid}
                    />
                ) : (
                    <PagePartChildren
                        {...props}
                        validationMessages={createValidationMessagesMap(
                            presenter.validationMessages,
                            props.validationMessages
                        )}
                        parentAnswerUuid={answer.uuid}
                        pagePartUuid={props.activePagePart.uuid}
                    />
                )}
            </div>
        </>
    );
});
