import {ReferenceObjectSortingStrategy} from './reference_object_sorting_strategy';
import {ReferenceSale} from '../../models/reference_sale';
import {SortingDirection} from '../../../../../../../../../enum/reference_objects_sorting';

export interface ReferenceSaleWithNormalizedSaleDate extends ReferenceSale {
    normalizedSaleDate: {
        year: number;
        month: number;
    };
}

export class ReferenceObjectSorterBySalesDate implements ReferenceObjectSortingStrategy {
    public sortReferenceObjects(referenceSales: ReferenceSale[], sortingDirection: SortingDirection): ReferenceSale[] {
        // make array of the reference sales with a valid normalized sale date
        const referenceSalesWithNormalizedSaleDate = referenceSales.filter(
            (referenceSale: ReferenceSale): referenceSale is ReferenceSaleWithNormalizedSaleDate =>
                referenceSale.normalizedSaleDate !== null
        );

        // make array of the reference sale without a normalized sales date
        const referenceSalesWithoutNormalizedSaleDate = referenceSales.filter(
            (referenceSale: ReferenceSale) => referenceSale.normalizedSaleDate === null
        );

        // sort the reference objects with a valid normalized sale date
        const sortedReferenceObjects: ReferenceSale[] = referenceSalesWithNormalizedSaleDate.sort(
            (referenceSale1, referenceSale2) => {
                return this.getSaleDateForReferenceSaleWithNormalizedSaleDate(referenceSale1) >
                    this.getSaleDateForReferenceSaleWithNormalizedSaleDate(referenceSale2)
                    ? sortingDirection === SortingDirection.DESCENDING
                        ? -1
                        : 1
                    : sortingDirection === SortingDirection.DESCENDING
                    ? 1
                    : -1;
            }
        );

        // put the invalid reference sales at the end
        return [...sortedReferenceObjects, ...referenceSalesWithoutNormalizedSaleDate];
    }

    private getSaleDateForReferenceSaleWithNormalizedSaleDate = (
        referenceObject: ReferenceSaleWithNormalizedSaleDate
    ): Date => {
        return new Date(referenceObject.normalizedSaleDate.year, referenceObject.normalizedSaleDate.month, 1);
    };
}
