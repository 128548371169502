import * as React from 'react';

import {SidebarIcons, humanizeSidebarIcon, isSidebarIcon, classNameSidebarIcon} from '../../../../enums/sidebar_icons';

import {FunctionComponent} from 'react';
import {Modal} from '../../../appraise/ui/content/questions/components/modal';
import {Select} from '../../../appraise/ui/components/select';
import {observer} from 'mobx-react-lite';

export interface NewRootPageState {
    name: string;
    isIndexPage: boolean;
    icon: SidebarIcons;
}

interface OwnProps {
    newRootPageState: NewRootPageState;
    onChange: (value: NewRootPageState) => void;
    onHide: () => void;
    onSave: (name: NewRootPageState) => void;
}

export const NewRootPageModal: FunctionComponent<OwnProps> = observer(
    ({newRootPageState, onChange, onHide, onSave}) => {
        return (
            <Modal visible={true} onHide={() => onHide()}>
                <div className="modal-header">
                    <h2>Nieuwe pagina</h2>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="new-page-name">Naam</label>
                                <input
                                    name="new-page-name"
                                    type="text"
                                    className="form-control"
                                    value={newRootPageState.name}
                                    onChange={(e) =>
                                        onChange({
                                            ...newRootPageState,
                                            name: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <input
                                    id="new-page-is-index"
                                    name="new-page-is-index"
                                    type="checkbox"
                                    checked={newRootPageState.isIndexPage === true}
                                    onChange={(e) =>
                                        onChange({
                                            ...newRootPageState,
                                            isIndexPage: e.target.checked,
                                        })
                                    }
                                />
                                <label htmlFor="new-page-is-index" className="col-form-label">
                                    Pagina is een overzichtspagina met onderliggende paginas
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <label htmlFor="new-page-icon">Icoon</label>
                            <Select
                                id="new-page-icon"
                                value={newRootPageState.icon}
                                placeHolder="Selecteer een icon voor de pagina"
                                isDisabled={false}
                                onChange={(newValue) => {
                                    if (newValue && isSidebarIcon(newValue)) {
                                        onChange({
                                            ...newRootPageState,
                                            icon: newValue,
                                        });
                                    }
                                }}
                                isClearable={false}
                                options={Object.values(SidebarIcons).map((icon) => ({
                                    label: humanizeSidebarIcon(icon),
                                    value: icon,
                                    icon: classNameSidebarIcon(icon),
                                }))}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-secondary" onClick={() => onHide()}>
                        Sluiten
                    </button>
                    <button className="btn btn-primary" onClick={() => onSave(newRootPageState)}>
                        Opslaan
                    </button>
                </div>
            </Modal>
        );
    }
);
