import {AutomatorDispatchGroup} from '../../create_appraisal/preferences/appraisal_costs';
import {AppraisalApi} from '../network/appraisal_api';
import {AnswerInteractor} from './answering/answer_interactor';
import {TaskHelper} from './task_helper';

export interface AutomatorInteractor {
    triggerAutomatorGroup(appraisalId: number, group: AutomatorDispatchGroup): Promise<void>;

    isLoading(group: AutomatorDispatchGroup): boolean;
    poll(group: AutomatorDispatchGroup): Promise<void>;
}

export class DefaultAutomatorInteractor implements AutomatorInteractor {
    private promises = new Map<AutomatorDispatchGroup, Promise<void>>();

    constructor(
        private appraisalApi: AppraisalApi,
        private taskHelper: TaskHelper,
        private answerInteractor: AnswerInteractor
    ) {}

    public async triggerAutomatorGroup(appraisalId: number, group: AutomatorDispatchGroup) {
        const {tasks} = await this.appraisalApi.triggerAutomatorGroup(appraisalId, group);

        const promise = Promise.all(tasks.map((task) => this.taskHelper.poll(task.task_id)))
            .then(
                () => this.answerInteractor.submit(true) // This will load new answers created by the automator
            )
            .then(() => {
                if (this.promises.get(group) === promise) {
                    this.promises.delete(group);
                }
            });

        this.promises.set(group, promise);

        await promise;
    }

    public isLoading(group: AutomatorDispatchGroup) {
        return this.promises.has(group);
    }

    public async poll(group: AutomatorDispatchGroup) {
        const promise = this.promises.get(group);
        if (promise) {
            await promise;
        }
    }
}
