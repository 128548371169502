import * as Uuid from 'uuid';

import {computed, makeObservable, observable} from 'mobx';

import {Answer} from '../../models/answer';
import {AnswerApi} from '../../network/answer_api';
import {Appraisal} from '../../models/appraisal';
import {ModelValuesAnswerDetails} from '../models/model_values_details_answer';
import {ModelValuesDetails} from '../models/model_values_details';
import {Presenter} from '../../../support/presenter/presenter';
import {ServerTimeProvider} from '../../server_time/server_time_provider';
import {ValidationInstituteReferentieObject} from '../../models/validation_institute_reference_object';

interface ModelWaardeDetail {
    modelwaardeLeverancier: string;
    referentieObjecten: Array<ValidationInstituteReferentieObject>;
}

export class ModelValuesPresenter implements Presenter {
    @computed
    public get submitButtonEnabled(): boolean {
        if (this.getNumberOfSources() >= 2) {
            return this.numberSelectedReferenceObjects() >= 4 && this.usesAllSources();
        }
        return this.numberSelectedReferenceObjects() >= 3;
    }

    @computed
    public get instruction() {
        if (this.getNumberOfSources() >= 2) {
            return 'Kies ten minste 4 objecten uit meer dan 1 bron.';
        }
        return 'Kies ten minste 3 objecten.';
    }

    @observable public submitting = false;
    @observable public error = false;
    @observable.ref private _answer: Answer;
    private _modelValuesDetails: ModelValuesDetails;
    private _modelValuesAnswerDetails: ModelValuesAnswerDetails[];
    private _appraisal: Appraisal;
    private _answerApi: AnswerApi;
    private _serverTimeProvider: ServerTimeProvider;

    constructor(
        appraisal: Appraisal,
        answer: Answer,
        modelValuesDetails: ModelValuesDetails,
        modelValuesAnswerDetails: ModelValuesAnswerDetails[],
        answerApi: AnswerApi,
        serverTimeProvider: ServerTimeProvider
    ) {
        makeObservable(this);
        this._appraisal = appraisal;
        this._serverTimeProvider = serverTimeProvider;
        this._answer = this.prepare(answer);
        this._modelValuesDetails = modelValuesDetails;
        this._modelValuesAnswerDetails = modelValuesAnswerDetails;
        this._answerApi = answerApi;
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    private prepare(answer: Answer): Answer {
        const contents = JSON.parse(answer.contents as string).modelWaardeDetails.map(
            (modelWaardeDetail: ModelWaardeDetail) => {
                return {
                    ...modelWaardeDetail,
                    referentieObjecten: modelWaardeDetail.referentieObjecten.map((referentieObject) => {
                        return {
                            _uuid: Uuid.v4(),
                            ...referentieObject,
                        };
                    }),
                };
            }
        );

        return {
            ...answer,
            updatedAt: this._serverTimeProvider.date,
            contents: JSON.stringify({modelWaardeDetails: contents}),
        };
    }

    @computed
    public get referenceObjectList() {
        if (this._answer.contents === null) {
            return null;
        }

        return JSON.parse(this._answer.contents as string).modelWaardeDetails.map(
            (modelWaardeDetail: ModelWaardeDetail) => {
                return {
                    ...modelWaardeDetail,
                    referentieObjecten: modelWaardeDetail.referentieObjecten.map(
                        (referentieObject: ValidationInstituteReferentieObject) => {
                            const referenceObjectFromAnswer = this.findReferenceObjectFromAnswer(
                                modelWaardeDetail,
                                referentieObject
                            );
                            if (referenceObjectFromAnswer) {
                                referentieObject.streetviewUrl = referenceObjectFromAnswer.streetviewUrl;
                            }
                            return referentieObject;
                        }
                    ),
                };
            }
        );
    }

    public onChange(changedReferentieObject: ValidationInstituteReferentieObject): ValidationInstituteReferentieObject {
        const contents = JSON.parse(this._answer.contents as string).modelWaardeDetails.map(
            (modelWaardeDetail: ModelWaardeDetail) => {
                return {
                    ...modelWaardeDetail,
                    referentieObjecten: modelWaardeDetail.referentieObjecten.map((referentieObject) => {
                        if (referentieObject._uuid === changedReferentieObject._uuid) {
                            return changedReferentieObject;
                        }
                        return referentieObject;
                    }),
                };
            }
        );

        this._answer = {
            ...this._answer,
            updatedAt: this._serverTimeProvider.date,
            contents: JSON.stringify({modelWaardeDetails: contents}),
        };

        return changedReferentieObject;
    }

    public async onSubmit() {
        await this._answerApi.storeAnswers(this._appraisal.id, [this._answer]);
    }

    public async store() {
        try {
            this.submitting = true;
            this.error = false;
            await this._answerApi.storeAnswers(this._appraisal.id, [this._answer]);
            window.location.href = `/appraisals/${this._appraisal.id}/validation/submit`;
        } catch (e) {
            console.error(e);
            this.error = true;
        } finally {
            this.submitting = false;
        }
    }

    private getListOfReferenceObjectsFromAnswer(): ValidationInstituteReferentieObject[] {
        if (this._answer.contents === null) {
            return [];
        }

        return JSON.parse(this._answer.contents).modelWaardeDetails.reduce(
            (
                p: ValidationInstituteReferentieObject[],
                modelWaardeDetail: {referentieObjecten: ValidationInstituteReferentieObject[]}
            ) => {
                return [...p, ...modelWaardeDetail.referentieObjecten];
            },
            []
        );
    }

    private numberSelectedReferenceObjects() {
        return this.getListOfReferenceObjectsFromAnswer().filter((item) => item._selected).length;
    }

    private usesAllSources() {
        const sources = this.getReferenceObjectsPerSource();

        return !sources.some((source) => {
            return source.numSelectedObjects === 0;
        });
    }

    private getNumberOfSources() {
        return this.getReferenceObjectsPerSource().length;
    }

    private getReferenceObjectsPerSource() {
        return this.getListOfReferenceObjectsFromAnswer().reduce(
            (p: Array<{source: string; numSelectedObjects: number}>, c: ValidationInstituteReferentieObject) => {
                if (!p.some((source) => source.source === c.bronGegevens)) {
                    p.push({
                        source: c.bronGegevens || '',
                        numSelectedObjects: 0,
                    });
                }

                return p.map((item) => {
                    if (item.source === c.bronGegevens && c._selected) {
                        item.numSelectedObjects++;
                    }

                    return item;
                });
            },
            []
        );
    }

    private findReferenceObjectFromAnswer(
        currentModelValuesAnswerDetails: ModelWaardeDetail,
        currentReferenceObject: ValidationInstituteReferentieObject
    ): ValidationInstituteReferentieObject | null | undefined {
        const answerModelWaardeDetailsForSource = this._modelValuesAnswerDetails.find((modelWaardeDetail) => {
            return modelWaardeDetail.source === currentModelValuesAnswerDetails.modelwaardeLeverancier;
        });

        if (!answerModelWaardeDetailsForSource) {
            return null;
        }

        return answerModelWaardeDetailsForSource.reference_objects.find(
            (referenceObject: ValidationInstituteReferentieObject) => {
                return (
                    currentReferenceObject.adres.postcode === referenceObject.adres.postcode &&
                    currentReferenceObject.adres.huisnummer === referenceObject.adres.huisnummer &&
                    currentReferenceObject.adres.huisnummerToevoeging === referenceObject.adres.huisnummerToevoeging
                );
            }
        );
    }
}
