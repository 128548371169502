import * as React from 'react';

import {AnswerImagePresenter} from './answer_image_presenter';
import {Appraisal} from '../../../../models/appraisal';
import {QuestionSet} from '../../../../models/question_set';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {PhotoAnswerWithFile} from '../../../../business/photo_answer_retry_interactor';

export interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    photoAnswerWithFile: PhotoAnswerWithFile;
}

export const AnswerImage: React.FC<OwnProps> = observer(function AnswerImage(props) {
    const presenter = usePresenter(() => new AnswerImagePresenter(props.photoAnswerWithFile));

    const url = presenter.url;
    if (!url) {
        return null;
    }

    return <div className="image-container" style={{backgroundImage: `url(${url})`}} />;
});
