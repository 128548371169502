import {AjaxDriver} from '../../network/driver/ajax_driver';
import {FileReference} from '../models/file_reference';
import {Sustainability} from '../models/sustainability';
import {ApiFile, apiFileToFile} from './models/api_file';
import {ApiSustainability, apiSustainabilityToSustainability} from './models/api_sustainability';

export enum SustainabilitySubscriptionFrequency {
    SINGLE = 'single',
    MONTHLY = 'monthly',
}

export interface ApiSustainabilityInfo {
    active_subscription_frequency: SustainabilitySubscriptionFrequency | null;
    single_costs_cents: number;
    monthly_costs_cents: number;
    total_usage_count: number;
    appraisal_usage_count: number;
}

export interface SustainabilityInfo {
    activeSubscriptionFrequency: SustainabilitySubscriptionFrequency | null;
    singleCostsCents: number;
    monthlyCostsCents: number;
    totalUsageCount: number;
    appraisalUsageCount: number;
}

export type SustainabilityResponse = {sustainability: Sustainability | null; subscription: SustainabilityInfo};
export type SustainabilityReportResponse = {report: FileReference | null; subscription: SustainabilityInfo};

export interface SustainabilityApi {
    getSustainabilityInfo(appraisalId: number, options: {[key: string]: unknown}): Promise<SustainabilityResponse>;

    getSustainabilityReport(
        appraisalId: number,
        options: {[key: string]: unknown}
    ): Promise<SustainabilityReportResponse>;
}

export class DefaultSustainabilityApi implements SustainabilityApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public getSustainabilityInfo(
        appraisalId: number,
        options: {[key: string]: unknown}
    ): Promise<SustainabilityResponse> {
        return new Promise<SustainabilityResponse>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/sustainability/request`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        options,
                    }),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: {sustainability: ApiSustainability | null; subscription: ApiSustainabilityInfo} =
                            await result.json();
                        if (!data) {
                            reject();
                        } else {
                            resolve({
                                sustainability: data.sustainability
                                    ? apiSustainabilityToSustainability(data.sustainability)
                                    : null,
                                subscription: {
                                    activeSubscriptionFrequency: data.subscription.active_subscription_frequency,
                                    appraisalUsageCount: data.subscription.appraisal_usage_count,
                                    monthlyCostsCents: data.subscription.monthly_costs_cents,
                                    singleCostsCents: data.subscription.single_costs_cents,
                                    totalUsageCount: data.subscription.total_usage_count,
                                },
                            });
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getSustainabilityReport(
        appraisalId: number,
        options: {[key: string]: unknown}
    ): Promise<SustainabilityReportResponse> {
        return new Promise<SustainabilityReportResponse>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/sustainability/report`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        options,
                    }),
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: {report: ApiFile | null; subscription: ApiSustainabilityInfo} = await result.json();
                        if (!data) {
                            reject();
                        } else {
                            resolve({
                                report: data.report ? apiFileToFile(data.report) : null,
                                subscription: {
                                    activeSubscriptionFrequency: data.subscription.active_subscription_frequency,
                                    appraisalUsageCount: data.subscription.appraisal_usage_count,
                                    monthlyCostsCents: data.subscription.monthly_costs_cents,
                                    singleCostsCents: data.subscription.single_costs_cents,
                                    totalUsageCount: data.subscription.total_usage_count,
                                },
                            });
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
