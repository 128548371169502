import {DefaultSurfaceAreaProvider, SurfaceAreaProvider} from './surface_area_provider';
import {QuestionSet} from '../../models/question_set';
import {Appraisal} from '../../models/appraisal';
import {BusinessComponent} from '../business_component';

export interface SupportComponent {
    surfaceAreaProvider(questionSet: QuestionSet, appraisal: Appraisal): SurfaceAreaProvider;
}

export class DefaultSupportComponent implements SupportComponent {
    constructor(private businessComponent: BusinessComponent) {}

    public surfaceAreaProvider(questionSet: QuestionSet, appraisal: Appraisal): SurfaceAreaProvider {
        const answersController = this.businessComponent.answerController(appraisal, questionSet);
        const questionEffectInteractor = this.businessComponent.questionEffectsInteractor(appraisal, questionSet);

        return new DefaultSurfaceAreaProvider(questionSet, answersController, questionEffectInteractor);
    }
}
