import {ReferenceObjectAnswer, referenceObjectAnswerFromSale} from '../../models/reference_object_answer';
import {computed, makeObservable, observable} from 'mobx';

import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {ReferenceObjectProvider} from '../../../../../../../../../business/reference_object_provider';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';

export class AddCustomReferenceObjectModalPresenter implements Presenter {
    @observable public postalCode = '';
    @observable public houseNumber = '';
    @observable public letter = '';
    @observable public hasError = false;
    @observable public errorMessage = '';
    @observable public submitting = false;

    @computed
    public get isDisabled(): boolean {
        return this.submitting;
    }

    constructor(
        private propsOnAddAndOpenModal: (referenceObjectAnswer: ReferenceObjectAnswer) => void,
        private referenceObjectsAnswers: ReferenceObjectAnswer[],
        private referenceObjectInteractor: ReferenceObjectProvider,
        private setType: SetType
    ) {
        makeObservable(this);
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public async onSubmitClick() {
        try {
            this.submitting = true;
            this.hasError = false;
            this.errorMessage = '';

            const sale = await this.referenceObjectInteractor.getSaleByAddress(
                this.postalCode,
                this.houseNumber,
                this.letter,
                this.setType
            );

            const existingInSet = this.referenceObjectsAnswers.find(
                (a) =>
                    a.referenceObject.adres.postcode === sale.postalCode &&
                    a.referenceObject.adres.huisnummer === sale.houseNumber &&
                    a.referenceObject.adres.huisnummerToevoeging === sale.letter
            );

            if (!existingInSet) {
                this.propsOnAddAndOpenModal(referenceObjectAnswerFromSale(sale));
            } else {
                this.hasError = true;
                this.errorMessage =
                    'De referentieverkoop die je probeert toe te voegen is al toegevoegd aan deze waardering.';
            }
        } catch (e) {
            this.hasError = true;
            this.errorMessage =
                'Er kon in het register geen referentieverkoop gevonden worden voor het ingevoerde adres.';
        } finally {
            this.submitting = false;
        }
    }

    public onPostalCodeChange(value: string) {
        this.postalCode = value;
    }

    public onHouseNumberChange(value: string) {
        this.houseNumber = value;
    }

    public onLetterChange(value: string) {
        this.letter = value;
    }
}
