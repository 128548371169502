import {AttachmentProps} from '../../appraise/ui/content/questions/advanced/attachment_question_presenter';
import {UploadInteractor} from './upload_interactor';
import {UploadResult} from '@uppy/core';

export class RetryUploadInteractor implements UploadInteractor {
    constructor(private uploadInteractor: UploadInteractor) {}

    public async upload(
        file: File,
        options: {
            fileTypes?: string[];
            progressCallback?: (progress: number) => void;
            metaData?: Record<string, unknown>;
        }
    ): Promise<UploadResult<AttachmentProps>> {
        let uploadResult: UploadResult<AttachmentProps> = {
            successful: [],
            failed: [],
        };

        for (let i = 0; i < 5; i++) {
            uploadResult = await this.uploadInteractor.upload(file, options);
            if (uploadResult.successful.length > 0) {
                return uploadResult;
            }
        }

        return uploadResult;
    }
}
