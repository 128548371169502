import {CompositeSubscription} from '../../../../../../../support/composite_subscription';
import {Presenter} from '../../../../../../../support/presenter/presenter';
import {PagePartsSetInteractor} from '../../../../../../business/page_parts/page_parts_set_interactor';

export class SortablePagePartChildrenPresenter implements Presenter {
    private subscriptions = new CompositeSubscription();

    constructor(private pagePartsInteractor: PagePartsSetInteractor) {}

    public mount() {
        //Noop
    }

    public async unmount() {
        this.subscriptions.clear();
    }

    public onPagePartRankUpdate = (pagePartSetId: number, pagePartUuid: string, newRank: number) => {
        this.pagePartsInteractor.updatePagePartsRanks(pagePartSetId, [{uuid: pagePartUuid, rank: newRank}]);
    };

    public onRemovePagePart = (pagePartSetId: number, pagePartUuid: string) => {
        this.pagePartsInteractor.removePagePart(pagePartSetId, pagePartUuid);
    };

    public onChangeExplanation = (pagePartSetId: number, pagePartUuid: string, explanation: string) => {
        this.pagePartsInteractor.updatePagePartsExplanation(pagePartSetId, pagePartUuid, explanation);
    };
}
