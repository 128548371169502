import {AdaptedDefaultValuesMap} from '../../../../../../../models/adapted_default_values_map';
import {AdaptedValueProvider} from '../../../../../../../business/adapted_values/adapted_value_provider';
import {AnswerController} from '../../../../../../../business/answering/answer_controller';
import {BuildYearProvider} from '../../../../../../../business/build_year_provider';
import {CompositeSubscription} from '../../../../../../../../support/composite_subscription';
import {ExtraReferenceSaleSet} from './models/reference_sale';
import {NormalQuestionType} from '../../../../../../../enum/question_type';
import {PlotAreaProvider} from '../../../../../../../business/plot_area_provider';
import {Presenter} from '../../../../../../../../support/presenter/presenter';
import {Question} from '../../../../../../../models/question';
import {QuestionSet} from '../../../../../../../models/question_set';
import {ReferenceSet} from './models/reference_set';
import {SurfaceAreaProvider} from '../../../../../../../business/support/surface_area_provider';
import {TechnicalReference} from '../../../../../../../enum/technical_reference';
import {observable, makeObservable} from 'mobx';

export interface ValuationAnswerWithQuestion {
    id: string | null;
    question: Question;
    parentAnswerUuid: string;
    iteration: string | null;
}

export interface QuestionWithAreaConclusion {
    question: Question;
    areaConclusionNumber: number | null;
}

export class ValuationAnswersContainerPresenter implements Presenter {
    @observable public surfaceArea: number | null = null;
    @observable public plotArea: number | null = null;
    @observable public buildYear: number | null = null;
    @observable public buildYearDestruction: number | null = null;
    private subscriptions = new CompositeSubscription();

    constructor(
        private answerController: AnswerController,
        private questionSet: QuestionSet,
        private adaptedValueProvider: AdaptedValueProvider,
        private parentContainerTechnicalReference: TechnicalReference,
        private plotAreaProvider: PlotAreaProvider,
        private surfaceAreaProvider: SurfaceAreaProvider,
        private buildYearProvider: BuildYearProvider
    ) {
        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            this.plotAreaProvider.plotArea().subscribe((sum) => (this.plotArea = sum ? Math.round(sum) : null))
        );
        this.subscriptions.add(
            this.surfaceAreaProvider.surfaceArea().subscribe((sum) => (this.surfaceArea = sum ? Math.round(sum) : null))
        );
        this.subscriptions.add(this.buildYearProvider.stream().subscribe((buildYear) => (this.buildYear = buildYear)));
        this.subscriptions.add(
            this.buildYearProvider
                .streamDestructionYear()
                .subscribe((buildYearDestruction) => (this.buildYearDestruction = buildYearDestruction))
        );
    }

    public unmount(): void {
        /* Noop */
    }

    public valuationAnswerWithQuestions(
        extraReferenceSalesSets: ExtraReferenceSaleSet[]
    ): ValuationAnswerWithQuestion[] {
        const result: ValuationAnswerWithQuestion[] = [];
        for (const extraReferenceSalesSet of extraReferenceSalesSets) {
            const answerWithQuestion = this.getValuationAnswerWithQuestion(extraReferenceSalesSet.id);
            if (answerWithQuestion !== null) {
                result.push(answerWithQuestion);
            }
        }
        return result;
    }

    public defaultAdaptedAnswers(defaultSet: ReferenceSet) {
        return this.buildDefaultAdaptedAnswers(defaultSet);
    }

    private getValuationAnswerWithQuestion(uuid: string | null) {
        if (uuid === null) {
            return null;
        }
        const valuationAnswer = this.answerController.byUuid(uuid);
        if (valuationAnswer?.parentUuid != null) {
            const iteratorAnswer = this.answerController.byUuid(valuationAnswer.parentUuid);
            if (iteratorAnswer !== null) {
                const groupQuestion = this.findGroupQuestion(iteratorAnswer.questionUuid);
                if (groupQuestion !== null) {
                    return {
                        id: uuid,
                        question: groupQuestion,
                        parentAnswerUuid: iteratorAnswer.uuid,
                        iteration: iteratorAnswer.iteration,
                    };
                }
            }
        }
        return null;
    }

    private findGroupQuestion(uuid: string) {
        return (
            this.questionSet
                .findQuestionsByTechnicalReference(this.parentContainerTechnicalReference)
                .find((q) => q.parentUuid === uuid) ?? null
        );
    }

    public baseValuationQuestions(referenceSet: ReferenceSet | null): QuestionWithAreaConclusion[] {
        // All groups with the parent container technical reference have the same structure.
        // Just pick one of them to show the same structure for the market value properties.
        const parent = this.questionSet.findQuestionByTechnicalReference(this.parentContainerTechnicalReference);
        if (parent) {
            return this.questionSet
                .findChildQuestionsByParentUuid(parent.uuid)
                .map((c) => this.questionSet.findChildQuestionsByParentUuid(c.uuid))
                .map((qq) => qq.filter((q) => q.type === NormalQuestionType.NUMBER))
                .map((q) => q)
                .flatMap((qq) =>
                    qq.map((q) => {
                        return {
                            question: q,
                            areaConclusionNumber: this.areaConclusionByTechnicalReference(
                                referenceSet,
                                q.technicalReference
                            ),
                        } as QuestionWithAreaConclusion;
                    })
                );
        }
        return [];
    }

    private areaConclusionByTechnicalReference(
        referenceSet: ReferenceSet | null,
        technicalReference: string | null
    ): number {
        if (referenceSet === null || referenceSet.areaConclusion === null) {
            return 0;
        }
        switch (technicalReference) {
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_GEBRUIKSOPPERVLAKTE_WONEN:
            case TechnicalReference.MARKET_VALUE_ARGUMENT_GEBRUIKSOPPERVLAKTE_WONEN:
                return this.surfaceArea ?? referenceSet.areaConclusion.gebruiksoppervlakteWonen ?? 0;
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_GEBOUWGEBONDEN_BUITENRUIMTE:
            case TechnicalReference.MARKET_VALUE_ARGUMENT_GEBOUWGEBONDEN_BUITENRUIMTE:
                return referenceSet.areaConclusion.gebouwgebondenBuitenruimte ?? 0;
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_OVERIGE_INPANDIGE_RUIMTE:
            case TechnicalReference.MARKET_VALUE_ARGUMENT_OVERIGE_INPANDIGE_RUIMTE:
                return referenceSet.areaConclusion.overigeInpandigeRuimte ?? 0;
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_EXTERNE_BERGRUIMTE:
            case TechnicalReference.MARKET_VALUE_ARGUMENT_EXTERNE_BERGRUIMTE:
                return referenceSet.areaConclusion.externeBergruimte ?? 0;
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_PERCEEL_OPPERVLAKTE:
            case TechnicalReference.MARKET_VALUE_ARGUMENT_PERCEEL_OPPERVLAKTE:
                return this.plotArea ?? referenceSet.areaConclusion.perceelOppervlakte ?? 0;
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_BRUTO_INHOUD:
            case TechnicalReference.MARKET_VALUE_ARGUMENT_BRUTO_INHOUD:
                return referenceSet.areaConclusion.brutoInhoud ?? 0;
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_BUILD_YEAR:
                return this.buildYear ?? referenceSet.buildYear ?? 0;
            case TechnicalReference.MARKET_VALUE_ARGUMENT_BUILD_YEAR:
                if (this.buildYearDestruction) {
                    return this.buildYearDestruction;
                }
                return this.buildYear ?? referenceSet.buildYear ?? 0;
            default:
                return 0;
        }
    }

    private buildDefaultAdaptedAnswers(defaultSet: ReferenceSet): AdaptedDefaultValuesMap {
        const technicalReferences = [
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_BUILD_YEAR,
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_BRUTO_INHOUD,
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_GEBRUIKSOPPERVLAKTE_WONEN,
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_GEBOUWGEBONDEN_BUITENRUIMTE,
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_OVERIGE_INPANDIGE_RUIMTE,
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_EXTERNE_BERGRUIMTE,
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_PERCEEL_OPPERVLAKTE,
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_ENERGY_LABEL,
            TechnicalReference.ADAPTED_VALUE_BIJGEBOUWEN,
            TechnicalReference.ADAPTED_VALUE_LIGGING,
            TechnicalReference.ADAPTED_VALUE_ONDERHOUDSITUATIE,
            TechnicalReference.ADAPTED_VALUE_ONDERHOUDSITUATIE_TOELICHTING,
            TechnicalReference.ADAPTED_VALUE_LUXE_EN_DOELMATIGHEID,
            TechnicalReference.ADAPTED_VALUE_KWALITEIT_EN_CONTITIE,
            TechnicalReference.ADAPTED_VALUE_GETAXEERDE_OBJECT_TOELICHTING_TAXATEUR,
            TechnicalReference.ADAPTED_VALUE_GETAXEERDE_OBJECT_AANBOUW_TOELICHTING_TAXATEUR,
        ];

        return this.adaptedValueProvider.get(
            technicalReferences,
            defaultSet.areaConclusion,
            defaultSet.energyConclusion
        );
    }
}
