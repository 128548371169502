import {Answer} from '../../../../../../../models/answer';
import {AnswerController} from '../../../../../../../business/answering/answer_controller';
import {CompositeSubscription} from '../../../../../../../../support/composite_subscription';
import {ExtraReferenceSaleSet} from './models/reference_sale';
import {Presenter} from '../../../../../../../../support/presenter/presenter';
import {QuestionSet} from '../../../../../../../models/question_set';
import {ReferenceObjectAnswer} from './models/reference_object_answer';
import {ReferenceSet} from './models/reference_set';
import {TechnicalReference} from '../../../../../../../enum/technical_reference';
import {isEmpty} from '../../../../../../../../support/util';
import {observable, makeObservable} from 'mobx';

export interface ReferenceSetOption {
    setId: string | null;
    name: string;
    valuation: string | null;
    selectedCount: number;
}

export interface ValuationFromAnswer {
    name: string;
    valuation: string;
}

export class ReferenceSetSelectorPresenter implements Presenter {
    @observable public isOpen = false;
    @observable.ref private specialValuationAnswers: Answer[] = [];

    private _activateReferenceSalesSet: (referenceSaleSetId: string | null) => void;
    private subscriptions = new CompositeSubscription();

    constructor(
        activateReferenceSalesSet: (referenceSaleSetId: string | null) => void,
        private answerController: AnswerController,
        private questionSet: QuestionSet
    ) {
        makeObservable(this);
        this._activateReferenceSalesSet = activateReferenceSalesSet;
    }

    public mount(): void {
        const specialValuationQuestions = this.questionSet.findQuestionsByTechnicalReference(
            TechnicalReference.SPECIAL_VALUE_ARGUMENT_PRICE
        );
        if (specialValuationQuestions.length > 0) {
            this.subscriptions.add(
                this.answerController
                    .answersForQuestionUuidsStream(specialValuationQuestions.map((q) => q.uuid))
                    .subscribe((valuationAnswers) => {
                        this.specialValuationAnswers = valuationAnswers;
                    })
            );
        }
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public minReferenceObjects = 3;

    public onSelect(referenceSaleSetId: string | null) {
        this._activateReferenceSalesSet(referenceSaleSetId);
        this.isOpen = false;
    }

    public onToggle(isOpen: boolean) {
        this.isOpen = isOpen;
    }

    public selectedReferenceObjectsCount(
        referenceObjectAnswers: ReferenceObjectAnswer[],
        referenceSaleSetId: string | null
    ): number {
        return referenceObjectAnswers.filter((answer: ReferenceObjectAnswer) => answer.setId === referenceSaleSetId)
            .length;
    }

    public buildOptions(
        defaultSet: ReferenceSet | null,
        extraReferenceSalesSets: ExtraReferenceSaleSet[],
        referenceObjectAnswers: ReferenceObjectAnswer[]
    ): ReferenceSetOption[] {
        const options: ReferenceSetOption[] = [];
        if (defaultSet !== null) {
            options.push({
                setId: defaultSet.id,
                name: defaultSet.name,
                valuation: defaultSet.valuation,
                selectedCount: this.selectedReferenceObjectsCount(referenceObjectAnswers, defaultSet.id),
            });
        }
        extraReferenceSalesSets.map((extraSet) => {
            const valuationFromAnswer = this.findValuationFromAnswer(extraSet);
            options.push({
                setId: extraSet.id,
                name: valuationFromAnswer.name,
                valuation: valuationFromAnswer.valuation,
                selectedCount: this.selectedReferenceObjectsCount(referenceObjectAnswers, extraSet.id),
            });
        });
        return options;
    }

    private findValuationFromAnswer(extraSet: ExtraReferenceSaleSet): ValuationFromAnswer {
        const result = {
            name: extraSet.name,
            valuation: extraSet.valuation,
        };

        if (this.specialValuationAnswers.length > 0) {
            const activeSetValuationAnswer = this.specialValuationAnswers.find((a) => a.uuid === extraSet.id);
            if (activeSetValuationAnswer) {
                const valuationQuestion = this.questionSet.findQuestionByUuid(activeSetValuationAnswer.questionUuid);
                if (valuationQuestion) {
                    result.name = !isEmpty(valuationQuestion.contents) ? valuationQuestion.contents : '0';
                }
                if (activeSetValuationAnswer.contents) {
                    result.valuation = activeSetValuationAnswer.contents;
                }
            }
        }

        return result;
    }
}
