import {Appraisal} from '../models/appraisal';
import {TaskHelper} from './task_helper';
import {
    ApiAdaptedDefaultValue,
    apiAdaptedDefaultValueToAdaptedDefaultValue,
} from '../network/models/api_adapted_default_value';
import {AdaptedDefaultValuesApi} from '../network/adapted_default_values_api';
import {AdaptedDefaultValue} from '../models/adapted_default_value';

export interface AdaptedDefaultValuesInteractor {
    getAdaptedDefaultValues(questionUuids: string[]): Promise<AdaptedDefaultValue[]>;
}

export class DefaultAdaptedDefaultValuesInteractor implements AdaptedDefaultValuesInteractor {
    constructor(
        private appraisal: Appraisal,
        private adaptedDefaultValuesApi: AdaptedDefaultValuesApi,
        private taskHelper: TaskHelper
    ) {}

    public async getAdaptedDefaultValues(questionUuids: string[]): Promise<AdaptedDefaultValue[]> {
        const adaptedDefaultValues = await this.adaptedDefaultValuesApi.getAdaptedDefaultValues(
            this.appraisal.id,
            questionUuids
        );
        if (!TaskHelper.isTaskReference(adaptedDefaultValues)) {
            return adaptedDefaultValues;
        }

        const result = await this.taskHelper.poll<ApiAdaptedDefaultValue[]>(adaptedDefaultValues.taskId);

        if (result !== null) {
            return result.map((value) => apiAdaptedDefaultValueToAdaptedDefaultValue(value));
        }

        throw new Error('No result');
    }
}
