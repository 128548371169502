import {BuildPeriodType} from '../../enum/build_period_type';
import {ObjectType} from '../../enum/object_type';
import {AppraisalGoal} from '../../enum/appraisal_goal';
import {MacroSettings} from '../../models/macro_settings';

export interface ApiMacroSettings {
    id: number;
    user_id: number;
    macro_id: number;
    hidden: boolean;
    favorite: boolean;
    district_codes?: string[] | null;
    build_periods?: BuildPeriodType[] | null;
    object_types?: ObjectType[] | null;
    appraisal_goals?: AppraisalGoal[] | null;
    new_construction?: boolean | null;
}

export function apiMacroSettingsToMacroSettings(apiMacroSettings: ApiMacroSettings): MacroSettings {
    return {
        id: apiMacroSettings.id,
        userId: apiMacroSettings.user_id,
        macroId: apiMacroSettings.macro_id,
        hidden: apiMacroSettings.hidden,
        favorite: apiMacroSettings.favorite,
        districtCodes: apiMacroSettings.district_codes ?? null,
        buildPeriods: apiMacroSettings.build_periods ?? null,
        objectTypes: apiMacroSettings.object_types ?? null,
        appraisalGoals: apiMacroSettings.appraisal_goals ?? null,
        newConstruction: apiMacroSettings.new_construction ?? null,
    };
}
