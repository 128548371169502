import {AltumReferenceObjectSettings} from '../../../../../../../../../create_appraisal/pre_check/altum_reference_objects';
import {ReferenceObjectTypeSettings} from '../../../../../../../../../create_appraisal/pre_check/altum_reference_objects/object_type';
import {AltumReferenceObjectSettingsGraph} from '../../../../../../../../../create_appraisal/pre_check/altum_reference_objects/graph';
import React from 'react';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../../../../support/presenter/use_presenter';
import {AltumSettings} from '../../../../../../../../network/preselected_reference_objects_api';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {Appraisal} from '../../../../../../../../models/appraisal';
import {AltumPreferencesPresenter} from './altum_preferences_presenter';
import {Loader} from '../../../../../../components/loader';
import {classNames} from '../../../../../../../../../support/classnames';

interface OwnProps {
    appraisal?: Appraisal;
    questionSet?: QuestionSet;
    updatePreferences?: (preferences: AltumSettings) => void;

    forceRequest: (preferences: AltumSettings) => void;
    isInModal: boolean;
    changeObjectType?: () => void;
}

export const AltumPreferences: React.FC<OwnProps> = observer(
    ({appraisal, questionSet, forceRequest, isInModal, updatePreferences, changeObjectType}) => {
        const presenter = usePresenter(
            (container) =>
                new AltumPreferencesPresenter(
                    container.business.appraisalProvider,
                    container.network.referenceObjectApi,
                    appraisal && questionSet ? container.business.buildYearProvider(appraisal, questionSet) : undefined,
                    appraisal && questionSet
                        ? container.business.surfaceAreaProvider(appraisal, questionSet)
                        : undefined
                )
        );

        const handlePreferencesChange = (newPreferences: AltumSettings) => {
            presenter.updatePreferences(newPreferences);
            if (updatePreferences) {
                updatePreferences(newPreferences);
            }
        };

        if (presenter.preferences === null) {
            return (
                <div className="d-flex justify-content-center align-items-center">
                    <Loader addSpace={false} />
                </div>
            );
        }

        return (
            <>
                {changeObjectType && (
                    <div className="empty-message__preferences px-2 d-flex justify-content-end">
                        <button className="btn btn-primary btn-sm" onClick={changeObjectType}>
                            Objecttype aanpassen
                        </button>
                    </div>
                )}
                <div className={classNames('row', 'empty-message__preferences')}>
                    <div
                        className={classNames(
                            {'col-12 col-lg-6 col-xl-5 pl-0 order-2': isInModal},
                            {'col-12 col-lg-8 col-xl-8 order-2': !isInModal}
                        )}
                    >
                        <AltumReferenceObjectSettings
                            value={presenter.preferences}
                            onChange={handlePreferencesChange}
                            givenValues={{
                                buildYear: presenter.buildYear?.toString(),
                                surfaceArea: presenter.surfaceArea ?? undefined,
                            }}
                            showVisualSimilarity={false}
                        />
                    </div>
                    <div
                        className={classNames(
                            {'col-12 col-lg-6 col-xl-3 order-3': isInModal},
                            {'col-12 col-lg-4 col-xl-4 order-2': !isInModal}
                        )}
                    >
                        <ReferenceObjectTypeSettings
                            value={presenter.preferences}
                            onChange={handlePreferencesChange}
                            givenValues={{
                                type: appraisal?.objectType ?? undefined,
                            }}
                        />
                    </div>
                    {isInModal && (
                        <div className="col-12 col-xl-4 order-1 order-xl-4">
                            <p>
                                Personaliseer de opties voor de referentieobjecten. De voorkeuren worden gebruikt om de
                                referentieobjecten op te halen voor deze taxatie.{' '}
                                <a href="/account/settings/altum" target="_blank">
                                    Klik hier
                                </a>{' '}
                                om de voorkeuren voor alle taxaties aan te passen.
                            </p>

                            <div className="d-none d-xl-block">
                                <AltumReferenceObjectSettingsGraph value={presenter.preferences} />
                            </div>
                        </div>
                    )}
                    {!isInModal && (
                        <div className="col-12 d-flex justify-content-end order-5 mt-3">
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                    presenter.savePreferences().then(() => {
                                        if (presenter.preferences) {
                                            forceRequest(presenter.preferences);
                                        }
                                    })
                                }
                            >
                                {presenter.saving ? <Loader /> : 'Nieuwe referenties ophalen'}
                            </button>
                        </div>
                    )}
                </div>
            </>
        );
    }
);
