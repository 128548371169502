import {BehaviorSubject, Observable, combineLatest} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';

import {AnswerController} from './answering/answer_controller';
import {AnswerInteractor} from './answering/answer_interactor';
import {QuestionSet} from '../models/question_set';

export interface UnsyncedAnswerRetryInteractor {
    numUnsyncedAnswers: Observable<number>;

    ignore(): void;
    attempt(): Observable<number>;
}

export class DefaultUnsyncedAnswerRetryInteractor implements UnsyncedAnswerRetryInteractor {
    constructor(
        private questionSet: QuestionSet,
        private answerController: AnswerController,
        private answerInteractor: AnswerInteractor
    ) {}

    private ignoreUnsyncedAnswersObservable = new BehaviorSubject(false);

    public get numUnsyncedAnswers(): Observable<number> {
        return combineLatest(this.ignoreUnsyncedAnswersObservable, this.getUnsyncedAnswers()).pipe(
            map(([ignore, unsyncedAnswers]) => {
                if (ignore) {
                    return 0;
                }
                return unsyncedAnswers.length;
            })
        );
    }

    public ignore(): void {
        this.ignoreUnsyncedAnswersObservable.next(true);
    }

    public attempt(): Observable<number> {
        this.answerInteractor.submit();
        return this.numUnsyncedAnswers;
    }

    private getUnsyncedAnswers() {
        return this.answerController.answersStream().pipe(
            debounceTime(1500),
            map((answers) => answers.filter((answer) => answer.changed)),
            map((answers) => this.answerController.filterDeleted(answers))
        );
    }
}
