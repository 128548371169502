import * as React from 'react';

import {NotificationMessage} from '../models/notification_message';
import {NotificationsStackPresenter} from './notifications_stack_presenter';
import {classNames} from '../../support/classnames';
import {observer} from 'mobx-react';
import {usePresenter} from '../support/use_presenter';

interface OwnProps {}

export const NotificationsStack: React.FunctionComponent<OwnProps> = observer(() => {
    const presenter = usePresenter(
        (container) =>
            new NotificationsStackPresenter(container.notificationsStackInteractor, container.notificationsInteractor)
    );

    return (
        <>
            {presenter.notifications.map((n: NotificationMessage) => {
                return (
                    <div
                        className={classNames('floater floater-information floater-slide-in-notification', {
                            'd-none': n.dismissedAt !== null,
                        })}
                        key={n.id}
                    >
                        <span className="row">
                            <span className="col-12">
                                <p>
                                    <span className="ion-md-notifications"></span> {n.subject}
                                </p>
                            </span>
                            <span className="col-12">
                                <small>
                                    {n.content.substring(0, 100)} {n.content.length > 100 ? '...' : null}
                                </small>
                            </span>
                            <span className="col-12">
                                <span className="floater-footer d-flex justify-content-between">
                                    <button onClick={() => presenter.onHide(n.id)}>Verbergen</button>
                                    <button onClick={() => presenter.onShowModal(n.id)}>Bekijken</button>
                                </span>
                            </span>
                        </span>
                    </div>
                );
            })}
        </>
    );
});
