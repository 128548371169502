import React from 'react';
import {
    ApiReferenceSalesData,
    apiReferenceSalesDataToReferenceSalesData,
} from '../network/models/api_reference_sales_data';
import {ApiSale, apiSaleToSale} from '../network/models/api_sale';
import {
    ExtraReferenceSaleSet,
    ReferenceSale,
} from '../appraise/ui/content/questions/advanced/reference_objects_question/v1/models/reference_sale';
import {FlashMessageBroadcaster, Type} from './flash_message_broadcaster';

import {AnswerInteractor} from './answering/answer_interactor';
import {Appraisal} from '../models/appraisal';
import {AreaConclusion} from '../models/area_conclusion';
import {EnergyConclusion} from '../models/energy_conclusion';
import {ReferenceObjectApi, ReferenceObjectsFlashMessageError} from '../network/reference_objects_api';
import {ReferenceSetFilters} from '../models/reference_set/reference_set_filters';
import {Sale} from '../models/sale';
import {SetType} from '../models/reference_set/set_type';
import {TaskHelper} from './task_helper';
import {ReferenceSubscriptions, ReferenceSubscriptionType} from '../models/reference_subscriptions';
import {bugsnagClient} from '../../support/bugsnag_client';

export interface ReferenceSaleSetRequestData {
    appraisalId: number;
    setType: SetType;
    valuationTypeUuid: string;
    buildYear: number;
    livingArea: number;
    plotArea: number | null;
    filters?: ReferenceSetFilters;
    forceUseSubscriptions?: ReferenceSubscriptionType[];
}

export interface ReferenceSalesData {
    energyConclusion: EnergyConclusion;
    areaConclusion: AreaConclusion;
    defaultReferenceSalesRequired: boolean;
    refreshCredentials: boolean;
    buildYear: number | null;
    referenceSales: ReferenceSale[];
    extraReferenceSalesSets: ExtraReferenceSaleSet[];
}

export interface ReferenceSaleSetData {
    valuationTypeUuid: string;
    refreshCredentials: ReferenceSubscriptionType[];
    referenceSales: ReferenceSale[];
    subscriptions: ReferenceSubscriptions | null;
    taskId: number | null;
}

export interface ReferenceObjectProvider {
    getReferenceSales(): Promise<ReferenceSalesData>;

    getReferenceSalesSet(data: ReferenceSaleSetRequestData): Promise<ReferenceSaleSetData | null>;

    getSale(id: string, setType: SetType): Promise<Sale>;

    getSaleByAddress(
        postalCode: string,
        houseNumber: string,
        letter: string,
        type: SetType,
        fetchSaleDetails?: boolean
    ): Promise<Sale>;

    enhanceHighlightedReferenceSale(id: string, setData: ReferenceSaleSetRequestData): Promise<ReferenceSale | null>;

    getEnhancedReferenceSale(
        id: string,
        source: string | null,
        setData: ReferenceSaleSetRequestData
    ): Promise<ReferenceSale | null>;
}

export class DefaultReferenceObjectProvider implements ReferenceObjectProvider {
    private _appraisal: Appraisal;
    private _referenceObjectApi: ReferenceObjectApi;
    private _taskHelper: TaskHelper;
    private _answerInteractor: AnswerInteractor;

    constructor(
        appraisal: Appraisal,
        referenceObjectApi: ReferenceObjectApi,
        taskHelper: TaskHelper,
        answerInteractor: AnswerInteractor,
        private _flashMessageBroadcaster: FlashMessageBroadcaster
    ) {
        this._appraisal = appraisal;
        this._referenceObjectApi = referenceObjectApi;
        this._taskHelper = taskHelper;
        this._answerInteractor = answerInteractor;
    }

    public async getReferenceSales(): Promise<ReferenceSalesData> {
        // Make sure the latest answers are used
        await this._answerInteractor.submit();

        const referenceSales = await this._referenceObjectApi.getReferenceSales(this._appraisal.id);

        if ('refreshCredentials' in referenceSales) {
            if (referenceSales.refreshCredentials) {
                this._flashMessageBroadcaster.broadcast(
                    'Niet alle gewenste koppelingen zijn op dit moment actief. Gelieve de gegevens in het account updaten naar de laatste versie',
                    Type.Danger
                );
            }
        }

        if (!TaskHelper.isTaskReference(referenceSales)) {
            return referenceSales;
        }

        const result = await this._taskHelper.poll<ApiReferenceSalesData>(referenceSales.taskId);

        if (result !== null) {
            return apiReferenceSalesDataToReferenceSalesData(result);
        }

        throw new Error('No result');
    }

    public async getReferenceSalesSet(data: ReferenceSaleSetRequestData): Promise<ReferenceSaleSetData | null> {
        let referenceSales: ReferenceSaleSetData | null = null;

        try {
            referenceSales = await this._referenceObjectApi.getReferenceSalesSet(data);
        } catch (e) {
            console.error('Error while fetching reference sale set', e);
            bugsnagClient?.notify(e, {
                metaData: {
                    origin: 'reference_objects_provider.ts getReferenceSalesSet()',
                },
            });
            this._flashMessageBroadcaster.broadcast(
                'Er is iets mis gegaan bij het ophalen van de referentieobjecten.',
                Type.Danger
            );

            return null;
        }

        for (const subscriptionType of referenceSales.refreshCredentials) {
            switch (subscriptionType) {
                case ReferenceSubscriptionType.BRAINBAY: {
                    this._flashMessageBroadcaster.broadcast(
                        <>
                            De NVM-koppeling is op dit moment niet actief. In je{' '}
                            <a href="/account/settings#nvm" target="_blank">
                                account-instellingen
                            </a>{' '}
                            kun je de koppeling (opnieuw) activeren.
                        </>,
                        Type.Danger
                    );
                    break;
                }
                default: {
                    this._flashMessageBroadcaster.broadcast(
                        'Niet alle gewenste koppelingen zijn op dit moment actief. Gelieve de gegevens in het account updaten naar de laatste versie',
                        Type.Danger
                    );
                }
            }
        }

        return referenceSales;
    }

    public async getSale(id: string, setType: SetType): Promise<Sale> {
        try {
            const sale = await this._referenceObjectApi.getSale(this._appraisal.id, id, setType);

            if (!TaskHelper.isTaskReference(sale)) {
                return sale;
            }

            const result = await this._taskHelper.poll<ApiSale>(sale.taskId);
            if (result !== null) {
                return apiSaleToSale(result);
            }

            throw new Error('No result');
        } catch (e) {
            if (e instanceof ReferenceObjectsFlashMessageError) {
                this._flashMessageBroadcaster.broadcast(e.flashMessage, Type.Danger);
            }

            throw e;
        }
    }

    public async getSaleByAddress(
        postalCode: string,
        houseNumber: string,
        letter: string,
        type: SetType,
        fetchSaleDetails = false
    ): Promise<Sale> {
        try {
            const saleByAddress = await this._referenceObjectApi.getSaleByAddress(
                this._appraisal.id,
                postalCode,
                houseNumber,
                letter,
                type,
                fetchSaleDetails
            );

            if (!TaskHelper.isTaskReference(saleByAddress)) {
                return saleByAddress;
            }

            const result = await this._taskHelper.poll<ApiSale>(saleByAddress.taskId);
            if (result !== null) {
                return apiSaleToSale(result);
            }

            throw new Error('No result');
        } catch (e) {
            if (e instanceof ReferenceObjectsFlashMessageError) {
                this._flashMessageBroadcaster.broadcast(e.flashMessage, Type.Danger);
            }

            throw e;
        }
    }

    public async enhanceHighlightedReferenceSale(
        id: string,
        setData: ReferenceSaleSetRequestData
    ): Promise<ReferenceSale | null> {
        return this._referenceObjectApi.enhanceHighlightedReferenceSale(id, setData);
    }

    public async getEnhancedReferenceSale(
        id: string,
        source: string | null,
        data: ReferenceSaleSetRequestData
    ): Promise<ReferenceSale | null> {
        return this._referenceObjectApi.getEnhancedReferenceSale(this._appraisal.id, id, source, data);
    }
}
