import * as React from 'react';

import {observer} from 'mobx-react';
import {MacroSettingsPayloadType, DraftMacroSettings} from './macro_settings_presenter';
import {BuildPeriodType} from '../../../../enum/build_period_type';
import {ObjectType} from '../../../../enum/object_type';
import {AppraisalGoal, appraisalGoalText} from '../../../../enum/appraisal_goal';
import {classNames} from '../../../../../support/classnames';
import {District} from '../../../../models/district';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {MacroSettingsFormPresenter} from './macro_settings_form_presenter';

export interface MacroSettingsFormProps {
    macroSettings: DraftMacroSettings;
    isLoading: boolean;
    onChangeNewConstruction: (value: boolean | null) => void;
    onNameChange: (value: string) => void;
    onAddDistrict: (value: string) => void;
    onRemoveDistrict: (value: string) => void;
    onAddBuildPeriod: (value: BuildPeriodType) => void;
    onRemoveBuildPeriod: (value: BuildPeriodType) => void;
    onAddObjectType: (value: ObjectType) => void;
    onRemoveObjectType: (value: ObjectType) => void;
    onAddAppraisalGoal: (value: AppraisalGoal) => void;
    onRemoveAppraisalGoal: (value: AppraisalGoal) => void;
}

export const MacroSettingsForm: React.FC<MacroSettingsFormProps> = observer((props) => {
    const presenter = usePresenter(
        (container) => new MacroSettingsFormPresenter(container.globalProvider.global.userDistricts)
    );
    const newConstructionForm = (
        <div className="row">
            <div className="col-12">
                <h3 className="heading-loader">Nieuwbouw of bestaande bouw</h3>
            </div>
            <div className="col-12 col-md-4">
                <input
                    type="radio"
                    id="new-construction"
                    checked={props.macroSettings.newConstruction === true}
                    onChange={() => props.onChangeNewConstruction(true)}
                />
                <label htmlFor="new-construction">Nieuwbouw</label>
            </div>
            <div className="col-12 col-md-4">
                <input
                    type="radio"
                    id="existing-construction"
                    checked={props.macroSettings.newConstruction === false}
                    onChange={() => props.onChangeNewConstruction(false)}
                />
                <label htmlFor="existing-construction">Bestaand</label>
            </div>
            <div className="col-12 col-md-4">
                <input
                    type="radio"
                    id="both-construction"
                    checked={props.macroSettings.newConstruction === null}
                    onChange={() => props.onChangeNewConstruction(null)}
                />
                <label htmlFor="both-construction">Beide</label>
            </div>
        </div>
    );

    const buildPeriods = props.macroSettings.buildPeriods;
    const buildPeriodsForm = (
        <div className="row">
            <div className="col-12">
                <h3 className="heading-loader">Bouwperioden</h3>
            </div>
            <div className="col-12">
                {buildPeriods !== null && buildPeriods !== undefined
                    ? buildPeriods.map((type) => (
                          <div className="form-group" key={type}>
                              <div className="input-group append-btn">
                                  <div className="form-control form-control-empty">
                                      {(type === BuildPeriodType.BELOW_1906
                                          ? 'Voor '
                                          : type === BuildPeriodType.AFTER_2020
                                          ? 'Na '
                                          : '') + type}
                                  </div>
                                  <div className="input-group-append">
                                      <button
                                          className="btn btn-append"
                                          onClick={() => props.onRemoveBuildPeriod(type)}
                                      >
                                          <span className="ion-md-trash"></span>
                                      </button>
                                  </div>
                              </div>
                          </div>
                      ))
                    : null}

                <div className="form-group">
                    <select
                        className="form-control custom-select"
                        onChange={(e) => props.onAddBuildPeriod(e.target.value as BuildPeriodType)}
                    >
                        <option>Selecteer een bouwperiode</option>
                        {Object.entries(BuildPeriodType).map(([key, value]) => {
                            return props.macroSettings.buildPeriods?.includes(value) ? null : (
                                <option value={value} key={key}>
                                    {(value === BuildPeriodType.BELOW_1906
                                        ? 'Voor '
                                        : value === BuildPeriodType.AFTER_2020
                                        ? 'Na '
                                        : '') + value}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
        </div>
    );

    const objectTypes = props.macroSettings.objectTypes;
    const objectTypesForm = (
        <div className="row">
            <div className="col-12">
                <h3 className="heading-loader">Object types</h3>
            </div>
            <div className="col-12">
                {objectTypes !== null && objectTypes !== undefined
                    ? objectTypes?.map((type) => (
                          <div className="form-group" key={type}>
                              <div className="input-group append-btn">
                                  <div className="form-control form-control-empty">{type}</div>
                                  <div className="input-group-append">
                                      <button className="btn btn-append" onClick={() => props.onRemoveObjectType(type)}>
                                          <span className="ion-md-trash"></span>
                                      </button>
                                  </div>
                              </div>
                          </div>
                      ))
                    : null}

                <div className="form-group">
                    <select
                        className="form-control custom-select"
                        onChange={(e) => props.onAddObjectType(e.target.value as ObjectType)}
                    >
                        <option>Selecteer een object type</option>
                        {Object.entries(ObjectType).map(([key, value]) =>
                            props.macroSettings.objectTypes?.includes(value) ? null : (
                                <option value={value} key={key}>
                                    {value}
                                </option>
                            )
                        )}
                    </select>
                </div>
            </div>
        </div>
    );

    const appraisalGoals = props.macroSettings.appraisalGoals;
    const appraisalGoalsForm = (
        <div className="row">
            <div className="col-12">
                <h3 className="heading-loader">Taxatiedoelen</h3>
            </div>
            <div className="col-12">
                {appraisalGoals !== null && appraisalGoals !== undefined
                    ? appraisalGoals?.map((type) => (
                          <div className="form-group" key={type}>
                              <div className="input-group append-btn">
                                  <div className="form-control form-control-empty">{appraisalGoalText(type)}</div>
                                  <div className="input-group-append">
                                      <button
                                          className="btn btn-append"
                                          onClick={() => props.onRemoveAppraisalGoal(type)}
                                      >
                                          <span className="ion-md-trash"></span>
                                      </button>
                                  </div>
                              </div>
                          </div>
                      ))
                    : null}

                <div className="form-group">
                    <select
                        className="form-control custom-select"
                        onChange={(e) => props.onAddAppraisalGoal(e.target.value as AppraisalGoal)}
                    >
                        <option>Selecteer een taxatiedoel</option>
                        {Object.entries(AppraisalGoal).map(([key, value]) =>
                            props.macroSettings.appraisalGoals?.includes(value) ? null : (
                                <option value={value} key={key}>
                                    {appraisalGoalText(value)}
                                </option>
                            )
                        )}
                    </select>
                </div>
            </div>
        </div>
    );

    const districtCodes = props.macroSettings.districtCodes;
    const districtsForm = (
        <div className="row">
            <div className="col-12">
                <h3 className="heading-loader">Werkgebied</h3>
            </div>
            <div className="col-12">
                {districtCodes !== null && districtCodes !== undefined
                    ? districtCodes
                          ?.map((code) => presenter.districts.find((d) => d.code === code) ?? null)
                          .map((district: District | null) =>
                              district === null ? null : (
                                  <div className="form-group" key={district.code}>
                                      <div className="input-group append-btn">
                                          <div className="form-control form-control-empty">
                                              {district.name} ({district.quarter})
                                          </div>
                                          <div className="input-group-append">
                                              <button
                                                  className="btn btn-append"
                                                  onClick={() => props.onRemoveDistrict(district.code)}
                                              >
                                                  <span className="ion-md-trash"></span>
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              )
                          )
                    : null}

                <div className="form-group">
                    <select
                        className="form-control custom-select"
                        onChange={(e) => props.onAddDistrict(e.target.value)}
                    >
                        <option>Selecteer een werkgebied</option>
                        {presenter.districts.map((district) =>
                            props.macroSettings.districtCodes?.includes(district.code) ? null : (
                                <option value={district.code} key={district.code}>
                                    {district.name} ({district.quarter})
                                </option>
                            )
                        )}
                    </select>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {props.macroSettings.type === MacroSettingsPayloadType.UNPERSISTED_SUPER && (
                <div className={classNames('form-group', {'form-group-loading': props.isLoading})}>
                    <div className="col-12">
                        <div className="row">
                            <label htmlFor="macro-name">Benaming</label>
                            <input
                                className="form-control"
                                type="text"
                                id="macro-name"
                                value={props.macroSettings.name}
                                onChange={(e) => props.onNameChange(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className={classNames('form-group', {'form-group-loading': props.isLoading})}>
                {newConstructionForm}
            </div>
            <div className={classNames('form-group', {'form-group-loading': props.isLoading})}>{buildPeriodsForm}</div>
            <div className={classNames('form-group', {'form-group-loading': props.isLoading})}>{objectTypesForm}</div>
            {presenter.districts.length > 0 ? (
                <div className={classNames('form-group', {'form-group-loading': props.isLoading})}>{districtsForm}</div>
            ) : null}
            <div className={classNames('form-group', {'form-group-loading': props.isLoading})}>
                {appraisalGoalsForm}
            </div>
        </>
    );
});
