import * as React from 'react';

import {ObjectType} from '../../../../../enum/object_type';
import {isEmpty} from '../../../../../../support/util';

interface OwnProps {
    value: string | null | undefined;
    disabled?: boolean;
    onChange: (objectType: ObjectType) => void;
}

export class ObjectTypeSelect extends React.Component<OwnProps> {
    public render() {
        return (
            <select
                value={isEmpty(this.props.value) ? '-1' : this.props.value}
                onChange={(e) => this.props.onChange(e.target.value as ObjectType)}
                disabled={this.props.disabled}
                className="form-control custom-select"
                name="object_type"
                data-live-search="true"
                autoComplete="off"
            >
                <option disabled value="-1">
                    - Maak een keuze -
                </option>
                <optgroup label="Woonhuis">
                    <option key={ObjectType.TWEE_ONDER_EEN_KAPWONING} value={ObjectType.TWEE_ONDER_EEN_KAPWONING}>
                        2-onder-1-kapwoning
                    </option>
                    <option key={ObjectType.EINDWONING} value={ObjectType.EINDWONING}>
                        Eindwoning
                    </option>
                    <option
                        key={ObjectType.GESCHAKELDE_TWEE_ONDER_EEN_KAPWONING}
                        value={ObjectType.GESCHAKELDE_TWEE_ONDER_EEN_KAPWONING}
                    >
                        Geschakelde 2-onder-1-kapwoning
                    </option>
                    <option key={ObjectType.GESCHAKELDE_WONING} value={ObjectType.GESCHAKELDE_WONING}>
                        Geschakelde woning
                    </option>
                    <option key={ObjectType.HALFVRIJSTAANDE_WONING} value={ObjectType.HALFVRIJSTAANDE_WONING}>
                        Halfvrijstaande woning
                    </option>
                    <option key={ObjectType.HOEKWONING} value={ObjectType.HOEKWONING}>
                        Hoekwoning
                    </option>
                    <option key={ObjectType.TUSSENWONING} value={ObjectType.TUSSENWONING}>
                        Tussenwoning
                    </option>
                    <option key={ObjectType.VRIJSTAAND} value={ObjectType.VRIJSTAAND}>
                        Vrijstaande woning
                    </option>
                </optgroup>
                <optgroup label="Appartement &amp; flat">
                    <option key={ObjectType.BENEDENWONING} value={ObjectType.BENEDENWONING}>
                        Benedenwoning
                    </option>
                    <option key={ObjectType.BOVENWONING} value={ObjectType.BOVENWONING}>
                        Bovenwoning
                    </option>
                    <option key={ObjectType.CORRIDORFLAT} value={ObjectType.CORRIDORFLAT}>
                        Corridorflat
                    </option>
                    <option key={ObjectType.GALERIJFLAT} value={ObjectType.GALERIJFLAT}>
                        Galerijflat
                    </option>
                    <option key={ObjectType.MAISONNETTE} value={ObjectType.MAISONNETTE}>
                        Maisonnette
                    </option>
                    <option key={ObjectType.PORTIEKFLAT} value={ObjectType.PORTIEKFLAT}>
                        Portiekflat
                    </option>
                    <option key={ObjectType.PORTIEKWONING} value={ObjectType.PORTIEKWONING}>
                        Portiekwoning
                    </option>
                </optgroup>
                <optgroup label="Overige">
                    <option key={ObjectType.LIGPLAATS} value={ObjectType.LIGPLAATS}>
                        Ligplaats
                    </option>
                    <option key={ObjectType.WATERWONING} value={ObjectType.WATERWONING}>
                        Waterwoning
                    </option>
                    <option key={ObjectType.WINKELPAND} value={ObjectType.WINKELPAND}>
                        Woon-/winkelpand
                    </option>
                    <option key={ObjectType.WOONBOOT} value={ObjectType.WOONBOOT}>
                        Woonboot
                    </option>
                    <option key={ObjectType.WOONWAGEN} value={ObjectType.WOONWAGEN}>
                        Woonwagen/stacaravan
                    </option>
                    <option key={ObjectType.WOONWAGENSTANDPLAATS} value={ObjectType.WOONWAGENSTANDPLAATS}>
                        Woonwagenstandplaats/Stacaravanstandplaats
                    </option>
                    <option key={ObjectType.GARAGE} value={ObjectType.GARAGE}>
                        Garage
                    </option>
                </optgroup>
            </select>
        );
    }
}
