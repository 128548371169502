import React, {useContext, useMemo} from 'react';
import {QuestionTableChildContext, QuestionTableContext} from './question_table_context';

export function QuestionTableCollapsible({children}: {children?: React.ReactNode}) {
    const tableContext = useContext(QuestionTableContext);
    const parentContext = useContext(QuestionTableChildContext);

    const val = useMemo(
        () => ({
            ...parentContext,
            collapsed:
                (parentContext.parentKey !== undefined &&
                    tableContext.collapsedKeys.includes(parentContext.parentKey)) ??
                parentContext.collapsed,
        }),
        [parentContext, tableContext.collapsedKeys]
    );

    return <QuestionTableChildContext.Provider value={val}>{children}</QuestionTableChildContext.Provider>;
}
