import * as React from 'react';

import {
    FloorQuestionType,
    IteratorQuestionType,
    LayoutQuestionType,
    NormalQuestionType,
    QuestionType,
    RootGroupQuestionType,
} from '../../../../enum/question_type';

import {AdaptedDefaultValuesMap} from '../../../../models/adapted_default_values_map';
import {AlertInlineQuestion} from './simple/alert_inline_question';
import {Appraisal} from '../../../../models/appraisal';
import {AppraisalDetails} from './advanced/appraisal_details';
import {AreaSummary} from './advanced/area_summary/area_summary';
import {AttachmentQuestion} from './advanced/attachment_question';
import {BooleanQuestion} from './simple/boolean_question';
import {BuildingCosts} from './advanced/building_costs';
import {BuildingCostsCondition} from './advanced/building_costs_condition/building_costs_condition';
import {BuildingCostsGroup} from './advanced/building_costs_group';
import {BuildingCostsPhoto} from './advanced/photo_question/building_costs_photo';
import {BuildingCostsSheet} from './advanced/building_costs_sheet';
import {BuildingInspectionAppointment} from './advanced/building_inspection_appointment';
import {CadastralDocumentQuestion} from './advanced/iterator_cadastral_document/cadastral_document_question';
import {Checklist} from './simple/checklist';
import {CollapsableChecklist} from './advanced/collapsable_checklist';
import {CompactHouseGroupQuestion} from './advanced/compact_house_group_question';
import {ConceptReport} from './advanced/concept_report';
import {ConceptReportReplacement} from './simple/concept_report_replacement';
import {ConstructionSpecificationViewer} from './advanced/construction_specification_viewer/construction_specification_viewer';
import {CurrencyQuestion} from './simple/currency_question';
import {DatePickerQuestion} from './simple/date_picker_question';
import {DateQuestion} from './simple/date_question';
import {DescriptionImageQuestion} from './simple/description_image_question';
import {DescriptionQuestion} from './simple/description_question';
import {EditableAddressQuestion} from './advanced/reference_objects_question/v3/questions/editable_address/editable_address_question';
import {FilesGroup} from './simple/files_group';
import {Group} from './simple/group';
import {GroupCollapsible} from './advanced/group_collapsible';
import {HiddenQuestion} from './simple/hidden_question';
import {ColumnGroup} from './simple/column_group';
import {HouseGroupQuestion} from './advanced/house_group_question';
import {IndexPage} from './index_page';
import {IntCompareQuestion} from './advanced/int_compare/int_compare_question';
import {IntNumberQuestion} from './simple/int_number_question';
import {IntUnknownQuestion} from './simple/int_unknown_question';
import {Iterator} from './advanced/iterator';
import {IteratorCadastral} from './advanced/iterator_cadastral';
import {IteratorFiles} from './advanced/iterator_files';
import {IteratorGroup} from './simple/iterator_group';
import {IteratorPhotoGroup} from './advanced/iterator_photo_group/iterator_photo_group';
import {ModalQuestion} from './simple/modal_question';
import {ModalWithAdaptedDefaultValues} from './advanced/modal_with_adapted_default_values';
import {MultipleBooleanGroupQuestion} from './advanced/multiple_boolean_group_question';
import {MultipleChoiceQuestion} from './simple/multiple_choice_question';
import {MultipleChoiceSelectCompareQuestion} from './advanced/reference_objects_question/v3/questions/select_compare/multiple_choice_select_compare_question';
import {MultipleChoiceSelectOptionalQuestion} from './simple/multiple_choice_select_optional_question';
import {MultipleChoiceSelectQuestion} from './simple/multiple_choice_select_question';
import {NumberQuestion} from './simple/number_question';
import {ObjectUsageDetails} from './advanced/object_usage_details';
import {OpenQuestion} from './simple/open_question';
import {OpenQuestionCompact} from './simple/open_question_compact';
import {OpenQuestionMaxCharacters} from './simple/open_question_max_characters';
import {PagePart} from '../../../../models/page_part';
import {PagePartGroup} from './advanced/page_part/page_part_group';
import {PagePartIndexPage} from './page_part_index_page';
import {PagePartIterator} from './advanced/page_part/page_part_iterator';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {PhotoDropZone} from './advanced/photo_drop_zone/photo_drop_zone';
import {PhotoIterator} from './advanced/photo_iterator/photo_iterator';
import {PhotoQuestion} from './advanced/photo_question/photo_question';
import {PhotosViewer} from './advanced/photo_viewer';
import {Question} from '../../../../models/question';
import {QuestionRow} from './simple/question_row';
import {QuestionSet} from '../../../../models/question_set';
import {ReferenceObjectExplanation} from './advanced/reference_objects_question/v3/questions/reference_object_explanation/reference_object_explanation';
import {ReferenceObjectPhotoQuestion} from './advanced/reference_objects_question/v3/questions/reference_object_photo/reference_object_photo_question';
import {ReferenceObjectsQuestion} from './advanced/reference_objects_question/v1/reference_objects_question';
import {ReferenceObjectsQuestion as ReferenceObjectsQuestionV2} from './advanced/reference_objects_question/v2/reference_objects_question';
import {ReferenceObjectsQuestion as ReferenceObjectsQuestionV3} from './advanced/reference_objects_question/v3/reference_objects_question';
import {ReferenceObjectsRentQuestion} from './advanced/reference_objects_question/v1/reference_objects_rent_question';
import {ReferenceObjectsSaleQuestion} from './advanced/reference_objects_question/v1/reference_objects_sale_question';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {Search} from './advanced/search';
import {SimpleQuestionPresenterProps} from './simple/simple_question_presenter';
import {SurfaceCalculatorQuestion} from './advanced/surface_calculator_question';
import {SurfaceMethodSelectQuestion} from './simple/surface_method_select_question';
import {ValidationError} from '../../../../models/validation_error';
import {ValidationMessageMap} from '../../../../business/validation/validation_message';
import {WidgetGroup} from './advanced/widget_group';
import {YearQuestion} from './simple/year_question';
import {bugsnagClient} from '../../../../../support/bugsnag_client';
import {ForcedSaleFilterRangesQuestion} from './advanced/reference_objects_question/v3/questions/forced_sale_filter_ranges/forced_sale_filter_ranges_question';
import {MultipleChoiceSelectDynamicQuestion} from './advanced/multiple_choice_select_dynamic_question';
import {PhotoIteratorSmall} from './advanced/photo_iterator_small';
import {SmallPhotoQuestion} from './advanced/photo_question/small_photo_question';
import {IteratorPhotoGroupSmall} from './advanced/iterator_photo_group_small/iterator_photo_group_small';
import {CurrencyIndexableQuestion} from './advanced/currency_indexable/currency_indexable_question';
import {QuestionTableCell} from '../../components/question_table/question_table_cell';
import {CompareGroupQuestion} from './advanced/compare_group_question';
import {SymlinkLink} from './advanced/symlink/symlink_link';
import {AttachmentWidget} from './advanced/attachment_widget';
import {BouwkundigeStaatViewer} from './advanced/bouwkundige_staat_viewer/bouwkundige_staat_viewer';
import {QuestionRenderingData} from '../../../../models/question_rendering_data';
import {GroupReferenceObjectDetails} from './simple/group_reference_object_details';
import {EnergeticWidget} from './simple/energetic_widget';
import {EnergetischeStaatViewer} from './advanced/energetische_staat_viewer/energetische_staat_viewer';
import {EnergeticOverview} from './advanced/energetic_overview/energetic_overview';
import {SinglePhotoDropZoneQuestion} from './advanced/photo_drop_zone/single_photo_drop_zone_question';

interface OwnProps {
    appraisal: Appraisal;
    question: Question;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    disabled?: boolean;
    hideLabel?: boolean;
    onChange?: (appraisal: Appraisal) => void;
    iteration?: string;
    parentAnswerUuid?: string;
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
    disableAdaptedValueContainer?: boolean;
    hiddenQuestionTypes: QuestionType[];
    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const ALLOWED_IMAGE_TYPES = ['.png', '.jpg', '.jpeg', '.jfif'];
export const ALLOWED_DOCUMENT_TYPES = ['.pdf'];

export class QuestionContainer extends React.Component<OwnProps> {
    private getRootQuestionProps(question: Question): SimpleQuestionPresenterProps {
        return {
            question: question,
            appraisal: this.props.appraisal,
            questionSet: this.props.questionSet,
            iteration: this.props.iteration,
            disabled: this.props.disabled,
            hideLabel: this.props.hideLabel,
            validationErrors: this.props.validationErrors,
            validationMessages: this.props.validationMessages,
            pagePartsSet: this.props.pagePartsSet,
            activePagePart: this.props.activePagePart,
            renderingContext: this.props.renderingContext,
            parentAnswerUuid: this.props.parentAnswerUuid,
            adaptedDefaultValue: this.props.adaptedDefaultValues
                ? this.props.adaptedDefaultValues[question.uuid]
                : undefined,
            disableAdaptedValueContainer: this.props.disableAdaptedValueContainer,
            hiddenQuestionTypes: this.props.hiddenQuestionTypes,
            onChange: this.props.onChange ?? undefined,
            adaptedDefaultValues: this.props.adaptedDefaultValues,
            forceShowValidationMessages: this.props.forceShowValidationMessages,
            questionRenderingData: this.props.questionRenderingData,
        };
    }
    private getSimpleQuestionProps(question: Question): SimpleQuestionPresenterProps {
        if (this.props.parentAnswerUuid === undefined) {
            throw new Error('Simple questions require a parent answer');
        }

        return this.getRootQuestionProps(question);
    }

    public render() {
        if (this.props.renderingContext === RenderingContextType.COMPACT_GRID_COLUMNS) {
            return (
                <QuestionTableCell questionUuid={this.props.question.uuid}>{this.renderQuestion()}</QuestionTableCell>
            );
        }

        return this.renderQuestion();
    }

    private renderQuestion(): JSX.Element | null {
        const {question, hiddenQuestionTypes} = this.props;

        if (hiddenQuestionTypes && hiddenQuestionTypes.includes(question.type)) {
            return null;
        }

        switch (question.type) {
            case NormalQuestionType.PAGE_PART_GROUP: {
                if (!this.props.activePagePart || !this.props.pagePartsSet) {
                    throw new Error('No active pagepart');
                }
                if (this.props.activePagePart.isIndexPage) {
                    return (
                        <PagePartIndexPage
                            appraisal={this.props.appraisal}
                            questionSet={this.props.questionSet}
                            iteration={this.props.iteration}
                            activePagePart={this.props.activePagePart}
                            pagePartsSet={this.props.pagePartsSet}
                            renderingContext={this.props.renderingContext}
                        />
                    );
                }

                return (
                    <PagePartGroup
                        {...this.getRootQuestionProps(question)}
                        parentAnswerUuid={undefined}
                        pagePartsSet={this.props.pagePartsSet}
                        activePagePart={this.props.activePagePart}
                    />
                );
            }
            case IteratorQuestionType.PAGE_PART_ITERATOR: {
                if (!this.props.activePagePart || !this.props.pagePartsSet) {
                    throw new Error('No active pagepart');
                }

                return (
                    <PagePartIterator
                        {...this.getRootQuestionProps(question)}
                        parentAnswerUuid={undefined}
                        pagePartsSet={this.props.pagePartsSet}
                        activePagePart={this.props.activePagePart}
                    />
                );
            }
            case NormalQuestionType.SYMLINK_LINK: {
                if (!this.props.activePagePart || !this.props.pagePartsSet) {
                    throw new Error('No active pagepart');
                }

                return (
                    <SymlinkLink
                        {...this.getSimpleQuestionProps(question)}
                        pagePartsSet={this.props.pagePartsSet}
                        activePagePart={this.props.activePagePart}
                    />
                );
            }
            case IteratorQuestionType.PAGE_PART_FLOORS: {
                throw new Error("IteratorQuestionType.PAGE_PART_FLOORS shouldn't be rendered!");
            }
            case NormalQuestionType.SYMLINK_TARGET: {
                throw new Error("NormalQuestionType.SYMLINK_TARGET shouldn't be rendered!");
            }
            case NormalQuestionType.SYMLINK_TARGET_COPY: {
                throw new Error("NormalQuestionType.SYMLINK_TARGET_COPY shouldn't be rendered!");
            }
            case RootGroupQuestionType.PHOTO_GROUP:
            case RootGroupQuestionType.PRE_GROUP:
            case RootGroupQuestionType.POST_GROUP:
            case RootGroupQuestionType.OBSERVATION_GROUP:
            case RootGroupQuestionType.ENERGETIC_SHEET:
            case FloorQuestionType.FLOOR_GROUP_ATTIC:
            case FloorQuestionType.FLOOR_GROUP_FLOOR:
            case FloorQuestionType.FLOOR_GROUP_GROUND:
            case FloorQuestionType.FLOOR_GROUP_BASEMENT: {
                return (
                    <IndexPage
                        questionSet={this.props.questionSet}
                        appraisal={this.props.appraisal}
                        question={question}
                        iteration={this.props.iteration}
                    />
                );
            }
            case NormalQuestionType.BUILDING_COSTS_PHOTO_GROUP:
                return null;
            case NormalQuestionType.APPRAISAL_DETAILS:
                if (this.props.onChange === undefined) {
                    throw new Error('onChange not defined for taxation details');
                }
                return (
                    <AppraisalDetails
                        question={question}
                        appraisal={this.props.appraisal}
                        parentAnswerUuid={this.props.parentAnswerUuid}
                        iteration={this.props.iteration}
                        questionSet={this.props.questionSet}
                        onChange={this.props.onChange}
                        disabled={this.props.disabled}
                        renderingContext={this.props.renderingContext}
                    />
                );

            case NormalQuestionType.OBJECT_USAGE_DETAILS:
                if (this.props.onChange === undefined) {
                    throw new Error('onChange not defined for taxation details');
                }
                return (
                    <ObjectUsageDetails
                        question={question}
                        appraisal={this.props.appraisal}
                        disabled={this.props.disabled}
                    />
                );

            /**
             * Files group is nothing more than a special type
             */
            case NormalQuestionType.FILES_GROUP:
                return <FilesGroup {...this.getRootQuestionProps(question)} />;

            case IteratorQuestionType.ITERATOR:
                return (
                    <Iterator
                        adaptedDefaultValues={this.props.adaptedDefaultValues}
                        {...this.getSimpleQuestionProps(question)}
                    />
                );

            case IteratorQuestionType.ITERATOR_FILES:
                return (
                    <IteratorFiles
                        adaptedDefaultValues={this.props.adaptedDefaultValues}
                        fileTypes={ALLOWED_DOCUMENT_TYPES}
                        {...this.getSimpleQuestionProps(question)}
                    />
                );

            case IteratorQuestionType.ITERATOR_CADASTRAL:
                return (
                    <IteratorCadastral
                        adaptedDefaultValues={this.props.adaptedDefaultValues}
                        {...this.getSimpleQuestionProps(question)}
                    />
                );

            case NormalQuestionType.CADASTRAL_DOCUMENT:
                return <CadastralDocumentQuestion {...this.getSimpleQuestionProps(question)} />;

            /**
             * Child of an iterator
             */
            case IteratorQuestionType.ITERATOR_GROUP:
                return <IteratorGroup {...this.getRootQuestionProps(question)} />;
            /**
             * Child of an iterator
             */
            case IteratorQuestionType.ITERATOR_PHOTO_GROUP:
                return <IteratorPhotoGroup {...this.getSimpleQuestionProps(question)} />;
            /**
             * Specific iterator for photo grid
             */
            case IteratorQuestionType.PHOTO_ITERATOR:
                return (
                    <PhotoIterator
                        {...this.getSimpleQuestionProps(question)}
                        fileTypes={ALLOWED_IMAGE_TYPES}
                        adaptedDefaultValues={this.props.adaptedDefaultValues}
                    />
                );
            /**
             * Small version of photo iterator
             */
            case IteratorQuestionType.PHOTO_ITERATOR_SMALL:
                return (
                    <PhotoIteratorSmall
                        {...this.getSimpleQuestionProps(question)}
                        fileTypes={ALLOWED_IMAGE_TYPES}
                        adaptedDefaultValues={this.props.adaptedDefaultValues}
                    />
                );
            /**
             * Child of an iterator
             */
            case IteratorQuestionType.ITERATOR_PHOTO_GROUP_SMALL:
                return <IteratorPhotoGroupSmall {...this.getSimpleQuestionProps(question)} />;

            case NormalQuestionType.AREA_SUMMARY:
                return <AreaSummary {...this.getSimpleQuestionProps(question)} />;

            /**
             * Parent container for other questions
             */
            case NormalQuestionType.GROUP:
            case NormalQuestionType.GROUP_COMPACT:
                return question.parentUuid === null ? (
                    <IndexPage {...this.getRootQuestionProps(question)} />
                ) : (
                    <Group {...this.getRootQuestionProps(question)} />
                );

            case NormalQuestionType.GROUP_REFERENCE_OBJECT_DETAILS:
                return <GroupReferenceObjectDetails {...this.getSimpleQuestionProps(question)} />;

            case LayoutQuestionType.HORIZONTAL_GROUP:
            case LayoutQuestionType.QUESTION_ROW:
            case LayoutQuestionType.QUESTION_ROW_COMPACT:
                return <ColumnGroup {...this.getSimpleQuestionProps(question)} />;

            case NormalQuestionType.COMPARE_GROUP:
                return <CompareGroupQuestion {...this.getSimpleQuestionProps(question)} />;

            /**
             * Group that starts collapsed with a header that can be clicked on to show the contents.
             */
            case NormalQuestionType.GROUP_COLLAPSIBLE:
                return <GroupCollapsible {...this.getSimpleQuestionProps(question)} />;

            case RootGroupQuestionType.HOUSE_INSIDE:
            case RootGroupQuestionType.OUTSIDE_GROUP:
            case RootGroupQuestionType.ROOT_GROUP:
                return <Group {...this.getRootQuestionProps(question)} />;

            case NormalQuestionType.WIDGET_GROUP:
                return <WidgetGroup {...this.getSimpleQuestionProps(question)} />;

            case NormalQuestionType.BUILDING_COSTS_GROUP:
                return <BuildingCostsGroup {...this.getSimpleQuestionProps(question)} />;

            case NormalQuestionType.BUILDING_DEFECTS:
            case NormalQuestionType.BUILDING_DEFECTS_COSTS:
                return <BuildingCosts {...this.getSimpleQuestionProps(question)} />;

            case RootGroupQuestionType.BUILDING_COSTS_SHEET:
                return <BuildingCostsSheet {...this.getRootQuestionProps(question)} />;

            case RootGroupQuestionType.CONCEPT_REPORT:
                return <ConceptReport {...this.getRootQuestionProps(question)} />;

            case NormalQuestionType.BOUWKUNDIGE_STAAT_VIEWER:
                return (
                    <BouwkundigeStaatViewer
                        appraisal={this.props.appraisal}
                        questionSet={this.props.questionSet}
                        renderingContext={this.props.renderingContext}
                    />
                );

            case NormalQuestionType.ENERGETISCHE_STAAT_VIEWER:
                return (
                    <EnergetischeStaatViewer
                        appraisal={this.props.appraisal}
                        questionSet={this.props.questionSet}
                        renderingContext={this.props.renderingContext}
                    />
                );

            case NormalQuestionType.CONSTRUCTION_SPECIFICATION_VIEWER:
                return (
                    <ConstructionSpecificationViewer
                        appraisal={this.props.appraisal}
                        questionSet={this.props.questionSet}
                        renderingContext={this.props.renderingContext}
                    />
                );

            case NormalQuestionType.BUILDING_COSTS_CONDITION:
                return <BuildingCostsCondition {...this.getSimpleQuestionProps(question)} />;

            case NormalQuestionType.BUILDING_COSTS_PHOTO:
                return (
                    <BuildingCostsPhoto fileTypes={ALLOWED_IMAGE_TYPES} {...this.getSimpleQuestionProps(question)} />
                );

            /**
             * Behaves as a group, however fancy overlay
             */
            case NormalQuestionType.MODAL:
                return <ModalQuestion {...this.getRootQuestionProps(question)} />;

            case NormalQuestionType.MODAL_WITH_ADAPTED_DEFAULT_VALUES:
                return <ModalWithAdaptedDefaultValues {...this.getRootQuestionProps(question)} />;

            /**
             * Behaves as a group, however contains only fancy formatted checkboxes
             */
            case NormalQuestionType.CHECKLIST:
                return <Checklist {...this.getSimpleQuestionProps(question)} />;

            case NormalQuestionType.COLLAPSABLE_CHECKLIST:
                return <CollapsableChecklist {...this.getSimpleQuestionProps(question)} />;
            /**
             * Behaves as a group, however contains only fancy formatted selection options
             */
            case NormalQuestionType.MULTIPLE_BOOLEAN_GROUP:
                return <MultipleBooleanGroupQuestion {...this.getSimpleQuestionProps(question)} />;

            /**
             * Behaves as a group, however contains only fancy formatted columns
             */
            case NormalQuestionType.BUILDING_COSTS_ROW:
                return <QuestionRow {...this.getRootQuestionProps(question)} />;

            /**
             * House group will just set the configuration of a house
             */
            case RootGroupQuestionType.HOUSE_GROUP:
                if (this.props.questionSet.reportDefintionConfig.compactHouseGroupSelection) {
                    throw new Error(
                        'House group question is not supported when question set has compactHouseGroupSelection'
                    );
                }
                return <HouseGroupQuestion {...this.getRootQuestionProps(question)} />;

            case RootGroupQuestionType.HOUSE_GROUP_COMPACT: {
                if (!this.props.questionSet.reportDefintionConfig.compactHouseGroupSelection) {
                    throw new Error(
                        'Compact house group question is not supported when question set has no compactHouseGroupSelection'
                    );
                }
                return <CompactHouseGroupQuestion {...this.getRootQuestionProps(question)} />;
            }

            case IteratorQuestionType.ITERATOR_REFERENCE_OBJECTS_V3:
                return null;
            /**
             * Simple questions
             */
            case NormalQuestionType.HIDDEN:
                return <HiddenQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.DESCRIPTION:
                return <DescriptionQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.DESCRIPTION_IMAGE:
                return (
                    <DescriptionImageQuestion {...this.getSimpleQuestionProps(question)} imageUrl={question.imageUrl} />
                );
            case NormalQuestionType.ALERT_INLINE:
                return <AlertInlineQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.REFERENCE_OBJECTS:
                return <ReferenceObjectsQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.REFERENCE_OBJECTS_SALE:
                return <ReferenceObjectsSaleQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.REFERENCE_OBJECTS_RENT:
                return <ReferenceObjectsRentQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.REFERENCE_OBJECTS_V2:
                return <ReferenceObjectsQuestionV2 {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.REFERENCE_OBJECTS_V3:
                return <ReferenceObjectsQuestionV3 {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.REFERENCE_OBJECT_PHOTO:
                return (
                    <ReferenceObjectPhotoQuestion
                        fileTypes={ALLOWED_IMAGE_TYPES}
                        {...this.getSimpleQuestionProps(question)}
                    />
                );
            case NormalQuestionType.REFERENCE_OBJECT_EDITABLE_ADDRESS:
                return <EditableAddressQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.REFERENCE_OBJECT_EXPLANATION:
                return <ReferenceObjectExplanation {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.OPEN:
                return <OpenQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.OPEN_COMPACT:
                return <OpenQuestionCompact {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.OPEN_MAX_CHARACTERS:
                return <OpenQuestionMaxCharacters {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.MC:
                return <MultipleChoiceQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.MC_SELECT:
                return <MultipleChoiceSelectQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.MC_SELECT_OPTIONAL:
                return <MultipleChoiceSelectOptionalQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.MC_SELECT_COMPARE:
                return <MultipleChoiceSelectCompareQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.MC_SELECT_DYNAMIC:
                return <MultipleChoiceSelectDynamicQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.SURFACE_METHOD_SELECT:
                return <SurfaceMethodSelectQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.BOOLEAN:
                return <BooleanQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.DATE:
                return <DateQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.DATE_PICKER:
                return <DatePickerQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.YEAR:
                return <YearQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.NUMBER:
                return <NumberQuestion negative={false} {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.NEGATIVE_NUMBER:
                return <NumberQuestion negative={true} {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.INT_NUMBER:
                return <IntNumberQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.INT_COMPARE:
                return <IntCompareQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.INT_UNKNOWN:
                return <IntUnknownQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.NEGATIVE_CURRENCY:
                return <CurrencyQuestion step="1" negative={true} {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.NEGATIVE_CURRENCY_CENTS:
                return <CurrencyQuestion step=".01" negative={true} {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.CURRENCY:
                return <CurrencyQuestion step="1" negative={false} {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.CURRENCY_INDEXABLE:
                return (
                    <CurrencyIndexableQuestion step="1" negative={false} {...this.getSimpleQuestionProps(question)} />
                );
            case NormalQuestionType.CURRENCY_CENTS:
                return <CurrencyQuestion step=".01" negative={false} {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.PHOTO:
                return <PhotoQuestion fileTypes={ALLOWED_IMAGE_TYPES} {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.PHOTO_SMALL:
                return (
                    <SmallPhotoQuestion fileTypes={ALLOWED_IMAGE_TYPES} {...this.getSimpleQuestionProps(question)} />
                );
            case NormalQuestionType.PHOTO_DROP_ZONE_SINGLE:
                return (
                    <SinglePhotoDropZoneQuestion
                        fileTypes={['.png', '.jpg', '.jpeg', '.jfif']}
                        {...this.getSimpleQuestionProps(question)}
                    />
                );
            case NormalQuestionType.PHOTO_VIEWER:
                return <PhotosViewer {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.FORCED_SALE_FILTER_RANGES:
                return <ForcedSaleFilterRangesQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.ATTACHMENT:
                return (
                    <AttachmentQuestion fileTypes={ALLOWED_DOCUMENT_TYPES} {...this.getSimpleQuestionProps(question)} />
                );
            case NormalQuestionType.PHOTO_DROP_ZONE:
                return <PhotoDropZone {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.CONCEPT_REPORT_REPLACEMENT:
                return <ConceptReportReplacement {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.SURFACE_CALCULATOR:
                return <SurfaceCalculatorQuestion {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.SEARCH:
                return <Search {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.BUILDING_INSPECTION_APPOINTMENT:
                return <BuildingInspectionAppointment {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.ATTACHMENT_WIDGET:
                return <AttachmentWidget {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.ENERGETIC_WIDGET:
                return <EnergeticWidget {...this.getSimpleQuestionProps(question)} />;
            case NormalQuestionType.ENERGETIC_OVERVIEW:
                return <EnergeticOverview {...this.getSimpleQuestionProps(question)} />;
            default: {
                console.error(this.props.parentAnswerUuid);
                console.trace();
                const error = new Error('No component renderable for question.type: ' + question.type);
                console.error(error, question);
                bugsnagClient?.notify(error);
                return null;
            }
        }
    }
}
