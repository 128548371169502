import * as React from 'react';

import {
    simpleQuestionPresenterConstructorParametersFactory,
    SimpleQuestionPresenterProps,
} from '../simple/simple_question_presenter';

import {BuildingCostsPresenter} from './building_costs_presenter';
import {classNames} from '../../../../../../support/classnames';
import {formatMoney} from '../../../support/format_money';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {SimpleTooltip} from '../../../../../components/tooltip';
import {NormalQuestionType} from '../../../../../enum/question_type';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const BuildingCosts: React.FC<OwnProps> = observer(function BuildingCosts(props) {
    const presenter = usePresenter(
        (container) =>
            new BuildingCostsPresenter(
                props.validationErrors,
                props.validationMessages,
                container.business.modalConfigStackInteractor,
                container.business.appraiseSecondaryConfigStackInteractor,
                container.globalProvider,
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );
    if (presenter.isHidden) {
        return null;
    }

    let partialCosts =
        presenter.validationInstituteConstructionalDefect !== null &&
        presenter.validationInstituteConstructionalDefect.directCosts !== 0
            ? presenter.validationInstituteConstructionalDefect.directCosts
            : 0;

    partialCosts =
        partialCosts +
        (presenter.validationInstituteConstructionalDefect !== null &&
        presenter.validationInstituteConstructionalDefect.costs5Years !== 0
            ? presenter.validationInstituteConstructionalDefect.costs5Years
            : 0);

    const costs = partialCosts > 0 ? formatMoney(partialCosts / 100, {currencySymbol: ''}) : null;
    const hasCosts = props.question.type === NormalQuestionType.BUILDING_DEFECTS_COSTS;

    return (
        <>
            <div
                className={classNames('form-group form-group-buttons', {
                    'form-group-buttons-double': presenter.buildingInspectorEnabled,
                })}
            >
                {presenter.buildingInspectorEnabled && (
                    <SimpleTooltip
                        disabled={props.disabled}
                        content={
                            <span>
                                Via deze knop kun je een second opinion aanvragen.
                                <br />
                                1. Maak een foto van het gebrek,
                                <br />
                                2. Beschrijf het gebrek,
                                <br />
                                3. Stel je vraag aan de bouwkundige,
                                <br />
                                4. Verstuur je vraag.
                                <br />
                                Je ontvangt zo snel mogelijk reactie per mail.
                            </span>
                        }
                    >
                        <button
                            disabled={props.disabled || presenter.isDisabled}
                            className={classNames('btn btn-secondary btn-sm btn-advice icon-advice', {
                                'pulse-input-enabled': costs === null,
                            })}
                            onClick={() => presenter.toggleRequestWidgetContainer()}
                        >
                            &nbsp;
                        </button>
                    </SimpleTooltip>
                )}
                <button
                    className={classNames('btn btn-secondary btn-sm btn-building-costs icon-building-costs', {
                        'btn-building-costs-empty': costs === null,
                        'pulse-input-enabled': costs === null,
                    })}
                    onClick={() =>
                        props.disabled ? presenter.toggleNotesWidgetContainer() : presenter.toggleCostsWidgetContainer()
                    }
                >
                    {hasCosts && costs !== null && (
                        <span className="btn-building-costs-amount">
                            <span className="btn-building-costs-amount-currency">€</span>
                            <span className="btn-building-costs-amount-costs"> {costs}</span>
                        </span>
                    )}
                </button>
                <label>&nbsp;</label>
            </div>
        </>
    );
});
