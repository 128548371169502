import {ImageUrlPair} from '../../../../../../../../models/image_url_pair';
import {Sale} from '../../../../../../../../models/sale';
import {ValidationInstituteReferentieObject} from '../../../../../../../../models/validation_institute_reference_object';
import {ReferenceSaleImage} from '../../v1/models/reference_sale_image';
import {buildPhotoSets} from '../components/photos/photo_sets';

export interface ReferenceObjectAnswer {
    //The ID of the reference sale
    id: string;
    //The Set to which this reference sale belongs to
    valuationType: string | null;

    uploadedPhotoUrl: string | null;
    imageUrlPairs?: ImageUrlPair[];
    images?: ReferenceSaleImage[];

    source: string | null;
    hasWarning: boolean;
    warning: string | null;

    latitude: number;
    longitude: number;

    saleQuarter: string | null;

    brochureUrl: string | null;
    matchingPercentage: number | null;
    rooms: number | null;
    frontview: string | null;

    referenceObject: ValidationInstituteReferentieObject;
}

export function referenceObjectAnswerFromSale(
    sale: Sale,
    saleQuarter: string | null,
    images: ReferenceSaleImage[] | null
): ReferenceObjectAnswer {
    let maintenance = sale.keywords?.maintenance ?? null;

    if (maintenance === null || maintenance === 'Onbekend') {
        maintenance = 'Goed';
    }
    let aanbouw = null;
    if (sale.keywords) {
        const keywordValues = Object.values(sale.keywords);

        aanbouw = keywordValues.map((keyword) => {
            switch (keyword.toLocaleLowerCase()) {
                case 'overkapping':
                    return 'Overkapping / luifel';
                case 'berging':
                    return 'Berging / schuur (vrijst.)';
                case 'kelder':
                    return 'Kelder / souterrain';
                case 'verhoogde nok':
                    return 'Dakkapel verh. nok';
                case 'dubbele garage':
                    return 'Garage (dubbel)';
                case 'garage':
                    return 'Garage (enkel)';
                case 'carport':
                    return 'Carport';
                case 'garage_and_carport':
                    return 'Garage (enkel met kap)';
                default:
                    return keyword;
            }
        });
    }

    const photoSets = buildPhotoSets(images ?? []);
    const reducedImageList = [];

    if (photoSets.length > 0) {
        const seenLabels = new Set<string>();
        for (const image of photoSets[0].images) {
            if (image.label === null || seenLabels.has(image.label)) {
                continue;
            }

            seenLabels.add(image.label);
            reducedImageList.push(image);

            if (reducedImageList.length >= 10) {
                break;
            }
        }

        if (reducedImageList.length < 10) {
            // Fill to 10 with images labelled null
            for (const image of photoSets[0].images) {
                if (image.label !== null) {
                    continue;
                }

                reducedImageList.push(image);
                if (reducedImageList.length >= 10) {
                    break;
                }
            }
        }
    }

    return {
        id: sale.id,
        //Will be filled when added by the above laying presenter
        valuationType: null,
        uploadedPhotoUrl: null,
        images: reducedImageList,
        source: sale.source,
        hasWarning: sale.hasWarning,
        warning: sale.warning,
        latitude: sale.latitude,
        longitude: sale.longitude,
        saleQuarter: saleQuarter,
        brochureUrl: sale.brochureUrl,
        matchingPercentage: sale.matchingPercentage,
        rooms: sale.rooms,
        frontview: sale.frontview,
        referenceObject: {
            adres: {
                straat: sale.street,
                huisnummer: sale.houseNumber,
                huisnummerToevoeging: sale.letter,
                postcode: sale.postalCode,
                plaats: sale.city,
                land: 'Nederland',
                latitude: sale.latitude,
                longitude: sale.longitude,
            },
            verkoopprijs: sale.purchasePrice,
            verkoopdatum: sale.saleDate,
            transportdatum: sale.transportDate,
            aanvangsHuurprijsPerMaand: null,
            gecorrigeerdeHuurprijsPerMaand: null,
            ingangsdatumHuur: null,
            gecorrigeerdeVerkoopprijs: sale.indexedPurchasePrice,
            verkochtBinnen: sale.daysOpenForSale !== null ? sale.daysOpenForSale : 'Onbekend',
            bronGegevens: sale.source,
            woningType: sale.objectType || '',
            woningTypeUitleg: '',
            bouwjaar: sale.buildYear,
            bouwjaarUitleg: '',
            gebruiksOppervlakte: sale.floorArea,
            gebruiksOppervlakteUitleg: '',
            inhoud: sale.volume,
            inhoudUitleg: '',
            perceelOppervlakte: sale.plotArea,
            perceelOppervlakteUitleg: '',
            energielabel: sale.energyLabel || 'Geen energielabel',
            energielabelVoorlopig: null,
            energielabelGeldigTot: sale.energyLabelValidDate || null,
            aanbouw: aanbouw?.join(',') ?? '',
            aanbouwUitleg: '',
            liggingUitleg: '',
            onderhoudsSituatieUitleg: '',
            luxeDoelmatigheidUitleg: '',
            kwaliteitConditieUitleg: '',
            mateVanDoelmatigheidUitleg: '',
            mateVanLuxeUitleg: '',
            toelichtingGebruikReferentieObject: '',
            vooraanzicht: sale.frontview ?? '',
            woningTypeStatus: 'Vergelijkbaar',
            onderhoudsSituatieStatus: 'Vergelijkbaar',
            luxeDoelmatigheidStatus: 'Vergelijkbaar',
            liggingStatus: 'Vergelijkbaar',
            kwaliteitConditieStatus: 'Vergelijkbaar',
            aanbouwStatus: 'Vergelijkbaar',
            mateVanDoelmatigheidStatus: 'Vergelijkbaar',
            mateVanLuxeStatus: 'Vergelijkbaar',
            onderhoudssituatie: maintenance,
            streetviewUrl: null,
            eigendomssituatieStatus: 'Vergelijkbaar',
            eigendomssituatieUitleg: '',
            eigendomssituatieVolleEigendom: true,
            eigendomssituatieRechtErfpacht: false,
            eigendomssituatieRechtOndererfpacht: false,
            eigendomssituatieRechtOpstal: false,
            eigendomssituatieRechtGebruikBewoning: false,
            eigendomssituatieRechtVruchtgebruik: false,
            eigendomssituatieRechtAnders: false,
            eigendomssituatieToelichting: '',
            andereWezenlijkeVerschillen: false,
            andereWezenlijkeVerschillenUitleg: '',
            highlights: [],
            overview: null,
            installationDates: null,

            vraagprijs: sale.askingPrice,
            oorspronkelijkeVraagprijs: sale.originalAskingPrice,
            teKoopSinds: sale.offeredSince,
        },
    };
}
