import * as React from 'react';

import GoogleMapReact, {Bounds} from 'google-map-react';
import {Location, getCenterLocation} from './center_location';

import {ApiGlobal} from '../../../../../../../../../../business/global_provider';
import {Appraisal} from '../../../../../../../../../models/appraisal';
import {Cluster} from './reference_object_map_cluster';
import {Pointer} from './reference_object_map_pointer';
import {ReferenceObject} from '../../models/reference_object';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {ReferenceObjectMapDetails} from './reference_object_map_details';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';
import Supercluster from 'supercluster';
import {VisibleReferenceObject} from '../../reference_objects_question_presenter';
import {getObjectPrice} from '../../internal/get_object_price';
import {isReferenceObjectAnswer} from './is_reference_object_answer';
import {mapStyles} from './map_styles';
import {
    ReferenceSubscriptions,
    ReferenceSubscriptionType,
} from '../../../../../../../../../models/reference_subscriptions';
import {referenceObjectImages} from '../../internal/reference_sale_images';
import {QuestionSet} from '../../../../../../../../../models/question_set';

declare let GLOBAL: ApiGlobal;

export interface ReferenceObjectMapProps {
    setType: SetType;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    visibleReferenceObjects: VisibleReferenceObject;
    subscriptions: ReferenceSubscriptions | null;
    onHoverChange: (id: string | null) => void;
    onClickChange: (referenceObject: ReferenceObject | ReferenceObjectAnswer | null) => void;
    clickedReferenceObject: ReferenceObject | ReferenceObjectAnswer | null;
    hoveringReferenceObjectId: string | null;
    showDetailsModal: (referenceObject: ReferenceObject | ReferenceObjectAnswer) => void;
}

interface OwnState {
    centerLocation: Location;
    initialCenterLocation: Location;
    bounds: null | Bounds;
}

interface GloogleMap {
    setZoom(zoom: number): void;
    panTo(coords: {lat: number; lng: number}): void;
}

export class ReferenceObjectMap extends React.Component<ReferenceObjectMapProps, OwnState> {
    private mapRef: null | GloogleMap = null;

    public state: OwnState = {
        centerLocation: getCenterLocation(
            {
                lat: this.props.appraisal.latitude || 0,
                lng: this.props.appraisal.longitude || 0,
            },
            [
                ...this.props.visibleReferenceObjects.selectedObjects.map((referenceObject) => ({
                    lat: referenceObject.referenceObjectAnswer.latitude,
                    lng: referenceObject.referenceObjectAnswer.longitude,
                })),
                ...this.props.visibleReferenceObjects.nonSelectedObjects.map((referenceObject) => ({
                    lat: referenceObject.latitude,
                    lng: referenceObject.longitude,
                })),
            ]
        ),
        initialCenterLocation: getCenterLocation(
            {
                lat: this.props.appraisal.latitude || 0,
                lng: this.props.appraisal.longitude || 0,
            },
            [
                ...this.props.visibleReferenceObjects.selectedObjects.map((referenceObject) => ({
                    lat: referenceObject.referenceObjectAnswer.latitude,
                    lng: referenceObject.referenceObjectAnswer.longitude,
                })),
                ...this.props.visibleReferenceObjects.nonSelectedObjects.map((referenceObject) => ({
                    lat: referenceObject.latitude,
                    lng: referenceObject.longitude,
                })),
            ]
        ),
        bounds: null,
    };

    public componentDidUpdate(prevProps: ReferenceObjectMapProps) {
        const clickedReferenceObject = this.props.clickedReferenceObject;
        const previousClickedId = prevProps.clickedReferenceObject ? prevProps.clickedReferenceObject.id : null;

        if (
            clickedReferenceObject !== null &&
            clickedReferenceObject.id !== previousClickedId &&
            this.mapRef !== null
        ) {
            this.mapRef.setZoom(19);
            this.mapRef.panTo({lat: clickedReferenceObject.latitude, lng: clickedReferenceObject.longitude});
        }

        if (this.mapRef && this.props.appraisal.latitude && this.props.appraisal.longitude) {
            const initialCenterLocation = getCenterLocation(
                {
                    lat: this.props.appraisal.latitude,
                    lng: this.props.appraisal.longitude,
                },
                [
                    ...this.props.visibleReferenceObjects.selectedObjects.map((referenceObject) => ({
                        lat: referenceObject.referenceObjectAnswer.latitude,
                        lng: referenceObject.referenceObjectAnswer.longitude,
                    })),
                    ...this.props.visibleReferenceObjects.nonSelectedObjects.map((referenceObject) => ({
                        lat: referenceObject.latitude,
                        lng: referenceObject.longitude,
                    })),
                ]
            );

            if (
                initialCenterLocation.zoom !== this.state.initialCenterLocation.zoom ||
                initialCenterLocation.center.lat !== this.state.initialCenterLocation.center.lat ||
                initialCenterLocation.center.lng !== this.state.initialCenterLocation.center.lng
            ) {
                this.setState({initialCenterLocation});
                this.mapRef.setZoom(initialCenterLocation.zoom);
                this.mapRef.panTo(initialCenterLocation.center);
            }
        }
    }

    private zoomToCluster(
        superCluster: Supercluster,
        cluster: Supercluster.ClusterFeature<Supercluster.AnyProps> | Supercluster.PointFeature<Supercluster.AnyProps>
    ) {
        if (this.mapRef === null) {
            return;
        }

        if (cluster.id) {
            const expansionZoom = Math.min(superCluster.getClusterExpansionZoom(cluster.id as number), 22);
            const [longitude, latitude] = cluster.geometry.coordinates;
            this.mapRef.setZoom(expansionZoom);
            this.mapRef.panTo({lat: latitude, lng: longitude});
        }
    }

    private createMapOptions(maps: GoogleMapReact.Maps) {
        return {
            styles: mapStyles,
            zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_CENTER,
                style: maps.ZoomControlStyle.SMALL,
            },
            mapTypeControl: false,
            panControl: true,
            disableDefaultUI: false,
            draggable: true,
            fullscreenControl: false,
            gestureHandling: 'greedy',
        };
    }

    private calculateClusters(
        superCluster: Supercluster<Supercluster.ClusterProperties | ReferenceObject | ReferenceObjectAnswer>,
        visibleReferenceObjects: VisibleReferenceObject
    ) {
        superCluster.load([
            ...visibleReferenceObjects.selectedObjects.map((referenceObjectData) => {
                return {
                    type: 'Feature' as const,
                    geometry: {
                        type: 'Point' as const,
                        coordinates: [
                            referenceObjectData.referenceObjectAnswer.longitude,
                            referenceObjectData.referenceObjectAnswer.latitude,
                        ],
                    },
                    properties: referenceObjectData.referenceObjectAnswer,
                };
            }),
            ...visibleReferenceObjects.nonSelectedObjects.map((referenceObject) => {
                return {
                    type: 'Feature' as const,
                    geometry: {
                        type: 'Point' as const,
                        coordinates: [referenceObject.longitude, referenceObject.latitude],
                    },
                    properties: referenceObject,
                };
            }),
        ]);

        return superCluster.getClusters(
            this.state.bounds
                ? [
                      this.state.bounds.nw.lng,
                      this.state.bounds.se.lat,
                      this.state.bounds.se.lng,
                      this.state.bounds.nw.lat,
                  ]
                : [0, 0, 0, 0],
            Math.round(this.state.centerLocation.zoom)
        );
    }

    private shouldHide(object: ReferenceObject) {
        const altumSubscription = this.props.subscriptions?.subscriptions.find(
            (sub) => sub.type === ReferenceSubscriptionType.ALTUM
        );
        const isPreselected = this.props.visibleReferenceObjects.nonSelectedPreselectedReferences.some(
            (ref) => ref.id === object.id && ref.source === object.source
        );

        return (
            object.source === 'Altum' &&
            altumSubscription?.available &&
            !altumSubscription.usedForAppraisal &&
            isPreselected
        );
    }

    public render() {
        if (
            GLOBAL.google_maps_api_key === undefined ||
            GLOBAL.google_maps_api_key === null ||
            !this.props.appraisal.latitude ||
            !this.props.appraisal.longitude
        ) {
            return null;
        }

        const centerLocation = getCenterLocation(
            {
                lat: this.props.appraisal.latitude,
                lng: this.props.appraisal.longitude,
            },
            []
        );

        const superCluster = new Supercluster<Supercluster.ClusterProperties | ReferenceObject | ReferenceObjectAnswer>(
            {
                radius: 180,
                maxZoom: 19,
            }
        );

        const clusters = this.calculateClusters(superCluster, this.props.visibleReferenceObjects);

        const {clickedReferenceObject} = this.props;

        return (
            <div className="reference-object-map-container">
                <div className="reference-object-map-title">
                    <h2>Object locaties</h2>
                </div>

                {clickedReferenceObject !== null && isReferenceObjectAnswer(clickedReferenceObject) && (
                    <ReferenceObjectMapDetails
                        isSelected={true}
                        referenceObject={null}
                        referenceObjectAnswer={clickedReferenceObject}
                        street={clickedReferenceObject.referenceObject.adres.straat}
                        houseNumber={clickedReferenceObject.referenceObject.adres.huisnummer}
                        letter={clickedReferenceObject.referenceObject.adres.huisnummerToevoeging}
                        postalCode={clickedReferenceObject.referenceObject.adres.postcode}
                        city={clickedReferenceObject.referenceObject.adres.plaats}
                        priceRange={getObjectPrice(this.props.setType, clickedReferenceObject.referenceObject)}
                        floorArea={clickedReferenceObject.referenceObject.gebruiksOppervlakte}
                        saleQuarter={clickedReferenceObject.saleQuarter}
                        onClose={() => this.props.onClickChange(null)}
                        onClick={() => this.props.onClickChange(clickedReferenceObject)}
                        showDetailsModal={() => this.props.showDetailsModal(clickedReferenceObject)}
                        appraisal={this.props.appraisal}
                        questionSet={this.props.questionSet}
                    />
                )}
                {clickedReferenceObject !== null &&
                    !isReferenceObjectAnswer(clickedReferenceObject) &&
                    !this.shouldHide(clickedReferenceObject) && (
                        <ReferenceObjectMapDetails
                            isSelected={false}
                            referenceObject={clickedReferenceObject}
                            referenceObjectAnswer={null}
                            street={clickedReferenceObject.street}
                            houseNumber={clickedReferenceObject.houseNumber}
                            letter={clickedReferenceObject.letter}
                            postalCode={clickedReferenceObject.postalCode}
                            city={clickedReferenceObject.city}
                            priceRange={clickedReferenceObject.priceRange}
                            floorArea={clickedReferenceObject.floorArea}
                            saleQuarter={clickedReferenceObject.saleQuarter}
                            onClose={() => this.props.onClickChange(null)}
                            onClick={() => this.props.onClickChange(clickedReferenceObject)}
                            showDetailsModal={() => this.props.showDetailsModal(clickedReferenceObject)}
                            appraisal={this.props.appraisal}
                            questionSet={this.props.questionSet}
                        />
                    )}

                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: GLOBAL.google_maps_api_key,
                        language: 'nl',
                    }}
                    options={this.createMapOptions}
                    defaultZoom={centerLocation.zoom}
                    defaultCenter={centerLocation.center}
                    onChange={({zoom, bounds}) => {
                        this.setState((oldState) => ({
                            centerLocation: {
                                center: oldState.centerLocation.center,
                                zoom,
                            },
                            bounds,
                        }));
                    }}
                    yesIWantToUseGoogleMapApiInternals={true}
                    shouldUnregisterMapOnUnmount={true}
                    onGoogleApiLoaded={({map}) => {
                        this.mapRef = map;
                    }}
                >
                    <Pointer
                        lat={this.props.appraisal.latitude}
                        lng={this.props.appraisal.longitude}
                        isHovering={false}
                        isBaseObject={true}
                        priceRange={null}
                        isSelectedAnswer={true}
                        imageUrl={null}
                    />

                    {clusters.map((cluster) => {
                        if ('cluster' in cluster.properties && cluster.properties.cluster && cluster.id) {
                            const childIds = superCluster
                                .getLeaves(cluster.id as number, Infinity)
                                .filter(
                                    (
                                        point
                                    ): point is Supercluster.PointFeature<ReferenceObject | ReferenceObjectAnswer> =>
                                        'id' in point.properties
                                )
                                .map((point) => point.properties.id);

                            const isHoveringChild =
                                this.props.hoveringReferenceObjectId !== null &&
                                childIds.includes(this.props.hoveringReferenceObjectId);

                            return (
                                <Cluster
                                    key={cluster.id}
                                    lng={cluster.geometry.coordinates[0]}
                                    lat={cluster.geometry.coordinates[1]}
                                    pointCount={cluster.properties.point_count}
                                    isHovering={isHoveringChild}
                                    onClick={() => this.zoomToCluster(superCluster, cluster)}
                                />
                            );
                        } else {
                            const referenceObject = cluster.properties as ReferenceObject | ReferenceObjectAnswer;
                            if (isReferenceObjectAnswer(referenceObject)) {
                                return (
                                    <Pointer
                                        key={referenceObject.id}
                                        lng={referenceObject.longitude}
                                        lat={referenceObject.latitude}
                                        isHovering={this.props.hoveringReferenceObjectId === referenceObject.id}
                                        onClick={() => this.props.onClickChange(referenceObject)}
                                        onMouseEnter={() => this.props.onHoverChange(referenceObject.id)}
                                        onMouseLeave={() => this.props.onHoverChange(null)}
                                        className="pointer-selected"
                                        isBaseObject={false}
                                        priceRange={getObjectPrice(this.props.setType, referenceObject.referenceObject)}
                                        isSelectedAnswer={true}
                                        imageUrl={
                                            referenceObjectImages(null, referenceObject)
                                                .map((pair) => pair.url)
                                                .filter((url): url is string => url !== null)[0] ?? null
                                        }
                                    />
                                );
                            } else {
                                return (
                                    <Pointer
                                        key={referenceObject.id}
                                        lng={referenceObject.longitude}
                                        lat={referenceObject.latitude}
                                        isHovering={this.props.hoveringReferenceObjectId === referenceObject.id}
                                        onClick={() => this.props.onClickChange(referenceObject)}
                                        onMouseEnter={() => this.props.onHoverChange(referenceObject.id)}
                                        onMouseLeave={() => this.props.onHoverChange(null)}
                                        className={
                                            this.props.clickedReferenceObject === referenceObject
                                                ? 'pointer-selected'
                                                : 'pointer-default'
                                        }
                                        isBaseObject={false}
                                        priceRange={referenceObject.priceRange}
                                        isSelectedAnswer={false}
                                        imageUrl={
                                            referenceObjectImages(referenceObject, null)
                                                .map((pair) => pair.url)
                                                .filter((url): url is string => url !== null)[0]
                                        }
                                    />
                                );
                            }
                        }
                    })}
                </GoogleMapReact>
            </div>
        );
    }
}
