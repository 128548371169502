import {makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../../../../../support/composite_subscription';
import {AnswerTouchState} from '../../../../../../enum/answer_touch_state';
import {SimpleQuestionPresenter} from '../../simple/simple_question_presenter';
import {Referencesv3IndexSource} from './sources/references_v3_index_source';

export class CurrencyIndexableQuestionPresenter extends SimpleQuestionPresenter {
    private subscriptions = new CompositeSubscription();

    @observable
    public indexableSource: Referencesv3IndexSource | null = null;

    @observable
    public indexing = false;

    constructor(
        private sources: Referencesv3IndexSource[],
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);
    }

    public mount() {
        super.mount();

        const answer = this.answerController.answerByIdentifiers(
            this.question.uuid,
            this.parentAnswerUuid ?? null,
            this.iteration ?? null
        );

        if (answer) {
            for (const source of this.sources) {
                source.enable(answer, (isIndexable) => {
                    runInAction(() => {
                        if (isIndexable) {
                            this.indexableSource = source;
                        } else {
                            this.indexableSource = null;
                        }
                    });
                });
            }
        }
    }

    public unmount() {
        super.unmount();

        for (const source of this.sources) {
            source.disable();
        }
    }

    public async index() {
        if (!this.indexableSource || !this.answer) {
            return;
        }

        runInAction(() => {
            this.indexing = true;
        });

        try {
            const indexed = await this.indexableSource.index(this.answer);
            if (!indexed) {
                return;
            }

            this.answerController.onContentsChange(this.answer.uuid, indexed.toString(), AnswerTouchState.TOUCHED);
        } catch (e) {
            console.error('Failed to index price', e);
        } finally {
            runInAction(() => {
                this.indexing = false;
            });
        }
    }
}
