import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from '../simple/simple_question_presenter';

import {GroupCollapsiblePresenter} from './group_collapsible_presenter';
import {Highlight} from '../components/highlight';
import {QuestionContainer} from '../question_container';
import {classNames} from '../../../../../../support/classnames';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {observer} from 'mobx-react';
import {QuestionLoader} from '../../../components/question_loader';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {shouldShowTitle} from '../../../../../../support/should_show_title';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const GroupCollapsible: React.FC<OwnProps> = observer(function GroupCollapsible(props) {
    const presenter = usePresenter(
        (container) =>
            new GroupCollapsiblePresenter(...simpleQuestionPresenterConstructorParametersFactory(props, container))
    );
    if (presenter.isHidden) {
        return null;
    }

    const answer = presenter.answer;
    if (answer === undefined) {
        return <QuestionLoader />;
    }

    const visible = presenter.expanded;

    return (
        <div
            className={classNames('group-collapsible', {
                'group-collapsible-visible': visible,
            })}
        >
            <a
                className={classNames('group-collapsible-header', {
                    'ion-ios-arrow-down': !visible,
                    'ion-ios-arrow-up': visible,
                })}
                onClick={() => {
                    if (!visible) {
                        presenter.expand();
                    } else {
                        presenter.collapse();
                    }
                }}
            >
                <Highlight searchText={presenter.searchText}>
                    {shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel)
                        ? props.question.contents
                        : ''}
                </Highlight>
            </a>
            <div className="group-collapsible-children">
                <div className="group-collapsible-inner">
                    {findChildrenForQuestion(props.question, props.questionSet).map((child) => (
                        <QuestionContainer
                            key={child.uuid}
                            appraisal={props.appraisal}
                            question={child}
                            questionSet={props.questionSet}
                            parentAnswerUuid={answer.uuid}
                            validationErrors={props.validationErrors}
                            validationMessages={props.validationMessages}
                            forceShowValidationMessages={props.forceShowValidationMessages}
                            adaptedDefaultValues={props.adaptedDefaultValues}
                            disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                            hiddenQuestionTypes={props.hiddenQuestionTypes}
                            pagePartsSet={props.pagePartsSet}
                            activePagePart={props.activePagePart}
                            renderingContext={props.renderingContext}
                            questionRenderingData={props.questionRenderingData}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
});
