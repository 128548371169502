import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';

import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const HiddenQuestion: React.FC<OwnProps> = observer(function HiddenQuestion(props) {
    usePresenter((container) => simpleQuestionPresenterFactory(props, container));

    return null;
});
