import {AnswerValidator} from '../answer_validator';
import {Question} from '../../../models/question';
import {Answer} from '../../../models/answer';
import {ValidationMessage, ValidationMessageImportance, ValidationMessageType} from '../validation_message';
import {NormalQuestionType, QuestionType} from '../../../enum/question_type';
import {ConditionsInteractor, EffectState} from '../../conditions/conditions_interactor';
import {ConditionComparison} from '../../../models/condition_comparison';

export class AnswerCompareValidator implements AnswerValidator {
    constructor(private conditionsInteractor: ConditionsInteractor) {}

    validate(question: Question, answer: Answer | null): ValidationMessage[] {
        if (!this.hasApplicableQuestionType(question.type) || answer === null) {
            return [];
        }

        const enabledCompareEffects = this.conditionsInteractor
            .get(question.uuid, answer.parentUuid)
            .filter((effectState) => effectState.effect.task === 'compare' && effectState.enabled);
        return enabledCompareEffects.map((effect) => {
            const messages = this.getMessages(effect);
            return {
                type: ValidationMessageType.TAXAPI,
                importance: ValidationMessageImportance.ERROR,
                question: question,
                answer: answer,
                messages: messages,
                fallbackMessage: messages.join(' '),
                floorInfo: null,
            };
        });
    }

    private hasApplicableQuestionType(questionType: QuestionType) {
        return (
            [
                NormalQuestionType.DATE,
                NormalQuestionType.DATE_PICKER,
                NormalQuestionType.YEAR,
                NormalQuestionType.NUMBER,
                NormalQuestionType.INT_NUMBER,
                NormalQuestionType.INT_COMPARE,
                NormalQuestionType.INT_UNKNOWN,
                NormalQuestionType.NEGATIVE_CURRENCY,
                NormalQuestionType.NEGATIVE_CURRENCY_CENTS,
                NormalQuestionType.CURRENCY,
                NormalQuestionType.CURRENCY_CENTS,
            ] as QuestionType[]
        ).includes(questionType);
    }

    private getMessages(effect: EffectState): string[] {
        if (!effect.conclusions) {
            return [];
        }

        const conditionComparisons: ConditionComparison[] = effect.conclusions
            .map((conclusion) => conclusion.enabled)
            .filter((comparison) => typeof comparison !== 'boolean') as ConditionComparison[];
        return conditionComparisons
            .filter((comparison) => comparison.showMessage)
            .map((comparison) => {
                let message = 'Het antwoord moet';
                switch (comparison.condition) {
                    case '<=':
                        message += ' kleiner of gelijk zijn aan ';
                        break;
                    case '>=':
                        message += ' groter of gelijk zijn aan ';
                        break;
                    case '<':
                        message += ' kleiner zijn dan ';
                        break;
                    case '>':
                        message += ' groter zijn dan ';
                        break;
                    case '==':
                        message += ' gelijk zijn aan ';
                        break;
                    case '!=':
                        message += ' niet gelijk zijn aan ';
                        break;
                }
                if (comparison.questionName) {
                    return message + comparison.questionName + ' (' + comparison.value + ').';
                }

                return message + comparison.value;
            });
    }
}
