import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';

import {QuestionSet} from '../../../../../models/question_set';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {
    questionSet: QuestionSet;
    imageUrl: string | null;
}

export const DescriptionImageQuestion: React.FC<OwnProps> = observer(function DescriptionImageQuestion(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));

    if (presenter.isHidden) {
        return null;
    }
    if (props.imageUrl !== null) {
        return <img className="img-fluid img-fluid-border" src={props.imageUrl} alt="Beschrijving"></img>;
    }
    return null;
});
