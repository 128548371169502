import {FindChildrenResult, TreeItem, buildTree} from './generic_tree';

import {Answer} from '../appraising/models/answer';
import {Question} from '../appraising/models/question';
import {QuestionSet} from '../appraising/models/question_set';
import {NormalQuestionType} from '../appraising/enum/question_type';
import {SymlinkType} from '../appraising/appraise/ui/content/questions/advanced/symlink/symlink_link_presenter';
import {buildQuestionParentAnswerMap} from './page_part_tree';

export interface QuestionAnswerPair {
    question: Question;
    answer: Answer | null;
}

/**
 * @param hadRootAnswer if somewhere above in the tree we had an answer, keep following that path.
 *   To ensure this we set this value to true, which will ensures we only allow answers that match the parentUuid downwards
 */

function getChildren(
    questionSet: QuestionSet,
    answersByQuestionUuidMap: Map<string, Answer[]>,
    questionParentAnswerMap: Map<string, Map<string, Answer[]>>,
    parent: QuestionAnswerPair,
    hadRootAnswer: boolean
): FindChildrenResult<QuestionAnswerPair, boolean> {
    if (parent.question?.type === NormalQuestionType.SYMLINK_LINK && parent.question?.technicalReference) {
        const otherQuestions = questionSet.findQuestionsByTechnicalReference(parent.question.technicalReference);
        const targetQuestion = otherQuestions.filter((q) => q.type !== NormalQuestionType.SYMLINK_LINK);

        if (parent.answer?.contents) {
            const contents = JSON.parse(parent.answer.contents);
            let iteration = parent.question.uuid;
            if (contents?.type === SymlinkType.MIRROR && contents.targetIterationUuid) {
                iteration = contents.targetIterationUuid;
            }
            if (parent.answer) {
                iteration = `${parent.question.uuid}|${parent.answer.uuid}`;
            }
            const byTargetQuestion =
                answersByQuestionUuidMap.get(targetQuestion[0].uuid)?.filter((a) => a.iteration === iteration) ?? [];
            const symlinkTargets = byTargetQuestion.map((a) => {
                return {
                    question: targetQuestion[0],
                    answer: a,
                };
            });
            return {
                children: symlinkTargets,
                metaData: hadRootAnswer ?? parent.answer !== null,
            };
        }

        return {
            children: [
                {
                    question: targetQuestion[0],
                    answer: null,
                },
            ],
            metaData: hadRootAnswer ?? parent.answer !== null,
        };
    }

    const childrenQuestions = questionSet.findChildQuestionsByParentUuid(parent.question.uuid);
    const result: QuestionAnswerPair[] = [];
    for (const childQuestion of childrenQuestions) {
        let childrenAnswers: Answer[] = [];
        if (parent.answer === null) {
            if (!hadRootAnswer) {
                childrenAnswers = questionSet
                    .findChildQuestionsByParentUuid(parent.question.uuid)
                    .flatMap((question) => answersByQuestionUuidMap.get(question.uuid) ?? []);
            }
        } else {
            childrenAnswers = questionParentAnswerMap.get(childQuestion.uuid)?.get(parent.answer.uuid) ?? [];
        }
        if (childrenAnswers.length === 0) {
            result.push({
                question: childQuestion,
                answer: null,
            });
        } else {
            for (const childrenAnswer of childrenAnswers) {
                result.push({
                    question: childQuestion,
                    answer: childrenAnswer,
                });
            }
        }
    }
    return {
        children: result,
        metaData: hadRootAnswer ?? parent.answer !== null,
    };
}

export function buildAnswersByQuestionUuidMap(answers: Answer[]) {
    const answersByQuestionUuidMap = new Map<string, Answer[]>();
    for (const answer of answers) {
        const currentList = answersByQuestionUuidMap.get(answer.questionUuid);
        if (currentList) {
            currentList.push(answer);
        } else {
            answersByQuestionUuidMap.set(answer.questionUuid, [answer]);
        }
    }
    return answersByQuestionUuidMap;
}

export function buildQuestionTree(questionSet: QuestionSet, rootQuestion: Question): TreeItem<QuestionAnswerPair> {
    const rootItem: QuestionAnswerPair = {
        question: rootQuestion,
        answer: null,
    };
    return buildTree(rootItem, (parent) => getChildren(questionSet, new Map(), new Map(), parent, false));
}

export function buildQuestionAnswerTrees(
    questionSet: QuestionSet,
    answers: Answer[],
    rootQuestion: Question
): Array<TreeItem<QuestionAnswerPair>> {
    const answerByQuestionUuidMap = buildAnswersByQuestionUuidMap(answers);
    const questionParentAnswerMap = buildQuestionParentAnswerMap(answers);

    const rootAnswers = answers.filter((answer) => answer.questionUuid === rootQuestion.uuid);
    if (rootAnswers.length === 0) {
        const rootItem: QuestionAnswerPair = {
            question: rootQuestion,
            answer: null,
        };
        return [
            buildTree(rootItem, (parent) =>
                getChildren(questionSet, answerByQuestionUuidMap, questionParentAnswerMap, parent, true)
            ),
        ];
    } else {
        return rootAnswers.map((answer) => {
            const rootItem: QuestionAnswerPair = {
                question: rootQuestion,
                answer: answer,
            };
            return buildTree(rootItem, (parent) =>
                getChildren(questionSet, answerByQuestionUuidMap, questionParentAnswerMap, parent, true)
            );
        });
    }
}

export function flattenTrees<T>(trees: Array<TreeItem<T>>): T[] {
    let result: T[] = [];

    for (const tree of trees) {
        result = [...result, tree.item, ...flattenTrees(tree.children)];
    }

    return result;
}

export function flattenTree<T>(tree: TreeItem<T>): T[] {
    return [tree.item, ...flattenTrees(tree.children)];
}

export function buildQuestionAnswerTree(
    questionSet: QuestionSet,
    answers: Answer[],
    rootAnswer: Answer
): TreeItem<QuestionAnswerPair> {
    const answerByQuestionUuidMap = buildAnswersByQuestionUuidMap(answers);
    const questionParentAnswerMap = buildQuestionParentAnswerMap(answers);

    const rootQuestion = questionSet.findQuestionByUuid(rootAnswer.questionUuid);
    if (rootQuestion === undefined) {
        throw new Error('Question not found!');
    }

    const rootItem: QuestionAnswerPair = {
        question: rootQuestion,
        answer: rootAnswer,
    };

    return buildTree(rootItem, (parent) =>
        getChildren(questionSet, answerByQuestionUuidMap, questionParentAnswerMap, parent, true)
    );
}
