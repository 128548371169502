import {SortingDirection, SortingMethod} from '../../../../../../../../enum/reference_objects_sorting';

import {Appraisal} from '../../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {ReferenceObjectSortingStrategyProvider} from './reference_object_sorting_strategy_provider';
import {ReferenceSale} from '../models/reference_sale';

export class ReferenceObjectSorter {
    constructor(private referenceObjectSortingStrategyProvider: ReferenceObjectSortingStrategyProvider) {}

    public sortReferenceObjects(
        referenceSales: ReferenceSale[],
        appraisal: Appraisal,
        questionSet: QuestionSet,
        sortingMethod: SortingMethod,
        sortingDirection: SortingDirection,
        gebruiksoppervlakteWonen: number | null,
        perceelOppervlakte: number | null
    ): ReferenceSale[] {
        return this.referenceObjectSortingStrategyProvider
            .create(sortingMethod, appraisal, questionSet, gebruiksoppervlakteWonen, perceelOppervlakte)
            .sortReferenceObjects(referenceSales, sortingDirection);
    }
}
