import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from '../simple/simple_question_presenter';

import {AdaptedDefaultValuesMap} from '../../../../../models/adapted_default_values_map';
import {CollapsableChecklistPresenter} from './collapsable_checklist_presenter';
import {QuestionContainer} from '../question_container';
import {QuestionSet} from '../../../../../models/question_set';
import {classNames} from '../../../../../../support/classnames';
import {createValidationMessagesMap} from '../../../../../../support/create_validation_messages_map';
import {observer} from 'mobx-react';
import {QuestionTableCollapsible} from '../../../components/question_table/question_table_collapsible';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';
import {QuestionTableCollapseButton} from '../../../components/question_table/question_table_collapse_button';
import {QuestionLoader} from '../../../components/question_loader';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {shouldShowTitle} from '../../../../../../support/should_show_title';

interface OwnProps extends SimpleQuestionPresenterProps {
    questionSet: QuestionSet;
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
    children?: React.ReactNode;
}

export const CollapsableChecklist: React.FC<OwnProps> = observer(function CollapsableChecklist(props) {
    const presenter = usePresenter(
        (container) =>
            new CollapsableChecklistPresenter(...simpleQuestionPresenterConstructorParametersFactory(props, container))
    );
    if (presenter.isHidden) {
        return null;
    }

    const answer = presenter.answer;
    if (answer === undefined) {
        return <QuestionLoader />;
    }

    if (props.renderingContext === RenderingContextType.COMPACT_GRID_COLUMNS) {
        return renderCompact();
    }

    function renderButton() {
        return (
            <div key="button" className="col-12 col-md-6">
                <button
                    className={classNames('btn btn-sm float-right', {
                        'ion-md-arrow-dropup': presenter.expanded,
                        'ion-md-arrow-dropdown': !presenter.expanded,
                    })}
                    onClick={() => presenter.expand()}
                >
                    {presenter.expanded ? 'Bekijk minder opties' : 'Bekijk meer opties'}
                </button>
            </div>
        );
    }

    function renderCompact() {
        const answer = presenter.answer;
        if (answer === undefined) {
            return <QuestionLoader />;
        }

        return (
            <div className="group-container group-container-compact">
                <a className="d-inline-flex" onClick={() => presenter.expand()}>
                    <h3 className="mt-0 mb-0" style={{width: 'auto'}}>
                        {props.question.contents}
                    </h3>
                    <QuestionTableCollapseButton
                        small
                        collapsed={!presenter.expanded}
                        setCollapsed={(expanded) => (presenter.expanded !== expanded ? presenter.expand() : null)}
                    />
                </a>
                {presenter.childQuestions.slice(0, 1).map((child) => (
                    <QuestionContainer
                        key={child.uuid}
                        question={child}
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        validationErrors={props.validationErrors}
                        validationMessages={createValidationMessagesMap(
                            presenter.validationMessages,
                            props.validationMessages
                        )}
                        forceShowValidationMessages={props.forceShowValidationMessages}
                        disabled={props.disabled}
                        iteration={props.iteration}
                        parentAnswerUuid={answer.uuid}
                        adaptedDefaultValues={props.adaptedDefaultValues}
                        disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                        onChange={props.onChange}
                        activePagePart={props.activePagePart}
                        pagePartsSet={props.pagePartsSet}
                        renderingContext={props.renderingContext}
                        questionRenderingData={props.questionRenderingData}
                    />
                ))}
                <QuestionTableCollapsible>
                    {presenter.childQuestions.slice(1).map((child) => (
                        <QuestionContainer
                            key={child.uuid}
                            question={child}
                            appraisal={props.appraisal}
                            questionSet={props.questionSet}
                            validationErrors={props.validationErrors}
                            validationMessages={createValidationMessagesMap(
                                presenter.validationMessages,
                                props.validationMessages
                            )}
                            forceShowValidationMessages={props.forceShowValidationMessages}
                            disabled={props.disabled}
                            iteration={props.iteration}
                            parentAnswerUuid={answer.uuid}
                            adaptedDefaultValues={props.adaptedDefaultValues}
                            disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                            hiddenQuestionTypes={props.hiddenQuestionTypes}
                            onChange={props.onChange}
                            activePagePart={props.activePagePart}
                            pagePartsSet={props.pagePartsSet}
                            renderingContext={props.renderingContext}
                            questionRenderingData={props.questionRenderingData}
                        />
                    ))}
                </QuestionTableCollapsible>
            </div>
        );
    }

    return (
        <div>
            {shouldShowTitle(props.renderingContext, props.question.showLabel, props.hideLabel) && (
                <h3>{props.question.contents}</h3>
            )}
            <div
                className={classNames('form-checklist', presenter.filledByAutomator, {
                    'automation-filled': presenter.filledByAutomator !== null,
                })}
            >
                <div className="row">
                    {presenter.childQuestions.map((child, index) => {
                        if (!presenter.expanded && index > 0) {
                            return null;
                        }
                        return (
                            <React.Fragment key={child.uuid}>
                                <div className="col-12 col-md-6">
                                    <QuestionContainer
                                        key={child.uuid}
                                        question={child}
                                        appraisal={props.appraisal}
                                        questionSet={props.questionSet}
                                        validationErrors={props.validationErrors}
                                        validationMessages={createValidationMessagesMap(
                                            presenter.validationMessages,
                                            props.validationMessages
                                        )}
                                        forceShowValidationMessages={props.forceShowValidationMessages}
                                        disabled={props.disabled}
                                        iteration={props.iteration}
                                        parentAnswerUuid={answer.uuid}
                                        adaptedDefaultValues={props.adaptedDefaultValues}
                                        disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                                        onChange={props.onChange}
                                        activePagePart={props.activePagePart}
                                        pagePartsSet={props.pagePartsSet}
                                        renderingContext={props.renderingContext}
                                        questionRenderingData={props.questionRenderingData}
                                    />
                                </div>
                                {index === 0 && renderButton()}
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </div>
    );
});
