import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from '../simple/simple_question_presenter';

import {Modal} from '../components/modal';
import {ModalWithAdaptedDefaultValuesQuestionPresenter} from './modal_with_adapted_default_values_presenter';
import {QuestionContainer} from '../question_container';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {observer} from 'mobx-react';
import {QuestionLoader} from '../../../components/question_loader';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {
    initiallyVisible?: boolean;
    children?: React.ReactNode;
}

export const ModalWithAdaptedDefaultValues: React.FC<OwnProps> = observer(function ModalWithAdaptedDefaultValues(
    props
) {
    const presenter = usePresenter(
        (container) =>
            new ModalWithAdaptedDefaultValuesQuestionPresenter(
                props.initiallyVisible === true,
                container.business.adaptedDefaultValuesInteractor(props.appraisal),
                container.business.networkStatusProvider,
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );
    const {isHidden, childAdaptedDefaultValues, answer, isModalVisible, setModalVisibility} = presenter;
    if (isHidden) {
        return null;
    }

    if (answer === undefined) {
        return <QuestionLoader />;
    }

    return (
        <div className="modal-trigger-container">
            <a className="btn btn-sm ion-md-open" onClick={() => setModalVisibility(!isModalVisible)}>
                {props.question.contents}
            </a>
            <Modal
                visible={isModalVisible}
                onHide={() => setModalVisibility(false)}
                onShow={() => setModalVisibility(true)}
            >
                <div className="modal-header">
                    <h2>{props.question.contents}</h2>
                </div>
                <div>
                    {childAdaptedDefaultValues === null ? (
                        <div className="empty-message appraise">
                            <h3>Data verzamelen</h3>
                            <div className="loader">
                                <span>&nbsp;</span>
                            </div>
                        </div>
                    ) : (
                        findChildrenForQuestion(props.question, props.questionSet).map((child) => (
                            <QuestionContainer
                                key={child.uuid}
                                appraisal={props.appraisal}
                                question={child}
                                questionSet={props.questionSet}
                                parentAnswerUuid={answer.uuid}
                                validationErrors={props.validationErrors}
                                validationMessages={props.validationMessages}
                                forceShowValidationMessages={props.forceShowValidationMessages}
                                adaptedDefaultValues={childAdaptedDefaultValues}
                                hiddenQuestionTypes={props.hiddenQuestionTypes}
                                pagePartsSet={props.pagePartsSet}
                                activePagePart={props.activePagePart}
                                renderingContext={props.renderingContext}
                                questionRenderingData={props.questionRenderingData}
                            />
                        ))
                    )}
                    <div className="modal-footer">
                        {props.children}
                        <button
                            className="btn btn-primary btn-full ion-md-checkbox-outline"
                            type="button"
                            onClick={() => setModalVisibility(false)}
                        >
                            Sluiten
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
});
