import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {Presenter} from '../../../../../support/presenter/presenter';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {PagePartsSetInteractor} from '../../../../business/page_parts/page_parts_set_interactor';

export class PagePartsSetSelectorPresenter implements Presenter {
    @observable.ref public pagePartsSets: PagePartsSet[] | null = null;
    @observable public isConfigSelectorOpen = false;

    @computed
    public get visiblePagePartsSets(): PagePartsSet[] | null {
        return this.pagePartsSets?.filter((set) => set.config.isVisible === true) ?? null;
    }

    private subscriptions = new CompositeSubscription();

    constructor(private pagePartsSetInteractor: PagePartsSetInteractor) {
        makeObservable(this);
    }

    public async mount() {
        this.subscriptions.add(
            this.pagePartsSetInteractor.pagePartsSetsStream().subscribe((pagePartsSets) =>
                runInAction(() => {
                    if (pagePartsSets) {
                        this.pagePartsSets = pagePartsSets;
                    } else {
                        this.pagePartsSets = null;
                    }
                })
            )
        );
    }

    public async unmount() {
        this.subscriptions.clear();
    }

    @action
    public setIsConfigSelectorOpen = (isOpen: boolean) => {
        this.isConfigSelectorOpen = isOpen;
    };

    @action
    public onConfigChange = (pagePartsSet: PagePartsSet) => {
        this.pagePartsSetInteractor.setActiveConfigId(pagePartsSet.id);
        this.isConfigSelectorOpen = false;
    };
}
