import {CadastralDocument} from '../../models/cadastral_document';

export interface ApiCadastralDocument {
    name: string;
    register: string;
    part: string;
    number: string;
    series: string | null;
}

export function apiCadastralDocumentToCadastralDocument(apiCadastralDocument: ApiCadastralDocument): CadastralDocument {
    return {
        name: apiCadastralDocument.name,
        register: apiCadastralDocument.register,
        part: apiCadastralDocument.part,
        number: apiCadastralDocument.number,
        series: apiCadastralDocument.series,
    };
}
