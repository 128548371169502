import {ReferenceObjectAnswer, referenceObjectAnswerFromSale} from '../../models/reference_object_answer';
import {makeObservable, observable} from 'mobx';

import {Presenter} from '../../../../../../../../../../support/presenter/presenter';
import {ReferenceObject} from '../../models/reference_object';
import {ReferenceObjectProvider} from '../../../../../../../../../business/reference_object_provider';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';

export class ReferenceObjectDetailsModalPresenter implements Presenter {
    @observable public isAdding = false;

    constructor(
        private referenceObject: ReferenceObject,
        private propsOnAdd: (referenceObject: ReferenceObject, referenceObjectAnswer: ReferenceObjectAnswer) => void,
        private referenceObjectInteractor: ReferenceObjectProvider
    ) {
        makeObservable(this);
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public async onAdd() {
        try {
            this.isAdding = true;

            const sale = await this.referenceObjectInteractor.getSale(this.referenceObject.id, SetType.SOLD);

            this.propsOnAdd(this.referenceObject, referenceObjectAnswerFromSale(sale));
        } finally {
            this.isAdding = false;
        }
    }
}
