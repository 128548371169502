import {Presenter} from '../../../../../../support/presenter/presenter';
import {Appraisal} from '../../../../../models/appraisal';
import {Question} from '../../../../../models/question';
import {ObjectType} from '../../../../../enum/object_type';
import {AppraisalApi} from '../../../../../network/appraisal_api';
import {computed, makeObservable} from 'mobx';
import {AppraisalState} from '../../../../../enum/appraisal_state';
import {AppraisalValidationType} from '../../../../../enum/appraisal_validation_type';

export class AppraisalDetailsPresenter implements Presenter {
    private _appraisal: Appraisal;
    private _question: Question;

    constructor(
        appraisal: Appraisal,
        question: Question,
        private onChange: (appraisal: Appraisal) => void,
        private appraisalApi: AppraisalApi
    ) {
        makeObservable(this);
        this._appraisal = appraisal;
        this._question = question;
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    @computed
    public get isDisabled(): boolean {
        if (!this._appraisal.isEditableAppraisal) {
            return true;
        }
        if (this._appraisal.validationType === AppraisalValidationType.NOT_VALIDATED) {
            return this._appraisal.isFrozen && this._question.freezes;
        }
        return (
            (this._appraisal.isFrozen && this._question.freezes) ||
            this._appraisal.status === AppraisalState.APPROVED ||
            this._appraisal.status === AppraisalState.CANCELED ||
            this._appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION
        );
    }

    public async onObjectTypeChange(value: ObjectType, appraisal: Appraisal) {
        this.onChange({
            ...appraisal,
            objectType: value,
        });

        await this.appraisalApi.updateObjectType(appraisal.id, value);
    }
}
