import $ from 'jquery';

(() => {
    const emailAddressesInput = $('#client_notification_emails');

    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
        if (emailAddressesInput.length > 0) {
            let emails = emailAddressesInput[0].value.split(',');
            emailAddressesInput[0].value = ''; // Clear the input
            if (emails.length === 1 && emails[0] === '') {
                emailAddressesInput[0].value = '';
            } else if (emails.length === 1 && emails[0] !== '') {
                emailAddressesInput[0].value = emails[0];
            } else {
                const emailsLength = emails.length;
                emails.forEach(function (email, key) {
                    if (key === emailsLength - 1) {
                        emailAddressesInput[0].value += email; // Last email, no comma
                    } else {
                        emailAddressesInput[0].value += email + ', '; // Reformat with comma
                    }
                });
            }
        }
    }
})();
