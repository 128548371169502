import * as React from 'react';

import {PagePart} from '../../../../../../models/page_part';
import {PagePartsSet} from '../../../../../../models/page_parts_set';
import {QuestionContainer} from '../../question_container';
import {QuestionSet} from '../../../../../../models/question_set';
import {SimpleQuestionPresenterProps} from '../../simple/simple_question_presenter';
import {getPagePartChildren} from './support/get_page_part_children';

interface OwnProps extends Omit<SimpleQuestionPresenterProps, 'question'> {
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet;
    pagePartUuid: string;
    activePagePart: PagePart;
}

export const PagePartChildren: React.FC<OwnProps> = function PagePartChildren({
    questionSet,
    pagePartsSet,
    pagePartUuid,
    ...props
}) {
    const pagePartChildrenQuestions = React.useMemo(() => {
        return getPagePartChildren(pagePartUuid, pagePartsSet, questionSet);
    }, [pagePartUuid, pagePartsSet, questionSet]);

    return (
        <>
            {pagePartChildrenQuestions.map((child) => (
                <QuestionContainer
                    key={child.id}
                    appraisal={props.appraisal}
                    question={child.question}
                    onChange={props.onChange}
                    questionSet={questionSet}
                    disabled={props.disabled}
                    validationErrors={props.validationErrors}
                    validationMessages={props.validationMessages}
                    parentAnswerUuid={props.parentAnswerUuid}
                    adaptedDefaultValues={props.adaptedDefaultValues}
                    disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                    hiddenQuestionTypes={props.hiddenQuestionTypes}
                    pagePartsSet={pagePartsSet}
                    activePagePart={child.pagePart}
                    renderingContext={props.renderingContext}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    questionRenderingData={props.questionRenderingData}
                />
            ))}
        </>
    );
};
