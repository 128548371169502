import {ReferenceObjectSortingStrategy} from './reference_object_sorting_strategy';
import {SortingDirection} from '../../../../../../../../../../enum/reference_objects_sorting';
import {DistanceObject, DistanceProvider} from '../../../../../../../../../../business/distance_provider';

export type DistanceSortableObject = DistanceObject;

export class ReferenceObjectSorterByDistance implements ReferenceObjectSortingStrategy {
    constructor(private distanceProvider: DistanceProvider) {}

    public sortReferenceObjects<TUnkown, TSortableObject extends DistanceSortableObject>(
        referenceObjects: TUnkown[],
        sortableObjectTransformer: (item: TUnkown) => TSortableObject,
        sortingDirection: SortingDirection
    ): TUnkown[] {
        return referenceObjects.sort((referenceObject1, referenceObject2) => {
            return (this.distanceProvider.getDistanceToAppraisal(sortableObjectTransformer(referenceObject1)) ?? 0) >
                (this.distanceProvider.getDistanceToAppraisal(sortableObjectTransformer(referenceObject2)) ?? 0)
                ? sortingDirection === SortingDirection.DESCENDING
                    ? -1
                    : 1
                : sortingDirection === SortingDirection.DESCENDING
                ? 1
                : -1;
        });
    }
}
