import {action, makeObservable, observable} from 'mobx';

import {Presenter} from '../../../../../support/presenter/presenter';
import {Question} from '../../../../models/question';
import {QuestionSet} from '../../../../models/question_set';
import {ReportParagraphReference} from '../../../../models/report_paragraph_reference';
import {SidebarItem} from '../../../../business/sidebar_tree_builder';
import {SidebarItemForQuestionProvider} from '../../../../business/sidebar_item_for_question_provider';
import {first} from 'rxjs/operators';

interface ParagraphReferencesGroup {
    section: string; //e.g. G
    title: string; //e.g. G.1.a
    paragraphReferences: ParagraphReference[];
}

interface ParagraphReference {
    section: string;
    parentSection: string;
    title: string;
    sidebarItem: SidebarItem;
    question: Question;
}

export class ParagraphReferencesListPresenter implements Presenter {
    @observable public activeGroup: string | null = null;
    @observable.ref public reportParagraphReferencesGrouped: ParagraphReferencesGroup[] = [];

    constructor(
        private questionSet: QuestionSet,
        private sidebarItemForQuestionProvider: SidebarItemForQuestionProvider
    ) {
        makeObservable(this);
    }

    @action
    public setActiveGroup(section: string) {
        this.activeGroup = section;
    }

    public isActiveGroup(group: string) {
        return this.activeGroup === group;
    }

    public async mount() {
        this.reportParagraphReferencesGrouped = await this.getReportParagraphReferencesGrouped();
    }

    public unmount(): void {
        //Noop
    }

    private async getReportParagraphReferencesGrouped(): Promise<ParagraphReferencesGroup[]> {
        const paragraphReferencesGroups: {[reference: string]: ParagraphReferencesGroup} = {};

        await Promise.all(
            this.questionSet.reportDefintionConfig.reportParagraphReferences.map(
                async (reportParagraphReference: ReportParagraphReference) => {
                    const groupSection: string = reportParagraphReference.section.substring(0, 1);
                    const paragraphReferencesGroup = this.getParagraphReferencesGroup(
                        paragraphReferencesGroups,
                        reportParagraphReference,
                        groupSection
                    );
                    const question = this.questionSet.findQuestionByReportParagraphReference(
                        reportParagraphReference.section
                    );

                    // Only add references when there are actual questions and answers
                    if (question !== null) {
                        const sidebarItem = await this.sidebarItemForQuestionProvider
                            .find(question)
                            .pipe(first())
                            .toPromise();

                        if (sidebarItem) {
                            paragraphReferencesGroups[groupSection].paragraphReferences.push(
                                this.createParagraphReference(
                                    paragraphReferencesGroup,
                                    reportParagraphReference,
                                    sidebarItem,
                                    question
                                )
                            );
                            paragraphReferencesGroups[groupSection].paragraphReferences = this.sortParagraphReferences(
                                paragraphReferencesGroups[groupSection].paragraphReferences
                            );
                        }
                    }
                }
            )
        );

        return Object.keys(paragraphReferencesGroups)
            .map((key) => paragraphReferencesGroups[key])
            .filter(this.filterInvalidParagraphReferencesGroups);
    }

    private sortParagraphReferences(paragraphReferences: ParagraphReference[]) {
        return paragraphReferences.sort(function (a, b) {
            if (a.section < b.section) {
                return -1;
            }
            if (a.section > b.section) {
                return 1;
            }
            return 0;
        });
    }

    private createParagraphReference(
        paragraphReferencesGroup: ParagraphReferencesGroup,
        reportParagraphReference: ReportParagraphReference,
        sidebarItem: SidebarItem,
        question: Question
    ): ParagraphReference {
        return {
            section: reportParagraphReference.section,
            parentSection: paragraphReferencesGroup.section,
            title: reportParagraphReference.title,
            sidebarItem: sidebarItem,
            question: question,
        };
    }

    private getParagraphReferencesGroup(
        paragraphReferencesGroups: {[reference: string]: ParagraphReferencesGroup},
        reportParagraphReference: ReportParagraphReference,
        groupSection: string
    ): ParagraphReferencesGroup {
        if (paragraphReferencesGroups[groupSection] === undefined) {
            paragraphReferencesGroups[groupSection] = {
                section: reportParagraphReference.section,
                title: reportParagraphReference.title,
                paragraphReferences: [],
            };
        }

        return paragraphReferencesGroups[groupSection];
    }

    private filterInvalidParagraphReferencesGroups(paragraphReferencesGroup: ParagraphReferencesGroup): boolean {
        return paragraphReferencesGroup.paragraphReferences.length !== 0;
    }
}
