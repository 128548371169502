import * as React from 'react';

import {Appraisal} from '../../../../../../../../../../../models/appraisal';
import {FunctionComponent} from 'react';
import {NullableNumberInput} from '../../../inputs/nullable_number';
import {PlotAreaBadgeAdapter} from '../../../badges/plot_area_badge_adapter';
import {QuestionSet} from '../../../../../../../../../../../models/question_set';
import {ReferenceObjectAnswer} from '../../../../models/reference_object_answer';
import {isApartment} from '../../../../../../../../../../../business/support/is_apartment_check';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    perceelOppervlakte: number | null;
    referenceObjectAnswer: ReferenceObjectAnswer;
    onPerceelOppervlakteChange: (value: number | null) => void;
    isFrozen: boolean;
}

export const Area: FunctionComponent<OwnProps> = ({
    appraisal,
    questionSet,
    perceelOppervlakte,
    referenceObjectAnswer,
    onPerceelOppervlakteChange,
    isFrozen,
}) => {
    const validationInstituteReferentieObject = referenceObjectAnswer.referenceObject;

    return (
        <div className="row">
            <div className="col-12">
                <dl>
                    <span className="row">
                        <span className="col-sm-6">
                            <NullableNumberInput
                                isRequired={!isApartment(validationInstituteReferentieObject.woningType)}
                                name="perceeloppervlakte"
                                label="Perceeloppervlakte (m²)"
                                value={validationInstituteReferentieObject.perceelOppervlakte}
                                badge={
                                    perceelOppervlakte !== null &&
                                    validationInstituteReferentieObject.perceelOppervlakte !== null ? (
                                        <PlotAreaBadgeAdapter
                                            plotArea={validationInstituteReferentieObject.perceelOppervlakte}
                                            perceelOppervlakte={perceelOppervlakte}
                                            appraisal={appraisal}
                                            questionSet={questionSet}
                                        />
                                    ) : null
                                }
                                onChange={onPerceelOppervlakteChange}
                                disabled={isFrozen}
                            />
                        </span>
                    </span>
                </dl>
            </div>
        </div>
    );
};
