import {
    findChildRecursiveByPredicate,
    findChildrenRecursiveByPredicate,
    findParentByPredicateRecursive,
    TreeItem,
} from '../../../../../../../../../support/generic_tree';

import {QuestionAnswerPair} from '../../../../../../../../../support/question_answer_tree';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {TechnicalReference} from '../../../../../../../../enum/technical_reference';
import {V3ReferenceSet} from './reference_sets/reference_sets_provider';
import {isNumeric} from '../../../../../../../../../support/util';
import {NormalQuestionType} from '../../../../../../../../enum/question_type';

export interface ReferenceObjectData {
    treeItem: TreeItem<QuestionAnswerPair>;
    referenceObjectTreeItems: ReferenceObjectTreeItems;
    referenceObjectValues: ReferenceObjectValues;
    referenceObjectAnswer: ReferenceObjectAnswer;
}

export interface ReferenceObjectDataWithSet {
    data: ReferenceObjectData;
    referenceSet: V3ReferenceSet<TreeItem<QuestionAnswerPair>>;
}

export function createReferenceObjectDataForAnswer(
    referenceSets: Array<V3ReferenceSet<TreeItem<QuestionAnswerPair>>> | null,
    questionUuid: string,
    parentAnswerUuid: string | null
): ReferenceObjectDataWithSet | null {
    if (referenceSets === null) {
        return null;
    }

    for (const referenceSet of referenceSets) {
        const thisAnswerItem = findChildRecursiveByPredicate(
            referenceSet.groupTree,
            (item) => item.answer?.questionUuid === questionUuid && item.answer.parentUuid === parentAnswerUuid
        );
        if (thisAnswerItem !== null) {
            const iteratorParent = findParentByPredicateRecursive(
                thisAnswerItem,
                (item) => item.question.technicalReference === TechnicalReference.REFERENCE_OBJECTS_V3_ITERATOR_GROUP
            );

            if (iteratorParent) {
                const data = createReferenceObjectData(iteratorParent);
                if (data) {
                    return {
                        data,
                        referenceSet,
                    };
                }
            }
        }
    }

    return null;
}

function getChildItem(treeItem: TreeItem<QuestionAnswerPair>, technicalReference: TechnicalReference) {
    return findChildRecursiveByPredicate(treeItem, (i) => i.question.technicalReference === technicalReference);
}

function getChildItems(treeItem: TreeItem<QuestionAnswerPair>, technicalReference: TechnicalReference) {
    return findChildrenRecursiveByPredicate(treeItem, (i) => i.question.technicalReference === technicalReference);
}

function getStringQuestionContents(treeItem: TreeItem<QuestionAnswerPair> | null) {
    if (treeItem === null) {
        return null;
    }

    if (treeItem.item.answer) {
        if (treeItem.item.answer.answerOptionId !== null) {
            const selectedAnswerOption = treeItem.item.question.answerOptions.find(
                (ao) => ao.id === treeItem.item.answer?.answerOptionId
            );
            if (selectedAnswerOption) {
                return selectedAnswerOption.contents;
            }
        }

        if (treeItem.item.answer.contents !== null) {
            return treeItem.item.answer.contents;
        }
    }

    return null;
}

function getArrayQuestionContents(treeItems: TreeItem<QuestionAnswerPair>[] | null): string[] {
    if (treeItems === null) {
        return [];
    }
    return treeItems
        .filter((treeItem) => treeItem.item.answer !== null)
        .map((treeItem) => {
            if (treeItem.item.question.type === NormalQuestionType.BOOLEAN) {
                return treeItem.item.answer?.contents === '1' ? treeItem.item.question.contents : null;
            } else if (treeItem.item.answer?.answerOptionId) {
                const answerOption = treeItem.item.question.answerOptions.find(
                    (ao) => ao.id === treeItem.item.answer?.answerOptionId
                );
                return answerOption?.reportValue ?? answerOption?.contents ?? null;
            }
            return treeItem.item.answer?.contents ?? null;
        })
        .filter((value) => value !== null) as string[];
}

function getParsedQuestionContents<T>(
    treeItem: TreeItem<QuestionAnswerPair> | null,
    parseFunction: (value: string) => T | null
) {
    const value = getStringQuestionContents(treeItem);
    if (value) {
        if (parseFunction !== null) {
            const parsed = parseFunction(value);
            if (parsed !== null) {
                return parsed;
            }
        } else {
            return value as unknown as T;
        }
    }

    return null;
}

function parseValueAsInt(value: string | null) {
    if (isNumeric(value)) {
        return parseInt(value, 10);
    }

    return null;
}

function parseValueAsBool(value: string | null) {
    if (value === null) {
        return false;
    }
    return value === '1' || value === 'true' || value === 'ja';
}

export function createReferenceObjectData(treeItem: TreeItem<QuestionAnswerPair>): ReferenceObjectData | null {
    if (treeItem.item.question.technicalReference !== TechnicalReference.REFERENCE_OBJECTS_V3_ITERATOR_GROUP) {
        throw new Error(
            'To calculate ReferenceObjectData we need a treeItem with TechnicalReference.REFERENCE_OBJECTS_V3_ITERATOR_GROUP'
        );
    }

    if (treeItem.item.answer?.contents) {
        const referenceObjectAnswer: ReferenceObjectAnswer = JSON.parse(treeItem.item.answer.contents);
        const referenceObjectTreeItems = getReferenceObjectItems(treeItem);
        const referenceObjectValues = extractReferenceObjectValues(referenceObjectTreeItems);

        return {
            treeItem,
            referenceObjectTreeItems,
            referenceObjectValues,
            referenceObjectAnswer: {
                ...referenceObjectAnswer,
                uploadedPhotoUrl: referenceObjectValues.uploadedPhotoUrl,
                images: referenceObjectAnswer.images?.map((image) => ({
                    ...image,
                    date: image.date ? new Date(image.date) : null,
                })),
                referenceObject: {
                    ...referenceObjectAnswer.referenceObject,
                    adres: {
                        ...referenceObjectAnswer.referenceObject.adres,
                        huisnummerToevoeging:
                            referenceObjectValues.adres.huisnummerToevoeging ??
                            referenceObjectAnswer.referenceObject.adres.huisnummerToevoeging,
                    },
                    bouwjaar: referenceObjectValues.bouwjaar ?? referenceObjectAnswer.referenceObject.bouwjaar,
                    energielabel:
                        referenceObjectValues.energielabel ?? referenceObjectAnswer.referenceObject.energielabel,
                    energielabelGeldigTot:
                        referenceObjectValues.energielabelGeldigTot ??
                        referenceObjectAnswer.referenceObject.energielabelGeldigTot,
                    woningType: referenceObjectValues.woningType ?? referenceObjectAnswer.referenceObject.woningType,
                    bronGegevens:
                        referenceObjectValues.bronGegevens ?? referenceObjectAnswer.referenceObject.bronGegevens,
                    gebruiksOppervlakte:
                        referenceObjectValues.gebruiksOppervlakte ??
                        referenceObjectAnswer.referenceObject.gebruiksOppervlakte,
                    inhoud: referenceObjectValues.inhoud ?? referenceObjectAnswer.referenceObject.inhoud,
                    perceelOppervlakte:
                        referenceObjectValues.perceelOppervlakte ??
                        referenceObjectAnswer.referenceObject.perceelOppervlakte,
                    aanvangsHuurprijsPerMaand:
                        referenceObjectValues.aanvangsHuurprijsPerMaand ??
                        referenceObjectAnswer.referenceObject.aanvangsHuurprijsPerMaand,
                    gecorrigeerdeHuurprijsPerMaand:
                        referenceObjectValues.gecorrigeerdeHuurprijsPerMaand ??
                        referenceObjectAnswer.referenceObject.gecorrigeerdeHuurprijsPerMaand,
                    ingangsdatumHuur:
                        referenceObjectValues.ingangsdatumHuur ??
                        referenceObjectAnswer.referenceObject.ingangsdatumHuur,
                    verkoopprijs:
                        referenceObjectValues.verkoopprijs ?? referenceObjectAnswer.referenceObject.verkoopprijs,
                    gecorrigeerdeVerkoopprijs:
                        referenceObjectValues.gecorrigeerdeVerkoopprijs ??
                        referenceObjectAnswer.referenceObject.gecorrigeerdeVerkoopprijs,
                    verkoopdatum:
                        referenceObjectValues.verkoopdatum ?? referenceObjectAnswer.referenceObject.verkoopdatum,
                    transportdatum:
                        referenceObjectValues.transportdatum ?? referenceObjectAnswer.referenceObject.transportdatum,
                    verkochtBinnen:
                        referenceObjectValues.verkochtBinnen ?? referenceObjectAnswer.referenceObject.verkochtBinnen,
                    eigendomssituatieVolleEigendom:
                        referenceObjectValues.eigendomssituatieVolleEigendom ??
                        referenceObjectAnswer.referenceObject.eigendomssituatieVolleEigendom,
                    eigendomssituatieRechtErfpacht:
                        referenceObjectValues.eigendomssituatieRechtErfpacht ??
                        referenceObjectAnswer.referenceObject.eigendomssituatieRechtErfpacht,
                    eigendomssituatieRechtOndererfpacht:
                        referenceObjectValues.eigendomssituatieRechtOndererfpacht ??
                        referenceObjectAnswer.referenceObject.eigendomssituatieRechtOndererfpacht,
                    eigendomssituatieRechtOpstal:
                        referenceObjectValues.eigendomssituatieRechtOpstal ??
                        referenceObjectAnswer.referenceObject.eigendomssituatieRechtOpstal,
                    eigendomssituatieRechtGebruikBewoning:
                        referenceObjectValues.eigendomssituatieRechtGebruikBewoning ??
                        referenceObjectAnswer.referenceObject.eigendomssituatieRechtGebruikBewoning,
                    eigendomssituatieRechtVruchtgebruik:
                        referenceObjectValues.eigendomssituatieRechtVruchtgebruik ??
                        referenceObjectAnswer.referenceObject.eigendomssituatieRechtVruchtgebruik,
                    eigendomssituatieRechtAnders:
                        referenceObjectValues.eigendomssituatieRechtAnders ??
                        referenceObjectAnswer.referenceObject.eigendomssituatieRechtAnders,
                    eigendomssituatieToelichting:
                        referenceObjectValues.eigendomssituatieToelichting ??
                        referenceObjectAnswer.referenceObject.eigendomssituatieToelichting,
                    eigendomssituatieStatus:
                        referenceObjectValues.eigendomssituatieStatus ??
                        referenceObjectAnswer.referenceObject.eigendomssituatieStatus,
                    eigendomssituatieUitleg:
                        referenceObjectValues.eigendomssituatieUitleg ??
                        referenceObjectAnswer.referenceObject.eigendomssituatieUitleg,
                    onderhoudssituatie:
                        referenceObjectValues.onderhoudssituatie ??
                        referenceObjectAnswer.referenceObject.onderhoudssituatie,
                    onderhoudsSituatieStatus:
                        referenceObjectValues.onderhoudsSituatieStatus ??
                        referenceObjectAnswer.referenceObject.onderhoudsSituatieStatus,
                    onderhoudsSituatieUitleg:
                        referenceObjectValues.onderhoudsSituatieUitleg ??
                        referenceObjectAnswer.referenceObject.onderhoudsSituatieUitleg,
                    woningTypeStatus:
                        referenceObjectValues.woningTypeStatus ??
                        referenceObjectAnswer.referenceObject.woningTypeStatus,
                    woningTypeUitleg:
                        referenceObjectValues.woningTypeUitleg ??
                        referenceObjectAnswer.referenceObject.woningTypeUitleg,
                    mateVanLuxeStatus:
                        referenceObjectValues.mateVanLuxeStatus ??
                        referenceObjectAnswer.referenceObject.mateVanLuxeStatus,
                    mateVanLuxeUitleg:
                        referenceObjectValues.mateVanLuxeUitleg ??
                        referenceObjectAnswer.referenceObject.mateVanLuxeUitleg,
                    mateVanDoelmatigheidStatus:
                        referenceObjectValues.mateVanDoelmatigheidStatus ??
                        referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus,
                    mateVanDoelmatigheidUitleg:
                        referenceObjectValues.mateVanDoelmatigheidUitleg ??
                        referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg,
                    liggingStatus:
                        referenceObjectValues.liggingStatus ?? referenceObjectAnswer.referenceObject.liggingStatus,
                    liggingUitleg:
                        referenceObjectValues.liggingUitleg ?? referenceObjectAnswer.referenceObject.liggingUitleg,
                    aanbouw: referenceObjectValues.aanbouw?.join(', ') ?? referenceObjectAnswer.referenceObject.aanbouw,
                    aanbouwStatus:
                        referenceObjectValues.aanbouwStatus ?? referenceObjectAnswer.referenceObject.aanbouwStatus,
                    aanbouwUitleg:
                        referenceObjectValues.aanbouwUitleg ?? referenceObjectAnswer.referenceObject.aanbouwUitleg,
                    andereWezenlijkeVerschillen:
                        referenceObjectValues.andereWezenlijkeVerschillen ??
                        referenceObjectAnswer.referenceObject.andereWezenlijkeVerschillen,
                    andereWezenlijkeVerschillenUitleg:
                        referenceObjectValues.andereWezenlijkeVerschillenUitleg ??
                        referenceObjectAnswer.referenceObject.andereWezenlijkeVerschillenUitleg,
                    toelichtingGebruikReferentieObject:
                        referenceObjectValues.toelichtingGebruikReferentieObject ??
                        referenceObjectAnswer.referenceObject.toelichtingGebruikReferentieObject,
                    vraagprijs: referenceObjectValues.vraagprijs ?? referenceObjectAnswer.referenceObject.vraagprijs,
                    oorspronkelijkeVraagprijs:
                        referenceObjectValues.oorspronkelijkeVraagprijs ??
                        referenceObjectAnswer.referenceObject.oorspronkelijkeVraagprijs,
                    teKoopSinds: referenceObjectValues.teKoopSinds ?? referenceObjectAnswer.referenceObject.teKoopSinds,
                },
            },
        };
    }

    return null;
}

export interface ReferenceObjectValues {
    uploadedPhotoUrl: string | null;
    adres: {
        huisnummerToevoeging: string | null;
    };
    bouwjaar: number | null;
    energielabel: string | null;
    energielabelGeldigTot: string | null;
    woningType: string | null;
    bronGegevens: string | null;
    bronGegevensToelichting: string | null;
    gebruiksOppervlakte: number | null;
    inhoud: number | null;
    perceelOppervlakte: number | null;
    aanvangsHuurprijsPerMaand: number | null;
    gecorrigeerdeHuurprijsPerMaand: number | null;
    ingangsdatumHuur: string | null;
    verkoopprijs: number | null;
    gecorrigeerdeVerkoopprijs: number | null;
    verkoopdatum: string | null;
    transportdatum: string | null;
    verkochtBinnen: string | null;
    eigendomssituatieVolleEigendom: boolean | null;
    eigendomssituatieRechtErfpacht: boolean | null;
    eigendomssituatieRechtOndererfpacht: boolean | null;
    eigendomssituatieRechtOpstal: boolean | null;
    eigendomssituatieRechtGebruikBewoning: boolean | null;
    eigendomssituatieRechtVruchtgebruik: boolean | null;
    eigendomssituatieRechtAnders: boolean | null;
    eigendomssituatieToelichting: string | null;
    eigendomssituatieStatus: string | null;
    eigendomssituatieUitleg: string | null;
    onderhoudssituatie: string | null;
    onderhoudsSituatieStatus: string | null;
    onderhoudsSituatieUitleg: string | null;
    woningTypeStatus: string | null;
    woningTypeUitleg: string | null;
    mateVanLuxeStatus: string | null;
    mateVanLuxeUitleg: string | null;
    mateVanDoelmatigheidStatus: string | null;
    mateVanDoelmatigheidUitleg: string | null;
    liggingStatus: string | null;
    liggingUitleg: string | null;
    aanbouw: string[] | null;
    aanbouwStatus: string | null;
    aanbouwUitleg: string | null;
    andereWezenlijkeVerschillen: boolean | null;
    andereWezenlijkeVerschillenUitleg: string | null;
    toelichtingGebruikReferentieObject: string | null;
    vraagprijs: number | null;
    oorspronkelijkeVraagprijs: number | null;
    teKoopSinds: string | null;
}

function extractReferenceObjectValues(treeItems: ReferenceObjectTreeItems): ReferenceObjectValues {
    return {
        uploadedPhotoUrl: treeItems.uploadedPhotoUrl?.item.answer?.file?.url ?? null,
        adres: {
            huisnummerToevoeging: getStringQuestionContents(treeItems.adres.huisnummerToevoeging),
        },
        bouwjaar: getParsedQuestionContents(treeItems.bouwjaar, parseValueAsInt),
        energielabel: getStringQuestionContents(treeItems.energielabel),
        energielabelGeldigTot: getStringQuestionContents(treeItems.energielabelGeldigTot),
        woningType: getStringQuestionContents(treeItems.woningType),
        bronGegevens: getStringQuestionContents(treeItems.bronGegevens),
        bronGegevensToelichting: getStringQuestionContents(treeItems.bronGegevensToelichting),
        gebruiksOppervlakte: getParsedQuestionContents(treeItems.gebruiksOppervlakte, parseValueAsInt),
        inhoud: getParsedQuestionContents(treeItems.inhoud, parseValueAsInt),
        perceelOppervlakte: getParsedQuestionContents(treeItems.perceelOppervlakte, parseValueAsInt),
        aanvangsHuurprijsPerMaand: getParsedQuestionContents(treeItems.aanvangsHuurprijsPerMaand, parseValueAsInt),
        gecorrigeerdeHuurprijsPerMaand: getParsedQuestionContents(
            treeItems.gecorrigeerdeHuurprijsPerMaand,
            parseValueAsInt
        ),
        ingangsdatumHuur: getStringQuestionContents(treeItems.ingangsdatumHuur),
        verkoopprijs: getParsedQuestionContents(treeItems.verkoopprijs, parseValueAsInt),
        gecorrigeerdeVerkoopprijs: getParsedQuestionContents(treeItems.gecorrigeerdeVerkoopprijs, parseValueAsInt),
        verkoopdatum: getStringQuestionContents(treeItems.verkoopdatum),
        transportdatum: getStringQuestionContents(treeItems.transportdatum),
        verkochtBinnen: getStringQuestionContents(treeItems.verkochtBinnen),
        eigendomssituatieVolleEigendom: getParsedQuestionContents(
            treeItems.eigendomssituatieVolleEigendom,
            parseValueAsBool
        ),
        eigendomssituatieRechtErfpacht: getParsedQuestionContents(
            treeItems.eigendomssituatieRechtErfpacht,
            parseValueAsBool
        ),
        eigendomssituatieRechtOndererfpacht: getParsedQuestionContents(
            treeItems.eigendomssituatieRechtOndererfpacht,
            parseValueAsBool
        ),
        eigendomssituatieRechtOpstal: getParsedQuestionContents(
            treeItems.eigendomssituatieRechtOpstal,
            parseValueAsBool
        ),
        eigendomssituatieRechtGebruikBewoning: getParsedQuestionContents(
            treeItems.eigendomssituatieRechtGebruikBewoning,
            parseValueAsBool
        ),
        eigendomssituatieRechtVruchtgebruik: getParsedQuestionContents(
            treeItems.eigendomssituatieRechtVruchtgebruik,
            parseValueAsBool
        ),
        eigendomssituatieRechtAnders: getParsedQuestionContents(
            treeItems.eigendomssituatieRechtAnders,
            parseValueAsBool
        ),
        eigendomssituatieToelichting: getStringQuestionContents(treeItems.eigendomssituatieToelichting),
        eigendomssituatieStatus: getStringQuestionContents(treeItems.eigendomssituatieStatus),
        eigendomssituatieUitleg: getStringQuestionContents(treeItems.eigendomssituatieUitleg),
        onderhoudssituatie: getStringQuestionContents(treeItems.onderhoudssituatie),
        onderhoudsSituatieStatus: getStringQuestionContents(treeItems.onderhoudsSituatieStatus),
        onderhoudsSituatieUitleg: getStringQuestionContents(treeItems.onderhoudsSituatieUitleg),
        woningTypeStatus: getStringQuestionContents(treeItems.woningTypeStatus),
        woningTypeUitleg: getStringQuestionContents(treeItems.woningTypeUitleg),
        mateVanLuxeStatus: getStringQuestionContents(treeItems.mateVanLuxeStatus),
        mateVanLuxeUitleg: getStringQuestionContents(treeItems.mateVanLuxeUitleg),
        mateVanDoelmatigheidStatus: getStringQuestionContents(treeItems.mateVanDoelmatigheidStatus),
        mateVanDoelmatigheidUitleg: getStringQuestionContents(treeItems.mateVanDoelmatigheidUitleg),
        liggingStatus: getStringQuestionContents(treeItems.liggingStatus),
        liggingUitleg: getStringQuestionContents(treeItems.liggingUitleg),
        aanbouw: getArrayQuestionContents(treeItems.aanbouw),
        aanbouwStatus: getStringQuestionContents(treeItems.aanbouwStatus),
        aanbouwUitleg: getStringQuestionContents(treeItems.aanbouwUitleg),
        andereWezenlijkeVerschillen: getParsedQuestionContents(treeItems.andereWezenlijkeVerschillen, parseValueAsBool),
        andereWezenlijkeVerschillenUitleg: getStringQuestionContents(treeItems.andereWezenlijkeVerschillenUitleg),
        toelichtingGebruikReferentieObject: getStringQuestionContents(treeItems.toelichtingGebruikReferentieObject),
        vraagprijs: getParsedQuestionContents(treeItems.vraagprijs, parseValueAsInt),
        oorspronkelijkeVraagprijs: getParsedQuestionContents(treeItems.oorspronkelijkeVraagprijs, parseValueAsInt),
        teKoopSinds: getStringQuestionContents(treeItems.teKoopSinds),
    };
}

export interface ReferenceObjectTreeItems {
    uploadedPhotoUrl: TreeItem<QuestionAnswerPair> | null;
    adres: {
        huisnummerToevoeging: TreeItem<QuestionAnswerPair> | null;
    };
    bouwjaar: TreeItem<QuestionAnswerPair> | null;
    energielabel: TreeItem<QuestionAnswerPair> | null;
    energielabelGeldigTot: TreeItem<QuestionAnswerPair> | null;
    woningType: TreeItem<QuestionAnswerPair> | null;
    bronGegevens: TreeItem<QuestionAnswerPair> | null;
    bronGegevensToelichting: TreeItem<QuestionAnswerPair> | null;
    gebruiksOppervlakte: TreeItem<QuestionAnswerPair> | null;
    inhoud: TreeItem<QuestionAnswerPair> | null;
    perceelOppervlakte: TreeItem<QuestionAnswerPair> | null;
    aanvangsHuurprijsPerMaand: TreeItem<QuestionAnswerPair> | null;
    gecorrigeerdeHuurprijsPerMaand: TreeItem<QuestionAnswerPair> | null;
    ingangsdatumHuur: TreeItem<QuestionAnswerPair> | null;
    verkoopprijs: TreeItem<QuestionAnswerPair> | null;
    gecorrigeerdeVerkoopprijs: TreeItem<QuestionAnswerPair> | null;
    verkoopdatum: TreeItem<QuestionAnswerPair> | null;
    transportdatum: TreeItem<QuestionAnswerPair> | null;
    verkochtBinnen: TreeItem<QuestionAnswerPair> | null;
    eigendomssituatieVolleEigendom: TreeItem<QuestionAnswerPair> | null;
    eigendomssituatieRechtErfpacht: TreeItem<QuestionAnswerPair> | null;
    eigendomssituatieRechtOndererfpacht: TreeItem<QuestionAnswerPair> | null;
    eigendomssituatieRechtOpstal: TreeItem<QuestionAnswerPair> | null;
    eigendomssituatieRechtGebruikBewoning: TreeItem<QuestionAnswerPair> | null;
    eigendomssituatieRechtVruchtgebruik: TreeItem<QuestionAnswerPair> | null;
    eigendomssituatieRechtAnders: TreeItem<QuestionAnswerPair> | null;
    eigendomssituatieToelichting: TreeItem<QuestionAnswerPair> | null;
    eigendomssituatieStatus: TreeItem<QuestionAnswerPair> | null;
    eigendomssituatieUitleg: TreeItem<QuestionAnswerPair> | null;
    onderhoudssituatie: TreeItem<QuestionAnswerPair> | null;
    onderhoudsSituatieStatus: TreeItem<QuestionAnswerPair> | null;
    onderhoudsSituatieUitleg: TreeItem<QuestionAnswerPair> | null;
    woningTypeStatus: TreeItem<QuestionAnswerPair> | null;
    woningTypeUitleg: TreeItem<QuestionAnswerPair> | null;
    mateVanLuxeStatus: TreeItem<QuestionAnswerPair> | null;
    mateVanLuxeUitleg: TreeItem<QuestionAnswerPair> | null;
    mateVanDoelmatigheidStatus: TreeItem<QuestionAnswerPair> | null;
    mateVanDoelmatigheidUitleg: TreeItem<QuestionAnswerPair> | null;
    liggingStatus: TreeItem<QuestionAnswerPair> | null;
    liggingUitleg: TreeItem<QuestionAnswerPair> | null;
    aanbouw: TreeItem<QuestionAnswerPair>[] | null;
    aanbouwStatus: TreeItem<QuestionAnswerPair> | null;
    aanbouwUitleg: TreeItem<QuestionAnswerPair> | null;
    andereWezenlijkeVerschillen: TreeItem<QuestionAnswerPair> | null;
    andereWezenlijkeVerschillenUitleg: TreeItem<QuestionAnswerPair> | null;
    toelichtingGebruikReferentieObject: TreeItem<QuestionAnswerPair> | null;
    vraagprijs: TreeItem<QuestionAnswerPair> | null;
    oorspronkelijkeVraagprijs: TreeItem<QuestionAnswerPair> | null;
    teKoopSinds: TreeItem<QuestionAnswerPair> | null;
}

function getReferenceObjectItems(treeItem: TreeItem<QuestionAnswerPair>): ReferenceObjectTreeItems {
    return {
        uploadedPhotoUrl: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_PHOTO),
        adres: {
            huisnummerToevoeging: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_ADRES),
        },
        bouwjaar: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_BOUWJAAR),
        energielabel: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_ENERGIELABEL),
        energielabelGeldigTot: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_ENERGIELABEL_GELDIG_TOT),
        woningType: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_WONING_TYPE),
        bronGegevens: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_BRON_GEGEVENS),
        bronGegevensToelichting: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_BRON_GEGEVENS_TOELICHTING),
        gebruiksOppervlakte: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_GEBRUIKS_OPPERVLAKTE),
        inhoud: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_INHOUD),
        perceelOppervlakte: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_PERCEEL_OPPERVLAKTE),
        aanvangsHuurprijsPerMaand: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_AANVANGS_HUURPRIJS_PER_MAAND
        ),
        gecorrigeerdeHuurprijsPerMaand: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_GECORRIGEERDE_HUURPRIJS_PER_MAAND
        ),
        ingangsdatumHuur: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_INGANGSDATUM_HUUR),
        verkoopprijs: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_VERKOOPPRIJS),
        gecorrigeerdeVerkoopprijs: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_GECORRIGEERDE_VERKOOPPRIJS
        ),
        verkoopdatum: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_VERKOOPDATUM),
        transportdatum: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_TRANSPORTDATUM),
        verkochtBinnen: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_VERKOCHT_BINNEN),
        eigendomssituatieVolleEigendom: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_VOLLE_EIGENDOM
        ),
        eigendomssituatieRechtErfpacht: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_ERFPACHT
        ),
        eigendomssituatieRechtOndererfpacht: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_ONDERERFPACHT
        ),
        eigendomssituatieRechtOpstal: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_OPSTAL
        ),
        eigendomssituatieRechtGebruikBewoning: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_GEBRUIK_BEWONING
        ),
        eigendomssituatieRechtVruchtgebruik: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_VRUCHTGEBRUIK
        ),
        eigendomssituatieRechtAnders: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_RECHT_ANDERS
        ),
        eigendomssituatieToelichting: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_TOELICHTING
        ),
        eigendomssituatieStatus: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_STATUS),
        eigendomssituatieUitleg: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_EIGENDOMSSITUATIE_UITLEG),
        onderhoudssituatie: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_ONDERHOUDSSITUATIE),
        onderhoudsSituatieStatus: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_ONDERHOUDS_SITUATIE_STATUS
        ),
        onderhoudsSituatieUitleg: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_ONDERHOUDS_SITUATIE_UITLEG
        ),
        woningTypeStatus: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_WONING_TYPE_STATUS),
        woningTypeUitleg: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_WONING_TYPE_UITLEG),
        mateVanLuxeStatus: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_MATE_VAN_LUXE_STATUS),
        mateVanLuxeUitleg: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_MATE_VAN_LUXE_UITLEG),
        mateVanDoelmatigheidStatus: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_MATE_VAN_DOELMATIGHEID_STATUS
        ),
        mateVanDoelmatigheidUitleg: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_MATE_VAN_DOELMATIGHEID_UITLEG
        ),
        liggingStatus: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_LIGGING_STATUS),
        liggingUitleg: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_LIGGING_UITLEG),
        aanbouw: getChildItems(treeItem, TechnicalReference.REFERENCE_OBJECT_AANBOUW),
        aanbouwStatus: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_AANBOUW_STATUS),
        aanbouwUitleg: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_AANBOUW_UITLEG),
        andereWezenlijkeVerschillen: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_ANDERE_WEZENLIJKE_VERSCHILLEN
        ),
        andereWezenlijkeVerschillenUitleg: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_ANDERE_WEZENLIJKE_VERSCHILLEN_UITLEG
        ),
        toelichtingGebruikReferentieObject: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_TOELICHTING_GEBRUIK_REFERENTIE_OBJECT
        ),
        vraagprijs: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_TE_KOOP_VRAAGPRIJS),
        oorspronkelijkeVraagprijs: getChildItem(
            treeItem,
            TechnicalReference.REFERENCE_OBJECT_TE_KOOP_OORSPRONKELIJKE_VRAAGPRIJS
        ),
        teKoopSinds: getChildItem(treeItem, TechnicalReference.REFERENCE_OBJECT_TE_KOOP_DATUM),
    };
}
