import * as React from 'react';

import {ActiveSetDefinition} from '../../reference_objects_question_presenter';
import {Appraisal} from '../../../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {ReferenceObjectAddress} from './reference_object_address';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {ReferenceObjectCompare} from './reference_object_compare';
import {ReferenceObjectDetails} from './reference_object_details';
import {SetType} from '../../../../../../../../../models/reference_set/set_type';
import {classNames} from '../../../../../../../../../../support/classnames';
import {format} from 'date-fns';
import {getObjectIndexedPrice, getObjectUnindexedPrice} from '../../internal/get_object_price';
import {isEmpty} from '../../../../../../../../../../support/util';
import {observer} from 'mobx-react';
import {sourceToClassName} from '../../models/reference_object';
import {referenceObjectImages} from '../../internal/reference_sale_images';
import {ReferenceObjectTileImageSlider} from './reference_object_tile_image_slider';
import {QuestionAnswerPair, flattenTree} from '../../../../../../../../../../support/question_answer_tree';
import {TreeItem} from '../../../../../../../../../../support/generic_tree';
import {TechnicalReference} from '../../../../../../../../../enum/technical_reference';
import {usePresenter} from '../../../../../../../../../../support/presenter/use_presenter';
import {ReferenceObjectAnswerTilePresenter} from './reference_object_answer_tile_presenter';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    setType: SetType;
    referenceObjectAnswer: ReferenceObjectAnswer;
    activeSetDefinition: ActiveSetDefinition;
    index: number;
    buildYear: number | null;
    isCompare: boolean;
    canDelete: boolean;
    onRemove: () => void;
    onRestore: () => void;
    showAnswerModal: (referenceObjectAnswer: ReferenceObjectAnswer) => void;
    isMapHovering: boolean;
    isMapSelected: boolean;
    onHoverChange: (id: string | null) => void;
    onClickChange: (referenceObjectAnswer: ReferenceObjectAnswer) => void;
    onAlertResize: (alert: Element, height: number | null) => void;
    treeItem: TreeItem<QuestionAnswerPair>;
    alertWrapperHeight: number;
    isDeleted: boolean;
    isCompact?: boolean;
}

export const ReferenceObjectAnswerTile: React.FC<OwnProps> = observer(function ReferenceObjectAnswerTile(props) {
    const presenter = usePresenter(
        (container) =>
            new ReferenceObjectAnswerTilePresenter(
                container.business.referenceObjectsAnswerEnhancementInteractor(props.appraisal, props.questionSet),
                props.referenceObjectAnswer
            )
    );

    const alertRef = React.useRef<HTMLSpanElement>(null);

    React.useEffect(() => {
        if (!alertRef.current) {
            return;
        }

        const alertResizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                props.onAlertResize(entry.target, entry.target.getBoundingClientRect().height + 15);
            }
        });

        alertResizeObserver.observe(alertRef.current);

        return () => {
            alertResizeObserver.disconnect();
            if (alertRef.current) {
                props.onAlertResize(alertRef.current, null);
            }
        };
    }, []);

    const {referenceObjectAnswer, setType, isDeleted, isCompact} = props;
    if (referenceObjectAnswer === null) {
        return null;
    }

    const images = React.useMemo(() => {
        return referenceObjectImages(presenter.enhancedReferenceSale, referenceObjectAnswer);
    }, [presenter.enhancedReferenceSale, referenceObjectAnswer]);

    const photoAnswerUuid = React.useMemo(() => {
        const pairs = flattenTree(props.treeItem);
        const photoItem = pairs.find(
            (p) => p.question.technicalReference === TechnicalReference.REFERENCE_OBJECT_PHOTO
        );
        return photoItem?.answer?.uuid ?? undefined;
    }, [props.treeItem]);

    return (
        <>
            <div
                className={classNames('col-12 col-sm-6 col-md-4 form-grid-item', {
                    hovering: props.isMapHovering,
                    selected: !isDeleted && !isCompact,
                })}
                key={referenceObjectAnswer.id ?? undefined}
                onMouseEnter={() => props.onHoverChange(referenceObjectAnswer.id)}
                onMouseLeave={() => props.onHoverChange(null)}
            >
                <div className={classNames('reference-logo', sourceToClassName(referenceObjectAnswer.source ?? null))}>
                    &nbsp;
                </div>
                <div className="form-grid-item-img">
                    <ReferenceObjectTileImageSlider
                        images={images}
                        hasCheckmark={!isDeleted && !isCompact}
                        appraisal={props.appraisal}
                        questionSet={props.questionSet}
                        photoAnswerUuid={photoAnswerUuid}
                    />
                </div>
                <div className="form-grid-item-body" onClick={() => props.onClickChange(referenceObjectAnswer)}>
                    <div className="row">
                        <div className="col-12" style={{height: props.alertWrapperHeight}}>
                            {referenceObjectAnswer.hasWarning ? (
                                <span className="form-grid-item-alert" ref={alertRef}>
                                    <span className="ion-md-alert">&nbsp;</span>
                                    {referenceObjectAnswer.warning}
                                </span>
                            ) : null}
                        </div>
                        <div className="col-12">
                            <div
                                className={classNames('grid-item-title', {
                                    'grid-item-title-with-avatar': !isCompact,
                                })}
                            >
                                {isCompact || (
                                    <div
                                        className={classNames(
                                            'grid-item-title-avatar grid-item-title-avatar-icon-pin',
                                            {active: props.isMapSelected}
                                        )}
                                    />
                                )}
                                <p>
                                    {isCompact || (
                                        <span className="badge-container">
                                            <span className="badge badge-success">#{props.index + 1}</span>
                                        </span>
                                    )}
                                    <ReferenceObjectAddress
                                        referenceObject={null}
                                        referenceObjectAnswer={referenceObjectAnswer}
                                    />
                                </p>
                            </div>
                        </div>
                        {props.isCompare ? (
                            <ReferenceObjectCompare
                                setType={setType}
                                referenceObjectAnswer={referenceObjectAnswer}
                                buildYear={props.buildYear}
                                surfaceArea={props.activeSetDefinition.setDefinition.surfaceArea}
                                plotArea={props.activeSetDefinition.setDefinition.plotArea}
                                volume={props.activeSetDefinition.setDefinition.volume}
                                energyLabel={props.activeSetDefinition.setDefinition.energyLabel}
                                objectType={props.activeSetDefinition.setDefinition.objectType}
                            />
                        ) : (
                            <div className="col-12">
                                <ReferenceObjectDetails
                                    appraisal={props.appraisal}
                                    questionSet={props.questionSet}
                                    referenceSetType={props.activeSetDefinition.setDefinition.type}
                                    referenceValuation={props.activeSetDefinition.setDefinition.valuation}
                                    referenceSurfaceArea={props.activeSetDefinition.setDefinition.surfaceArea}
                                    referenceVolume={props.activeSetDefinition.setDefinition.volume}
                                    referencePlotArea={props.activeSetDefinition.setDefinition.plotArea}
                                    referenceBuildYear={props.activeSetDefinition.setDefinition.buildYear}
                                    referenceEnergyLabel={props.activeSetDefinition.setDefinition.energyLabel}
                                    referenceObjectType={props.activeSetDefinition.setDefinition.objectType}
                                    referenceObjectId={props.referenceObjectAnswer?.id}
                                    floorArea={referenceObjectAnswer.referenceObject?.gebruiksOppervlakte ?? null}
                                    plotArea={referenceObjectAnswer.referenceObject?.perceelOppervlakte ?? null}
                                    buildYear={referenceObjectAnswer.referenceObject?.bouwjaar ?? null}
                                    objectType={referenceObjectAnswer.referenceObject?.woningType ?? null}
                                    energyLabel={referenceObjectAnswer.referenceObject?.energielabel ?? null}
                                    priceRange={getObjectUnindexedPrice(setType, referenceObjectAnswer.referenceObject)}
                                    indexedPriceRange={getObjectIndexedPrice(
                                        setType,
                                        referenceObjectAnswer.referenceObject
                                    )}
                                    period={
                                        referenceObjectAnswer.referenceObject?.verkoopdatum &&
                                        !isEmpty(referenceObjectAnswer.referenceObject.verkoopdatum)
                                            ? referenceObjectAnswer.referenceObject.verkoopdatum.length > 8
                                                ? format(
                                                      new Date(referenceObjectAnswer.referenceObject.verkoopdatum),
                                                      'dd-MM-yyyy'
                                                  )
                                                : 'Q' + referenceObjectAnswer.referenceObject.verkoopdatum
                                            : referenceObjectAnswer?.saleQuarter &&
                                              !isEmpty(referenceObjectAnswer.saleQuarter)
                                            ? referenceObjectAnswer.saleQuarter.length > 8
                                                ? format(new Date(referenceObjectAnswer.saleQuarter), 'dd-MM-yyyy')
                                                : 'Q' + referenceObjectAnswer.saleQuarter
                                            : '-'
                                    }
                                    volume={referenceObjectAnswer.referenceObject.inhoud ?? null}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {!isCompact && (
                    <div className="form-grid-item-footer d-flex justify-content-between">
                        {isDeleted ? (
                            <button className="ion-md-undo" onClick={() => props.onRestore()}>
                                Herstellen
                            </button>
                        ) : (
                            <>
                                <button
                                    className="ion-md-trash"
                                    onClick={() => confirm('Weet je het zeker?') && props.onRemove()}
                                >
                                    Verwijder
                                </button>
                                <button
                                    className="ion-md-create"
                                    onClick={() => props.showAnswerModal(referenceObjectAnswer)}
                                >
                                    Bewerk
                                </button>
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    );
});
