import * as React from 'react';
import {Link} from 'react-router-dom';
import {classNames} from '../../../support/classnames';
import {Popper} from '../../components/popper';
import {PagePartsSet} from '../../models/page_parts_set';
import {Loader} from '../../appraise/ui/components/loader';

export interface OwnProps {
    activePagePartSet: PagePartsSet | null;
    sets: PagePartsSet[] | null;
    onLeave: () => Promise<void>;
    isLoadingLeave: boolean;
}

export const PagePartsSetSelector: React.FC<OwnProps> = ({activePagePartSet, sets, onLeave, isLoadingLeave}) => {
    if (activePagePartSet === null || sets === null || sets.length === null) {
        return null;
    }

    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <Popper
            isPopperOpen={isOpen}
            setIsPopperOpen={setIsOpen}
            className="config-selector"
            popperOffset={[0, 10]}
            toggle={
                <button className="btn-text-link ion-md-arrow-dropdown icon-right" onClick={() => setIsOpen(!isOpen)}>
                    {activePagePartSet.name}
                </button>
            }
            popperClassName="config-selector-dialog"
            popper={
                <>
                    {sets.map((set) => (
                        <span className="config-row" key={set.id}>
                            <Link
                                onClick={() => setIsOpen(false)}
                                to={`/page-parts-configuration/${set.id}/${set.rootItems[0]?.uuid ?? ''}`}
                                className={classNames('config-row-link', {
                                    active: activePagePartSet.id === set.id,
                                })}
                            >
                                {set.name}
                            </Link>
                        </span>
                    ))}
                    <span onClick={onLeave} className="config-row" key="back">
                        {/* Todo: Add tooltip "Keer terug naar de opnameomgeving met de ingestelde configuratie."*/}
                        <span
                            className={classNames('config-row-link', {
                                'c-icon-system': !isLoadingLeave,
                                'config-row-link-loading': isLoadingLeave,
                            })}
                        >
                            {isLoadingLeave && <Loader isSmall />}
                            Terug naar opnameomgeving
                        </span>
                    </span>
                </>
            }
        />
    );
};
