import {IteratorQuestionType, QuestionType} from '../appraising/enum/question_type';

export function isIteratorQuestionType(questionType: QuestionType): questionType is IteratorQuestionType {
    return (
        questionType === IteratorQuestionType.PAGE_PART_FLOORS ||
        questionType === IteratorQuestionType.PAGE_PART_ITERATOR ||
        questionType === IteratorQuestionType.ITERATOR ||
        questionType === IteratorQuestionType.ITERATOR_GROUP ||
        questionType === IteratorQuestionType.PHOTO_ITERATOR ||
        questionType === IteratorQuestionType.ITERATOR_PHOTO_GROUP ||
        questionType === IteratorQuestionType.ITERATOR_CADASTRAL ||
        questionType === IteratorQuestionType.ITERATOR_REFERENCE_OBJECTS_V3 ||
        questionType === IteratorQuestionType.PHOTO_ITERATOR_SMALL ||
        questionType === IteratorQuestionType.ITERATOR_PHOTO_GROUP_SMALL ||
        questionType === IteratorQuestionType.ITERATOR_FILES
    );
}
