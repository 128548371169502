export interface CadastralPlotAddress {
    address: string | null;
    number: string | null;
    numberAddition: string | null;
    letter: string | null;
    postalCode: string | null;
    city: string | null;
    municipal: string | null;
    cadastralSection: string | null;
    cadastralPlotNumber: string | null;
    apartmentRightsIndex: string | null;
    identificationType: string | null;
    plotType: CadastralPlotAddressType | null;
    plotTypeText: string | null;
}

export enum CadastralPlotAddressType {
    HOUSE = 'house',
    GARAGE = 'garage',
    OTHER = 'other',
}
