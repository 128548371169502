import {Observable, Subject} from 'rxjs';
import {ReportSelectorType} from '../enum/report_selector_type';
import {AppraisalApi} from '../network/appraisal_api';
import {TaskHelper} from './task_helper';
import {GeneratedReportFormat, GeneratedReportType} from '../enum/generated_report';

export class ConceptReportProvider {
    private replacementInvalidateEvents = new Subject<ReportSelectorType>();

    constructor(private taskHelper: TaskHelper, private appraisalApi: AppraisalApi) {}

    public async getConceptReportUrl(id: number): Promise<string> {
        const conceptReportUrl = await this.appraisalApi.generateReport(
            id,
            GeneratedReportType.CONCEPT_REPORT,
            GeneratedReportFormat.PDF
        );
        if (!TaskHelper.isTaskReference(conceptReportUrl)) {
            return conceptReportUrl;
        }

        const result = await this.taskHelper.poll<string>(conceptReportUrl.taskId);
        if (result !== null) {
            return result;
        }

        throw new Error('No result');
    }

    public getReplacementInvalidateStream(): Observable<ReportSelectorType> {
        return this.replacementInvalidateEvents;
    }

    public invalidateReplacementAnswer(type: ReportSelectorType) {
        this.replacementInvalidateEvents.next(type);
    }

    public async getHtmlConceptReport(id: number): Promise<string> {
        const htmlConceptReport = await this.appraisalApi.generateReport(
            id,
            GeneratedReportType.CONCEPT_REPORT,
            GeneratedReportFormat.HTML
        );
        if (!TaskHelper.isTaskReference(htmlConceptReport)) {
            return htmlConceptReport;
        }

        const result = await this.taskHelper.poll<string>(htmlConceptReport.taskId);
        if (result !== null) {
            return result;
        }

        throw new Error('No result');
    }
}
