import * as React from 'react';

import {observer} from 'mobx-react';
import {ProgressType} from '../../../camera_presenter';

interface OwnProps {
    percentage: number;
    type: ProgressType;
}

@observer
export class PhotoProgressBar extends React.Component<OwnProps> {
    private getTypeName(type: ProgressType) {
        switch (type) {
            case ProgressType.CRITICAL:
                return 'Voorbereiden...';
            case ProgressType.CASUAL:
                return 'Uploaden...';
            case ProgressType.RECOGNITION:
                return 'Herkennen...';
            case ProgressType.FAILURE:
                return 'Fout!';
            case ProgressType.IDLE:
                return 'Klaar.';
        }
    }

    public render() {
        return (
            <div className="dropzone-progress">
                <div className={'info'}>
                    <span>{this.getTypeName(this.props.type)}</span>
                    <span className="percentage">{Math.round(this.props.percentage * 100)}%</span>
                </div>
                <span
                    className="bar"
                    // Progress bar should not transition back to 0 when switching types
                    key={this.props.type}
                    style={{width: `${Math.round(this.props.percentage * 100)}%`}}
                />
            </div>
        );
    }
}
