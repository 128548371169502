import {Macro} from '../models/macro';
import {MacroInteractor} from './macro_interactor';
import {AnswerController} from './answering/answer_controller';
import {Appraisal} from '../models/appraisal';
import {combineLatest, Observable, of, map, switchMap} from 'rxjs';
import {TechnicalReference} from '../enum/technical_reference';
import {QuestionSet} from '../models/question_set';
import {getNewestAnswer} from '../../support/get_newest_answer';
import {MacroEffectInteractor} from './conditions/macro_effects_interactor';
import {Global} from '../../business/global_provider';
import {SuperMacroFilter} from './super_macro_filter';

export type VisibleMacro<T = Macro> = {
    macro: T;
    isNormalHidden: boolean;
    isSuperHidden: boolean;
};

export class SuperMacroInteractor {
    constructor(
        private appraisal: Appraisal,
        private questionSet: QuestionSet,
        private global: Global,
        private macroInteractor: MacroInteractor,
        private answerController: AnswerController,
        private macroEffectInteractor: MacroEffectInteractor,
        private superMacroFilter: SuperMacroFilter
    ) {}

    private isHiddenBasedOnMacroSettings(macro: Macro): Observable<boolean> {
        const buildYearQuestion = this.questionSet.findQuestionByTechnicalReference(
            TechnicalReference.OBJECT_BUILD_YEAR
        );
        const buildYearAnswer = buildYearQuestion
            ? getNewestAnswer(this.answerController.answersForQuestionUuid(buildYearQuestion.uuid))
            : null;

        return this.macroInteractor.getSettingsStream(macro.id).pipe(
            map((settings) => {
                if (settings === null) {
                    return false;
                }
                return !this.superMacroFilter.isVisible(settings, buildYearAnswer);
            })
        );
    }

    public visibleMacrosStream(questionUuid: string, parentAnswerUuid: string | null): Observable<VisibleMacro[]> {
        return this.macroInteractor.macrosForQuestionUuid(questionUuid).pipe(
            switchMap((macros) => {
                return macros.length === 0
                    ? of([])
                    : combineLatest(
                          macros.map((macro) => {
                              return combineLatest([
                                  this.isHiddenBasedOnMacroSettings(macro),
                                  this.macroEffectInteractor.isHiddenStream(
                                      macro.conditionGroups,
                                      parentAnswerUuid ?? null
                                  ),
                              ]).pipe(
                                  map(([superHidden, normalHidden]) => {
                                      return {
                                          macro,
                                          isNormalHidden: normalHidden,
                                          isSuperHidden: superHidden,
                                      };
                                  })
                              );
                          })
                      );
            })
        );
    }
}
