import {AppraisalState} from '../../enum/appraisal_state';
import {SearchAppraisal} from '../../models/search_appraisal';

export interface ApiSearchAppraisal {
    id: number;
    title: string;
    address: string;
    postal_code: string;
    house_number: string;
    letter: string | null;
    city: string;
    object_type: string;
    object_display_type: string;
    improvements_planned: boolean;
    recently_sold: boolean;
    status: AppraisalState;
    streetview_url: string;
    created_at: string;
}

export function apiSearchAppraisalToSearchAppraisal(apiSearchAppraisal: ApiSearchAppraisal): SearchAppraisal {
    return {
        id: apiSearchAppraisal.id,
        title: apiSearchAppraisal.title,
        address: apiSearchAppraisal.address,
        postalCode: apiSearchAppraisal.postal_code,
        houseNumber: apiSearchAppraisal.house_number,
        letter: apiSearchAppraisal.letter,
        city: apiSearchAppraisal.city,
        objectType: apiSearchAppraisal.object_type,
        objectDisplayType: apiSearchAppraisal.object_display_type,
        improvementsPlanned: apiSearchAppraisal.improvements_planned,
        recentlySold: apiSearchAppraisal.recently_sold,
        status: apiSearchAppraisal.status,
        streetviewUrl: apiSearchAppraisal.streetview_url,
        createdAt: new Date(apiSearchAppraisal.created_at),
    };
}
