import * as React from 'react';

import {FunctionComponent} from 'react';
import {classNames} from '../../../../../../support/classnames';

const DummyGridItem: FunctionComponent<{className: string}> = ({className}) => {
    return (
        <div className={classNames(className, 'col-12 col-sm-6 col-md-4 form-grid-item')}>
            <div className="form-grid-item-img">
                <div className="form-grid-item-img-content">
                    <div className="img-wrap">
                        <div className="placeholder-image"></div>
                    </div>
                </div>
            </div>
            <div className="form-grid-item-body">
                <div className="form-group">
                    <div className="form-group">
                        <span className="placeholder placeholder-width-100"></span>
                        <span className="placeholder w-100 placeholder-height-40 mt-1"></span>
                    </div>
                </div>
            </div>
            <div className="form-grid-item-footer d-flex justify-content-between">
                <div className="d-flex w-100 flex-row justify-content-between align-items-center">
                    <span className="placeholder placeholder-width-100" />
                    <span className="placeholder placeholder-width-75" />
                </div>
            </div>
        </div>
    );
};

export const DummyPhotoIterator: FunctionComponent = () => {
    return (
        <>
            <div className="photo-sorting-checkbox-container">
                <label className="toggle-checkbox">
                    <span className="placeholder placeholder-width-100" />
                    <input type="checkbox" disabled={true} />
                    <div className="toggle-checkbox__bullet" />
                </label>
            </div>

            <div className="row grid">
                <DummyGridItem className="placeholder-opaque-80" />
                <DummyGridItem className="placeholder-opaque-70" />
                <DummyGridItem className="placeholder-opaque-60" />
                <DummyGridItem className="placeholder-opaque-50" />
                <DummyGridItem className="placeholder-opaque-40" />
                <DummyGridItem className="placeholder-opaque-30" />
            </div>
        </>
    );
};
