import {ExplanationData} from './explanation_data';
import {ReferenceObjectAnswer} from '../../../models/reference_object_answer';
import {normalizeNumber} from '../../../../../../../../../../business/support/normalize_number';

export class ExplanationPlotAreaGenerator {
    public generateDetailsSentence(
        explanationData: ExplanationData,
        referenceObjectAnswer: ReferenceObjectAnswer
    ): string {
        const result: string[] = [];
        const plotAreaDiffPercentage = this.getPlotAreaDiffPercentage(referenceObjectAnswer, explanationData);
        const plotAreaDiff = normalizeNumber(
            Math.abs(
                (explanationData.object.plotArea ?? 0) - (referenceObjectAnswer.referenceObject.perceelOppervlakte ?? 0)
            )
        );
        if (plotAreaDiffPercentage !== null) {
            if (plotAreaDiffPercentage > 30 || plotAreaDiffPercentage > 10) {
                result.push(`${plotAreaDiff} m2 meer perceeloppervlakte`);
            } else if (plotAreaDiffPercentage < -30 || plotAreaDiffPercentage < -10) {
                result.push(`${plotAreaDiff} m2 minder perceeloppervlakte`);
            }
        }
        return result.join('');
    }

    public generatePlotAreaStatus(referenceObjectAnswer: ReferenceObjectAnswer, explanationData: ExplanationData) {
        const plotAreaDiffPercentage = this.getPlotAreaDiffPercentage(referenceObjectAnswer, explanationData);
        if (plotAreaDiffPercentage !== null) {
            if (plotAreaDiffPercentage > 30) {
                return 'Beduidend groter';
            } else if (plotAreaDiffPercentage > 10) {
                return 'Groter';
            } else if (plotAreaDiffPercentage < -30) {
                return 'Beduidend kleiner';
            } else if (plotAreaDiffPercentage < -10) {
                return 'Kleiner';
            } else {
                return 'Vergelijkbaar';
            }
        }
        return 'Vergelijkbaar';
    }

    private getPlotAreaDiffPercentage(
        referenceObjectAnswer: ReferenceObjectAnswer,
        explanationData: ExplanationData
    ): number | null {
        if (
            referenceObjectAnswer.referenceObject.perceelOppervlakte === null ||
            explanationData.object.plotArea === null
        ) {
            return null;
        }

        return normalizeNumber(
            (100 * (referenceObjectAnswer.referenceObject.perceelOppervlakte - explanationData.object.plotArea)) /
                referenceObjectAnswer.referenceObject.perceelOppervlakte
        );
    }
}
