import {AppraiseModel, isAppraiseModelOrNewer} from '../../../../../../../../../enum/appraise_model';
import {ExplanationBuildYearGenerator} from './explanation_build_year_generator';
import {ExplanationBuildingTypeGenerator} from './explanation_building_type_generator';
import {ExplanationBuildingsGenerator} from './explanation_buildings_generator';
import {ExplanationData} from './explanation_data';
import {ExplanationDataProvider} from './explanation_data_provider';
import {ExplanationFloorAreaGenerator} from './explanation_floor_area_generator';
import {ExplanationPlotAreaGenerator} from './explanation_plot_area_generator';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {isEmpty} from '../../../../../../../../../../support/util';
import {lowercaseFirstChar, uppercaseFirstChar} from '../../../../../../../../../../support/string';
import {ExplanationEnergyLabelGenerator} from './explanation_energy_label_generator';

export enum DescriptionType {
    WONINGTYPE = 'woningtype',
    BOUWJAAR = 'bouwjaar',
    GEBRUIKSOPP = 'gebruiksoppervlakte',
    PERCEELOPP = 'perceeloppervlakte',
    LIGGING = 'ligging',
    AANBOUW = 'aanbouw',
    LUXE_DOELMATIGHEID = 'luxedoelmatigheid',
    LUXE = 'luxe',
    DOELMATIGHEID = 'doelmatigheid',
    ONDERHOUD = 'onderhoud',
    KWALITEIT = 'kwaliteit',
    ENERGIELABEL = 'energielabel',
}

export function getAllDescriptionTypes(explanationData: ExplanationData): DescriptionType[] {
    const types = [
        DescriptionType.WONINGTYPE,
        DescriptionType.BOUWJAAR,
        DescriptionType.GEBRUIKSOPP,
        DescriptionType.LIGGING,
        DescriptionType.AANBOUW,
        DescriptionType.ONDERHOUD,
        DescriptionType.ENERGIELABEL,
    ];

    if (isAppraiseModelOrNewer(explanationData.appraisal.appraiseModel, AppraiseModel.MODEL2021)) {
        types.push(DescriptionType.LUXE);
        types.push(DescriptionType.DOELMATIGHEID);
    } else {
        types.push(DescriptionType.LUXE_DOELMATIGHEID);
        types.push(DescriptionType.KWALITEIT);
    }

    if (explanationData.object.plotArea !== null && explanationData.object.plotArea > 0) {
        types.push(DescriptionType.PERCEELOPP);
    }

    return types;
}

function getDescriptionForDescriptionType(type: DescriptionType): string | null {
    switch (type) {
        case DescriptionType.WONINGTYPE:
            return 'Woningtype';
        case DescriptionType.BOUWJAAR:
            return 'Bouwjaar';
        case DescriptionType.GEBRUIKSOPP:
            return 'Gebruiksoppervlakte';
        case DescriptionType.PERCEELOPP:
            return 'Perceeloppervlakte';
        case DescriptionType.AANBOUW:
            return 'Bij-, op- en aanbouwen';
        case DescriptionType.LIGGING:
            return 'Ligging';
        case DescriptionType.ONDERHOUD:
            return 'Onderhoudsstatus';
        case DescriptionType.LUXE_DOELMATIGHEID:
            return 'Luxe en doelmatigheid';
        case DescriptionType.LUXE:
            return 'Luxe';
        case DescriptionType.DOELMATIGHEID:
            return 'Doelmatigheid';
        case DescriptionType.KWALITEIT:
            return 'Kwaliteit';
        case DescriptionType.ENERGIELABEL:
            return 'Energielabel';
        default:
            return null;
    }
}

export class ExplanationAppraiserGenerator {
    constructor(
        private explanationDataProvider: ExplanationDataProvider,
        private explanationBuildYearGenerator: ExplanationBuildYearGenerator,
        private explanationFloorAreaGenerator: ExplanationFloorAreaGenerator,
        private explanationPlotAreaGenerator: ExplanationPlotAreaGenerator,
        private explanationBuildingsGenerator: ExplanationBuildingsGenerator,
        private explanationBuildingTypeGenerator: ExplanationBuildingTypeGenerator,
        private explanationEnergyLabelGenerator: ExplanationEnergyLabelGenerator
    ) {}

    public generate(
        referenceObjectAnswer: ReferenceObjectAnswer,
        gebruiksoppervlakteWonen: number | null,
        perceelOppervlakte: number | null,
        brutoInhoud: number | null,
        energyLabel: string | null
    ): string {
        const explanationData = this.explanationDataProvider.get(
            gebruiksoppervlakteWonen,
            perceelOppervlakte,
            brutoInhoud,
            energyLabel
        );
        const result: Array<string | null> = [];

        // Gather all description fields with "Vergelijkbaar" value
        const equalValues = this.getDescriptionTypesForStatus('Vergelijkbaar', referenceObjectAnswer, explanationData);

        // Gather all description fields with another value then "Vergelijkbaar"
        const otherValues = getAllDescriptionTypes(explanationData).filter((descriptionItem: DescriptionType) => {
            if (descriptionItem === DescriptionType.ENERGIELABEL) {
                if (
                    referenceObjectAnswer.referenceObject.energielabel === undefined ||
                    referenceObjectAnswer.referenceObject.energielabel === null ||
                    explanationData.object.energyLabel === undefined ||
                    explanationData.object.energyLabel === null ||
                    referenceObjectAnswer.referenceObject.energielabel === 'Geen energielabel'
                ) {
                    return false;
                } else {
                    return !equalValues.includes(descriptionItem);
                }
            }
            return !equalValues.includes(descriptionItem);
        });

        // Example: Vergelijkbaar op basis van <gelijke kenmerken>.
        result.push(this.getFirstSentence(equalValues));

        // Example: Deze woning heeft een <verschil> woningtype (<woningtype>). <toelichting>
        result.push(this.getSecondSentence(referenceObjectAnswer, explanationData));

        // Example: Deze woning heeft <verschillen in waarden>.
        result.push(this.getThirdSentence(otherValues, referenceObjectAnswer, explanationData));

        // Example:  De onderhoudsstaat is <waarde>, <toelichting>
        result.push(this.getFourthSentence(equalValues, referenceObjectAnswer));

        // Example: De mate van luxe en doelmatigheid is <waarde>, <toelichting>
        result.push(this.getFifthSentence(explanationData, referenceObjectAnswer));

        // Example:  De ligging is <waarde>, <toelichting>
        result.push(this.getSixthSentence(equalValues, referenceObjectAnswer));

        return result
            .filter((part) => part !== null)
            .join(' ')
            .replace(/(.{997})..+/, '$1…')
            .replace(/\.\./g, '.');
    }

    // Example: Vergelijkbaar op basis van <gelijke kenmerken>.
    private getFirstSentence(descriptionTypesWithEqualStatus: DescriptionType[]): string | null {
        if (descriptionTypesWithEqualStatus.length === 0) {
            return null;
        }
        const result: string[] = [];
        result.push(`vergelijkbaar op basis van `);
        descriptionTypesWithEqualStatus.forEach((descriptionItem: DescriptionType, key) => {
            const descriptionText = getDescriptionForDescriptionType(descriptionItem);
            if (descriptionText !== null) {
                result.push(lowercaseFirstChar(descriptionText));
                if (key === descriptionTypesWithEqualStatus.length - 2) {
                    result.push(` en `);
                } else if (key !== descriptionTypesWithEqualStatus.length - 1) {
                    result.push(`, `);
                }
            }
        });

        return uppercaseFirstChar(result.join('') + '.');
    }
    // Example: Deze woning heeft een <verschil> woningtype (<woningtype>). <toelichting>
    private getSecondSentence(
        referenceObjectAnswer: ReferenceObjectAnswer,
        explanationData: ExplanationData
    ): string | null {
        if (
            isEmpty(referenceObjectAnswer.referenceObject.woningTypeUitleg) ||
            referenceObjectAnswer.referenceObject.woningTypeStatus === 'Vergelijkbaar'
        ) {
            // If there is no explanation, the difference will be placed in the third sentence
            return null;
        }

        const result: string[] = ['deze woning '];

        result.push(
            this.explanationBuildingTypeGenerator.generateDetailsSentence(explanationData, referenceObjectAnswer) + '. '
        );

        if (referenceObjectAnswer.referenceObject.woningTypeStatus !== 'Vergelijkbaar') {
            result.push(uppercaseFirstChar(referenceObjectAnswer.referenceObject.woningTypeUitleg));
        }

        return uppercaseFirstChar(result.join('') + '.');
    }

    // Example:  De <verschillen in waarden>.
    private getThirdSentence(
        descriptionTypesWithDifferentStatus: DescriptionType[],
        referenceObjectAnswer: ReferenceObjectAnswer,
        explanationData: ExplanationData
    ): string | null {
        // Create list with supported description types for this sentence
        const typesForSentence: DescriptionType[] = [];

        // Some types are not included in the comma separated sentence and added at the end as separate sentence.
        const ignoredTypesInSentence: DescriptionType[] = [];

        if (explanationData.object.buildYear !== null) {
            typesForSentence.push(DescriptionType.BOUWJAAR);
        }
        if (
            explanationData.object.objectType !== null &&
            !isEmpty(referenceObjectAnswer.referenceObject.woningTypeStatus) &&
            referenceObjectAnswer.referenceObject.woningType !== null &&
            // If there is an explanation, this part will be placed in the second sentence
            isEmpty(referenceObjectAnswer.referenceObject.woningTypeUitleg)
        ) {
            typesForSentence.push(DescriptionType.WONINGTYPE);
        }
        if (explanationData.object.floorArea !== null && explanationData.object.floorArea > 0) {
            typesForSentence.push(DescriptionType.GEBRUIKSOPP);
        }
        if (explanationData.object.plotArea !== null && explanationData.object.plotArea > 0) {
            typesForSentence.push(DescriptionType.PERCEELOPP);
        }
        if (
            !isEmpty(referenceObjectAnswer.referenceObject.aanbouwStatus) &&
            descriptionTypesWithDifferentStatus.includes(DescriptionType.AANBOUW)
        ) {
            typesForSentence.push(DescriptionType.AANBOUW);
            ignoredTypesInSentence.push(DescriptionType.AANBOUW);
        }
        if (
            !isEmpty(referenceObjectAnswer.referenceObject.energielabel) &&
            descriptionTypesWithDifferentStatus.includes(DescriptionType.ENERGIELABEL)
        ) {
            typesForSentence.push(DescriptionType.ENERGIELABEL);
        }

        descriptionTypesWithDifferentStatus = descriptionTypesWithDifferentStatus.filter(
            (descriptionItem: DescriptionType) => {
                return typesForSentence.includes(descriptionItem);
            }
        );

        if (descriptionTypesWithDifferentStatus.length === 0) {
            return null;
        }

        const result: string[] = [];

        // Fill the sentence, but filter the ignored types for the combined sentence
        descriptionTypesWithDifferentStatus = descriptionTypesWithDifferentStatus.filter(
            (descriptionItem: DescriptionType) => !ignoredTypesInSentence.includes(descriptionItem)
        );

        descriptionTypesWithDifferentStatus.forEach((descriptionItem: DescriptionType, key) => {
            switch (descriptionItem) {
                case DescriptionType.WONINGTYPE:
                    result.push(
                        this.explanationBuildingTypeGenerator.generateDetailsSentence(
                            explanationData,
                            referenceObjectAnswer
                        )
                    );
                    break;
                case DescriptionType.BOUWJAAR:
                    result.push(
                        `is ${this.explanationBuildYearGenerator.generateDetailsSentence(
                            explanationData,
                            referenceObjectAnswer
                        )}`
                    );
                    break;
                case DescriptionType.GEBRUIKSOPP:
                    result.push(
                        `heeft ${this.explanationFloorAreaGenerator.generateDetailsSentence(
                            explanationData,
                            referenceObjectAnswer
                        )}`
                    );
                    break;
                case DescriptionType.PERCEELOPP:
                    result.push(
                        `heeft ${this.explanationPlotAreaGenerator.generateDetailsSentence(
                            explanationData,
                            referenceObjectAnswer
                        )}`
                    );
                    break;
                case DescriptionType.ENERGIELABEL:
                    result.push(
                        `${this.explanationEnergyLabelGenerator.generateDetailsSentence(
                            explanationData,
                            referenceObjectAnswer
                        )}`
                    );
                    break;
                default:
                    break;
            }
            if (descriptionTypesWithDifferentStatus.length > 1) {
                if (key === descriptionTypesWithDifferentStatus.length - 2) {
                    result.push(` en `);
                } else if (key !== descriptionTypesWithDifferentStatus.length - 1) {
                    result.push(`, `);
                }
            }
        });

        if (result.length > 0) {
            result.unshift('deze woning ');
        }

        // Other sentences
        if (
            !isEmpty(referenceObjectAnswer.referenceObject.aanbouwStatus) &&
            ignoredTypesInSentence.includes(DescriptionType.AANBOUW)
        ) {
            result.push(
                `${
                    result.length > 0 ? '. ' : ''
                }Deze woning heeft ${this.explanationBuildingsGenerator.generateDetailsSentence(referenceObjectAnswer)}`
            );
        }

        return uppercaseFirstChar(result.join('') + '.');
    }

    // Example:  De onderhoudsstaat is <waarde>, <toelichting>
    private getFourthSentence(
        descriptionTypesWithEqualStatus: DescriptionType[],
        referenceObjectAnswer: ReferenceObjectAnswer
    ): string | null {
        if (descriptionTypesWithEqualStatus.indexOf(DescriptionType.ONDERHOUD) > -1) {
            return null;
        }

        const result: string[] = [];

        if (
            !isEmpty(referenceObjectAnswer.referenceObject.onderhoudsSituatieUitleg) &&
            referenceObjectAnswer.referenceObject.onderhoudsSituatieStatus.toLocaleLowerCase() !== 'vergelijkbaar'
        ) {
            result.push(
                `${uppercaseFirstChar(
                    referenceObjectAnswer.referenceObject.onderhoudsSituatieUitleg +
                        (!referenceObjectAnswer.referenceObject.onderhoudsSituatieUitleg.endsWith('.') ? '.' : '')
                )}`
            );
        }

        return result.length === 0 ? null : uppercaseFirstChar(result.join('') + '.');
    }

    // Example: De mate van luxe en doelmatigheid is <waarde>. Or with explanation: Luxe en doelmatigheid: <toelichting>
    private getFifthSentence(
        explanationData: ExplanationData,
        referenceObjectAnswer: ReferenceObjectAnswer
    ): string | null {
        const result: string[] = [];
        if (
            explanationData.appraisal.appraiseModel === AppraiseModel.MODEL2018 &&
            referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus !== undefined &&
            !isEmpty(referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus) &&
            referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus.toLocaleLowerCase() !== 'vergelijkbaar'
        ) {
            if (
                referenceObjectAnswer.referenceObject.luxeDoelmatigheidUitleg !== undefined &&
                !isEmpty(referenceObjectAnswer.referenceObject.luxeDoelmatigheidUitleg)
            ) {
                result.push(
                    `Mate van luxe en doelmatigheid: ${lowercaseFirstChar(
                        referenceObjectAnswer.referenceObject.luxeDoelmatigheidUitleg +
                            (!referenceObjectAnswer.referenceObject.luxeDoelmatigheidUitleg.endsWith('.') ? '.' : '')
                    )}`
                );
            } else {
                result.push(
                    `De mate van luxe en doelmatigheid is ${this.formatStatus(
                        referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus
                    )}`
                );
            }
            return result.length === 0 ? null : uppercaseFirstChar(result.join('') + '.');
        } else {
            if (
                isAppraiseModelOrNewer(explanationData.appraisal.appraiseModel, AppraiseModel.MODEL2021) &&
                referenceObjectAnswer.referenceObject.mateVanLuxeStatus !== undefined &&
                !isEmpty(referenceObjectAnswer.referenceObject.mateVanLuxeStatus) &&
                referenceObjectAnswer.referenceObject.mateVanLuxeStatus.toLocaleLowerCase() !== 'vergelijkbaar'
            ) {
                if (
                    referenceObjectAnswer.referenceObject.mateVanLuxeUitleg !== undefined &&
                    !isEmpty(referenceObjectAnswer.referenceObject.mateVanLuxeUitleg)
                ) {
                    result.push(
                        `Mate van luxe: ${lowercaseFirstChar(
                            referenceObjectAnswer.referenceObject.mateVanLuxeUitleg +
                                (!referenceObjectAnswer.referenceObject.mateVanLuxeUitleg.endsWith('.') ? '.' : '')
                        )}`
                    );
                } else {
                    result.push(
                        `De mate van luxe is ${this.formatStatus(
                            referenceObjectAnswer.referenceObject.mateVanLuxeStatus
                        )}`
                    );
                }
                result.push(`. `);
            }
            if (
                isAppraiseModelOrNewer(explanationData.appraisal.appraiseModel, AppraiseModel.MODEL2021) &&
                referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus !== undefined &&
                !isEmpty(referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus) &&
                referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus.toLocaleLowerCase() !== 'vergelijkbaar'
            ) {
                if (
                    referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg !== undefined &&
                    !isEmpty(referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg)
                ) {
                    result.push(
                        `Doelmatigheid: ${lowercaseFirstChar(
                            referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg +
                                (!referenceObjectAnswer.referenceObject.mateVanDoelmatigheidUitleg.endsWith('.')
                                    ? '.'
                                    : '')
                        )}`
                    );
                } else {
                    result.push(
                        `De mate van doelmatigheid is ${this.formatStatus(
                            referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus
                        )}`
                    );
                }
                result.push(`.`);
            }
        }

        return result.length === 0 ? null : uppercaseFirstChar(result.join(''));
    }

    // Example:  De ligging is <waarde>, <toelichting>
    private getSixthSentence(
        descriptionTypesWithEqualStatus: DescriptionType[],
        referenceObjectAnswer: ReferenceObjectAnswer
    ): string | null {
        if (descriptionTypesWithEqualStatus.indexOf(DescriptionType.LIGGING) > -1) {
            return null;
        }
        const result: string[] = [];

        if (
            !isEmpty(referenceObjectAnswer.referenceObject.liggingUitleg) &&
            referenceObjectAnswer.referenceObject.liggingStatus.toLocaleLowerCase() !== 'vergelijkbaar'
        ) {
            result.push(
                uppercaseFirstChar(
                    referenceObjectAnswer.referenceObject.liggingUitleg +
                        (!referenceObjectAnswer.referenceObject.liggingUitleg.endsWith('.') ? '.' : '')
                )
            );
        } else if (referenceObjectAnswer.referenceObject.liggingStatus) {
            result.push(`de ligging is ${this.formatStatus(referenceObjectAnswer.referenceObject.liggingStatus)}`);
        }

        return result.length === 0 ? null : uppercaseFirstChar(result.join('') + '.');
    }

    private getDescriptionTypesForStatus(
        status: string,
        referenceObjectAnswer: ReferenceObjectAnswer,
        explanationData: ExplanationData
    ): DescriptionType[] {
        const result: DescriptionType[] = [];
        getAllDescriptionTypes(explanationData).forEach((descriptionItem: DescriptionType) => {
            switch (descriptionItem) {
                case DescriptionType.WONINGTYPE:
                case DescriptionType.ONDERHOUD:
                case DescriptionType.LUXE_DOELMATIGHEID:
                case DescriptionType.DOELMATIGHEID:
                case DescriptionType.LUXE:
                case DescriptionType.LIGGING:
                case DescriptionType.AANBOUW: {
                    const simpleStatus = this.getSimpleStatus(descriptionItem, referenceObjectAnswer);
                    if (simpleStatus !== null && simpleStatus.toLocaleLowerCase() === status.toLocaleLowerCase()) {
                        result.push(descriptionItem);
                    }
                    break;
                }
                case DescriptionType.BOUWJAAR: {
                    if (
                        this.explanationBuildYearGenerator
                            .generateBuildyearStatus(referenceObjectAnswer, explanationData)
                            .toLocaleLowerCase() === status.toLocaleLowerCase()
                    ) {
                        result.push(descriptionItem);
                    }
                    break;
                }
                case DescriptionType.GEBRUIKSOPP: {
                    if (
                        this.explanationFloorAreaGenerator
                            .generateFloorAreaStatus(referenceObjectAnswer, explanationData)
                            .toLocaleLowerCase() === status.toLocaleLowerCase()
                    ) {
                        result.push(descriptionItem);
                    }
                    break;
                }
                case DescriptionType.PERCEELOPP: {
                    if (
                        explanationData.object.plotArea !== null &&
                        explanationData.object.plotArea > 0 &&
                        this.explanationPlotAreaGenerator
                            .generatePlotAreaStatus(referenceObjectAnswer, explanationData)
                            .toLocaleLowerCase() === status.toLocaleLowerCase()
                    ) {
                        result.push(descriptionItem);
                    }
                    break;
                }
                case DescriptionType.ENERGIELABEL: {
                    const energylabelDifference = this.explanationEnergyLabelGenerator.getEnergyLabelStatus(
                        referenceObjectAnswer,
                        explanationData
                    );

                    if (
                        explanationData.object.energyLabel !== undefined &&
                        energylabelDifference.toLocaleLowerCase() === status.toLocaleLowerCase()
                    ) {
                        result.push(descriptionItem);
                    }
                    break;
                }
                default:
                    break;
            }
        });
        return result;
    }

    private getSimpleStatus(
        descriptionItem: DescriptionType,
        referenceObjectAnswer: ReferenceObjectAnswer
    ): string | null {
        switch (descriptionItem) {
            case DescriptionType.WONINGTYPE:
                return referenceObjectAnswer.referenceObject.woningTypeStatus ?? null;
            case DescriptionType.LUXE_DOELMATIGHEID:
                return referenceObjectAnswer.referenceObject.luxeDoelmatigheidStatus ?? null;
            case DescriptionType.LUXE:
                return referenceObjectAnswer.referenceObject.mateVanLuxeStatus ?? null;
            case DescriptionType.DOELMATIGHEID:
                return referenceObjectAnswer.referenceObject.mateVanDoelmatigheidStatus ?? null;
            case DescriptionType.LIGGING:
                return referenceObjectAnswer.referenceObject.liggingStatus ?? null;
            case DescriptionType.KWALITEIT:
                return referenceObjectAnswer.referenceObject.kwaliteitConditieStatus ?? null;
            case DescriptionType.AANBOUW:
                return referenceObjectAnswer.referenceObject.aanbouwStatus ?? null;
            case DescriptionType.ONDERHOUD:
                return referenceObjectAnswer.referenceObject.onderhoudsSituatieStatus ?? null;
            case DescriptionType.ENERGIELABEL:
                return referenceObjectAnswer.referenceObject.energielabel ?? null;
            default:
                return null;
        }
    }

    private formatStatus(status: string): string {
        switch (status.toLocaleLowerCase()) {
            default:
                return status.toLocaleLowerCase();
        }
    }
}
