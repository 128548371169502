import {ImageUrlPair} from '../../../../../../../../models/image_url_pair';
import {ReferenceObjectAnswer} from '../models/reference_object_answer';
import {ReferenceSale} from '../models/reference_sale';
import {toJS} from 'mobx';
import {referenceSaleImagesToImageUrlPairs} from '../models/reference_sale_image';

export function referenceSaleImageUrlPairs(
    referenceSale: ReferenceSale,
    referenceObjectAnswer: ReferenceObjectAnswer | null
): ImageUrlPair[] {
    const imageUrls: ImageUrlPair[] = [];
    if (referenceObjectAnswer !== null && referenceObjectAnswer.photoUrl !== null) {
        imageUrls.push({
            path: null,
            url: referenceObjectAnswer.photoUrl,
        });
    } else {
        imageUrls.push({
            path: null,
            url:
                '/reference-objects/address/' +
                referenceSale.street +
                '/' +
                referenceSale.houseNumber +
                '/' +
                referenceSale.city +
                '/streetview',
        });
    }

    return [...imageUrls, ...toJS(referenceSaleImagesToImageUrlPairs(referenceSale.images))];
}

export function referenceSaleInSaleImageUrlPairs(
    referenceSale: ReferenceSale,
    referenceObjectAnswer: ReferenceObjectAnswer | null
): ImageUrlPair[] {
    const imageUrls: ImageUrlPair[] = [];
    if (referenceObjectAnswer !== null && referenceObjectAnswer.photoUrl !== null) {
        imageUrls.push({path: null, url: referenceObjectAnswer.photoUrl});
    } else if (referenceObjectAnswer !== null) {
        imageUrls.push({
            path: null,
            url:
                '/reference-objects/address/' +
                referenceObjectAnswer.referenceObject.adres.straat +
                '/' +
                referenceObjectAnswer.referenceObject.adres.huisnummer +
                '/' +
                referenceObjectAnswer.referenceObject.adres.plaats +
                '/streetview',
        });
    } else {
        imageUrls.push({
            path: null,
            url:
                '/reference-objects/address/' +
                referenceSale.street +
                '/' +
                referenceSale.houseNumber +
                '/' +
                referenceSale.city +
                '/streetview',
        });
    }

    return [...imageUrls, ...toJS(referenceSaleImagesToImageUrlPairs(referenceSale.images))];
}

export function referenceSaleInRentImageUrlPairs(
    referenceSale: ReferenceSale,
    referenceObjectAnswer: ReferenceObjectAnswer | null
) {
    return referenceSaleInSaleImageUrlPairs(referenceSale, referenceObjectAnswer);
}
