import * as React from 'react';

import {SimpleQuestionPresenterProps} from './simple_question_presenter';

import {AdaptedDefaultValuesMap} from '../../../../../models/adapted_default_values_map';
import {Appraisal} from '../../../../../models/appraisal';
import {QuestionSet} from '../../../../../models/question_set';
import {observer} from 'mobx-react';
import {EnergeticWidgetPresenter} from './energetic_widget_presenter';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {
    questionSet: QuestionSet;
    onChange?: (appraisal: Appraisal) => void;
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
}

export const EnergeticWidget: React.FC<OwnProps> = observer(function EnergeticWidget(props) {
    const presenter = usePresenter(
        (container) =>
            new EnergeticWidgetPresenter(
                container.business.appraiseSecondaryConfigStackInteractor,
                container.business.modalConfigStackInteractor,
                props.pagePartsSet,
                props.appraisal,
                props.questionSet,
                props.renderingContext,
                props.question,
                props.parentAnswerUuid ?? null,
                props.iteration ?? null,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.userInteractor,
                container.business.questionEffectsInteractor(props.appraisal, props.questionSet)
            )
    );

    if (presenter.isHidden) {
        return null;
    }

    const unspported = presenter.getUnsupportedMessage();
    if (unspported) {
        return <div className="alert-inline alert-disabled ion-md-calculator mt-3 mb-3">{unspported}</div>;
    }

    return (
        <div className="alert-inline alert-success ion-md-calculator mt-3 mb-3">
            Genereer een advies voor verbetermaatregelen op basis van de gegevens ingevuld in de energetische
            opnamestaat.
            <a className="mt-2 btn btn-sm float-right" onClick={() => presenter.toggleWidget()}>
                Genereren
            </a>
        </div>
    );
});
