import React, {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {classNames} from '../../../../../support/classnames';
import {QuestionTableChildContext, QuestionTableContext} from './question_table_context';

export function QuestionTableCollapseButton({
    small = false,
    collapsed: collapsedState,
    setCollapsed,
}: {
    small?: boolean;
    collapsed?: boolean;
    setCollapsed?: (collapsed: boolean) => void;
}) {
    const tableContext = useContext(QuestionTableContext);
    const parentContext = useContext(QuestionTableChildContext);

    const collapsedRef = useRef(false);
    useMemo(() => {
        if (parentContext.collapsed) {
            collapsedRef.current = true;
        } else if (
            parentContext.parentKey !== undefined &&
            tableContext.collapsedKeys.includes(parentContext.parentKey)
        ) {
            collapsedRef.current = true;
        } else {
            collapsedRef.current = false;
        }
    }, [parentContext, tableContext.collapsedKeys]);

    const onClick = useCallback(() => {
        if (!parentContext.parentKey) {
            return;
        }

        if (collapsedRef.current) {
            tableContext.expand(parentContext.parentKey);
            collapsedRef.current = false;
        } else {
            tableContext.collapse(parentContext.parentKey);
            collapsedRef.current = true;
        }
    }, [parentContext.parentKey, setCollapsed]);

    // If the state of the parent component changes, we carry this through to the context such that all other columns will also change
    useEffect(() => {
        if (collapsedState === undefined) {
            return;
        }

        if (collapsedState !== collapsedRef.current) {
            onClick();
        }
    }, [collapsedState]);

    // If the state of the context changes, we will need to update the parent state, such that it contains the valid current state
    useEffect(() => {
        if (setCollapsed && collapsedState !== collapsedRef.current) {
            setCollapsed(collapsedRef.current);
        }
    }, [collapsedRef.current]);

    if (small) {
        return (
            <button
                className={classNames('btn-text-link btn-single-icon ml-1 text-dark', {
                    'ion-md-arrow-dropup': !collapsedRef.current,
                    'ion-md-arrow-dropdown': collapsedRef.current,
                })}
                onClick={onClick}
                type="button"
            />
        );
    }

    return (
        <button className="btn-circle btn-sm" type="button" onClick={onClick}>
            <span
                className={classNames({
                    'ion-md-arrow-dropup': !collapsedRef.current,
                    'ion-md-arrow-dropdown': collapsedRef.current,
                })}
            />
        </button>
    );
}
