import * as React from 'react';
import {createPortal} from 'react-dom';
import {ConfigTabsContainer} from './config_tabs_container';
import {PagePartsSet} from '../../models/page_parts_set';
import {Appraisal} from '../../models/appraisal';
import {QuestionSet} from '../../models/question_set';

interface OwnProps {
    activePagePartSet: PagePartsSet | null;
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export const SecondaryContainer: React.FunctionComponent<OwnProps> = ({appraisal, questionSet, activePagePartSet}) => {
    const [containerDisplayed, setContainerDisplayed] = React.useState(false);

    const readContainerState = React.useCallback(() => {
        //If the container is hidden, dont render the secondary
        const appraiseSecondaryContainer = document.getElementById('appraise-secondary-container');
        const isContainerDisplayed = !(
            appraiseSecondaryContainer && window.getComputedStyle(appraiseSecondaryContainer, null).display === 'none'
        );

        if (isContainerDisplayed !== containerDisplayed) {
            setContainerDisplayed(isContainerDisplayed);
        }
    }, [containerDisplayed]);
    React.useEffect(() => {
        readContainerState();
        window.addEventListener('resize', readContainerState);
        return () => window.removeEventListener('resize', readContainerState);
    });

    if (activePagePartSet == null) {
        return null;
    }

    const appraiseSecondary = document.getElementById('appraise-secondary');
    if (!appraiseSecondary) {
        return null;
    }

    if (containerDisplayed === false) {
        return null;
    }

    return createPortal(
        <div className="secondaries-container">
            <div className="secondary-container">
                <ConfigTabsContainer
                    appraisal={appraisal}
                    questionSet={questionSet}
                    activePagePartSet={activePagePartSet}
                />
            </div>
        </div>,
        appraiseSecondary
    );
};
