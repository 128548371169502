import {UnjudgedPageAction} from '../unjudged_page_action';
import {SidebarItem} from '../../../../../business/sidebar_tree_builder';
import {UnjudgedPage} from '../unjudged_pages_provider';
import {TechnicalReference} from '../../../../../enum/technical_reference';

export class ReferenceObjectGroupAction implements UnjudgedPageAction {
    public judge(page: SidebarItem): UnjudgedPage | null {
        if (
            page.question === undefined ||
            page.question.technicalReference !== TechnicalReference.REFERENCE_OBJECTS_GROUP
        ) {
            return null;
        }

        return {
            sidebarItem: page,
            message: 'Het is verplicht om 3 of meer referenties op te voeren.',
            action: 'visit',
        };
    }
}
