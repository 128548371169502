import {
    useSensors,
    useSensor,
    PointerSensor,
    KeyboardSensor,
    DragEndEvent,
    closestCenter,
    DndContext,
} from '@dnd-kit/core';
import {SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {FunctionComponent} from 'react';
import {LoadingStateType} from '../../../support/presenter/helpers';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {ConfigListItem} from './config_list_item';
import {ConfigListTabPresenter} from './config_list_tab_presenter';
import {NewConfigModal} from './new_config_modal';
import {restrictToParentElement, restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {Appraisal} from '../../models/appraisal';
import {QuestionSet} from '../../models/question_set';
import {QuestionLoader} from '../../appraise/ui/components/question_loader';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

export const ConfigListTab: FunctionComponent<OwnProps> = observer(({appraisal, questionSet}) => {
    const presenter = usePresenter(
        (component) =>
            new ConfigListTabPresenter(
                component.business.pagePartsSetInteractor(appraisal, questionSet),
                component.business.flashMessageBroadcaster
            )
    );

    const visibleItems = presenter.visiblePagePartsSets.sort((a, b) => a.config.rank - b.config.rank);
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    function handleDragEnd(event: DragEndEvent) {
        const {active, over} = event;

        if (over && active.id !== over.id) {
            const newIndex = presenter.visiblePagePartsSets.findIndex((set) => set.id === over.id);
            presenter.updateConfigRank(newIndex, active.id as number);
        }
    }

    return (
        <div className="row">
            <h3>
                <span className="ion-md-list"></span> Model configuraties
            </h3>
            <p>
                In dit overzicht zijn alle model configuraties te vinden. Hier kunnen configuraties aangemaakt,
                verwijderd, gedupliceerd en gedeeld worden. De configuraties kunnen van volgorde verwisseld worden door
                ze te verslepen.
            </p>
            {presenter.loadingState === LoadingStateType.LOADING && presenter.pagePartsSets === null ? (
                <QuestionLoader />
            ) : (
                <>
                    <h2>Zichtbare configuraties</h2>
                    <div className="col-12">
                        <ul className="list-circles">
                            <DndContext
                                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}
                            >
                                <SortableContext items={visibleItems} strategy={verticalListSortingStrategy}>
                                    {visibleItems.map((set) => (
                                        <ConfigListItem
                                            key={set.id}
                                            disabled={presenter.loadingState === LoadingStateType.LOADING}
                                            pagePartsSet={set}
                                            visibleFullSetsCount={presenter.visibleFullSetsCount}
                                            duplicatePagePartsSet={presenter.duplicatePagePartsSet}
                                            showPagePartsSet={presenter.showPagePartsSet}
                                            hidePagePartsSet={presenter.hidePagePartsSet}
                                            deletePagePartsSet={presenter.deletePagePartsSet}
                                            stopSharingPagePartsSet={presenter.stopSharingPagePartsSet}
                                            sharePagePartsSet={presenter.sharePagePartsSet}
                                        />
                                    ))}
                                </SortableContext>
                            </DndContext>
                        </ul>
                    </div>

                    {presenter.hiddenPagePartsSets.length > 0 && (
                        <>
                            <h2>Verborgen configuraties</h2>
                            <div className="col-12">
                                <ul className="list-circles">
                                    {presenter.hiddenPagePartsSets.map((set) => (
                                        <ConfigListItem
                                            key={set.id}
                                            disabled={presenter.loadingState === LoadingStateType.LOADING}
                                            pagePartsSet={set}
                                            visibleFullSetsCount={presenter.visibleFullSetsCount}
                                            duplicatePagePartsSet={presenter.duplicatePagePartsSet}
                                            showPagePartsSet={presenter.showPagePartsSet}
                                            hidePagePartsSet={presenter.hidePagePartsSet}
                                            deletePagePartsSet={presenter.deletePagePartsSet}
                                            stopSharingPagePartsSet={presenter.stopSharingPagePartsSet}
                                            sharePagePartsSet={presenter.sharePagePartsSet}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </>
                    )}
                </>
            )}

            <div className="d-flex justify-content-end w-100">
                <button
                    disabled={presenter.loadingState === LoadingStateType.LOADING}
                    className="btn btn-primarty btn-sm ion-md-add"
                    onClick={() => presenter.setIsAddConfigurationModalOpen(true)}
                >
                    Configuratie Toevoegen
                </button>
            </div>

            {presenter.newConfigurationModalState.isOpen && (
                <NewConfigModal
                    loadingState={presenter.loadingState}
                    newConfigurationModalState={presenter.newConfigurationModalState}
                    onNewConfigurationNameChange={presenter.onNewConfigurationNameChange}
                    onNewConfigurationDescriptionChange={presenter.onNewConfigurationDescriptionChange}
                    onNewConfigurationIsSharedChange={presenter.onNewConfigurationIsSharedChange}
                    setIsAddConfigurationModalOpen={presenter.setIsAddConfigurationModalOpen}
                    createnNewConfiguration={presenter.createnNewConfiguration}
                />
            )}
        </div>
    );
});
