import {ExplanationData} from './explanation_data';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {normalizeNumber} from '../../../../../../../../../business/support/normalize_number';

export class ExplanationFloorAreaGenerator {
    public generateDetailsSentence(
        explanationData: ExplanationData,
        referenceObjectAnswer: ReferenceObjectAnswer
    ): string {
        const result: string[] = [];
        const floorAreaDiffPercentage = this.getFloorAreaDiffPercentage(referenceObjectAnswer, explanationData);
        const floorAreaDiff = normalizeNumber(
            Math.abs(
                (explanationData.object.floorArea ?? 0) -
                    (referenceObjectAnswer.referenceObject.gebruiksOppervlakte ?? 0)
            )
        );
        if (floorAreaDiffPercentage !== null) {
            if (floorAreaDiffPercentage > 30 || floorAreaDiffPercentage > 10) {
                result.push(`${floorAreaDiff} m2 meer woonoppervlakte`);
            } else if (floorAreaDiffPercentage < -30 || floorAreaDiffPercentage < -10) {
                result.push(`${floorAreaDiff} m2 minder woonoppervlakte`);
            }
        }
        return result.join('');
    }

    public generateFloorAreaStatus(referenceObjectAnswer: ReferenceObjectAnswer, explanationData: ExplanationData) {
        const floorAreaDiffPercentage = this.getFloorAreaDiffPercentage(referenceObjectAnswer, explanationData);
        if (floorAreaDiffPercentage !== null) {
            if (floorAreaDiffPercentage > 30) {
                return 'Beduidend groter';
            } else if (floorAreaDiffPercentage > 10) {
                return 'Groter';
            } else if (floorAreaDiffPercentage < -30) {
                return 'Beduidend kleiner';
            } else if (floorAreaDiffPercentage < -10) {
                return 'Kleiner';
            } else {
                return 'Vergelijkbaar';
            }
        }
        return 'Vergelijkbaar';
    }

    private getFloorAreaDiffPercentage(
        referenceObjectAnswer: ReferenceObjectAnswer,
        explanationData: ExplanationData
    ): number | null {
        if (
            referenceObjectAnswer.referenceObject.gebruiksOppervlakte === null ||
            explanationData.object.floorArea === null
        ) {
            return null;
        }

        return normalizeNumber(
            (100 * (referenceObjectAnswer.referenceObject.gebruiksOppervlakte - explanationData.object.floorArea)) /
                referenceObjectAnswer.referenceObject.gebruiksOppervlakte
        );
    }
}
