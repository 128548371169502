import * as React from 'react';
import {classNames} from '../../../../support/classnames';
import {RenderingContextType} from '../../../enum/rendering_context_type';
import {Answer} from '../../../models/answer';
import {Question} from '../../../models/question';

interface OwnProps {
    renderingContext: RenderingContextType;
    compact?: () => React.ReactNode;
    expanded?: (close: () => void) => React.ReactNode;
    captureClick?: boolean;
    children?: React.ReactNode;
    question: Question | null;
    answer?: Answer | null;
    isAnswered?: boolean;
}

export const CompactEditable: React.FC<OwnProps> = (props: OwnProps) => {
    const [expanded, setExpanded] = React.useState<boolean | null>(null);

    React.useEffect(() => {
        if (
            props.renderingContext !== RenderingContextType.COMPACT_GRID_COLUMNS ||
            expanded !== null ||
            !props.question?.isRequired
        ) {
            return;
        }
        if (props.isAnswered === false) {
            setExpanded(true);
        } else if (
            props.answer !== null &&
            props.answer !== undefined &&
            !props.answer.contents &&
            !props.answer.answerOptionId
        ) {
            setExpanded(true);
        }
    }, [props.answer, props.isAnswered]);

    if (props.renderingContext !== RenderingContextType.COMPACT_GRID_COLUMNS) {
        return <>{props.children}</>;
    }

    return (
        <div
            className={classNames('compact-editable clearfix', {
                active: expanded,
                capture: props.captureClick ?? true,
            })}
            onClick={props.captureClick ?? true ? () => (!expanded ? setExpanded(true) : null) : undefined}
        >
            {expanded
                ? props.expanded
                    ? props.expanded(() => setExpanded(false))
                    : props.children
                : props.compact?.()}
            <div className="compact-editable-container">
                <button
                    type="button"
                    className={classNames('btn btn-xs compact-editable-button', {
                        'ion-md-create': !expanded,
                        'ion-md-checkmark': expanded,
                    })}
                    onClick={() => setExpanded(!expanded)}
                />
            </div>
        </div>
    );
};
