import {AppraisalProvider} from '../appraisal_provider';
import {TextAIContextBuilder} from './textai_context_builder';

export class DefaultTextAIAppraisalContextBuilder implements TextAIContextBuilder {
    constructor(private appraisalProvider: AppraisalProvider) {}

    public getContext(): {[key: string]: unknown} {
        const appraisal = this.appraisalProvider.appraisal;

        return {
            Taxatie: {
                Straat: appraisal.address,
                Huisnummer: appraisal.houseNumber,
                Toevoeging: appraisal.letter,
                Postcode: appraisal.postalCode,
                Plaats: appraisal.city,
                Objecttype: appraisal.objectType,
                Nieuwbouw: appraisal.newConstruction ? 'Ja' : undefined,
                Verbouwing: appraisal.improvementsPlanned ? 'Ja' : undefined,
            },
        };
    }
}
