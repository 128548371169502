import * as React from 'react';

import {FunctionComponent} from 'react';
import {ReferenceObjectTilePlaceholder} from './components/reference_object_tile/reference_object_tile_placeholder';

export const DummyReferenceObjectsQuestion: FunctionComponent = () => {
    return (
        <>
            <div className="group-container-content">
                <div className="form-group">
                    <div className="row">
                        <div className="reference-objects-nav-container">
                            <ul className="reference-objects-nav">
                                <li className="reference-objects-nav-item active disabled">
                                    <span className="reference-objects-nav-item-title">
                                        <span className="reference-objects-nav-item-title-inner">
                                            <span className="placeholder placeholder-width-75"></span>
                                        </span>
                                    </span>
                                </li>
                                <li className="reference-objects-nav-item disabled">
                                    <span className="reference-objects-nav-item-title-inner">
                                        <span className="placeholder placeholder-width-100"></span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-6">
                            <h2 className="reference-objects-nav-title">
                                <span className="placeholder placeholder-width-50 mr-1 placeholder-opacity-3"></span>
                                <span className="placeholder placeholder-width-100"></span>
                            </h2>
                            <h3 className="reference-objects-nav-subtitle">
                                <span className="placeholder placeholder-width-200 placeholder-opacity-1"></span>
                            </h3>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="reference-objects-nav-btn-container"></div>
                        </div>
                        <div className="reference-set-valuation-questions-container">
                            <div className="col-12">
                                <div>
                                    <div className="placeholder"></div>
                                </div>
                                <div className="form-columns">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <div className="label-container">
                                                    <label className="label-placeholder">&nbsp;</label>
                                                </div>
                                                <div className="input-group input-group-no-prepend">
                                                    <input type="text" className="form-control disabled" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <div className="label-container">
                                                    <label className="label-placeholder">&nbsp;</label>
                                                </div>
                                                <div className="input-group input-group-no-prepend">
                                                    <input type="text" className="form-control disabled" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <div className="label-container">
                                                    <label className="label-placeholder">&nbsp;</label>
                                                </div>
                                                <div className="input-group input-group-no-prepend">
                                                    <input type="text" className="form-control disabled" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="reference-object-grid-parent">
                                <div className="row">
                                    <div className="col-12">
                                        <ul className="nav-filter">
                                            <li className="nav-filter-item active">
                                                <span className="placeholder placeholder-width-100"></span>
                                            </li>
                                            <li className="nav-filter-item">
                                                <span className="placeholder placeholder-width-75"></span>
                                            </li>
                                            <li className="nav-filter-item">
                                                <span className="placeholder placeholder-width-100"></span>
                                            </li>
                                            <li className="nav-filter-item disabled">
                                                <span className="placeholder placeholder-width-50"></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row grid reference-object-grid reference-object-grid-reload">
                                    <ReferenceObjectTilePlaceholder animated={false} tiles={6} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
