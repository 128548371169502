import {RenderingContextType} from '../appraising/enum/rendering_context_type';

export function shouldShowTitle(
    renderingContext: RenderingContextType,
    showLabel: boolean,
    hideLabel?: boolean
): boolean {
    // if (renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
    //     return true;
    // }

    return hideLabel !== true && showLabel;
}
