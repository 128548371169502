import * as React from 'react';

import {FunctionComponent} from 'react';

export const DummyConceptReport: FunctionComponent = () => {
    return (
        <div>
            <div className="placeholder d-block placeholder-width-500 placeholder-opaque-40 mt-1"></div>
            <div className="placeholder d-block placeholder-width-400 placeholder-opaque-38 mt-1"></div>
            <div className="placeholder d-block placeholder-width-500 placeholder-opaque-36 mt-1"></div>
            <div className="placeholder d-block placeholder-width-200 placeholder-opaque-34 mt-1"></div>
            <div className="placeholder d-block placeholder-width-100 placeholder-opaque-32 mt-1"></div>
            <div className="placeholder d-block placeholder-width-500 placeholder-opaque-30 mt-1"></div>
            <div className="placeholder d-block placeholder-width-300 placeholder-opaque-28 mt-1"></div>
            <div className="placeholder d-block placeholder-width-200 placeholder-opaque-26 mt-1"></div>
            <div className="placeholder d-block placeholder-width-300 placeholder-opaque-24 mt-1"></div>
            <div className="placeholder d-block placeholder-width-400 placeholder-opaque-22 mt-1"></div>
            <div className="placeholder d-block placeholder-width-100 placeholder-opaque-24 mt-1"></div>
            <div className="placeholder d-block placeholder-width-500 placeholder-opaque-22 mt-1"></div>
            <div className="placeholder d-block placeholder-width-400 placeholder-opaque-20 mt-1"></div>
            <div className="placeholder d-block placeholder-width-200 placeholder-opaque-18 mt-1"></div>
            <div className="placeholder d-block placeholder-width-100 placeholder-opaque-16 mt-1"></div>
            <div className="placeholder d-block placeholder-width-500 placeholder-opaque-14 mt-1"></div>
            <div className="placeholder d-block placeholder-width-300 placeholder-opaque-16 mt-1"></div>
            <div className="placeholder d-block placeholder-width-200 placeholder-opaque-14 mt-1"></div>
            <div className="placeholder d-block placeholder-width-300 placeholder-opaque-12 mt-1"></div>
            <div className="placeholder d-block placeholder-width-400 placeholder-opaque-10 mt-1"></div>
            <div className="placeholder d-block placeholder-width-100 placeholder-opaque-8 mt-1"></div>
        </div>
    );
};
