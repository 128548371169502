import React, {useState} from 'react';
import {createRoot} from 'react-dom/client';

import {AltumPreferencesModal} from '../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v3/components/modals/altum_preferences_modal';
import {usePresenter} from '../support/presenter/use_presenter';
import {SearchPresenter} from './search_presenter';
import {observer} from 'mobx-react';
import {PriceRange} from '../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v3/components/price_range';
import {isEmpty} from '../support/util';
import {Loader} from '../appraising/appraise/ui/components/loader';
import {SearchDetailsModal} from './search_details_modal';
import {ObjectTypeModal} from '../create_appraisal/pre_check/altum_reference_objects/object_type_modal';
import {referenceObjectImages} from '../appraising/appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/reference_sale_images';

const Search = observer(() => {
    const [showPreferences, setShowPreferences] = useState(false);
    const [detailsIndex, setDetailsIndex] = useState<number | null>(null);

    const presenter = usePresenter((component) => new SearchPresenter(component.network.referenceObjectApi));

    return (
        <div>
            <div className="row card-filter">
                <div className="col-12 col-md-4">
                    <div className="form-group">
                        <label htmlFor="input-postal-code">Postcode</label>
                        <input
                            id="input-postal-code"
                            className="form-control small"
                            type="text"
                            value={presenter.addressDetails.postalCode}
                            onChange={(e) => presenter.setAddressDetails({postalCode: e.target.value})}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-2">
                    <div className="form-group">
                        <label htmlFor="input-house-number">Huisnummer</label>
                        <input
                            id="input-house-number"
                            className="form-control small"
                            type="text"
                            value={presenter.addressDetails.houseNumber}
                            onChange={(e) => presenter.setAddressDetails({houseNumber: e.target.value})}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-2">
                    <div className="form-group">
                        <label htmlFor="input-letter">Toevoeging</label>
                        <input
                            id="input-letter"
                            className="form-control small"
                            type="text"
                            value={presenter.addressDetails.letter}
                            onChange={(e) => presenter.setAddressDetails({letter: e.target.value})}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <button
                        className="btn btn-primary btn-sm card-filter-button"
                        onClick={() => {
                            presenter.request();
                        }}
                    >
                        Zoeken
                    </button>
                </div>
                <div className="col-12 col-md-1 d-flex justify-content-center">
                    <a className="ion-md-settings card-filter-link" onClick={() => setShowPreferences(true)}></a>
                </div>
            </div>
            <AltumPreferencesModal
                visible={showPreferences}
                onClose={() => setShowPreferences(false)}
                forceRequest={(preferences) => {
                    presenter.updatePreferences(preferences);
                    presenter.request();
                }}
                changeObjectType={() => presenter.setShowObjectTypeModal(true)}
            />
            <ObjectTypeModal
                visible={presenter.showObjectTypeModal}
                onClose={() => presenter.setShowObjectTypeModal(false)}
                storeObjectType={(objectType) => {
                    presenter.setAddressDetails({objectType});
                    presenter.request();
                }}
                street={presenter.addressDetails.street}
                houseNumber={presenter.addressDetails.houseNumber}
                letter={presenter.addressDetails.letter}
                postalCode={presenter.addressDetails.postalCode}
                city={presenter.addressDetails.city}
                error={presenter.error}
            />
            {presenter.loading ? (
                <div className="mt-5 d-flex justify-content-center">
                    <Loader addSpace={false} />
                </div>
            ) : (
                <ul className="list-circles mt-2">
                    {presenter.referenceObjects.map((referenceObject, i) => {
                        const images = referenceObjectImages(referenceObject, null);
                        const frontview = images.length > 0 ? images[0]?.url : null;

                        return (
                            <li key={`${referenceObject.id}-${referenceObject.saleQuarter}`}>
                                <span className="list-avatar ion-md-pin">
                                    {frontview ? <img src={frontview}></img> : null}
                                </span>
                                <span className="list-title">
                                    <strong className="grid-item-address">
                                        {referenceObject.street}{' '}
                                        <span className="grid-item-address-number">
                                            {referenceObject.houseNumber} {referenceObject.letter}
                                        </span>
                                    </strong>
                                    {referenceObject.postalCode} {referenceObject.city}
                                </span>
                                <small className="list-details">
                                    <PriceRange
                                        range={
                                            !isEmpty(referenceObject.indexedPriceRange)
                                                ? String(referenceObject.indexedPriceRange)
                                                : referenceObject.priceRange !== null
                                                ? String(referenceObject.priceRange)
                                                : null
                                        }
                                    />
                                    {referenceObject.saleQuarter ? ` | ${referenceObject.saleQuarter}` : null}
                                </small>
                                <span className="list-options">
                                    <button className="ion-md-eye" onClick={() => setDetailsIndex(i)} />
                                </span>
                            </li>
                        );
                    })}
                </ul>
            )}
            {detailsIndex !== null && presenter.referenceObjects[detailsIndex] && (
                <SearchDetailsModal
                    referenceObject={presenter.referenceObjects[detailsIndex]}
                    onClose={() => setDetailsIndex(null)}
                />
            )}
        </div>
    );
});

export function renderReferenceObjectsSearchInto(element: HTMLElement) {
    createRoot(element).render(<Search />);
}
