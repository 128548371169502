import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from '../simple/simple_question_presenter';
import {isMobile, isTablet} from '../../../../../../support/check_mobile';

import {AdaptedDefaultValuesMap} from '../../../../../models/adapted_default_values_map';
import {Appraisal} from '../../../../../models/appraisal';
import {Label} from '../components/label';
import {MultipleBooleanGroupQuestionPresenter} from './multiple_boolean_group_question_presenter';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import ReactSelect from 'react-select';
import {ValidationError} from '../../../../../models/validation_error';
import {ValidationMessages} from '../components/validation_messages';
import {classNames} from '../../../../../../support/classnames';
import {filterValidationMessages} from '../../../support/filter_validation_messages';
import {findChildrenForQuestion} from '../../../support/question_filtering';
import {observer} from 'mobx-react';
import {CompactEditable} from '../../../components/compact_editable';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {QuestionLoader} from '../../../components/question_loader';

interface OwnProps extends SimpleQuestionPresenterProps {
    appraisal: Appraisal;
    question: Question;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    parentAnswerUuid?: string;
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
}

export const MultipleBooleanGroupQuestion: React.FC<OwnProps> = observer(function MultipleBooleanGroupQuestion(props) {
    const presenter = usePresenter(
        (container) =>
            new MultipleBooleanGroupQuestionPresenter(
                findChildrenForQuestion(props.question, props.questionSet),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );

    if (presenter.isHidden) {
        return null;
    }
    if (presenter.childAnswers === undefined) {
        return <QuestionLoader />;
    }

    const childQuestions = findChildrenForQuestion(props.question, props.questionSet);

    function handleChange(newValue: string[], questionUuids: string[]) {
        presenter.onChildChange(newValue, questionUuids);
        presenter.onChange('');
    }

    function renderDefault(childQuestions: Question[]) {
        const options = childQuestions.map((q: Question) => {
            return {
                label: q.contents,
                value: q.uuid,
            };
        });

        const value = presenter.selectedQuestions.map((q: Question) => {
            return {
                label: q.contents,
                value: q.uuid,
            };
        });

        return (
            <ReactSelect
                id={props.question.uuid}
                name={props.question.uuid}
                classNamePrefix="form-multiple-select"
                className="form-multiple-select"
                isMulti={true}
                isClearable={false}
                options={options}
                isSearchable={false}
                value={value}
                isDisabled={props.disabled || presenter.isDisabled}
                onChange={(newValue) =>
                    handleChange(
                        newValue ? newValue.map((v) => v.value) : [],
                        childQuestions.map((child) => child.uuid)
                    )
                }
                closeMenuOnSelect={false}
                closeMenuOnScroll={false}
                menuShouldScrollIntoView={false}
                placeholder={presenter.placeholderText}
                menuPortalTarget={document.getElementById('react-select-portal')}
            />
        );
    }

    function renderMobile(childQuestions: Question[]) {
        const selectedUuids = presenter.selectedQuestions.map((q) => q.uuid);

        return (
            <div>
                <select
                    id={props.question.uuid}
                    name={props.question.uuid}
                    className="form-control custom-select"
                    onChange={(e) => {
                        handleChange(
                            Array.from(e.target.selectedOptions, (option) => {
                                return option.value;
                            }),
                            childQuestions.map((child) => child.uuid)
                        );
                    }}
                    multiple={true}
                    value={selectedUuids}
                    disabled={props.disabled || presenter.isDisabled}
                >
                    <option disabled>{presenter.placeholderText}</option>
                    {childQuestions.map((q) => (
                        <option key={q.uuid} value={q.uuid}>
                            {q.contents}
                        </option>
                    ))}
                </select>
                <span className="mt-2 float-left">
                    <span>Geselecteerde opties: </span>
                    {presenter.selectedQuestions.map((q, i, arr) => (
                        <span key={'selected-value-' + q.uuid}>
                            {q.contents}
                            {i !== arr.length - 1 ? ', ' : ''}
                        </span>
                    ))}
                </span>
            </div>
        );
    }

    function renderCompact() {
        return (
            <>
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    disableHistory
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />

                <div>
                    {presenter.selectedQuestions.length > 0 ? (
                        presenter.selectedQuestions.map((q, i, arr) => (
                            <span key={'selected-value-' + q.uuid}>
                                {q.contents}
                                {i !== arr.length - 1 ? ', ' : ''}
                            </span>
                        ))
                    ) : (
                        <i>Niet ingevuld</i>
                    )}
                </div>
            </>
        );
    }

    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'automation-filled': presenter.filledByAutomator !== null,
            })}
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
        >
            <CompactEditable
                renderingContext={props.renderingContext}
                compact={() => renderCompact()}
                question={props.question}
                isAnswered={presenter.selectedQuestions.length > 0}
            >
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />

                {isMobile() || isTablet() ? renderMobile(childQuestions) : renderDefault(childQuestions)}
            </CompactEditable>
            <ValidationMessages
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={presenter.answer}
                validationMessages={filterValidationMessages(
                    props.question,
                    presenter.answer,
                    props.validationMessages,
                    presenter.validationMessages
                )}
            />
        </div>
    );
});
