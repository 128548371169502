if ($('#message-template-list').length > 0) {
    $('#message-template-list .list-item').on('click', function (e) {
        const element = this;
        const key = $(this).attr('data-key');
        if (!key) {
            return;
        }

        if (e.target.className.includes('ion-md-eye-off')) {
            post(`/ajax/message-templates/${key}/disable`, {
                method: 'POST',
                body: null,
            }).then(() => {
                $(e.target).removeClass('ion-md-eye-off').addClass('ion-md-eye');
                setMode(element, 'disabled');
            });
            return;
        } else if (e.target.className.includes('ion-md-eye')) {
            post(`/ajax/message-templates/${key}/enable`, {
                method: 'POST',
                body: null,
            }).then(() => {
                $(e.target).removeClass('ion-md-eye').addClass('ion-md-eye-off');
                setMode(element, 'default');
            });
            return;
        }

        const url = $(this).attr('data-url-frame');

        if (url) {
            if (window.innerWidth < 1400) {
                window.location.href = $(this).attr('data-url-page');
                return;
            }

            $('#message-template-list .list-item').removeClass('list-item-active');
            $(this).addClass('list-item-active');
            $('.message-template-frame').attr('src', url);

            if (key === 'global-settings') {
                $('#message-template-frame-title').html('<span class="ion-md-settings"></span> Instellingen');
            } else {
                $('#message-template-frame-title').html('<span class="ion-md-document"></span> Bericht');
            }
        }
    });

    window.addEventListener('message', (e) => {
        const data = e.data;

        if (data.close === true) {
            $('#message-template-list .list-item').removeClass('list-item-active');
            $('.message-template-frame').attr('src', null);
            return;
        }

        if (!data.mailTemplateMode) {
            return;
        }

        setMode(
            $(`#message-template-list .list-item[data-key="${e.data.mailTemplateMode.key}"]`),
            e.data.mailTemplateMode.mode
        );
    });
}

if ($('.message-template-preview').length > 0) {
    $('.message-template-preview').on('click', function (e) {
        e.preventDefault();
        const element = this;
        const form = $(element).closest('form');
        const key = form.attr('data-key');
        if (!key) {
            return;
        }

        let subject = $('#subject').val() ?? '';
        let content = $('#content').val() ?? '';

        if ($('input[name="mode"]:checked').val() !== 'custom') {
            subject = form.attr('data-default-subject');
            content = form.attr('data-default-content');
        }

        post(`/ajax/message-templates/${key}/preview`, {
            method: 'POST',
            body: JSON.stringify({
                content,
                subject,
            }),
        }).then(async (res) => {
            const json = await res.json();

            if (!res.ok) {
                if (Array.isArray(json?.errors?.content)) {
                    const errors = $(element).siblings('.message-template-preview-errors');
                    errors.text(json.errors.content.join('\n'));
                    errors.show();
                }
                return;
            }

            const iframe = $(element).siblings('.message-template-preview-iframe');
            iframe.find('iframe').attr('srcdoc', json.html);
            iframe.show();

            const subject = $(element).siblings('.message-template-preview-subject');
            subject.find('input').val(json.subject);
            subject.show();
        });
    });
}

function post(url, options = {}) {
    return window.fetch(url, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            'X-Csrf-Token': document.head.querySelector('meta[name="csrf-token"]').content,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        ...options,
    });
}

function setMode(element, mode) {
    $(element).find('.list-options .badge').addClass('d-none');
    if (mode === 'disabled') {
        $(element).find('.list-options .badge.badge-danger').removeClass('d-none');
    } else if (mode === 'custom') {
        $(element).find('.list-options .badge.badge-info').removeClass('d-none');
    } else if (mode === 'default') {
        $(element).find('.list-options .badge.badge-success').removeClass('d-none');
    }
}
