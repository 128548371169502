import {ConfigPhotoRecognition, PhotoCompetingDetails, PhotoDetailRecognizeResult} from './config_photo_recognition';
import {PhotoContentPredicter, Prediction} from './photo_content_predicter';

import {AutoMLVersion} from './automl_photo_content_predicter';
import {PhotoRecognizedAs} from './automl_photo_recognition';

export interface PhotoDetailPredictor {
    getDetailsFromPhoto(photo: PhotoRecognizedAs, file: File): Promise<Prediction[]>;
}

export class AutoMLDetailPhotoRecognition implements PhotoDetailPredictor {
    private predictor: PhotoContentPredicter;
    private details: Prediction[];

    constructor(predictor: PhotoContentPredicter) {
        this.predictor = predictor;
        this.details = [];
    }

    public async getDetailsFromPhoto(photo: PhotoRecognizedAs, file: File): Promise<Prediction[]> {
        const config = photo.label ? ConfigPhotoRecognition.getConfig(photo.label) : null;

        this.details = [];
        if (config !== null) {
            for (const recognizer of config.recognizers) {
                if (recognizer.recognizeResult === PhotoDetailRecognizeResult.MULTIPLE) {
                    await this.setDetails(recognizer.recognizeVersion, file, recognizer.competingDetails);
                } else {
                    await this.setDetail(recognizer.recognizeVersion, file);
                }
            }
        }
        return this.details;
    }

    private async setDetails(version: AutoMLVersion, file: File, competingValues: PhotoCompetingDetails[]) {
        const predictions = await this.predictor.getVersionPredictions(version, file);
        const foundCompeting: Prediction[][] = [];
        if (predictions !== null) {
            predictions.forEach((prediction) => {
                let competing = false;
                competingValues.forEach((values, index) => {
                    values.details.forEach((value) => {
                        if (
                            value.toString().trim().toLowerCase() ===
                            prediction.className.toString().trim().toLowerCase()
                        ) {
                            competing = true;
                            if (foundCompeting[index] === undefined) {
                                foundCompeting[index] = [];
                            }
                            foundCompeting[index].push(prediction);
                        }
                    });
                });
                if (!competing) {
                    this.details.push(prediction);
                }
            });
            for (const value of foundCompeting) {
                if (value.length < 2) {
                    this.details.push(value[0]);
                } else {
                    const reduced = this.reducePredictionValue(value);
                    if (reduced) {
                        this.details.push(reduced);
                    }
                }
            }
        }
    }

    private async setDetail(version: AutoMLVersion, file: File) {
        const prediction = await this.predictor.getVersionPrediction(version, file);
        if (prediction !== null) {
            this.details.push(prediction);
        }
    }

    private reducePredictionValue(value: Prediction[]): Prediction | null {
        const returnValue = value.reduce<null | Prediction>((p, c) => {
            return p === null || c.probability > p.probability ? c : p;
        }, null);
        return returnValue;
    }
}
