import {Answer} from '../../../models/answer';
import {NormalQuestionType} from '../../../enum/question_type';
import {Question} from '../../../models/question';
import {isEmpty} from '../../../../support/util';

export interface AnswerContentBuilder {
    build(answer: Answer, question: Question): string | null;
}

export class DefaultAnswerContentBuilder implements AnswerContentBuilder {
    public build(answer: Answer, question: Question): string | null {
        if (answer.answerOptionId !== null) {
            const option = question.answerOptions.find((o) => o.id === answer.answerOptionId);
            if (option && !option.isIgnoredInReport) {
                return option.reportValue !== null ? option.reportValue : option.contents;
            }
        } else if (question.type === NormalQuestionType.BOOLEAN) {
            if (question.reportValue !== null && answer.contents === '1') {
                return question.reportValue;
            }
            return null;
        } else if (!isEmpty(answer.contents) && answer.contents !== null) {
            return answer.contents;
        }

        return null;
    }
}
