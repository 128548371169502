import {observer} from 'mobx-react-lite';
import React, {FunctionComponent, useEffect} from 'react';
import {usePresenter} from '../../support/presenter/use_presenter';
import {Loader} from '../appraise/ui/components/loader';
import {FlashMessage} from '../appraise/ui/container/flash_message';
import {Appraisal} from '../models/appraisal';
import {QuestionSet} from '../models/question_set';
import {NewSubPageModal} from './components/new_page_modal/new_sub_page_modal';
import {NewRootPageModal} from './components/new_root_page_modal/new_root_page_modal';
import {SortableSidebar} from './components/sidebar/sortable_sidebar';
import {PageConfigurator} from './page_configurator';
import {ModalTypes, ActivePagePartsSetLoaderPresenter} from './active_page_parts_set_loader_presenter';
import {SecondaryContainer} from './secondary/secondary_container';
import {PagePartsSetSelector} from './components/page_parts_set_selector';

interface OwnProps {
    pagePartConfigurationId: number;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    activePagePartUuid: string | null;
}

export const ActivePagePartsSetLoader: FunctionComponent<OwnProps> = observer(
    ({pagePartConfigurationId, appraisal, questionSet, activePagePartUuid}) => {
        const presenter = usePresenter(
            (component) =>
                new ActivePagePartsSetLoaderPresenter(
                    pagePartConfigurationId,
                    questionSet,
                    component.network.pagePartConfigurationApi,
                    component.business.pagePartConfigurationsInteractor,
                    component.business.flashMessageBroadcaster,
                    component.business.pagePartsSetInteractor(appraisal, questionSet),
                    component.business.pagePartsCleaner(questionSet),
                    component.globalProvider
                )
        );
        useEffect(() => {
            if (activePagePartUuid === null && presenter.pagePartsSet) {
                const rootItems = presenter.pagePartsSet.rootItems;
                if (rootItems.length > 0) {
                    window.location.replace(
                        `#/page-parts-configuration/${presenter.pagePartsSet.id}/${rootItems[0].uuid}`
                    );
                }
            }
        }, [presenter.pagePartsSet, activePagePartUuid]);

        if (presenter.pagePartsSet === undefined) {
            return (
                <div className="empty-message appraise">
                    <Loader />
                    <h3>Aan het laden...</h3>
                </div>
            );
        }

        return (
            <>
                <SecondaryContainer
                    appraisal={appraisal}
                    questionSet={questionSet}
                    activePagePartSet={presenter.pagePartsSet}
                />

                <div className="messages-stack">
                    <FlashMessage />
                </div>

                <div className="appraisal-header card-header d-flex align-items-center">
                    {presenter.global.appraisalsUrl && <a href={presenter.global.appraisalsUrl}>Taxatie</a>}

                    <div className="card-header-content">
                        <PagePartsSetSelector
                            activePagePartSet={presenter.pagePartsSet}
                            sets={presenter.visiblePagePartsSets}
                            onLeave={presenter.onLeave}
                            isLoadingLeave={presenter.isLoadingLeave}
                        />
                    </div>
                    {presenter.pagePartsSet?.config.isEditable === false && (
                        <span className="badge badge-pill badge-info ml-2">
                            <i className="ion-md-information-circle"></i>
                            Je kan deze configuratie niet aanpassen omdat je niet de eigenaar bent
                        </span>
                    )}
                </div>

                <SortableSidebar
                    pagePartsSet={presenter.pagePartsSet}
                    activePagePartUuid={activePagePartUuid}
                    updateRank={presenter.updateRank}
                    onNewSubPage={presenter.onShowNewSubPageModal}
                    onDelete={(uuid) => presenter.onDeletePagePart(uuid, activePagePartUuid)}
                    onNewRootPage={presenter.onShowNewRootPageModal}
                />

                {presenter.modalState.type === ModalTypes.NEW_ROOT_PAGE && (
                    <NewRootPageModal
                        newRootPageState={presenter.modalState}
                        onChange={presenter.onNewRootPageChange}
                        onHide={presenter.onHideModals}
                        onSave={presenter.onNewRootPage}
                    />
                )}

                {presenter.modalState.type === ModalTypes.NEW_SUB_PAGE && (
                    <NewSubPageModal
                        newSubPageState={presenter.modalState}
                        onChange={presenter.onNewSubPageChange}
                        onHide={presenter.onHideModals}
                        onSave={presenter.onNewSubPage}
                    />
                )}

                <PageConfigurator
                    appraisal={appraisal}
                    questionSet={questionSet}
                    pagePartsSet={presenter.pagePartsSet}
                    activePagePartUuid={activePagePartUuid}
                    unassignedPagePartQuestions={presenter.unassignedPagePartQuestions}
                    onQuestionAdded={presenter.onQuestionAdded}
                    isLoading={presenter.isLoading}
                    onSave={presenter.onSave}
                    isLoadingLeave={presenter.isLoadingLeave}
                    onLeave={presenter.onLeave}
                />
            </>
        );
    }
);
