import {AppraisalProvider} from './appraisal_provider';

export interface DistanceObject {
    latitude: number | null;
    longitude: number | null;
}

export interface DistanceProvider {
    /**
     * @param object Object containing lat and long to calculate distance from
     * @returns Distance in meters or null if distance can not be calculated
     */
    getDistanceToAppraisal<T extends DistanceObject>(object: T): number | null;
}

export class DefaultDistanceProvider {
    constructor(private appraisalProvider: AppraisalProvider) {}

    private deg2rad(degrees: number) {
        return (degrees * Math.PI) / 180;
    }

    public getDistanceToAppraisal<T extends DistanceObject>(object: T) {
        // if latitude or longitude is not set, distance can not be calculated
        if (
            this.appraisalProvider.appraisal.latitude === null ||
            this.appraisalProvider.appraisal.longitude === null ||
            object.latitude === null ||
            object.longitude === null ||
            (object.latitude === 0 && object.longitude === 0)
        ) {
            return null;
        }

        const earthRadius = 6371000;

        // convert from degrees to radians
        const latFrom = this.deg2rad(this.appraisalProvider.appraisal.latitude);
        const lonFrom = this.deg2rad(this.appraisalProvider.appraisal.longitude);
        const latTo = this.deg2rad(object.latitude);
        const lonTo = this.deg2rad(object.longitude);

        const latDelta = latTo - latFrom;
        const lonDelta = lonTo - lonFrom;

        const angle =
            2 *
            Math.asin(
                Math.sqrt(
                    Math.pow(Math.sin(latDelta / 2), 2) +
                        Math.cos(latFrom) * Math.cos(latTo) * Math.pow(Math.sin(lonDelta / 2), 2)
                )
            );

        // Distance in m
        return angle * earthRadius;
    }
}
