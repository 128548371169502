export function getPositiveNegativeMacros(
    inputValue?: string,
    positiveMacros: string[] = [],
    negativeMacros: string[] = [],
    neutralMacros: string[] = []
): string[] {
    switch (inputValue) {
        case 'Veel beter':
        case 'Beter':
            return positiveMacros;
        case 'Vergelijkbaar':
            return neutralMacros;
        case 'Minder':
        case 'Veel minder':
            return negativeMacros;
        default:
            return [];
    }
}
