import {SidebarItem, SidebarTreeBuilder} from './sidebar_tree_builder';
import {map, shareReplay, switchMap} from 'rxjs/operators';

import {ChildQuestionsProvider as ConstructionChildQuestionsProvider} from '../appraise/ui/global/construction_cost_counter_presenter';
import {NormalQuestionType} from '../enum/question_type';
import {Observable} from 'rxjs';
import {Question} from '../models/question';
import {QuestionSet} from '../models/question_set';
import {ChildQuestionsProvider as SurfaceChildQuestionsProvider} from '../appraise/ui/global/surface_area_counter_presenter';
import {findAllChildrenForQuestion} from '../appraise/ui/support/question_filtering';

export interface PathNameProvider {
    stream: Observable<string>;
}

export class DefaultChildQuestionsProvider
    implements SurfaceChildQuestionsProvider, ConstructionChildQuestionsProvider
{
    constructor(
        private questionSet: QuestionSet,
        private sidebarTreeBuilder: SidebarTreeBuilder,
        private pathNameProvider: PathNameProvider
    ) {}

    private _stream: Observable<Question[]> = this.sidebarTreeBuilder.build.pipe(
        //Flatten list of sidebaritems for easy searching
        map((sidebarItems) =>
            sidebarItems.reduce(
                (p: SidebarItem[], sidebarItem: SidebarItem) => [...p, sidebarItem, ...sidebarItem.children],
                []
            )
        ),
        //React to changes is current page
        switchMap((sidebarItems) =>
            this.pathNameProvider.stream.pipe(
                map((currentPathname) => sidebarItems.find((sidebarItem) => sidebarItem.link === currentPathname))
            )
        ),
        //Map to all children of current sidebar item
        map((sidebarItem) => {
            return sidebarItem === undefined ||
                sidebarItem.question === undefined ||
                sidebarItem.question.type !== NormalQuestionType.GROUP
                ? []
                : findAllChildrenForQuestion(sidebarItem.question, this.questionSet);
        }),
        shareReplay(1)
    );

    public get stream() {
        return this._stream;
    }
}
