import {ReferenceObjectSortingStrategy} from './reference_object_sorting_strategy';
import {SortingDirection} from '../../../../../../../../../../enum/reference_objects_sorting';

export interface SaleDateSortableObject {
    normalizedSaleDate: {
        year: number;
        month: number;
    } | null;
}

interface ReferenceObjectWithNormalizedSaleDate extends SaleDateSortableObject {
    normalizedSaleDate: {
        year: number;
        month: number;
    };
}

interface Pair<TUnkown, TSortableObject extends SaleDateSortableObject> {
    referenceObject: TUnkown;
    sortableObject: TSortableObject;
}

export class ReferenceObjectSorterBySalesDate implements ReferenceObjectSortingStrategy {
    public sortReferenceObjects<TUnkown, TSortableObject extends SaleDateSortableObject>(
        referenceObjects: TUnkown[],
        sortableObjectTransformer: (item: TUnkown) => TSortableObject,
        sortingDirection: SortingDirection
    ): TUnkown[] {
        const pairs: Array<Pair<TUnkown, TSortableObject>> = referenceObjects.map((referenceObject) => {
            return {
                referenceObject,
                sortableObject: sortableObjectTransformer(referenceObject),
            };
        });

        // make array of the reference sales with a valid normalized sale date
        const referenceObjectsWithNormalizedSaleDate = pairs.filter(
            (pair): pair is Pair<TUnkown, TSortableObject & ReferenceObjectWithNormalizedSaleDate> =>
                pair.sortableObject.normalizedSaleDate !== null
        );

        // make array of the reference sale without a normalized sales date
        const referenceObjectsWithoutNormalizedSaleDate = pairs.filter(
            (pair) => pair.sortableObject.normalizedSaleDate === null
        );

        // sort the reference objects with a valid normalized sale date
        const sortedReferenceObjects = referenceObjectsWithNormalizedSaleDate.sort((pair1, pair2) => {
            return this.getSaleDateForReferenceObjectWithNormalizedSaleDate(pair1.sortableObject) >
                this.getSaleDateForReferenceObjectWithNormalizedSaleDate(pair2.sortableObject)
                ? sortingDirection === SortingDirection.DESCENDING
                    ? -1
                    : 1
                : sortingDirection === SortingDirection.DESCENDING
                ? 1
                : -1;
        });

        // put the invalid reference sales at the end
        return [
            ...sortedReferenceObjects.map((pair) => pair.referenceObject),
            ...referenceObjectsWithoutNormalizedSaleDate.map((pair) => pair.referenceObject),
        ];
    }

    private getSaleDateForReferenceObjectWithNormalizedSaleDate = (
        referenceObject: ReferenceObjectWithNormalizedSaleDate
    ): Date => {
        return new Date(referenceObject.normalizedSaleDate.year, referenceObject.normalizedSaleDate.month, 1);
    };
}
