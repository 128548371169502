import * as React from 'react';

import {PriceRange} from '../price_range';
import {ReferenceObject} from '../../models/reference_object';
import {ReferenceObjectAnswer} from '../../models/reference_object_answer';
import {classNames} from '../../../../../../../../../../support/classnames';
import {referenceObjectImageUrlPairs} from '../../internal/reference_sale_image_urls';

interface OwnProps {
    lng: number;
    lat: number;
    className?: string;
    iconName?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onClick?: () => void;
    referenceObject?: ReferenceObject;
    referenceObjectAnswer?: ReferenceObjectAnswer | null;
    isHovering: boolean;
    isBaseObject: boolean;
}

export class Pointer extends React.Component<OwnProps> {
    public render() {
        const imageUrlPairs = this.props.referenceObject
            ? referenceObjectImageUrlPairs(this.props.referenceObject, this.props.referenceObjectAnswer || null)
            : [];

        return (
            <div
                className={classNames('marker-container', {
                    hovering: this.props.isHovering,
                    selected: !!this.props.referenceObjectAnswer,
                })}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
                onClick={this.props.onClick}
            >
                {this.props.referenceObject !== undefined && (
                    <div className="pointer-tooltip">
                        <div className="pointer-tooltip-img-container">
                            {this.props.referenceObjectAnswer && <div className="active-icon ion-md-checkmark" />}
                            {imageUrlPairs.length > 0 && <img src={imageUrlPairs[0].url} />}
                        </div>
                        <strong>
                            <PriceRange range={this.props.referenceObject.priceRange} break={true} />
                        </strong>
                    </div>
                )}
                <div
                    className={classNames(
                        this.props.className,
                        this.props.iconName,
                        {'pointer-object': this.props.isBaseObject},
                        'pointer'
                    )}
                />
            </div>
        );
    }
}
