import {ValidationMessage, ValidationMessageMap} from '../../../business/validation/validation_message';

import {Answer} from '../../../models/answer';
import {Question} from '../../../models/question';

export function filterValidationMessages(
    question: Question,
    answer: Answer | undefined | null,
    validationMessagesMap: ValidationMessageMap,
    ownMessages?: ValidationMessage[]
): ValidationMessage[] | null {
    if (question === null || question === undefined) {
        return null;
    }

    const questionMessages = validationMessagesMap[question.uuid] ?? [];
    const mapMessages = questionMessages.filter((vm) => {
        if (vm.answer === null) {
            return true;
        }
        return vm.answer.uuid === answer?.uuid;
    });

    const messages = mapMessages.concat(ownMessages ?? []);

    if (messages.length === 0) {
        return null;
    }

    return messages;
}
