import {ReferenceObject} from './reference_object';
import {Sale} from '../../../../../../../../models/sale';
import {ValidationInstituteReferentieObject} from '../../../../../../../../models/validation_institute_reference_object';

export interface ReferenceObjectAnswer {
    //The ID of the reference sale
    id: string;
    //The Set to which this reference sale belongs to
    valuationType: string | null;
    //Path to a manually added photo
    photoPath: string | null;
    //When response is received from the server, it might have this one filled in
    photoUrl: string | null;
    //If the user manually uploaded his own photo
    isOwnUpload?: boolean;

    referenceObject: ValidationInstituteReferentieObject;
}

export function referenceObjectAnswerFromSale(sale: Sale): ReferenceObjectAnswer {
    return {
        id: sale.id,
        //Will be filled when added by the above laying presenter
        valuationType: null,
        photoPath: sale.images !== undefined ? sale.images[0]?.key ?? null : null,
        photoUrl: sale.images !== undefined ? sale.images[0]?.url ?? null : null,
        referenceObject: {
            adres: {
                straat: sale.street,
                huisnummer: sale.houseNumber,
                huisnummerToevoeging: sale.letter,
                postcode: sale.postalCode,
                plaats: sale.city,
                land: 'Nederland',
                latitude: sale.latitude,
                longitude: sale.longitude,
            },
            verkoopprijs: sale.purchasePrice,
            verkoopdatum: sale.saleDate,
            transportdatum: sale.transportDate,
            aanvangsHuurprijsPerMaand: null,
            gecorrigeerdeHuurprijsPerMaand: null,
            ingangsdatumHuur: null,
            gecorrigeerdeVerkoopprijs: sale.indexedPurchasePrice,
            verkochtBinnen: sale.daysOpenForSale !== null ? sale.daysOpenForSale : 'Onbekend',
            bronGegevens: sale.source,
            woningType: sale.objectType || '',
            woningTypeUitleg: '',
            bouwjaar: sale.buildYear,
            bouwjaarUitleg: '',
            gebruiksOppervlakte: sale.floorArea,
            gebruiksOppervlakteUitleg: '',
            inhoud: sale.volume,
            inhoudUitleg: '',
            perceelOppervlakte: sale.plotArea,
            perceelOppervlakteUitleg: '',
            energielabel: sale.energyLabel || 'Geen energielabel',
            energielabelVoorlopig: null,
            energielabelGeldigTot: sale.energyLabelValidDate || null,
            aanbouw: '',
            aanbouwUitleg: '',
            liggingUitleg: '',
            onderhoudsSituatieUitleg: '',
            luxeDoelmatigheidUitleg: '',
            kwaliteitConditieUitleg: '',
            mateVanDoelmatigheidUitleg: '',
            mateVanLuxeUitleg: '',
            toelichtingGebruikReferentieObject: '',
            vooraanzicht: sale.frontview ?? '',
            woningTypeStatus: 'Vergelijkbaar',
            onderhoudsSituatieStatus: 'Vergelijkbaar',
            luxeDoelmatigheidStatus: 'Vergelijkbaar',
            liggingStatus: 'Vergelijkbaar',
            kwaliteitConditieStatus: 'Vergelijkbaar',
            aanbouwStatus: 'Vergelijkbaar',
            mateVanDoelmatigheidStatus: 'Vergelijkbaar',
            mateVanLuxeStatus: 'Vergelijkbaar',
            onderhoudssituatie: 'Goed',
            streetviewUrl: null,
            eigendomssituatieStatus: 'Vergelijkbaar',
            eigendomssituatieUitleg: '',
            eigendomssituatieVolleEigendom: true,
            eigendomssituatieRechtErfpacht: false,
            eigendomssituatieRechtOndererfpacht: false,
            eigendomssituatieRechtOpstal: false,
            eigendomssituatieRechtGebruikBewoning: false,
            eigendomssituatieRechtVruchtgebruik: false,
            eigendomssituatieRechtAnders: false,
            eigendomssituatieToelichting: '',
            andereWezenlijkeVerschillen: false,
            andereWezenlijkeVerschillenUitleg: '',
            highlights: [],
            overview: null,
            installationDates: null,
        },
    };
}

export function referenceObjectAnswerFromReferenceSale(sale: ReferenceObject): ReferenceObjectAnswer {
    return {
        id: sale.id,
        //Will be filled when added by the above laying presenter
        valuationType: null,
        photoPath: sale.images !== undefined ? sale.images[0]?.key ?? null : null,
        photoUrl: sale.images !== undefined ? sale.images[0]?.url ?? null : null,
        referenceObject: {
            adres: {
                straat: sale.street,
                huisnummer: sale.houseNumber,
                huisnummerToevoeging: sale.letter,
                postcode: sale.postalCode,
                plaats: sale.city,
                land: 'Nederland',
                latitude: sale.latitude,
                longitude: sale.longitude,
            },
            verkoopprijs: null,
            verkoopdatum: null,
            gecorrigeerdeVerkoopprijs: null,
            verkochtBinnen: 'Onbekend',
            bronGegevens: null,
            woningType: '',
            woningTypeUitleg: '',
            bouwjaar: sale.buildYear,
            bouwjaarUitleg: '',
            gebruiksOppervlakte: sale.floorArea,
            gebruiksOppervlakteUitleg: '',
            inhoud: null,
            inhoudUitleg: '',
            perceelOppervlakte: sale.plotArea,
            perceelOppervlakteUitleg: '',
            energielabel: '',
            energielabelVoorlopig: null,
            aanbouw: '',
            aanbouwUitleg: '',
            liggingUitleg: '',
            onderhoudsSituatieUitleg: '',
            luxeDoelmatigheidUitleg: '',
            kwaliteitConditieUitleg: '',
            mateVanDoelmatigheidUitleg: '',
            mateVanLuxeUitleg: '',
            toelichtingGebruikReferentieObject: '',
            vooraanzicht: '',
            woningTypeStatus: 'Vergelijkbaar',
            onderhoudsSituatieStatus: 'Vergelijkbaar',
            luxeDoelmatigheidStatus: 'Vergelijkbaar',
            liggingStatus: 'Vergelijkbaar',
            kwaliteitConditieStatus: 'Vergelijkbaar',
            aanbouwStatus: 'Vergelijkbaar',
            mateVanDoelmatigheidStatus: 'Vergelijkbaar',
            mateVanLuxeStatus: 'Vergelijkbaar',
            onderhoudssituatie: 'Goed',
            streetviewUrl: null,
            eigendomssituatieVolleEigendom: false,
            eigendomssituatieRechtErfpacht: false,
            eigendomssituatieRechtOndererfpacht: false,
            eigendomssituatieRechtOpstal: false,
            eigendomssituatieRechtGebruikBewoning: false,
            eigendomssituatieRechtVruchtgebruik: false,
            eigendomssituatieRechtAnders: false,
            eigendomssituatieToelichting: '',
            andereWezenlijkeVerschillen: false,
            andereWezenlijkeVerschillenUitleg: '',
            highlights: sale.highlights,
            overview: sale.overview,
            installationDates: sale.installationDates,
        },
    };
}
