import {
    ConstructionalDefectsGroup,
    ConstructionalDefectsGroupsProvider,
} from '../../../../../business/constructional_defects/constructional_defects_groups_provider';
import {observable, toJS, makeObservable} from 'mobx';

import {PredictionResult} from '../../../../../business/ml/vision/photo_content_predicter';
import {Presenter} from '../../../../../../support/presenter/presenter';

export class LabelSelectModalPresenter implements Presenter {
    @observable public isDisabled = false;
    @observable public isLoading = false;
    @observable public activeGroup: string | null = null;
    @observable.ref public activeSubGroup: ConstructionalDefectsGroup | null = null;
    @observable public activeFloor: string | null = null;
    @observable public showFloors = false;
    @observable.ref public constructionalDefectsGroups: Map<string, ConstructionalDefectsGroup[]> | null = null;
    @observable public didFindGroupMatch = false;

    constructor(
        private predictionResult: PredictionResult | null,
        private onSucceedCallback: (value: {
            answerUuid: string | null;
            questionUuid: string | null;
            buildingCostsLabel: string | null;
        }) => void,
        private constructionalDefectsGroupsProvider: ConstructionalDefectsGroupsProvider
    ) {
        makeObservable(this);
    }

    public onSelectGroup(title: string) {
        this.activeGroup = title;
        this.showFloors = false;
    }

    public onSelectSubGroup(group: ConstructionalDefectsGroup) {
        this.activeSubGroup = group;
        this.activeFloor = null;
        if (this.activeSubGroup.floorAnswers.length !== 0) {
            this.showFloors = true;
        }
    }

    public onSelectFloor(uuid: string) {
        this.activeFloor = uuid;
    }

    public mount(): void {
        this.constructionalDefectsGroups = this.constructionalDefectsGroupsProvider.build();

        if (this.predictionResult !== null) {
            const matchedGroup = Array.from(this.constructionalDefectsGroups ?? [])
                .flatMap(([, groups]) => toJS(groups))
                .find((group) => {
                    const buildingCostsLabels = this.predictionResult?.className?.split(',') ?? [];

                    if (buildingCostsLabels.length !== 0) {
                        for (const label of buildingCostsLabels) {
                            const labelFormatted = label.toLocaleLowerCase().trim();
                            if (group.question.labels?.toLocaleLowerCase().trim().includes(labelFormatted)) {
                                return true;
                            }
                        }
                    }
                });

            if (matchedGroup !== undefined) {
                this.activeGroup = matchedGroup.group;
                this.activeSubGroup = matchedGroup;
                this.didFindGroupMatch = true;
            }
        }
    }

    public unmount(): void {
        //Noop
    }

    public async onSucceed() {
        this.isLoading = true;
        this.onSucceedCallback({
            answerUuid: this.activeFloor ?? null,
            questionUuid: this.activeSubGroup?.question.uuid ?? null,
            buildingCostsLabel: this.predictionResult?.className ?? null,
        });
        this.isLoading = false;
    }
}
