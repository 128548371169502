import {action, makeObservable, observable, runInAction} from 'mobx';
import {CompositeSubscription} from '../../../../../../../../../support/composite_subscription';
import {Presenter} from '../../../../../../../../../support/presenter/presenter';
import {AppraisalProvider} from '../../../../../../../../business/appraisal_provider';
import {BuildYearProvider} from '../../../../../../../../business/build_year_provider';
import {SurfaceAreaProvider} from '../../../../../../../../business/support/surface_area_provider';
import {ReferenceSubscriptionType} from '../../../../../../../../models/reference_subscriptions';
import {AltumSettings} from '../../../../../../../../network/preselected_reference_objects_api';
import {ReferenceObjectApi} from '../../../../../../../../network/reference_objects_api';
import {ObjectType} from '../../../../../../../../enum/object_type';

export class AltumPreferencesPresenter implements Presenter {
    @observable public preferences: AltumSettings | null = null;
    @observable public buildYear: number | null = null;
    @observable public surfaceArea: number | null = null;
    @observable public saving = false;
    @observable public objectType: ObjectType | null = null;

    private readonly subscriptions = new CompositeSubscription();

    constructor(
        private readonly appraisalProvider: AppraisalProvider,
        private readonly referenceObjectApi: ReferenceObjectApi,
        private readonly buildYearProvider?: BuildYearProvider,
        private readonly surfaceAreaProvider?: SurfaceAreaProvider
    ) {
        makeObservable(this);
    }

    public mount(): void {
        if (this.appraisalProvider.hasAppraisal) {
            this.referenceObjectApi
                .getSubscriptionPreferences<AltumSettings>(
                    this.appraisalProvider.appraisal.id,
                    ReferenceSubscriptionType.ALTUM
                )
                .then((preferences) => {
                    this.updatePreferences(preferences);
                });
        } else {
            this.referenceObjectApi
                .getUserSubscriptionPreferences<AltumSettings>(ReferenceSubscriptionType.ALTUM)
                .then((preferences) => {
                    this.updatePreferences(preferences);
                });
        }

        if (this.buildYearProvider) {
            this.subscriptions.add(
                this.buildYearProvider.stream().subscribe((buildYear) => {
                    runInAction(() => {
                        this.buildYear = buildYear;
                    });
                })
            );
        }

        if (this.surfaceAreaProvider) {
            this.subscriptions.add(
                this.surfaceAreaProvider.surfaceArea().subscribe((surfaceArea) => {
                    runInAction(() => {
                        this.surfaceArea = surfaceArea;
                    });
                })
            );
        }
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    @action
    public updatePreferences = (preferences: AltumSettings): void => {
        this.preferences = preferences;
    };

    public savePreferences = (): Promise<void> => {
        if (!this.preferences || !this.appraisalProvider.hasAppraisal) {
            return Promise.resolve();
        }

        runInAction(() => {
            this.saving = true;
        });

        return this.referenceObjectApi
            .updateSubscriptionPreferences(
                this.appraisalProvider.appraisal.id,
                ReferenceSubscriptionType.ALTUM,
                this.preferences
            )
            .then(() => {
                runInAction(() => {
                    this.saving = false;
                });
            });
    };
}
