import * as React from 'react';

import {simpleQuestionPresenterFactory, SimpleQuestionPresenterProps} from './simple_question_presenter';

import {Answer} from '../../../../../models/answer';
import {AnswerOption} from '../../../../../models/answer_option';
import {Label} from '../components/label';
import {ValidationMessages} from '../components/validation_messages';
import {classNames} from '../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const MultipleChoiceQuestion: React.FC<OwnProps> = observer(function MultipleChoiceQuestion(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));
    if (presenter.isHidden) {
        return null;
    }

    function fitsOnSingleLine() {
        return (
            props.question.answerOptions.length <= 3 &&
            props.question.answerOptions.reduce((p: string, c: AnswerOption) => p + c.contents, '').length < 35
        );
    }

    function renderAnswerOption(answer: Answer | undefined, answerOption: AnswerOption) {
        const checked = answer !== undefined && answer.answerOptionId === answerOption.id;
        const name = presenter.name + '-' + answerOption.id;
        return (
            <div className="answer-option" key={answerOption.id}>
                <input
                    type="radio"
                    id={name}
                    name={name}
                    value={answerOption.id}
                    checked={checked}
                    disabled={presenter.isDisabled || props.disabled === true}
                    onChange={() => presenter.onAnswerOptionChange(answerOption.id)}
                />
                <label htmlFor={name}>{answerOption.contents}</label>
            </div>
        );
    }

    function renderAnswerOptionsOnSingleLine() {
        const answer = presenter.answer;

        if (props.renderingContext === RenderingContextType.PLAUSIBILITY_CHECK) {
            return (
                <div className="input-group-pill">
                    {props.question.answerOptions.map((answerOption) => {
                        return (
                            <div key={answerOption.id} className="input-group-pill-item">
                                {renderAnswerOption(answer, answerOption)}
                            </div>
                        );
                    })}
                </div>
            );
        }

        const columns = Math.floor(12 / props.question.answerOptions.length);
        return (
            <div className="row">
                {props.question.answerOptions.map((answerOption) => {
                    return (
                        <div key={answerOption.id} className={'col-md-' + columns}>
                            {renderAnswerOption(answer, answerOption)}
                        </div>
                    );
                })}
            </div>
        );
    }

    function renderAnswerOptions() {
        const answer = presenter.answer;
        return props.question.answerOptions.map((answerOption) => {
            return renderAnswerOption(answer, answerOption);
        });
    }

    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'automation-filled': presenter.filledByAutomator !== null,
            })}
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
        >
            <Label
                for={presenter.name}
                question={props.question}
                parentAnswerUuid={props.parentAnswerUuid}
                iteration={props.iteration}
                appraisal={props.appraisal}
                questionSet={props.questionSet}
                disabled={props.disabled || presenter.isDisabled}
                hideLabel={props.hideLabel}
                showLabel={props.question.showLabel}
                renderingContext={props.renderingContext}
            />
            {fitsOnSingleLine() ? renderAnswerOptionsOnSingleLine() : renderAnswerOptions()}
            <ValidationMessages
                alignLeft
                disabled={props.disabled || presenter.isDisabled}
                forceShowValidationMessages={props.forceShowValidationMessages}
                answer={presenter.answer}
                validationMessages={presenter.validationMessages}
            />
        </div>
    );
});
