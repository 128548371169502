import {Answer} from '../../../../../../models/answer';

export interface HouseGroupConfiguration {
    numberOfAttics: number;
    numberOfFloors: number;
    numberOfGroundFloors: number;
    numberOfBasements: number;
}

export function houseGroupConfigurationFromAnswer(answer: Answer) {
    if (answer.contents === null) {
        return getDefaultHouseGroupConfiguration();
    }

    const value = JSON.parse(answer.contents);
    return {
        numberOfAttics: 'numberOfAttics' in value ? value.numberOfAttics : 0,
        numberOfFloors: 'numberOfFloors' in value ? value.numberOfFloors : 0,
        numberOfGroundFloors: 'numberOfGroundFloors' in value ? value.numberOfGroundFloors : 0,
        numberOfBasements: 'numberOfBasements' in value ? value.numberOfBasements : 0,
    };
}

export function getTotalFloorCountFromHouseGroupConfiguration(answer: Answer): number {
    const data = houseGroupConfigurationFromAnswer(answer);
    return data.numberOfFloors + data.numberOfGroundFloors + data.numberOfAttics + data.numberOfBasements;
}

export function getDefaultHouseGroupConfiguration(): HouseGroupConfiguration {
    return {
        numberOfAttics: 0,
        numberOfFloors: 0,
        numberOfGroundFloors: 0,
        numberOfBasements: 0,
    };
}
