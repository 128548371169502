import * as Localforage from 'localforage';
import * as Uuid from 'uuid';

export interface BlobCacheInteractor {
    canStore(file: File): Promise<boolean>;

    find(uuid: string): Promise<File | null>;

    move(oldUuid: string, newUuid: string): Promise<boolean>;

    put(uuid: string, file: File): Promise<void>;

    remove(uuid: string): Promise<void>;
}

export class DefaultBlobCacheInteractor implements BlobCacheInteractor {
    private storage: LocalForage = Localforage.createInstance({
        name: 'taxapi-unsynced-blobs',
    });

    public async find(uuid: string): Promise<File | null> {
        const reference = await this.storage.getItem<string>('reference-' + uuid);
        const item = await this.storage.getItem<File>(reference !== null ? reference : uuid);
        return item || null;
    }

    public async move(oldUuid: string, newUuid: string): Promise<boolean> {
        await this.storage.setItem('reference-' + newUuid, oldUuid);

        return true;
    }

    public async put(uuid: string, file: File): Promise<void> {
        try {
            await this.storage.removeItem('reference-' + uuid);
            await this.storage.setItem(uuid, file);
        } catch (e) {
            alert(
                "Offline opslag niet beschikbaar op dit apparaat, gebruik de systeem camera app om foto's te maken en voeg deze op een later moment toe."
            );
            throw e;
        }
    }

    public async remove(uuid: string): Promise<void> {
        try {
            const reference = await this.storage.getItem<string>('reference-' + uuid);
            if (reference) {
                await this.storage.removeItem(reference);
            }

            await this.storage.removeItem('reference-' + uuid);
            await this.storage.removeItem(uuid);
        } catch (e) {
            /* Noop */
        }
    }

    public async canStore(file: File): Promise<boolean> {
        try {
            const uuid = Uuid.v4();
            await this.storage.setItem(uuid, file);
            await this.storage.removeItem(uuid);

            return true;
        } catch (e) {
            return false;
        }
    }
}
