import * as React from 'react';

import {SimpleQuestionPresenterProps, simpleQuestionPresenterFactory} from './simple_question_presenter';
import {isEmpty, isNumeric} from '../../../../../../support/util';

import {AlternativeAdaptedDefaultAnswer} from '../components/alternative_adapted_default_answer';
import {Label} from '../components/label';
import NumberFormat from 'react-number-format';
import {classNames} from '../../../../../../support/classnames';
import {isMobile} from '../../../../../../support/check_mobile';
import {observer} from 'mobx-react';
import {CompactEditable} from '../../../components/compact_editable';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {}

export const IntNumberQuestion: React.FC<OwnProps> = observer(function IntNumberQuestion(props) {
    const presenter = usePresenter((container) => simpleQuestionPresenterFactory(props, container));
    if (presenter.isHidden) {
        return null;
    }

    function renderCompact() {
        return (
            <>
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    disableHistory
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <div>{presenter.answer?.contents || <i>Niet ingevuld</i>}</div>
            </>
        );
    }
    return (
        <div
            className={classNames('form-group', presenter.filledByAutomator, {
                'automation-filled': presenter.filledByAutomator !== null,
            })}
            data-test-box={props.question.contents.toLowerCase().replace(' ', '_')}
        >
            <CompactEditable
                renderingContext={props.renderingContext}
                compact={() => renderCompact()}
                question={props.question}
                answer={presenter.answer}
            >
                <Label
                    for={presenter.name}
                    question={props.question}
                    parentAnswerUuid={props.parentAnswerUuid}
                    iteration={props.iteration}
                    appraisal={props.appraisal}
                    questionSet={props.questionSet}
                    disabled={props.disabled || presenter.isDisabled}
                    hideLabel={props.hideLabel}
                    showLabel={props.question.showLabel}
                    renderingContext={props.renderingContext}
                />
                <AlternativeAdaptedDefaultAnswer
                    adaptedDefaultValue={props.adaptedDefaultValue}
                    currentAnswer={presenter.answer}
                    disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                    onChange={(value) => presenter.onChange(value)}
                />
                {isMobile() ? (
                    <input
                        id={presenter.name}
                        name={presenter.name}
                        className="form-control"
                        type="number"
                        step="1"
                        pattern="\d*"
                        inputMode="numeric"
                        disabled={props.disabled || presenter.isDisabled}
                        value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value = event.target.value;
                            if (isEmpty(value)) {
                                presenter.onChange(value, false);
                            } else if (isNumeric(value)) {
                                presenter.onChange(value, false);
                            }
                        }}
                        onBlur={() => presenter.onTouched()}
                    />
                ) : (
                    <NumberFormat
                        value={presenter.answer && presenter.answer.contents !== null ? presenter.answer.contents : ''}
                        displayType="input"
                        type="text"
                        className="form-control"
                        decimalScale={0}
                        allowNegative={false}
                        disabled={props.disabled || presenter.isDisabled}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if (isEmpty(event.target.value)) {
                                presenter.onChange(event.target.value, false);
                            } else if (isNumeric(event.target.value)) {
                                presenter.onChange(event.target.value, false);
                            }
                        }}
                        onBlur={() => presenter.onTouched()}
                    />
                )}
            </CompactEditable>
        </div>
    );
});
