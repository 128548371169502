import {ApiAnswer, apiAnswerToAnswer} from '../network/models/api_answer';
import {computed, makeObservable, observable, runInAction} from 'mobx';

import {Answer} from '../models/answer';
import {Appraisal} from '../models/appraisal';
import {AppraisalApi} from '../network/appraisal_api';
import {ModelValuesAnswerDetails} from './models/model_values_details_answer';
import {ModelValuesDetails} from './models/model_values_details';
import {Presenter} from '../../support/presenter/presenter';
import {QuestionSetApi} from '../network/question_set_api';
import {ServerTimeProvider} from '../server_time/server_time_provider';
import {first} from 'rxjs/operators';
import {QuestionSet} from '../models/question_set';
import {AppraisalProvider} from '../business/appraisal_provider';
import {AnswerLoader} from '../business/answering/support/answer_loader';

//This GLOBAL is different then the global used anywhere else in the app
declare let GLOBAL: {
    answer: ApiAnswer;
    model_values_details: ModelValuesDetails;
    model_values_answer_details: ModelValuesAnswerDetails[];
};

export class RootPresenter implements Presenter {
    @observable.ref public appraisal?: Appraisal;
    @observable.ref public questionSet?: QuestionSet;
    @observable.ref public answer?: Answer;
    public modelValuesAnswerDetails: ModelValuesAnswerDetails[] = GLOBAL.model_values_answer_details;
    public modelValuesDetails: ModelValuesDetails = GLOBAL.model_values_details;
    @observable public error = false;
    @observable private serverTimeSynchronized = false;

    @computed
    public get loading(): boolean {
        return (
            this.serverTimeSynchronized &&
            (this.appraisal === undefined || this.questionSet === undefined) &&
            !this.error
        );
    }

    constructor(
        private appraisalId: number | null,
        private questionSetId: number | null,
        private appraisalApi: AppraisalApi,
        private questionSetApi: QuestionSetApi,
        private serverTimeProvider: ServerTimeProvider,
        private appraisalProvider: AppraisalProvider,
        private answerLoader: AnswerLoader
    ) {
        makeObservable(this);
    }

    public async mount(): Promise<void> {
        try {
            if (this.appraisalId === null || this.questionSetId === null) {
                this.error = true;
                return;
            }
            if (GLOBAL === undefined || GLOBAL.answer === undefined) {
                this.error = true;
                return;
            }
            await this.serverTimeProvider.dates().pipe(first()).toPromise();
            runInAction(() => {
                this.serverTimeSynchronized = true;
            });

            this.answer = apiAnswerToAnswer(GLOBAL.answer);

            const [appraisal, questionSet] = await Promise.all([
                this.appraisalApi.get(this.appraisalId),
                this.questionSetApi.get(this.questionSetId),
                this.answerLoader.fetchForAppraisal(this.appraisalId),
            ]);

            this.appraisalProvider.onChange(appraisal);

            runInAction(() => {
                this.appraisal = appraisal;
                this.questionSet = questionSet;
            });
        } catch (e) {
            this.error = true;
        }
    }

    public unmount(): void {
        /* Noop */
    }
}
