import {action, makeObservable, observable} from 'mobx';

import {ActiveSetDefinition} from '../reference_objects_question_presenter';
import {AdaptedDefaultValuesMap} from '../../../../../../../../models/adapted_default_values_map';
import {AdaptedValueProvider} from '../../../../../../../../business/adapted_values/adapted_value_provider';
import {NormalQuestionType} from '../../../../../../../../enum/question_type';
import {Presenter} from '../../../../../../../../../support/presenter/presenter';
import {Question} from '../../../../../../../../models/question';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {SetType} from '../../../../../../../../models/reference_set/set_type';
import {SuggestedReferenceObjectAnswer} from '../calculators/suggested_reference_object_answers_calculator';
import {TechnicalReference} from '../../../../../../../../enum/technical_reference';
import {findChildRecursiveByPredicate} from '../../../../../../../../../support/generic_tree';
import {findChildrenForQuestion} from '../../../../../../support/question_filtering';

export interface QuestionWithValue {
    question: Question;
    value: number | null;
}

export class ValuationAnswersContainerPresenter implements Presenter {
    @observable public selectedCopyValuationType: string | null = null;

    constructor(
        private questionSet: QuestionSet,
        private adaptedValueProvider: AdaptedValueProvider,
        private propsOnAddMany: (value: SuggestedReferenceObjectAnswer) => void
    ) {
        makeObservable(this);
    }

    public mount(): void {
        //Noop
    }

    public unmount(): void {
        //Noop
    }

    @action
    public setSelectedCopyValuationType(valuationType: string | null) {
        this.selectedCopyValuationType = valuationType;
    }

    public onAddMany(suggestedReferenceObjectAnswers: SuggestedReferenceObjectAnswer[]) {
        const selectedSuggested = suggestedReferenceObjectAnswers.find(
            (sroa) => sroa.setDefinition.valuationType === this.selectedCopyValuationType
        );
        if (selectedSuggested) {
            this.propsOnAddMany(selectedSuggested);
        }
    }

    public specialValueData(activeSetDefinition: ActiveSetDefinition) {
        const specialValueArgumentGroup = findChildRecursiveByPredicate(
            activeSetDefinition.setDefinition.groupTree,
            (item) =>
                item.question.technicalReference ===
                (activeSetDefinition.setDefinition.type === SetType.SOLD
                    ? TechnicalReference.SPECIAL_VALUE_ARGUMENT_GROUP
                    : TechnicalReference.SPECIAL_VALUE_ARGUMENT_GROUP_RENT)
        );

        if (!specialValueArgumentGroup || !specialValueArgumentGroup.item.answer) {
            return null;
        }

        const childQuestions = findChildrenForQuestion(specialValueArgumentGroup.item.question, this.questionSet);
        const iteration = specialValueArgumentGroup.item.answer.iteration;
        const parentAnswerUuid = specialValueArgumentGroup.item.answer.uuid;

        return {
            questions: childQuestions,
            iteration,
            parentAnswerUuid,
        };
    }

    public defaultAdaptedAnswers(activeSetDefinition: ActiveSetDefinition | null): AdaptedDefaultValuesMap {
        const adaptedDefaultValuesMap: AdaptedDefaultValuesMap = this.buildDefaultAdaptedAnswers();

        if (activeSetDefinition === null) {
            return adaptedDefaultValuesMap;
        }

        const specialValueArgumentGroup = findChildRecursiveByPredicate(
            activeSetDefinition.setDefinition.groupTree,
            (node) =>
                node.question.technicalReference ===
                (activeSetDefinition.setDefinition.type === SetType.SOLD
                    ? TechnicalReference.SPECIAL_VALUE_ARGUMENT_GROUP
                    : TechnicalReference.SPECIAL_VALUE_ARGUMENT_GROUP_RENT)
        )?.item.question;

        if (!specialValueArgumentGroup) {
            return adaptedDefaultValuesMap;
        }

        this.questionSet
            .findChildQuestionsByParentUuid(specialValueArgumentGroup.uuid)
            .map((c) => this.questionSet.findChildQuestionsByParentUuid(c.uuid))
            .map((qq) =>
                qq
                    .filter((q) => q.technicalReference)
                    .forEach((q) => {
                        const value = this.valueByTechnicalReference(q.technicalReference, activeSetDefinition);
                        adaptedDefaultValuesMap[q.uuid] = value.toString(10);
                    })
            );

        return adaptedDefaultValuesMap;
    }

    public marketValuationQuestions(activeSetDefinition: ActiveSetDefinition | null): QuestionWithValue[] {
        if (activeSetDefinition === null) {
            return [];
        }

        // A random group is used to see which questions should rendered for market valuation.
        // Todo: Add separate questions for market value
        const specialValueArgumentGroupBlueprint = this.questionSet.findQuestionByTechnicalReference(
            activeSetDefinition.setDefinition.type === SetType.SOLD
                ? TechnicalReference.SPECIAL_VALUE_ARGUMENT_GROUP
                : TechnicalReference.SPECIAL_VALUE_ARGUMENT_GROUP_RENT
        );

        if (!specialValueArgumentGroupBlueprint) {
            return [];
        }

        return this.questionSet
            .findChildQuestionsByParentUuid(specialValueArgumentGroupBlueprint.uuid)
            .map((c) => this.questionSet.findChildQuestionsByParentUuid(c.uuid))
            .map((qq) => qq.filter((q) => q.type === NormalQuestionType.NUMBER))
            .flatMap((qq) => {
                return qq.map((q) => {
                    return {
                        question: q,
                        value: this.valueByTechnicalReference(q.technicalReference, activeSetDefinition),
                    };
                });
            });
    }

    private valueByTechnicalReference(
        technicalReference: TechnicalReference | null,
        activeSetDefinition: ActiveSetDefinition
    ): number {
        switch (technicalReference) {
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_GEBRUIKSOPPERVLAKTE_WONEN:
            case TechnicalReference.MARKET_VALUE_ARGUMENT_GEBRUIKSOPPERVLAKTE_WONEN:
                return activeSetDefinition.setDefinition.surfaceArea ?? 0;
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_GEBOUWGEBONDEN_BUITENRUIMTE:
            case TechnicalReference.MARKET_VALUE_ARGUMENT_GEBOUWGEBONDEN_BUITENRUIMTE:
                return activeSetDefinition.setDefinition.gebruiksOppervlakteBuitenruimte ?? 0;
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_OVERIGE_INPANDIGE_RUIMTE:
            case TechnicalReference.MARKET_VALUE_ARGUMENT_OVERIGE_INPANDIGE_RUIMTE:
                return activeSetDefinition.setDefinition.overigeInpandigeRuimte ?? 0;
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_EXTERNE_BERGRUIMTE:
            case TechnicalReference.MARKET_VALUE_ARGUMENT_EXTERNE_BERGRUIMTE:
                return activeSetDefinition.setDefinition.gebruiksOppervlakteExterneBergruimte ?? 0;
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_PERCEEL_OPPERVLAKTE:
            case TechnicalReference.MARKET_VALUE_ARGUMENT_PERCEEL_OPPERVLAKTE:
                return activeSetDefinition.setDefinition.plotArea ?? 0;
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_BRUTO_INHOUD:
            case TechnicalReference.MARKET_VALUE_ARGUMENT_BRUTO_INHOUD:
                return activeSetDefinition.setDefinition.volume ?? 0;
            case TechnicalReference.SPECIAL_VALUE_ARGUMENT_BUILD_YEAR:
            case TechnicalReference.MARKET_VALUE_ARGUMENT_BUILD_YEAR:
                return activeSetDefinition.setDefinition.buildYear ?? 0;
            default:
                return 0;
        }
    }

    private buildDefaultAdaptedAnswers(): AdaptedDefaultValuesMap {
        const technicalReferences = [TechnicalReference.ADAPTED_VALUE_BIJGEBOUWEN];

        return this.adaptedValueProvider.get(technicalReferences, null, null);
    }
}
