import React, {useEffect, useMemo, useRef} from 'react';
import {fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

interface OwnProps {
    words: string[];
}

const lineHeight = 0.8;
const spiralLimit = 360 * 5;
const spiralResolution = 1;
const xWordPadding = 0;
const yWordPadding = 3;

const spiral = (step: number) => {
    const angle = spiralResolution * step;

    return {
        x: (1 + angle) * Math.cos(angle) * 2,
        y: (1 + angle) * Math.sin(angle) * 0.5,
    };
};

const intersects = (
    cloudContainer: HTMLDivElement,
    wordContainer: HTMLDivElement,
    x: number,
    y: number,
    placedWords: DOMRect[]
) => {
    cloudContainer.appendChild(wordContainer);

    wordContainer.style.left = x - wordContainer.offsetWidth / 2 + 'px';
    wordContainer.style.top = y - wordContainer.offsetHeight / 2 + 'px';

    const currentWord = wordContainer.getBoundingClientRect();

    cloudContainer.removeChild(wordContainer);

    for (const comparisonWord of placedWords) {
        if (
            !(
                currentWord.right + xWordPadding < comparisonWord.left - xWordPadding ||
                currentWord.left - xWordPadding > comparisonWord.right + xWordPadding ||
                currentWord.bottom + yWordPadding < comparisonWord.top - yWordPadding ||
                currentWord.top - yWordPadding > comparisonWord.bottom + yWordPadding
            )
        ) {
            return true;
        }
    }

    return false;
};

const placeWord = (cloudContainer: HTMLDivElement, wordContainer: HTMLDivElement, x: number, y: number) => {
    cloudContainer.appendChild(wordContainer);

    wordContainer.style.left = x - wordContainer.offsetWidth / 2 + 'px';
    wordContainer.style.top = y - wordContainer.offsetHeight / 2 + 'px';

    return wordContainer.getBoundingClientRect();
};

export const WordCloud: React.FC<OwnProps> = ({words}: OwnProps) => {
    const data = useMemo(() => {
        return words
            .map((word) => {
                return {
                    word: word,
                    freq: Math.floor(Math.random() * 10) + 10,
                };
            })
            .sort(function (a, b) {
                return -1 * (a.freq - b.freq);
            });
    }, [words]);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let firstRender = true;

        const update = () => {
            if (!ref.current) {
                return;
            }

            ref.current.innerHTML = '';

            if (data.length === 0) {
                ref.current.style.height = '0px';
                return;
            }

            const placedWords: DOMRect[] = [];
            const points: {x: number; y: number}[] = [];

            ref.current.style.width = '100%';
            ref.current.style.height = '500px';
            ref.current.style.marginTop = '';

            const startPoint = {
                x: ref.current.offsetWidth / 2,
                y: ref.current.offsetHeight / 2,
            };

            let delaySeconds = 2;
            for (const word of data) {
                const wordContainer = document.createElement('div');
                wordContainer.style.position = 'absolute';
                wordContainer.style.fontSize = word.freq + 'px';
                wordContainer.style.lineHeight = lineHeight.toString();

                if (firstRender) {
                    wordContainer.style.animation = `precheck-hide ${delaySeconds}s, precheck-fade-in-move-down 0.8s ${delaySeconds}s`;
                }

                delaySeconds += 0.05;
                wordContainer.appendChild(document.createTextNode(word.word));

                for (let i = 0; i < spiralLimit; i++) {
                    let {x, y} = spiral(i);

                    x = startPoint.x + x;
                    y = startPoint.y + y;

                    if (!intersects(ref.current, wordContainer, x, y, placedWords)) {
                        placedWords.push(placeWord(ref.current, wordContainer, x, y));
                        points.push({x, y});
                        break;
                    }
                }
            }

            const ys = points.map((point) => point.y);
            const minY = Math.min(...ys);
            const maxY = Math.max(...ys);

            ref.current.style.marginTop = `${-(minY - 30)}px`;
            ref.current.style.height = `${maxY + 10}px`;

            firstRender = false;
        };

        update();

        const subscription = fromEvent(window, 'resize')
            .pipe(debounceTime(200))
            .subscribe(() => {
                update();
            });

        return () => {
            subscription.unsubscribe();
        };
    }, [words]);

    return <div ref={ref} className="word-cloud position-relative"></div>;
};
