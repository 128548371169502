import {ConditionsInteractor} from '../../conditions/conditions_interactor';
import {Question} from '../../../models/question';
import {Answer} from '../../../models/answer';

export interface IsRequiredCalculator {
    isRequired(question: Question, answer: Answer | null): boolean;
    isRequiredWithoutAnswer(question: Question): boolean;
}

export class DefaultIsRequiredCalculator implements IsRequiredCalculator {
    constructor(private conditionsInteractor: ConditionsInteractor) {}

    public isRequired(question: Question, answer: Answer | null): boolean {
        return question.isRequired || this.isRequiredThroughConditions(question, answer);
    }

    public isRequiredWithoutAnswer(question: Question): boolean {
        // Check only based on the question conditions without checking with the parent answer
        return question.isRequired || this.isRequiredThroughConditions(question, null, false);
    }

    private isRequiredThroughConditions(question: Question, answer: Answer | null, checkAnswer = true) {
        if (checkAnswer && answer === null) {
            return false;
        }
        const effectStates = this.conditionsInteractor.get(question.uuid, answer?.parentUuid ?? null);
        return effectStates.reduce((isRequired, effectState) => {
            if (effectState.effect.task === 'required' && effectState.enabled) {
                return true;
            }
            return isRequired;
        }, false);
    }
}
