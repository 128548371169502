import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {Presenter} from '../../../../../support/presenter/presenter';
import {MacroInteractor} from '../../../../business/macro_interactor';
import {DefaultMacro, SuperMacro} from '../../../../models/macro';
import {BuildPeriodType} from '../../../../enum/build_period_type';
import {ObjectType} from '../../../../enum/object_type';
import {AppraisalGoal} from '../../../../enum/appraisal_goal';
import {StubberValuesMap} from '../../../../business/answering/answer_path_stubber';

interface DraftMacroSettingsBase {
    type: MacroSettingsPayloadType;
    name: string;
    isUserDefined: boolean;
    macroId: number | null;
    districtCodes: string[] | null;
    buildPeriods: BuildPeriodType[] | null;
    objectTypes: ObjectType[] | null;
    appraisalGoals: AppraisalGoal[] | null;
    newConstruction: boolean | null;
}

export interface DefaultMacroDraftSettings extends DraftMacroSettingsBase {
    type: MacroSettingsPayloadType.DEFAULT;
    macroId: number;
}

export interface UnpersistedSuperMacroDraftSettings extends DraftMacroSettingsBase {
    type: MacroSettingsPayloadType.UNPERSISTED_SUPER;
    macroId: null;
    values: StubberValuesMap;
    questionUuid: string;
}

export interface PersistedSuperMacroDraftSettings extends DraftMacroSettingsBase {
    type: MacroSettingsPayloadType.PERSISTED_SUPER;
    macroId: number;
}

export type DraftMacroSettings =
    | DefaultMacroDraftSettings
    | UnpersistedSuperMacroDraftSettings
    | PersistedSuperMacroDraftSettings;

export enum MacroSettingsPayloadType {
    DEFAULT,
    UNPERSISTED_SUPER,
    PERSISTED_SUPER,
}

interface DefaultMacroPayload {
    type: MacroSettingsPayloadType.DEFAULT;
    macro: DefaultMacro;
}

export interface UnpersistedSuperMacroPayload {
    type: MacroSettingsPayloadType.UNPERSISTED_SUPER;
    values: StubberValuesMap;
    questionUuid: string;
}

export interface PersistedSuperMacroPayload {
    type: MacroSettingsPayloadType.PERSISTED_SUPER;
    macro: SuperMacro;
}

export type MacroSettingsPayload = DefaultMacroPayload | UnpersistedSuperMacroPayload | PersistedSuperMacroPayload;

export class MacroSettingsPresenter implements Presenter {
    @observable
    public macroSettings: DraftMacroSettings;

    @observable
    private isPersisting = false;

    @computed
    public get isLoading() {
        return this.isPersisting === true;
    }

    constructor(payload: MacroSettingsPayload, private onClose: () => void, private macroInteractor: MacroInteractor) {
        switch (payload.type) {
            case MacroSettingsPayloadType.DEFAULT: {
                this.macroSettings = {
                    type: MacroSettingsPayloadType.DEFAULT,
                    name: payload.macro.contents ?? '',
                    isUserDefined: payload.macro.isUserDefined,
                    macroId: payload.macro.id,
                    districtCodes: null,
                    buildPeriods: null,
                    objectTypes: null,
                    appraisalGoals: null,
                    newConstruction: null,
                };
                break;
            }
            case MacroSettingsPayloadType.UNPERSISTED_SUPER: {
                this.macroSettings = {
                    type: MacroSettingsPayloadType.UNPERSISTED_SUPER,
                    name: '',
                    values: payload.values,
                    questionUuid: payload.questionUuid,
                    isUserDefined: true,
                    macroId: null,
                    districtCodes: null,
                    buildPeriods: null,
                    objectTypes: null,
                    appraisalGoals: null,
                    newConstruction: null,
                };
                break;
            }

            case MacroSettingsPayloadType.PERSISTED_SUPER: {
                this.macroSettings = {
                    type: MacroSettingsPayloadType.PERSISTED_SUPER,
                    name: payload.macro.contents ?? '',
                    isUserDefined: payload.macro.isUserDefined,
                    macroId: payload.macro.id,
                    districtCodes: null,
                    buildPeriods: null,
                    objectTypes: null,
                    appraisalGoals: null,
                    newConstruction: null,
                };
                break;
            }
        }
        makeObservable(this);
    }

    public async mount() {
        if (this.macroSettings.type !== MacroSettingsPayloadType.UNPERSISTED_SUPER) {
            const settings = await this.macroInteractor.getSettings(this.macroSettings.macroId);
            if (settings !== null) {
                runInAction(() => {
                    this.macroSettings = {
                        ...this.macroSettings,
                        districtCodes: settings.districtCodes,
                        buildPeriods: settings.buildPeriods,
                        objectTypes: settings.objectTypes,
                        appraisalGoals: settings.appraisalGoals,
                        newConstruction: settings.newConstruction,
                    };
                });
            }
        }
    }

    public unmount() {
        //Noop
    }

    @action
    public onAddDistrict(code: string) {
        this.macroSettings = {
            ...this.macroSettings,
            districtCodes: [...(this.macroSettings.districtCodes ?? []), code],
        };
        // if (this.macroSettings.type !== MacroSettingsPayloadType.UNPERSISTED_SUPER) {
        //     this.onSaveWithType(MacroSettingsType.DISTRICTS);
        // }
    }

    @action
    public onRemoveDistrict(code: string) {
        this.macroSettings = {
            ...this.macroSettings,
            districtCodes: (this.macroSettings.districtCodes ?? []).filter((c) => c !== code),
        };
    }

    @action
    public onAddBuildPeriod(value: BuildPeriodType) {
        this.macroSettings = {
            ...this.macroSettings,
            buildPeriods: [...(this.macroSettings.buildPeriods ?? []), value],
        };
    }

    @action
    public onRemoveBuildPeriod(value: BuildPeriodType) {
        this.macroSettings = {
            ...this.macroSettings,
            buildPeriods: (this.macroSettings.buildPeriods ?? []).filter((b) => b !== value),
        };
    }

    @action
    public onAddObjectType(value: ObjectType) {
        this.macroSettings = {
            ...this.macroSettings,
            objectTypes: [...(this.macroSettings.objectTypes ?? []), value],
        };
    }

    @action
    public onRemoveObjectType(value: ObjectType) {
        this.macroSettings = {
            ...this.macroSettings,
            objectTypes: (this.macroSettings.objectTypes ?? []).filter((b) => b !== value),
        };
    }

    @action
    public onAddAppraisalGoal(value: AppraisalGoal) {
        this.macroSettings = {
            ...this.macroSettings,
            appraisalGoals: [...(this.macroSettings.appraisalGoals ?? []), value],
        };
    }

    @action
    public onRemoveAppraisalGoal(value: AppraisalGoal) {
        this.macroSettings = {
            ...this.macroSettings,
            appraisalGoals: (this.macroSettings.appraisalGoals ?? []).filter((b) => b !== value),
        };
    }

    @action
    public onChangeNewConstruction(value: boolean | null) {
        this.macroSettings = {
            ...this.macroSettings,
            newConstruction: value,
        };
    }

    @action
    public onNameChange(name: string) {
        this.macroSettings = {
            ...this.macroSettings,
            name,
        };
    }

    public onRemoveClick = async () => {
        if (this.macroSettings.type !== MacroSettingsPayloadType.UNPERSISTED_SUPER) {
            try {
                if (!this.macroSettings.isUserDefined) {
                    await this.macroInteractor.hideForUser(this.macroSettings.macroId);
                } else {
                    await this.macroInteractor.destroy(this.macroSettings.macroId);
                }
            } catch (e) {
                /* Noop */
                console.warn(e);
            } finally {
                this.onClose();
            }
        }
    };

    public async onSave() {
        runInAction(() => {
            this.isPersisting = true;
        });
        try {
            this.store();
            this.onClose();
        } catch (e) {
            /* Noop */
            console.warn(e);
        } finally {
            runInAction(() => {
                this.isPersisting = false;
            });
        }
    }

    private async store() {
        if (this.macroSettings.type === MacroSettingsPayloadType.UNPERSISTED_SUPER) {
            await this.macroInteractor.storeSuper(
                this.macroSettings.name,
                this.macroSettings.values,
                this.macroSettings.questionUuid,
                this.macroSettings.districtCodes,
                this.macroSettings.buildPeriods,
                this.macroSettings.objectTypes,
                this.macroSettings.appraisalGoals,
                this.macroSettings.newConstruction
            );
        } else {
            await this.macroInteractor.storeSettings(
                this.macroSettings.macroId,
                this.macroSettings.districtCodes,
                this.macroSettings.buildPeriods,
                this.macroSettings.objectTypes,
                this.macroSettings.appraisalGoals,
                this.macroSettings.newConstruction
            );
        }
    }
}
