import * as React from 'react';

import {FunctionComponent} from 'react';
import {Root} from './root';

interface OwnProps {
    appraisalId: number | null;
    questionSetId: number;
    pagePartConfigurationId: number;
    activePagePartUuid: string | null;
}

export const PagePartsConfigurator: FunctionComponent<OwnProps> = ({
    appraisalId,
    questionSetId,
    activePagePartUuid,
    pagePartConfigurationId,
}) => {
    return (
        <Root
            appraisalId={appraisalId}
            questionSetId={questionSetId}
            pagePartConfigurationId={pagePartConfigurationId}
            activePagePartUuid={activePagePartUuid}
        />
    );
};
