import {ReferenceObjectAnswer, referenceObjectAnswerFromSale} from '../models/reference_object_answer';
import {computed, observable, makeObservable} from 'mobx';

import {Presenter} from '../../../../../../../../../support/presenter/presenter';
import {ReferenceObjectCurrentlyInSaleInteractor} from '../../../../../../../../business/reference_object_sale_interactor';

export class AddCustomReferenceSaleInSaleModalPresenter implements Presenter {
    @observable public postalCode = '';
    @observable public houseNumber = '';
    @observable public letter = '';
    @observable public hasError = false;
    @observable public errorMessage = '';
    @observable public submitting = false;

    @computed
    public get isDisabled(): boolean {
        return this.submitting;
    }

    private _onAdd: (referenceObjectAnswer: ReferenceObjectAnswer) => void;
    private _referenceObjectsAnswers: ReferenceObjectAnswer[];
    private _referenceObjectInteractor: ReferenceObjectCurrentlyInSaleInteractor;

    constructor(
        onAdd: (referenceObjectAnswer: ReferenceObjectAnswer) => void,
        referenceObjectsAnswers: ReferenceObjectAnswer[],
        referenceObjectInteractor: ReferenceObjectCurrentlyInSaleInteractor
    ) {
        makeObservable(this);
        this._onAdd = onAdd;
        this._referenceObjectsAnswers = referenceObjectsAnswers;
        this._referenceObjectInteractor = referenceObjectInteractor;
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public async onSubmitClick() {
        try {
            this.submitting = true;
            this.hasError = false;
            this.errorMessage = '';

            const sale = await this._referenceObjectInteractor.getSaleByAddress(
                this.postalCode,
                this.houseNumber,
                this.letter
            );

            const existingInSet = this._referenceObjectsAnswers.find(
                (a) =>
                    a.referenceObject.adres.postcode === sale.postalCode &&
                    a.referenceObject.adres.huisnummer === sale.houseNumber &&
                    a.referenceObject.adres.huisnummerToevoeging === sale.letter
            );

            if (!existingInSet) {
                this._onAdd(referenceObjectAnswerFromSale(sale));
            } else {
                this.hasError = true;
                this.errorMessage =
                    'De referentieverkoop die je probeert toe te voegen is al toegevoegd aan deze waardering.';
            }
        } catch (e) {
            this.hasError = true;
            this.errorMessage =
                'Er kon in het register geen referentieverkoop gevonden worden voor het ingevoerde adres.';
        } finally {
            this.submitting = false;
        }
    }

    public onPostalCodeChange(value: string) {
        this.postalCode = value;
    }

    public onHouseNumberChange(value: string) {
        this.houseNumber = value;
    }

    public onLetterChange(value: string) {
        this.letter = value;
    }
}
