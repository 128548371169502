import {AdaptedDefaultValuesApi, DefaultAdaptedDefaultValuesApi} from './adapted_default_values_api';
import {AnswerApi, DefaultAnswerApi} from './answer_api';
import {AnswerAuditTrailItemApi, DefaultAnswerAuditTrailItemApi} from './answer_audit_trail_api';
import {AppraisalApi, DefaultAppraisalApi} from './appraisal_api';
import {AppraisalAutomationApi, DefaultAppraisalAutomationApi} from './appraisal_automation_api';
import {BuildingInspectorsApi, DefaultBuildingInspectorsApi} from './building_inspectors_api';
import {ConstructionCostsSummaryApi, DefaultConstructionCostsSummaryApi} from './construction_costs_api';
import {DefaultMacroApi, MacroApi} from './macro_api';
import {
    DefaultPreselectedReferenceObjectsApi,
    PreselectedReferenceObjectsApi,
} from './preselected_reference_objects_api';
import {DefaultQuestionSetApi, QuestionSetApi} from './question_set_api';
import {DefaultReferenceObjectApi, ReferenceObjectApi} from './reference_objects_api';
import {DefaultReferenceObjectRentApi, ReferenceObjectRentApi} from './reference_objects_rent_api';
import {DefaultReferenceObjectSaleApi, ReferenceObjectSaleApi} from './reference_objects_sale_api';
import {DefaultUserWidgetApi, UserWidgetApi} from './user_widget_api';
import {DefaultValidationErrorApi, ValidationErrorApi} from './validation_error_api';
import {DefaultControllerAppraiserApi, ControllerAppraiserApi} from './controller_appraiser_api';

import {AjaxDriver} from '../../network/driver/ajax_driver';
import {FetchDriver} from '../../network/driver/fetch_driver';
import {TaskHelper} from '../business/task_helper';
import {DefaultSystemModalApi, SystemModalApi} from './system_modal_api';
import {DefaultExternalAppointmentsApi, ExternalAppointmentsApi} from './external_appointment_api';
import {ComposedNotificationsApi, DefaultComposedNotificationsApi} from './composed_notification_api';
import {DefaultPagePartConfigurationApi, PagePartConfigurationApi} from './page_part_configuration_api';
import {GlobalProvider} from '../../business/global_provider';
import {DefaultTextAIApi, TextAIApi} from './text_ai_api';
import {DefaultSustainabilityApi, SustainabilityApi} from './sustainability_api';
import {DefaultUserSettingsApi, UserSettingsApi} from './user_settings_api';

export interface NetworkComponent {
    ajaxDriver: AjaxDriver;
    questionSetApi: QuestionSetApi;
    appraisalApi: AppraisalApi;
    appraisalAutomationApi: AppraisalAutomationApi;
    answerApi: AnswerApi;
    referenceObjectApi: ReferenceObjectApi;
    referenceObjectSaleApi: ReferenceObjectSaleApi;
    referenceObjectRentApi: ReferenceObjectRentApi;
    validationErrorApi: ValidationErrorApi;
    macroApi: MacroApi;
    adaptedDefaultValuesApi: AdaptedDefaultValuesApi;
    userWidgetsApi: UserWidgetApi;
    constructionCostsSummaryApi: ConstructionCostsSummaryApi;
    buildingInspectorsApi: BuildingInspectorsApi;
    taskHelper: TaskHelper;
    preselectedReferenceObjectsApi: PreselectedReferenceObjectsApi;
    answerAuditTrailItemApi: AnswerAuditTrailItemApi;
    systemModalApi: SystemModalApi;
    externalAppointmentApi: ExternalAppointmentsApi;
    composedNotificationApi: ComposedNotificationsApi;
    pagePartConfigurationApi: PagePartConfigurationApi;
    textAIApi: TextAIApi;
    sustainabilityApi: SustainabilityApi;
    userSettingsApi: UserSettingsApi;
    controllerAppraiserApi: ControllerAppraiserApi;
}

export class DefaultNetworkComponent implements NetworkComponent {
    private _questionSetApi?: QuestionSetApi;
    private _appraisalApi?: AppraisalApi;
    private _appraisalAutomationApi?: AppraisalAutomationApi;
    private _answerApi?: AnswerApi;
    private _referenceObjectApi?: ReferenceObjectApi;
    private _referenceObjectSaleApi?: ReferenceObjectSaleApi;
    private _referenceObjectRentApi?: ReferenceObjectRentApi;
    private _validationErrorApi?: ValidationErrorApi;
    private _macroApi?: MacroApi;
    private _adaptedDefaultValuesApi?: AdaptedDefaultValuesApi;
    private _userWidgetsApi?: UserWidgetApi;
    private _constructionCostsSummaryApi?: ConstructionCostsSummaryApi;
    private _buildingInspectorsApi?: BuildingInspectorsApi;
    private _preselectedReferenceObjectsApi?: PreselectedReferenceObjectsApi;
    private _answerAuditTrailItemApi?: AnswerAuditTrailItemApi;
    private _systemModalApi?: SystemModalApi;
    private _externalAppointmentApi?: ExternalAppointmentsApi;
    private _composedNotificationApi?: ComposedNotificationsApi;
    private _pagePartConfigurationApi?: PagePartConfigurationApi;
    private _textAIApi?: TextAIApi;
    private _sustainabilityApi?: SustainabilityApi;
    private _userSettingsApi?: UserSettingsApi;
    private _controllerAppraiserApi?: ControllerAppraiserApi;
    public ajaxDriver: AjaxDriver = new FetchDriver();
    public taskHelper: TaskHelper = new TaskHelper(this.ajaxDriver);

    constructor(private globalProvider: GlobalProvider) {}

    get questionSetApi(): QuestionSetApi {
        if (this._questionSetApi === undefined) {
            this._questionSetApi = new DefaultQuestionSetApi(this.ajaxDriver, this.globalProvider);
        }
        return this._questionSetApi;
    }

    get appraisalApi(): AppraisalApi {
        if (this._appraisalApi === undefined) {
            this._appraisalApi = new DefaultAppraisalApi(this.ajaxDriver);
        }
        return this._appraisalApi;
    }

    get appraisalAutomationApi(): AppraisalAutomationApi {
        if (this._appraisalAutomationApi === undefined) {
            this._appraisalAutomationApi = new DefaultAppraisalAutomationApi(this.ajaxDriver);
        }
        return this._appraisalAutomationApi;
    }

    get systemModalApi(): SystemModalApi {
        if (this._systemModalApi === undefined) {
            this._systemModalApi = new DefaultSystemModalApi(this.ajaxDriver);
        }
        return this._systemModalApi;
    }

    get textAIApi(): TextAIApi {
        if (this._textAIApi === undefined) {
            this._textAIApi = new DefaultTextAIApi(this.ajaxDriver);
        }
        return this._textAIApi;
    }

    get sustainabilityApi(): SustainabilityApi {
        if (this._sustainabilityApi === undefined) {
            this._sustainabilityApi = new DefaultSustainabilityApi(this.ajaxDriver);
        }
        return this._sustainabilityApi;
    }

    get answerApi(): AnswerApi {
        if (this._answerApi === undefined) {
            this._answerApi = new DefaultAnswerApi(this.ajaxDriver);
        }
        return this._answerApi;
    }

    get referenceObjectApi(): ReferenceObjectApi {
        if (this._referenceObjectApi === undefined) {
            this._referenceObjectApi = new DefaultReferenceObjectApi(this.ajaxDriver);
        }
        return this._referenceObjectApi;
    }

    get referenceObjectSaleApi(): ReferenceObjectSaleApi {
        if (this._referenceObjectSaleApi === undefined) {
            this._referenceObjectSaleApi = new DefaultReferenceObjectSaleApi(this.ajaxDriver);
        }
        return this._referenceObjectSaleApi;
    }

    get referenceObjectRentApi(): ReferenceObjectRentApi {
        if (this._referenceObjectRentApi === undefined) {
            this._referenceObjectRentApi = new DefaultReferenceObjectRentApi(this.ajaxDriver);
        }
        return this._referenceObjectRentApi;
    }

    get validationErrorApi(): ValidationErrorApi {
        if (this._validationErrorApi === undefined) {
            this._validationErrorApi = new DefaultValidationErrorApi(this.ajaxDriver);
        }
        return this._validationErrorApi;
    }

    get macroApi(): MacroApi {
        if (this._macroApi === undefined) {
            this._macroApi = new DefaultMacroApi(this.ajaxDriver);
        }

        return this._macroApi;
    }

    get adaptedDefaultValuesApi(): AdaptedDefaultValuesApi {
        if (this._adaptedDefaultValuesApi === undefined) {
            this._adaptedDefaultValuesApi = new DefaultAdaptedDefaultValuesApi(this.ajaxDriver);
        }
        return this._adaptedDefaultValuesApi;
    }

    get userWidgetsApi(): UserWidgetApi {
        if (this._userWidgetsApi === undefined) {
            this._userWidgetsApi = new DefaultUserWidgetApi(this.ajaxDriver);
        }
        return this._userWidgetsApi;
    }

    get constructionCostsSummaryApi(): ConstructionCostsSummaryApi {
        if (this._constructionCostsSummaryApi === undefined) {
            this._constructionCostsSummaryApi = new DefaultConstructionCostsSummaryApi(this.ajaxDriver);
        }
        return this._constructionCostsSummaryApi;
    }

    get buildingInspectorsApi(): BuildingInspectorsApi {
        if (this._buildingInspectorsApi === undefined) {
            this._buildingInspectorsApi = new DefaultBuildingInspectorsApi(this.ajaxDriver);
        }

        return this._buildingInspectorsApi;
    }

    get preselectedReferenceObjectsApi(): PreselectedReferenceObjectsApi {
        if (this._preselectedReferenceObjectsApi === undefined) {
            this._preselectedReferenceObjectsApi = new DefaultPreselectedReferenceObjectsApi(this.ajaxDriver);
        }
        return this._preselectedReferenceObjectsApi;
    }

    get answerAuditTrailItemApi(): AnswerAuditTrailItemApi {
        if (this._answerAuditTrailItemApi === undefined) {
            this._answerAuditTrailItemApi = new DefaultAnswerAuditTrailItemApi(this.ajaxDriver);
        }
        return this._answerAuditTrailItemApi;
    }

    get externalAppointmentApi(): ExternalAppointmentsApi {
        if (this._externalAppointmentApi === undefined) {
            this._externalAppointmentApi = new DefaultExternalAppointmentsApi(this.ajaxDriver);
        }
        return this._externalAppointmentApi;
    }

    get composedNotificationApi(): ComposedNotificationsApi {
        if (this._composedNotificationApi === undefined) {
            this._composedNotificationApi = new DefaultComposedNotificationsApi(this.ajaxDriver);
        }
        return this._composedNotificationApi;
    }

    get pagePartConfigurationApi(): PagePartConfigurationApi {
        if (this._pagePartConfigurationApi === undefined) {
            this._pagePartConfigurationApi = new DefaultPagePartConfigurationApi(this.ajaxDriver);
        }
        return this._pagePartConfigurationApi;
    }

    get userSettingsApi(): UserSettingsApi {
        if (this._userSettingsApi === undefined) {
            this._userSettingsApi = new DefaultUserSettingsApi(this.ajaxDriver);
        }
        return this._userSettingsApi;
    }

    get controllerAppraiserApi(): ControllerAppraiserApi {
        if (this._controllerAppraiserApi === undefined) {
            this._controllerAppraiserApi = new DefaultControllerAppraiserApi(this.ajaxDriver);
        }
        return this._controllerAppraiserApi;
    }
}
