import {Question} from '../../../../../../../../models/question';
import {ReferenceObject} from './reference_object';
import {ReferenceObjectAnswer} from './reference_object_answer';
import {ReferenceObjectData} from '../internal/create_reference_object_data';
import {ReferenceSubscriptions} from '../../../../../../../../models/reference_subscriptions';
import {SetDefinition} from '../reference_objects_question_presenter';
import {ReferenceSaleImage} from '../../v1/models/reference_sale_image';

export enum ModalType {
    NONE = 'none',
    CUSTOM_REFERENCE_SALE = 'custom_reference_sale',
    OBJECT_ANSWER = 'object_answer',
    OBJECT_ANSWERS_COMPARISON = 'object_answers_comparison',
    REFERENCE_OBJECT_DETAILS = 'reference_object_details',
    IMAGE_VIEWER = 'image_viewer',
}

export interface BaseModalState {
    type: ModalType;
    id: string;
}

export interface NoneModalState extends BaseModalState {
    type: ModalType.NONE;
}

export interface CustomReferenceSaleModalState extends BaseModalState {
    type: ModalType.CUSTOM_REFERENCE_SALE;
    subscriptions: ReferenceSubscriptions | null;
}

export interface ObjectAnswerModalState extends BaseModalState {
    type: ModalType.OBJECT_ANSWER;
    referenceObjectAnswer: ReferenceObjectAnswer;
    question: Question;
    parentAnswerUuid: string;
    iteratorAnswerUuid: string;
    setDefinition: SetDefinition;
}

export interface ObjectAnswersComparisonState extends BaseModalState {
    type: ModalType.OBJECT_ANSWERS_COMPARISON;
    referenceObjects: ReferenceObjectData[];
    referenceSets: SetDefinition[];
    activeSetDefinition: SetDefinition;
}

export interface ReferenceObjectDetailsModalState extends BaseModalState {
    type: ModalType.REFERENCE_OBJECT_DETAILS;
    referenceObjectAnswer: ReferenceObjectAnswer | null;
    referenceObject: ReferenceObject | null;
    canAdd: boolean;
    isEnhancing: boolean;
    setDefinition: SetDefinition | null;
}

export interface ImageViewerModalState extends BaseModalState {
    type: ModalType.IMAGE_VIEWER;
    images: ReferenceSaleImage[];
    initialIndex?: number;
    photoAnswerUuid?: string;
}

export type ModalState =
    | NoneModalState
    | CustomReferenceSaleModalState
    | ObjectAnswerModalState
    | ReferenceObjectDetailsModalState
    | ObjectAnswersComparisonState
    | ImageViewerModalState;
