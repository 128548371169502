import * as React from 'react';

import {
    DndContext,
    DragEndEvent,
    PointerSensor,
    closestCenter,
    useSensor,
    useSensors,
    KeyboardSensor,
    DragOverlay,
} from '@dnd-kit/core';
import {useMemo, useState} from 'react';
import {SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {restrictToWindowEdges} from '@dnd-kit/modifiers';

import {DraggableQuestionContainer} from './draggable_question_container';
import {PagePart} from '../../../../../../models/page_part';
import {PagePartsSet} from '../../../../../../models/page_parts_set';
import {QuestionSet} from '../../../../../../models/question_set';
import {SimpleQuestionPresenterProps} from '../../simple/simple_question_presenter';
import {SortablePagePartChildrenPresenter} from './sortable_page_part_children_presenter';
import {getPagePartChildren} from './support/get_page_part_children';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';

import type {DragStartEvent} from '@dnd-kit/core';
import {snapToCursor} from '../../../../../../../support/drag_snap_to_cursor';
import {createPortal} from 'react-dom';

interface OwnProps extends Omit<SimpleQuestionPresenterProps, 'question'> {
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet;
    pagePartUuid: string;
    activePagePart: PagePart;
}

export const SortablePagePartChildren: React.FC<OwnProps> = function SortablePagePartChildren({
    appraisal,
    questionSet,
    pagePartsSet,
    pagePartUuid,
    renderingContext,
    ...props
}) {
    const presenter = usePresenter((component) => {
        return new SortablePagePartChildrenPresenter(component.business.pagePartsSetInteractor(appraisal, questionSet));
    });

    const pagePartChildrenQuestions = useMemo(() => {
        return getPagePartChildren(pagePartUuid, pagePartsSet, questionSet);
    }, [pagePartUuid, pagePartsSet, questionSet]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const [isDraggingDisabled, setDraggingDisabled] = useState(pagePartsSet.config.isEditable === false);

    const [activePagePart, setActivePagePart] = useState<null | PagePart>(null);
    function handleDragEnd(event: DragEndEvent) {
        setActivePagePart(null);
        const {active, over} = event;

        if (over && active.id !== over.id) {
            const newIndex = pagePartChildrenQuestions.findIndex((child) => child.id === over.id);
            presenter.onPagePartRankUpdate(pagePartsSet.id, active.id as string, newIndex);
        }
    }
    function handleDragStart(event: DragStartEvent) {
        setActivePagePart(pagePartChildrenQuestions.find((q) => q.id === event.active.id)?.pagePart ?? null);
    }

    if (pagePartChildrenQuestions.length === 0) {
        return null;
    }

    return (
        <DndContext
            modifiers={[restrictToWindowEdges, snapToCursor]}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
        >
            {createPortal(
                <DragOverlay>
                    {activePagePart ? (
                        <div className="draggable-question-container draggable-question-container-drag-overlay ion-md-reorder draggable-question-container-dragging">
                            <h2>{activePagePart.name}</h2>
                        </div>
                    ) : null}
                </DragOverlay>,
                document.body
            )}
            <SortableContext
                items={pagePartChildrenQuestions}
                strategy={verticalListSortingStrategy}
                disabled={isDraggingDisabled}
            >
                {pagePartChildrenQuestions.map((child) => (
                    <DraggableQuestionContainer
                        key={child.id}
                        onRemove={presenter.onRemovePagePart}
                        onChangeExplanation={presenter.onChangeExplanation}
                        appraisal={appraisal}
                        question={child.question}
                        onChange={props.onChange}
                        questionSet={questionSet}
                        disabled={props.disabled}
                        validationErrors={props.validationErrors}
                        validationMessages={props.validationMessages}
                        parentAnswerUuid={props.parentAnswerUuid}
                        adaptedDefaultValues={props.adaptedDefaultValues}
                        disableAdaptedValueContainer={props.disableAdaptedValueContainer}
                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                        pagePartsSet={pagePartsSet}
                        activePagePart={child.pagePart}
                        renderingContext={renderingContext}
                        questionRenderingData={null}
                        forceShowValidationMessages={props.forceShowValidationMessages}
                        onSetDraggingDisabled={(isDisabled) => setDraggingDisabled(isDisabled)}
                    />
                ))}
            </SortableContext>
        </DndContext>
    );
};
