import * as React from 'react';

import {Appraisal} from '../../../../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../../../../models/question_set';
import {VolumeBadge} from '../../../../../../../../../components/badges/volume_badge';
import {observer} from 'mobx-react';

interface OwnProps {
    compact?: boolean;
    volume: number;
    brutoInhoud: number;
    appraisal: Appraisal;
    questionSet: QuestionSet;
}

@observer
export class VolumeBadgeAdapter extends React.Component<OwnProps> {
    public render() {
        return (
            <VolumeBadge
                compact={this.props.compact}
                volume={this.props.volume}
                referenceVolume={this.props.brutoInhoud}
            />
        );
    }
}
