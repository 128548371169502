export function isEmpty<T extends null | undefined | ''>(value: T | unknown | number | string): value is T {
    return value === null || value === undefined || value === '';
}

export function isNumeric<T extends number | string>(
    value: T | null | undefined | Record<string, unknown>
): value is T {
    if (isEmpty(value)) {
        return false;
    }

    //isNaN also casts the value to a number, so '123' will be checked as a number
    return !isNaN(value as number);
}
