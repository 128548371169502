import $ from 'jquery';

/**
 * Toggle ellipsis in list
 */
(() => {
    // Select extended form for appraisal goal
    const listItem = $('[data-toggle="list-ellipsis"]');
    if (listItem.length > 0) {
        listItem.bind('click', function (radio) {
            const id = $(this).attr('data-target');
            $(id).toggleClass('list-item-open');
        });
    }
})();
