import {CustomizationsSet, DefaultCustomizationsSet} from './customizations_set';
import {PagePart} from './page_part';
import {PagePartConfiguration} from './page_part_configuration';

export interface PagePartsSet {
    id: number;
    name: string | null;
    config: PagePartConfiguration;
    rootItems: PagePart[];
    pageParts: PagePart[];
    customizations: CustomizationsSet;

    getByUuid(uuid: string): PagePart | null;
    getChildrenForUuid(uuid: string): PagePart[];
    getRecursiveChildrenForUuid(uuid: string): PagePart[];
    getLeafs(uuid: string): PagePart[];
}

export class DefaultPagePartsSet implements PagePartsSet {
    private pagePartByUuidMap: null | Map<string, PagePart> = null;
    private pagePartByParentUuidMap: null | Map<string | null, PagePart[]> = null;
    private rootPageParts: null | PagePart[] = null;

    private _pageParts: PagePart[];
    private _customizations: CustomizationsSet;
    constructor(private pagePartConfiguration: PagePartConfiguration) {
        this._pageParts = Array.from(pagePartConfiguration.pageParts).sort((a, b) => a.rank - b.rank);
        this._customizations = new DefaultCustomizationsSet(pagePartConfiguration);
    }

    public get id() {
        return this.pagePartConfiguration.id;
    }
    public get name() {
        return this.pagePartConfiguration.name;
    }

    public get config() {
        return this.pagePartConfiguration;
    }

    public get pageParts(): PagePart[] {
        return this._pageParts;
    }

    public get customizations(): CustomizationsSet {
        return this._customizations;
    }

    public get rootItems(): PagePart[] {
        if (this.rootPageParts === null) {
            this.rootPageParts = this._pageParts.filter((pp) => pp.parentUuid === null).sort((a, b) => a.rank - b.rank);
        }
        return this.rootPageParts;
    }

    private createPagePartsMap(pageParts: PagePart[]): Map<string, PagePart> {
        const result = new Map<string, PagePart>();
        for (const pagePart of pageParts) {
            result.set(pagePart.uuid, pagePart);
        }
        return result;
    }

    public getByUuid(uuid: string): PagePart | null {
        if (this.pagePartByUuidMap === null) {
            this.pagePartByUuidMap = this.createPagePartsMap(this._pageParts);
        }
        return this.pagePartByUuidMap.get(uuid) ?? null;
    }

    private createParentPagePartsMap(pageParts: PagePart[]): Map<string | null, PagePart[]> {
        const result = new Map<string | null, PagePart[]>();
        for (const pagePart of pageParts) {
            const newArray = [...(result.get(pagePart.parentUuid) ?? []), pagePart];
            result.set(pagePart.parentUuid, newArray);
        }
        return result;
    }

    public getChildrenForUuid(uuid: string): PagePart[] {
        if (this.pagePartByParentUuidMap === null) {
            this.pagePartByParentUuidMap = this.createParentPagePartsMap(this._pageParts);
        }
        return this.pagePartByParentUuidMap.get(uuid) ?? [];
    }

    public getRecursiveChildrenForUuid(uuid: string): PagePart[] {
        const directChildren = this.getChildrenForUuid(uuid);
        let result = [...directChildren];
        for (const child of directChildren) {
            result = result.concat(this.getChildrenForUuid(child.uuid));
        }
        return result;
    }

    private gibLeafs(pagePart: PagePart): PagePart[] {
        const children = this.getChildrenForUuid(pagePart.uuid);
        if (children.length === 0) {
            return [pagePart];
        }

        let childLeafs: PagePart[] = [];
        for (const child of children) {
            childLeafs = childLeafs.concat(this.gibLeafs(child));
        }
        return childLeafs;
    }

    public getLeafs(uuid: string): PagePart[] {
        const thisNode = this.getByUuid(uuid);
        if (thisNode) {
            return this.gibLeafs(thisNode);
        }
        return [];
    }
}
