import {FlashMessageBroadcaster, Type} from '../../../../business/flash_message_broadcaster';
import {FloorInfo, FloorInteractor, FloorTypeAnswerOptionContents} from '../../../../business/floor_interactor';
import {action, computed, makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {Presenter} from '../../../../../support/presenter/presenter';

export enum PopoverType {
    none,
    moreMenu,
    floorAdd,
    floorOverview,
    floorEdit,
}

interface PopoverStateBase {
    type: PopoverType.none | PopoverType.moreMenu | PopoverType.floorAdd | PopoverType.floorOverview;
}

export interface EditPopoverState {
    type: PopoverType.floorEdit;
    floorInfo: FloorInfo;
    newFloorType: FloorTypeAnswerOptionContents;
}

type PopoverState = PopoverStateBase | EditPopoverState;

export class FloorSelectorPresenter implements Presenter {
    @observable public floorIteration: string | null = null;
    @observable.ref public floorInfos: FloorInfo[] = [];

    @observable public popoverState: PopoverState = {type: PopoverType.none};

    @observable public ownSelectionFloorType = FloorTypeAnswerOptionContents.VERDIEPING;
    @observable public ownSelectionFloorNumber: string | null = null;

    @computed
    public get selectedFloorInfo() {
        return this.floorInfos.find((fi) => fi.iteration === this.floorIteration);
    }

    private subscriptions = new CompositeSubscription();

    constructor(private floorInteractor: FloorInteractor, private FlashMessageBroadcaster: FlashMessageBroadcaster) {
        makeObservable(this);
    }

    public async mount() {
        this.subscriptions.add(
            this.floorInteractor.getFloorIteration().subscribe((floorIteration) => {
                runInAction(() => {
                    this.floorIteration = floorIteration;
                });
            })
        );

        this.subscriptions.add(
            this.floorInteractor.getFloorsStream().subscribe((floorInfos) => {
                if (floorInfos) {
                    runInAction(() => {
                        this.floorInfos = floorInfos;
                    });
                }
            })
        );
    }

    public async unmount() {
        this.subscriptions.clear();
    }

    public visibleFloorInfos(collapsed: boolean) {
        const currentSelectedIndex = this.floorInfos.findIndex((fi) => fi.iteration === this.floorIteration);
        if (collapsed) {
            return [this.floorInfos[currentSelectedIndex]];
        }

        let visibleFloorInfos: Array<FloorInfo | null> = [];
        if (currentSelectedIndex <= 0) {
            visibleFloorInfos = [null, ...this.floorInfos.slice(0, 2)];
        } else {
            visibleFloorInfos = this.floorInfos.slice(currentSelectedIndex - 1, currentSelectedIndex + 2);
        }
        return visibleFloorInfos;
    }

    public hiddenFloorInfos(collapsed: boolean) {
        return this.floorInfos.filter(
            (fi) => this.visibleFloorInfos(collapsed).some((vfi) => vfi?.iteration === fi.iteration) === false
        );
    }

    @action
    public onFloorChange = (floorInfo: FloorInfo) => {
        this.popoverState = {
            type: PopoverType.none,
        };
        this.floorInteractor.setFloorIteration(floorInfo.iteration);
    };

    @action
    public setIsMoreMenuOpen = (isMoreMenuOpen: boolean) => {
        this.popoverState = {
            type: isMoreMenuOpen ? PopoverType.moreMenu : PopoverType.none,
        };
    };

    @action
    public setIsAddFloorModalOpen = (isAddFloorModalOpen: boolean) => {
        this.popoverState = {
            type: isAddFloorModalOpen ? PopoverType.floorAdd : PopoverType.none,
        };
    };

    @action
    public setIsOverviewFloorModalOpen = (isFloorOverviewModalOpen: boolean) => {
        this.popoverState = {
            type: isFloorOverviewModalOpen ? PopoverType.floorOverview : PopoverType.none,
        };
    };

    @action
    public setIsEditFloorModalOpen = (isOpen: boolean, floorInfo: FloorInfo) => {
        this.popoverState = isOpen
            ? {
                  type: PopoverType.floorEdit,
                  floorInfo: floorInfo,
                  newFloorType: floorInfo.floorType ?? FloorTypeAnswerOptionContents.VERDIEPING,
              }
            : {
                  type: PopoverType.none,
              };
    };

    @action
    public setFloorEditFloorType = (floorType: FloorTypeAnswerOptionContents) => {
        const currentPopoverState = this.popoverState;
        if ('newFloorType' in currentPopoverState) {
            this.popoverState = {
                ...currentPopoverState,
                newFloorType: floorType,
            };
        }
    };

    @action
    public setOwnSelectionFloorType = (floorType: FloorTypeAnswerOptionContents) => {
        this.ownSelectionFloorType = floorType;
    };

    @action
    public setOwnSelectionFloorNumber = (floorNumber: string) => {
        this.ownSelectionFloorNumber = floorNumber;
    };

    @action
    public addFloor = (floorType: FloorTypeAnswerOptionContents, floorNumber?: string) => {
        const newIteration = this.floorInteractor.addFloor(floorType, floorNumber);
        if (newIteration) {
            this.floorInteractor.setFloorIteration(newIteration);
            this.popoverState = {
                type: PopoverType.none,
            };
            this.ownSelectionFloorType = FloorTypeAnswerOptionContents.VERDIEPING;
            this.ownSelectionFloorNumber = null;
        }
    };

    @action
    public removeFloor = (floorInfo: FloorInfo) => {
        if (this.floorInfos.length === 1) {
            this.FlashMessageBroadcaster.broadcast('Kan niet de enige woonlaag verwijderen.', Type.Danger);
            return;
        }

        if (confirm(`Weet je zeker dat je ${floorInfo.name} wil verwijderen?`)) {
            if (this.floorIteration === floorInfo.iteration) {
                const index = this.floorInfos.findIndex((fi) => fi.iteration === this.floorIteration);
                const newIterator = this.floorInfos[index + 1] ?? this.floorInfos[index - 1] ?? null;
                if (newIterator) {
                    this.floorInteractor.setFloorIteration(newIterator.iteration);
                }
            }
            this.floorInteractor.removeFloor(floorInfo.iteration);
        }
    };

    @action
    public editFloorType = (floorInfo: FloorInfo, newType: FloorTypeAnswerOptionContents) => {
        if (floorInfo.floorTypeAnswerUuid) {
            const updatedAnswer = this.floorInteractor.changeFloorType(floorInfo.floorTypeAnswerUuid, newType);
            if (updatedAnswer) {
                this.popoverState = {type: PopoverType.none};
            }
        }
    };
}
