import {computed, makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../support/composite_subscription';
import {GroupType} from '../../../enum/group_type';
import {Observable} from 'rxjs';
import {Presenter} from '../../../../support/presenter/presenter';
import {Question} from '../../../models/question';
import {QuestionSet} from '../../../models/question_set';
import {SurfaceAreaProvider} from '../../../business/support/surface_area_provider';
import {map} from 'rxjs/operators';
import {normalizeNumber} from '../../../business/support/normalize_number';

export interface ChildQuestionsProvider {
    stream: Observable<Question[]>;
}

export class SurfaceAreaCounterPresenter implements Presenter {
    @observable public surfaceArea: number | null = null;

    @computed
    public get visible() {
        return this.currentPathnameContainsSurfaceArea;
    }

    @observable private currentPathnameContainsSurfaceArea = false;

    private subscriptions = new CompositeSubscription();

    constructor(
        private questionSet: QuestionSet,
        private childQuestionsProvider: ChildQuestionsProvider,
        private surfaceAreaProvider: SurfaceAreaProvider
    ) {
        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            this.childQuestionsProvider.stream
                .pipe(
                    //Find one that has our group in it
                    map<Question[], boolean>((questions) => {
                        return questions.some(
                            (question) =>
                                question.group?.toString().includes(GroupType.SURFACE_AREA_ADDITIVE.toString()) ||
                                question.group?.toString().includes(GroupType.SURFACE_AREA_DEDUCTIVE.toString())
                        );
                    })
                )
                .subscribe((currentPathnameContainsSurfaceArea) => {
                    runInAction(() => {
                        this.currentPathnameContainsSurfaceArea = currentPathnameContainsSurfaceArea;
                    });
                })
        );

        this.subscriptions.add(
            this.surfaceAreaProvider
                .surfaceArea()
                // Round the total
                .subscribe((sum) => {
                    runInAction(() => {
                        this.surfaceArea = normalizeNumber(sum);
                    });
                })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
