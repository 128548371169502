import * as React from 'react';

import {Appraisal} from '../../../../../../models/appraisal';
import {QuestionSet} from '../../../../../../models/question_set';
import {RenderingContextType} from '../../../../../../enum/rendering_context_type';
import {ConstructionSpecificationViewerDummy} from './construction_specification_viewer_dummy';
import {ReportIframeViewer} from '../report_viewer/report_iframe_viewer';
import {GeneratedReportType} from '../../../../../../enum/generated_report';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    renderingContext: RenderingContextType;
}

export const ConstructionSpecificationViewer: React.FunctionComponent<OwnProps> = (props) => {
    if (props.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
        return <ConstructionSpecificationViewerDummy />;
    }
    return (
        <ReportIframeViewer
            appraisal={props.appraisal}
            questionSet={props.questionSet}
            reportType={GeneratedReportType.CONSTRUCTION_SPECIFICATION}
        />
    );
};
