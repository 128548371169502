/**
 * Show iOS add to home screen
 */
import {isIos, isIpad} from '../support/check_mobile';

(() => {
    const target = $('.ios-homescreen-message');

    if (target.length !== 0) {
        // Detects if device is in standalone mode
        const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;

        const isDismissed = () => {
            return sessionStorage.getItem('taxapi-ios-homescreen') === 'dismissed';
        };

        // Checks if should display install popup notification:
        if (isIos() && !isInStandaloneMode() && !isDismissed()) {
            target.addClass('show');

            if (isIpad()) {
                target.addClass('is-ipad');
            }
        }
        // Dismiss on click
        target.bind('click', function () {
            $(this).removeClass('show');
            sessionStorage.setItem('taxapi-ios-homescreen', 'dismissed');
        });
    }
})();
