import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';

import colorSyntax, {PluginOptions} from '@toast-ui/editor-plugin-color-syntax';

import {PluginContext} from '@toast-ui/editor/dist/toastui-editor-viewer';

export function colorPlugin(context: PluginContext, options: PluginOptions) {
    const plugin = colorSyntax(context, options);

    if (plugin.toolbarItems) {
        plugin.toolbarItems[0].groupIndex += 1;
    }

    return plugin;
}
