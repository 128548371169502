import * as React from 'react';

import {classNames} from '../../support/classnames';
import {objectTypeDiffToBadgeContext, statusTextToBadgeContext} from './badges/badge_context_calculators';
import {ObjectType} from '../enum/object_type';

interface OwnProps {
    label: string;
    status: string | null;
    value: ObjectType | null;
    referenceValue: ObjectType | null;
}

export class StatusDetailListForObjectType extends React.Component<OwnProps> {
    public render() {
        if (this.props.value !== null) {
            let badgeContext = objectTypeDiffToBadgeContext(this.props.value, this.props.referenceValue);
            if (this.props.status !== null) {
                badgeContext = statusTextToBadgeContext(this.props.status);
            }

            const badge = this.props.referenceValue || '';

            return (
                <dl>
                    <dt>{this.props.label}</dt>
                    <dd>
                        <span className={classNames('badge-container-wide', badgeContext)}>
                            {this.props.value || '-'}
                            {badge.length > 0 ? (
                                <span className={classNames('badge', badgeContext)}>{badge}</span>
                            ) : null}
                        </span>
                    </dd>
                </dl>
            );
        }

        return (
            <dl>
                <dt>{this.props.label}</dt>
                <dd>
                    <span className={classNames('badge-container-wide')}>{this.props.value || '-'}</span>
                </dd>
            </dl>
        );
    }
}
