import * as React from 'react';

import {IteratorFilesListType} from './iterator_files_attachment_interactor';

import {AdaptedDefaultValuesMap} from '../../../../../../models/adapted_default_values_map';
import {Answer} from '../../../../../../models/answer';
import {AttachmentQuestionProps} from '../attachment_question_presenter';
import {BooleanQuestion} from '../../simple/boolean_question';
import {DefaultIteratorFilesAttachmentProvider} from './iterator_files_attachment_provider';
import {DummyFileGroup} from '../../simple/dummy_files_group';
import {IteratorFilesAttachmentPresenter} from './iterator_files_attachment_presenter';
import {MultipleChoiceSelectQuestion} from '../../simple/multiple_choice_select_question';
import {OpenQuestionCompact} from '../../simple/open_question_compact';
import {QuestionSet} from '../../../../../../models/question_set';
import {ValidationError} from '../../../../../../models/validation_error';
import {classNames} from '../../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {simpleQuestionPresenterConstructorParametersFactory} from '../../simple/simple_question_presenter';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {SimpleTooltip} from '../../../../../../components/tooltip';

interface OwnProps extends AttachmentQuestionProps {
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    adaptedDefaultValues?: AdaptedDefaultValuesMap;
    showDeleteButton?: boolean;
    iteratorFilesListType: IteratorFilesListType;
    fileTypes: string[];
    iterationProgresses: Record<string, number>;
}

export const IteratorFilesAttachment: React.FC<OwnProps> = observer(function IteratorFilesAttachment(props) {
    const presenter = usePresenter(
        (container) =>
            new IteratorFilesAttachmentPresenter(
                props.iteratorFilesListType,
                new DefaultIteratorFilesAttachmentProvider(
                    props.question,
                    props.questionSet,
                    container.business.answerController(props.appraisal, props.questionSet),
                    container.business.iteratorFilesAttachmentInteractor(props.appraisal, props.questionSet)
                ),
                container.business.iteratorFilesAttachmentInteractor(props.appraisal, props.questionSet),
                props.fileTypes,
                container.globalProvider,
                container.business.blobCacheInteractor(),
                container.business.attachmentUploadInteractor(props.appraisal, props.questionSet),
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.attachmentQuestionsInteractor(props.appraisal, props.questionSet),
                container.business.flashMessageBroadcaster,
                container.business.automatorInteractor(props.appraisal, props.questionSet),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );
    const iterationAnswer = presenter.answer;
    const showDeleteButton = props.showDeleteButton === undefined ? true : props.showDeleteButton;
    const isUploadContext = props.iteratorFilesListType === IteratorFilesListType.UPLOAD_LIST;
    const isEditContext = props.iteratorFilesListType === IteratorFilesListType.FILE_LIST && presenter.isEditing;
    const showForm = presenter.isEditable && (isEditContext || isUploadContext);

    if (presenter.loading) {
        return <DummyFileGroup className="placeholder-opaque-80" />;
    }

    function renderQuestions(iterationAnswer: Answer | undefined) {
        return (
            <div className="list-item-attachment-form">
                {iterationAnswer && presenter.mcQuestion && (
                    <MultipleChoiceSelectQuestion
                        key={presenter.mcQuestion.uuid}
                        appraisal={props.appraisal}
                        question={presenter.mcQuestion}
                        questionSet={props.questionSet}
                        validationErrors={props.validationErrors}
                        validationMessages={props.validationMessages}
                        parentAnswerUuid={iterationAnswer.uuid}
                        adaptedDefaultValues={props.adaptedDefaultValues}
                        hiddenQuestionTypes={props.hiddenQuestionTypes}
                        pagePartsSet={props.pagePartsSet}
                        activePagePart={props.activePagePart}
                        hideLabel={true}
                        renderingContext={props.renderingContext}
                        questionRenderingData={props.questionRenderingData}
                        forceShowValidationMessages={props.forceShowValidationMessages}
                    />
                )}
                {iterationAnswer && presenter.openQuestion && (
                    <div
                        className={classNames('form-group', 'position-relative', {
                            'form-group-close': props.iteratorFilesListType === IteratorFilesListType.UPLOAD_LIST,
                        })}
                    >
                        <OpenQuestionCompact
                            key={presenter.openQuestion.uuid}
                            appraisal={props.appraisal}
                            question={presenter.openQuestion}
                            questionSet={props.questionSet}
                            validationErrors={props.validationErrors}
                            validationMessages={props.validationMessages}
                            parentAnswerUuid={iterationAnswer.uuid}
                            adaptedDefaultValues={props.adaptedDefaultValues}
                            hiddenQuestionTypes={props.hiddenQuestionTypes}
                            pagePartsSet={props.pagePartsSet}
                            activePagePart={props.activePagePart}
                            hideLabel={false}
                            renderingContext={props.renderingContext}
                            questionRenderingData={props.questionRenderingData}
                            forceShowValidationMessages={props.forceShowValidationMessages}
                        />
                        {props.iteratorFilesListType === IteratorFilesListType.UPLOAD_LIST &&
                            presenter.hasOpenField && (
                                <button
                                    className="btn btn-secondary files-btn ion-md-checkmark pulse"
                                    onClick={() => presenter.onAcceptOpenField()}
                                />
                            )}
                    </div>
                )}
            </div>
        );
    }

    function renderButtons(iterationAnswer: Answer | undefined, showDeleteButton: boolean) {
        if (!presenter.isEditable) {
            return null;
        }

        if (props.iteratorFilesListType === IteratorFilesListType.UPLOAD_LIST) {
            return (
                showDeleteButton && (
                    <div className="list-item-attachment-buttons">
                        <button
                            className="btn btn-clear ion-md-trash"
                            onClick={() => presenter.onDeleteClick()}
                            disabled={presenter.isDisabled}
                        />
                    </div>
                )
            );
        }

        if (presenter.isEditing) {
            return (
                <div className="list-item-attachment-buttons">
                    <button className="btn btn-save ion-md-checkmark" onClick={() => presenter.onToggleEdit()} />
                </div>
            );
        }

        return (
            <div className="list-item-attachment-buttons">
                {presenter.url ? (
                    <button
                        className="btn btn-upload"
                        disabled={presenter.isDisabled}
                        onClick={(e) => {
                            if (
                                e.target instanceof HTMLElement &&
                                e.target.firstChild instanceof HTMLInputElement &&
                                confirm('Zeker dat je dit bestand wil vervangen?')
                            ) {
                                e.target.firstChild.click();
                            }
                        }}
                    >
                        {renderFileUpload()}
                        Vervang
                        <br />
                        bijlage
                    </button>
                ) : (
                    <label className="btn btn-upload">
                        {renderFileUpload()}
                        Upload
                        <br />
                        een bijlage
                    </label>
                )}

                {presenter.checkQuestion !== null && (
                    // This boolean question is rendered as button
                    <SimpleTooltip
                        content={
                            presenter.isActive ? 'Bijlage opgenomen in rapport.' : 'Bijlage niet opgenomen in rapport.'
                        }
                    >
                        <div className="btn btn-check">
                            <BooleanQuestion
                                key={presenter.checkQuestion.uuid}
                                appraisal={props.appraisal}
                                question={presenter.checkQuestion}
                                questionSet={props.questionSet}
                                validationErrors={props.validationErrors}
                                validationMessages={props.validationMessages}
                                parentAnswerUuid={iterationAnswer?.uuid}
                                adaptedDefaultValues={props.adaptedDefaultValues}
                                hiddenQuestionTypes={props.hiddenQuestionTypes}
                                pagePartsSet={props.pagePartsSet}
                                activePagePart={props.activePagePart}
                                hideLabel={true}
                                renderingContext={props.renderingContext}
                                questionRenderingData={props.questionRenderingData}
                                forceShowValidationMessages={props.forceShowValidationMessages}
                            />
                        </div>
                    </SimpleTooltip>
                )}
                <button
                    className="btn btn-edit ion-md-create"
                    onClick={() => presenter.onToggleEdit()}
                    disabled={presenter.isDisabled}
                />
                {showDeleteButton && (
                    <button
                        className="btn btn-clear ion-md-trash"
                        onClick={() => presenter.onDeleteClick()}
                        disabled={presenter.isDisabled}
                    />
                )}
            </div>
        );
    }

    function renderProgressBar(iterationAnswer: Answer | undefined) {
        const progress =
            presenter.uploadProgress ??
            (iterationAnswer?.iteration ? props.iterationProgresses[iterationAnswer.iteration] : 0);
        if (progress === 1 || progress === 0 || progress === undefined || progress === null) {
            return null;
        }

        return (
            <span className="list-progress">
                <div className="uppy uppy-ProgressBar" style={{position: 'fixed'}}>
                    <div
                        className="uppy-ProgressBar-inner"
                        style={{
                            width: progress ? `${progress * 100}%` : '0%',
                        }}
                    />
                    <div className="uppy-ProgressBar-percentage">0</div>
                </div>
            </span>
        );
    }

    function renderFileData(iterationAnswer: Answer | undefined, showForm: boolean) {
        return (
            <>
                {presenter.url && (
                    <a
                        className="list-item-attachment-download"
                        onClick={(e) => presenter.onOpenFile(e)}
                        href={presenter.url}
                        target="_blank"
                        rel="noreferrer"
                    />
                )}
                {presenter.hasUserAvatar && renderUserAvatar()}
                <SimpleTooltip
                    content={
                        presenter.isActive ? 'Bijlage opgenomen in rapport.' : 'Bijlage niet opgenomen in rapport.'
                    }
                >
                    <span
                        className={classNames('list-avatar', {
                            'list-avatar-second': presenter.hasUserAvatar,
                            'list-avatar-download': presenter.url !== null,
                            'list-avatar-active': presenter.isActive,
                            'ion-md-cloud-upload': presenter.url === null,
                            'ion-md-cloud-download': presenter.url !== null,
                        })}
                    >
                        {presenter.shouldShowUnreadIndicator &&
                            presenter.fileAnswer?.file &&
                            presenter.fileAnswer?.isVisited === false && <span className="unread-notification" />}
                    </span>
                </SimpleTooltip>

                <span
                    className={classNames('form-group', presenter.filledByAutomator, {
                        'automation-filled': presenter.filledByAutomator !== null,
                        'list-title-download': presenter.url !== null,
                    })}
                >
                    {showForm ? renderQuestions(iterationAnswer) : presenter.attachmentTitle}
                </span>
                <small
                    className={classNames('list-details', {
                        'list-details-download': presenter.url !== null,
                    })}
                >
                    {presenter.filename !== null ? presenter.filename : 'Nog geen .pdf bestand geüpload'}
                    {presenter.showRetryButton ? ' - Er is iets mis met deze pdf. Upload een nieuwe pdf.' : null}
                </small>
            </>
        );
    }

    function renderUserAvatar() {
        return (
            <SimpleTooltip content={presenter.avatarData.tooltip} placement="top">
                <span
                    className={classNames('list-avatar', 'list-user-avatar', presenter.avatarData.iconClass)}
                    data-type="info"
                >
                    {presenter.avatarData.initials}
                </span>
            </SimpleTooltip>
        );
    }

    function renderFileUpload() {
        return (
            <input
                type="file"
                name="files[]"
                style={{display: 'none'}}
                disabled={presenter.isDisabled}
                onChange={(e) => presenter.onFileChange(e)}
            />
        );
    }

    return (
        <div
            className={classNames('list-item-attachment list-item-attachment-iterator', {
                uploaded: presenter.filename !== null,
                'list-item-attachment-should-refresh': presenter.showRetryButton,
                'list-item-attachment-upload-context': isUploadContext,
                'list-item-attachment-edit-context': isEditContext || presenter.hasOpenField,
                'list-item-attachment-will-change-from-list': presenter.willChangeFromList,
                'list-item-attachment-did-change-from-list': presenter.didChangeFromList,
                'list-item-attachment-multiple-avatar': presenter.hasUserAvatar,
            })}
        >
            {renderFileData(iterationAnswer, showForm)}
            {renderButtons(iterationAnswer, showDeleteButton)}
            {renderProgressBar(iterationAnswer)}
        </div>
    );
});
