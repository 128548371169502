import {Observable, Subject} from 'rxjs';

import {StatusLabelType} from '../appraise/ui/container/status_label';

export enum Type {
    Danger,
    Success,
}

export interface FlashMessageBroadcaster {
    stream: Observable<FlashMessage>;

    broadcast(message: string | React.ReactNode, type: Type, label?: FlashMessageLabel | null): void;
}

export interface FlashMessage {
    content: string | React.ReactNode;
    type: Type;
    date: Date;
    label?: FlashMessageLabel | null;
}

export interface FlashMessageLabel {
    content: string;
    type: StatusLabelType;
}

export class DefaultFlashMessageBroadcaster implements FlashMessageBroadcaster {
    public stream = new Subject<FlashMessage>();

    public broadcast(message: string | React.ReactNode, type: Type, label?: FlashMessageLabel | null): void {
        this.stream.next({
            content: message,
            type: type,
            date: new Date(),
            label: label,
        });
    }
}
