import React from 'react';
import {Appraisal} from '../appraising/models/appraisal';
import moment from 'moment';
import 'moment/locale/nl';
moment.locale('nl');

interface OwnProps {
    appraisal: Appraisal;
    imageUrl: string | null;
}

export function AppraisalDetails({appraisal, imageUrl}: OwnProps) {
    return (
        <ul className="list-circles mt-2 mb-2 popup-card-appraisal-details">
            <li className="list-item list-item-labelled">
                <span className="list-avatar">
                    <img src={imageUrl ?? ''} />
                </span>
                <span className="list-title">
                    <strong>
                        {appraisal.address} {appraisal.houseNumber} {appraisal.letter}
                    </strong>
                    <span className="thin">
                        - {appraisal.postalCode} {appraisal.city}
                    </span>
                </span>
                <small className="list-details">
                    <span>Aangemaakt op {moment(appraisal.createdAt).format('D MMMM YYYY')}</span>
                </small>
            </li>
        </ul>
    );
}
