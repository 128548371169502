import {Appraisal} from '../../../../../../../../../models/appraisal';
import {BuildYearProvider} from '../../../../../../../../../business/build_year_provider';
import {ExplanationData} from './explanation_data';
import {ValuationProvider} from '../../../../../../../../../business/valuation_provider';

export interface ExplanationDataProvider {
    get(
        gebruiksoppervlakteWonen: number | null,
        perceelOppervlakte: number | null,
        brutoInhoud: number | null,
        energielabel: string | null
    ): ExplanationData;
}

export class DefaultExplanationDataProvider implements ExplanationDataProvider {
    constructor(
        private appraisal: Appraisal,
        private buildYearProvider: BuildYearProvider,
        private valuationProvider: ValuationProvider
    ) {}

    public get(
        gebruiksoppervlakteWonen: number | null,
        perceelOppervlakte: number | null,
        brutoInhoud: number | null,
        energielabel: string | null
    ): ExplanationData {
        return {
            object: {
                objectType: this.appraisal.objectType,
                buildYear: this.buildYearProvider.get(),
                floorArea: gebruiksoppervlakteWonen ?? null,
                plotArea: perceelOppervlakte ?? null,
                volume: brutoInhoud ?? null,
                energyLabel: energielabel ?? null,
            },
            appraisal: {
                valuation: this.valuationProvider.get(),
                appraiseModel: this.appraisal.appraiseModel,
                addressLine: `${this.appraisal.address} ${this.appraisal.houseNumber} ${this.appraisal.letter}`,
            },
        };
    }
}
