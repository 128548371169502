import {makeObservable, observable, runInAction} from 'mobx';

import {CompositeSubscription} from '../../../../../support/composite_subscription';
import {Presenter} from '../../../../../support/presenter/presenter';
import {
    AttachmentPair,
    AttachmentPairWithDisplayName,
    AttachmentQuestionsInteractor,
} from '../../../../business/attachment_questions_interactor';
import {AppraiseSecondaryConfigStackInteractor} from '../../../../business/appraise_secondary_config_stack_interactor';
import {AppraiseSecondaryType} from '../../../../models/appraise_secondary_config';

export enum AttachmentsWidgetType {
    ATTACHMENTS = 'attachments',
    CLIENT_ATTACHMENTS = 'client-attachments',
}

export class AttachmentsWidgetPresenter implements Presenter {
    @observable.ref
    public questionAnswerPairs: AttachmentPairWithDisplayName[] = [];

    private subscriptions = new CompositeSubscription();

    constructor(
        private attachmentQuestionsInteractor: AttachmentQuestionsInteractor,
        private appraiseSecondaryConfigStackInteractor: AppraiseSecondaryConfigStackInteractor
    ) {
        makeObservable(this);
    }

    public mount() {
        this.subscriptions.add(
            this.attachmentQuestionsInteractor.attachments().subscribe((pairs: AttachmentPairWithDisplayName[]) => {
                runInAction(() => {
                    this.questionAnswerPairs = pairs;
                });
            })
        );
    }

    public unmount() {
        this.subscriptions.clear();
    }

    public setActiveAttachment = (name: string | null, url: string | null, pair?: AttachmentPair) => {
        this.appraiseSecondaryConfigStackInteractor.upsert({
            id: `attachment-${pair?.answer.uuid}`,
            type: AppraiseSecondaryType.ATTACHMENT_PREVIEW_WIDGET,
            displayName: name,
            url: url,
            onClose: () => null,
        });

        if (pair) {
            this.attachmentQuestionsInteractor.markVisited(pair.answer.uuid);
        }
    };
}
