import * as React from 'react';

import {
    SimpleQuestionPresenterProps,
    simpleQuestionPresenterConstructorParametersFactory,
} from '../simple/simple_question_presenter';

import {ValidationError} from '../../../../../models/validation_error';
import {ValidationMessageMap} from '../../../../../business/validation/validation_message';
import {WidgetGroupPresenter} from './widget_group_presenter';
import {classNames} from '../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps extends SimpleQuestionPresenterProps {
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
}

export const WidgetGroup: React.FC<OwnProps> = observer(function WidgetGroup(props) {
    const presenter = usePresenter(
        (container) =>
            new WidgetGroupPresenter(
                props.validationErrors,
                props.validationMessages,
                props.pagePartsSet,
                props.activePagePart,
                container.business.modalOrSecondaryConfigStackInteractor,
                container.business.childQuestionValidationProvider(
                    props.appraisal,
                    props.questionSet,
                    props.validationErrors
                ),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );

    if (presenter.isHidden) {
        return null;
    }
    return (
        <>
            <button
                className={classNames('btn btn-secondary btn-sm ion-md-document mr-1', {
                    pulse: presenter.shouldShowPulse,
                })}
                onClick={() => presenter.toggleWidgetContainer()}
            >
                {props.question.contents}
            </button>
        </>
    );
});
