import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {Loader} from '../../components/loader';
import {NwwiControlReportWidgetPresenter} from './nwwi_control_report_widget_presenter';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {PreValidationConclusiongState} from '../../../../business/validation/validation_conclusion_provider';
import {QuestionSet} from '../../../../models/question_set';
import {ValidationError} from '../../../../models/validation_error';
import {ValidationErrorsList} from '../../content/validation_errors_list';
import {observer} from 'mobx-react';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {usePresenter} from '../../../../../support/presenter/use_presenter';

export interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    pagePartsSet: PagePartsSet | null;
    validationErrors: ValidationError[];
    renderingContext: RenderingContextType;
}

export const NwwiControlReportWidget: React.FC<OwnProps> = observer(function NwwiControlReportWidget(props) {
    const presenter = usePresenter(
        (container) =>
            new NwwiControlReportWidgetPresenter(
                container.business.validationConclusionProvider(
                    props.appraisal,
                    props.questionSet,
                    props.pagePartsSet,
                    props.validationErrors,
                    props.renderingContext
                )
            )
    );

    if (presenter.validationConclusion === null) {
        return null;
    }

    const preValidationErrors = presenter.validationConclusion.preValidationErrors;

    return (
        <div className="col-12">
            <div className="widget widget-control-report">
                <div className="widget-title">
                    <h3>
                        <span className="ion-md-today" />
                        Controleverslag
                    </h3>
                    {preValidationErrors.errors.length > 0 && (
                        <button
                            className="btn btn-sm btn-default ion-md-refresh float-right"
                            onClick={() => presenter.refresh()}
                            disabled={preValidationErrors.state !== PreValidationConclusiongState.PROVIDED}
                        >
                            {preValidationErrors.state !== PreValidationConclusiongState.PROVIDED ? (
                                <Loader />
                            ) : (
                                'Refresh'
                            )}
                        </button>
                    )}
                </div>
                <div className="widget-body">
                    {preValidationErrors.errors.length === 0 ? (
                        <p>Er zijn geen aandachtspunten vanuit het validatie-instituut.</p>
                    ) : (
                        <>
                            <p>De volgende aandachtspunten zijn er nog vanuit het validatie-instituut:</p>
                            <ValidationErrorsList
                                validationErrors={preValidationErrors.errors}
                                questionSet={props.questionSet}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});
