import * as React from 'react';

import {Link, useHistory} from 'react-router-dom';

import {Appraisal} from '../../../../models/appraisal';
import {QuestionSet} from '../../../../models/question_set';
import {RenderingContextType} from '../../../../enum/rendering_context_type';
import {SidebarItem} from '../../../../business/sidebar_tree_builder';
import {SidebarItemPresenter} from '../sidebar/sidebar_item_presenter';
import {ValidationError} from '../../../../models/validation_error';
import {classNames} from '../../../../../support/classnames';
import {observer} from 'mobx-react';
import {PagePartsSet} from '../../../../models/page_parts_set';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {isRootQuestionType} from '../../../../../support/is_root_question_type';

interface OwnProps {
    pagePartsSet: PagePartsSet | null;
    sidebarItem: SidebarItem;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    renderingContext: RenderingContextType;
    widthPercentage?: number;
}

export const SidebarTabItem: React.FC<OwnProps> = observer((props) => {
    const history = useHistory();
    const presenter = usePresenter(
        (container) =>
            new SidebarItemPresenter(
                props.questionSet,
                props.sidebarItem,
                props.pagePartsSet,
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.locationProvider(history),
                container.business.floorInteractor(props.appraisal, props.questionSet, props.renderingContext),
                container.business.questionValidationMessagesProvider(
                    props.appraisal,
                    props.questionSet,
                    props.validationErrors
                )
            )
    );

    function renderItem(item: SidebarItem) {
        return (
            <div
                key={item.link}
                className={classNames('nav-item', {active: presenter.isActive(item)})}
                style={{width: props.widthPercentage ? props.widthPercentage + '%' : 'auto'}}
            >
                <Link to={item.link} className="nav-link">
                    {item.label}
                </Link>
            </div>
        );
    }

    if (props.sidebarItem.question?.type && isRootQuestionType(props.sidebarItem.question?.type)) {
        return renderItem(props.sidebarItem);
    }

    return props.sidebarItem.children.map((child) => renderItem(child));
});
