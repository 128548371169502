import * as React from 'react';

import {Appraisal} from '../../../models/appraisal';
import {QuestionSet} from '../../../models/question_set';
import {observer} from 'mobx-react';
import {PagePartsSet} from '../../../models/page_parts_set';
import {usePresenter} from '../../../../support/presenter/use_presenter';
import {AppraisalCopyPresenter} from './appraisal_copy_presenter';
import {Loader} from '../components/loader';
import {formatDate} from '../support/format_date';
import {RenderingContextType} from '../../../enum/rendering_context_type';

interface OwnProps {
    questionSet: QuestionSet;
    appraisal: Appraisal;
    renderingContext: RenderingContextType;
    pagePartsSet: PagePartsSet | null;
}

export const AppraisalCopy: React.FC<OwnProps> = observer(function AppraisalCopy(props) {
    const presenter = usePresenter(
        (container) =>
            new AppraisalCopyPresenter(
                props.appraisal,
                props.questionSet,
                container.network.appraisalApi,
                container.business.macroInteractor(props.questionSet),
                container.business.answerController(props.appraisal, props.questionSet),
                container.business.answerPathStubber(props.appraisal, props.questionSet, props.renderingContext),
                container.business.flashMessageBroadcaster
            )
    );

    function renderSearch() {
        return (
            <>
                <p>Zoek naar een andere taxatie om gegevens van te kopiëren.</p>
                <div className="card-search">
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Zoek op adres, postcode of plaats"
                            className="form-control"
                            onChange={(ev) => presenter.onSearchAppraisal(ev.target.value)}
                            onKeyDown={(ev) =>
                                ev.key === 'Enter' ? presenter.onSearchAppraisal(undefined, true) : null
                            }
                        />
                    </div>
                    <button className="btn-search" onClick={() => presenter.onSearchAppraisal(undefined, true)}>
                        <span className="ion-md-search"></span>
                    </button>
                </div>

                {presenter.isSearchingAppraisals ? (
                    <div className="empty-message mt-5">
                        <Loader />
                        <h3>Zoeken naar taxaties...</h3>
                    </div>
                ) : presenter.appraisalFillValues !== null && presenter.appraisalFillValues.length === 0 ? (
                    <div className="empty-message mt-5">
                        <span>
                            Er zijn geen gegevens gevonden voor deze taxatie.
                            <br />
                            Mogelijk is deze taxatie nog niet uitgewerkt, of is deze taxatie uitgewerkt voor een ander
                            model.
                        </span>
                        <span className="spacer-gradient"></span>
                    </div>
                ) : presenter.searchAppraisals !== null && presenter.searchAppraisals.length > 0 ? (
                    <>
                        <ul
                            className="list-circles mt-2"
                            style={presenter.isAppraisalSearchPending ? {opacity: 0.6} : undefined}
                        >
                            {presenter.searchAppraisals.map((appraisal) => (
                                <li
                                    className="list-item ion-md-arrow-forward"
                                    key={appraisal.id}
                                    onClick={() => presenter.getAppraisalValues(appraisal.id)}
                                >
                                    <span className="list-avatar">
                                        <img src={appraisal.streetviewUrl} />
                                    </span>
                                    <span className="list-title">
                                        <strong>
                                            {appraisal.address} {appraisal.houseNumber} {appraisal.letter}
                                        </strong>
                                        <span className="thin">
                                            - {appraisal.postalCode} {appraisal.city}
                                        </span>
                                    </span>
                                    <small className="list-details">
                                        <span>Aangemaakt op {formatDate(appraisal.createdAt, 'dd-MM-yyyy')}</span>
                                    </small>
                                </li>
                            ))}
                        </ul>
                    </>
                ) : (
                    <div className="empty-message mt-5">
                        <h3>
                            {presenter.searchAppraisals === null
                                ? 'Voer een adres in om taxaties te zoeken'
                                : 'Geen taxaties gevonden'}
                        </h3>
                        <span className="spacer-gradient"></span>
                    </div>
                )}
            </>
        );
    }

    function renderValues() {
        return (
            <>
                <div className="form-group d-flex justify-content-between align-items-center">
                    <p className="mb-0">Kies de gegevens die je over wil nemen.</p>
                    <input
                        type="checkbox"
                        id="select-all-values"
                        checked={(presenter.appraisalFillValues ?? []).length === presenter.selectedFillValues.length}
                        onClick={() =>
                            presenter.appraisalFillValues === presenter.selectedFillValues
                                ? presenter.setSelectedFillValues([])
                                : presenter.setSelectedFillValues(presenter.appraisalFillValues ?? [])
                        }
                    />
                    <label htmlFor="select-all-values" className="label-align-right d-flex">
                        Selecteer alles
                    </label>
                </div>
                <ul
                    className="list-circles mt-2"
                    style={presenter.isAppraisalSearchPending ? {opacity: 0.6} : undefined}
                >
                    {presenter.appraisalFillValues?.map((values, i) => (
                        <li className="list-item " key={i} onClick={() => null}>
                            <span className="list-avatar ion-md-reorder" />
                            <span className="list-title">
                                <strong>{props.questionSet.findQuestionByUuid(values.questionUuid)?.contents}</strong>
                                {values.name ? <span className="thin">&nbsp;- {values.name}</span> : null}
                                <span className="list-form-element">
                                    <input
                                        type="checkbox"
                                        name={`appraisal-value-${i}`}
                                        id={`appraisal-value-${i}`}
                                        checked={presenter.selectedFillValues.includes(values)}
                                        onClick={() =>
                                            presenter.setSelectedFillValues(
                                                presenter.selectedFillValues.includes(values)
                                                    ? presenter.selectedFillValues.filter((v) => v !== values)
                                                    : presenter.selectedFillValues.concat([values])
                                            )
                                        }
                                    />
                                    <label htmlFor={`appraisal-value-${i}`}></label>
                                </span>
                            </span>
                        </li>
                    ))}
                </ul>
                <footer className="d-flex justify-content-end">
                    <div className="d-flex justify-content-end w-100 overflow-hidden">
                        <button className="btn btn-secondary" onClick={() => presenter.clearAppraisalValues()}>
                            Terug
                        </button>
                        <button className="btn btn-primary" onClick={() => presenter.copySelectedValues()}>
                            Kopiëren
                        </button>
                    </div>
                </footer>
            </>
        );
    }

    return (
        <span>
            <div className="card-header">Kopiëren</div>
            <div className="card-body">
                {presenter.isLoadingAppraisalValues ? (
                    <div className="empty-message mt-5">
                        <Loader />
                        <h3>Gegevens ophalen...</h3>
                    </div>
                ) : presenter.appraisalFillValues && presenter.appraisalFillValues.length > 0 ? (
                    renderValues()
                ) : (
                    renderSearch()
                )}
            </div>
        </span>
    );
});
