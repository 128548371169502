import * as React from 'react';

import {ActiveSetDefinition} from '../reference_objects_question_presenter';
import {Appraisal} from '../../../../../../../../models/appraisal';
import {PagePart} from '../../../../../../../../models/page_part';
import {PagePartsSet} from '../../../../../../../../models/page_parts_set';
import {QuestionContainer} from '../../../../question_container';
import {QuestionSet} from '../../../../../../../../models/question_set';
import {QuestionType} from '../../../../../../../../enum/question_type';
import {RenderingContextType} from '../../../../../../../../enum/rendering_context_type';
import {SuggestedReferenceObjectAnswer} from '../calculators/suggested_reference_object_answers_calculator';
import {TechnicalReference} from '../../../../../../../../enum/technical_reference';
import {ValidationError} from '../../../../../../../../models/validation_error';
import {ValidationMessageMap} from '../../../../../../../../business/validation/validation_message';
import {ValuationAnswersContainerPresenter} from './valuation_answers_container_presenter';
import {classNames} from '../../../../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {uppercaseFirstChar} from '../../../../../../../../../support/string';
import {usePresenter} from '../../../../../../../../../support/presenter/use_presenter';
import {SimpleTooltip} from '../../../../../../../../components/tooltip';
import {QuestionRenderingData} from '../../../../../../../../models/question_rendering_data';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    valuationAnswersChanged: boolean;
    disabled?: boolean;
    suggestedReferenceObjectAnswers: SuggestedReferenceObjectAnswer[];
    onAddMany: (suggestedReferenceObjectAnswer: SuggestedReferenceObjectAnswer) => void;
    hiddenQuestionTypes: QuestionType[];
    activeSetDefinition: ActiveSetDefinition;
    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const ValuationAnswersContainer: React.FC<OwnProps> = observer(function ValuationAnswersContainer(props) {
    const presenter = usePresenter(
        (container) =>
            new ValuationAnswersContainerPresenter(
                props.questionSet,
                container.business.adaptedValueProvider(props.appraisal, props.questionSet),
                props.onAddMany
            )
    );

    //Is marktwaarde?
    const isMarketValueSet =
        props.activeSetDefinition.setDefinition.groupTree.item.question.technicalReference ===
        TechnicalReference.VALUATION_MARKET_VALUE_GROUP;

    //Do we show copy options from other sets?
    const shouldShowCopyButton =
        props.activeSetDefinition.referenceObjectAnswers.length === 0 &&
        props.suggestedReferenceObjectAnswers.length > 0;

    function renderSet(activeSetDefinition: ActiveSetDefinition) {
        const specialValueData = presenter.specialValueData(activeSetDefinition);
        if (specialValueData === null) {
            return null;
        }

        const adaptedValuesMap = presenter.defaultAdaptedAnswers(props.activeSetDefinition);
        const {questions, iteration, parentAnswerUuid} = specialValueData;

        // Normally the iteration is used, but these questions have no iteration. So React can't switch because the key's
        // are the same. The valuationType is unique so we use this as unique key.
        const key = props.activeSetDefinition.setDefinition.valuationType;

        return (
            <>
                <div className="col-12">
                    {questions.map((question) => {
                        return (
                            <QuestionContainer
                                key={question.uuid + '[' + key + ']' + 'iteration'}
                                iteration={iteration !== null ? iteration : undefined}
                                appraisal={props.appraisal}
                                question={question}
                                questionSet={props.questionSet}
                                validationErrors={props.validationErrors}
                                validationMessages={props.validationMessages}
                                forceShowValidationMessages={props.forceShowValidationMessages}
                                parentAnswerUuid={parentAnswerUuid}
                                disabled={props.disabled}
                                adaptedDefaultValues={adaptedValuesMap}
                                disableAdaptedValueContainer={true}
                                hiddenQuestionTypes={props.hiddenQuestionTypes}
                                pagePartsSet={props.pagePartsSet}
                                activePagePart={props.activePagePart}
                                renderingContext={props.renderingContext}
                                questionRenderingData={props.questionRenderingData}
                            />
                        );
                    })}
                </div>
            </>
        );
    }

    function renderMutedFieldsForActiveSet(activeSetDefinition: ActiveSetDefinition) {
        return (
            <div className="col-12">
                <div>
                    <h3>Objectkenmerken</h3>
                </div>
                <div className="form-columns">
                    <div className="row">
                        {presenter.marketValuationQuestions(activeSetDefinition).map((q) => {
                            return (
                                <div key={q.question.uuid} className="col-12 col-md-6">
                                    <div className="form-group">
                                        <div className="label-container">
                                            <label>{q.question.contents}</label>
                                        </div>
                                        <SimpleTooltip
                                            content={q.question.contents + ' kun je hier niet aanpassen.'}
                                            placement="top"
                                        >
                                            <div className="input-group input-group-no-prepend" data-type="error">
                                                <input
                                                    type="text"
                                                    disabled
                                                    className="form-control disabled"
                                                    value={q.value ?? '-'}
                                                />
                                            </div>
                                        </SimpleTooltip>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
    return (
        <>
            <div
                className={classNames('col-12', {
                    'col-sm-5 col-md-7 col-lg-6': shouldShowCopyButton,
                    'col-md-7': !shouldShowCopyButton,
                })}
            >
                <h2 className="reference-objects-nav-title">
                    <span className="badge badge-secondary float-left">
                        {uppercaseFirstChar(props.activeSetDefinition.setDefinition.type)}
                    </span>
                    <span>&nbsp;</span>
                    {props.activeSetDefinition.setDefinition.name
                        ? props.activeSetDefinition.setDefinition.name
                        : isMarketValueSet
                        ? 'Marktwaarde'
                        : 'Bijzonder uitgangspunt'}
                    {!isMarketValueSet && <span>{props.activeSetDefinition.setDefinition.description}</span>}
                </h2>
                {props.activeSetDefinition.setDefinition.description ? (
                    <h3 className="reference-objects-nav-subtitle">
                        {props.activeSetDefinition.setDefinition.description}
                    </h3>
                ) : (
                    isMarketValueSet && <h3 className="reference-objects-nav-subtitle">Marktwaarde in huidige staat</h3>
                )}
            </div>
            <div
                className={classNames('col-12', {
                    'col-sm-7 col-md-5 col-lg-6': shouldShowCopyButton,
                    'col-md-5': !shouldShowCopyButton,
                })}
            >
                <div className="reference-objects-nav-btn-container">
                    {shouldShowCopyButton ? (
                        <div className="select-with-copy">
                            <select
                                name="set-select"
                                id="set-select"
                                className="form-control custom-select custom-select-sm"
                                disabled={props.disabled}
                                value={presenter.selectedCopyValuationType ?? 'disabled'}
                                onChange={(e) => presenter.setSelectedCopyValuationType(e.target.value)}
                            >
                                <option value="disabled" disabled>
                                    Kies waardering
                                </option>
                                {props.suggestedReferenceObjectAnswers.map((suggestedReferenceObjectAnswer) => {
                                    return (
                                        <option
                                            key={suggestedReferenceObjectAnswer.setDefinition.title}
                                            value={suggestedReferenceObjectAnswer.setDefinition.valuationType}
                                        >
                                            {suggestedReferenceObjectAnswer.setDefinition.title} (
                                            {suggestedReferenceObjectAnswer.answers.length})
                                        </option>
                                    );
                                })}
                            </select>
                            <button
                                className="btn btn-sm btn-append btn-secondary ion-md-copy"
                                disabled={props.disabled || presenter.selectedCopyValuationType === null}
                                onClick={() => presenter.onAddMany(props.suggestedReferenceObjectAnswers)}
                            >
                                Kopieër
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>

            <div className="reference-set-valuation-questions-container">
                {isMarketValueSet
                    ? renderMutedFieldsForActiveSet(props.activeSetDefinition)
                    : renderSet(props.activeSetDefinition)}
            </div>
        </>
    );
});
