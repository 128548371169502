import {BuildYearProvider} from '../../../../../../../../../../business/build_year_provider';
import {ReferenceObject} from '../../../models/reference_object';
import {ReferenceObjectSortingStrategy} from './reference_object_sorting_strategy';
import {SortingDirection} from '../../../../../../../../../../enum/reference_objects_sorting';

interface ReferenceObjectWithRequiredAttributes extends ReferenceObject {
    buildYear: number;
    lotArea: number;
    floorArea: number;
}

export class ReferenceObjectSorterByDeviationScore implements ReferenceObjectSortingStrategy {
    constructor(
        private buildYearProvider: BuildYearProvider,
        private gebruiksoppervlakteWonen: number | null,
        private perceelOppervlakte: number | null
    ) {}

    public sortReferenceObjects(
        referenceObjects: ReferenceObject[],
        sortingDirection: SortingDirection
    ): ReferenceObject[] {
        // make array of custom reference sales
        const customReferenceObjects = referenceObjects.filter((referenceObject: ReferenceObject) =>
            referenceObject.id.includes('custom')
        );

        // make array of reference sales with all the required properties
        const referenceObjectsWithRequiredAttributes = referenceObjects.filter(
            (referenceObject: ReferenceObject): referenceObject is ReferenceObjectWithRequiredAttributes =>
                !referenceObject.id.includes('custom') &&
                referenceObject.buildYear !== null &&
                referenceObject.plotArea !== null &&
                referenceObject.floorArea !== null
        );

        // make array of reference sales with at least one of the required attributes missing
        const referenceObjectsWithoutNormalizedSaleDate = referenceObjects.filter(
            (referenceObject: ReferenceObject) =>
                !referenceObject.id.includes('custom') &&
                referenceObject.buildYear === null &&
                referenceObject.plotArea === null &&
                referenceObject.floorArea === null
        );

        // sort the reference objects with all required attributes
        const sortedReferenceObjects: ReferenceObject[] = referenceObjectsWithRequiredAttributes.sort(
            (referenceObject1, referenceObject2) => {
                return this.getDeviationScoreWithAppraisalForReferenceObject(referenceObject1) >
                    this.getDeviationScoreWithAppraisalForReferenceObject(referenceObject2)
                    ? sortingDirection === SortingDirection.DESCENDING
                        ? 1
                        : -1
                    : sortingDirection === SortingDirection.DESCENDING
                    ? -1
                    : 1;
            }
        );

        // put the invalid reference sales at the end
        return [...customReferenceObjects, ...sortedReferenceObjects, ...referenceObjectsWithoutNormalizedSaleDate];
    }

    private getDeviationScoreWithAppraisalForReferenceObject(
        referenceObject: ReferenceObjectWithRequiredAttributes
    ): number {
        let appraisalBuildYear = this.buildYearProvider.getDestructionYear();
        if (appraisalBuildYear === null) {
            appraisalBuildYear = this.buildYearProvider.get();
        }

        // cannot be null as the reference object view cannot be opened when build year is not known
        if (appraisalBuildYear === null || this.gebruiksoppervlakteWonen === null || this.perceelOppervlakte === null) {
            return 0;
        }

        const buildYearDeviationScore = this.getBuildYearDeviationScore(appraisalBuildYear, referenceObject.buildYear);
        const floorAreaDeviationScore = this.getAreaDeviationScore(
            this.gebruiksoppervlakteWonen,
            referenceObject.floorArea
        );
        const lotAreaDeviationScore = this.getAreaDeviationScore(this.perceelOppervlakte, referenceObject.lotArea);

        return buildYearDeviationScore + floorAreaDeviationScore + lotAreaDeviationScore;
    }

    private getBuildYearDeviationScore(appraisalBuildYear: number, referenceObjectBuildYear: number): number {
        const difference = Math.abs(appraisalBuildYear - referenceObjectBuildYear);
        const yearWeight = 0.25;
        return Math.min(5, difference * yearWeight);
    }

    private getAreaDeviationScore(appraisalArea: number, referenceObjectArea: number): number {
        const difference = Math.abs(appraisalArea - referenceObjectArea);
        const areaWeight = 0.166666666;
        return Math.min(5, difference * areaWeight);
    }
}
