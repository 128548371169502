import {Presenter} from '../../../../support/presenter/presenter';
import {makeObservable, observable, runInAction} from 'mobx';
import {AppraisalApi} from '../../../network/appraisal_api';
import {Appraisal} from '../../../models/appraisal';
import {AppraisalRecentAction} from '../../../models/appraisal_recent_action';

export class AppraisalRecentUserActionsPresenter implements Presenter {
    @observable.ref public appraisalRecentActions: AppraisalRecentAction[] = [];
    private timer: number | null = null;

    constructor(private appraisal: Appraisal, private appraisalApi: AppraisalApi) {
        makeObservable(this);
    }

    public get appraisalRecentActionsSentence(): string | null {
        if (this.appraisalRecentActions.length === 0) {
            return null;
        }
        const userNames = this.appraisalRecentActions.map((a) => a.userName).join(', ');

        return 'Er zijn vragen recent bewerkt door ' + userNames + '.';
    }

    public get appraisalRecentActionsQuestionSentence(): string | null {
        if (this.appraisalRecentActions.length === 0) {
            return null;
        }

        const questionContents = this.appraisalRecentActions.map((a) => '"' + a.questionContent + '"').join(', ');

        return 'Recent aangepaste vragen: ' + questionContents + '.';
    }

    public mount(): void {
        // Check opn mount and each 5 minutes for changes from other users
        this.checkRecentUserActionsList();
        this.timer = window.setInterval(() => this.checkRecentUserActionsList(), 300000);
    }

    private checkRecentUserActionsList() {
        this.appraisalApi.recentUserActionsList(this.appraisal.id).then((appraisalRecentActions) => {
            runInAction(() => {
                this.appraisalRecentActions = appraisalRecentActions;
            });
        });
    }

    public unmount(): void {
        this.timer !== null ? window.clearInterval(this.timer) : null;
    }
}
