import * as React from 'react';

import {Appraisal} from '../../../../../models/appraisal';
import {DecimalNumberInput} from '../components/decimal_number';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {SurfaceCalculatorQuestionPresenter} from './surface_calculator_question_presenter';
import {isEmpty} from '../../../../../../support/util';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps {
    question: Question;
    parentAnswerUuid?: string;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    iteration?: string;
    disabled?: boolean;
}

export const SurfaceCalculatorQuestion: React.FC<OwnProps> = observer(function SurfaceCalculatorQuestion(props) {
    const presenter = usePresenter(
        (container) =>
            new SurfaceCalculatorQuestionPresenter(
                props.question,
                props.iteration,
                props.parentAnswerUuid,
                props.appraisal,
                container.business.userInteractor,
                container.business.questionEffectsInteractor(props.appraisal, props.questionSet),
                container.business.answerController(props.appraisal, props.questionSet)
            )
    );

    if (presenter.isHidden) {
        return null;
    }
    return (
        <div>
            <div className="form-columns">
                <div className="row">
                    <div className="col-6 col-sm-4">
                        <DecimalNumberInput
                            name="length"
                            label="Lengte (m)"
                            value={presenter.length}
                            disabled={props.disabled || presenter.isDisabled}
                            onChange={(value) => presenter.setLength(isEmpty(value) ? null : value)}
                        />
                    </div>
                    <div className="col-6 col-sm-4">
                        <DecimalNumberInput
                            name="width"
                            label="Breedte (m)"
                            value={presenter.width}
                            disabled={props.disabled || presenter.isDisabled}
                            onChange={(value) => presenter.setWidth(isEmpty(value) ? null : value)}
                        />
                    </div>
                    <div className="col-12 col-sm-4">
                        <DecimalNumberInput
                            name="total"
                            label="Totaal (m²)"
                            value={presenter.total}
                            disabled={true}
                            onChange={(value) => presenter.setTotal(isEmpty(value) ? null : value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});
