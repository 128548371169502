import React from 'react';

import {AttachmentQuestionProps} from '../attachment_question_presenter';
import {PhotoQuestionPresenter} from './photo_question_presenter';
import {QuestionContainer} from '../../question_container';
import {findChildrenForQuestion} from '../../../../support/question_filtering';
import {observer} from 'mobx-react';
import {simpleQuestionPresenterConstructorParametersFactory} from '../../simple/simple_question_presenter';
import {usePresenter} from '../../../../../../../support/presenter/use_presenter';
import {PhotoQuestionImage} from './photo_question_image';

export const PhotoQuestion: React.FunctionComponent<AttachmentQuestionProps> = observer(function PhotoQuestion(props) {
    const presenter = usePresenter(
        (container) =>
            new PhotoQuestionPresenter(
                props.fileTypes,
                container.business.blobCacheInteractor(),
                container.business.imageUploadInteractor(props.appraisal, props.questionSet),
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.attachmentQuestionsInteractor(props.appraisal, props.questionSet),
                container.business.flashMessageBroadcaster,
                container.globalProvider,
                container.business.automatorInteractor(props.appraisal, props.questionSet),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );

    const {answer, uncompressedUrl, url, loading, isHidden, showError, showRetryButton, retry} = presenter;
    if (isHidden) {
        return null;
    }

    return (
        <>
            <div className="form-grid-item-img" data-answer-uuid={answer?.uuid ?? null}>
                <div className="form-grid-item-img-content">
                    <div className="img-wrap">
                        {url ? (
                            <PhotoQuestionImage
                                url={uncompressedUrl ?? url}
                                isLoading={loading}
                                showRetryButton={showRetryButton}
                                isDisabled={props.disabled ?? false}
                            />
                        ) : null}
                        {showError ? (
                            <>
                                <span className="img-danger">
                                    <span className="img-danger-content split-top ion-md-alert">Missende foto</span>
                                </span>
                                <a className="img-retry-duplicate" onClick={() => retry()}>
                                    <span className="img-retry-content split-bottom ion-md-refresh">Opnieuw</span>
                                </a>
                            </>
                        ) : showRetryButton ? (
                            <a className="img-retry" onClick={() => retry()}>
                                <span className="img-retry-content ion-md-refresh">Opnieuw</span>
                            </a>
                        ) : null}
                        {loading ? (
                            <span className="img-uploading">
                                <span className="img-uploading-content ion-md-cloud-upload">Uploading</span>
                            </span>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="form-grid-item-body">
                <div className="form-group">
                    {answer &&
                        findChildrenForQuestion(props.question, props.questionSet).map((child) => (
                            <QuestionContainer
                                key={child.uuid}
                                disabled={loading || props.disabled}
                                appraisal={props.appraisal}
                                question={child}
                                questionSet={props.questionSet}
                                parentAnswerUuid={answer.uuid}
                                validationErrors={props.validationErrors}
                                validationMessages={props.validationMessages}
                                forceShowValidationMessages={props.forceShowValidationMessages}
                                hiddenQuestionTypes={props.hiddenQuestionTypes}
                                pagePartsSet={props.pagePartsSet}
                                activePagePart={props.activePagePart}
                                renderingContext={props.renderingContext}
                                questionRenderingData={props.questionRenderingData}
                            />
                        ))}
                </div>
            </div>
        </>
    );
});
