import {BehaviorSubject, Observable} from 'rxjs';

import {Macro, MacroExternalType, SuperMacro, SuperMacroValueMap} from '../models/macro';
import {MacroApi} from '../network/macro_api';
import {MacroSettings} from '../models/macro_settings';
import {QuestionSet} from '../models/question_set';
import {map, switchMap} from 'rxjs/operators';
import {Global} from '../../business/global_provider';
import {BuildPeriodType} from '../enum/build_period_type';
import {ObjectType} from '../enum/object_type';
import {AppraisalGoal} from '../enum/appraisal_goal';
import {StubberValuesMap} from './answering/answer_path_stubber';

export interface MacroInteractor {
    macrosForQuestionUuid(questionUuid: string): Observable<Macro[]>;
    getMacrosForQuestionUuid(questionUuid: string): Macro[];
    macrosForExternalType(externalType: MacroExternalType): Observable<Macro[]>;

    getAutofillFavorites(questionUuid: string): Macro[];

    hasAutofillFavorites(questionUuid: string): boolean;

    store(contents: string, questionUuid: string): Promise<Macro>;

    storeSuper(
        name: string,
        values: StubberValuesMap,
        questionUuid: string,
        districtCodes: string[] | null,
        buildPeriods: BuildPeriodType[] | null,
        objectTypes: ObjectType[] | null,
        appraisalGoals: AppraisalGoal[] | null,
        newConstruction: boolean | null
    ): Promise<SuperMacro>;

    storeSettings(
        macroId: number,
        districtCodes: string[] | null,
        buildPeriods: BuildPeriodType[] | null,
        objectTypes: ObjectType[] | null,
        appraisalGoals: AppraisalGoal[] | null,
        newConstruction: boolean | null
    ): Promise<MacroSettings>;

    getSettings(macroId: number): Promise<MacroSettings | null>;

    getSettingsStream(macroId: number): Observable<MacroSettings | null>;

    storeExternal(contents: string, externalType: MacroExternalType): Promise<Macro>;

    destroy(id: number): Promise<void>;

    toggleFavorite(id: number): Promise<void>;

    hideForUser(id: number): Promise<void>;

    getAppraisalValues(appraisalId: number, questionUuids: string[]): Promise<SuperMacroValueMap[]>;
}

export class DefaultMacroInteractor implements MacroInteractor {
    private userDefinedMacros: Macro[];
    private userMacroSettings: MacroSettings[];
    private superMacros: SuperMacro[];

    private events = new BehaviorSubject<Macro | null>(null);

    constructor(private questionSet: QuestionSet, private macroApi: MacroApi, private global: Global) {
        this.userDefinedMacros = questionSet.userDefinedMacros;
        this.userMacroSettings = questionSet.userMacroSettings;
        this.superMacros = questionSet.superMacros;
    }

    public macrosForQuestionUuid(questionUuid: string): Observable<Macro[]> {
        return this.events.pipe(map(() => this.getMacrosForQuestionUuid(questionUuid)));
    }

    public macrosForExternalType(externalType: MacroExternalType): Observable<Macro[]> {
        return this.events.pipe(map(() => this.getMacrosForExternalType(externalType)));
    }

    public getAutofillFavorites(questionUuid: string): Macro[] {
        const macros = this.getMacrosForQuestionUuid(questionUuid);
        return macros.filter((macro) => macro.favorite);
    }

    public hasAutofillFavorites(questionUuid: string): boolean {
        return this.getAutofillFavorites(questionUuid).length > 0;
    }

    public destroy(id: number): Promise<void> {
        this.userDefinedMacros = this.userDefinedMacros.filter((userDefinedMacro) => userDefinedMacro.id !== id);
        this.superMacros = this.superMacros.filter((superMacro) => superMacro.id !== id);

        this.events.next(null);

        return this.macroApi.destroy(id);
    }

    public getSettingsStream(macroId: number): Observable<MacroSettings | null> {
        return this.events.pipe(switchMap(() => this.getSettings(macroId)));
    }

    public async getSettings(macroId: number): Promise<MacroSettings | null> {
        return this.userMacroSettings.find((s) => s.macroId === macroId) ?? null;
    }

    public async toggleFavorite(id: number): Promise<void> {
        const settings = await this.macroApi.toggleFavorite(id);

        this.userMacroSettings = this.userMacroSettings.filter((s) => s.id !== settings.id);
        this.userMacroSettings.push(settings);

        this.events.next(null);
    }

    public async hideForUser(id: number): Promise<void> {
        const settings = await this.macroApi.hideForUser(id);

        this.userMacroSettings = this.userMacroSettings.filter((s) => s.id !== settings.id);
        this.userMacroSettings.push(settings);

        this.events.next(null);
    }

    public async store(contents: string, questionUuid: string): Promise<Macro> {
        const macro = await this.macroApi.store(this.questionSet.id, contents, questionUuid, null);

        this.userDefinedMacros = [...this.userDefinedMacros, macro];
        this.events.next(null);

        return macro;
    }

    public async storeSettings(
        macroId: number,
        districtCodes: string[] | null,
        buildPeriods: BuildPeriodType[] | null,
        objectTypes: ObjectType[] | null,
        appraisalGoals: AppraisalGoal[] | null,
        newConstruction: boolean | null
    ): Promise<MacroSettings> {
        const settings = await this.macroApi.storeSettings(
            macroId,
            districtCodes,
            buildPeriods,
            objectTypes,
            appraisalGoals,
            newConstruction
        );

        this.userMacroSettings = this.userMacroSettings.filter((s) => s.id !== settings.id);
        this.userMacroSettings.push(settings);

        this.events.next(null);

        return settings;
    }

    public async storeExternal(contents: string, externalType: MacroExternalType): Promise<Macro> {
        const macro = await this.macroApi.store(this.questionSet.id, contents, null, externalType);

        this.userDefinedMacros = [...this.userDefinedMacros, macro];
        this.events.next(null);

        return macro;
    }

    public async storeSuper(
        name: string,
        values: StubberValuesMap,
        questionUuid: string,
        districtCodes: string[] | null,
        buildPeriods: BuildPeriodType[] | null,
        objectTypes: ObjectType[] | null,
        appraisalGoals: AppraisalGoal[] | null,
        newConstruction: boolean | null
    ): Promise<SuperMacro> {
        const superMacro = await this.macroApi.storeSuper(
            this.questionSet.id,
            name,
            values,
            questionUuid,
            districtCodes,
            buildPeriods,
            objectTypes,
            appraisalGoals,
            newConstruction
        );

        this.superMacros = [...this.superMacros, superMacro];

        this.events.next(null);

        return Promise.resolve(superMacro);
    }

    public getMacrosForQuestionUuid(questionUuid: string): Macro[] {
        const question = this.questionSet.findQuestionByUuid(questionUuid);

        const macros = [
            ...(question?.macros ?? []),
            ...this.userDefinedMacros.filter((userDefinedMacro) => userDefinedMacro.questionUuid === questionUuid),
            ...this.superMacros.filter((macro) => macro.questionUuid === questionUuid),
        ];

        const favoriteMacros = [];
        const nonFavoriteMacros = [];
        for (const macro of macros) {
            const settings = this.userMacroSettings.find((s) => s.macroId === macro.id);
            if (settings?.hidden) {
                continue;
            }

            if (settings?.favorite) {
                favoriteMacros.push({
                    ...macro,
                    favorite: true,
                });
            } else {
                nonFavoriteMacros.push(macro);
            }
        }

        return [...favoriteMacros, ...nonFavoriteMacros];
    }

    private getMacrosForExternalType(externalType: MacroExternalType): Macro[] {
        const macros = [
            ...(this.global.externalMacros[externalType] ?? []),
            ...this.userDefinedMacros.filter((userDefinedMacro) => userDefinedMacro.externalType === externalType),
        ];

        const favoriteMacros = [];
        const userMacros = [];
        for (const macro of macros) {
            const settings = this.userMacroSettings.find((s) => s.macroId === macro.id);
            if (settings?.hidden) {
                continue;
            }

            if (settings?.favorite) {
                favoriteMacros.push({
                    ...macro,
                    favorite: true,
                });
            } else {
                userMacros.push(macro);
            }
        }

        return [...favoriteMacros, ...userMacros];
    }

    public async getAppraisalValues(appraisalId: number, questionUuids: string[]): Promise<SuperMacroValueMap[]> {
        return this.macroApi.getAppraisalValues(appraisalId, questionUuids);
    }
}
