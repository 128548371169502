import * as React from 'react';
import {Appraisal} from '../../../../../../models/appraisal';
import {Question} from '../../../../../../models/question';
import {QuestionSet} from '../../../../../../models/question_set';
import {RenderingContextType} from '../../../../../../enum/rendering_context_type';
import {AreaSummaryDummy} from './area_summary_dummy';
import {ReportIframeViewer} from '../report_viewer/report_iframe_viewer';
import {GeneratedReportType} from '../../../../../../enum/generated_report';

interface OwnProps {
    question: Question;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    renderingContext: RenderingContextType;
}

export const AreaSummary: React.FunctionComponent<OwnProps> = (props) => {
    if (props.renderingContext === RenderingContextType.PAGE_PARTS_CONFIGURATOR) {
        return <AreaSummaryDummy />;
    }
    return (
        <ReportIframeViewer
            appraisal={props.appraisal}
            questionSet={props.questionSet}
            reportType={GeneratedReportType.UNIFORM_MEASUREMENT}
        />
    );
};
