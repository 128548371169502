/**
 * Gets the start and end index of the needle in the haystack,
 * Only if the needle starts and ends with a newline \n
 * @param haystack
 * @param needle
 */
export function getStringIndices(haystack: string, needle: string): {start: number; end: number} {
    if (haystack === needle) {
        return {
            start: 0,
            end: needle.length,
        };
    }

    if (needle.length === 0) {
        return {
            start: -1,
            end: -1,
        };
    }

    let startIndex = 0;
    // Keep looking through our haystack if we can find our needle
    while (startIndex <= haystack.length && haystack.indexOf(needle, startIndex) > -1) {
        // find the next occurence of our needle starting from the startIndex
        const index = haystack.indexOf(needle, startIndex);
        if (index > -1) {
            // Now that we found our needle, look at the chars before and after it if they are a newline of undefined
            const beforeNeedleIndex = index - 1;
            const startsWithNewline = haystack[beforeNeedleIndex] === '\n' || haystack[beforeNeedleIndex] === undefined;

            const afterNeedleIndex = index + needle.length;
            const endsWithNewline = haystack[afterNeedleIndex] === '\n' || haystack[afterNeedleIndex] === undefined;

            // If this needle starts/ends with newline or undefined we found our match
            if (startsWithNewline && endsWithNewline) {
                return {
                    start: beforeNeedleIndex + 1,
                    end: afterNeedleIndex - 1,
                };
            }
        }

        // Calculate the next startIndex location by finding the next occurence and making sure its not -1
        const newStartPosition = haystack.indexOf(needle, startIndex + 1);
        startIndex = newStartPosition > -1 ? newStartPosition : haystack.length;
    }

    return {
        start: -1,
        end: -1,
    };
}
