import * as React from 'react';

import {StatusLabel, StatusLabelType} from '../../../container/status_label';

import {Answer} from '../../../../../models/answer';
import {Appraisal} from '../../../../../models/appraisal';
import {ConstructionalDefectsGroup} from '../../../../../business/constructional_defects/constructional_defects_groups_provider';
import {LabelSelectModalPresenter} from './label_select_presenter';
import {Loader} from '../../../components/loader';
import {Modal} from '../../questions/components/modal';
import {PredictionResult} from '../../../../../business/ml/vision/photo_content_predicter';
import {QuestionSet} from '../../../../../models/question_set';
import {QuestionType} from '../../../../../enum/question_type';
import {ValidationError} from '../../../../../models/validation_error';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';

interface OwnProps {
    appraisal: Appraisal;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    hiddenQuestionTypes: QuestionType[];
    predictionResult: PredictionResult | null;
    onSucceed: (value: {
        answerUuid: string | null;
        questionUuid: string | null;
        buildingCostsLabel: string | null;
    }) => void;
    photoRecognized: boolean;
}

export const LabelSelectModal: React.FC<OwnProps> = observer(function LabelSelectModal(props) {
    const presenter = usePresenter(
        (container) =>
            new LabelSelectModalPresenter(
                props.predictionResult,
                props.onSucceed,
                container.business.constructionalDefectsGroupsProvider(props.appraisal, props.questionSet)
            )
    );

    const [isVisible, setIsVisible] = React.useState(true);

    function renderFloor(answer: Answer) {
        return (
            <div className="form-block-select-option form-block-select-option-small">
                <input
                    id={answer.uuid}
                    type="radio"
                    name="floor"
                    value={answer.uuid}
                    onChange={() => presenter.onSelectFloor(answer.uuid)}
                    checked={presenter.activeFloor === answer.uuid}
                />
                <label htmlFor={answer.uuid}>
                    <span className="form-block-select-option-title">{answer.iteration}e woonlaag</span>
                </label>
            </div>
        );
    }

    function renderGroup(title: string, group?: ConstructionalDefectsGroup) {
        if (group !== undefined) {
            return (
                <div className="form-block-select-option form-block-select-option-small">
                    <input
                        id={group.id}
                        type="radio"
                        name="sub-group"
                        value={group.title}
                        onChange={() => presenter.onSelectSubGroup(group)}
                        checked={presenter.activeSubGroup?.id === group.id}
                    />
                    <label htmlFor={group.id}>
                        <span className="form-block-select-option-title">{group.title}</span>
                    </label>
                </div>
            );
        }

        return (
            <div className="form-block-select-option">
                <input
                    id={title}
                    type="radio"
                    name="group"
                    value={title}
                    onChange={() => presenter.onSelectGroup(title)}
                    checked={presenter.activeGroup === title}
                />
                <label htmlFor={title}>
                    <span className="form-block-select-option-title">{title}</span>
                </label>
            </div>
        );
    }

    const groups = Array.from(presenter.constructionalDefectsGroups ?? []);
    return (
        <Modal visible={isVisible} onHide={() => setIsVisible(false)} onShow={() => setIsVisible(true)} forced>
            <div className="modal-header">
                <h2>Details van de foto</h2>
            </div>
            <div className="modal-body">
                <p>Geef aan op welke locatie het aandachtspunt aanwezig is.</p>
                {props.photoRecognized && presenter.didFindGroupMatch ? (
                    <div className="row">
                        <div className="alert-inline alert-info ion-md-information-circle">
                            <StatusLabel type={StatusLabelType.Beta} content="Bèta" />
                            Onderstaand is de vermoedelijke locatie van het aandachtspunt ingevuld op basis van onze
                            fotoherkenning. Indien dit niet klopt dan kun je dit aanpassen.
                        </div>
                    </div>
                ) : null}
                <h3>Locatie</h3>
                <div className="form-block-select">
                    {groups.map((group: [title: string, groups: ConstructionalDefectsGroup[]]) =>
                        renderGroup(group[0])
                    )}
                </div>
                {presenter.activeGroup !== null ? (
                    <>
                        <h3>{presenter.activeGroup}</h3>
                        <div className="form-block-select">
                            {groups.map((group: [title: string, groups: ConstructionalDefectsGroup[]]) =>
                                group[1]
                                    .filter((g) => g.group === presenter.activeGroup)
                                    .map((g) => renderGroup(g.title, g))
                            )}
                        </div>
                    </>
                ) : null}
                {presenter.showFloors ? (
                    <>
                        <h3>Seleceer de verdieping</h3>
                        <div className="form-block-select">
                            {presenter.activeSubGroup?.floorAnswers.map((a) => renderFloor(a))}
                        </div>
                    </>
                ) : null}
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary ion-md-close" type="button" onClick={() => setIsVisible(false)}>
                    Sluiten
                </button>
                <button
                    className="btn btn-primary btn-full ion-md-arrow-forward"
                    type="button"
                    disabled={presenter.isDisabled || presenter.isLoading}
                    onClick={() => presenter.onSucceed()}
                >
                    {presenter.isLoading ? <Loader /> : null}
                    Opslaan
                </button>
            </div>
        </Modal>
    );
});
