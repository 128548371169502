import {
    AttachmentProps,
    ImageUploadState,
} from '../../appraise/ui/content/questions/advanced/attachment_question_presenter';

interface OldAttachmentProps extends Omit<AttachmentProps, 'state'> {
    synced?: boolean;
}

export function parseImageAnswerContents(contents: string): AttachmentProps {
    const parsedContents = JSON.parse(contents) as AttachmentProps | OldAttachmentProps;

    if (!('state' in parsedContents)) {
        const wasSynced = parsedContents.synced;
        delete parsedContents.synced;
        return {
            ...parsedContents,
            state: wasSynced === true ? ImageUploadState.SYNCED : ImageUploadState.UNSYNCED,
        };
    }

    return parsedContents;
}
